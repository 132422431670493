<div class="ui-g pb-4">
    <div class="ui-g-6 ui-sm-12">
        <label>{{'Field' | translate}}</label>
        <div class="input-group w-100">
            <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                    <i class="fa-solid fa-mouse-pointer"></i>
                </button>
            </div>
            <p-dropdown [options]="fieldNames" [(ngModel)]="selectedColField" placeholder="{{'Select One' | translate}}">
				<ng-template pTemplate="item" let-item>
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
				<ng-template let-item pTemplate="selectedItem">
					<span class="one-liner">{{item.label | translate}}</span>
				</ng-template>
            </p-dropdown>
        </div>
    </div>
    <div class="ui-g-6 ui-sm-12">
        <label>{{'Date Range' | translate}}</label>
        <div class="input-group w-100">
            <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                    <i class="fa-solid fa-calendar-week"></i>
                </button>
            </div>
            <mat-form-field class="w-100">
                <mat-date-range-input [rangePicker]="picker">
					<input matStartDate matInput placeholder="Start date" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
			 		<input matEndDate matInput placeholder="End date" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
                </mat-date-range-input>
			<mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <div class="input-group-append pl-3">
                <button class="btn btn-white" (click)="reset()" pTooltip="{{'button.reset' | translate}}" tooltipPosition="left"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
                <button class="btn btn-white" (click)="filterClientHistory()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
            </div>
        </div>
    </div>
</div>
<p-panel id="table-ppanel" [toggleable]="true" class="mb-4">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{selectedColField}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content?.length}})</span></span>
	</p-header>
	<p-table #table [value]="(dataSource$|async)?.content" [rows]="10" [totalRecords]="(dataSource$|async)?.content?.length" [paginator]="true" (onLazyLoad)="loadDataSource($event)" [lazy]="false" [responsive]="true">

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of cols">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
					</span>
				</th>
			</tr>
		</ng-template> 
		<ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of cols; let i = index">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="one-liner">
								<span class="mobile-table-label">{{this.cols[i].label | translate}}: </span>
								<span *ngIf="rowData[col.field]" pTooltip="{{this.rowData[col.field]}}">{{this.rowData[col.field]}}</span>
								<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'updateDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'M/d/yyyy h:mm a'}}">{{rowData.updatedDate | date: 'M/d/yyyy h:mm a'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>		
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
                                <span class="mobile-table-label">{{'Old Value' | translate}}: </span>
								<span *ngIf="rowData.oldValue" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
								<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
						</span>
						<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
                                <span class="mobile-table-label">{{'New Value' | translate}}: </span>
								<span *ngIf="rowData.newValue" pTooltip="{{rowData.newValue}}">{{rowData.newValue}}</span>
								<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
								<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5" style="margin: 46px auto !important;">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5" style="margin: 46px auto !important;"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>
