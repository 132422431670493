<ng-container [ngSwitch]="type">
  <span class="md-inputfield" *ngSwitchCase="'address'">
    <input type="text" #inputElement pInputText class="w-100"
           placeholder="{{placeholder | translate}}"
           autocomplete="nope"
           [formControl]="inputControl"
           (input)="onInputChange()"
           (change)="onInputChange()"
           (blur)="onBlur()"
           [readonly]="readonly"
           [required]="required"
           [id]="inputId"
           (keydown.enter)="onEnter()"
           [disabled]="disabled"
           >
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
    <div class="ui-message ui-messages-error ui-corner-all position-absolute"
         *ngIf="!!(inputControl && inputControl.invalid && (inputControl.dirty || inputControl.touched))">
      {{validationMessage}}
    </div>
  </span>

  <span class="md-inputfield" *ngSwitchCase="'phone'">
    <p-inputMask mask="(999) 999-9999" #inputElement
                 styleClass="w-100"
                 placeholder="{{placeholder | translate}}"
                 [inputId]="inputId"
                 [formControl]="inputControl"
                 (onComplete)="onInputChange()"
                 (onFocus)="phoneFocus()"
                 [readonly]="readonly"
                 [required]="required"
                 [disabled]="disabled"
                 [class.has-error]="inputControl.invalid"></p-inputMask>
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
    <div class="ui-message ui-messages-error ui-corner-all position-absolute"
         *ngIf="!!(inputControl && inputControl.invalid && inputControl.dirty )">
      {{validationMessage}}
    </div>
  </span>
  <span class="md-inputfield" *ngSwitchCase="'phoneBase'">
    <p-inputMask mask="(999) 999-9999" #inputElement autocomplete="nope"
                 styleClass="w-100"
                 placeholder="{{placeholder | translate}}"
                 [inputId]="inputId"
                 [formControl]="inputControl"
                 (onComplete)="onInputChange()"
                 (onFocus)="phoneFocus()"
                 [readonly]="readonly"
                 [required]="required"
                 [disabled]="disabled"></p-inputMask>
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
    <div class="ui-message ui-messages-error ui-corner-all position-absolute"
         *ngIf="!!(inputControl && inputControl.invalid && inputControl.dirty )">
      {{validationMessage}}
    </div>
  </span>
  <span class="md-inputfield" *ngSwitchCase="'ssn'">
    <p-inputMask mask="999-99-9999" #inputElement autocomplete="nope"
                 styleClass="w-100"
                 placeholder="{{placeholder | translate}}"
                 [inputId]="inputId"
                 [formControl]="inputControl"
                 (onComplete)="onInputChange()"
                 [readonly]="readonly"
                 [required]="required"
                 [disabled]="disabled"
                 [class.has-error]="inputControl.invalid"></p-inputMask>
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
    <div class="ui-message ui-messages-error ui-corner-all position-absolute"
         *ngIf="!!(inputControl && inputControl.invalid && inputControl.dirty )">
      {{validationMessage}}
    </div>
  </span>
  <span class="md-inputfield" *ngSwitchCase="'view_only'">
    <input [type]="type" #inputElement pInputText class="w-100" style="border: 0 !important;"
           autocomplete="nope"
           [id]="inputId"
           [formControl]="inputControl"
           (input)="onInputChange()"
           (change)="onInputChange()"
           (blur)="onBlur()"
           [disabled]="disabled"
           [readonly]="readonly"
           placeholder="{{placeholder | translate}}"
           (keydown.enter)="onEnter()"
           [required]="required">
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
  </span>

  <span class="md-inputfield" *ngSwitchDefault>
    <input [type]="type" #inputElement pInputText class="w-100"
           autocomplete="nope"
           [id]="inputId"
           tabindex="{{appTabindex}}"
           [formControl]="inputControl"
           (input)="onInputChange()"
           (change)="onInputChange()"
           (blur)="onBlur()"
           [readonly]="readonly"
           placeholder="{{placeholder | translate}}"
           [required]="required"
           [min]="min"
           [step]="step"
           (keydown.enter)="onEnter()"
           [maxlength]="maxlength"
           (keypress)="noSpecial($event.key)"
           (paste)="onPaste($event)"
           [disabled]="disabled"
           [class.has-error]="checkValid(inputControl)">
    <label class="{{labelClass || ''}}" *ngIf="showLabel === true" translate>{{placeholder}}<span style="color: red">{{required ? ' *' : ''}}</span></label>
    <div class="ui-message ui-messages-error ui-corner-all position-absolute"
         *ngIf="!!(inputControl && inputControl.invalid && (inputControl.dirty || inputControl.touched))">
      {{validationMessage}}
    </div>
  </span>
  <i *ngIf="showClear && !showPasswordIcon && inputControl.value" class="p-dropdown-clear-icon pi pi-times ng-tns-c51-11 ng-star-inserted" style="right: 20px; position: absolute"
     (click)="writeValue('')"></i>
  <i *ngIf="showPasswordIcon" class="pi pi-eye{{showPassword ? '' : '-slash'}} ng-tns-c51-11 ng-star-inserted" style="right: 25px; top: 22px; position: absolute" (click)="onShowPasswordChange()"></i>
  <i *ngIf="showPasswordIcon && inputControl.value" class="p-dropdown-clear-icon pi pi-times ng-tns-c51-11 ng-star-inserted" style="right: 45px; position: absolute"
     (click)="writeValue('')"></i>
</ng-container>
