import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientAddComponent } from '../component/client-add/client-add.component';
import { FreelancerAddComponent } from '../component/freelancer-add/freelancer-add.component';
import { FreelancerUpdateComponent } from '../component/freelancer-update/freelancer-update.component';
import { JobFollowUpAddComponent } from '../job-follow-up/component/job-follow-up-add/job-follow-up-add.component';
import { JobFollowUpUpdateComponent } from '../job-follow-up/component/job-follow-up-update/job-follow-up-update.component';
import { JobFollowUpViewComponent } from '../job-follow-up/component/job-follow-up-view/job-follow-up-view.component';
import {SpreadsheetListComponent} from '../../preadsheet/component/spreadsheet-list/spreadsheet-list.component';
import {RecollectionDetailsFormComponent} from '../../recollection-details/component/recollection-details-form/recollection-details-form.component';

export interface CanComponentLeave {
  canLeave: () => any;
}
@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<CanComponentLeave> {
  canDeactivate(component: CanComponentLeave): Promise<boolean> | boolean {
    if (component.canLeave) {
      return component.canLeave();
    }
    return true;
  }
}

export class CanDeactivateGuardClientLognAdd implements CanDeactivate<JobFollowUpAddComponent > {
  canDeactivate( component: JobFollowUpAddComponent): Observable<boolean> | boolean {
    return component.confirmNavigate();
  }
}

export class CanDeactivateGuardClientLogView implements CanDeactivate<JobFollowUpUpdateComponent > {
  canDeactivate( component: JobFollowUpUpdateComponent): Observable<boolean> | boolean {
    return component.confirmNavigate();
  }
}

export class CanDeactivateGuardContractor implements CanDeactivate<FreelancerAddComponent > {
  canDeactivate( component: FreelancerAddComponent): Observable<boolean> | boolean {
    return component.confirmNavigate();
  }
}

export class CanDeactivateGuardContractor1 implements CanDeactivate<FreelancerUpdateComponent > {
  canDeactivate( component: FreelancerUpdateComponent): Observable<boolean> | boolean {
    return component.confirmNavigate();
  }
}

export class CanDeactivateGuardSpreadsheetList implements CanDeactivate<SpreadsheetListComponent > {
  canDeactivate( component: SpreadsheetListComponent): Observable<boolean> | boolean {
    return component.confirmNavigate();
  }
}
