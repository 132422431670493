export class Popup {
    id: number;
    popupType: string;
    createdBy: string;
    message: string;
    employeeId: number;
    uniqueKey: number;
    status: number;
}

export class SuspendUser {
    employeeId: number;
    unit: number;
    time: any;
    suspendAt: number | Date = new Date();
    status: number;
    actionType: any;
    userName: any;
    reason: any;
}
