export class MonitorDetails {
  action: string;
  companyId: number;
  createdAt: any;
  createdByUsr: string;
  duration: number;
  endTime: any;
  id: number;
  lastModifiedBy:string;
  process: string;
  processStartTime: any;
  requestStartTime: any;
  startedById: number;
  startedByName: string;
  status: string;
  updatedAt: any;
  arguments:any;
  details?:any;
  userName?: string;
  userId?: number;
  projectId?: number;
  ipAddress: string;
}
