export enum Frequency {
    Daily, Monthly, Weekly
}

export enum PayType{
    HOURLY, SALARY
}

export enum RoundOn {
    UP, CLOSEST
}

export enum PayReference {
    Weekly, BIWEEKLY
}

export enum VacationMethod {
    YEARLY, 
    EMP_START_DATE
}

export enum WeekDays {

    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THRUSDAY,
    FRIDAY,
    SATURDAY
}

export enum SORT {
    ASC, DESC
}

export enum ResponseStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export enum HttpStatus {
    OK = 200,
    NOT_FOUND = 404
}

export enum ClockLocation{
    IN_OFFICE,
    REMOTELY
}

export enum ClockLocationName{
    IN_OFFICE = 'In Office',
    REMOTELY = 'Remotely',
    NA = 'N/A'
}