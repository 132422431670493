import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { ToDo } from '../model/todo.model';
import { environment } from '../../../environments/environment';
import { Stock } from '../../stock/model/stock.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class ToDoService extends AbstractService<ToDo> {



    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.TODO, '');
    }

    getToDoListByPage(params: any): Observable<Page<ToDo>> {
        return this._http.get<Page<ToDo>>(this.baseUrl + Constants.URI.TODO.LISTALLBYPAGE, { params: params }).pipe(map(res => res));
    }

    filter(options: any): Observable<Page<ToDo>> {
        return this._http.post<Page<ToDo>>(`${this.baseUrl}filter`, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(res => res));
    }

    getAllByDueDateAndStatus(options: any): Observable<ToDo[]> {
        return this._http.post<ToDo[]>(`${this.baseUrl}todos`, options).pipe(map(res => res));
    }

    getToDosByCompanyAndAgency(params: any): Observable<Page<ToDo>> {
        return this._http.get<Page<ToDo>>(
            this.baseUrl + Constants.URI.TODO.LISTALLBYCOMPANYANDAGENCY, { params: params }).pipe(map(res => res));
    }

    readAllForDropDownList(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrl + Constants.URI.TODO.DROWDOWNLIST, { params: params }).pipe(map(res => res));
    }

    generatePdfFile(params: any): Observable<any> {
        const url = `${environment.v2_server_ip}/${Constants.ROUTES.EXPORT_TODO}`;
        // const url = `http://localhost:8007/api/v2/operation/todo/export-pdf`;
        return this._http.post<any>(url, params).pipe(map(resp => resp));
    }


    generateTodoListPdf(todos, agencyId, start_end): Observable<any> {
        if (agencyId != null) {
            return this._http
                .post<any>(`${environment.server_ip}/${Constants.URI.TODO.PDFGENEREATE}/${Constants.URI.TODO.EXPORTTODOLIST}/${agencyId}/${start_end}`,
                    todos).pipe(map(res => res));
        } else {
            console.log('agency id ', agencyId);
            return this._http.post<any>(`${environment.server_ip}/${Constants.URI.TODO.PDFGENEREATE}/${Constants.URI.TODO.EXPORTTODOLIST}/${start_end}`, todos)
                .pipe(map(res => res));
        }
    }

    updateStatus(todoId, updatedStatus) {
        return this._http.get<any[]>(`${this.baseUrl + Constants.URI.TODO.UPDATESTATUS}/${todoId}/${updatedStatus}`).pipe(map(res => res));
    }

    getConcernsByUsername(username: string, date: number): Observable<ToDo[]> {
        return this._http.get<ToDo[]>(`${this.baseUrl}${Constants.URI.TODO.GETCONCERNBYUSERNAME}/${username}/${date}`).pipe(map(res => res));
    }

    createConcernDetails(concernDetails): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.CONCERN_DETAILS}`, concernDetails).pipe(map(res => res));
    }

    getConcernDetails(options: any): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.CONCERN_DETAILS}/search`, options).pipe(map(res => res));
    }

    createTodo(options: any): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.TODONOTE}/todo`, options).pipe(map(res => res));
    }

    dashboardSearch(options: any): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.TODONOTE}/todo/dashboard-search`, options).pipe(map(res => res));
    }

    countDashboardSearch(options: any): Observable<any> {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.TODONOTE}/todo/dashboard-count`, options).pipe(map(res => res));
    }

    exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const wscols = [{ wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 },  { wch: 25 }, { wch: 50 }, { wch: 50 }];

        worksheet['!cols'] = wscols;
        const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

        const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
        if (fileExtension) {
            this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
        } else {
            this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
        }
    }

    saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
        let downloadFileExtension: string = EXCEL_EXTENSION;
        if (fileExtension) {
            downloadFileExtension = fileExtension;
        }
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
    }
}
