<div class="ui-g pb-4">
	<div class="ui-g-5 ui-sm-12">
		<label>{{'Field' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-mouse-pointer"></i>
				</button>
			</div>
			<p-dropdown [options]="fieldsName"  placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedField" (onChange)="selectField($event)">
				<ng-template pTemplate="item" let-item>
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
				<ng-template let-item pTemplate="selectedItem">
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
			</p-dropdown>
		</div>
	</div>
	<div class="ui-g-7 ui-sm-12">
		<label>{{'Date Range' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-calendar-week"></i>
				</button>
			</div>
            <mat-form-field class="w-100">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="rangeDates[0]" (focus)="picker.open()">
                    <input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="rangeDates[1]" (focus)="picker.open()">
                </mat-date-range-input>
				<mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
			<div class="input-group-append pl-3">
				<button class="btn btn-white" (click)="reset()"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
				<button class="btn btn-white" (click)="loadDataSource()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
			</div>
		</div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History' | translate}}: {{historyFor?.label}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content.length}})</span></span>
	</p-header>
    <p-table #table [value]="(dataSource$|async)?.content" [rows]="(dataSource$|async)?.size" [paginator]="false" [scrollable]="true" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true" [loading]="loading">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumns" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
								<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort"
								ariaLabelDesc="Activate to sort in descending order"
								ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
							</span>
						</span>
					</ng-container>
					<p-overlayPanel #op1 appendTo="col">
						<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption, op1)">
							<ng-template let-col pTemplate="item">
								<div class="w-100">
									<i class="fa-solid {{col.img}} text-primary mr-2"></i>
									<span>{{col.name | translate}}</span>
								</div>
							</ng-template>
						</p-listbox>
					</p-overlayPanel>
					<p-overlayPanel #op2 appendTo="col">
						<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption, op2)">
							<ng-template let-col pTemplate="item">
								<div class="w-100">
									<i class="fa-solid {{col.img}} text-primary mr-2"></i>
									<span>{{col.name | translate}}</span>
								</div>
							</ng-template>
						</p-listbox>
					</p-overlayPanel>
				</th>
			</tr>
		</ng-template> 
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of displayColumns">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'MM/dd/yyyy h:mm:ss a'}}">{{rowData.updatedDate | date: 'MM/dd/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Username' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>		
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Old Value' | translate}}: </span>
								<span *ngIf="rowData.oldValue && rowData.fieldName !== 'documentId'" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
								<i *ngIf="rowData.oldValue && rowData.fieldName === 'documentId'" (click)="onViewDocument(rowData.oldValue)" class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Attachments' | translate}}"></i>
								<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'New Value' | translate}}: </span>
								<span *ngIf="rowData.newValue && rowData.fieldName !== 'documentId'" pTooltip="{{ rowData.newValue}}">{{rowData.newValue}}</span>
								<i *ngIf="rowData.newValue && rowData.fieldName === 'documentId'" (click)="onViewDocument(rowData.newValue)" class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Attachments' | translate}}"></i>
								<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
								<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading && !(dataSource$|async)?.totalElements">
				<td [attr.colspan]="5" class="text-center py-5">
					<span class="text-muted">{{'no data' | translate}}</span>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5" class="text-center py-5">
					<span class="text-muted">{{'Loading...' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>
