import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AbstractService} from "../../shared/service/abstract.service";
import {PaymentProfile} from "../model/PaymentProfile";
import { AuthService } from '../../shared/service/auth/auth.service';
import { Constants } from '../../shared/model/constants';
import { environment } from '../../../environments/environment';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class PaymentProfileService extends AbstractService<PaymentProfile>{
    reqHeaders:HttpHeaders;

    private paymentChargingSub = new BehaviorSubject<any>(null);

    private balanceChangeSub = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient, private authService: AuthService) {
        super(http, Constants.ROUTES.PAYMENT, 'profile');
        this.reqHeaders = new HttpHeaders({ 'X-App-Key': environment.payment_app_key}); 
    }
    
    registerPaymentProfile(username) {
        return this.http.post(this.baseUrl + '/' + username, {});
        
    }
    
    getPaymentProfile(cardType, ownerId, gateway) {
        return this.http.get(this.baseUrl + '/' + cardType + '/' + ownerId + '/' + gateway);
    }
    
    createPaymentProfile(payload) {
        return this.http.post(this.baseUrl, payload);
    }
    
    getPaymentProfiles(gateway, ownerId) {
        return this.http.get(this.baseUrl + '/list/' + gateway + '/' + ownerId);
    }
    
    applyTransferAmount(transferRequest) {
        return this.http.post(this.baseUrl + '/transfer/amount', transferRequest);
    }
    
    isExistedCardNumber(ownerId, encryptedRequest) {
        return this.http.post(this.baseUrl + '/validate/' + ownerId, encryptedRequest);
    }
    
    getBankName(rountingNumber) {
        return this.http.get(this.baseUrl + '/bank-name/' + rountingNumber);
    }
    
    applyChargeAmount(transferRequest) {
        return this.http.post(this.baseUrl + '/charge/amount', transferRequest);
    }
    
    deleteCard(profileId, profilePaymentId, cardType) {
        return this.http.get(this.baseUrl + '/delete/' + profileId + '/' + profilePaymentId + '/' + cardType);
    }

    deletePaymentCard(id: any, customerId: any, userType): Observable<any> {
        return this.http.delete<any>(`${this.paymentUrl}/payment-profile/card/${customerId}/${id}?user_type=${userType}`, {headers: {'X-App-Key': environment.payment_app_key}});
    }

    validateCardNumber(payload) {
        return this.http.post(this.baseUrl + '/card-number/validate', payload);
    }
    
    generateTransferInfo() {
        return this.http.get(this.baseUrl + '/generate/transfer-data');
    }

    // New APIs for payment process 
    getKeyStore(){
        return this.http.get(`${this.paymentUrl}/payment-profile/get-key-store`).map(res => res);
    }

    getBankKeyStore() {
        return this.http.get(`${this.paymentUrl}/bank/get-key-store`)
    }

    listBankAccount(appUserId, userType) {
        return this.http.get(`${this.paymentUrl}/bank/list?app_user_id=${appUserId}&user_type=${userType}`, {headers: this.reqHeaders})
    }

    listBankAccountExternal(appUserId, userType) {
        return this.http.get(`${this.paymentUrl}/bank/list/external?app_user_id=${appUserId}&user_type=${userType}`, {headers: this.reqHeaders})
    }
    
    addBankAccount(bankProfileRequest: any, storeKey: string, isExternal?) {
        const headerDict = {
            'X-App-Key': environment.payment_app_key,
            'X-Session-Id': storeKey
        }
        const reqHeaders: HttpHeaders = new HttpHeaders(headerDict);
        if (isExternal) {
            return this.http.post(`${this.paymentUrl}/bank/add`, bankProfileRequest, {headers: reqHeaders});
        }
        return this.http.post(`${this.paymentUrl}/bank/add/ach`, bankProfileRequest, {headers: reqHeaders});
    }

    verifyBankAccount(verifyBankPayload: any) {
        return this.http.post(`${this.paymentUrl}/bank/ach/verify`, verifyBankPayload, {headers: this.reqHeaders});
    }

    linkBankAccount(linkBankPayload: any) {
        return this.http.post(`${this.paymentUrl}/bank/link-account`, linkBankPayload, {headers: this.reqHeaders});
    }

    linkBankAccountStatus(accountId: any) {
        const params = {
            account_id: accountId
        }
        return this.http.get(`${this.paymentUrl}/bank/link-account-status`, {params:params, headers: this.reqHeaders});
    }

    deleteBankAccount(appUserId, userType, bankId, isExternal?) {
        if (isExternal) {
            return this.http.delete(`${this.paymentUrl}/connect/bank/delete/${appUserId}/${bankId}?user_type=${userType}`, {headers: this.reqHeaders})
        }
        return this.http.delete(`${this.paymentUrl}/bank/delete/${appUserId}/${bankId}?user_type=${userType}`, {headers: this.reqHeaders})
    }

    chargeBalanceAccount(payload, notify?) {
        return this.http.post(`${this.paymentUrl}/bank/charge-balance/amount`, payload, {headers: this.reqHeaders}).map(res => {
            if (notify) {
                this.paymentChargingSub.next(res);
            }
            this.notifyBalanceWalletChange();
            return res;
        });
    }

    chargeBankAccountAmount(payload, notify?) {
        return this.http.post(`${this.paymentUrl}/bank/charge/amount`, payload, {headers: this.reqHeaders}).map(res => {
            if (notify) {
                this.paymentChargingSub.next(res);
            }
            return res;
        });
    }

    encryptCardDetails(data:any, sessionId:string){
        const params = {
            data:data
        }
        const reqHeaders: HttpHeaders = new HttpHeaders({'X-Session-Id': sessionId});
        return this.http.get(`${this.paymentUrl}/bank/test-encrypt`, {params:params, headers: reqHeaders}).map(res => res);
    }

    createNewPaymentProfile(payload, sessionId:string) {
        const headerDict = {
            'X-App-Key': environment.payment_app_key,
            'X-Session-Id': sessionId
          }
        const reqHeaders: HttpHeaders = new HttpHeaders(headerDict);
        return this.http.post(`${this.paymentUrl}/payment-profile/add`, payload, {headers: reqHeaders}).map(res => res);
    }

    getUserCardList(userId:any, userType?) {
        let params;
        if (userType) {
            params = { user_app_id:userId, user_type: userType }
        } else {
            params = { user_app_id:userId }
        }
        return this.http.get(`${this.paymentUrl}/payment-profile/list`, { params:params, headers: this.reqHeaders}).map(res => res);
    }

    chargeAmount(payload, notify?) {
        return this.http.post(`${this.paymentUrl}/payment/charge/amount`, payload, {headers: this.reqHeaders}).map(res => {
            if (notify) {
                this.paymentChargingSub.next(res);
            }
            return res;
        });
    }

  chargeNewAmount(payload, sessionId:string) {
    const headerDict = {
      'X-App-Key': environment.payment_app_key,
      'X-Session-Id': sessionId
    }
    const reqHeaders: HttpHeaders = new HttpHeaders(headerDict);
    return this.http.post(`${this.paymentUrl}/payment/charge-new/amount`, payload, {headers: reqHeaders}).map(res => {
      /*if (notify) {
        this.paymentChargingSub.next(res);
      }*/
      return res;
    });
  }

    payoutAmount(transferRequest) {
        return this.http.post(this.paymentUrl + '/bank/payouts/amount', transferRequest, {headers: this.reqHeaders});
    }

    topUpAmount(transferRequest) {
        return this.http.post(this.paymentUrl + '/bank/top-ups/amount', transferRequest, {headers: this.reqHeaders});
    }

    destinationCharge(transferRequest) {
        return this.http.post(this.paymentUrl + '/bank/destination-charge/amount', transferRequest, {headers: this.reqHeaders});
    }

    transferAmount(transferRequest) {
        return this.http.post(this.paymentUrl + '/bank/transfer/amount', transferRequest, {headers: this.reqHeaders});
    }

    chargeThenTransfer(transferRequest) {
        return this.http.post(this.paymentUrl + '/bank/charge-transfer/amount', transferRequest, {headers: this.reqHeaders});
    }

    getAccountBalance(params) {
        return this.http.get(this.paymentUrl + '/bank/account-balance-v2', { params: params, headers: this.reqHeaders});
    }

    getTransactionList(params): Observable<any> {
        return this.http.get(`${this.paymentUrl}/transaction/transaction-list`, { params: params, headers: this.reqHeaders}).map(res => res);
    }

    listAccounts(params): Observable<any> {
        return this.http.get(`${this.paymentUrl}/account/list`, { params: params, headers: this.reqHeaders}).map(res => res);
    }

    unlinkAccount(params): Observable<any> {
        return this.http.get(`${this.paymentUrl}/account/unlink-account`, { params: params, headers: this.reqHeaders}).map(res => res);
    }

    get paymentChargingObservable(): Observable<boolean> {
        return this.paymentChargingSub.asObservable();
    }

    get balanceUpdateObservable(): Observable<boolean> {
        return this.balanceChangeSub.asObservable();
    }

    search(payload) {
        return this.http.post(`${this.paymentUrl}/payment-profile/search`, payload, { headers: this.reqHeaders}).map(res => res);
    }

    searchBank(payload) {
        return this.http.post(`${this.paymentUrl}/bank/search`, payload, { headers: this.reqHeaders}).map(res => res);
    }

    //verfiy bank account popup image
    openBankAccountImage(){
        return 'https://livestore.operrwork.com/operrwork/task_management/2022__8/2022_8_19__14_26_58__8__image_1660919256403.jpg'
    }


    searchBalanceTransaction(payload) {
        return this.http.post(`${this.paymentUrl}/transaction/balance_transactions`, payload, { headers: this.reqHeaders}).map(res => res);
    }

    getAvailableBalance(payload) {
        return this.http.post(`${this.paymentUrl}/transaction/available-balance_transactions`, payload, { headers: this.reqHeaders}).map(res => res);
    }

    notifyBalanceWalletChange() {
        this.balanceChangeSub.next(true);
    }
}
