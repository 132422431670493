import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { TransactionTypeService } from '../../service/transaction-type.service';
import { TransactionType } from '../../model/transaction-type.model';
import { CompanyService } from '../../../company/service/company.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { CommService } from '../../../employee/performance/service/comm.service';
import { TransactionTypeListComponent } from '../transaction-type-list/transaction-type-list.component';
import { Company } from '../../../company/model/company.model';
import { Message, MessageService } from 'primeng/api';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-transaction-type-form',
    templateUrl: './transaction-type-form.component.html',
    styleUrls: ['./transaction-type-form.component.css'],
    providers: [TransactionTypeService, AuthService, CompanyService, DropDownsService]
})
export class TransactionTypeFormComponent implements OnInit {
    @Input() mode: String;
    @Input() transactionTypeId: number;
    @ViewChild('ttList') ttList: TransactionTypeListComponent;
    msgs: Message[] = [];
    userPermission: boolean;
    loggedUserId: number;
    loggedUserName: string;
    transactionType: TransactionType;
    statusTypes: Array<any> = [];
    adminData: any;
    isPlatformAdmin: boolean = false;
    allCompanyList: Company[] = [];
    selectedCompany: any;
    transactionTyp: any;
    isNew: boolean = false;

    constructor(
        private transactionTypeService: TransactionTypeService,
        private router: Router,
        private commService: CommService,
        private companyService: CompanyService,
        private authService: AuthService,
        private messageService: MessageService,
        private translatePipe: TranslatePipe) {

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.loggedUserId = this.authService.getCurrentLoggedInId();
        this.loggedUserName = this.authService.getCurrentLoggedInName();
        this.transactionType = new TransactionType();
        if (this.transactionTypeId) {
            this.getTransactionType();
        }
        if (!this.isPlatformAdmin) {
            this.selectedCompany = this.authService.getCurrentCompany();
        } else {
            this.loadAllCompanies();
        }
        this.statusTypes = [{ label: 'active', value: 'Active' }, { label: 'inactive', value: 'Inactive' }];
        this.userPermission = this.authService.adminRole();
        this.transactionType.status = 'Active';
        this.getTransactions();
    }

    updateTransactionType() {
        if (this.submitClicked) return;
        this.submitClicked = true;
        this.isNew = false;
        if (this.isContractExist()) {
            setTimeout(() => {
                this.submitClicked = false;
            }, 1000);
            return this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Error'),
              detail: this.translatePipe.transform(`This Type already Exist, please add different name!`)
            });
          }
        const transactionTypeData: any = this.transactionType;
        this.transactionType.companyId = (this.isPlatformAdmin) ? this.selectedCompany.key : this.authService.getCurrentCompany();
        this.transactionTypeService.updateTransactionType(transactionTypeData).subscribe(res => {
            this.msgs = [];
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('TransactionTypeUpdated') });
                // this.ttList.search();
                setTimeout(() => this.router.navigate(['app/transaction-type/add']), 2000);
                setTimeout(() => {
                    this.submitClicked = false;
                }, 2000);
            }
        }, () => {
            setTimeout(() => {
                this.submitClicked = false;
            }, 2000);
        });
    }

    getTransactionType() {
        this.transactionTypeService.getTransactionType(this.transactionTypeId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.transactionType = resObj.data;
            }
        });
    }

    submitClicked = false;
    createTransactionType() {
        if (this.submitClicked) return;
        this.submitClicked = true;
        this.isNew = true;
        if (this.isContractExist()) {
            setTimeout(() => {
               this.submitClicked = false;
            }, 1000);
            return this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Error'),
              detail: this.translatePipe.transform(`This Type already Exist, please add different name!`)
            });
          }
        this.transactionType.companyId = (this.isPlatformAdmin) ? this.selectedCompany.key : this.authService.getCurrentCompany();
        this.transactionType.createdBy = this.loggedUserId;
        this.transactionType.creatorName = this.loggedUserName;
        this.transactionTypeService.createTransactionType(this.transactionType).subscribe(res => {
            console.log('createTransactionType res: ', res)
            const resObj: any = res;
            this.msgs = [];
            if (resObj.status === 'SUCCESS') {
                this.messageService.add({ severity: 'success',
                    summary: this.translatePipe.transform('success'), detail: this.translatePipe.transform('TransactionTypeCreated') });
                this.transactionType = new TransactionType();
                this.reset();
                this.getTransactions();
                setTimeout(() => {
                    this.submitClicked = false;
                }, 1000);
            } else {
                this.messageService.add({ detail: 'Transaction Type Created Fail!', severity: 'error', summary: 'Error' });
            }
        }, (err) => {
            this.messageService.add({ detail: 'Transaction Type Created Fail!', severity: 'error', summary: 'Error' });
            setTimeout(() => {
                this.submitClicked = false;
            }, 1000);
        });
    }

    reset() {
        this.ttList.table.reset();
        this.ttList.searchText = null;
        this.selectedCompany = null;
        this.transactionType.transactionType = null;
        this.transactionType.status = undefined;
        this.ttList.setCompanyId(0);
        this.ttList.search();
        this.router.navigate(['/', 'app', 'transaction-type', 'add']);
    }

    getHeader() {
        return this.mode === 'edit' ? 'EditTransactionType' : 'AddTransactionType';
    }

    isSubmitDisabled() {
        return (this.isPlatformAdmin && !this.selectedCompany) || (!this.transactionType.transactionType || this.noWhitespaceValidator()) || (this.transactionType.status === undefined);
    }

    noWhitespaceValidator() {
        if(this.transactionType && this.transactionType.transactionType){
            const isWhitespace = this.transactionType.transactionType.trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : { 'whitespace': true };
        }
      }

    loadAllCompanies() {
        this.companyService.getCompaniesDropdown({}).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            this.allCompanyList = resObj.data;
            if (this.transactionType.companyId) {
                this.selectedCompany = this.allCompanyList.find(company => company.key == this.transactionType.companyId);
            }
          }
        });
    }

    search() {
        this.ttList.setCompanyId(this.selectedCompany.key);
    }

    getTransactions() {
        this.transactionTyp = [];
        const options:any = {}
        if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
        if (this.isPlatformAdmin && this.selectedCompany) options.companyId = this.selectedCompany.key;
        this.transactionTypeService.findAllTransactionType(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS'){
                resObj.data.content.forEach(ele => {
                    this.transactionTyp.push({label:ele.transactionType, value:ele.companyId})
                })
                console.log(this.transactionTyp)
            } 
        });

    }


    isContractExist() {
        const companyId = this.isPlatformAdmin ? this.selectedCompany.key : this.authService.getCurrentCompanyId()
        const isExist = this.transactionTyp.filter(e => 
            e.label.trim().toLowerCase() === this.transactionType.transactionType.trim().toLowerCase() 
            && e.value === companyId
        )
        if (this.isNew && isExist.length > 0) return true;
        if (!this.isNew && isExist.length > 1) return true;
        return false;
      }

	  goBack() {
		window.history.back();
	  }
}
