import { Component, OnInit, ViewChild, Input } from '@angular/core';
import moment, * as _moment from 'moment';
import * as _ from 'lodash';
import { PayrollJournalReportService } from '../../service/payroll-journal-report.service';
import { MessageService, Table } from 'primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUtility } from 'app/shared/utility/file.utility';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { CompanyService } from 'app/company/service/company.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { forkJoin, fromEvent, interval } from 'rxjs';
import { debounceTime, takeWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs-compat';
import { EmployeeService } from 'app/employee/service/employee.service';

@Component({
  selector: 'app-payroll-journal-report-preview',
  templateUrl: './payroll-journal-report-preview.component.html',
  styleUrls: ['./payroll-journal-report-preview.component.scss'],
  providers: [PayrollJournalReportService, OperatorService]
})
export class PayrollJournalReportPreViewComponent implements OnInit {
  @Input() id: number;
  @Input() detailId: number;
  company: any;
  payrollEmployee: any;
  payrollReportData: any;
  allEmployees: any[];
  allPayrollReports: any[];
  cols: any = [
    { label: '#', field: 'id', sortOptions: '', sort: 'number', width: '200px' },
    { label: 'Emp ID', field: 'empId', sortOptions: '', sort: 'text', width: '200px' },
    { label: 'First Name', field: 'firstName', sortOptions: '', sort: 'text', width: '200px' },
    { label: 'Last Name', field: 'lastName', sort: 'text', width: '200px' },
    { label: 'Employee Type', field: 'employeeType', sort: 'text', width: '200px' },
    { label: 'Payment', field: 'payment', sort: 'text', width: '200px' },
    { label: 'Regular Hours', field: 'regularHours', sort: 'number', width: '200px' },
    { label: 'Regular Rate', field: 'regularRate', sort: 'number', width: '200px' },
    { label: 'Regular Amount', field: 'regularAmount', sort: 'number', width: '200px' },
    { label: 'Overtime Hours', field: 'overtimeHours', sort: 'number', width: '200px' },
    { label: 'Overtime Rate', field: 'overtimeRate', sort: 'number', width: '200px' },
    { label: 'Overtime Amount', field: 'overtimeAmount', sort: 'number', width: '200px' },
    { label: 'Gross Earnings', field: 'grossEarnings', sort: 'number', width: '200px' },
    { label: 'Employee Taxes', field: 'employeeTaxes', sort: 'number', width: '200px', tax: true },
    { label: 'Federal Income Tax', field: 'federalIncomeTaxEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'Social Security (Employee)', field: 'socialSecurityEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'Medicare (Employee)', field: 'medicareEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'Additional Medicare (Employee)', field: 'additionalMedicareEmployee', sort: 'number', width: '200px', tax: true, customizeColumn: true },
    { label: 'NY State Withholdings Tax (Employee)', field: 'nyStateWithholdingsTaxEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'NY SDI (Employee)', field: 'nySdiEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'NY Family Leave Insurance (Employee)', field: 'nyFamilyLeaveInsuranceEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'New York City Tax (Employee)', field: 'newYorkCityTaxEmployee', sort: 'number', width: '200px', tax: true },
    { label: 'Employer Taxes', field: 'employerTaxes', sort: 'number', width: '200px' },
    { label: 'Social Security (Employer)', field: 'socialSecurityEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'Medicare (Employer)', field: 'medicareEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'FUTA (Employer)', field: 'futaEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'NY SUI (Employer)', field: 'nySuiEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'NY Reemployment (Employer)', field: 'nyReemploymentEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'NY MCTMT (company tax) (Employer)', field: 'nyMctmtCompanyTaxEmployer', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'Net Pay', field: 'netPay', sort: 'number', width: '200px' },
    { label: 'Reimbursements', field: 'reimbursements', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'Donations', field: 'donations', sort: 'number', width: '200px', customizeColumn: true },
    { label: 'Check Amount Employer Cost', field: 'checkAmount', sort: 'number', width: '200px' },
  ];

  wrongEmployee = false;
  employee;

  constructor(
    private authService: AuthService,
    private operatorService: OperatorService,
    private messageService: MessageService,
    private payrollJournalReportService: PayrollJournalReportService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private router: Router,
    private employeeService: EmployeeService) {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.detailId = +params.detailId;
    });
  }

  ngOnInit() {
    this.companyService.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
      this.company = res.data;
    });
    this.loadPayrollJournalReport();
  }

  loadPayrollJournalReport() {
    this.payrollJournalReportService.getId(this.id).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS' && res.data) {
        this.payrollReportData = resObj.data;
        this.allPayrollReports = resObj.data.payrollEmployeeReports;
        const payrollEmployee = this.allPayrollReports.find(r => r.id === this.detailId);
        if (payrollEmployee) {
          if (this.authService.getUserInfo().adminId === payrollEmployee.empId) {
            this.employeeService.get(payrollEmployee.empId).subscribe((res: any) => {
              this.employee = res.data;
              this.generatePaystubPdf(payrollEmployee);
            });
            this.wrongEmployee = false;
          } else {
            this.wrongEmployee = true;
          }
        }
      }
    });
  }

  generatePaystubPdf(payrollEmployee) {
    const employeeAddress: string = payrollEmployee.workAddress ? payrollEmployee.workAddress : '';
    const payrollTaxDetails = [];
    let deductions = payrollEmployee.employeeTaxes ? payrollEmployee.employeeTaxes : 0;
    let ytdDeductions = 0;

    this.cols.filter(c => c.tax).forEach(c => {
      if (payrollEmployee[c.field] > 0) {
        const value = Number(payrollEmployee[c.field] ? payrollEmployee[c.field].toFixed(2) : 0);
        const ytdValue = 0;
        let taxName = c.label;
        if (value > 0) {
          payrollTaxDetails.push({
            taxName: taxName,
            taxTypeName: '',
            valueStr: '$' + value,
            ytdValueStr: '$' + ytdValue.toFixed(2)
          });
        }
      }
    });
    let password = this.employee.pin;
    const netPay = payrollEmployee.netPay > 0 ? Number(payrollEmployee.netPay.toFixed(2)) : 0;
    const ytdTotal = 0;
    const total = netPay + deductions;
    let ytdNetPay = 0;
    const currentPay = payrollEmployee.regularAmount ? Number(payrollEmployee.regularAmount) : 0;
    const overCurrentPay = payrollEmployee.overtimeAmount ? Number(payrollEmployee.overtimeAmount) : 0;
    const payload = {
      companyName: '',
      companyAddress: '',
      companyPhone: '',
      employeeName: payrollEmployee.firstName + ' ' + payrollEmployee.lastName,
      employeeAddress: employeeAddress,
      employeeSsn: '',
      reportingPeriod: moment(this.payrollReportData.payrollPeriodStartDate).format('MM/DD/YYYY') + ' - ' + moment(this.payrollReportData.payrollPeriodEndDate).format('MM/DD/YYYY'),
      payDate: moment(this.payrollReportData.payrollPeriodStartDate).format('MM/DD/YYYY'),
      employeeId: null,
      rate: payrollEmployee.regularRate > 0 ? '$' + payrollEmployee.regularRate : '$0',
      hours: !payrollEmployee.regularHours || payrollEmployee.regularHours === 0 ? '00:00' : payrollEmployee.regularHours,
      currentPay: '$' + currentPay.toFixed(2),
      overRate: payrollEmployee.overtimeRate > 0 ? '$' + payrollEmployee.overtimeRate : '$0',
      overHours: !payrollEmployee.overtimeHours || payrollEmployee.overtimeHours === 0 ? '00:00' : payrollEmployee.overtimeHours,
      overCurrentPay: '$' + overCurrentPay.toFixed(2),
      payrollTaxDetails: payrollTaxDetails,
      ytdGross: '$' + ytdTotal.toFixed(2),
      ytdDeductions: '$' + ytdDeductions.toFixed(2),
      ytdNetPay: '$' + ytdNetPay.toFixed(2),
      total: '$' + total.toFixed(2),
      deductions: '$' + deductions.toFixed(2),
      netPay: '$' + netPay.toFixed(2),
      password: password
    }
    this.operatorService.exportPayStubStub(payload).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        document.querySelector("iframe").src = blobUrl;
      }
    });
  }
}
