<form [formGroup]="contractForm" class="p-4">
	<div class="ui-g">
		<div class="ui-g-12 text-center">
			<img class="mt-4" src="https://www.operrgroup.com/images/operr-title.png" style="width: 220px; height:auto">
			<h1 class="m-0">{{'MAS Billing Service Contract' | translate}}</h1>
			<span style="font-size: 16px;line-height: 1.4;">30-50 Whitestone Expressway<br>Suite 402, Flushing N.Y. 11354<br>(929) 201-1899</span>
		</div>
	</div>
	<div class="ui-g mt-4" style="row-gap: 50px">
		<div class="ui-g-12 ui-sm-12">
			<div class="ui-g-12 p-0">	
				<h1 class="m-0 ml-2 my-4">{{'Sales Agent' | translate}}</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-briefcase text-primary mr-2"></i> {{'Merchant Details' | translate}}</span>
					</p-header>
					<div class="ui-g pb-3">
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Authorized Agent' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="printNameCompany" pInputText placeholder="{{'Enter name' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameCompany')" >{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Email' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="emailCompany" pInputText placeholder="example@operr.com">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isEmail('emailCompany') && isRequired('emailCompany')">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>	
								<p-inputMask class="w-100" formControlName="directPhoneCompany" mask="(999) 999-9999" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneCompany')" >{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Date' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>	
								<mat-form-field>
									<input matInput placeholder="{{'Select One' | translate}}" [ngxMatDatetimePicker]="picker1" formControlName="dateCompany" (focus)="picker1.open()">
									<ngx-mat-datetime-picker #picker1 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateCompany')" >{{'This field is required' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
			<div class="ui-g-12 p-0">
				<h1 class="m-0 ml-2 my-4">{{'Customer' | translate}}</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-handshake-alt text-primary mr-2"></i> {{'Client Details' | translate}}</span>
					</p-header>
					<div class="ui-g pb-3">
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Client Company' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-briefcase"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="clientName" pInputText placeholder="{{'Enter name' | translate}}">
							</div>	
							<span class="ui-message ui-messages-error" *ngIf="isRequired('clientName')" >{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Authorized Agent' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="printNameClient" pInputText placeholder="{{'Enter name' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameClient')" >{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Address' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="addressClient" pInputText placeholder="{{'Full address' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('addressClient')" >{{'This field is required' | translate}}</span>           
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-phone"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="phoneClient" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('phoneClient')" >{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Email' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="emailClient" pInputText placeholder="example@operr.com">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('emailClient') || isEmail('emailClient')">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="directPhoneClient" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneClient')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Date' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>
								<mat-form-field class="w-100">
									<input matInput [ngxMatDatetimePicker]="picker2"  placeholder="{{'Select One' | translate}}" formControlName="dateClient" (focus)="picker2.open()">
									<ngx-mat-datetime-picker #picker2 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateClient')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
		</div>

		<div class="ui-g-12 ui-sm-12" id="contract-area2">
			<div class="ui-g-12 p-0">
				<h1 class="m-0 mb-2">{{'Service Agreement' | translate}}</h1>
				<p class="m-0 mb-4">{{'fidelisBillingIntro' | translate}}</p>		
				<div class="" style="font-size: 16px;line-height: 1.4;">{{'Client is a For-Hire Vehicle (FHV) base with a base name and number of...' | translate}}</div>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Base Name (Client)' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-briefcase"></i>
								</button>
							</div>
							<input type="text" formControlName="clientNameContract" pInputText placeholder="{{'Client Company' | translate}}">
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('clientNameContract')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Base Number (T&LC)' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-hashtag"></i>
								</button>
							</div>
							<input type="text" formControlName="baseNumber" pInputText placeholder="{{'T&LC Base Number' | translate}}">
						</div>
					</div>
				</div>
				
				
				<div class="mt-5" style="font-size: 16px;line-height: 1.4"><b>{{'Both parties, Operr and Client enter into the following service agreement:' | translate}}</b></div>

				<h4 class="mb-2 mt-5">{{'Services' | translate}}</h4>
				<p class="m-0 mb-4">{{'Client Shall Pay the Following Fees for Operr’s Billing Services:' | translate}}</p>
				<ul>
					<li class="mb-4">
						<p class="m-0" style="font-size: 16px">{{'Percentage of total funds received from MAS preliminary billing in the amount of...' | translate}}</p>
						<p class="m-0 mb-2 asterisk-text text-muted" style="font-style: italic">{{'MASbullet1Subtext' | translate}}</p>
						<div class="d-flex" style="column-gap: 60px">
							<div>
								<label>{{'Client Sign-off Rate' | translate}}<span class="required-indicator">*</span></label>
								<div class="input-group" style="max-width: 100px">
									<div class="input-group-prepend p-0">
										<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
											<i class="fa-solid fa-percent"></i>
										</button>
									</div>
									<input type="text" pInputText formControlName="fromMas">
								</div>
								<span class="ui-message ui-messages-error" *ngIf="isRequired('fromMas')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
							</div>
							<div>
								<label>{{'Operr Sign-off Rate' | translate}}<span class="required-indicator">*</span></label>
								<div class="input-group" style="max-width: 100px">
									<div class="input-group-prepend p-0">
										<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
											<i class="fa-solid fa-percent"></i>
										</button>
									</div>
									<input type="text" pInputText formControlName="unchangedRemain">
								</div>
								<span class="ui-message ui-messages-error" *ngIf="isRequired('unchangedRemain')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
							</div>
						</div>
					</li>
					<li class="mb-4">

						<p class="m-0" style="font-size: 16px">{{'Percentage of money claimed from MAS in the amount of...' | translate}}<span class="required-indicator">*</span></p>
						<p class="m-0 mb-2 asterisk-text text-muted" style="font-style: italic">{{'MASbullet2Subtext' | translate}}</p>
						<div class="input-group" style="max-width: 100px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-percent"></i>
								</button>
							</div>
							<input type="text" formControlName="operrClaim" pInputText>
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('operrClaim')" style="font-size: 12px !important">{{'This field is required' | translate}}</span>
					</li>
					<li class="mb-2">
						<p class="m-0" style="font-size: 16px">{{'Billing service rates under 1.1 have to be re-negotiated if client does not comply with 1.3 clause.' | translate}}</p>
					</li>
					<li>
						<p class="m-0" style="font-size: 16px">{{'Within two weeks of effectiveness of the contract, the above rate may be subject to renegotiation.' | translate}}</p>
					</li>
				</ul>

				<h4 class="mb-4 mt-5">{{'Payment Terms' | translate}}</h4>
				<p class="m-0 mb-2"><b>1.</b> {{'MASpaymentTerms1' | translate}}</p>
				<p class="m-0 mb-2"><b>2.</b> {{'MASpaymentTerms2' | translate}}</p>
				<p class="m-0 mb-2"><b>3.</b> {{'MASpaymentTerms3' | translate}}</p>
				<p class="m-0 mb-2"><b>4.</b> {{'MASpaymentTerms4' | translate}}</p>
				<p class="m-0 mb-2"><b>5.</b> {{'MASpaymentTerms5' | translate}}</p>
				<p class="m-0 mb-2"><b>6.</b> {{'MASpaymentTerms6' | translate}}</p>
				
				<h4 class="mb-2 mt-4">{{'Disputes' | translate}}</h4>
				<p class="m-0">{{'fidelisBillingDisputesSubtext' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Termination' | translate}}</h4>
				<p class="m-0">{{'fidelisBillingTerminationSubtext' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Agreement' | translate}}</h4>
				<p class="m-0">{{'The terms set forth in this Service Agreement constitute the entire Agreement.' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Effectiveness' | translate}}</h4>
				<p class="m-0 mb-2">{{'Upon both parties signatures, the above Service Agreement is agreed upon and is effective from...' | translate}}</p>
				<div class="input-group mt-2" style="max-width: 260px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<mat-form-field>
						<input matInput [ngxMatDatetimePicker]="picker3" formControlName="effectiveDate" (focus)="picker3.open()">
						<ngx-mat-datetime-picker #picker3 [enableMeridian]="true"></ngx-mat-datetime-picker>
					</mat-form-field>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="isRequired('effectiveDate')" >{{'This field is required' | translate}}</span>
			
				<h1 class="mt-5">{{'Signatures' | translate}}</h1>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Merchant' | translate}}</label>   
						<div class="input-group d-flex">
							<div id="companySignpad">
								<signature-pad #companySignpad [options]="signaturePadConfig" height="300" width="300"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign()">
									<i class="fa-solid fa-eraser text-danger px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Client' | translate}}</label>  
						<div class="input-group d-flex">
							<div id="clientSignpad">
								<signature-pad #clientSignpad [options]="signaturePadConfig" height="300" width="300"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign('client')">
									<i class="fa-solid fa-eraser text-danger px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g">
		<div class="ui-g-12 text-center py-4">
			<button (click)="cancelEditing()" *ngIf="disableEditingContract" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>	
			<button (click)="edit()" *ngIf="disableEditingContract && showEditButton" class="btn btn-info mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit' | translate}}</button>	
			<button class="btn btn-secondary btn-lg mx-1" [cdkCopyToClipboard]="copyLink()" (cdkCopyToClipboardCopied)="onClipboardCopy($event)" pTooltip="{{'Copy URL' | translate}}"><i class="fa-solid fa-link"></i></button>
			<button class="btn btn-purple btn-lg mx-1" (click)="showSendEmail()"><i class="fa-solid fa-paper-plane mr-2"></i>{{'Email Contract' | translate}}</button>
			<button (click)="generateContract()" *ngIf="!disableEditingContract" class="btn btn-primary btn-lg mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Complete' | translate}}</button>
		</div>
	</div>
</form>

<p-dialog *ngIf="shareContractDialog" [(visible)]="shareContractDialog" [showHeader]="true" showEffect="fade" [closable]="true" [style]="{'width':'90%', 'max-width':'600px'}" [modal]="true" [dismissableMask]="true">
	<p-header>
		<span><i class="fa-solid fa-paper-plane mr-2"></i>{{'Send Contract' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Recipent' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-users"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="clientName" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
            <label>{{'E-Mail' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-at"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="emailClientSelect" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
            <label>{{'Subject' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-inbox"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="modalSubject" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-12 pt-4">
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Message' | translate}}</span>
				</p-header>
				<div style="pointer-events: none;">
					<p-editor [(ngModel)]="bodyEmail" [style]="{'height':'220px', 'pointer-events':'none'}" ></p-editor>
				</div>
			</p-panel>
		</div>
    </div>
    <p-footer class="d-flex justify-content-between align-items-center">
		<button type="button" (click)="rejectEmail()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="sendEmail()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
    </p-footer>
</p-dialog>
