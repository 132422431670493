import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { TransactionTypeUpdateComponent } from './component/transaction-type-update/transaction-type-update.component';
import { TransactionTypeAddComponent } from './component/transaction-type-add/transaction-type-add.component';
import { TransactionTypeLayoutComponent } from './component/transaction-type-layout/transaction-type-layout.component';
import { TransactionListComponent } from './component/transaction-list/transaction-list.component';
import { TransactionAddComponent } from './component/transaction-add/transaction-add.component';
import { TransactionUpdateComponent } from './component/transaction-update/transaction-update.component';
import { TransactionGroupAddComponent } from './component/transaction-group-add/transaction-group-add.component';
import { TransactionGroupUpdateComponent } from './component/transaction-group-update/transaction-group-update.component';
import { StatementBalanceComponent } from './component/statement-balance/statement-balance.component';
import { TransactionDiscountListComponent } from './component/transaction-discount-list/transaction-discount-list.component';

export const TransactionTypeRoutes: Routes = [
    {
        path: 'app/transaction-type',
        component: TransactionTypeLayoutComponent,
        children: [
            { path: 'add', component: TransactionTypeAddComponent, canActivate: [AuthGuard] },
            { path: 'edit/:id', component: TransactionTypeUpdateComponent, canActivate: [AuthGuard] },
        ]
    },
    {
        path: 'app/transaction',
        component: TransactionTypeLayoutComponent,
        children: [
            { path: '', redirectTo: 'app/transaction/list', pathMatch: 'full' },
            { path: 'list', component: TransactionListComponent, canActivate: [AuthGuard] },
            { path: 'add', component: TransactionAddComponent, canActivate: [AuthGuard] },
            { path: 'edit/:id', component: TransactionUpdateComponent, canActivate: [AuthGuard] },
            { path: 'statement-balance', component: StatementBalanceComponent, canActivate: [AuthGuard] },
            { path: 'discount', component: TransactionDiscountListComponent, canActivate: [AuthGuard] },
            { path: 'group', component: TransactionGroupAddComponent, canActivate: [AuthGuard] },
            { path: 'group/edit/:id', component: TransactionGroupUpdateComponent, canActivate: [AuthGuard] },
        ]
    },
    {
        path: 'app/statement-balance',
        component: TransactionTypeLayoutComponent,
        children: [
            { path: '', redirectTo: 'app/statement-balance/list', pathMatch: 'full' },
            { path: 'list', component: StatementBalanceComponent, canActivate: [AuthGuard] },
        ]
    },
];

export const TransactionTypeRoutingModule = RouterModule.forChild(TransactionTypeRoutes);
