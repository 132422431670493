<p-toast></p-toast>
<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>

<div class="login-body">
    <div *ngIf="expiredTokenMsg" class="login-panel container">
        <div class="row justify-content-md-center align-self-center" style="text-align: center; display: inline;">
            <div class="col-md-auto" style="margin-top: 100px">
                <img src="../../../assets/images/warning-icon-svg-11.jpg" style="width: 100px;height: auto;"><br>
                <h3>{{expiredTokenMsg}}</h3>
            </div>
        </div>
    </div>
    
    <div class="login-panel ui-fluid" *ngIf="!expiredTokenMsg">
        <div class="login-panel-header">
            <h3>Reset Password</h3>
        </div>
        <div class="row mx-0 mb-1 align-items-center" style="color:red;">
            <h4 style="text-align:center;font-weight: 500;margin: auto;" *ngIf="maxLimit">
                You are allowed to enter maximum 30 characters!</h4>
        </div>
        <div class="login-panel-content">
            <div class="ui-g-12">
                <div class="ui-g-11">
                    <span class="ui-float-label">
                        <input pInputText *ngIf="!isShowPassword" type="password" id="password" [(ngModel)] ="resetPassworReq.password" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
                        (keypress)="checkInput($event)"
                        autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
                        <input pInputText *ngIf="isShowPassword" type="text" id="password" [(ngModel)] ="resetPassworReq.password" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
                        (keypress)="checkInput($event)"
                        autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
                        <label for="password">Password</label>
                    </span>
                </div>
                <div class="ui-g-1">
                    <i *ngIf="isShowPassword" class="fa-solid fa-eye"  (click)="showPassword()"></i>
                    <i *ngIf="!isShowPassword" class="fa-solid fa-eye" style="opacity: 0.5;" (click)="showPassword()"></i>
                </div>
            </div>
            <div class="ui-g-12">
                <div class="ui-g-11">
                    <span class="ui-float-label">
                        <input pInputText *ngIf="!isShowRePassword" type="password" id="re-password" [(ngModel)] ="resetPassworReq.rePassword" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
                        (keypress)="checkInput($event)"
                        autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
                        <input pInputText *ngIf="isShowRePassword" type="text" id="re-password" [(ngModel)] ="resetPassworReq.rePassword" class="ui-inputtext ui-corner-all ui-widget" minLength='10'  maxLength='30' 
                        (keypress)="checkInput($event)"
                        autocorrect="off" spellcheck="false" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
                        <label for="re-password">Re-Password</label>
                    </span>
                </div>
                <div class="ui-g-1">
                    <i *ngIf="isShowRePassword" class="fa-solid fa-eye"  (click)="showRePassword()"></i>
                    <i *ngIf="!isShowRePassword" class="fa-solid fa-eye" style="opacity: 0.5;" (click)="showRePassword()"></i>
                </div>
            </div>
            <div class="ui-g-12">
                <div class="ui-g-11">
                    <div class="ui-float-label {{ configCaptcha.cssClass}}">
                        <canvas id="captcahCanvas" width="310" height="80"></canvas>
                        <div class="captcha-actions">
                            <input type="text" [(ngModel)]="captch_input"/>
                            <a href="javascript:void(0)" class="cpt-btn" (click)="playCaptcha()">🔈</a>
                            <a href="javascript:void(0)" class="cpt-btn reload" (click)="createCaptcha()">&#x21bb;</a>
                        </div>
                    </div>
                </div>
                <div class="ui-g-1"></div>
            </div>
            <div class="divider divider-md"></div>
            <div *ngIf="message" class="ui-g-12" class="text-red"> {{message}}  </div>
            <div *ngIf="successMsg" class="ui-g-12" class="text-green"> {{successMsg}} </div>
            <div class="ui-g-12">
                <button type="button" (click)="resetPassword()" [disabled]="!resetPassworReq.password || !resetPassworReq.rePassword || resetPassworReq.password.trim() == '' || resetPassworReq.rePassword.trim() == '' " class="ui-button-secondary ui-button ui-widget ui-corner-all">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>