import { CompanySettingV2 } from '../model/v2/company-setting.model';
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { environment } from '../../../environments/environment';
import { CompanySetting } from '../model/company-setting.model';
import { Contract } from '../model/contract.model';
import { ServiceType } from '../model/service-type.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {CompanySearch} from '../../shared/model/search/compnay.search';

@Injectable({
    providedIn: 'root'
})
export class CompanySettingServiceV2 extends AbstractServiceV2<CompanySettingV2, CompanySearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY_SETTING, '');
        // this.baseUrlV2 = 'http://localhost:8003/api/v2/company-settings';
    }

    getCompanySettingByCompanyId(companyId: any): Observable<any> {
        // return this._http.get<any>(`http://localhost:8003/api/v2/company-settings/company/${companyId}`).pipe(map(resp => resp));
        return this._http.get<any>(`${this.baseUrlV2}/company/${companyId}`).pipe(map(resp => resp));
    }

    updateCompanySetting(companyId: any, id: any, entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/${id}/company/${companyId}`;
        // const url = `http://localhost:8003/api/v2/company-settings/${id}/company/${companyId}`;
        return this._http.put<any>(url, entity).pipe(map(resp => resp));
    }

    saveHistoryData(data) {
        return this._http.post(`${this.baseUrlV2}/history`, data).pipe(map(resp => resp));
    }

    updateSmsAvailable(data: any) {
        // return this._http.post(`http://localhost:8003/api/v2/company-settings/update-sms-available`, data).pipe(map(resp => resp));
        return this._http.post(`${this.baseUrlV2}/update-sms-available`, data).pipe(map(resp => resp));
    }

    updateCreditSetting(id: any, entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/credit-setting/${id}`;
        return this._http.put<any>(url, entity).pipe(map(resp => resp));
    }

    updateCreditSettingAll(entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/credit-setting-all`;
        return this._http.put<any>(url, entity).pipe(map(resp => resp));
    }

    getMyCurrentIpAddress(): Observable<any> {
        // return this._http.get<any>(`http://localhost:8003/api/v2/company-settings/get-my-current-ip-address`).pipe(map(resp => resp));
        return this._http.get<any>(`${this.baseUrlV2}/get-my-current-ip-address`).pipe(map(resp => resp));
    }
}
