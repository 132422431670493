import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Table} from 'primeng/table';
import {FormValidatorService} from '../../../shared/service/form-validator/form-validator.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {PayrollHolidayService} from '../../service/payroll-holiday.service';
import {PayrollHoliday} from '../../model/payroll-holiday.model';
import {EmployeeService} from '../../../employee/service/employee.service';
import {Employee} from '../../../employee/model/employee.model';
import {DepartmentService} from '../../../department/service/department.service';
import {Department} from '../../../department/model/department.model';
import {PayrollSettingService} from '../../service/payroll-setting.service';
import {PayrollSetting} from '../../model/payroll-setting.model';
import {AgencyHolidayService} from '../../../agency/service';
import {AgencyHoliday} from '../../../agency/model';
import {PositionService} from '../../../position/service/position.service';
import {Position} from '../../../position/model/position.model';
@Component({
    selector: 'app-payroll-holiday-form',
    templateUrl: './payroll-holiday-form.component.html',
    styleUrls: ['./payroll-holiday-form.component.css'],
    providers: [PayrollHolidayService, FormValidatorService, AuthService,
        EmployeeService, DepartmentService, PayrollSettingService, PositionService]
})
export class PayrollHolidayFormComponent implements OnInit {
    @Input() mode: String;
    @Input() readOnly: Boolean = false;
    @Input() companyId: number;
    @Input() payrollHolidayId: number;
    @Input() companyDataForm: ElementRef;
    @ViewChild('fdt', {static: true}) table: Table;
    msgs = [];
    @Input() payrollSetting: PayrollSetting = new PayrollSetting();
    companyForm: UntypedFormGroup;
    payrollHolidays: PayrollHoliday[] = [];
    holidays: AgencyHoliday[] = [];
    holiday: AgencyHoliday;
    employees: Employee[] = [];
    departments: Department[] = [];
    userPermission: boolean;
    operationDays: Array<any> = [];
    statusTypes: Array<any> = [];
    payrollHoliday: PayrollHoliday;
    agencyHolidays: AgencyHoliday[];
    positions: Position[];
    returnUrl: string;
    constructor(
        private payrollHolidayService: PayrollHolidayService,
        private employeeService: EmployeeService,
        private positionService: PositionService,
        private departmentService: DepartmentService,
        private payrollSettingService: PayrollSettingService,
        private agencyHolidayService: AgencyHolidayService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        console.log(this.payrollHolidayId);
        if (!this.payrollHolidayId) {
            this.payrollHolidays.push(new PayrollHoliday());
            this.employeeService.getAllByAgency(this.companyId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employees = resObj.data;
                }
            });
        }

        this.holidays = [];

        this.positionService.findAllForSelection({}).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.positions = resObj.data;
                console.log(this.positions);
            }
        });

        this.agencyHolidayService.readAllForDropDownList({companyId: this.companyId}).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.holidays = resObj.data;
            }
        });

        this.departmentService.getAllByAgency(this.companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.departments = resObj.data;
            }
        });

        if (this.payrollHolidayId) {
            this.payrollHolidayService.getById(this.payrollHolidayId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.payrollHoliday = resObj.data;
                    this.holiday = this.holidays.find(holiday => this.payrollHoliday.holidayId === holiday.id);
                    // this.departmentService.get(this.payrollHoliday.departmentId).subscribe(res1 => {
                    //     const resObj1: any = res1;
                    //     if (resObj1.status === 'SUCCESS') {
                    //         this.payrollHoliday.department = resObj1.data;
                    //     }
                    // });

                    console.log(this.payrollHoliday);
                    this.payrollHolidays.push(this.payrollHoliday);
                    console.log(this.holiday);
                }
            });
        }
        this.route.queryParams.subscribe((params) => {
            if(params.returnUrl !== undefined) {
                this.returnUrl = params.returnUrl;
            } else {
                this.returnUrl = "/app/agency/list";
            }
        });
    }
    changeHoliday(event) {
        // tslint:disable-next-line: no-console
        console.info(event);
    }
    changeDepartment(event) {
        // tslint:disable-next-line: no-console
        console.info(event);
    }
    addRowPayrollHoliday() {
        this.payrollHolidays.push(new PayrollHoliday());
    }

    updateCompany() {


    }

    getCompany() {

    }

    updatePayrollHoliday(){
        this.payrollHolidays.forEach(payrollHoliday => {
            payrollHoliday.payrollSetting = this.payrollSetting;
            payrollHoliday.holidayId = this.holiday.id;
            // payrollHoliday.departmentId = payrollHoliday.department.id;
            // payrollHoliday.position = payrollHoliday.position;
        });
        this.payrollHolidayService.updateHoliday(this.payrollHolidays[0]).subscribe(res1 => {
            const resObj1: any = res1;
            if (resObj1.status === 'SUCCESS') {
                this.msgs.push({severity: 'info', summary: 'created', detail: 'Payroll Holiday updated successfully!'});
                setTimeout(() => this.router.navigate(['app/agency/payroll', this.companyId,'view']), 2000);
            }
        });
    }

    addPayrollHoliday() {
        this.payrollSettingService.getPayrollSetting(this.companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.payrollSetting = resObj.data;
                this.payrollHolidays.forEach(payrollHoliday => {
                    payrollHoliday.payrollSetting = this.payrollSetting;
                    payrollHoliday.holidayId = this.holiday.id;
                    // payrollHoliday.departmentId = payrollHoliday.department.id;
                    // payrollHoliday.position = payrollHoliday.position;
                });
                this.payrollHolidayService.createAll(this.payrollHolidays).subscribe(res1 => {
                    const resObj1: any = res1;
                    if (resObj1.status === 'SUCCESS') {
                        this.msgs.push({severity: 'info', summary: 'created', detail: 'Payroll Holiday added successfully!'});
                        setTimeout(() => this.router.navigate(['app/agency/payroll', this.companyId, 'view']), 2000);
                    }
                });
            }


        });

    }
    goBack() {
        this.router.navigateByUrl(this.returnUrl);
    }
}
