<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow mb-0 p-4">
	<h1 class="m-0 ml-2 my-4">{{'Holiday Pay' | translate}}</h1>
	<p-panel [toggleable]="true" id="table-ppanel"> 
		<p-header *ngIf="mode === 'create'" class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Configure Holiday Pay' | translate}}</span>
		</p-header>
		<p-header *ngIf="mode !== 'create'" class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Edit Holiday Pay' | translate}}</span>
		</p-header>
		<p-table [value]="payrollHolidays"> 
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between">
					<div class="input-group w-100" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button">
								<i class="fa-solid fa-asterisk text-danger"></i>
							</button>
						</div>
						<p-dropdown [options]="holidays" optionLabel="holidayName" placeholder="{{'Select Holiday' | translate}}" dataKey="id" [(ngModel)]="holiday" (onChange)="changeHoliday($event)">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div> 
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th>
						<span class="d-flex justify-content-between align-items-center w-100">
							<span class="one-liner" pTooltip="{{'Position' | translate}}" tooltipPosition="left">{{'Position' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex justify-content-between align-items-center w-100">
							<span class="one-liner" pTooltip="{{'Department' | translate}}" tooltipPosition="left">{{'Department' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex justify-content-between align-items-center w-100">
							<span class="one-liner" pTooltip="{{'Rate' | translate}}" tooltipPosition="left">{{'Rate (1.5x)' | translate}}</span>
						</span>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td class="p-0">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label text-nowrap">{{'Position' | translate}}: </span>
							<p-multiSelect [options]="positions" [(ngModel)]="rowData.positions" [ngModelOptions]="{standalone: true}" (onChange)="changePositions($event.value)"  id="selected_label" [style]="{'width':'100%'}" class="w-100">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
								</ng-template>
							</p-multiSelect>
						</span>
					</td>
					<td class="p-0">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label text-nowrap">{{'Department' | translate}}: </span>
							<p-multiSelect [options]="departments" [(ngModel)]="rowData.departments" [ngModelOptions]="{standalone: true}" (onChange)="changeDepartments($event.value)"  id="department_selected_label" [style]="{'width':'100%'}" class="w-100">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
								</ng-template>
							</p-multiSelect>
						</span>
					</td>
					<td class="p-0">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label text-nowrap">{{'Rate (1.5x)' | translate}}: </span>
							<input type="text" pInputText [(ngModel)]="rowData.holidayMultipler" (keypress)="isNumberKey($event)">
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
		<p-footer class="d-flex justify-content-center">
			<button class="btn btn-white" (click)="addRowPayrollHoliday()"><i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}</button>
		</p-footer>
	</p-panel>
	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button type="button" class="btn btn-danger mx-1" (click)="goBack()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button type="submit" class="btn btn-primary mx-1" (click)="updatePayrollHoliday()" *ngIf="mode == 'edit'"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
			<button type="submit" class="btn btn-primary mx-1" (click)="addPayrollHoliday()" *ngIf="mode == 'create'"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</div>
	</div>
</div>