<div class="ui-g pb-4">
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Field' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-mouse-pointer"></i>
				</button>
			</div>
			<p-dropdown [filter]="true" [showClear]="true" [options]="fieldNames" placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedField" (onChange)="selectField($event)">
				<ng-template pTemplate="item" let-item>
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
				<ng-template let-item pTemplate="selectedItem">
					<span class="one-liner">{{item.label | translate}}</span>
				</ng-template>
			</p-dropdown>
		</div>
	</div>
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Date Range' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-calendar-week"></i>
				</button>
			</div>
			<mat-form-field class="w-100">
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate matInput placeholder="Start date" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
					<input matEndDate matInput placeholder="End date" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
				</mat-date-range-input>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
			<div class="input-group-append pl-3">
				<button class="btn btn-white" (click)="reset()" pTooltip="{{'Reset' | translate}}"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
				<button class="btn btn-white" (click)="filterClientHistory()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
			</div>
		</div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{historyFor?.label}} <span *ngIf="(dataSource$|async)?.totalElements">({{companyHistory.length}})</span></span>
	</p-header>
	<p-table #table [value]="companyHistory" [rows]="(dataSource$|async)?.size" [paginator]="false" [totalRecords]="(dataSource$|async)?.totalElements" [lazy]="true" [responsive]="true" [loading]="loading">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
					<th *ngSwitchDefault>
						<span class="d-flex align-items-center justify-content-between">
							<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); sortField = col.field; sortType = col.sort ; loadSortOption(col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</span>
					</th>
				</ng-container>
			</tr>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of cols" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData">
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Updated' | translate}}: </span>
						<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}">{{rowData.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
						<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Username' | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.userName}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedField !== 'type'">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Old Value' | translate}}: </span>
						<span *ngIf="rowData.oldValue && rowData.fieldName != 'planId'" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
						<span *ngIf="rowData.oldValue && rowData.fieldName == 'planId'" pTooltip="{{getPlanName(rowData.oldValue)}}">{{getPlanName(rowData.oldValue)}}</span>
						<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedField !== 'type'">
					<span class="one-liner">
						<span class="mobile-table-label">{{'New Value' | translate}}: </span>
						<span *ngIf="rowData.newValue && rowData.fieldName != 'planId'" pTooltip="{{rowData.newValue}}">{{rowData.newValue}}</span>
						<span *ngIf="rowData.newValue && rowData.fieldName == 'planId'" pTooltip="{{getPlanName(rowData.newValue)}}">{{getPlanName(rowData.newValue)}}</span>
						<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedField === 'type'">
					<span class="one-liner">
					  <span class="mobile-table-label">{{ getLabelForValue('type', 'Old Value') | translate }}: </span>
					  <span *ngIf="rowData.oldValue" pTooltip="{{ getLabelForValue('type', rowData.oldValue) }}">{{ getLabelForValue('type', rowData.oldValue) }}</span>
					  <span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="selectedField === 'type'">
					<span class="one-liner">
					  <span class="mobile-table-label">{{ getLabelForValue('type', 'New Value') | translate }}: </span>
					  <span *ngIf="rowData.newValue" pTooltip="{{ getLabelForValue('type', rowData.newValue) }}">{{ getLabelForValue('type', rowData.newValue) }}</span>
					  <span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				  
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Action' | translate}}: </span>
						<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
						<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
			</tr>
			<p-overlayPanel #op1 appendTo="body" class="p-0">
				<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption, op1)">
					<ng-template let-col pTemplate="item">
						<div class="d-flex align-items-center">
							<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
							<span>{{col.name | translate}}</span>
						</div>
					</ng-template>
				</p-listbox>
			</p-overlayPanel>
			<p-overlayPanel #op2 appendTo="body">
				<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption, op2)">
					<ng-template let-col pTemplate="item">
						<div class="d-flex align-items-center">
							<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
							<span>{{col.name | translate}}</span>
						</div>
					</ng-template>
				</p-listbox>
			</p-overlayPanel>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr>
				<td [attr.colspan]="5" class="text-center py-5">
					<span class="text-muted">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>
