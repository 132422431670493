import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaceRecognitionService {
  baseUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.face_recognition_url}`;
  }

  recognize(blob: Blob): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/api/recognize?system=${environment.face_recognition_system}`, blob)
      .pipe(map((res) => res));
  }

  register(payload): Observable<any> {
    return this.http
      .post(
        `${this.baseUrl}/api/register?system=${environment.face_recognition_system}`,
        payload
      )
      .pipe(map((res) => res));
  }

  detect(blob: Blob): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/api/detection?system=${environment.face_recognition_system}`, blob)
      .pipe(map((res) => res));
  }

  detectionByEmbedding(payload): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/api/detection/embedding?system=${environment.face_recognition_system}`, payload)
      .pipe(map((res) => res));
  }

  listFace(payload): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/api/list-face?system=${environment.face_recognition_system}`, payload)
      .pipe(map((res) => res));
  }

  deleteFace(humanId): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}/api/delete-face?system=${environment.face_recognition_system}&human_id=${humanId}`)
      .pipe(map((res) => res));
  }
}
