import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {PrimeNgModule} from '../prime-ng/prime-ng.module';
import {SharedModule} from '../shared/shared.module';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './component/sign-up.component';
import { SignUpService } from './service/sign-up.service';
import { PaymentModule } from 'app/payment/payment.module';
import { CompanyModule } from 'app/company/company.module';

@NgModule({
    imports: [
        CommonModule,
        PrimeNgModule,
        SharedModule,
        ProgressSpinnerModule,
        SignUpRoutingModule,
        PaymentModule,
        CompanyModule
    ],
    declarations: [
        SignUpComponent,
    ],
    providers: [
        SignUpService
    ]
})
export class SignUpModule { }
