import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { AgencyIpAddress } from '../model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({ providedIn: 'root' })
export class IpAddressService extends AbstractService<AgencyIpAddress> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.AGENCY_IP_ADDRESS, '');
    }

    findAllByAgencyId(params: any): Observable<AgencyIpAddress[]> {
        return this._http.get<any>(`${this.baseUrl}` + params).pipe(map(resp => resp));
    }

    deleteIpAddress(id: any): Observable<any> {
        return this.http.delete(`${this.baseUrl}${id}`, { params: id }).pipe(map((resp) => resp));
    }

    saveIpAddress(entity: any): Observable<any> {
        return this._http.post<any>(this.baseUrl, entity).pipe(map(res => res));
    }

    updateIpAddress(entity: any): Observable<any> {
        return this._http.put<any>(this.baseUrl, entity).pipe(map(res => res));
    }
}
