import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './component/layout/layout.component';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { AuthGuard } from '../authenticate/auth.guard';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import { AppEmailComponent } from './component/app-email/app-email.component';
import { NoteLayoutComponent } from '../note/component/note-layout/note-layout.component'
import { FaqLayoutComponent } from 'app/faq/component/faq-layout/faq-layout.component';
import { EmailListComponent } from './component/app-email-list/app-email-list.component';
import { EmailLayoutComponent } from './component/app-email-layout/email-layout.component';

export const LayoutRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'app/demo-dashboard',
                component: AppDashboardComponent
            },
            {
                path: 'app/dashboard',
                component: DashboardComponent
            },
            {
                path: 'app/note',
                component: NoteLayoutComponent, 
                canActivate: [AuthGuard]
            },
            {
                path: 'app/faq',
                component: FaqLayoutComponent, 
                canActivate: [AuthGuard]
            }
        ]
    }, {
        path: 'app/email',
        component: EmailLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AppEmailComponent
            },
            {
                path: 'list',
                component: EmailListComponent
            },
        ]
    }
];

export const LayoutRoutingModule = RouterModule.forChild(LayoutRoutes);
