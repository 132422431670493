import { Component, OnInit } from '@angular/core';

import { Message, MessageService } from 'primeng/api';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { LazyLoadEvent } from 'primeng';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import {Employee} from '../../../employee/model/employee.model';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-tracking-card',
  templateUrl: './tracking-card.component.html',
  styleUrls: ['./tracking-card.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }, ]
})
export class TrackingCardComponent implements OnInit {
  companies: any[];
  filterDate: Date[] = [];
  selectedCompany: any;
  action: any;
  isSuperAdmin: boolean = this.authService.isSuper() || this.authService.isSubSuper();
  loading = false;
  actions: any[] = [
    { label: 'Moved to another Board', value: 'MOVED' },
    { label: 'Deleted', value: 'DELETED' }
  ];
  
  cols = [
    { label: '#', field: '#' },
    { label: 'Board', field: 'boardName', sortOptions: '', sort: 'text' },
    { label: 'Creator', field: 'createdByUsr', sortOptions: '', sort: 'text' },
    { label: 'Created', field: 'createdAt', sortOptions: '', sort: 'number' },
    { label: 'Card URL', field: 'ticketLink', sortOptions: '', sort: 'text' },
    { label: 'Action', field: 'action', sortOptions: '', sort: 'text' },
  ];
  textSortOptions = [
    {name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z'},
    {name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a'}
];
numberSortOptions = [
    {name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9'},
    {name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1'}
];
dateSortOptions = [
    {name: 'Sort 1 To 12', value: 'DESC', img: 'arrow-down-1-9'},
    {name: 'Sort 12 To 1', value: 'ASC', img: 'arrow-up-9-1'}
];
  selectedSortOption: any;
  tickets: any[];
  allTickets: any[];
  textSearchStr: string;
  totalRecords: number;
  allProject: any;
  boardSelected: any;
  sortField: string;
  selectedField: string;
  first = 0;

  constructor(private authService: AuthService, private dropdownsService: DropDownsService,
    private taskManagementService: TaskManagementService, private messageService: MessageService,   private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.loadAllCompanyList();
    this.getAllProject();
    this.search();
  }

  loadTicketsLazy(event: LazyLoadEvent) {
    const page = (event.first) / event.rows;
    const option = {
      page: page, size: event.rows
    };
    this.loadPage(option);
  }

  search(event?: any) {
    const options = {
      page: 0,
      size: 9999
    };
    this.loadPage(options);

  }

  loadPage(options: any) {
    this.loading = true;
    this.tickets = [];
    if (this.selectedCompany) { options.companyId = this.selectedCompany; }
    if (!this.isSuperAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    if (this.action) { options.taskAction = this.action; }
    if (this.boardSelected) { options.boardId = this.boardSelected; }
    if (this.filterDate[0] && this.filterDate[1]) {
      options.startDate = _moment(this.filterDate[0]);
      options.endDate = _moment(this.filterDate[1]);
    }

    if (this.action) {
        options.taskAction = this.action; // Ensure this matches the backend's filter criteria
      }
      
    this.taskManagementService.getTaskHistory(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        this.allTickets = resObj.data.content;
        this.allTickets.forEach(t => {
          t.ticketLink = this.getTicketLink(t);
          t.taskAction = this.displayActions(t.taskAction);
        });
        this.searchTickets();
      } else {
        this.allTickets = [];
        this.loading = false;
         this.messageService.add({ severity: 'error', summary: 'Error', detail: `Error encountered while loading Task History data, try again later.` });
      }
    }, err => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Error encountered while getting Task History data from back-end service, try again later.` });
      });
  }

  reset() {
    this.action = null;
    this.filterDate =  [];
    this.selectedCompany = null;
    this.boardSelected = null;
    this.textSearchStr = null;
    this.first = 0;
    this.search();
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  getAllProject() {
    this.taskManagementService.getAllProject().subscribe((res: any) => {
      this.allProject = res.data.content.map((board: any) => this.mapToDropdown(board.name, board.id));
    }, err =>  console.log(err));
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  getTicketLink(task) {
    let slugName = task.name.replace(/ /g, '-');
    slugName = slugName.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    let slug = `${task.internalId}-${slugName}`;
    slug = slug.replace(/--+/g, '-');
    const enc = this.taskManagementService.encodeId(task.boardId).replace(/=/g, '');
    return `${window.location.origin}/#/app/tm/${enc}?t=${slug}`;
}

  sortingClick(selectedSortOption) {
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.tickets
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
 } else if (value1 == null && value2 == null) {
            result = 0;
 } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
 } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
 }
          return (event.order * result);
        });
      }
      this.sortField = '';
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  displayActions(val) {
    if (val === 'MOVED') {
      return 'Moved to another Board';
    } else if (val === 'DELETED') {
      return 'Deleted';
    } else {
      return 'Unknown Action'; // Fallback for unrecognized action values
    }
  }
  



  searchTickets() {
    this.first = 0;
    if (!this.textSearchStr || this.textSearchStr === '') {
      this.tickets = [...this.allTickets];
      this.totalRecords = this.tickets.length;
      return;
    }
    this.filterTickets();
  }

  filterTickets() {
    this.tickets = this.allTickets.filter(event => {
      return (this.filterBoardName(event) || this.filterCreatedByUsr(event) || this.filterTicketLink(event)
          || this.filterTaskAction(event) );
    });
    this.totalRecords = this.tickets.length;
  }

  filterBoardName(event) {
    return event.boardName != null && !(event.boardName.search(RegExp(this.textSearchStr, 'i')) === -1);
  }
  filterCreatedByUsr(event) {
    return event.createdByUsr != null && !(event.createdByUsr.search(RegExp(this.textSearchStr, 'i')) === -1);
  }
  filterTicketLink(event) {
    return event.ticketLink != null && !(event.ticketLink.search(RegExp(this.textSearchStr, 'i')) === -1);
  }
  filterTaskAction(event) {
    return event.taskAction != null && !(event.taskAction.search(RegExp(this.textSearchStr, 'i')) === -1);
  }


  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.showSuccess();
    }).catch(err => {
		this.messageService.add({severity:'error', summary: 'Copy Error', detail: 'An unknown error has occurred.'});
    });
  }

  showSuccess() {
    this.messageService.add({severity:'success', summary: 'Success', detail: 'Text Copied!'});
  }



}
