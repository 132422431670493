import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AbstractService} from "../../shared/service/abstract.service";
import {AuthService} from '../../shared/service/auth/auth.service';
import {Constants} from '../../shared/model/constants';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentTransactionService extends AbstractService<any>{
    reqHeaders:HttpHeaders;
    
    constructor(private http: HttpClient, private authService: AuthService) {
        super(http, Constants.ROUTES.PAYMENT_TRANSCTION, '');
    }

    search(options?: any) {
        return this._http.post(`${this.baseUrlV2}search`, options).pipe(map(res => res));
    }

    getTotalAmountPaid(options?: any) {
        return this._http.post(`${this.baseUrlV2}totalPaid`, options).pipe(map(res => res));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

    exportPaymentMethodPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPaymentMethodPdf`, data).pipe(map(resp => resp));
    }

    getAllTransactionId(companyId: number) {
        return this._http.get(`${this.baseUrlV2}all-transaction-id?companyId=${companyId}`).pipe(map(res => res));
    }
    
    acquireLock(key: string, timeout: number) {
        return this._http.get(`${this.baseUrlV2}acquireLock?key=${key}&timeout=${timeout}`).pipe(map(res => res));
    }

    releaseLock(key: string) {
        return this._http.delete(`${this.baseUrlV2}releaseLock`).pipe(map(res => res));
    }
}
