

export class FollowUpBillingInvoice {
    id: number;
    methodContact: string;
    collectionRepresentative: string;
    note: string;
    invoiceId: number;
    createdByUsr: string;
    createdAt: any;
    invoiceNumber: any;
    clientName: any;
    companyId: any;
    lastModifiedBy: any;
    collectionType: string;
    invoiceDueDate: Date;
    type: any;
}
