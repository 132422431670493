import { Injectable } from '@angular/core';
import {AbstractService} from './abstract.service';
import {Constants} from '../model/constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.USER_CONFIG, '');
  }

  getByUserIdAndPage(options: any): Observable<any> {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/user-config/get-by-user-id`, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}get-by-user-id`, options).pipe(map(res => res));
  }

  save(options: any): Observable<any> {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/user-config/create`, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}create`, options).pipe(map(res => res));
  }
}
