import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '../model/page.model';
import { environment, environmentLocal } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export abstract class AbstractService<T> {

  protected baseUrl: string;
  protected baseUrlV2: string;
  protected sipUrl: string;
  protected moduleRouteG: String;
  protected sendUrl: string;
  protected paymentUrl: string;
  protected operrtellUrl: string;

  constructor(protected _http: HttpClient, protected moduleRoute: String, protected uri: String) {
    this.moduleRouteG = moduleRoute;
    this.baseUrl = `${environment.server_ip}/${moduleRoute}/${uri}`;
    this.baseUrlV2 = `${environment.v2_server_ip}/${moduleRoute}/${uri}`;
    this.operrtellUrl = `${environment.operrtell_server_ip}/${moduleRoute}/${uri}`;
    this.sipUrl = environmentLocal.api;
    this.sendUrl = `${environment.v2_server_ip}`;
    this.paymentUrl = `${environment.payment_api_url}`;
  }

  setBaseUrl(url: string) {
    this.baseUrl = `${url}/${this.moduleRouteG}/`;
  }

  getAll(): Observable<T[]> {
    return this._http.get<T[]>(this.baseUrl);
  }

  getPage(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
      return this._http.post<Page<T>>(this.baseUrlV2 + 'search', options).pipe(map(res => res));
      // return this._http.post<Page<T>>('http://localhost:8010/api/v2/crm/client/search', options).pipe(map(res => res));
              // return this._http.get<any>(`http://localhost:8010/api/v2/crm/client/dropdown`, { params: params }).pipe(map(resp => resp));

  }

  getAllPage(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(this.baseUrl + 'all', { params: params }).pipe(map(res => res));
  }

  getPageByFilter(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(this.baseUrlV2 + 'filter', { params: params }).pipe(map(res => res));
  }

  getPageAll(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(this.baseUrl + 'all', { params: params }).pipe(map(res => res));
  }

  fiterContactHistory(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(`${environment.server_ip}/contact/history/filter`, { params: params }).pipe(map(res => res));
  }

  getContactHistory(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(`${environment.server_ip}/contact/history/filter`, { params: params }).pipe(map(res => res));
  }

  getProductsList(options?: any) {
    const params = this.createParams(options);
    return this._http.post(`${this.baseUrlV2}search`, params).pipe(map(res => res));
  }

  getClientHistoryPage(options?: any, params?: any): Observable<Page<T>> {
    if (params) {
      return this._http.post<Page<T>>(`${this.baseUrlV2}history/search`, options, { params: params }).pipe(map(res => res));
    } else {
      return this._http.post<Page<T>>(`${this.baseUrlV2}history/search`, options).pipe(map(res => res));
    }
  }

  getAdminPaymentDetails(options?: any) {
    console.log(options)
    const params = this.createParams(options);
    return this._http.get(`${this.baseUrl}checkPayment`, { params: params }).pipe(map(res => res));
  }

  get(id: Number): Observable<T> {
    return this._http.get<T>(`${this.baseUrlV2}${id}`)
      .pipe(map(res => res));
  }

  create(entity: T): Observable<T> {
    return this._http.post<T>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  createCallout(entity: T): Observable<T> {
    return this._http.post<T>(`${this.baseUrlV2}/absence`, entity).pipe(map(res => res));
  }

  update(entity: T, id: Number): Observable<T> {

    return this._http.put<T>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }

  updateEntity(entity: T): Observable<T> {
    return this._http.put<T>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }

  delete(id: Number) {
    return this._http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
  }

  deleteByIds(toDeleteIds?: any): Observable<Page<T>> {
    const toSendData = {
      ids: toDeleteIds
    };
    return this._http.post<Page<T>>(`${this.baseUrlV2}` + 'delete', toSendData).pipe(map(res => res));
  }

  createParams(options: any): HttpParams {
    let params = new HttpParams();
    if (options) {
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          params = params.set(key, options[key]);
        }
      }
    }
    return params;
  }

  getAllCompanyDropdown() {
    return this._http.get(this.baseUrlV2 + 'dropdown').pipe(map(res => res))
  }

  createClient(entity: any): Observable<any> {
    if(this.sendUrl === 'https://prod2-api.operrwork.com') entity.frontEndUrl = 'https://login.operrwork.com';
    //return this._http.post<any>('http://localhost:8010/api/v2/crm/client', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  saveClient(entity: any, id: number): Observable<any> {
    if (id == null) {
      return this.createClient(entity);
    } else {
      entity.id = id;
      return this.updateEntity(entity);
    }
  }

  checkServerUrl(payload){
    if(this.sendUrl === 'https://dev2-api.operrwork.com') payload.url = 'https://dev2.operrwork.com';
    if(this.sendUrl === 'https://qa2-api.operrwork.com') payload.url = 'https://qa2.operrwork.com';
    if(this.sendUrl === 'https://prod2-api.operrwork.com') payload.url = 'https://login.operrwork.com';
    if(this.sendUrl === 'https://loginbackup-api.operrwork.com') payload.url = 'https://login.operrwork.com';
  }


}
