import {Injectable} from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {Page} from '../../shared/model/page.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CompanySettingHistory} from '../model/company-setting-history.model';


@Injectable({ providedIn: 'root' })
export class CompanyOtherSettingHistoryService extends AbstractService<CompanySettingHistory> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.COMPANY_OTHER_SETTING_HISTORY, '');
  }

  saveHistoryData(data) {

    return this._http.post(`${this.baseUrlV2}`, data).pipe(map(resp => resp));
  }
  getClientHistoryPage(options?: any): Observable<Page<CompanySettingHistory>> {
    const params = this.createParams(options);
    console.log(this.baseUrlV2);
    return this._http.post<Page<CompanySettingHistory>>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }


}
