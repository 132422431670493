import {Injectable} from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { Page } from 'app/shared/model/page.model';

@Injectable({ providedIn: 'root' })
export class LocationManagementService extends AbstractService<any> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.LOCATION_MANAGEMENT, '');
  }

  getListPage(options?: any) {
    // return this._http.post(`http://localhost:8007/api/v2/operation/location-management/search`, options).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  getCreatedBy(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/location-management/getCreatedBy`, { params }).pipe(map(resp => resp));
    return this._http.get<any>(`${this.baseUrlV2}getCreatedBy`, { params }).pipe(map(resp => resp));
  }

  exportPdf(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  getLocationManagementById(id: Number): Observable<any> {
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/location-management/${id}`).pipe(map(res => res));
    return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(res => res));
  }

  createLocationManagement(entity: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/location-management`, entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  updateLocationManagement(entity: any, id: Number): Observable<any> {
    // return this._http.put<any>(`http://localhost:8007/api/v2/operation/location-management`, entity).pipe(map(res => res));
    return this._http.put<any>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }

  getLocationMenu(entity: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/location-management/get-location-menu`, entity).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}get-location-menu`, entity).pipe(map(res => res));
  }
  
  getLocationMenuList(entity: any): Observable<any> {
    //return this._http.post<any>(`http://localhost:8007/api/v2/operation/location-management/get-location-menu-list`, entity).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}get-location-menu-list`, entity).pipe(map(res => res));
  }

  getHistory(params: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8007/api/v2/operation/location-management/history`, params).pipe(map(resp => resp));
    return this._http.post<Page<any>>(`${this.baseUrlV2}history`, params).pipe(map(resp => resp));
  }

  findByCompany(entity: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/location-management/find-by-company`, entity).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}find-by-company`, entity).pipe(map(res => res));
  }

  exportRoomPdf(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}room-details/pdf`, data).pipe(map(resp => resp));
  }

  exportUserPdf(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}user-details/pdf`, data).pipe(map(resp => resp));
  }
}
