import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { PositionSearch } from "../../../shared/model/search/position.search";
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResponse } from 'app/shared/model/list.response';
import { Position } from '../../model/position.model';

@Injectable({
  providedIn: 'root'
})
export class PositionV2Service extends AbstractServiceV2<Position, PositionSearch>{ 

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR, '');
  }

  getDropdown(searchParams:PositionSearch):Observable<ListResponse<Position>>
  {
      let params = this.createParams(searchParams);
      return this._http.get<ListResponse<Position>>(this.baseUrlV2 + '/' + Constants.ROUTES.POSITION + "/dropdown", { params: params }).pipe(map(resp => resp));
  }
}