import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { Membership } from '../model/membership.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { AuthService } from '../../shared/service/auth/auth.service';
import { Response } from '../../shared/model/response';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MembershipService extends AbstractService<Membership> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.MEMBERSHIP, '');
  }

  filterByCompanyAndAgency(params: any): Observable<Page<Membership>> {
    return this._http.get<Page<Membership>>(this.baseUrl + Constants.URI.MEMBERSHIP.FIND_WITH_FILTERS, { params: params }).pipe(map(res => res));
  }

  getAllByAgency(agencyId: number): Observable<Membership[]> {
    return this._http.get<Membership[]>(`${this.baseUrl}byagency/${agencyId}`).pipe(map(res => res));
  }

  saveMembership(membership) {
    const reqUrl = `${this.baseUrl}`;
    return this.http.post(reqUrl, membership);
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrl}history`, data).pipe(map(res => res));
  }

  updateBadgePrintCount(id) {
    return this.http.get(`${this.baseUrl}${Constants.URI.MEMBERSHIP.UPDATEBADGEPRINT}/${id}`);
  }

  saveMultipleMembership(data: Membership[]): Observable<Response<Membership>> {
    return this.http.post<Response<Membership>>(`${this.baseUrl}${Constants.URI.MEMBERSHIP.SAVEMULTIPLEROLWS}`, data).pipe(map(res => res));
  }
  generateMembershipListPdf(agencyId: number, members: any[]): Observable<any> {
    // return this.http.get(`${this.baseUrl}${}`);
    if (agencyId) {
      return this.http.post<any>(`${environment.server_ip}/${Constants.URI.MEMBERSHIP.PDF_GENERATE}/${agencyId}`, members).pipe(map(res => res));
    } else {
      return this.http.post<any>(`${environment.server_ip}/${Constants.URI.MEMBERSHIP.PDF_GENERATE}/${-1}`, members).pipe(map(res => res));
    }
  }

  createSignUp(entity: any): Observable<any> {
    const url = environment.v2_server_ip + '/membership/api/v2/guest';
    return this._http.post<any>(url, entity).pipe(map(res => res));
  }

  updateSignUp(entity: any): Observable<any> {
    const url = environment.v2_server_ip + '/membership/api/v2/guest/' + entity.id;
    return this._http.put<any>(url, entity).pipe(map(res => res));
  }

  search(options: any): Observable<any> {
    const url = environment.v2_server_ip + '/membership/api/v2/guest/search';
    return this._http.post<any>(url, options).pipe(map(resp => resp));
  }

  exportPdf(options: any): Observable<any> {
    const url = environment.v2_server_ip + '/membership/api/v2/guest/pdf';
    return this._http.post<any>(url, options).pipe(map(resp => resp));
  }

  isAccountNameAvail(accountName: any) {
    const url = environment.v2_server_ip + '/membership/api/v2/guest/isAccountNameAvail';
    const option = { accountName: accountName };
    return this._http.get(url, { params: option });
  }

  isEmailAvail(email: string) {
    const URL = environment.v2_server_ip + '/membership/api/v2/guest/email-exist';
    const option = {email: email};
    return this._http.post(URL, option);
  }

  updateAgreement(id: any) {
    const url  = environment.v2_server_ip + '/membership/api/v2/guest/' + id + '/agreement';
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  updateGuestStatus(id: any, status: number) {
    const url  = environment.v2_server_ip + '/membership/api/v2/guest/' + id + '/' + status;
    return this._http.get<any>(url);
  }

  updateMigratedStatus(id: any) {
    const url  = environment.v2_server_ip + '/membership/api/v2/guest/migrated-guest/' + id;
    return this._http.get<any>(url);
  }

  updateOnlineStatus(id: any, status: number) {
    const url  = environment.v2_server_ip + '/membership/api/v2/guest/' + id + '/online-status/' + status;
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

   unblockUser(data) {
    const url  = environment.v2_server_ip + '/membership/api/v2/guest/'
        return this._http.get(`${url}${data.id}/status/${data.status}`);
      }

}
