import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';
import {DatePipe} from '@angular/common';
import {FreelancerTicketService} from '../../../crm/service/freelancerticket.service';
import {FreelancerTicketHistory} from '../../model/freelancer-ticket-history.model';

@Component({
  selector: 'app-freelancer-ticket-history-task',
  templateUrl: './freelancer-ticket-history.component.html',
  styleUrls: ['./freelancer-ticket-history.component.scss'],
  providers: [FreelancerTicketService]
})
export class FreelancerTicketHistoryComponent implements OnInit {
  @Input() freelancerTicketId: number;
  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  size: number;
  page: number;
  clientHistory: FreelancerTicketHistory[];
  cols: any[] = [
    {field: 'updateDate', label: 'Updated Date'},
    {field: 'userName', label: 'User'},
    {field: 'oldValue', label: 'Old Value'},
    {field: 'newValue', label: 'New Value'},
    {field: 'action', label: 'Action'}
  ];
  
  displayColumns: any[] = [
    {field: 'updateDate', label: 'Updated Date'},
    {field: 'userName', label: 'User'},
    {field: 'oldValue', label: 'Old Value'},
    {field: 'newValue', label: 'New Value'},
    {field: 'action', label: 'Action'}
  ];
  
  first = 0;
  rangeDates;
  maxDate: any;
  fieldNames: any[];
  selectedField = 'Field Name';

  constructor(
    private datePipe: DatePipe,
    private freelancerTicketService: FreelancerTicketService
  ) {
    this.maxDate = new Date();
  }

  ngOnInit() {
    const fieldNames = [
      {label: 'Ticket Link', value: 'ticketLink'},
      {label: 'Estimated Hours', value: 'estimationHrs'},
      {label: 'Details', value: 'details'},
      {label: 'Start Date', value: 'startDate'},
      {label: 'Deadline', value: 'deadline'},
      {label: 'Status', value: 'status'}

    ];
    this.fieldNames = _.sortBy(fieldNames, 'label');
  }

  loadDataSource(event?: any) {
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
  }

  filterClientHistory() {
    const fromDate = this.rangeDates ? new Date(this.rangeDates[0]).getTime() : null;
    const toDate = this.rangeDates ? new Date(this.rangeDates[1]).getTime() : null;
    const options: any = {};
    if (this.freelancerTicketId) {
      options.freelancerTicketId = this.freelancerTicketId;
    }
    if (this.selectedField) {
      options.fieldName = this.selectedField;
    }
    if (fromDate) {
      options.fromDate = fromDate;
    }
    if (toDate) {
      options.toDate = toDate;
    }
    this.freelancerTicketService.getClientHistoryPage(options).subscribe(
      (data: any) => {
        this.clientHistory = data.data.content;
        this.clientHistory.forEach(history => {
          if (history.fieldName === 'startDate' || history.fieldName === 'deadline') {
            history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
            history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
          }
        });
        this.dataSourceSubject.next(data.data);
      }
    );
  }

  reset() {
    this.rangeDates = null;
    this.selectedField = 'Field Name';
    this.filterClientHistory();
  }
}
