import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-edit-column-title',
  templateUrl: './edit-column-title.component.html',
  styleUrls: ['./edit-column-title.component.scss']
})
export class EditColumnTitleComponent implements OnInit {
  @Input() column;
  @Output() columnEvent = new EventEmitter<any>();

  editingTitle = false;
  editTitleText: string;

  @ViewChild('editTitleField') editTitleField: ElementRef;
  @Input() board;
  @Input() allMember;

  constructor(
    private renderer: Renderer2,
    private messageService: MessageService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }


  /**
   * Show text field for creating new column
   *
   * @memberof EditColumnTitleComponent
   */
  enableEditTitle() {
    if(this.isBoardAdmin()){
      this.editingTitle = true;
      this.editTitleText = this.column.name;
      setTimeout(() => {
        this.renderer.selectRootElement(this.editTitleField.nativeElement).focus();
      }, 50);
    }
  }

  /**
   * Update column title
   *
   * @memberof EditColumnTitleComponent
   */
  editTitle() {
    const column = {
      name: this.editTitleText,
      id: this.column.id
    };
    // Update column
    this.columnEvent.emit(column);
    this.clearEditTitle();
  }

  /**
   * Update column on pressing enter key
   *
   * @param {KeyboardEvent} event
   * @memberof EditColumnTitleComponent
   */
  editTitleOnEnter(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      if (this.editTitleText && this.editTitleText.trim() !== '') {
        this.editTitleText = this.editTitleText.replace(/\n$/, '');
        this.editTitle();
      } else {
        this.clearEditTitle();
      }
    } else if (event.keyCode === 27) {
      this.clearEditTitle();
    }
  }

  /**
   * Update column when blured
   *
   * @memberof EditColumnTitleComponent
   */
  editTitleOnBlur() {
    if (this.editTitleText && this.editTitleText.trim() !== '') {
      this.editTitleText = this.editTitleText.replace(/\n$/, '');
      this.editTitle();
    }
    this.clearEditTitle();
  }

  /**
   * Clear column form
   *
   * @memberof EditColumnTitleComponent
   */
  clearEditTitle() {
    this.editingTitle = false;
  }

  isBoardAdmin() {
    if ((this.board && this.board.userId === this.authService.getCurrentLoggedInId() 
    && this.board.createdByUsr === this.authService.getCurrentUsername())
    || this.isBoardSubAdmin()) {
      return true;
    } else {
      return this.messageService.add({ severity: 'error', summary: 'Error', detail: `You don't have permission to update bucket title for this board` });
    }
  }

  isBoardSubAdmin() {
    const isBoardSubAdmin = this.allMember.find(user => 
      (user.id == this.authService.getCurrentLoggedInId() 
      && user.userName == this.authService.getCurrentUsername()))
    if (isBoardSubAdmin && isBoardSubAdmin.role == 'SUB_ADMIN') {
      return true;
    } else return false;
  }
  
}
