import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validator, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {PaymentProfileService} from "../../../../../payment/service/payment-profile.service";
import {AuthService} from "../../../../../shared/service/auth/auth.service";
import {AesUtilService} from "../../../../../shared/service/aes-util.service";

@Component({
  selector: 'app-echeck-card',
  templateUrl: './echeck-card.component.html',
  styleUrls: ['./echeck-card.component.scss']
})
export class EcheckCardComponent implements OnInit {
    
    @Output()
    displayChange = new EventEmitter();
    
    @Input()
    paymentCardType;
    
    registerCustomerPaymentId = null;
    
    cardNumber;
    cardTokenCode;
    cardToken = null;
    
    echeckCardForm: UntypedFormGroup;
    
    roundNumberValid: string = '';
    
    msgs = [];
    
    
    constructor(private fb: UntypedFormBuilder,
                private paymentProfileService: PaymentProfileService,
                private authService: AuthService,
                private aesUtilService: AesUtilService,
                private router: Router) { }
    
    accountTypes = [
        {label: 'Checking', value: 'checking'},
        {label: 'Savings', value: 'savings'},
        {label: 'Business Checking', value: 'businessChecking'}
    ]
    
    ngOnInit() {
        this.getPaymentProfile(this.authService.getUserInfo().userId);
        this.echeckCardForm = this.fb.group({
            profileName: [],
            accountName: [],
            accountType: [],
            roundNumber: [null, [Validators.required]],
            accountNumber: [],
            address1: [],
            address2: [],
            city: [],
            state: [],
            zipcode: [],
            phone: [],
            email: [],
        })
        
        this.echeckCardForm.valueChanges.subscribe(e => {
            if (e) {
            }
        });
    }
    
    cancel(): void {
        this.displayChange.emit(true);
    }
    
    getPaymentProfile(userId) {
        this.paymentProfileService.getPaymentProfile(this.paymentCardType, userId, 'AUTHORIZE_NET').subscribe(response => {
            if (response) {
                this.registerCustomerPaymentId = (response as any).registerPaymentId;
                this.cardNumber = 'XXXX-XXXX-XXX-' + (response as any).lastFourNumbers;
                this.cardTokenCode = (response as any).cardTokenCode;
                this.cardToken = (response as any).cardToken;
            }
        })
    }
    
    addPaymentMethod() {
        const formValues: any = this.echeckCardForm.getRawValue();
        if (!formValues.accountName || !formValues.accountType || !formValues.roundNumber || !formValues.accountNumber) {
            this.msgs.push({ severity: 'warning', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
            return;
        }
        const cardDataStr = `accountName=${formValues.accountName};accountType=${formValues.accountType};roundNumber=${formValues.roundNumber};
    accountNumber=${formValues.accountNumber};address1=${formValues.address1};address2=${formValues.address2};
    city=${formValues.city};state=${formValues.state};zipcode=${formValues.zipcode};phone=${formValues.phone};email=${formValues.email}`;
        
        const encryptData = this.aesUtilService.encrypt(cardDataStr);
        const customer = this.authService.getUserInfo();
        const payload = {
            customerRegisterPaymentId: this.registerCustomerPaymentId,
            paymentCardType: this.paymentCardType,
            ownerId: customer.userId,
            gatewayType: 'AUTHORIZE_NET',
            encryptData: encryptData,
            userName: this.authService.getUserInfo().userName,
            securelyStoreCard: formValues.saveCardDetails,
            profileName: formValues.profileName,
            echeckAccount: true,
        };
        
        this.paymentProfileService.createPaymentProfile(payload).subscribe((response: any) => {
            if (response && response.cardToken) {
                this.msgs.push({ severity: 'success', summary: 'SUCCESS', detail: 'Echeck card created successfully' });
                this.registerCustomerPaymentId = response.registerPaymentId;
                this.cardNumber = 'XXXX-XXXX-XXX-' + response.lastFourNumbers;
                this.cardTokenCode = response.cardTokenCode;
                this.cardToken = response.cardToken;
            } else {
                this.msgs.push({ severity: 'error', summary: 'ERROR', detail: 'Error while creating the Echeck card' });
            }
        })
    }
    
    verify() {
        if (this.echeckCardForm.get('roundNumber').value) {
            this.getRountingNumber(this.echeckCardForm.get('roundNumber').value)
        }
    }
    
    getRountingNumber(rountingNumber) {
        this.paymentProfileService.getBankName(rountingNumber).subscribe((rs: any) => {
            if (rs && rs.code && rs.code === 200) {
                this.roundNumberValid = 'valid';
                this.msgs.push({ severity: 'success', summary: 'SUCCESS', detail: 'You have correct Round Number' });
            } else {
                this.roundNumberValid = 'invalid';
                this.msgs.push({ severity: 'error', summary: 'ERROR', detail: 'You have incorrect Round Number' });
                this.echeckCardForm.get('roundNumber').setValue(null);
            }
        })
    }

}
