import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { ArReportLayoutComponent } from './component/ar-report-layout/ar-report-layout.component';
import { RevenuePaymentComponent } from './component/revenue-payment/revenue-payment.component';
import { ServiceRevenueComponent } from './component/service-revenue/service-revenue.component';
import { GrossIncomeComponent } from './component/gross-income/gross-income.component';
import { ReceivableAgingDetailsComponent } from './component/receivable-aging-details/receivable-aging-details.component';
import { AgingSummaryComponent } from './component/aging-summary/aging-summary.component';


export const ArReportRoutes: Routes = [
  {
    path: 'app/revenue-payment',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/revenue-payment/list', pathMatch: 'full' },
      { path: 'list', component: RevenuePaymentComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: 'app/service-revenue',
    component: ArReportLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'app/service-revenue/list', pathMatch: 'full' },
      { path: 'list', component: ServiceRevenueComponent }
    ]
  },
  {
    path: 'app/gross-income',
    component: ArReportLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'app/gross-income/list', pathMatch: 'full' },
      { path: 'list', component: GrossIncomeComponent }
    ]
  },
  {
    path: 'app/receivable-aging-details',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/receivable-aging-details/list', pathMatch: 'full' },
      { path: 'list', component: ReceivableAgingDetailsComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: 'app/aging-summary',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/aging-summary/list', pathMatch: 'full' },
      { path: 'list', component: AgingSummaryComponent, canActivate: [AuthGuard] },
    ]
  }
];

export const ArReportRoutingModule = RouterModule.forChild(ArReportRoutes);
