<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'Client Self-Pay' | translate}}</h1>
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Client Payments' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Date Range' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client/Base' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="allClientList" optionLabel="label" [filter]="true" filterMatchMode="startsWith" [(ngModel)]="selectedClient" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isClientRole">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'PaymentMethod' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-wallet"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="paymentMethods" [showClear]="true" [(ngModel)]="paymentMethodSelected" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" name="statusSearch" [options]="statusTypes" [filter]="false" [(ngModel)]="selectedStatus">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="loadContentsLazy()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex align-items-center flex-wrap justify-content-between py-4">   
		<h1 class="m-0 ml-2">{{'Browse Payments' | translate}}</h1>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Client Payments' | translate}} ({{totalRecords}})</span>
		</p-header>
		<p-table #dt [columns]="cols" [value]="transactionList" dataKey="id" [lazy]="false" (onLazyLoad)="loadContentsLazy($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="totalRecords" [resizableColumns]="true"
				 [globalFilterFields]="['clientName', 'invoiceNumber', 'amount', 'balance', 'paymentMethod','updatedBy']">

			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
					<div class="d-flex">
						<button type="button" (click)="exportPdfClientSelfPayment()" class="btn btn-primary mx-1">
							<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
						</button>
					</div>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input pInputText (input)="dt.filterGlobal($event?.target?.value, 'contains')" [(ngModel)]="searchText" #searchKeyInput  placeholder="{{'Filter' | translate}}">
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display, 'width': col.width}">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'createdAt'">
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op6.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'updatedAt'">
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op6.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'status'">
								<span class="d-flex justify-content-center align-items-center w-100">
									<i class="fa-solid fa-info text-muted" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event): op4.toggle($event); selectedField = col.field;setSortOptions(col.field, col.sortOptions)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</span>
							</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Added' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{ rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}">{{ rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}: </span>
							<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}">{{rowData.clientName}}</span>
							<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
							<span *ngIf="rowData.invoiceNumber" class="text-primary c-pointer" (click)="clickInvoiceRef($event,rowData);" pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Amount' | translate}}: </span>
							<span *ngIf="rowData.amount" pTooltip="{{rowData.amount | currency : 'USD' : true : '1.2-2'}}">{{rowData.amount | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.amount" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Balance' | translate}}: </span>
							<span *ngIf="rowData.balance" pTooltip="{{rowData.balance | currency : 'USD' : true : '1.2-2'}}">{{rowData.balance | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.balance" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Method' | translate}}: </span>
							<span *ngIf="rowData.paymentMethod" pTooltip="{{rowData.paymentMethod}}">{{rowData.paymentMethod}}</span>
							<span *ngIf="!rowData.paymentMethod" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
							<span *ngIf="rowData.documentUrl" class="d-flex justify-content-center w-100">
								<a href="{{rowData.documentUrl}}"><i class="fa-solid fa-upload text-success m-auto"></i></a>
							</span>
							<span *ngIf="!rowData.documentUrl" class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-upload text-muted m-auto"></i>
							</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Update' | translate}}: </span>
							<span *ngIf="rowData.updatedBy" pTooltip="{{rowData.updatedBy}}">{{rowData.updatedBy}}</span>
							<span *ngIf="!rowData.updatedBy" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Updated' | translate}}: </span>
							<span *ngIf="rowData.updatedAt" pTooltip="{{ rowData.updatedAt | date: 'MM/dd/yyyy hh:mm:ss a' }}">{{ rowData.updatedAt | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
							<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>	
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Status' | translate}}: </span>
							<i *ngIf="rowData.status === 'Approved'" class="fa-solid fa-circle-check text-success" [pTooltip]="rowData.status" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'Rejected' && rowData.reason" class="fa-solid fa-circle-xmark text-danger" [pTooltip]="rowData.status + ' - Reason: ' + rowData.reason" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'Pending'" class="fa-solid fa-circle-info text-primary" [pTooltip]="rowData.status" tooltipPosition="left"></i>	
						</span>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="cols.length" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="cols.length" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>

		</p-table>
	</p-panel>
</div>


<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick()">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick()">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op6 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick()">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick()">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op5 appendTo="body">
	<p-listbox [options]="uploadSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick()">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-confirmDialog header="Confirmation" icon="fa-solid fa-circle-question" width="525"></p-confirmDialog>

<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
		<ng-template let-col pTemplate="item">
			<div>
				<span>{{col.name}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>