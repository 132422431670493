
<div *ngIf="!fromZoneList" style="position: relative;">
  <div *ngIf="!member.avatar" class="member-name mr-1" [ngStyle]="{background: member.color}" [pTooltip]="member.name" tooltipZIndex="1000001" tooltipPosition="top">{{member.displayName}}</div>
  <div *ngIf="member.avatar"><img class="member-name mr-1 " [pTooltip]="member.name" tooltipZIndex="1000001" tooltipPosition="top" [src]="member.avatar" /></div>
  <span *ngIf="boardAdmin" class="board-admin" pTooltip="{{'Board Admin' | translate}}"></span>
</div>
<div *ngIf="fromZoneList" class="d-flex align-items-center py-1" style="cursor: pointer !important">
	<span *ngIf="!member.avatar" class="member-name mr-1" [ngStyle]="{background: member.color}">{{member.displayName}}</span>
	<span *ngIf="member.avatar"><img class="member-name mr-1" [src]="member.avatar" /></span>
	<span class="ml-2 one-liner flex-grow-1" style="width: 150px;max-width: 150px;">{{member.name}}</span>
	<i class="fa-solid fa-xmark text-danger pull-right float-right ml-2"></i>
</div>