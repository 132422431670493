import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericLength]'
})
export class NumericLengthDirective {

  @Input('appNumericLength') maxLength: number;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const maxLength = this.maxLength || Infinity;

    if (value.length > maxLength) {
      const truncatedValue = value.slice(0, maxLength);
      this.control.control.setValue(truncatedValue);
      this.el.nativeElement.value = truncatedValue;
    }
  }
}