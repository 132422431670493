import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';

import { PhoneSettingsModel, PhoneData } from '../../model/call-log/phone-settings';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment, environmentLocal } from '../../../../environments/environment';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Injectable({ providedIn: 'root' })
export class PhoneSettingsService {
    private static URL = environment.server_ip + '/phone-settings';
    private phoneSettings: PhoneSettingsModel;

    constructor(private http: HttpClient) {
    }

    public create(phoneSettings: PhoneSettingsModel): Observable<PhoneSettingsModel> {
        const createdPhoneSettings = Object.assign(new PhoneSettingsModel(), phoneSettings);
        return this.http.post<PhoneSettingsModel>(PhoneSettingsService.URL, createdPhoneSettings);
    }

    onSavePhoneSettings(dndPhoneData: PhoneData, cfwdPhoneData: PhoneData): Observable<any> {
        return forkJoin(this.http.post<any>(environmentLocal.dndApi, dndPhoneData), this.http.post<any>(environmentLocal.callForwardApi, cfwdPhoneData));
    }

    public getPhoneSettings(did: string): Observable<PhoneSettingsModel> {
        return this.http.post<any>(environmentLocal.phoneSettingsApi, {extension: did});
    }
}
