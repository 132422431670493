import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { EmployeEvent } from '../model/employe-event';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Response } from '../../shared/model/response';
import { NoteHistory } from '../model/note-history';
import { Employee } from '../../employee/model/employee.model';
import { Page } from '../../shared/model/page.model';
import { Agency } from '../../agency/model';
import { EmployeeNotifcationSetting } from '../../employee/model/employee-setting/employee-notifcation-setting.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NoteHistoryService extends AbstractService<NoteHistory> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CRM.NOTE_HISTORY, '');
    }

    getAllNoteByClientId(userId: number, options?: any): Observable<Page<NoteHistory>> {
        const params = this.createParamsPrivately(options);
        return this._http.get<Page<NoteHistory>>(`${this.baseUrlV2}${userId}`, { params: params }).pipe(map(resp => resp));
    }

    saveNote(data: NoteHistory): Observable<NoteHistory> {
        return this._http.post<NoteHistory>(`${this.baseUrlV2}`, data).pipe(map(resp => resp));
    }

    sendEmailNoteHistory(id: number, options?: any): Observable<any> {
        const params = this.createParamsPrivately(options);
        return this._http.get(`${this.baseUrl}api/note/history/sendEmail/${id}`, { params: params }).pipe(map(resp => resp));
    }


    getNoteById(id: Number): Observable<NoteHistory> {
        return this._http.get<NoteHistory>(`${this.baseUrlV2}getById/${id}`).pipe(map(resp => resp));
    }

    deleteNoteById(id: Number): Observable<NoteHistory> {
        return this._http.delete<NoteHistory>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    getNoteByUser(user: any, arrayOfIds): Observable<NoteHistory> {
        return this._http.post<NoteHistory>(`${this.baseUrlV2}getNoteByUser`,arrayOfIds , { params: { user: user } }).pipe(map(resp => resp));
    }

    getNotesToUser(user: any): Observable<NoteHistory> {
        return this._http.get<NoteHistory>(`${this.baseUrlV2}getNoteToUser`, { params: { user: user } }).pipe(map(resp => resp));
    }

    private createParamsPrivately(options: any): HttpParams {
        let params = new HttpParams();
        if (options) {
            for (const key in options) {
                if (options.hasOwnProperty(key)) {
                    params = params.set(key, options[key]);
                }
            }
        }
        return params;
    }

    saveNotification(notificationData: any): Observable<any> {
        return this._http.post<any>(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}`, notificationData).pipe(map(resp => resp));
    }

    getNotification(): Observable<any> {
        return this._http.get<any>(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}`).pipe(map(resp => resp));
    }

    getNotificationByUser(user: any): Observable<any> {
        return this._http.get<any>(`${environment.server_ip}/${Constants.ROUTES.NOTIFCATIONSETTING}/getNotificationByUser`, { params: { user: user } }).pipe(map(resp => resp));
    }

    getAllNoteByPage(params: any): Observable<Page<NoteHistory>> {
        return this._http.get<Page<NoteHistory>>(`${this.baseUrlV2}search`, { params: params }).pipe(map(resp => resp));
    }

    deleteDocument(id: Number) {
        return this._http.delete(`${this.baseUrlV2}document/${id}`).pipe(map(data => data));
    }

    updateDocument(id: Number, urlPath: String) {
        return this._http.put<any>(`${this.baseUrlV2}document/${id}`, { documentPath: urlPath }).pipe(map(resp => resp));
    }

    getDocumentHistory(noteId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}document/history`, { params: { noteId: noteId } }).pipe(map(resp => resp));
    }

    getOvertimeNotification(): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/${Constants.ROUTES.NOTIFCATIONSETTING}/getOvertimeNotification`).pipe(map(resp => resp));
    }
}
