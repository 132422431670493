import { AbstractService } from "../../shared/service/abstract.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../../shared/model/constants";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { EmployeeJobReportNote } from "../model/employee-job-report-note.model";

@Injectable({ providedIn: 'root' })
export class EmployeeJobReportNoteService extends AbstractService<EmployeeJobReportNote>{
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.EMPLOYEE_JOB_REPORT_NOTE, '');
    }

    getAllEmployeeJobReportNote(id: number): Observable<any[]> {
        // return this._http.get<any[]>(`http://localhost:8004/api/v2/employee/employee-job-report/${id}/employee-job-report-note`).pipe(map(resp => resp));
        return this._http.get<any[]>(`${this.baseUrlV2 + 'employee-job-report'}/${id}/employee-job-report-note`).pipe(map(resp => resp));
    }

    saveEmployeeJobReportNote(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8004/api/v2/employee/employee-job-report-note`, entity).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}employee-job-report-note`, entity).pipe(map(res => res));
    }

    getAllEmployeeLatestNote(companyId: number): Observable<any[]> {
        // return this._http.get<any[]>(`http://localhost:8004/api/v2/employee/employee-latest-note`).pipe(map(resp => resp));
        return this._http.get<any[]>(`${this.baseUrlV2}employee-latest-note/${companyId}`).pipe(map(resp => resp));
    }
}
