
import {PayrollSetting} from "./payroll-setting.model";
import {Department} from "../../department/model/department.model";
import {Employee} from "../../employee/model/employee.model";
import {Holiday} from "./holiday.model";
import {AgencyHoliday} from "../../agency/model/agency-holiday.model";
import {Position} from '../../position/model/position.model';
export class PayrollHoliday {
    id: number;
    payrollSetting: PayrollSetting;
    positions: Position[];
    positionNames: string;
    holidayId: number;
    departmentId: number;
    holidayMultipler: number;
    departments: Department[];
    departmentNames: string;
    employee: Employee;
    holiday: AgencyHoliday;

}
