export class FundraisersDetailModel {
  id: number;
  employer: string;
  candidateId: number;
  fundraisersId: number;
  occupation: string;
  address: string;
  aptSuiteFloor: string;
  city: string;
  companyId: number;
  state: string;
  createdByUsr: string;
  createdAt: Date;
  updatedAt: Date;
  lastModifiedBy: string;
  zipCode: string;

  raisedAmount: number;
  createdAtStr: string;
}
