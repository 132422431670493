<h1 class="mt-5">Privacy Policy</h1>
<p ><span >This privacy notice for Operr Work, Inc (&quot;Company.&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), describes how and why we might collect, store, use, and/or share (&quot;process&quot;) your information when you use our services (&quot;Services&quot;), such as when you:</span></p>
<ul>
	<li> Visit our website at https://operrwork.com, or any website of ours that links to this privacy notice</li>
	<li>Download and use our mobile application (Operr Work), or any other application of ours that links to this privacy notice</li>
	<li>Engage with us in other related ways, including any sales, marketing, or events</li>
</ul>

<p ><strong><span >Questions or concerns?</span></strong><span >&nbsp;Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please</span></p>
<p ><span >contact us at&nbsp;</span><a href="mailto:operwork@operr.com"><span style='font-size:16px;line-height:150%;font-family:"IBM plex Sans",sans-serif;color:#1155CC;'>operwork@operr.com</span></a></p>

<p ><strong><span >SUMMARY OF KEY POINTS</span></strong></p>
<p ><em><span >This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</span></em></p>

<p ><strong><span >What personal information do we process?</span></strong><span >&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Operr Work, Inc, the Services, the choices you make, and the products and features you use. Learn more about the personal information you disclose to us.</span></p>

<p ><strong><span >Do we process any sensitive personal information?</span></strong><span >&nbsp;We do not process sensitive personal information.</span></p>

<p ><strong><span >Do we receive any information from third parties?&nbsp;</span></strong><span >We do not receive any information from third parties.</span></p>



<p ><strong><span >How do we process your information?&nbsp;</span></strong><span >We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</span></p>

<p ><strong><span >In what situations and with which parties do we share personal information?&nbsp;</span></strong><span >We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</span></p>

<p ><strong><span >How do we keep your information safe?</span></strong><span >&nbsp;We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</span></p>

<p ><strong><span >What are your rights?&nbsp;</span></strong><span >Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</span></p>
<p ><strong><span >How do you exercise your rights?</span></strong><span >&nbsp;The easiest way to exercise your rights is by visiting https://operrwork.com/, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></p>

<p ><span >Want to learn more about what Operr Work, Inc does with any information we collect? Review the privacy notice in full.</span></p>


<p ><strong><span >TABLE OF CONTENTS&nbsp;</span></strong></p>
<p ><a (click)="tableOfContents('1')" href="javascript:void(0);"><strong><span >1. What information do we collect&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="float: right; padding-right: 150px;">3</span></strong></a></p>
<p ><a (click)="tableOfContents('2')" href="javascript:void(0);"><strong><span >2. How Do We Process Your Information?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">6</span></strong></a></p>
<p ><a (click)="tableOfContents('3')" href="javascript:void(0);"><strong><span >3. What Legal Bases Do We Rely On To Process Your Information?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="float: right; padding-right: 150px;">6</span></strong></a></p>
<p ><a (click)="tableOfContents('4')" href="javascript:void(0);"><strong><span >4. When And With Whom Do We Share Your Personal Information?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">8</span></strong></a></p>
<p ><a (click)="tableOfContents('5')" href="javascript:void(0);"><strong><span >5. Do We Use Cookies And Other Tracking Technologies?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="float: right; padding-right: 150px;">8</span></strong></a></p>
<p ><a (click)="tableOfContents('6')" href="javascript:void(0);"><strong><span >6. How Long Do We Keep Your Information?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">8</span></strong></a></p>
<p ><a (click)="tableOfContents('7')" href="javascript:void(0);"><strong><span >7. How Do We Keep Your Information Safe?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">9</span></strong></a></p>
<p ><a (click)="tableOfContents('8')" href="javascript:void(0);"><strong><span >8. What Are Your Privacy Rights?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="float: right; padding-right: 150px;">9</span></strong></a></p>
<p ><a (click)="tableOfContents('9')" href="javascript:void(0);"><strong><span >9. Controls For Do-not-track Features&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">11</span></strong></a></p>
<p ><a (click)="tableOfContents('10')" href="javascript:void(0);"><strong><span >10. Do We Make Updates To This Notice?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="float: right; padding-right: 150px;">11</span></strong></a></p>
<p ><a (click)="tableOfContents('11')" href="javascript:void(0);"><strong><span >11. How Can You Contact Us About This Notice?&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">12</span></strong></a></p>
<p ><a (click)="tableOfContents('12')"href="javascript:void(0);"><strong><span >12. How Can You Review, Update, Or Delete The Data We Collect From You?&nbsp; &nbsp; &nbsp;</span><span style="float: right; padding-right: 150px;">12</span></strong></a></p>

<div id="tableOfContents1" tabindex="1" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol style="margin-bottom:0in;list-style-type: decimal;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>What information do we collect</span></strong></h2>
        </li>
    </ol>
</div>
<p ><strong><span >Personal information you disclose to us</span></strong></p>

<p ><strong><span >In Short:</span></strong><span >&nbsp;We collect personal information that you provide to us.</span></p>

<p ><span >We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services when you participate in activities on the Services, or otherwise when you contact us.</span></p>

<p ><strong><span >Personal Information Provided by You.</span></strong><span >&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></p>
<p ><span>&bull; names</span></p>
<p ><span>&bull; phone numbers</span></p>
<p ><span>&bull; email addresses</span></p>
<p ><span>&bull; mailing addresses</span></p>
<p ><span>&bull; job titles</span></p>
<p ><span>&bull; usernames</span></p>
<p ><span>&bull; passwords</span></p>
<p ><span>&bull; billing addresses</span></p>
<p ><span>&bull; debit/credit card numbers</span></p>

<p ><strong><span >Sensitive Information.</span></strong><span >&nbsp;We do not process sensitive information.</span></p>

<p ><strong><span >Payment Data</span></strong><span >. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Operr Work.</span></p>
<p ><strong><span >Application Data.&nbsp;</span></strong><span >If you use our application (s), we also may collect the following information if you choose to provide us with access or permission:</span></p>


<p ><span >&bull; Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&apos;s settings.</span></p>

<p ><span >&bull; &nbsp;Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device&apos;s operating system or platform, the type of mobile device you use, your mobile device&apos;s unique device ID, and information about the features of our application(s) you accessed</span></p>

<p ><span >&bull; Push Notifications. We may request to send you push notifications regarding your account or certain features of the application (s). If you wish to opt out from receiving these types of communications, you may turn them off in your device&apos;s settings.</span></p>

<p ><span >This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span></p>

<p ><span >All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information</span></p>

<p ><strong><span >Information automatically collected</span></strong></p>
<p ><span >In Short: Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics - is collected automatically when you visit our Services.</span></p>

<p ><span >We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></p>

<p ><span >Like many businesses, we also collect information through cookies and similar technologies.</span></p>

<p ><span >The information we collect includes:</span></p>

<p ><span >&bull; Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called &quot;crash dumps&quot;), and hardware settings).</span></p>

<p ><span >&bull; Device Data. We collect device data such as information about your computer, phone, tablet, or another device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</span></p>

<p ><span >&bull; Location Data. We collect location data such as information about your device&apos;s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address. You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt-out, you may not be able to use certain aspects of the Services.</span></p>

<div id="tableOfContents2" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="2" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>How Do We Process Your Information?</span></strong></h2>
        </li>
    </ol>
</div>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>&nbsp;</p>
<p ><strong><span >In Short:</span></strong><span >&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.</span></p>

<p ><span >We process your personal information for various reasons, depending on how you interact with our Services, including:</span></p>
<p ><strong><span >&nbsp;</span></strong></p>
<p ><span >&bull; To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></p>
<p ><span >&bull; To deliver and facilitate the delivery of services to the user. We may process your information to provide you with the requested service</span></p>
<p ><span >&bull; To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more</span></p>
<p ><span >&bull; To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.&nbsp;</span></p>
<p ><span >&bull; To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</span></p>

<div id="tableOfContents3" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="3" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>What Legal Bases Do We Rely On To Process Your Information?</span></strong></h2>
        </li>
    </ol>
</div>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>&nbsp;</p>
<p ><strong><span >In Short:&nbsp;</span></strong><span >We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</span></p>

<p ><em><u><span >If you are located in Canada, this section applies to you.</span></u></em></p>
<p ><span >We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</span></p>

<p ><span >In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example</span></p>

<p ><span >&bull;If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></p>
<p ><span >&bull; For investigations and fraud detection and prevention</span></p>
<p ><span >&bull; For business transactions provided certain conditions are met</span></p>
<p ><span >&bull; If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></p>
<p ><span >&bull; For identifying injured, ill, or deceased persons and communicating with next of kin</span></p>
<p ><span >&bull; If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span></p>
<p ><span >&bull; If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span></p>
<p ><span >&bull; If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</span></p>
<p ><span >&bull; If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span></p>
<p ><span >&bull; If the collection is solely for journalistic, artistic or literary purpose</span></p>
<p ><span >&bull; If the information is publicly available and is specified by the regulations</span></p>

<div id="tableOfContents4" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="4" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>When And With Whom Do We Share Your Personal Information?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: We may share information in specific situations described in this section and/or with the following third parties.</span></p>

<p ><span >We may need to share your personal information in the following situations:</span></p>
<p ><span >&bull;Business Transfers. We may share or transfer your information in connection with or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></p>
<p ><span >&bull; When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We obtain and store on your device (&quot;cache&quot;) your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</span></p>

<div id="tableOfContents5" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="5" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>Do We Use Cookies And Other Tracking Technologies?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: We may use cookies and other tracking technologies to collect and store your information.</span></p>
<p ><span >We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</span></p>


<div id="tableOfContents6" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="6" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>How Long Do We Keep Your Information?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span></p>

<p ><span >We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user&apos;s</span></p>
<p ><span >Account</span></p>

<p ><span >When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives). then we will securely store your personal information and isolate it from any further processing until deletion is possible</span></p>

<div id="tableOfContents7" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="7" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>How Do We Keep Your Information Safe?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: We aim to protect your personal information through a system of organizational and technical security measures.</span></p>

<p ><span >We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></p>

<div id="tableOfContents8" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="8" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>What Are Your Privacy Rights?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: In some regions, such as Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span></p>

<p ><span >In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (i) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided</span></p>
<p ><span >We will consider and act upon any request in accordance with applicable data protection laws.</span></p>

<p ><span >If you are located in the EA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority</span></p>

<p ><span >If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</span></p>

<p ><u><span >Withdrawing your consent:&nbsp;</span></u><span >If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided&nbsp;</span></p>

<p ><span >However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p>

<p ><u><span >Opting out of marketing and promotional communications:</span></u><span >&nbsp;You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying &quot;STOP&quot; or &quot;UNSUBSCRIBE to the SMS messages that we send, or by contacting us using the details provided. You will then be removed from the marketing lists. However, we may still communicate with you - for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span></p>

<p ><strong><span >Account Information</span></strong></p>

<p ><span >If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>

<p ><span >&bull; Log in to your account settings and update your user account.</span></p>

<p ><span >Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms, and/or comply with applicable legal requirements.</span></p>

<p ><strong><u><span >Cookies and similar technologies:&nbsp;</span></u></strong><span >Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services.</span></p>

<p ><span >If you have questions or comments about your privacy rights, you may email us at&nbsp;</span><a href="mailto:operrwork@operr.com"><span style='font-size:16px;line-height:150%;font-family:"IBM plex Sans",sans-serif;color:#1155CC;'>operrwork@operr.com</span></a><span >.</span></p>

<div id="tableOfContents9" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="9" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
            <h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>Controls For Do-not-track Features</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>

<div id="tableOfContents10" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="10" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
			<h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>Do We Make Updates To This Notice?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></p>
<p ><span >We may update this privacy notice from time to time. The updated version will be indicated by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></p>

<div id="tableOfContents11" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="11" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
			<h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>How Can You Contact Us About This Notice?</span></strong></h2> 
        </li>
    </ol>
</div>
<p><span>If you have questions or comments about this notice, you may email us at operrwork@operr.com or by post to:</span></p>
<span>&nbsp;</span>
<p>
<span>Operr Work. Inc</span><br>
<span>30-50 Whitestone Expy</span><br>
<span>Flushing, NY 11354</span><br>
<span>United States</span>
</p>
<div id="tableOfContents12" style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
    <ol start="12" style="margin-bottom:0in;list-style-type: undefined;margin-left:0in;">
        <li style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>
			<h2 style='margin-top:.25in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:150%;font-size:21px;font-family:"IBM plex Sans",sans-serif;font-weight:normal;'><strong><span style='line-height:150%;font-family:"IBM plex Sans",sans-serif;font-family:"IBM plex Sans",sans-serif;font-size:12.0pt;'>How Can You Review, Update, Or Delete The Data We Collect From You?</span></strong></h2>
        </li>
    </ol>
</div>
<p ><span >You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: https://operwork.com/</span></p>
<p style='margin:0in;margin-bottom:.0001pt;line-height:115%;font-size:15px;font-family:"IBM plex Sans",sans-serif;'>&nbsp;</p>