import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AbsenceService } from '../../absence/service/absence.service';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { TimeUtility } from '../../../shared/utility/time.utility';
import { Chart } from 'chart.js';
import { EmployeeService } from '../../service/employee.service';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { DatePipe } from '@angular/common';
import { UtilsService } from '../../../shared/service/utils.service';
import { Employee } from '../../../employee/model/employee.model';
import { EmployeeClockingServiceV2 } from '../../../employee-clocking/service/v2/employee.clocking.v2.service';
import { CookieService } from 'ngx-cookie-service';
import { NoteService } from 'app/note/service/note.service';
import { ToDoService } from 'app/todo/service/todo.service';
import { AgencyHolidayService } from 'app/agency/service';
import { EmployeeClockingService } from 'app/employee-clocking/service/employee-clocking.service';
import {EmployeeJobReportNoteService} from '../../service/employee-job-report-note.service';
import {ClockingComplianceReport, ClockingComplianceRequest, DailyComplianceReport} from '../../../reports/model/clock.compliance.model';
import {EmployeeJobReportService} from '../../service/employee-job-report.service';
import {MenuItem} from 'primeng/api';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import * as _ from 'lodash';
@Component({
    selector: 'app-employee-dashboard',
    templateUrl: './employee-dashboard.component.html',
    styleUrls: ['./employee-dashboard.component.css'],
    providers: [AbsenceService, EmployeeClockingServiceV2, ToDoService]
})
export class EmployeeDashboardComponent implements OnInit {
    absences: any[] = [];
    fromDate;
    toDate;
    absenceList: any[] = [];
    showAgreementDialog = true;
    checkAgreement = false;
    showOkBtn = false;
    dataPlanAssignment: any;
    horizontalOptions: any;
    timeOffCols: any[] = [
        { field: 'from', label: 'From' },
        { field: 'to', label: 'To' },
        { field: 'status', label: 'Status' }
    ];
    shareDocumentCols: any[] = [
        { field: 'name', label: 'Note' },
        // { field: 'createdBy', label: 'Send to' },
        { field: 'createdAt', label: 'Date' }
    ];
    sharedNotes: any[] = [];
    absenceCols: any[] = [
        { field: 'absenceType', label: 'Type' },
        { field: 'ptoAccrued', label: 'PTO' },
        { field: 'toPeriod', label: 'Period' },
        { field: 'usedHours', label: 'Used' },
        { field: 'pendingHours', label: 'Pending' },
        { field: 'availablePtoHours', label: 'Available' }
    ];

    payHourChart: any;
    payrollChart: any;

    workedMins = 0;
    overtimeMins = 0;
    workedHours = 0;
    overtimeHours = 0;
    totalMins = 0;
    totalHours = 0;
    totalWorking = '';
    regularHours = 0;
    totalSchedule = 0;
    birthdays: any[] = [];
    anniversaries: any[] = [];
    holidays: any[] = [];
    employeeId: number;
    OTLabel: String;
    absencesAprroved: any[] = [];
    clockedIn = false;
    delayedArrivalTimeout;
    delayedArrivalCountDown = 0;
    isDelayedArrival = false;
    delayedTimeStr;
    delayedCounDownTimeout;
    fullName;
    jobReports = [];
    data = [];
    dataCached = [];
    totalRecords: number;
    loading: Boolean = false;
    loadingJobReports: Boolean = false;
    page: number = 0;
    size: number = 99999;
    totalScheduledTime: number = 0;
    totalHoursWorked: number = 0;
    complianceFilter: ClockingComplianceRequest = new ClockingComplianceRequest();
    complianceReport: ClockingComplianceReport = new ClockingComplianceReport();
    complianceReportColumns = [
        { label: 'Date', field: 'date', sortOptions: '', sort: 'number' },
        // { label: 'Week', field: 'expectedToday', sortOptions: '', sort: 'number' },
        // { label: 'Absence', field: 'absentCount', sortOptions: '', sort: 'number' },
        { label: 'Good', field: 'onTimeCount', sortOptions: '', sort: 'number' },
        { label: 'Late', field: 'lateCount', sortOptions: '', sort: 'number' },
        { label: 'Workers', field: 'totalEmployee', sortOptions: '', sort: 'number' },
        { label: 'Miss', field: 'missedclockCount', sortOptions: '', sort: 'number' },
        { label: 'No Break', field: 'withoutLunchCount', sortOptions: '', sort: 'number' },
        { label: 'Sched.', field: 'schduledTimeInMinutes', sortOptions: '', sort: 'number' },
        { label: 'Actual', field: 'regularTimeinMinutes', sortOptions: '', sort: 'number' }
    ];
    sharedLoading = false;
    requestsLoading = false;
    holidaysLoading = false;
    isEmployee = false;

    startWork: any = '';


    basicMenutItems = [
        { label: 'Employee Dashboard', id: 'employeeDashboard', icon: 'fa-chart-pie', routerLink: ['/', 'app', 'invoice', 'dashboard'] },
      ];
    employeeDashboardTabs: MenuItem[] = []
    overtimeTabMenuItems: MenuItem[];
    activeItem: MenuItem;

    initTab() {
        this.employeeDashboardTabs = [];
        this.employeeDashboardTabs.push(...this.basicMenutItems);
        this.activeItem = this.employeeDashboardTabs[0];
    }

    constructor(private router: Router,
        private absenceService: AbsenceService,
        private breadcrumbService: BreadcrumbService,
        private utilsService: UtilsService,
        private authService: AuthService,
        private toDoService: ToDoService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        private employeeClockingService: EmployeeClockingService,
        private employeeService: EmployeeService,
        private cookieService: CookieService,
        private payrollSettingService: PayrollSettingService,
        private agencyHolidayService: AgencyHolidayService,
        private employeeJobReportNoteService: EmployeeJobReportNoteService,
        private datePipe: DatePipe,
        private employeeJobReportService: EmployeeJobReportService,
        private documentService: DocumentsService,
        private noteService: NoteService) {
    }

    ngOnInit() {
//        this.initTab();
  //      this.breadcrumbService.setItems([{ label: 'Employee Dashboard', routerLink: ['/app/employee-dashboard'] }]);
        this.getAllEmployee();
        this.loadOverTime();
        this.loadDashboard();
        this.loadHolidays();
        this.getShareNote();
        this.planAssignmentData();
        // this.getAllEmployeeLatestNote();
        // this.loadComplianceReport();
        if (this.authService.isEmployeeRole()) {
            this.isEmployee = true;
            this.complianceReportColumns.splice(this.complianceReportColumns.findIndex(value => value.field === 'totalEmployee'), 1);
            this.fullName = this.authService.getUserInfo().firstName + ' ' + this.authService.getUserInfo().lastName;
            this.delayedArrivalTimeout = setInterval(() => {
                const delayedArrival = localStorage.getItem('_delayedArrival');
                if (delayedArrival) {
                    const delayedArrivalDate: Date = JSON.parse(delayedArrival);
                    const startCountTime = moment(delayedArrivalDate).toDate();
                    // console.log(moment(delayedArrivalDate).toDate());
                    if (moment().isAfter(moment(delayedArrivalDate))) {
                        this.employeeClockingService.punchHistoryFilter({
                            employeeIds: [this.authService.getUserInfo().adminId],
                            fromDate: new Date(),
                            toDate: new Date()
                        }).subscribe((res: any) => {
                            if (res && res.data && res.data.content && res.data.content.length > 0) {
                                const puchInTime = new Date(res.data.content[0].clockInTime);
                                if (moment(puchInTime).isAfter(moment(delayedArrivalDate))) {
                                    const delayedTime = puchInTime.getTime() - startCountTime.getTime();
                                    this.delayedTimeStr = this.convertMillisecondsToTime(delayedTime);
                                    if (this.delayedArrivalTimeout) {
                                        clearInterval(this.delayedCounDownTimeout);
                                    }
                                    this.isDelayedArrival = true;
                                }
                                if (this.delayedArrivalTimeout) {
                                    clearInterval(this.delayedArrivalTimeout);
                                }
                            } else {
                                this.isDelayedArrival = true;
                                // const delayedArrivalCountDown = delayedArrivalDate.getTime() + 120000 - new Date().getTime();
                                // this.delayedArrivalCountDown = delayedArrivalCountDown > 0 ? delayedArrivalCountDown: 0;
                                if (!this.delayedCounDownTimeout) {
                                    this.delayedCounDownTimeout = setInterval(() => {
                                        const delayedTime = new Date().getTime() - startCountTime.getTime();
                                        this.delayedTimeStr = this.convertMillisecondsToTime(delayedTime);
                                    }, 1000);
                                }

                            }
                        });
                    }
                }
            }, 5000);
        }
        this.loadDataSourceJobReport({ first: 0, rows: 6 });
    }

    convertMillisecondsToTime(milliseconds) {
        const date = new Date(milliseconds);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        return hours.toString().padStart(2, '0') + ':' +
                         minutes.toString().padStart(2, '0') + ':' +
                         seconds.toString().padStart(2, '0');
    }

    loadDashboard() {
        const employeeId = this.authService.getCurrentEmployee();
        this.employeeId = employeeId;
        this.requestsLoading = true;
        this.employeeService.dashboard(employeeId).subscribe(res => {
            const data = res.data;
            console.log('loadDashboard data: ', data);
            // absenceAccrued
            this.handleAbsenceAccrued(data);
            this.birthdays = data.birthday;
            this.anniversaries = data.anniversary ?? [];
            const today = new Date();
            if (this.loadAnniversary) {
                this.anniversaries.forEach(an => {
                    an.title = an.gender === 'FEMALE' ? 'her' : 'his';
                    an.year = today.getFullYear() - new Date(an.employeeStartDate).getFullYear();
                });
            }
            this.absenceList = data.absences;
            this.requestsLoading = false;
            if (this.absenceList && this.absenceList.length > 0) {
                this.absenceList.forEach(absence => {
                    absence['status'] = this.status(absence);
                    absence['from'] = this.datePipe.transform(absence.timeStart, 'M/d/y');
                    absence['to'] = this.datePipe.transform(absence.timeEnd, 'M/d/y');
                    if (absence.status === 'Approved' && absence.employee) {
                        const fullName = absence.employee.firstName + ' ' + absence.employee.lastName;
                        const timeStart = new Date(absence.timeStart);
                        const timeEnd = new Date(absence.timeEnd);
                        let message;
                        if (moment(timeStart).diff(moment(timeEnd), 'd') === 0) {
                        message = `<b>${fullName}</b> is OFF on ${moment(timeStart).format('MM/DD')}`;
                        } else {
                         message = `<b>${fullName}</b> is OFF on ${moment(timeStart).format('MM/DD')} <i class="fa-solid fa-arrow-right text-muted mx-1"></i> ${moment(timeEnd).format('MM/DD')}`;
                        }
                        this.absencesAprroved.push(message);
                    }
                });
            }
        });
    }

    handleAbsenceAccrued(data) {
        const absenceAccrued = data.absenceAccrued.map(d => {
            if (d.toPeriod) {
                d.toPeriod = moment.unix(d.toPeriod / 1000).format('MM/DD');
            } else {
                d.toPeriod = new Date();
            }
            return d;
        });
        this.absences = absenceAccrued;
    }

    loadAbsence() {
        const options: any = {};
        options.size = 9999;
        options.page = 0;
        const employee = this.authService.getCurrentEmployee();
        if (employee !== null) {
            this.absenceService.getAccruedByEmployeeId(employee, options).subscribe(
                (data: any) => {
                    data.data.content = data.data.content.map(d => {
                        if (d.toPeriod) {
                            d.toPeriod = moment.unix(d.toPeriod / 1000).format('MM/DD');
                        } else {
                            d.toPeriod = new Date();
                        }
                        return d;
                    });
                    this.absences = data.data.content;
                }
            );
        }
    }

    getHoursFormat(data) {
        return this.utilsService.getHoursFormat(data);
    }

    getHHMMFormat(data) {
        return this.utilsService.getHHMMFormat(data * 60);
    }

    checkHoursPositive(rowData, pendingHour) {
        return rowData - pendingHour > 0;
    }

    getDate(data: any) {
        if (data && moment(data).isValid()) {
            return moment(data).format('MM/DD/YYYY');
        }
    }

    loadBirthday() {
        this.employeeService.findAllByDateOfBirth({ companyId: this.authService.getCurrentCompany().id }).subscribe(res => {
            this.birthdays = res.data;
        });
    }

    loadAnniversary() {
        this.employeeService.findAllByEmployeeStartDate().subscribe(res => {
            this.anniversaries = res.data;
            const today = new Date();
            this.anniversaries.forEach(an => {
                an.year = today.getFullYear() - new Date(an.employeeStartDate).getFullYear();
            });
        });
    }
    hasSpecialAnniversary(): boolean {
        return this.anniversaries.some(ann => ann.condition); // Replace with your condition.
      }
      

    loadOverTime() {
        const companyId = this.authService.getCurrentCompanyId();
        this.payrollSettingService.getbyId(companyId).subscribe((res: any) => {
            const payrollSetting = res.data;
            if (payrollSetting) {
                const dayOfWeekTemp = payrollSetting.payrollWeekEnding;
                let dayOfWeek = 0;
                switch (dayOfWeekTemp) {
                    case 'MONDAY':
                        dayOfWeek = 1;
                        break;
                    case 'TUESDAY':
                        dayOfWeek = 2;
                        break;
                    case 'WEDNESDAY':
                        dayOfWeek = 3;
                        break;
                    case 'THURSDAY':
                        dayOfWeek = 4;
                        break;
                    case 'FRIDAY':
                        dayOfWeek = 5;
                        break;
                    case 'SATURDAY':
                        dayOfWeek = 6;
                        break;
                    case 'SUNDAY':
                        dayOfWeek = 0;
                        break;
                    default:
                        break;
                }
                const curr = new Date(); // get current date
                const endWeek: number = curr.getDate() - (curr.getDay() === 0 ? 7 : curr.getDay()) + (dayOfWeek === 0 ? 7 : dayOfWeek);
                const date = new Date(curr.setDate(endWeek));
                this.fromDate = new Date(date.setDate(date.getDate() - 6));
                this.toDate = new Date(date.setDate(date.getDate() + 6));
                const options = {
                    page: 0,
                    size: 9999,
                    companyId: this.authService.getCurrentCompanyId(),
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    groupBy: 'department',
                    withNoOvertime: true,
                    isFromMobile: true,
                    employeeIds: [this.authService.getCurrentLoggedInId()]
                };
                this.employeeClockingServiceV2.getSummaryDetail(options).subscribe((res1: any) => {
                    const data = res1.data.content;
                    let type;
                    data.forEach(d => {
                        this.workedMins += d.durationTotal;
                        this.overtimeMins += d.OT;
                        this.regularHours = d.regularHours;
                        this.totalSchedule = d.totalSchedule;
                        type = d.type;
                    });
                    this.totalMins = this.workedMins;
                    const hour = Math.floor(this.totalMins / 60);
                    const min = this.totalMins % 60;
                    this.totalWorking = `${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min}`;
                    this.workedHours = Math.floor(this.workedMins / 60) + ((this.workedMins % 60) / 60);
                    this.overtimeHours = Math.floor(this.overtimeMins / 60) + ((this.overtimeMins % 60) / 60);
                    this.totalHours = this.overtimeHours + this.workedHours;
                    this.OTLabel = 'Overtime';
                    if (type === 'salary') {
                        this.OTLabel = 'Additional Time';
                    }
                    const chartData = this.totalHours ? this.getConnectivityData(type) : this.getDefaultChart();
                    this.payHourChart = new Chart('payHourChart', chartData);
                    this.payrollChart = new Chart('payrollChart', this.getDefaultChart());
                });

                this.employeeService.getEmployeeByIdCustom(this.authService.getUserInfo().adminId).subscribe((response: any) => {
                    console.log(moment().get('weekday'));
                    console.log(moment().subtract(2, 'd').get('weekday'));
                    const weekday = moment().get('weekday');
                    const subtract = weekday - dayOfWeek;
                    const currDayOfWeek = subtract === 0 ? 7 : (subtract > 0 ? subtract : (subtract + 7));
                    if (response.data && response.data.workHours) {
                        const workDay = response.data.workHours.find(w => w.dayOfWeek === currDayOfWeek);
                        console.log('this.workDay', workDay)
                        if (workDay) {
                            this.startWork =  moment.utc(workDay.startTime).format('hh:mm a');
                            console.log('this.startWork', this.startWork)
                            const startWork = new Date(moment().startOf('d').toDate().getTime() + workDay.startTime);
                            const delayedArrival = moment(startWork).add(payrollSetting.employeeLateAfterHours, 'minutes').toDate();
                            localStorage.setItem('_delayedArrival', JSON.stringify(delayedArrival));
                        }
                    }
                });
            }
        });
    }

    getDefaultChart() {
        return {
            type: 'doughnut',
            data: {
                labels: ['No Hours'],
                datasets: [
                    {
                        data: [1],
                        backgroundColor: ['#CCCCCC'],
                        hoverBackgroundColor: ['#CCCCCC']
                    }
                ]
            },
            options: {
                legend: {
                    display: false,
                 },
                plugins: {
                    legend: false,
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (tooltipItem) {
                                return tooltipItem.dataset.label + '';
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                cutout: '50%',
            }
        };
    }
    
    

    loadTimeOff() {
        this.requestsLoading = true;
        this.absenceService.filter({
            employeeId: this.authService.getCurrentLoggedInId(),
            companyId: this.authService.getCurrentCompany().id,
            agencyId: this.authService.getCurrentAgency().id,
            page: 0,
            size: 9999,
            fromDate: moment().startOf('week').toDate(),
            toDate: moment().endOf('week').toDate()
        }).subscribe((data: any) => {
            this.requestsLoading = false;
            this.absenceList = data.data.content;
            this.absenceList.forEach(absence => {
                absence['status'] = this.status(absence);
                absence['from'] = this.datePipe.transform(absence.timeStart, 'M/d/y');
                absence['to'] = this.datePipe.transform(absence.timeEnd, 'M/d/y');
            });
        });
    }

    status(data: any): string {
        if (data.status === 0) {
            return 'Pending';
        }
        if (data.status === 1) {
            return 'Rejected';
        }
        if (data.status === 2) {
            return 'Approved';
        }

        return 'Pending';
    }

    getConnectivityData(type: string) {

        const label = [];
        const data = [];
        const color = [];
        const totalHour = this.totalSchedule - this.totalMins;
        let additionalTime = 0;
        const remain = totalHour > 0 ? totalHour : 0;
        if (type === 'salary') {
            additionalTime = this.totalMins - this.totalSchedule ;
            if (additionalTime > 0) {
                this.totalMins = this.totalSchedule;
            } else {
                additionalTime = 0;
            }
            this.overtimeMins = 0; // no OT for salary type
        }
        if (remain >= 0) {
            label.push('Stipulated');
            label.push('Worked');
            label.push(this.OTLabel);
            data.push(remain);
            const worked = this.totalMins - this.overtimeMins;
            data.push(worked > 0 ? worked : 0);
            if (type === 'salary') {
                data.push(additionalTime);
            } else {
                data.push(this.overtimeMins);
            }
            color.push('#CCCCCC');
            color.push('#1b96ff');
            color.push('#FF6384');
        } else {
            label.push('Hours worked');
            label.push(this.OTLabel);
            data.push(this.workedHours);
            data.push(this.overtimeHours);
            color.push('#1b96ff');
            color.push('#FF6384');
        }
        return {
            type: 'doughnut',
            data: {
                labels: label || 'no data',
                datasets: [
                    {
                        data: data,
                        backgroundColor: color,
                        hoverBackgroundColor: color
                    }]
            },
            options: {
                background: 'https://operrwork.com/images/operr-icon.png',
                responsive: false,
                legend: false,
                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                            // return "title";
                        },
                        label: function (tooltipItem, data) {
                            const totalMins = parseFloat(data['datasets'][0]['data'][tooltipItem['index']]);
                            const hour = Math.floor(totalMins / 60);
                            const min = totalMins % 60;
                            const totalWorking = `${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min}`;
                            return totalWorking;
                        }
                    }
                }
            }
        };
    }

    showOnlyOkBtn() {
        if (this.checkAgreement) {
            this.showOkBtn = true;
        } else {
            this.showOkBtn = false;
        }
    }

    onClickLogout() {
        this.authService.logout().subscribe((res: any) => {
            if (res.status === 200) {
                this.authService.logUserActivity();
                this.cookieService.deleteAll();
                localStorage.clear();
                this.authService.authenticateUser();
                this.authService.setAuthObservable();
                this.router.navigate(['/', 'login']);
            }
        }, error => {
            this.cookieService.deleteAll();
            localStorage.clear();
            this.authService.authenticateUser();
            this.authService.setAuthObservable();
            this.router.navigate(['/login']);
        });
        this.utilsService.isLogoutTriggered.next(false);
        this.utilsService.isLogoutTriggerCompleted = false;
    }

    getShareNote() {
        this.sharedLoading = true;
        this.noteService.getSharedNotesByUser().subscribe((res: any) => {
            console.log('getShareNote res: ', res)
            const allNote = [];
            res.data.forEach(folder => {
                folder.notes?.forEach(note => {
                    note?.sharedNoteByNoteId?.forEach( sharedNote => {
                        allNote.push({
                            ...note,
                            email: sharedNote.email,
                            createdBy: folder.user,
                            createdAt: this.datePipe.transform(sharedNote.createdAt, 'MM/dd/yyyy hh:mm a')
                        });
                    });
                });
            });
            this.sharedLoading = false;
            this.sharedNotes = allNote;
            console.log('getShareNote sharedNotes: ', this.sharedNotes)
        });
    }

    async planAssignmentData() {
        const date = new Date();
        const last2Week = new Date(date.setDate(date.getDate() - 13));
        const toDate  = new Date(date.setDate(date.getDate() + 13));
        const options = {
            fromDate: last2Week,
            toDate: date,
            page: 0,
            size: 9999,
            companyId: this.authService.getCurrentCompanyId(),
            username: this.authService.getCurrentUsername(),
        };
        const defaultMaxValue = 10;
        const defaultMinValue = 0;
        this.horizontalOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        min: defaultMinValue,
                        max: defaultMaxValue,
                        stepSize: 1,
                        callback: function(value) {
                            return Number(value).toFixed(0);
                        },
                        fontColor: '#666'
                    },
                    gridLines: {
                        color: '#e4e4e4',
                        display: true,
                    }
                }],
                yAxes: [{
                    ticks: {
                        display: true,
                        fontFamily: 'IBM Plex Sans',
                        fontSize: 14,
                    },
                    gridLines: {
                        display: true,
                        color: '#e4e4e4',
                    },
                    barThickness: 38,
                    maxBarThickness: 40,
                }]
            },
            layout: {
                padding: {
                    top: 20,
                    bottom: 20
                }
            },
            legend: {
                display: false,
            },
            tooltips: {
                titleFontFamily: 'IBM Plex Sans',
                titleFontSize: 14,
                bodyFontFamily: 'IBM Plex Sans',
                bodyFontSize: 12,
            }
        };
        const planData: any = await this.toDoService.dashboardSearch(options).toPromise();
        if (planData.status === 'SUCCESS') {
            const capValue = (value) => Math.min(value, defaultMaxValue);
            this.dataPlanAssignment = {
                labels: ['Open', 'Working', 'Complete', 'Waiting', 'On Hold'], 
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: [
                            '#803cff',  // Open
                            '#0081ff',  // In Progress
                            '#70b60e',  // Completed
                            '#ef8f00',  // Waiting
                            '#e42e1f'   // On Hold
                        ],
                        borderColor: '#000000',
                        barThickness: 30,
                        data: [
                            capValue(planData.data.numberOfTodo || 0),
                            capValue(planData.data.numberOfProcessing || 0),
                            capValue(planData.data.numberOfCompleted || 0),
                            capValue(planData.data.numberOfWaiting || 0),
                            capValue(planData.data.numberOfTempStop || 0)
                        ] 
                    }
                ]
            };
            this.dataPlanAssignment.update(); 
        }
    }

    loadHolidays() {
        this.holidaysLoading = true;
        const options = {
            fromDate: moment().subtract(7, 'days').startOf('day').toDate(),
            toDate: moment().add(5, 'days').endOf('day').toDate(),
            companyId: this.authService.getCurrentCompanyId()
        };
        this.agencyHolidayService.filter(options).subscribe((res: any) => {
           res.data.content.forEach(i => {
                const date = new Date(i.holidayDate);
                 date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                 i.holidayDate = date;
                 this.holidays.push(i);
                 this.holidaysLoading = false;
            });
            console.log(this.holidays)
        });
    }

    loadDataSourceJobReport(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        if (event && (event.first / event.rows) === 0) {
            this.page = 0;
        }
        const options: any = {};
        if (event && event.sortField && event.isSortClick) {
            const sortOrder = <any>event['sortOrder'];
            const sortField = event['sortField'];
            options.sortField = sortField;
            options.sortOrder = sortOrder === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
        } else {
            options.sortField = 'createdAt';
            options.sortOrder = 'DESC';
        }
        this.loadJobReport(options);
    }

    loadJobReport(optionSort) {
        this.loadingJobReports = true;
        const options: any = {
            size: this.size,
            page: this.page,
            sortOrder: optionSort.sortOrder,
            sortField: optionSort.sortField
        };
        options.companyId = this.authService.getCurrentCompanyId();
        options.username = this.authService.getCurrentUsername();
        this.employeeJobReportService.search(options).subscribe((data: any) => {
            this.jobReports = data.data.content;
            this.jobReports.forEach(item => {
                item.latestNote = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].note : '';
            });
            this.loadingJobReports = false;
        }, () => {
            this.loadingJobReports = false;
        });
    }

    getAllEmployeeLatestNote() {
        this.employeeJobReportNoteService.getAllEmployeeLatestNote(this.authService.getCurrentCompanyId()).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.jobReports = resObj.data;
            } else {
                this.jobReports = [];
            }
        });
    }
    loadComplianceReport(event?) {
        this.complianceFilter.size = event && event.rows ? event.rows : (this.complianceFilter.size ? this.complianceFilter.size : 25);
        this.complianceFilter.page = event && event.first >= 0 && event.rows >= 0 ?
            (event.first / event.rows) : (this.complianceFilter.page ? this.complianceFilter.page : 0);
        this.complianceFilter.companyId = this.authService.getCurrentCompanyId();
        this.complianceFilter.employeeIds = [];
        this.complianceFilter.employeeIds.push(this.authService.getUserInfo().adminId);
        let start = new Date();
        let end: Date = new Date();
        start.setDate(end.getDate() - 6);
        this.complianceFilter.fromDate = start.getTime();
        this.complianceFilter.toDate = end.getTime();

        this.loadComplaince();
    }

    loadComplaince() {
        this.loading = true;
        this.data = [];
        this.complianceFilter.timeZone = moment.tz.guess();
        this.employeeClockingService.getComplianceReport(this.complianceFilter).subscribe(res => {
                this.loading = false;
                if (res.status === 'SUCCESS') {
                    this.complianceReport = res.data;
                    let totalExpected = 0;
                    let totalEmployee = 0;
                    let totalWorkTime = 0;
                    this.totalRecords = this.complianceReport.numberOfDays;
                    if (this.complianceReport.daywiseCompliance.length != 0)
                        this.complianceReport.daywiseCompliance.forEach((dwc: DailyComplianceReport) => {
                            dwc.sceduledTimeHHMM = this.convertMinutesToHours(dwc.schduledTimeInMinutes);
                            dwc.regularTimeHHMM = this.convertMinutesToHours(dwc.regularTimeinMinutes);
                            var day = new Date(dwc.date);
                            dwc.isHoliday = day.getDay() !== 0 && day.getDay() !== 6 ? true : false;
                            dwc.totalEmployee = dwc.lateCount + dwc.onTimeCount;
                            dwc.weekDay = this.transform(dwc.expectedToday, 1);
                            totalEmployee += dwc.totalEmployee;
                            totalExpected += dwc.expectedToday;
                            totalWorkTime += dwc.timeWorkDay;
                        });
                    this.data = this.complianceReport.daywiseCompliance;
                    this.complianceReport.totalScheduledHoursHHMM = this.convertMinutesToHours(this.complianceReport.totalScheduled);
                    this.complianceReport.totalWorkHourHHMM = this.convertMinutesToHours(this.complianceReport.totalWorkTime);
                    this.complianceReport.totalExpected = this.transform(totalExpected, 1);
                    this.complianceReport.totalEmployee = totalEmployee;
                    this.complianceReport.totalWorkTimeHHMM = this.convertMinutesToHours(totalWorkTime);
                }
            },
            error => {
                console.error('Error Fetching Compliance');
                this.loading = false;
            }
        );
    }

    convertMinutesToHours(minutes: number) {
        let isNegative = false;
        if (minutes < 0)
            isNegative = true;

        minutes = Math.abs(minutes);

        if (minutes == 0 || minutes == null)
            return '0:00';
        else {
            if (minutes % 60 == 0) {
                let m = (minutes / 60);
                return m < 10 ? '0' + m + ':00' : m.toString().concat(':00');
            }

            else {
                let m: any = Math.floor(minutes % 60);
                let h: any = Math.floor(((minutes - m) / 60));

                if (h < 10) h = '0' + h;
                if (m < 10) m = '0' + m;

                return (isNegative ? '-' : '') + h + ':' + m;
            }
        }

    }

    detailClick(data, type, title) {
        // this.detailTitle = title;
        let ids = data[type];
        if (ids && ids.length) {
            let option = {
                ids: data[type],
                page: 0,
                size: ids.length,
                status: 1,
            };
            // this.emmployeeService.filterByCompany(option).subscribe(res => {
            //     this.detailDisplay = true;
            //     this.details = res.data.content;
            // })
        } else {
            // this.details = [];
            // this.detailDisplay = true;
        }
    }
    transform(value: number, decimalPlaces: number = 2): number {
        const daysPerWeek = 7; // Number of hours in a week
        const result = value / daysPerWeek;
        return parseFloat(result.toFixed(decimalPlaces));
    }

    isBeforeToday(rowDate: Date): boolean {
        const currentDate = new Date();
        const inputDate = new Date(rowDate);
        inputDate.setFullYear(currentDate.getFullYear());
        return inputDate < currentDate;
    }
    gotoJobReport() {
        this.router.navigate(['/app/employee/employee-job-report/list']);
    }
    gotoRequestTimeOff() {
        this.router.navigate(['/app/absence/list']);
    }

    allEmployeeList: any[] = [];
    getAllEmployee() {
        this.employeeService.getAllEmployee().subscribe(res => {
            this.allEmployeeList = res.data.map(x =>
                ({ email: x.email, fullName: x.fullName, key: x.email, id: x.id, firstName: x.firstName, lastName: x.lastName })); 
        });
    }

    getEmailByEmployeeId(ids: any) {
        if (ids && ids.length > 0) {
            let result = null;
            for (let id of ids) {
                const employee = this.allEmployeeList.find(el => el.id === id);
                if (employee && employee.email) { 
                    if (result) {
                        result = result + ', ' + employee.email; 
                    } else {
                        result = employee.email; 
                    }
                    
                }
            }
            return result;
        }
        return 'No Data';
    }

    showViewNoteDetailDialog = false;
    noteDetail: any = {};
    clickViewDetailNote(event: any, rowData: any) {
        this.showViewNoteDetailDialog = true;
        this.noteDetail = rowData;
        if ((this.noteDetail.content || '').length) {
            const matches = this.noteDetail.content.match(/https[^\s]+/g);
            const wMatches = this.noteDetail.content.match(/www.[^\s]+/g);
            if ((wMatches || []).length) {
                const matchesList = _.uniq(wMatches);
                matchesList.forEach((match: any) => {
                    const link = 'https://' + match;
                    this.noteDetail.content = this.noteDetail.content.concat(' ').replaceAll(match.concat(' '), `<a class="message-links"  target="_blank" href="${link}">${match}</a> `);
                    this.noteDetail.content = this.noteDetail.content.replaceAll(match.concat('\n'), `<a class="message-links"  target="_blank" href="${link}">${match}</a>\n`);
                });
            }
            if ((matches || []).length) {
                const matchesList = _.uniq(matches);
                matchesList.forEach((match: any) => {
                    const link = match;
                    this.noteDetail.content = this.noteDetail.content.concat(' ').replaceAll(match.concat(' '), `<a class="message-links"  target="_blank" href="${link}">${match}</a> `);
                    this.noteDetail.content = this.noteDetail.content.replaceAll(match.concat('\n'), `<a class="message-links"  target="_blank" href="${link}">${match}</a>\n`);
                });
            }
        }
    }

    photoURL: string;

    getProfileImage(id) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS' && resObj.data) {
                // console.log(resObj.data);
                this.photoURL = resObj.data.fileUrl;
            }
        }, error => { });
    }
    updateUrl(event) {
        this.photoURL = 'assets/images/logo.png';
    }

}


