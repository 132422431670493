import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from 'app/quote/service/quote.service';
import { CompanyServicev2 } from 'app/company/service/company.v2.service';
import { ClientService } from 'app/crm/service/client.service';
import { forkJoin, Subscription } from 'rxjs';
import { CompanyService } from 'app/company/service/company.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { PaymentInvoice } from 'app/payment/model/payment-invoice.model';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { FirebaseNotificationService } from 'app/shared/service/firebase-notification.service';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';
import { buildSurveyLandPdfFile } from 'app/quote/model/quote.model';
import moment from 'moment';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-quote-preview',
  templateUrl: './quote-preview.component.html',
  styleUrls: ['./quote-preview.component.scss'],
  providers: [QuoteService, CompanyServicev2, ClientService, DocumentsService, DocumentsServicev2, PaymentInvoiceService]
})
export class QuotePreviewComponent implements OnInit, OnDestroy {

  @Input() id;
  quoteDetails;
  clientDetails;
  companyDetails;
  processing;

  queryParamsSubscription: Subscription;
  companyEmail;

  rejectReasons = [
    {label: 'Price too high', value: 'Price too high'},
    {label: 'Wrong Address', value: 'Wrong Address'},
    {label: 'Quote not Clear', value: 'Quote not Clear'},
    {label: 'Other', value: 'Other'},
  ];

  rejectIndex;
  selectedRejectReason;
  rejectNote;
  showRejectDialog;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private quoteService: QuoteService,
    private companyService: CompanyService,
    private clientService: ClientService,
    private paymentInvoiceService: PaymentInvoiceService,
    private documentServiceV2: DocumentsServicev2,
    private firebaseNotificationService: FirebaseNotificationService,
    private companySettingService: CompanySettingServiceV2,
    private messageService: MessageService) {
      if (this.queryParamsSubscription) {
        this.queryParamsSubscription.unsubscribe();
      }
      this.queryParamsSubscription = this.route.queryParams.subscribe(p => {
        if (!this.id) {
          this.router.navigate([], { relativeTo: this.route, queryParams: { } });
          return;
        }
        if (p.quote >= 0) {
          this.accept(p.quote);
        } else if (p.reject >= 0) {
          this.reject(p.reject);
        } else if (p.invoiceNumber) {
          this.pay(p.invoiceNumber);
        }
      });
  }

  ngOnInit() {
    this.companySettingService.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
          if (res.data.employeeMissingPunchNotificationEmail) {
              this.companyEmail = res.data.employeeMissingPunchNotificationEmail;
          }
        }
  });
    this.loadData();
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  loadData() {
    this.quoteService.getById(this.id).subscribe(res => {
      const resObj: any = res;
      forkJoin([
        this.clientService.getClientById(resObj.data.clientId),
        this.companyService.getCompanyByCompanyId(resObj.data.companyId)
      ]).subscribe((response: any) => {
        this.quoteDetails = Object.assign({}, resObj.data);
        if (!this.quoteDetails.viewQuote && this.authService.isClientRole()) {
          const bodyEmail = `Hi Guys,<br><b>${this.authService.getUserInfo().name}</b> View quote number ${this.quoteDetails.quoteNumber}`;
          const payload = {
              toEmails: [this.companyEmail],
              subject: 'View Quote',
              body: bodyEmail,
              emailCompanyId: this.authService.getUserInfo().companyId,
          }
          this.quoteService.sendEmailQuote(payload).subscribe(() => {
            this.quoteDetails.viewQuote = true;
              this.quoteService.updateServiceRate(this.quoteDetails).subscribe();
          });
      }
        this.clientDetails = Object.assign({}, response[0].data);
        this.companyDetails = Object.assign({}, response[1].data);
        const doc = buildSurveyLandPdfFile(this.quoteDetails, this.companyDetails.name, this.clientDetails.email, this.clientDetails.phone);
        document.querySelector("iframe").src = window.URL.createObjectURL(doc.output("blob"));
      });
    });
  } 

  getAllFiles(options: any, index: number, quoteDetails) {
    this.documentServiceV2.searchFile(options)
      .subscribe(res => {
        const resObj: any = res;
        quoteDetails[index].documents = resObj.data.content;
        quoteDetails[index].showFileUpload = quoteDetails[index].documents.length > 0;
        for (let i = 0; i < quoteDetails[index].documents.length; i++) {
          quoteDetails[index].fileUploaded.push(quoteDetails[index].documents[i]);
        }
      });
  }

  accept(quoteIdx) {
    if (!this.quoteDetails || this.processing) {
      return;
    }
    this.processing = true;
    const details = this.quoteDetails.quoteDetails[quoteIdx];
    if (!details || details.invoiceNumber) {
      this.processing = false;
      return;
    }
    const paymentInvoice = new PaymentInvoice();
    paymentInvoice.companyId = this.quoteDetails.companyId;
    paymentInvoice.clientId = this.quoteDetails.clientId;
    paymentInvoice.userId = this.authService.getUserInfo().adminId;
    paymentInvoice.createdByUsr = this.authService.getCurrentUsername();
    paymentInvoice.userRoleId = this.authService.getRoleLevel();
    paymentInvoice.appliedCredit = false;
    paymentInvoice.invoiceType = 'Survey Land';
    paymentInvoice.invoiceDetails = [];
    paymentInvoice.totalAmount = 0;
    paymentInvoice.fee = details.totalAmount ? Number(details.totalAmount.toFixed(2)) : 0;
    paymentInvoice.quoteInvoice = true;
    this.paymentInvoiceService.create(paymentInvoice).subscribe((res: any) => {
      this.quoteDetails.quoteDetails[quoteIdx].invoiceId = res.data.id;
      this.quoteDetails.quoteDetails[quoteIdx].invoiceNumber = res.data.invoiceNumber;
      this.quoteDetails.quoteDetails[quoteIdx].statusPurchase = 'Approved';
      this.firebaseNotificationService.saveFirebaseNotification(`/generate-new-invoice/${this.authService.getCurrentCompanyId()}/${paymentInvoice.clientId}`, {
        ...paymentInvoice,
        updateTime: new Date().getTime()
      }).subscribe();
      this.firebaseNotificationService.saveFirebaseNotification(`${this.authService.getCurrentCompanyId()}/generate-new-invoice`, {
        ...paymentInvoice,
        updateTime: new Date().getTime()
      }).subscribe();
      this.quoteService.updateServiceRate(this.quoteDetails).subscribe(() => {
        const emailBody = `Hi Guys,<br><b>${this.authService.getUserInfo().name}</b> Approved quote number ${this.quoteDetails.quoteNumber}, an have Invoice #${res.data.invoiceNumber} ready.`
        this.sendEmail('Quote Approved', emailBody)
        const doc = buildSurveyLandPdfFile(this.quoteDetails, this.companyDetails.name, this.clientDetails.email, this.clientDetails.phone);
        document.querySelector("iframe").src = window.URL.createObjectURL(doc.output("blob"));
        this.processing = false;
      });
    }, () => this.processing = false)
  }

  reject(quoteIdx) {
    if (!this.quoteDetails) {
      return;
    }
    this.rejectIndex = quoteIdx;
    this.showRejectDialog = true;
  }

  pay(invoiceNumber) {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    setTimeout(() => {
      this.router.navigateByUrl('/app/payments/detail?invoiceNumber=' + invoiceNumber, {replaceUrl: true});
    }, 100);
    
  }

  sendEmail(subject, bodyEmail) {
    const payload = {
        toEmails: [this.companyEmail],
        subject: subject,
        body: bodyEmail,
        emailCompanyId: this.authService.getUserInfo().companyId,
    }
    this.quoteService.sendEmailQuote(payload).subscribe();
  }

  confirmReject() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    const details = this.quoteDetails.quoteDetails[this.rejectIndex];
    if (!details) {
      this.processing = false;
      return;
    }
    // const emailBody = `Hi Guys,<br><b>${this.authService.getUserInfo().name}</b> Rejected quote number ${this.quoteDetails.quoteNumber}.`
    // this.sendEmail('Quote Rejected', emailBody);
    this.quoteDetails.quoteDetails[this.rejectIndex].publicNotes = this.rejectNote;
    this.quoteDetails.quoteDetails[this.rejectIndex].statusPurchase = 'Rejected';
    this.quoteService.updateServiceRate(this.quoteDetails).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Quote updated successfully' });
      const currentDate = moment().format('MM/DD/YYYY HH:mm:ss');
      const emailBody  = `<div style="font-size: 13px;">
        <div>Date: ${currentDate}</div>
        <div>Client Name: ${this.authService.getUserInfo().name}</div>
        <div>Rejection Reason: ${this.selectedRejectReason}</div>
        <div>Quote #: ${this.quoteDetails.quoteNumber}</div>
        <div>Note: ${this.rejectNote}</div>
      </div>`;
      this.sendEmail('Quote Rejection', emailBody);
      
      this.rejectIndex = null;
      this.showRejectDialog = false;
      this.processing = false;
      this.router.navigate([], { relativeTo: this.route, queryParams: { } });
    }, () => this.processing = false);
  }

  cancelReject() {
    this.rejectIndex = null;
    this.selectedRejectReason = null;
    this.rejectNote = null;
    this.showRejectDialog = false;
    this.processing = false;
    this.router.navigate([], { relativeTo: this.route, queryParams: { } });
  }
}
