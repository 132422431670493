import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Note, NoteFolder } from "../model/note.model";
import { environment } from '../../../environments/environment';
import { Constants } from "../../shared/model/constants";
import { AuthService } from 'app/shared/service/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  baseUrl: string;
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.baseUrl = `${environment.v2_server_backend}/operation/api/v2/operation`;
  }
  getAllNote() {
    const userName = this.authService.getCurrentUsername();
    return this.httpClient.get(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}?user=${userName}`);
  }

  addFolder(name) {
    const userName = this.authService.getCurrentUsername();
    return this.httpClient.post(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}`, { name: name, createdByUsr: userName });
  }

  updateFolder(obj: NoteFolder) {
    return this.httpClient.put(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}`, { id: obj.id, name: obj.name });
  }

  deleteFolder(id) {
    return this.httpClient.delete(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}/${id}`);
  }

  addNote(folderId, folderName, name) {
    return this.httpClient.post(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}/${Constants.URI.NOTES.FILES}`, {
      noteFolderId: folderId,
      noteFolderName: folderName,
      name: name
    });
  }

  updateNote(obj: Note) {
    return this.httpClient.put(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}/${Constants.URI.NOTES.FILES}`, {
      id: obj.id,
      name: obj.name,
      content: obj.content,
      noteFolderId: 0
    });
  }

  deleteNote(id) {
    return this.httpClient.delete(`${this.baseUrl}/${Constants.URI.NOTES.NOTE}/${Constants.URI.NOTES.FILES}/${id}`);
  }
  shareNote(request) {
    const url = `${this.baseUrl}/${Constants.URI.NOTES.NOTE}/shares`
    return this.httpClient.post(url, request)
  }
  getShareNote() {
    const url = `${this.baseUrl}/${Constants.URI.NOTES.NOTE}/shares/${this.authService.getCurrentLoggedInId()}`
    return this.httpClient.get(url)
  }

  getSharedNotesByUser() {
    // const url = `http://localhost:8007/api/v2/operation/note/shared-notes/${this.authService.getCurrentUsername()}`
    const url = `${this.baseUrl}/${Constants.URI.NOTES.NOTE}/shared-notes/${this.authService.getCurrentUsername()}`
    return this.httpClient.get(url)
  }
}
