<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive, 'layout-sidebar-dark': app.darkMenu}"
     (click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false" style="top: 64px;">
  <!-- <div class="sidebar-logo">
    <a routerLink="/dashboard">
      <img alt="logo" src="assets/layout/images/logo-slim-new.png" style="width: 190px;" />
      <span class="app-name">Operr</span>
    </a>
    <a href="#" class="sidebar-anchor" title="Toggle Menu" (click)="app.onToggleMenuClick($event)"></a>
  </div> -->

  <p-scrollPanel #scrollPanel [style]="{height: '100%'}">
    <ul app-submenu [item]="model" root="true" class="layout-menu" [visible]="false" [reset]="reset"></ul>
  </p-scrollPanel>

</div>

