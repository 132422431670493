<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="spinner-overlay" *ngIf="isLoading">
	<p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<div class="container-fluid px-0 shadow d-flex align-items-center bg-darkblue" id="headerArea">
	<div class="container-lg m-auto text-center">
		<div class="row align-items-center justify-content-start">
			<div class="col-lg-12 text-left px-5">
				<div class="text-light" id="headerText">Create Account</div>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid px-0 shadow d-flex align-items-center pb-5">
	<div class="container-lg m-auto">
		<div class="ui-g mt-5">
			<div class="ui-g-12">
				<h1 class="m-0 ms-2 mt-4">Primary Contact</h1>
				<div class="ms-2 mb-4">The person you would like us to contact in case of issues.</div>
			</div>
		</div>
		<div class="card p-0">
			<div class="card-header">
				<span><i class="fa-solid fa-circle-plus text-primary me-2"></i>Contact Info</span>
			</div>
			<form [formGroup]="guessForm" #guessDataForm [name]="guessDataForm">
<!--				<div class="ui-g p-4 pb-0" style="text-align: center; display: inline;">-->
<!--					<img src="../../../assets/images/logo.png" style="width: 135px;height: auto;"><br>-->
<!--					<h1 class="m-0" translate>{{'Create an Account' | translate}}</h1>-->
<!--				</div>-->
				<div class="ui-g p-4">
					<div class="ui-g-12">
						<div *ngIf="guessForm.get('email').errors?.userTaken" class="alert alert-danger alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}: </strong> {{'EmailExistingInSystem' | translate}}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
						<div *ngIf="guessForm.get('username').errors?.userTaken" class="alert alert-danger alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}: </strong> {{'validate.thisFieldNotAvailable' | translate:{value: 'username' | translate} }}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
						<div *ngIf="guessForm.controls['password'].errors?.pattern && guessForm.controls['password'].dirty"  class="alert alert-warning alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}: </strong> {{'password_pattern_required' | translate}}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'firstName' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['first_name'].invalid)&& guessForm.controls['first_name'].touched && (guessForm.controls['first_name'].hasError('required'))" style="font-size: 12px">{{'Required' | translate:{value: 'firstName' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<input type="text" pInputText formControlName="first_name" [(ngModel)]="guess.firstName" placeholder="{{'firstName' | translate}}">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'lastName' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['last_name'].invalid) && guessForm.controls['last_name'].touched && (guessForm.controls['last_name'].hasError('required'))" style="font-size: 12px">{{'Required' | translate:{value: 'lastName' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-lock"></i>
								</button>
							</div>
							<input type="text" pInputText placeholder="{{'lastName' | translate}}" formControlName="last_name" [(ngModel)]="guess.lastName">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="email">{{'Email' | translate}} <span class="required-indicator">* <span class="text-danger" *ngIf="guessForm.controls['email'].errors?.pattern && guessForm.controls['email'].dirty" style="font-size: 12px">{{'Required' | translate:{value: 'Email' | translate} }}</span> </span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-at"></i>
								</button>
							</div>
							<input type="text" pInputText formControlName="email" [(ngModel)]="guess.email" placeholder="example@operr.com">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'username' | translate}} (8 chars min)<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['username'].invalid)&& guessForm.controls['username'].touched && (guessForm.controls['username'].hasError('required'))" style="font-size: 12px">{{'Required' | translate:{value: 'username' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user-shield"></i>
								</button>
							</div>
							<input type="text" pInputText formControlName="username" [(ngModel)]="guess.username" autocomplete="off"  placeholder="{{'username' | translate}}" (keydown.space)="$event.preventDefault()">
						</div>
						<span class="text-danger" *ngIf="guessForm.get('username').errors?.minLength" style="font-size: 12px">{{'validate.minlength' | translate:{value: 8} }}</span>
					</div>

					<div class="ui-g-6 ui-sm-12">
						<label>{{'password' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['password'].invalid)  && guessForm.controls['password'].touched && (guessForm.controls['password'].hasError('required'))" style="font-size: 12px">{{'Required' | translate:{value: 'password' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-lock"></i>
								</button>
							</div>
							<input *ngIf="showPwd" pInputText type="text" id="password" formControlName="password" [(ngModel)]="guess.password" name="password" placeholder="{{'Password' | translate}}" class="w-100" />
							<input *ngIf="!showPwd" pInputText type="password" id="password" formControlName="password" [(ngModel)]="guess.password" name="password" placeholder="{{'Password' | translate}}" class="w-100" />
							<div class="input-group-append">
								<button *ngIf="!showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-eye text-primary"></i>
								</button>
								<button *ngIf="showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-eye-slash text-danger"></i>
								</button>
							</div>
						</div>
					</div>

					<div class="ui-g-6 ui-sm-12">
						<label>{{'Country Code' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="guessForm.controls['phone_code'].invalid && guessForm.controls['phone_code'].touched && guessForm.controls['phone_code'].hasError('required')" style="font-size: 12px">{{'Required' | translate:{value: 'Code' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-hashtag"></i>
								</button>
							</div>
							<p-dropdown [options]="listOfPhoneCode" [filter]="true" formControlName="phone_code" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="guess.phoneCode" >
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
					</div>

					<div class="ui-g-6 ui-sm-12">
						<label>{{'PhoneNumber' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['phone'].invalid)&& guessForm.controls['phone'].touched && (guessForm.controls['phone'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'PhoneNumber' | translate} }}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-mobile-phone"></i>
								</button>
							</div>
							<p-inputMask mask="(999)999-9999" formControlName="phone" placeholder="{{'PhoneNumber' | translate}}" [(ngModel)]="guess.phone" class="w-100"></p-inputMask>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Service Type' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['serviceTypes'].invalid)&& guessForm.controls['serviceTypes'].touched && (guessForm.controls['serviceTypes'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'Service Type' | translate} }}</span></span></label>
						<app-company-service-type formControlName="serviceTypes" name="serviceTypes" [(ngModel)]="guess.serviceTypes"></app-company-service-type>
					</div>

					<!--		<div class="ui-g-6 ui-sm-12">-->
					<!--			<label>{{'dateOfBirth' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['date_of_birth'].invalid)&& guessForm.controls['date_of_birth'].touched && (guessForm.controls['date_of_birth'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'dateOfBirth' | translate} }}</span></span></label>-->
					<!--			<div class="input-group w-100">-->
					<!--				<div class="input-group-prepend p-0">-->
					<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
					<!--						<i class="fa-solid fa-calendar-day"></i>-->
					<!--					</button>-->
					<!--				</div>-->
					<!--				<mat-form-field class="w-100">-->
					<!--					<input class="w-100" matInput (focus)="picker.open()" [(ngModel)]="dateOfBirth"  [max]="maxDate" formControlName="date_of_birth" [matDatepicker]="picker" name="dateOfBirth">-->
					<!--					<mat-datepicker #picker></mat-datepicker>-->
					<!--				</mat-form-field>-->
					<!--			</div>-->
					<!--		</div>	-->

					<!--		<div class="ui-g-6 ui-sm-12">-->
					<!--			<label>{{'gender' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['gender'].invalid)&& guessForm.controls['gender'].touched && (guessForm.controls['gender'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'gender' | translate} }}</span></span></label>-->
					<!--			<div class="input-group w-100">-->
					<!--				<div class="input-group-prepend p-0">-->
					<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
					<!--						<i class="fa-solid fa-mars-and-venus"></i>-->
					<!--					</button>-->
					<!--				</div>-->
					<!--				<p-dropdown [options]="genders" formControlName="gender" placeholder="{{'Select One' | translate}}" [(ngModel)]="guess.gender">-->
					<!--					<ng-template pTemplate="item" let-item>-->
					<!--						<span class="text-truncate">{{item.label | translate}}</span>-->
					<!--					</ng-template>-->
					<!--					<ng-template let-item pTemplate="selectedItem">-->
					<!--						<span class="one-liner">{{item.label | translate}}</span>-->
					<!--					</ng-template>-->
					<!--				</p-dropdown>-->
					<!--			</div>-->
					<!--		</div>	-->

					<!--		<div class="ui-g-6 ui-sm-12">-->
					<!--			<label>{{'referenceBy' | translate}}<span class="required-indicator">*</span></label>-->
					<!--			<div class="input-group w-100">-->
					<!--				<div class="input-group-prepend p-0">-->
					<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
					<!--						<i class="fa-solid fa-user"></i>-->
					<!--					</button>-->
					<!--				</div>-->
					<!--				<input type="text" pInputText formControlName="referenceBy" [(ngModel)]="guess.referenceBy" placeholder="{{'referenceBy' | translate}}">-->
					<!--			</div>-->
					<!--		</div>	-->

					<!--		<div class="ui-g-6 ui-sm-12">-->
					<!--			<label>{{'Account Type' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['accountType'].invalid)&& guessForm.controls['accountType'].touched && (guessForm.controls['accountType'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'Account Type' | translate} }}</span></span></label>-->
					<!--			<div class="input-group w-100">-->
					<!--				<div class="input-group-prepend p-0">-->
					<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
					<!--						<i class="fa-solid fa-folder-tree"></i>-->
					<!--					</button>-->
					<!--				</div>-->
					<!--				<p-dropdown [options]="accountTypeList" formControlName="accountType" placeholder="{{'Select One' | translate}}" [(ngModel)]="guess.accountType"  (onChange)="onChangeAccountType($event)">-->
					<!--					<ng-template pTemplate="item" let-item>-->
					<!--						<span class="text-truncate">{{item.label | translate}}</span>-->
					<!--					</ng-template>-->
					<!--					<ng-template let-item pTemplate="selectedItem">-->
					<!--						<span class="one-liner">{{item.label | translate}}</span>-->
					<!--					</ng-template>-->
					<!--				</p-dropdown>-->
					<!--			</div>-->
					<!--		</div>	-->
				</div>
				<!--	<div class="ui-g px-4">-->
				<!--		<div class="ui-g-12 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'Service Type' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['serviceTypes'].invalid)&& guessForm.controls['serviceTypes'].touched && (guessForm.controls['serviceTypes'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'Service Type' | translate} }}</span></span></label>-->
				<!--			<app-company-service-type formControlName="serviceTypes" name="serviceTypes" [(ngModel)]="guess.serviceTypes"></app-company-service-type>-->
				<!--		</div>-->
				<!--		<div class="ui-g-12 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'Service Plan' | translate}}<span class="required-indicator">* <span class="text-danger" *ngIf="(guessForm.controls['servicePlan'].invalid)&& guessForm.controls['servicePlan'].touched && (guessForm.controls['servicePlan'].hasError('required'))" style="font-size: 12px">{{'validate.required' | translate:{value: 'Service Plan' | translate} }}</span></span></label>-->
				<!--			<div class="input-group w-100">-->
				<!--				<div class="input-group-prepend p-0">-->
				<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--						<i class="fa-solid fa-folder-tree"></i>-->
				<!--					</button>-->
				<!--				</div>-->
				<!--				<p-dropdown [options]="servicePlanList" formControlName="servicePlan" placeholder="{{'Select One' | translate}}" [(ngModel)]="guess.servicePlan">-->
				<!--					<ng-template pTemplate="item" let-item>-->
				<!--						<span class="text-truncate">{{item.label | translate}}</span>-->
				<!--					</ng-template>-->
				<!--					<ng-template let-item pTemplate="selectedItem">-->
				<!--						<span class="one-liner">{{item.label | translate}}</span>-->
				<!--					</ng-template>-->
				<!--				</p-dropdown>-->
				<!--			</div>-->
				<!--		</div>-->
				<!--		<hr>-->
				<!--		<div class="ui-g-6 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'Street Address' | translate}}<span class="required-indicator">*</span></label>-->
				<!--			<div class="input-group w-100">-->
				<!--				<div class="input-group-prepend p-0">-->
				<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--						<i class="fa-solid fa-signs-post"></i>-->
				<!--					</button>-->
				<!--				</div>-->
				<!--				<input type="text" pInputText formControlName="addressOne" [(ngModel)]="guess.addressOne" class="w-100" placeholder="1 Example Street, Apt. 3D">-->
				<!--			</div>-->
				<!--		</div>-->

				<!--		<div class="ui-g-6 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'form.city' | translate}}<span class="required-indicator">*</span></label>-->
				<!--			<div class="input-group w-100">-->
				<!--				<div class="input-group-prepend p-0">-->
				<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--						<i class="fa-solid fa-map-location-dot"></i>-->
				<!--					</button>-->
				<!--				</div>-->
				<!--				<input type="text" pInputText formControlName="city" [(ngModel)]="guess.city" placeholder="{{'form.city' | translate}}">-->
				<!--			</div>-->
				<!--		</div>-->

				<!--		<div class="ui-g-6 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'form.state' | translate}}<span class="required-indicator">*</span></label>-->
				<!--			<div class="input-group w-100">-->
				<!--				<div class="input-group-prepend p-0">-->
				<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--						<i class="fa-solid fa-map"></i>-->
				<!--					</button>-->
				<!--				</div>-->
				<!--				<input type="text" pInputText formControlName="state" [(ngModel)]="guess.state" placeholder="{{'form.state' | translate}}">-->
				<!--			</div>-->
				<!--		</div>-->

				<!--		<div class="ui-g-6 ui-sm-12" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<label>{{'form.zipCode' | translate}}<span class="required-indicator">*</span></label>-->
				<!--			<div class="input-group w-100">-->
				<!--				<div class="input-group-prepend p-0">-->
				<!--					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--						<i class="fa-solid fa-location-dot"></i>-->
				<!--					</button>-->
				<!--				</div>-->
				<!--				<input type="text" pInputText formControlName="zipcode" oninput="this.value=this.value.replace(/[^0-9]/g,'').slice(0, 5);" placeholder="12345" pattern="\d{5}" [(ngModel)]="guess.zipcode"> -->
				<!--			</div>-->
				<!--		</div>-->

				<!--		<div class="ui-g-12 ui-sm-12 p-4" *ngIf="guess.accountType === 'Merchant'">-->
				<!--			<p-panel [toggleable]="true">-->
				<!--				<p-header class="flex-grow-1">-->
				<!--					<span><i class="fa-solid fa-credit-card text-primary mr-2"></i>{{'Credit/Debit Card' | translate}}</span>-->
				<!--				</p-header>-->
				<!--				<div class="ui-g pb-3">-->
				<!--					<div class="ui-g-12">-->
				<!--						<h2 class="m-0 ml-2 py-3">{{'Choose Card Type' | translate}}</h2>-->
				<!--					</div>-->
				<!--					<div class="ui-g-6 ui-sm-12">-->
				<!--						<div class="input-group w-100">-->
				<!--							<div class="input-group-prepend p-0">-->
				<!--								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--									<p-radioButton (click)="clickChangeData('Visa Card')" formControlName="paymentGroup" name="paymentGroup" value="VISA" [(ngModel)]="paymentCard" #visa></p-radioButton>-->
				<!--								</button>-->
				<!--							</div>-->
				<!--							<div class="form-control d-flex align-items-center">-->
				<!--								<img src="/assets/payment/visa-straight-128px.png" class="mr-2" style="width:30px;height:auto">-->
				<!--								<span class="one-liner">{{'Visa Card' | translate}}</span>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--					<div class="ui-g-6 ui-sm-12">-->
				<!--						<div class="input-group w-100">-->
				<!--							<div class="input-group-prepend p-0">-->
				<!--								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--									<p-radioButton (click)="clickChangeData('MasterCard')" formControlName="paymentGroup" name="paymentGroup" value="MASTER_CARD" [(ngModel)]="paymentCard" #master_card></p-radioButton>-->
				<!--								</button>-->
				<!--							</div>-->
				<!--							<div class="form-control d-flex align-items-center">-->
				<!--								<img src="/assets/payment/mastercard-straight-128px.png" class="mr-2" style="width:30px;height:auto">-->
				<!--								<span class="one-liner">{{'MasterCard' | translate}}</span>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--					<div class="ui-g-6 ui-sm-12">-->
				<!--						<div class="input-group w-100">-->
				<!--							<div class="input-group-prepend p-0">-->
				<!--								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--									<p-radioButton (click)="clickChangeData('American Express')" formControlName="paymentGroup" name="paymentGroup" value="AMERICAN_EXPRESS" [(ngModel)]="paymentCard" #american_express></p-radioButton>-->
				<!--								</button>-->
				<!--							</div>-->
				<!--							<div class="form-control d-flex align-items-center">-->
				<!--								<img src="/assets/payment/american-express-straight-128px.png" class="mr-2" style="width:30px;height:auto">-->
				<!--								<span class="one-liner">{{'American Express' | translate}}</span>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--					<div class="ui-g-6 ui-sm-12">-->
				<!--						<div class="input-group w-100">-->
				<!--							<div class="input-group-prepend p-0">-->
				<!--								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
				<!--									<p-radioButton (click)="clickChangeData('Discover Card')" formControlName="paymentGroup" name="paymentGroup" value="DISCOVER" [(ngModel)]="paymentCard" #discover></p-radioButton>-->
				<!--								</button>-->
				<!--							</div>-->
				<!--							<div class="form-control d-flex align-items-center">-->
				<!--								<img src="/assets/payment/discovery-straight-128px.png" class="mr-2" style="width:30px;height:auto">-->
				<!--								<span class="one-liner">{{'Discover Card' | translate}}</span>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</div>-->

				<!--				<app-visa-card *ngIf="paymentCard === 'VISA' || paymentCard === 'MASTER_CARD' || paymentCard === 'DISCOVER'"-->
				<!--					[paymentCardType]="paymentCard" (displayChange)="reset($event)" (getDataSave)="getDataSave($event)"-->
				<!--					[formatCard]="'9999 9999 9999 9999'" [key]="generatedKey" [functionImport]="'SIGN_IN'">-->
				<!--				</app-visa-card>-->
				<!--				<app-amex-card *ngIf="paymentCard === 'AMERICAN_EXPRESS'"[paymentCardType]="paymentCard"-->
				<!--					(displayChange)="reset($event)" (getDataSave)="getDataSave($event)"-->
				<!--					[key]="generatedKey" [functionImport]="'SIGN_IN'">-->
				<!--				</app-amex-card>-->
				<!--			</p-panel>-->
				<!--		</div>-->
				<!--		<div class="ui-g-12 text-center py-4" style="text-align: center;">-->
				<!--			By signing up, you agree to the <a href="javascript:void(0);" (click)="showPolicyClick()" class="text-primary">Terms of Service</a> & <a href="javascript:void(0);" (click)="showPolicyClick()" class="text-primary">Privacy Policy</a>-->
				<!--			<br>-->
				<!--			Already have an Account? <a (click)="backToLogin()" href="javascript:void(0);" class="text-primary">{{'sign_in' | translate}}</a>-->
				<!--		</div>-->
				<!--	</div>-->

<!--				<div class="card-footer p-4 d-flex align-items-center justify-content-between">-->
<!--					<a [routerLink]="['/login']" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</a>-->
<!--					<button type="button" (click)="createAdmin(guessDataForm)"  class="btn btn-primary mx-1"><span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</span></button>-->
<!--				</div>-->
			</form>
		</div>
		<div class="ui-g">
			<div class="ui-g-12 text-center py-5">
				<a class="btn btn-danger btn-lg mx-1" [routerLink]="['/login']">
					<span><i class="fa-solid fa-xmark me-2"></i>Cancel</span>
				</a>
				<a class="btn btn-primary btn-lg mx-1" (click)="createAdmin(guessDataForm)">
					<span><i class="fa-solid fa-check me-2"></i>Continue<i class="fa-solid fa-chevron-right ms-2"></i></span>
				</a>
			</div>
		</div>
	</div>
</div>




<p-dialog [showHeader]="true" *ngIf="showPolicy" [(visible)]="showPolicy" modal="modal" [dismissableMask]="true" showEffect="fade" class="deparment-history" [style]="{'width':'80%'}">
    <p-header>
        <span><i class="fa-solid fa-info mr-2"></i>Terms and Privacy Policy</span>
    </p-header>
    <h2>TERMS OF SERVICE / USER AGREEMENT</h2>
    <h3>
        Terms and Pronouns
    </h3>
    <div>
        The following terms shall be applicable throughout the entirety of this Agreement.
        Us, Our, Ours, We and Operr Work refer to Operr Work Inc. and Operr Work Technologies.
        You, Yours, Merchant(s), User(s), Buyer(s), Member(s) and Customer(s) refers to anyone legitimately using the Operr Work platform.
        The Mobile Application Terms of Use, all terms and additional policies published on and in our Services are incorporated into this User Agreement. Please read our Terms of Service so you understand what’s up with your use of Operr Work. You agree to our Terms of Service (“Terms”) by installing, accessing, or using our apps, Services, features, software, or website (Collectively, “Services”).
    </div>
    <ul>
        <li>Who you are?</li>
        <li>What matters to you.</li>
        <li>What you do.</li>
        <li>How you do it.</li>
    </ul>
    <h3>I. Overview</h3>
    <div>
        Our Operr Work Rules or The Terms of Use combine all documents/sections/rules that are listed below. The Operr Work Terms of Use (Operr Work Terms for short) is a legally binding contract between you and Operr Work. Once you have activities, Pay or participation under any means, you will be bound by Operr Work Terms.
        Operr Work Terms will outline your duties and your rights when you participate on Operr Work or use any Services that Operr Work provides. By participating on Operr Work under any means or using any of our Services, you are agreeing to these terms.
    </div>
    <div>
        In the event that you disagree with any of Our Terms contained herein, then you must cease using Operr Work.
    </div>
    <div>
        Please read the agreement carefully as our Operr Work Terms Section entitle Disputes, defines the way we, at Bynfor, handle any dispute you may have with Bynfor. In Section Disputes, by agreeing with Operr Work Terms and using Bynfor service, you agree to resolve all disputes through binding individual arbitration, which means that you agree to bring claims against Operr Work in your one and only individual capacity; you will waive any right to have those disputes decided by a judge or jury, which means you cannot bring claims against Operr Work as plaintiff or member of a class action, and for that, you agree to waive your right to participate in class actions, class arbitration, or representative action.
    </div>
</p-dialog>
