import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DocumentsService} from '../../../shared/service/documents-upload/documents.service';
import {TransactionFileHistoryService} from '../../service/transaction-file-history.service';

@Component({
  selector: 'app-transaction-file-history',
  templateUrl: './transaction-file-history.component.html',
  styleUrls: ['./transaction-file-history.component.scss']
})
export class TransactionFileHistoryComponent implements OnChanges {

  @Input() data: string;
  @Input() transactionDetailId: number;

  historiesData: any;
  loading: boolean = false;
  totalRecords: any;

  displayColumns: any[] = [
    {field: 'createdAt', label: 'Uploaded', sortable: false},
    {field: 'createdByUsr', label: 'User', sortable: false},
    {field: 'fileName', label: 'Filename', sortable: false},
    {field: 'action', label: 'Action', sortable: false}
  ];

  showViewFileDialog = false;
  fileUrl: any;

  constructor(private transactionFileHistoryService: TransactionFileHistoryService,
              private documentsService: DocumentsService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDataSource();
  }

  loadDataSource(event?: any) {
    if (this.data) {
      this.historiesData = this.data || [];
      this.totalRecords = this.historiesData.length;
    } else {
      const options: any = {
        sortOrder: 'DESC',
        sortField: 'createdAt',
        size: event && event.rows ? event.rows : 10,
        page: event && event.first && event.rows ? (event.first / event.rows) : 0,
      };
      this.loading = true;
      this.transactionFileHistoryService.getFileHistories(this.transactionDetailId, options).subscribe(res => {
        this.loading = false;
        const rspObj = res;
        if (rspObj.status === 'SUCCESS') {
          this.historiesData = rspObj.data.content || [];
          this.totalRecords = this.historiesData.length;
        }
      }, () => {
        this.loading = false;
      });
    }
  }

  onViewDocument(fileUrl, fileId) {
    if (fileUrl) {
      if (fileUrl.endsWith('pdf') || fileUrl.endsWith('txt')
          || fileUrl.endsWith('docx') || fileUrl.endsWith('doc')
          || fileUrl.endsWith('xls') || fileUrl.endsWith('xlsx')) {
        window.open(fileUrl);
      } else {
        this.fileUrl = fileUrl;
        this.showViewFileDialog = true;
      }
    } else {
      this.documentsService.getUploadedFile(fileId).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          if (resObj.data.fileType === 'pdf' || resObj.data.fileType === 'txt'
              || resObj.data.fileType === 'docx' || resObj.data.fileType === 'doc'
              || resObj.data.fileType === 'xls' || resObj.data.fileType === 'xlsx') {
            window.open(resObj.data.fileUrl);
          } else {
            this.fileUrl = resObj.data.fileUrl;
            this.showViewFileDialog = true;
          }
        }
      }, () => {});
    }
  }
}
