import { CrmClient } from 'app/crm/model/base';
import { Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { Email } from '../../model/email';
import { EmployeeService } from '../../../employee/service/employee.service';
import { Employee } from '../../../employee/model/employee.model';
import { Message, MessageService } from 'primeng/api';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Location } from '@angular/common';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { ClientService } from 'app/crm/service/client.service';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { Freelancer } from 'app/crm/model/freelancer.model';
import { EmailTemplateService } from 'app/crm/service/email-template.service';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';
import { Editor } from 'primeng';
import { TranslatePipe } from "@ngx-translate/core";
import { forkJoin } from 'rxjs';
import { ProductService } from 'app/crm/service/product.service';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { Router } from '@angular/router';
import { SummernoteOptions } from 'ngx-summernote/lib/summernote-options';
import { DomSanitizer } from '@angular/platform-browser';
import { PotentialClientService } from 'app/crm/campaign/service/potential-client.service';
import { VoterListService } from 'app/electoral-campaign/voter-list/service/voter-list.service';
import { VrmGroupService } from 'app/electoral-campaign/vrm-group/service/vrm-group.service';
declare var $;
@Component({
  selector: 'app-app-email',
  templateUrl: './app-email.component.html',
  styleUrls: ['./app-email.component.scss'],
  providers: [VrmGroupService]
})
export class AppEmailComponent implements OnInit {

  title = "Email";
  body = "Body";
  email: Email = new Email();
  suggestionsForBaseEmail = <any>[];
  suggestionsForBaseEmailFullList = <any>[];
  toEmail: any = [];
  ccEmail: any = [];
  msgs: Message[] = [];
  @ViewChild('item', { static: true }) item: AutoComplete;
  @ViewChild('item1', { static: true }) item1: AutoComplete;
  uploadedFiles: any[] = [];
  uploadedUrls: any[] = [];
  uploadedIds: any[] = [];
  isClient: boolean = false;
  isClicked: boolean;
  @ViewChild('messeditor', { static: true })
  messeditor: Editor;
  MESS_MAX_LENGTH = 6000;
  messMaxLengthInvalid: boolean = false;

  type = { value: 'Individual', label: 'Individual' };
  typeList = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Bulk', label: 'Bulk' }
  ];

  group: any;
  groupList = [
    { value: 'Client', label: 'Client' },
    { value: 'Employee', label: 'Employee' },
    { value: 'Contractor', label: 'Contractor' },
    { value: 'Voter', label: 'Voter' },
    { value: 'Group', label: 'Group' },
    { value: 'Lead', label: 'Lead' }
  ];

  dataMenu = [
    { name: 'Sent', value: 0 },
    { name: 'Failure', value: 0 }
  ];

  isBulk: boolean = false;
  fillAllEmployeeEmailLoading: boolean = false;
  isPlatformAdmin: Boolean = false;

  toEmailGroupEmployeeList: any[] = [];
  toEmailGroupClientList: any[] = [];
  toEmailGroupContractorList: any[] = [];
  toEmailGroupVoterList: any[] = [];
  toEmailGroupVrmList: any[] = [];
  toEmailGroupLeadList: any[] = [];


  toEmailObjectGroupEmployeeList: any[] = [];
  toEmailObjectGroupClientList: any[] = [];
  toEmailObjectGroupContractorList: any[] = [];
  toEmailObjectGroupVoterList: any[] = [];
  toEmailObjectGroupVrmList: any[] = [];
  toEmailObjectGroupLeadList: any[] = [];

  emailTemplate: any;
  emailTemplateList: any = [];
  selectedCompany: any;

  getEmailTemplateListLoading: boolean = false;

  charactersNumber = 0;
  onlyWhitespaceError = false;

  toEmailTemp: any;
  ccEmailTemp: any;
  initLoading = false;

  isClientGroup: boolean = false;

  invoiceStatusList = [
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Partially Paid', value: 'Partial Paid' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Pending', value: 'Pending' }
  ];
  selectedInvoiceStatus: string[];

  productList = [];
  selectedProducts = [];
  selectedProductService: any;
  clientInvoiceMap: { [key: number]: Set<string> } = {};
  clientDataList = [];
  allListUserHaveEmails = [];
  allListUserObjectHaveEmails = [];
  emailInput: any[] = [];
  emailTokens: string[] = [];
  ccEmailInput: any[] = [];
  ccEmailTokens: any[] = [];

  isVoterGroup: boolean = false;
  selectedParties;
  selectedADs;
  selectedEDs;

  parties = [
    { label: 'Democrat', value: 'Democratic' },
    { label: 'Republican', value: 'Republican' },
    { label: 'Conservative', value: 'Conservative' },
    { label: 'Working Families', value: 'Working Families' },
    { label: 'Other', value: 'Other' },
    { label: 'No party', value: 'No party' },
  ];
  adListOptions = [];
  edListOptions = [];
  allVoterList: any[] = [];
  voterFilteredList: any[] = [];
  totalRecords = 0;

  cols: any[] = [
    { label: 'Priority', field: 'priority', sortOptions: '', sort: 'text', width: '9%' },
    { label: 'Recipient', field: 'destinationUserName', sortOptions: '', sort: 'number', width: '25%' },
    { label: 'Message', field: 'message', sortOptions: '', sort: 'text' },
    { label: 'Creator', field: 'createdByUsr', sortOptions: '', sort: 'text', width: '11%' },
    { label: 'Created', field: 'date', sortOptions: '', sort: 'number', width: '9%' },
    { label: 'Action', field: 'Action', width: '8%' },
  ];
  mailList: any;
  allMail = {
    sent: [],
    fail: [],
  };
  selectedMail = false;
  selectedMailData: any = {};
  loading = true;
  totalElement = 0;
  groupNames = [];
  groupOptions = [];
  selectedGroupName;
  receipentList = [];
  emailTemplateSectionsContentList: any[] = [];

  @ViewChild('previewTemplate') previewTemplate: ElementRef;
  summernoteOptions: SummernoteOptions = {
    height: 280,
    toolbar: [
      ['misc', ['undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['view', ['fullscreen', 'codeview', 'help']],
      ['customButtons', ['testBtn']]
    ],
    buttons: {
      testBtn: customButton
    },
  }

  isEmployee: Boolean = false;

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private notificationService: DocumentsServicev2,
    private documentService: DocumentsService,
    private _location: Location,
    private messageService: MessageService,
    private clientService: ClientService,
    private freelancerService: FreelancerService,
    private emailTemplateService: EmailTemplateService,
    private translatePipe: TranslatePipe,
    private companySettingServiceV2: CompanySettingServiceV2,
    private voterService: VoterListService,
    private vrmGroupService: VrmGroupService,
    private productService: ProductService,
    private router: Router,
    private paymentInvoiceService: PaymentInvoiceService,
    private sanitizer: DomSanitizer,
    private potentialClientService: PotentialClientService,
  ) { }

  ngOnInit() {
    this.isEmployee = this.authService.isEmployeeRole();
    if (this.isEmployee) {
      this.groupList = [
        { value: 'Client', label: 'Client' },
        { value: 'Lead', label: 'Lead' }
      ];
    }

    this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    if (!(this.authService.isSuper() || this.authService.isSubSuper())) {
      this.selectedCompany = this.authService.getCurrentCompany();
    }

    this.isPlatformAdmin = this.authService.adminRole();
    this.isClient = this.authService.isClientRole() || this.authService.isChildClientRole() || this.authService.isContractorRole();
    this.getAllEmails();
    this.getCurrentLoggedUser();

    if (this.isClient) {
      this.toEmail = [{ key: 'support@operr.com', email: 'support@operr.com' }];
      this.getSupportEmail();
    }
    this.getEmailTemplateList();
    this.getProductList();
    this.paymentInvoiceService.getClientInvoiceStatus({
      companyId: this.authService.getCurrentCompanyId(),
      oldInvoiceOnly: false,
      ignoreInvoiceDetails: true
    }).subscribe((getClientInvoiceStatusRes: any) => {
      if (getClientInvoiceStatusRes && getClientInvoiceStatusRes.data) {
        this.clientInvoiceMap = getClientInvoiceStatusRes.data.reduce((result, item) => {
          const clientId = item.clientId;
          if (!clientId) {
            return result;
          }
          if (!result[clientId]) {
            result[clientId] = new Set();
          }
          const status = item.status;
          result[clientId].add(status);
          return result;
        }, {});
      }
    });
  }

  getCurrentLoggedUser() {
    let userInfo = this.authService.getUserInfo();
    if (userInfo.email != "") {
      this.email.from = userInfo.email;
    }
    else
      this.email.from = "noreply@operr.com";
  }

  onUpload(event) {
    this.uploadedUrls = [];
    this.uploadedIds = [];
    this.uploadedFiles = [];

    if (event && event.files && event.files.length > 0) {
      let check = 0;
      for (const file of event.files) {
        if (file.type === 'application/x-javascript') {
          check = check + 1;
          break;
        }
      }
      if (check > 0) {
        this.messageService.add({
          key: 'br', severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: 'Invalid Attach File', sticky: true
        });
        return;
      }
    }

    // for (const file of event.files) {
    //   this.documentService.uploadFile(file, 'attachment', 1, 'Email').subscribe(res => {
    //     const resObj: any = res;
    //     if (resObj.status === 'SUCCESS') {
    //       this.uploadedFiles.push(file);
    //         this.messageService.add({key: 'br', severity: 'success',
    //             summary: this.translatePipe.transform('Success'),
    //             detail: this.translatePipe.transform('Image uploaded successfully') , sticky: true });
    //       this.uploadedUrls.push(resObj.data.fileUrl);
    //       this.uploadedIds.push(resObj.data.id);
    //     }
    //   });
    //  }

    this.notificationService.uploadMultiFile(event.files, 'attachment', this.authService.getCurrentCompanyId(), 'Email').subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const uploadMultiFileData: any[] = resObj.data;
        for (const file of event.files) {
          this.uploadedFiles.push(file);
        }
        for (const file1 of uploadMultiFileData) {
          this.uploadedUrls.push(file1.fileUrl);
          this.uploadedIds.push(file1.id);
        }
        this.messageService.add({
          key: 'br', severity: 'success',
          summary: this.translatePipe.transform('Success'),
          detail: this.translatePipe.transform('File uploaded successfully')
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Upload files failed' });
      }
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Upload files failed' });
    });
  }

  sendEmail() {
    console.log('sendEmail allListUserHaveEmails: ', this.allListUserHaveEmails);
    this.isClicked = true;
    this.sendMail();
  }

  sendMail() {
    this.messageService.clear();
    if (this.messMaxLengthInvalid) {
      this.isClicked = false;
      this.messageService.add({
        key: 'br', severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('You have exceeded the maximum text limit of 6000 characters'), sticky: true
      });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
      return;
    }
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.emailInput && this.emailInput.length > 0) {
      for (let e of this.emailInput) {
        if (e && (<any>e).email) {
          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test((<any>e).email))) {
            this.isClicked = false;
            this.messageService.add({
              key: 'br', severity: 'error',
              summary: this.translatePipe.transform('Error'),
              detail: this.translatePipe.transform('email_format_should_be'), sticky: true
            });
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            return;
          }
        }
      }
    }

    if (this.ccEmailTemp && this.ccEmailTemp.length > 0) {
      if (!this.ccEmailTemp.match(mailformat)) {
        this.isClicked = false;
        this.messageService.add({
          key: 'br', severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('send_copy_to_email_format_should_be'), sticky: true
        });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
        return;
      }
    }
    this.email.attach_file_url = [];
    this.email.to = '';

    this.email.toEmails = [];

    if (this.isBulk) {
      if (this.group && this.group.value) {

        this.email.toEmails = this.allListUserHaveEmails;
        this.email.toEmailObjects = this.allListUserObjectHaveEmails;
        // if (this.group.value === 'Employee') {
        //   this.email.toEmails = this.toEmailGroupEmployeeList;
        // } else if (this.group.value === 'Client') {
        //   this.email.toEmails = this.toEmailGroupClientList;
        // } else if (this.group.value === 'Contractor') {
        //   this.email.toEmails = this.toEmailGroupContractorList;
        // } else if (this.group.value === 'Voter') {
        //   this.email.toEmails = this.toEmailGroupVoterList;
        // } else if (this.group.value === 'Group') {
        //   this.email.toEmails = this.toEmailGroupVrmList;
        // }
      } else {
        this.isClicked = false;
        this.messageService.add({
          key: 'br', severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('Please select emailing group'), sticky: true
        });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
        return;
      }
    } else {
      if (this.emailInput && this.emailInput.length > 0) {
        this.emailInput.forEach((item) => {
          this.email.to = this.email.to + item.email + ",";
        })
      } else if (this.toEmail) {
        this.toEmail.forEach((item) => {
          this.email.to = this.email.to + item.email + ",";
        })
      }

    }

    if (this.ccEmail.length > 0) {
      this.email.cc = [];
      this.ccEmail.forEach((item) => {
        this.email.cc.push(item.email)
      });
    } else if (this.ccEmailInput && this.ccEmailInput.length > 0) {
      this.email.cc = [];
      this.ccEmailInput.forEach((item) => {
        this.email.cc.push(item.email)
      });
    }
    this.email.to = this.email.to.replace(/,\s*$/, "");
    this.email.from = 'service@operr.com';
    this.email.attach_file_url = this.uploadedUrls;

    if ((this.emailInput && this.emailInput.length > 0) || (this.toEmail && this.toEmail.length > 0)
      || (this.isBulk && this.group && this.group.value && this.email.toEmails && this.email.toEmails.length > 0)
    ) {

      if (this.isClient) {
        this.email.footerBody = 'Client name: ' + this.authService.getUserInfo()?.name + '<br>';
        this.email.footerBody = this.email.footerBody + 'Email address: ' + this.authService.getUserInfo()?.email + '<br>';
        this.email.footerBody = this.email.footerBody + 'Phone number: ' + this.authService.getUserInfo()?.phone;

        this.email.body = this.email.body + '<br>' + this.email.footerBody;
      }
      this.email.createdByUsr = this.authService.getUserInfo().username;
      this.email.documentIds = this.uploadedIds;
      this.email.emailType = 'COMPOSE';
      if (!this.email.subject) {
        this.email.subject = '';
      }
      this.email.emailCompanyId = this.authService.getCurrentCompanyId();
      const payload = Object.assign({}, this.email);
      payload.body = this.sanitizeHtmlContent(this.email.body);
      console.log('sendEmailFromOperr email: ', this.email);
      this.notificationService.sendEmailSendGrid(this.email).subscribe(res => {
        const resObj: any = res;
        console.log(resObj);
        this.messageService.add({
          key: 'br', severity: 'success',
          summary: this.translatePipe.transform('Success'),
          detail: this.translatePipe.transform('Email sent successfully'), sticky: true
        });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
        alert(this.translatePipe.transform('Email sent successfully'));
        this.isClicked = false;
        this.cancel();
      }, (error) => {
        alert(this.translatePipe.transform('Email not sent'));
        this.isClicked = false;
      });
    }
    else {
      // alert("Please add valid mail");
      this.isClicked = false;
      this.messageService.add({
        key: 'br', severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('Please add valid mail'), sticky: true
      });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }
  }

  filterEmailProviderForTo(event) {
    this.suggestionsForBaseEmail = [];
    const query = event.query;
    this.toEmailTemp = event.query;
    (this.suggestionsForBaseEmailFullList).forEach((item) => {
      if (item && item.email) {
        if (item.key.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          this.suggestionsForBaseEmail.push(item);
        }
      }
    });

    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (event.query.match(mailformat)) {
      this.toEmailTemp = null;
      let newItem = {
        key: query,
        email: query
      }
      this.suggestionsForBaseEmail = [];
      this.suggestionsForBaseEmail.push(newItem);
      if (this.toEmail.indexOf(newItem) == -1) {
        this.toEmail.push(newItem);
        this.item.multiInputEL.nativeElement.value = "";
      }
    }
  }

  onSelectToEmail(event) {
    console.log('onSelectToEmail event', event);
    this.toEmailTemp = null;
    this.toEmail = '';
  }

  onSelectCCEmail(event) {
    console.log('onSelectCCEmail event', event);
    this.ccEmailTemp = null;
  }

  keyupToEmail(event) {
    console.log('keyupToEmail value', event.target.value);
    if (!event.target.value) {
      this.toEmailTemp = null;
    }
  }

  keyupCCEmail(event) {
    console.log('keyupCCEmail value', event.target.value);
    if (!event.target.value) {
      this.ccEmailTemp = null;
    }
  }

  // Call this method when a new email is validated and turned into a token
  addToken(newToken: string) {
    if (newToken && !this.emailTokens.includes(newToken)) {
      this.emailTokens.push(newToken);
      this.emailInput = []; // Clear the input field
    }
  }

  filterEmailProviderForFrom(event) {
    this.suggestionsForBaseEmail = [];
    const query = event.query;
    this.ccEmailTemp = event.query;
    (this.suggestionsForBaseEmailFullList).forEach((item) => {
      if (item && item.email) {
        if (item.key.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          this.suggestionsForBaseEmail.push(item);
        }
      }
    });
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (event.query.match(mailformat)) {
      this.ccEmailTemp = null;
      let newItem = {
        key: event.query,
        email: event.query
      };
      this.suggestionsForBaseEmail = [];
      this.suggestionsForBaseEmail.push(newItem);
      // Add new item to tokens if not already present
      if (!this.ccEmailTokens.some(e => e.email === newItem.email)) {
        this.ccEmailTokens.push(newItem);
        // Clear the input field
        this.ccEmailInput = [];
      }
    }
  }

  cancel() {
    this.email.cc = "";
    this.email.body = "";
    this.toEmail = [];
    this.ccEmail = [];
    this.email.to = "";
    this.email.subject = "";
    this._location.back();

  }

  changeType(event) {
    console.log('event: ', event);
    if (event && event.value && event.value.value) {
      this.group = {};
      if (event.value.value === 'Bulk') {
        this.isBulk = true;
      } else {
        this.isBulk = false;
      }
    }
  }

  changeGroup(event) {
    if (event && event.value && event.value.value) {
      this.selectedInvoiceStatus = [];
      this.selectedProductService = {};
      this.selectedProducts = [];
      this.allListUserHaveEmails = [];
      this.allListUserObjectHaveEmails = [];

      if (this.group.value === 'Employee') {
        this.allListUserHaveEmails = this.toEmailGroupEmployeeList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupEmployeeList;
      } else if (this.group.value === 'Client') {
        this.isClientGroup = true;
        this.allListUserHaveEmails = this.toEmailGroupClientList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupClientList;
      } else if (this.group.value === 'Contractor') {
        this.allListUserHaveEmails = this.toEmailGroupContractorList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupContractorList;
      } else if (this.group.value === 'Voter') {
        this.isVoterGroup = true;
        this.allListUserHaveEmails = this.toEmailGroupVoterList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupVoterList;
      } else if (this.group.value === 'Group') {
        this.allListUserHaveEmails = this.toEmailGroupVrmList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupVrmList;
      } else if (this.group.value === 'Lead') {
        this.allListUserHaveEmails = this.toEmailGroupLeadList;
        this.allListUserObjectHaveEmails = this.toEmailObjectGroupLeadList;
      }
    }
  }

  changeGroupName(event) {
    this.allListUserHaveEmails = [];
    this.allListUserObjectHaveEmails = [];
    console.log(this.selectedGroupName);
    if (event && event.value) {
      let toEmailGroupVrm = '';
      let vrmGroup = this.groupNames.find(item => item.id == this.selectedGroupName);
      const vrmGroupRecipients = vrmGroup.vrmGroupRecipients;
      if (vrmGroupRecipients) {
        vrmGroupRecipients.forEach(r => {
          const item = this.receipentList.find(u => r.userId === u.userId && r.userType === u.userType);
          if (item && item.email && !toEmailGroupVrm.includes(item.email)) {
            toEmailGroupVrm = toEmailGroupVrm + item.email + ',';
            this.allListUserHaveEmails.push(item.email);
            this.allListUserObjectHaveEmails.push(item);
          }
        });
      }
    }
  }

  changeProductService(event) {

  }

  getAllEmails() {
    const options = <any>{};
    options.status = 1;
    options.page = 0;
    options.size = 99999;
    options.companyId = this.selectedCompany;
    options.showProductIds = true;
    const voterOptions = Object.assign({}, options);
    voterOptions.status = 'ACTIVE';
    voterOptions.hasEmail = true;
    voterOptions.companyId = this.selectedCompany;
    const vrmGroupOptions = Object.assign({}, options);
    vrmGroupOptions.vrmGroupRecipients = true;
    vrmGroupOptions.status = null;
    const leadOptions = Object.assign({}, options);
    this.initLoading = true;
    forkJoin([
      this.employeeService.searchEmployeeGet(options),
      this.clientService.getClientDropdown(options),
      this.freelancerService.filter(options),
      this.voterService.getEmailListV2(voterOptions),
      this.vrmGroupService.search(vrmGroupOptions),
      this.potentialClientService.search(leadOptions)
    ]).subscribe((res: any[]) => {
      this.initLoading = false;
      const employeeList = res[0].data.content;
      this.getEmployeeList(employeeList);
      const clientList = res[1].data;
      this.getClientList(clientList);
      const contractorList = res[2].data.content;
      this.getFreelancerList(contractorList);
      const voterList = res[3].data.content;
      console.log('getVoterList res[3].data: ', res[3])
      this.getVoterList(voterList);
      this.buildVoterOptions(this.adListOptions, res[3].data.adList);
      this.buildVoterOptions(this.edListOptions, res[3].data.edList);

      const vrmGroupList = res[4].data.content;
      this.groupNames = vrmGroupList;
      for (let group of this.groupNames) {
        this.groupOptions.push({ label: group.vrmGroupName, value: group.id });
      }
      let recipients = [];
      recipients = recipients.concat(employeeList.filter(e => e.email).map(e => {
        return {
          userType: 'Employee',
          userId: e.id,
          email: e.email
        }
      }));
      recipients = recipients.concat(clientList.filter(c => c.email).map(c => {
        return {
          userType: 'Client',
          userId: c.key,
          email: c.email
        }
      }));
      recipients = recipients.concat(voterList.filter(v => v.email).map(v => {
        return {
          userType: 'Voter',
          userId: v.id,
          email: v.email
        }
      }));
      this.receipentList = recipients;
      this.getVrmGroupList(vrmGroupList, recipients);

      const leadList = res[5].data.content;
      console.log('getLeadList leadList: ', res[5]);
      this.getLeadList(leadList);
    });
  }

  buildVoterOptions(options, listValue) {
    const labels = new Set();
    listValue.forEach(item => {
      if (!labels.has(item)) {
        labels.add(item)
      }
    });
    labels.forEach(label => {
      options.push({
        value: label,
        label: label
      })
    });
  }

  getEmployeeList(content) {
    this.fillAllEmployeeEmailLoading = true;
    let toEmailGroupEmployee = '';
    content.forEach((emp: Employee) => {
      if (emp.email) {
        let empkey = emp.fullName + ': ' + emp.email;
        const obj = { key: empkey, email: emp.email }
        this.suggestionsForBaseEmailFullList.push(obj);
        if (!toEmailGroupEmployee.includes(emp.email)) {
          toEmailGroupEmployee = toEmailGroupEmployee + emp.email + ',';
          this.toEmailGroupEmployeeList.push(emp.email);
          this.toEmailObjectGroupEmployeeList.push({
            id: emp.id,
            email: emp.email,
            fullName: emp.firstName + ' ' + emp.lastName,
            username: emp.username,
            type: 'employee'
          });
        }
      }
    });
    this.fillAllEmployeeEmailLoading = false;
  }

  getEmailList() {
    const options = <any>{};
    options.page = 0;
    options.size = 99999;
    options.receiver = this.authService.getUserInfo().username;
    this.selectedMail = false;
    this.notificationService.searchEmail(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const rspData = resObj.data.content;
        for (let i = 0; i < rspData.length; i++) {
          if (rspData[i]?.status == 'FAILURE') {
            this.allMail.fail.push(rspData[i]);
          }
          if (rspData[i]?.status == 'SENT') {
            this.allMail.sent.push(rspData[i]);
          }
        }
        this.dataMenu = [
          { name: 'Sent', value: this.allMail.sent.length },
          { name: 'Failure', value: this.allMail.fail.length }
        ];
        this.mailList = this.allMail.sent;
        this.totalElement = this.mailList.length;
      } else {
        this.messageService.add({
          key: 'br', severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('Load List Mail Fail'), sticky: true
        });
      }
      this.loading = false;
    });
  }

  selectMenu(name: any) {
    if (name == 'Sent') {
      this.mailList = this.allMail.sent;
    }

    if (name == 'Failure') {
      this.mailList = this.allMail.fail;
    }
    this.totalElement = this.mailList.length;
    this.loading = false;
  }

  selectMail(data: any) {
    this.selectedMail = true;
    this.selectedMailData = data;
  }

  getClientList(content) {
    this.clientDataList = content;
    let toEmailGroupClient = '';
    content.forEach((client: any) => {
      if (client.email && !toEmailGroupClient.includes(client.email)) {
        toEmailGroupClient = toEmailGroupClient + client.email + ',';
        this.toEmailGroupClientList.push(client.email);
        this.toEmailObjectGroupClientList.push({
          id: client.key,
          email: client.email,
          fullName: client.value,
          username: client.fullName,
          type: 'client'
        });
      }
    });
  }

  getFreelancerList(content) {
    let toEmailGroupContractor = '';
    content.forEach((freelancer: Freelancer) => {
      if (freelancer.email && !toEmailGroupContractor.includes(freelancer.email)) {
        toEmailGroupContractor = toEmailGroupContractor + freelancer.email + ',';
        this.toEmailGroupContractorList.push(freelancer.email);
        this.toEmailObjectGroupContractorList.push({
          id: freelancer.id,
          email: freelancer.email,
          fullName: freelancer.fullName,
          username: freelancer.githubId,
          type: 'contractor'
        });
      }
    });
  }

  getVoterList(content) {
    let toEmailGroupVoter = '';
    content.forEach((voter: any) => {
      if (voter.email && !toEmailGroupVoter.includes(voter.email)) {
        toEmailGroupVoter = toEmailGroupVoter + voter.email + ',';
        this.toEmailGroupVoterList.push(voter.email);
        this.allVoterList.push(voter);
        this.toEmailObjectGroupVoterList.push({
          id: voter.id,
          email: voter.email,
          fullName: voter.fname,
          username: voter.fname,
          type: 'voter'
        });
      }
    });
  }

  getVrmGroupList(content, recipients) {
    console.log('getVrmGroupList content: ', content)
    let toEmailGroupVrm = '';
    content.forEach((vrmGroup: any) => {
      const vrmGroupRecipients = vrmGroup.vrmGroupRecipients;
      if (vrmGroupRecipients) {
        vrmGroupRecipients.forEach(r => {
          const item = recipients.find(u => r.userId === u.userId && r.userType === u.userType);
          if (item && item.email && !toEmailGroupVrm.includes(item.email)) {
            toEmailGroupVrm = toEmailGroupVrm + item.email + ',';
            this.toEmailGroupVrmList.push(item.email);
            this.toEmailObjectGroupVrmList.push({
              id: item.userId,
              email: item.email,
              fullName: item.fullName,
              username: item.username,
              type: 'vrm'
            });
          }
        });
      }
    });
  }

  getEmailTemplateList() {
    this.getEmailTemplateListLoading = true;
    const options = {} as any;
    options.sort = 'id';
    options.order = 'ASC';
    options.status = 1;
    options.size = 9999;
    options.page = 0;
    options.companyId = this.selectedCompany;
    this.emailTemplateService.search(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.emailTemplateList = resObj.data.content;
      }
      this.getEmailTemplateListLoading = false;
    });
  }

  changeEmailTemplate(event) {
    if (event && event.value) {
      this.emailTemplate = event.value;
      this.email.subject = this.emailTemplate.subject;
      this.emailTemplateService.get(this.emailTemplate.id).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.emailTemplate = resObj.data;
          this.emailTemplate.createdAt = new Date(this.emailTemplate.createdAt);
          this.emailTemplateSectionsContentList = this.emailTemplate.emailTemplateSectionsContents;
          if (!this.emailTemplateSectionsContentList || !this.emailTemplateSectionsContentList.length) {
            this.email.body = this.emailTemplate.body;
            this.charactersNumber = this.email.body.length;
          } else {
            this.emailTemplateSectionsContentList.forEach(sectionsContent => {
              if (sectionsContent.mainImage) {
                this.documentService.getUploadedFile(sectionsContent.mainImage).subscribe(res1 => {
                  const resObj1: any = res1;
                  if (resObj1.status === 'SUCCESS') {
                    if (resObj1.data.fileType === 'pdf') {
                      window.open(resObj1.data.fileUrl);
                    } else {
                      sectionsContent.fileUrl = resObj1.data.fileUrl;
                    }
                  }
                });
              }
            });
            setTimeout(() => {
              this.email.body = this.previewTemplate.nativeElement.innerHTML;
              this.charactersNumber = this.email.body.length;
            }, 1000);
          }
        }
      });
    }
  }

  getSupportEmail() {
    this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        if (response.data && response.data.supportEmail) {
          const supportEmailArr = response.data.supportEmail.split(",");
          this.toEmail = [];
          for (let i = 0; i < supportEmailArr.length; i++) {
            const email = {
              key: supportEmailArr[i],
              email: supportEmailArr[i],
            }
            this.toEmail.push(email);
          }
        }

      }
    });
  }

  getProductList() {

    const options = { page: 0, size: 9999, sort: 'id' + ',' + 'desc' } as any;
    const criteria: any = {
      status: 1,
    };
    criteria.companyId = this.selectedCompany;

    this.productService.searchProduct(criteria, options).subscribe(res => {
      const resObj: any = res;
      console.log('searchProduct res: ', res)
      if (resObj.status === 'SUCCESS') {
        resObj.data.content.forEach(product => {
          this.productList.push({ label: product.productName, value: product.id });
        });
      }
    });
  }


  changeClientFilter() {
    this.allListUserHaveEmails = [];
    this.allListUserObjectHaveEmails = [];

    let filtered = this.filterClientByInvoiceStatus(this.clientDataList);
    filtered = this.filterClientByProducts(filtered);

    let toEmailGroupClient = '';
    filtered.forEach((client) => {
      if (client.email) {
        if (client.email && !toEmailGroupClient.includes(client.email)) {
          toEmailGroupClient = toEmailGroupClient + client.email + ',';
          this.allListUserHaveEmails.push(client.email);
          this.allListUserObjectHaveEmails.push(client);
        }
      }
    });
  }

  filterClientByInvoiceStatus(allClient) {
    if (!this.selectedInvoiceStatus || this.selectedInvoiceStatus.length === 0 || this.selectedInvoiceStatus.length === this.invoiceStatusList.length) {
      return allClient;
    }
    return allClient.filter(c => {
      const invoiceStatuses = this.clientInvoiceMap[c.key];
      if (!invoiceStatuses) {
        return false;
      }
      return this.selectedInvoiceStatus.find(s => invoiceStatuses.has(s));
    });
  }

  filterClientByProducts(allClient) {
    if (!this.selectedProducts || this.selectedProducts.length === 0 || this.selectedProducts.length === this.productList.length) {
      return allClient;
    }
    return allClient.filter(c => {
      if (!c.productIds || c.productIds.length === 0) {
        return false;
      }
      return c.productIds.find(productId => this.selectedProducts.find(s => s === productId));
    });
  }

  changeEmail(event) {
    this.ccEmailTemp = event.target.value;
  }

  changeVoterFilter() {
    const filtered = this.allVoterList.filter(v => {
      if (this.selectedADs && this.selectedADs.length > 0 && !this.voterOptionsFilter(v, this.selectedADs, 'assemblyDistrict')) {
        return false;
      }
      if (this.selectedEDs && this.selectedEDs.length > 0 && !this.voterOptionsFilter(v, this.selectedEDs, 'electionDistrict')) {
        return false;
      }
      if (this.selectedParties && this.selectedParties.length > 0 && !this.voterOptionsFilter(v, this.selectedParties, 'party')) {
        return false;
      }
      return true;
    });

    console.log('changeVoterFilter filtered', filtered)
    let toEmailGroupVoter = '';
    this.toEmailGroupVoterList = [];
    this.toEmailObjectGroupVoterList = [];
    this.allListUserHaveEmails = [];
    this.allListUserObjectHaveEmails = [];
    this.voterFilteredList = filtered;
    filtered.forEach((voter: any) => {
      if (voter.email && !toEmailGroupVoter.includes(voter.email)) {
        toEmailGroupVoter = toEmailGroupVoter + voter.email + ',';
        this.toEmailGroupVoterList.push(voter.email);
        this.allListUserHaveEmails.push(voter.email);
        this.allListUserObjectHaveEmails.push(voter.email);
        this.toEmailObjectGroupVoterList.push({
          id: voter.id,
          email: voter.email,
          fullName: voter.fname,
          username: voter.fname,
          type: 'voter'
        });
      }
    });
  }

  voterOptionsFilter(value: any, selectedValues: [], field: any) {
    return value && value[field] && selectedValues.find(s => s === value[field]);
  }

  addEmailTemplate() {
    this.router.navigate(['/app/crm/email-template/list']);
  }

  sanitizeHtmlContent(unsafeHtml: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, unsafeHtml) || '';
  }

  getLeadList(content) {
    let toEmailGroupLead = '';
    content.forEach((lead: any) => {
      if (lead.email && !toEmailGroupLead.includes(lead.email)) {
        toEmailGroupLead = toEmailGroupLead + lead.email + ',';
        this.toEmailGroupLeadList.push(lead.email);
        this.toEmailObjectGroupLeadList.push({
          id: lead.id,
          email: lead.email,
          fullName: lead.ptClientName,
          username: lead.ptClientName,
          type: 'lead'
        });
      }
    });
  }
}

function customButton(context) {
  const ui = $.summernote.ui;
  const button = ui.button({
    contents: '<i class="fa fa-user-plus"></i>',
    tooltip: 'Add Place Holder',
    container: '.note-editor',
    className: 'note-btn',
    click: function () {
      context.invoke('editor.insertText', ' [placeholder]');
    }
  });
  return button.render();
}
