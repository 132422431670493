import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'app/crm/service/client.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import moment from 'moment';
import { LazyLoadEvent, MessageService, Table } from 'primeng';
import { BillingClientStatusService } from '../../service/billing-client-status.service'
import * as XLSX from 'xlsx';
import { FileUtility } from 'app/shared/utility/file.utility';
import { DatePipe, formatNumber } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-billing-client-status',
  templateUrl: './billing-client-status.component.html',
  styleUrls: ['./billing-client-status.component.scss'],
  providers: [BillingClientStatusService]
})
export class BillingClientStatusComponent implements OnInit {
  loading: boolean = true;
  isPlatformAdmin: any;
  searchText: any;
  billingReports: any[];
  fromDate: Date;
  companySelected: any;
  toDate: Date;
  createdByList: any[];
  createdSelected: any;
  clients: any[];
  clientList: any[];
  reportTypeSelected: any;
  companies: any[];
  isClientAccount: boolean = false;
  cols = [
    { field: '#', label: 'ID', width: '3%' },
    { field: 'client', label: 'Client', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'totalJobUploadedBy', label: 'Total Job Uploader', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'totalJobUpTime', label: 'Up Time (Total Job)', sortOptions: '', sort: 'number', width: '10%' },
    { field: 'clientUpdateTotalJobBy', label: 'Client Return/Update (Total Job)', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'billingReportUploadedBy', label: 'Report Uploader', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'billingReportUpTime', label: 'Report Up Time', sortOptions: '', sort: 'number', width: '12%' },
    { field: 'billingAddedBy', label: 'Added By', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'billingAddedDate', label: 'Added', sortOptions: '', sort: 'number', width: '12%' },
    { field: 'status', label: 'Status', sortOptions: '', sort: 'text', width: '10%' },
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  reportTypes = [
    { label: 'Billing Report', value: 'BILLING_REPORT' },
    { label: 'Total Job', value: 'TOTAL_JOB_REPORT' },
    { label: 'Details Report', value: 'DETAIL_REPORT' },
    { label: 'All', value: 'ALL' }
  ];

  @ViewChild('dt', { static: true }) table: Table;
  selectedSortOption = 'ASC';
  selectedField = 'updateAt';
  clientSelected: any;
  sortField: string;
  size: any;
  page: any;
  totalRecords: number = 0;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();

  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private datePipe: DatePipe,
    private messageService: MessageService, private clientService: ClientService,
    private billingClientStatusService: BillingClientStatusService, private router: Router,
    private monitoringDetailsService: MonitoringDetailsService) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-client-status') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Billing Client Status`,
            this.timeSpent,
            {
              navigated_to_upload_history_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Billing Client Status`,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.loading = true;
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.searchText = '';
    this.getClientList();
    if (this.authService.isClientRole()) {
      this.clientSelected = this.authService.getUserInfo().adminId;
      this.isClientAccount = true;
    } else {
      this.isClientAccount = false;
    }
    // this.search();
    this.getCreatedByList();
    // this.loadBillingReports();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
  }

  loadBillingReports(event?: LazyLoadEvent) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 9999);
    this.page = event && typeof event.first !== "undefined" && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options: any = {};
    options = {
      size: this.size,
      page: this.page,
      sortOrder: 'DESC'
    }

    if (this.reportTypeSelected) {
      options.reportType = this.reportTypeSelected;
    }
    if (this.clientSelected) {
      options.clientId = this.clientSelected;
    }

    if (this.createdSelected) {
      options.createdBy = this.createdSelected;
    }

    this.loadPage(options);
  }

  search() {
    this.loadBillingReports();
  }

  searchTextByClient(event?: any) {
    this.searchList();
  }

  loadPage(options: any) {
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    if (this.createdSelected) options.createdBy = this.createdSelected;
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingClientStatusService.getList(options).subscribe(res => {
      console.log('getList', res);
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        this.billingReports.forEach(item => {
          item.totalJobUpTime = item.totalJobUpTime ? moment(item.totalJobUpTime).toDate() : null;
          item.clientUpdateTotalJob = item.clientUpdateTotalJob ? moment(item.clientUpdateTotalJob).toDate() : null;
          item.billingReportUpTime = item.billingReportUpTime ? moment(item.billingReportUpTime).toDate() : null;
          item.billingAdded = item.billingAdded ? moment(item.billingAdded).toDate() : null;
          item.client = this.getClientName(item);
          if (item?.clientId) {
            const client = this.clientList.find(el => el.value === item.clientId);
            if (client) {
              item.name = client.label;
            }
          }
        });
        if (this.searchText) {
          this.billingReports = this.billingReports.filter((val) => {
            return val.name.toLowerCase().search(this.searchText.toLowerCase()) > -1
          });
        }
        console.log(this.billingReports);
        this.sortingClick(this.selectedSortOption);
      }
      this.loading = false;
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.billingReports = [];
    this.clientSelected = null;
    this.createdSelected = null;
    this.searchText = '';
    this.table.reset();
    this.search();
  }

  onChangeClient(event) {
    this.getCreatedByList();
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    if (this.companySelected) { options.companyId = this.companySelected; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
      this.search();
    });
  }

  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null
    };
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingClientStatusService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    })
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.search();
  }

  sortingClick(selectedSortOption) {
    console.log('selectedSortOption', selectedSortOption);
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.billingReports
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      "complete",
      action,
      0
    );
  }

  getClientName(data) {
    if (data?.clientId) {
      const client = this.clientList.find(el => el.value === data.clientId);
      if (client) return client.label;
    }
    return data.clientName;
  }

}
