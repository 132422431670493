import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MenuItem, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DepartmentService } from '../../../department/service/department.service';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService } from '../../../agency/service';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../agency/model';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { Constants } from '../../../shared/model/constants';
import { EditedClockingBeyondSettings } from '../../model/edited.clocking-beyond-settings.model';
import { EmployeeService } from "../../../employee/service/employee.service";
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import { bool } from 'aws-sdk/clients/signer';
import {DropDownsService} from "../../../shared/service/drop-downs/drop-downs.service";
import {TranslatePipe} from '@ngx-translate/core';

declare let $: any;

@Component({
    selector: 'app-summary-view',
    templateUrl: './summary-view.component.html',
    styleUrls: ['./summary-view.component.scss'],
    providers: [EmployeeClockingService, WindowRefService, PayrollSettingService, MessageService]
})
export class SummaryViewComponent implements OnInit {
    punchHistoryList: Array<any>;
    summaryViewList: Array<any>;
    selectedSummaryView: EmployeeClocking = new EmployeeClocking();
    employeeClocking: EmployeeClocking = new EmployeeClocking();
    totalRecords: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = false;
    types: Array<any> = [];
    employeeList: Array<Employee> = [];
    filteredEmployeeList: Array<Employee> = [];
    @ViewChild('dt', {static: true}) table: Table;
    historyFilter: any;
    rowData: any;
    totalOvertime: string;

    missingOrInvalidDate = false;
    showNoteDetailsDialog = false;
    missingCheckInCount = 0;
    missingCheckOutCount = 0;
    selected: any;
    alwaysShowCalendars: boolean;
    currentTime = new Date();

    companies = [];
    allAgencies = [];
    agencies = [];
    selectedCompany: any = 0;
    selectedAgency: any = 0;
    selectedViewBy: any;
    selectedEmployee: any;

    uniqueDataForLunchCalculation = <any>[];
    dataForCheckOut = <any>[];
    employeeAndDateWiseLunchTime = <any>[];
    viewBy = [];
    departments: any;
    adminData: any;
    disableCalField: boolean = false;

    textSortOptionsClient = [
        { name: 'SortAToZ', value: 'ASC', img: 'a-z' },
        { name: 'SortZToA', value: 'DESC', img: 'z-a' }
    ];
    numberSortOptionsClient = [
        { name: 'Sort0To9', value: 'ASC', img: 'a-z' },
        { name: 'Sort9To0', value: 'DESC', img: 'z-a' }
    ];
    selectedSortOption = '';
    summarySelectedSortOption = '';
    selectedField = '';
    selectedSummaryField = '';
    selectedSummaryData: any;
    employeeSummaryDetails:any=[];

    ranges: any = {
        'Today': [MOMENT(), MOMENT()],
        'Yesterday': [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')],
        'Last 7 Days': [MOMENT().subtract(6, 'days'), MOMENT()],
        'Last 30 Days': [MOMENT().subtract(29, 'days'), MOMENT()],
        'This Month': [MOMENT().startOf('month'), MOMENT().endOf('month')],
        'Last Month': [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')]
    };
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];
    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }
    isEditAble: boolean = false;
    totalHours = 0;
    payrollSetting: PayrollSetting;
    payrollDate = [null, null];
    daysDisable: any[] = [];
    showShort: boolean = false;
    listGroups: any = [];
    totalWeeklyhoursInMinute: any;
    totalWeeklyhoursWorked: any;
    totalCompleteHours: any;
    maxDateForHistory: any = new Date();
    disableAll: boolean = true;
    showSummaryViewDetails: boolean = false;
    isPlatformAdmin: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private noteHistoryService: NoteHistoryService,
        private windowRef: WindowRefService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private agencyService: AgencyService,
        private datePipe: DatePipe,
        private dropdownService: DropDownsService,
        private departmentService: DepartmentService,
        private authService: AuthService,
                private translatePipe: TranslatePipe) { }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.adminData = JSON.parse(localStorage.getItem('_globals'));
        this.viewBy = [{ label: 'ShortTimePrediction', value: 'shortTimePrediction' }, { label: 'ShortTimeHistory', value: 'shortTimeHistory' }];

        this.items = [
            { label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewPunchHistory(this.selectedSummaryView) },
        ];
        this.punchHistoryList = [];
        this.summaryViewList = [];
        this.types = [
            { label: 'Part Time', value: 'part_time' },
            { label: 'Full Time', value: 'full_time' },
            { label: 'Consultant', value: 'consultant' },
            { label: 'Volunteer', value: 'volunteer' }];
        this.historyFilter = {
            employees: [],
            employeeIds: [],
            fromDate: null,
            toDate: null,
            page: 0,
            size: 10,
            searchKey: '',
            date: [new Date(), new Date()],
            agencyId: null,
            isMissing: false,
        };

        this.selectedViewBy = 'shortTimeHistory';
        this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : null;
        this.selectedAgency = this.authService.getCurrentAgency() ? this.authService.getCurrentAgency().id : null;

        if (this.authService.getCurrentEmployee()) {
            this.historyFilter.employeeIds.push(this.authService.getCurrentEmployee());
        }

        // this.getAllEmployees();
        this.dropdownService.getAllCompanyList().subscribe(
            res => {
                const responseObject = res;
                const tempCompanyList = [];
                if (responseObject.status === 'SUCCESS') {
                    responseObject.data.forEach(element => {
                        tempCompanyList.push({ label: element.name, value: element.id });
                    });
                    this.companies = tempCompanyList;
                }
            });
        this.agencyService.getAll().pipe(first()).subscribe((response: any) => {
            this.allAgencies = response.data.content;
            if (this.selectedCompany) {
                this.loadAgencies(this.selectedCompany);
            }
        });

        const employeeIdsStr = localStorage.getItem('overtime_alert');
        if (employeeIdsStr && employeeIdsStr.length > 0) {
            this.historyFilter.employeeIds.push(employeeIdsStr.split(','));
            localStorage.removeItem('overtime_alert');
        }

        this.loadAllDepartments();
        this.isEditAble = this.authService.getLoggedInUserEditList().includes("23"); // get id from menu-list.ts
        // this.disableDayForShortHistory();
        if(this.selectedAgency){
            this.disableAll = false;
            this.loadEmployeeList(this.selectedAgency);
        }
    }



    loadAllDepartments() {
        this.departmentService.getDepartmentsDropdown({ companyId: (this.selectedCompany) ? this.selectedCompany : 0, agencyId: (this.selectedAgency) ? this.selectedAgency : 0 }).subscribe(res => {
            const resObj: any = res;
            const tempDepList = [];
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach((dVal) => {
                    tempDepList.push({ label: dVal.name, value: dVal.id });
                });
                this.departments = tempDepList;
            }
        });
    }

    viewPunchHistory(d: EmployeeClocking) {
        // this.router.navigate(['app/employee/clocking','view',d.id]);
    }

    editPunchHistory(d: EmployeeClocking) {
        // this.router.navigate(['app/employee/clocking', 'edit', d.id]);
    }

    viewByChange(event) {
        if (event.value == 'shortTimePrediction') {
            this.summaryViewList = [];
            this.disableCalField = true;
            this.selectCurrentWeek('true');
            this.showShort = true;
        } else {
            this.payrollDate = [];
            this.disableCalField = false;
            this.disableDayForShortHistory(this.payrollSetting && this.payrollSetting.payrollWeekEnding? this.payrollSetting.payrollWeekEnding: 0);
            this.showShort = false;
        }
    }

    disableDayForShortHistory(dayOfWeek) {
        const curr = new Date();
        const first = new Date();
        const last = new Date();
        first.setDate(first.getDate() - curr.getDay() );
        last.setDate(last.getDate() - curr.getDay());
        last.setDate(first.getDate() - dayOfWeek);
        first.setDate(first.getDate() - 6);
        first.setHours(0, 0, 0);
        last.setHours(0, 0, 0);
        this.payrollDate.push(first);
        this.payrollDate.push(last);
        this.historyFilter.fromDate = this.payrollDate[0];
        this.historyFilter.toDate = this.payrollDate[1];
    }

    selectCurrentWeek(callFn?) {
        const data = [];
        const curr = new Date();
        const first = new Date();
        const last = new Date();
        const dayOfWeek = this.payrollSetting && this.payrollSetting.payrollWeekEnding ? this.payrollSetting.payrollWeekEnding : 0;
        first.setDate(first.getDate() - curr.getDay() );
        last.setDate(last.getDate() - curr.getDay());

        if (dayOfWeek >= curr.getDay()){
            last.setDate(last.getDate() + dayOfWeek);
            first.setDate(last.getDate() - 6);
        } else {
            first.setDate(first.getDate() + dayOfWeek + 1 );
            last.setDate(first.getDate() + 6);
        }
        first.setHours(0, 0, 0);
        last.setHours(0, 0, 0);
        data[0] = first;
        data[1] = last;
        this.payrollDate = data;
        this.historyFilter.fromDate = this.payrollDate[0];
        this.historyFilter.toDate = this.payrollDate[1];
        if (!callFn) {
            this.loadPage();
        }
    }

    changeAgency(agencyId: number) {

        this.loadPayment(agencyId);
        this.loadAllDepartments();
        this.disableAll = false;
    }

    loadPayment(agencyId: number) {
        this.payrollSettingService.getPayrollSetting(agencyId).subscribe(res1 => {
            const res1Obj: any = res1;
            this.payrollSetting = res1Obj.data;
            let event = <any>{};
            event.value = 'shortTimeHistory';
            this.viewByChange(event);
            this.getByDayOfWeek(this.payrollSetting? this.payrollSetting.payrollWeekEnding:1);
        });
    }

    getByDayOfWeek(dayOfWeek: number) {
        this.disableDayArr(dayOfWeek);
        const curr = new Date(); // get current date
        const endWeek: number = curr.getDate() - curr.getDay() + (dayOfWeek === 0 ? 7 : dayOfWeek);
        const date = new Date(curr.setDate(endWeek));
        const lastEndWeek: number = endWeek - 7;
        const firstLastWeek: number = lastEndWeek - 6;
        let startDate = new Date(date.setDate(date.getDate() - 7 - 6));
        let endDate = new Date(date.setDate(date.getDate() + 6));
        // this.payrollDate = endDate;
    }

    disableDayArr(day: number) {
        const days = [];
        this.daysDisable = [];
        for (let i = 0; i < 7; i++) {
            if (i !== day) {
                this.daysDisable.push(i);
            }
        }
    }

    searchByDepartment(event: any) {
        if (event.value) {
            this.historyFilter.department = event.value;
        }
    }

    onSearch() {
        this.msgs = [];
        if(this.payrollDate){
            this.loadPage();
        }else{
            this.msgs.push({ severity: 'error', summary: 'Error', detail: `Please select the Week Date!` });
        }
    }

    onReset() {
        this.selectedCompany = null;
        this.selectedAgency = null;
        this.historyFilter.employees = null;
        this.payrollDate = null;
    }

    async loadSummaryViewyLazy(event?: LazyLoadEvent) {
    }

    async loadPage(options?: any) {
        this.totalCompleteHours = 0;
        this.totalWeeklyhoursInMinute = 0;
        this.totalWeeklyhoursWorked = 0;
        this.loading = true;
        if (!options) {
            options = this.historyFilter;
        }
        const empTypes = this.types;
        const resObj: any = await this.employeeClockingService.summaryViewFilter(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.loading = false;
            this.summaryViewList = resObj.data.summaryView;
            this.updateOverTimeSheet();
            this.getDapaatmentName();
        }
    }

    updateOverTimeSheet() {
        this.summaryViewList.forEach((record, i) => {
            this.totalWeeklyhoursInMinute = this.totalWeeklyhoursInMinute + record.weeklyhoursInMinute;
            this.totalCompleteHours = this.totalCompleteHours + record.shortHours;
            this.totalWeeklyhoursWorked = this.totalWeeklyhoursWorked + record.completeHoursInWeak;
            if (i === (this.summaryViewList.length - 1)) {
                this.totalWeeklyhoursInMinute = this.convertMinutesToHours(this.totalWeeklyhoursInMinute ? this.totalWeeklyhoursInMinute : 0);
                this.totalCompleteHours = this.convertMinutesToHours(this.totalCompleteHours ? this.totalCompleteHours : 0);
                this.totalWeeklyhoursWorked = this.convertMinutesToHours(this.totalWeeklyhoursWorked ? this.totalWeeklyhoursWorked : 0);
            }
        }
        )
    }

    convertMinutesToHours(minutes: number) {
        minutes = minutes / 60000;
        let isNegative = false;
        if (minutes < 0)
            isNegative = true;
        minutes = Math.abs(minutes);
        if (minutes == 0 || minutes == null)
            return "00:00";
        else {
            if (minutes % 60 == 0)
                return (minutes / 60).toString().concat(":00");
            else {
                let m: any = Math.floor(minutes % 60);
                let h: any = Math.floor(((minutes - m) / 60));

                if (h < 10) h = '0' + h;
                if (m < 10) m = '0' + m;

                return (isNegative ? '-' : '') + h + ":" + m;
            }
        }

    }

    selectWeek(evt) {
        const endDate = new Date(evt);
        endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());
        let startDate: any = endDate.setDate(endDate.getDate() - 6);
        startDate = new Date(startDate);
        this.payrollDate[0] = startDate;
        this.payrollDate[1] = new Date(evt);
        this.historyFilter.fromDate = this.payrollDate[0];
        this.historyFilter.toDate = this.payrollDate[1];
        this.historyFilter.isMissing = false;
    }

    getTotalDay(punchHistoryList: Array<any>) {
        return punchHistoryList.length.toString() + this.translatePipe.transform('Record');
    }

    search(event) {
        this.loading = true;
        this.table.first = 0;
        this.historyFilter.searchKey = event.target.value;
        this.historyFilter.page = 0;
        this.historyFilter.size = this.table.rows;
        if (this.historyFilter.searchKey) {

            this.loadPage(this.historyFilter);

        } else {
            this.historyFilter.searchKey = '';

            this.loadPage(this.historyFilter);

        }
    }

    searchBySelectedEmployees(event) {
        this.table.first = 0;
        this.historyFilter.page = 0;
        this.historyFilter.size = this.table.rows;
        this.historyFilter.employeeIds = [];
        const pchc = this;
        this.historyFilter.employees.forEach((obj) => {
            pchc.historyFilter.employeeIds.push(obj);
        });
        this.historyFilter.isMissing = false;
        if (this.historyFilter.employeeIds && this.historyFilter.employeeIds.length > -1) {
            // this.loadPage(this.historyFilter);
        }
        if (event.value.length === this.filteredEmployeeList.length) {
            const x = document.getElementById('id_filteredEmployeeList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('id_filteredEmployeeList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }

    getAllEmployees() {
        this.employeeClockingService.getEmployeeList().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeeList = resObj.data;

                if (this.selectedEmployee) {
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === this.selectedEmployee;
                    });
                }
            }
        });
    }

    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    loadAgencies(companyId) {
        this.historyFilter.employees = null;
        this.listGroups = [];
        this.historyFilter.department = null;
        this.departments = [];
        this.historyFilter.companyId = companyId;
        // console.log('LoadAgency', companyId);
        this.agencyService.readAllForDropDownList({companyId: companyId}).subscribe((res) => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.agencies = resObj.data;
            }
            if (this.agencies && this.agencies.length > 0) {
                this.getAllEmployeesByCompany();
                this.selectedAgency =  this.agencies[0].id;
                this.changeAgency(this.selectedAgency);
            }
        });


    }

    loadEmployeeList(agencyId) {
        this.listGroups = [];
        this.historyFilter.agencyId = agencyId;
        this.payrollSettingService.getPayrollSetting(agencyId).subscribe(res => {
            const resObj: any = res;
            const payrollSetting = resObj.data;
            this.employeeService.getAllByAgency(agencyId).subscribe(resEmp => {
                const empObj: any = resEmp;
                this.filteredEmployeeList = empObj.data;
                // console.log(this.filteredEmployeeList)
                this.filteredEmployeeList.forEach(employee => {
                    employee.payrollSetting = payrollSetting;
                })
                this.filteredEmployeeList.forEach(emp => {
                    this.listGroups.push({ label: `${emp.username}(${emp.fullName})`, value: emp.id });
                });
                this.filterPunchHistory();
            });
        });
    }

    getAllEmployeesByCompany() {
        this.listGroups = [];
        if (this.selectedCompany) {
            this.employeeClockingService.getByCompanyAndAgency({ companyId: this.selectedCompany }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === (this.selectedEmployee || this.authService.getCurrentEmployee());
                    });
                    this.filteredEmployeeList = this.employeeList;
                    // console.log(this.filteredEmployeeList);
                    this.filteredEmployeeList.forEach(emp => {
                        this.listGroups.push({ label: `${emp.username}(${emp.fullName})`, value: emp.id });
                    });
                    this.filterPunchHistory();
                }
            });
        }
    }

    getAllEmployeesByCompanyAndAgency() {
        this.listGroups = [];
        if (this.selectedCompany && this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({ companyId: this.selectedCompany, agencyId: this.selectedAgency }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === (this.selectedEmployee || this.authService.getCurrentEmployee());
                    });
                    this.filteredEmployeeList = this.employeeList;
                    // console.log(this.filteredEmployeeList);
                    this.filteredEmployeeList.forEach(emp => {
                        this.listGroups.push({ label: `${emp.username}(${emp.fullName})`, value: emp.id });
                    });
                    this.filterPunchHistory();
                }
            });
        } else if (this.selectedCompany && !this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({ companyId: this.selectedCompany }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === (this.selectedEmployee || this.authService.getCurrentEmployee());
                    });
                    this.filteredEmployeeList = this.employeeList;
                    // console.log(this.filteredEmployeeList);
                    this.filteredEmployeeList.forEach(emp => {
                        this.listGroups.push({ label: `${emp.username}(${emp.fullName})`, value: emp.id });
                    });
                    this.filterPunchHistory();
                }
            });
        } else if (!this.selectedCompany && this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({ agencyId: this.selectedAgency }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === (this.selectedEmployee || this.authService.getCurrentEmployee());
                    });
                    this.filteredEmployeeList = this.employeeList;
                    // console.log(this.filteredEmployeeList);
                    this.filteredEmployeeList.forEach(emp => {
                        this.listGroups.push({ label: `${emp.username}(${emp.fullName})`, value: emp.id });
                    });
                    this.filterPunchHistory();
                }
            });
        }
    }

    filterPunchHistory() {

    }

    cancelEditCheckIn(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckIn = false;
        }, 0);
    }

    allowEditCheckOut(rowData) {
        if (!rowData.editAllowedCheckOut) {
            rowData.editAllowedCheckOut = true;
            if (rowData.checkOutTime) {
                // rowData.tempCheckOutTime = this.showHour(rowData.checkOutTime);
                rowData.tempCheckOutTime = MOMENT(rowData.checkOutTime).format('HH:mm');
            }
        }
    }
    cancelEditCheckOut(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckOut = false;
        }, 0);
    }

    chechEditedBeyondSettings() {
        this.employeeClockingService.isEditedBeyondSettingsValue(this.employeeClocking.id).subscribe((data: any) => {
            const editedPunchInOutSettingsBeyond: EditedClockingBeyondSettings = data.data;
            if (editedPunchInOutSettingsBeyond) {
                //
            }
        });
    }

    notificationSave(notificationData: any) {
        notificationData.tableName = Constants.NOTE_DATA.SAVE_HISTORY; // this is static because from here only save the note notification
        notificationData.type = Constants.NOTE_DATA.SAVE_EDITED_PUNCH_IN_OUT_NOTE; // this is static because from here only save the note notification
        this.noteHistoryService.saveNotification(notificationData).subscribe((e) => {
        });
    }

    noteDetailsMouseEnter(event) {
        if (this.showNoteDetailsDialog) {
            return;
        } else {

            this.showNoteDetailsDialog = true;
            setTimeout(() => {
                $('#id_note_detail_dialog > .ui-dialog').css('cssText', `top: ${event.clientY + 10}px !important; left: ${event.pageX + 10}px !important;`);
            }, 0);
        }
    }
    noteDetailsMouseLeave(event) {
        this.showNoteDetailsDialog = false;

        localStorage.setItem('punchHistoryFilter', JSON.stringify(this.historyFilter));
        this.router.navigate(['app/employee/payment'], { queryParams: { missingClockOut: true } });
    }

    disableCompany(): boolean {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency(): boolean {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee(): boolean {
        return this.authService.isEmployeeRole();
    }
    dateToShow(date) {
        if (date) {
            return moment(date).format('HH:mm');
        } else {
            return '';
        }
    }
    getToolTip(text) {
        return 'Time updated by ' + text;
    }

    sortingClick(selectedSortOption) {
        this.summaryViewList.sort(this.compare(this.selectedField.split('.').reverse()[0], selectedSortOption));
    }

    compare(selected, sortBy) {
        return function (a, b) {
            if (selected === 'completeHours' && sortBy === 'ASC') {
                return (parseInt(a[selected])) - (parseInt(b[selected]));
            }
            if (selected === 'completeHours' && sortBy === 'DESC') {
                return (parseInt(b[selected])) - (parseInt(a[selected]));
            }
            if (selected === 'completeHoursInWeak' && sortBy === 'ASC') {
                return (parseInt(a[selected])) - (parseInt(b[selected]));
            }
            if (selected === 'completeHoursInWeak' && sortBy === 'DESC') {
                return (parseInt(b[selected])) - (parseInt(a[selected]));
            }
            if (selected === 'weeklyHours' && sortBy === 'ASC') {
                return (parseInt(a.emp.employee[selected])) - (parseInt(b.emp.employee[selected]));
            }
            if (selected === 'weeklyHours' && sortBy === 'DESC') {
                return (parseInt(b.emp.employee[selected])) - (parseInt(a.emp.employee[selected]));
            }
            if (sortBy === 'ASC') {
                return (a.emp.employee[selected]).toLowerCase() > (b.emp.employee[selected]).toLowerCase() ? 1 : -1;
            }
            if (sortBy === 'DESC') {
                return (a.emp.employee[selected]).toLowerCase() > (b.emp.employee[selected]).toLowerCase() ? -1 : 1;
            }
        }
    }

    convertToHours(hours) {
        if (hours.toString().length === 4) {
            hours = hours / 100;
        }
        return hours + ':00';
    }

    getDapaatmentName() {
        this.summaryViewList.forEach(element => {
            if (element.emp.employee.department) {
                this.departments.forEach(el => {
                    if (el.value == element.emp.employee.department) {
                        element.emp.employee.department = el.label;
                    }
                });
            }
        });
    }

    // open modal
    openSummaryView(rowData) {
        this.showSummaryViewDetails = true;
        this.selectedSummaryData = rowData;
        this.employeeSummaryDetails[0] = this.selectedSummaryData.employeeOT.weekday1 // weekday1 for sunday
        this.employeeSummaryDetails[1] = this.selectedSummaryData.employeeOT.weekday2 // weekday2 for monday
        this.employeeSummaryDetails[2] = this.selectedSummaryData.employeeOT.weekday3 // weekday3 for tuesday
        this.employeeSummaryDetails[3] = this.selectedSummaryData.employeeOT.weekday4 // weekday4 for wednesday
        this.employeeSummaryDetails[4] = this.selectedSummaryData.employeeOT.weekday5 // weekday5 for thursday
        this.employeeSummaryDetails[5] = this.selectedSummaryData.employeeOT.weekday6 // weekday6 for friday
        this.employeeSummaryDetails[6] = this.selectedSummaryData.employeeOT.weekday7 // weekday7 for saturday
        this.employeeSummaryDetails.forEach((el, index) =>{
            let endDate = new Date(this.payrollDate[0]);
            let startDate = endDate.setDate(endDate.getDate() + index);
            if(endDate.getDay() === 0){
                this.employeeSummaryDetails[0].weekDate = startDate;
            }
            if(endDate.getDay() === 1){
                this.employeeSummaryDetails[1].weekDate = startDate;
            }
            if(endDate.getDay() === 2){
                this.employeeSummaryDetails[2].weekDate = startDate;
            }
            if(endDate.getDay() === 3){
                this.employeeSummaryDetails[3].weekDate = startDate;
            }
            if(endDate.getDay() === 4){
                this.employeeSummaryDetails[4].weekDate = startDate;
            }
            if(endDate.getDay() === 5){
                this.employeeSummaryDetails[5].weekDate = startDate;
            }
            if(endDate.getDay() === 6){
                this.employeeSummaryDetails[6].weekDate = startDate;
            }

            if(el.workedTimeStr === "No schedule"){
                el.workedTimeStr = "00:00"
            }

        });
        this.employeeSummaryDetails.sort((a,b) => (a.weekDate > b.weekDate) ? 1 : ((b.weekDate > a.weekDate) ? -1 : 0)); 

    }

    closeModal() {
        this.showSummaryViewDetails = false;
    }

    summarySortingClick(option) {
        if(option === 'ASC'){
            this.employeeSummaryDetails.sort((a,b) => (a[this.selectedSummaryField] > b[this.selectedSummaryField]) ? 1 : ((b[this.selectedSummaryField] > a[this.selectedSummaryField]) ? -1 : 0));
        }
        if(option === 'DESC'){
            this.employeeSummaryDetails.sort((a,b) => (a[this.selectedSummaryField] > b[this.selectedSummaryField]) ? -1 : ((b[this.selectedSummaryField] > a[this.selectedSummaryField]) ? 1 : 0));
        }
    }

}
