import { Company } from '../../../company/model/company.model';
import { Role } from '../../../shared/model/user/role.model';

export class AgencyAdmin {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    email: string;
    phone: string;
    role?: Role;
    username: string;
    password: string;
    status: number;
    actionList: string[];
    agency: any;
    isEmployee: boolean;
    company?: Company;
    companyId?: any;
    fcmToken: string;
    menuList: string[];
    profilePhoto?: number;
    secondaryPhone: string;
    address: string;
    did: string;
    extension:number;
    listGroupIds: any[];
    deleteList:any;
    editList:any;
    updatedAt: any;
    fakeCode: any;
    defaultDashboard?: string;
}
