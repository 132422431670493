import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad, Route} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {AuthService} from '../shared/service/auth/auth.service';
import { MenuList } from '../shared/data/menu-list';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private authService: AuthService , private router: Router) {}
    loggedInUserMenus  = this.authService.getLoggedInUserMenuList();
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            const allMenusList = new MenuList();
            let currentPageMenu: any = {
                id: 0
            };
            currentPageMenu =  this.getMenuIem(state.url);
            if (this.authService.adminRole() && state.url.includes('app/admin/company-admin')) {
                return this.authService.adminRole();
            }
            if (this.authService.adminRole()) {
                if (this.loggedInUserMenus) {
                    if (this.loggedInUserMenus.indexOf(currentPageMenu.id.toString()) < 0){
                        let getFirstMenu: any = {routerLink: ''};
                         allMenusList.menus.forEach((mi) => {
                            if (mi.id === parseInt(this.loggedInUserMenus[0])){
                                getFirstMenu = mi;
                            }
                        });

                        this.router.navigate([getFirstMenu.routerLink]);
                    }
                }else{
                    this.router.navigate(['/', 'login']);
                }
            }else{
                this.router.navigate(['/', 'login']);
            }
        return this.authService.adminRole();
      }

        canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            const allMenusList = new MenuList();
            let currentPageMenu: any = {
                id: 0
            };
            currentPageMenu =  this.getMenuIem(state.url);
            if (state.url.includes('app/admin/company-admin')) {
                return this.authService.adminRole();
            }
            if (this.authService.adminRole()) {
                if (this.loggedInUserMenus){
                    if (this.loggedInUserMenus.indexOf(currentPageMenu.id.toString()) < 0){
                        let getFirstMenu: any = {routerLink: ''};
                         allMenusList.menus.forEach((mi) => {
                            if (mi.id === parseInt(this.loggedInUserMenus[0])){
                                getFirstMenu = mi;
                            }
                        });

                        this.router.navigate([getFirstMenu.routerLink]);
                    }
                }else{
                    this.router.navigate(['/', 'login']);
                }
            }else{
                this.router.navigate(['/', 'login']);
            }
            return this.authService.adminRole();
        }

        canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
            return this.authService.adminRole();
        }

        private getMenuIem(stateUrl: string){
            const allMenusList = new MenuList();
            let toReturnMenuItem = {id: 0, routerLink: stateUrl };
            allMenusList.menus.forEach((menuItem) => {
                if (menuItem.routerLink && stateUrl.includes(menuItem.routerLink)){
                    toReturnMenuItem = menuItem;
                }
            });
            return toReturnMenuItem;
        }
}
