import { PipeTransform, Pipe } from "@angular/core";
import { parsePhoneNumberFromString, PhoneNumber, CountryCode, formatIncompletePhoneNumber } from 'libphonenumber-js'

@Pipe({ name : "phoneFormat"})
export class PhoneFormatPipe implements PipeTransform{
    
    transform(value: number | string, code?:string):string {
        
        const stringPhone = value + '';
        if(!code)
            code = "US";
        
        const inputNumber = parsePhoneNumberFromString(stringPhone, code as CountryCode);
        
        try{
            return inputNumber.isValid()? inputNumber.formatNational(): formatIncompletePhoneNumber(stringPhone, code as CountryCode);
            }
        catch(error){
            return formatIncompletePhoneNumber(stringPhone, code as CountryCode)
        }
        
        

    }

}