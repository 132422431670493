import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {PayrollSettingService} from "../../service/payroll-setting.service";
import {PayrollSetting} from "../../model/payroll-setting.model";

@Component({
  selector: 'app-payroll-holiday-edit',
  templateUrl: './payroll-holiday-edit.component.html',
  styleUrls: ['./payroll-holiday-edit.component.css'],
    providers: [PayrollSettingService]
})
export class PayrollHolidayEditComponent implements OnInit {
    companyId: number;
    holidayId: number;
    payrollSetting: PayrollSetting;
  constructor(private route: ActivatedRoute, private payrollSettingService: PayrollSettingService) {
      this.route.params.subscribe(params => {
          this.companyId = +params.id;
          this.holidayId = +params.holidayId;
      });
      this.payrollSettingService.getPayrollSetting(this.companyId).subscribe(res=>{
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
              this.payrollSetting = resObj.data;
              console.log(this.payrollSetting);
          }


      });
      console.log(this.payrollSetting);
  }

  ngOnInit() {

  }

}
