import { LazyLoadEvent, MessageService } from 'primeng/api';
import { FileUtility } from 'app/shared/utility/file.utility';
import { DatePipe, formatNumber } from '@angular/common';
import {PaymentProfileService} from '../../service/payment-profile.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {PaymentTransactionService} from '../../service/payment-transaction.service';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WindowRefService} from '../../../shared/service/window-ref/window-ref.service';
import moment from 'moment';
import {PaymentCardDefaultService} from '../../service/payment-card-default.service';
import { forkJoin, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { FirebaseNotificationService } from 'app/shared/service/firebase-notification.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-view-payment-method-list',
    templateUrl: './view-payment-method-list.component.html',
    styleUrls: ['./view-payment-method-list.component.scss'],
    providers: [WindowRefService, PaymentInvoiceService, FirebaseNotificationService]

})
export class ViewPaymentMethodListComponent implements OnInit, OnDestroy, AfterViewInit {

    transactionList: any[];
    loading = false;
    totalRecords = 0;
    isPlatformAdmin: any;
    paymentMethod: { label: string; value: string; }[];
    defaultDate: any;
    msgs = [];
    rowData: any;

    cols: any[];
    size: number;
    page: number = 0;
    sortField = '';
    sortOrder: number;
    selectedSortOption = '';
    selectedField = '';
    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
    numberSortOptions = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];
    fromDate: Date;
    toDate: Date;
    autoPays = [
        {label: 'All', value: null},
        {label: 'Yes', value: 1},
        {label: 'No', value: 0}
    ];
    userCardList: any[];
    cards = [
        { label: 'All', value: null },
        { label: 'Visa', value: 'VISA' },
        { label: 'Master Card', value: 'MASTER_CARD' },
        { label: 'American Express', value: 'AMERICAN_EXPRESS' },
        { label: 'Discover', value: 'DISCOVERY' },
        { label: 'Bank', value: 'BANK' }
    ];
    autoPaySelected: any;
    cardSelected: any;

    searchText: string;
    @ViewChild('searchKeyInput') searchKeyInput: ElementRef;
    source: any;

    private destroyed$ = new Subject<void>();

    showAutoPayConfirmDialog = false;
    clientAutoPaySelected;

    constructor(
        private dropDownsService: DropDownsService,
        private paymentProfileService: PaymentProfileService,
        private authService: AuthService,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private datePipe: DatePipe,
        private paymentTransactionService: PaymentTransactionService,
        private paymentCardDefaultService: PaymentCardDefaultService,
        private paymentInvoiceService: PaymentInvoiceService,
        private firebaseNotificationService: FirebaseNotificationService
    ) {

    }

    ngOnInit() {
        this.fromDate = moment().startOf('month').toDate();
        this.toDate = moment().endOf('month').toDate();
        this.cols = [
            { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
            { field: 'paymentCardType', label: 'Payment Method', sortOptions: '', sort: 'text' },
            { field: 'expirationDate', label: 'Expiration Date', sortOptions: '', sort: 'number' },
            { field: 'autoPay', label: 'Auto Pay', sortOptions: '', sort: 'text' },

        ];
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.loadTable();
    }

    ngAfterViewInit(): void {
        this.source = fromEvent(this.searchKeyInput.nativeElement, 'keyup');
        this.source.pipe(debounceTime(1200), takeUntil(this.destroyed$)).subscribe(() => {
            this.loadTable();
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    getCardPaymentCard(carId) {
        const card = this.userCardList.find(a => a.id === carId);
        return card ? card.paymentCardType : '';
    }
    getCardExprid(carId) {
        const card = this.userCardList.find(a => a.id === carId);
        return card ? card.expire : '';
    }

    loadContentsLazy(event?: LazyLoadEvent) {
        this.loading = true;
        this.sortField = event && event.sortField ? event.sortField : '';
        this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
        const options: any = {
            size: event ? event.rows : 10,
            page: event ? (event.first) / event.rows : 0,
        };
        this.loadPage(options);
    }

    loadTable() {
        this.loading = true;
        const options: any = {
            size: 999999,
            page: 0,
        };
        this.loadPage(options);
    }

    onChangeType() {
    }

    loadPage(options: any) {
        this.loading = true;
        this.transactionList = null;
        this.dropDownsService.getAllClientList({ companyId: this.authService.getUserInfo().companyId, status: 1, size: 99999, page: 0}).subscribe((r) => {
            const allClients = r.data;
            this.paymentCardDefaultService.search({}).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    const paymentCardTypes = this.cardSelected ? [this.cardSelected] : ['VISA', 'MASTER_CARD', 'AMERICAN_EXPRESS', 'DISCOVER'];
                    const autoPaidMethods = resObj.data.content.filter(c => c.autoPay && allClients.find(a => a.key === c.userId));
                    const autoPaidCardIds = autoPaidMethods.filter(c => c.cardId).map(c => c.cardId);
                    const autoPaidBankIds = autoPaidMethods.filter(c => c.bankId).map(c => c.bankId);
                    const autoPaidUserIds = autoPaidMethods.map(c => c.userId);
                    const payload = {
                        paymentCardTypes: paymentCardTypes,
                        ...options
                    };
                    if (this.searchText) {
                        const clientsSearch = allClients.filter(d => d.value.includes(this.searchText));
                        if (this.autoPaySelected === 1) {
                            payload.cardIds = autoPaidCardIds;
                            payload.bankIds = autoPaidBankIds;
                            payload.userIds = autoPaidUserIds.filter(c => clientsSearch.find(client => client.key === c));
                        } else if (this.autoPaySelected === 0) {
                            payload.ignoreCardIds = autoPaidCardIds;
                            payload.ignoreBankIds = autoPaidBankIds;
                            payload.userIds = clientsSearch.map(d => d.key);
                        } else {
                            payload.userIds = clientsSearch.map(d => d.key);
                        }
                    } else {
                        if (this.autoPaySelected === 1) {
                            payload.cardIds = autoPaidCardIds;
                            payload.bankIds = autoPaidBankIds;
                            payload.userIds = autoPaidMethods.map(c => c.userId);
                        } else if (this.autoPaySelected === 0) {
                            payload.ignoreCardIds = autoPaidCardIds;
                            payload.ignoreBankIds = autoPaidBankIds;
                            payload.userIds = allClients.map(d => d.key);
                        } else {
                            payload.userIds = allClients.map(d => d.key);
                        }
                    }
                    const datas = [];
                    const cardPayload = Object.assign({}, payload);
                    cardPayload.paymentCardTypes = paymentCardTypes.length === 1 && paymentCardTypes[0] === 'BANK' ? null : paymentCardTypes;
                    forkJoin([
                        this.paymentProfileService.search(cardPayload),
                        this.paymentProfileService.searchBank(payload)
                    ]).subscribe((details: any[]) => {
                        if (details[0].status === 200) {
                            const list: any[] = details[0].data.content;
                            list.forEach(l => {
                                if (paymentCardTypes.length === 1 && (!l.paymentCardType || paymentCardTypes[0] !== l.paymentCardType.toUpperCase())) {
                                    return;
                                }
                                if (l.userType === 'CLIENT') {
                                    const u = allClients.find(c => c.key.toString() === l.appUserId);
                                    datas.push({
                                        clientId: u.key,
                                        clientName: u ? u.value : '',
                                        paymentCardType: l.paymentCardType,
                                        expirationDate: l.expire,
                                        autoPay: autoPaidCardIds.find(c => c === l.id) ? 'Yes' : 'No'
                                    });
                                }
                            })
                            
                        }

                        if (details[1].status === 200) {
                            const list: any[] = details[1].data.content;
                            list.forEach(l => {
                                if (paymentCardTypes.length === 1 && (!l.bankName || paymentCardTypes[0] !== 'BANK')) {
                                    return;
                                }
                                if (l.userType === 'CLIENT') {
                                    const u = allClients.find(c => c.key.toString() === l.appUserId);
                                    datas.push({
                                        clientId: u.key,
                                        clientName: u ? u.value : '',
                                        paymentCardType: l.bankName,
                                        expirationDate: 'N/A',
                                        autoPay: autoPaidBankIds.find(c => c === l.id) ? 'Yes' : 'No'
                                    });
                                }
                            })
                            
                        }
                        const autoPayClientIds = datas.filter(d => d.autoPay === 'Yes').map(d => d.clientId);
                        if (autoPayClientIds.length > 0) {
                            this.paymentInvoiceService.filter({
                                companyId: this.authService.getCurrentCompanyId(),
                                clientIds: autoPayClientIds,
                                ignoreInvoiceDetails: true,
                                listStatus:  ['Unpaid', 'Partial Paid'],
                                oldInvoiceOnly: false
                            }).subscribe((res: any) => {
                                if (res.status === 'SUCCESS') {
                                    const clientUnPaidInvoices = res.data.content.reduce((result, obj) => {
                                        const clientId = obj.clientId;
                                        if (!result[clientId]) {
                                          result[clientId] = [];
                                        }
                                        result[clientId].push(obj);
                                        return result;
                                      }, {});
                                    datas.forEach(data => {
                                        const unpaidInvoices = clientUnPaidInvoices[data.clientId];
                                        if (unpaidInvoices && unpaidInvoices.length > 0) {
                                            let balance = 0;
                                            unpaidInvoices.forEach(i => {
                                                if (i.balance) {
                                                    balance += i.balance;
                                                }
                                            })
                                            data.balance = balance;
                                            data.unpaidInvoices = unpaidInvoices;
                                        }
                                    });
                                    this.convertToTransactionList(datas);
                                } else {
                                    this.convertToTransactionList(datas);
                                }
                            }, () => this.convertToTransactionList(datas));
                        } else {
                            this.convertToTransactionList(datas);
                        }

                    }, () => this.loading = false);
                    // this.paymentProfileService.search(payload).subscribe((p: any) => {
                    //     if (p.status === 200) {
                    //         const list: any[] = p.data.content;
                    //         list.forEach(l => {
                    //             const u = allClients.find(c => c.key.toString() === l.appUserId);
                    //             this.transactionList.push({
                    //                 clientName: u ? u.value : '',
                    //                 paymentCardType: l.paymentCardType,
                    //                 expirationDate: l.expire,
                    //                 autoPay: autoPaidCardIds.find(c => c === l.id)
                    //             });
                    //         })
                    //         this.sort(this.selectedField, this.sortOrder)
                    //         this.totalRecords = p.data.totalElements;
                    //     } else {
                    //         this.totalRecords = 0;
                    //     }
        
                    //     this.loading = false;
                    // }, () => this.loading = false);
                }
            }, () => this.loading = false);
        }, () => this.loading = false);
    }

    convertToTransactionList(datas) {
        this.loading = false;
        if (this.autoPaySelected === 1) {
            this.transactionList = datas.filter(d => d.autoPay === 'Yes');
        } else if (this.autoPaySelected === 0) {
            this.transactionList = datas.filter(d => d.autoPay === 'No');
        } else {
            this.transactionList = datas;
        }
        this.totalRecords = this.transactionList.length;
    }

    sortingClick(selectedSortOption) {
        const sortForStatus = selectedSortOption;
        selectedSortOption = selectedSortOption === 'ASC' ? 1 : -1;
        this.sort(this.selectedField, selectedSortOption);
    }

    sort(fieldName: string, order: number) {
        this.transactionList.sort((row1, row2) => {
            let val1 = row1[fieldName];
            let val2 = row2[fieldName];
            if (fieldName === 'expirationDate') {
                if (row1[fieldName]) {
                    if (row1[fieldName] === 'N/A') {
                        val1 = 'N/A';
                    } else {
                        val1 = moment(row1[fieldName], 'MM/YYYY').valueOf().toString();
                    }
                }
                if (row2[fieldName]) {
                    if (row2[fieldName] === 'N/A') {
                        val2 = 'N/A';
                    } else {
                        val2 = moment(row2[fieldName], 'MM/YYYY').valueOf().toString();
                    }

                }
            } else {
                val1 = row1[fieldName];
                val2 = row2[fieldName];
            }

            if (val1?.toLowerCase) {
                val1 = val1.toLowerCase();
            }
            if (val2?.toLowerCase) {
                val2 = val2.toLowerCase();
            }
            if (val1 === val2) {
                return 0;
            }
            let result = -1;
            if (val1 > val2) {
                result = 1;
            }
            if (order < 0) {
                result = -result;
            }
            return result;
        });
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sort = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    exportPdf() {
        const options: any = {
            size: 99999,
            page: 0,
            fromDate: this.fromDate ? (new Date(this.fromDate).getTime() + new Date(this.fromDate).getTimezoneOffset() * 60000) : null,
            toDate: this.toDate ? (new Date(this.toDate).getTime() + new Date(this.toDate).getTimezoneOffset() * 60000) : null,
            sortField: 'createdAt',
            sortOrder: 'DESC'

        };
        if (this.authService.isCompanyAdminRole()) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.authService.isClientRole()) {
            options.createdByUsr = this.authService.getCurrentUsername();
        }
        this.paymentTransactionService.search(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const billings = resObj.data.content;
                if (billings && billings.length) {
                    const data: any = {
                        contentList: billings.map(value => {
                            return {
                                date: this.datePipe.transform(value.date, 'MM/dd/yyyy'),
                                transactionId: value.transactionId,
                                invoiceNumber: this.convertArrayToString(value.invoiceNumber),
                                description: 'OPERRWORK Invoices Payment',
                                amount: formatNumber(value.amount, 'en-US', '1.2-2'),
                                paymentMethod: value.paymentMethod,
                                transactionType: 'Authorization and Capture',
                                createdByUsr: value.createdByUsr
                            };
                        })
                    };
                    if (!this.isPlatformAdmin) {
                        data.companyId = this.authService.getCurrentCompany();
                    }
                    data.username = this.authService.getCurrentUsername();
                    // tslint:disable-next-line:no-shadowed-variable
                    this.paymentTransactionService.exportPdf(data).subscribe(res => {
                        if (res.data) {
                            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                            const blobUrl = URL.createObjectURL(blob);
                            window.open(blobUrl, '_blank');
                        } else {
                            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'),
                            detail: this.translatePipe.transform('Problem exporting client pdf list')});
                        }
                    });
                }
            }
        });
    }

    convertArrayToString(array: any) {
        return array.join(', ');
    }

    exportTablePdf() {
        if (this.transactionList && this.transactionList.length) {
            const data: any = {
                contentList: this.transactionList.map((value, idx) => {
                    return {
                        index: idx + 1,
                        clientName: value.clientName,
                        paymentCardType: value.paymentCardType,
                        expirationDate: value.expirationDate,
                        autoPay: value.autoPay
                    };
                })
            };
            if (!this.isPlatformAdmin) {
                data.companyId = this.authService.getCurrentCompanyId();
                data.includeCompany = false;
            } else {
                data.includeCompany = true;
            }
            data.username = this.authService.getCurrentUsername();
            this.paymentTransactionService.exportPaymentMethodPdf(data).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting payment method list' });
                }
            });
        }
    }
    reset() {
        this.cardSelected = null;
        this.autoPaySelected = null;
        this.searchText = '';
        this.loadTable();
    }

    autoPay(rowData) {
        this.clientAutoPaySelected = rowData;
        this.showAutoPayConfirmDialog = true;
    }

    acceptAutoPay() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        const payload = {
            clientId: this.clientAutoPaySelected.clientId,
            companyId: this.authService.getCurrentCompanyId(),
            invoices: this.clientAutoPaySelected.unpaidInvoices
        }
        this.paymentInvoiceService.autoPayInvoices(payload).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                setTimeout(() => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully' });
                    this.sendNewPaymentTransactionNotification(payload);
                    this.closeAutoPay();
                    this.loadTable();
                    this.loading = false;
                }, 1000);

            } else {
                this.messageService.add({ severity: 'error', summary: 'ERROR', detail: res.message });
                this.loading = false;
            }
        }, () => this.loading = false);
    }

    closeAutoPay() {
        this.clientAutoPaySelected = null;
        this.showAutoPayConfirmDialog = false;
    }

    sendNewPaymentTransactionNotification(transaction) {
        this.firebaseNotificationService.saveFirebaseNotification(`/latest-transaction/${this.authService.getCurrentCompanyId()}`, {
            ...transaction,
            updateTime: new Date().getTime()
        }).subscribe();
    }
}
