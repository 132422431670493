import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-user-privacy',
  templateUrl: './user-privacy.component.html'
})
export class UserPrivacyComponent implements OnInit {

  constructor(public app: AppComponent
  ) {
  }

  ngOnInit() {
  }

  tableOfContents(type) {
    if (type == '1') {
      var element = document.getElementById("tableOfContents1");
      element.scrollIntoView();
    } 
    else if (type == '2') {
      var element = document.getElementById("tableOfContents2");
      element.scrollIntoView();
    }
    else if (type == '3') {
      var element = document.getElementById("tableOfContents3");
      element.scrollIntoView();
    }
    else if (type == '4') {
      var element = document.getElementById("tableOfContents4");
      element.scrollIntoView();
    }
    else if (type == '5') {
      var element = document.getElementById("tableOfContents5");
      element.scrollIntoView();
    }
    else if (type == '6') {
      var element = document.getElementById("tableOfContents6");
      element.scrollIntoView();
    }
    else if (type == '7') {
      var element = document.getElementById("tableOfContents7");
      element.scrollIntoView();
    }
    else if (type == '8') {
      var element = document.getElementById("tableOfContents8");
      element.scrollIntoView();
    }
    else if (type == '9') {
      var element = document.getElementById("tableOfContents9");
      element.scrollIntoView();
    }
    else if (type == '10') {
      var element = document.getElementById("tableOfContents10");
      element.scrollIntoView();
    }
    else if (type == '11') {
      var element = document.getElementById("tableOfContents11");
      element.scrollIntoView();
    }
    else if (type == '12') {
      var element = document.getElementById("tableOfContents12");
      element.scrollIntoView();
    }
        
    }
}
