<p-card>
    <form [formGroup]="transferFunds">
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-4">
                    <h1>Transfer Funds</h1>
                </div>
                <div class="ui-g-4"></div>
                <div class="ui-g-4">
                    <p-calendar [showIcon]="true" formControlName="transferDate" [disabled]="true"></p-calendar>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-4"></div>
            <div class="ui-g-2">
                Transfer Funds From
            </div>
            <div class="ui-g-6">
                <p-dropdown
                        [options]="transferFroms"
                        formControlName="transferFundFrom"
                        styleClass="w-100"
                        [autoDisplayFirst]="false"
                        [showClear]="true">
                </p-dropdown>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-4"></div>
            <div class="ui-g-2">
                Transfer Funds To
            </div>
            <div class="ui-g-6">
                <p-dropdown
                        [options]="transferTos"
                        formControlName="transferFundTo"
                        styleClass="w-100"
                        [autoDisplayFirst]="false"
                        [showClear]="true">
                </p-dropdown>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-4">
                    Online Funds Transfer
                </div>
                <div class="ui-g-2">
                    Invoice #:
                </div>
                <div class="ui-g-3">
                    <p-multiSelect
                            [options]="amounts"
                            formControlName="transferAmount"
                            (onChange)="totalInvoice(transferFunds.get('transferAmount')?.value, $event)"
                            id="id_amount_to_selected_label"
                    ></p-multiSelect>
                </div>
                <div class="ui-g-1">
                    Amount:
                </div>
                <div class="ui-g-2">
                    <input pInputText value="${{amountValue}}" disabled="true"/>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-6"></div>
            <div class="ui-g-2" *ngIf="transferFunds.get('transferAmount').value">
                Total: {{transferFunds.get('transferAmount').value.length}} Invoices
            </div>
            <div class="ui-g-1"></div>
            <div class="ui-g-2" *ngIf="totalInvoices">
                ${{totalInvoices | number}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-2">
                    Memo
                </div>
                <div class="ui-g-10">
                    <input style="width: 100%" pInputText formControlName="transferMemo"/>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12" style="text-align: center;">
                <p-button label="Process" style="margin-right: 10px;" (onClick)="showEsignature()"></p-button>
                <p-button label="Cancel" (onClick)="cancel()"></p-button>
            </div>
        </div>
    </form>
    <app-esignature-dialog *ngIf="submittedData" [transferData]="submittedData" [display]="displayEsignatureDialog" (displayChange)="closeCancelDialog($event)"></app-esignature-dialog>
    <app-payment-confirmation-dialog *ngIf="isCompleted" [display]="isCompleted" [data]="confirmedData" (displayChange)="cancel($event)"></app-payment-confirmation-dialog>
</p-card>
