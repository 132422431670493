<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Document Settings' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Document Settings' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker" placeholder="">
						<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="fromDate" name="Startdate"
							   (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
						<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="toDate" name="Enddate"
							   (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Creator' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" [ngModelOptions]="{standalone: true}" [options]="creators" dataKey="id"
							placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedCreatedBy">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Operational Policy' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-location-dot"></i>
					</button>
				</div>
				<p-dropdown [ngModelOptions]="{standalone: true}" [options]="businessTypes" placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedBusinessType">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Role' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-location-dot"></i>
					</button>
				</div>
				<p-dropdown [ngModelOptions]="{standalone: true}" [options]="roles" placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedRole">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button (click)="reset()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset'
				| translate}}</button>
			<button (click)="getAllDocument()" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Search' |
				translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center justify-content-between py-4">
	<h1 class="m-0 ml-2">{{'Browse Settings' | translate}}</h1>
	<button type="button" class="btn btn-primary" (click)="openAddDocumentPopup()" pTooltip="{{'button.add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Documents' | translate}} <span *ngIf="documentDetails">({{documentDetails?.length}})</span></span>
	</p-header>
	<p-table #fdt [value]="documentDetails" dataKey="id" [responsive]="true" [columns]="cols" [loading]="loading" [rows]="10"
             [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="documentDetails?.length">
		<ng-template pTemplate="caption">
			<div class="d-flex justify-content-between w-100">
				<button (click)="exportPdf()" class="btn btn-primary">
					<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
				</button>
				<div class="input-group w-100" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterText" (keyup)="onFilter()" placeholder="{{'Filter' | translate}}" pInputText>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr class="text-left">
				<th>#</th>
				<th>
					<span class="d-flex align-items-center justify-content-between w-100">
					<span class="one-liner" pTooltip="{{'role' | translate}}" tooltipPosition="left">{{'role' | translate}}</span>
					<span>
						<span (click)="op1.toggle($event); selectedField = 'role'">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</span>
					</span>

				</th>
				<th>
					<span class="d-flex align-items-center justify-content-between w-100">
					<span class="one-liner">
						<p-checkbox [(ngModel)]="selectedVisibleAll" [ngModelOptions]="{standalone: true}" binary="true" (onChange)="onSelectedVisibleAll($event)" pTooltip="{{'Select All' | translate}}" tooltipPosition="left"></p-checkbox>
						<span  pTooltip="{{'Select All' | translate}}" tooltipPosition="left">{{'Select All' | translate}}</span>
					</span>
					<span>
						<span (click)="op1.toggle($event); selectedField = 'documentName';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</span>
					</span>
				</th>
				<th class="text-center">
					<i class="fa-solid fa-asterisk text-danger m-auto" pTooltip="{{'Make Required' | translate}}" tooltipPosition="left"></i>
				</th>
				<th>
					<span class="d-flex align-items-center justify-content-between w-100">
					<span class="one-liner" pTooltip="{{'Business Type' | translate}}" tooltipPosition="left">{{'Business Type' | translate}}</span>
					<span>
						<span (click)="op1.toggle($event); selectedField = 'operationalPolicy';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</span>
					</span>
				</th>
				<th>
					<span class="one-liner" pTooltip="{{'Material' | translate}}" tooltipPosition="left">{{'Material' | translate}}</span>
				</th>
				<th>
					<span class="one-liner" pTooltip="{{'Info' | translate}}" tooltipPosition="left">{{'Info' | translate}}</span>
				</th>
				<th>
					<span class="d-flex align-items-center justify-content-between w-100">
					<span class="one-liner" pTooltip="{{'Created By' | translate}}" tooltipPosition="left">{{'Created By' | translate}}</span>
					<span>
						<span (click)="op1.toggle($event); selectedField = 'createdBy'">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</span>
					</span>
				</th>
				<th>
					<span class="d-flex align-items-center justify-content-between w-100">
					<span class="one-liner" pTooltip="{{'Added Date' | translate}}" tooltipPosition="left">{{'Added Date' | translate}}</span>
					<span>
						<span (click)="op2.toggle($event); selectedField = 'createdAt';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</span>
					</span>
				</th>
				<th class="text-center">
					<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr [pSelectableRow]="rowData">
				<td>{{i + 1}}</td>
				<td class="p-0">
					<span class="d-flex align-items-center">
						<span class="mobile-table-label text-nowrap">{{'Role' | translate}}: </span>
						<p-multiSelect id="roleField" [options]="documentRoles"
													 (onChange)="changeRequired(rowData)"
													 [(ngModel)]="rowData.selectedRole" [ngModelOptions]="{standalone: true}"
													 optionLabel="label" ></p-multiSelect>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label text-nowrap">{{'Visiblity' | translate}}: </span>
						<p-checkbox [(ngModel)]="rowData.isVisible" (onChange)="changeRequired(rowData)" binary="true" name="visible" class="ml-1" [ngModelOptions]="{standalone: true}" (onChange)="onSelectedVisible($event, rowData)"></p-checkbox> {{rowData.documentName}}
					</span>
					
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label text-nowrap">{{'Make Required' | translate}}: </span>
						<p-checkbox [(ngModel)]="rowData.isRequired" (onChange)="changeRequired(rowData)" binary="true" name="required" [ngModelOptions]="{standalone: true}" [disabled]="!rowData.isVisible" pTooltip="{{'Make Required' | translate}}"></p-checkbox>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Business Type' | translate}}: </span>
						<span *ngIf="rowData.operationalPolicy" pTooltip="{{rowData.operationalPolicy}}">{{rowData.operationalPolicy}}</span>
						<span *ngIf="!rowData.operationalPolicy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<i *ngIf="rowData.documentIds && rowData.documentIds.length" class="fa-solid fa-paperclip text-success" pTooltip="{{'Material' | translate}}" tooltipPosition="left" (click)="getAllMaterials(rowData.documentIds)"></i>
				</td>
				<td>
					<span *ngIf="rowData.information && rowData.information.trim() != ''">
						<i style="cursor: pointer;" class="fa-solid fa-info-circle" (click)="information = rowData.information; documentInformation.toggle($event)"></i>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created By' | translate}}: </span>
						<span *ngIf="rowData.createdBy" pTooltip="{{rowData.createdBy}}">{{rowData.createdBy}}</span>
						<span *ngIf="!rowData.createdBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Added Date' | translate}}: </span>
						<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="d-flex align-items-center">
						<span class="mobile-table-label">{{ 'Actions' | translate}}: </span>
						<i class="fa-solid fa-pen-to-square text-primary mx-1" (click)="editContent(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
						<i class="fa-solid fa-xmark text-danger mx-1" (click)="onDeleteClick(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<p-dialog [(visible)]="showConfirmDialog" [modal]="true" [style]="{'width': '90%', 'max-width': '500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
    </div>
    <p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" class="btn btn-danger" (click)="showConfirmDialog = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="deleteContent();showConfirmDialog = false"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>



<p-dialog [(visible)]="showAddDocumentPopup" *ngIf="showAddDocumentPopup" [modal]="true" [dismissableMask]="true" [style]="{'width':'90%', 'max-width':'500px'}">
    <p-header>
        <span *ngIf="!documentSettingsId"><i class="fa-solid fa-circle-plus mr-2"></i>{{'header.addNewDocument' | translate}}</span>
        <span *ngIf="documentSettingsId"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit Document' | translate}}</span>
    </p-header>
		<app-document-settings-form [documentSettings]="documentDetails" [companyId]="companyId" [documentSettingsId] = "documentSettingsId" (onDocumentSettingsHide)="closeAddDocumentPopup($event)"></app-document-settings-form>
</p-dialog>

<p-overlayPanel #documentInformation appendTo="body">
	<div style=" max-width: 500px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			Information
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;" [innerHtml]="information">
		</div>
	</div>
</p-overlayPanel>


<p-dialog header="{{'View Document' | translate}}" [(visible)]="showViewFileDialog" [style]="{width: '50vw'}">
	<img class="w-100" [src]="fileUrl" alt="">
</p-dialog>

<p-dialog header="{{'View Document' | translate}}" [(visible)]="documentOverview" [style]="{width: '50vw'}">
	<div *ngFor="let file of materials; let i = index" class="w-100">
		<div class="d-flex align-items-center justify-content-between w-100">
			<span class="one-liner" style="max-height: 2rem;line-height: 2;" data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse">{{ i + 1 }}&#41; {{getFileName(file.fileName)}}</span>
			<span class="d-flex">
				<i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="onViewDocument(file.id); $event.stopPropagation()" pTooltip="Open" tooltipPosition="left"></i>
			</span>
		</div>
	</div>
</p-dialog>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
