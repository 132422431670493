import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TotalJobService extends AbstractService<any>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.TOTAL_JOB_DETAIL, '');
  }

  jobDetailDropdown(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}dropdown`, options).pipe(map(resp => resp));
  }
}
