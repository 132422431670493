<form [formGroup]="polygonForm">
	<div class="ui-g p-2 pb-4">
		<div class="ui-g-4 ui-sm-12">
			<label>{{'Turf Name' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div>
				<input pInputText type="text" maxlength="100" formControlName="polygonName" placeholder="{{'Give it a name' | translate}}">
			</div>
		</div>
		<div class="ui-g-4 ui-sm-12">
			<label>{{'Assign to Walker' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-location-dot"></i>
					</button>
				</div>
				<p-multiSelect [options]="walkerList" optionLabel="value" [(ngModel)]="selectedWalkers" formControlName="selectedWalker" defaultLabel="{{'Select Items' | translate}}"  emptyFilterMessage="{{'No results found' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="one-liner" style="width: 93% !important">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-2 ui-sm-12">
			<label>{{'Color' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-palette"></i>
					</button>
				</div>
				<p-colorPicker [style]="{width: '100%'}" formControlName="color"></p-colorPicker>
			</div>
		</div>
		<div class="ui-g-2 ui-sm-12">
			<label>{{'Display Mode' | translate}}</label>
			<span class="d-flex">
				<button class="btn cursor-pointer flex-grow-1 mx-1" id="button-addon2" [ngClass]="isMapView ? 'btn-white' : 'btn-primary'" (click)="isMapView = false" pTooltip="{{'Map' | translate}}" tooltipPosition="left">
					<i class="fa-solid fa-list cursor-pointer" [ngClass]="isMapView ? 'text-primary' : ''"></i>
				</button>
				<button class="btn cursor-pointer flex-grow-1 mx-1" id="button-addon2" [ngClass]="isMapView ? 'btn-primary' : 'btn-white'" (click)="isMapView = true" pTooltip="{{'Details' | translate}}" tooltipPosition="left">
					<i class="fa-solid fa-map" [ngClass]="isMapView ? '' : 'text-primary'"></i>
				</button>
			</span>
		</div>
	</div>

	<div class="ui-g">
		<div class="ui-g-12 p-0" [hidden]="!isMapView">
			<agm-map class="map-container" [latitude]="40.7830603" [longitude]="-73.9712488">
				<turf-polygon-drawer-map [clearPolygons]="clearPolygons" [zones]="zones" [turfs]="turfs" [turfData]="turfData"
					[goBack]="polygonLength" (polygonChanged)="polygonChanged($event)" (pointClicked)="pointClicked($event)"
					[removedPoint]="selectedMovePoint">
				</turf-polygon-drawer-map>
				<voter-markercluster-map [selectedVoterIds]="selectedVoterIds" [selectedTurfVoterIds]="selectedTurfVoterIds">
				</voter-markercluster-map>
			</agm-map>
		</div>
		<div class="ui-g-12 p-0" [hidden]="isMapView">
			<p-table #table [columns]="voterColumns" [value]="turfVoters" [totalRecords]="turfVoters?.length || 0" [lazy]="false" [responsive]="true" [scrollable]="true" [rows]="100" scrollHeight="500px" [virtualScroll]="true" [virtualRowHeight]="44">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of voterColumns">
							<span class="one-liner" pTooltip="{{col.header | translate}}" tooltipPosition="left">{{col.header | translate}}</span>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-columns>
					<tr>
						<td *ngFor="let col of voterColumns">
							<ng-container [ngSwitch]="col.field">
								<span class="one-liner" *ngSwitchDefault>
									<span class="mobile-table-label">{{col?.header}}: </span>
									<span *ngIf="rowData[col.field]" pTooltip="{{rowData[col.field]}}" tooltipPosition="left">{{rowData[col.field]}}</span>
									<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
								</span>
								<span class="d-flex align-items-center" *ngSwitchCase="'questionair'">
									<span *ngIf="!rowData.status" class="text-muted">{{'no data' | translate}}</span>
									<span class="one-liner c-pointer" *ngIf="rowData.status === 'Completed' && rowData.validate">
										<span class="mobile-table-label">{{'Visit Date' | translate}}: </span><span class="badge badge-muted" style="cursor: pointer">{{'Signed' | translate}}<i class="fa-solid fa-check ml-1"></i></span>
									</span>
									<span class="one-liner c-pointer text-underline" *ngIf="rowData.status === 'Completed' && !rowData.validate">
										<span class="mobile-table-label">{{'Visit Date' | translate}}: </span><span class="badge badge-success" style="cursor: pointer">{{'Signed' | translate}}<i class="fa-solid fa-check ml-1"></i></span>
									</span>
									<span class="one-liner c-pointer text-underline" *ngIf="rowData.status && rowData.status !== 'Completed' && !showQuestionOnListWalk(rowData)">
										<span class="mobile-table-label">{{'Question' | translate}}: </span><span class="badge badge-primary" style="cursor: pointer">{{'Start' | translate}}<i class="fa-solid fa-arrow-right ml-1"></i></span>
									</span>
									<span class="one-liner c-pointer text-underline" *ngIf="rowData.status && rowData.status !== 'Completed' && showQuestionOnListWalk(rowData)">
										<span class="mobile-table-label">{{'Question' | translate}}: </span>
										<span *ngIf="rowData.answerReason === 'Non available'" class="badge badge-danger" style="cursor: pointer">{{'Unavailable' | translate}}<i class="fa-solid fa-xmark ml-1"></i></span>
										<span *ngIf="rowData.answerReason === 'Died'" class="badge badge-secondary" style="cursor: pointer">{{'Deceased' | translate}}<i class="fa-solid fa-ban ml-1"></i></span>
										<span *ngIf="!(rowData.answerReason === 'Non available') && !(rowData.answerReason === 'Died')" pTooltip="{{rowData.answerReason}}"class="badge badge-orange" style="cursor: pointer">{{rowData.answerReason}}<i class="fa-solid fa-triangle-exclamation ml-1"></i></span>
									</span>
								</span>
								<span class="d-flex align-items-center" *ngSwitchCase="'action'">
									<span class="mobile-table-label">{{'Actions' | translate}}: </span>
									<i class="fa-solid fa-xmark text-danger c-pointer mx-1" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" (click)="removeTurfVoter(rowData)"></i>
								</span>
							</ng-container>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="!loading">
						<td [attr.colspan]="5" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loading">
						<td [attr.colspan]="5" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<div class="d-flex align-items-center justify-content-between w-100 p-3">
		<span>
			<button class="btn btn-danger mx-1" (click)="cancel()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' |
				translate}}</button>
			<button type="submit" class="btn btn-orange mx-1" (click)="removeAll()"
				pTooltip="{{'Delete Polygon' | translate}}"><i class="fa-solid fa-trash mr-2"></i>{{'Polygon' |
				translate}}</button>
			<button *ngIf="selectedPoint" class="btn btn-danger mx-1" (click)="removePoint()"><i
					class="fa-solid fa-xmark mr-2"></i>{{'Point' | translate}}</button>
		</span>
		<span>
			<button type="button" class="btn btn-success mx-1" (click)="sendSms(turfData)">Send SMS/MMS</button>
			<button type="submit" class="btn btn-primary" (click)="createTurf()"
				[disabled]="!isSaveButtonEnabled || processing" id="btnSave"><i class="fa-solid fa-check mr-2"></i>{{'Submit' |
				translate}}</button>
		</span>
	</div>
</form>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="outOfZoneDialog" class="modal_in" [modal]='true'>
		<div class="ui-g">
				{{'The turf you created is outside of the zone and won\'t be saved, do you want to continue?' | translate}}
		</div>
		<p-footer>
				<div class="ui-g">
						<div class="ui-g-12">
								<button type="button" pButton label="{{'yes' | translate}}" (click)="outOfZoneDialog = false; cancel()"></button>
								<button type="button" pButton label="{{'no' | translate}}" (click)="outOfZoneDialog = false"></button>
						</div>
				</div>
		</p-footer>
</p-dialog>

<p-dialog *ngIf="turfData" header="{{'Confirmation' | translate}}" [(visible)]="deleteTurfDialog"
		class="modal_in" [modal]='true'>
		<div class="ui-g">
			The turf {{turfData.title}} will be deleted, are you sure you want to proceed?
		</div>
		<p-footer>
				<div class="ui-g">
						<div class="ui-g-12">
								<button type="button" pButton label="{{'yes' | translate}}" (click)="deleteTurfDialog = false; deleteTurf()"></button>
								<button type="button" pButton label="{{'no' | translate}}" (click)="deleteTurfDialog = false"></button>
						</div>
				</div>
		</p-footer>
</p-dialog>