import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "app/shared/model/constants";
import { environment } from "environments/environment";
import { AbstractService } from "../abstract.service";

@Injectable({ providedIn: 'root' })
export class UserGatewayService extends AbstractService<any> {
  private url = `${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/api/v2/user`;

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.USER_GATEWAY, '');
  }

  isAccountNameAvail(username: any) {
    const option = { username: username };
    return this.http.get(`${this.baseUrlV2}isExistUsername`, { params: option });
  }

  findEmailByUsername(usernames: string[]) {
    return this.http.post(`${this.baseUrlV2}findEmailByUsername`, usernames);
  }
}