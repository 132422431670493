import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../app/shared/model/constants';
import { AbstractService } from '../../../../app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FundraisersModel } from '../model/fundraisers.model';
import { environment } from 'environments/environment';
import {Page} from '../../../shared/model/page.model';

@Injectable({ providedIn: 'root' })
export class FundraisersService extends AbstractService<FundraisersModel> {

    notificationUrl: string;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.FUNDRAISERS, '');
        this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
        this.sendUrl = `${environment.v2_server_ip}`;
    }

    search(options: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    addFundraisers(entity: any): Observable<any> {
        const url = this.baseUrlV2;
        return this.http.post<any>(url, entity).pipe(map(res => res));
    }

    updateFundraisers(entity: any, id: any): Observable<any> {
        return this.http.put<any>(`${this.baseUrlV2}${id}`, entity).pipe(map(res => res));
    }

    findFundraisersById(id: number): Observable<any> {
        return this.http.get(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    deleteFundraisers(id: number): Observable<any> {
        return this.http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    getCreatedByList(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('companyId', options.companyId);
        return this._http.get<any>(`${this.baseUrlV2}getCreatedBy`, { params }).pipe(map(resp => resp));
    }
    exportPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

    exportDetailPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportDetailPdf`, data).pipe(map(resp => resp));
    }

    getHistory(options: any) {
        return this._http.post<any>(this.baseUrlV2 +  "history", options).pipe(map(rsp => rsp));
    }

    addFundraisersDetail(entity: any): Observable<any> {
        const url = this.baseUrlV2 + 'save-fundraisers-detail';
        // const url = 'http://localhost:8010/api/v2/crm/fundraisers/save-fundraisers-detail';
        return this.http.post<any>(url, entity).pipe(map(res => res));
    }

    findFundraisersDetail(id: number): Observable<any> {
        return this.http.get(`${this.baseUrlV2}fundraisers-detail/${id}`).pipe(map(data => data));
    }

    getBaseUrlV2() {
        return this.sendUrl;
    }
}
