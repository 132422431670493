<!--<p-tabView>-->
  <!--<p-tabPanel header="Payment Method">-->
    <!--<app-payment-method></app-payment-method>-->
  <!--</p-tabPanel>-->
  <!--<p-tabPanel header="Payment Process">-->
    <!--<app-payment-details></app-payment-details>-->
  <!--</p-tabPanel>-->

  <!--<p-tabPanel header="Invoice Search">-->
    <!--<app-invoice-list></app-invoice-list>-->
  <!--</p-tabPanel>-->
<!--</p-tabView>-->
<p-tabMenu [model]="paymentItems" [activeItem]="activeItem">
  <ng-template pTemplate="item" let-item let-i="index" >
    <div *ngIf="item.visible ? item.visible : true">
      {{item.label | translate}}
    </div>
  </ng-template>
</p-tabMenu>
<div class="ui-g">
  <div class="ui-g-12 p-0">
    <router-outlet></router-outlet>
  </div>
</div>
