import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Note, NoteFolder } from 'app/note/model/note.model';

@Component({
  selector: 'app-note-item',
  templateUrl: './note-item.component.html',
  styleUrls: ['./note-item.component.scss']
})
export class NoteItemComponent implements OnInit {
  @Input() data: Note;
  @Input() folder: NoteFolder;
  @Input() isActive: Boolean;
  @Output() onEditName = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onShare = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    // console.log(this.data)
  }
  editName(event) {
    event.stopPropagation();
    this.onEditName.emit({ ...this.data });;
  }
  delete(event) {
    event.stopPropagation();
    this.onDelete.emit({ ...this.data })
  }
  share(event) {
    event.stopPropagation();
    console.log('share:',this.data);
    
    this.onShare.emit({ ...this.data })
  }
  eleminateHtmlTags(value) {
    if (!value || value === '') return value;
    return value.replace(/<[^>]*>/g, '');
  }
}
