import { IpAddressSetting } from '../model/v2/ip-address.model';
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {CompanySearch} from '../../shared/model/search/compnay.search';

@Injectable({ providedIn: 'root' })
export class IpAddressSettingServiceV2 extends AbstractServiceV2<IpAddressSetting, CompanySearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY, '');
    }

    findAllByCompanyId(companyId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/${companyId}/setting/ip-address`).pipe(map(resp => resp));
    }

    deleteIpAddress(companyId: any, id: any): Observable<any> {
        const url = `${this.baseUrlV2}/${companyId}/setting/ip-address/${id}`;
        return this.http.delete(url).pipe(map((resp) => resp));
    }

    saveIpAddress(companyId: any, entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/${companyId}/setting/ip-address`;
        return this._http.post<any>(url, entity).pipe(map(res => res));
    }

    updateIpAddress(companyId: any, id: any, entity: any): Observable<any> {
        // const url = `http://localhost:8003/api/v2/company/${companyId}/setting/ip-address/${id}`;
        const url = `${this.baseUrlV2}/${companyId}/setting/ip-address/${id}`;
        return this._http.put<any>(url, entity).pipe(map(res => res));
    }
}
