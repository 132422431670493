<h1 class="mt-4">{{'Total Jobs' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="d-flex flex-grow-1 justify-content-between align-items-between">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Billing Jobs' | translate}}</span>
		<a href="javascript:void(0);" class="text-dark mr-3" (click)="showSignOffReminderDialog()"><i class="fa-solid fa-bell text-primary mr-2"></i>{{'Billing Sign-Off' | translate}} </a>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="companies" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="companySelected" (onChange)="chooseCompany($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client/Base' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="clientList" filter="true" [disabled]="!isChooseClient"
					placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="clientSelected" (onChange)="onChangeClient($event)"
					appendTo="body" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12" *ngIf="!isClientAccount">
			<label>{{'Uploader' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="createdByList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="createdSelected">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label >{{'Uploaded' | translate}} :</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>

				<p-multiSelect id="id_to_selected_label" class="flex-grow-1" styleClass="w-100"
							   [options]="uploadDateList" [ngModelOptions]="{standalone: true}"
							   [(ngModel)]="uploadDates" (onChange)="onSelectChange($event)">
					<ng-template pTemplate="item" let-item>
					<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
				</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Display Columns' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-table-columns"></i>
					</button>
				</div>
				<p-multiSelect class="w-100" [options]="customizeColumns" [(ngModel)]="customColumnName"
					[ngModelOptions]="{standalone: true}" [filter]="false" [style]="{'width':'100%', 'height':'40px'}"
					(onChange)="onSelectColumn($event)" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i
					class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="loadBillingReports()"><i
					class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-between py-4">
	<h1 class="m-0 ml-2">{{'Browse Jobs' | translate}}</h1>
</div>
<div *ngIf="displayMessage" class="alert alert-info alert-dismissible d-flex align-items-center c-pointer fade show mb-4" role="alert">
	<span (click)="showUpdateBillingConfirmDialog = true"><i class="fa-solid fa-circle-info mr-2"></i> {{'When you complete your updates, please notify the Billing Team by clicking this alert' | translate}}.</span>
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{ 'Total Jobs' | translate }} ({{totalRecords || 0 | number }})</span>
	</p-header>
	<p-table #dt [scrollable]="false" selectionMode="single" [columns]="cols" [value]="billingReports" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [resizableColumns]="true" dataKey="id" (onLazyLoad)="loadBillingReports($event)" [totalRecords]="totalRecords" [lazy]="true" [responsive]="true">

		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
				<div class="d-flex">
					<span class="d-flex" id="table-buttons" *ngIf="!isClientAccount">
						<button type="button" *ngIf="this.toggleMultiDeleteOptions.show" [ngClass]="toggleMultiDeleteOptions.label === 'Delete Selected' ? 'btn-orange' : 'btn-purple'" class="btn mx-1" (click)="toggleDelete()"><i class="fa-solid fa-square-check mr-2"></i>{{toggleMultiDeleteOptions.label | translate}}</button>
						<button *ngIf="this.toggleMultiDeleteOptions.allowSelection" type="button" class="btn btn-danger mx-1" (click)="cancelDelete()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
					</span>
					<button *ngIf="!isClientAccount" type="button" [disabled]="!downloadEnable" (click)="op3.toggle($event)" class="btn btn-primary mx-1" id="export-button">
						<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export' | translate}}</span>
					</button>
					<button *ngIf="isClientAccount" type="button" [disabled]="!downloadEnable" (click)="downloadExcelNewFormat()" class="btn btn-primary mx-1">
						<span class="text-nowrap"><i class="fa-solid fa-file-excel mr-2"></i> {{'Export XLS' | translate}}</span>
					</button>
					<button type="button" [disabled]="!clientSelected || !fromDate || !toDate" (click)="importBtn()" class="btn btn-primary mx-1">
						<i class="fa-solid fa-file-import mr-2"></i> {{'Import' | translate}}
					</button>
					<button type="button" [disabled]="!downloadEnable" (click)="uploadBtn()" class="btn btn-info mx-1">
						<i class="fa-solid fa-upload mr-2"></i> {{'Upload' | translate}}
					</button>
					<button type="button" [disabled]="!clientSelected || !uploadDates" (click)="showSendEmail = true" class="btn btn-info mx-1">
						<i class="fa-solid fa-envelope-circle-check mr-2"></i> {{'Email to Client' | translate}}
					</button>
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="searchText" (ngModelChange)="searchBilling($event)" (keyup)="searchBilling($event)" placeholder="{{'Filter' | translate}}">
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngIf="toggleMultiDeleteOptions.allowSelection" style="width: 45px !important">
					<p-checkbox *ngIf="ableCheckAll" [(ngModel)]="toggleMultiDeleteOptions.checkAll" [disabled]="ableCheckAll" [ngModelOptions]="{standalone: true}" binary="true" (onChange)="onGroupCheckAllToggle($event)"></p-checkbox>
					<p-checkbox *ngIf="!ableCheckAll" [(ngModel)]="toggleMultiDeleteOptions.checkAll" [disabled]="ableCheckAll" pTooltip="{{'Cant delete after 24 hours' | translate}}" [ngModelOptions]="{standalone: true}" binary="true" (onChange)="onGroupCheckAllToggle($event)"></p-checkbox>
				</th>
				<th class="adjust-padding" pResizableColumn *ngFor="let col of getShowHeader()" [ngStyle]="{'display': col.display}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;loadSortOption(col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'serviceDate'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="op4.toggle($event); loadSortOption(col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'updatedAt'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="op4.toggle($event); loadSortOption(col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'createdAt'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="op4.toggle($event); loadSortOption(col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'actions'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td *ngIf="toggleMultiDeleteOptions.allowSelection">
					<p-checkbox *ngIf="rowData.isDisable" [(ngModel)]="rowData.isSelected" binary="true" [disabled]="rowData.isDisable" pTooltip="{{'Cant delete after 24 hours' | translate}}" [ngModelOptions]="{standalone: true}" (onChange)="onGroupSelectedChanged()"></p-checkbox>
					<p-checkbox *ngIf="!rowData.isDisable" [(ngModel)]="rowData.isSelected" binary="true" [disabled]="rowData.isDisable" [ngModelOptions]="{standalone: true}" (onChange)="onGroupSelectedChanged(rowData)"></p-checkbox>
				</td>
				<ng-container *ngFor="let col of getShowHeader()" [ngSwitch]="col.field">

					<td *ngSwitchCase="'serviceDate'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Service Date' | translate}}: </span>
							<span *ngIf="rowData.serviceDate" pTooltip="{{rowData.serviceDate | timezoneDate: 'MM/DD/YYYY' : 'UTC'}}">{{rowData.serviceDate | timezoneDate: 'MM/DD/YYYY' : 'UTC'}}</span>
							<span *ngIf="!rowData.serviceDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'invoiceNumber'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}</span>
							<span *ngIf="rowData.invoiceNumber" pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					
					<td *ngSwitchCase="'fleetNumber'" pEditableColumn (click)="openModal()">
						<span class="d-flex text-truncate">
							<span class="mobile-table-label">{{'Fleet Number' | translate}}: </span>
							<span *ngIf="!rowData.fleetNumber" class="text-muted">{{'no data' | translate}}</span>
							<p-cellEditor class="w-100">
								<ng-template pTemplate="input">
									<input pInputText type="text" [(ngModel)]="rowData.fleetNumber" (keydown.enter)="updateData(rowData, 'Fleet')" (blur)="updateData(rowData, 'Fleet')" style="background: transparent !important">
								</ng-template>
								<ng-template pTemplate="output">
									<div *ngIf="rowData.fleetNumber" class="d-flex align-items-center justify-content-between w-100">
										<span pTooltip="{{rowData.fleetNumber}}" class="flex-grow-1">{{rowData.fleetNumber}}</span><i class="fa-solid fa-pen-to-square text-muted pull-right" pTooltip="{{'Edit' | translate}}"></i>
									</div>
								</ng-template>
							</p-cellEditor>
						</span>
					</td>

					<td *ngSwitchCase="'tollFee'" pEditableColumn (click)="openModal()">
						<span class="d-flex text-truncate">
							<span class="mobile-table-label">{{'Tolls' | translate}}: </span>
							<span *ngIf="!rowData.tollFee || rowData.tollFee === '' || rowData.tollFee == null" class="text-muted">{{'no data' | translate}}</span>
							<p-cellEditor class="w-100">
								<ng-template pTemplate="input">
									<input pInputText type="text" [(ngModel)]="rowData.tollFee" (keydown.enter)="updateData(rowData, 'Tolls')" (blur)="updateData(rowData, 'Tolls')">
								</ng-template>
								<ng-template pTemplate="output">
									<div class="d-flex align-items-center justify-content-between w-100" style="position: relative;">
										<span *ngIf="rowData.tollFee && !(rowData.tollFee === '') && !(rowData.tollFee == null)" pTooltip="{{rowData.tollFee | currency : 'USD':'symbol':'1.2-2'}}" class="flex-grow-1">{{rowData.tollFee | currency : 'USD':'symbol':'1.2-2'}}</span>
										<i class="fa-solid fa-pen-to-square text-muted pull-right" pTooltip="{{'Edit' | translate}}" style="position: absolute; top: 0; right: 0;"></i>
									</div>
								</ng-template>
							</p-cellEditor>
						</span>
					</td>

					<td *ngSwitchCase="'name'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Name' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'cin'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'CIN' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'tripPrice'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Trip Price' | translate}}</span>
							<span *ngIf="rowData.tripPrice" pTooltip="${{rowData.tripPrice | number : '1.2-2'}}">${{rowData.tripPrice | number : '1.2-2'}}</span>
							<span *ngIf="!rowData.tripPrice" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'legMileage'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Mileage' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'legId'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Leg ID' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'clientId'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client/Base' | translate}}</span>{{getClient(rowData.clientId)}}
						</span>
					</td>

					<td *ngSwitchCase="'createdByUsr'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client/Base' | translate}}</span>{{rowData.createdByUsr}}
						</span>
					</td>
					
					<td *ngSwitchCase="'createdAt'">
						<span class="one-liner" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy HH:mm:ss a'}}">
							<span class="mobile-table-label">{{'Created' | translate}}</span>{{rowData.createdAt | date: 'MM/dd/yyyy HH:mm:ss a'}}
						</span>
					</td>

					<td *ngSwitchCase="'updatedAt'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Updated' | translate}}:</span>
							<span *ngIf="rowData.updatedAt" pTooltip="{{rowData.updatedAt | date: 'MM/dd/yyyy HH:mm:ss a'}}">{{rowData.updatedAt | date: 'MM/dd/yyyy HH:mm:ss a'}}</span>
							<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'gender'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Gender' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'phone'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Phone' | translate}}: </span>
							<span *ngIf="rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>

					<td *ngSwitchCase="'pickUpDate'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'P/U Date' | translate}}: </span>{{rowData.pickUpDate | timezoneDate: 'MM/DD/YYYY' : 'UTC'}}
						</span>
					</td>

			


					<ng-container *ngIf="isPermitted">
						<td *ngSwitchCase="'actions'" class="text-center">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i class="fa-solid fa-xmark text-danger c-pointer" (click)="deleteRecord(rowData, 2)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
							</span>
						</td>
					</ng-container>
					<ng-container *ngIf="!isPermitted">
						<td *ngSwitchCase="'actions'" class="text-center">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i class="fa-solid fa-xmark text-muted" pTooltip="{{'Not Allowed' | translate}}" tooltipPosition="left"></i>
							</span>
						</td>
					</ng-container>
					<td *ngSwitchDefault>
						<span class="one-liner">
							<span class="mobile-table-label"></span>
							<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
							<span *ngIf="!this.rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>
		
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="getShowHeader().length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="getShowHeader().length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>

	</p-table>
</p-panel>



<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog header="Upload" [(visible)]="showUploaded" class="modal_in" [modal]='true' [style]="{'width': '900px'}" (onHide)="closePopupUploaded()">
	<div class="ui-g">
		<div class="ui-g-12">
			<label>{{'Client/Base' | translate}} :</label>
			<p-dropdown [options]="clientList" filter="true" [style]="{'width': '100%'}"
				[disabled]="!isChooseClientUpload" class="employee-list-dropdowns" placeholder="Please Select"
				[(ngModel)]="clientSelectedUpload" appendTo="body">
			</p-dropdown>
		</div>
		<div class="ui-g-12 m-2 text-center">
			<p-fileUpload #fileUpload accept=".csv,.xlsx,.xls" customUpload="true" (onUpload)="onUpload($event)" 
				maxFileSize="100000000" (uploadHandler)="importReports($event)" multiple="true" [disabled]='!clientSelectedUpload'>
			</p-fileUpload>
			<ng-template pTemplate="content">
				<ul *ngIf="uploadedFiles.length">
					<li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
				</ul>
			</ng-template>
		</div>
	</div>
</p-dialog>

<p-dialog [showHeader]="false" [(visible)]="showProgressBar" styleClass="w-50" id="id_progress_bar_dialog">
	<div class="card card-w-title p-0 border-0 m-0">
		<div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
			<div class="ui-g-12 ui-md-12">
				<p style="margin: 0 0 0 50%;">{{progressBarValue}}%</p>
				<p-progressBar [value]="progressBarValue"></p-progressBar>
			</div>
			<div class="ui-g-12 ui-md-12 text-center pb-3">
				<button pButton class="w-auto" label="Close" (click)="showProgressBar=false"></button>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Confirmation" width="500px" [(visible)]="showConfirmDialog" class="modal_in" [modal]='true'>
	<div class="ui-g p-2" *ngIf="!toggleMultiDeleteOptions.allowSelection">
		{{'Are you sure you want to delete this record?' | translate}}
	</div>
	<div class="ui-g p-2" *ngIf="toggleMultiDeleteOptions.allowSelection">
		{{'Are you sure you want to delete the selected records?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12 p-1" align="center">
				<button type="button" pButton label="Yes" (click)="accept()"></button>
				<button type="button" pButton label="No" (click)="reject()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showProcessUpdateComplete" styleClass="w-50" id="id_process_complete_dialog">
	<div class="card card-w-title p-0 border-0 m-0">
		<p class="tabCardHead">
			<span>{{'Process Completed' | translate}}</span>
		</p>
		<div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
			<div class="ui-g-12 ui-md-12">
				<p-card>
					<p-header class="text-center font-weight-bold">
						Result
					</p-header>
					<div *ngIf="isPreviousUpdatedDate">
						<span class="font-weight-bold">{{'This File was previously upload on' | translate}}: </span>
						<span>{{previousUpdatedDate}}</span>
					</div>
					<div>
						<span class="font-weight-bold">{{'Total Record updated' | translate}}: </span>
						<span>{{totalUpdatedRecords}}</span>
					</div>
					<div>
						<span class="font-weight-bold">{{'New record' | translate}}: </span>
						<span>{{totalNewRecords}}</span>
					</div>
					<div>
						<span class="font-weight-bold">{{'File Uploaded' | translate}}: </span>
						<span>{{totalFileUpload}}</span>
					</div>
				</p-card>
			</div>
			<div class="ui-g-12 ui-md-12 text-center pb-3">
				<button pButton class="w-auto" label="{{'Ok' | translate}}" (click)="closeProcessCompleteDialog()"></button>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showUpdateBillingConfirmDialog" class="modal_in"
	[modal]='true'>
	<div class="ui-g p-2">
		{{'Are you sure you want to send notification to Billing Team now?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12 p-1" align="center">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="updateBillingAccept()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="updateBillingReject()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showSignOffReminder" [modal]="true" id="id_process_complete_dialog" class="modal_in" [style]="{'width':'100%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-bell mr-2"></i>{{'MAS Sign-Off Reminders' | translate}} ({{reminderData?.length}})</span>
	</p-header>

		<p-table #table [value]="reminderData" [paginator]="false" [lazy]="false" [responsive]="true">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-start flex-wrap" style="row-gap: 10px">
					<button type="button" (click)="exportPdf()" class="btn btn-primary mr-1">
						<i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}
					</button>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr style="border: 1px solid #e4e4e4;">
					<ng-container *ngFor="let col of colsReminder" [ngSwitch]="col.field">
						<th *ngSwitchDefault [pSortableColumn]="col.field">
							<span>
								{{ col?.label | translate}}
								<span>
									<p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
										ariaLabelDesc="Activate to sort in descending order"
										ariaLabelAsc="Activate to sort in ascending order"
										[pSortableColumn]="col.field">
									</p-sortIcon>
								</span>
							</span>
						</th>
					</ng-container>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr [pSelectableRow]="rowData">
					<td>{{i+1}}</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Uploaded' | translate}}: </span>
							<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate}}">{{rowData.updatedDate}}</span>
							<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}</span>
							<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}">{{rowData.clientName}}</span>
							<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Days Until Due' | translate}}</span>
							<span *ngIf="rowData.dueIn" pTooltip="{{rowData.dueIn}}">{{rowData.dueIn}}</span>
							<span *ngIf="!rowData.dueIn" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>

	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closeShowSignOffReminder()">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-overlayPanel [appendTo]="'body'" #op3>
	<p-listbox [options]="downloadOptions" [(ngModel)]="selectedDownload" (onClick)="clickDownloadFile();">
		<ng-template let-col pTemplate="item">
			<div>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog [showHeader]="false" [(visible)]="showProgressBarImport" styleClass="w-50">
	<div class="card card-w-title p-0 border-0 m-0">
			<div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
					<div class="ui-g-12 ui-md-12">
							<p style="margin: 0 0 0 50%;">{{progressBarImportValue}}%</p>
							<p-progressBar [value]="progressBarImportValue" [mode]="progressBarImportValue <= 0 ? 'indeterminate' : 'determinate'"></p-progressBar>
					</div>
					<div class="ui-g-12 ui-md-12" *ngIf="progressBarImportValue > 0">
							<span style="font-weight: bold;">{{estimateTime}}</span>
					</div>
					<div class="ui-g-12 ui-md-12 text-center pb-3">
							<button pButton class="w-auto" label="Close" (click)="showProgressBarImport = false"></button>
					</div>
			</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="showProcessImportComplete" styleClass="w-50">
	<div class="card card-w-title p-0 border-0 m-0">
			<p class="tabCardHead">
					<span>{{'ProcessCompleted' | translate}}</span>
			</p>
			<div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
					<div class="ui-g-12 ui-md-12">
							<p-card>
									<p-header class="text-center font-weight-bold">
											{{'result' | translate}}
									</p-header>
									<div>
											<span class="font-weight-bold">{{'Total Record Added' | translate}}: </span>
											<span>{{totalRecordAdded}}</span>
									</div>
									<div>
											<span class="font-weight-bold">{{'Duplicate Record' | translate}}: </span>
											<span>{{duplicateRecord}}</span>
									</div>
									<div>
											<span class="font-weight-bold">{{'Duration' | translate}}: </span>
											<span>{{durationImport}}</span>
									</div>
							</p-card>
					</div>
					<div class="ui-g-12 ui-md-12 text-center pb-3">
							<button pButton class="w-auto" label="Ok" (click)="showProcessImportComplete = false"></button>
					</div>
			</div>
	</div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showSendEmail" class="modal_in"
		  [modal]='true'>
	<div class="ui-g p-2">
		{{'Are you sure you want to send email to client?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12 p-1" align="center">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="sendEmail()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="showSendEmail = false"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>
