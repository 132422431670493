import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../shared/shared.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PayrollSettingComponent } from "./component/payroll-setting/payroll-setting.component";
import { PayrollLayoutComponent } from "./component/payroll-layout/payroll-layout.component";
import { PayrollSettingViewComponent } from "./component/payroll-setting-view/payroll-setting-view.component";
import { PayrollSettingFormComponent } from "./component/payroll-setting-form/payroll-setting-form.component";
import { PayrollSettingFormNewComponent } from "./component/payroll-setting-form-new/payroll-setting-form-new.component";
import { PayrollSettingHistoryComponent } from "./component/payroll-setting-history/payroll-setting-history.component";
// import { CurrencyMaskModule } from "ngx-currency-mask";
import { CURRENCY_MASK_CONFIG } from "ngx-currency-mask/src/currency-mask.config";
import { CustomCurrencyMaskConfig } from "../shared/data/config-common"
import { InputNumberModule } from "primeng";
import { PayrollSettingOtherHistoryComponent } from './component/payroll-setting-other-history/payroll-setting-other-history.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNgModule,
    WebcamModule,
    SharedModule,
    SignaturePadModule,
    InputNumberModule,
    NgbModule
  ],
  declarations: [
    PayrollSettingComponent,
    PayrollLayoutComponent,
    PayrollSettingFormComponent,
    PayrollSettingViewComponent,
    PayrollSettingHistoryComponent,
    PayrollSettingFormNewComponent,
    PayrollSettingOtherHistoryComponent
  ],
  providers: [{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }]
})
export class PayrollSettingModule { }
