import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from 'app/shared/model/constants';
import { ZoneModel } from '../model/zone-model';

@Injectable({ providedIn: 'root' })
export class PolygonService {
  private static URL = `${environment.v2_server_ip}/${Constants.ROUTES.ZONE}`;

  constructor(private http: HttpClient) {}

  public getZipcodeCounty(lng: number, lat: string): Observable<any> {
    return this.http.get(
      PolygonService.URL + '/polygon/zipcode-county' + `?lng=${lng}&lat=${lat}`
    );
  }

  getPolygon(polygonId: any) {
    return this.http.post(
      PolygonService.URL + '/polygon' + `/${polygonId}`,
      ''
    );
  }

  getPolygonByIds(polygonIds: any): Observable<any> {
    return this.http.post(PolygonService.URL + '/polygon' + `/ids`, polygonIds);
  }

  public createZoneWithPolygonId(
    companyId: number,
    title: string,
    zoneType: string,
    polygonId: number
  ): Observable<ZoneModel> {
    return this.http.post<ZoneModel>(PolygonService.URL, {
      companyId,
      title,
      zoneType,
      polygonId,
    });
  }
}
