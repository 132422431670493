import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import * as _ from 'lodash';
import { InputMask } from 'primeng/inputmask';

import { dummyValidator, inputValidationMessage, setDummyError } from '../../model/helper';

@Component({
  selector: 'opr-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputTextComponent,
    multi: true,
  }],
})
export class InputTextComponent implements OnInit, DoCheck, AfterViewInit, ControlValueAccessor {
  @Output()
  eventEmitter = new EventEmitter();

  @Output()
  enterPress = new EventEmitter();

  @Input()
  placeholder = '';

  @Input()
  required = false;

  @Input()
  readonly = false;
  /**
   * @type {string}
   * If type == 'address', use google map api autocomplete;
   * If type == 'phone', use US phone format;
   */
  @Input()
  type = 'text';

  @Input()
  min = -9999;

  @Input()
  maxValue;

  @Input()
  minValue;

  @Input()
  step = 1;

  @Input()
  inputLimit = 0;

  @Input()
  showLabel = true;

  @Input()
  appTabindex = 0;

  @Input()
  decimal = 9999;

  @Input()
  precision = 9999;

  @Input()
  maxlength;

  @ViewChild('inputElement')
  inputElement: ElementRef;

  @ViewChild(InputMask)
  InputMask: InputMask;

  onChange;
  onTouch;

  inputControl: any = new UntypedFormControl(null);
  parentControl: AbstractControl;

  validationMessage = '';
  @Output()
  onAddressSelected = new EventEmitter<any>();
  @Output()
  onAddressChange = new EventEmitter<any>();

  @Input()
  inputId = "";

  input;

  @Input()
  noSpecialAllowed;

  @Input()
  disabled:boolean = false;

  @Input()
  showPasswordIcon: boolean = false;
  showPassword = false;

  @Input()
  showClear = true;

  @Input()
  labelClass;

  constructor(private injector: Injector) {
  }

  ngOnInit() {
    this.placeholder = this.required ? `${this.placeholder}` : this.placeholder;
    this.input = this.inputControl.value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.parentControl = this.injector.get(NgControl).control;
      this.inputControl.setValidators(dummyValidator(this.parentControl));
      // const validator = this.parentControl.validator && this.parentControl.validator(this.parentControl);
      // const isRequired = validator && validator.required;
      // if (isRequired)
      //     this.placeholder = this.placeholder + ' *';
    }, 0);

    if (this.type === 'address' && !this.readonly) {
      // this.mapsAPILoader.load().then(() => {
      //   const autocomplete = new google.maps.places.Autocomplete(this.inputElement.nativeElement);
      //   google.maps.event.addListener(autocomplete, 'place_changed', () => {
      //     this.onAddressChange.emit(this.inputElement.nativeElement.value);
      //     // const place = autocomplete.getPlace();
      //     this.inputControl.setValue(this.inputElement.nativeElement.value); // place.formatted_address
      //     if (this.onChange) {
      //       this.input = this.inputControl.value;
      //       this.onChange(this.inputControl.value);
      //       this.onAddressSelected.emit(autocomplete.getPlace().address_components);
      //     }
      //   });
      // });
    }
  }

  writeValue(value) {
    this.inputControl.setValue(value);
  }

  reset() {
    this.inputControl.reset();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngDoCheck() {
    this.validationMessage = '';
    if (this.parentControl) {
      if (!this.parentControl.invalid) {
        this.inputControl.setErrors(null);
      } else {
        setDummyError(this.inputControl);
      }
      this.validationMessage = inputValidationMessage(this.parentControl);
    }
  }

  onInputChange() {
    if (this.type === 'number') {
      if (this.inputLimit > 0) {
        let val = this.inputControl.value ? String(this.inputControl.value) : '';
        val = val.slice(0, this.inputLimit);
        if (Number(val) != Number(this.inputControl.value)) {
          this.inputControl.setValue(Number(val));
        }
      }
      if (this.min >= 0 && Number(this.inputControl.value) < 0) {
        // Prevent negative number
        this.inputControl.setValue(Number(this.inputControl.value) * -1);
      }
      // Correct decimal precision
      const value = String(this.inputControl.value);
      if (value && value.indexOf(".") > -1) {
        const precision = value.split(".")[0] || "";
        const scale = value.split(".")[1] || "";
        if (scale.length > this.decimal) {
          this.inputControl.setValue(Number(`${precision}.${scale.substring(0, this.decimal)}`));
        } else if (precision.length > this.precision) {
          this.inputControl.setValue(Number(`${precision.substring(0, this.precision)}.${scale}`));
        }
      } else {
        if (value.length > this.precision) {
          this.inputControl.setValue(Number(value.substring(0, this.precision)));
        }
      }
      // Check min & max value
      if (this.maxValue && (Number(this.inputControl.value) || 0) > this.maxValue) {
        this.inputControl.setValue(this.maxValue);
      } else if (this.minValue && (Number(this.inputControl.value) || 0) < this.minValue) {
        this.inputControl.setValue(this.minValue);
      }
      if (this.min && this.min > 0 && !this.inputControl.value) {
        this.inputControl.setValue(this.min);
      }
    }

    if (this.onChange) {
      if (this.type === 'phone' && _.get(this.inputControl, 'value')) {
        this.onChange(this.inputControl.value.replace(/\D+/g, ''));
      } else if (this.type === 'number') {
        this.onChange(Number(this.inputControl.value));
      } else {
        this.onChange(this.inputControl.value);
      }
    }
    if (this.type === 'address') {
      this.input = this.inputControl.value;
    }
  }

  // In case of {updateOn:'blur'}
  onBlur() {
    this.eventEmitter.emit({blur: true, value: this.inputControl ? this.inputControl.value : null});
    // if (this.type === 'number') {
    //     this.inputControl.setValue(Number(this.inputControl.value));
    // }
    //
    // if (this.onTouch) {
    //     if (this.type === 'phone' && _.get(this.inputControl, 'value')) {
    //         this.onTouch(this.inputControl.value.replace(/\D+/g, ''));
    //     } else {
    //         this.onTouch(this.inputControl.value);
    //     }
    // }
  }

  clearInput() {
    this.input = '';
    this.inputControl.reset();
    this.onClickClose();
  }

  onClickClose() {
    this.inputControl.setValue(null, {emitEvent: false});
    this.onInputChange();
  }

  checkValid(e) {
    if (this.required) {
      return e.invalid;
    } else {
      if (!e.value) {
        return false;
      } else {
        return e.invalid;
      }
    }
  }

  onEnter() {
    this.enterPress.emit(true);
  }

  noSpecial(value) {
    if (this.noSpecialAllowed) {
      const pattern = /^[a-zA-Z0-9 ]*$/;
      return pattern.test(value);
    }
    return true;
  }

  onPaste(event) {
    if (this.noSpecialAllowed) {
      const clipboardData = event.clipboardData || window["clipboardData"];
      if (clipboardData && !this.noSpecial(clipboardData)) {
        event.preventDefault();
      }
    }
    return true;
  }

  onShowPasswordChange() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  phoneFocus() {
    setTimeout(() => {
      if (!this.inputControl.value) {
        this.InputMask.inputViewChild.nativeElement.setSelectionRange(1,1);
      }
    }, 100)
  }
}
