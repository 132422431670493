import { AbstractService } from "../../shared/service/abstract.service";
import { BillingNote } from "../model/billingnote.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../../shared/model/constants";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BillingNotesService extends AbstractService<BillingNote>{
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.BILLINGNOTE, '');
    }

    getAllBillingNotes(id: number): Observable<any[]> {
        return this._http.get<any[]>(`${this.baseUrlV2 + Constants.URI.BILLING.BILLINGNOTESLIST}/${id}`).pipe(map(resp => resp));
    }

}