import { SORT } from '../enums';
import {AbstractSearch} from "./abstract.search";

export interface CompanySearch extends AbstractSearch{
    
}



export class CompanyHolidaySearchCriteria implements AbstractSearch{
	page?:number
    size?:number;
    key?:string;
    offset?:number;
    sortField?:string;
    sortOrder?:string;
    id:number;
	fromDate:number;
	toDate:number;
	holidayDate:number;
    companyId:number;
    status:string;
}