import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AvatarService {

    userAvatar = new BehaviorSubject<any>(null);

    constructor() { }


    changeAvatar(url: string) {
        this.userAvatar.next(url);
    }

    get changeAvatarObservable(): Observable<any> {
        return this.userAvatar.asObservable();
    }
}