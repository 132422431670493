<p-tabMenu [model]="mailTabMenuItems" [activeItem]="activeItem">
	<ng-template pTemplate="item" let-item let-i="index" >
		<div *ngIf="item.visible ? item.visible : true">
			{{item.label | translate}}
		</div>
	</ng-template>
</p-tabMenu>
<div class="ui-g">
	<div class="ui-g-12 p-0">
		<div class="card add-shadow p-4">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>