import {Component, OnInit} from '@angular/core';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ConfirmationService, Message, MessageService} from 'primeng/api';
import * as moment from 'moment';
import * as _ from 'lodash';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {CompanyService} from '../../../company/service/company.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {TransactionDetailsService} from '../../services/transaction-details.service';
import {WindowRefService} from '../../../shared/service/window-ref/window-ref.service';
import {PaymentInvoiceService} from '../../../payment/service/payment-invoice.service';
import {BillingInvoiceService} from '../../../billing/service/billinginvoice.service';
import {FileUtility} from '../../../shared/utility/file.utility';
import {Company} from '../../../company/model/company.model';
import {TranslatePipe} from '@ngx-translate/core';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';



@Component({
  selector: 'app-revenue-payment',
  templateUrl: './revenue-payment.component.html',
  styleUrls: ['./revenue-payment.component.scss'],
  providers: [WindowRefService, TransactionDetailsService, ConfirmationService,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}]
})
export class RevenuePaymentComponent implements OnInit {

  companies: any = [];
  msgs: Message[] = [];
  colsInvoice = [];
  colsPayment = [];
  invoices = [];
  payments = [];
  totalRecords: Number;
  totalRecordsInvoice: Number;
  selectedDateRange: any;
  loading = false;
  loadingInvoice = false;
  page: number;
  size: number;
  textSearch: string;
  requestFilter: any;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedSortOption = '';
  selectedSortOptionPayment = '';
  selectedField = '';
  selectedFieldPayment = '';
  filteredTransaction = [];
  isPlatformAdmin: Boolean = false;
  allCompanyList: Company[] = [];
  selectedCompany: any;
  fromDate: Date;
  toDate: Date;
  sortF: any = '';
  sortFPayment: any = '';
  timeSpent = new Date();
  totalInvoice = 0;
  totalPayment = 0;
  deleteTime: Date;
  transactionType = [
      {label: 'All', value: 'All'},
    {label: 'Debit', value: 'Debit'},
    {label: 'Credit', value: 'Credit'}
  ];
  selectedTransactionType;
  arListExport: any = {};
  arTotalInvoice: number = 0;
  paymentsListExport: any = {};
  totalPaymentsExport: number = 0;
  fileTypes = [
    { name: 'AR & Payments', value: 1 },
    { name: 'AR Only', value: 2 },
    { name: 'Payment Only', value: 3 }
  ];
  sortInvoice = false;
  constructor(
    private messageService: MessageService,
    private companyService: CompanyService,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private transactionDetailsService: TransactionDetailsService,
    private contentService: PaymentInvoiceService,
    private billingInvoiceService: BillingInvoiceService,
    private exportMonitorService: ExportMonitorService,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    private decimalPipe: DecimalPipe
  ) {
  }

  ngOnInit() {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin) {
      this.loadAllCompanies();
    }
    this.colsInvoice = [
      {field: 'index', label: '#', sortOptions: '', sort: 'number'},
      {field: 'invoiceDate', label: 'Invoiced', sortOptions: '', sort: 'number'},
      {field: 'fee', label: 'Amount', sortOptions: '', sort: 'text'},
    ];

    this.colsPayment = [
		{field: 'index', label: '#', sortOptions: '', sort: 'number'},
		{field: 'date', label: 'Payment', sortOptions: '', sort: 'number'},
		{field: 'netCashInOut', label: 'Amount', sortOptions: '', sort: 'text'},
    ];
    this.textSearch = '';

    this.requestFilter = {
      fromDate: '',
      toDate: '',
      page: 0,
      size: 10,
      searchText: '',
      sortOrder: '',
      sortField: ''
    };

    this.onLoadTotalBalance();
    this.onLoadTotalPayment();
    this.loadLazy();
    this.onLazyLoadInvoice();
  }

  reset() {
    this.selectedTransactionType = null;
    this.selectedCompany = null;
    this.fromDate = null;
    this.toDate = null;
    this.requestFilter = {
      fromDate: '',
      toDate: '',
      page: 0,
      size: 10,
      sortOrder: '',
      sortField: ''
    };

    this.searchList();
  }

  buildLoadBalanceReq(event?: any) {
      console.log(event);
    const options: any = {};
    this.size = event?.rows || 10;
    this.page = (event?.first || 0) / this.size;

    options.page = this.page;
    options.size = this.size;

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = moment(this.fromDate).utc(true).startOf('day').toDate();
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = moment(this.toDate).utc(true).endOf('day').toDate();
    }

    options.sortField = 'id';
    options.sortOrder = 'DESC';

    if (event && event.sortField) {
      const sortOrder = <any>event['sortOrder'];
      options.sortField = event['sortField'];
      options.sortOrder = sortOrder;// === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
    }
    options.companyId = this.selectedCompany ? this.selectedCompany.key : this.authService.getCurrentCompanyId();
    return options;
  }

  loadLazy(event?: any) {
    this.loading = true;
    const options = this.buildLoadBalanceReq(event);
    this.transactionDetailsService.loadLists(options).subscribe(res => {
      this.loading = false;
      this.payments = res.data.content;
      this.totalRecords = res.data.totalElements;
      this.payments.forEach(ele => {
        ele.date = new Date(ele.date);
        ele.netCashInOut = ele.netCashInOut ? ele.netCashInOut : 0;
      });
      if (this.selectedTransactionType === 'Debit') {
        this.payments = this.payments.filter(item => item.netCashInOut < 0);
      }
      if (this.selectedTransactionType === 'Credit') {
        this.payments = this.payments.filter(item => item.netCashInOut > 0);
      }
    });
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  sortingClick(selectedSortOption) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.invoices
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }

          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }

  sort(fieldName: string, order: number) {
    this.invoices.sort((row1, row2) => {
      const val1 = fieldName === 'transactionType'
        ? row1[fieldName].transactionType : row1[fieldName];
      const val2 = fieldName === 'transactionType'
        ? row2[fieldName].transactionType : row2[fieldName];
      if (val1 === val2) {
        return 0;
      }
      let result = -1;
      if (val1 > val2) {
        result = 1;
      }
      if (order < 0) {
        result = -result;
      }
      return result;
    });
  }

  setSortOption(field, selectedSortOption?) {
    this.colsInvoice.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  setSortOptionPayment(field, selectedSortOption?) {
    this.colsPayment.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOptionPayment = selectedSortOption;
      }
    });
  }

  sortingClickPayment(selectedSortOption) {
    this.sortF = this.selectedFieldPayment;
    // const options = {
    //   field: this.selectedFieldPayment,
    //   order: selectedSortOption === 'ASC' ? 1 : -1,
    //   mode: 'single',
    //   data: this.payments
    // };
    const options = {
      sortOrder: selectedSortOption,
      sortField: this.selectedField
    }
    if (this.sortInvoice) {
      this.onLazyLoadInvoice(options);
    } else {
        this.loadLazy(options);
    }
    //this.customSortPayment(options);
  }

  customSortPayment(event) {
    if (this.sortFPayment.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }

          return (event.order * result);
        });
      }
      this.sortFPayment = '';
    }
  }

  sortPayment(fieldName: string, order: number) {
    this.payments.sort((row1, row2) => {
      const val1 = fieldName === row1[fieldName];
      const val2 = fieldName === row2[fieldName];
      if (val1 === val2) {
        return 0;
      }
      let result = -1;
      if (val1 > val2) {
        result = 1;
      }
      if (order < 0) {
        result = -result;
      }
      return result;
    });
  }

  loadAllCompanies() {
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = resObj.data;
        this.allCompanyList = _.sortBy(resObj.data, 'value');
        this.selectedCompany = (this.allCompanyList || []).find(c => c.key === this.authService.getCurrentCompanyId());
      }
    });
  }

  searchList() {
    this.onLoadTotalBalance();
    this.onLoadTotalPayment();
    this.loadLazy();
    this.onLazyLoadInvoice();
  }

  onLoadTotalPayment() {
    const options = this.buildLoadInvoiceReq();
    this.totalInvoice = 0;
    this.contentService.getTotalPayment(options).subscribe(res => {
      const totalRsp: any = res;
      this.totalInvoice = (totalRsp?.data?.totalPaidFee || 0).toFixed(2);
    });
  }

  onLoadTotalBalance() {
    const options = this.buildLoadBalanceReq();
    const optionsClone = Object.assign({}, options);
    optionsClone.searchText = this.selectedTransactionType;
    this.totalPayment = 0;
    this.transactionDetailsService.getTotalBalance(optionsClone).subscribe(resClone => {
      const totalRsp: any = resClone;
      this.totalPayment = (totalRsp?.data?.totalCashInOut || 0).toFixed(2);
    });
  }

  formatDate(date: any) {
    if (date) {
      return this.datePipe.transform(date, 'MM/dd/yyyy hh:mm a')
    }
    return null;
  }


  async exportPdfAR(type) {
    await this.exportARList();
    await this.paymentsList();

    let arList = [];
    if (this.arListExport && this.arListExport.length) {
      arList = this.arListExport.map((value, index) => {
        return {
          id: index + 1,
          invoiceDate: this.formatDate(value.invoiceDate),
          amount: this.decimalPipe.transform(value.paidFee, '1.2-2'),
        };
      });
    }
    let payments = [];
    if (this.paymentsListExport && this.paymentsListExport.length) {
      payments = this.paymentsListExport.map(value => {
        return {
          invoiceDate: this.formatDate(value.date),
          amount: this.decimalPipe.transform(value.netCashInOut, '1.2-2'),
        };
      });
    }
    const data: any = {
      arList,
      payments,
      type,
      totalPayment: this.decimalPipe.transform(this.totalPaymentsExport, '1.2-2'),
      totalInvoice: this.decimalPipe.transform(this.arTotalInvoice, '1.2-2')
    };
    if (!(this.authService.isSuper() || this.authService.isSubSuper())) {
      data.companyId = this.authService.getCurrentCompanyId();
    }
    data.username = this.authService.getCurrentUsername();
    data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
    if (type === 2) {
      this.billingInvoiceService.exportAr(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Revenue Payment',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Problem exporting', detail: 'Problem exporting ar report pdf list'
          });
        }
      });
    } else if (type === 1) {
      this.billingInvoiceService.exportArPayment(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting a/r payment report pdf list')
          });
        }
      });
    } else {
      this.billingInvoiceService.exportPayment(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting payment report pdf list')
          });
        }
      });
    }
  }

  buildLoadInvoiceReq(event?: any) {
    console.log(event);
    const options: any = {};
    this.size = event?.rows || 10;
    this.page = (event?.first || 0) / this.size;

    options.page = this.page;
    options.size = this.size;

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = moment(this.fromDate).utc(true).startOf('day').toDate();
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = moment(this.toDate).utc(true).endOf('day').toDate();
    }

    options.sortField = 'id';
    options.sortOrder = 'DESC';

    if (event && event.sortField) {
      const sortOrder = <any>event['sortOrder'];
      options.sortField = event['sortField'];
      options.sortOrder = sortOrder;// === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
    }
    options.companyId = this.selectedCompany ? this.selectedCompany.key : this.authService.getCurrentCompanyId();
    return options;
  }

  onLazyLoadInvoice(event?: any) {
    this.loadingInvoice = true;
    const options = this.buildLoadInvoiceReq(event);
    this.contentService.filter(options).toPromise().then(res => {
      const resObj: any = res;
      let count = 0;
      if (resObj.status === 'SUCCESS') {
        this.loadingInvoice = false;
        this.invoices = resObj.data.content;
        this.totalRecordsInvoice = resObj.data.totalElements;
        this.invoices.forEach(ele => {
          ele.paidFee = ele.paidFee ? ele.paidFee : (ele.totalFee ? ele.totalFee : 0);
        });
        if (this.selectedTransactionType === 'Debit') {
          this.invoices = this.invoices.filter(item => item.paidFee < 0);
        }
        if (this.selectedTransactionType === 'Credit') {
          this.invoices = this.invoices.filter(item => item.paidFee > 0);
        }
        this.invoices.forEach(ele => {
          const invoiceDate = new Date(ele.invoiceDate);
          invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
          ele.date = invoiceDate;
          // ele.paidFee = ele.paidFee ? ele.paidFee : (ele.fee ? ele.fee : 0);
          ele.index = ++count;
        });
      }
    });
  }

  async exportARList() {
    const options: any = {};

    options.page = 0;
    options.size = 9999;

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = moment(this.fromDate).utc(true).startOf('day').toDate();
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = moment(this.toDate).utc(true).endOf('day').toDate();
    }

    options.sortField = 'id';
    options.sortOrder = 'DESC';

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }

    this.arTotalInvoice = 0;
    await this.contentService.filter(options).toPromise().then(res => {
      const resObj: any = res;
      let count = 0;
      if (resObj.status === 'SUCCESS') {
        this.arListExport = resObj.data.content;
        this.arListExport.forEach(ele => {
          ele.paidFee = ele.paidFee ? ele.paidFee : (ele.totalFee ? ele.totalFee : 0);
        });
        if (this.selectedTransactionType === 'Debit') {
          this.arListExport = this.arListExport.filter(item => item.paidFee < 0);
        }
        if (this.selectedTransactionType === 'Credit') {
          this.arListExport = this.arListExport.filter(item => item.paidFee > 0);
        }
        this.arListExport.forEach(ele => {
          const invoiceDate = new Date(ele.invoiceDate);
          invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
          ele.date = invoiceDate;
          ele.index = ++count;
          this.arTotalInvoice += (ele.paidFee ? ele.paidFee : 0);
        });
      }
    });
  }

  async paymentsList() {
    const options: any = {};

    options.page = 0;
    options.size = 9999;

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = moment(this.fromDate).utc(true).startOf('day').toDate();
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = moment(this.toDate).utc(true).endOf('day').toDate();
    }

    options.sortField = 'id';
    options.sortOrder = 'DESC';

    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompany();
    }
    if (this.isPlatformAdmin && this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }

    this.totalPaymentsExport = 0;
    await this.transactionDetailsService.loadLists(options).toPromise().then(res => {
      this.paymentsListExport = res.data.content;
      this.totalRecords = res.data.totalElements;
      this.paymentsListExport.forEach(ele => {
        ele.date = new Date(ele.date);
        ele.netCashInOut = ele.netCashInOut ? ele.netCashInOut : 0;
      });
      if (this.selectedTransactionType === 'Debit') {
        this.paymentsListExport = this.paymentsListExport.filter(item => item.netCashInOut < 0);
      }
      if (this.selectedTransactionType === 'Credit') {
        this.paymentsListExport = this.paymentsListExport.filter(item => item.netCashInOut > 0);
      }
      this.paymentsListExport.forEach(ele => {
        this.totalPaymentsExport += ele.netCashInOut;
      });
    });
  }
}
