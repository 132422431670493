import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GeneralInvoiceTypeModel} from '../model/general-invoice-type.model';
import {AbstractService} from '../../shared/service/abstract.service';
import {Constants} from '../../shared/model/constants';
import {Page} from '../../shared/model/page.model';


@Injectable({ providedIn: 'root' })
export class GeneralInvoiceTypeService extends AbstractService<GeneralInvoiceTypeModel> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.INVOICE_TYPE, '');
        // this.baseUrlV2 = 'http://localhost:8007/api/v2/operation/invoice-type/';
    }

    searchInvoiceType(data: any): Observable<Page<GeneralInvoiceTypeModel>> {
        return this._http.post<Page<GeneralInvoiceTypeModel>>(`${this.baseUrlV2}search`, data).pipe(map(resp => resp));
    }

    createInvoiceType(data:any) : Observable<GeneralInvoiceTypeModel> {
        return this._http.post<GeneralInvoiceTypeModel>(`${this.baseUrlV2}`, data).pipe(map(res => res));
    }

    updateInvoiceType(data:any): Observable<GeneralInvoiceTypeModel> {
        return this._http.put<GeneralInvoiceTypeModel>(`${this.baseUrlV2}`, data).pipe(map(res => res));
    }

    deleteInvoiceType(id:number){
        return this._http.delete(`${this.baseUrlV2}${id}`).pipe(map(res => res));
    }

    getDropdown(options?:any) {
        let params = this.createParams(options);
        return this._http.get(`${this.baseUrlV2}dropdown`, {params :params}).pipe(map(res => res));
    }
}
