import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class TimerRecordsService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CRM.TIMER_RECORDS, '');
  }

  search(options: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8010/api/v2/crm/timer-records/search`, options).pipe(map(res => res));
    return this._http.post<Page<any>>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  updateData(options: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8010/api/v2/crm/timer-records/update-data`, options).pipe(map(res => res));
    return this._http.post<Page<any>>(`${this.baseUrlV2}update-data`, options).pipe(map(res => res));
  }

  startTimer(options: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8010/api/v2/crm/timer-records/start-timer`, options).pipe(map(res => res));
    return this._http.post<Page<any>>(`${this.baseUrlV2}start-timer`, options).pipe(map(res => res));
  }
  
  endTimer(options: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8010/api/v2/crm/timer-records/end-timer`, options).pipe(map(res => res));
    return this._http.post<Page<any>>(`${this.baseUrlV2}end-timer`, options).pipe(map(res => res));
  }

  updateDataItem(options: any): Observable<Page<any>> {
    // return this._http.post<Page<any>>(`http://localhost:8010/api/v2/crm/timer-records/item/update-data`, options).pipe(map(res => res));
    return this._http.post<Page<any>>(`${this.baseUrlV2}item/update-data`, options).pipe(map(res => res));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const wscols = [{ wch: 12 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }];

      worksheet['!cols'] = wscols;
      const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

      const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
      if (fileExtension) {
          this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
      } else {
          this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
      }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
        downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + fileExtension);
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }
}
