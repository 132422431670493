<div *ngIf="!isShowPaymentMethod">
    <div class="card add-shadow p-4">
		<div class="d-flex flex-wrap align-items-center justify-content-between py-4">
			<h1 class="m-0 ml-2">{{'Payment Method' | translate}}</h1>
			<span>
				<button class="btn btn-primary mx-1" (click)="openRegisterDialog()" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
				<button *ngIf="backKeyFlag" class="btn btn-primary mx-1" type="button" label="{{'Register Acoount' | translate}}" (click)="goBackURL()" disabled ><i class="fa-solid fa-plus"></i></button>
			</span>
		</div>
		<p-panel [toggleable]="true" id="table-ppanel">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-wallet text-primary mr-2"></i>{{'Select Payment Method' | translate}}</span>
			</p-header>
			<div class="d-flex flex-column p-4" style="column-gap: 50px">
				<p-radioButton *ngIf="!noEcheck" name="visaGroup" value="E_CHECK" label="{{'eCheck' | translate}}" [(ngModel)]="paymentMethod"></p-radioButton>
				<p-radioButton name="visaGroup" value="CREDIT" label="{{'Credit/Debit' | translate}}" [(ngModel)]="paymentMethod"></p-radioButton>
				<p-radioButton name="visaGroup" value="TRANSFER" label="{{'Bank Account' | translate}}" [(ngModel)]="paymentMethod" (ngModelChange)="changePaymentMethod()" (click)="transferClicked()"></p-radioButton>
			</div>
		</p-panel>
		<br>
		<br>

		<p-panel *ngIf="paymentMethod === 'E_CHECK'" [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Payment Method Details' | translate}}</span>
			</p-header>
			<div *ngIf="paymentMethod === 'E_CHECK'" class="alert alert-warning alert-dismissible align-items-center fade show" role="alert">
				<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{ 'This feature is under development and not available' | translate }}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div class="d-flex flex-column flex-wrap align-items-center justify-content-between text-center w-100 py-4">
				<i class="fa-solid fa-triangle-exclamation text-muted m-auto fa-3x"></i>
				<span class="text-muted m-auto">{{'Under Development' | translate}}</span>
			</div>
		</p-panel>
		
		<p-panel *ngIf="paymentMethod === 'CREDIT' || (paymentMethod !== 'E_CHECK' && paymentMethod !== 'TRANSFER' && paymentMethod !== 'CREDIT')" [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-credit-card text-primary mr-2"></i>{{'Credit/Debit Card' | translate}}</span>
			</p-header>
			<div class="ui-g pb-3">
				<div class="ui-g-12">
					<h2 class="m-0 ml-2 py-3">{{'Choose Card Type' | translate}}</h2>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<p-radioButton name="paymentGroup" value="VISA" [(ngModel)]="paymentCard" #visa></p-radioButton>
							</button>
						</div>
						<div class="form-control d-flex align-items-center"><img src="/assets/payment/visa-straight-128px.png" class="mr-2" style="width:30px;height:auto"><span class="one-liner">{{'Visa Card' | translate}}</span></div>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<p-radioButton name="paymentGroup" value="MASTER_CARD" [(ngModel)]="paymentCard" #master_card></p-radioButton>
							</button>
						</div>
						<div class="form-control d-flex align-items-center"><img src="/assets/payment/mastercard-straight-128px.png" class="mr-2" style="width:30px;height:auto"><span class="one-liner">{{'MasterCard' | translate}}</span></div>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<p-radioButton name="paymentGroup" value="AMERICAN_EXPRESS" [(ngModel)]="paymentCard" #american_express></p-radioButton>
							</button>
						</div>
						<div class="form-control d-flex align-items-center"><img src="/assets/payment/american-express-straight-128px.png" class="mr-2" style="width:30px;height:auto"><span class="one-liner">{{'American Express' | translate}}</span></div>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<p-radioButton name="paymentGroup" value="DISCOVER" [(ngModel)]="paymentCard" #discover></p-radioButton>
							</button>
						</div>
						<div class="form-control d-flex align-items-center"><img src="/assets/payment/discovery-straight-128px.png" class="mr-2" style="width:30px;height:auto"><span class="one-liner">{{'Discover Card' | translate}}</span></div>
					</div>
				</div>
			</div>
		</p-panel>

		<p-panel *ngIf="paymentMethod === 'TRANSFER'" [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Bank Account Details' | translate}}</span>
			</p-header>
			<form [formGroup]="registerAccountForm" #registerAccountDataForm [name]="registerAccountDataForm" class="ui-g pb-3">
				<div class="ui-g pb-3">
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Country/Region' | translate}}:</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-earth-america"></i>
								</button>
							</div>
							<p-dropdown [options]="listOfPhoneCode"
										[(ngModel)]="account.countryCode"
										styleClass="w-100"
										[autoDisplayFirst]="false"
										[showClear]="true"
										formControlName="countryCode"
										>
										<ng-template pTemplate="item" let-item>
											<span class="text-truncate">{{item.label | translate}}</span>
										</ng-template>
										<ng-template let-item pTemplate="selectedItem">
											<span class="one-liner">{{item.label | translate}}</span>
										</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Account Holder' | translate}}<span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<input pInputText maxlength="45" placeholder="{{'Full Name' | translate}}" type="text" [(ngModel)]="account.beneficiaryName" class="w-100" formControlName="beneficiaryName"/>
						</div>
						<span class="text-danger" *ngIf="registerAccountForm.controls['beneficiaryName'].invalid && (registerAccountForm.controls['beneficiaryName'].dirty || registerAccountForm.controls['beneficiaryName'].touched)" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
			
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Local Currency' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-money-bill-wave"></i>
								</button>
							</div>
							<input pInputText placeholder="USD" disabled type="text" [(ngModel)]="account.bankCurrency" class="w-100" formControlName="bankCurrency"/>
						</div>
						<span *ngIf="registerAccountForm.controls['bankCurrency'].invalid && (registerAccountForm.controls['bankCurrency'].dirty || registerAccountForm.controls['bankCurrency'].touched)"  class="ui-message ui-messages-error" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
	
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Account Type' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-piggy-bank"></i>
								</button>
							</div>
							<p-dropdown [options]="accountTypes"
								[(ngModel)]="account.accountType"
								styleClass="w-100"
								[autoDisplayFirst]="false"
								[showClear]="true"
								formControlName="accountType" >
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
						<span *ngIf="registerAccountForm.controls['accountType'].invalid && (registerAccountForm.controls['accountType'].dirty || registerAccountForm.controls['accountType'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
	
		
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Routing Number' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i *ngIf="!showError && !showSuccess && !pending" class="fa-solid fa-hashtag"></i>
									<i *ngIf="showError" class="fa-solid fa-triangle-exclamation text-danger" pTooltip="{{'Invalid' | translate}}"></i>
									<i *ngIf="showSuccess" class="fa-solid fa-circle-check text-success" pTooltip="{{'Success' | translate}}"></i>
									<i *ngIf="pending" class="fa-solid fa-hour text-primary" pTooltip="{{'Wait...' | translate}}"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.swiftCode" formControlName="swiftCode" class="w-100" (keypress)="isNumber($event, 'rout')" (keyup)="enable($event)" id="input1"/>
							<div class="input-group-append">
								<button *ngIf="showSuccess" class="btn btn-white" id="button-addon2" type="button"  (click)="verifyBank()" [disabled]="isDisabled" style="cursor:pointer !important">
									<span><i class="fa-solid fa-check text-primary mr-2"></i>{{'Verify' | translate}}</span>
								</button>
								<button *ngIf="showError" class="btn btn-white" id="button-addon2" type="button" (click)="account.swiftCode = null" pTooltip="{{'Reset' | translate}}" tooltipPosition="left" kstyle="cursor:pointer !important">
									<i class="fa-solid fa-xmark text-danger"></i>
								</button>	
							</div>
						</div>
						<span *ngIf="registerAccountForm.controls['swiftCode'].invalid && (registerAccountForm.controls['swiftCode'].dirty || registerAccountForm.controls['swiftCode'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>

					<div class="ui-g-6 ui-sm-12">
						<label>{{'Bank' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-institution"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.bankName" formControlName="bankName" class="w-100" disabled/>
						</div>
					</div>
	
				
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Account' | translate}}#<span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-lock"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.bankAccount" formControlName="bankAccount" (keypress)="isNumber($event)" />
						</div>
						<span *ngIf="registerAccountForm.controls['bankAccount'].invalid && (registerAccountForm.controls['bankAccount'].dirty || registerAccountForm.controls['bankAccount'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div> 
					

					<div class="ui-g-6 ui-sm-12">
						<label>{{'Branch Address' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-signs-post"></i>
								</button>
							</div>
							<input pInputText type="text" id="address" autocomplete="off" [(ngModel)]="account.branchAddress" placeholder="" formControlName="address" class="w-100" />
						</div>
						<span *ngIf="registerAccountForm.controls['address'].invalid && (registerAccountForm.controls['address'].dirty || registerAccountForm.controls['address'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
			
					<div class="ui-g-6 ui-sm-12">
						<label>{{'City' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-city"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.city" formControlName="city" class="w-100" />
						</div>
						<span *ngIf="registerAccountForm.controls['city'].invalid && (registerAccountForm.controls['city'].dirty || registerAccountForm.controls['city'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>


					<div class="ui-g-6 ui-sm-12">
					   <label>{{'State' | translate}} <span class="required-indicator">*</span></label>
					   <div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-map-location-dot"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.state" class="w-100" formControlName="state" />
						</div>
						<span  *ngIf="registerAccountForm.controls['state'].invalid && (registerAccountForm.controls['state'].dirty || registerAccountForm.controls['state'].touched)"  class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
	
					<div class="ui-g-6 ui-sm-12">
						<label>{{'zipCode' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-location-dot"></i>
								</button>
							</div>
							<input pInputText type="text" [(ngModel)]="account.zipcode" formControlName="zipcode" class="w-100" (keypress)="isNumber($event)" />
						</div>
						<span *ngIf="registerAccountForm.controls['zipcode'].invalid && (registerAccountForm.controls['zipcode'].dirty || registerAccountForm.controls['zipcode'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
			
					<div class="ui-g-6 ui-sm-12">
						<label>{{'DOB' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-calendar-day"></i>
								</button>
							</div>
							<p-calendar [showButtonBar]="true"
								[monthNavigator]="true" [yearNavigator]="true"
								yearRange="1900:2030"
								todayButtonStyleClass="displayNone"
								clearButtonStyleClass="clearButton"
								class="w-100"
								[(ngModel)]="account.dob"
								formControlName="dob" 
								[inputStyle]="{'width':'100%'}">
							</p-calendar>
						</div>
						<span *ngIf="registerAccountForm.controls['dob'].invalid && (registerAccountForm.controls['dob'].dirty || registerAccountForm.controls['dob'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
		
					<div class="ui-g-6 ui-sm-12">
						<label>{{'SSN' | translate}} <span class="required-indicator">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user-shield"></i>
								</button>
							</div>
							<input pInputText type="password" [(ngModel)]="account.ssn" class="w-100" formControlName="ssn" minlength="9" maxlength="9" (keypress)="isNumber($event)"/>
						</div>
						<span *ngIf="(!registerAccountForm.controls['ssn'].valid || registerAccountForm.get('ssn').hasError('minlength') || registerAccountForm.get('ssn').hasError('maxlength')) && registerAccountForm.controls['ssn'].dirty " class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
					</div>
				</div>
			</form>
		</p-panel>
		<div *ngIf="paymentMethod === 'TRANSFER'" class="ui-g">
			<div class="ui-g-12 text-center pt-4">
				<button class="btn btn-primary mx-1" [disabled]="isClicked" (click)="registerAccount(registerAccountDataForm)" type="submit"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
				<button class="btn btn-danger mx-1" (click)="showRegisterAccountDialog = false;"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			</div>
		</div>
		<div class="ui-g" *ngIf="paymentMethod === 'CREDIT'">
			<div class="ui-g-12 text-center pt-4">
				<button class="btn btn-primary" (click)="showCardInfo()" [disabled]="disableNextButton()">{{'Next' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="isShowPaymentMethod">
	<app-amex-card *ngIf="isEmexCard" (displayChange)="reset($event)" [paymentCardType]="paymentCard"  [key]="generatedKey"></app-amex-card>
	<app-visa-card *ngIf="isVisaCard" [paymentCardType]="paymentCard" (displayChange)="reset($event)" [formatCard]="formatCard" [selectedInvoices]="selectedInvoices" [key]="generatedKey"></app-visa-card>
	<app-transfer-card *ngIf="isTransfer" (displayChange)="reset($event)" [paymentCardType]="paymentMethod"></app-transfer-card>
	<app-echeck-card *ngIf="isEcheck" (displayChange)="reset($event)" [paymentCardType]="paymentMethod"></app-echeck-card>
</div>
<p-dialog [modal]="true" [(visible)]="showRegisterAccountDialog" [style]="{'width':'90%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Add Bank Account' | translate}}</span>
	</p-header>
    <form [formGroup]="registerAccountForm" #registerAccountDataForm [name]="registerAccountDataForm" class="ui-g pb-3">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Country/Region' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-earth-america"></i>
					</button>
				</div>
				<p-dropdown [options]="listOfPhoneCode" [(ngModel)]="account.countryCode" styleClass="w-100" [autoDisplayFirst]="false" [showClear]="true" formControlName="countryCode">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Account Holder' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<input pInputText maxlength="45" type="text" [(ngModel)]="account.beneficiaryName" class="w-100" formControlName="beneficiaryName"/>
			</div>
			<span class="text-danger" *ngIf="registerAccountForm.controls['beneficiaryName'].invalid && (registerAccountForm.controls['beneficiaryName'].dirty || registerAccountForm.controls['beneficiaryName'].touched)" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Local Currency' | translate}} ({{'read only' | translate}})</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-money-bill-wave"></i>
					</button>
				</div>
				<input pInputText placeholder="USD" disabled type="text" [(ngModel)]="account.bankCurrency" class="w-100"/>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Account Type' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-piggy-bank"></i>
					</button>
				</div>
				<p-dropdown [options]="accountTypes" [(ngModel)]="account.accountType" styleClass="w-100" [autoDisplayFirst]="false" [showClear]="true" formControlName="accountType" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
			<span *ngIf="registerAccountForm.controls['accountType'].invalid && (registerAccountForm.controls['accountType'].dirty || registerAccountForm.controls['accountType'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Branch Address' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-signs-post"></i>
					</button>
				</div>
				<input pInputText type="text" id="address" autocomplete="off" [(ngModel)]="account.branchAddress" placeholder="" formControlName="address" class="w-100" />
			</div>
			<span  *ngIf="registerAccountForm.controls['address'].invalid && (registerAccountForm.controls['address'].dirty || registerAccountForm.controls['address'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Routing Number' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i *ngIf="!showError && !showSuccess && !pending" class="fa-solid fa-hashtag"></i>
						<i *ngIf="showError" class="fa-solid fa-xmark text-danger" pTooltip="{{'Not available' | translate}}"></i>
						<i *ngIf="showSuccess" class="fa-solid fa-circle-check text-success" pTooltip="{{'Success' | translate}}"></i>
						<i *ngIf="pending" class="fa-solid fa-hour text-primary" pTooltip="{{'Wait...' | translate}}"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.swiftCode" formControlName="swiftCode" class="w-100" (keypress)="isNumber($event, 'rout')" (keyup)="enable($event)" id="input1"/>
				<div class="input-group-append">
					<button *ngIf="showSuccess" class="btn btn-white" id="button-addon2" type="button"  (click)="verifyBank()" [disabled]="isDisabled" style="cursor:default !important">
						<span><i class="fa-solid fa-check text-primary mr-2"></i>{{'Verify' | translate}}</span>
					</button>
					<button *ngIf="showError" class="btn btn-white" id="button-addon2" type="button"  (click)="verifyBank()" [disabled]="isDisabled" pTooltip="{{'Reset' | translate}}" kstyle="cursor:default !important">
						<i class="fa-solid fa-check"></i>
					</button>	
				</div>
			</div>
			<span *ngIf="registerAccountForm.controls['swiftCode'].invalid && (registerAccountForm.controls['swiftCode'].dirty || registerAccountForm.controls['swiftCode'].touched)" class="text-danger"  style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'City' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-city"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.city" formControlName="city" class="w-100" />
			</div>
			<span *ngIf="registerAccountForm.controls['city'].invalid && (registerAccountForm.controls['city'].dirty || registerAccountForm.controls['city'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Bank' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-institution"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.bankName" formControlName="bankName" class="w-100" disabled/>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Account #' | translate}}<span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-lock"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.bankAccount" formControlName="bankAccount" (keypress)="isNumber($event)" />
			</div>
			<span *ngIf="registerAccountForm.controls['bankAccount'].invalid && (registerAccountForm.controls['bankAccount'].dirty || registerAccountForm.controls['bankAccount'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div> 
		<div class="ui-g-6 ui-sm-12">
			<label>{{'State' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-map-location-dot"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.state" class="w-100" formControlName="state" />
			</div>
			<span  *ngIf="registerAccountForm.controls['state'].invalid && (registerAccountForm.controls['state'].dirty || registerAccountForm.controls['state'].touched)"  class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'zipCode' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-location-dot"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.zipcode" formControlName="zipcode" class="w-100" (keypress)="isNumber($event)" />
			</div>
			<span *ngIf="registerAccountForm.controls['zipcode'].invalid && (registerAccountForm.controls['zipcode'].dirty || registerAccountForm.controls['zipcode'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'DOB' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<p-calendar [showButtonBar]="true"
					[monthNavigator]="true" [yearNavigator]="true"
					yearRange="1900:2030"
					todayButtonStyleClass="displayNone"
					clearButtonStyleClass="clearButton"
					class="w-100"
					[(ngModel)]="account.dob"
					formControlName="dob" 
					[inputStyle]="{'width':'100%'}" style="width: 100% !important">
				</p-calendar>
			</div>
			<span *ngIf="registerAccountForm.controls['dob'].invalid && (registerAccountForm.controls['dob'].dirty || registerAccountForm.controls['dob'].touched)" class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'SSN' | translate}} <span class="required-indicator">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user-shield"></i>
					</button>
				</div>
				<input pInputText type="text" [(ngModel)]="account.ssn" class="w-100" formControlName="ssn" minlength="9" maxlength="9" (keypress)="isNumber($event)"/>
			</div>
			<span *ngIf="(!registerAccountForm.controls['ssn'].valid || registerAccountForm.get('ssn').hasError('minlength') || registerAccountForm.get('ssn').hasError('maxlength')) && registerAccountForm.controls['ssn'].dirty " class="text-danger" style="font-size:12px">{{'This field is required' | translate}}</span>
		</div>
	</form>
	<p-footer>
		<div class="d-flex align-items-center justify-content-between p-2">
			<button class="btn btn-danger mx-1" (click)="showRegisterAccountDialog = false;"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-primary mx-1" (click)="registerAccount(registerAccountDataForm,true)" type="submit"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</div>
	</p-footer>
</p-dialog>
