import { RouterModule, Routes } from '@angular/router';
import { PaymentMethodComponent } from './component/payment-method/payment-method.component';
import { AuthGuard } from '../authenticate/auth.guard';
import { PaymentLayoutComponent } from './component/payment-layout/payment-layout.component';
import { PaymentDetailsComponent } from './component/payment-details/payment-details.component';
import { PaymentInvoiceListComponent } from './component/payment-invoice-list/payment-invoice-list.component';
import { PaymentOldInvoiceListComponent } from './component/payment-old-invoice-list/payment-old-invoice-list.component';
import { PaymentFollowUpNoteListComponent } from './component/payment-follow-up-note-list/payment-follow-up-note.component';
import { PaymentAvailableCreditListComponent } from './component/payment-available-credit-list/payment-available-credit.component';
import { PaymentDetailsListComponent } from "./component/payment-details-list/payment-details-list.component";
import { ViewPaymentMethodListComponent } from './component/view-payment-method-list/view-payment-method-list.component';
import { WalletComponent } from './component/wallet/wallet.component';
import { WalletTransactionComponent } from './component/wallet-transaction/wallet-transaction.component'
import { PaymentAvailabilityComponent } from './component/payment-availability/payment-availability.component';
import { ClientSelfPaymentComponent } from './component/client-self-payment/client-self-payment.component';
import { InvoiceVerificationComponent } from './component/invoice-verification/invoice-verification.component';
import { InvoiceSummaryComponent } from './component/invoice-summary/invoice-summary.component';
import { NgModule } from '@angular/core';

export const routes: Routes = [
    {
        path: '',
        component: PaymentLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'method', component: PaymentMethodComponent, pathMatch: 'full' },
            { path: 'detail/list', component: PaymentDetailsListComponent, canActivate: [AuthGuard] },
            { path: 'view-payment-method/list', component: ViewPaymentMethodListComponent, canActivate: [AuthGuard] },
            { path: 'detail', component: PaymentDetailsComponent, canActivate: [AuthGuard] },
            { path: 'invoice', component: PaymentInvoiceListComponent, canActivate: [AuthGuard] },
            { path: 'old-invoice', component: PaymentOldInvoiceListComponent, canActivate: [AuthGuard] },
            { path: 'follow-up-note', component: PaymentFollowUpNoteListComponent, canActivate: [AuthGuard] },
            { path: 'follow-up-note/:id', component: PaymentFollowUpNoteListComponent, canActivate: [AuthGuard] },
            { path: 'available-credit', component: PaymentAvailableCreditListComponent, canActivate: [AuthGuard] },
            { path: 'wallet', component: WalletComponent, canActivate: [AuthGuard] },
            { path: 'wallet-transaction', component: WalletTransactionComponent, canActivate: [AuthGuard] },
            { path: 'payment-availability', component: PaymentAvailabilityComponent, canActivate: [AuthGuard] },
            { path: 'client-self-payment', component: ClientSelfPaymentComponent, canActivate: [AuthGuard] },
            { path: 'invoice-verification', component: InvoiceVerificationComponent, canActivate: [AuthGuard] },
            { path: 'invoice-summary', component: InvoiceSummaryComponent, canActivate: [AuthGuard] },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaymentRoutingModule { }
