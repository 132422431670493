import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../../shared/shared.module';
import { PrimeNgModule } from '../../prime-ng/prime-ng.module';
import { JobFollowUpRoutingModule } from './job-follow-up-routing.module';
import { JobFollowUpListComponent } from './component/job-follow-up-list/job-follow-up-list.component';
import { JobFollowUpFormComponent } from './component/job-follow-up-form/job-follow-up-form.component';
import { JobFollowUpAddComponent } from './component/job-follow-up-add/job-follow-up-add.component';
import { JobFollowUpViewComponent } from './component/job-follow-up-view/job-follow-up-view.component';
import { JobFollowUpLayoutComponent } from './component/job-follow-up-layout/job-follow-up-layout.component';
import { CanDeactivateGuardClientLognAdd, CanDeactivateGuardClientLogView } from '../guards/unsaved-changes.guard';
import { JobFollowUpUpdateComponent } from './component/job-follow-up-update/job-follow-up-update.component';
import { JobFolloUpHistoryComponent } from './component/job-follo-up-history/job-follo-up-history.component';
import { ClientBillingReportComponent } from './component/client-billing-report/client-billing-report.component';
import { BillingTotalJobListComponent } from "./component/billing-total-job-list/billing-total-job-list.component";
import { DispatchTotalJobListComponent } from "./component/dispatch-total-job-list/dispatch-total-job-list.component";
import { BillingLogTotalJobComponent } from "./component/billing-log-total-job/billing-log-total-job.component";
import { BillingDetailsReportComponent } from "./component/billing-details-report/billing-details-report.component";
import { BillingCorrectionReportComponent } from "./component/billing-correction-report/billing-correction-report.component";
import { BillingUploadHistoryComponent } from "./component/billing-upload-history/billing-upload-history.component";
import { BillingLogDetailsReportComponent } from "./component/billing-log-details-report/billing-log-details-report.component";
import { BillingClientStatusComponent } from "./component/billing-client-status/billing-client-status.component";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNgModule,
    WebcamModule,
    SharedModule,
    JobFollowUpRoutingModule
  ],
  declarations: [
    JobFollowUpListComponent,
    JobFollowUpFormComponent,
    JobFollowUpAddComponent,
    JobFollowUpViewComponent,
    JobFollowUpLayoutComponent,
    JobFollowUpUpdateComponent,
    JobFolloUpHistoryComponent,
    ClientBillingReportComponent,
    BillingTotalJobListComponent,
    BillingDetailsReportComponent,
    BillingLogTotalJobComponent,
    BillingCorrectionReportComponent,
    BillingUploadHistoryComponent,
    BillingClientStatusComponent,
    BillingLogDetailsReportComponent,
    DispatchTotalJobListComponent
  ],
  providers: [
    CanDeactivateGuardClientLognAdd,
    CanDeactivateGuardClientLogView
  ]
})
export class JobFollowUpModule { }
