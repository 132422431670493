import { Injectable } from '@angular/core';
import { MonitoringDetailsService } from '../../admin/process-monitor/service/monitoring-details.service';
import { MonitoringActionModel } from '../model/monitoring-action.model';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class ActionMonitoringService {

    private readonly actions: MonitoringActionModel[] = [];

    constructor(
        private utilsService: UtilsService,
        private monitoringDetailsService: MonitoringDetailsService,
    ) {
    }

    startAction(action: MonitoringActionModel): void {
        this.resetActions();
        this.saveAction(action);
    }

    endAllActions(): void {
        const lastAction = this.popLastAction();
        this.reportAction(lastAction);
        this.resetActions();
    }

    startChildAction(action: MonitoringActionModel): void {
        const parentAction = this.getLastAction();
        this.reportAction(parentAction);
        this.saveAction(action);
    }

    endAction(actionPageTitle: string): void {
        if (actionPageTitle === this.getLastAction().pageTitle) {
            const lastAction = this.popLastAction();
            this.reportAction(lastAction);
            let parentAction = this.popLastAction();
            parentAction = this.getUpdatedActionToLatest(parentAction);
            this.saveAction(parentAction);
        } else {
            this.removeActionByPageTitle(actionPageTitle);
        }
    }

    private resetActions(): void {
        this.utilsService.visitedPageData = {} as any;
        this.actions.length = 0;
    }

    private saveAction(action: MonitoringActionModel): void {
        // console.warn('started  : ' + action.action);
        action.startTime = new Date();
        this.utilsService.visitPage(action.pageTitle, action.args, action.startTime);
        this.actions.push(action);
    }

    private reportAction(action: MonitoringActionModel): void {
        // console.warn('reported : ' + action.action);
        this.monitoringDetailsService.monitorAction(action.action, action.startTime, action.args, 'Completed', 'Navigation', 0);
    }

    private getLastAction(): MonitoringActionModel {
        return this.actions.length > 0 ? this.actions[this.actions.length - 1] : {} as MonitoringActionModel;
    }

    private popLastAction(): MonitoringActionModel {
        return this.actions.pop();
    }

    private getUpdatedActionToLatest(actionModel: MonitoringActionModel): MonitoringActionModel {
        const action = {...actionModel};
        action.startTime = new Date();
        return action;
    }

    private removeActionByPageTitle(actionName: string): void {
        const i = this.actions.findIndex(value => actionName === value.pageTitle);
        if (i > -1) {
            // console.warn('removed : ' + this.actions[i].action);
            this.actions.splice(i, 1);
        }
    }
}
