import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Contract } from '../../model/contract.model';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { EmployeeClockingService } from '../../../employee-clocking/service/employee-clocking.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { CompanyService } from '../../service/company.service';
import { ActivatedRoute } from '@angular/router';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { FileUtility } from '../../../shared/utility/file.utility';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { DocumentsService } from '../../../shared/service/documents-upload/documents.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-company-contract',
    templateUrl: './company-contract.component.html',
    styleUrls: ['./company-contract.component.scss'],
    providers: [EmployeeClockingService, FormValidatorService, DropDownsService]
})
export class CompanyContractComponent implements OnInit, AfterViewInit {
    contractForm: UntypedFormGroup;
    @Input() readOnly: Boolean = false;
    contract: Contract = new Contract();

    isEdit: boolean = false;
    showLicenceeSign: boolean = true;
    showLicensorSign: boolean = true;
    disableInput: boolean = false;
    
    signaturePadConfig: Object = {
        'minWidth': 1,
        'canvasWidth': 440,
        'canvasHeight': 225,
        'canvasBorder': 'none',
        'penColor': '#4a87f3'
    };

    @ViewChild('licenseeSignpad') licenseeSignaturePad: SignaturePad;
    @ViewChild('licensorSignpad') licensorSignaturePad: SignaturePad;
    msgs = [];
    companyId: number;

    constructor(
        private formValidator: FormValidatorService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private documentServicev2: DocumentsServicev2,
        private documentService: DocumentsService,
        private messageService: MessageService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.companyId = +params.id;
            this.getCompany(this.companyId);
            this.getContract(this.companyId);
        });
        this.contractForm = new UntypedFormGroup({
            effectiveDate: new UntypedFormControl({ value: new Date(), disabled: this.readOnly }, Validators.required),
            licensor: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licensee: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            businessPlace: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            identificationNo: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licenseeName: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licenseeAddress: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licenseePhone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            licenseePrintName: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licenseeEmail: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licenseeDirectPhone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            licenseeDate: new UntypedFormControl({ value: new Date(), disabled: this.readOnly }, Validators.required),
            licensorName: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licensorAddress: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licensorPhone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            licensorPrintName: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licensorEmail: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            licensorDirectPhone: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
            licensorDate: new UntypedFormControl({ value: new Date(), disabled: this.readOnly }, Validators.required),
        });
    }

    getCompany(companyId) {
        this.companyService.get(companyId).subscribe(res => {
            const resObj: any = res;
            this.contract.company = resObj.data;
        });
    }

    generateContract(contractDataForm, isUpdate) {
        if(!this.validateEmail()) return;
        if (this.formValidator.validateForm(this.contractForm, contractDataForm)) {
            this.uploadLicenceeSign(contractDataForm, isUpdate);
        } else {
            this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'All fields are required!' });
        }
    }

    uploadLicenceeSign(contractDataForm, isUpdate) {
        if (this.showLicenceeSign) {
            if (!this.licenseeSignaturePad.isEmpty()) {
                const licenseeSignatureRaw = this.dataURItoBlob(this.licenseeSignaturePad.toDataURL());
                const licenseeSignatureFile = new File([licenseeSignatureRaw], 'licenseeSignature.png');
                this.documentServicev2.uploadFile(licenseeSignatureFile, 'licensee_signature', this.companyId, 'Company') // upload licensee sign
                    .subscribe(res => {
                        const resObj: any = res;
                        this.contract.licenseeSignature = resObj.data;
                        this.uploadLicensorSign(contractDataForm, isUpdate);
                    });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Licensee Signature is required!' });
            }
        } else {
            this.uploadLicensorSign(contractDataForm, isUpdate);
        }
    }

    uploadLicensorSign(contractDataForm, isUpdate) {
        if (this.showLicensorSign) {
            if (!this.licensorSignaturePad.isEmpty()) {
                const licensorSignatureRaw = this.dataURItoBlob(this.licensorSignaturePad.toDataURL());
                const licensorSignatureFile = new File([licensorSignatureRaw], 'licensorSignature.png');
                this.documentServicev2.uploadFile(licensorSignatureFile, 'licensor_signature', this.companyId, 'Company') // upload licensor sign
                    .subscribe(res => {
                        const resObj: any = res;
                        this.contract.licensorSignature = resObj.data;
                        if(isUpdate){
                            this.updateContract(contractDataForm);
                        }else{
                            this.getContractPdfFile(this.contract);
                        }

                    });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'Licensor Signature is required!' });
            }
        } else {
            if(isUpdate){
                this.updateContract(contractDataForm);
            }else{
                this.getContractPdfFile(this.contract);
            }
        }
    }

    getContractPdfFile(contract) {
        this.companyService.generateCompanyContract(contract).subscribe(res => {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        });
    }

    ngAfterViewInit() {
        if (this.licenseeSignaturePad) {
            this.licenseeSignaturePad.set('minWidth', 1);
            this.licenseeSignaturePad.clear();
        }
        if (this.licensorSignaturePad) {
            this.licensorSignaturePad.set('minWidth', 1);
            this.licensorSignaturePad.clear();
        }
    }

    drawCompleteLincenceSign() {
    }

    drawCompleteLincensorSign() {
    }

    drawStartLincence() {

    }

    clearLicenceeSign() {
        this.showLicenceeSign = true;
        if (this.licenseeSignaturePad){
            this.licenseeSignaturePad.clear();
        }
    }

    clearLicensorSign() {
        this.showLicensorSign = true;
        if (this.licensorSignaturePad){
            this.licensorSignaturePad.clear();
        }
    }

    drawStartLincensor() {

    }

    customPhoneValidate(control: AbstractControl) {
        const value = control.value;
        if(value && !!value.replace(/\s/g, '')){
            const isValid = value.match(/(^[^a-zA-Z0-9() ]{1,}| [^a-zA-Z0-9() ]{2,})/g);
            if(!!isValid) {
                return {
                    'custom': true
                }
            }
        }
        return null;
    }

    restrictCharacter(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    

    validateEmail():boolean {
        if (!this.formValidator.checkValidEmails(this.contract.licenseeEmail)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Licensee email is not valid!' });
            return false;
        }
        if (!this.formValidator.checkValidEmails(this.contract.licensorEmail)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Licensor email is not valid!' });
            return false;
        }
        return true;
    }

    saveContractToDb(contractDataForm) {
        
        if(!this.validateEmail()) return;
        if (this.formValidator.validateForm(this.contractForm, contractDataForm)) {
            if (!this.licenseeSignaturePad.isEmpty() && !this.licensorSignaturePad.isEmpty()) {
                const licenseeSignatureRaw = this.dataURItoBlob(this.licenseeSignaturePad.toDataURL());
                const licensorSignatureRaw = this.dataURItoBlob(this.licensorSignaturePad.toDataURL());
                const licenseeSignatureFile = new File([licenseeSignatureRaw], 'licenseeSignature.png');
                const licensorSignatureFile = new File([licensorSignatureRaw], 'licensorSignature.png');
                this.documentServicev2.uploadFile(licenseeSignatureFile, 'licensee_signature', this.companyId, 'Company') // upload licensee sign
                    .subscribe(res => {
                        const resObj: any = res;
                        this.contract.licenseeSignature = resObj.data.id;
                        this.documentServicev2.uploadFile(licensorSignatureFile, 'licensor_signatureFile', this.companyId, 'Company') // upload licensor sign
                            .subscribe(res => {
                                const resObj: any = res;
                                this.contract.licensorSignature = resObj.data.id;
                                this.companyService.saveCompanyContract(this.contract, this.companyId).subscribe(res => { //save all data to database
                                    const resObj: any = res;
                                    if (resObj.status === 'SUCCESS') {
                                        this.contract.id = resObj.data.id;
                                        this.isEdit = true;
                                        this.disableInput = true;
                                        this.messageService.add({ severity: 'info', summary: 'Created', detail: 'contract created successfully'});
                                    } else {
                                        this.messageService.add({ severity: 'error', summary: 'Error', detail: resObj.message });
                                    }
                                });
                            });
                    });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Licensee/Licensor Signatures are required!' });
            }
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All fields are required!' });
        }
    }

    private dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: mimeString
        });
    }

    getContract(companyId: number) {
        this.companyService.companyContract(companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data) {
                    this.disableInput = true;
                    this.showLicenceeSign = false;
                    this.showLicensorSign = false;
                    this.isEdit = true;
                    this.contract = resObj.data;
                    this.contract.effectiveDate = new Date(this.contract.effectiveDate);
                    this.contract.licenseeDate = new Date(this.contract.licenseeDate);
                    this.contract.licensorDate = new Date(this.contract.licensorDate);
                    this.getSignatureFile(this.contract.licenseeSignature, true);
                    this.getSignatureFile(this.contract.licensorSignature, false);
                } else {
                    this.disableInput = false;
                }
            }
        });
    }

    getSignatureFile(id, st) {
        this.documentService.getUploadedFile(id).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if(st){
                    this.contract.licenseeSignature = resObj.data;
                }else{
                    this.contract.licensorSignature = resObj.data;
                }
            }
        }, error1 => {
            //error
        });
    }

    updateContract(contractDataForm) {
        // if (this.formValidator.validateForm(this.contractForm, contractDataForm)) {
            this.companyService.updateCompanyContract(this.contract, this.companyId).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.disableInput = false;
                    this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'Company Contract updated successfully!' });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: resObj.message });
                }
            });
        // } else {
        //     this.messageService.add({ severity: 'error', summary: 'Invalid data', detail: 'All fields are required!' });
        // }
    }


    enableForm () {
        this.disableInput = false;
        this.showLicensorSign = true;
    }

}
