import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { CrmDashboardComponent } from './component/crm-dashboard/crm-dashboard.component';
import { CrmDashboardLayoutComponent } from './component/crm-dashboard-layout/crm-dashboard-layout.component';


export const CRMDashBoardRoutes: Routes = [
    {
        path: 'app/crm-dashboard',
        component: CrmDashboardLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', component: CrmDashboardComponent },
        ]
    },
    {
        path: 'app/ceo/dashboard',
        component: CrmDashboardLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', component: CrmDashboardComponent },
        ]
    }
];

export const CRMDashboardRoutingModule = RouterModule.forChild(CRMDashBoardRoutes);
