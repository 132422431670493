import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-transaction-type-add',
    templateUrl: './transaction-type-add.component.html',
    styleUrls: ['./transaction-type-add.component.css']
})
export class TransactionTypeAddComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
