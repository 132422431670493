import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { PayrollSettingService } from '../../service/payroll-setting.service';
import { PayrollSetting } from '../../model/payroll-setting.model';
import { PayrollHoliday } from '../../model/payroll-holiday.model';
import { PayrollHolidayService } from '../../service/payroll-holiday.service';
import { DepartmentService } from '../../../department/service/department.service';
import { AgencyHolidayService } from '../../../agency/service';
import { AgencyHoliday } from '../../../agency/model';
import { PayrollModelUtility, NGDropdownModel } from '../../utility/model.utility';
import { PositionService } from '../../../position/service/position.service';
import { Position } from '../../../position/model/position.model';
import { AgencyService } from '../../../agency/service';
import { AGENCY_PAGE, CMD_ACTION } from '../../../agency/agency.constant';
import { Constants } from '../../../shared/model/constants';
import { CurrencyPipe } from '@angular/common';
import { Department } from '../../../department/model/department.model';

@Component({
    selector: 'app-payroll-setting-form',
    templateUrl: './payroll-setting-form.component.html',
    styleUrls: ['./payroll-setting-form.component.css'],
    providers: [AuthService, FormValidatorService, PayrollSettingService, PayrollHolidayService,
        DepartmentService, PositionService, CurrencyPipe]
})
export class PayrollSettingFormComponent implements OnInit {
    payrollSettingId: number;
    payrollSettingFormData: PayrollSetting;
    payrollSetting: PayrollSetting;
    payrollSettingsForm: UntypedFormGroup;
    displayEmployeeNameOnSub: boolean;
    payrollHolidays: PayrollHoliday[] = [];
    @Input() mode: string;
    @Input()
    readOnly = false;
    @Input() settingsForm: ElementRef;
    @Input()
    agencyId: number;
    days: Array<any>;
    msgs: Message[];
    totalRecords: Number = 0;
    displayHoliday = false;
    holiday: AgencyHoliday;
    holidays: AgencyHoliday[] = [];
    dropDownPositions: NGDropdownModel[] = [];

    allowEmployeePTOs: any[] = [];
    selectedSentToAlerts: any[] = [];
    roundOn: any = [];
    mins: any = [];
    size: number;
    page: number;
    disableOvertime = true;
    frequency: any[];
    historyShow = false;
    isEditAble = false;
    positions: Position[];
    departments: Department[] = [];
    constructor(
        private currencyPipe: CurrencyPipe,
        private authService: AuthService,
        private agencyService: AgencyService,
        private positionService: PositionService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidator: FormValidatorService,
        private departmentService: DepartmentService,
        private agencyHolidayService: AgencyHolidayService,
        private payrollSettingService: PayrollSettingService,
        private payrollHolidayService: PayrollHolidayService) {
    }

    ngOnInit() {
        this.frequency = [
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' }
        ];

        this.roundOn = [
            { value: 'Closest', label: 'Closest' },
            { value: 'Up', label: 'Up' },
            { value: 'Down', label: 'Down' }
        ];
        this.mins = [
            { value: 15, label: '15' },
            { value: 30, label: '30' },
            { value: 45, label: '45' },
            { value: 60, label: '60' }
        ];
        this.positionService.findAllForSelection({}).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.positions = resObj.data;
            }
        });

        this.departmentService.getAllByAgency(this.agencyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.departments = resObj.data;
            }
        });

        this.allowEmployeePTOs = PayrollModelUtility.getConditionalData();
        this.payrollHolidays = [];
        this.payrollSettingFormData = new PayrollSetting();
        this.setFormGroup();
        this.getPositions();
        if (this.agencyId) {
            this.getSettings(this.agencyId);
        }

        this.days = [{ label: 'Sun', value: 0 }, { label: 'Mon', value: 1 }, { label: 'Tue', value: 2 }, { label: 'Wed', value: 3 }, { label: 'Thur', value: 4 }, {
            label: 'Fri',
            value: 5
        }, { label: 'Sat', value: 6 }];
        this.msgs = [];
        this.isEditAble = this.isSuperAdmin() || this.isCompanyAdmin(); // get id from menu-list.ts
    }

    formatMoney(value) {
        const temp = `${value}`.replace(/\,/g, '');
        return this.currencyPipe.transform(temp).replace('$', '');
    }
    setDisable(event: boolean) {
        this.disableOvertime = event;
    }
    loadPayrollHolidayLazy(event?: any) {
        if (this.mode === 'update') {
            this.payrollHolidays = JSON.parse(localStorage.getItem('_payrollHolidays'));
            this.totalRecords = JSON.parse(localStorage.getItem('_totalRecords'));
        }
        if (this.mode === 'view') {
            this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
            this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
            this.loadPage({ page: this.page, size: this.size });
        }

    }
    viewPayroll() {
        this.router.navigate(['app/agency/payroll', this.agencyId, 'view']);
    }

    settingPayroll() {
        this.msgs = [];
        localStorage.setItem('_payrollHolidays', JSON.stringify(this.payrollHolidays));
        localStorage.setItem('_totalRecords', JSON.stringify(this.totalRecords));

        if (this.isEditAble) {
            localStorage.setItem('_payrollHolidays', JSON.stringify(this.payrollHolidays));
            localStorage.setItem('_totalRecords', JSON.stringify(this.totalRecords));
            this.agencyService.sendCommand({
                page: AGENCY_PAGE.PAYROLL_SETTING,
                action: CMD_ACTION.EDIT,
                data: this.agencyId
            });
        } else {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
        }
    }
    onCancel() {
        if (this.mode === Constants.FORM_MODE.UPDATE) {
            this.viewPayroll();
            // localStorage.removeItem('_agencyData');
        } else {
            const hasBranch = this.authService.isCurrentCompanyHasBranch();
            if (hasBranch) {
                localStorage.removeItem('_payrollSetting');
                localStorage.removeItem('_payrollHolidays');
                localStorage.removeItem('_totalRecords');
                this.agencyService.sendCommand({
                    page: AGENCY_PAGE.PAYROLL_SETTING,
                    action: CMD_ACTION.CANCEL,
                    data: {
                        routerLink: 'app/agency'
                    }
                });
            }
        }
    }
    loadPage(options) {
        this.payrollSettingService.getPayrollSetting(this.agencyId).subscribe((res: any) => {
            const data = (res.data) ? res.data : new PayrollSetting();
            this.payrollSettingFormData = data;
            this.displayEmployeeNameOnSub = this.payrollSettingFormData.displayEmployeeNameOnSub === 1 ? true : false;
            this.displayHoliday = true;
            if (this.payrollSettingFormData.useOvertime) {
                this.disableOvertime = true;
            } else {
                this.disableOvertime = false;
            }

            this.agencyHolidayService.readAllForDropDownList({ agencyId: this.agencyId }).subscribe(resHoliday => {
                const resObjHoliday: any = resHoliday;
                if (resObjHoliday.status === 'SUCCESS') {
                    this.holidays = resObjHoliday.data;

                }
            });
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data) {
                    this.payrollSetting = resObj.data;
                    this.payrollHolidayService.getByPayrollSetting(this.payrollSetting.id, options).subscribe(res1 => {
                        const res1Obj: any = res1;
                        if (res1Obj.status === 'SUCCESS') {
                            this.payrollHolidays = res1Obj.data.content;
                            this.payrollHolidays.forEach(payrollHoliday => {
                                payrollHoliday.holiday = this.holidays.find(holiday => payrollHoliday.holidayId === holiday.id);
                                if (payrollHoliday.positions.length === this.positions.length) {
                                    payrollHoliday.positionNames = 'All';
                                } else {
                                    const positionNames = [];
                                    payrollHoliday.positions.forEach(pos => {
                                        positionNames.push(pos.positionName);
                                    });
                                    payrollHoliday.positionNames = positionNames.join(',');
                                }

                                if (payrollHoliday.departments.length === this.departments.length) {
                                    payrollHoliday.departmentNames = 'All';
                                } else {
                                    const departmentNames = [];
                                    payrollHoliday.departments.forEach(dep => {
                                        departmentNames.push(dep.name);
                                    });
                                    payrollHoliday.departmentNames = departmentNames.join(',');
                                }
                                // this.departmentService.get(payrollHoliday.departmentId).subscribe(response => {
                                //     const resObj1: any = response;
                                //     if (resObj1.status === 'SUCCESS') {
                                //         payrollHoliday.department = resObj1.data;
                                //     }
                                // });

                            });
                            console.log(this.payrollHolidays);
                            this.totalRecords = res1Obj.data.totalElements;
                        }
                    });
                }
            }
        });

    }

    deletePayrollHistory(rowData: PayrollHoliday) {
        this.payrollHolidayService.delete(rowData.id).subscribe((_response) => {
            this.loadPayrollHolidayLazy();
        }, (error) => {
            console.log(error);
        });
    }

    editPayrollHistory(rowData: PayrollHoliday) {
        this.router.navigate(['app/agency/payroll', this.agencyId, 'holiday', 'edit', rowData.id],
            { queryParams: { returnUrl: this.router.url } });
    }

    addPayrollHoliday() {
        this.router.navigate(['app/agency/payroll', this.agencyId, 'holiday', 'add'],
            { queryParams: { returnUrl: this.router.url } });
    }

    changeHistoryHandler(payrollSettingId, mergedObject) {
        const admin = this.authService.getUserInfo();
        console.log(mergedObject);
        const historyData = [];
        const fieldsName = ['payReference', 'mileagePrice', 'mile',
            'roundOn', 'mins', 'payrollWeekEnding', 'minimumHourDaily', 'useOvertime',
            'displayEmployeeNameOnSub', 'overtimeAfter', 'overtimeMultipler',
            'sickTimeCalculationHour', 'sickTimeCalculationForEvery',
            'vacationTimeCalculationHour', 'vacationTimeCalculationForEvery'];
        fieldsName.forEach(element => {
            if (mergedObject[element] !== null && mergedObject[element] !== '') {
                const dataObj: any = {};
                dataObj.fieldName = element;
                dataObj.oldValue = '';
                dataObj.newValue = mergedObject[element];
                dataObj.action = 'Created';
                dataObj.userId = payrollSettingId;
                dataObj.payrollSettingId = payrollSettingId;
                dataObj.userName = admin.username;
                historyData.push(dataObj);
            }
        });
        console.log(historyData);
        const newData = { 'historyArrays': historyData };
        this.payrollSettingService.saveHistoryData(newData).subscribe((item: any) => {
            console.log(item);
        });
    }

    changeHistoryHandlerEdit(newClientData) {
        const admin = this.authService.getUserInfo();
        console.log(newClientData);
        this.payrollSettingService.getbyId(newClientData.id).subscribe((item: any) => {
            const oldData = item.data;
            console.log(oldData);
            const historyData = [];
            const fieldsName = ['payReference', 'mileagePrice', 'mile',
                'roundOn', 'mins', 'payrollWeekEnding', 'minimumHourDaily', 'useOvertime',
                'displayEmployeeNameOnSub', 'overtimeAfter', 'overtimeMultipler',
                'sickTimeCalculationHour', 'sickTimeCalculationForEvery',
                'vacationTimeCalculationHour', 'vacationTimeCalculationForEvery'];
            fieldsName.forEach(element => {
                if (newClientData[element] !== oldData[element]) {
                    const dataObj: any = {};
                    dataObj.fieldName = element;
                    if (element === 'date') {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = new Date(newClientData[element]).getTime();
                    } else {
                        dataObj.oldValue = oldData[element];
                        dataObj.newValue = newClientData[element];
                    }

                    if (dataObj.newValue.length === 0) {
                        dataObj.action = 'Deleted';
                    } else {
                        dataObj.action = 'Updated';
                    }

                    dataObj.payrollSettingId = newClientData.id;
                    dataObj.userName = admin.username;
                    historyData.push(dataObj);
                }
            });
            console.log(historyData);
            const newData = { 'historyArrays': historyData };
            this.payrollSettingService.saveHistoryData(newData).subscribe((item: any) => {
                console.log(item);
            });
        });
    }
    getHistory() {

        this.historyShow = true;
    }
    modifyPayrollSetting(form) {
        console.log(this.payrollSettingsForm);
        if (this.formValidator.validateForm(this.payrollSettingsForm, form)) {
            this.payrollSettingFormData.agencyId = this.agencyId;
            // this.assignSentToIdsToObject();
            localStorage.setItem('_payrollSetting', JSON.stringify(this.payrollSettingFormData));
            if (this.payrollSettingFormData.id) {
                this.changeHistoryHandlerEdit(this.payrollSettingFormData);
                this.payrollSettingService.savePayrollSetting(this.payrollSettingFormData).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.msgs.push({ severity: 'info', summary: 'Success', detail: 'Payroll Settings have been updated!' });
                        setTimeout(() => this.viewPayroll(), 2000);
                    }
                });
            } else {
                this.payrollSettingService.savePayrollSetting(this.payrollSettingFormData).subscribe(res => {
                    const resObj: any = res;
                    if (resObj.status === 'SUCCESS') {
                        this.changeHistoryHandler(resObj.data.id, this.payrollSettingFormData);
                        this.msgs.push({ severity: 'info', summary: 'Success', detail: 'Payroll Settings have been updated!' });
                        setTimeout(() => this.viewPayroll(), 2000);
                    }
                });
            }

        }
    }

    EmployeeNameOnSub(checked: boolean) {
        this.payrollSettingFormData.displayEmployeeNameOnSub = checked === true ? 1 : 0;
    }

    getSettings(agencyId: number) {
        const data = JSON.parse(localStorage.getItem('_payrollSetting'));
        this.payrollSettingFormData = data;
        if (this.payrollSettingFormData.alertEmployeeEndTime) {
            this.payrollSettingFormData.alertEmployeeEndTime = new Date(this.payrollSettingFormData.alertEmployeeEndTime);
        }
        if (this.payrollSettingFormData.employeeLateAfterHours) {
            const hour = Math.floor(parseInt(this.payrollSettingFormData.employeeLateAfterHours, 10) / 60);
            const min = parseInt(this.payrollSettingFormData.employeeLateAfterHours, 10) - hour * 60;
            this.payrollSettingFormData.employeeLateAfterHours = `${hour < 10 ? '0' + hour.toString() : hour}:${min < 10 ? '0' + min.toString() : min}`;
        }
        if (this.payrollSettingFormData.onTimeRate) {
            this.payrollSettingFormData.onTimeRate = new Date(this.payrollSettingFormData.onTimeRate);
        }

        // if (this.payrollSettingFormData.lunchTimeDuration) {
        //     this.payrollSettingFormData.lunchTimeDuration = new Date(this.payrollSettingFormData.lunchTimeDuration);
        // }

        this.payrollSettingId = this.payrollSettingFormData.id;
        this.displayEmployeeNameOnSub = this.payrollSettingFormData.displayEmployeeNameOnSub === 1 ? true : false;
        this.displayHoliday = true;
        if (this.payrollSettingFormData.useOvertime) {
            this.disableOvertime = true;
        } else {
            this.disableOvertime = false;
        }
    }
    setAndFormatPrice(mileagePrice) {

    }
    private getPositions() {
        this.positionService.readAllForDropDownList({})
            .subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.dropDownPositions = resObj.data.map((position) => ({
                        label: position.name,
                        value: position.id
                    }));
                }
            });
    }

    private setFormGroup() {
        this.payrollSettingsForm = new UntypedFormGroup({
            payReference: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            useOvertime: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            minimumHourDaily: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            displayEmployeeNameOnSub: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            payrollWeekEnding: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            overtimeAfter: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            overtimeMultipler: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
            vacationTimeCalculationHour: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.pattern('^[0-9]*\\.?[0-9]+$')),
            vacationTimeCalculationForEvery: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            sickTimeCalculationHour: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.pattern('^[0-9]*\\.?[0-9]+$')),
            sickTimeCalculationForEvery: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.pattern('^([0-9][0-9]):[0-5][0-9]$')]),
            mileagePrice: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            mile: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            roundOn: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            mins: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            // Other group
            allowEmployeePTO: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            sendAlertTo: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            employeeLate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            alertEmployeeEndTime: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            frequency: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            frequencyForOvertimeRate1: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            lunchTimeDuration: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            redeemHours: new UntypedFormControl({ value: '', disabled: this.readOnly }),
            onTimeRate: new UntypedFormControl({ value: '', disabled: this.readOnly }),
        });
    }

    payrollSettingToDropDownChange(event) {
        if (event.value.length === this.dropDownPositions.length) {
            const x = document.getElementById('id_payrollSetting_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('id_payrollSetting_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }
    public showHistory() {
        this.historyShow = true;
    }

    public hideHistory() {
        this.historyShow = false;
    }

    isSuperAdmin() {
        const role = this.authService.getRoleLevel();
        return role === 1 || role === 2;
    }

    isCompanyAdmin() {
        const role = this.authService.getRoleLevel();
        return role === 4 || role === 3;
    }
}
