import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../shared/model/constants';
import { FollowUplabel } from '../model/follow-up-label'
import { AbstractService } from '../../../shared/service/abstract.service';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class BillingClientStatusService extends AbstractService<any>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.BILLING_CLIENT_STATUS, '');
  }

  getList(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  save(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}save`, options).pipe(map(resp => resp));
  }

  getCreatedByList(options: any): Observable<any> {
    let params = new HttpParams();
    if (options.companyId) {
      params = params.set('companyId', options.companyId);
    }
    if (options.clientId) {
      params = params.set('clientId', options.clientId);
    }

    return this._http.get<any>(`${this.baseUrlV2}find-created-by-user`, { params }).pipe(map(resp => resp));
    // return this._http.get<any>(`http://localhost:8010/api/v2/crm/billing-client-status/find-created-by-user`, { params }).pipe(map(resp => resp));
  }
}
