import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../shared/model/constants';
import { PaginatedResponse } from '../../shared/model/PageResponse/paginated.response';
import { AbstractService } from '../../shared/service/abstract.service';
import {NotificationModel} from '../model/notification.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AbstractService<NotificationModel> {

  notificationUrl: string;

  private invoiceListChangeSubject = new Subject<any>();
  invoiceListChange$ = this.invoiceListChangeSubject.asObservable();

  notifyInvoiceStatusChanged(status) {
    this.invoiceListChangeSubject.next({
      type: 'STATUS',
      status: status
    });
  }

  notifyInvoiceNumbersChanged(invoices) {
    this.invoiceListChangeSubject.next({
      type: 'INVOICE_NUMBER',
      invoices: invoices
    });
  }

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/notification', '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  search(options: any): Observable<PaginatedResponse<NotificationModel>> {
    //return this._http.post<PaginatedResponse<NotificationModel>>(`http://localhost:8007/api/v2/operation/notification/search`, options).pipe(map(res => res));
    return this._http.post<PaginatedResponse<NotificationModel>>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  exportPdf(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}pdf`, options).pipe(map(res => res));
  }

  getNotificationListHistory(entity) {
    return this._http.post(`${this.baseUrlV2}search-history`, entity).pipe(map(res => res));
  }

  sendTextEmail(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }

  createNotification(entity: any): Observable<any> {
    //return this._http.post<any>('http://localhost:8007/api/v2/operation/notification', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  readNotification(options: any): Observable<PaginatedResponse<NotificationModel>> {
    return this._http.post<PaginatedResponse<NotificationModel>>(`${this.baseUrlV2}is-read`, options).pipe(map(res => res));
  }
}
