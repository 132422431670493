<div class="loading-bg-upload" *ngIf="loadingUpload"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
        <style>
            .line {
                fill: none;
                stroke: #aaa;
                stroke-width: 8;
                stroke-dasharray: 40
            }

            .animated {
                animation: draw .8s ease-in-out;
                animation-iteration-count: infinite;
                stroke-dashoffset: 0
            }

            .line2 {
                animation-delay: .08s
            }

            .line3 {
                animation-delay: .16s
            }

            @keyframes draw {
                0% {
                    stroke-dashoffset: 0
                }

                50% {
                    stroke-dashoffset: 30
                }

                100% {
                    stroke-dashoffset: 0
                }
            }
        </style>
        <path class="line animated" d="M14 10V50z" />
        <path class="line animated line2" d="M30 10V50z" />
        <path class="line animated line3" d="M46 10V50z" />
    </svg>
</div>
<div class="background-list">
    <div class="background-item">
        <label class="background-none bg-add-btn" for="upload-background">
            Upload +
        </label>
        <input id="upload-background" (change)="onSelectFile($event)" style="display: none;" type="file" />
    </div>
    <ng-container *ngFor="let item of backgroundList;let i=index">
        <div class="background-item" (click)="pickBg(item)" *ngIf="i<maxItem">
            <div class="checked" *ngIf="background==item.background">
                <i class="material-icons selected">done</i>
            </div>
            <img [src]="item.background" *ngIf="item.background" />
            <div *ngIf="!item.background" class="background-none">None</div>
        </div>
    </ng-container>
    <div class="loadmore-btn" (click)="maxItem=20" *ngIf="maxItem<20">Load more</div>
</div>