import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { Constants } from '../../shared/model/constants';
import { Popup } from '../model/popup';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PopupService extends AbstractService<Popup> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.POPUP, '');
    }

    savePopup(data: Popup): Observable<Popup> {
        return this._http.post<Popup>(`${this.baseUrl}`, data).pipe(map(res => res));
    }

    getPopupByEmployeeId(id: number): Observable<Popup> {
        return this._http.get<Popup>(`${this.baseUrl}getPopupByEmployeeId?id=${id}`).pipe(map(res => res));
    }

}
