import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { ProductService } from 'app/crm/service/product.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { FileUtility } from 'app/shared/utility/file.utility';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-mas-billing',
  templateUrl: './mas-billing.component.html',
  styleUrls: ['./mas-billing.component.scss']
})
export class MasBillingComponent implements OnInit {
  @Input() id;
  @Input() disableEdit;
  row;
  emailClientSelect;
  clientName;
  bodyEmail;
  showEditButton = false;
  shareContractDialog = false;
  private _disableEditingContract = null;
  contractForm: UntypedFormGroup;
  signaturePadConfig: Object = {
    minWidth: 3,
    canvasWidth: 263,
    canvasHeight: 70,
    canvasBorder: 'none',
    penColor: '#0084ff',
  };

  @Input('disableEditingContract')
  set disableEditingContract(disableEditingContract: Boolean) {
    if (this._disableEditingContract != null) {
      if (disableEditingContract) {
        this.lockEditing();
      } else {
        this.enableEditing();
      }
    }
    this.checkAndSetContractData();

    this._disableEditingContract = disableEditingContract;
  }

  get disableEditingContract(): Boolean { return this._disableEditingContract; }

  @ViewChild('companySignpad') companySignpad: SignaturePad;
  @ViewChild('clientSignpad') clientSignpad: SignaturePad;
  companyId;
  @Output() onHide = new EventEmitter<boolean>();
  @Output() getProductByClient = new EventEmitter<boolean>();

  constructor(private documentServiceV2: DocumentsServicev2,
    private authService: AuthService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private contentService: ProductService) {
    this.route.params.subscribe(params => this.id = +params.id);
  }

  ngOnInit(): void {
    this.contractForm = new UntypedFormGroup({
      clientNameContract: new UntypedFormControl(null, [Validators.required]),
      baseNumber: new UntypedFormControl(null, [Validators.required]),
      // clientAddress: new FormControl(null, [Validators.required]),
      fromMas: new UntypedFormControl(null, [Validators.required]),
      unchangedRemain: new UntypedFormControl(null, [Validators.required]),
      operrClaim: new UntypedFormControl(null, [Validators.required]),
      effectiveDate: new UntypedFormControl(null, [Validators.required]),
      printNameCompany: new UntypedFormControl(null, [Validators.required]),
      signatureCompany: new UntypedFormControl(null, [Validators.required]),
      emailCompany: new UntypedFormControl(null, [Validators.required]),
      directPhoneCompany: new UntypedFormControl(null, [Validators.required]),
      dateCompany: new UntypedFormControl(null, [Validators.required]),

      clientName: new UntypedFormControl(null),
      addressClient: new UntypedFormControl(null),
      phoneClient: new UntypedFormControl(null),
      printNameClient: new UntypedFormControl(null),
      signatureClient: new UntypedFormControl(null),
      emailClient: new UntypedFormControl(null),
      directPhoneClient: new UntypedFormControl(null),
      dateClient: new UntypedFormControl(null),
    });
    this.companyId = this.authService.getCurrentCompanyId();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.disableEditingContract) {
        this.lockEditing();
      } else {
        this.enableEditing();
      }
      if (!this.disableEdit) {
        this.contractForm.controls['printNameCompany'].disable();
        this.contractForm.controls['emailCompany'].disable();
        this.contractForm.controls['directPhoneCompany'].disable();
        this.contractForm.controls['dateCompany'].disable();
        this.companySignpad.off();
      }
      this.checkAndSetContractData();
	  this.companySignpad.set('canvasWidth', 263);
	  this.companySignpad.set('canvasHeight', 70);
	  this.companySignpad.set('minWidth', 3);
	  this.companySignpad.set('penColor', '#0084ff');
	  this.companySignpad.clear();

	  this.clientSignpad.set('canvasWidth', 263);
	  this.clientSignpad.set('canvasHeight', 70);
	  this.clientSignpad.set('minWidth', 3);
	  this.clientSignpad.set('penColor', '#0084ff');
	  this.clientSignpad.clear();
    });
  }

  updateProduct() {
    this.contractForm.value.templateType = 1;
    this.row.contractDocumentInfo = JSON.stringify(this.contractForm.value);
    this.contentService.updateProductContract(this.row).subscribe(res => {
      this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'eContract updated successfully!' });
      this.onHide.emit(true);
      this.getProductByClient.emit();
    },
      () => this.messageService.add({ severity: 'error', summary: 'Error', detail: 'eContract updated error!' }));
  }

  generateContract() {
    if (this.companySignpad.isEmpty()) {
      this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'Please sign the contract !' });
      return;
    }
    this.contractForm.get('signatureCompany').setValue(this.companySignpad.toDataURL());
    this.contractForm.get('signatureClient').setValue(this.clientSignpad.toDataURL());
    if (this.contractForm.valid) {
      this.contentService.generateContractPdf(4, this.contractForm.value).subscribe((res: any) => {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const contractFile = new File([blob], 'eContract.pdf');
        this.documentServiceV2.uploadFile(contractFile, 'econtract_file', this.companyId, 'eContract').subscribe(res => {
          this.row.contractDocumentId = res.data.id;
          this.updateProduct();
        });
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'Please enter all fields !' });
    }
  }

  isRequired(field) {
    return this.contractForm.get(field).hasError('required') && this.contractForm.get(field).touched;
  }
  isEmail(field) {
    return this.contractForm.get(field).invalid && this.contractForm.get(field).touched;
  }

  lockEditing() {
    this.contractForm.disable();
    this.companySignpad.off();
    this.clientSignpad.off();
  }

  cancelEditing() {
    this.onHide.emit(true);
  }

  enableEditing() {
    this.contractForm.enable();
    this.companySignpad.on();
    this.clientSignpad.on();
  }

  edit() {
    this.disableEditingContract = false;
  }

  checkAndSetContractData() {
    if (!this.id) {
      return;
    }
    this.contentService.getProductPrice(this.id).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.row = resObj.data;
        this.clientName = this.row?.client.name;
        this.emailClientSelect = this.row?.client.email;
        if (this.row.contractDocumentInfo != null) {
          if (this.row.contractSignAt == null || ((new Date().getTime() - this.row.contractSignAt) < 1000 * 3600 * 24)) {
            this.showEditButton = true;
          }

          if (this.contractForm != null) {
            this.contractForm.patchValue(JSON.parse(this.row.contractDocumentInfo));
            this.companySignpad.fromDataURL(JSON.parse(this.row.contractDocumentInfo).signatureCompany);
            this.clientSignpad.fromDataURL(JSON.parse(this.row.contractDocumentInfo).signatureClient);
          }
        }
      } else {
        this.contractForm.reset();
        this.companySignpad.clear();
        this.clientSignpad.clear();
      }
    });
  }


clearSign(type?) {
	if(!type){
		this.contractForm.get('signatureCompany').setValue(null);
		if (this.companySignpad) {
			this.companySignpad.clear();
			this.contractForm.get('signatureCompany').setValue(null);
		}
	} else {
		this.contractForm.get('signatureClient').setValue(null);
		if (this.clientSignpad) {
			this.clientSignpad.clear();
			this.contractForm.get('signatureClient').setValue(null);
		}
	}
}



  sendEmail() {
    const emails = [];
    const url = location.origin + '/#/mas-client/' + this.id;
    this.bodyEmail = 'Hi ' + this.clientName + ',<br>Click on view <a href="' + url + '">eContract</a> hyberlink  have access to the contract, please fill out the required information and sign then click <strong>Submit</strong> when done. </br></br> Best Regards!<br> For any concern or support email us.</br>Support@operrwork.com';
    emails.push(this.emailClientSelect);
    const payload = {
      toEmails: emails,
      subject: 'eContract',
      body: this.bodyEmail,
      emailCompanyId: this.authService.getUserInfo().companyId,
    }
	this.contentService.sendEmailQuote(payload).subscribe(() => {
	this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Send email quote successfully' });
	this.shareContractDialog = false;
    }, () => {
      this.shareContractDialog = false;
    });
  }

  rejectEmail() {
    this.shareContractDialog = false;
  }

  showSendEmail() {
    this.shareContractDialog = true;
    const url = location.origin + '/#/mas-client/' + this.id;
    this.bodyEmail = 'Hi ' + this.clientName + ',<br>Click on view <a href="' + url + '">eContract</a> hyberlink  have access to the contract, please fill out the required information and sign then click <strong>Submit</strong> when done. </br></br> Best Regards!<br> For any concern or support email us.</br>Support@operrwork.com';
  }

  copyLink() {
    return location.origin + '/#/mas-client/' + this.id;
  }

	onClipboardCopy(successful: boolean): void {
		if (successful) {
			this.messageService.add({ severity: 'success', summary: 'Copied', detail: 'Copy successfully' });
		}
	}

  createPdf() {
    this.contentService.generateContractPdf(1, this.contractForm.value).subscribe((res: any) => {
      const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    })
  }
}