import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'opr-polygon-map',
  templateUrl: './polygon-map.component.html',
  styleUrls: ['./polygon-map.component.scss'],
})
export class PolygonMapComponent implements OnInit, OnChanges {
  @Input() polygonIds: any = [];
  @Input() boundCoordinates: any = [[]];
  @Input() zoneType: any = {};
  @Input() displayAllZone: boolean = false;
  @Output() onPolygonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectCounty: EventEmitter<any> = new EventEmitter<any>();

  polygons: any = [];
  baseId: number;
  address: string;
  defaultAddress = '130-30 31st Avenue, 801 8th Floor, Flushing, NY 11354';
  lat = 40.7830603;
  lon = -73.9712488;

  constructor() {}

  ngOnInit() {}

  onPolygonItemClick(data) {
    this.onPolygonClick.emit(data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['polygonIds'] &&
      changes['polygonIds'].previousValue !== changes['polygonIds'].currentValue
    ) {
      this.polygons = this.polygonIds;
    }
  }

  onCountySelected(polygonIds) {
    this.onSelectCounty.emit(polygonIds);
  }
}
