<h1 class="m-0 ml-2 my-4">{{'Discounts & Waivers' | translate}}</h1>
<p-panel [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Discounts & Waivers' | translate}}</span>
    </p-header>
    <div class="ui-g ">
        <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
            <label>{{'companyLabel' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-building"></i>
                    </button>
                </div>
				<p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" filter="true" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Type' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  id="button-addon2"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-filter-circle-dollar"></i>
                    </button>
                </div>
				<p-dropdown [options]="typeDropdown" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedType">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'dateRange' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white"  id="button-addon2"  type="button" style="cursor:default !important">
                        <i class="fa-solid fa-calendar-week"></i>
                    </button>
                </div>
                <mat-form-field class="w-100">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
                        <input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="ui-g-12 text-center py-4">
            <button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
            <button type="button" class="btn btn-primary mx-1"  (click)="searchData()"><i class="fa-solid fa-check mr-2"></i>{{'Search' | translate}}</button>
        </div>
    </div>
</p-panel>
<h1 class="m-0 ml-2 my-4">{{'Browse Discounts & Waivers' | translate}}</h1>
<p-panel id="table-ppanel" [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Discounts & Fee Waivers' | translate}} <span *ngIf="totalRecords">({{totalRecords}})</span></span>
    </p-header>
    <p-table #dt [value]="listData" dataKey="id" selectionMode="single" [lazy]="true" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]"
             [totalRecords]="totalRecords" (onLazyLoad)="search($event)" [columns]="cols" [responsive]="true" [loading]="loading">
        <ng-template pTemplate="caption">
            <div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
                <span class="d-flex">
					<button type="button" class="btn btn-primary mr-2" (click)="exportPdfDiscountAndWaiveFee()" [disabled]="listData.length === 0">
						<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
					</button>
				</span>
                <div class="input-group w-100 mr-2" style="max-width: 450px">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-filter"></i>
                        </button>
                    </div>
                    <input [(ngModel)]="filterText" (input)="searchData()" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.field" [style.width]="col?.width">
                        <span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100" style="row-gap: 10px">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
<!--								<span *ngIf="!col.sort">-->
<!--									<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>-->
<!--								</span>-->
<!--								<span *ngIf="col.sort">-->
<!--									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sort)">-->
<!--										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>-->
<!--									</span>-->
<!--								</span>-->
							</div>
                        </span>
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
			<tr *ngIf="loading && (rowIndex + 1 === 1)">
                <td [attr.colspan]="columns.length" class="text-center py-5">
					<span class="text-muted">{{'Loading...' | translate}}</span>
                </td>
            </tr>
            <tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td>
                    <span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" class="c-pointer text-primary" (click)="clickInvoiceRef($event, rowData);" pTooltip="{{rowData?.invoiceNumber}}">{{rowData?.invoiceNumber}}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.date" pTooltip="{{rowData.date | date: 'M/d/yyyy h:mm:ss a'}}">{{rowData.date | date: 'M/d/yyyy h:mm:ss a'}}</span>
						<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'AddedBy' | translate}}: </span>
						<span *ngIf="rowData.addedBy" pTooltip="{{rowData?.addedBy}}">{{rowData?.addedBy}}</span>
						<span *ngIf="!rowData.addedBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Type' | translate}}: </span>
						<span *ngIf="rowData.discountType" pTooltip="{{getDiscountType(rowData.discountType) | translate}}">{{getDiscountType(rowData.discountType) | translate}}</span>
						<span *ngIf="!rowData.discountType" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Amount' | translate}}: </span>
						<span *ngIf="rowData.amount" pTooltip="{{rowData.amount | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.amount | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.amount" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading && listData.length === 0">
				<td [attr.colspan]="columns.length" class="text-center py-5">
					<span class="text-muted my-5">{{'no data' | translate}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
			<ng-template let-col pTemplate="item">
					<div>
							<span>{{col.name | translate}}</span>
					</div>
			</ng-template>
	</p-listbox>
</p-overlayPanel>
<!--<p-overlayPanel #op1 appendTo="body">-->
<!--    <p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption"-->
<!--               (onChange)="sortingClick(selectedSortOption)">-->
<!--        <ng-template let-col pTemplate="item">-->
<!--            <div>-->
<!--                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">-->
<!--                <span>{{col.name | translate}}</span>-->
<!--            </div>-->
<!--        </ng-template>-->
<!--    </p-listbox>-->
<!--</p-overlayPanel>-->
<!--<p-overlayPanel #op2 appendTo="body">-->
<!--    <p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption"-->
<!--               (onChange)="sortingClick(selectedSortOption)">-->
<!--        <ng-template let-col pTemplate="item">-->
<!--            <div>-->
<!--                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">-->
<!--                <span>{{col.name | translate}}</span>-->
<!--            </div>-->
<!--        </ng-template>-->
<!--    </p-listbox>-->
<!--</p-overlayPanel>-->
