<div [class]="isPunchInOut? 'layout-breadcrumb pt-0' : 'layout-breadcrumb'">
    <ul>
        <li><a (click)="onHomeClick()"><i class="material-icons">home</i></a></li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label | translate}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label | translate}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
        <li *ngIf="isShowSixHoursMessage" style="width: 400px;"></li>
        <li *ngIf="isShowSixHoursMessage">
            <a style="color: red; font-size: 15px;padding-left: 50px;"><strong>You're on violation of the labor department law.</strong>
            </a>
        <br>
        <span style="font-size: 10px;">When the employee works for MORE than 6 hours, it's necessary to take a rest break of at least 30 minutes.</span>
            
        </li> 
    </ul>
    <div class="layout-breadcrumb-options">
<!--        <ng-container *ngIf="disable === 1; else enableBackup">-->
<!--            <a *ngIf="!isEmployeeUser" title="Cloud coming soon" aria-disabled="true">-->
<!--                <i class="material-icons">backup</i>-->
<!--            </a>-->
<!--        </ng-container>-->
<!--        <ng-template #enableBackup>-->
<!--            <a *ngIf="!isEmployeeUser"  routerLink="/" title="Cloud coming soon">-->
<!--                <i class="material-icons">backup</i>-->
<!--            </a>-->
<!--        </ng-template>-->
        <!-- <ng-container *ngIf="disable === 1; else enableBookmark">
            <a title="Bookmark" aria-disabled="true">
                <i class="material-icons">bookmark</i>
            </a>
        </ng-container>
        <ng-template #enableBookmark>
            <a routerLink="/" title="Bookmark">
                <i class="material-icons">bookmark</i>
            </a>
        </ng-template> -->
        <a title="Logout" style="cursor: pointer;">
            <i class="material-icons" (click)="triggerLogOut()">power_settings_new</i>
        </a>
    </div>
</div>
