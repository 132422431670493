import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {map} from 'rxjs/operators';
import {Page} from '../../shared/model/response';
import {AbstractService} from '../../shared/service/abstract.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SmsMonitoringService extends AbstractService<any> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.SMS_MONITORING, '');
    }

    search(options: any) {
        return this._http.post<Page<any>>(this.baseUrlV2 + 'search', options).pipe(map(resp => resp));
        // return this._http.post<Page<any>>('http://localhost:8009/api/v2/notification/sms-monitoring/search', options).pipe(map(resp => resp));
    }
    saveData(data: any) {
        return this._http.post(this.baseUrlV2, data).pipe(map(res => res));
        // return this._http.post('http://localhost:8009/api/v2/notification/sms-monitoring', data).pipe(map(res => res));
    }
    
    countDataByCondition(data: any) {
        //return this._http.post('http://localhost:8009/api/v2/notification/sms-monitoring/count-data-by-condition', data).pipe(map(res => res));
        return this._http.post(this.baseUrlV2 + 'count-data-by-condition', data).pipe(map(res => res));
    }

    countDataByFrequency(data: any) {
        //return this._http.post('http://localhost:8009/api/v2/notification/sms-monitoring/count-data-by-condition', data).pipe(map(res => res));
        return this._http.post(this.baseUrlV2 + 'count-data-by-frequency', data).pipe(map(res => res));
    }

    sendToEmail(mail: any): Observable<any> {
        //return this._http.post('http://localhost:8009/api/v2/notification/sms-monitoring/send-to-email', mail).pipe(map(res => res));
        return this._http.post(`${this.baseUrlV2}send-to-email`, mail).pipe(map(resp => resp));
    }

    findById(id: number) {
        return this._http.get(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    printOut(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}printOut`, data).pipe(map(resp => resp));
    }

    getReportSummary(options: any) {
        return this._http.post<any>(`${this.baseUrlV2}report-summary`, options).pipe(map(resp => resp));
    }

    exportSummaryReport(options: any) {
        return this._http.post<any>(`${this.baseUrlV2}export-summary`, options).pipe(map(resp => resp));
    }

    exportSummaryReportNew(options: any) {
        return this._http.post<any>(`${this.baseUrlV2}export-summary-new`, options).pipe(map(resp => resp));
    }
}
