import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-multi-select-group',
  templateUrl: './multi-select-group.component.html',
  styleUrls: ['./multi-select-group.component.scss']
})
export class MultiSelectGroupComponent implements OnInit {

  @Input() options = [];

  @Input() disabled: string;

  selectedItems = [];
  selectedSubItems = [];
  selectedSubItems2 = [];
  allSelectedLabels = [];

  @Input()
  set initValue(value) {
    this.selectedItems = [];
    this.selectedSubItems = [];
    this.selectedSubItems2 = [];
    this.allSelectedLabels = [];
    if (!value || !value.length) {
      return;
    }
    value.filter(v => v.value).forEach(s => {
      if (!s.level || !s.itemValue) {
        this.selectedItems.push(s.value);
      } else if (s.itemValue) {
        if (s.level === 1) {
          this.selectedSubItems.push(s);
        } else {
          this.selectedSubItems2.push(s);
        }
      }
    });
    this.updateAllSelectedItem();
  }

  @Input() selected = [];
  @Output() selectedChange = new EventEmitter<any[]>();

  private hasSubItemSelected = new Set<string>();
  private hasSubItemSelected2 = new Set<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["selected"] &&
      changes["selected"].previousValue !==
      changes["selected"].currentValue
    ) {

      if (!this.selected || !this.selected.length) {
        this.selectedItems = [];
        this.selectedSubItems = [];
        this.selectedSubItems2 = [];
        this.allSelectedLabels = [];
      }

    }
  }

  onPanelHide() {
    this.options.forEach(option => {
      if (this.hasSubItemSelected.has(option.value)) {
        option.hasSubItemSelected = true;
      } else {
        option.hasSubItemSelected = false;
      }
      if (option.subItems) {
        option.subItems.forEach(sub => {
          if (this.hasSubItemSelected2.has(sub.value)) {
            sub.hasSubItemSelected = true;
          } else {
            sub.hasSubItemSelected = false;
          }
        })
      }
    });
  }

  onSelectItem(event) {
    setTimeout(() => {
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedSubItems2 = this.selectedSubItems2.filter(v => v);
      if (!event.itemValue) {
        if (!this.selectedItems.length) {
          this.selectedSubItems = [];
          this.selectedSubItems2 = [];
          this.updateAllSelectedItem();
          return;
        }
        const subItems = [];
        const subItems2 = [];
        for (let itemValue of this.selectedItems) {
          this.options.forEach(option => {
            if (option && option.subItems) {
              option.subItems.forEach(sub => {
                if (sub.itemValue == itemValue) {
                  subItems.push(sub);
                  if (sub.subItems) {
                    sub.subItems.forEach(sub2 => {
                      subItems2.push(sub2);
                    })
                  }
                }

              })
            }
          });
        }
        this.selectedSubItems = subItems;
        this.selectedSubItems2 = subItems2;
        return;
      }

      if (event.value.find(e => e === event.itemValue)) {
        const subItems = [];
        const subItems2 = [];
        this.options.forEach(option => {
          if (option && option.subItems) {
            option.subItems.forEach(sub => {
              if (sub.itemValue == event.itemValue) {
                subItems.push(sub);
                if (sub.subItems) {
                  sub.subItems.forEach(sub2 => {
                    subItems2.push(sub2);
                  })
                }
              }
            })
          }
        });
        subItems.forEach(sub => {
          if (!this.selectedSubItems.find(s => s.value == sub.value)) {
            this.selectedSubItems.push(sub);
          }
        });
        subItems2.forEach(sub => {
          if (!this.selectedSubItems2.find(s => s.value == sub.value)) {
            this.selectedSubItems2.push(sub);
          }
        });
      } else {
        const subItemValue = new Set();
        this.selectedSubItems = this.selectedSubItems.filter(sub => {
          if (sub.itemValue != event.itemValue) {
            return true;
          }
          subItemValue.add(sub.value);
        });
        this.selectedSubItems2 = this.selectedSubItems2.filter(sub => !subItemValue.has(sub.itemValue));
      }
      this.selectedSubItems = [...this.selectedSubItems];
      this.selectedSubItems2 = [...this.selectedSubItems2];
      this.updateAllSelectedItem();
    }, 0);
  }

  onSelectSubItem(event, item) {
    setTimeout(() => {
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedSubItems2 = this.selectedSubItems2.filter(v => v);
      if (!this.selectedSubItems.length) {
        this.selectedItems = this.selectedItems.filter(value => value != item.itemValue);
        this.selectedSubItems2 = this.selectedSubItems2.filter(s => s.itemValue != item.value);
      } else {
        this.selectedSubItems.forEach(sub => {
          const options = this.options.find(i => sub.itemValue == i.value);
          if (options.subItems && options.subItems.length ===  this.selectedSubItems.filter(s => s.itemValue == sub.itemValue).length) {
            if (!this.selectedItems.find(value => value == sub.itemValue)) {
              this.selectedItems.push(sub.itemValue);
            }
          } else {
            this.selectedItems = this.selectedItems.filter(value => value != sub.itemValue);
          }
          if (event.checked) {
            if (item.subItems) {
              item.subItems.forEach(sub => {
                if (!this.selectedSubItems2.find(s => s.value == sub.value)) {
                  this.selectedSubItems2.push(sub);
                }
              });
            }
          } else {
            this.selectedSubItems2 = this.selectedSubItems2.filter(sub => sub.itemValue != item.value);
          }
        });
      }
      this.selectedSubItems = [...this.selectedSubItems];
      this.selectedSubItems2 = [...this.selectedSubItems2];
      this.updateAllSelectedItem();
    }, 0);
  }

  onSelectSubItem2(event, item) {
    setTimeout(() => {
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedItems = this.selectedItems.filter(v => v);
      this.selectedSubItems2 = this.selectedSubItems2.filter(v => v);
      if (event.checked) {
        const option = this.options.find(i => i.subItems && i.subItems.find(s => s.value == item.itemValue));
        if (option) {
          let subItem;
          option.subItems.forEach(sub => {
            if (sub.subItems && sub.value === item.itemValue 
              && !this.selectedSubItems.find(s => s.value == sub.value) && this.selectedSubItems2.length === sub.subItems.length) {
              subItem = sub;
              this.selectedSubItems.push(sub);
            }
          });
          if (subItem && option.subItems.length === this.selectedSubItems.filter(s => s.value === item.itemValue).length) {
            if (!this.selectedItems.find(value => value == subItem.itemValue)) {
              this.selectedItems.push(subItem.itemValue);
            }
          }
          this.selectedSubItems = [...this.selectedSubItems];
          this.selectedItems = [...this.selectedItems];
        }
      } else {
        const itemValue = new Set();
        this.selectedSubItems = this.selectedSubItems.filter(sub => {
          if (sub.value != item.itemValue) {
            return true;
          }
          itemValue.add(sub.itemValue);
        });
        this.selectedItems = this.selectedItems.filter(value => !itemValue.has(value));
      }
      this.updateAllSelectedItem();
    }, 0);
  }

  updateAllSelectedItem() {
    this.hasSubItemSelected = new Set<string>();
    const allSelectedItems = [];
    const items = new Set<string>();
    const subItems = new Set<string>();
    const subItems2 = new Set<string>();
    const labels = [];
    this.selectedItems.forEach(value => {
      const options = this.options.find(z => z.value == value);
      if (options) {
        labels.push(options.label);
      }
      if (!items.has(value)) {
        items.add(value);
        this.hasSubItemSelected.add(value);
        allSelectedItems.push({
          itemValue: value,
          label: options ? options.label : null,
        });
      }

    });
    this.selectedSubItems.forEach(sub => {
      if (!items.has(sub.itemValue)) {
        if (!subItems.has(sub.value)) {
          subItems.add(sub.value);
          this.hasSubItemSelected2.add(sub.value);
          this.hasSubItemSelected.add(sub.itemValue);
          allSelectedItems.push({
            itemValue: sub.itemValue,
            subItemValue: sub.value,
            type: sub.type,
            label: sub.label,
            level: 1,
          });
        }
        labels.push(sub.label);
      } else {
        subItems.add(sub.value);
      }
    });
    this.selectedSubItems2.forEach(sub => {
      if (!subItems.has(sub.itemValue)) {
        if (!subItems2.has(sub.value)) {
          subItems2.add(sub.value);
          const options = this.options.find(z => z.subItems && z.subItems.find(s => s.value == sub.itemValue));
          if (options) {
            this.hasSubItemSelected.add(options.value);
          }
          this.hasSubItemSelected2.add(sub.itemValue);
          allSelectedItems.push({
            itemValue: sub.itemValue,
            subItemValue: sub.value,
            type: sub.type,
            label: sub.label,
            level: 2
          });
          labels.push(sub.label);
        }
      }
    })
    this.allSelectedLabels = labels;
    this.selected = allSelectedItems;
    this.selectedChange.next(this.selected);
  }
}
