<div class="spinner-overlay" *ngIf="loadingSpinner">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="ml-2 mt-4">{{'Client Credits' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Client Credits' | translate}}</span>
		</p-header>
        <div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client/Base' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [options]="allClientList" [filter]="true" [(ngModel)]="selectedClient" [showClear]="true" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}"></p-dropdown>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="resetFilters()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex align-items-center justify-content-start my-4">   
		<h1 class="m-0 ml-2">{{'Browse Credits' | translate}}</h1>
	</div>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Client Credits' | translate}} ({{totalRecords}})</span>
        </p-header>
        <p-contextMenu #cm [model]="items"></p-contextMenu>
		<p-table #dt [value]="contentList" dataKey="id" (sortFunction)="customSort($event)" selectionMode="single"  [loading]="loading" [lazy]="false" (onLazyLoad)="search($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [contextMenu]="cm"
				 [responsive]="true" [globalFilterFields]="['name', 'creditAmount', 'createdByUsr', 'updatedAt']">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
					<div class="d-flex">
						<button type="button" (click)="exportTablePdf()" class="btn btn-primary mx-1">
							<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
						</button>
						<button class="btn btn-info mx-1" (click)="exportPdf()" type="button">
							<span class="text-nowrap"><i class="fa-solid fa-glasses mr-2"></i>{{'Detailed Report' | translate}}</span>
						</button>
					</div>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<!-- <input [(ngModel)]="searchText" (keyup)="search($event)" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important"> -->
						<input [(ngModel)]="searchText" (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
					</div>
				</div>
			</ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of cols">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr *ngIf="!loading && contentList.length" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
					<td>{{rowData.index + 1}}</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}: </span>
							<span *ngIf="rowData.name" pTooltip="{{rowData.name}}" tooltipPosition="left">{{rowData.name}}</span>
							<span *ngIf="!rowData.name" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Credit' | translate}}: </span> 
							<span *ngIf="rowData.creditAmount" pTooltip="{{rowData?.creditAmount}}" tooltipPosition="left">{{rowData?.creditAmount}}</span>
							<span *ngIf="!rowData.creditAmount" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Created' | translate}}: </span>
							<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData?.createdByUsr}}" tooltipPosition="left">{{rowData?.createdByUsr}}</span>
							<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Udpated' | translate}}: </span>
							<span *ngIf="rowData.updatedAt" pTooltip="{{rowData?.updatedAt}}" tooltipPosition="left">{{rowData?.updatedAt}}</span>
							<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<span class="text-muted">{{'no data' | translate}}</span>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<span class="text-muted">{{'Loading...' | translate}}</span>
					</td>
				</tr>
			</ng-template>
        </p-table>
    </p-panel>
</div>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
