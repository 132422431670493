import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

   transform(itemList: any[], searchKeyword: string) {
    if (!itemList) {
        return [];
    }
    if (!searchKeyword || searchKeyword == null || searchKeyword === '')
      return itemList;
    const filteredList = [];
    if (itemList.length > 0) {
      searchKeyword = searchKeyword.toLowerCase();
      itemList.forEach(item => {
        const propValueList = Object.values(item);
        for (let i = 0; i < propValueList.length; i++)
        {
          if (propValueList[i]) {
            if (propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1)
            {
              filteredList.push(item);
              break;
            }
          }
        }
      });
    }
    return filteredList;
  }

}
