import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../../shared/service/abstract.service';
import { Constants } from '../../shared/model/constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class InvoiceVerificationService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/billing/invoice-verification', '');
  }

  search(options: any): Observable<Page<any>> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/billing/invoice-verification/search`, options).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  confirmInvoiceVerification(entity) {
    // return this._http.put(`http://localhost:8007/api/v2/operation/billing/invoice-verification/confirm`, entity).pipe(map(res => res));
    return this._http.put(`${this.baseUrlV2}confirm`, entity).pipe(map(res => res));
  }

  bulkConfirmInvoiceVerification(entity) {
    // return this._http.put(`http://localhost:8007/api/v2/operation/billing/invoice-verification/confirm`, entity).pipe(map(res => res));
    return this._http.put(`${this.baseUrlV2}bulk-confirm`, entity).pipe(map(res => res));
  }

  sendEmailInvoice(data): Observable<any> {
    // return this._http.post(`http://localhost:8007/api/v2/operation/billing/invoice-verification/send-email-invoice`, data).pipe(map(resp => resp));
    return this._http.post(`${this.baseUrlV2}send-email-invoice`, data).pipe(map(resp => resp));
  }

  sendSmsInvoice(data): Observable<any> {
    // return this._http.post(`http://localhost:8007/api/v2/operation/billing/invoice-verification/send-email-invoice`, data).pipe(map(resp => resp));
    return this._http.post(`${this.baseUrlV2}send-sms-invoice`, data).pipe(map(resp => resp));
  }

  updateStatusInvoice(data): Observable<any> {
    // return this._http.post(`http://localhost:8007/api/v2/operation/billing/invoice-verification/update-status-invoice`, data).pipe(map(resp => resp));
    return this._http.post(`${this.baseUrlV2}update-status-invoice`, data).pipe(map(resp => resp));
  }

  exportAsExcelFile(json: any[], excelFileName: string, headers: any[], fileExtension?: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    XLSX.utils.sheet_add_aoa(worksheet, headers);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  findAllVerification(companyId) {
    return this._http.get(`${this.baseUrlV2}findAllVerification/?companyId=${companyId}`).pipe(map(res => res));
  }
}
