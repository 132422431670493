import {PerformanceCriteria} from './performance-criteria';
import {Employee} from '../../model/employee.model';

export class EmployeePerformance {
    id: number;
    criteria: PerformanceCriteria
    employee: Employee;
    value: number;
    fromDate: Date;
    toDate: Date;
    surveySetupId?:number;


    constructor(criteria: PerformanceCriteria, employee: Employee, value: number, fromDate: Date, toDate: Date, surveySetupId?:number) {
        this.criteria = criteria;
        this.employee = employee;
        this.value = value;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.surveySetupId = surveySetupId;
    }
}
