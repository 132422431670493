import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';

@Component({
  selector: 'app-background-panel',
  templateUrl: './background-panel.component.html',
  styleUrls: ['./background-panel.component.scss']
})
export class BackgroundPanelComponent implements OnInit, AfterViewInit {
  backgroundList = [{
    background: '',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/adelin-grigorescu-yGCeV-8VeDw-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/aden-lao-bHaDK74hWLY-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/aden-lao-TCiPU0tiLeI-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/ali-choubin-aO-Wq5CV0z8-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/carnaby-gilany-u1EjAmywwI0-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/dan-asaki-WfU13iQobg4-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/hello-i-m-nik-ON9DwGUABIo-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/joe-pohle-ow1J09OyNAM-unsplash.jpeg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/john-hreniuc-cD9U2KveIO0-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/marcreation-pWV8HjvHzk8-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/marek-piwnicki-c9c0IWXrUkc-unsplash.jpg',
    type: 'image'
  },
  {
    background: 'https://livestore.operrwork.com/operrwork/marek-piwnicki-lcRJRJlZzgg-unsplash.jpg',
    type: 'image'
  }, {
    background: 'https://livestore.operrwork.com/operrwork/ralph-ravi-kayden-j5xlNpgzqds-unsplash.jpg',
    type: 'image'
  }, {
    background: 'https://livestore.operrwork.com/operrwork/ricardo-gomez-angel-7RuhOrZO-1g-unsplash.jpg',
    type: 'image'
  }, {
    background: 'https://livestore.operrwork.com/operrwork/sergey-pesterev-llGiAyYthDw-unsplash.jpeg',
    type: 'image'
  }, {
    background: 'https://livestore.operrwork.com/operrwork/solen-feyissa-gq7WBs1ejHw-unsplash.jpg',
    type: 'image'
  }, {
    background: 'https://livestore.operrwork.com/operrwork/the-blowup-KEVgRFDWl34-unsplash.jpg',
    type: 'image'
  }]
  @Input() background: string;
  @Output() onPick = new EventEmitter<any>();
  maxItem: number = 4
  loadingUpload: boolean = false
  constructor(private documentsService: DocumentsService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
  }
  pickBg(item) {
    this.backgroundList.forEach((e: any) => e.checked = false)
    item.checked = true;
    this.onPick.next(item);
  }
  onSelectFile(event) {
    if (event.target.files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.uploadBgImage(event.target.files[0]);
  }
  uploadBgImage(file) {
    const fileName = file.name.replace(/ /g, '').replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_')
    this.loadingUpload = true;
    this.documentsService.uploadFileWithFileName(file, 'task-management', '', 'task-management', fileName)
      .subscribe((res: any) => {
        this.loadingUpload = false;
        this.backgroundList.unshift({
          background: res.data.fileUrl,
          type: 'image'
        })
        this.maxItem++;
      }, err => {
        this.loadingUpload = false
      });
  }
}
