import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Message} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {AbstractServiceV2} from '../../shared/service/abstract.v2.service';
import {DocumentSettings} from '../model/document-settings';
import {DocumentSettingsCriteriaSearch} from '../model/document-settings-criteria.search';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentSettingsService extends AbstractServiceV2<DocumentSettings, DocumentSettingsCriteriaSearch> {

  private announcedSource = new Subject();
  announced$ = this.announcedSource.asObservable();
  private messageSubject = new Subject<Message>();
  message$ = this.messageSubject.asObservable();

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.DOCUMENT_SETTINGS, '');
  }

  executeCmd(cmd: string, data?: any) {
    this.announcedSource.next({cmd: cmd, data: data});
  }

  showSpin() {
    this.executeCmd('show_spin');
  }

  hideSpin() {
    this.executeCmd('hide_spin');
  }

  notifyMessage(message: Message) {
    this.messageSubject.next(message);
  }

  search(searchParams: DocumentSettingsCriteriaSearch): Observable<any> {
    //type = 'default' -> get all document settings (default + custom)
    //type = 'visible' -> get visible document settings (added document)
    return this._http.post<any>(this.baseUrlV2 + '/search', searchParams).pipe(map(rsp => rsp));
  }

  updateAll(entities: DocumentSettings[]): Observable<any> {
    return this._http.put<any>(this.baseUrlV2 + '/update-all', entities).pipe(map(rsp => rsp));
  }

  delete(id): Observable<any> {
    return this._http.delete<any>(this.baseUrlV2 + '/' + id).pipe(map(rsp => rsp));
  }

  getHistory(searchParams): Observable<any> {
      return this._http.post<any>(this.baseUrlV2 + '/history', searchParams).pipe(map(rsp => rsp));
  }

  exportPdf(data?: any): Observable<any> {
      return this._http.post<any>(`${this.baseUrlV2}/exportPdf`, data).pipe(map(resp => resp));
  }
}
