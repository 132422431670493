import { AuthService } from 'app/shared/service/auth/auth.service';
import {Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener} from '@angular/core';
import {BrowserNotification} from '../../../shared/model/browser-notification.model';
import {EmployeeService} from '../../../employee/service/employee.service';
import {BrowserNotificationService} from '../../../shared/service/browser-notification/browser-notification.service';
import {Editor} from 'primeng';
import {Router} from '@angular/router';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {
  @Input() comment: any = {};
  @Output() onDelete = new EventEmitter();
  @Output() onUpdate = new EventEmitter();
  @Output() onReply = new EventEmitter();
  @Input() isShowLog: boolean = true;
  @Output() onMakeCover = new EventEmitter();
  @Output() onRemoveCover = new EventEmitter();
  @Input() taskBackground: string = '';
  userId: any;
  cloneComment: any = {}
  showUpdateForm: boolean = false;
  previewImage: string = ''
  isSuperAdmin: boolean = this.authService.isSuper();
  // mentionEmployees: any[];
  @ViewChild('commentEditor', { static: true })
  commentEditor: Editor;
  @HostListener('document:click', ['$event'])
  clickout() {
    const abc = event.target as Element
    if (abc.localName !== 'img') {
      this.previewImage = '';
    }
  }
  @Input() mentionEmployees: any;

  constructor(private authService: AuthService,
              private employeeService: EmployeeService,
              private router: Router,
              private browserNotificationService: BrowserNotificationService,
              private documentsService:DocumentsService) { }

  ngOnInit(): void {
    this.comment.content = this.comment.content.replace(/<img /g, `<img width="150" `);
    // this.comment.content = this.comment.content.replace(/<p><br[\/]?><[\/]?p>/g, '');
    this.userId = this.authService.getCurrentLoggedInId();
    this.cloneComment = { ...this.comment }
    // const optionsEmployee = {
    //   status: 1,
    //   companyId: this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null
    // };
    // this.employeeService.getAllsDropdown(optionsEmployee).subscribe(res => {
    //   const resObj: any = res;
    //   this.mentionEmployees = resObj.data;
    // });
  }
  delete() {
    this.onDelete.emit(this.comment);
    this.closeModalImage();
    this.removeCover();
  }
  update() {
    this.showUpdateForm = false;
    this.onUpdate.emit(this.cloneComment)
    this.notifyMentionEmployee(this.cloneComment.content);
  }
  reply() {
    this.onReply.emit(this.cloneComment)
    if(this.cloneComment.type !== 'IMAGE_URL'){
      this.notifyMentionEmployee(this.cloneComment.content);
    }
  }
  viewImage(type?) {
    const regExp = /\[([^)]+)\]/;
    if (type) {
      this.previewImage = regExp.exec(this.comment.content)[1]
      return;
    }
    if (!type && !this.comment.documentId) {
      this.previewImage = this.comment.content;
    } else {
      this.documentsService.getUploadedFile(this.comment.documentId).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.previewImage = res.data.fileUrl;
        }
      })
    }
  }

  closeModalImage() {
    this.previewImage = ''
  }
  onEditorInit(event) {
    // console.log('onEditorInit', event);
    event.editor.root.focus()
  }
  makeCover() {
    this.onMakeCover.emit(this.comment)
  }
  removeCover() {
    this.onRemoveCover.emit()
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.cloneComment.content = this.cloneComment.content.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.cloneComment.content = this.cloneComment.content.replace(regexTag2, '<strong>$1$2</strong>$3');
      this.commentEditor.writeValue(this.cloneComment.content.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3'));
      this.commentEditor.writeValue(this.cloneComment.content.replace(regexTag2, '<strong>$1$2</strong>$3'));
      setTimeout(() => {
        this.commentEditor.getQuill().setSelection(this.commentEditor.value.length);
      }, 100);

    }, 500);
  }

  async notifyMentionEmployee(details: string) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    for (const employee of employeeList) {
      const key = this.mentionEmployees.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#' + this.router.url;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
      }
    }
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

}
