import { TransactionDetail } from './transaction-detail.model';

export class Transaction {
    id: number;
    transactionDetails: TransactionDetail[];
    company: any;
    expenseCurrency: string;
    project: string;
    note: string;
    status: number;
    createdAt: number;
    createdByUsr: string;
    lastModifiedBy: string;
    updatedAt: number;
    companyId?: number;
    transactionType: any;
    showHideActions?: boolean;
    crmClientId?: number;
    projectId?: number;
    contractorInvoiceId: number;
    invoiceId: number;
    appliedTransaction: any;
    checkLateFee: any;
    checkApiUsageFee: any;
    selectedExpense: any;
    invoiceLst: any;
    type: any;
    selectedExpenseStr: any;
    transactionDate?: any;
}
