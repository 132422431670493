import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {CompanyService} from '../../service/company.service';


@Component({
  selector: 'app-company-update',
  templateUrl: './company-update.component.html',
  styleUrls: ['./company-update.component.css'],
  providers: [CompanyService]
})
export class CompanyUpdateComponent implements OnInit {
  companyId: Number;
  constructor(private companyService: CompanyService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.companyId = +params.id);
  }

  ngOnInit() {
  }

}
