import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../authenticate/auth.guard';
import { JobFollowUpAddComponent } from './component/job-follow-up-add/job-follow-up-add.component';
import { JobFollowUpLayoutComponent } from './component/job-follow-up-layout/job-follow-up-layout.component';
import { JobFollowUpListComponent } from './component/job-follow-up-list/job-follow-up-list.component';
import { JobFollowUpViewComponent } from './component/job-follow-up-view/job-follow-up-view.component';
import { CanDeactivateGuardClientLognAdd, CanDeactivateGuardClientLogView } from '../guards/unsaved-changes.guard';
import { JobFollowUpUpdateComponent } from './component/job-follow-up-update/job-follow-up-update.component';
import { ClientBillingReportComponent } from './component/client-billing-report/client-billing-report.component';
import { BillingTotalJobListComponent } from "./component/billing-total-job-list/billing-total-job-list.component";
import { DispatchTotalJobListComponent } from "./component/dispatch-total-job-list/dispatch-total-job-list.component";
import { BillingLogTotalJobComponent } from "./component/billing-log-total-job/billing-log-total-job.component";
import { BillingDetailsReportComponent } from "./component/billing-details-report/billing-details-report.component";
import { BillingLogDetailsReportComponent } from "./component/billing-log-details-report/billing-log-details-report.component";
import { BillingCorrectionReportComponent } from "./component/billing-correction-report/billing-correction-report.component";
import { BillingUploadHistoryComponent } from "./component/billing-upload-history/billing-upload-history.component";
import { BillingClientStatusComponent } from "./component/billing-client-status/billing-client-status.component";

export const JobFollowUpRoutes: Routes = [
    {
        path: 'app/job-follow-up',
        component: JobFollowUpLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/app/conference-room/list', pathMatch: 'full' },
            { path: 'list', component: JobFollowUpListComponent },
            { path: 'add', component: JobFollowUpAddComponent, canDeactivate: [CanDeactivateGuardClientLognAdd] },
            { path: 'view/:id', component: JobFollowUpViewComponent },
            { path: 'edit/:id', component: JobFollowUpUpdateComponent, canDeactivate: [CanDeactivateGuardClientLogView] },
            { path: 'billing-report', component: ClientBillingReportComponent },
            { path: 'billing-total-job', component: BillingTotalJobListComponent },
            { path: 'dispatch-total-job', component: DispatchTotalJobListComponent },
            { path: 'billing-log-total-job', component: BillingLogTotalJobComponent },
            { path: 'billing-details-report', component: BillingDetailsReportComponent },
            { path: 'billing-correction-report', component: BillingCorrectionReportComponent },
            { path: 'billing-upload-history', component: BillingUploadHistoryComponent },
            { path: 'billing-client-status', component: BillingClientStatusComponent },
            { path: 'billing-log-details-report', component: BillingLogDetailsReportComponent },
        ]
    }
];

export const JobFollowUpRoutingModule = RouterModule.forChild(JobFollowUpRoutes);

