import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Page } from "../../shared/model/page.model";
import { Transaction } from "../model/transaction.model";
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TransactionService extends AbstractService<Transaction> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.TRANSACTION, '');
  }
  filter(options: any): Observable<Page<Transaction>> {
    // options.toDate = moment(options.toDate).toISOString(true);
    // options.fromDate = moment(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrl}${Constants.URI.EXPENSE.ALL}`;
    return this._http.post<Page<Transaction>>(this.baseUrl, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(res => res));
  }

  loadExpenses(options: any) {
    const params: any = { page: options.page, size: options.size };
    if (options.sort) {
      params.sort = options.sort;
    }
    return this._http.post<Page<Transaction>>(this.baseUrl + 'all', options, { params: params }).pipe(map(res => res));
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrl}history`, data).pipe(map(res => res));
  }

  getUserList(): Observable<any> {
    return this._http.get<any>(this.baseUrl + Constants.URI.EXPENSE.GETUSER, {}).pipe(map(res => res));
  }

  getTransaction(id): Observable<any> {
    //return this._http.get<any>(`http://localhost:8007/api/v2/operation/transaction/${id}`).pipe(map(res => res));
    return this._http.get<any>(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction/${id}`).pipe(map(res => res));
  }

  uploadFfile(file: File, id: any) {
    const reqUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;
    const reqParams = { entity: 'trnasactionFile', entityId: id, fileClass: 'transaction' };
    const reqParamStr = JSON.stringify(reqParams);
    const payload = new FormData();
    payload.append('file', file);
    return this._http.post<any>(reqUrl, payload, { params: reqParams }).pipe(map(res => res));
  }

  createTransaction(entity){
    // return this._http.post(`http://localhost:8007/api/v2/operation/transaction`, entity).pipe(map(res => res));
    return this._http.post(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction`, entity).pipe(map(res => res));
  }

  updateTransaction(entity, id: Number){
    return this._http.put(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction`, entity).pipe(map(res => res));
    // return this._http.put(`http://localhost:8007/api/v2/operation/transaction`, entity).pipe(map(res => res));
  }
}
