import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FileUpload, LazyLoadEvent, MessageService, OverlayPanel, Table } from 'primeng';
import { AuthService } from "../../../../shared/service/auth/auth.service";
import { PlatformAdminService } from "../../../../admin/platform-admin/service/platform-admin.service";
import { CompanyService } from "../../../service/company.service";
import { BillingDetailsReportService } from "../../service/billing-details-report.service";
import { BillingUploadHistoryService } from '../../service/billing-upload-history.service';
import * as _moment from 'moment';
import { Message } from "primeng/api";
import * as XLSX from 'xlsx';
import moment from "moment";
import * as _ from 'lodash';
import { DropDownsService } from "../../../../shared/service/drop-downs/drop-downs.service";
import { ClientService } from "../../../service/client.service";
import { threadId } from 'worker_threads';
import { FileUtility } from 'app/shared/utility/file.utility';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { BillingClientStatusService } from '../../service/billing-client-status.service';
import { TranslatePipe } from "@ngx-translate/core";
import { UploadEvent } from '../../model/upload-event.model';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';

@Component({
  selector: 'app-billing-details-report',
  templateUrl: './billing-details-report.component.html',
  styleUrls: ['./billing-details-report.component.scss'],
  providers: [BillingDetailsReportService]
})
export class BillingDetailsReportComponent implements OnInit {
  showProgressBar = false;
  showProgressReasonFail = false;
  showProgressBarPage = false;
  showProgressBarForNote = false;
  showProgressBarOnInitialLoad = false;
  showProcessComplete = false;
  progressBarValue = 0;
  excel: {
    csv2json: any;
    excel2json: any;
    json2excel: any;
  };
  page: number;
  size: number;
  notSavedCount: number;
  loading: boolean = true;
  companySelected: any;
  uploadDateSelected: any;
  uploadDateList: any;
  isPlatformAdmin: any;
  companies: any[];
  totalRecords: number = 0;
  billingReports: any[];
  showConfirmDeleteDialog = false;
  selectedVideo: any;
  fromDate: null;
  toDate: null;
  createdByList: any[];
  createdSelected: any;
  detailReportType = "MAS";
  cols = [
    { label: '#', field: 'id', sortOptions: '', sort: '' },
    { label: 'Invoice', field: 'invoiceNumber', sort: 'number' },
    { label: 'Result', field: 'result', sort: 'text' },
    { label: 'Paid', field: 'totalPaidAmt', sort: 'number' },
    { label: 'Encoded Amt.', field: 'encodedPaidAmt', sort: 'number' },
    { label: 'Last Name (Patient)', field: 'patientLastname', sort: 'text' },
    { label: 'Fist Name (Patient)', field: 'patientFirstname', sort: 'text' },
    { label: 'CIN', field: 'cin', sort: 'number' },
    { label: 'Service Date', field: 'serviceDate', sort: 'number' },
    { label: 'Actions', field: 'actions', sort: 'text' }
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  fileTypes = [
    { name: 'Excel', value: 'excel' },
    { name: 'PDF', value: 'pdf' }
  ];

  downloadSelected: any;
  @ViewChild('dt', { static: true }) table: Table;
  @ViewChild('fileUpload') fileUpload: FileUpload;
  selectedSortOption = 'ASC';
  selectedField = 'serviceDate';
  clients: any[];
  clientList: any[];
  clientSelected: any;
  showUploaded: boolean = false;
  clientSelectedUpload: any;
  totalJobId: number;
  showConfirmDialog: boolean;
  isPermitted: boolean = false;
  isChooseClient: boolean = true;
  isChooseClientUpload: boolean = true;
  downloadEnable: boolean = false;
  isClientAccount: boolean = false;
  sortField: string;
  totalPaidAmount: number = 0;
  showProcessUpdateComplete = false;
  previousUpdatedDate: any;
  textSearch: any;
  totalUpdatedRecords: number = 0;
  totalNewRecords: number = 0;
  isPreviousUpdatedDate = false;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();
  totalFileUpload = 0;
  uploadedFiles: any = [];
  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private datePipe: DatePipe,
    private messageService: MessageService, private clientService: ClientService,
    private billingDetailsReportService: BillingDetailsReportService,
    private billingUploadHistoryService: BillingUploadHistoryService,
    private billingClientStatusService: BillingClientStatusService,
    private router: Router,
    private translatePipe: TranslatePipe,
    private decimalPipe: DecimalPipe,
    private exportMonitorService: ExportMonitorService,
    private monitoringDetailsService: MonitoringDetailsService) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-details-report') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Details Report`,
            this.timeSpent,
            {
              navigated_to_details_report_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Details Report`,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.excel = require('assets/libs/js2excel.min.js');
    this.loading = true;
    this.isPermitted = this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole() || this.authService.isCompanyAd();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.loadAllCompanyList();
    this.getClientList();
    if (this.authService.isClientRole()) {
      this.clientSelected = this.authService.getUserInfo().adminId;
      this.clientSelectedUpload = this.authService.getUserInfo().adminId;
      this.isChooseClient = false;
      this.isChooseClientUpload = false;
      this.downloadEnable = true;
      this.isClientAccount = true;
      this.cols = [
        { label: '#', field: 'id', sortOptions: '', sort: 'number' },
        { label: 'Invoice', field: 'invoiceNumber', sort: 'text' },
        { label: 'Result', field: 'result', sort: 'text' },
        { label: 'Paid', field: 'totalPaidAmt', sort: 'number' },
        { label: 'Encoded Paid', field: 'encodedPaidAmt', sort: 'text' },
        { label: 'Patient (Last)', field: 'patientLastname', sort: 'text' },
        { label: 'Patient (Fist)', field: 'patientFirstname', sort: 'text' },
        { label: 'CIN', field: 'cin', sort: 'text' },
        { label: 'Serviced', field: 'serviceDate', sort: 'text' }
      ];
    } else {
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }
    // this.search();
    this.getCreatedByList();
    this.loadUploadedDateList();
    this.search();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
  }

  loadBillingReports(event: LazyLoadEvent) {
    this.loading = true;
    const page = (event.first) / event.rows;
    const option = {
      page: page, size: event.rows
    };
    this.loadPage(option);
  }

  inputTimeout;
  searchContent(event) {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
          this.search();
      }, 1000);
  }

  search(event?: any) {
    this.table.first = 0;
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && typeof event.first !== "undefined" && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options = {
      size: 99999,
      page: 0,
      companyId: null,
      type: this.detailReportType
    };
    this.loadPage(options);
  }
  accept() {
    this.billingDetailsReportService.delete(this.totalJobId).subscribe((_response) => {
      this.search();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Deleted Successfully'
      });
      this.showConfirmDialog = false;
    }, (error) => {
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Delete Failed'
      });
    });
  }

  reject() {
    this.showConfirmDialog = false;
  }
  deleteRecord(data) {
    if (!this.isPermitted) {
      const showHideActions = (moment(moment().toDate()).diff(data.createdAt, 'minutes') >= 1440);
      if (showHideActions) {
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Information'),
          detail: this.translatePipe.transform(`Sorry you cannot delete the selected record after 24hrs`)
        });
        return;
      }
    }
    this.totalJobId = data.id;
    this.showConfirmDialog = true;
  }

  loadPage(options: any) {
    this.downloadSelected = null;
    this.totalPaidAmount = 0;
    options.type = this.detailReportType;
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    /*if (this.uploadDateSelected) {
      options.startDate = this.uploadDateSelected;
      options.endDate = this.uploadDateSelected;
    }*/
    if (this.textSearch) {
      options.textSearch = this.textSearch;
    }
    if (this.createdSelected) options.createdBy = this.createdSelected;
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingDetailsReportService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.billingReports.forEach(ele => {
          if (!isNaN(Number(ele.totalPaidAmt))) {
            this.totalPaidAmount += Number(ele.totalPaidAmt);
          } else {
            console.log("Not a Number" + ele.totalPaidAmt);
          }
        });
        this.sortingClick(this.selectedSortOption);
        this.totalRecords = resObj.data.totalElements;
        if (this.totalRecords === 0) {
          this.totalPaidAmount = 0;
        }
        this.loading = false;
      }
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.createdSelected = null;
    this.uploadDateSelected = null;
    this.billingReports = [];
    this.totalPaidAmount = 0;
    this.downloadSelected = null;
    this.textSearch = null;
    this.table.reset();
    this.search();
    if (!this.authService.isClientRole()) {
      this.downloadEnable = false;
      this.companySelected = null;
      this.clientSelected = null;
      this.clientSelectedUpload = null;
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  loadUploadedDateList() {
    let options = {
      companyId: null,
      clientId: null,
      type: this.detailReportType
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingDetailsReportService.getUploadedDateList(options).subscribe((res) => {
      const resObj: any = res;
      const tempUploadDateList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        resObj.data.forEach((date) => {
          tempUploadDateList.push({ label: _moment(date).format('MM/DD/YYYY'), value: _moment(date, 'YYYY-MM-DD').toDate().getTime() });
        });
        this.uploadDateList = tempUploadDateList;
        this.uploadDateList.sort((a, b) => b.value - a.value);
        this.uploadDateSelected = this.uploadDateList[0].value;
      } else {
        this.uploadDateList = [];
      }
      // this.search();
    });
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    options.type = this.detailReportType;
    if (this.companySelected) { options.companyId = this.companySelected; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }

  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null,
      type: this.detailReportType
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingDetailsReportService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    });
  }

  chooseCompany(event) {
    this.getClientList();
  }

  exportClientList(type) {
    if (type === 'excel') {
      this.downloadExcel();
    }
    if (type === 'pdf') {
      this.exportPdf();
    }
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.search();
    this.downloadEnable = true;
  }

  async importReports(event) {
    this.totalFileUpload = event.files.length;
    this.showProgressBar = true;
    const loggedInUser = JSON.parse(localStorage.getItem('_globals'));
    const dataToBeSaved = [];
    const allData = [];

    /* wire up file reader */
    const filePromises = event.files.map((file) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true, dateNF: 'mm/dd/yyyy;@' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          /* save data */
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          let output = {};
          (data).forEach((item: any) => {
            const isEmpty = Object.values(item).every(x => (x === null || x === ''));
            if (!isEmpty) {
              let output = {};
              for (let key in item) {
                output[_.camelCase(key)] = item[key];
              }
              allData.push(output);
            }
          });
          // Resolve the promise with the response value
          resolve(reader.result)
        }
      });
    });

    // Wait for all promises to be resolved
    await Promise.all(filePromises);
    const uniqueArray = allData.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === allData.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });

    console.log(uniqueArray);
    let uploadHistory: any = {
      createdByUsr: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      startDate: new Date().getTime(),
      reportType: 'DETAIL_REPORT'
    }

    let billingClientStatus: any = {
      billingReportUploadedBy: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      billingReportUpTime: new Date().getTime(),
      startDate: moment(new Date().getTime()).utc(true).startOf('week').toDate(),
      endDate: moment(new Date().getTime()).utc(true).endOf('week').toDate(),
      type: 2
    };

    uniqueArray.forEach((item) => {
      item.clientId = this.clientSelectedUpload;
      item.companyId = this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId();
      item.invoiceNumber = item.invoiceNumber;
      item.result = item.result;
      item.totalPaidAmt = Number(item.totalPaidAmt);
      item.encodedPaidAmt = item.encodedPaidAmt;
      item.patientLastname = item.patientLastname;
      item.patientFirstname = item.patientFirstname;
      item.cin = item.cin;
      item.serviceDate = item.serviceDate;
      item.type = this.detailReportType;
      item.createdByUsr = loggedInUser.username;
      dataToBeSaved.push(item);
    });

    const invoiceNumbers = dataToBeSaved.map(c => c.invoiceNumber);
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
    if (findDuplicates(invoiceNumbers).length > 0) {
      this.showProgressBar = false;
      this.showProcessComplete = true;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Uploading failed, duplicate Invoice Number: ') + ' ' + findDuplicates(invoiceNumbers)
      });
      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });

      return;
    }
    let userType = this.authService.getUserType();
    const date = new Date();
    if (dataToBeSaved.length > 0) {
      this.billingDetailsReportService.uploadAndMail(dataToBeSaved, userType, loggedInUser.username,
        _moment(date).format('MM/DD/YYYY')).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            uploadHistory.status = 'SUCCESS';
            uploadHistory.endDate = new Date().getTime();

            billingClientStatus.status = 'Pending';
            this.showUploaded = false;
            if (res.data.previousUpdatedDate) {
              this.previousUpdatedDate = moment(res.data.previousUpdatedDate).format('MM/DD/YYYY');
              this.isPreviousUpdatedDate = true;
              uploadHistory.firstUpdateOn = res.data.previousUpdatedDate;
            }
            this.totalNewRecords = res.data.totalNewRecords;
            this.totalUpdatedRecords = res.data.totalUpdatedRecords;
            this.showProgressBar = false;
            this.showProcessUpdateComplete = true;
            uploadHistory.newRecords = res.data.totalNewRecords;
            uploadHistory.oldRecords = res.data.totalUpdatedRecords;
            this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
            }, (error) => {
            });

            this.billingClientStatusService.save(billingClientStatus).subscribe((_response) => {
            }, (error) => {
            });
            const args = {
              details_report_file_uploaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            const action = 'Details Report File Uploaded'
            this.monitorDetails(args, action, date)
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Failed'),
              detail: this.translatePipe.transform('Uploading Failed!')
            });
          }
          this.search();
        }, () => {
          this.showProgressBar = false;
          this.showProcessComplete = true;
          uploadHistory.status = 'FAILED';
          uploadHistory.endDate = new Date().getTime();
          this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
          }, (error) => {
          });

          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Failed'),
            detail: this.translatePipe.transform('Uploading Failed!')
          });
        });
    } else {
      this.showProgressBar = false;
      this.showProcessComplete = true;
      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });

      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Invalid data please check file again!')
      });
    }

    this.fileUpload.clear();
    const interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 300);

  }
  sortingClick(selectedSortOption) {
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.billingReports
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  downloadExcel() {
    if (!this.clientSelected) {
      this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Please choose client'),
        detail: this.translatePipe.transform('Please choose client')
      });
      return;
    }

    let options = {
      size: 99999,
      page: 0,
      companyId: null,
      startDate: null,
      endDate: null,
      uploadStartDate: null,
      uploadEndDate: null,
      createdBy: this.createdSelected,
      clientId: this.clientSelected,
      type: this.detailReportType
    };
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    if (this.uploadDateSelected) {
      options.uploadStartDate = this.uploadDateSelected;
      options.uploadEndDate = this.uploadDateSelected;
    }
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    this.billingDetailsReportService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billingReports = data[i];
          const excelTrans: any = {};
          excelTrans['Invoice Number'] = billingReports.invoiceNumber;
          excelTrans['Result'] = billingReports.result;
          excelTrans['Total Paid Amt'] = billingReports.totalPaidAmt;
          excelTrans['Encoded Paid Amt'] = billingReports.encodedPaidAmt;
          excelTrans['Patient Lastname'] = billingReports.patientLastname;
          excelTrans['Patient Firstname'] = billingReports.patientFirstname;
          excelTrans['CIN'] = billingReports.cin;
          excelTrans['Service Date'] = billingReports.serviceDate;
          excelData.push(excelTrans);
        }
        this.billingDetailsReportService.exportAsExcelFile(excelData, 'BillingDetailReport');
      }
    });
  }

  exportPdf() {
    if (this.billingReports && this.billingReports.length) {
      const data: any = {
        contentList: this.billingReports.map(value => {
          return {
            invoiceNumber: value.invoiceNumber,
            result: value.result,
            totalPaidAmt: formatNumber(value.totalPaidAmt, 'en-US', '1.2-2'),
            encodedPaidAmt: value.encodedPaidAmt,
            patientLastname: value.patientLastname,
            patientFirstname: value.patientFirstname,
            cin: value.cin,
            serviceDate: value.serviceDate,
            type: this.detailReportType
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompany();
      } else {
        if (this.companySelected) data.companyId = this.companySelected.key;
      }
      if (this.authService.isClientRole()) {
        data.clientName = this.authService.getUserInfo()?.name;
      }
      data.username = this.authService.getCurrentUsername();
      data.totalPaid = this.decimalPipe.transform(this.totalPaidAmount, '1.2-2');
      this.billingDetailsReportService.exportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Billing Detail Report',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting billing detail report pdf list')
          });
        }
      });
    }
  }

  updateData(rowData: any) {
    this.billingDetailsReportService.update(rowData).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log("update success");
      }
    });
  }

  onChangeClient(event) {
    this.loadUploadedDateList();
    this.getCreatedByList();
    if (!this.clientSelectedUpload) {
      this.clientSelectedUpload = this.clientSelected;
    }
  }

  closeProcessCompleteDialog() {
    this.showProcessUpdateComplete = false;
  }

  uploadBtn() {
    this.showUploaded = true;
    const args = {
      details_report_upload_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    }
    const action = 'Details Report Upload Button Clicked'
    this.monitorDetails(args, action, new Date())
  }


  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  closePopupUploaded() {
    this.clientSelectedUpload = null;
    this.uploadedFiles = [];
    this.showUploaded = false;
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      "complete",
      action,
      0
    );
  }
}
