<div *ngIf="fileUrl || fileName"  class="uploader border-all p-3" (click)="fileInput.click();$event.stopPropagation();">
	<img *ngIf="isImageFile()" [src]="imageSrc?imageSrc:''" [ngClass]="{'loaded':!!imageSrc}" (click)="onViewImage(imageSrc ? imageSrc : '')" style="pointer-events: auto !important;">
	<i *ngIf="isPdfFile()" class="fa-solid fa-file-pdf text-muted  fa-3x m-auto" (click)="openFile($event, fileUrl);$event.stopPropagation();" style="font-size: 105px; cursor: pointer;"></i>
	<i *ngIf="isDocFile()" class="fa-solid fa-file-word text-muted  fa-3x m-auto" (click)="openFile($event, fileUrl);$event.stopPropagation();" style="font-size: 105px; cursor: pointer;"></i>
	<i *ngIf="isExcelFile()" class="fa-solid fa-file-excel fa-3x  text-muted m-auto" (click)="openFile($event, fileUrl);$event.stopPropagation();" style="font-size: 105px; cursor: pointer;"></i>
	<i *ngIf="isPowerpointFile()" class="fa-solid fa-file-powerpoint fa-3x  text-muted m-auto" (click)="openFile($event, fileUrl);$event.stopPropagation();" style="font-size: 105px; cursor: pointer;"></i>
	<i *ngIf="isTxtFile()" class="fa-solid fa-file-text fa-3x m-auto text-muted " (click)="openFile($event, fileUrl);$event.stopPropagation();" style="font-size: 105px; cursor: pointer;"></i>
	<button *ngIf="!view" style="position: absolute; bottom: -50px"
			class="btn btn-white mx-1" type="button" [hidden]="readonly" (click)="removeImage()" pTooltip="{{'Delete' | translate}}" tooltipPosition="left">
		<i class="fa-solid fa-xmark text-danger"></i>
	</button>
</div>
<div *ngIf="(!fileUrl || !fileName) && !view"  class="uploader border-all">
	<div (click)="fileInput.click()" class="text-center d-flex flex-column" style="width: 100%;">
		<div class="flex-grow-1 d-flex flex-column align-items-center">
			<i *ngIf="!fileUrl || !fileName" class="fa fa-upload text-muted fa-3x m-auto"></i>
			<i *ngIf="isPdfFile()" class="fa-solid fa-file-pdf text-muted  fa-3x m-auto"></i>
			<i *ngIf="isDocFile()" class="fa-solid fa-file-word text-muted  fa-3x m-auto"></i>
			<i *ngIf="isExcelFile()" class="fa-solid fa-file-excel fa-3x  text-muted m-auto"></i>
			<i *ngIf="isPowerpointFile()" class="fa-solid fa-file-powerpoint fa-3x  text-muted m-auto"></i>
			<i *ngIf="isTxtFile()" class="fa-solid fa-file-text fa-3x m-auto text-muted "></i>
		</div>
		<div *ngIf="!fileUrl || !fileName" class="text-muted m-auto" style="font-size: 12px;padding: 10px !important;">{{'Upload Document' | translate}}</div>
		<img *ngIf="isImageFile()" [src]="imageSrc?imageSrc:''" [ngClass]="{'loaded':!!imageSrc}">
		<input [disabled]="readonly" #fileInput type="file"  hidden name="file" accept=".docx,.pdf,.doc,.xlsx,.xls,.txt,.ppt,.pptx,.jpg,.jpeg,.png" (change)="onFileUploadChange($event)">
	</div>
</div>
<p-dialog *ngIf="showPreview" [(visible)]="showPreview" class="modal_in" [modal]="true" [style]="{'width':'600px','max-width':'90%'}">
	<p-header>
		<span class="text-truncate"><i class="fa-solid fa-image mr-2"></i>{{this.fileName}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<img *ngIf="isImageFile()" [src]="imageSrc?imageSrc:''" class="w-100">
		</div>
	</div>
	<p-footer class="d-flex justify-content-end align-items-center">
		<button type="button" (click)="showPreview = false" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog header="Preview" *ngIf="showViewImage" [(visible)]="showViewImage" [responsive]="true" showEffect="fade" [style]="{width: '65vw'}" [closable]="false">
	<img [src]="previewfileUrl" id="rotated" style="object-fit: contain;" *ngIf="previewfileUrl" class="imageShown"/>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
          <span style="margin-right: 40%">
              <span style="cursor: pointer;" placeholder="Rotate image" (click)="rotateImage()"><i class="fa fa-repeat" aria-hidden="true"></i></span>
              <span style="margin-left: 10px; cursor: pointer;" placeholder="Zoom In" (click)="zoomin()"><i class="fa fa-search-plus" aria-hidden="true"></i></span>
              <span style="margin-left: 10px; cursor: pointer;" placeholder="Zoom Out" (click)="zoomout()"><i class="fa fa-search-minus" aria-hidden="true"></i></span>
          </span>
			<button style="cursor: pointer;" type="button" pButton (click)="closeViewFileDialog()" label="Close"></button>
		</div>
	</p-footer>
</p-dialog>