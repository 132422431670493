import { CrmClient } from "app/crm/model/base";

export class JobFollowUpDetails{
    id:number;
    billerId:number;
    clientId:number;
    crmClient:any;
    contactName:String;
    contactNumber:String;
    emailId1:String;
    emailId2:String;
    cycle:String;
    period:String;
    followUp:String;
    supportId:number;
    totalJobId:number;
    createdAt:Date;
    showCreatedOn?:boolean;
    showCycle?:boolean;
    showNumber?:boolean;
    showEmail1?:boolean;
    showEmail2?:boolean;
    showNote?:boolean;
    statusDate?:Date;
    note?: String;
    lastModifiedBy?:string;
    hide?: boolean;
    support: String;
    biller: String;
    base: String;
    follow: String;
    createdAtStr: String;
    statusDateStr: String;
    highLight:boolean;
    clientName?:string;
}
