import { Agency } from '../../payroll-setting/model/agency.model';
import { FreelancerType } from './freelancerType.model';
import { FreelancerSkill } from './freelancerSkill.model';
import { FreelancerWorkHour } from './freelancer-work-hours.model';
import { FreeLancerContractItem } from './freelancer-contract-item.model';


export class Freelancer {
    id: number;
    firstName: string;
    lastName: string;
    gender: string;
    fullName?: string;
    agency: Agency;
    company: any;
    companyName?: any;
    type: string;
    project: string;
    email: string;
    dateOfBirth: Date;
    startDate: Date;
    status: number;
    rate: number;
    rateTxt: string;
    githubId: string;
    pin: string;
    isPinBlocked: boolean;
    isSkill = true;
    freelancerTypes: FreelancerType[] = [];
    freelancerSkills: FreelancerSkill[] = [];
    freelancerWorkHours: FreelancerWorkHour[] = [];
    password: any;
    confPass: any;
    timezone: any;
    firstLoggedIn: any;
    availability: string;
    companyId: number;
    createdByUsr: string;
    paymentMethodId: string;
    bringUser: string;
    bringFromUserType: string;
    documentId: any;
    teamId: any;
    isTeamLeader: any;
    phone: any;
    fax: any;
    address: any;
    isChangedPw: any;
    statusStr: any;
    emergencyFullName: string;
    emergencyRelationship: string;
    emergencyPhone: string;
    emergencyAddress: string;
    contractItems: FreeLancerContractItem[] = [];
    groupId: number;
    preferredZipcode?: string[];
    preferredZone?: number[];
    complianceStatus?: string;
    documentNames?: any;
    complianceDocuments?: any;
    completedDocument?: number;
    totalDocument?: number;

}

export class FreelancerExcel {
    id: number;
    startDate: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    type: string;
    availability: string;
    status: string;
    completedDocument?: number;
    totalDocument?: number;
}
