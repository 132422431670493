import { Injectable } from '@angular/core';
import { AbstractService } from '../../../shared/service/abstract.service';
import { PlatformAdmin } from '../model/platform-admin.model';

import { Constants } from '../../../shared/model/constants';
import { Page } from '../../../shared/model/page.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { AgencyAdminSearch } from '../../../shared/model/agency.admin.search';
import {Employee} from '../../../employee/model/employee.model';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformAdminService extends AbstractServiceV2<PlatformAdmin, AgencyAdminSearch> {

  v2Url = environment.v2_api_url;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.ADMIN, '');
  }

  getPlatformAdminsByPage(params: any): Observable<Page<PlatformAdmin>> {
    return this._http.get<Page<PlatformAdmin>>(this.baseUrlV2 + '/' + Constants.URI.ADMIN.GETALLPLATFORMADMIN, { params: params }).pipe(map(resp => resp));
  }

  searchPlatformAdmins(params: any): Observable<Page<PlatformAdmin>> {
    const url = `${this.baseUrlV2}/search`;
    return this._http.get<Page<PlatformAdmin>>(url, { params: params }).pipe(map(resp => resp));
  }

  generatePdfFile(params: any): Observable<Page<PlatformAdmin>> {
    const url = `${this.baseUrlV2}/pdf`;
    return this._http.post<any>(url, params).pipe(map(resp => resp));
  }

  getDropdown(params: any): Observable<any> {
    const url = `${this.baseUrlV2}/${Constants.URI.ADMIN.DROPDOWNLIST}`;
    return this._http.get<any>(url, { params: params }).pipe(map(resp => resp));
  }

  isAccountNameAvail(accountName: string, id: Number) {
    if (id) {
      const URL = this.baseUrlV2 + '/isAccountNameAvail/' + accountName + '/' + id;
      return this._http.get(URL);
    } else {
      const URL = this.baseUrlV2 + '/isAccountNameAvail';
      const option = { accountName: accountName };
      return this._http.get(URL, { params: option });
    }
  }
  changePassword(entity: any): Observable<any> {
    return this._http.put<any>(this.baseUrlV2 + '/change-password', entity).pipe(map(resp => resp));
  }

  getAdmins(companyId: any) {
    return this._http.get<any>(`${this.v2Url}/adminv2/all/${companyId}`).pipe(map(res => res));
  }

  updateAgreement(id: any) {
    const url  = `${this.baseUrlV2}/${id}/agreement`;
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  updateOnlineStatus(id: any, status: number) {
    const url  = this.baseUrlV2 + '/' + id + '/online-status/' + status;
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  unblockUser(data) {
    return this._http.get(`${this.baseUrlV2}/${data.id}/status/${data.status}/${data.modifiedBy}`);
}
}
