import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MessageService, Table } from 'primeng';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { FreelancerTicketService } from 'app/crm/service/freelancerticket.service';
import Quill from 'quill';
import AutoLinks from 'quill-auto-links';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { TranslatePipe } from "@ngx-translate/core";
import moment from 'moment';


@Component({
  selector: 'app-all-card-list-task',
  templateUrl: './all-card-list.component.html',
  styleUrls: ['./all-card-list.component.scss'],
  providers: [FreelancerTicketService, FreelancerService]
})
export class AllCardListTaskComponent implements OnInit {
  page: number;
  size: number = 10;
  loading = true;
  totalRecords = 0;
  fromDate: Date;
  toDate: Date;
  createdByList: any[];
  createdSelected: any;
  uploadDateSelected: any;
  selectedSortOption = '';
  selectedField = '';
  tasks: any[];
  clientSelected: any;
  companies: any[];
  uploadDateList: any[];
  statusSelected: any;
  companySelected: any;
  isPlatformAdmin: any;
  selectedCompany: any;
  boardList: any[];
  boardSelected: any;
  sortField: string;
  sortOrder: string;
  showConfirmation = false;
  statusList: any = [
    { label: this.translatePipe.transform('Processing'), value: 'Processing' },
    { label: this.translatePipe.transform('Confirmed'), value: 'Confirmed' },
    { label: this.translatePipe.transform('Pending'), value: 'Pending' },
    { label: this.translatePipe.transform('Approved'), value: 'Approved' },
    { label: this.translatePipe.transform('Closed'), value: 'Closed' },
    { label: this.translatePipe.transform('Rejected'), value: 'Rejected' },
    { label: this.translatePipe.transform('Reviewing'), value: 'Reviewing' },
    { label: this.translatePipe.transform('Save'), value: 'Save' }
  ];
  cols = [
    { label: '#', field: 'id' },
    { label: 'Board', field: 'boardName', sort: 'text', sortOptions: '' },
    { label: 'Assigned', field: 'memberAssignee', sort: 'text', sortOptions: '' },
    { label: 'Ticket', field: 'ticketLink', sort: 'text', sortOptions: '' },
    { label: 'Start', field: 'startDate', sort: 'number', sortOptions: '' },
    { label: 'Deadline', field: 'deadline', sort: 'number', sortOptions: '' },
    { label: 'Creator', field: 'createdByUsr', sort: 'text', sortOptions: '' },
    { label: 'Created', field: 'createdAt', sortOptions: '', sort: 'number' },
    { label: 'Status', field: 'status', sort: 'text', sortOptions: '' }
  ];

  quillConfig = {
    autoLinks: true
  };

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'DESC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'ASC', img: 'arrow-up-9-1' }
  ];
  boardListTmp: any[];

  @ViewChild('dt', { static: true }) table: Table;

  textSearchStr;
  allTasks: any[] = [];

  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private taskManagementService: TaskManagementService,
    private freelancerTicketService: FreelancerTicketService,
    private messageService: MessageService,
    private translatePipe: TranslatePipe,
    private freelancerService: FreelancerService) {
    Quill.register('modules/autoLinks', AutoLinks);
  }

  @ViewChild('textToCopy', { static: false }) textToCopy: ElementRef;

  freelancers = [];
  freelancerSelected;

  ngOnInit(): void {
    this.loading = true;
    // tslint:disable-next-line:max-line-length
    this.tasks = [];
    this.allTasks = [];
    this.loadAllBoardList();
    this.loadAllBoardListByCompanyId();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (!this.isPlatformAdmin) {
      this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    }
    this.loadAllCompanyList();
    // this.getCreatedByList();
    this.getAllFreelancer();
    this.search();
  }

  search(event?: any) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    if (!this.sortField) {
      this.sortField = 'createdAt';
    }
    if (!this.sortOrder) {
      this.sortOrder = 'DESC';
    }
    const options = {
      size: this.size,
      page: this.page,
      companyId: null,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      userId: this.authService.isSuper() ? null : this.authService.getCurrentLoggedInId(),
      userType: this.authService.isSuper() ? null : this.getCurrentUserRole(), // 0
    };
    this.loadPage(options);
  }

  getCurrentUserRole() {
    // admin : 0, employee:1
    return this.authService.adminRole() ? 0 : this.authService.isEmployeeRole() ? 1 : this.authService.isGuestRole() ? 3 : 2;
  }

  loadPage(options: any) {
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.fromDate) {
      options.startDate = this.fromDate;
    }
    if (this.toDate) {
      options.endDate = this.toDate;
    }
    if (this.createdSelected) {
      options.createdBy = this.createdSelected;
    }
    if (this.statusSelected) {

      if (this.statusSelected === 'Closed') {
        this.statusSelected = 'Close';
      }
      if (this.statusSelected === 'Confirmed') {
        this.statusSelected = 'Confirm';
      }
      options.status = this.statusSelected;
    }
    if (this.boardSelected) {
      options.boardId = this.boardSelected;
    }

    if (this.freelancerSelected) {
      options.freelancerId = this.freelancerSelected;
    }
    this.freelancerTicketService.searchFilterTickets(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allTasks = resObj.data.content;
        this.tasks = this.allTasks;
        // this.sortingClick(this.selectedSortOption);
        this.totalRecords = resObj.data.totalElements;
        this.loading = false;
      }
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.createdSelected = null;
    this.allTasks = [];
    this.tasks = [];
    this.selectedSortOption = '';
    this.selectedField = '';
    this.sortOrder = 'DESC';
    this.sortField = 'createdAt';
    this.statusSelected = null;
    this.boardSelected = null;
    this.freelancerSelected = null;
    this.textSearchStr = null;
    this.sortingClick('');
    this.search();
  }

  searchClick() {
    this.table.first = 0;
    this.search();
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  loadAllBoardList() {
      this.taskManagementService.getNewProjectList().subscribe((res) => {
      const resObj: any = res;
      const tempBoardList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((p) => {
          tempBoardList.push({ label: p.name, value: p.id });
        });
        this.boardList = tempBoardList;
      }
    });
  }


  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  sortingClick(selectedSortOption) {
    this.setSortOption(this.selectedField, selectedSortOption);
    if (this.selectedField === 'boardName') {
      this.sortOrder = selectedSortOption;
      this.customSortTasks();
      return;
    } else if (this.selectedField === 'memberAssignee') {
      this.sortField = 'freelancer.firstName';
      this.sortOrder = selectedSortOption;
    } else {
      this.sortField = this.selectedField;
      this.sortOrder = selectedSortOption;
    }
    this.search();
  }

  customSortTasks() {
    if (this.tasks) {
      if (this.sortOrder === 'ASC') {
        this.tasks = this.tasks.sort((data1, data2) =>
          (this.getBoardName(data1.boardId) < this.getBoardName(data2.boardId) ? -1 : 1));
      } else {
        this.tasks = this.tasks.sort((data1, data2) =>
          (this.getBoardName(data1.boardId) > this.getBoardName(data2.boardId) ? -1 : 1));
      }
    }
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
      } else {
        item.sortOptions = '';
      }
    });
  }

  loadSortOption(selectedSortOption?) {
    this.selectedSortOption = selectedSortOption;
  }

  onChangeStatus(event) {
    this.table.first = 0;
    this.search();
  }

  getName(data: any) {
    return data?.firstName;
  }

  getBoardName(val: any) {
    if (!val) {
      return;
    }
    if (this.boardListTmp) {
      const b = this.boardListTmp.filter(a => a.value === val);
      if (b.length > 0) {
        return b[0].label;
      }
    }
    return '';
  }

  getCreatedByList() {
    const options = {
      companyId: null
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.freelancerTicketService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    });
  }

  getAllFreelancer() {
    this.freelancerService.getAllFreelancer({
      companyId: this.authService.getCurrentCompanyId(),
      status: 1
    }).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        res.data.forEach(r => {
          this.freelancers.push({
            label: r.fullName + ' (' + r.githubId + ')',
            value: r.id
          });
        });
      }
    });
  }

  linkify(inputText) {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    return replacedText;
  }

  copyToClipboard() {
    const text = document.getElementById('textToCopy').innerText;
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.messageService.add({ severity: 'success', summary: 'Ticket Link', detail: 'The ticket URL has been copied to clipboard.' });
  }

  loadAllBoardListByCompanyId() {
    const body = {
      companyId: this.authService.getCurrentCompanyId()
    }

    this.boardListTmp = [];
    this.taskManagementService.getProjectListNew(body).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((p) => {
          this.boardListTmp.push({ label: p.name, value: p.id });
        });
      }
    });
  }

  filterTextSearch() {
    if (!this.textSearchStr || this.textSearchStr === '') {
      this.tasks = this.allTasks;
    } else {
      this.tasks = this.allTasks.filter(task => {
        const boardName = this.getBoardName(task.boardId);
        const assignedTo = this.getName(task.freelancer);
        return (boardName && boardName.includes(this.textSearchStr)) 
          || (assignedTo && assignedTo.includes(this.textSearchStr))
          || (task.ticketLink && task.ticketLink.includes(this.textSearchStr))
          || (task.startDate && moment(task.startDate).format('MM/DD/YYYY').includes(this.textSearchStr))
          || (task.deadline && moment(task.deadline).format('MM/DD/YYYY').includes(this.textSearchStr))
          || (task.createdAt && moment(task.createdAt).format('MM/DD/YYYY').includes(this.textSearchStr))
          || (task.status && task.status.includes(this.textSearchStr))
          || (task.createdByUsr && task.createdByUsr.includes(this.textSearchStr));
      });
    }

  }
}
