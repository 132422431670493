import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BillingHistory } from '../model/billinghistory.model';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BillingHistoryService extends AbstractService<BillingHistory> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.BILLING_HISTORY, '');
    }

    getAllBillingInvoice(): Observable<BillingHistory[]> {
        return this._http.get<BillingHistory[]>(`${this.baseUrlV2}search`).pipe(map(resp => resp));
    }

    getByPage(params: any): Observable<Page<BillingHistory>> {
        return this._http.post<Page<BillingHistory>>(`${this.baseUrlV2}search`,
            params).pipe(map(resp => resp));
    }

    getFieldNames(): Observable<Array<string>> {
        return this._http.get<Array<string>>(this.baseUrlV2 + Constants.URI.BILLING.GETFIELDNAMES).pipe(map(resp => resp));
    }
}
