import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { PerformanceCriteriaSearch } from '../../../shared/model/search/performance-criteria.search';
import { PerformanceCriteria } from '../model/performance-criteria';
import { Constants } from '../../../shared/model/constants';
import { Page } from '../../../shared/model/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PerformanceService extends AbstractServiceV2<PerformanceCriteria, PerformanceCriteriaSearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PERFORMANCE_CRITERIA, '');
    }

    searchPerformanceCriteria(searchParam: PerformanceCriteriaSearch): Observable<Page<PerformanceCriteria>> {
        return this._http.post<Page<PerformanceCriteria>>(
            this.baseUrlV2 + "/"+ Constants.URI.PERFORMANCE_CRITERIA.SEARCH, searchParam).pipe(map(resp => resp));
    }

    getPerformanceCriteriaHistory(options: any) {
        return this._http.post<Page<any>>(this.baseUrlV2 + "/history", options).pipe(map(rsp => rsp));
    }
}
