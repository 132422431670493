import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import {FollowUplabel} from '../model/follow-up-label'
import { AbstractService } from 'app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FollowUpLabelService  extends AbstractService<FollowUplabel>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.FOLLOW_UP_LABEL, '');
  }

    getLabelDropdown(options: any): Observable<any> {
      return this._http.post<any>(`${this.baseUrlV2}dropdown`, options).pipe(map(resp => resp));
  }

}
