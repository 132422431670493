import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { DomHandler } from 'primeng/dom';

@Directive({
  selector: '[pFrozenColumn]',
  host: {
      'class': 'p-element',
      '[class.p-frozen-column]': 'frozen'
  }
})
export class FrozenColumnDirective implements AfterViewInit {

  @Input() get frozen(): boolean {
      return this._frozen;
  }

  @Input() left;

  set frozen(val: boolean) {
      this._frozen = val;
      this.updateStickyPosition();
  }

  @Input() alignFrozen: string = "left";

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
      this.updateStickyPosition();
  }

  _frozen: boolean = true;

  updateStickyPosition() {
      if (this._frozen) {
          if (this.alignFrozen === 'right') {
              let right = 0;
              let next = this.el.nativeElement.nextElementSibling;
              if (next) {
                  right = DomHandler.getOuterWidth(next) + parseFloat(next.style.right);
              }
              this.el.nativeElement.style.right = right + 'px';
          }
          else {
              let left = 0;
              if (this.left) {
                left = this.left;
              } else {
                let prev = this.el.nativeElement.previousElementSibling;
                if (prev) {
                    left = DomHandler.getOuterWidth(prev) + parseFloat(prev.style.left);
                }
              }

              this.el.nativeElement.style.left = left + 'px';
          }

          let filterRow = this.el.nativeElement.parentElement.nextElementSibling;

          if (filterRow) {
              let index = DomHandler.index(this.el.nativeElement);
              if (filterRow.children && filterRow.children[index]) {
                  filterRow.children[index].style.left = this.el.nativeElement.style.left;
                  filterRow.children[index].style.right = this.el.nativeElement.style.right;
              }
          }
      }
  }
}