import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Constants } from 'app/shared/model/constants';

@Injectable({ providedIn: 'root' })
export class AdminAuthService {
    private static URL = environment.v2_server_ip + '/admin/auth';
    private static USER_AWAY_URL = environment.v2_server_ip +'/' +Constants.ROUTES.OPERATOR + "/users-away"

    constructor(private http: HttpClient) {
    }
    createAwayFromDesk(body: any): Observable<any> {
        return this.http.post(`${AdminAuthService.USER_AWAY_URL}`, body);
    }

    updateDeskDuration(id: number, duration: number): Observable<any> {
        return this.http.put(`${AdminAuthService.USER_AWAY_URL}/awayActivity/${id}/${duration}`, {});
    }
}
