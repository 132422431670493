import { Injectable } from '@angular/core';
import { AbstractService } from '../../../shared/service/abstract.service';
import { AgencyAdmin } from '../model/agency-admin.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Page } from '../../../shared/model/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AbstractServiceV2} from '../../../shared/service/abstract.v2.service';
import {AgencyAdminSearch} from '../../../shared/model/agency.admin.search';
import {environment} from "../../../../environments/environment";
import {Company} from "../../../company/model/company.model";

@Injectable({ providedIn: 'root' })
export class AgencyAdminService extends AbstractServiceV2<AgencyAdmin, AgencyAdminSearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.ADMIN, '');
    }

    getAgencyAdminsByPage(params: any): Observable<Page<AgencyAdmin>> {
        return this._http.get<Page<AgencyAdmin>>(this.baseUrlV2 + '/' + Constants.URI.ADMIN.GETALLAGENCYADMIN, { params: params }).pipe(map(resp => resp));
    }

    getActiveAgencyAdmin(options?: any): Observable<Page<AgencyAdmin>> {
        return this._http.get<Page<AgencyAdmin>>(this.baseUrlV2 + Constants.URI.ADMIN.GETALLACTIVEAGENCYADMIN, {params: options}).pipe(map(resp => resp));
    }

    getAgencyAdminByCompanyId(params: any): Observable<Page<AgencyAdmin>> {
        return this._http.get<Page<AgencyAdmin>>(this.baseUrlV2 + Constants.URI.ADMIN.GETAGENCYADMINBYCOMPANYID, { params: params }).pipe(map(resp => resp));
    }

    getAgencyAdminByCompanyAndAgencyId(params: any): Observable<Page<AgencyAdmin>> {
        return this._http.get<Page<AgencyAdmin>>(this.baseUrlV2 + Constants.URI.ADMIN.GETAGENCYADMINBYAGENCYID, { params: params }).pipe(map(resp => resp));
    }

    getAllAgencySubAgencyAdmin(): Observable<any> {
        return this._http.get<any>(this.baseUrlV2 + Constants.URI.ADMIN.GETALLAGENCYSUBAGENCYADMIN).pipe(map(resp => resp));
    }

    generatePdfFile(data:any){
        return this._http.post<any>(this.baseUrlV2 + '/' + Constants.URI.ADMIN.GETALLAGENCYADMIN+'/pdf', data).pipe(map(res => res));
    }

    isAccountNameAvail(accountName: string) {
        const URL = this.baseUrlV2 + '/isAccountNameAvail';
        const option = {accountName: accountName};
        return this._http.get(URL, {params: option});
    }

    isAccountNameAvailWithId(accountName: string, id: Number) {
        // const params = accountName + (id ? '/' + id : '');
        // const URL = this.baseUrlV2 + '/isAccountNameAvail/' + params;
        // return this._http.get(URL);
        const URL = this.baseUrlV2 + '/isAccountNameAvail';
        const option = {accountName: accountName};
        return this._http.get(URL, {params: option});
    }

    isEmailAvail(email: string) {
        const URL = this.baseUrlV2 + '/email-exist';
        const option = {email: email};
        return this._http.post(URL,option);
      }

    changePassword(entity: any): Observable<any> {
        return this._http.put<any>(this.baseUrlV2+'/change-password', entity).pipe(map(res => res));
    }
    saveHistoryData(data) {
        return this._http.post(this.baseUrlV2+'/history', data).pipe(map(res => res));
    }
    getHistoryData(options: any) {
        return this._http.post<Company>(`${this.baseUrlV2}/history/search`, options ).pipe(map(resp => resp));
    }
    validateCurrentPassword(entity: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2+'/validate-password', entity).pipe(map(res => res));
    }

    updateAccessControlSetting(entity: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}${Constants.URI.ADMIN.ACCESS_CONTROL_SETTING}`, entity).pipe(map(res => res));
    }

    updateStageState(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrlV2+'stage_state', {params: params}).pipe(map(res => res));
    }

    getAdminById(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrlV2+'adminbyid', {params: params}).pipe(map(res => res));
    }

    getAgencyAdminDropdown(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrlV2 + Constants.URI.ADMIN.DROPDOWNLIST, {params: params}).pipe(map(resp => resp));
    }

    getAllAdmin(pageNumber, pageSize, companyId?: any) {
        const params = <any>{
            pageSize: pageSize,
            pageNumber: pageNumber
        };
        if (companyId) {
            params.companyId = companyId;
        }
        return this._http.get<any>(`${this.baseUrlV2}`, {params: params}).pipe(map(resp => resp));
    }

    getAgencyAdminForDropdown(params: any): Observable<any> {
        return this._http.get<any>(this.baseUrlV2 + Constants.URI.AGENCY.USERNAME_DROPDOWNLIST, {params: params}).pipe(map(resp => resp));
    }

    getPunchInOutAdmin(companyId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}punchinout_admin/${companyId}`).pipe(map(resp => resp));
    }

    getDetail(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/${id}`)
            .pipe(map(res => res));
    }

    getAllUsers(options?: any): Observable<any> {
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}/user_detail_list`, { params: params }).pipe(map(res => res));
    }
    
    unblockUser(data) {
        // return this._http.get(`http://localhost:8003/api/v2/admin/${data.id}/status/${data.status}/${data.modifiedBy}`);
        return this._http.get(`${this.baseUrlV2}/${data.id}/status/${data.status}/${data.modifiedBy}`);
    }

    createAdminAccount(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8003/api/v2/admin/create-admin-account`, entity).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}/create-admin-account`, entity).pipe(map(res => res));
    }

    createAdmin(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8003/api/v2/admin`, entity).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
    }
}
