import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../model/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Employee} from '../../../employee/model/employee.model';

@Injectable({ providedIn: 'root' })
export class DropDownsService {

  private company_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}`;
  private agency_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.AGENCY}`;
  private admin_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.ADMIN}`;
  private group_api_url = `${environment.v2_server_ip}/${Constants.URI.ADMIN.GROUP}`;
  private employee_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.EMPLOYEE}`;
  private crm_contact_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.CRM.CONTACT_DROPDOWN}`;
  private freelancer_api_url = `${environment.server_ip}/${Constants.ROUTES.FREELANCER}`;
  private client_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.CRM.CLIENT}`;
  private biller_api_url = `${environment.v2_server_ip}/${Constants.ROUTES.BILLING}`;
  private v2Url = `${environment.v2_api_url}`;
  constructor(private http: HttpClient) { }

  getAllCrmContactList(clientId: any): Observable<any[]> {
      const sendParam: any = {};
      if (clientId) {
          sendParam.clientId = clientId;
      }
    return this.http.get<any[]>(`${this.crm_contact_api_url}`, {params: sendParam}).pipe(map((resp) => resp));
  }


  getAllCompanyList(): Observable<any> {
    return this.http.get(`${this.company_api_url}/${Constants.URI.COMPANY.DROPDOWNLIST}`).pipe(map((resp) => resp));
  }


  getAllAdminNameList(): Observable<any> {
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.USERNAME_DROPDOWNLIST}`).pipe(map((resp) => resp));
  }

  getAllAdminNameListByCompany(params:any): Observable<any> {
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.USERNAME_DROPDOWNLIST}`, {params: params}).pipe(map((resp) => resp));
  }

  getAllCompanyAdminList(): Observable<any> {
    return this.http.get(`${this.admin_api_url}/${Constants.URI.COMPANY.ADMINDROPDOWNLIST}`).pipe(map((resp) => resp));

  }

  getAllGroupList(): Observable<any> {
    return this.http.get(`${this.group_api_url}/drop_down`).pipe(map((resp) => resp));
  }

  getAllEmployeeList(params?:any): Observable<any> {
    return this.http.get(`${this.employee_api_url}/dropdown`, {params: params}).pipe(map((resp) => resp));
  }

  getAllEmployeeListWithCondition(params?:any): Observable<any> {
      return this.http.post(`${this.employee_api_url}/dropdownWithCondition`, params).pipe(map((resp) => resp));
  }

  getAllEmployeeListByCompanyAgency(params): Observable<any> {
    return this.http.get(`${this.employee_api_url}/getByCmpAgn`, {params: params}).pipe(map((resp) => resp));
  }

  filterByCompany(params: any): Observable<any> {
    return this.http.get(`${this.employee_api_url}/` + Constants.URI.EMPLOYEE.FILTERBYCOMPANY, { params: params }).pipe(map(resp => resp));
  }

  getAllEmployeeListByCompany(params): Observable<any> {
    return this.http.get(`${this.employee_api_url}/getByCompany`, {params: params}).pipe(map((resp) => resp));
  }

  getAllAgencyByCompanyId(companyId: any): Observable<any>{
    const sendParam: any = {};
    if (companyId) {
      sendParam.companyId = companyId;
    }
    return this.http.get(`${this.agency_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  loadAgencyCreatedUsername(): Observable<any> {
    const sendParam: any = {};
    return this.http.get(`${this.agency_api_url}/${Constants.URI.AGENCY.CREATED_USERNAME}`, {params: sendParam}).pipe(map((resp) => resp));
  }


  getAllAgencyListByCompanyId(companyId: any): Observable<any>{
    const sendParam: any = {};
    if (companyId){
      sendParam.companyId = companyId;
    }
    return this.http.get(`${this.agency_api_url}/${Constants.URI.AGENCY.LISTAll}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAdminListBasedOnRole(adminType: number, includeAll?: boolean): Observable<any> {
    const toSendParams: any = {};
    if (includeAll) {
      toSendParams.includeAll = includeAll;
    }
    return this.http.get(`${this.admin_api_url}/drop_down`, {params: toSendParams}).pipe(map((resp) => resp));
  }

  getAllAgencyAdminByCompanyId(companyId: any): Observable<any>{
    const sendParam: any = {};
    if (companyId) {
      sendParam.companyId = companyId;
      sendParam.type = 3;
    }
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllAgencyAdminByAgencyId(agencyId: any): Observable<any>{
    const sendParam: any = {};
    if (agencyId) {
      sendParam.agencyId = agencyId;
      sendParam.type = 3;
    }
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllAgencyAdmin(): Observable<any>{
    const sendParam: any = {};
    sendParam.type = 3;
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllCompanyAdmin(): Observable<any>{
    const sendParam: any = {};
    sendParam.type = 2;
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllAdmin(): Observable<any>{
    const sendParam: any = {};
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllCompanyAdminByCompanyId(companyId: any): Observable<any>{
    const sendParam: any = {};
    if (companyId) {
      sendParam.companyId = companyId;
      sendParam.type = 2;
    }
    return this.http.get(`${this.admin_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getCompanyById(companyId: any): Observable<any>{
    const sendParam: any = {};
    if (companyId) {
      sendParam.companyId = companyId;
    }
    return this.http.get(`${this.company_api_url}/${Constants.URI.COMPANY.DROPDOWNLIST}`, {params: sendParam}).pipe(map((resp) => resp));
  }

  getAllClientList(params?: any): Observable<any> {
    // return this.http.get(`http://localhost:8010/api/v2/crm/client/${Constants.URI.COMPANY.DROPDOWNLIST}`, { params: params }).pipe(map((resp) => resp));
    return this.http.get(`${this.client_api_url}/${Constants.URI.COMPANY.DROPDOWNLIST}`, { params: params }).pipe(map((resp) => resp));
  }

  getAllClientListWithCondition(params?: any): Observable<any> {
      // return this.http.get(`http://localhost:8010/api/v2/crm/client/${Constants.URI.COMPANY.DROPDOWNLIST}`, { params: params }).pipe(map((resp) => resp));
      return this.http.post(`${this.client_api_url}/dropdownWithConditions`, params).pipe(map((resp) => resp));
  }

  getAllBillerList(): Observable<any> {
    return this.http.get(`${this.biller_api_url}/${Constants.URI.BILLING.BILLERLIST}`).pipe(map((resp) => resp));
  }

  getFreelancerAdminListBasedOnRole(adminType: number, includeAll?: boolean): Observable<any> {
    const toSendParams: any = {};
    if (includeAll) {
      toSendParams.includeAll = includeAll;
    }
    return this.http.get(`${this.freelancer_api_url}/drop_down`, {params: toSendParams}).pipe(map((resp) => resp));
  }

  getAllCreatedByUsers(companyId: any, type: number): Observable<any> {
    const url = `${this.admin_api_url}/all_created_by_users?companyId=${companyId}&type=${type}`;
    //const url = `http://localhost:8003/api/v2/admin/all_created_by_users?companyId=${companyId}&type=${type}`;
    return this.http.get<any>(url).pipe(map(resp => resp));
  }

  getGroup(id: Number): Observable<any> {
    return this.http.get<any>(`${this.group_api_url}/${id}`)
      .pipe(map(res => res));
  }


  getDropDownAdmin(companyId: number): Observable<Employee[]> {
    let query = '';
    if (companyId) {
      query = `?companyId=${companyId}`;
    }
    return this.http.get<Employee[]>(`${this.v2Url}/adminv2/dropdown-admin` + query).pipe(map(resp => resp));
  }

  getDropDownAdminByEmail(entity: any): Observable<any> {
    return this.http.post<any>(`${this.v2Url}/adminv2/email-conference`, entity).pipe(map(res => res));
  }

  getAllBillerByCompany(options): Observable<any> {
    return this.http.post(`${this.biller_api_url}/${Constants.URI.BILLING.BILLERLIST}?companyid=${options.companyId || 0}`, options).pipe(map((resp) => resp));
  }
}
