import { Injectable } from "@angular/core";
import { PDFDocument } from "pdf-lib";

@Injectable({ providedIn: 'root' })
export class DocSignUtils {

  static SIGNATURE_FIELD = 1;
  static DATE_FIELD = 2;
  static FULL_NAME_FIELD = 3;
  static TITLE_FIELD = 4;

  constructor() {
  }

  createSignatureImgTemplate(callback: BlobCallback) {
    fetch('assets/pdfjs/sign-btn.png').then(async response => {
      const imageBlob = await response.blob();
      const imageElement = new Image();
      const objectURL = URL.createObjectURL(imageBlob);
      imageElement.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          return;
        }
        const borderWidth = 1;
        canvas.width = 50;
        canvas.height = 50;
        // Draw border
        context.strokeRect(borderWidth / 2, borderWidth / 2, canvas.width - borderWidth, canvas.height - borderWidth);

        // Draw resized image at the bottom center of the canvas
        const imageWidth = 30; // Desired width of the image
        const imageX = (canvas.width - imageWidth) / 2; // Calculate X-coordinate to center the image horizontally
        const imageY = canvas.height - imageWidth - borderWidth; // Position the image at the bottom (subtracting borderWidth for padding)
        context.imageSmoothingEnabled = false;

        context.drawImage(imageElement, imageX, imageY, imageWidth, imageWidth);

        // Step 5: Add Text at the Top Center
        context.fillStyle = "black"; // Text color
        context.font = "11px Helvetica"; // Font style and size
        context.textAlign = "center"; // Text alignment
        context.fillText('Sign', canvas.width / 2, 15);
        canvas.toBlob(callback, 'image/png');
        URL.revokeObjectURL(objectURL)
      }
      imageElement.src = objectURL;
    });
  }

  creatFiledImg(fieldName, width, height, callback) {
    this.textToBlob((blob) => {
      this.scaleFiledCanvas(blob, width, height, callback);
    }, fieldName);
  }

  scaleFiledCanvas(blob, newWidth, newHeight, callback) {
    const img = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const borderWidth = 1;
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.strokeRect(borderWidth / 2, borderWidth / 2, canvas.width - borderWidth, canvas.height - borderWidth);
        ctx.drawImage(img, 0, 0, img.width, img.height, 2, 2, newWidth - 4, newHeight - 4);
        canvas.toBlob(callback, 'image/png')
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(blob);
  }

  textToBlob(blobCallback: BlobCallback, text: string, fontSize = 50) {
    const VERTICAL_EXTRA_SPACE = 5;
    const HORIZONTAL_EXTRA_SPACE = 2;
    const font = 'Helvetica';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      blobCallback(null);
      return;
    }
    ctx.textBaseline = 'top';
    ctx.font = `${fontSize}px "${font}"`;

    const {
      actualBoundingBoxLeft,
      actualBoundingBoxRight,
      fontBoundingBoxAscent,
      fontBoundingBoxDescent,
      width,
    } = ctx.measureText(text);
    const canvasHeight =
      Math.max(
        Math.abs(fontBoundingBoxAscent) + Math.abs(fontBoundingBoxDescent),
        (Math.abs(fontBoundingBoxAscent) || 0) +
        (Math.abs(fontBoundingBoxAscent) || 0),
      ) * VERTICAL_EXTRA_SPACE;
    canvas.height = canvasHeight;

    const canvasWidth =
      Math.max(width, Math.abs(actualBoundingBoxLeft) + actualBoundingBoxRight) *
      HORIZONTAL_EXTRA_SPACE;
    canvas.width = canvasWidth;
    ctx.textBaseline = 'top';
    ctx.font = `${fontSize * 1}px "${font}"`;
    ctx.fillText(text, canvasWidth / 4, canvasHeight / 4);
    this.trimCanvas(canvas);
    canvas.toBlob(blobCallback, 'image/png');
  }

  trimCanvas(canvas) {
    const ctx = canvas.getContext('2d');
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const length = pixels.data.length;
    let topCoord = null;
    let bottomCoord = null;
    let leftCoord = null;
    let rightCoord = null;
    let x = 0;
    let y = 0;
    for (let i = 0; i < length; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width;
        y = Math.trunc(i / 4 / canvas.width);

        if (topCoord === null) {
          topCoord = y;
        }
        if (leftCoord === null || x < leftCoord) {
          leftCoord = x;
        }
        if (rightCoord === null || x > rightCoord) {
          rightCoord = x;
        }
        if (bottomCoord === null || bottomCoord < y) {
          bottomCoord = y;
        }
      }
    }
    topCoord = topCoord || 0;
    bottomCoord = bottomCoord || 0;
    leftCoord = leftCoord || 0;
    rightCoord = rightCoord || 0;
    const trimHeight = bottomCoord - topCoord + 20;
    const trimWidth = rightCoord - leftCoord + 20;
    const trimmed = ctx.getImageData(leftCoord, topCoord, trimWidth, trimHeight);

    canvas.width = trimWidth;
    canvas.height = trimHeight;
    ctx.putImageData(trimmed, 10, 10);
  }

  isPdfType(type: string) {
    return !type || type.toLowerCase() === 'pdf';
  }

  isImageType(type: string) {
    return type.toLowerCase() === 'png' || type.toLowerCase() === 'jpg' || type.toLowerCase() === 'jpeg';
  }

  isDocType(type: string) {
    return type.toLowerCase() === 'doc' || type.toLowerCase() === 'docx';
  }

  async imageToPDF(blob: Blob, type): Promise<Blob> {
    const pdfDoc = await PDFDocument.create();
    const arrayBuffer = await blob.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
      let image;
    if (type.toLowerCase() == 'png') {
      image = await pdfDoc.embedPng(uint8Array);
    } else {
        image = await pdfDoc.embedJpg(uint8Array);
    }

    // Set the PDF page dimensions to A4 size (approx. 595.28 x 841.89 points)
    let pageWidth = 595.28;
    let pageHeight = 841.89;
    if ((image.width / image.height) > 1.4) {
      pageWidth = 841.89
      pageHeight = 595.28;
    }
    const page = pdfDoc.addPage([pageWidth, pageHeight]);

    // Calculate the scaling factors to fit the image within the A4 page
    const scaleX = pageWidth / image.width;
    const scaleY = pageHeight / image.height;
    const scale = Math.min(scaleX, scaleY);

    // Calculate the centered position for the scaled image
    const scaledWidth = image.width * scale;
    const scaledHeight = image.height * scale;
    const x = (pageWidth - scaledWidth) / 2;
    const y = (pageHeight - scaledHeight) / 2;

    // Draw the scaled image on the page
    page.drawImage(image, {
      x,
      y,
      width: scaledWidth,
      height: scaledHeight,
    });
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }


  blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  base64ToBlob(base64String: string): Blob {
    const base64Part = base64String.split(',')[1];
    if (base64Part) {
      const binaryData = atob(base64Part);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      return new Blob([arrayBuffer], { type: 'image/png' });
    }

    return null;
  }

  convertRectToXY(rect: number[]): { x: number, y: number } {
    const [x1, y1, x2, y2] = rect;
    const x = Math.min(x1, x2);
    const y = Math.min(y1, y2);
    return { x, y };
  }
}