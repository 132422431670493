import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from '../../../app/shared/model/constants';

declare let google: any;

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  public static URL = `${environment.v2_server_ip}/${Constants.ROUTES.ZONE}`;
  // private static URL = `http://localhost:8080/operation/api/v2/operation/zone`;
  constructor(private http: HttpClient, private ngZone: NgZone) {}

  public deleteZone(zoneId: number): Observable<any> {
    return this.http.delete<any>(ZoneService.URL + `/${zoneId}`);
  }

  getZoneList(options): Observable<any> {
    return this.http.post(`${ZoneService.URL}/list`, options);
  }

  streamZones(companyId, searchText): Observable<any> {
    let url = `${ZoneService.URL}/stream-zones`;
    if (companyId) {
      url = url + `?companyId=${companyId}`;
      if (searchText) {
        url = url + `&searchText=${searchText}`;
      }
    } else if (searchText) {
      url = url + `?searchText=${searchText}`;
    }
    
    return new Observable(observer => {
      const eventSource = new EventSource(url);
      eventSource.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        if (data) {
          if (data.id) {
            observer.next(data);
          } else {
            eventSource.close();
          }
        }
      });
      eventSource.addEventListener('error', (error) => {
        console.error('Error: ', error);
        eventSource.close();
      });
      return () => {
        eventSource.close();
      };
    });
  }

  getZonePage(options): Observable<any> {
    // return this.http.post(`http://localhost:8007/api/v2/operation/zone/search`, options);
    return this.http.post(`${ZoneService.URL}/search`, options);
  }

  getZoneStatistic(companyId: number): Observable<any> {
    return this.http.get(`${ZoneService.URL}/statistic?companyId=${companyId}`);
  }

  createZone(options: any) {
    return this.http.post(`${ZoneService.URL}`, options);
  }

  dispatch(options: any) {
    return this.http.post(`${ZoneService.URL}/dispatch`, options);
  }

  getPolygons(name: string): Observable<any> {
    return this.http.post(`${ZoneService.URL}/polygon`, {
      search: name,
      isWithGeometry: false,
      excludeCustomize: true,
    });
  }

  getPolygonByType(name: string): Observable<any> {
    return this.http.get(`${ZoneService.URL}/polygon?type=${name}`, {});
  }

  getSimpleZones(options): Observable<any> {
    return this.http.post(`${ZoneService.URL}/simple-list`, options);
  }

  getSimpleSubZones(companyId: number): Observable<any> {
    return this.http.post(`${ZoneService.URL}/simple-sub-list?companyId=${companyId}`, {});
  }

  loadZonePolygon(type: string, includeFeature: boolean): Observable<any> {
    return this.http.get(`${ZoneService.URL}/load-zone-polygon?type=${type}&includeFeature=${includeFeature}`);
  }

  getZoneVoterMapping(companyId?: number): Observable<any> {
    let url;
    if (companyId) {
      url = `${ZoneService.URL}/zone-voter-mapping?companyId=${companyId}`;
    } else {
      url = `${ZoneService.URL}/zone-voter-mapping`;
    }
    return this.http.get(url);
  }

  getZoneVoterMappingDetails(companyId?: number, zoneType?): Observable<any> {
    let url;
    if (companyId) {
      url = `${ZoneService.URL}/zone-voter-mapping-details?companyId=${companyId}`;
      if (zoneType) {
        url += `&zoneType=${zoneType}`
      }
    } else {
      url = `${ZoneService.URL}/zone-voter-mapping-details`;
      if (zoneType) {
        url += `?zoneType=${zoneType}`
      }
    }
    return this.http.get(url);
  }

  addZoneMember(options: any, memberType: string) {
    return this.http.post(`${ZoneService.URL}/add-zone-member?memberType=${memberType}`, options);
  }

  addSubZoneMember(options: any, memberType: string) {
    return this.http.post(`${ZoneService.URL}/add-sub-zone-member?memberType=${memberType}`, options);
  }

  removeZoneMember(id: number, memberId: number, memberType: string) {
    return this.http.delete(`${ZoneService.URL}/remove-zone-member/${id}/?memberId=${memberId}&memberType=${memberType}`);
  }

  removeSubZoneMember(id: number, memberId: number, memberType: string) {
    return this.http.delete(`${ZoneService.URL}/remove-sub-zone-member/${id}/?memberId=${memberId}&memberType=${memberType}`);
  }

  getHistory(param): Observable<any> {
      return this.http.post(`${ZoneService.URL}/history`, param);
  }

  updateSubZone(options: any) {
    return this.http.put(`${ZoneService.URL}/update-sub-zone`, options);
  }

  deleteSubZone(id: number) {
    return this.http.delete(`${ZoneService.URL}/sub-zone/${id}`);
  }
}
