export class PhoneSettingsModel {
	id: number;
	did: string;
	dnd: boolean;
	cfwd: boolean;
	cfwdTo: string;
	createdAt: number | Date = new Date();
	updatedAt: number | Date = new Date();
}
export interface PhoneData {
    type: string;
    extension: string;
    cfextension?: string;
}