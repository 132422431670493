import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentInterruptSource, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { MonitorDetails } from '../admin/process-monitor/model/monitor-details.model';
import { MonitoringDetailsService } from '../admin/process-monitor/service/monitoring-details.service';
import { Constants } from '../shared/model/constants';
import { AuthService } from '../shared/service/auth/auth.service';
import { UtilsService } from '../shared/service/utils.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';

@Injectable({ providedIn: 'root' })
export class IdleService {

    private static readonly IDLE_PERIOD_SECONDS = 10 * 60;
    private static readonly IDLE_TIMEOUT_SECONDS = 2;
    private static readonly PING_INTERVAL_SECONDS = 5;
    private static readonly EVENTS_LISTENED_FOR_IDLE = 'mousedown keydown';
    private static readonly MONITORING_ACTION_NOACTION = 'No action';
    private static readonly MONITORING_ACTION_INACTIVITY = 'INACTIVITY';
    private static readonly MONITORING_PROCESS_INACTIVITY = 'Inactivity';
    private idleState = '';
    private timedOut: boolean;

    constructor(
        private idle: Idle,
        private keepalive: Keepalive,
        private authService: AuthService,
        private router: Router,
        private cookieService: CookieService,
        private http: HttpClient,
        private monitoringDetailsService: MonitoringDetailsService,
        private utilsService: UtilsService,
        private companySettingServiceV2: CompanySettingServiceV2
    ) {
        // configure idle
        // idle.setIdle(IdleService.IDLE_PERIOD_SECONDS);
        // idle.setTimeout(IdleService.IDLE_TIMEOUT_SECONDS);
        idle.setInterrupts([new DocumentInterruptSource(IdleService.EVENTS_LISTENED_FOR_IDLE)]);

        // set actions
        idle.onTimeoutWarning.subscribe(this.onTimeoutWarning);
        idle.onIdleEnd.subscribe(this.onIdleEnd);
        idle.onTimeout.subscribe(this.onTimeout);
        idle.onIdleStart.subscribe(this.onIdleStart);

        // set ping interval
        keepalive.interval(IdleService.PING_INTERVAL_SECONDS);
        keepalive.onPing.subscribe(() => {
            // console.warn('Stayin\' alive@' + this.utilsService.visitedPageData.pageTitle);
        });

        // detect user logging in
        this.authService.authObservable.subscribe(this.onUserAuthorized);
    }

    /**
     * Necessary to trigger subscription to authService!
     * Avoids lazy loading of service.
     */
    public init(): void {
    }

    isPunchInOutAccount() {
        return this.authService.getCurrentUsername().indexOf('punchinout_') >=0 && this.authService.getRoleLevel() == 8;
    }

    public updateUserSpecificIdleTime() {
        if(this.isPunchInOutAccount()) {
            return;
        }
        this.companySettingServiceV2.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
            const date = new Date(res.data.requiredHours);
            const seconds = date.getMinutes() * 60 + date.getHours() * 60 * 60;
            if(res.data.requiredHoursStatus && seconds > 0) {
                this.idle.setIdle(seconds);
                this.idle.setTimeout(IdleService.IDLE_TIMEOUT_SECONDS)
                this.onRecordInactivity();
                this.reset();
            } else {
                // this.idle.setIdle(IdleService.IDLE_PERIOD_SECONDS);
                // this.idle.setTimeout(IdleService.IDLE_TIMEOUT_SECONDS)
            }
        })

        const agency = this.authService.getCurrentAgency();
        // const agency = { id: 1 };
        if (agency) {
            const agencyId = agency.id;
            this.fetchAgencySettingRequiredHours(agencyId).subscribe((value: number) => {
                if (value === 0) {
                    this.idle.stop();
                    return;
                }
                if (!value) {
                    this.idle.stop();
                    return;
                }
                const date = new Date(value);
                const seconds = date.getUTCMinutes() * 60 + date.getUTCHours() * 60 * 60;
                this.idle.setIdle(seconds);
                // this.reset();
            });
        }
      
    }

    private onTimeoutWarning = (countdown): void => {
        this.log('You will time out in ' + countdown + ' seconds!');
    }

    private onIdleEnd = (): void => {
        this.log('No longer idle.');
        this.reset();
    }

    private onTimeout = (): void => {
        if(this.isPunchInOutAccount()) {
            return;
        }
        this.log('Timed out!');
        this.timedOut = true;
        this.recordNoAction().subscribe(this.logout);
    }

    private onIdleStart = (): void => {
        this.log('You\'ve gone idle!');
    }

    private onUserAuthorized = (isUserLogged: boolean): void => {
        if (!isUserLogged) {
            this.idle.stop();
            return;
        }
        this.reset();
        this.updateUserSpecificIdleTime();
        this.onRecordInactivity();
    }

    private onRecordInactivity() {
        const options = {
            size: 5,
            page: 0,
            sortField: 'requestStartTime',
            sortOrder: 1,
            companyId: this.authService.getCurrentCompanyId(),
            startedBy: this.authService.getCurrentLoggedInId()
        }; 
        this.monitoringDetailsService.getProcessListByPage(options)
            .subscribe((res: any) => {
                const resObj: any = res.data.content;
                if(resObj && resObj.length > 0) {
                    let lastMonitorDetails = resObj[0];
                    if(lastMonitorDetails.action !== IdleService.MONITORING_ACTION_NOACTION) {
                        return;
                    }
                    this.recordInactivity(lastMonitorDetails).subscribe((createdInactivity: any) => {
                        console.log(`Created inactivity action ${JSON.stringify(createdInactivity)}`);
                    });
                }
            }, (error: any) => console.log(error));
    }

    private recordInactivity(lastMonitorDetails: any) {
        const monitorDetails = new MonitorDetails();
        const inactivityStart = lastMonitorDetails.endTime;
        monitorDetails.requestStartTime = inactivityStart;
        monitorDetails.processStartTime = inactivityStart;
        monitorDetails.endTime = new Date();
        monitorDetails.process = IdleService.MONITORING_PROCESS_INACTIVITY;
        monitorDetails.companyId = this.authService.getCurrentCompanyId();
        monitorDetails.startedById = this.authService.getCurrentLoggedInId();
        monitorDetails.startedByName = this.authService.getCurrentLoggedInName();
        monitorDetails.arguments = { '': this.utilsService.visitedPageData.pageTitle };
        monitorDetails.status = 'Completed';
        monitorDetails.action = IdleService.MONITORING_ACTION_INACTIVITY;
        let duration = monitorDetails.endTime.getTime() - new Date(monitorDetails.processStartTime).getTime();
        monitorDetails.duration = Math.round(duration / 1000 ) * 1000;
        monitorDetails.userName = this.authService.getUserInfo().username;
        monitorDetails.userId = this.authService.getUserInfo().adminId;
        return this.monitoringDetailsService.create(monitorDetails);
    }

    private reset = (): void => {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    private logout = (): void => {
        this.authService.logout().subscribe((res: any) => {
            if (res.status === 200) {
                this.authService.logUserActivity();
                this.cookieService.deleteAll();
                localStorage.clear();
                this.authService.authenticateUser();
                this.authService.setAuthObservable();
                this.router.navigate(['/login']);
            }
        }, error => {
            this.cookieService.deleteAll();
            localStorage.clear();
            this.authService.authenticateUser();
            this.authService.setAuthObservable();
            this.router.navigate(['/login']);
        });
    }

    private fetchAgencySettingRequiredHours(agencyId: number): Observable<number> {
        const url = `${environment.server_ip}/${Constants.ROUTES.AGENCY}/${agencyId}/setting/requiredHours`;
        return this.http.get(url).pipe(map((response: any) => response.data));
    }

    private log(text: string): void {
        this.idleState = text;
        // console.warn(this.idleState); // comment out
        console.log(text);
    }

    private recordNoAction(): Observable<MonitorDetails> {
        const monitorDetails = new MonitorDetails();
        const now = new Date();
        const inactivityLength = this.idle.getIdle();
        const inactivityStart = new Date(now.getTime() - inactivityLength);
        monitorDetails.requestStartTime = inactivityStart;
        monitorDetails.processStartTime = inactivityStart;
        monitorDetails.endTime = inactivityStart;
        monitorDetails.process = IdleService.MONITORING_PROCESS_INACTIVITY;
        monitorDetails.companyId = this.authService.getCurrentCompanyId();
        monitorDetails.startedById = this.authService.getCurrentLoggedInId();
        monitorDetails.startedByName = this.authService.getCurrentLoggedInName();
        monitorDetails.arguments = { '': this.utilsService.visitedPageData.pageTitle };
        monitorDetails.status = 'Incomplete';
        monitorDetails.action = IdleService.MONITORING_ACTION_NOACTION;
        monitorDetails.userName = this.authService.getUserInfo().username;
        monitorDetails.userId = this.authService.getUserInfo().adminId;
        monitorDetails.duration = 0;  // workaround: latest inactivity has endTime equal to startTime
        return this.monitoringDetailsService.create(monitorDetails);
    }
}
