import { AbstractService } from "../../shared/service/abstract.service";
import { ToDoNotes } from "../model/todonotes.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../../shared/model/constants";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ToDoNotesService extends AbstractService<ToDoNotes>{
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.TODONOTE, '');
    }

    getAllTodoNotes(id: number): Observable<any[]> {
        // return this._http.get<any[]>(`http://localhost:8007/api/v2/operation/todo/${id}/todo-notes`).pipe(map(resp => resp));
        return this._http.get<any[]>(`${this.baseUrlV2 + 'todo'}/${id}/todo-notes`).pipe(map(resp => resp));
    }

    saveToDoNote(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8007/api/v2/operation/todo-notes`, entity).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}todo-notes`, entity).pipe(map(res => res));
      }

}