import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { PhonePackageSearch } from '../../shared/model/search/phone-package.search';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { PhonePackage } from '../model/phone-package.model';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';
import {PaginatedResponse} from '../../shared/model/PageResponse/paginated.response';
import {MonthlyFeeHistory} from '../../monthly-fee/model/monthly-fee-history.model';
import {PhonePackageHistory} from '../model/phone-package-history.model';

@Injectable({ providedIn: 'root' })
export class PhonePackageService extends AbstractServiceV2<PhonePackage, PhonePackageSearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PHONEPACKAGE, '');
    }

    getAllList(options?: any): Observable<PhonePackage[]> {
        const params = this.createParams(options);
        return this._http.get<PhonePackage[]>(this.baseUrlV2 + '/search', { params: params }).pipe(map(res => res));
    }
    getDepartmentListByPage(params: any): Observable<Page<PhonePackage>> {
        return this._http.get<Page<PhonePackage>>(this.baseUrlV2, { params: params }).pipe(map(res => res));
    }

    saveOpertelContract(contractData: any): Observable<any> {
        console.log(this.baseUrlV2);
        return this._http.post<any>(`${this.baseUrlV2}`, contractData).pipe(map(res => res));
    }

    generateContract(contractData): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/contract/contract-pdf`, contractData).pipe(map(resp => resp));
    }

    getCompanyContract(id: Number) {
        return this._http.get(`${this.baseUrlV2}/${id}`).pipe(map(res => res));
    }

    updateCompanyContract(contractData: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}${Constants.URI.COMPANY.COMPANYCONTRACT}`, contractData).pipe(map(res => res));
    }

    search(options: any): Observable<Page<PhonePackage>> {
        return this._http.post<Page<PhonePackage>>(`${this.baseUrlV2}/search`, options).pipe(map(res => res));
    }

    getDropDownList() {
        return this._http.get(`${this.baseUrlV2}/getCreatedByDropdown`).pipe(map(res => res));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/exportPdf`, data).pipe(map(resp => resp));
    }

    getHistory(options: any): Observable<PaginatedResponse<PhonePackageHistory>> {
        return this._http.post<PaginatedResponse<PhonePackageHistory>>(this.baseUrlV2 + '/history/search', options).pipe(map(res => res));
    }

    saveHistoryData(data) {
        return this._http.post(this.baseUrlV2 + '/history', data).pipe(map(res => res));
    }
}
