
<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<form [formGroup]="forgotPassForm" class="card p-0 add-shadow login mx-auto">
	<div class="ui-g p-4 pb-0" style="text-align: center; display: inline;">
		<img src="../../../assets/images/logo.png" style="width: 135px;height: auto;"><br>
		<h1 class="m-0" translate>{{'PasswordReset' | translate}}</h1>
	</div>
	<div class="ui-g p-4 pb-5">
		<div *ngIf="successMsg || message" class="ui-g-12">
			<div *ngIf="successMsg" class="alert alert-success alert-dismissible fade show" role="alert">
				<span class="one-liner"><strong><i class="fa-solid fa-circle-check mr-2"></i>{{'Success' | translate}}: </strong> {{successMsg}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
			<div *ngIf="message" class="alert alert-danger alert-dismissible fade show" role="alert">
				<span class="one-liner"><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}: </strong> {{message}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label for="email">{{'Email' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-at"></i>
					</button>
				</div>
				<input pInputText type="text" id="email" [(ngModel)]="forgotPasswordData.email" formControlName="email" placeholder="{{'Email' | translate}}"/>
			</div>
			<span class="text-danger" *ngIf="forgotPassForm.controls['email'].hasError('required') && showErr" style="font-size: 12px">{{'This field is required' | translate}}</span>
		</div>
		<!-- <div class="ui-g-12">
			<label for="type" class="mb-0" translate>login_type</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
                <p-dropdown style="flex-grow:1 !important" [options]="userTypes" formControlName="type" placeholder="{{'SelectUserType' | translate}}" [(ngModel)]="forgotPasswordData.type">
                    <ng-template pTemplate="item" let-item>
                        {{item.label | translate}}
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        {{item.label | translate}}
                    </ng-template>
                </p-dropdown>
			</div>
			<span class="text-danger" *ngIf="forgotPassForm.controls['type'].hasError('required') && showErr" style="font-size: 12px">{{'This field is required' | translate}}</span>
		</div>				 -->
	</div>
	<div class="card-footer p-4 d-flex align-items-center justify-content-between">
		<button type="button" (click)="backToLogin()"  class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="forgotPassword()" class="btn btn-primary mx-1"><span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span></button>		
	</div>
</form>
