export class AwayActivity {
    id: number;
    userId: number;
    username: string;
    name: string;
    type: string;
    loginStatus: string;
    deskStatus: string;
    other: string;
    duration: number;
}
