import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LettermailSearch} from '../../shared/model/search/lettermail.search';
import {Constants} from '../../shared/model/constants';
import {ListResponse} from '../../shared/model/list.response';
import {PaginatedResponse} from '../../shared/model/PageResponse/paginated.response';
import {Page} from '../../shared/model/page.model';
import {ToDo} from '../../todo/model/todo.model';
import {EmailTemplate} from "../model/email-template.model";
import {AbstractService} from "../../shared/service/abstract.service";

@Injectable({ providedIn: 'root' })
export class EmailTemplateService extends AbstractService<EmailTemplate>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.EMAIL_TEMPLATE, '');
  }


    search(options: any ): Observable<PaginatedResponse<EmailTemplate>> {
        let params = this.createParams(options);
        return this._http.get<PaginatedResponse<EmailTemplate>>(this.baseUrlV2 + "search", {params: params}).pipe(map(res => res));
    }

    printPdf(data): Observable<any> {
        const headers = new HttpHeaders();
        const params = new HttpParams().append('companyName', data.companyName)
            .append('printedBy', data.printedBy);
        return this.http.get(this.baseUrlV2  + data.id + '/exportPdf', {headers, params}).pipe(map(resp => resp));
    }
    getDropdownCreatedBy(searchParams:LettermailSearch):Observable<ListResponse<EmailTemplate>>
    {
        let params = this.createParams(searchParams);
        return this._http.get<ListResponse<EmailTemplate>>(this.baseUrlV2 + "/dropdownCreatedBy", { params: params }).pipe(map(resp => resp));
    }

    updateMail(options: any): Observable<Page<ToDo>> {
        // return this._http.put<Page<ToDo>>('http://localhost:8010/api/v2/email-template', options).pipe(map(res => res));
        return this._http.put<Page<ToDo>>(`${this.baseUrlV2}`, options).pipe(map(res => res));
    }

    createMail(entity: any): Observable<any> {
        // return this._http.post('http://localhost:8010/api/v2/email-template', entity).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
    }

    getMail(id: Number): Observable<any> {
        // return this._http.get<any>(`http://localhost:8010/api/v2/email-template/${id}`).pipe(map(res => res));
        return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(res => res));
    }
}
