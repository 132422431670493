import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/shared/service/auth/auth.service';
import * as _ from 'lodash';
import { LazyLoadEvent, OverlayPanel } from 'primeng';
import { Subject, Subscription } from 'rxjs';
import { FileUtility } from "../../../shared/utility/file.utility";
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import moment from 'moment';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { debounceTime } from 'rxjs/operators';
import {MessageService} from "primeng/api";
import { CurrencyPipe, DatePipe } from "@angular/common";

@Component({
  selector: 'app-client-self-payment',
  templateUrl: './client-self-payment.component.html',
  styleUrls: ['./client-self-payment.component.scss'],
})
export class ClientSelfPaymentComponent implements OnInit {
  @ViewChild('op3', { static: true }) op3: OverlayPanel;

  transactionList: any[] = [];
  invoiceRef: any = {};
  exportAs: any;
  loading = false;
  totalRecords = 0;
  isPlatformAdmin: any;
  showPaymentDialog: boolean = false;
  paymentMethod: { label: string; value: string; }[];
  paymentType: { label: string; value: string; }[];
  paymentPayer: any[];
  defaultDate: any;
  disablePayer: boolean = true;
  msgs = [];
  rowData: any;

  cols: any[];
  size: number = 9999;
  page: number = 0;
  sortField: string = "";
  sortOrder: number;
  selectedSortOption = "";
  selectedField = "";
  textSortOptions = [
    {name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z'},
    {name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a'}
    ];
    dateSortOptions = [
        {name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1'}
    ];
    numberSortOptions = [
        {name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1'}
    ];
    statusSortOptions = [
        {name: 'First', value: 'ASC', img: 'circle-xmark text-danger'},
        {name: 'First', value: 'DESC', img: 'circle-check text-success'}
    ];
    uploadSortOptions = [
        { name: 'Attachments First', value: 'DESC', img: 'paperclip text-success' },
        { name: 'Empty First', value: 'ASC', img: 'paperclip text-muted' }
    ];
  exportOptions = [
    { name: 'View Detail', value: 'view' },
    { name: 'Download invoice', value: 'download' },
  ];
  fromDate: Date;
  toDate: Date;
  timeSpent = new Date();
  private _routerSub = Subscription.EMPTY;
  searchText;
  searchTextSubject = new Subject<string>();

  statusTypes: any[] = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' }
  ];
  selectedStatus;

  allClientList: any = [];
  selectedClient;

  paymentMethods = [
    { label: 'Zelle', value: 'zelle' },
    { label: 'Check', value: 'check' },
    { label: 'Other', value: 'other' }
  ];
  paymentMethodSelected;

  isClientRole;

  constructor(
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private paymentInvoiceService: PaymentInvoiceService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) {
    this.isClientRole = this.authService.isClientRole();

  }

  ngOnInit() {
    this.searchTextSubject.pipe(debounceTime(1000)).subscribe(text => {
      this.searchText = text;
      // this.search();
    })
    this.fromDate = moment().startOf('month').toDate();
    this.toDate = moment().endOf('month').toDate();
    this.cols = [
      { field: 'createdAt', label: 'Added', sortOptions: '', sort: 'number' },
      { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
      { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
      { field: 'amount', label: 'Amount', sortOptions: '', sort: 'number' },
      { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
      { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text' },
      { field: 'documentUrl', label: 'Attachment' },
      { field: 'updatedBy', label: 'Updated By',  sortOptions: '', sort: 'text' },
      { field: 'updatedAt', label: 'Updated', sortOptions: '', sort: 'number' },
      { field: 'status', label: 'Status' }
    ];
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.loadAllClient();
    this.loadContentsLazy();
  }


  ngOnDestroy(): void {
    this.searchTextSubject.unsubscribe();
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
  }

  loadAllClient() {
    const options: any = {size: 99999, page: 0, moduleName: 'billing'};
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.isClientRole) {
      // Prevent load all client when login user is client
      const userInfor = this.authService.getUserInfo();
      this.selectedClient = {value: userInfor.username, label: userInfor.name, email: userInfor.email};
      this.allClientList = [this.selectedClient];
      return;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.allClientList = data.map(c => ({value: c.fullName, label: c.value, email: c.email}));
      }
    });
  }

  loadContentsLazy(event?: LazyLoadEvent) {
    if (this.authService.isContractorRole()) {
      return;
    }
    this.loading = true;
    this.sortField = event && event.sortField ? event.sortField : "";
    this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
    let sortOrder = this.selectedSortOption === 'DESC' ? 'desc' : 'asc'
    const fromDate = this.fromDate ? (new Date(this.fromDate).getTime() + new Date(this.fromDate).getTimezoneOffset() * 60000) : null;
    const toDate = this.toDate ? (new Date(this.toDate).getTime() + new Date(this.toDate).getTimezoneOffset() * 60000) : null;
    this.size = event ? event.rows : (this.size ? this.size : 10);
    this.page = event ? (event.first) / event.rows : (this.page ? this.page : 0);
    const options: any =
    {
      size: this.size,
      page: this.page,
      fromDate: fromDate,
      toDate: toDate,
      statuses: this.selectedStatus,
      paymentMethod: this.paymentMethodSelected
    };
    if (this.selectedClient) {
      options.createdByUsr = this.selectedClient.value;
    }
    if (this.authService.isCompanyAdminRole()) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.authService.isClientRole()) {
      options.createdByUsr = this.authService.getCurrentUsername();
    }
    // if (this.searchText) {
    //   options.searchText = this.searchText;
    // }

    if (this.selectedField != "") {
      options.sort = this.selectedField + ',' + sortOrder;
      options.sortField = this.selectedField;
      options.sortOrder = this.selectedSortOption;
    } else {
      options.sortField = 'createdAt';
      options.sortOrder = 'DESC';
    }
    this.loadPage(options);
  }

  loadPage(options: any) {
    this.paymentInvoiceService.searchClientSelfPayment(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.transactionList = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
      }
      this.loading = false;
    }, () => this.loading = false);

  }

  sortingClick() {
    this.loadContentsLazy();
  }

  sort(fieldName: string, order: number) {
    this.transactionList.sort((row1, row2) => {
      let val1 = row1[fieldName];
      let val2 = row2[fieldName];
      if (val1?.toLowerCase) {
        val1 = val1.toLowerCase();
      }
      if (val2?.toLowerCase) {
        val2 = val2.toLowerCase();
      }
      if (val1 === val2) {
        return 0;
      }
      let result = -1;
      if (val1 > val2) {
        result = 1;
      }
      if (order < 0) {
        result = -result;
      }
      return result;
    });
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  convertArrayToString(array: any) {
    return array.join(", ");
  }

  clickInvoiceRef(event: any, rowData) {
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportInvoicePdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.paymentInvoiceService.exportPdfWithNameByInvoiceNumber(this.invoiceRef.invoiceNumber).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
      }
    });
  }

  exportInvoicePdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdfByInvoiceNumber(invoice.invoiceNumber).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
    }
  }

  searchEvent(event?: any) {
    const wordSearch = event.target.value;
    this.searchTextSubject.next(wordSearch);
    this.search();
  }

  search() {
    this.page = 0;
    this.loadContentsLazy();
  }

  reset() {
    this.page = 0;
    this.selectedClient = null;
    this.searchText = null;
    this.selectedField = '';
    this.selectedStatus = null;
    this.paymentMethodSelected = null;
    this.fromDate = moment().startOf('month').toDate();
    this.toDate = moment().endOf('month').toDate();
    this.loadContentsLazy();
  }

  exportPdfClientSelfPayment() {
    const fromDate = this.fromDate ? (new Date(this.fromDate).getTime() + new Date(this.fromDate).getTimezoneOffset() * 60000) : null;
    const toDate = this.toDate ? (new Date(this.toDate).getTime() + new Date(this.toDate).getTimezoneOffset() * 60000) : null;
    
    const options: any =
    {
      size: 9999,
      page: 0,
      fromDate: fromDate,
      toDate: toDate,
      statuses: this.selectedStatus,
      paymentMethod: this.paymentMethodSelected,
      sortField: 'createdAt',
      sortOrder: 'DESC'
    };
    if (this.selectedClient) {
      options.createdByUsr = this.selectedClient.value;
    }
    if (this.authService.isCompanyAdminRole()) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.authService.isClientRole()) {
      options.createdByUsr = this.authService.getCurrentUsername();
    }
    if (this.searchText) {
      options.searchText = this.searchText;
    }
    this.paymentInvoiceService.searchClientSelfPayment(options).subscribe(
      (res) => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {

          const transactions = resObj.data.content;
          if (transactions && transactions.length) {
            const data: any = {
              contentList: transactions.map((value, idx) => {
                return {
                  index: idx +1,
                  createdAt: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy HH:mm:ss'),
                  clientName: value.clientName,
                  invoiceNumber: value.invoiceNumber,
                  balance: this.currencyPipe.transform(value.balance, 'USD', true, "1.2-2"),
                  amount: this.currencyPipe.transform(value.amount, 'USD', true, "1.2-2"),
                  status: value.status,
                  paymentMethod: value.paymentMethod,
                  updatedAt: this.datePipe.transform(value.updatedAt, 'MM/dd/yyyy HH:mm:ss'),
                  updatedBy: value.updatedBy
                };
              })
            };
            if (!this.isPlatformAdmin) {
              data.companyId = this.authService.getCurrentCompany();
            }
            data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
            data.username = this.authService.getCurrentUsername();
            this.paymentInvoiceService.exportPdfClientSelfPayment(data).subscribe(res => {
              if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
              } else {
                this.messageService.add({ severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting wallet transaction pdf list' });
              }
            });
          }
        }
        this.loading = false;
      }, 
      () => (this.loading = false)
    );
  }
}
