import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NoteFolder } from 'app/note/model/note.model';

@Component({
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
  styleUrls: ['./folder-item.component.scss']
})
export class FolderItemComponent implements OnInit {
  @Input() data: NoteFolder;
  @Input() isActive: Boolean;
  @Output() onEditName = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onShare = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  editName(event) {
    event.stopPropagation();
    this.onEditName.emit({ ...this.data });
  }
  delete(event) {
    event.stopPropagation();
    this.onDelete.emit({  ...this.data });
  }
  share(event) {
    event.stopPropagation();
    this.onShare.emit({  ...this.data });
  }
}
