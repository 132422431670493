import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '../model/page.model';
import { environment, environmentLocal } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractSearch } from '../../shared/model/search/abstract.search';
import { PaginatedResponse } from '../model/PageResponse/paginated.response';

export abstract class AbstractServiceV2<T, S extends AbstractSearch> {

  protected baseUrlV2: string;
  protected moduleRouteG: String;

  constructor(protected _http: HttpClient, protected moduleRoute: String, protected uri: String) {
    this.moduleRouteG = moduleRoute;
    this.baseUrlV2 = `${environment.v2_server_ip}/${moduleRoute}`;
  }

  setBaseUrl(url: string) {
    this.baseUrlV2 = `${url}/${this.moduleRouteG}/`;
  }

  getPage(options?: any): Observable<Page<T>> {
    const params = this.createParams(options);
    return this._http.get<Page<T>>(this.baseUrlV2, { params: params }).pipe(map(res => res));
  }

  get(id: Number): Observable<T> {
    return this._http.get<T>(`${this.baseUrlV2}/${id}`)
      .pipe(map(res => res));
  }

  create(entity: T): Observable<T> {
    return this._http.post<T>(this.baseUrlV2 + '/', entity).pipe(map(res => res));
  }

  update(entity: T, id: Number): Observable<T> {
    return this._http.put<T>(`${this.baseUrlV2}/` + id, entity).pipe(map(res => res));
  }

  delete(id: Number) {
    return this._http.delete(`${this.baseUrlV2}/${id}`).pipe(map(data => data));
  }

  createParams(options: any): HttpParams {
    let params = new HttpParams();
    if (options) {
      for (const key in options) {
        if (options.hasOwnProperty(key) && options[key] != null) {
          params = params.set(key, options[key]);
        }
      }
    }
    return params;
  }

  searchByGet(options: S): Observable<PaginatedResponse<T>> {
    const params = this.createParams(options);
    return this._http.get<PaginatedResponse<T>>(this.baseUrlV2 + '/search', { params: params }).pipe(map(res => res));
  }

  searchByPost(options: S) {
    return this._http.post<PaginatedResponse<T>>(this.baseUrlV2 + '/asset/search', options).pipe(map(res => res));
  }

  deleteByIds(toDeleteIds?: any): Observable<Page<T>> {
    const toSendData = {
      ids: toDeleteIds
    };
    return this._http.post<Page<T>>(this.baseUrlV2 + '/delete', toSendData).pipe(map(res => res));
  }

  getAll(): Observable<T[]> {
    return this._http.get<T[]>(this.baseUrlV2);
  }
}
