import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { PotentialClientService } from 'app/crm/campaign/service/potential-client.service';
import { FreelancerTicketService } from 'app/crm/service/freelancerticket.service';
import { EmployeeClockingService } from 'app/employee-clocking/service/employee-clocking.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { DashboardService } from 'app/layout/service/dashboard/dashboard.service';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { ToDoService } from 'app/todo/service/todo.service';
import * as moment from 'moment';
import * as MOMENT from 'moment';
import { PaymentInvoiceService } from '../../../payment/service/payment-invoice.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import {ProductService} from '../../../crm/service/product.service';
import {Router} from '@angular/router';
import {FileUtility} from '../../../shared/utility/file.utility';
import { CompanyService } from 'app/company/service/company.service';
import {MessageService} from 'primeng/api';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { PaymentTransactionService } from 'app/payment/service/payment-transaction.service';
import { TransactionDetailsService } from 'app/transaction-type/service/transaction-details.service';
import {EmployeeJobReportNoteService} from '../../../employee/service/employee-job-report-note.service';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';
import { ClockingComplianceRequest } from 'app/reports/model/clock.compliance.model';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
  selector: 'app-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.scss'],
  providers: [OperatorService, PaymentTransactionService, TransactionDetailsService]
})
export class CrmDashboardComponent implements OnInit, OnDestroy {
  dummyData = [
    { heading: 'Clients', working: '', count: '0', rate: ' 0' },
    { heading: 'Punch In/Out', working: '', count: '0', rate: ' 0' },
    { heading: 'Payments', working: '', count: '0', rate: ' 0' },
    { heading: 'Current User Login', working: '', count: '0', rate: ' 0' },
    { heading: 'Task Management', working: '', count: '0', rate: ' 0' }
  ];
  clientsCount = 0;
  absencesCount = 0;
  clockedIn = 0;
  totalPayment = 0;
  expectedClockedIn = 0;
  taskManagementNumber = 0;
  employeeDisplayData: any = [];
  popupJobReportSummaryData: any = [];
  employeeAbsentDisplayData: any = [];
  employeeDisplayClockingOutForLunch: any = [];
  listEmployeeOnline: any = [];
  employeeDisplayClockIn: any = [];
  employeeDisplayClockingAbsent: any = [];
  employeeDisplayMissedJobReport: any = [];
  employeeDisplayMissedJobReportMoreThanLimit: any = [];
  employeeDisplayAbsenseList: any = [];
  employeeNotLunchList: any = [];
  popupOutHeader = `Out to Lunch - (${moment().format('MM/DD/YYYY')}) `;
  popupOutForLunchCols = [
    { field: 'order', header: '#' },
    { field: 'fullName', header: 'FullName' },
    { field: 'time', header: 'Time' }
  ];
  popupAbsentCols = [];
  popupJobReportSummaryCols = [];
  totalOutForLunch = 0;
  totalOutForLunchCount = 0;
  totalAbsent = 0;
  totalMissJobReport = 0;
  totalMissThanLimit = 0;
  totalClockedIn = 0;
  totalNotLunch = 0;
  numOfCurrentLoginUser = 0;
  isPopupOutForLunch = false;
  isPopupJobReportSummary = false;
  data: any;
  startDate = MOMENT().startOf('week').toDate();
  endDate = MOMENT().endOf('week').toDate();

  startOfWeek = MOMENT().startOf('week').toDate();
  endOfWeek = MOMENT().endOf('week').toDate();

  showModalContract = false;
  balances = [];
  clientList: any = [];
  types = [];
  selectedType = 'weekly';
  dataPie: any;
  payment: any;
  date = [];
  totalPtClient = 0;
  totalPtClientThisWeek = 0;
  numOfOverDueTask = 0;
  numOfOverDueTaskThisWeek = 0;
  numOfCurrentTask = 0;
  numOfCurrentTaskThisTask = 0;
  numOfCompletedTaskToday = 0;
  totalCompletedTaskToday = 0;
  totalContractSigned = 0;
  totalContractSignedThisWeek = 0;
  potentialClientList: any = [];
  crmProducts = [];
  overtimeChart = [];
  chartData: any;
  chartOptions: any;
  options = {
    tooltips: {
        callbacks: {
            label: function (t, data) {
                const dataset: any = data.datasets[t.datasetIndex];
                const currentValue = dataset.data[t.index];
                 return data['labels'][t['index']] + ': ' + currentValue.toFixed(2);
            }
        }
    }
  };

  intervalUpdateTimeout;

  monitorDetails = [];
  planAssigmentList = [];
  scheduleTos: any;
  jobReports = [];
  showFullNote = false;
  latestNote = '';

  todoStatus: Array<any> = [
    { label: 'ToDo', value: 'ToDo' , color: '#CCCCCC'},
    { label: 'Start', value: 'Start' , color: '#FF6600'},
    { label: 'InProgress', value: 'In Progress' , color: 'blue'},
    { label: 'TempStop', value: 'Temp Stop' , color: 'red'},
    { label: 'Completed', value: 'Completed' , color: 'green'},
    { label: 'waitingforOthers', value: 'Waiting for Others', color: 'purple'}
  ];

  totalAmountPaid = 0;
  totalUserLeads = 0;
  totalCompletedTicketCard = 0;
  viewMonitoring = false;
  viewOvertime = false;
  viewLeads = false;
  viewAssignments = false;
  viewJobReports = false;

  loadingAssignments = false;
  loadingJobReports = false;
  loadingLeads = false;

  popupIndex = 0;
  popupIndexSummary = 1;
  showSumary = false;
  leaveTable = false;
  userTable = true;
  isPopupLateness = false;
  popupLatenessData: any = [];
  popupLatenessCols = [];
  totalLateness = 0;
  complianceFilter: ClockingComplianceRequest = new ClockingComplianceRequest();
  modalHeaderTitle: string;
  paymentDetailData: any;
  showPaymentDetailPopup = false;
  pieChartByPaymentMethodData: any = [];
  pieChartOption =  {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#000000',
        usePointStyle: true
      },
    },
    responsive: true,
    title: {
      display: true,
      text: '$Payments'
    }
  };

  totalCashOut = 0;
  totalCashIn = 0;

  invoices = [];
  displayedInvoices = [];
  public currentIndex = 0;
  public readonly pageSize = 6;
  workOrdersArea = false;
  jobReportsArea = true;

  textSearch = '';
  filteredEmployeeDisplayData: any[] = [];
  absentFilter = false;
  loadingTable = false;
  constructor(private dashboardService: DashboardService,
              private employeeClockingService: EmployeeClockingService,
              private paymentInvoiceService: PaymentInvoiceService,
              private employeeService: EmployeeService,
              private taskManagementService: TaskManagementService,
              private authService: AuthService,
              private freelancerTicketService: FreelancerTicketService,
              private toDoService: ToDoService,
              private potentialClientService: PotentialClientService,
              private employeeJobReportService: EmployeeJobReportService,
              private dropDownsService: DropDownsService,
              private productService: ProductService,
              private exportMonitorService: ExportMonitorService,
              private router: Router,
              private companyService: CompanyService,
              private messageService: MessageService,
              private monitorDetailsService: MonitoringDetailsService,
              private operatorService: OperatorService,
              private paymentTransactionService: PaymentTransactionService,
              private transactionDetailsService: TransactionDetailsService,
              private employeeJobReportNoteService: EmployeeJobReportNoteService,
              private emmployeeService: EmployeeService,
              private cdr: ChangeDetectorRef,
              private translatePipe: TranslatePipe
  ) { }
  showContract() {
    this.showModalContract = true;
    console.log(this.startOfWeek);
  }
  ngOnInit(): void {
    this.startDate = MOMENT().startOf('week').toDate();
    this.endDate = MOMENT().endOf('week').toDate();
    this.selectedType = 'weekly';
    this.types = [
      { value: 'weekly', label: 'This Week' },
      { value: 'month', label: 'This Month' },
      { value: 'year', label: 'This Year' }
    ];
    this.dataPie = {
      labels: [this.translatePipe.transform('Service Fees Paid'), this.translatePipe.transform('Outstanding Balances'), this.translatePipe.transform('Total Amount')],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: [
            '#36A2EB',
            '#FFCE56',
            '#e9e8e6'
          ],
          hoverBackgroundColor: [
            '#36A2EB',
            '#FFCE56',
            '#e9e8e6'
          ]
        }]
    };
    this.loadAllClient();
    this.getOutStandingBalance();
    this.getTotalPayment();
    this.getInvoice();
    this.getInvoices();
    this.getCrmProduct();
    this.getClients();
    this.loadStat();
    // this.getNumberOfCurrentUserOnline();
    this.loadAllTask();
    this.loadPotentialClients();
    this.loadTasks();
    this.loadCompletedTicket();
    this.loadAllContractSigned();
    this.getMonitorDetails();
    this.getAllEmployeeLatestNote();
    this.getUserLeads();
    this.getCompletedTicketCard();
    this.getMissedJobReport();
    this.loadDataLateness();
    this.chartOptions = {
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return (data.tooltips[tooltipItem.index]).split('#');
          }
        }

      }
    };
    this.getEmployeeOnline();
    this.loadAllSchedules();
    this.getTotalAmountPaid();
    if (this.intervalUpdateTimeout) {
      clearInterval(this.intervalUpdateTimeout);
    }
    this.viewJobReports = true;
    this.intervalUpdateTimeout = setInterval(() => {
      this.getClients();
      this.loadStat();
      this.getTotalAmountPaid();
      this.getEmployeeOnline();
      this.loadAllContractSigned();
      this.getInvoice();
      this.getMonitorDetails();
      this.getPlanAssignment();
  }, 30000);

  }

  ngOnDestroy(): void {
    if (this.intervalUpdateTimeout) {
      clearInterval(this.intervalUpdateTimeout);
    }
  }

  getCrmProduct() {
    const options: any = {
      page: 0,
      size: 9999,
      companyId: this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0
    };
    const criteria = {
    };
    console.log(options);
    this.productService.searchProduct(options, criteria).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        // this.loading = false;
        this.crmProducts = resObj.data.content;
      }
    });
  }

  private getLineLabels() {
    const labels = [];
    this.overtimeChart.forEach(item => {
      labels.push(new Date(item.weekEndDate).toLocaleDateString('en-US'));
    });
    return labels;
  }
  private getLineTooltips() {
    const tooltips = [];
    this.overtimeChart.forEach(item => {
      tooltips.push(item.tooltip);
    });
    return tooltips;
  }
  private getLineValues() {
    const values = [];
    this.overtimeChart.forEach(item => {
      values.push(item.totalHours);
    });
    return values;
  }
  selectData(event) {
    const data = this.chartData.datasets[0].data[event.element._index];
    this.router.navigate(['/app/overtime/list',
      {weekEndDate: this.chartData.labels[event.element._index], includeNoOvertime: data === 0}]);
  }
  getOvertimeData() {
    return {
      labels: this.getLineLabels(),
      tooltips: this.getLineTooltips(),
      datasets: [
        {
          label: this.translatePipe.transform('Total Overtime Hours'),
          data: this.getLineValues(),
          fill: false,
          borderColor: '#03A9F4'
        }
      ]
    };
  }

  getInvoice() {
    const options = <any>{
      companyId: 0,
      fromDate: null,
      toDate: null,
      page: 0,
      size: 9999
    };
    options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    options.fromDate = this.startDate;
    options.toDate = this.endDate;
    this.paymentInvoiceService.filter(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.invoices = resObj.data.content;
        this.invoices.forEach(invoice => {

          const client = this.clientList.find(com => invoice.clientId && com.key === invoice.clientId);
          if (client) {
            invoice.clientName = client.value;
          }
          const paidFee = invoice.paidFee ? invoice.paidFee : 0;
          invoice.paidFeeTxt = paidFee.toFixed(2);
          invoice.feeTxt = invoice.fee.toFixed(2);
          invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
          const balance = invoice.totalFee - paidFee;
          invoice.balanceTxt = balance.toFixed(2);
          invoice.color = '';
          if (!invoice.dueDate) {
            invoice.color = 'green';
          } else {
            this.date.push(new Date(invoice.dueDate));

            invoice.color = MOMENT(invoice.dueDate).isAfter(MOMENT(new Date())) ? 'orange' : 'red';
          }
        });
        this.invoices = this.invoices.filter(i => i.feeTxt !== '0.00');
      }
    });
  }

  loadAllClient() {
    const options: any = { size: 99999, page: 0};
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.clientList = resObj.data;
      }
    });
  }

  getAllData() {
    let byType: any = 'week';
    if (this.selectedType === 'today') {
      byType = 'today';
    }
    if (this.selectedType === 'yesterday') {
      byType = 'yesterday';
    }
    if (this.selectedType === 'weekly') {
      byType = 'week';
    }
    if (this.selectedType === 'month') {
      byType = 'month';
    }
    if (this.selectedType === 'year') {
      byType = 'year';
    }

    this.startDate = byType === 'yesterday' ? MOMENT().subtract(1, 'days').startOf('day').toDate() : MOMENT().startOf(byType).toDate();
    this.endDate = byType === 'yesterday' ? MOMENT().subtract(1, 'days').endOf('day').toDate() : MOMENT().endOf(byType).toDate();
    this.getTotalPayment();
    this.getOutStandingBalance();
    this.getInvoice();
    this.getInvoices();
    this.getMonitorDetails();
    this.getPlanAssignment();
    this.getTotalAmountPaid();
    this.getCompletedTicketCard();
    this.getMissedJobReport();
  }

  onSetup() {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: `Coming soon` });
  }

  getOutStandingBalance() {
    const options = <any>{
      fromDate: null,
      toDate: null,
      companyId: 0
    };
    options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    options.fromDate = this.startDate;
    options.toDate = this.endDate;
    this.paymentInvoiceService.invoiceOutStandingBalance(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.balances = resObj.data;
        const labels = [];
        const data = [];

        setTimeout(() => {
          this.balances.forEach(dt => {
            if (dt.clientId) {
              const client = this.clientList.find(com => com.key === dt.clientId);
              if (client) {
                labels.push(client.value);
              }
              data.push(dt.total);
            }

          });
          this.data = {
            labels: labels,
            datasets: [
              {
                label: this.translatePipe.transform('Outstanding Balances'),
                backgroundColor: '#4842f5',
                borderColor: '#4842f5',
                data: data
              }
            ]
          };
        }, 1000);
      }
    });
  }


  getClients() {
    const d = new Date();
    const request = {
      page: 0,
      size: 1000,
      isFromMobile: true,
      fromDate: moment().subtract(4, 'weeks').startOf('week').toDate(),
      toDate: moment().endOf('week').toDate(),
      timeZoneOffset: (d.getTimezoneOffset()).toString(),
      adminId: parseInt(localStorage.getItem('_userId'), 10)
    };
    this.dashboardService.getDashboardData(request).subscribe((res) => {
      if (res.status === 'SUCCESS') {
        this.clientsCount = res.data.clientsCount;
        this.absencesCount = res.data.absencesCount;
        this.clockedIn = res.data.clockedIn;
        this.expectedClockedIn = res.data.expectedClockedIn;
        this.overtimeChart = res.data.overtimeChart.overtimeChartBean;
        this.chartData = this.getOvertimeData();
      }
    });
  }
  exportPdf(invoice) {
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            const data = <any>{
                featureName: 'Crm Dashboard',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(() => {
            });
        }
      });
    }
  }
  loadStat(option?: any) {
    option = {};
    option.companyId = this.authService.getCurrentCompanyId();
    option.fromDate = moment(new Date()).startOf('day').toDate();
    option.toDate = moment(new Date()).endOf('day').toDate();
    option.isForOT = false;
    option.isMissing = false;
    option.type = 1;
    option.employeeIds = [];
    option.employees = [];
    option.ids = [];
    option.page = 0;
    option.size = 9999;
    if (this.authService.isEmployeeRole()) {
      option.employeeIds.push(this.authService.getCurrentEmployee());
      option.companyId = this.authService.getUserInfo().companyId;
    }
    this.employeeClockingService.punchStatisticNew(option).subscribe(res => {
      if (res.data) {
        console.log('res.data', res.data);
        const statisticData: any = res.data;
        this.totalOutForLunch = statisticData.totalOutForLunch;
        this.totalAbsent = statisticData.totalAbsent > 0 ? statisticData.totalAbsent : 0;
        this.totalClockedIn = statisticData.totalClockedIn;
        this.totalNotLunch = statisticData.totalNotLunch;
        this.employeeDisplayClockIn = statisticData.employeeClockIn;
        this.employeeDisplayClockingOutForLunch = statisticData.totalOutForLunchEmployeeList;
        this.employeeDisplayClockingAbsent = statisticData.employeeAbsent;
        this.employeeDisplayAbsenseList = statisticData.employeeAbsentAskForLeave;
        this.employeeNotLunchList = statisticData.employeeNotLunch;
      }
    }, (_error) => {
      console.log(_error);
    });
  }

  getTotalPayment() {
    const options = <any>{
      fromDate: null,
      toDate: null,
      companyId: 0
    };
    options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    options.fromDate = this.startDate;
    options.toDate = this.endDate;
    this.paymentInvoiceService.getTotalPayment(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log( resObj.data.totalAmount);
        this.totalPayment = resObj.data.totalAmount.toFixed(2);
        this.payment = resObj.data;
        this.dataPie = {
          labels: [this.translatePipe.transform('Service Fees Paid'), this.translatePipe.transform('Outstanding Balances'), this.translatePipe.transform('Total Amount')],
          datasets: [
            {
              data: [this.payment.serviceFee.toFixed(2),
              this.payment.outStandingBalance.toFixed(2),
              this.payment.totalAmount.toFixed(2)],
              backgroundColor: [
                '#36A2EB',
                '#FFCE56',
                '#e9e8e6'
              ],
              hoverBackgroundColor: [
                '#36A2EB',
                '#FFCE56',
                '#e9e8e6'
              ]
            }]
        };
      }
    });
  }

  getNumberOfCurrentUserOnline() {
    this.employeeService.getNumberOfCurrentUserOnline(this.authService.getCurrentCompanyId()).subscribe(res => {
      if (res.status === 'SUCCESS') {
        this.numOfCurrentLoginUser = res.data.numOfEmployee;
        this.listEmployeeOnline = res.data.listEmployeeOnline;
      }
    }, (_error) => {
      console.log(_error);
    });
  }

  loadAllTask(option?: any) {
    option = {};
    option.page = 0;
    option.size = 999;
    option.fromDate = new Date();
    option.toDate = new Date;
    this.taskManagementService.getAllTask(option).subscribe(res => {
      if (res.data) {
        const data: any = res.data;
        this.taskManagementNumber = data.numberOfElements;
      }
    }, (_error) => {
      console.log(_error);
    });
  }

  openOutForLunchDialog() {
    this.popupIndex = 2;
    this.modalHeaderTitle = 'Break';
    this.popupOutForLunchCols = [
      { field: 'order', header: '#' },
      { field: 'fullName', header: 'FullName' },
      { field: 'time', header: 'Time' }
    ];
    this.popupOutHeader = `[${moment().format('MM/DD/YYYY')}] `;
    this.totalOutForLunchCount = this.totalOutForLunch;
    this.employeeDisplayData = [];
    this.employeeDisplayClockingOutForLunch.forEach((item: any, index: number) => {
      const time = item.clockOutTime != null ? item.clockOutTime : item.lunchOutTime;
      this.employeeDisplayData.push(
        {
          order: `${index + 1}.`,
          fullName: `${item.employee.fullName} `,
          time: moment(time).format('h:mm:ss A'),
          employeeId: `${item.employeeId}`,
        }
      );
    });
    this.isPopupOutForLunch = true;

  }

  openOutForClockInDialog() {
    this.popupIndex = 1;
    this.modalHeaderTitle = 'Working';
    this.popupOutForLunchCols = [
      { field: 'order', header: '#' },
      { field: 'fullName', header: 'FullName' },
      { field: 'timeIn', header: 'In Time' },
      { field: 'timeOut', header: 'Out Time' }
  ];
    this.popupOutHeader = `[${moment().format('MM/DD/YYYY')}] `;
    this.totalOutForLunchCount = this.totalClockedIn;
    this.employeeDisplayData = [];
    this.employeeDisplayClockIn.forEach((item: any, index: number) => {
      this.employeeDisplayData.push(
        {
          order: `${index + 1}.`,
          fullName: `${item.fullName} `,
          timeIn: moment(item.clockIn).format('hh:mm:ss A'),
          timeOut: item.clockOut != null ? moment(item.clockOut).format('h:mm:ss A') : '',
          employeeId: `${item.employeeId}`,
        }
      );
    });
    this.isPopupOutForLunch = true;
  }

  openOutForEmployeeOnlineDialog() {
    this.popupIndex = null;
    this.modalHeaderTitle = 'Online';
    this.popupOutForLunchCols = [
      { field: 'order', header: '#' },
      { field: 'fullName', header: 'Full Name' },
      { field: 'userName', header: 'User Name' },
      { field: 'type', header: 'Type' }
    ];
    this.popupOutHeader = `[${moment().format('MM/DD/YYYY')}] `;
    this.totalOutForLunchCount = this.numOfCurrentLoginUser;
    this.employeeDisplayData = [];
    if (this.listEmployeeOnline && this.listEmployeeOnline.length > 0) {
      this.listEmployeeOnline.forEach((item: any, index: number) => {
        this.employeeDisplayData.push(
          {
            order: `${index + 1}.`,
            fullName: item.firstLastName ? item.firstLastName : '',
            userName: `${item.userName} `,
            type: `${item.userType}`,
            employeeId: `${item.employeeId}`,
          }
        );
      });
      this.isPopupOutForLunch = true;
    }

  }

  openOutForAbsentDialog() {
  this.popupIndex = 3;
    this.userTable = true;
    this.leaveTable = false;
    this.modalHeaderTitle = 'Absent';

    this.popupOutForLunchCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'FullName' }
    ];
    this.popupAbsentCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'FullName' },
        { field: 'employeeId', header: 'ID' }
    ];
    this.popupOutHeader = `[${moment().format('MM/DD/YYYY')}] `;
    this.totalOutForLunchCount = this.totalAbsent;
    this.employeeDisplayData = [];
    this.employeeAbsentDisplayData = [];
    this.employeeDisplayClockingAbsent.forEach((item: any, index: number) => {
      this.employeeDisplayData.push(
        {
          order: `${index + 1}.`,
          fullName: `${item.fullName} `,
          employeeId: `${item.employeeId}`,
        }
      );
    });
    this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
    this.isPopupOutForLunch = true;
  }

  openNotLunchDialog() {
    this.popupIndex = 4;
    this.modalHeaderTitle = 'Missed Break';
    this.popupOutForLunchCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'FullName' }
    ];
    this.popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
    this.totalOutForLunchCount = this.totalNotLunch;
    this.employeeDisplayData = [];
    this.employeeAbsentDisplayData = [];
    this.employeeNotLunchList.forEach((item: any, index: number) => {
        this.employeeDisplayData.push(
            {
                order: `${index + 1}.`,
                fullName: `${item.fullName} `,
                employeeId: `${item.employeeId}`,
            }
        );
    });
    this.isPopupOutForLunch = true;
  }

  closeDialog() {
    this.isPopupOutForLunch = false;
  }

  loadPotentialClients() {
    const options: any = { size: 9999, page: 0 };
    const currentDate = new Date();
    const fromDate = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset());
    const toDate = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7));
    toDate.setMinutes(toDate.getMinutes() + toDate.getTimezoneOffset());
    options.companyId = this.authService.getCurrentCompanyId();
    options.fromDate = fromDate.toUTCString();
    options.toDate = toDate.toUTCString();
    this.potentialClientList = [];
    this.potentialClientService.countDashBoard(options).subscribe((data: any) => {
      const resObj: any = data;
      if (resObj.status === 'SUCCESS') {
        this.totalPtClient = resObj.data.totalPotentialClient;
        this.totalPtClientThisWeek = resObj.data.totalPotentialClientThisWeek;
      }
    });
  }

  loadTasks() {
    const options: any = { size: 9999, page: 0 };
    options.companyId = this.authService.getCurrentCompanyId();
    this.potentialClientList = [];
    this.toDoService.countDashboardSearch(options).subscribe((data: any) => {
      const resObj: any = data;
      if (resObj.status === 'SUCCESS') {
        this.numOfOverDueTask = resObj.data.numOfOverDueTask;
        this.numOfOverDueTaskThisWeek = resObj.data.numOfOverDueTaskThisWeek;
        this.numOfCurrentTask = resObj.data.numOfCurrentTask;
        this.numOfCurrentTaskThisTask = resObj.data.numOfCurrentTaskThisTask;
        this.numOfCompletedTaskToday = resObj.data.numOfCompletedTaskToday;
      }
    });
  }

  loadCompletedTicket() {
    const options: any = { size: 9999, page: 0 };
    const d = new Date();
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    options.companyId = this.authService.getCurrentCompanyId();
    options.status = 'Close';
    options.updateFrom = d;
    options.updateTo = d;
    this.potentialClientList = [];
    this.freelancerTicketService.countToDoTaskOnCEOBoard(options).subscribe((data: any) => {
      const resObj: any = data;
      if (resObj.status === 'SUCCESS') {
        this.totalCompletedTaskToday = resObj.data.totalCompletedTaskToday;
      }
    });
  }

  loadAllContractSigned() {
    const options: any = { size: 9999, page: 0 };
    options.companyId = this.authService.getCurrentCompanyId();
    this.productService.countDashboardSearch(options).subscribe((data: any) => {
      const resObj: any = data;
      if (resObj.status === 'SUCCESS') {
        this.totalContractSigned = resObj.data.totalContractSigned;
        this.totalContractSignedThisWeek = resObj.data.totalContractSignedThisWeek;
      }
    });
  }

  getEmployeeOnline() {
    this.companyService.getOnlineUsers(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.listEmployeeOnline = res.data;
        this.numOfCurrentLoginUser = res.data.length;
      }
    }, (_error) => {
      console.log(_error);
    });
  }

  getMonitorDetails() {
    const options = <any> {
      companyId: this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0,
      page: 0,
      size: 9999,
      sortField: 'createdAt',
      sortOrder: 'DESC'
    };
    options.fromDate = MOMENT(this.startDate).format('YYYY-MM-DD');
    options.toDate = MOMENT(this.endDate).format('YYYY-MM-DD');
    this.monitorDetailsService.getProcessListByPage(options).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.monitorDetails = resObj.data.content;
          const currentDate = moment(new Date()).set({hour: 0, minute: 0, second: 0, millisecond: 0});
          this.monitorDetails.forEach(m => {
            const requestStartTime = moment(m.requestStartTime).set({hour: 0, minute: 0, second: 0, millisecond: 0});
            const diff = requestStartTime.diff(currentDate, 'days');
            if (diff === 0) {
              m.openAt = 'Today ' + moment(m.requestStartTime).format('HH:mm:ss');
            } else if (diff === -1) {
              m.openAt = 'Yesterday ' + moment(m.requestStartTime).format('HH:mm:ss');
            }
          });
        }
    });
  }

  getPlanAssignment() {
    this.loadingAssignments = true;
    const options: any = {
      companyId: this.authService.getCurrentCompanyId(),
      page: 0,
      size: 9999,
      sortField: 'createdAt',
      sortOrder: 'DESC'
    };
    options.fromDate = MOMENT(this.startDate).startOf('day').utc(true);
    options.toDate = MOMENT(this.endDate).endOf('day').utc(true);
    this.operatorService.searchTodo(options, true).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loadingAssignments = false;
        this.planAssigmentList = resObj.data.content;
        this.planAssigmentList.forEach(todo => {
          todo.startsDate = MOMENT.utc(todo.startsDate);
          todo.scheduleTo = this.showScheduleFullname(todo.scheduleTo);
          todo.todo = todo.todo.trim();
          const status = this.todoStatus.find(s => s.value === todo.status);
          if (status) {
            todo.label = status.label;
            todo.color = status.color;
          }
        });
      }
    });
  }

  showScheduleFullname(value) {
    const users = [];
    let userNames = [];
    if (value) {
        userNames = value.split(',');
    }
    let names;
    userNames.forEach(user => {
        this.scheduleTos.forEach(schedule => {
            if (user === schedule.value) {
                user = schedule.fullName;
                users.push(user);
            }
        });
        names = users;
    });
    if (names) {
        return userNames.length === names.length ? names.toString() : userNames.toString();
    }
    return null;
  }

  loadAllSchedules() {
    this.scheduleTos = [];
    this.employeeService.getAllsDropdown({}).subscribe(res => {
        const resObj: any = res;
        if (resObj.status = 'SUCCESS') {
            this.scheduleTos = resObj.data;
            this.getPlanAssignment();
        }
    });
  }

  getTotalAmountPaid() {
    let fromDate = null;
    if (this.startDate) {
      fromDate = moment(moment(this.startDate).startOf('d')).utc(true).toDate();
        // fromDate = new Date(from).getTime() + new Date(from).getTimezoneOffset() * 60000;
    }
    let toDate = null;
    if (this.endDate) {
      toDate = moment(moment(this.endDate).endOf('d')).utc(true).toDate();
        // toDate = new Date(to).getTime() + new Date(to).getTimezoneOffset() * 60000;
    }
    const options: any = {
      companyId: this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0,
      size: 9999,
      page: 0,
      fromDate: fromDate,
      toDate: toDate
    };
    this.transactionDetailsService.loadLists(options).subscribe((res: any) => {
      let total = 0, totalCashOut = 0, totalCashIn = 0;
      this.paymentDetailData = res.data.content;
      res.data.content.forEach(t => {
        if (t.status === '1') {
          total += t.netCashInOut;
          totalCashOut += t.cashOut;
          totalCashIn += t.cashIn;
        }
      });
      this.totalAmountPaid = total;
      this.totalCashOut = totalCashOut;
      this.totalCashIn = totalCashIn;
    });
    // this.paymentTransactionService.search(options).subscribe((res: any) => {
    //   if (res.status === 'SUCCESS') {
    //     let total = 0;
    //     res.data.content.forEach(t => {
    //       if (t.status === 'Succeeded') {
    //         total += t.amount;
    //       }
    //     });
    //     this.totalAmountPaid = total;
    //   }
    // });
  }

  // getAllEmployeeLatestNote() {
  //   this.employeeJobReportNoteService.getAllEmployeeLatestNote(this.authService.getCurrentCompanyId()).subscribe(res => {
  //     const resObj: any = res;
  //     if (resObj.status === 'SUCCESS') {
  //       this.jobReports = resObj.data;
  //     } else {
  //       this.jobReports = [];
  //     }
  //   })
  // }

  getAllEmployeeLatestNote() {
    this.loadingJobReports = true;
    const options: any = {
      size: 10,
      page: 0,
      sortOrder: 'DESC',
      sortField: 'createdAt'
    };
    options.companyId = this.authService.getCurrentCompanyId();
    this.employeeJobReportService.search(options).subscribe((data: any) => {
      this.jobReports = data.data.content;
      this.jobReports.forEach(item => {
        item.latestNote = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].note : '';
        item.time = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].notesTime : '';
      });
      this.loadingJobReports = false;
    }, () => {
      this.loadingJobReports = false;
    });
  }

  showNote(latestNote) {
    this.showFullNote = true;
    this.latestNote = latestNote;
  }

  getUserLeads() {
    const options: any = { size: 10, page: 0 };
    options.companyId = this.authService.getCurrentCompanyId();
    options.sortField = 'id';
    options.sortOrder = 'DESC';
    this.potentialClientService.search(options).subscribe((data: any) => {
      const resObj: any = data;
      if (resObj.status === 'SUCCESS') {
        this.totalUserLeads = resObj.data.totalElements;
      }
    });
  }

  getCompletedTicketCard() {
    const options = {
      size: 10,
      page: 0,
      companyId: this.authService.getCurrentCompanyId(),
      sortField: 'createdAt',
      sortOrder: 'DESC',
      startDate: null,
      endDate: null
    };
    if (this.startDate) {
      options.startDate = this.startDate;
    }
    if (this.endDate) {
      options.endDate = this.endDate;
    }
    this.freelancerTicketService.searchFilterTickets(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.totalCompletedTicketCard = resObj.data.totalElements;
      }
    });
  }

  getMissedJobReport() {
    const options = {
      companyId: this.authService.getCurrentCompanyId(),
    };
    this.employeeJobReportService.getMissedJobReport(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.employeeDisplayMissedJobReport = resObj.data.lstMissJobReport;
        this.employeeDisplayMissedJobReportMoreThanLimit = resObj.data.lstMissJobReportThanLimit;
        this.totalMissJobReport = this.employeeDisplayMissedJobReport.length;
        this.totalMissThanLimit = this.employeeDisplayMissedJobReportMoreThanLimit.length;
      }
    });
  }

  onTabChange(event: any) {
    if (event.index === 0) {
        this.totalOutForLunchCount = this.employeeDisplayData.length;
    }

    if (event.index === 1) {
        this.totalOutForLunchCount = this.employeeAbsentDisplayData.length;
    }
  }

  openJobReportSummaryDialog(type: any) {
    this.isPopupJobReportSummary = true;
    this.changeTypeJobReport(type);
  }

  changeTypeJobReport(type: any) {
    this.showSumary = false;
    this.loadingTable = true;
    this.popupJobReportSummaryCols = [
        { field: 'order', header: '#' },
        { field: 'name', header: 'Full Name' },
        { field: 'missedReport', header: 'Count' }
    ];
    this.popupOutHeader = `Job Report Summary: (${moment().format('MM/DD/YYYY')}) `;
    this.popupJobReportSummaryData = [];
    const currentTime = moment(new Date()).format('YYYY-MM-DD');

    if (type === 'miss') {
        this.popupIndexSummary = 1;
        this.employeeDisplayMissedJobReport.forEach((item: any, index: number) => {
            let listMiss = '';
            let totalMiss = 0;
            item.listDataMiss.forEach((product: any) => {
                const hhmm = product.timeNote.split(' - ');
                const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
                const endTime =  moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
                const currTime = moment().unix() * 1000;

                if ((currTime - endTime > 0 && product.status !== 'Done') || product?.noteReason != null) {
                    product.status = 'Missed';
                    listMiss += `${product.timeNote}: ${product.status} \n`;
                    totalMiss += 1;
                }
            });
            this.popupJobReportSummaryData.push(
            {
                order: `${index + 1}.`,
                name: `${item.name} `,
                missedReport: totalMiss,
                listMissed: listMiss,
            }
            );
            this.loadingTable = false;
        });

    } else {
        this.popupIndexSummary = 2;
        this.showSumary = true;
        this.employeeDisplayMissedJobReportMoreThanLimit.forEach((item: any, index: number) => {
            let listMiss = '';
            let totalMiss = 0;
            item.listDataMiss.forEach((product: any) => {
                const hhmm = product.timeNote.split(' - ');
                const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
                const endTime =  moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
                const currTime = moment().unix() * 1000;

                if ((currTime - endTime > 0 && product.status !== 'Done') || product?.noteReason != null) {
                    product.status = 'Missed';
                    listMiss += `${product.timeNote}: ${product.status} \n`;
                    totalMiss += 1;
                }
            });
            this.popupJobReportSummaryData.push(
            {
                order: `${index + 1}.`,
                name: `${item.name} `,
                missedReport: totalMiss,
                listMissed: listMiss,
            }
            );
            this.loadingTable = false;
        });
    }
    this.loadingTable = false;
  }

  openLateness() {
    this.isPopupLateness = true;
    this.popupLatenessCols = [
        { field: 'id', header: 'ID' },
        { field: 'fullName', header: 'FullName' }
    ];
  }

  loadDataLateness() {
    const start = new Date();
    const end = new Date();
    this.complianceFilter.fromDate = start.getTime();
    this.complianceFilter.toDate = end.getTime();
    this.complianceFilter.companyId = this.authService.getCurrentCompanyId();
    this.complianceFilter.size = 1;
    this.complianceFilter.page = 0;
    this.complianceFilter.timeZone = moment.tz.guess();
    let dataRsp: any = {};
    this.employeeClockingService.getComplianceReport(this.complianceFilter).subscribe(res => {
        if (res.status === 'SUCCESS') {
            dataRsp = res.data;
            if (dataRsp?.daywiseCompliance?.length !== 0 && dataRsp.daywiseCompliance[0].lateIds?.length > 0) {
                const option = {
                    ids: dataRsp.daywiseCompliance[0]?.lateIds,
                    page: 0,
                    size: dataRsp.daywiseCompliance[0]?.lateIds.length,
                    status: 1,
                };

                this.emmployeeService.filterByCompanyNew(option).subscribe(res => {
                    this.totalLateness = res?.data?.content?.length;
                    this.popupLatenessData = [];
                    res?.data?.content.forEach((item: any, index: number) => {
                        const comeIn = ((dataRsp.daywiseCompliance || []).length && dataRsp.daywiseCompliance[0].comeInMap) ?
                            dataRsp.daywiseCompliance[0].comeInMap[item.id] : new Date();
                        this.popupLatenessData.push(
                            {
                                order: `${index + 1}.`,
                                fullName: `${item.fullName} `,
                                id: `${item.id} `,
                                comeIn: moment(comeIn).format('HH:mm a')
                            }
                        );
                    });
                    this.cdr.detectChanges();
                });
            }
        }
    }, error => {
        this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Error encountered while fetching data, try again later. If this error persists, contact support.')});
    });
  }

  openPaymentDetailPopup(type: any) {
    const pieData = (this.paymentDetailData || []).reduce((payments, item) => {
      const pItem = payments.find(pi => pi.paymentMethod === item.paymentMethod);

      if (pItem) {
        pItem.value += 'cash_in' === type ? item.cashIn : item.cashOut;
      } else {
        payments.push({paymentMethod: item.paymentMethod, value: item.netCashInOut});
      }

      return payments;
    }, []);

    this.pieChartByPaymentMethodData = {
      labels: [this.translatePipe.transform('Cash'), this.translatePipe.transform('Credit/Debit'), this.translatePipe.transform('Zelle'), this.translatePipe.transform('Check'),
        this.translatePipe.transform('Stripe'), this.translatePipe.transform('AHC'), this.translatePipe.transform('Bank Transfer'), this.translatePipe.transform('Other')],
      datasets: [
        {
          data: [
            Math.abs((pieData || []).find(i => i.paymentMethod === 'cash')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'credit/Debit')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'zelle')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'check')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'stripe')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'ahc')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'BANK_TRANSFER')?.value || 0).toFixed(2),
            Math.abs((pieData || []).find(i => i.paymentMethod === 'other')?.value || 0).toFixed(2)
          ],
          backgroundColor: [
            '#36A2EB',
            '#FFCE56',
            '#ff3e3e',
            '#82bb0c',
            '#34f8b7',
            '#0740fd',
            '#b016ff',
            '#ff00a8'
          ],
          hoverBackgroundColor: [
            '#36A2EB',
            '#FFCE56',
            '#ff3e3e',
            '#82bb0c',
            '#34f8b7',
            '#0740fd',
            '#b016ff',
            '#ff00a8'
          ]
        }]
    };
    this.showPaymentDetailPopup = true;
  }

  getInvoices() {
    const options = {
      companyId: this.authService.getCurrentCompany() || 0,
      fromDate: this.startDate,
      toDate: this.endDate,
      page: 0,
      size: 9999
    };

    this.paymentInvoiceService.filter(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.invoices = resObj.data.content;
        this.processInvoices();
        this.resetDisplayedInvoices();
      }
    });
  }

  processInvoices() {
    this.invoices.forEach(invoice => {
      const client = this.clientList.find(com => invoice.clientId && com.key === invoice.clientId);
      if (client) {
        invoice.clientName = client.value;
      }
      const paidFee = invoice.paidFee || 0;
      invoice.paidFeeTxt = paidFee.toFixed(2);
      invoice.feeTxt = invoice.fee.toFixed(2);
      invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
      const balance = invoice.totalFee - paidFee;
      invoice.balanceTxt = balance.toFixed(2);
      invoice.color = !invoice.dueDate ? 'green' : (MOMENT(invoice.dueDate).isAfter(MOMENT(new Date())) ? 'orange' : 'red');
    });
    this.invoices = this.invoices.filter(i => i.feeTxt !== '0.00');
  }

  resetDisplayedInvoices() {
    this.displayedInvoices = [];
    this.currentIndex = 0;
    this.loadMore();
  }

  loadMore() {
    const nextInvoices = this.invoices.slice(this.currentIndex, this.currentIndex + this.pageSize);
    this.displayedInvoices = nextInvoices;
  }

  loadPrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex -= this.pageSize;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
      const previousInvoices = this.invoices.slice(this.currentIndex, this.currentIndex + this.pageSize);
      this.displayedInvoices = previousInvoices;
    }
  }

  nextPage() {
    if (this.currentIndex + this.pageSize < this.invoices.length) {
      this.currentIndex += this.pageSize;
      this.loadMore();
    }
  }

  previousPage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= this.pageSize;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
      this.loadMore();
    }
  }
  gotoJobReport() {
     this.router.navigate(['/app/employee/employee-job-report/list']);
    }
    gotoEmployeePage(employeeId: number) {
        this.router.navigate([`/app/employee/edit/${employeeId}`]);
    }

    onSearchChange(searchValue: string) {
        this.textSearch = searchValue.toLowerCase();
        if (this.textSearch) {
          this.filteredEmployeeDisplayData = this.employeeDisplayData.filter(employee =>
            employee.fullName.toLowerCase().includes(this.textSearch)
          );
        } else {
          this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        }
      }

}
