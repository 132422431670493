
<div *ngIf="(isShowLog && (comment.type=='HISTORY' || comment.type!='ADD_MEMBER_TASK' || comment.type!='DEL_MEMBER_TASK')) || (comment.type!='HISTORY' && comment.type!='ADD_MEMBER_TASK' && comment.type!='DEL_MEMBER_TASK')">
    <div class="comment-header">
        <app-card-member [member]="comment.userInfo"></app-card-member>
        <div class="member-name">{{comment.createdByUsr}}</div>
        <div class="comment-time">{{comment.createdAt | date:'short'}}</div>
    </div>
    <div class="comment-content">
        <ng-container *ngIf="!showUpdateForm">
            <div *ngIf="comment.type=='TEXT'" [innerHTML]="comment.content"></div>
            <div *ngIf="comment.type=='IMAGE_URL'">
                <img style="width: 100px;cursor: pointer;max-height: 125px;" (click)="viewImage()" [src]="comment.content" />
            </div>
            <div *ngIf="comment.type=='HISTORY'" [innerHTML]="comment.content"></div>
            <div *ngIf="comment.type=='ADD_MEMBER_TASK' || comment.type=='7'" [innerHTML]="comment.content"></div>
            <div *ngIf="comment.type=='DEL_MEMBER_TASK'" [innerHTML]="comment.content"></div>
            <div *ngIf="comment.type=='COMMENT_ON_IMAGE'" (click)="viewImage('comment_img')" style="cursor: pointer;">
                <div [innerHTML]="comment.content"></div>
            </div>
            <div *ngIf="comment.type=='DOCUMENT'" style="cursor: pointer;">
                Attached a
                <span *ngIf="comment.content.includes('.pdf') ">
                    <a href="{{comment.content}}" target="_blank">
                        Pdf File
                    </a>
                </span>
                <span *ngIf="comment.content.includes('.xlsx') ">
                    <a href="{{comment.content}}" target="_blank">
                        Excel File
                    </a>
                </span>
                <span *ngIf="comment.content.includes('.txt') ">
                    <a href="{{comment.content}}" target="_blank">
                        Text File
                    </a>
                </span>
                <span *ngIf="comment.content.includes('.html') ">
                    <a href="{{comment.content}}" target="_blank">
                        Html File
                    </a>
                </span>
                <span *ngIf="comment.content.includes('.zip') ">
                    <a href="{{comment.content}}" target="_blank">
                        Zip File
                    </a>
                </span>
                to this card.
            </div>
        </ng-container>
        <div *ngIf="showUpdateForm">
            <p-editor placeholder="New comment here...." (onInit)="onEditorInit($event)" #commentEditor
                [(ngModel)]="cloneComment.content" [mention]="mentionEmployees"
                [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'value'}" (closed)="boldMention()">
            </p-editor>
            <div style="margin-top: 5px;">
                <p-button label="Update" (onClick)="update()" style="margin-right: 10px;">
                </p-button>
                <p-button label="Cancel" (onClick)="showUpdateForm=false"></p-button>
            </div>
        </div>
    </div>
    <div [ngClass]="{'disabledIcon': isSuperAdmin}" class="comment-action" *ngIf="comment.userId == userId && !showUpdateForm && comment.type!='HISTORY'
        && comment.type!='ADD_MEMBER_TASK' && comment.type!='DEL_MEMBER_TASK'">
        <u (click)="makeCover()" *ngIf="comment.type=='IMAGE_URL' && taskBackground!=comment.content">Make Cover</u>
        <u (click)="removeCover()" *ngIf="comment.type=='IMAGE_URL' && taskBackground==comment.content">Remove Cover</u>
        <u *ngIf="comment.type=='TEXT' || comment.type=='COMMENT_ON_IMAGE'" (click)="showUpdateForm=true">edit</u>
        <u (click)="delete()">delete</u>
    </div>
    <div [ngClass]="{'disabledIcon': isSuperAdmin}" class="comment-action" *ngIf="comment.userId != userId && !showUpdateForm && comment.type!='HISTORY'
        && comment.type!='ADD_MEMBER_TASK' && comment.type!='DEL_MEMBER_TASK'">
        <u (click)="reply()">reply</u>
    </div>
    <!-- <div class="modal-image-preview" *ngIf="previewImage" id="close-iamge">
        <div class="preview-content">
            <img [src]="previewImage" />
            <div class="close" (click)="closeModalImage()">X</div>
            <div class="action">
                <span (click)="delete()">Delete</span>
            </div>
        </div>
    </div> -->
    
<p-dialog class="show-image" [showHeader]="false" [(visible)]="previewImage" [modal]="true" [dismissableMask]="true">
    <div><img [src]="previewImage"/></div>
    <div class="close" (click)="closeModalImage()">X</div>
</p-dialog>
</div>
