import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MenuItem, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeDepartmentService } from '../../../department/service/employee-department.service';
import { TransactionTypeService } from '../../service/transaction-type.service';
import { TransactionType } from '../../model/transaction-type.model';
import { Company } from '../../../company/model/company.model';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-transaction-type-list',
    templateUrl: './transaction-type-list.component.html',
    styleUrls: ['./transaction-type-list.component.css'],
    providers: [TransactionTypeService, AuthService, ConfirmationService, EmployeeDepartmentService]
})
export class TransactionTypeListComponent implements OnInit {

    @ViewChild('dt', {static: true}) table: Table;
    loading = false;
    transactionTypes: TransactionType[];
    selectedTransactionType: TransactionType = new TransactionType();
    totalRecords: Number = 0;
    msgs: Message[] = [];
    searchText = '';
    userPermission: Boolean;
    rowData: any;
    selectedSortOption: any;
    selectedField = '';
    cols: any[] = [
        { field: 'id', label: 'ID', sortOptions: '', sort: 'number' },
        { field: 'transactionType', label: 'Type', sortOptions: '', sort: 'text' },
        { field: 'creatorName', label: 'Creator', sortOptions: '', sort: 'text' },
        { field: 'createdAt', label: 'Created', sortOptions: '', sort: 'number' },
        { field: 'status', label: 'Status', sortOptions: '', sort: 'text' },
        { field: 'action', label: 'Actions' }
    ];
    textSortOptions = [
        {name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z'},
        {name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a'}
    ];
    numberSortOptions = [
        {name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1'}
    ];
    dateSortOptions = [
        {name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1'}
    ];
    statusSortOptions = [
        {name: 'First', value: 'ASC', img: 'circle-xmark text-danger'},
        {name: 'First', value: 'DESC', img: 'circle-check text-success'}
    ];
    sortField: string;
    sortOrder: number;
    isDeleteAble: boolean = false;
    isEditable: boolean = false;
    size: any = 0;
    page: any = 0;
    isPlatformAdmin: Boolean = false;
    selectedCompanyId: number;
    displayDialog: boolean = false;
    selectedData: TransactionType;

    constructor(
        private transactionTypeService: TransactionTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private confirmationService: ConfirmationService,
        private employeeDepartmentService: EmployeeDepartmentService,
        private messageService: MessageService,
        private translatePipe: TranslatePipe) {
    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.transactionTypes = [];
        this.userPermission = this.authService.adminRole();
        this.isEditable =  this.authService.getLoggedInUserEditList().includes('82');
        this.isDeleteAble = this.authService.getLoggedInUserDeleteList().includes('82'); // get id from menu-list.ts
    }

    sortingClicked(selectedSortOption) {
        this.setSortOption(this.selectedField, selectedSortOption);
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach(item => {
            if (field == item.field) {
                item.sortOptions = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
        const sortOption = selectedSortOption === 'ASC' ? 1 : -1;
        this.sortData(field, sortOption);
    }

    sortData(sortField: string, selectedSortOption: number) {
        console.log(sortField);
        this.transactionTypes.sort((row1, row2) => {
            let val1 = row1[sortField];
            let val2 = row2[sortField];

            console.log(val1 + "==" + val2);
            if (sortField === 'transactionType' || sortField === 'creatorName') {
                console.log(val1 + "--" + val2);
                val1 = ("" + val1).toLowerCase();
                val2 = ("" + val2).toLowerCase();
                console.log(val1 + "--" + val2);
            }

            if (sortField === 'status') {
                val1 = ("" + val1).toLowerCase();
                val2 = ("" + val2).toLowerCase();
            }
            console.log(val1 + " - " + + val2 + " ->" + (val1 > val2))
            if (val1 === val2)
                return 0;

            let result = -1;
            if (val1 > val2) result = 1;

            if (selectedSortOption < 0) {
                result = -result;
            }

            return result;
        })

    }
    deleteTransactionType(data: TransactionType) {
        this.msgs = [];
        if (this.isDeleteAble) {
            this.selectedData = data;
            this.displayDialog = true;
        } else {
            this.messageService.add({ severity: 'error',
                summary: this.translatePipe.transform('Error'),
                detail: this.translatePipe.transform(`You do not have permissions!`) });
        }
    }

    accept() {
        this.transactionTypeService.deleteTransactionType(this.selectedData.id).subscribe((_response) => {
            this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Deleted'), detail: this.translatePipe.transform('TransactionTypeDeleted') });
            this.search();
        }, (error) => {
            this.messageService.add({ 
                severity: 'error',
                summary: this.translatePipe.transform('Error'),
                detail: this.translatePipe.transform('FailedToDelete') });
        });
        this.displayDialog = false;
    }

    reject() {
        this.displayDialog = false;
    }

    editTransactionType(data: TransactionType) {
        this.msgs = [];
        if (this.isEditable) {
            this.router.navigate(['app/transaction-type', 'edit', data.id]);
        } else {
            this.msgs.push({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform(`You do not have permissions!`) });
        }
    }

    loadTransactionTypesLazy(event: LazyLoadEvent, loading) {
        this.loading = loading;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        // this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        this.page = event.first / event.rows;
        console.log(event.first / event.rows)
        this.sortField = event && event.sortField ? event.sortField : 'id';
        this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
        const order = this.sortOrder === 1 ? 'ASC' : this.sortOrder;
        this.loadPage({ page: this.page, size: this.size, sort: this.sortField, order: order });
    }

    search(event?: any) {
        this.loading = true;
        this.table.first = 0;
        let searchKey;
        if (!event || !event.target || !event.target.value) {
            searchKey = this.searchText;
        } else {
            searchKey = event.target.value;
            this.searchText = event.target.value;
        }
        const options: any = { page: 0, size: this.table.rows };
        if (searchKey) {
            options.searchText = searchKey;
        }
        this.loadPage(options);
    }

    loadPage(options) {
        this.transactionTypes = [];
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompany();
        }
        if (this.isPlatformAdmin && this.selectedCompanyId) {
            options.companyId = this.selectedCompanyId;
        }
        this.transactionTypeService.findAllTransactionType(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                setTimeout(() => {
                    this.loading = false;
                    this.transactionTypes = resObj.data.content;
                    this.totalRecords = resObj.data.totalElements;
                }, 1000);

            }
        });
    }

    filterGlobal(search: string) {
        this.searchText = search;
        this.search();
    }

    setCompanyId(companyId) {
        this.selectedCompanyId = companyId;
        this.search();
    }

}
