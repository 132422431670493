
<p-dialog header="Payment Confirmation"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [style]="{
            width: '700px',
            minWidth: '400px'
          }"
          (onHide)="onClickClose()"
          [minY]="70"
          [dismissableMask]="true"
          id="paymentConfirmationDialog"
          >
    <p-card>
        <div class="ui-g">
            <div class="ui-g-2">
            </div>
            <div class="ui-g-10">
                =============RECEIPT CONFIRMATION=============
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Candidate:
            </div>
            <div class="ui-g-9">
                {{data?.candidateName}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Fundraising title:
            </div>
            <div class="ui-g-9">
                {{data?.fundraisingTitle}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Amount:
            </div>
            <div class="ui-g-9">
                ${{data?.amount | number:'1.2-2'}} USD
            </div>
        </div>

        <div class="ui-g">
            <div class="ui-g-3">
                Fee:
            </div>
            <div class="ui-g-9">
                ${{data?.fee | number:'1.2-2'}} USD
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Payment Method:
            </div>
            <div class="ui-g-9">
                {{data?.paymentCardType}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
            <div class="ui-g-3">
                Transaction Type:
            </div>
            <div class="ui-g-9">
                Authorization and Capture
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                Transaction ID:
            </div>
            <div class="ui-g-9">
                {{data?.transactionPaymentId}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-2">
            </div>
            <div class="ui-g-10">
                ===============LINE ITEMS===============
            </div>
        </div>
    </p-card>
    <p-footer>
        <button pButton label="Ok" (click)="onSubmit()"></button>
    </p-footer>
</p-dialog>
