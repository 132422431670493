export class PaymentMethod {
  createdAt: string;
  updatedAt: string;
  createdByUsr: string;
  lastModifiedBy: string;
  id: number;
  beneficiaryName: string;
  bankCurrency: string;
  paymentMethod: string;
  accountType: string;
  countryCode: string;
  swiftCode: string;
  bankName: string;
  bankAccount: string;
  ifscCode: string;
  branchAddress: string;
  city: string;
  zipcode: string;
  state: string;
  upworkLink: string;
  reasonForRemittance: string;
  status: any;
  companyId: number;
  isRegister: boolean;
  dob?: string;
  ssn?: string;
  bankAccountId?: string;
  email: string;
}
