import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralInvoice } from 'app/general-invoice/model/general-invoice.model';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResponse } from "../../shared/model/list.response";

@Injectable({
  providedIn: 'root'
})
export class GeneralInvoiceService extends AbstractService<GeneralInvoice> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.GENERAL_INVOICE, '');
    // this.baseUrlV2 = 'http://localhost:8007/api/v2/operation/general-invoice/';
  }

  getList(entity) {
    // return this._http.post(`http://localhost:8007/api/v2/operation/general-invoice/search`, entity).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}search`, entity).pipe(map(res => res));
  }

  getCreatedByList(companyId){
    // return this._http.get(`http://localhost:8007/api/v2/operation/general-invoice/find-created-by-user/${companyId}`).pipe(map(res => res));
    return this._http.get(`${this.baseUrlV2}find-created-by-user/${companyId}`).pipe(map(res => res));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}pdf`, data).pipe(map(resp => resp));
  }

  createGeneralInvoice(entity: GeneralInvoice): Observable<GeneralInvoice> {
    // return this._http.post<GeneralInvoice>(`http://localhost:8007/api/v2/operation/general-invoice`, entity).pipe(map(res => res));
    return this._http.post<GeneralInvoice>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
}

  updateGeneralInvoice(entity: GeneralInvoice): Observable<GeneralInvoice> {
      // return this._http.put<GeneralInvoice>(`http://localhost:8007/api/v2/operation/general-invoice`, entity).pipe(map(res => res));
      return this._http.put<GeneralInvoice>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }

  deleteAccount(id) {
    return this._http.delete(`${this.baseUrlV2}delete-account/${id}`).pipe(map(res => res));
  }

  generate(id): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}generate/${id}`).pipe(map(resp => resp));
  }

  exportPdfWithName(id: Number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}pdf-file-name/${id}`)
        .pipe(map(res => res));
  }

  exportPdfDetails(id: Number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}pdfDetails/${id}`)
        .pipe(map(res => res));
  }

  getBillToList(searchParams:any):Observable<ListResponse<any>> {
    let params = this.createParams(searchParams);
    // return this._http.get<ListResponse<any>>(`http://localhost:8007/api/v2/operation/general-invoice/dropdown-bill-to`, { params: params }).pipe(map(resp => resp));
    return this._http.get<ListResponse<any>>(`${this.baseUrlV2}dropdown-bill-to`, { params: params }).pipe(map(resp => resp));
  }

  getHistory(options: any) {
      return this._http.post<any>(this.baseUrlV2 +  "history", options).pipe(map(rsp => rsp));
  }

  exportPdfNeww(data?: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/general-invoice/exportPdf`, data).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }
}
