<div class="add-card-cnt" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="enableAddCard()">
    <!-- Text to display -->
    <div class="add-card text-dark" *ngIf="addingCard ? false : true"><i class="fa-solid fa-plus text-primary mr-2"></i> {{'Add Card' | translate}}</div>
</div>

<!-- Form to show when clicked on text -->
<div class="add-card-frm" *ngIf="addingCard ? true : false">
    <div class="card mb-2">
        <div class="card-body">
            <textarea maxlength="250" #addCardField class="no-resize" placeholder="Enter card details..."
                (keyup)="addCardOnEnter($event)" (blur)="addCardOnBlur()" [(ngModel)]="addCardText" (ngModelChange)="addCardTextValueChange()"></textarea>
        </div>
    </div>
</div>