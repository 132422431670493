export class Contract {
    id:Number;
    effectiveDate: any;
    licensor: string;
    licensee: string;
    businessPlace: string;
    identificationNo: string;
    licenseeName: string;
    licenseeAddress: string;
    licenseePhone: string;
    licenseePrintName: string;
    licenseeEmail: string;
    licenseeDirectPhone: string;
    licenseeDate: any;
    licensorName: string;
    licensorAddress: string;
    licensorPhone: string;
    licensorPrintName: string;
    licensorEmail: string;
    licensorDirectPhone: string;
    licensorDate: any;
    licensorSignature: any;
    licenseeSignature: any;
    company:any;
}
