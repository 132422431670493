import {
  Directive,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core";
import * as _ from "lodash";
import { PolygonUtils } from "../utils/polygon.utils";
// import {} from '@types/googlemaps';
declare let google: any;

@Directive({
  selector: "custom-polygon-drawer-map",
})
export class PolygonDrawerMapDirective implements OnInit, OnChanges {
  @Output() polygonChanged: EventEmitter<any> = new EventEmitter();
  @Output() pointClicked: EventEmitter<any> = new EventEmitter();
  private polygons: any = [];
  private map;
  customPolygons: any[] = [];
  overlays: any = [];
  @Input() lockedZonesPolygon: any[] = [];
  @Input() zonePolygonGeoJson: any[] = [];
  @Input() statePolygonGeoJson: any[] = [];
  @Input() zoneData;
  @Input() editMode;
  polygonList = [];
  markers = [];

  @Input("clearPolygons")
  set clearPolygons(_clearPolygons: any) {
    if (_clearPolygons) {
      if (this.overlays && this.overlays.length) {
        for (let i = 0; i < this.overlays.length; i++) {
          this.overlays[i].setMap(null);
        }
        this.overlays = [];
      }
      this.polygons = [];
      this.polygonChanged.emit(this.polygons);
      this.initDrawingManager();
    }
  }

  @Input("goBack")
  set goBack(index: any) {
    const overlay = this.overlays.filter((o, i) => i == index)[0];
    this.polygons = this.polygons.filter((p, i) => i != index);
    this.overlays = this.overlays.filter((o, i) => i != index);
    this.polygonChanged.emit(this.polygons);
    if (overlay) {
      overlay.setMap(null);
    }
  }

  @Input("removedPoint")
  set removedPoint(_removedPoint: any) {
    if (_removedPoint) {
      if (this.overlays && this.overlays.length) {
        for (let i = 0; i < this.overlays.length; i++) {
          const length = this.overlays[i].getPath().getLength();
          console.log(length);
          if (length <= 3) {
            break;
          }
          for (let j = 0; j < length; j++) {
            const vertex = this.overlays[i].getPath().getAt(j);
            if (vertex && _removedPoint.lat === vertex.lat() && _removedPoint.lng === vertex.lng()) {
              this.overlays[i].getPath().removeAt(j);
            }
          }
          for (let j = 0; j < length; j++) {
            const vertex = this.overlays[i].getPath().getAt(j);
            if (vertex && _removedPoint.lat === vertex.lat() && _removedPoint.lng === vertex.lng()) {
              this.overlays[i].getPath().removeAt(j);
            }
          }
        }
      }
      if (this.highlightedPoint) {
        this.highlightedPoint.setMap(null);
      }
      this.pointClicked.emit(false);
    }
  }

  highlightedPoint;
  drawingManager;
  zoneFeatures;
  zonePolygonInfoWindow;
  stateFeatures;

  constructor(private gmapsApi: GoogleMapsAPIWrapper, private ngZone: NgZone) {}
  ngOnInit() {
    this.gmapsApi.getNativeMap().then((map) => {
      this.map = map;
      this.map.setZoom(13);
      if ((!this.lockedZonesPolygon || this.lockedZonesPolygon.length === 0) && PolygonUtils.isCreateNew(this.zoneData)) {
        this.initDrawingManager();
      }
      if (this.lockedZonesPolygon && this.lockedZonesPolygon.length > 0) {
        PolygonUtils.loadGeoJson(this.map, this.lockedZonesPolygon);
      }
      this.zoom(this.map);
      this.map.data.addListener('click', (event) => {
        this.ngZone.runOutsideAngular(() => this.zonePolygonInfoWindow = PolygonUtils.openFeatureInfoWindow(this.map, event.feature, this.zonePolygonInfoWindow));
      });
      // this.map.data.addListener('mouseout', (event) => {
      //   if (this.zonePolygonInfoWindow) {
      //     this.zonePolygonInfoWindow.close();
      //   }
      // });
      google.maps.event.addListener(map, 'click', (event) => {
        if (this.highlightedPoint) {
          this.highlightedPoint.setMap(null);
        }
        if (this.zonePolygonInfoWindow) {
          this.zonePolygonInfoWindow.setMap(null);
        }
        this.pointClicked.emit(false);
      });
    });
  }

  initDrawingManager() {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
    });
    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //drawingManager.setDrawingMode(null);
          this.overlays.push(event.overlay);
          const newPolygon = event.overlay.getPath().getArray();
          google.maps.event.addListener(event.overlay, 'drag', (event) => {
            if (this.highlightedPoint) {
              this.highlightedPoint.setMap(null);
            }
          });
          google.maps.event.addListener(event.overlay, 'mouseover', (event) => {
            this.drawingManager.setDrawingMode(null);
          });
          google.maps.event.addListener(event.overlay, 'mouseout', (event) => {
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
          });
          google.maps.event.addListener(event.overlay, 'click', (event) => {
            if (event.vertex >= 0) {
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              this.pointClicked.emit({
                lat: lat,
                lng: lng
              });
              console.log('Clicked at: Lat ' + lat + ', Lng ' + lng);
              this.overlays.forEach(overlay => {
                overlay.getPath().forEach((point, index) => {
                  const pointLat = point.lat();
                  const pointLng = point.lng();
                  if (pointLat === lat && pointLng === lng) {
                    if (this.highlightedPoint) {
                      this.highlightedPoint.setMap(null);
                    }
                    this.highlightedPoint = new google.maps.Marker({
                      position: point,
                      map: this.map,
                      icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#00448D',
                        scale: 8,
                      }
                    });
                  }
                });
              });
            } else {
              if (this.highlightedPoint) {
                this.highlightedPoint.setMap(null);
              }
              this.pointClicked.emit(false);
            }
          });
          newPolygon.push(newPolygon[0]);
          this.polygons.push(newPolygon);
          this.polygonChanged.emit(this.polygons);
          if (this.drawingManager) {
            this.drawingManager.setMap(null);
          }
        }
      }
    );
  }

  clearPolygonList() {
    this.polygonList.forEach((p) => {
      p.setMap(null);
    });
    this.markers = [];
    this.markers.forEach((p) => {
      p.setMap(null);
    });
    this.markers = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["overlays"] &&
      changes["overlays"].previousValue !==
        changes["overlays"].currentValue
    ) {
      console.log(this.overlays);
    }

    if (
      changes["zonePolygonGeoJson"] &&
      changes["zonePolygonGeoJson"].previousValue !==
      changes["zonePolygonGeoJson"].currentValue
    ) {
      this.ngZone.runOutsideAngular(() => {
        if (this.zoneFeatures) {
          this.zoneFeatures.forEach(features => {
            features.forEach(feature => {
              this.map.data.remove(feature);
            })
          });
        }
        this.zoneFeatures = PolygonUtils.loadGeoJson(this.map, this.zonePolygonGeoJson);
      })
    }

    if (
      changes["statePolygonGeoJson"] &&
      changes["statePolygonGeoJson"].previousValue !==
      changes["statePolygonGeoJson"].currentValue
    ) {
      this.ngZone.runOutsideAngular(() => {
        if (this.stateFeatures) {
          this.stateFeatures.forEach(features => {
            features.forEach(feature => {
              this.map.data.remove(feature);
            })
          });
        }
        this.stateFeatures = PolygonUtils.loadGeoJson(this.map, this.statePolygonGeoJson);
      })

    }

    if (
      changes["editMode"] &&
      changes["editMode"].previousValue !==
        changes["editMode"].currentValue
    ) {
      this.ngZone.runOutsideAngular(() => {
        // if (this.zoneData && this.zoneData.polygon) {
        //   this.zoneData.polygon.setMap(null);
        // }
        this.zoom(this.map);
      })
    }
  }

  zoom(map) {
    if (!map || PolygonUtils.isCreateNew(this.zoneData)) {
      return;
    }
    if (this.zoneData.zoneDataFeatures) {
      this.zoneData.zoneDataFeatures.forEach(zoneDataFeature => {
        if (zoneDataFeature) {
          if (Array.isArray(zoneDataFeature)) {
            zoneDataFeature.forEach(feature => map.data.remove(feature));
          } else {
            map.data.remove(zoneDataFeature)
          }
        }
        ;
      })
    }
    const zoneDataFeatures = PolygonUtils.loadGeoJson(this.map, PolygonUtils.convertToGeoJsons([this.zoneData]), this.editMode);
    if (zoneDataFeatures && zoneDataFeatures.length) {
      this.zoneData.zoneDataFeatures = zoneDataFeatures[0];
    }
    console.log(this.zoneData.zoneDataFeatures);
    
  }
}
