import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
@Pipe({name: 'durationFormat'})
export class DurationFormatPipe implements PipeTransform {

    private static formatDuration(duration: number): string {
        const milliseconds = (duration % 1000);
        let seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor((duration / (1000 * 60 * 60)));
        if (milliseconds >= 500) {
            seconds = seconds + 1;
        }

        const h = (hours < 10) ? '0' + hours : hours;
        const m = (minutes < 10) ? '0' + minutes : minutes;
        const s = (seconds < 10) ? '0' + seconds : seconds;
        // const ms = (milliseconds < 100) ? ((milliseconds < 10) ? '00' : '0') + milliseconds : milliseconds;

        return h + ':' + m + ':' + s;
    }

    transform(value: number): string {
        return DurationFormatPipe.formatDuration(value);
    }
}

@Pipe({name: 'durationFormatMinute'})
export class DurationFormatMinutePipe implements PipeTransform {

    private static formatDuration(duration: number): string {
        const milliseconds = (duration * 1000);
        let seconds = Math.floor(duration);
        const minutes = Math.floor((duration / 60));
        const hours = Math.floor((duration / 3600));
        if (milliseconds >= 500) {
            seconds = seconds + 1;
        }

        const h = (hours < 10) ? '0' + hours : hours;
        const m = (minutes < 10) ? '0' + minutes : minutes;
        const s = (seconds < 10) ? '0' + seconds : seconds;
        // const ms = (milliseconds < 100) ? ((milliseconds < 10) ? '00' : '0') + milliseconds : milliseconds;

        return h + ':' + m + ':' + s;
    }

    transform(value: number): string {
        return DurationFormatMinutePipe.formatDuration(value);
    }
}

@Pipe({name: 'durationTime'})
export class DurationTimePipe implements PipeTransform {
    transform(durationInSeconds: number): string {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        const formattedHours = this.padNumber(hours);
        const formattedMinutes = this.padNumber(minutes);
        const formattedSeconds = this.padNumber(seconds);

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    private padNumber(num: number): string {
        return num < 10 ? `0${num}` : `${num}`;
    }
}

@Pipe({name: 'durationHM'})
export class DurationHMPipe implements PipeTransform {
    transform(durationInSeconds: number): string {
        let rs = durationInSeconds / 60000;
        return this.hhmm(Math.round(rs));
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }
}