import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';
import { environment } from '../../../environments/environment';
import { PayrollSetting } from '../model/payroll-setting.model';
import { Employee } from '../../employee/model/employee.model';
import * as MOMENT from 'moment';
import { map } from 'rxjs/operators';
import {PayrollHoliday} from '../model/payroll-holiday.model';
@Injectable({ providedIn: 'root' })
export class PayrollSettingService extends AbstractService<PayrollSetting> {


    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PAYROLL_SETTING, '');
    }

    getPayrollSettings(companyId: Number, params: any): Observable<PayrollSetting> {
        return this._http.get<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings`, {params: params})
            .pipe(map(res => res));
    }

    getPayrollSetting(id: Number): Observable<PayrollSetting> {
        return this._http.get<PayrollSetting>(`${this.baseUrl}byagency/${id}`).pipe(map(res => res));
    }

    getAllByEnding(id: Number): Observable<PayrollSetting[]> {
        return this._http.get<PayrollSetting[]>(`${this.baseUrlV2}weekending/${id}`).pipe(map(res => res));
    }
    // 'http://69.18.218.40:8080/${Constants.ROUTES.COMPANY}/1/payrollSettings'

    // savePayrollSetting(settingData: any): Observable<any> {
    //     return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/payrollsetting`, settingData).pipe(map(res => res));
    // }

    savePayrollSetting(settingData: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrl}`, settingData).pipe(map(res => res));
    }

    saveHistoryData(data) {
        return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(res => res));
    }

    getbyId(id: Number): Observable<any> {
        // return this._http.get<any>(`http://localhost:8003/api/v2/company/${id}/payrollSettings`).pipe(map(resp => resp));
        return this._http.get<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${id}/payrollSettings`)
          .pipe(map(res => res));
    }

    getPayRollSettingbyId(companyId: number, id: Number): Observable<any> {
        return this._http.get<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings/${id}`)
          .pipe(map(res => res));
    }

    createPayrollSetting(companyId: number, settingData: any) {
        return this._http.post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings`, settingData).pipe(map(res => res));
    }

    updatePayrollSetting(companyId: number, settingData: any, settingId: number) {
        // return this._http.put<any>(`http://localhost:8003/api/v2/company/${companyId}/payrollSettings/${settingId}`, settingData).pipe(map(resp => resp));
        return this._http
            .put<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings/${settingId}`, settingData)
            .pipe(map(res => res));
    }

    updateHoliday(entity: any): Observable<any> {
        return this._http
            .put<PayrollHoliday>(`${this.baseUrlV2}${Constants.URI.PAYROLL.PAYROLL_HOLIDAY}`, entity)
            .pipe(map(res => res));
    }

    savePayrollHoliday(companyId: number, settingId: number, settingData: any): Observable<any> {
        return this._http
            .post<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings/${settingId}/holiday`, settingData)
            .pipe(map(res => res));
    }

    getPayrollSettingListHistory(entity) {
        return this._http.post(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/other-history/search`, entity).pipe(map(res => res));
    }

    updatePunchSetting(id: number, settingData: any) {
        return this._http.put<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/payrollSettings/${id}/punch-setting`, settingData).pipe(map(res => res));
    }
}
