import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _moment from 'moment';
import { AuthService } from "../../../shared/service/auth/auth.service";
import { PaymentProfileService } from "../../service/payment-profile.service";

@Component({
    selector: 'app-echeck-funds',
    templateUrl: './echeck-funds.component.html',
    styleUrls: ['./echeck-funds.component.scss']
})
export class EcheckFundsComponent implements OnInit {

    @Output()
    displayChange = new EventEmitter();

    @Input()
    registerCustomerPaymentId;
    @Input()
    cardToken;

    echeckFunds: UntypedFormGroup;

    //paymentProfile;

    displayEsignatureDialog = false;

    submittedData = null;

    amounts = [];

    totalInvoices = 0;
    amountValue = '';
    invoiceNumbers = '';
    confirmedData: any = {};
    isCompleted: boolean = false;

    constructor(private fb: UntypedFormBuilder,
        private authService: AuthService,
        private paymentProfileService: PaymentProfileService) { }

    ngOnInit() {
        //this.paymentProfile();
        this.echeckFunds = this.fb.group({
            transferDate: [_moment().local().format('MM/DD/YYYY')],
            transferAmount: [],
            transferMemo: [],
        });

        this.echeckFunds.valueChanges.subscribe(e => {
            if (e) {
                //console.log('e form', e)
            }
        });
        this.generateTransferInvoiceData();
    }

    getPaymentProfile() {
        this.paymentProfileService.getPaymentProfile('E_CHECK', this.authService.getUserInfo().userId, 'AUTHORIZE_NET').subscribe(res => {
            if (res) {
                //this.paymentProfile = res;
            }
        })

    }

    showEsignature() {
        const transferObjects = this.echeckFunds.getRawValue();
        this.submittedData = {
            ownerId: this.authService.getUserInfo().userId,
            gatewayType: 'AUTHORIZE_NET',
            amount: 10,
            transferDate: transferObjects.transferDate ? _moment(transferObjects.transferDate) : _moment(),
            memo: transferObjects.transferMemo,
            fromCustomerId: this.registerCustomerPaymentId,
            fromCustomerProfileId: this.cardToken,
            //medicaidProviderNum: this.authService.get(),
            invoiceNumber: this.invoiceNumbers
        }
        this.displayEsignatureDialog = true;
    }

    closeCancelDialog(event) {
        this.confirmedData.response = event;
        this.confirmedData.invoice = this.echeckFunds.get('transferAmount').value;
        this.confirmedData.amount = this.totalInvoices;
        this.confirmedData.invoiceNumbers = this.invoiceNumbers;
        this.displayEsignatureDialog = false;
        // need to show confirmation
        this.isCompleted = true;
    }

    cancel(): void {
        this.displayChange.emit(true);
    }

    onBack() {
        this.displayChange.emit(true);
    }

    generateTransferInvoiceData() {
    }

    totalInvoice(values, event) {
        this.totalInvoices = 0;
        if (values && values.length > 0) {
            this.totalInvoices = values.map(v => v.total).reduce((sum, current) => sum + current);
            this.amountValue = values.map(v => v.total).join(',');
            this.invoiceNumbers = values.map(v => v.invoiceNumber).join(',');
        }
        if (event.value.length === this.amounts.length) {
            let x = document.getElementById('id_transferAmount_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            let x = document.getElementById('id_transferAmount_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }

}
