export enum PunchType {
    CLOCK_IN = 'CLOCK_IN',
    CLOCK_OUT = 'CLOCK_OUT',
    LUNCH_IN = 'LUNCH_IN',
    LUNCH_OUT = 'LUNCH_OUT',
    WRONG_PIN = 'WRONG_PIN'
}


export enum PunchDirection
{
    IN, OUT
}