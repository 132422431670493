import { Directive, OnInit } from "@angular/core";
import { Validator, AbstractControl, ValidatorFn, ValidationErrors, FormControl, NG_VALIDATORS } from "@angular/forms";

export function whitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value) {
      const isValid = !control.value.includes(' ');
      if (!isValid) {
        return {
          whitespace: true
        }
      }
      return null;
    }
  }
}

  @Directive({
    selector: '[no-whitespace][ngModel]',
    providers: [{
      provide: NG_VALIDATORS, useExisting: NoWhitespaceValidatorDirective, multi: true
    }]
  })
  export class NoWhitespaceValidatorDirective implements Validator {

    validate(c: AbstractControl) {
      return whitespaceValidator()(c);
    }
  }