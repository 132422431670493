<p-tabMenu [model]="JobFollowUpItems">
    <ng-template pTemplate="item" let-item>
        {{item.label | translate}}
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
        {{item.label | translate}}
    </ng-template>
</p-tabMenu>
<div class="ui-g" style="min-height: 640px;">
    <div class="ui-g-12 p-0">
        <div class="card add-shadow p-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>