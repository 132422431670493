<p-toast position="bottom-right">{{msgs}}</p-toast>
<form [formGroup]="payrollSettingsForm" #payrollForm [name]="payrollForm" class="ui-g-12">
    <p-panel class="p0">
        <p-header>
            <span>Payment Settings Details</span>
            <span class="pull-right ci-history" *ngIf="payrollSettingFormData.id" (click)="getHistory()">History</span>
        </p-header>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">
                        Pay Reference<span class="required-indicator">*</span> :
                    </label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <p-radioButton name="payReference" formControlName="payReference" value="1" label="Weekly"
                        [(ngModel)]="payrollSettingFormData.payReference" inputId="male"></p-radioButton>
                    <p-radioButton name="payReference" formControlName="payReference" value="0" label="Bi-weekly"
                        [(ngModel)]="payrollSettingFormData.payReference" inputId="female"></p-radioButton>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                            *ngIf="payrollSettingsForm.controls['payReference'].errors?.required && payrollSettingsForm.controls['payReference'].dirty ">Pay Reference
                                is required</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Mileage Price:</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <!-- <input currencyMask  type="text" pInputText formControlName="mileagePrice"
                        [(ngModel)]="payrollSettingFormData.mileage.mileagePrice">  -->
                </div>
            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">For:</label>
                </div>
                <!-- <div class="ui-g-4 ui-g-4">

                    <input type="text" pInputText formControlName="mile" [(ngModel)]="payrollSettingFormData.mileage.perMile">
                </div> -->
                <div class="ui-g-2 ui-g-2">
                    Mile(s)
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Round On:</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <p-dropdown [options]="roundOn" formControlName="roundOn" placeholder="Please Select"
                        [(ngModel)]="payrollSettingFormData.roundOn"></p-dropdown>
                </div>
            </div>

            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Mins:</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <p-dropdown [options]="mins" formControlName="mins" placeholder="Please Select"
                        [(ngModel)]="payrollSettingFormData.mins"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Payroll week-Ending Day<span class="required-indicator">*</span> :</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <p-dropdown [options]="days" formControlName="payrollWeekEnding" placeholder="Please Select"
                        [(ngModel)]="payrollSettingFormData.payrollWeekEnding"></p-dropdown>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                            *ngIf="payrollSettingsForm.controls['payrollWeekEnding'].errors?.required && payrollSettingsForm.controls['payrollWeekEnding'].dirty ">Payroll week-Ending Day
                                is required</span>
                    </div>
                </div>
            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Minimum Hours For Daily:</label>
                </div>
                <div class="ui-g-6 ui-g-6 p0">

                    <p-inputMask formControlName="minimumHourDaily" [(ngModel)]="payrollSettingFormData.minimumHourDaily" mask="99:99">

                    </p-inputMask>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                              *ngIf="payrollSettingsForm.controls['minimumHourDaily'].errors?.required && payrollSettingsForm.controls['minimumHourDaily'].dirty ">Minimum Hours is required</span>
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['minimumHourDaily'].errors?.pattern && payrollSettingsForm.controls['minimumHourDaily'].dirty">
                            Minimum Hours is format HH:mm, Max minutes is 59
                        </span>
                    </div>

                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">use Overtime<span class="required-indicator">*</span> :</label>

                </div>
                <div class="ui-g-6 ui-g-6">
                    <p-radioButton name="useOvertime" (onClick)="setDisable(true)" formControlName="useOvertime"
                        value="1" label="Yes" [(ngModel)]="payrollSettingFormData.useOvertime"></p-radioButton>
                    <p-radioButton name="useOvertime" (onClick)="setDisable(false)" formControlName="useOvertime"
                        value="0" label="No" [(ngModel)]="payrollSettingFormData.useOvertime"></p-radioButton>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                            *ngIf="payrollSettingsForm.controls['useOvertime'].errors?.required && payrollSettingsForm.controls['useOvertime'].dirty ">use Overtime
                                is required</span>
                    </div>
                </div>
            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Display Employee Name on Stub:</label>

                </div>
                <div class="ui-g-6 ui-g-6">
                    <p-checkbox name="displayEmployeeNameOnSub" formControlName="displayEmployeeNameOnSub"
                        (onChange)="EmployeeNameOnSub($event)" [(ngModel)]="displayEmployeeNameOnSub" binary="true">
                    </p-checkbox>
                </div>
            </div>
        </div>
        <!--<div class="ui-g" *ngIf="disableOvertime">-->
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Overtime After<span class="required-indicator">*</span> : </label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <!--<input type="text" pInputText formControlName="overtimeAfter" placeholder="Hrs"-->
                        <!--[(ngModel)]="payrollSettingFormData.overtimeAfter">-->

                    <p-inputMask formControlName="overtimeAfter" [(ngModel)]="payrollSettingFormData.overtimeAfter" mask="99:99">

                    </p-inputMask>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                            *ngIf="payrollSettingsForm.controls['overtimeAfter'].errors?.required && payrollSettingsForm.controls['overtimeAfter'].dirty ">Overtime
                            After is required</span>
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['overtimeAfter'].errors?.pattern && payrollSettingsForm.controls['overtimeAfter'].dirty">
                            Overtime After is format HH:mm, Max minutes is 59
                        </span>
                    </div>
                </div>

            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Frequency:</label>
                </div>
                <!-- <div class="ui-g-6 ui-g-6">
                    <p-dropdown [style]="{'width':'54%'}" [options]="frequency" formControlName="frequencyForOvertimeRate1"
                                [(ngModel)]="payrollSettingFormData.frequencyForOvertimeRate1"></p-dropdown>
                </div> -->
            </div>
        </div>

        <div class="ui-g" >
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Overtime Rate<span class="required-indicator">*</span> :</label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <input type="text" pInputText formControlName="overtimeMultipler"
                           [(ngModel)]="payrollSettingFormData.overtimeMultipler">
                    <div class="ui-g-12 ui-g-12">
                        (1.5 time the rate selected)
                    </div>
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error"
                            *ngIf="payrollSettingsForm.controls['overtimeMultipler'].errors?.required && payrollSettingsForm.controls['overtimeMultipler'].dirty ">Overtime Rate
                            is required</span>
                    </div>
                </div>
            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Lunch Time Duration:</label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <p-calendar [(ngModel)]="payrollSettingFormData.lunchTimeDuration" [timeOnly]="true" formControlName="lunchTimeDuration"
                                class="timer"></p-calendar>
                </div>
            </div>
        </div>
        
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Sick time Hours Caculation:</label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <input type="text" pInputText formControlName="sickTimeCalculationHour" placeholder="Hrs"
                        [(ngModel)]="payrollSettingFormData.sickTimeCalculationHour">
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['sickTimeCalculationHour'].errors?.pattern && payrollSettingsForm.controls['sickTimeCalculationHour'].dirty">only numbers</span>
                    </div>
                </div>
            </div>
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Sick time Caculation Every:</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <p-inputMask formControlName="sickTimeCalculationForEvery" [(ngModel)]="payrollSettingFormData.sickTimeCalculationForEvery" mask="99:99">

                    </p-inputMask>
                    <div class="ui-g-12 custom-error-message left-padding-0" *ngIf="payrollSettingFormData.sickTimeCalculationForEvery">
                        <span class="ui-message ui-messages-error"
                              *ngIf="payrollSettingsForm.controls['sickTimeCalculationForEvery'].errors?.required && payrollSettingsForm.controls['sickTimeCalculationForEvery'].dirty ">Minimum Hours is required</span>
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['sickTimeCalculationForEvery'].errors?.pattern && payrollSettingsForm.controls['sickTimeCalculationForEvery'].dirty">
                            Sick time Caculation Every is format HH:mm, Max minutes is 59
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Vacation Caculation Hours:</label>
                </div>
                <div class="ui-g-6 ui-g-6">
                    <input type="text" pInputText formControlName="vacationTimeCalculationHour" placeholder="Hrs"
                        [(ngModel)]="payrollSettingFormData.vacationTimeCalculationHour">
                    <div class="ui-g-12 custom-error-message left-padding-0">
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationTimeCalculationHour'].errors?.pattern && payrollSettingsForm.controls['vacationTimeCalculationHour'].dirty">only numbers</span>
                    </div>
                </div>
            </div>

            <div class="ui-g ui-g-6 p0">
                <div class="ui-g-6 ui-g-6">
                    <label class="pull-right">Vacation time Caculation Every:</label>
                </div>
                <div class="ui-g-6 ui-g-6">

                    <p-inputMask formControlName="vacationTimeCalculationForEvery" [(ngModel)]="payrollSettingFormData.vacationTimeCalculationForEvery" mask="99:99">

                    </p-inputMask>
                    <div class="ui-g-12 custom-error-message left-padding-0" *ngIf="payrollSettingFormData.vacationTimeCalculationForEvery">
                        <span class="ui-message ui-messages-error"
                              *ngIf="payrollSettingsForm.controls['vacationTimeCalculationForEvery'].errors?.required && payrollSettingsForm.controls['vacationTimeCalculationForEvery'].dirty ">Minimum Hours is required</span>
                        <span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationTimeCalculationForEvery'].errors?.pattern && payrollSettingsForm.controls['vacationTimeCalculationForEvery'].dirty">
                            Vacation time Caculation Every is format HH:mm, Max minutes is 59
                        </span>
                    </div>
                </div>

            </div>
        </div>

        <p-panel header="Other">
            <div class="ui-g">
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">Employee Late/After Hours</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <p-inputMask class="timer" formControlName="employeeLate" [(ngModel)]="payrollSettingFormData.employeeLateAfterHours" mask="99:99"></p-inputMask>
                        <label>After schedule Time</label>
                    </div>
                </div>

                <!-- <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">Lateness Decision</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <input type="number" pInputText formControlName="employeeLatenessDecision"
                            [(ngModel)]="payrollSettingFormData.employeeLatenessDecision" min="0">
                    </div>
                </div> -->
            </div>
            <div class="ui-g">
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">Alert Employee End Time:</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <p-calendar [(ngModel)]="payrollSettingFormData.alertEmployeeEndTime" [timeOnly]="true" formControlName="alertEmployeeEndTime"
                                    class="timer"></p-calendar>
                    </div>
                </div>
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">Frequency:</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <p-dropdown [style]="{'width':'54%'}" [options]="frequency" formControlName="frequency"
                            [(ngModel)]="payrollSettingFormData.frequency"></p-dropdown>
                    </div>
                </div>
            </div>

            <div class="ui-g">
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6">
                        <label class="pull-right">Send Alert to</label>
                    </div>
                    <div class="ui-g-6">
                        <p-multiSelect [disabled]="readOnly" class="d-inline-block w-100" [options]="dropDownPositions"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="payrollSettingFormData.sendAlertTo"
                            (onChange)="payrollSettingToDropDownChange($event)"
                            id="id_payrollSetting_to_selected_label"
                            [filter]="false" [style]="{'width':'54%'}"></p-multiSelect>
                    </div>
                </div>
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6">
                        <label class="pull-right">Allow Employee to see PTO</label>
                    </div>
                    <div class="ui-g-6">
                        <p-dropdown [style]="{'width':'54%'}" [options]="allowEmployeePTOs"
                            formControlName="allowEmployeePTO" placeholder="Please Select"
                            [(ngModel)]="payrollSettingFormData.allowEmployeePTO">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">Redeem Hours:</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <input type="number" pInputText formControlName="redeemHours"
                            [(ngModel)]="payrollSettingFormData.redeemHours" min="0" oninput="validity.valid||(value='');">
                    </div>
                </div>
                <div class="ui-g ui-g-6 p0">
                    <div class="ui-g-6 ui-g-6">
                        <label class="pull-right">On Time Rate:</label>
                    </div>
                    <div class="ui-g-6 ui-g-6">
                        <p-calendar [(ngModel)]="payrollSettingFormData.onTimeRate" placeholder="Hrs" [timeOnly]="true" formControlName="onTimeRate"
                                    class="timer"></p-calendar>
                    </div>
                </div>
            </div>
        </p-panel>
        <div class="ui-g">
            <div class="ui-g-12" *ngIf="displayHoliday">

                <div class="ui-g-12">
                    <p-table [value]="payrollHolidays" [lazy]="true" (onLazyLoad)="loadPayrollHolidayLazy($event)"
                        [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,20]" [totalRecords]="totalRecords" class="holiday-grid">
                        <ng-template pTemplate="caption">
                            <div class="ui-g">
                                <div class="ui-g-6">
                                    <label style="float: left">Payroll Holiday</label>
                                </div>
                                <div class="ui-g-6">
                                    <div class="pull-right">
                                        <button [disabled] = "readOnly" icon="fa-solid fa-plus" label="Add" pButton class="btn btn-primary"
                                            (click)="addPayrollHoliday()"></button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th width="5%"></th>
                                <th>Holiday Name</th>
                                <th>Holiday Date</th>
                                <th>Position</th>
                                <th>Department</th>
                                <th>Holiday Rate</th>

                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.id}}</td>
                                <td>
                                    <div *ngIf="rowData.holiday">
                                        {{rowData.holiday.holidayName}}
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="rowData.holiday">
                                        {{rowData.holiday.date | date: 'MM/dd/yyyy'}}
                                    </div>
                                </td>
                                <td>{{rowData?.positionNames}}</td>
                                <td>{{rowData?.departmentNames}}</td>
                                <td>{{rowData.holidayMultipler}}</td>

                                <td>
                                    <!-- <button [disabled] = "readOnly" pButton type="button" icon="fa-solid fa-pen-to-square"
                                        (click)="editPayrollHistory(rowData)"></button>
                                    <button [disabled] = "readOnly" pButton type="button" icon="fa-solid fa-xmark text-danger"
                                        (click)="deletePayrollHistory(rowData)"></button> -->
                                    <a label="Edit" style="margin-bottom:10px" class="" (click)="editPayrollHistory(rowData)">
                                        <i class="material-icons" style="color: #757575;">edit</i>
                                    </a>
                                    <a label="Delete" style="margin-bottom:10px" class="" (click)="deletePayrollHistory(rowData)">
                                        <i class="material-icons" style="color: #757575;">delete</i>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <div style="text-align: center;">

            <button pButton label="Submit" (click)="modifyPayrollSetting(payrollForm)" class="green-btn" *ngIf="mode !== 'view'"></button>
            <button pButton label="Edit" (click)="settingPayroll()" *ngIf="mode === 'view'"></button>
            <button pButton label="Cancel" (click)="onCancel()"></button>
        </div>
    </p-panel>

</form>
<p-dialog *ngIf="historyShow" [showHeader]="false" [modal]="true"
          [(visible)]="historyShow" [dismissableMask]="true"
          showEffect="fade" width="1000">
    <app-payroll-setting-history></app-payroll-setting-history>
</p-dialog>

<p-dialog *ngIf="historyShow"  [(visible)]="historyShow" 
 [closable]="true" showEffect="fade" 
     width="1000" modal="true"
     [showHeader]="false" dismissableMask="true" >
     <app-payroll-setting-history [payrollSettingId]="payrollSettingId" (onHistoryHide)="hideHistory()"> </app-payroll-setting-history>
</p-dialog>
