<div class="card add-shadow p-2">
	<div class="d-flex flex-wrap align-items-center justify-content-between w-100" style="row-gap: 5px">
		<div class="d-flex">
			<button *ngIf="showMenu" class="btn btn-primary mx-1" (click)="activities.toggle($event); onLoadActivityEvent(false)" type="button" pTooltip="{{'Menu' | translate}}" style="cursor:pointer !important">
				<i class="fa-solid fa-bars"></i>
			</button>
			<button class="btn btn-white mx-1">
				<span><i class="fa-solid fa-home text-primary" (click)="gotoHome()" pTooltip="{{'Home' | translate}}"></i></span>
			</button>
			<button class="btn btn-white mx-1" (click)="opBoard.toggle($event)">
				<span class="text-nowrap"><i class="fa-solid fa-sticky-note text-primary mr-2"></i>{{'Boards' | translate}}</span>
			</button>
			<button class="btn btn-white mx-1" (click)="addAction()" type="button" style="cursor:pointer !important" pTooltip="{{'Add List' | translate}}">
				<i class="fa-solid fa-plus text-primary"></i>
			</button>
			<div class="input-group flex-grow-1 mx-1" style="max-width: 350px">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
				<input (focus)="opTask.show($event)" [(ngModel)]="searchTermTask" (input)="onSearchInputChange($event)" placeholder="{{'Enter task name/ticket number' | translate}}" class="form-control" style="height: 40px !important">
                <span *ngIf="searchTermTask" class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="removeTermTask()">
                        <i class="fa-solid fa-xmark text-danger mx-1"></i>
                    </button>
                </span>
			</div>
		</div>
		<div class="d-flex flex-wrap flex-grow-1" style="row-gap: 5px">
			<ng-container [ngTemplateOutlet]="childTemplate"></ng-container>
		</div>
	</div>
</div>
<p-overlayPanel #opBoard [id]="'searchPanel'">
    <div class="opBoards">
        <input type="text" pInputText placeholder="{{'Find Board by Name' | translate}}" [(ngModel)]="searchTermBoard" />
        <div class="list-board p-4" style="padding-top: 0.5rem !important;">
            <ng-container *ngFor="let board of boards">
                <div class="search-task-result-item" *ngIf="board.name.includes(searchTermBoard)" (click)="gotoBoard(board)">
                    <div class="image border-all" style="background-image: url('{{board.background}}');"></div>
                    <div class="name">{{board.name}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>
<p-overlayPanel #opTask [id]="'searchPanel'">
    <div *ngIf="searchTermTask && !showResultSearchTask" class="text-center w-100 py-4">
        <div *ngIf="loadProject" class="w-100 d-flex justify-content-center">
            <mat-spinner></mat-spinner>
        </div>

        <span class="text-muted" *ngIf="!loadProject">{{'no data' | translate}}</span>
    </div>
    <div class="search-task-result" *ngIf="searchTermTask">
        <ng-container *ngFor="let board of filteredTaskBoard">
            <ng-container *ngFor="let task of board.matchingTask">
                <div class="search-task-result-item" (click)="openTask(task,board)">
                    <div *ngIf="task.background" class="image border-all" style="background-image: url('{{task?.background}}');"></div>
                    <div *ngIf="!task.background && board.background" class="image border-all" style="background-image: url('{{board.background}}');"></div>
                    <div *ngIf="!task.background && !board.background" class="image border-all" style="background-image: url('https://livestore.operrwork.com/operrwork/task_management/2024__1/2024_1_8__21_41_9__8__1704750068941_thumb_file.jpg');"></div>
                    <div>
                        <div>{{task.name}} #{{task.internalId}}</div>
                        <span class="text-muted text-sm">{{board.name}}</span>
                        <div class="text-muted text-sm">{{'Created At' | translate}}: {{task.createdAt | date: 'M/d/yyyy h:mm a'}}</div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</p-overlayPanel>
<p-overlayPanel #activities class="activity">
    <div [hidden]="sidebarTab!=''">
        <div class="font-weight-bold pt-2 text-center">
            <span>Menu</span>
            <i class="align-middle fa-solid fa-xmark" style="float: right"
                (click)="closeTimeSelector($event, activities)"></i>
        </div>
        <hr />
        <div>
            <div class="d-flex align-items-center c-pointer" (click)="changeSidebarTab('description')">
                <i class="fa-solid fa-file text-primary" style="width: 25px"></i><div>{{'About This Board' | translate}}</div>
            </div>
            <div class="d-flex align-items-center c-pointer" (click)="openPickBackground($event);activities.hide($event);">
				<i class="fa-solid fa-palette text-primary" style="width: 25px"></i><div>{{'Change Background' | translate}}</div>
            </div>
            <div class="d-flex align-items-center c-pointer" (click)="changeSidebarTab('search')">
				<i class="fa-solid fa-magnifying-glass text-primary" style="width: 25px"></i><div>{{'Search Card' | translate}}</div>
            </div>
            <div *ngIf="allowUnhide" class="d-flex align-items-center c-pointer" (click)="unhideList()">
				<i class="fa-solid fa-eye text-primary" style="width: 25px"></i><div>{{'Show Hidden Lists' | translate}}</div>
            </div>
        </div>
        <hr />
        <ng-container>
            <div>
                <span class="group-handle">&#x2630;</span>
                <span class="text-black-50 pl-2">{{'Activity' | translate}}</span>
            </div>
            <div class="search-task-result-activity text-center" *ngIf="isLoadingActivity">
        		<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
            </div>
            <div class="search-task-result-activity" *ngIf="!isLoadingActivity">
                <ng-container *ngFor="let activity of activityData">
                    <div class="search-task-result-item ui-g-12">
                        <div class="ui-g-1">
                            <app-card-member [member]="activity.userInfo"></app-card-member>
                        </div>
                        <div class="ui-g-11">
                            <div *ngIf="activity.type === commonCode.ADD_BOARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="text-black-50">{{activity.boardName}}</span>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.UPDATE_BOARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity.boardName}}</span>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.UPDATE_BG_BOARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity.boardName}}</span>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.DELETE_MEMBER" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity.memberName}}</span> to
                                    <a (click)="showTaskUrl(activity)" class="font-weight-bold">{{activity?.taskName}}</a>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_MEMBER" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity.memberName}}</span> to
                                    <a (click)="showTaskUrl(activity)" class="font-weight-bold">{{activity?.taskName}}</a>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_CARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <a (click)="showTaskUrl(activity)"
                                        class="font-weight-bold">{{activity?.taskName}}</a> to {{activity?.bucketName}}
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.UPDATE_CARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <a (click)="showTaskUrl(activity)"
                                        class="font-weight-bold">{{activity?.taskName}}</a> from {{activity?.bucketName}}
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.DELETE_CARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <a class="font-weight-bold">{{activity?.taskName}}</a> from
                                    {{activity?.bucketName}}
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_BUCKET" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity?.bucketName}}</span> to
                                    this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.DELETE_BUCKET" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity?.bucketName}}</span>
                                    from this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.MOVE_CARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <a (click)="showTaskUrl(activity)"
                                        class="font-weight-bold">{{activity?.taskName}}</a>
                                    from {{activity?.bucketPrev}} to {{activity?.bucketNext}}
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_MEMBER_BOARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity.memberName}}</span> to
                                    this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_DESCRIPTION_CARD" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <a (click)="showTaskUrl(activity)"
                                        class="font-weight-bold">{{activity.taskName}}</a> to this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_IMAGE" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">Image</span> to
                                    <a (click)="showTaskUrl(activity)" class="font-weight-bold">{{activity.taskName}}</a>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                                <div>
                                    <img style="width: 150px;cursor: pointer;" [src]="activity.content" />
                                </div>
                            </div>
                            <div *ngIf="activity.type === commonCode.ADD_COMMENT" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    on <a (click)="showTaskUrl(activity)" class="font-weight-bold">{{activity.taskName}}</a>
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                                <div [innerHTML]="activity.content" class="content-comment"></div>
                            </div>

                            <div *ngIf="activity.type === commonCode.HIDE_BUCKET" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity?.bucketName}}</span>
                                    from this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>

                            
                            <div *ngIf="activity.type === commonCode.UNHIDE_BUCKET" class="pl-3">
                                <div>
                                    <span class="font-weight-bold">{{activity?.createBy}}</span>
                                    {{activity.typeName}} <span class="font-weight-bold">{{activity?.bucketName}}</span>
                                    from this board
                                </div>
                                <div class="comment-time">{{activity.createdAt | date:'short'}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="d-flex justify-content-center w-100">
                    <button *ngIf="viewMore" type="button" class="btn btn-white" (click)="onLoadActivityEvent(true)"><i class="fa-solid fa-chevron-down mr-2"></i>{{viewMore}}</button>
                </div>
            </div>
        </ng-container>
        

    </div>
    <div [hidden]="sidebarTab!='search'">
        <div class="font-weight-bold pt-2 text-center">
            <i class="fa-solid fa-chevron-left align-middle float-left" (click)="changeSidebarTab('')" aria-hidden="true"></i>
            <span>Search Card</span>
            <i class="align-middle fa-solid fa-xmark" style="float: right"
                (click)="closeTimeSelector($event, activities)"></i>
        </div>
        <hr />
        <div class="search-task-result-activity">
            <input [(ngModel)]="seachTerm.name" (ngModelChange)="onSearchChange()" pInputText />
            <label>Search by term, label, member, or due time.</label>
            <hr />
            <div class="sidebar-label" (click)="selectLabel(0)">
                <div class="d-flex">
                    <div [style.background]="'#b3bac5'" class="sidebar-label__color">
                    </div>
                    <div class="sidebar-label__name">
                        No labels
                    </div>
                </div>
                <i *ngIf="seachTerm.labels.includes(0)" class="material-icons selected">done</i>
            </div>
            <div *ngFor="let label of labels" class="sidebar-label" (click)="selectLabel(label.id)" [hidden]="label.hide">
                <div class="d-flex">
                    <div [style.background]="label.color" class="sidebar-label__color">
                    </div>
                    <div class="sidebar-label__name">
                        {{label.name}}
                    </div>
                </div>
                <i *ngIf="seachTerm.labels.includes(label.id)" class="material-icons selected">done</i>
            </div>
            <hr />
            <div *ngFor="let type of TICKET_TYPE | keyvalue" class="sidebar-label" (click)="selectTicketType(type.key)">
                <div class="d-flex">
                    <img *ngIf="type.key === 'EPIC'" style="width: 25px; height: 25px;" src="assets/images/ticket_type_epic.jpg">
                    <img *ngIf="type.key === 'STORY'" style="width: 25px; height: 25px;" src="assets/images/ticket_type_story.jpg">
                    <img *ngIf="type.key === 'BUG'" style="width: 25px; height: 25px;" src="assets/images/ticket_type_bug.jpg">
                    <img *ngIf="type.key === 'NONE'" style="width: 25px; height: 25px;" >
                    <div class="sidebar-label__name">
                        {{type.value | titlecase}}
                    </div>
                </div>
                <i *ngIf="seachTerm.ticketTypes.includes(type.key)" class="material-icons selected">done</i>
            </div>
            <hr />
            <div class="sidebar-member" (click)="selectMember(0)">
                <div class="d-flex align-items-center">
                    <app-card-member [member]="noMember" [allMember]="members"></app-card-member>
                    <div class="sidebar-member__name">
                        No Members
                    </div>
                </div>
                <i *ngIf="seachTerm.members.includes(0)" class="material-icons selected">done</i>
            </div>
            <div *ngFor="let member of members" class="sidebar-member" (click)="selectMember(member.id)" [hidden]="member.hide">
                <div class="d-flex align-items-center">
                    <app-card-member [member]="member" [allMember]="members"></app-card-member>
                    <div class="sidebar-member__name">
                        {{member.name}}
                    </div>
                </div>
                <i *ngIf="seachTerm.members.includes(member.id)" class="material-icons selected">done</i>
            </div>
            <hr />
            <div class="sidebar-member" *ngFor="let option of searchByDateOptions" (click)="selectSearchDate(option.type)">
                <div class="d-flex align-items-center">
                    <div class="sidebar-member__name">
                       {{option.title}}
                    </div>
                </div>
                <i *ngIf="seachTerm.date == option.type" class="material-icons selected">done</i>
            </div>
        </div>
    </div>
    <div [hidden]="sidebarTab!='description'">
        <div class="font-weight-bold pt-2 text-center">
            <i class="fa-solid fa-chevron-left align-middle float-left" (click)="changeSidebarTab('')" aria-hidden="true"></i>
            <span>Description</span>
            <i class="align-middle fa-solid fa-xmark" style="float: right"
                (click)="closeTimeSelector($event, activities)"></i>
        </div>
        <hr />
        <div class="search-task-result-activity">
            Under Development
        </div>
    </div>
</p-overlayPanel>