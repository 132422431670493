<h1 class="m-0 ml-2 my-4">{{mode == 'create' ? ('Add Log' | translate) : mode == 'view' ? ('Log Details' | translate) : ('Edit Log' | translate) }}</h1>
<p-panel [toggleable]="true">
    <p-header class="flex-grow-1">
		<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Client Log Details' | translate}}</span>
    </p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'Company' | translate}}<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown filter="true" [options]="allCompanyList" optionLabel="value" dataKey="key" [ngModelOptions]="{'standalone':true}" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()" [disabled]="disableInput">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Period' | translate}}<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker" [max]="maxDate">
						<input matStartDate matInput [(ngModel)]="jobFollowUp.fromDate" name="Startdate" (focus)="picker.open()" [disabled]="disableInput">
						<input matEndDate matInput [(ngModel)]="jobFollowUp.toDate" name="Enddate" (focus)="picker.open()" [disabled]="disableInput">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
			<span class="text-danger" *ngIf="!jobFollowUp.fromDate && !jobFollowUp.toDate && showErr" style="font-size: 11px !important">{{'This field is required' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12" >
			<label>{{'Cycle' | translate}}<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<p-dropdown [options]="cycle" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]='false' [(ngModel)]="jobFollowUp.cycle" [ngModelOptions]="{standalone: true}" (onChange)="changeCycle()" [disabled]="disableInput">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
			<span class="text-danger" *ngIf="!jobFollowUp.cycle && showErr" style="font-size: 11px">{{'validate.thisField' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Customize column' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-table-columns"></i>
					</button>
				</div>
				<p-multiSelect  [disabled]="disableInput" [options]="customizeColumns" [(ngModel)]="customColumnName" defaultLabel="{{'Select Items' | translate}}" [ngModelOptions]="{standalone: true}" [filter]="false" (onChange)="onSelectColumn($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div  class="ui-g-6 ui-sm-12">
			<label>{{'Support' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-life-ring"></i>
					</button>
				</div>
				<p-multiSelect  [options]="supportEmployees" [(ngModel)]="supportSearch" [ngModelOptions]="{standalone: true}" defaultLabel="{{'Select Items' | translate}}" [filter]="false" (onChange)="onSelectEmployee($event)"  [disabled]="disableInput">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
    </div>
</p-panel>
<div class="d-flex justify-content-between align-items-center py-4">
	<h1 class="m-0 ml-2">{{'Billing Clients' | translate}}</h1>
	<button class="btn btn-primary"(click)="addJobDetails()" [disabled]="disableInput" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel [toggleable]="true" id="table-ppanel">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Clients' | translate}}</span>
	</p-header>
	<div class="d-flex justify-content-between align-items-center p-3 pt-2" style="row-gap: 10px">
		<div class="d-flex align-items-center">
			<button type="button" class="float-right btn btn-primary mx-1" (click)="exportPdf()">
				<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
			</button>
		</div>
		<div class="input-group w-100" style="max-width: 400px">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button">
					<i class="fa-solid fa-filter"></i>
				</button>
			</div>
			<input type="text" pInputText placeholder="{{'Filter' | translate}}" [(ngModel)]="searchText" (ngModelChange)="search()">
		</div> 
	</div>
	<div class="ui-g" *ngIf="displaySaveUp">
		<div class="ui-g-12 text-center pt-4">
			<button label="Edit" *ngIf="mode === 'view'" (click)="enableForm()"></button>
			<button label="Save & Close" *ngIf="mode !== 'view'" (click)="saveJobDetails(true)" [disabled]="isValid() || disableButton"></button>
			<button label="Save" *ngIf="mode !== 'view'" (click)="saveJobDetails(false)" [disabled]="isValid()"></button>
			<button label="Cancel" routerLink="/app/job-follow-up/list"></button>
		</div>
	</div>

	<div class="card add-shadow m-4 p-0" *ngFor="let job of getTotalJobDetailsView();index as i">
		<div class="card-header d-flex justify-content-between align-items-center py-2 px-3">
			<span><i class="fa-solid fa-briefcase text-primary mr-2"></i>{{'Client' | translate}} {{i+1}}</span>
			<span>
				<span *ngIf="mode !== 'create'" (click)="openHistory(job.id)" class="mr-3" style="cursor:pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
				<i *ngIf="i > 0" (click)="showConfirmDialog(i, job.id)" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" style="cursor:pointer !important;font-size:16px"></i>
			</span>
		</div>
	
		<div *ngIf="!job.hide" class="d-flex align-items-center flex-wrap pb-3 px-3">
			<div *ngIf="job.showCreatedOn" class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Created' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<input *ngIf="job.createdAt" type="text" pInputText [ngModel]="job.createdAt | date: 'M/d/yyyy'" readonly>
					<input *ngIf="!job.createdAt && createdOn" type="text" pInputText [ngModel]="createdOn | date: 'M/d/yyyy'" readonly>			
					<input *ngIf="!job.createdAt && !createdOn" type="text" pInputText placeholder="{{'no data' | translate}}" disabled>	
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Base' | translate}}<span class="text-danger">*</span>:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [options]="clientList" filter="true" [styleClass]="!job.clientId && showErrorMsg? 'input-require' : ''" [(ngModel)]="job.clientId" [ngModelOptions]="{standalone: true}" (onChange)="setOtherfields(i)" [disabled]="disableInput" appendTo="body" style="flex-grow:1 !important;">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | truncate:[12] }}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!job.clientId && showErrorMsg">{{'This field is required' | translate}}</span>
			</div>

			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Biller' | translate}}<span class="text-danger">*</span>:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<p-dropdown [options]="billerEmployees" filter="true" style="flex-grow:1 !important"
								[styleClass]="!job.billerId && showErrorMsg? 'input-require' : ''" 
								[(ngModel)]="job.billerId" [ngModelOptions]="{standalone: true}" [disabled]="disableInput"
								(onChange)="onChange()" [autoDisplayFirst]="false"
								appendTo="body">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | truncate:[12] }}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!job.billerId && showErrorMsg">{{'This field is required' | translate}}</span>
			</div>

			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Support' | translate}}<span class="text-danger">*</span>: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-life-ring"></i>
						</button>
					</div>
					<p-dropdown [options]="supportEmployees" filter="true" [styleClass]="!job.supportId && showErrorMsg? 'input-require' : ''" [(ngModel)]="job.supportId" [ngModelOptions]="{standalone: true}" [disabled]="disableInput" (onChange)="onChange()" appendTo="body" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | truncate:[12] }}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!job.supportId && showErrorMsg">{{'This field is required' | translate}}</span>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12" *ngIf="job.showCycle">
				<label>{{'Job Cycle' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<input *ngIf="job.cycle" type="text" pInputText [ngModel]="job.cycle" readonly>			
					<input *ngIf="!job.cycle" type="text" pInputText placeholder="{{'no data' | translate}}" disabled>	
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Contact' | translate}}<span class="text-danger">*</span>: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-contact-book"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="job.contactName" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChange()" [disabled]="disableInput" [ngClass]="{'ui-dropdown' : (!job.contactName && showErrorMsg)}">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!job.contactName && showErrorMsg">{{'This field is required' | translate}}</span>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12" *ngIf="job.showNumber">
				<label>{{'Contact Number' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-phone display-dot"></i>
						</button>
					</div>
					<input *ngIf="job.contactNumber" type="text" pInputText [ngModel]="job.contactNumber" readonly>			
					<input *ngIf="!job.contactNumber" type="text" pInputText placeholder="{{'no data' | translate}}" disabled>	
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12" *ngIf="job.showEmail1">
				<label>{{'Email ID 1' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag display-dot"></i>
						</button>
					</div>
					<input *ngIf="job.emailId1" type="text" pInputText [ngModel]="job.emailId1" readonly>			
					<input *ngIf="!job.emailId1" type="text" pInputText placeholder="{{'no data' | translate}}" disabled>	
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12" *ngIf="job.showEmail2">
				<label>{{'Email ID 2' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag display-dot"></i>
						</button>
					</div>
					<input *ngIf="job.emailId2" type="text" pInputText [ngModel]="job.emailId2" readonly>			
					<input *ngIf="!job.emailId2" type="text" pInputText placeholder="{{'no data' | translate}}" disabled>	
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Follow Up' | translate}}<span class="text-danger">*</span> : </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [options]="dropdownLabel" [styleClass]="!job.followUp && showErrorMsg? 'input-require' : ''" [(ngModel)]="job.followUp" [ngModelOptions]="{standalone: true}" [disabled]="disableInput" (onChange)="onChange()" appendTo="body" [autoDisplayFirst]="false">
						<ng-template pTemplate="emptymessage">
							<span class="text-truncate text-light px-3">{{'Select' | translate}}</span>
						</ng-template>
						<ng-template pTemplate="item" let-item>
							<span [style.background-color]="item.color" class="text-truncate text-light px-1">{{item.label}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span [style.background-color]="item.color" class="one-liner text-light px-1">{{item.label }}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!job.followUp && showErrorMsg">{{'This field is required' | translate}}</span>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12">
				<label>{{'Status Date' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<input matInput (focus)="picker1.open()" [matDatepicker]="picker1" name="submissionDate" [disabled]="readOnly" [ngModelOptions]="{standalone: true}" [disabled]="disableInput" (ngModelChange)="onChange()" utc="true" [(ngModel)]="job.statusDate">
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-2 ui-md-6 ui-sm-12" *ngIf="job.showNote">
				<label>{{'Note' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-sticky-note"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="job.note" (ngModelChange)="onChange()" [ngModelOptions]="{standalone: true}" [disabled]="disableInput" maxlength="100" autoResize="true" [rows]="1">
				</div>
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-center">
		<button class="btn btn-white" (click)="addJobDetails()" [disabled]="disableInput"><i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}</button>
	</p-footer>
</p-panel>
<div class="ui-g">
	<div class="ui-g-12 text-center pt-4">
		<button [routerLink]="'/app/job-follow-up/list'" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{ 'Cancel' | translate}}</button>
		<button *ngIf="mode === 'view'" (click)="enableForm()" class="btn btn-info mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i>{{ 'Edit' | translate}}</button>
		<button *ngIf="mode !== 'view'" (click)="saveJobDetails(false)" class="btn btn-purple mx-1" [disabled]="!isCheckSave"><i class="fa-solid fa-floppy-disk mr-2"></i>{{ 'Save' | translate}}</button>
		<button *ngIf="mode !== 'view'" (click)="saveJobDetails(true)" class="btn btn-primary mx-1" [disabled]="isValid() || disableButton"><i class="fa-solid fa-check mr-2"></i>{{ 'Submit' | translate}}</button>
	</div>
</div>
<!-- <p-overlayPanel #opLabel appendTo="body"> -->
<p-overlayPanel #opLabel appendTo="body">
    <div class="loading-label" *ngIf="loadingLabel">
        <p-progressSpinner class="center-spin"></p-progressSpinner>
    </div>
    <div *ngIf="!showLabelForm">
        <div style="padding: 0px;">{{'Labels' | translate}}: </div>
        <div style="padding: 0px;" class="panel-label-list" style="color:white;max-height: 180px;overflow-y: scroll;">
            <div class="panel-label-item" *ngFor="let label of allLabel" [style.background]="label.color"
                style="padding:3px;margin-bottom:4px">
                {{label.labelName}}
<!--                <i class="material-icons selected" *ngIf="label.checked">done</i>-->
                <i class="material-icons edit-btn" (click)="openEditLabel(label)" style="float:right">edit</i>
            </div>
        </div>
        <div class="btn-add-label" (click)="openAddNewLabel()">
			{{'Create a new label' | translate}}
        </div>
    </div>
    <div *ngIf="showLabelForm">
        <input type="text" class="form-control" [(ngModel)]="selectedLabel.labelName"
            style="font-size: 14px; padding-right: 30px;margin-top: 3px;margin-bottom: 3px;" placeholder="{{'label name' | translate}}" />

        <div style="padding-bottom: 3px;padding: 0px;"> {{'Select a color' | translate}} </div>
        <div style="padding: 0px;" class="d-flex-wrap">
            <div class="new-label-color-item" *ngFor="let labelColor of listLabelColor" [style.background]="labelColor"
                (click)="selectedLabel.color=labelColor">
                <i class="material-icons selected" *ngIf="labelColor===selectedLabel.color">done</i>
            </div>
        </div>
        <div class="label-form-action">
            <ng-container *ngIf="!selectedLabel.id">
                <p-button label="{{'Cancel' | translate}}" (click)="showLabelForm=false"></p-button>
                <p-button label="{{'Create' | translate}}" [disabled]="!selectedLabel.labelName" (click)="createLabel()"></p-button>
            </ng-container>
            <ng-container *ngIf="selectedLabel.id">
                <p-button label="{{'Delete' | translate}}" (click)="deleteLabel()"></p-button>
                <p-button label="{{'Update' | translate}}" [disabled]="!selectedLabel.labelName" (click)="createLabel('update')">
                </p-button>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>

<p-dialog *ngIf="historyShow" [modal]="true" [(visible)]="historyShow" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'85vh'}" [dismissableMask]="true" class="modal_in">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
    <app-job-follo-up-history (onHideHistory)="hideHistory()" [jobId]="selectedJobId"  [clientList]="clientList" [billerEmployees]="billerEmployees" [supportEmployees]="supportEmployees"></app-job-follo-up-history>
	<p-footer class="d-flex justify-content-end">
		<button  class="btn btn-primary" (click)="historyShow = false">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="displayConfirmDialog" [draggable]="false" [resizable]="false" [style]="{'width': '90%', 'max-width':'500px'}" >
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
   	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
	</div>
 	<p-footer class="d-flex justify-content-between">
		<button (click)="displayConfirmDialog=0; resetHighlight(displayConfirmDialog)"  class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button *ngIf="displayConfirmDialog != 0" (click)="removeJobDetail(displayConfirmDialog);displayConfirmDialog=0;" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>

