import {Injectable} from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {PayrollSettingHistory} from "../model/payroll-setting-history.model";
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PayrollSettingHistoryService extends AbstractService<PayrollSettingHistory> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PAYROLL_SETTING, '');
  }
  getPayrollSettingListHistory(entity) {
      return this._http.post(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/other-history/search`, entity).pipe(map(res => res));
  }

}
