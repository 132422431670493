import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Message} from "primeng/api";

@Injectable({ providedIn: 'root' })
export class CommService {
    private announcedSource = new Subject();
    announced$ = this.announcedSource.asObservable();
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    executeCmd(cmd: string, data?: any) {
        this.announcedSource.next({cmd: cmd, data: data});
    }
    showSpin() {
        this.executeCmd('show_spin');
    }
    hideSpin() {
        this.executeCmd('hide_spin');
    }

    notifyMessage(message: Message) {
        this.messageSubject.next(message);
    }
}
