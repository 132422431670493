<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="ui-g">
    <div class="ui-g-12">
        <button pButton label="Back" (click)="cancel()"></button>
    </div>
</div>
<p-card *ngIf="!cardToken">
    <form [formGroup]="echeckCardForm">
        <p-panel header="Profile Information">
            <div class="ui-g ui-fluid">
                <div class="ui-g-12">
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            Profile Name:
                        </div>
                        <div class="ui-g-9">
                            <input pInputText formControlName="profileName"/>
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
        <br/>
        <p-panel header="Account Information">
            <div class="ui-g ui-fluid">
                <div class="ui-g-12">
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            Holder's Name:
                        </div>
                        <div class="ui-g-9">
                            <input pInputText formControlName="accountName"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            Account Type:
                        </div>
                        <div class="ui-g-9">
                            <p-dropdown
                                    [options]="accountTypes"
                                    formControlName="accountType"
                                    styleClass="w-100"
                                    [autoDisplayFirst]="false"
                                    [showClear]="true">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            Routing Number:
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <input pInputText formControlName="roundNumber" (change)="verify()"/>
                                <span class="ui-inputgroup-addon"><i class="fa-solid fa-check" style="color: green;" *ngIf="'valid' === roundNumberValid"></i></span>
                                <span class="ui-inputgroup-addon"><i class="fa-solid fa-xmark" style="color: red;" *ngIf="'invalid' === roundNumberValid"></i></span>
                                <span class="ui-inputgroup-addon"><i class="material-icons">help</i></span>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            Account Number:
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <input pInputText formControlName="accountNumber"/>
                                <span class="ui-inputgroup-addon"><i class="material-icons">help</i></span>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-3"></div>
                        <div class="ui-g-9">
                            NOTE: Account number re-entry required for any profile modification.
                            Current Account number is ending with7648
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
        <br/>
        <p-panel header="Billing Address Information">
            <div class="ui-g ui-fluid">
                <div class="ui-g-12">
                    <div class="ui-g-12">
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2">
                            Address 1:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="address1"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2">
                            (optional) Address 2:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="address2"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2"></div>
                        <div class="ui-g-2">
                            City:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="city"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                            For U.S. Address
                        </div>
                        <div class="ui-g-2">
                            State:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="state"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                        </div>
                        <div class="ui-g-2">
                            Zip:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="zipcode"/>
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
        <br/>
        <p-panel header="Contact Information">
            <div class="ui-g ui-fluid">
                <div class="ui-g-12">
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                        </div>
                        <div class="ui-g-2">
                            Daytime Phone:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="phone"/>
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                        </div>
                        <div class="ui-g-2">
                        </div>
                        <div class="ui-g-7">
                            e.g. (555) 555-1212x123 OR +31421234567
                        </div>
                    </div>
                    <div class="ui-g-12">
                        <div class="ui-g-2">
                        </div>
                        <div class="ui-g-2">
                            Email Address:
                        </div>
                        <div class="ui-g-7">
                            <input pInputText formControlName="email"/>
                        </div>
                    </div>
                </div>
            </div>
        </p-panel>
        <div class="ui-g ui-fluid">
            <div class="ui-g-12" style="text-align: center;">
                <p-button label="Save" style="margin-right: 10px;" (onClick)="addPaymentMethod()"></p-button>
                <p-button label="Cancel" (onClick)="cancel()"></p-button>
            </div>
        </div>
    </form>
</p-card>

<app-echeck-funds *ngIf="cardToken" (displayChange)="cancel()" [registerCustomerPaymentId]="registerCustomerPaymentId" [cardToken]="cardToken"></app-echeck-funds>
