import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, OverlayPanel, Table } from 'primeng';
import { AuthService } from '../../../../shared/service/auth/auth.service';
import { BillingLogTotalJobService } from '../../service/billing-log-total-job.service';
import { BillingUploadHistoryService } from '../../service/billing-upload-history.service';
import * as _moment from 'moment';
import moment from 'moment';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { DropDownsService } from '../../../../shared/service/drop-downs/drop-downs.service';
import { ClientService } from '../../../service/client.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { Subscription } from 'rxjs';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { DatePipe } from '@angular/common';
import { TranslatePipe } from "@ngx-translate/core";
import { UploadEvent } from '../../model/upload-event.model';
@Component({
  selector: 'app-billing-log-total-job',
  templateUrl: './billing-log-total-job.component.html',
  styleUrls: ['./billing-log-total-job.component.scss'],
  providers: [BillingLogTotalJobService]
})
export class BillingLogTotalJobComponent implements OnInit {
  showProgressBar = false;
  showProgressReasonFail = false;
  showProgressBarPage = false;
  showProgressBarForNote = false;
  showProgressBarOnInitialLoad = false;
  showProcessComplete = false;
  progressBarValue = 0;
  excel: {
    csv2json: any;
    excel2json: any;
    json2excel: any;
  };
  page: number;
  size: number;
  notSavedCount: number;
  loading: boolean = true;
  companySelected: any;
  uploadDateSelected: any;
  uploadDateList: any;
  isPlatformAdmin: any;
  companies: any[];
  totalRecords: number = 0;
  billingReports: any[];
  showConfirmDeleteDialog = false;
  selectedVideo: any;
  fromDate: Date;
  toDate: Date;
  createdByList: any[];
  createdSelected: any;
  showProcessUpdateComplete = false;
  previousUpdatedDate: any;
  totalUpdatedRecords: number = 0;
  totalNewRecords: number = 0;
  isPreviousUpdatedDate = false;
  reminderData: any[];
  searchText: any;
  colsAll = [
    { label: '#', field: 'id', customizeColumn: true, show: true, sortOptions: '', sort: 'number' },
    { label: 'Fleet#', field: 'fleet', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Date', field: 'serviceDate', customizeColumn: true, show: true, sortOptions: '', sort: 'text' },
    { label: 'Name', field: 'name', customizeColumn: true, show: true, sort: 'text' },
    { label: 'CIN', field: 'cin', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Trip', field: 'tripNumber', customizeColumn: true, show: true, sort: 'text' },
    { label: 'P/U Address', field: 'pickupAddress', customizeColumn: true, show: false, sort: 'text' },
    { label: 'P/U City', field: 'pickupCity', customizeColumn: true, show: false, sort: 'text' },
    { label: 'P/U State', field: 'pickupState', customizeColumn: true, show: false, sort: 'text' },
    { label: 'P/U Zip', field: 'pickupZip', customizeColumn: true, show: false, sort: 'text' },
    { label: 'D/O Address', field: 'dropoffAddress', customizeColumn: true, show: false, sort: 'text' },
    { label: 'D/O City', field: 'dropoffCity', customizeColumn: true, show: false, sort: 'text' },
    { label: 'D/O State', field: 'dropoffState', customizeColumn: true, show: false, sort: 'text' },
    { label: 'D/O Zip', field: 'dropoffZip', customizeColumn: true, show: false, sort: 'text' },
    { label: 'Mileage', field: 'tripMileage', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Tolls', field: 'tollFee', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Price', field: 'tripPrice', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Added By', field: 'createdByUsr', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Uploaded', field: 'createdAt', customizeColumn: true, show: true, sort: 'text' },
    { label: 'Actions', field: 'actions', customizeColumn: false, show: true, sort: 'text' }
  ];

  cols = [];

  textSortOptionsStock = [
    { name: 'Sort A to Z', value: 'ASC', img: 'a-z' },
    { name: 'Sort Z to A', value: 'DESC', img: 'z-a' }
  ];
  numberSortOptionsStock = [
    { name: 'Sort 0 to 9', value: 'ASC', img: 'a-z' },
    { name: 'Sort 9 to 0', value: 'DESC', img: 'z-a' }
  ];
  @ViewChild('dt', { static: true }) table: Table;
  selectedSortOption: string = 'ASC';
  selectedField: string = 'pickUpDate';
  clients: any[];
  clientList: any[];
  clientSelected: any;
  showUploaded: boolean = false;
  clientSelectedUpload: any;
  totalJobId: number;
  showConfirmDialog: boolean;
  showUpdateBillingConfirmDialog: boolean;
  isPermitted: boolean = false;
  isChooseClient: boolean = true;
  isChooseClientUpload: boolean = true;
  downloadEnable: boolean = false;
  sortField: string;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();
  displayMessage: boolean = false;
  customColumnName: any = [];
  customizeColumns = [];
  totalFileUpload = 0;
  uploadedFiles: any = [];

  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private messageService: MessageService, private clientService: ClientService,
    private billingLogTotalJobService: BillingLogTotalJobService,
    private billingUploadHistoryService: BillingUploadHistoryService,
    private employeeService: EmployeeService,
    private router: Router,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    private monitoringDetailsService: MonitoringDetailsService) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-log-total-job') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to LOG Total Job`,
            this.timeSpent,
            {
              navigated_to_log_total_job_page_by: this.authService.isClientRole() ?
                this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Log LOG Total Job`,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.excel = require('assets/libs/js2excel.min.js');
    this.loading = true;
    this.isPermitted = this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole() || this.authService.isCompanyAd();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.loadAllCompanyList();
    this.getClientList();
    if (this.authService.isClientRole() || this.authService.isChildClientRole()) {
      if (this.authService.isClientRole()) {
        this.clientSelected = this.authService.getUserInfo().adminId;
        this.clientSelectedUpload = this.authService.getUserInfo().adminId;
      } else {
        this.clientSelected = this.authService.getUserInfo().crmClientId;
        this.clientSelectedUpload = this.authService.getUserInfo().crmClientId;
      }

      this.isChooseClient = false;
      this.isChooseClientUpload = false;
      this.downloadEnable = true;
      this.colsAll = [
        { label: '#', field: 'id', customizeColumn: true, show: true, sortOptions: '', sort: 'number' },
        { label: 'Fleet#', field: 'fleet', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Date', field: 'serviceDate', customizeColumn: true, show: true, sortOptions: '', sort: 'text' },
        { label: 'Name', field: 'name', customizeColumn: true, show: true, sort: 'text' },
        { label: 'CIN', field: 'cin', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Trip', field: 'tripNumber', customizeColumn: true, show: true, sort: 'text' },
        { label: 'P/U Address', field: 'pickupAddress', customizeColumn: true, show: false, sort: 'text' },
        { label: 'P/U City', field: 'pickupCity', customizeColumn: true, show: false, sort: 'text' },
        { label: 'P/U State', field: 'pickupState', customizeColumn: true, show: false, sort: 'text' },
        { label: 'P/U Zip', field: 'pickupZip', customizeColumn: true, show: false, sort: 'text' },
        { label: 'D/O Address', field: 'dropoffAddress', customizeColumn: true, show: false, sort: 'text' },
        { label: 'D/O City', field: 'dropoffCity', customizeColumn: true, show: false, sort: 'text' },
        { label: 'D/O State', field: 'dropoffState', customizeColumn: true, show: false, sort: 'text' },
        { label: 'D/O Zip', field: 'dropoffZip', customizeColumn: true, show: false, sort: 'text' },
        { label: 'Mileage', field: 'tripMileage', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Tolls', field: 'tollFee', customizeColumn: true, show: true, sort: 'text' },
      ];

      this.cols = this.colsAll.filter(item => !item.customizeColumn || item.show);
    } else {
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }

    this.customizeColumns = this.colsAll.filter(c => c.customizeColumn).map(c => {
      return {
        ...c,
        value: c.field
      };
    });
    this.customColumnName = this.colsAll.filter(item => item.show).map(c => c.field);
    this.cols = this.colsAll.filter(item => !item.customizeColumn || item.show);

    // this.search();
    this.getCreatedByList();
    this.loadUploadedDateList();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
  }

  loadBillingReports(event: LazyLoadEvent) {
    this.loading = true;
    const page = (event.first) / event.rows;
    const option = {
      page: page, size: event.rows
    };
    this.loadPage(option);
  }

  search(event?: any) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && typeof event.first !== "undefined" && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options = {
      size: this.size,
      page: this.page,
      companyId: null
    };
    this.loadPage(options);
  }

  accept() {
    this.billingLogTotalJobService.delete(this.totalJobId).subscribe((_response) => {
      this.search();
      this.messageService.add({
        severity: 'success',
        summary: this.translatePipe.transform('Success'),
        detail: this.translatePipe.transform('Deleted Successfully')
      });
      this.showConfirmDialog = false;
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('Delete Failed')
      });
    });
  }

  reject() {
    this.showConfirmDialog = false;
  }

  deleteRecord(data) {
    if (!this.isPermitted) {
      const showHideActions = (moment(moment().toDate()).diff(data.createdAt, 'minutes') >= 1440);
      if (showHideActions) {
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Information'),
          detail: this.translatePipe.transform(`Sorry you cannot delete the selected record after 24hrs`)
        });
        return;
      }
    }
    this.totalJobId = data.id;
    this.showConfirmDialog = true;
  }

  loadPage(options: any) {
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.startDate = _moment(this.fromDate, 'YYYY-MM-DD HH:mm:ss').toDate().getTime();
    if (this.toDate) options.endDate = _moment(this.toDate, 'YYYY-MM-DD HH:mm:ss').toDate().getTime();
    if (this.uploadDateSelected) {
      options.startDateUpdated = this.uploadDateSelected;
      options.endDateUpdated = this.uploadDateSelected;
    }
    if (this.createdSelected) options.createdBy = this.createdSelected;
    if (this.clientSelected) options.clientId = this.clientSelected;
    if (this.searchText) {
      options.key = this.searchText;
    }
    this.billingLogTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.billingReports.forEach(item => {
          if (item.createdAt !== item.updatedAt) {
            item.lastUpdated = item.updatedAt;
          } else {
            item.lastUpdated = '';
          }
          item.serviceDate = moment.utc(item.serviceDate).toDate();
        });

        this.sortingClick(this.selectedSortOption);
        this.totalRecords = resObj.data.totalElements;
        this.loading = false;
      }
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.createdSelected = null;
    this.uploadDateSelected = null;
    this.searchText = null;
    if (!this.authService.isClientRole() && !this.authService.isChildClientRole()) {
      this.downloadEnable = false;
      this.companySelected = null;
      this.clientSelected = null;
      this.clientSelectedUpload = null;
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }
    this.ngOnInit();
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  loadUploadedDateList(type?) {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingLogTotalJobService.getUploadedDateList(options).subscribe((res) => {
      const resObj: any = res;
      const tempUploadDateList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        resObj.data.forEach((date) => {
          tempUploadDateList.push({ label: _moment(date).format('MM/DD/YYYY'), value: _moment(date, 'YYYY-MM-DD').toDate().getTime() });
        });
        this.uploadDateList = tempUploadDateList;
        this.uploadDateList.sort((a, b) => b.value - a.value);
        this.uploadDateSelected = this.uploadDateList[0].value;
      } else {
        this.uploadDateList = [];
      }
      if (!type) this.search();
    }, () => { if (!type) this.search() });
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    options.companyId = this.authService.getCurrentCompanyId();
    if (this.isPlatformAdmin && this.companySelected) {
      options.companyId = this.companySelected;
    }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }


  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingLogTotalJobService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    })
  }

  chooseCompany(event) {
    this.getClientList();
  }


  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.search();
   // this.downloadEnable = true;
    const args = {
      log_total_job_data_searched_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    }
    const action = 'Searched LOG Total job Data'
    this.monitorDetails(args, action, new Date());
  }

  async importReports(event) {
    this.totalFileUpload = event.files.length;
    const date = new Date();
    this.showProgressBar = true;
    const loggedInUser = JSON.parse(localStorage.getItem('_globals'));
    const dataToBeSaved = [];
    const allData = [];

    /* wire up file reader */
    const filePromises = event.files.map((file) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true, dateNF: 'mm/dd/yyyy;@' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          /* save data */
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          let output = {};
          (data).forEach((item: any) => {
            const isEmpty = Object.values(item).every(x => (x === null || x === ''));
            if (!isEmpty) {
              let output = {};
              for (let key in item) {
                output[_.camelCase(key)] = item[key];
              }
              allData.push(output);
            }
          });
          // Resolve the promise with the response value
          resolve(reader.result)
        }
      });
    });

    // Wait for all promises to be resolved
    await Promise.all(filePromises);
    const uniqueArray = allData.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === allData.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });

    console.log(uniqueArray);

    let uploadHistory: any = {
      createdByUsr: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      startDate: new Date().getTime(),
      reportType: 'LOG_TOTAL_JOB_REPORT'
    }

    uniqueArray.forEach((item) => {
      item.clientId = this.clientSelectedUpload;
      item.companyId = this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId();
      item.createdByUsr = loggedInUser.username;
      dataToBeSaved.push(item);
    });

    const tripNumbers = dataToBeSaved.map(c => c.tripNumber);
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
    if (findDuplicates(tripNumbers).length > 0) {
      this.showProgressBar = false;
      this.showProcessComplete = true;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Uploading failed, duplicate trip Number:') + ' ' + findDuplicates(tripNumbers)
      });

      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });
      this.uploadFailed(date)
      return;
    }
    let userType = this.authService.getUserType();
    if (dataToBeSaved.length > 0) {
      this.billingLogTotalJobService.uploadAndMail(dataToBeSaved, userType, loggedInUser.username,
        _moment(date).format('MM/DD/YYYY')).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            uploadHistory.status = 'SUCCESS';
            uploadHistory.endDate = new Date().getTime();
            this.showUploaded = false;
            if (res.data.previousUpdatedDate) {
              this.previousUpdatedDate = moment(res.data.previousUpdatedDate).format('MM/DD/YYYY');
              this.isPreviousUpdatedDate = true;
              uploadHistory.firstUpdateOn = res.data.previousUpdatedDate;
            }
            this.totalNewRecords = res.data.totalNewRecords;
            this.totalUpdatedRecords = res.data.totalUpdatedRecords;
            this.showProgressBar = false;
            this.showProcessUpdateComplete = true;
            uploadHistory.newRecords = res.data.totalNewRecords;
            uploadHistory.oldRecords = res.data.totalUpdatedRecords;
            this.loadUploadedDateList('ref');
            const args = {
              log_total_job_file_uploaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            const action = 'Log Total Job File Uploaded'
            this.monitorDetails(args, action, date);
            this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
            }, (error) => {
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Failed'),
              detail: this.translatePipe.transform('Uploading Failed!')
            });
            this.uploadFailed(date)
          }
          this.search();
        }, () => {
          this.showProgressBar = false;
          this.showProcessComplete = true;
          uploadHistory.status = 'FAILED';
          uploadHistory.endDate = new Date().getTime();
          this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
          }, (error) => {
          });
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Failed'),
            detail: this.translatePipe.transform('Uploading Failed!')
          });
          this.uploadFailed(date)
        });
    } else {
      this.showProgressBar = false;
      this.showProcessComplete = true;
      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Invalid data please check file again!')
      });
      this.uploadFailed(date)
    }
    const interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 300);

  }
  sortingClick(selectedSortOption) {
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.billingReports
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  downloadExcel() {
    let options = {
      size: 99999,
      page: 0,
      companyId: null,
      startDate: this.fromDate,
      endDate: this.toDate,
      createdBy: this.createdSelected,
      clientId: this.clientSelected
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.uploadDateSelected) {
      options.startDate = this.uploadDateSelected;
      options.endDate = this.uploadDateSelected;
    }
    this.billingLogTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billing = data[i];
          const excelTrans: any = {};
          excelTrans['Service Date'] = this.datePipe.transform(billing.serviceDate, 'yyyy-MM-dd');
          excelTrans['Name'] = billing.name;
          excelTrans['CIN'] = billing.cin;
          excelTrans['Trip Number'] = billing.tripNumber;
          excelTrans['Pickup Address'] = billing.pickupAddress;
          excelTrans['Pickup City'] = billing.pickupCity;
          excelTrans['Pickup State'] = billing.pickupState;
          excelTrans['Pickup Zip'] = billing.pickupZip;
          excelTrans['Dropoff Address'] = billing.dropoffAddress;
          excelTrans['Dropoff City'] = billing.dropoffCity;
          excelTrans['Dropoff State'] = billing.dropoffState;
          excelTrans['Dropoff Zip'] = billing.dropoffZip;
          excelTrans['Trip Mileage'] = billing.tripMileage;
          excelTrans['Trip Price'] = billing.tripPrice;
          excelTrans['Fleet'] = billing.fleet;
          excelTrans['Toll Fee'] = billing.tollFee;
          excelData.push(excelTrans);
        }
        this.billingLogTotalJobService.exportAsExcelFile(excelData, 'BillingLogTotalJob');
        const args = {
          log_total_job_downloaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        }
        const action = 'Log Total Job Downloaded'
        this.monitorDetails(args, action, new Date());
      }
    });
  }

  onChangeClient(event) {
    // this.loadUploadedDateList();
    this.getCreatedByList();
    this.downloadEnable = true;

  }

  closeProcessCompleteDialog() {
    this.showProcessUpdateComplete = false;
  }

  updateBillingReject() {
    this.showUpdateBillingConfirmDialog = false;
  }

  uploadBtn() {
    this.showUploaded = true;
    const args = {
      total_job_upload_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    }
    const action = 'LOG Total Job Upload Button Clicked'
    this.monitorDetails(args, action, new Date())
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      "complete",
      action,
      0
    );
  }

  searchBilling(event) {
    this.table.first = 0;
    this.loadPage({ page: 0, size: this.table.rows, searchText: this.searchText });
  }

  updateData(rowData: any) {
    this.billingLogTotalJobService.update(rowData).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log("update success");
        this.search();
      }
    });
  }

  uploadFailed(date) {
    const args = {
      log_total_job_upload_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    }
    const action = 'Log Total Job Upload Failed'
    this.monitorDetails(args, action, date);
  }


  getClient(id) {
    const client = this.clientList.find(el => el.value === id);
    if (client) return client.label;
  }

  onSelectColumn(event) {
    console.log(this.customColumnName);

    console.log(event);

    if (event) {
      this.checkColumns(event.value);
    } else {
      const column = this.customColumnName;
      if (column && column.length) { this.checkColumns(column); }
    }
  }

  checkColumns(column) {
    this.colsAll.filter(c => c.customizeColumn).forEach(col => {
      if (column.includes(col.field)) {
        col.show = true;
      } else {
        col.show = false;
      }
    });
    this.cols = this.colsAll.filter(item => !item.customizeColumn || item.show);
  }

  getShowHeader() {
    return this.cols.filter(item => !item.customizeColumn || item.show);
  }

  onSelectChange(event) {
    if (event.value.length === this.uploadDateList.length) {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = 'All';
    } else {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = `${event.value.length} items selected`;
    }
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  closePopupUploaded() {
    this.clientSelectedUpload = null;
    this.uploadedFiles = [];
    this.showUploaded = false;
  }

}
