import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NodeService {

    constructor(private http: HttpClient) {}

    getFiles() {
        return this.http.get('assets/demo/data/files.json')
                    .toPromise()
                    .then((res: any) => <any[]> res.data)
                    .then(data => data);
    }

    getFilesystem() {
        return this.http.get('assets/demo/data/filesystem.json')
                    .toPromise()
                    .then((res: any) => <any[]> res.data)
                    .then(data => data);
    }
}
