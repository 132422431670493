import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractorForgotPasswordService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CONTRACTOR_FORGOT_PASSWORD, '');
  }

  requestResetPassword(request: any): Observable<any> {
    this.checkServerUrl(request)
    // return this.http.post(`http://localhost:8013/api/v2/forgot-password`, request);
    return this.http.post(`${this.baseUrlV2}`, request);
  }

  checkValidToken(token: string): Observable<any> {
    // return this.http.get(`http://localhost:8013/api/v2/forgot-password/check-token?token=${token}`);
    return this.http.get(`${this.baseUrlV2}check-token?token=${token}`);
  }

  resetPassword(token: string, req: any): Observable<any> {
    return this.http.post(`${this.baseUrlV2}${token}`, req);
  }
   
}
