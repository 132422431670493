import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { from, Observable } from 'rxjs';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-job-follow-up-update',
  templateUrl: './job-follow-up-update.component.html',
  styleUrls: ['./job-follow-up-update.component.scss']
})
export class JobFollowUpUpdateComponent implements OnInit {
leaveConfirmDialogVisible: boolean = false;
  jobDetailsId: number;
  private navigationDecisionResolver: (decision: boolean) => void;
  constructor(private route: ActivatedRoute, public confirmationService: ConfirmationService,
              private translatePipe: TranslatePipe) {
    this.route.params.subscribe(params => this.jobDetailsId = +params.id);
   }
  ngOnInit(): void {
  }
  confirmNavigate(): Observable<any> {
    const isChangeForm = JSON.parse(localStorage.getItem('_jobFollowUp'));
    if (isChangeForm) {
      this.leaveConfirmDialogVisible = true; // Show the dialog
      return from(new Promise<boolean>((resolve) => {
        // Store the resolver
        this.navigationDecisionResolver = resolve;
      }));
    } else {

    }
  }
onAccept() {
    if (this.navigationDecisionResolver) {
      this.navigationDecisionResolver(true);
    }
    this.leaveConfirmDialogVisible = false; 
  }
  onReject() {
    if (this.navigationDecisionResolver) {
      this.navigationDecisionResolver(false);
    }
    this.leaveConfirmDialogVisible = false; 
  }



}
