import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethod } from "../model/payment-method.model";
import { Constants } from '../../shared/model/constants';
import { map } from 'rxjs/operators';
import {AbstractService} from "../../shared/service/abstract.service";
import {ContractorInvoice} from "../../contractor/model/contractor-invoice.model";

@Injectable({ providedIn: 'root' })
export class PaymentMethodService extends AbstractService<any> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PAYMENT_METHOD, '');
    }

    savePaymentMethod(paymentMethod: PaymentMethod) {
        return this.http.post(`${this.baseUrlV2}`, paymentMethod).pipe(map(resp => resp));
    }

}