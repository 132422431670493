<div class="ui-g pb-4">
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Field' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-mouse-pointer"></i>
				</button>
			</div>
			<p-dropdown [options]="fieldNames" style="flex-grow:1 !important" placeholder="Select a field" [(ngModel)]="selectedField"></p-dropdown>
		</div>
	</div>
	<div class="ui-g-6 ui-sm-12">
		<label>{{'Date Range' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-calendar-week"></i>
				</button>
			</div>
			<p-calendar [(ngModel)]="rangeDates" selectionMode="range" readonlyInput="true" placeholder="Date" style="flex-grow:1"></p-calendar>
			<div class="input-group-append pl-3">
				<button class="btn btn-white" (click)="reset()"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
				<button class="btn btn-white" (click)="filterClientHistory()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
			</div>
		</div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true" class="mb-4">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{selectedField}} <span *ngIf="(dataSource$|async)?.totalElements">({{(dataSource$|async)?.totalElements}})</span></span>
	</p-header>
	<p-table #table [value]="clientHistory" [rows]="(dataSource$|async)?.size" [paginator]="false" [totalRecords]="(dataSource$|async)?.totalElements" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true" class="mb-4">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumns" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort"
							ariaLabelDesc="Activate to sort in descending order"
							ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">

                <td *ngFor="let col of cols">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'dd/MM/yyyy hh:mm'}}">{{rowData.updatedDate | date: 'dd/MM/yyyy hh:mm'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Username' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>		
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Old Value' | translate}}: </span>
								<span *ngIf="rowData.oldValue" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
								<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'newValue'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'New Value' | translate}}: </span>
								<span *ngIf="rowData.newValue" pTooltip="{{rowData.newValue}}">{{rowData.newValue}}</span>
								<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
								<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr>
				<td [attr.colspan]="5" class="text-center py-4">
					<span class="text-muted">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>