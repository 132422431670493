import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { Constants } from '../../../shared/model/constants';
import { EmployeePerformance } from '../model/employee-performance';
import { EmployeePerformanceSearch } from '../../../shared/model/search/employee-performance.search';
import * as MOMENT from 'moment';
import { Employee } from '../../model/employee.model';
import { Page } from '../../../shared/model/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeePerformanceService extends AbstractServiceV2<EmployeePerformance, EmployeePerformanceSearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.EMPLOYEE_PERFORMANCE, '');
    }

    createAll(entities: EmployeePerformance[]): Observable<EmployeePerformance[]> {
        return this._http.post<EmployeePerformance[]>(this.baseUrlV2 + "/" + Constants.URI.EMPLOYEE_PERFORMANCE.SAVE_ALL, entities).pipe(map(resp => resp));
    }

    searchEmployeePerformances(options: any): Observable<EmployeePerformance[]> {
        if (options.toDate) {
            options.toDate = MOMENT(options.toDate).toISOString(true);
        }
        if (options.fromDate) {
            options.fromDate = MOMENT(options.fromDate).toISOString(true);
        }
        const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEE_PERFORMANCE.GET_BY_EMPLOYEE}`;
        return this._http.post<EmployeePerformance[]>(reqUrl, options).pipe(map(resp => resp));
    }

    deleteEmployeePerformances(options: any): Observable<EmployeePerformance[]> {
        if (options.toDate) {
            options.toDate = MOMENT(options.toDate).toISOString(true);
        }
        if (options.fromDate) {
            options.fromDate = MOMENT(options.fromDate).toISOString(true);
        }
        const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEE_PERFORMANCE.DELETE_ALL}`;
        return this._http.post<EmployeePerformance[]>(reqUrl, options).pipe(map(resp => resp));
    }

    getPerformanceList(params: any, payload: any): Observable<Page<Employee>> {
        return this._http.post<Page<Employee>>(this.baseUrlV2 + Constants.URI.EMPLOYEE_PERFORMANCE.GET_PERFORMANCE_LIST, payload, { params: params }).pipe(map(resp => resp));
    }

    getPerformancePeriods(payload: any): Observable<any> {
        if (payload.toDate) {
            payload.toDate = MOMENT(payload.toDate).toISOString(true);
        }
        if (payload.fromDate) {
            payload.fromDate = MOMENT(payload.fromDate).toISOString(true);
        }
        const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEE_PERFORMANCE.GET_PERFORMANCE_PERIOD}`;
        return this._http.post<any>(reqUrl, payload).pipe(map(resp => resp));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/pdf`, data).pipe(map(resp => resp));
    }
}
