import { Component, Input, OnInit } from '@angular/core';
import { ProcessMonitorEmailActivityService } from 'app/admin/process-monitor/service/process-monitor-email-activity.service';
import { SmsMonitoringService } from 'app/phonesystem/service/sms-monitoring.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { LazyLoadEvent, MenuItem } from 'primeng';
import { forkJoin } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  @Input() phone: string;
  @Input() email: string;

  smsItems: MenuItem[];
  smsTableList = [];
  totalSmsRecords = 0;
  loadingSms = false;
  selectedSmsField;
  selectedSmsSortOption;
  smsSize: number;
  smsPage: number;
  smsSortField;
  smsSortOrder;
  smsColumns = [
    { field: 'createdAt', label: 'Date', click: true, dblClick: true, sortOptions: '', sort: 'number' },
    { field: 'sentBy', label: 'Sender', click: true, dblClick: true, sortOptions: '', sort: 'text' },
    { field: 'content', label: 'Content', click: true, dblClick: true, sortOptions: '', sort: 'text' },
    { field: 'documentId', label: 'File', click: true, dblClick: true, sortOptions: '', sort: 'text', width: '84px' }
  ];

  emailItems: MenuItem[];
  emailTableList = [];
  totalEmailRecords = 0;
  loadingEmail = false;
  selectedEmailField;
  selectedEmailSortOption;
  emailSize: number;
  emailPage: number;
  emailSortField;
  emailSortOrder;
  emailColumns = [
    { field: 'createdAt', label: 'Date', click: true, dblClick: true, sortOptions: '', sort: 'number' },
    { field: 'createdByUsr', label: 'Sender', click: true, dblClick: true, sortOptions: '', sort: 'text' },
    { field: 'subject', label: 'Subject', click: true, dblClick: true, sortOptions: '', sort: 'text' },
    { field: 'content', label: 'Content', click: true, dblClick: true, sortOptions: '', sort: 'text' },
    { field: 'documentIds', label: 'File', click: true, dblClick: true, sortOptions: '', sort: 'text', width: '84px' }
  ];

textSortOptions = [
    {name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z'},
    {name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a'}
];
numberSortOptions = [
    {name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9'},
    {name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1'}
];
dateSortOptions = [
    {name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9'},
    {name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1'}
];
statusSortOptions = [
    {name: 'First', value: 'ASC', img: 'circle-xmark text-danger'},
    {name: 'First', value: 'DESC', img: 'circle-check text-success'}
];

  showViewFileDialog = false;
  fileUrls: any[];

  smsMonitoringFromDate: any;
  smsMonitoringToDate: any;
  
  emailFromDate: any;
  emailToDate: any;
  showViewSmsMonitoring = false;
  selectedSmsMonitoring;
  headerTitle;

  constructor(private authService: AuthService, 
    private smsMonitoringService: SmsMonitoringService,
    private documentsService: DocumentsService,
    private emailMonitoringService: ProcessMonitorEmailActivityService) { }

  ngOnInit(): void {
  }

  loadSmsListLazy(event?: LazyLoadEvent) {
    this.loadingSms = true;
    const options: any = {};
    this.smsSize = event ? event.rows : this.smsSize ? this.smsSize : 10;
    this.smsPage = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    this.smsSortField = this.smsSortField ? this.smsSortField : event && event.sortField ? event.sortField : 'id';
    this.smsSortOrder = this.smsSortOrder ? this.smsSortOrder : event && event.sortOrder ? event.sortOrder : 0;
    options.page = this.smsPage;
    options.size = this.smsSize;
    options.sortField = this.smsSortField;
    options.sortOrder = this.smsSortOrder === 1 ? 'DESC' : 'ASC';

    if (this.smsMonitoringFromDate) {
      options.fromDate = moment(this.smsMonitoringFromDate).utc(true).startOf('day').toDate();
    }

    if (this.smsMonitoringToDate) {
      options.toDate = moment(this.smsMonitoringToDate).utc(true).endOf('day').toDate();
    }

    this.loadSmsPage(options);
  }

  loadSmsPage(options) {
    if (!this.phone) {
      return;
    }
    options.recipient = this.phone.replace(/\D/g, '');
    this.loadingSms = true;
    this.smsMonitoringService.search(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.loadingSms = false;
        this.smsTableList = res.data.content;
        this.totalSmsRecords = res.data.totalElements;
      }
      this.loadingSms = false;
    });
  }

  smsSortingClick(selectedSortOption) {
    this.smsSortField = this.selectedSmsField;
    this.smsSortOrder = selectedSortOption === 'ASC' ? 0 : 1;
    this.loadSmsListLazy();
  }

  loadEmailListLazy(event?: LazyLoadEvent) {
    const options: any = {};
    this.emailSize = event ? event.rows : this.emailSize ? this.emailSize : 10;
    this.emailPage = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    this.emailSortField = this.emailSortField ? this.emailSortField : event && event.sortField ? event.sortField : 'id';
    this.emailSortOrder = this.emailSortOrder ? this.emailSortOrder : event && event.sortOrder ? event.sortOrder : 0;
    options.page = this.emailPage;
    options.size = this.emailSize;
    options.sortField = this.emailSortField;
    options.sortOrder = this.emailSortOrder === 1 ? 'DESC' : 'ASC';
    if (this.emailFromDate) {
      options.fromDate = moment(this.emailFromDate).utc(true).startOf('day').toDate();
    }
    if (this.emailToDate) {
      options.toDate = moment(this.emailToDate).utc(true).endOf('day').toDate();
    }
    this.loadEmailPage(options);
  }

  loadEmailPage(options) {
    if (!this.email) {
      return;
    }
    options.receiver = this.email;
    // options.emailType = 'COMPOSE';
    this.loadingEmail = true;
    this.emailMonitoringService.search(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.loadingEmail = false;
        this.emailTableList = res.data.content;
        this.totalEmailRecords = res.data.totalElements;
      }
    });
  }

  emailSortingClick(selectedSortOption) {
    this.emailSortField = this.selectedEmailField;
    this.emailSortOrder = selectedSortOption === 'ASC' ? 0 : 1;
    this.loadEmailListLazy();
  }

  onViewDocument(documentId, isMulti?) {
    this.fileUrls = [];
    if (isMulti) {
      const requests = [];
      for (let id of documentId) {
        requests.push(this.documentsService.getUploadedFile(id));
      }
      if (requests.length > 0) {
        forkJoin(requests).subscribe((responses: any[]) => {
          responses.forEach(res => {
            if (res.data.fileType === 'jpg'
              || res.data.fileType === 'png'
            ) {
              this.fileUrls.push(res.data.fileUrl);
            } else {
                window.open(res.data.fileUrl);
            }
            if (this.fileUrls.length > 0) {
              this.showViewFileDialog = true;
            }
          });
        })
      }
    } else {
      this.documentsService.getUploadedFile(documentId).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          if (resObj.data.fileType === 'jpg'
            || resObj.data.fileType === 'png'
          ) {
            this.fileUrls.push(resObj.data.fileUrl);
            this.showViewFileDialog = true;
          } else {
              window.open(resObj.data.fileUrl);
          }
        }
      }, error => {
      });
    }
  }

  resetSmsMonitoring() {
    this.smsMonitoringFromDate = null;
    this.smsMonitoringToDate = null;
    this.loadSmsListLazy();
  }
  resetEmail() {
    this.emailFromDate = null;
    this.emailToDate = null;
    this.loadEmailListLazy();
  }

  showModalViewSmsMonitoring(data, title) {
    this.headerTitle = title;
    this.selectedSmsMonitoring = data;
    this.showViewSmsMonitoring = true;
  }
}
