import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Department } from '../model/department.model';
import { EmployeeDepartment } from '../model/employee-department.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class EmployeeDepartmentService extends AbstractService<EmployeeDepartment>{

  // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.EMPLOYEE_DEPARTMENT, '');
  }

  getAllByDepartmentId(departmentId: number): Observable<EmployeeDepartment[]> {
    return this._http.get<any>(`${this.baseUrl}bydepartment/${departmentId}`).pipe(map(res => res));
  }

  getActiveByDepartmentId(departmentId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}${departmentId}/active`).pipe(map(res => res));
  }

  getAllByPositionId(positionId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}byPosition/${positionId}`).pipe(map(res => res));
  }

  getEmployeeEmailsByPosition(positionIds: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}byPositionGetEmployeeEmails?positionIds=${positionIds}`).pipe(map(res => res));
  }

  getEmployeesByPosition(positionIds: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}byPositionGetEmployees?positionIds=${positionIds}`).pipe(map(res => res));
  }

}
