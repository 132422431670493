import { Company } from '../../company/model/company.model';
import {EmployeeWorkHour} from '../../employee/model/employee-work-hour.model';
import {ContactInfoMapping} from "../../employee/model/contact-info-mapping";
import {PhonePackage} from "../../phonesystem/model/phone-package.model";
import {PayrollSetting} from "../../payroll-setting/model/payroll-setting.model";

export class Agency {
    id?: number;
    name?: string;
    licenseNo?: string;
    trackingMode?: string;
    contactPerson?: string;
    email?: string;
    addressOne: string;
    addressTwo?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    timezone?: string;
    phone?: string;
    fax?: string;
    status?: number;
    capacityNum?: number;
    minimumWage?: number;
    cctvUrl?: string;
    agreementSignature?: number;
    meals?: string;
    mainWhiteLabel?: string;
    upperLeftLabel?: string;
    is2ndVerificationEnable?: boolean;
    offDaysMultiple?: number;
    agencyType?: string;
    company?: Company;
    companyId?: number;
    workHours: EmployeeWorkHour[];
    contactInfoMapping: ContactInfoMapping;
    createdBy: string;
    phonePackages: PhonePackage[];
    payrollSetting: PayrollSetting;
}
