import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from '../../service/forgot-password.service';
import { EmployeeForgotPasswordService } from '../../service/employee-forgot-password.service';
import {ContractorForgotPasswordService} from '../../service/contractor-forgot-password.service';
import {ClientForgotPasswordService} from '../../service/client-forgot-password.service';
import {GuestForgotPasswordService} from '../../service/guest-forgot-password.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-component',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    providers: [ForgotPasswordService, EmployeeForgotPasswordService]
})
export class ForgotPasswordComponent implements OnInit {
    userTypes: Array<object> = [
        {label: 'Admin', value: 'admin'},
        {label: 'employeeName', value: 'employee'},
        {label: 'Contractor', value: 'contractor'},
        {label: 'Client', value: 'client'},
        {label: 'Guest', value: 'guest'},
        {label: 'Client Sub-Account', value: 6}
    ];
    forgotPasswordData: any = {
        username: String,
        email: String,
        type: String
    };

    loading = false;
    message: string;
    successMsg: string;
    forgotPassForm: UntypedFormGroup;
    showErr: boolean;

    constructor(private forgotPasswordService: ForgotPasswordService,
                public employeeForgotPasswordService: EmployeeForgotPasswordService,
                private router: Router, private contractorFgtPassService: ContractorForgotPasswordService,
                private clientForgotPasswordService: ClientForgotPasswordService,
                private guestForgotPasswordService: GuestForgotPasswordService,
                private formBuilder: UntypedFormBuilder, private messageService: MessageService,) {
    }

    ngOnInit() {
        this.forgotPasswordData.username = '';
        this.forgotPasswordData.email = '';

        this.forgotPassForm = this.formBuilder.group({
            // username: ['', Validators.required],
            email: ['', Validators.required]
        });
    }
    forgotPassword() {
      if(this.forgotPassForm.invalid) {
        this.showErr = true;
        return this.messageService.add({ severity: 'error', summary: 'Error', 
        detail: 'Please enter required fields!' });
      }
      this.showErr = false;
        this.message = '';

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.forgotPasswordData.email).toLowerCase())) {
            this.message = 'provide proper email';
            this.loading = false;
            return;
        }
        if (this.forgotPasswordData.email !== ''){
            this.loading = true;
            this.message = '';
            this.forgotPasswordService.requestResetPasswordGateway(this.forgotPasswordData).subscribe(
                data => {
                    if (data.status === 'SUCCESS') {
                        this.successMsg = data.message;
                    } else {
                        this.message = data.message;
                    }
                    this.loading = false;
                }, () => this.loading = false
            );
        } else {
            this.message = 'Enter value for all fields!';
        }
    }

    backToLogin() {
        this.router.navigate(['/', 'login']);
    }
}
