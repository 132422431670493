import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { AbstractService } from '../../../shared/service/abstract.service';
import { ServeySetupModel } from "../model/survey-setup-model";
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveySetupService extends AbstractService<ServeySetupModel> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.SURVEY_SETUP, '');
  }

  searchSurveySetup(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  displayPopup(options:any){
    return this._http.post<any>(`${this.baseUrlV2}find-popup`, options).pipe(map(resp => resp));
  }

}
