import { Injectable } from '@angular/core';
import { AgencyAdmin } from '../../admin/agency-admin/model/agency-admin.model';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Department } from '../model/department.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class DepartmentService extends AbstractService<Department>{
    // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.DEPARTMENT, '');
    }

    getDepartmentListByPage(params: any): Observable<Page<Department>> {
        return this._http.get<Page<Department>>(this.baseUrlV2 + 'search', { params: params }).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<Department>> {
        return this._http.post<Page<Department>>(`${this.baseUrl}filter`, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(resp => resp));
    }

    getFilteredAdminsWhoCreatedDepartment(): Observable<AgencyAdmin[]> {
        return this._http.get<string[]>(`${this.baseUrl}getFilteredAdminsWhoCreatedDepartment`).pipe(map((resp: any) => resp.data));
    }
    getAllByAgency(agencyId: number): Observable<Department[]> {
        return this._http.get<Department[]>(`${this.baseUrl}byagency/${agencyId}`).pipe(map(resp => resp));
    }

    getDepartmentsDropdown(params: any): Observable<any> {
        // return this._http.get<any>(`http://localhost:8007/api/v2/operation/department/` + Constants.URI.DEPARTMENT.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
        return this._http.get<any>(this.baseUrlV2 + Constants.URI.DEPARTMENT.DROPDOWNLIST, { params: params }).pipe(map(resp => resp));
    }

    searchChanges(params: any): Observable<Page<any>> {
        return this._http.get<Page<any>>(`${this.baseUrl}history`, { params: params }).pipe(map(resp => resp));
    }

}
