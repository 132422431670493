export const environmentLocal = {
  api: 'https://voip1.operrtel.com/api/addExtensionPjsipWeb.php',
  dndApi: 'https://voip1.operrtel.com/api/dndWeb.php',
  callForwardApi: 'https://voip1.operrtel.com/api/setCallForwardWeb.php',
  phoneSettingsApi: 'https://voip1.operrtel.com/api/extensionStatusWeb.php',
  callLog: 'https://voip1.operrtel.com/api/callLogWeb.php',
  listAvailableDids: 'https://voip1.operrtel.com/api/listAvailableDidsWeb.php'
};


const server_ip = 'https://prod2-api.operrwork.com';
const v2_server_ip = 'https://prod2-api.operrwork.com';
const operrtell_ip = 'https://prodapi.operrtel.com';
//const v2_server_ip = "http://192.168.1.150:8080"

export const environment = {
  production: true,
  // server_backend: 'http://69.18.218.57:8080', // pls change this field when change the server ip without '/api'
  server_backend: server_ip, // pls change this field when change the server ip without '/api'
  server_ip: `${server_ip}/api`,
  v2_server_backend: `${v2_server_ip}`,
  v2_server_ip: `${v2_server_ip}`,
  operrtell_server_ip: `${operrtell_ip}`,
  ip_checking_site: 'https://api.ipify.org/?format=json',
  time_to_display_clock: 15,
  sip: {
    server: 'voip1.operrtel.com',
    websocketProxyUrl: 'wss://ws.operrtel.com:8089/ws',
    iceServers: [{url: 'stun:stun.l.google.com:19302'}]
  },
  chat_url: 'https://chatbox-prod.operr.com/#/init?',
  chat_api_key: '697ce7aa546b5dd022fb7e09ef4cb5fb7a2cf2b8',
  mail_url: 'https://mail.operr.com/',
  chat_api_url: 'https://chatbox-prod-api.operr.com/api/v1.0',
  conference_api_url: 'https://prod2-api.operrwork.com/meeting/api/v2/meeting',
  v2_api_url: "https://prod2-api.operrwork.com/company/api/v2",
  // v2_api_url: "http://localhost:8003/api/v2"
  payment_api_url:'https://payment-gateway-prod.operr.com/api/v1',
  payment_app_key: '11ac599dfa164ba7b707aaa01176438',
  map_google_key: 'AIzaSyC3XFSSWsi_EhfzQhi_cMr5xJhh73Vhop0',
  firebase: {
    apiKey: "AIzaSyDDrufXNgavYvB4PyE1Ubd_pucHejUKwJs",
    authDomain: "operrwork-5501c.firebaseapp.com",
    databaseURL: "https://operrwork-5501c-default-rtdb.firebaseio.com",
    projectId: "operrwork-5501c",
    storageBucket: "operrwork-5501c.appspot.com",
    messagingSenderId: "96837581608",
    appId: "1:96837581608:web:74cf738154ccace348ae1d",
    measurementId: "G-KCGKRW5RRJ"
  },
  fustiontable: {
    apiUrl: 'https://www.googleapis.com/fusiontables/v2/query',
    apiKey: 'AIzaSyC3XFSSWsi_EhfzQhi_cMr5xJhh73Vhop0',
    tableId: '1rMogw_5raP2ggDiK0S8k2-bDhQzf3yrmu4ObwcA_',
  },
  face_recognition_url: 'https://face-api.operrwork.com',
  face_recognition_system: 'Operrwork_PROD',
  dispatch: {
    url: 'https://prod-api.operr.com'
    // url: 'https://prodbackup-api.operr.com'
  },
  fe_url: 'https://login.operrwork.com/#/app',
  operrtel_socket: 'https://devsocket.operrtel.com'
};
