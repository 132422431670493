import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../shared/shared.module';
import { CrmDashboardComponent } from './component/crm-dashboard/crm-dashboard.component';
import { CrmDashboardLayoutComponent } from './component/crm-dashboard-layout/crm-dashboard-layout.component';
import { CRMDashboardRoutingModule } from './crm-dashboard-routing.module';
import { ClientContractListDashboardComponent } from '../crm/component/client-contract-list-dashboard/client-contract-list-dashboard.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        PrimeNgModule,
        WebcamModule,
        SharedModule,
        CRMDashboardRoutingModule,
    ],
    declarations: [
        CrmDashboardComponent,
        CrmDashboardLayoutComponent,
        ClientContractListDashboardComponent
    ],
})
export class CRMDashboardModule { }
