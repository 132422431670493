import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../app/shared/model/constants';
import { AbstractService } from '../../../../app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CandidateModel } from '../model/candidate.model';
import { environment } from 'environments/environment';
import {Page} from '../../../shared/model/page.model';

@Injectable({ providedIn: 'root' })
export class CandidateService extends AbstractService<CandidateModel> {

    notificationUrl: string;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CANDIDATE, '');
        this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
    }

    search(options: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    addCandidate(entity: any): Observable<any> {
        const url = this.baseUrlV2;
        return this.http.post<any>(url, entity).pipe(map(res => res));
    }

    updateCandidate(entity: any): Observable<any> {
        const url = this.baseUrlV2;
        return this.http.put<any>(url, entity).pipe(map(res => res));
    }

    findCandidateById(id: number): Observable<any> {
        return this.http.get(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    deleteCandidate(id: number): Observable<any> {
        return this.http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    exportPdf(options: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrlV2}pdf`, options).pipe(map(res => res));
    }

    importData(options: any[]) {
        return this.http.post<any>(`${this.baseUrlV2}import`, options).pipe(map(res => res));
    }

    sendTextEmail(payload) {
        return this.http.post(`${this.notificationUrl}/text-email`, payload);
    }

    getClientHistoryPage(options?: any): Observable<Page<any>> {
        const params = this.createParams(options);
        return this._http.post<Page<any>>(`${this.baseUrlV2}history/search`, options).pipe(map(resp => resp));
    }

    validate(options: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrlV2}validate`, options).pipe(map(res => res));
    }
}
