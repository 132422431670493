import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ForgotPassworRoutingModule } from './forgot-passwor-routing.module';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { ForgotPasswordService } from './service/forgot-password.service';

@NgModule({
    imports: [
        CommonModule,
        PrimeNgModule,
        SharedModule,
        ProgressSpinnerModule,
        ForgotPassworRoutingModule
    ],
    declarations: [
        ForgotPasswordComponent,
        ResetPasswordComponent
    ],
    providers: [
        ForgotPasswordService
    ]
})
export class ForgotPasswordModule { }
