import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../app/shared/model/constants';
import { AbstractService } from '../../../../app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoterListModel } from '../model/voter-list.model';
import { environment } from 'environments/environment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class VoterListService extends AbstractService<VoterListModel> {

  notificationUrl: string;


  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.VOTER_LIST, '');
    // this.baseUrlV2 = `http://localhost:8080/${Constants.ROUTES.VOTER_LIST}/`;
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  search(options: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }
  searchContactsStats(options: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}search-contacts-stats`, options).pipe(map(resp => resp));
  }

  addVoter(entity: any): Observable<any> {
    const url = this.baseUrlV2;
    return this.http.post<any>(url, entity).pipe(map(res => res));
  }

  updateVoterFromWorker(entity: any): Observable<any> {
    const url = this.baseUrlV2 + 'update-voter';
    return this.http.put<any>(url, entity).pipe(map(res => res));
  }

  updateVoter(entity: any): Observable<any> {
    const url = this.baseUrlV2 + entity.id;
    return this.http.put<any>(url, entity).pipe(map(res => res));
  }

  findVoterById(id: number): Observable<any> {
    return this.http.get(`${this.baseUrlV2}${id}`).pipe(map(data => data));
  }

  deleteVoter(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
  }

  removeWalker(option: any): Observable<any> {
    return this.http.post(`${this.baseUrlV2}remove-walker`, option).pipe(map(data => data));
  }

  exportPdf(ids: number[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}export-pdf-url`, ids).pipe(map(res => res));
  }

  importData(options: any[]) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/import`, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}import`, options).pipe(map(res => res));
  }

  importDataFile(formData: FormData) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/import-file`, formData).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}import-file`, formData).pipe(map(res => res));
  }

  updateWalker(options: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}walker`, options).pipe(map(res => res));
  }

  sendTextEmail(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }

  findAllAssemblyDistrict(options) {
    return this.http.post<any>(`${this.baseUrlV2}ad-list`, options).pipe(map(res => res));
  }

  findAllElectionDistrict(options) {
    return this.http.post<any>(`${this.baseUrlV2}ed-list`, options).pipe(map(res => res));
  }

  getEthnicityList(companyId) {
    return this.http.get<any>(`${this.baseUrlV2}ethnicity-list?companyId=${companyId}`).pipe(map(res => res));
  }

  getReligionList(companyId) {
    return this.http.get<any>(`${this.baseUrlV2}religion-list?companyId=${companyId}`).pipe(map(res => res));
  }

  getFileNameSourceList(companyId) {
    return this.http.get<any>(`${this.baseUrlV2}file-name-source-list?companyId=${companyId}`).pipe(map(res => res));
  }

  getPartyList(companyId) {
    return this.http.get<any>(`${this.baseUrlV2}party-list?companyId=${companyId}`).pipe(map(res => res));
  }

  saveSearchHistory(payload) {
    return this.http.post<any>(`${this.baseUrlV2}search-history`, payload).pipe(map(res => res));
  }

  recentSearchHistory(options) {
    return this.http.post<any>(`${this.baseUrlV2}search-history/recent`, options).pipe(map(res => res));
  }

  getVoterListHistory(entity) {
    return this._http.post(`${this.baseUrlV2}history/search`, entity).pipe(map(res => res));
  }

  getImportTemplate(data?: any): Observable<Blob> {
    // return this._http.post(`http://localhost:8007/api/v2/operation/voter-list/get-import-template`, data, { responseType: 'blob' });
    return this._http.post(`${this.baseUrlV2}get-import-template`, data, { responseType: 'blob' });
  }

  getPhoneFlags(companyId): Observable<any> {
      return this.http.get<any>(`${this.baseUrlV2}all-phone-flags?companyId=` + companyId).pipe(map(resp => resp));
  }

  exportPhoneContact(data?: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}export-phone-contact-list-pdf`, data).pipe(map(res => res));
  }

  getEmailList(options) {
    return this.http.post<any>(`${this.baseUrlV2}email-list?`, options).pipe(map(res => res));
  }

  getSmsPhoneList(options) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/sms-phone-list?`, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}sms-phone-list?`, options).pipe(map(res => res));
  }

  searchSimple(options: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}search-simple`, options).pipe(map(resp => resp));
  }
	
  exportPdfNew(voters: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}export-pdf`, voters).pipe(map(res => res));
  }

  exportCsv(voters: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}export-csv-url`, voters).pipe(map(res => res));
  }

  getEmailListV2(options) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/email-list-v2`, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}email-list-v2`, options).pipe(map(res => res));
  }

  updateLatLng(id, latLng) {
    return this.http.put<any>(`${this.baseUrlV2}update-lat-lng/${id}`, latLng).pipe(map(res => res));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [{ wch: 12 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }];

    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  autoAssign(options: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrlV2}auto-assign`, options).pipe(map(res => res));
  }

  getAllVoterLatLng(companyId) {
    return this.http.get<any>(`${this.baseUrlV2}get-voters-lat-lng?companyId=${companyId}`);
  }

  getVoterLatLngByIds(ids: number[]) {
    return this.http.post<any>(`${this.baseUrlV2}voters-lat-lng`, ids);
  }

  bulkGeocoding(options: any, checkedAll?): Observable<any> {
    let url;
    if (checkedAll) {
      url = `${this.baseUrlV2}bulk-geocoding?checkedAll=${checkedAll}`;
    } else {
      url = `${this.baseUrlV2}bulk-geocoding`;
    }
    return this.http.post<any>(url, options).pipe(map(res => res));
  }

  saveDataImport(data: any) {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/voter-list/save-data-import`, data).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}save-data-import`, data).pipe(map(res => res));
  }
}
