
<p-toast position="bottom-right" class="concern"></p-toast>
<div class="container" style="height: 100vh;">
  <!-- <div class="ui-g" *ngIf="quoteDetails">
    <div class="ui-g-12" align="right" *ngIf="!quoteDetails.invoiceId">
      <button type="button" pButton label="{{'Approve' | translate}}" (click)="accept()" [disabled]="processing"></button>
      <button type="button" pButton label="{{'Reject' | translate}}" (click)="reject()" [disabled]="processing"></button>
    </div>
    <div class="ui-g-12" align="right" *ngIf="quoteDetails.invoiceId">
      <button type="button" pButton label="{{'Pay' | translate}}" (click)="pay()"></button>
    </div>
  </div> -->
  <iframe src="" type="application/pdf" width="100%" height="100%"></iframe>
</div>

<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showRejectDialog" class="modal_in" [modal]='true' (onHide)="cancelReject()">
  <div class="ui-g confirm">
    <div class="ui-g-12">
      <label>Reason: </label>
      <div class="input-group w-100">
          <p-dropdown class="flex-auto dropdown-full-width w-100" [options]="rejectReasons" [style]="{'width':'100%'}" filter="true" [showClear]="true"
              placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedRejectReason">
          </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12">
      <label>Note: </label>
      <div class="input-group w-100">
        <textarea pInputTextarea [rows]="8" maxlength="500"
                placeholder="{{'Note' | translate}}"
                [(ngModel)]="rejectNote">
        </textarea>
      </div>
    </div>
  </div>
  <p-footer>
      <div class="ui-g">
          <div class="ui-g-12" align="right">
              <button type="button" pButton label="{{'Confirm' | translate}}" (click)="confirmReject()" [disabled]="!selectedRejectReason || !rejectNote"></button>
              <button type="button" pButton label="{{'Cancel' | translate}}" (click)="cancelReject()"></button>
          </div>
      </div>
  </p-footer>
</p-dialog>