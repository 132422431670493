<div class="note-item bottom-border" [class.active]="isActive">
    <div>
		<span class="d-flex align-items-center justify-content-start w-100 mb-1">
			<h5 class=" m-0 one-liner">{{data.name}}</h5>
		</span>
        <div class="note-action-icon flex-column text-center justify-content-center align-items-center" *ngIf="data.owner">
			<i class="fa-solid fa-share-alt note-item-icon text-primary" (click)="share($event)" pTooltip="{{'Share' | translate}}" tooltipPosition="left"></i>
			<i class="fa-solid fa-pen-to-square note-item-icon text-primary mx-1" (click)="editName($event)" pTooltip="{{'Edit' | translate}}" tooltipPosition="left"></i>
			<i class="fa-solid fa-xmark text-danger note-item-icon mx-1" (click)="delete($event)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
        </div>
    </div>
    <div class="flex-center-vertical note-item-preview pr-4">
        <span *ngIf="data.content" class="one-liner">{{eleminateHtmlTags(data.content)}}</span>
		<span *ngIf="!data.content" class="one-liner"><i class="fa-solid fa-triangle-exclamation text-warning mr-1"></i>{{'no data' | translate}}</span>
    </div>
    <div class="flex-center-vertical">
		<span class="one-liner text-muted" style="font-size: 10px">{{data.updatedAt ? data.updatedAt : data.createdAt}}</span>
    </div>
</div>