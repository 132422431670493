import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyAlphanumeric]'
})
export class OnlyAlphanumericDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys: string[] = [
      'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Meta'
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }

    const regex = new RegExp('^[a-zA-Z0-9]$');
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

}