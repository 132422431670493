<p-panel header="{{'EmployeeOT' | translate}}">
    <div class="ui-g">
        <div class="ui-g-4" *ngIf="isPlatformAdmin">
            <span>{{'company.name' | translate}} :</span>
            <p-dropdown [options]="companies" [(ngModel)]="selectedCompanyId" (ngModelChange)="loadAgencies($event)" optionLabel="name"
                [showClear]="true" [style]="{'width': '80%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}" [disabled]="companyDisabled">
            </p-dropdown>
        </div>
        <!-- <div class="ui-g-4" *ngIf="isPlatformAdmin">
            <span>Agency :</span>
            <p-dropdown [options]="agencies" [(ngModel)]="selectedAgencyId" (ngModelChange)="onAgencyChange()" optionLabel="name"
                [showClear]="true" [style]="{'width': '80%'}" placeholder="Please select" [disabled]="agencyDisabled">
            </p-dropdown>
        </div> -->
        <div class="ui-g-4" *ngIf="!isPlatformAdmin">
            <span>{{'ViewBy' | translate}} : </span>
            <p-dropdown [options]="viewBy" [(ngModel)]="selectedViewBy" [style]="{'width': '80%'}"
                placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="viewByChange($event)">
                <ng-template pTemplate="item" let-item>
                    {{item.label | translate}}
                </ng-template>
                <ng-template let-item pTemplate="selectedItem">
                    {{item.label | translate}}
                </ng-template>
            </p-dropdown>
        </div>
        <div class="ui-g-4" *ngIf="!isPlatformAdmin">
        </div>
        <div class="ui-g-4">
            <span>{{'employee.name' | translate}} :</span>
            <p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" [options]="employeeList" [(ngModel)]="historyFilter.employeeIds" [style]="{'width': '80%'}"
                [panelStyle]="{'width': '100%'}" [disabled]="disableEmployee()"
                id="id_filteredEmployeeList_to_selected_label">
            </p-multiSelect>
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4">
            <span>{{'weekDate' | translate}} :</span>
            <p-calendar id="end-date" [showIcon]="true" [(ngModel)]="payrollDate" selectionMode="range"
                [style]="{'width': '80%'}" [inputStyle]="{'width': '100%'}" panelStyle="{'width': '100%'}"
                [readonlyInput]="true" [disabledDays]="daysDisable" (onSelect)="selectWeek($event)" [maxDate]="futureDatesDisableOTHistory"
                [disabled]="disableCaledar" [placeholder] = "placeholderCalendar"></p-calendar>
        </div>
        <div class="ui-g-4" *ngIf="isPlatformAdmin">
            <span>{{'ViewBy' | translate}} : </span>
            <p-dropdown [options]="viewBy" [(ngModel)]="selectedViewBy" [style]="{'width': '80%'}"
                placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="viewByChange($event)">
                <ng-template pTemplate="item" let-item>
                    {{item.label | translate}}
                </ng-template>
                <ng-template let-item pTemplate="selectedItem">
                    {{item.label | translate}}
                </ng-template>
            </p-dropdown>
        </div>
        <div class="ui-g-4" *ngIf="!isPlatformAdmin">
        </div>
        <div class="ui-g-4">
            <span>{{'department.name' | translate}} : </span>
            <p-dropdown [(ngModel)]="selectedDepartment" [options]="departments" [style]="{'width': '80%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}"
                (onChange)="searchByDepartment($event)">

            </p-dropdown>
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-1 ui-g-offset-5 text-center">
            <button pButton label="{{'button.search' | translate}}" (click)="onSearch()"></button>
        </div>
        <div class="ui-g-1 text-center">
            <button pButton label="{{'button.reset' | translate}}" (click)="onReset()"></button>
        </div>
    </div>
</p-panel>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-table #dt header="Index" [value]="OtviewList" dataKey="id" [customSort]="true" [lazy]="false"
    (onLazyLoad)="loadSummaryViewyLazy($event)" [rows]="25" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]"
    [totalRecords]="totalRecords" [style]="{'margin-bottom':'20px'}" [(contextMenuSelection)]="selectedOTview"
    [contextMenu]="cm" [responsive]="true" [columns]="cols" [autoLayout]="true" [globalFilterFields]="['department','firstName','lastName','totalTimeinHHMM','overtimeHoursInHHMM', 'balanceHoursinHHMM']">

    <ng-template pTemplate="caption">
        <div style="text-align: right">
            <i class="fa-solid fa-magnifying-glass" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="{{'button.search' | translate}}" style="width:auto" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': showPrevent ? col.preventDisplay:col.OTdisplay  }">
                <ng-container>
                    <span >
                        {{col.label | translate}}
                        <span *ngIf="!col.sort">
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field">
                            </p-sortIcon>
                        </span>
                        <span *ngIf="col.sort">
                            <span
                                (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;">
                                <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                            </span>
                        </span>
                    </span>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
            <td>{{i+1}}</td>
            <td>{{rowData.department}}</td>
            <td>{{rowData.firstName}}</td>
            <td>{{rowData.lastName}}</td>
            <td>{{payrollDate[0] | date: 'MM/dd/yyyy'}} - {{payrollDate[1] | date: 'MM/dd/yyyy'}}</td>
            <td>{{rowData.totalTimeinHHMM}}</td>
            <td *ngIf="!showPrevent">{{rowData.overtimeHoursInHHMM}}</td>
            <td *ngIf="!showPrevent">{{rowData.balanceHoursinHHMM}}</td>
            <td *ngIf="showPrevent">{{rowData.potentialOTInHHMM}}</td>
        </tr>

    </ng-template>
    <ng-template pTemplate="footer" let-columns>
        <ng-container *ngIf="loading">
            <tr class="text-center">
                <td [attr.colspan]="columns.length" style="background: #eee !important; color: #000 !important">
                    {{'button.loading' | translate}}
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="OtviewList.length == 0 && !loading">
            <tr class="text-center">
                <td [attr.colspan]="columns.length" style="background: #eee !important; color: #000 !important">
                    {{'noData' | translate}}
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!loading && OtviewList.length > 0">
            <tr>
                <td class="text-right" colspan="4">
                    <b>{{'TotalRecords' | translate}}</b>
                </td>
                <td>{{totalRecords}}</td>
                <td></td>
                <td *ngIf="!showPrevent"></td>
                <td *ngIf="!showPrevent"></td>
                <td *ngIf="showPrevent"></td>
            </tr>
        </ng-container>
    </ng-template>
</p-table>
<p-overlayPanel #op1 appendTo="body">
    <p-listbox [options]="textSortOptionsOT" [(ngModel)]="selectedSortOption"
        (onChange)="sortingClicked(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
    <p-listbox [options]="numberSortOptionsOT" [(ngModel)]="selectedSortOption"
        (onChange)="sortingClicked(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
