import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractService } from "../../../shared/service/abstract.service";
import { Constants } from "../../../shared/model/constants";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from 'environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({ providedIn: 'root' })
export class DispatchTotalJobService extends AbstractService<any> {

  notificationUrl: string;

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.DISPATCH_TOTAL_JOB, '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  saveMultiple(options: any, type?): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}save-multiple?userType=${type}`, options).pipe(map(resp => resp));
  }

  getList(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  getUploadedDateList(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    if (options.clientId) {
      params = params.set('clientId', options.clientId);
    }

    return this._http.get<any>(`${this.baseUrlV2}getUploadedDateList`, { params }).pipe(map(resp => resp));
  }

  getBillingSignOff(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    return this._http.get<any>(`${this.baseUrlV2}getUploadedDateSignOffList`, { params }).pipe(map(resp => resp));
  }


  getCreatedByList(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    if (options.clientId) {
      params = params.set('clientId', options.clientId);
    }
    return this._http.get<any>(`${this.baseUrlV2}find-created-by-user`, { params }).pipe(map(resp => resp));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [{ wch: 12 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }];

    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  update(payload) {
    return this.http.post(`${this.baseUrlV2}update`, payload)
  }

  upload(options: any, type: any, uploadBy: any, createDate: any): Observable<any> {
    const reqHeaders: HttpHeaders = new HttpHeaders({'uploadBy': uploadBy, 'createDate': createDate});
    return this._http.post<any>(`${this.baseUrlV2}save-multiple`, options, {headers: reqHeaders}).pipe(map(resp => resp));
  }

  sendBillingTeam(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  importBillingJobs(payload) {
    return this.http.post(`${this.baseUrlV2}import-from-billing`, payload);
  }

  updateFleetTollFee(payload) {
    return this.http.post(`${this.baseUrlV2}update-fleet-toll-fee`, payload);
  }

  sendEmail(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}send-email`, data).pipe(map(resp => resp));
  }
}
