export class Response<T> {
    data: T[];
    message: string;
    status: string;
}

export class SingleResponse<T> {
    data: T;
    message: string;
    status: string;
}

export declare type OrderDirection = 'ASC' | 'asc' | 'DESC' | 'desc';
export class Order {
    property: string;
    direction?: OrderDirection = 'ASC';
    constructor(property: string, direction?: OrderDirection) {
        this.property = property;
        if (direction) {
            this.direction = direction;
        }
    }
}
export class Sort {
    sorted: boolean;
    unsorted: boolean;
    orders: Order[];
    constructor(properties?: string[]) {
        if (properties) {
            this.orders = properties.map(p => new Order(p));
        }
    }
}


export class Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: true;
    unpaged: boolean;
    sort: Sort;
}

export abstract class AbstractPageRequest {
    page: number;
    size: number;
    constructor(page: number, size: number) {
        this.page = page;
        this.size = size;
    }
}

export class PageRequest extends AbstractPageRequest {
    sort: Sort;
    constructor(page: number, size: number, sort?: Sort) {
        super(page, size);
        if(sort) {
            this.sort = sort;
        }
    }
}
export class Page<T> {
    content: T[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
    pageable: Pageable;
    sort: Sort;
}
export class PageResponse<T> {
    data: Page<T>;
    message: string;
    status: string;
}
