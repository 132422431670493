import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { from, Observable } from 'rxjs';

@Component({
  selector: 'app-job-follow-up-view',
  templateUrl: './job-follow-up-view.component.html',
  styleUrls: ['./job-follow-up-view.component.scss']
})
export class JobFollowUpViewComponent implements OnInit {

  jobDetailsId:number;
  constructor(private route:ActivatedRoute) {
    this.route.params.subscribe(params => this.jobDetailsId = +params.id);
   }

  ngOnInit(): void {
  }

}
