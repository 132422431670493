<p-toast position="bottom-right">{{msgs}}</p-toast>
<form [formGroup]="contractForm" #contractFormData [name]="contractFormData">
    <div class="ui-g">
        <div id="contentToConvert" style="padding:50px;">
                <div style="text-align: center;">
                    <img src="../../../../assets/punch-images/operr_logo.jpg">
                </div>
            <div class="ui-g-12">
                <p style="text-align: center;">
                    <b>SOFTWARE LICENCE AGREEMENT</b>
                </p>
            </div>
            <div class="ui-g-12">
                <p> This Software Licence Agreement ("Agreement" or "License") is enterned into as of
                    <p-calendar id="effectiveDate" [(ngModel)]="contract.effectiveDate" formControlName="effectiveDate" [disabled]="readOnly || disableInput"
                        placeholder="mm/dd/yyyy" dateFormat="mm-dd-yy"></p-calendar> ("Effective Date") by and between Operr Technologies, Inc. and/or Operr Tel, Inc. are hereinafter
                    referred to as "Operr" or Operr Technologies, Inc. and its affilaites ("Lincesor") and
                    <input type="text" pInputText [(ngModel)]="contract.licensor" formControlName="licensor" [attr.disabled]="disableInput? '' : null"> ("Lincensee")</p>
                <p>
                    Lincensor is a New York Corporation with its principal place at 130-30 31st Ave., Ste.801, Flushing, NY 1134. Operr Service
                    Bureau, Inc. is licensed by the New York State Department of Health to provide billing services for third
                    parties.
                </p>
                <p>
                    License is a
                    <input type="text" pInputText [(ngModel)]="contract.licensee" formControlName="licensee" [attr.disabled]="disableInput? '' : null"> Corporation, with its principal, with its principal place of business at
                    <input type="text" pInputText [(ngModel)]="contract.businessPlace" formControlName="businessPlace" [attr.disabled]="disableInput? '' : null">
                </p>
                <p>
                    WHEREAS Licensee is desirous of using Licensor’s Software, and Licensee hereby agrees that all use of the Software is subject
                    to the following terms stipulated in the documentation and this Agreement.
                </p>
                <p>
                    NOW THEREFORE, License and Licensor agree to the following:
                </p>
                <b>1. Scope, Ownership, Limited License, and Restrictions</b>
                <br>
                <br>
                <p>1.1
                    <span style="text-decoration: underline"> Scope</span>
                </p>
                <p>
                    Licensor's Software allows Licensee to prepare and submit Medicaid reimbursement claims to governmental agencies. The Licensee
                    expressly agrees that it will use the Software only for the preparation and submission of its own Medical
                    reimbursement claims under License's New York State Medicaid Identification Number:
                    <input type="text" pInputText [(ngModel)]="contract.identificationNo" formControlName="identificationNo" [attr.disabled]="disableInput? '' : null">
                </p>
                <p>1.2
                    <span style="text-decoration: underline">Ownership</span>
                </p>
                <p>
                    Licensee agrees that Licensor owns and will retain all title, interest, proprietary and business methods and rights, including
                    patent, copyright, trade secret, trademark, and other Intellectual Proprietary rights, in the original
                    and all copies of the Software and any improvements, whether invented by Licensor, Licensee, Licensee’s
                    employees, contractors, or agents.
                </p>
                <br>
                <p>
                    Licensee does not acquire any rights, express or implied, in the Software or any improvements or to any component(s) thereof,
                    other than those specified in this License. Licensor may use, sell, assign, transfer, and license copies
                    of and rights relating to the Software to third parties free from any claim of the Licensee.
                </p>
                <p>1.3
                    <span style="text-decoration: underline">Limited License and Restrictions</span>
                </p>
                <p>
                    Licensee acknowledges that the license granted hereunder is limited and will be used on a designated system. Licensee will
                    not assign, sell, license, re-license, sublicense, rent, lease, publish, display, distribute, permit
                    unauthorized use, or otherwise transfer this License, Software, documentation or any Improvements to
                    any person or entity for any purpose or permit to use the Software by third-party, training, commercial
                    time- sharing, service bureau use, or for any use not explicitly authorized by this License.
                </p>
                <b>2. Warranties and Disclaimers and Restrictions</b>
                <br>
                <br>
                <p>2.1
                    <span style="text-decoration: underline"> Warranty</span>
                </p>
                <p>
                    Licensor warrants that the License granted hereunder will operate by the Documentation provided along with the License (without
                    any modification).
                </p>
                <p>2.2
                    <span style="text-decoration: underline">Disclaimers</span>
                </p>
                <p>
                    THE WARRANTIES OUTLINED IN THIS SECTION ARE EXCLUSIVE AND INSTEAD OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
                    BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Licensor
                    does not warrant that the Software will operate in combinations or that the operation of the Software
                    will be uninterrupted or error-free.
                </p>
                <p>2.3
                    <span style="text-decoration: underline"> Restrictions</span>
                </p>
                <p>
                    The Licensee will not assign the License or this Agreement or give the Software either in part or as a whole to another individual
                    or entity. If Licensee grants a security interest in the Software or service to any third party, without
                    prior written approval from Licensor, the third party will not have any right or interest in the License
                    granted under this Agreement and the Licensee will be liable for damages. The rights and remedies available
                    to the Licensee under this Agreement are non-transferable and cannot be availed by any third party, which
                    is not a party to this Agreement.
                </p>
                <br>
                <p>
                    Licensee is prohibited from copying, modifying, altering, adapt, translate, reverse engineer, disassemble, or create derivative
                    works based upon the Software, in whole or in part.
                </p>

            </div>
        </div>
       
        <div class="ui-g-12">
            <div class="ui-g-1"></div>
            <div class="ui-g-5">
                <b style="margin-left:30%">LICENSEE</b>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licenseeName" formControlName="licenseeName" [attr.disabled]="disableInput? '' : null" >
                    <br> Name/Corporation</p>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licenseeAddress" formControlName="licenseeAddress" [attr.disabled]="disableInput? '' : null" >
                    <br>Address</p>
                <p>
                    <p-inputMask mask="(999)999-9999" formControlName="licenseePhone" placeholder="Licensee Phone" [(ngModel)]="contract.licenseePhone" [disabled]="disableInput"></p-inputMask>
                    <br>Phone</p>
                    <div class="ui-g align-items-center">
                        <div class="ui-g-6 p-0">
                        </div>
                        <div class="custom-error-message p-0">
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licenseePhone'].touched && contractForm.get('licenseePhone').hasError('required')">This field is
                                required.</span>
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licenseePhone'].touched && !contractForm.get('licenseePhone').hasError('required') && contractForm.get('licenseePhone').hasError('whitespace')">Whitespace
                                not allowed.</span>
                            <!-- <span class="ui-message ui-messages-error"
                                                    *ngIf="contractForm.controls['licenseePhone'].touched && !contractForm.get('licenseePhone').hasError('required') && !contractForm.get('licenseePhone').hasError('whitespace') && contractForm.get('licenseePhone').hasError('letter')">Phone should be a number.</span> -->
                            <span class="ui-message ui-messages-error"
                                *ngIf="!contractForm.get('licenseePhone').hasError('required') && contractForm.controls['licenseePhone'].errors?.custom && (contractForm.controls['licenseePhone'].dirty || contractForm.controls['licenseePhone'].touched)">Invalid
                                phone number</span>
                        </div>
                    </div>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licenseePrintName" formControlName="licenseePrintName" [attr.disabled]="disableInput? '' : null" >
                    <br>Authorized Officer (Print Name):</p>
                <div id="signLicensee">
                    <img *ngIf="!showLicenceeSign" src="{{contract?.licenseeSignature?.fileUrl}}"/>
                    <signature-pad *ngIf="showLicenceeSign && !disableInput" #licenseeSignpad [options]="signaturePadConfig" (onBeginEvent)="drawStartLincence()" (onEndEvent)="drawCompleteLincenceSign()"></signature-pad>
                </div>
                <i class="material-icons" (click)="clearLicenceeSign()" style="cursor:pointer" title="clear signature">format_clear</i>
                <br>
                <p>Signature:</p>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licenseeEmail" formControlName="licenseeEmail" [attr.disabled]="disableInput? '' : null" >
                    <br>Email:</p>
                <p>
                    <p-inputMask mask="(999)999-9999" formControlName="licenseeDirectPhone" placeholder="Licensee Direct Phone" [(ngModel)]="contract.licenseeDirectPhone" [disabled]="disableInput"></p-inputMask>
                    <br>Direct Phone #:</p>
                    <div class="ui-g align-items-center">
                        <div class="ui-g-6 p-0">
                        </div>
                        <div class="ui-g-6 custom-error-message p-0">
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licenseeDirectPhone'].touched && contractForm.get('licenseeDirectPhone').hasError('required')">This
                                field is
                                required.</span>
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licenseeDirectPhone'].touched && !contractForm.get('licenseeDirectPhone').hasError('required') && contractForm.get('licenseeDirectPhone').hasError('whitespace')">Whitespace
                                not allowed.</span>
                            <!-- <span class="ui-message ui-messages-error"
                                                                        *ngIf="contractForm.controls['licenseeDirectPhone'].touched && !contractForm.get('licenseeDirectPhone').hasError('required') && !contractForm.get('licenseeDirectPhone').hasError('whitespace') && contractForm.get('licenseeDirectPhone').hasError('letter')">Phone should be a number.</span> -->
                            <span class="ui-message ui-messages-error"
                                *ngIf="!contractForm.get('licenseeDirectPhone').hasError('required') && contractForm.controls['licenseeDirectPhone'].errors?.custom && (contractForm.controls['licenseeDirectPhone'].dirty || contractForm.controls['licenseeDirectPhone'].touched)">Invalid
                                phone number</span>
                        </div>
                    </div>
                <p>
                    <p-calendar id="licenseeDate" [(ngModel)]="contract.licenseeDate" formControlName="licenseeDate" [disabled]="readOnly || disableInput" placeholder="mm/dd/yyyy"
                        dateFormat="mm-dd-yy" ></p-calendar>
                    <br>Date:</p>
            </div>
            <div class="ui-g-1"></div>
            <div class="ui-g-5">
                <b style="margin-left:30%">LICENSOR</b>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licensorName" formControlName="licensorName" [attr.disabled]="disableInput? '' : null" >
                    <br> Name/Corporation</p>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licensorAddress" formControlName="licensorAddress" [attr.disabled]="disableInput? '' : null" >
                    <br>Address</p>
                <p>
                    <p-inputMask mask="(999)999-9999" formControlName="licensorPhone" placeholder="Licensor Phone" [(ngModel)]="contract.licensorPhone" [disabled]="disableInput"></p-inputMask>
                    <br>Phone</p>
                    <div class="ui-g align-items-center">
                        <div class="ui-g-6 p-0">
                        </div>
                        <div class=" custom-error-message p-0">
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licensorPhone'].touched && contractForm.get('licensorPhone').hasError('required')">This field is
                                required.</span>
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licensorPhone'].touched && !contractForm.get('licensorPhone').hasError('required') && contractForm.get('licensorPhone').hasError('whitespace')">Whitespace
                                not allowed.</span>
                            <!-- <span class="ui-message ui-messages-error"
                                                    *ngIf="contractForm.controls['licensorPhone'].touched && !contractForm.get('licensorPhone').hasError('required') && !contractForm.get('licensorPhone').hasError('whitespace') && contractForm.get('licensorPhone').hasError('letter')">Phone should be a number.</span> -->
                            <span class="ui-message ui-messages-error"
                                *ngIf="!contractForm.get('licensorPhone').hasError('required') && contractForm.controls['licensorPhone'].errors?.custom && (contractForm.controls['licensorPhone'].dirty || contractForm.controls['licensorPhone'].touched)">Invalid
                                phone number</span>
                        </div>
                    </div>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licensorPrintName" formControlName="licensorPrintName" [attr.disabled]="disableInput? '' : null" >
                    <br>Authorized Officer (Print Name):</p>
                <div id="signLicensor">
                    <img *ngIf="!showLicensorSign" src="{{contract?.licensorSignature?.fileUrl}}"/>
                    <signature-pad *ngIf="showLicensorSign && !disableInput" #licensorSignpad [options]="signaturePadConfig" (onBeginEvent)="drawStartLincensor()" 
                    (onEndEvent)="drawCompleteLincensorSign()"></signature-pad>
                </div>
                <i class="material-icons" (click)="clearLicensorSign()" style="cursor:pointer" title="clear signature">format_clear</i>
                <br>
                <p>Signature:</p>
                <p>
                    <input type="text" pInputText [(ngModel)]="contract.licensorEmail" formControlName="licensorEmail" [attr.disabled]="disableInput? '' : null" >
                    <br>Email:</p>
                <p>
                    <p-inputMask mask="(999)999-9999" formControlName="licensorDirectPhone" placeholder="Licensee Direct Phone" [(ngModel)]="contract.licensorDirectPhone" [disabled]="disableInput"></p-inputMask>
                    <br>Direct Phone #:</p>
                    <div class="ui-g align-items-center">
                        <div class="ui-g-6 p-0">
                        </div>
                        <div class="ui-g-6 custom-error-message p-0">
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licensorDirectPhone'].touched && contractForm.get('licensorDirectPhone').hasError('required')">This field is
                                required.</span>
                            <span class="ui-message ui-messages-error"
                                *ngIf="contractForm.controls['licensorDirectPhone'].touched && !contractForm.get('licensorDirectPhone').hasError('required') && contractForm.get('licensorDirectPhone').hasError('whitespace')">Whitespace
                                not allowed.</span>
                            <!-- <span class="ui-message ui-messages-error"
                                                    *ngIf="contractForm.controls['licensorDirectPhone'].touched && !contractForm.get('licensorDirectPhone').hasError('required') && !contractForm.get('licensorDirectPhone').hasError('whitespace') && contractForm.get('licensorDirectPhone').hasError('letter')">Phone should be a number.</span> -->
                            <span class="ui-message ui-messages-error"
                                *ngIf="!contractForm.get('licensorDirectPhone').hasError('required') && contractForm.controls['licensorDirectPhone'].errors?.custom && (contractForm.controls['licensorDirectPhone'].dirty || contractForm.controls['licensorDirectPhone'].touched)">Invalid
                                phone number</span>
                        </div>
                    </div>
                <p>
                    <p-calendar id="licensorDate" [(ngModel)]="contract.licensorDate" formControlName="licensorDate" [disabled]="readOnly || disableInput" placeholder="mm/dd/yyyy"
                        dateFormat="mm-dd-yy"></p-calendar>
                    <br>Date:</p>
            </div>
        </div>
        
    </div>
    <div class="ui-g-12" style="text-align:center;">
        <hr>
        <p-button label="Edit" style="margin-bottom:10px" (onClick)="enableForm()" *ngIf="isEdit && disableInput"></p-button>

        <button pButton type="submit" label="Submit" (click)="generateContract(contractFormData, null)" *ngIf="isEdit && !disableInput" class="green-btn" ></button>
        <button *ngIf="isEdit && !disableInput" pButton type="Save" label="Save" (click)="generateContract(contractFormData, true)"></button>
        <button *ngIf="!isEdit" pButton type="Save" label="Save" (click)="saveContractToDb(contractFormData)"></button>
    </div>
</form>