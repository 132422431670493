<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Compose' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header>
			<span *ngIf="!isClient"><i class="fa-solid fa-user text-primary mr-2"></i>{{'From' | translate}}: {{email.from}}</span>
			<span *ngIf="isClient"><i class="fa-solid fa-at text-primary mr-2"></i>{{'Message' | translate}}</span>
		</p-header>
  		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin || isEmployee">
				<label>{{'Type' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="typeList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="type" optionLabel="value" [filter]="false" emptyFilterMessage="{{'No results found' | translate}}" (onChange)="changeType($event)" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>			
			<div *ngIf="isBulk && (adListOptions || isEmployee)" class="ui-g-3 ui-sm-12">
				<label>{{'Group' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-users"></i>
						</button>
					</div>
					<p-dropdown [options]="groupList" placeholder="{{'pleaseSelect' | translate}}" (onChange)="changeGroup($event)" [(ngModel)]="group" optionLabel="value" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
			  	</div>
			</div>
			<div *ngIf="group && group.value === 'Group'" class="ui-g-3 ui-sm-12">
				<label>{{'Group Name' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-users"></i>
						</button>
					</div>
					<p-dropdown [options]="groupOptions" placeholder="{{'pleaseSelect' | translate}}" (onChange)="changeGroupName($event)" [(ngModel)]="selectedGroupName" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div *ngIf="isBulk && isClientGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Invoice Status' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-landmark"></i>
						</button>
					</div>
					<p-multiSelect [options]="invoiceStatusList" [(ngModel)]="selectedInvoiceStatus" defaultLabel="{{'Select Items' | translate}}" (onChange)="changeClientFilter()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
			  	</div>
			</div>

			<div *ngIf="isBulk && isClientGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Product/Service' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [options]="productList" [(ngModel)]="selectedProducts"  defaultLabel="{{'Select Items' | translate}}" (onChange)="changeClientFilter()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
			  	</div>
			</div>

			<div *ngIf="isBulk && isVoterGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Party' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-democrat"></i>
						</button>
					</div>
					<p-multiSelect [options]="parties" [(ngModel)]="selectedParties" defaultLabel="{{'Select Items' | translate}}" (onChange)="changeVoterFilter()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
			  	</div>
			</div>

			<div *ngIf="isBulk && isVoterGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Assembly District (AD)' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-landmark"></i>
						</button>
					</div>
					<p-multiSelect [options]="adListOptions" [(ngModel)]="selectedADs"  
						defaultLabel="{{'Select Items' | translate}}" (onChange)="changeVoterFilter()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
			  	</div>
			</div>

			<div *ngIf="isBulk && isVoterGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Election District (ED)' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-landmark-dome"></i>
						</button>
					</div>
					<p-multiSelect [options]="edListOptions" [(ngModel)]="selectedEDs" defaultLabel="{{'Select Items' | translate}}" (onChange)="changeVoterFilter()">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
			  	</div>
			</div>

			<div *ngIf="isBulk && isVoterGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Quantity Voter' | translate}}:</label>
				<div class="input-group w-100">
					<span style="font-size: 30px;">{{toEmailGroupVoterList && toEmailGroupVoterList.length > 0 ? toEmailGroupVoterList.length : 0}}</span>
			  	</div>
			</div>

			<div *ngIf="isBulk && isClientGroup && isPlatformAdmin" class="ui-g-6 ui-sm-12">
				<label>{{'Recipient' | translate}}: </label> <span class="text-dark" style="font-size: 36px">{{allListUserHaveEmails?.length}}</span>
			</div>

			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{'Template' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="!getEmailTemplateListLoading" class="fa-solid fa-envelope"></i>
							<i *ngIf="getEmailTemplateListLoading" class="fa-solid fa-spinner loadingHourglass"></i>
						</button>
					</div>
					<p-dropdown [options]="emailTemplateList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="emailTemplate" optionLabel="subject" (onChange)="changeEmailTemplate($event)" [filter]="true"  emptyFilterMessage="{{'No results found' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
					<div class="input-group-append">
						<button class="btn btn-white" (click)="addEmailTemplate()" id="button-addon2" type="button" style="cursor:pointer !important">
							<i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add' | translate}}
						</button>
					</div>
			  	</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="!isBulk">
				<label>{{'Recipient' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="!fillAllEmployeeEmailLoading" class="fa-solid fa-at"></i>
							<i *ngIf="fillAllEmployeeEmailLoading" class="fa-solid fa-spinner loadingHourglass"></i>
						</button>
					</div>
					<p-autoComplete #item
						[(ngModel)]="emailInput"
						[suggestions]="suggestionsForBaseEmail"
						(completeMethod)="filterEmailProviderForTo($event)"
						[minLength]="1"
						placeholder="{{'Email Address' | translate}}"
						field="key"
						[multiple]="true"
						[disabled]="isClient" 
						class="w-100" style="flex-grow:1;width: 100%">
					</p-autoComplete>
	
				</div>
			</div>
    
			<div class="ui-g-6 ui-sm-12" *ngIf="!isClient && !isBulk">
				<label>{{'CC (Send copy to)'  | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="!fillAllEmployeeEmailLoading" class="fa-solid fa-copy"></i>
							<i *ngIf="fillAllEmployeeEmailLoading" class="fa-solid fa-spinner loadingHourglass"></i>
						</button>
					</div>
					<p-autoComplete #item1
						[(ngModel)]="ccEmailInput"
						(keyup)="changeEmail($event)"
						[suggestions]="suggestionsForBaseEmail"
						(completeMethod)="filterEmailProviderForFrom($event)"
						[minLength]="1"
						placeholder="{{'Email Address' | translate}}"
						field="key"
						[multiple]="true"
						class="w-100">
					</p-autoComplete>
				</div>
			</div>


			<div class="ui-g-6 ui-sm-12">
				<label>{{'Subject' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-inbox"></i>
						</button>
					</div>
					<input id="subject" type="text" pInputText class="w-100" [(ngModel)]="email.subject" placeholder="{{'Write a subject' | translate}}">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Attach Files' | translate}}</label>
				<p-fileUpload multiple="multiple" [showUploadButton]="false" [showCancelButton]="false" #documentUpload name="attachments[]" (onSelect)="onUpload($event)" showLabel="false" accept=".csv,.xlsx,.xls,.pdf,.docx,image/*"></p-fileUpload>
			</div>
		</div>
	</p-panel>
	<div class="d-flex justify-content-between align-items-center py-4">
		<h1 class="m-0 ml-2">{{'Your Message' | translate}}</h1>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header>	
			<span><i class="fa-solid fa-keyboard text-primary mr-2"></i>{{'Message Body' | translate}}</span>
		</p-header>
		<div [ngxSummernote]="summernoteOptions" [(summernoteModel)]="email.body"></div>
		<p-footer class="d-flex justify-content-end">
			<span class="pull-right" [ngClass]="charactersNumber < 6000 ? 'text-muted' : 'text-danger'" style="font-size: 12px;">{{charactersNumber}}/6000</span>
		</p-footer>
	</p-panel>
	<div clas="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button class="btn btn-danger mx-1" type="button" (click)="cancel()"><i class="fa-solid fa-xmark mr-2"></i> {{'button.cancel' | translate}}</button>
			<button class="btn btn-primary mx-1" type="button" (click)="sendEmail()" [disabled]="isClicked || initLoading"><i class="fa-solid fa-check mr-2"></i> {{'button.submit' | translate}}</button>
		</div>
	</div>
</div>

<p-toast position="bottom-right" key="br"></p-toast>

<div #previewTemplate *ngIf="emailTemplate" style="position: absolute; top: -10000px; left: -10000px">
	<div style="display: flex; flex-wrap: wrap; box-sizing: border-box; padding-left: 1rem !important; padding-right: 1rem !important;">
		<div style="width: 100%; float: left; box-sizing: border-box; padding: .5em; text-align: center; padding-left: 3rem !important; padding-right: 3rem !important;">
			<h1 style="margin: 0">{{emailTemplate.taglineText}}</h1>
			<hr>
		</div>
	</div>

	<table width="100%" border="0" cellspacing="0" cellpadding="0" *ngIf="emailTemplate.layout === 'singleColumn' || emailTemplate.layout === 'twoColumns'" class="section-table">
		<tr>
			<td align="center" valign="middle">
				<table border="0" cellspacing="0" cellpadding="0" [ngStyle]="{'width': emailTemplate.layout === 'singleColumn' ? '33.33%' : '66.66%'}">
					<tr>
						<ng-container *ngFor="let sectionsContent of emailTemplateSectionsContentList; let i = index">

							<td width="50%" valign="top" style="padding: 0 10px;">
								<img *ngIf="sectionsContent.fileUrl" src="{{sectionsContent.fileUrl}}" alt="" style="width: 100%; height: auto;">
								<div style="text-align:left; padding-top: 20px;">
									<h4 *ngIf="sectionsContent.heading">{{sectionsContent.heading}}</h4>
									<p *ngIf="sectionsContent.sectionTextBlock" [innerHTML]="sectionsContent.sectionTextBlock | safeHtml"></p>
								</div>
							</td>
						</ng-container>
					</tr>
				</table>
			</td>

		</tr>
	</table>

	<table width="100%" border="0" cellspacing="0" cellpadding="0" *ngIf="emailTemplate.layout === 'horizontal'" class="section-table">
    <ng-container *ngFor="let sectionsContent of emailTemplateSectionsContentList; let i = index">
      <tr>
        <td width="33%" valign="top" style="padding: .5em;">
          <img src="{{sectionsContent.fileUrl}}" style="width: 100%; max-width: 100%; height: auto;">
        </td>
        <td width="67%" valign="top" style="padding: .5em;">
          <h5>{{sectionsContent.heading}}</h5>
          <div *ngIf="sectionsContent.sectionTextBlock" style="line-height: 1.42; text-align: left; padding: 1.5rem !important;">
            <p [innerHTML]="sectionsContent.sectionTextBlock | safeHtml" style="margin: 0; outline: none; overflow-y: auto; tab-size: 4; white-space: pre-wrap; word-wrap: break-word;"></p>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>

	<div style="padding: 1.5rem !important; background-color: #f8f9fa !important;">
		<div *ngIf="emailTemplate.showDonateLink" style="width: 100%; float: left; box-sizing: border-box; padding: .5em; text-align: center !important;">
			<h1>Make a contribution to our campaign</h1>

			<a id="donateLink" href="{{emailTemplate.donateLink}}" style="font-size: 2em;border-radius: 29px; cursor: pointer; padding-left: 1.5rem !important; padding-right: 1.5rem !important; margin: 1.5rem !important; padding: .5rem 1rem; line-height: 1.5; color: #212529; border-color: #ffc107; display: inline-block; font-weight: 400; text-align: center; vertical-align: middle; user-select: none; border: 1px solid transparent; transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; background-color: #ffc107;" target="_blank">Donate Now</a>
			<div style="width: 100%; float: left; box-sizing: border-box; padding: .5em; text-align: center !important;">
				<span id="paidForText">{{emailTemplate.paidForText}}</span>
			</div>
		</div>
		<table width="100%" border="0" cellspacing="0" cellpadding="0" class="section-table">
			<tr>
				<td align="center" valign="middle">
					<table border="0" cellspacing="0" cellpadding="0" style="width: 220px;">
						<tr>
							<td align="center" style="padding: 0 .25rem;"><a href="{{emailTemplate.facebookMetaProfile}}" target="_blank"><img src="https://f.hubspotusercontent30.net/hubfs/2235233/blog-import/2020/20-08-Aug/sm-icons-facebook-logo.png" style="max-width: 30px; cursor: pointer;"></a></td>
							<td align="center" style="padding: 0 .25rem;"><a href="{{emailTemplate.twitterProfile}}" target="_blank"><img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/twitter-512.png" style="max-width: 30px; cursor: pointer;"></a></td>
							<td align="center" style="padding: 0 .25rem;"><a href="{{emailTemplate.linkedInProfile}}" target="_blank"><img src="https://cdn-icons-png.flaticon.com/512/179/179330.png" style="max-width: 30px; cursor: pointer;"></a></td>
							<td align="center" style="padding: 0 .25rem;"><a href="{{emailTemplate.redditProfile}}" target="_blank"><img src="https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_reddit-512.png" style="max-width: 30px; cursor: pointer;"></a></td>
							<td align="center" style="padding: 0 .25rem;"><a href="{{emailTemplate.youtubeChannel}}" target="_blank"><img src="https://cdn-icons-png.flaticon.com/512/2504/2504848.png" style="max-width: 30px; cursor: pointer;"></a></td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</div>
</div>
