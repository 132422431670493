<div class="ui-g">
    <div class="ui-g-12">
        <button pButton label="Back" (click)="onBack()"></button>
    </div>
</div>
<p-card *ngIf="!cardToken">
    <p-panel header="Register Account">
        <form [formGroup]="transferForm">
            <div class="ui-g ui-fluid">
                <div class="ui-g-6">
                    <div class="ui-g">
                        <div class="ui-g-4">
                            Name on the account
                        </div>
                        <div class="ui-g-8">
                            <input pInputText formControlName="accountName" required/>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['accountName'].errors?.required">
                  Account Name is required
                </div>
              </span>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            Account Type
                        </div>
                        <div class="ui-g-9">
                            <p-dropdown
                                    [options]="accountTypes"
                                    formControlName="accountType"
                                    styleClass="w-100"
                                    [autoDisplayFirst]="false"
                                    [showClear]="true">
                            </p-dropdown>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['accountType'].errors?.required">
                  Account Type is required
                </div>
              </span>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            Routing#
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <input pInputText formControlName="roundNumber"/>
                                <button pButton label="Verify" (click)="verify()" [disabled]="transferForm.get('bankName').value"></button>
                            </div>
                            <span class="md-inputfield ng-star-inserted" *ngIf="messageError">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="messageError">
                  {{messageError}}
                </div>
              </span>
                            <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                  <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['roundNumber'].errors?.required">
                    Round number is required
                  </div>
                </span>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            Bank Name
                        </div>
                        <div class="ui-g-9">
                            <input pInputText formControlName="bankName" required/>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['bankName'].errors?.required">
                  Bank Name is required
                </div>
              </span>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            Bank Account #
                        </div>
                        <div class="ui-g-9">
                            <input pInputText formControlName="bankAccountNumber" required/>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['bankAccountNumber'].errors?.required">
                  Bank Account Name is required
                </div>
              </span>
                    </div>
                </div>
                <div class="ui-g-6">
                    <div class="ui-g ui-fluid">
                        <div class="ui-g-3">
                            Start Date*
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <p-calendar [showIcon]="true" formControlName="startDate" [required]="true"></p-calendar>
                            </div>
                            <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['startDate'].errors?.required">
                  Start Date is required
                </div>
              </span>
                        </div>
                    </div>
                    <div class="ui-g ui-fluid">
                        <div class="ui-g-3">
                            End Date
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <p-calendar [showIcon]="true" formControlName="endDate"></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            Pay Amount*
                        </div>
                        <div class="ui-g-9">
                            <div class="ui-inputgroup">
                                <input pInputText formControlName="payAmount" [required]="true"/>
                            </div>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                  <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['payAmount'].errors?.required">
                    Pay amount is required
                  </div>
                </span>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4">
                            Pay Percentage*
                        </div>
                        <div class="ui-g-8">
                            <input pInputText formControlName="payPercentage" [required]="true"/>
                        </div>
                        <span class="md-inputfield ng-star-inserted" *ngIf="requiredValidate">
                <div class="ui-message ui-messages-error ui-corner-all" *ngIf="transferForm.controls['payPercentage'].errors?.required">
                  Pay Percentage is required
                </div>
              </span>
                    </div>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12" style="text-align: center">
                    <p-button label="Save" style="margin-right: 10px;" (click)="save()"></p-button>
                    <p-button label="Cancel" (onClick)="cancel()"></p-button>
                </div>
            </div>
        </form>
    </p-panel>
</p-card>

<app-transfer-funds *ngIf="cardToken" (displayChange)="cancel()"></app-transfer-funds>
