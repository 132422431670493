<div class="d-flex flex-wrap align-items-center justify-content-between py-4">
	<h1 class="m-0 ml-2">{{'Browse Types' | translate}}</h1>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Transaction Types' | translate}} ({{totalRecords}})</span>
	</p-header>
	<p-toast position="bottom-right">{{msgs}}</p-toast>
	<p-table #dt [value]="transactionTypes" dataKey="id" [lazy]="true" (onLazyLoad)="loadTransactionTypesLazy($event, true)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords"  [responsive]="true" [columns]="cols">
		<ng-template pTemplate="caption">
			<div class="d-flex justify-content-end align-items-center">
				<div class="input-group w-100 mx-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input (input)="filterGlobal($event?.target?.value)" [(ngModel)]="searchText" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of columns" [hidden]="col.field==='action' && !(isEditable || isDeleteAble)">
					<ng-container [ngSwitch]="col.field">
					<span *ngSwitchDefault>
						<div class="d-flex justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
					</span>
					<span *ngSwitchCase="'createdAt'">
						<div class="d-flex justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
					</span>
					<span *ngSwitchCase="'status'">
						<span class="d-flex justify-content-between w-100">
							<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}"></i>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op4.toggle($event); selectedField = col.field;">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</span>
					</span>
					<span *ngSwitchCase="'action'">
						<span class="d-flex justify-content-center w-100">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
					</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'ID' | translate}}: </span>
						<span *ngIf="rowData.id" pTooltip="{{rowData.id}}">{{rowData.id}}</span>
						<span *ngIf="!rowData.id" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Transaction Type' | translate}}: </span>
						<span *ngIf="rowData.transactionType" pTooltip="{{rowData.transactionType}}">{{rowData.transactionType}}</span>
						<span *ngIf="!rowData.transactionType" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Creator' | translate}}: </span>
						<span *ngIf="rowData.creatorName" pTooltip="{{rowData.creatorName}}">{{rowData.creatorName}}</span>
						<span *ngIf="!rowData.creatorName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>
						<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}">{{rowData.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Status' | translate}}: </span>
						<i *ngIf="rowData.status === 'Active'" class="fa-solid fa-circle-check text-success" pTooltip="{{rowData.status.toLowerCase() | translate}}"></i>
						<i *ngIf="rowData.status === 'Inactive'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{rowData.status.toLowerCase() | translate}}"></i>
					</span>
				</td>
				<td *ngIf="isEditable || isDeleteAble">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i *ngIf="isEditable" class="fa-solid fa-pen-to-square text-primary mx-1" (click)="editTransactionType(rowData)" pTooltip="{{'View Edit'| translate}}" tooltipPosition="left"></i>
						<i *ngIf="isDeleteAble" (click)="deleteTransactionType(rowData)" class="fa-solid fa-xmark text-danger mx-1" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="columns?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<p-dialog [(visible)]="displayDialog" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
	</div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="reject()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="accept()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>
<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
