import { SpreadHourSetting } from '../model/v2/spread-hour.model';
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {CompanySearch} from '../../shared/model/search/compnay.search';

@Injectable({ providedIn: 'root' })
export class SpreadHourSettingServiceV2 extends AbstractServiceV2<SpreadHourSetting, CompanySearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY, '');
    }

    findAllByCompanyId(companyId: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/${companyId}/setting/spread-hour`).pipe(map(resp => resp));
    }

    deleteSpreadHour(companyId: any, id: any): Observable<any> {
        const url = `${this.baseUrlV2}/${companyId}/setting/spread-hour/${id}`;
        return this.http.delete(url).pipe(map((resp) => resp));
    }

    saveSpreadHour(companyId: any, entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/${companyId}/setting/spread-hour`;
        return this._http.post<any>(url, entity).pipe(map(res => res));
    }

    updateSpreadHour(companyId: any, id: any, entity: any): Observable<any> {
        const url = `${this.baseUrlV2}/${companyId}/setting/spread-hour/${id}`;
        return this._http.put<any>(url, entity).pipe(map(res => res));
    }
}
