import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';

import { DocumentDataType } from '../../model/document-data-type.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  document: any;

  @Output()
  documentChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  documentTemplate: any;

  @Input()
  disabled = false;

  @Input()
  createdAt;

  formGroup: UntypedFormGroup;

  DocumentDataType = DocumentDataType;
  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  showMessage = false;

  constructor(private fb: UntypedFormBuilder) {
    this.buildBaseDocumentForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['documentTemplate'] && changes['documentTemplate'].previousValue !== changes['documentTemplate'].currentValue
      && !_.isEmpty(this.documentTemplate)) {
      this.buildBaseDocumentForm();
    }

    if (changes['document'] && changes['document'].previousValue !== changes['document'].currentValue && !_.isEmpty(this.document)) {
      this.applyDocumentToForm();
    }

    if (changes['disabled'] && changes['disabled'].previousValue !== changes['disabled'].currentValue) {
      if (this.disabled) {
        this.formGroup.disable({ emitEvent: false });
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  buildBaseDocumentForm() {
    if (this.documentTemplate) {
      const formConfig = {};
      if (this.documentTemplate.fields) {
        this.documentTemplate.fields.forEach((field) => {
          formConfig[field.id]
            = field.required ? [null, Validators.required] : null;
        });
      }

      this.formGroup = this.fb.group(formConfig);

      // Listen to value changes on the form and convert data for submit
      this.formGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
        if (!this.document) {
          this.document = {};
        }
        _.assign(this.document, {
          documentTypeId: this.documentTemplate ? this.documentTemplate.id : null,
          status: this.documentTemplate ? this.documentTemplate.status : null,
          data: [],
        });

        Object.keys(e).forEach((fieldKey) => {
          if (fieldKey !== 'expiredOn' && fieldKey !== 'issuedOn' && fieldKey !== 'refNo') {
            let fieldValue = e[fieldKey];
            if (fieldValue instanceof Date || fieldValue instanceof moment) {
              fieldValue = moment(e[fieldKey]).isValid() ? moment(e[fieldKey]).format('MM/DD/YYYY') : '';
            }

            if (typeof fieldValue === 'boolean' || typeof fieldValue === 'number') {
              fieldValue = String(fieldValue);
            }
            this.document.data.push({ documentFieldId: fieldKey, value: fieldValue });
          }
        });

        this.documentChange.emit(this.document);
      });
    }
  }

  applyDocumentToForm() {
    if (this.formGroup && this.document) {
      console.log(this.document);
      const convertedDocumentData = _.assign(
        {
          // issuedOn: this.document.issuedOn ? moment.utc(this.document.issuedOn) : null,
          // expiredOn: this.document.expiredOn ? moment.utc(this.document.expiredOn) : null,
          // refNo: this.document.refNo,
        }, _.mapValues(_.keyBy(this.document.data, 'documentFieldId'), (v) => {
          const fieldDef: any = _.find(this.documentTemplate.fields, { id: v.documentFieldId });
          if (fieldDef) {
            switch (fieldDef.dataType) {
              case DocumentDataType[DocumentDataType.NUMBER]:
                return Number(v.value);
              case DocumentDataType[DocumentDataType.DECIMAL]:
                return Number(v.value);
              case DocumentDataType[DocumentDataType.BOOLEAN]:
                return (v.value ? v.value === 'true' : v.value).toString();
              case DocumentDataType[DocumentDataType.TEXT]:
                  if (fieldDef.documentFieldType && fieldDef.documentFieldType.name == 'Note') {
                      this.characterCount = (v.value || '').length;
                  }
                return v.value;
              case DocumentDataType[DocumentDataType.DATE]:
                //return moment(v.value).isValid() ? moment.utc(v.value) : null;
                return v.value;
              case DocumentDataType[DocumentDataType.DATETIME]:
                //return moment(v.value).isValid() ? moment.utc(v.value) : null;
                return v.value;
              default:
                return v.value;
            }
          }
        }));

      this.formGroup.patchValue(convertedDocumentData);
    }
  }

  validateDateTime(id, value) {
    const choosenTime = moment(value);
    const currentTime = moment();
    this.showMessage = false;
    if (choosenTime.isBefore(currentTime)) {
      this.formGroup.controls[id].setValue(null);
      this.showMessage = true;
    }
  }

  getCountWords(value) {
      let r1 = new RegExp('[\u3000-\u4DFF]', 'g');
      let r2 = new RegExp('[\u4E00-\u9FFF]', 'g');
      let r3 = new RegExp('[\u0E00-\u0E7F]', 'g');
      let string = value.trim().replace(r1, ' {PNK} ');
      string = string.replace(r2, ' {CJK} ');
      string = string.replace(r3, ' {THI} ');
      string = string.replace(/(\(|\)|\*|\||\+|\”|\’|_|;|:|,|\.|\?)/ig, " ");
      string = string.replace(/\s+/ig, " ");
      let a = string.split(/[\s+|\\|\/]/g);
      let count = 0;
      let pnkCounter = 0;
      let thiCounter = 0;
      for (let i = 0; i < a.length; i++) {
          if (a[i] == '{PNK}') {
              pnkCounter++;
          } else if (a[i] == '{THI}') {
              thiCounter++;
          } else if (a[i].length > 0) {
              count++;
          }
      }
      count += Math.ceil(pnkCounter / 3) + Math.ceil(thiCounter / 4);
      return count;
  }

  numberPasteMaxWordsLengthOnly(evt, length?: number) {
      // Handle paste
      let key;
      let numberOfWords = 0;
      if (evt.type === 'paste') {
          key = evt.clipboardData.getData('text/plain');
      }
      if (key) {
          let value = evt.target.value + key;

          /// return the total of the mixture
          numberOfWords = this.getCountWords(value);
      }
      if (numberOfWords > length) {
          evt.returnValue = false;
          if (evt.preventDefault) evt.preventDefault();
      }
  }


  characterCount = 0;
  countRemainChars(e) {
      let numberOfWords = 0;
      if (e.target.value) {
          numberOfWords = this.getCountWords(e.target.value);
      }
      if (numberOfWords > 300) {
          return false;
      }
      if (e.keyCode === 32 && numberOfWords == 300) {
          return false;
      }
      //this.remainWords = this.maxDescriptionWord - numberOfWords;
      //this.ref.markForCheck();
      this.characterCount = e.target.value.length;
      return true;
  }

  disableEdit() {
      if (!this.createdAt) return false;
      let after24Hours = moment().subtract(24, 'hours').toDate();
      let createdTime = moment(this.createdAt).toDate();
      if (createdTime.getTime() < after24Hours.getTime()) {
          return true;
      }
      return false;
  }
  clearDocument() {
      this.buildBaseDocumentForm();
      this.characterCount = 0;
  }
}
