import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Message, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {PaymentProfileService} from '../../../../service/payment-profile.service';
import {AuthService} from '../../../../../shared/service/auth/auth.service';
import {AesUtilService} from '../../../../../shared/service/aes-util.service';
import {PaymentMethodService} from '../../../../service/payment-method.service';
import {MonitoringDetailsService} from '../../../../../admin/process-monitor/service/monitoring-details.service';
import {PaymentCardDefaultService} from '../../../../service/payment-card-default.service';
import {PaymentNotificationService} from '../../../../service/payment-notification.service';
import * as _moment from 'moment';

declare var google: any;
@Component({
  selector: 'app-one-time-visa-card',
  templateUrl: './one-time-visa-card.component.html',
  styleUrls: ['./one-time-visa-card.component.scss']
})
export class OneTimeVisaCardComponent implements OnInit {

  @Output()
  displayChange = new EventEmitter();

  visaCardForm: UntypedFormGroup;

  registerCustomerPaymentId = null;

  cardValid: string = null;

  placeholder = 'Card Number';

  @Input()
  paymentCardType;

  @Input()
  formatCard = '9999 9999 9999 9999';

  @Input()
  selectedInvoice;

  @Input()
  selectedInvoices;
  @Input() key;
  cardNumber;
  cardTokenCode;
  cardToken = null;
  invoices;
  minDate;
  isStored = false;
  messageMonthError: string = '';
  msgs: Message[] = [];
  timeSpent = new Date();
  defaultCard;
  showConfirmAutoPayReminderDialog;
  isDisabled = false;

  @Input()
  functionImport;

  @Output()
  getDataSave = new EventEmitter();

  constructor(
      private fb: UntypedFormBuilder,
      private router: Router,
      private paymentService: PaymentProfileService,
      private authService: AuthService,
      private aesUtilService: AesUtilService,
      private messageService: MessageService,
      private paymentMethodService:PaymentMethodService,
      private monitoringDetailsService: MonitoringDetailsService,
      private paymentCardDefaultService: PaymentCardDefaultService,
      private paymentNotificationService: PaymentNotificationService
  ) { }

  ngOnInit() {
    this.visaCardForm = this.fb.group({
      cardNumber: [null, [Validators.required, this.onlyWhitespaceValidator()]],
      cardExpiry: [null, [Validators.required, this.onlyWhitespaceValidator()]],
      cvcNumber: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      cardName: [null, [Validators.required, this.onlyWhitespaceValidator()]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      country: [null, [Validators.required]],
      zipcode: [null, [Validators.required]],
      securelyStoreCard: []
    });

    this.visaCardForm.valueChanges.subscribe(e => {
      if (e) {
        //console.log('e form', e)
      }
    });
  }
  ngAfterViewInit() {
    const addressOneBox = new google.maps.places.SearchBox(document.getElementById("address"));
    addressOneBox.addListener("places_changed", () => {
      const places = addressOneBox.getPlaces();
      if (places.length == 0) {
        return;
      }
      this.handlePlaceDetail(places[0])
    });
  }
  submit() {

  }

  getPaymentProfile(userId) {
    this.paymentService.getPaymentProfile(this.paymentCardType, userId, 'AUTHORIZE_NET').subscribe((response: any) => {
      if (response) {
        if (!response.securelyStoreCard) {
          this.paymentService.deleteCard(response.registerPaymentId, response.cardToken, this.paymentCardType).subscribe(res => {
          }, (err) => {
            this.msgs.push({ severity: 'error', summary: 'ERROR', detail: err.error.message });
          })
        } else {
          this.registerCustomerPaymentId = response.registerPaymentId;
          this.cardNumber = 'XXXX-XXXX-XXX-' + response.lastFourNumbers;
          this.cardTokenCode = response.cardTokenCode;
          this.cardToken = response.cardToken;
        }

      }
    })
  }

  addPaymentMethod() {
    const formValues: any = this.visaCardForm.getRawValue();
    const cardExpiryTime = _moment(formValues.cardExpiry);
    if (!formValues.cardNumber || !formValues.cvcNumber || !cardExpiryTime) {
      this.msgs.push({ severity: 'warning', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
      return;
    }
    const month = cardExpiryTime.month() + 1;
    const realmonth = month >= 10 ? month : '0' + month;
    const cardDataStr = `cardNumber=${formValues.cardNumber ? formValues.cardNumber.replace(/\D+/g, '') : ''};cvcNumber=${formValues.cvcNumber};
    year=${cardExpiryTime.year()};month=${realmonth};zipCode=${formValues.zipcode};cardName=${formValues.cardName};address=${formValues.address};city=${formValues.city};country=${formValues.country};email=${formValues.email};phone=${formValues.phone}`;
    const encryptData = this.aesUtilService.encrypt(cardDataStr);
    const customer = this.authService.getUserInfo();

    const payload = {
      customerRegisterPaymentId: this.registerCustomerPaymentId,
      paymentCardType: this.paymentCardType,
      ownerId: customer.adminId,
      gatewayType: 'AUTHORIZE_NET',
      encryptData: encryptData,
      userName: this.authService.getUserInfo().username,
      firstName: this.authService.getUserInfo().firstName,
      lastName: this.authService.getUserInfo().lastName,
      email: this.authService.getUserInfo().email,
      securelyStoreCard: formValues.securelyStoreCard,
    };

    this.paymentService.createPaymentProfile(payload).subscribe((response: any) => {
      if (response && response.cardToken) {
        if (formValues.securelyStoreCard) {
          this.msgs.push({ severity: 'success', summary: 'created', detail: 'Information Saved Successfully' });
        } else {
          this.msgs.push({ severity: 'success', summary: 'created', detail: 'Information Verified Successfully' });
        }
        this.isStored = formValues.securelyStoreCard;
        this.registerCustomerPaymentId = (response as any).registerPaymentId;
        this.cardNumber = 'XXXX-XXXX-XXX-' + (response as any).lastFourNumbers;
        this.cardTokenCode = response.cardTokenCode;
        this.cardToken = response.cardToken;
      } else {
        this.msgs.push({ severity: 'error', summary: 'ERROR', detail: 'The card infor has problem. Please help check.' });
      }
    }, (err) => {
      this.msgs.push({ severity: 'error', summary: 'ERROR', detail: err.error.message });
    })

  }

  onBack(){
    this.displayChange.emit(true);
  }

  cancel(): void {
    this.displayChange.emit(true);
  }

  isExistedCardNumber(encryptedData) {
    this.cardValid = 'invalid';
    const payloadMethod = {
      gatewayType: 'AUTHORIZE_NET',
      encryptData: encryptedData,
    };
    this.paymentService.isExistedCardNumber(this.authService.getUserInfo().adminId, payloadMethod).subscribe(res => {
      const isExisted = res as any;
      if (!isExisted) {
        this.cardValid = 'valid';
      }
    }, err =>  this.cardValid = 'invalid')
  }

  validateCardNumber(event) {
    this.placeholder = 'Card Number';
    this.messageMonthError = '';
    const cardNumberValue = this.visaCardForm.get('cardNumber').value;
    const cvcNumber = this.visaCardForm.get('cvcNumber').value;
    const expirationDate = this.visaCardForm.get('cardExpiry').value;
    if (cardNumberValue && cvcNumber && expirationDate) {
      const encryptData = this.aesUtilService.encrypt(cardNumberValue);
      if (this.functionImport !== 'SIGN_IN') {
        this.isExistedCardNumber(encryptData);
      }
    } else {
      const cardExpiryTime = _moment(this.visaCardForm.get('cardExpiry').value);
      const momentYear = _moment();
      const valueMonth = cardExpiryTime.month() + 1;
      const valueYear = cardExpiryTime.year();
      console.log(cardExpiryTime);
      if (valueMonth*1 <= momentYear.month() + 1 && valueYear <= momentYear.year()) {
        // console.log(cardExpiryTime);
        this.messageMonthError = 'The month should be greater than current month';
        // this.cardValid = 'valid';
        // this.visaCardForm.get('cardExpiry').setValue(null);
      }
    }

    if (this.functionImport === 'SIGN_IN') {
      const formValues: any = this.visaCardForm.getRawValue();
      this.getDataSave.emit(formValues);
    }
  }

  focusPlaceHolder() {
    this.placeholder = '1111 2222 3333 4444';
  }

  cancelToHomepage(event) {
    if (event) {
      this.cardToken = null;
      this.displayChange.emit(true);
    }
  }

  async addPaymentProfile() {

    if (this.functionImport === 'SIGN_IN') {
      console.log('addPaymentProfile functionImport: ', this.functionImport);
      const formValues: any = this.visaCardForm.getRawValue();
      const cardExpiryTime = _moment(formValues.cardExpiry);
      if ((!formValues.cardName || formValues.cardName.trim() === '') || !formValues.cardNumber || !formValues.cvcNumber || !cardExpiryTime) {
        this.isDisabled = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
        return;
      }
      const month = cardExpiryTime.month() + 1;
      const realmonth = month >= 10 ? month : '0' + month;
      const cardDataStr = `${formValues.cardName.trim()};${formValues.cardNumber ? formValues.cardNumber.replace(/\s/g, '') : ''};${formValues.cvcNumber};${cardExpiryTime.year()};${realmonth};${formValues.zipcode};${formValues.address};${formValues.city};${formValues.country}`;
      const payload = {
        formValues: formValues,
        paymentCardType: this.paymentCardType,
        cardDataStr: cardDataStr,
      };
      this.getDataSave.emit(payload);
      return;
    }

    this.isDisabled = true;
    const formValues: any = this.visaCardForm.getRawValue();
    const cardExpiryTime = _moment(formValues.cardExpiry);
    if ((!formValues.cardName || formValues.cardName.trim() === '') || !formValues.cardNumber || !formValues.cvcNumber || !cardExpiryTime) {
      this.isDisabled = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'error', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
      return;
    }
    const month = cardExpiryTime.month() + 1;
    const realmonth = month >= 10 ? month : '0' + month;
    const cardDataStr = `${formValues.cardName.trim()};${formValues.cardNumber ? formValues.cardNumber.replace(/\s/g, '') : ''};${formValues.cvcNumber};${cardExpiryTime.year()};${realmonth};${formValues.zipcode};${formValues.address};${formValues.city};${formValues.country}`;
    /*const customer = this.authService.getUserInfo();
    if (this.authService.isClientRole()) {

    }*/
    const encrtyptedData: any = await this.paymentService.encryptCardDetails(cardDataStr, this.key).toPromise()
    const payload = {
      paymentCardType: this.paymentCardType,
      encryptDataCard: encrtyptedData.data,
      /*userId: this.authService.getUserInfo().adminId,
      firstName:this.authService.isClientRole() ?  customer.name : customer.firstName ,
      lastName: this.authService.isClientRole() ?  customer.name : customer.lastName,
      email: customer.email,*/
      userType: this.authService.getPaymentUserType(),
      phoneNumber:"",
    };
    return payload;
    /*await this.paymentService.createNewPaymentProfile(payload, this.key).subscribe((response: any) => {
        if (response && response.data.cardInfo && response.data.user) {
            this.messageService.clear();
            this.messageService.add({ severity: 'success', summary: 'created', detail: 'Information Saved Successfully' });
            this.defaultCard = null;
            if (this.authService.isClientRole()) {
                this.paymentCardDefaultService.getDefaultCard({
                    roleId: this.authService.getRoleLevel(),
                    userId: this.authService.getCurrentLoggedInId(),
                    companyId: this.authService.getCurrentCompanyId()
                  }).subscribe(r => {
                    const resObj: any = r;
                    if (resObj.status === 'SUCCESS' && resObj.data && (!resObj.data.autoPay || (!resObj.data.bankId && !resObj.data.cardId))) {
                        this.defaultCard = resObj.data;
                        if (!this.defaultCard.cardId && !resObj.data.bankId) {
                            this.defaultCard.cardId = response.data.cardInfo.id;
                        }
                        this.defaultCard.paymentCardType = payload.paymentCardType;
                        this.showConfirmAutoPayReminderDialog = true;
                    } else {
                        this.onNewCardAdded();
                    }
                }, () => this.onNewCardAdded());
            } else {
                this.onNewCardAdded();
            }
        } else {
            this.isDisabled = false;
            const args = {
                failed_to_add_new_card_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
                card_type: this.paymentCardType
            }
            this.saveMonitorDetails(args, 'Failed to Add New Card', (r) => {
                this.paymentNotificationService.sendPaymentFailedEmail(r, response.message);
            });
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: 'ERROR', detail: response.message });
        }
    }, (err) => {
        this.isDisabled = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: err.error.message });
        const args = {
            failed_to_add_new_card_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
            card_type: this.paymentCardType
        }
        this.saveMonitorDetails(args, 'Failed to Add New Card', (r) => {
            this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
        });
    })*/

  }

  handlePlaceDetail(placeDetail){
    const address_components = placeDetail.address_components
    const countryObj = address_components.find(x => x.types.includes('country'));
    const cityObj = address_components.find(x => x.types.includes("sublocality_level_1"));
    const zipCode = address_components.find(x => x.types.includes("postal_code"));
    this.visaCardForm.patchValue({
      address: placeDetail.name,
      city: cityObj ? cityObj.long_name : '',
      country: countryObj ? countryObj.long_name : '',
      zipcode: zipCode ? zipCode.long_name : '',
    })
  }

  saveMonitorDetails(args, type, callback?: Function){
    this.monitoringDetailsService.monitorAction(
        `${type}`,
        this.timeSpent,
        args,
        'complete',
        `${type}`,
        0,
        callback
    );
  }

  onNewCardAdded() {
    setTimeout(() => this.router.navigate(['app/payments/detail']), 1000);
    const args = {
      new_card_added_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
      card_type: this.paymentCardType
    }
    this.saveMonitorDetails(args, 'New Card Added')
  }

  acceptAutoPay() {
    if (this.defaultCard) {
      this.defaultCard.autoPay = true;
      this.defaultCard.roleId = this.authService.getRoleLevel(),
          this.defaultCard.userId = this.authService.getCurrentLoggedInId(),
          this.defaultCard.clientName = this.authService.getCurrentUsername()
      this.paymentCardDefaultService.setDefaultCard(this.defaultCard).subscribe(() => {
        this.showConfirmAutoPayReminderDialog = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auto pay active successfully' });
        this.onNewCardAdded();
      }, () => this.onNewCardAdded());
    } else {
      this.onNewCardAdded();
    }
  }

  rejectAutoPay() {
    this.defaultCard = null;
    this.showConfirmAutoPayReminderDialog = false;
    this.onNewCardAdded();
  }

  closeModal() {
    this.defaultCard = null;
    this.showConfirmAutoPayReminderDialog = false;
    this.router.navigate(['app/payments/detail'])
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onlyWhitespaceValidator() {
    return function validate(control: UntypedFormControl) {
      if (control) {
        const value = String(control.value);
        if (value.trim() === '') {
          return {
            whitespace: true
          };
        }
      }
      return null;
    };
  }

  changeValueForm() {
    console.log('changeValueForm functionImport: ', this.functionImport)
    if (this.functionImport === 'SIGN_IN') {
      const formValues: any = this.visaCardForm.getRawValue();
      console.log('changeValueForm formValues: ', formValues)
      this.getDataSave.emit(formValues);
    }
  }

}
