export class Email {
    to: string;
    from: string;
    cc: any;
    subject: string;
    body: string;
    attach_file_url?:any;
    type?:string;
    group?:string;
    toEmails?:any[];
    toEmailObjects?:any[];
    footerBody: string;
    createdByUsr?: string;
    documentIds?: number[];
    emailType?: string;
    emailCompanyId?: number;
}
