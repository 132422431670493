import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VrmGroupService extends AbstractService<any> {

  constructor(private http: HttpClient) { 
    super(http, Constants.ROUTES.VRM_GROUP, '');
  }

  search(options: any): Observable<any> {
    // return this._http.post<any>('http://localhost:8007/api/v2/operation/vrm-group/search', options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  saveVrmGroup(data: any) {
    // return this._http.post('http://localhost:8007/api/v2/operation/vrm-group', data).pipe(map(res => res));
    return this._http.post(this.baseUrlV2, data).pipe(map(res => res));
  }

  updateVrmGroup(entity: any): Observable<any> {
    // return this._http.put<any>(`http://localhost:8007/api/v2/operation/vrm-group`, entity).pipe(map(res => res));
    return this._http.put<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  getVrmGroupById(id: any) {
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/vrm-group/${id}`).pipe(map(res => res));
    return this._http.get<any>(this.baseUrlV2 + `${id}`).pipe(map(res => res));
  }

  exportRecipientSheet(id, data) {
      return this._http.post<any>(this.baseUrlV2 + `recipient-pdf/${id}`, data).pipe(map(res => res));
  }

  getHistory(params: any): Observable<any> {
      return this._http.post<any>(`${this.baseUrlV2}/history`, params).pipe(map(resp => resp));
  }
}
