import {
  Directive,
  Input,
  OnInit,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core";
import * as _ from "lodash";
// import {} from '@types/googlemaps';
declare let google: any;

@Directive({
  selector: "custom-direction-map",
})
export class DirectionMapDirective implements OnInit {
  @Input() origin;
  @Input() destination;
  @Input() zoom = 13;
  @Input() travelMode;

  constructor(private gmapsApi: GoogleMapsAPIWrapper) {}
  ngOnInit() {
    this.gmapsApi.getNativeMap().then((map) => {
      map.setZoom(13);
      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: this.origin,
        destination: this.destination,
        travelMode: this.travelMode ? this.travelMode : google.maps.TravelMode.DRIVING
      };

      directionsService.route(request, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          // Display the route on the map
          const directionsRenderer = new google.maps.DirectionsRenderer();
          directionsRenderer.setMap(map);
          directionsRenderer.setDirections(response);
        } else {
          // Handle error
        }
      });
    });
  }
}
