import { CompanyV2 } from "../model/v2/company.model";
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyHolidaySearchCriteria } from "../../shared/model/search/compnay.search"
import { CompanyHoliday } from "../model/v2/company-Holiday.model";
import { PaginatedResponse } from 'app/shared/model/PageResponse/paginated.response';

@Injectable({ providedIn: 'root' })
export class CompanyHolidayService extends AbstractServiceV2<CompanyHoliday, CompanyHolidaySearchCriteria> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY, '');
    }

    getHoliDayById(holidayId?: number): Observable<PaginatedResponse<CompanyHoliday>> {

        return this._http.get<PaginatedResponse<CompanyHoliday>>(`${this.baseUrlV2}` + '/holiday' + `/${holidayId}`,
            ).pipe(map(resp => resp));
    }

    getHoliDay(companyId: number, searchParams?: CompanyHolidaySearchCriteria): Observable<PaginatedResponse<CompanyHoliday>> {
        let params;
        if (searchParams)
            params = this.createParams(searchParams);

        return this._http.get<PaginatedResponse<CompanyHoliday>>(`${this.baseUrlV2}` + `/${companyId}` + '/holiday',
            {
                params: params
            }).pipe(map(resp => resp));
    }

    createHoliDay(holiday: CompanyHoliday) {
        return this._http.post(`${this.baseUrlV2}` + `/${holiday.companyId}` + '/holiday', holiday).pipe(map(resp => resp));
    }

    updateHoliday(holiday: CompanyHoliday) {
        return this._http.put(`${this.baseUrlV2}` + `/${holiday.companyId}` + `/holiday/${holiday.id}`, holiday).pipe(map(resp => resp));
    }

    deleteHoliday(compnayId: number, id: Number) {
        return this._http.delete(`${this.baseUrlV2}/${compnayId}/holiday/${id}`).pipe(map(data => data));
    }

    searchHoliday(options: any): Observable<PaginatedResponse<CompanyHoliday>> {
        const reqUrl = `${this.baseUrlV2}/holiday/search`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
      }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/holiday/exportPdf`, data).pipe(map(resp => resp));
    }
}