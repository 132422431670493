import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../../environments/environment';
import '../../../rxjs.operators';
import {Agency} from '../../../agency/model';
import {Company} from '../../../company/model/company.model';
import {Constants} from '../../model/constants';
import {UserActivity} from '../../model/user-activity.model';
import { Subject, Observer, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Long } from 'aws-sdk/clients/cloudsearch';
import { EmployeeService } from 'app/employee/service/employee.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

    public ROLE_ADMIN = 1;
    public ROLE_EMPLOYEE = 7;
    public ROLE_UNDEFINED = 1000;
    public ROLE_COMPANY_ADMIN = 3;
    public ROLE_SUB_COMPANY_ADMIN = 4;
    public ROLE_CLIENT = 9;
    public ROLE_GUEST = 10;
    public ROLE_CHILD_CLIENT = 14;

    private isUserLoggedIn: boolean;
    private isAdmin: boolean;
    private apiUrl = Constants;
    watchAuthChange: Observer<any>;
    private authorizationSub = new BehaviorSubject<boolean>(this.authenticateUser());
    private authorizationSub8 = new BehaviorSubject<boolean>(this.authenticateUser8());

    constructor(private cookieService: CookieService, private http: HttpClient) {
        Observable.create((observer: Observer<any>) => {
            this.watchAuthChange = observer;
        });
    }

    authenticateUser8(): (boolean) {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        if (getCookieObj && getCookieObj.token && getCookieObj.permission && getCookieObj.role.level !== 8) {
            this.isUserLoggedIn = true;
        } else {
            this.isUserLoggedIn = false;
        }
        return this.isUserLoggedIn;
    }

    /*init check*/
    /**
     * If user logged in
     * @returns {boolean}
     */
    authenticateUser(): (boolean) {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        if (getCookieObj && getCookieObj.token && getCookieObj.permission) {
            this.isUserLoggedIn = true;
        } else {
            this.isUserLoggedIn = false;
        }
        return this.isUserLoggedIn;
    }

    adminRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === 1 || level === 2 || level === 3 || level === 4 || level === 5 || level === 6 || level === 12) {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        return this.isAdmin;
    }

    isSuper(): boolean {
        return (this.getRoleLevel() === 1);
    }

    isSubSuper(): boolean {
        return (this.getRoleLevel() === 2);
    }

    isCompanyAd(): boolean {
        return (this.getRoleLevel() === 3);
    }

    isSubCompanyAd(): boolean {
        return (this.getRoleLevel() === 4);
    }

    isAgencyAd(): boolean {
        return (this.getRoleLevel() === 5);
    }

    isSubAgencyAd(): boolean {
        return (this.getRoleLevel() === 6);
    }

    isCEOAdmin(): boolean {
        return (this.getRoleLevel() === 12);
    }

    getCurrentCompany(): Company {
        const cookieJSON = localStorage.getItem('_globals');
        let cookieObj: any;
        if (cookieJSON) {
            cookieObj = JSON.parse(cookieJSON);
        }

        return cookieObj && cookieObj.token && cookieObj.companyId ? cookieObj.companyId : null;
    }

    getCurrentCompanyId(): any {
        const cookieJSON = localStorage.getItem('_globals');
        let cookieObj: any;
        if (cookieJSON) {
            cookieObj = JSON.parse(cookieJSON);
        }

        return cookieObj && cookieObj.token && cookieObj.companyId ? cookieObj.companyId : null;
    }

    isCurrentCompanyHasBranch() {
        const currentCompany = this.getCurrentCompany();
        return ((this.isSuper() || this.isSubSuper()) || (currentCompany && currentCompany.branchAgency !== 0)) ? true : false;
    }

    getCurrentAgency(): Agency {
        const cookieJSON = localStorage.getItem('_globals');
        let cookieObj: any;
        if (cookieJSON) {
            cookieObj = JSON.parse(cookieJSON);
        }

        return cookieObj && cookieObj.token && cookieObj.agency ? cookieObj.agency : null;
    }

    getCurrentEmployee(): number {
        return this.isEmployeeRole() ? this.getCurrentLoggedInId() : null;
    }

    isFrontDesk(): (boolean) {
        return this.getRoleLevel() === 8;
    }

    isEmployeeRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === this.ROLE_EMPLOYEE) {
            return true;
        }
        return false;
    }

    isContractorRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === 13) {
            return true;
        }
        return false;
    }

    isGuestRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === this.ROLE_GUEST) {
            return true;
        }
        return false;
    }

    isClientRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === this.ROLE_CLIENT) {
            return true;
        }
        return false;
    }

    isChildClientRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === this.ROLE_CHILD_CLIENT) {
            return true;
        }
        return false;
    }


    isCompanyAdminRole(): (boolean) {
        const level = this.getRoleLevel();
        if (level === this.ROLE_COMPANY_ADMIN || level === this.ROLE_SUB_COMPANY_ADMIN) {
            return true;
        }
        return false;
    }

    getRoleLevel(): number {
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.role && getCookieObj.role.level ? getCookieObj.role.level : 1000;
    }

    getCurrentLoggedInId(): number {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.adminId ? getCookieObj.adminId : 0;
    }

    getCurrentUsername(): string {
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.username ? getCookieObj.username : '';
    }

    getUserInfo() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj;
    }

    getCurrentLoggedInName() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }

        return getCookieObj && getCookieObj.token ? getCookieObj.firstName + ' ' + getCookieObj.lastName : '';
    }

    getCurrentPIN() {
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }

        return getCookieObj && getCookieObj.token ? getCookieObj.pin : '';
    }

    get authObservable8(): Observable<boolean> {
        return this.authorizationSub8.asObservable();
    }
    setAuthObservable8() {
        if (!!this.authorizationSub8) {
            if (this.authenticateUser8()) {
                this.authorizationSub8.next(true);
            } else {
                this.authorizationSub8.next(false);
            }
        }
    }

    get authObservable(): Observable<boolean> {
        return this.authorizationSub.asObservable();
    }

    setAuthObservable() {
        if (!!this.authorizationSub) {
            if (this.authenticateUser()) {
                this.authorizationSub.next(true);
            } else {
                this.authorizationSub.next(false);
            }

            // this.watchAuthChange.next(this.authorizationSub);
            // this.watchAuthChange.complete();
        }
        this.setAuthObservable8();
    }

    logout() {
        let reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.LOGOUT}`;
        if (localStorage.getItem('_user') === 'freelancer') {
            // reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.FREELANCER}/${this.apiUrl.URI.FREELANCER.LOGOUT}`;
            reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.FREELANCER}/${this.getCurrentLoggedInId()}/online-status/0`;
        }

        if (localStorage.getItem('_user') === 'employee') {
            reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.EMPLOYEE}/${this.getCurrentLoggedInId()}/online-status/0`;
        }

        if (localStorage.getItem('_user')  === 'admin') {
            reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.getCurrentLoggedInId()}/online-status/0`;
        }

        if (localStorage.getItem('_user')  === 'client') {
            reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.CRM.CLIENT}/${this.getCurrentLoggedInId()}/online-status/0`;
        }

        if (localStorage.getItem('_user')  === 'guess') {
            reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.MEMBERSHIP}/${this.getCurrentLoggedInId()}/online-status/0`;
        }

        this.isAdmin = false;
        const tmpCookie = JSON.parse(localStorage.getItem('_globals'));
        let authHeader = '';
        if (tmpCookie && tmpCookie.token) {
            authHeader = tmpCookie.token;
        }
        const reqHeaders: HttpHeaders = new HttpHeaders({'auth_token': authHeader});
        return this.http.post(reqUrl, {}, {headers: reqHeaders}).pipe(map(res => res));
    }

    logUserActivity() {
        const userActivity = new UserActivity();
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }

        if (getCookieObj && getCookieObj.token) {
            userActivity.userId = getCookieObj.adminId;
            userActivity.name = getCookieObj.firstName + ' ' + getCookieObj.lastName;
            userActivity.username = getCookieObj.username;
            userActivity.type = getCookieObj.type;
            userActivity.companyId = getCookieObj.companyId;
            userActivity.loggedIn = 0;
            userActivity.loginStatus = 'Logged out';
            userActivity.applicationType = 'Computer';
            userActivity.profilePhoto = getCookieObj.profileId;
            const reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.OPERATOR}/${this.apiUrl.URI.ADMIN.USERACTIVITY}`;
            this.http.post(reqUrl, userActivity).pipe(map(res => res)).subscribe(res => {
                console.log(res);
            }, error1 => console.log(error1));
        }
    }

    getUserActivities(options): Observable<any> {
        const reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.GETALLACTIVITIES}`;
        return this.http.get(reqUrl, {params: options}).pipe(map(res => res));
    }

    getPageUserActivities(options): Observable<any> {
        const reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.OPERATOR}/${this.apiUrl.URI.ADMIN.GETALLACTIVITIESPAGE}`;
        return this.http.post(reqUrl, options).pipe(map(res => res));
    }

    getLatestUserActivities(companyId, index, size): Observable<any> {
        const query = `companyId=${companyId}&index=${index}&size=${size}`;
        const reqUrl = `${environment.v2_server_backend}/${this.apiUrl.ROUTES.OPERATOR}/${this.apiUrl.URI.ADMIN.LATEST_ACTIVITIES}?${query}`;
        return this.http.get(reqUrl).pipe(map(res => res));
    }

    getAllUsersCount(options?: any): Observable<any> {
        const reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.ALLUSERS}`;
        return this.http.get(reqUrl, {params: options}).pipe(map(res => res));
    }

    getLoginsToday(options?: any): Observable<any> {
        const reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.GETLOGINSTODAY}`;
        return this.http.get(reqUrl, {params: options}).pipe(map(res => res));
    }

    createUserActivity(activity: UserActivity): Observable<any> {
        const reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.USERACTIVITY}`;
        return this.http.post(reqUrl, activity).pipe(map(res => res));
    }

    getLoggedInUserMenuList() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.menuList ? getCookieObj.menuList : [];
    }

    getLoggedInUserGroup() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.group ? getCookieObj.group : null;
    }

    getLoggedInUserEditList() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.editList ? getCookieObj.editList : [];
    }

    getLoggedInUserDeleteList() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.deleteList ? getCookieObj.deleteList : [];
    }

    setSipmlUser(user) {
        localStorage.setItem('_sipml_user', JSON.stringify(user));
      }

      getSipmlUser() {

        const infoString = localStorage.getItem('_sipml_user');

        if (infoString) {
          try {
            return JSON.parse(infoString);
          } catch (e) {
            return {};
          }
        } else {
          return {};
        }
      }

    getLoggedInActionsList() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.actionList ? getCookieObj.actionList : [];
    }

    getUserLogInTime(): any {
        const cookieJSON = localStorage.getItem('_globals');
        let cookieObj: any;
        if (cookieJSON) {
            cookieObj = JSON.parse(cookieJSON);
        }

        return cookieObj && cookieObj.token && cookieObj.loginTime ? cookieObj.loginTime : null;
    }

    setUserLoginTime(loggedinTime: any): any {
        const toSetLocalJSON = localStorage.getItem('_globals');
        const toSetCookieJSON = this.cookieService.get('_globals');
        let localObj: any, cookieObj: any;
        if (toSetLocalJSON) {
            localObj = JSON.parse(toSetLocalJSON);
            localObj.loginTime = loggedinTime;
        }
        // if (toSetCookieJSON){
        //     cookieObj = JSON.parse(toSetCookieJSON);
        //     cookieObj.loginTime = loggedinTime;
        // }
        try {
            cookieObj = JSON.parse(toSetCookieJSON);
            cookieObj.loginTime = loggedinTime;
        } catch (e) {
            console.log('#Warning: invalid cookies object!');
        }
        this.cookieService.set('_globals', JSON.stringify(cookieObj));
        localStorage.setItem('_globals', JSON.stringify(localObj));
    }

    refreshToken() {
        const tmpCookie = JSON.parse(localStorage.getItem('_globals'));
        let authHeader = '';
        if (tmpCookie && tmpCookie.token) {
            authHeader = tmpCookie.token;
        }
        // let reqHeaders: HttpHeaders = new HttpHeaders({'token': authHeader});
        // const reqUrl = `${environment.server_ip}/${this.apiUrl.ROUTES.ADMIN}/${this.apiUrl.URI.ADMIN.REFRESH_TOKEN}`;
        // return this.http.get(reqUrl, {headers: reqHeaders}).toPromise().then((res: any) =>{
        //     const resObj = res;
        //     if (resObj.status === 'SUCCESS' && res.data) {
        //         const token = res.data;
        //         tmpCookie.token = token;
        //         // console.log('refreshToken token', token);
        //         localStorage.setItem('_globals', JSON.stringify(tmpCookie));
        //     }
        // })
    }

    getUserConfig() {
        const getCookieJSON = localStorage.getItem('_config');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj;
    }

    getEmployeeType() {
        const getCookieJSON = localStorage.getItem('_config');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj.type;
    }

    getUserType() {
        return this.adminRole() ? 'ADMIN' : this.isEmployeeRole() ? 'EMPLOYEE' : this.isGuestRole() ? 'GUEST' : this.isClientRole() ? 'CLIENT' : 'FREELANCER';
    }

    /**
     * return normal contractor: 0 - member team contractor: 1 - Leader contractor 2
     */
    getContractorType() {
        const getCookieJSON = localStorage.getItem('_config');
        let getCookieObj: any;
        let type = 0;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
         // normal contractor: 0 - member team contractor: 1 - Leader contractor 2 - other account: 3
        if (localStorage.getItem('_user') === 'freelancer') {
            type = 0;
            if (getCookieObj.isTeamLeader === 'YES') {
                type = 2;
            } else if (getCookieObj.teamId && getCookieObj.teamId > 0) {
                type = 1;
            }
        } else {
            type = 3;
        }
        return type;
    }

    getContractorTeamId() {
        const getCookieJSON = localStorage.getItem('_config');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
            return getCookieObj.teamId;
        }
        return 0;
    }

    getCurrentLoggedInEmail() {
        // const getCookieJSON = this.cookieService.get('_globals');
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }

        return getCookieObj && getCookieObj.token ? getCookieObj.email : null ;
    }

    getLoginUserType() {
        if (localStorage.getItem('_user') === 'admin') {
            return 1;
        }

        if (localStorage.getItem('_user') === 'employee') {
           return 2;
        }

        if (localStorage.getItem('_user') === 'freelancer') {
            return 3;
        }

        if (localStorage.getItem('_user') === 'client') {
            return 4;
        }

        if (localStorage.getItem('_user') === 'guess') {
            return 5;
        }

        if (localStorage.getItem('_user') === 'child_client') {
            return 6;
        }

        return 0;
    }

    getPaymentUserType(): string {
        if (this.isClientRole() || this.isChildClientRole()) {
            return 'CLIENT';
        }
        if (this.isContractorRole()) {
            return 'CONTRACTOR';
        }
        if (this.isEmployeeRole()) {
            return 'EMPLOYEE';
        }
        return 'ADMIN';
    }

    getLoggedInUserEditListRoleEmployee() {
        const getCookieJSON = localStorage.getItem('_globals');
        let getCookieObj: any;
        if (getCookieJSON) {
            getCookieObj = JSON.parse(getCookieJSON);
        }
        return getCookieObj && getCookieObj.token && getCookieObj.group && getCookieObj.group.editList ? getCookieObj.group.editList : [];
    }
}
