import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Constants} from '../../shared/model/constants';
import {AbstractService} from '../../shared/service/abstract.service';
import {environment} from '../../../environments/environment';
import {PayrollSetting} from '../model/payroll-setting.model';
import {Employee} from '../../employee/model/employee.model';
import * as MOMENT from 'moment';
import {PayrollHoliday} from '../model/payroll-holiday.model';
import {Page} from '../../shared/model/page.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PayrollHolidayService extends AbstractService<PayrollHoliday> {


  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PAYROLL, '');
  }

    getById(id: Number): Observable<PayrollHoliday> {
        return this._http
            .get<PayrollHoliday>(`${this.baseUrlV2}${Constants.URI.PAYROLL.PAYROLL_HOLIDAY}/${id}`)
            .pipe(map(res => res));
    }

    findById(id: Number): Observable<any> {
        const url = `${environment.v2_server_ip}/company/api/v2/company/payrollHoliday/${id}`;
        return this._http.get<any>(url).pipe(map(res => res));
    }

    getAllByPayrollSetting(id: Number): Observable<PayrollHoliday[]> {
        return this._http
            .get<PayrollHoliday[]>(`${this.baseUrlV2}${Constants.URI.PAYROLL_HOLIDAY}/bypayroll/${id}`)
            .pipe(map(res => res));
    }

    getByPayrollSetting(id: Number, params: any): Observable<Page<PayrollHoliday>> {
        return this._http
            .get<Page<PayrollHoliday>>(`${this.baseUrlV2}${Constants.URI.PAYROLL.PAYROLL_HOLIDAY}/bypayroll/${id}`, {params: params})
            .pipe(map(res => res));
    }

    createAll(entitys: any[]): Observable<any[]> {
        return this._http
            .post<PayrollHoliday[]>(`${this.baseUrlV2}${Constants.URI.PAYROLL.PAYROLL_HOLIDAY}/createAll`, entitys)
            .pipe(map(res => res));
    }

    updateHoliday(entity: any): Observable<any> {
        return this._http
            .put<PayrollHoliday>(`${environment.v2_server_ip}/company/api/v2/company/payrollHoliday/${entity.id}`, entity)
            .pipe(map(res => res));
    }

    savePayrollHoliday(settingData: any): Observable<any> {
        return this._http
            .post<any>(`${this.baseUrlV2}${Constants.URI.PAYROLL_HOLIDAY}`, settingData)
            .pipe(map(res => res));
    }
    delete(id: Number) {
        return this._http
            .delete(`${environment.v2_server_ip}/company/api/v2/company/payrollHoliday/${id}`)
            .pipe(map(data => data));
    }

    deleteAll(ids: any) {
        return this._http.post(`${environment.v2_server_ip}/company/api/v2/company/payrollHoliday/delete`, ids).pipe(map(data => data));
    }
}
