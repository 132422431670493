<p-panel id="table-ppanel">
  <p-header>Gross Income</p-header>
  <div class="ui-g justify-content-center">
    <div class="ui-g">
      <table>
        <tr>
          <td colspan="2" class="text-center font-weight-bold td-gray">{{'Gross Income' | translate}}</td>
        </tr>
        <tr>
          <td colspan="2" class="text-center font-weight-bold td-gray">{{'Income' | translate}}</td>
        </tr>
        <tr>
          <td>{{'Service Income' | translate}}</td>
          <td class="text-center">{{totalIncome | number : '2.2-2'}}</td>
        </tr>
        <tr>
          <td>{{'Total Income' | translate}}</td>
          <td class="text-center">{{totalIncome | number : '2.2-2'}}</td>
        </tr>
        <tr>
          <td colspan="2" class="text-center font-weight-bold td-gray">Expenses</td>
        </tr>
        <tr>
          <td>{{'Total Expenses' | translate}}</td>
          <td class="text-center">{{totalExpenses | number : '2.2-2'}}</td>
        </tr>
        <tr>
          <td class="td-red">{{'Gross Income' | translate}}</td>
          <td class="text-center">{{(totalIncome - totalExpenses) | number : '2.2-2'}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="ui-g justify-content-center">
    <div class="ui-g">
      <table>
        <tr>
          <td colspan="2" class="text-center font-weight-bold td-red">{{'Detail Income Statement' | translate}}</td>
        </tr>
        <tr>
          <td>{{'Service Income' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{'Total Income' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2" class="text-center font-weight-bold td-gray">{{'Expenses' | translate}}</td>
        </tr>
        <tr>
          <td>{{'Total Rent Expenses' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{'Total Supplies Expenses' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{'Contractors Expenses' | translate}}</td>
          <td class="text-center">{{totalContractorExpenses | number : '2.2-2'}}</td>
        </tr>
        <tr>
          <td>{{'Payroll Expenses' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{'Total Insurance Expenses' | translate}}</td>
          <td></td>
        </tr>
        <tr>
          <td>{{'Total Insurance Expenses' | translate}}</td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</p-panel>
