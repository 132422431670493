export const CMD_ACTION = {
    ADD: 'add',
    EDIT: 'edit',
    CANCEL: 'cancel',
    VIEW : 'view'
};

export const AGENCY_PAGE = {
    LIST: 'agency_list',
    FORM: 'agency_form',
    SETTING_FORM: 'agency_seting_form',
    HOLIDAY_MANAGEMENT: 'holiday_management',
    PAYROLL_SETTING: 'payroll_setting'
};
