import { DocSignService } from '../../service/doc-sign.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { MessageService } from 'primeng/api';
import { TranslatePipe } from '@ngx-translate/core';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnotationEditorLayerRenderedEvent, NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { SignaturePad } from 'angular2-signaturepad';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { DocSignUtils } from 'app/doc-sign/utils/doc-sign.utils';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-doc-sign-recipient-signing',
  templateUrl: './doc-sign-recipient-signing.component.html',
  styleUrls: ['./doc-sign-recipient-signing.component.scss'],
  providers: [AuthService, DocSignService]
})
export class DocSignRecipientSigningComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfViewer') pdfViewer!: NgxExtendedPdfViewerComponent;

  @ViewChild('op') op!: any;
  pdfDoc!: PDFDocument;

  viewInit = false;
  recipient: any = {};
  sessionSign: string;
  docSignFileBlobUrl: any;

  loadDocSign = false;

  editorLayerListenPages = new Set<number>();

  addedEditorIds = new Set<string>();

  allEditorSignatureMap: { [key: string]: string } = {};

  currentPage!: number;

  addSignatureDialog = false;
  replaceSignatureDialog = false;

  signatureImgTemplate!: Blob;
  signatureDrawTemplate!: Blob;
  signatureTextTemplate!: Blob;
  signatureTextTemplateUrl: any;


  selectedSignatureBlob!: Blob;
  selectedDateSignedBlob!: Blob;
  selectedFullNameBlob!: Blob;

  drawType = 0;

  sending = false;

  drawSignatureType = 0;
  signaturePadConfig = {
    'minWidth': 3,
    'maxWidth': 3,
    'canvasWidth': 520,
    'canvasHeight': 200,
    'canvasBorder': 'none',
  };

  selectedEditorId;
  selectedSignatureId;
  summiting = false;

  penWidths: number[] = [1, 2, 3, 4, 5];
  selectedPenWidth = 3;

  liveUpdateSubscription: Subscription;

  constructor(
    private documentService: DocumentsService,
    private docSignService: DocSignService,
    private messageService: MessageService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private docSignUtils: DocSignUtils,
    private db: AngularFireDatabase
  ) {
    this.route.params.subscribe(params => this.sessionSign = params.id);
  }

  async ngOnInit() {
    if (this.sessionSign) {
      this.docSignUtils.createSignatureImgTemplate((blob) => {
        this.signatureImgTemplate = blob;
      });
      this.docSignUtils.textToBlob((blob) => {
        this.selectedDateSignedBlob = blob;
      }, moment().format('MM/DD/YYYY'));
      this.initPage();
    } else {
      this.router.navigate(['app/doc-sign/form'])
    }
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  initPage() {
    this.loadDocSign = false;
    this.docSignService.findBySessionSign(this.sessionSign).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {

        this.recipient = resObj.data;

        if (this.recipient && !this.recipient.allSigned) {
          if (this.liveUpdateSubscription) {
            this.liveUpdateSubscription.unsubscribe();
          }
          this.liveUpdateSubscription = this.db.object(`/DocSign/${this.recipient.docSignId}/${this.sessionSign}`).valueChanges().pipe(debounceTime(200)).subscribe((event: any) => {
            if (event) {
              this.initPage();
            }
          });
        }

        if (this.recipient && this.recipient.signed) {
          if (this.recipient.allSigned) {
            this.recipient.allSignedRecipients.forEach(r => {
              if (r.signatureSignedFields) {
                if (r.signatureFields) {
                  r.signatureFields = [];
                }
                r.signatureFields = JSON.parse(r.signatureSignedFields);
              }
            });
          } else {
            if (this.recipient && this.recipient.signatureSignedFields) {
              this.recipient.signatureFields = JSON.parse(this.recipient.signatureSignedFields);
            }
          }
        } else {
          this.docSignUtils.textToBlob((blob) => {
            this.signatureTextTemplate = blob;
            this.signatureTextTemplateUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.signatureTextTemplate));
          }, this.recipient.name, 30);
          this.docSignUtils.textToBlob((blob) => {
            this.selectedFullNameBlob = blob;
          }, this.recipient.name);
          if (this.recipient.signatureFields) {
            this.recipient.signatureFields = JSON.parse(this.recipient.signatureFields);
          }
        }

        this.documentService.downloadFile(this.recipient.documentId).subscribe(async (response: any) => {
          const blob = new Blob([response.body], { type: 'application/octet-stream' });
          if (this.docSignUtils.isPdfType(this.recipient.documentType)) {
            this.pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
            if (this.recipient.signed) {
              this.docSignFileBlobUrl = URL.createObjectURL(new Blob([await this.loadSignedFields(this.recipient)], { type: 'application/pdf' }));
            } else {
              this.docSignFileBlobUrl = URL.createObjectURL(blob);
            }
          } else if (this.docSignUtils.isImageType(this.recipient.documentType)) {
            const pdfBlob = await this.docSignUtils.imageToPDF(blob, this.recipient.documentType);
            this.pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
            if (this.recipient.signed) {
              this.docSignFileBlobUrl = URL.createObjectURL(new Blob([await this.loadSignedFields(this.recipient)], { type: 'application/pdf' }));
            } else {
              this.docSignFileBlobUrl = URL.createObjectURL(pdfBlob);
            }
          } else if (this.docSignUtils.isDocType(this.recipient.documentType)) {
            const convertResponse: any =  await this.docSignService.convertDocxToPDF(new File([blob], new Date().getTime() + '.docx', { type: 'application/docx' })).toPromise();
            const convertedBlob = new Blob([convertResponse.body], { type: 'application/octet-stream' });
            this.pdfDoc = await PDFDocument.load(await convertedBlob.arrayBuffer());
            if (this.recipient.signed) {
              this.docSignFileBlobUrl = URL.createObjectURL(new Blob([await this.loadSignedFields(this.recipient)], { type: 'application/pdf' }));
            } else {
              this.docSignFileBlobUrl = URL.createObjectURL(convertedBlob);
            }
          }
          if (this.recipient.allSigned && !this.recipient.signedDocumentId) {
            const signedBlob = await this.pdfDoc.save();
            const fileName = 'DocSign_' + this.recipient.docSignId + '_' + new Date().getTime() + '.pdf';
            this.documentService.uploadFileEntity(new File([signedBlob], fileName, { type: 'application/pdf' }), 'OperrSign', this.recipient.docSignId, 'OperrSign').subscribe((fileResponse: any) => {
              this.docSignService.signedDocument(this.recipient.docSignId, fileResponse.data.id).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Sent'), detail: this.translatePipe.transform('The document will be sent to your email when both parties signed the document, thank you.') });
              });
            });
          }
          this.loadDocSign = true;
        });

      }
    });
  }

  ngOnDestroy() {
    if (this.liveUpdateSubscription) {
      this.liveUpdateSubscription.unsubscribe();
    }
  }

  pdfLoaded() {
    if (this.pdfViewer) {
      this.currentPage = 1;
      this.pdfViewer.service.scrollPageIntoView(this.currentPage, {
        top: 0,
        left: 0
      });
      (window as any).PDFViewerApplication.eventBus.dispatch("switchannotationeditormode", {
        mode: 13
      });
    }
  }

  annotationEditorLayerRendered(event: AnnotationEditorLayerRenderedEvent) {
    if (this.editorLayerListenPages.has(event.pageNumber)) {
      return;
    }
    const annotationEditorLayer = (event.source as any).annotationEditorLayer;
    if (!annotationEditorLayer) {
      return;
    }
    this.editorLayerListenPages.add(event.pageNumber);
    setTimeout(() => {
      if (this.recipient.allSigned) {
        this.recipient.allSignedRecipients.forEach(recipient => {
          this.renderRecipientFields(annotationEditorLayer.annotationEditorLayer, event.pageNumber - 1, recipient);
        });
      } else {
        this.renderRecipientFields(annotationEditorLayer.annotationEditorLayer, event.pageNumber - 1, this.recipient);
      }

    }, 500);
  }

  pageChange(page: number) {
    this.currentPage = page;
  }

  hexToRgba(hex: string, opacity: number): string {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const alpha = opacity >= 0 && opacity <= 1 ? opacity : 1;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  renderRecipientFields(annotationEditorLayer, currentPage, recipient) {
    let currentId = 0;
    if (annotationEditorLayer) {
      currentId = annotationEditorLayer.getCurrentId();
    }
    if (recipient.signatureFields && recipient.signatureFields.length > 0) {
      for (let i = 0; i < recipient.signatureFields.length; i++) {
        if (recipient.signed) {
          continue;
        }
        let newEditorId = 'pdfjs_internal_editor_' + currentId;
        const editor = recipient.signatureFields[i];
        if (editor.pageIndex === currentPage) {
          editor.editorId = newEditorId;
          if (editor.fieldType !== DocSignUtils.SIGNATURE_FIELD) {
            editor.annotationType = 3;
            editor.fontSize = 19;
            editor.color = [0, 0, 0];
            if (editor.fieldType === DocSignUtils.DATE_FIELD || editor.fieldType === DocSignUtils.FULL_NAME_FIELD) {
              editor.customDefaultContent = '...';
              editor.disabledEditting = true;
            } else {
              editor.customDefaultContent = 'Title...';
              if (recipient.signed) {
                editor.disabledEditting = true;
              }
            }
          }
          this.pdfViewer.service.addEditorAnnotation(editor);
          currentId++;

        }
      }
    }
    this.pdfViewer.service.getSerializedAnnotations()?.forEach((annotationEditor: any) => {
      this.dispatchImageEvent(annotationEditorLayer, annotationEditor, recipient);
    });
    if (annotationEditorLayer.getEditors()) {
      annotationEditorLayer.getEditors().forEach(editor => {
        editor._isDraggable = false;
      });
    }
    setTimeout(() => {
      annotationEditorLayer.getUiManager().unselectAll();
    }, 500);
  }

  dispatchImageEvent(annotationEditorLayer, editor, recipient) {
    if (this.addedEditorIds.has(editor.editorId)) {
      return;
    }
    this.addedEditorIds.add(editor.editorId);
    if (editor.fieldType === DocSignUtils.TITLE_FIELD) {
      if (recipient.signed) {
        return;
      }
      document.getElementById(editor.editorId + '-editor').addEventListener('focusout', (focusoutEvent) => {
        console.log(focusoutEvent);
        (window as any).PDFViewerApplication.eventBus.dispatch("switchannotationeditormode", {
          mode: 13
        });
        let newContent = (focusoutEvent.target as any).textContent;
        if (annotationEditorLayer.getEditors()) {
          annotationEditorLayer.getEditors().forEach(e => {
            const annotationEditor = e.serialize();
            if (annotationEditor.annotationType === 3 && annotationEditor.fieldType === DocSignUtils.TITLE_FIELD) {
              e.updateContent(newContent);
            }
            e._isDraggable = false;
          });
        }
      });
      return;
    }
    if (editor.fieldType === DocSignUtils.DATE_FIELD || editor.fieldType === DocSignUtils.FULL_NAME_FIELD) {
      if (recipient.signed) {
        return;
      }
      let content;
      if (editor.fieldType === DocSignUtils.DATE_FIELD) {
        content = moment().format('MM/DD/YYYY');
      } else {
        content = recipient.name;
      }
      if (annotationEditorLayer.getEditors()) {
        annotationEditorLayer.getEditors().forEach(e => {
          if (editor.editorId === e.id) {
            e.updateContent(content);
          }
          e._isDraggable = false;
        });
      }
      return;
    }
    const inputFile = (document.getElementById(editor.editorId + '_input') as HTMLInputElement);
    if (!inputFile) {
      return;
    }
    let blob;
    if (recipient.signed) {
      const data = recipient.signatureFields.find(r => r.fieldType === DocSignUtils.SIGNATURE_FIELD);
      if (data) {
        blob = this.docSignUtils.base64ToBlob(data.signedData);
      }
    } else {
      blob = this.signatureImgTemplate;
    }
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(new File([blob], 'image.png', { type: 'image/png' }));
    inputFile.files = dataTransfer.files;
    const changeEvent = new Event('change');
    inputFile.dispatchEvent(changeEvent);
    const editorElement = document.getElementById(editor.editorId);
    if (editor.fieldType === DocSignUtils.SIGNATURE_FIELD && !recipient.signed) {
      editorElement.addEventListener('click', (event: any) => {
        let editorId = event.target.id ? event.target.id : event.target.parentElement.id;
        this.selectedEditorId = editorId;
        if (Object.keys(this.allEditorSignatureMap).length > 0 && this.selectedSignatureBlob) {
          this.adoptAndSign(this.selectedEditorId, true);
        } else {
          this.addSignatureDialog = true;
        }
      });

      setTimeout(() => {
        if (editor.fieldType === DocSignUtils.SIGNATURE_FIELD && !recipient.signed) {
          editorElement.style.backgroundColor = this.hexToRgba(recipient.color, 0.5);
        }
        editorElement.style.cursor = 'pointer';
      }, 200);
    }
  }

  setPenWidth(signaturePad: SignaturePad, width: number) {
    signaturePad.set('minWidth', width);
    signaturePad.set('maxWidth', width);
    this.selectedPenWidth = width;
  }

  drawStartSignature(signaturePad: SignaturePad) {
    signaturePad.set('minWidth', this.selectedPenWidth);
    signaturePad.set('maxWidth', this.selectedPenWidth);
  }

  drawCompleteSignature(signaturePad: SignaturePad) {
    this.dataURLtoBlob(signaturePad.toDataURL()).then(blob => {
      this.signatureDrawTemplate = blob;
    });
  }

  async dataURLtoBlob(dataURL: string): Promise<Blob> {
    const response = await fetch(dataURL);
    const blob = await response.blob();
    return blob;
  }

  changeDrawType(event) {
    this.drawType = event.index;
  }

  adoptAndAllCurrentSign() {
    if (this.drawType == 0) {
      this.selectedSignatureBlob = this.signatureTextTemplate;
    } else {
      this.selectedSignatureBlob = this.signatureDrawTemplate;
    }
    for (const [key, value] of Object.entries(this.allEditorSignatureMap)) {
      this.adoptAndSign(key, true);
    }
    this.replaceSignatureDialog = false;
  }

  adoptAndSign(selectedEditorId, force?) {
    if (!force) {
      if (Object.keys(this.allEditorSignatureMap).length > 1) {
        this.replaceSignatureDialog = true;
        return;
      }
      if (this.drawType == 0) {
        this.selectedSignatureBlob = this.signatureTextTemplate;
      } else {
        this.selectedSignatureBlob = this.signatureDrawTemplate;
      }
    }
    if (!this.selectedSignatureBlob) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('Please draw your signature') });
      return;
    }

    const editor = document.getElementById(selectedEditorId);
    if (editor) {
      editor.setAttribute('hidden', '');
    }
    const editorAnnotation = this.getAnnotationEditorById(selectedEditorId);
    const newAnnotation: any = Object.assign({}, editorAnnotation);
    newAnnotation.bitmapId = null;
    const rect = newAnnotation.rect;
    const diff = (rect[2] - rect[0]) / 2;
    rect[0] = rect[0] - diff;
    rect[2] = rect[2] + diff;
    this.pdfViewer.service.addEditorAnnotation(newAnnotation);
    const annotationEditorLayer = ((window as any).PDFViewerApplication.pdfViewer)._pages[this.currentPage - 1].annotationEditorLayer.annotationEditorLayer;
    let newEditorId = 'pdfjs_internal_editor_' + (annotationEditorLayer.getCurrentId() - 1);
    const inputFile = (document.getElementById(newEditorId + '_input') as HTMLInputElement);
    if (inputFile) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(new File([this.selectedSignatureBlob], 'image.png', { type: 'image/png' }));
      inputFile.files = dataTransfer.files;
      const changeEvent = new Event('change');
      inputFile.dispatchEvent(changeEvent);
    }
    const editorElement = document.getElementById(newEditorId);
    editorElement.addEventListener('click', (event: any) => {
      let editorId = event.target.id ? event.target.id : event.target.parentElement.id;
      this.selectedSignatureId = editorId;
      if (!this.recipient.signed && !this.summiting) {
        this.op.toggle(event);
      }
    });
    const oldEditorId = this.allEditorSignatureMap[selectedEditorId];
    this.allEditorSignatureMap[selectedEditorId] = newEditorId;
    if (oldEditorId) {
      this.pdfViewer.service.removeEditorAnnotations((serialized: any) => {
        return oldEditorId === serialized.editorId;
      });
    }
    this.addSignatureDialog = false;
  }

  changeSignature() {
    this.addSignatureDialog = true;
    this.op.hide();
  }

  clearSignature() {
    this.pdfViewer.service.removeEditorAnnotations((serialized: any) => {
      return this.selectedSignatureId === serialized.editorId;
    });
    let editorId;
    for (const [key, value] of Object.entries(this.allEditorSignatureMap)) {
      if (this.selectedSignatureId === value) {
        const editor = document.getElementById(key);
        if (editor) {
          editor.removeAttribute('hidden');
          editorId = key;
          break;
        }
      }
    }
    delete this.allEditorSignatureMap[editorId];
    this.op.hide();
  }

  getAnnotationEditorById(editorId) {
    for (let annotation of this.pdfViewer.service.getSerializedAnnotations()) {
      if ((annotation as any).editorId === editorId) {
        return annotation;
      }
    }
    return null;
  }

  async finish() {
    this.summiting = true;
    let numOfSignature = this.recipient.signatureFields.filter(s => s.fieldType === DocSignUtils.SIGNATURE_FIELD).length;
    if (Object.keys(this.allEditorSignatureMap).length < numOfSignature) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('Please fill your signature') });
      this.summiting = false;
      return;
    }

    for (const [key, value] of Object.entries(this.allEditorSignatureMap)) {
      this.pdfViewer.service.removeEditorAnnotations((serialized: any) => {
        return key === serialized.editorId;
      });
    }

    const signatureSignedFields = [];
    let hasSignatureBlob;
    let title;
    for (let annotation of (this.pdfViewer.service.getSerializedAnnotations() as any[])) {
      if (annotation.fieldType === DocSignUtils.SIGNATURE_FIELD) {
        if (!hasSignatureBlob) {
          hasSignatureBlob = true;
          annotation.signedData = await this.docSignUtils.blobToBase64(this.selectedSignatureBlob);
        }
        signatureSignedFields.push(annotation);
      } else if (annotation.value) {
        if (annotation.fieldType === DocSignUtils.TITLE_FIELD) {
          title = annotation.value;
        }
        signatureSignedFields.push(annotation);
      }
    }
    this.recipient.signatureFields = JSON.stringify(this.recipient.signatureFields);
    this.recipient.signatureSignedFields = JSON.stringify(signatureSignedFields);
    this.recipient.dateSigned = new Date();
    this.recipient.title = title;
    this.docSignService.recipientSigned(this.recipient).subscribe((res: any) => {
      this.recipient.signed = true;
      this.initPage();
      // if (res.data.allSigned) {
      //   this.initPage();
      //   // this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Sent'), detail: this.translatePipe.transform('The document will be sent to your email when both parties signed the document, thank you.') });
      // }
      this.messageService.add({ severity: 'success', summary: '', detail: this.translatePipe.transform('The document will be send to both parties when both parties signed the document') });
      this.summiting = false;
    }, () => this.summiting = false);
  }

  async loadSignedFields(recipient) {
    const font = await this.pdfDoc.embedFont(StandardFonts.Helvetica);
    if (recipient.allSigned) {
      for (let r of recipient.allSignedRecipients) {
        if (r.signatureFields) {
          const firstSinature = r.signatureFields.find(s => s.fieldType === DocSignUtils.SIGNATURE_FIELD);
          for (let annotation of r.signatureFields) {
            await this.drawSignedFields(annotation, firstSinature.signedData, font);
          }
        }
      }
    } else {
      if (recipient.signatureFields) {
        const firstSinature = recipient.signatureFields.find(s => s.fieldType === DocSignUtils.SIGNATURE_FIELD);
        for (let annotation of recipient.signatureFields) {
          await this.drawSignedFields(annotation, firstSinature.signedData, font);
        }
      }
    }
    return this.pdfDoc.save();
  }

  async drawSignedFields(annotation, signedData, font) {
    const page = this.pdfDoc.getPages()[annotation.pageIndex];
    const { x, y } = this.docSignUtils.convertRectToXY(annotation.rect);
    if (annotation.fieldType === DocSignUtils.SIGNATURE_FIELD) {
      const imageBytes = await this.docSignUtils.base64ToBlob(signedData).arrayBuffer();
      const image = await this.pdfDoc.embedPng(imageBytes);
      page.drawImage(image, {
        x: x,
        y: y,
        width: annotation.rect[2] - annotation.rect[0],
        height: annotation.rect[3] - annotation.rect[1],
        opacity: 1,
      });

    } else {
      page.drawText(annotation.value, {
        size: annotation.fontSize - 4,
        x: x,
        y: y,
        font: font
      });
    }
  }

  async download() {
    const blob = await this.pdfDoc.save();
    this.summiting = true;
    const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = new Date().getTime() + '.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
    this.summiting = false;
  }
}
