import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeToStringPipe } from './pipes/time-to-string.pipe';
import { EnumToDropownPipe } from "./pipes/enumToDropdown.pipe"
import { DocumentsService } from './service/documents-upload/documents.service';
import { DocumentsServicev2 } from './service/documents-upload/document.v2.service';
import { BrowserNotificationService } from './service/browser-notification/browser-notification.service';
import { UtilsService } from './service/utils.service';
import { DurationFormatMinutePipe, DurationFormatPipe, DurationHMPipe, DurationTimePipe } from './pipes/duration-format.pipe';
import { CounterDirective } from './directive/counter.directive';
import { CounterTimerRecordDirective } from './directive/counter-timer-records.directive';
import { CounterIncreaseDirective } from './directive/counter-increase.directive';
import { DisableControlDirective } from './directive/disable.directive';
import { NoDblClickDirective } from './directive/no-dbl-click.directive';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { PrimeNgModule } from 'app/prime-ng/prime-ng.module';
import { MaterialModule } from './material-module/material.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TruncateTextPipe } from './pipes/truncate.pipe';
import { DragDropFileUploadDirective } from "./directive/drag-drop.directive";
import { SafeHtmlPipe } from './pipes/SafeHtmlPipe.pipe';
import { BlockPasteCopyDirective } from './directive/block-paste-copy.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptHeaderHttps } from 'app/intercept.header.https';
import { FirebaseNotificationService } from './service/firebase-notification.service';
import { TwoDigitDecimaNumberDirective } from './directive/two-digit-decima-number.directive';
import { OnlyNumbersDirective } from './directive/only-numbers.directive';
import { DocumentUploadComponent } from './component/document-upload/document-upload.component';
import { DocumentFormComponent } from './component/document-form/document-form.component';
import { ComplianceDocumentService } from './service/compliance-document/compliance-document.service';
import { OnlyNumbersSpecialCharacterDirective } from './directive/only-numbers-special-character.directive';
import { InputTextComponent } from './component/input-text/input-text.component';
import { CommunicationComponent } from './component/communication/communication.component'
import { TimezoneDatePipe } from './pipes/timezone-date.pipe'
import { FrozenColumnDirective } from './directive/frozen-column.directive';
import { CardMemberComponent } from 'app/task-management/component/card-member/card-member.component';
import { ChipsComponent } from './component/chips/chips.component';
import { MultiSelectGroupComponent } from './component/multi-select-group/multi-select-group.component';
import { OnlyAlphanumericDirective } from './directive/only-alphanumeric.directive';
import { UserGatewayService } from './service/auth/user-gateway.service';
import { NumberTowordsPipe } from 'app/employee/pipe/number-towords.pipe';
import { FilterPipe } from './pipes/FilterPipe.pipe';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { CurrencyInputMaskDirective } from './directive/currency-input-mask.directive';
import { NumericLengthDirective } from './directive/numeric-length.directive';
import { ThousandSeparatorDirective } from './directive/thousand-separator.directive';
import { ThousandSeparator2DecimaDirective } from './directive/thousand-separator-2-decima.directive';
import { CustomTimePickerComponent } from './component/custom-time-picker/custom-time-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNgModule,
        MaterialModule,
        HttpClientModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimeToStringPipe,
        DurationFormatPipe,
        DurationFormatMinutePipe,
        DurationHMPipe,
        PhoneFormatPipe,
        CounterDirective,
        CounterIncreaseDirective,
        DisableControlDirective,
        NoDblClickDirective,
        PrimeNgModule,
        MaterialModule,
        HttpClientModule,
        TranslateModule,
        TruncateTextPipe,
        DragDropFileUploadDirective,
        BlockPasteCopyDirective,
        SafeHtmlPipe,
        TwoDigitDecimaNumberDirective,
        OnlyNumbersDirective,
        DocumentUploadComponent,
        DocumentFormComponent,
        OnlyNumbersSpecialCharacterDirective,
        InputTextComponent,
        CommunicationComponent,
        TimezoneDatePipe,
        DurationTimePipe,
        FrozenColumnDirective,
        CardMemberComponent,
        ChipsComponent,
        MultiSelectGroupComponent,
        OnlyAlphanumericDirective,
        NumberTowordsPipe,
        FilterPipe,
        NumbersOnlyDirective,
        CurrencyInputMaskDirective,
        NumericLengthDirective,
        ThousandSeparatorDirective,
        ThousandSeparator2DecimaDirective,
        CustomTimePickerComponent
    ],
    declarations: [
        TimeToStringPipe,
        DurationFormatPipe,
        DurationFormatMinutePipe,
        DurationHMPipe,
        PhoneFormatPipe,
        CounterDirective,
        CounterIncreaseDirective,
        DisableControlDirective,
        NoDblClickDirective,
        EnumToDropownPipe,
        TruncateTextPipe,
        DragDropFileUploadDirective,
        BlockPasteCopyDirective,
        SafeHtmlPipe,
        TwoDigitDecimaNumberDirective,
        OnlyNumbersDirective,
        DocumentUploadComponent,
        DocumentFormComponent,
        OnlyNumbersSpecialCharacterDirective,
        InputTextComponent,
        CommunicationComponent,
        TimezoneDatePipe,
        DurationTimePipe,
        FrozenColumnDirective,
        CardMemberComponent,
        ChipsComponent,
        MultiSelectGroupComponent,
        OnlyAlphanumericDirective,
        NumberTowordsPipe,
        FilterPipe,
        NumbersOnlyDirective,
        CurrencyInputMaskDirective,
        NumericLengthDirective,
        ThousandSeparatorDirective,
        ThousandSeparator2DecimaDirective,
        CounterTimerRecordDirective,
        CustomTimePickerComponent
    ],
    providers: [
        DocumentsService,
        UtilsService,
        DocumentsServicev2,
        BrowserNotificationService,
        TranslateService,
        FirebaseNotificationService,
        UserGatewayService,
        ComplianceDocumentService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptHeaderHttps,
            multi: true
        }
    ]
})
export class SharedModule {
}
