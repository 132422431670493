export enum CommonCode {
  ADD_BOARD = 1,
  UPDATE_BOARD = 2,
  UPDATE_BG_BOARD = 3,
  ADD_BUCKET = 4,
  DELETE_BUCKET = 5,
  UPDATE_BUCKET = 6,
  ADD_CARD = 7,
  DELETE_CARD = 8,
  UPDATE_CARD = 9,
  ADD_MEMBER = 10,
  DELETE_MEMBER = 11,
  MOVE_CARD = 12,
  ADD_DESCRIPTION_CARD = 13,
  ADD_MEMBER_BOARD = 14,
  DELETE_MEMBER_BOARD = 15,
  HIDE_BUCKET = 16,
  UNHIDE_BUCKET = 17,
  ADD_IMAGE = 'IMAGE_URL',
  ADD_COMMENT= 'TEXT',
  ADD_MEMBER_TASK = 'ADD_MEMBER_TASK',
  DEL_MEMBER_TASK = 'DEL_MEMBER_TASK',
  ADD_LABEL = 'ADD_LABEL',
  UPDATE_LABEL = 'UPDATE_LABEL',
  DELETE_LABEL = 'DELETE_LABEL',
  ADD_COMMENT_CARD = 'ADD_COMMENT_CARD',
  UPDATE_COMMENT_CARD = 'UPDATE_COMMENT_CARD',
  DELETE_COMMENT_CARD = 'DELETE_COMMENT_CARD',
  ADD_IMAGE_CARD = 'ADD_IMAGE_CARD',
  DELETE_IMAGE_CARD = 'DELETE_IMAGE_CARD',
  VIEW_CARD = 'VIEW_CARD'
}
