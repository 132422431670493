import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'timezoneDate'
})
export class TimezoneDatePipe implements PipeTransform {
  transform(value: string | Date, format: string, timezone: string): string {
    if (!value) {
      return '';
    }

    const date = moment(value).tz(timezone);
    return date.format(format);
  }
}