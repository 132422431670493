<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Add Card' | translate}}</h1>
	<p-panel *ngIf="!cardToken" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span>		
				<img src="/assets/payment/visa-straight-128px.png" *ngIf="paymentCardType === 'VISA'" class="card-image mr-2"/>
				<img src="/assets/payment/mastercard-straight-128px.png" *ngIf="paymentCardType === 'MASTER_CARD'" class="card-image mr-2"/>
				<img src="/assets/payment/discovery-straight-128px.png" *ngIf="paymentCardType === 'DISCOVER'" class="card-image mr-2"/>
				<img src="/assets/payment/american-express-straight-128px.png" *ngIf="paymentCardType === 'AMERICAN_EXPRESS'" class="card-image mr-2"/>
				<img src="/assets/payment/debit-card-straight-128px.png" *ngIf="paymentCardType === 'DEBIT'" class="card-image mr-2"/>
				<span>{{'Card Details' | translate}}</span>
			</span>
		</p-header>
		<form [formGroup]="visaCardForm">
			<div class="ui-g pb-3">
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Cardholder Name' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<input type="text" formControlName="cardName" name="cardName" pInputText (change)="changeValueForm()">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Card Number' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-credit-card"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<!-- <i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i> -->
							</button>
						</div>
						<p-inputMask [mask]="formatCard" formControlName="cardNumber" [placeholder]="placeholder" slotChar=" " 
							(onComplete)="validateCardNumber($event)" class="w-100">
						</p-inputMask>
					</div>
				</div>
				<div class="ui-g-3 ui-sm-6">
					<label>{{'Exp. Date (mm/yyyy)' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-calendar-days"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<!-- <i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i> -->
							</button>
						</div>
						<p-calendar placeholder="Expiry" selectionMode="single" view="month" [yearNavigator]="true"
							yearRange="2019:3000"
							(onSelect)="validateCardNumber($event)"
							class="w-100" [minDate]="minDate"
							[inputStyle]="{'width':'100%'}"
							formControlName="cardExpiry" dateFormat="mm/yy"
							style="display:grid"
							readonlyInput="true">
						</p-calendar>
					</div>
					<span class="text-danger ui-messages-error" *ngIf="messageMonthError" style="font-size:11px">{{messageMonthError}}</span>
				</div>
				<div class="ui-g-3 ui-sm-6">
					<label>{{'CVV' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-hashtag"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<!-- <i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i> -->
							</button>
						</div>
						<input pInputText placeholder="CVV" formControlName="cvcNumber" onkeypress="if(this.value.length==3) return false;" (keypress)="isNumber($event)" maxlength="3" type="text" (change)="validateCardNumber($event)"/>
					</div>
					<span class="ui-message ui-messages-error ui-corner-all" *ngIf="!visaCardForm.get('cvcNumber')?.valid && visaCardForm.get('cvcNumber').hasError('maxlength')">Maximum length {{visaCardForm.get('cvcNumber').errors.maxlength.requiredLength}}</span>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Street Address' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-signs-post"></i>
							</button>
						</div>
						<input type="text" id="address" placeholder="" formControlName="address" name="address" pInputText (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'City' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map-location-dot"></i>
							</button>
						</div>
						<input type="text" formControlName="city" name="city" pInputText class="flex-auto" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Country' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-earth-america"></i>
							</button>
						</div>
						<input type="text" formControlName="country" name="country" pInputText class="flex-auto" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Zip Code' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-earth-america"></i>
							</button>
						</div>
						<input type="text" formControlName="zipcode" name="zipcode" pInputText class="flex-auto" (keypress)="isNumber($event)" maxlength="5" (change)="changeValueForm()"/>
					</div>
				</div>
			</div>
		</form>
	</p-panel>
	<div class="ui-g" *ngIf="functionImport !== 'SIGN_IN'">
		<div class="ui-g-12 text-center pt-4">
			<button (click)="cancel()" class="btn btn-danger mr-2"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button (click)="addPaymentProfile()" class="btn btn-primary mr-2" [disabled]="isDisabled || !visaCardForm.valid"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}</button>
		</div>
	</div>
</div>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmAutoPayReminderDialog" class="modal_in" [modal]='true' (onHide)="closeModal()">
	<div class="ui-g">
		{{'confirmAutoPayReminder' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="acceptAutoPay()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="rejectAutoPay()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>
