<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Add Card' | translate}}</h1>
	<p-panel *ngIf="!cardToken" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><img src="/assets/payment/american-express-straight-128px.png" class="card-image mr-2"/><span>{{'Card Details' | translate}}</span></span>
		</p-header>
		<form [formGroup]="amexCardForm">
			<div class="ui-g pb-3">
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Cardholder Name' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<input type="text" pInputText formControlName="cardName" class="form-control" required (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Card Number' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-credit-card"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i>
							</button>
						</div>
						<input *ngIf="showCard" type="text" pInputText formControlName="cardNumber" required (change)="changeValueForm()"/>
						<input *ngIf="!showCard" type="password" pInputText formControlName="cardNumber" required (change)="changeValueForm()"/>
						<div class="input-group-append">
							<button *ngIf="!showCard" class="btn btn-white" (click)="toggleCard()" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-eye text-primary"></i>
							</button>
							<button *ngIf="showCard" class="btn btn-white" (click)="toggleCard()" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-eye-slash text-danger"></i>
							</button>
						</div>
					</div>
					<span class="text-danger ui-messages-error" *ngIf="messageMonthError" style="font-size:12px">{{messageMonthError}}</span>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Exp.' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-calendar-days"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i>
							</button>
						</div>
						<p-calendar placeholder="mm/yy" selectionMode="single" view="month" [yearNavigator]="true"
								yearRange="2019:3000"
								(onSelect)="validateCardNumber($event)"
								class="w-100"
								[inputStyle]="{'width':'100%'}"
								formControlName="cardMonth" dateFormat="mm/yy"
								style="display:grid"
								readonlyInput="true">
						</p-calendar>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'CVV' | translate}} (4 digits)<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i *ngIf="'valid' !== cardValid || 'invalid' !== cardValid" class="fa-solid fa-hashtag"></i>
								<i *ngIf="'valid' === cardValid" class="fa-solid fa-circle-check text-success"></i>
								<i *ngIf="'invalid' === cardValid" class="fa-solid fa-circle-xmark text-danger"></i>
							</button>
						</div>
						<input pInputText *ngIf="showCVV"  type="text" formControlName="cvcNumber" onkeypress="if(this.value.length==5) return false;" maxlength="4" [required]="true" (keypress)="isNumber($event)" (change)="changeValueForm()"/>
						<input pInputText *ngIf="!showCVV" type="password" formControlName="cvcNumber" onkeypress="if(this.value.length==5) return false;" maxlength="4" [required]="true" (keypress)="isNumber($event)" (change)="changeValueForm()"/>
						<div class="input-group-append">
							<button *ngIf="!showCVV" class="btn btn-white" (click)="toggleCVV()" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-eye text-primary"></i>
							</button>
							<button *ngIf="showCVV" class="btn btn-white" (click)="toggleCVV()" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-eye-slash text-danger"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Address (Line 1)' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-signs-post"></i>
							</button>
						</div>
						<input type="text" id="address" placeholder="" formControlName="address" class="form-control" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Address (Line 2)' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-signs-post"></i>
							</button>
						</div>
						<input type="text" id="address2" placeholder="" pInputText formControlName="address2" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'City' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map-location-dot"></i>
							</button>
						</div>
						<input type="text" pInputText formControlName="city" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'State' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map-location-dot"></i>
							</button>
						</div>
						<input  type="text" pInputText formControlName="state" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Country' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-earth-america"></i>
							</button>
						</div>
						<input  type="text" pInputText formControlName="country" maxlength="5" (change)="changeValueForm()"/>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label >{{'Zip Code' | translate}}<span class="required-indicator">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-earth-america"></i>
							</button>
						</div>
						<input type="text" pInputText formControlName="zipcode" maxlength="5" (change)="changeValueForm()"/>
					</div>
				</div>
			</div>
		</form>
	</p-panel>
	<div class="ui-g" *ngIf="functionImport !== 'SIGN_IN'">
		<div class="ui-g-12 text-center pt-4">
			<button (click)="onBack()" class="btn btn-danger mr-2"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button (click)="addPaymentProfile()" [disabled]="!amexCardForm.valid" class="btn btn-primary mr-2"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}</button>
		</div>
	</div>
</div>

<p-dialog [(visible)]="showConfirmAutoPayReminderDialog" class="modal_in" [modal]='true' (onHide)="closeModal()">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Confirmation' | translate}}</span>
	</p-header>
	<div class="ui-g p-4">
		{{'confirmAutoPayReminder' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="acceptAutoPay()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="rejectAutoPay()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>
