import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmDrawingManager, AgmDrawingModule } from '@agm/drawing';
import { PolygonDrawerMapDirective } from './directive/polygon-drawer-map.directive';
import { PolygonMapDirective } from './directive/polygon-map.directive';
import { DirectionMapDirective } from './directive/direction-map.directive';
import { GMapsService } from './service/gmaps.service';
import { PolygonService } from './service/polygon.service';
import { ZoneService } from './service/zone.service';
import { GeoService } from './service/geo.service';
import { PolygonDrawerMapComponent } from './component/polygon-drawer-map/polygon-drawer-map.component';
import { PolygonMapComponent } from './component/polygon-map/polygon-map.component';
import { AgmCoreModule, GoogleMapsAPIWrapper, LazyMapsAPILoader } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from 'app/prime-ng/prime-ng.module';
import { SharedModule } from 'primeng';
import { TranslateModule } from "@ngx-translate/core";
import { TurfPolygonDrawerMapComponent } from './component/turf-polygon-drawer-map/turf-polygon-drawer-map.component';
import { TurfPolygonDrawerMapDirective } from './directive/turf-polygon-drawer-map.directive';
import { TurfService } from './service/turf.service';
import { VoterMarkerClusterMapDirective } from './directive/voter-markercluster-map.directive';

@NgModule({
  declarations: [
    PolygonDrawerMapDirective,
    PolygonMapDirective,
    DirectionMapDirective,
    PolygonDrawerMapComponent,
    PolygonMapComponent,
    TurfPolygonDrawerMapComponent,
    TurfPolygonDrawerMapDirective,
    VoterMarkerClusterMapDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    SharedModule,
    AgmCoreModule,
    TranslateModule,
    AgmDrawingModule,
  ],
  exports: [
    PolygonDrawerMapDirective,
    PolygonMapDirective,
    DirectionMapDirective,
    PolygonDrawerMapComponent,
    TurfPolygonDrawerMapComponent,
    TurfPolygonDrawerMapDirective,
    VoterMarkerClusterMapDirective
  ],
  providers: [
    GMapsService,
    PolygonService,
    ZoneService,
    GeoService,
    AgmDrawingManager,
    GoogleMapsAPIWrapper,
    LazyMapsAPILoader,
    TurfService
  ],
})
export class MapModule { }
