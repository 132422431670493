<p-toast position="bottom-right" class="concern"></p-toast>
<div class="container" style="height: 100vh;">
  <ng-container *ngIf="wrongEmployee">
    <div class="row justify-content-md-center align-self-center" style="text-align: center; display: inline;">
        <div class="col-md-auto" style="margin-top: 100px">
            <img src="../../../assets/images/warning-icon-svg-11.jpg" style="width: 100px;height: auto;"><br>
            <h3>{{'Sorry, you do not have permission to access this page' | translate}}</h3>
        </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!wrongEmployee">
    <iframe src="" type="application/pdf" width="100%" height="100%"></iframe>
  </ng-container>

</div>
