<p-tabMenu [model]="companyTabMenuItems" [activeItem]="activeItem">
  <ng-template pTemplate="item" let-item let-i="index">
    {{item.label | translate}}
  </ng-template>
</p-tabMenu>
<div class="ui-g">
  <div class="ui-g-12 p-0">
    <div class="card add-shadow p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>