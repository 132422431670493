import { Constants } from './../../shared/model/constants';
import { Page } from './../../shared/model/response';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DocSignService extends AbstractService<any> {

  constructor( private http: HttpClient ) { 
    super(http, Constants.ROUTES.DOC_SIGN, '');
  }

  search(options: any): Observable<Page<any>> {
  // return this._http.post<Page<any>>('http://localhost:8007/api/v2/operation/doc-sign/search', options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  saveData(data: any) {
    return this._http.post(this.baseUrlV2, data).pipe(map(res => res));
  }

  updateDocSign(entity: any): Observable<any> {
    return this._http.put<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  getDocSignById(id: any) {
    return this._http.get<any>(this.baseUrlV2 + `${id}`).pipe(map(res => res));
  }

  sendToRecipients(payload: any) {
    return this._http.post(`${this.baseUrlV2}send-to-recipients`, payload).pipe(map(res => res));
  }

  resendEmail(recipientId: number) {
    return this._http.post(`${this.baseUrlV2}resend-email/${recipientId}`, {}).pipe(map(res => res));
  }

  findBySessionSign(sessionSign: string) {
    return this._http.get<any>(`${this.baseUrlV2}recipient/signing?sessionSign=${sessionSign}`).pipe(map(res => res));
  }

  recipientSigned(payload: any) {
    return this._http.post(`${this.baseUrlV2}recipient/signed`, payload).pipe(map(res => res));
  }

  signedDocument(id: number, documentId: number) {
    return this._http.post(`${this.baseUrlV2}recipient/signedDocument/${id}?documentId=${documentId}`, {}).pipe(map(res => res));
  }

  convertDocxToPDF(file: File) {
    let payload = new FormData();
    payload.append('file', file);
    return this._http.post(`${this.baseUrlV2}recipient/convert-docx`, payload).pipe(map(res => res));
  }
}
