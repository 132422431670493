export class Time {
    hour: number;
    minutes: number;
    constructor(timeString ?: String, hour ?: number, minutes ?: number) {
        if (hour != null && minutes != null) {
            this.hour = hour;
            this.minutes = minutes;
        }
        if (timeString == null) {
            return;
        }
        const tokens = timeString.split(':');
        if (tokens.length < 2) {
            return;
        }
        this.hour = Number(tokens[0]);
        this.minutes = Number(tokens[1]);
    }

    isValid() {
        return this.hour != null && this.minutes != null;
    }

    toString() {
        if (this.hour == null || this.minutes == null) {
            return '';
        }
        let hours = String(this.hour);
        if (this.hour < 10) {
            hours = '0' + hours;
        }
        let minutes = String(this.minutes);
        if (this.minutes < 10) {
            minutes = '0' + minutes;
        }
        return hours + ':' + minutes;
    }
}
