<h1 class="ml-2">{{'Billing Clients' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Billing Clients' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
            <label>{{'Company' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
            	<p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{'Select One' | translate}}" [autoDisplayFirst]='false' [(ngModel)]="selectedCompany" (onChange)="onCompanyChanges()" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Creator' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="allCreatedByList" [filter]="true" [showClear]="true" [(ngModel)]="selectedCreatedBy" placeholder="{{'Select One' | translate}}" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Follow up' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-check"></i>
					</button>
				</div>
<!--            	<p-dropdown [options]="followUp" optionLabel="value" dataKey="key" placeholder="{{'Select One' | translate}}" [autoDisplayFirst]='false' >-->
				<p-dropdown [options]="followUp" [(ngModel)]="followUpSelect" [ngModelOptions]="{standalone: true}" [autoDisplayFirst]='false' appendTo="body">
					<ng-template pTemplate="emptymessage">
						<span class="text-truncate text-light px-3">{{'Select' | translate}}</span>
					</ng-template>
					<ng-template pTemplate="item" let-item>
						<span [style.background-color]="item.color" class="text-truncate text-light px-1">{{item.label}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span [style.background-color]="item.color" class="one-liner text-light px-1">{{item.label }}</span>
					</ng-template>
				</p-dropdown>
			</div>
        </div>
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="loadDataSource($event)"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
    </div>
</p-panel>
<div class="d-flex justify-content-between align-items-center py-4">
	<h1 class="m-0 ml-2">{{'Browse Clients' | translate}}</h1>
	<button class="btn btn-primary" [routerLink]="'/app/job-follow-up/add'" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Billing Client Logs' | translate}} ({{totalRecords}})</span>
	</p-header>
    <p-table #dt (onFilter)="onFilter($event, dt)" [value]="totalJobList" selectionMode="single" dataKey="id" [lazy]="false" [rows]="10" [paginator]="true"
		[rowsPerPageOptions]="[10,25,50,100]" [totalRecords]="totalRecords" [responsive]="true" [columns]="cols"
		[globalFilterFields]="['bases', 'createdByUsr', 'cycle', 'lastModifiedBy']">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between">
				<span class="d-flex">
                    <button type="button" (click)="exportPdf()" class="btn btn-primary mx-1">
                        <i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
                    </button>
                </span>
				<div class="d-flex align-items-center justify-content-between">
                    <div class="input-group flex-grow-1 mr-2" style="max-width: 450px">
                        <div class="input-group-prepend p-0">
                            <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                                <i class="fa-solid fa-filter"></i>
                            </button>
                        </div>
                        <input type="text" [(ngModel)]="textSearch" pInputText placeholder="{{'Filter' | translate}}" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
                    </div>
                </div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of cols" [style.width]="col.width">
                    <ng-container [ngSwitch]="col.field">
                        <span *ngSwitchDefault>
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op1.toggle($event) : op2.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
                        </span>
						<span *ngSwitchCase="'totalJobDetails'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op2.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'action'">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="loading && (i+1 === 1)">
                <td [attr.colspan]="9" class="text-center py-4">
                   <span class="text-muted my-5">{{'Loading...' | translate}}</span>
                </td>
            </tr>
            <tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData"  [ngClass]="getDesiredHighlightClass(rowData)">
                <td>{{i+1}}</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span> 
						<span *ngIf="rowData.createdAt" pTooltip="{{ rowData.createdAt | date: 'M/d/yyyy h:mm a' }}">{{ rowData.createdAt | date: 'M/d/yyyy h:mm a' }}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Creator' | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Bases' | translate}}: </span>
						<span *ngIf="rowData.totalJobDetails.length" pTooltip="{{rowData.totalJobDetails.length}}">{{rowData.totalJobDetails.length}}</span>
						<span *ngIf="!rowData.totalJobDetails.length" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Period' | translate}}: </span>
						<span *ngIf="rowData.fromDate || rowData.toDate" pTooltip="{{rowData.fromDate | date: 'M/d/yyyy h:mm a' }} - {{rowData.toDate | date: 'M/d/yyyy h:mm a' }}">{{rowData.fromDate | date: 'M/d/yyyy h:mm a' }} - {{rowData.toDate | date: 'M/d/yyyy h:mm a' }}</span>
						<span *ngIf="!rowData.fromDate && !rowData.toDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Cycle' | translate}}: </span>
						<span *ngIf="rowData.cycle" pTooltip="{{rowData.cycle}}">{{rowData.cycle}}</span>
						<span *ngIf="!rowData.cycle" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Updated' | translate}}: </span>
						<span *ngIf="rowData.updatedAt" pTooltip="{{rowData.updatedAt | date: 'M/d/yyyy h:mm a'}}">{{rowData.updatedAt | date: 'M/d/yyyy h:mm a'}}</span>
						<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td (click)="selectedRow($event,rowData)">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Update' | translate}}: </span>
						<span *ngIf="rowData.lastModifiedBy" pTooltip="{{rowData.lastModifiedBy}}">{{rowData.lastModifiedBy}}</span>
						<span *ngIf="!rowData.lastModifiedBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
                    	<i class="fa-solid fa-pen-to-square text-primary mx-1" (click)="viewJob(rowData.id)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
                    	<i class="fa-solid fa-xmark text-danger mx-1" (click)="deleteJob(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="9" class="text-center py-4">
                    <span *ngIf="!loading" class="text-muted my-5">{{'no data' | translate}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="numbersSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClicked(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog  header="{{'Confirmation' | translate}}" [(visible)]="showConfirmDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
        {{'deleteConfirm' | translate}}
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" align="right">
          <button type="button" pButton label="{{'yes' | translate}}" (click)="accept()"></button>
          <button type="button" pButton label="{{'no' | translate}}" (click)="reject()"></button>
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <p-overlayPanel #op appendTo="body">
    <span (click)="showDuplicateModal=true; op.toggle($event);">{{'Duplicate' | translate}}</span>
</p-overlayPanel>

<p-dialog  header="{{'Duplicate Record' | translate}}" [(visible)]="showDuplicateModal" class="modal_in" [modal]='true' [style]="{'width':'95%','max-width':'700px'}">
    <div class="ui-g" style="width:380px;padding:8px;">
        <label>{{'Select Period' | translate}} :</label>
        <mat-form-field class="w-100">
            <mat-date-range-input [rangePicker]="picker1">
                <input matStartDate matInput [(ngModel)]="startDate" (dateChange)="checkChange($event)"
                name="Startdate" (focus)="picker1.open()">
                <input matEndDate matInput [(ngModel)]="endDate" name="Enddate" (dateChange)="checkChange($event)"
                (focus)="picker1.open()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
        </div>
        <div class="ui-g" style="width:380px;padding:8px;">
            <label>{{'Select Cycle' | translate}} :</label>
        </div>
        <div class="ui-g" >
            <p-dropdown [options]="cycle" [style]="{'width':'370px'}" placeholder="{{'pleaseSelect' | translate}}" (onChange)="checkChange($event)" appendTo="body"
                        [autoDisplayFirst]='false' [(ngModel)]="selectedCycle" [ngModelOptions]="{standalone: true}"
                        >
                </p-dropdown>
        </div>
        <br>
        <div class="m-2 text-center">
            <button pButton label='Save' (click)="duplicateRecord()"></button>
        </div>
  </p-dialog>
