import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbService} from '../../../layout/service/breadcrumb.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import {OverlayPanel, Table} from 'primeng';
import {CompanyOtherSettingHistoryService} from '../../service/company-other-setting-history.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


@Component({
    selector: 'app-company-other-setting-history',
    templateUrl: './company-other-setting-history.component.html',
    styleUrls: ['./company-other-setting-history.component.scss']
})
export class CompanyOtherSettingHistoryComponent implements OnInit {
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    size: number;
    page: number;
    clientHistory: any[];
    totalRecords: any;
    rows: number;
    fromDate;
    toDate;
    cols: any[] = [
        {field: 'updateDate', label: 'Updated', sort: 'number'},
        {field: 'userName', label: 'User', sort: 'text'},
        {field: 'oldValue', label: 'Old Value', sort: 'text'},
        {field: 'newValue', label: 'New Value', sort: 'text'},
        {field: 'action', label: 'Actions', sort: 'text'}
    ];
    first = 0;
    rangeDates;
    maxDate: any;
    fieldNames: any[];
    selectedColField = null;
    @Input() compaynId: any;
    @Input() historyType: string;
    selectedSortOption: any;
    selectedField: any;
    sortField: any;
    overlayPanel: OverlayPanel;
    loading = false;
 
    constructor(
        private breadcrumbService: BreadcrumbService,
        private historyService: CompanyOtherSettingHistoryService,
        private route: ActivatedRoute
    ) {
        this.maxDate = new Date();
    }

    ngOnInit() {
        console.log(this.historyType);
        if (this.historyType == 'emailSetting') {
            this.fieldNames = [
                {label: 'Protocol', value: 'driver'},
                {label: 'Host', value: 'host'},
                {label: 'From Name', value: 'fromName'},
                {label: 'User Name', value: 'userName'},
                {label: 'Port', value: 'port'},
                {label: 'Encryption', value: 'encryption'},
                {label: 'Password', value: 'password'}
            ];
        } else if (this.historyType == 'branding') {
            this.fieldNames = [
                {label: 'Logo', value: 'logoId'},
                {label: 'Main White Label', value: 'mainWhiteLabel'},
                {label: 'Upper Left Label', value: 'upperLeftLabel'}
            ];
        } else {
            this.fieldNames = [
                {label: 'Client Dashboard F1', value: 'clientDashboardF1'},
                {label: 'Client Dashboard F2', value: 'clientDashboardF2'},
                {label: 'Employee Dashboard F2', value: 'clientDashboardF3'},
                {label: 'Promotional Text Content', value: 'marquee'}
            ];
        }
    }

    loadDataSource(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    }

    filterClientHistory() {
        if (!this.selectedColField) return;
        const fromDate = this.fromDate? new Date(this.fromDate).getTime() : null;
        const toDate = this.toDate? new Date(this.toDate).getTime() : null;
        const options: any = {};
        if (this.selectedColField) {
            options.fieldName = this.selectedColField;
        }
        if (fromDate) {
            options.fromDate = moment(fromDate).utc(true).startOf('day').toDate();
        }
        if (toDate) {
            options.toDate = moment(toDate).utc(true).endOf('day').toDate();
        }
        if (this.compaynId) {
            options.otherSettingId = this.compaynId;
        }
        this.loading = true;
        this.historyService.getClientHistoryPage(options).subscribe(
            (data: any) => {
                this.clientHistory = data.data.content;
                this.totalRecords = data.data.content.length;
                this.dataSourceSubject.next(data.data);
                this.loading = false;
            },
            (error: any) => {
                console.error(error);
                this.loading = false;
            }
        );

    }
    reset() {
        this.rangeDates = [];
        this.selectedColField = null;
        this.fromDate = null;
        this.toDate = null;
        this.filterClientHistory();
    }
}
