import { Freelancer } from './freelancer.model';

export class FreelancerTicket {
    id: number;
    freelancer: Freelancer;
    ticketLink: string;
    estimationHrs: string;
    details: string;
    startDate: Date;
    deadline: Date;
    status: string;
    createdByUsr: string;
    disable: boolean;
    checked: boolean;
    companyId?: number;
    updatedAt: Date;
    loggedInUserEmail?: string;
    loggedInUserFullName?: string;
    taskId?: number;
    isCheckboxDisable: boolean;
    invoiceNumber: any;
    invoiceId: any;
    invoiceStatus: any;
    userDetails?: any;
    team: any = [];
    boardId: number;
    internalId: number;
    documentId: any;
    confirmationDate: any;
    index: any;
    lastModifiedBy: any;
}
