import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-transaction-add',
    templateUrl: './transaction-add.component.html',
    styleUrls: ['./transaction-add.component.scss']
})
export class TransactionAddComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
