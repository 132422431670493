import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CompanyService } from '../../service/company.service';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import moment, * as MOMENT from 'moment';
import {LangChangeEvent, TranslatePipe, TranslateService} from '@ngx-translate/core';
@Component({
    selector: 'app-company-history',
    templateUrl: './company-history.component.html',
    styleUrls: ['./company-history.component.scss']
})
export class CompanyHistoryComponent implements OnInit {
    @Input() companyId: number;
    @Input() type: string;

    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    rangeDates: any;
    maxDate: any;
    fieldNames: any[];
    selectedField;
    selectedSortOption: any;
    cols: any[] = [
        { field: 'updatedDate', label: 'Updated', sortOptions: '', sort: 'number' },
        { field: 'createdByUsr', label: 'history.userName', sortOptions: '', sort: 'text' },
        { field: 'oldValue', label: 'history.oldValue', sortOptions: '', sort: 'text' },
        { field: 'newValue', label: 'history.newValue', sortOptions: '', sort: 'text' },
        { field: 'action', label: 'history.action', sortOptions: '', sort: 'text' }
    ];
    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
      ];
      numberSortOptions = [
        { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
      ];
    sortField: string;
    sortOrder: number;
    sortType: string;
    size: number;
    page: number;
    groups = [];
    companyHistory: any;
    @Input() field: number;
    @Input() plans: any;
    companyType = [
        { label: 'companyType.soleProprietorship', value: '1' },
        { label: 'companyType.partnership', value: '2' },
        { label: 'companyType.corporation', value: '3' },
        { label: 'companyType.LLC', value: '4' },
        { label: 'companyType.ministries', value: '5' },
        { label: 'companyType.lawFirm', value: '6' },
    ];

    historyFor: any = {};

    fromDate: Date;
    toDate: Date;
    loading = false;
    constructor(
        private companyService: CompanyService, 
        private datePipe: DatePipe, 
        private translate: TranslateService,
        private translatePipe: TranslatePipe) 
    { }

    ngOnInit() {
        const adminType = ['PLAN'];
        this.companyService.getAdminType(adminType).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.groups = resObj.data;
            }
        }, err => {
        });
        let fieldNames = [];
        if (this.type === 'companyDetails') {
            fieldNames = [
                { label: 'License number', value: 'licenseNumber' },
                { label: 'Address One', value: 'addressOne' },
                { label: 'Address Two', value: 'addressTwo' },
                { label: 'City', value: 'city' },
                { label: 'State', value: 'state' },
                { label: 'Zip Code', value: 'zipcode' },
                { label: 'Phone', value: 'phone' },
                { label: 'Fax', value: 'fax' },
                { label: 'Email', value: 'email' },
                { label: 'Time zone', value: 'timezone' },
                { label: 'Company Type', value: 'type' },
                { label: 'Status', value: 'status' },
                { label: 'Service Type', value: 'serviceTypeId' },
                { label: 'Operational Policy', value: 'operationalPolicy' },
                { label: 'Total Employee', value: 'totalEmployee' },
            ];
        } else if (this.type === 'taxDetail') {
            fieldNames = [
                { label: 'Federal Tax Number', value: 'federalTax' },
                { label: 'Federal Tax Status', value: 'federalTaxStatus' },
                { label: 'Federal Tax Start', value: 'federalTaxStart' },
                { label: 'Federal Tax Expire', value: 'federalTaxExpire' },
                { label: 'State Tax Number', value: 'stateTax' },
                { label: 'State Tax Status', value: 'stateTaxStatus' },
                { label: 'State Tax Start', value: 'stateTaxStart' },
                { label: 'State Tax Expire', value: 'stateTaxExpire' }
            ];
        } else if (this.type === 'phoneConfiguration') {
            fieldNames = [
                { label: 'Process Method', value: 'operrTelProcessMethod' }
            ];
            this.getListPhoneConfigurationHis();
        } else if (this.type === 'punchInOutDetail') {
            fieldNames = [
                { label: 'Username', value: 'punchInOutName' },
                { label: 'Facial recognition', value: 'useFaceDetection' },
                { label: 'PIN Method', value: 'pinMethod' },
                { label: 'Lock Status', value: 'lockStatus' },
            ];
        } else {
            fieldNames = [
                { label: 'Name', value: 'companyName' },
                { label: 'Type', value: 'type' },
                { label: 'Address One', value: 'addressOne' },
                { label: 'Address Two', value: 'addressTwo' },
                { label: 'City', value: 'city' },
                { label: 'State', value: 'state' },
                { label: 'Zipcode', value: 'zipcode' },
                { label: 'Status', value: 'status' },
                { label: 'License No', value: 'licenseNumber' },
                { label: 'Email', value: 'email' },
                { label: 'Phone', value: 'phone' },
                { label: 'Fax', value: 'fax' }
            ];
        }
        if (this.field) {
            fieldNames = [{ label: 'Plan', value: 'planId' }],
            this.selectedField = 'planId';
        };

        this.fieldNames = _.sortBy(fieldNames, 'label');
    }

    selectField(event) {
        this.historyFor = this.fieldNames.find(field => event.value === field.value)
    }

    loadDataSource(event?: any) {
        if (!this.selectedField) return;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        const fromDate = this.fromDate ? new Date(this.fromDate).getTime() : null;
        const toDate = this.toDate ? new Date(this.toDate).getTime() : null;
        const options: any = {};
        options.page = this.page;
        options.size = this.size;
        if (this.companyId) {
            options.companyId = this.companyId;
        }
        if (this.selectedField && this.selectedField !== 'All') {
            options.fieldName = this.selectedField;
        }
        if (fromDate) {
            options.fromDate = fromDate;
        }
        if (toDate) {
            options.toDate = toDate;
        }
        if (this.type) {
            options.historyType = this.type;
        }
        if (this.fromDate && this.toDate) {
            options.startDate = this.fromDate ? MOMENT(this.fromDate).startOf('day').utc(true).toDate() : null;
            options.endDate = this.toDate ? MOMENT(this.toDate).endOf('day').utc(true).toDate() : null;
        }
        this.companyService.companyTrackChanges(options).subscribe(
            (data: any) => {
                this.companyHistory = data.data.content;
                this.companyHistory.forEach(history => {
                    if (history.fieldName === 'date') {
                        history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
                        history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
                    }
                    if (history.fieldName === 'planId') {
                        history.newValue = this.plans.filter(p => p.id === Number(history.newValue))[0].name;
                        history.oldValue = this.plans.filter(p => p.id === Number(history.oldValue))[0].name;
                    }
                });
                this.dataSourceSubject.next(data.data);
            }
        );
        this.loading = false;

    }

    filterClientHistory() {
        if (!this.selectedField) {
            this.dataSourceSubject.next([]);
            this.companyHistory = [];
            return;
        }
        const fromDate = this.fromDate ? new Date(this.fromDate).getTime() : null;
        const toDate = this.toDate ? new Date(this.toDate).getTime() : null;
        const options: any = {};
        if (this.companyId) {
            options.companyId = this.companyId;
        }
        if (this.selectedField && this.selectedField !== 'All') {
            options.fieldName = this.selectedField;
        }
        if (fromDate) {
            options.fromDate = fromDate;
        }
        if (toDate) {
            options.toDate = toDate;
        }
        if (this.type) {
            options.historyType = this.type;
        }
        if (this.fromDate && this.toDate) {
            options.startDate = this.fromDate ? MOMENT(this.fromDate).startOf('day').utc(true).toDate() : null;
            options.endDate = this.toDate ? MOMENT(this.toDate).endOf('day').utc(true).toDate() : null;
        }
        this.companyService.companyTrackChanges(options).subscribe(
            (data: any) => {
                this.companyHistory = data.data.content;
                this.companyHistory.forEach(history => {
                    if (history.fieldName === 'date') {
                        history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
                        history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
                    }
                    if (history.fieldName === 'licenseNumber') {
                        this.cols[2].sort = 'number';
                        this.cols[3].sort = 'number';
                        history.newValue = Number(history.newValue);
                        history.oldValue = Number(history.oldValue);
                    }
                });
                this.dataSourceSubject.next(data.data);
            }
        );
        this.loading = false;

    }
    sortingClick(selectedSortOption) {
        this.setSortOption(this.sortField, selectedSortOption);

        const elas = this.sortField;
        this.companyHistory.sort((a, b) => {
            const valA = a[elas];
            const valB = b[elas];
            if (selectedSortOption === 'DESC') {
                if (this.sortType === 'number') {
                    return valB - valA;
                }
                return -1 * valA.localeCompare(valB);
            } else if (selectedSortOption === 'ASC') {
                if (this.sortType === 'number') {
                    return valA - valB;
                }
                return 1 * valA.localeCompare(valB);
            }
        });
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sortOptions = selectedSortOption;
            } else {
                item.sortOptions = '';
            }
        });
    }
    loadSortOption(selectedSortOption?) {
        this.selectedSortOption = selectedSortOption;
    }

    reset(){
        this.fromDate = null;
        this.toDate = null;
        this.selectedField = '';
        this.companyHistory = [];
    }

getLabelForValue(fieldName: string, value: string): string {
    if (fieldName === 'type') { 
      const company = this.companyType.find(c => c.value === value);
      return company ? this.translate.instant(company.label) : 'no data';
    }
    return value; 
  }
getCompanyTypeLabel(value: string): string {
    const company = this.companyType.find(c => c.value === value);
    return company ? company.label : 'Unknown'; 
  }

  getListPhoneConfigurationHis() {
    if (!this.companyId) {
        return;
    }
    this.companyService.getListPhoneConfigurationHis(this.companyId).subscribe(
        (resObj: any) => {
            if (resObj.status === 'SUCCESS' && resObj.data) {
                resObj.data.filter((operrTel, index) => {
                    let obj = {label: 'OperrTel#' + (index+1), value: ('operrTel'+index)};
                    this.fieldNames.push(obj);
                });
            }
        }
    );
  }

  getPlanName(planId) {
    if (!this.groups) return planId;
    let plan = this.groups.find(item => item.id == planId);
    if (plan && plan.name) return plan.name;
    return planId;
  }

}
