<div class="ui-g pb-4">
	<div class="ui-g-5 ui-sm-12">
		<label>{{'Field' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-mouse-pointer"></i>
				</button>
			</div>
			<p-dropdown [options]="fieldNames" style="flex-grow:1 !important" placeholder="Select a field" [(ngModel)]="selectedField">
                <ng-template pTemplate="item" let-item>
                    {{item.label | translate}}
                </ng-template>
                <ng-template let-item pTemplate="selectedItem">
                    {{item.label | translate}}
                </ng-template>
            </p-dropdown>
		</div>
	</div>
	<div class="ui-g-7 ui-sm-12">
		<label>{{'Date' | translate}}</label>
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-calendar-week"></i>
				</button>
			</div>
            <mat-form-field class="w-100">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="startDate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
                    <input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="endDate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
                </mat-date-range-input>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
			<div class="input-group-append pl-3">
				<button class="btn btn-white" (click)="reset()"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
				<button class="btn btn-white"  (click)="filterClientHistory()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
			</div>
		</div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true" class="mb-5">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{selectedField}} <span *ngIf="(dataSource$|async)?.totalElements">({{(dataSource$|async)?.totalElements}})</span></span>
	</p-header>
    <p-table #table [value]="holidayHistories" [rows]="(dataSource$|async)?.size" [paginator]="false" [totalRecords]="(dataSource$|async)?.totalElements" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true">
    		<ng-template pTemplate="header" let-columns>
		<tr>
			<th *ngFor="let col of cols" [ngStyle]="{'width': col?.width }" [pSortableColumn]="col?.field">
			{{ col?.label | translate}}
			</th>
		</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData >
			<tr [pSelectableRow]="rowData" >
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Updated' | translate}}: </span>
						<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'MM/dd/yyyy hh:mm:ss a'}}">{{rowData.updatedDate | date: 'MM/dd/yyyy hh:mm:ss a'}}</span>
						<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Username' | translate}}: </span>
						<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
						<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>		
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Old Value' | translate}}: </span>
						<span *ngIf="rowData.oldValue" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
						<span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'New Value' | translate}}: </span>
						<span *ngIf="rowData.newValue" pTooltip="{{rowData.newValue}}">{{rowData.newValue}}</span>
						<span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Action' | translate}}: </span>
						<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
						<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
			</tr>
		</ng-template> 
		<ng-template pTemplate="emptymessage" let-columns>
			<tr>
				<td [attr.colspan]="5" class="text-center py-4">
					<span class="text-muted">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>
