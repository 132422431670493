import {Injectable} from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {map} from 'rxjs/operators';
import {OperrtelPool} from '../model/operrtel-pool.model';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperrtelPoolService extends AbstractService<OperrtelPool> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERRTEL_POOL, '');
  }

  getListPage(options?: any) {
    // return this._http.post(`http://localhost:8007/api/v2/operation/operrtel-pool/search`, options).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  getCreatedBy(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/operrtel-pool/getCreatedBy`, { params }).pipe(map(resp => resp));
    return this._http.get<any>(`${this.baseUrlV2}getCreatedBy`, { params }).pipe(map(resp => resp));
  }

  getListHistory(entity) {
    // return this._http.post(`http://localhost:8007/api/v2/operation/operrtel-pool/search-history`, entity).pipe(map(res => res));
    return this._http.post(`${this.baseUrlV2}search-history`, entity).pipe(map(res => res));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  updateByIds(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}update-by-ids`, data).pipe(map(resp => resp));
  }
}
