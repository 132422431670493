import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Message} from 'primeng/api';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {FormValidatorService} from '../../../shared/service/form-validator/form-validator.service';
import {PayrollSettingService} from '../../service/payroll-setting.service';
import {PayrollSetting} from '../../model/payroll-setting.model';
import {PayrollHoliday} from '../../model/payroll-holiday.model';
import {PayrollHolidayService} from '../../service/payroll-holiday.service';
import {DepartmentService} from '../../../department/service/department.service';
import {AgencyHolidayService} from '../../../agency/service';
import {AgencyHoliday} from '../../../agency/model';
import { PayrollModelUtility, NGDropdownModel } from '../../utility/model.utility';
import { PositionService } from '../../../position/service/position.service';
import {Position} from '../../../position/model/position.model';

@Component({
    selector: 'app-payroll-setting-view',
    templateUrl: './payroll-setting-view.component.html',
    styleUrls: ['./payroll-setting-view.component.css'],
    providers: [AuthService, FormValidatorService, PayrollSettingService, PayrollHolidayService,
        DepartmentService, PositionService]
})
export class PayrollSettingViewComponent implements OnInit {
    agencyId: number;
    constructor(
        private route: ActivatedRoute
        ) {
        this.route.params.subscribe(params => {
            this.agencyId = +params.id;
        });
    }

    ngOnInit() {
    }

}
