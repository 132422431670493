import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix small">
            <span class="footer-text-left d-inline-flex">
                <!--<img alt="logo-colored" src="assets/layout/images/logo-colored.png" />-->
                <span class="material-icons">copyright</span>
                <span> Copyright 2019 - OPERRWork All Rights Reserved</span>
            </span>
            <span class="footer-text-right mt-0">
                <!--<span class="material-icons">copyright</span>-->
                <!--<span>All Rights Reserved</span>-->
                <!--<span>Operr Platform build V1.1</span>-->
                <span>Operr Platform build V1.1</span>
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
