
export class SpreadHourSetting {
    id?: number;
    minimumWage?: any ;
    states?: string[];
    companyId?:  number;
    calculateMethod: string;
    statesObj: any[];
    constructor(minimumWage: any, states: any, calculateMethod: any) {
        this.minimumWage = minimumWage;
        this.states = states;
        this.calculateMethod = calculateMethod;
    }
}

