import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PaymentProfileService } from '../../service/payment-profile.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import moment from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { FirebaseNotificationService } from 'app/shared/service/firebase-notification.service';
import { PaymentTransactionService } from 'app/payment/service/payment-transaction.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
    selector: 'app-payment-availability',
    templateUrl: './payment-availability.component.html',
    styleUrls: ['./payment-availability.component.scss'],
    providers: [WindowRefService, PaymentInvoiceService, FirebaseNotificationService]

})
export class PaymentAvailabilityComponent implements OnInit, OnDestroy, AfterViewInit {

    transactionList: any[];
    transactionPdfList: any[];
    loading = false;
    totalRecords = 0;
    isPlatformAdmin: any;
    defaultDate: any;
    msgs = [];
    rowData: any;

    cols: any[];
    size: number;
    page: number = 0;
    sortField = '';
    sortOrder: number;
    selectedSortOption = '';
    selectedField = '';
    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
      ];
      numberSortOptions = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
      ];
      dateSortOptions = [
          { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
          { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
        ];
    fromDate: Date;
    toDate: Date;
    statuses = [
        { label: 'Available', value: 'available' },
        { label: 'In Transit', value: 'pending' }
    ];
    selectedStatus: any;

    searchText: string;
    @ViewChild('searchKeyInput') searchKeyInput: ElementRef;
    source: any;

    private destroyed$ = new Subject<void>();

    availableBalance = 0;

    transactionIds: Set<string>;
    loadedTransactionIds = false;
    exportingPDF = false;
    constructor(
        private paymentProfileService: PaymentProfileService,
        private authService: AuthService,
        private messageService: MessageService,
        private datePipe: DatePipe,
        private paymentTransactionService: PaymentTransactionService
    ) {

    }

    ngOnInit() {
        this.cols = [
            { field: 'type', label: 'Type', sortOptions: '', sort: 'text' },
            { field: 'net', label: 'Net', sortOptions: '', sort: 'number' },
            { field: 'amount', label: 'Amount', sortOptions: '', sort: 'number' },
            { field: 'fee', label: 'Fee', sortOptions: '', sort: 'number' },
            { field: 'description', label: 'Description', sortOptions: '', sort: 'text' },
            { field: 'availableOn', label: 'Available On', sortOptions: '', sort: 'number' },

        ];
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();

        if (this.authService.isSuper() || this.authService.isSubSuper()) {
            this.loadedTransactionIds = true;
            this.search();
        } else {
            const options: any = {};
            options.companyId = this.authService.getCurrentCompanyId();
            this.paymentTransactionService.search(options).subscribe((res: any) => {
                this.transactionIds = new Set<string>();
                res.data.content.filter(data => data.transactionId).forEach(data => this.transactionIds.add(data.transactionId));
                this.loadedTransactionIds = true;
                this.search();
            });
        }
    }

    ngAfterViewInit(): void {
        this.source = fromEvent(this.searchKeyInput.nativeElement, 'keyup');
        this.source.pipe(debounceTime(1200), takeUntil(this.destroyed$)).subscribe(() => {
            // this.search();
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    loadContentsLazy(event?: LazyLoadEvent) {
        this.loading = true;
        this.sortField = event && event.sortField ? event.sortField : '';
        this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
        const options: any = {
            size: event ? event.rows : 10,
            page: event ? (event.first) / event.rows : 0,
        };
        this.loadPage(options);
    }

    search(event?: LazyLoadEvent) {
        this.loading = true;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        const options: any = {};
        options.size = this.size;
        options.page = this.page;
        this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'created';
        this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 1;
    
        options.sortField = this.sortField;
        options.sortOrder = this.sortOrder === 1 ? 'DESC' : 'ASC';

        options.status = this.selectedStatus;
        if (this.fromDate) {
            options.startDate = moment(this.fromDate).startOf('date').toDate().getTime()
        }
        if (this.toDate) {
            options.endDate = moment(this.toDate).endOf('date').toDate().getTime()
        }
        this.loadPage(options);
    }

    onChangeType() {
    }

    loadPage(options?: any) {
        if (!this.loadedTransactionIds) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.transactionList = null;
        if (this.transactionIds) {
            if (this.transactionIds.size === 0) {
                this.transactionList = [];
                this.totalRecords = 0;
                this.availableBalance = 0;
                this.loading = false;
                return;
            }
            options.sources = [...this.transactionIds];
        }
        this.paymentProfileService.searchBalanceTransaction(options).subscribe((res: any) => {
            this.loading = false;
            if (res.status === 200) {
                this.transactionList = res.data.content;
                this.totalRecords = res.data.totalElements;
            }
        }, () => this.loading = false);
        this.paymentProfileService.getAvailableBalance(options).subscribe((res: any) => {
            this.availableBalance = res.data;
        });
    }

    sortingClick(selectedSortOption) {
        this.sortField = this.selectedField;
        this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
        this.page = 0;
        this.search();
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sort = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    reset() {
        this.selectedStatus = null;
        this.fromDate = null;
        this.toDate = null;
        this.page = 0;
        this.sortField = '';
        this.sortOrder = null;
        this.searchText = null;
        this.search();
    }

    async exportTablePdf() {
        this.exportingPDF = true;
        this.messageService.add({ severity: 'info', summary: 'Exporting PDF', detail: 'This may take up to a minute to complete, please wait...'});
        const options: any = {};
        options.size = 9999;
        options.page = 0;
        this.sortField = this.sortField ? this.sortField : 'created';
        this.sortOrder = this.sortOrder ? this.sortOrder : 1;

        options.sortField = this.sortField;
        options.sortOrder = this.sortOrder === 1 ? 'DESC' : 'ASC';

        options.status = this.selectedStatus;
        if (this.fromDate) {
            options.startDate = moment(this.fromDate).startOf('date').toDate().getTime()
        }
        if (this.toDate) {
            options.endDate = moment(this.toDate).endOf('date').toDate().getTime()
        }
        await this.exportData(options);
    }


    async exportData(options) {
        this.exportingPDF = true;
        if (!this.loadedTransactionIds) {
            return;
        }
        this.transactionList = null;
        if (this.transactionIds) {
            if (this.transactionIds.size === 0) {
                return;
            }
            options.sources = [...this.transactionIds];
        }
        this.paymentProfileService.searchBalanceTransaction(options).subscribe((res: any) => {
            this.exportingPDF = false;
            if (res.status === 200) {
                this.transactionPdfList = res.data.content;
                this.transactionList = res.data.content;
                const doc = this.buildPdf();
                let output = doc.output('bloburl');
                window.open(output.toString(), '_blank');
            } else {
                this.exportingPDF = false;
                this.messageService.add({ severity: 'error', summary: 'Export Error', detail: 'Error exporting data to PDF, try again later. If this error persists, contact support.'});
            }
        }, (error) => {
        this.exportingPDF = false;
        this.messageService.add({ severity: 'error', summary: 'Export Error', detail: 'Error exporting data to PDF, try again later. If this error persists, contact support.'});
        });
    }

    getPdfBody() {
        const cloneList = this.transactionPdfList.map(x => Object.assign({}, x));
        return cloneList.map((rowData, i) => {
            return [
                {
                    content: String(i + 1),
                    styles: {
                        halign: 'left'
                    }
                },
                {
                    content: rowData.type,
                    styles: {
                        halign: 'left'
                    }
                },
                {
                    content: '$' + (rowData.net || 0).toFixed(2),
                    styles: {
                        halign: 'right'
                    }
                },
                {
                    content: '$' + (rowData.amount || 0).toFixed(2),
                    styles: {
                        halign: 'right'
                    }
                },
                {
                    content: '$' + (rowData.fee || 0).toFixed(2),
                    styles: {
                        halign: 'right'
                    }
                },
                {
                    content: rowData.description ? rowData.description : '',
                    styles: {
                        halign: 'left'
                    }
                },
                {
                    content: moment(rowData.availableOn).format('MM/DD/YYYY'),
                    styles: {
                        halign: 'left'
                    }
                }
            ];
        });
    }

    buildPdf(): jsPDF {
        const pdfBody: any = this.getPdfBody();
        const doc = new jsPDF();
        autoTable(doc, {
            margin: 0,
            head: [
                [
                    {
                        content: '#',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 10
                        }
                    },
                    {
                        content: 'Type',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 20
                        }
                    },
                    {
                        content: 'Net',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 20
                        }
                    },
                    {
                        content: 'Amount',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 20
                        }
                    },
                    {
                        content: 'Fee',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 20
                        }
                    },
                    {
                        content: 'Description',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 45
                        }
                    },
                    {
                        content: 'Available On',
                        styles: {
                            halign: 'left',
                            fontSize: 11,
                            fontStyle: 'bold',
                            cellWidth: 25
                        }
                    }
                ],
            ],
            theme: 'striped',
            columnStyles: {
                0: {cellWidth: 10},
                1: {cellWidth: 20},
                2: {cellWidth: 20},
                3: {cellWidth: 20},
                4: {cellWidth: 20},
                5: {cellWidth: 45},
                6: {cellWidth: 25},
            },
            styles: {
                lineWidth: 0.5,
            },
            bodyStyles: {
                fillColor: 255
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245]
            },
            body: pdfBody,
        });
        return doc;
    }
}
