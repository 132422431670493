import { Injectable } from '@angular/core';
import { Plan } from '../model/plan.model';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {Page} from "../../shared/model/page.model";

@Injectable({ providedIn: 'root' })
export class PlanService extends AbstractService<Plan>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PLAN, '');
  }

  createContent(entity) {
    return this._http.post(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan`, entity).pipe(map(res => res));
    // return this._http.post(`http://localhost:8007/api/v2/operation/plan`, entity).pipe(map(res => res));
  }

  getContentId(id) {
    return this._http.get(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/${id}`).pipe(map(res => res));
    // return this._http.get(`http://localhost:8007/api/v2/operation/plan/${id}`).pipe(map(res => res));
  }
  getPlanHistoryPage(options?: any): Observable<Page<any>> {
    const params = this.createParams(options);
    return this._http.post<Page<any>>(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/history/search`, options).pipe(map(resp => resp));
    // return this._http.post<Page<any>>(`http://localhost:8007/api/v2/operation/plan/history/search`, options).pipe(map(resp => resp));
  }
  updateContent(entity, id) {
    return this._http.put(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan`, entity).pipe(map(res => res));
    // return this._http.put(`http://localhost:8007/api/v2/operation/plan/${id}`, entity).pipe(map(res => res));
  }

  getContentPagePreview(options?: any) {
    // const params = this.createParams(options);
    return this._http.post(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/search`, options).pipe(map(res => res));
    // return this._http.post(`http://localhost:8007/api/v2/operation/plan/search`, options).pipe(map(res => res));
  }

  getContentPage(options?: any) {
    // const params = this.createParams(options);
    return this._http.post(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/search-list`, options).pipe(map(res => res));
    // return this._http.post(`http://localhost:8007/api/v2/operation/plan/search-preview`, options).pipe(map(res => res));
  }

  deleteContent(id: Number) {
    return this._http.delete(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/${id}`).pipe(map(data => data));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/exportPdf`, data).pipe(map(resp => resp));
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/plan/exportPdf`, data).pipe(map(resp => resp));
  }

  createParams(options: any): HttpParams {
    let params = new HttpParams();
    if (options) {
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          params = params.set(key, options[key]);
        }
      }
    }
    return params;
  }
  getCreatedByList(options: any): Observable<any> {
    let params = new HttpParams();
    if (options.companyId) {
      params = params.set('companyId', options.companyId);
    } else {
      params = params.set('companyId', 0);
    }
    return this._http.get<any>(`${this.baseUrlV2}getCreatedBy`, { params }).pipe(map(resp => resp));
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/plan/getCreatedBy`, { params }).pipe(map(resp => resp));
  }

}
