import { CompanyV2 } from "../model/v2/company.model";
import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanySearch } from "../../shared/model/search/compnay.search";


@Injectable({ providedIn: 'root' })
export class CompanyServicev2 extends AbstractServiceV2<CompanyV2, CompanySearch> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.COMPANY, '');
    }

    getDropdown(searchParams: CompanySearch): Observable<any> {
        const params = this.createParams(searchParams);
        return this.http.get(this.baseUrlV2 + "/dropdown", { params: params }).pipe(res => res);
    }


    updateCompany(entity: any, id: Number): Observable<any> {
        // return this._http.put<any>(`http://localhost:8003/api/v2/company/` + id, entity).pipe(map(res => res));
        return this._http.put<any>(`${this.baseUrlV2}/` + id, entity).pipe(map(res => res));
    }

    createCompany(entity: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8003/api/v2/company/`, entity).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
    }

    updateOperrTelKeySetup(companyId: number, options: any): Observable<any> {
        return this._http.put<any>(`${this.baseUrlV2}/operrtel-key-setup/${companyId}`, options).pipe(map(res => res));
    }

    deleteOperrTelKeySetup(operrTelKeyId: number, options: any): Observable<any> {
        // return this._http.put<any>(`http://localhost:8003/api/v2/company/delete-operrtel-key-setup/${operrTelKeyId}`, options).pipe(map(res => res));
        return this._http.put<any>(`${this.baseUrlV2}/delete-operrtel-key-setup/${operrTelKeyId}`, options).pipe(map(res => res));
    }

    getOperrTelKeySetup(companyId: number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}/valid-key-setup/${companyId}`).pipe(map(res => res));
    }
    deleteOperrTelKeySetupByPhone(options: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8003/api/v2/company/delete-operrtel-key-by-phone`, options).pipe(map(res => res));
        return this._http.post<any>(`${this.baseUrlV2}/delete-operrtel-key-by-phone`, options).pipe(map(res => res));
    }
}
