<p-toast position="bottom-right">{{msgs}}</p-toast>
<p-panel>
    <p-header *ngIf="mode === 'create'">
        Add Holiday Payment Rate
    </p-header>
    <p-header *ngIf="mode !== 'create'">
        Edit Holiday Payment Rate
    </p-header>
    <div class="ui-g ui-fluid">
        <div class="ui-g-12">
            <button pButton type="button" label="Back"
                    class="wd-auto"
                    (click)="goBack()"></button><br/>
            <div class="ui-g-6 ui-g-offset-3">
                <div class="ui-g-4">Holiday *:</div>
                <div class="ui-g-8">
                    <p-dropdown [options]="holidays" optionLabel = "holidayName"
                                [style]="{'width':'100%'}"
                                placeholder="Please Select" dataKey="id"
                                [(ngModel)]="holiday" (onChange)="changeHoliday($event)"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="ui-g-12">
            <p-table [value] = "payrollHolidays">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>Position</th>
                        <th>Department</th>
                        <th>Holiday rate (1.5 time employee rate)</th>


                    <th width="15%" *ngIf="mode == 'create'"><button label="Add Row" pButton  (click)="addRowPayrollHoliday()"></button></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>
                        <p-multiSelect [options]="positions"
                                       [(ngModel)]="rowData.positions" [ngModelOptions]="{standalone: true}"
                                       (onChange)="changePositions($event)" id=""
                                       [style]="{'width':'100%'}" class="flex-auto" optionLabel="positionName"></p-multiSelect>
                        <!--<p-dropdown [options]="positions" optionLabel = "name"-->
                                    <!--[style]="{'width':'100%'}"-->
                                    <!--placeholder="Please Select" dataKey="id" [(ngModel)]="rowData.position"></p-dropdown>-->
                    </td>
                    <td>
                        <p-multiSelect [options]="departments"
                                       [(ngModel)]="rowData.departments" [ngModelOptions]="{standalone: true}"

                                       [style]="{'width':'100%'}" class="flex-auto" optionLabel="name"></p-multiSelect>
                        <!--<p-dropdown [options]="departments" optionLabel = "name"-->
                                    <!--[style]="{'width':'100%'}"-->
                                    <!--placeholder="Please Select" dataKey="id" [(ngModel)]="rowData.department" (onChange)="changeDepartment($event)"></p-dropdown>-->
                    </td>
                    <td>
                        <input type="text" pInputText  [(ngModel)]="rowData.holidayMultipler">
                    </td>
                    <td *ngIf="mode == 'create'"></td>

                    </tr>
                </ng-template>
            </p-table>


        </div>
        <div class="ui-g-4 ui-g-offset-4 ">
            <button pButton type="submit" label="Submit" style="margin-bottom:10px" class="orange-btn pull-right" (click)="updatePayrollHoliday()" *ngIf="mode == 'edit'"></button>
            <button pButton type="submit" label="Submit" style="margin-bottom:10px" class="green-btn pull-right" (click)="addPayrollHoliday()" *ngIf="mode == 'create'"></button>
        </div>
    </div>
</p-panel>
