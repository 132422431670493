import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '../../service/company.service';

@Component({
  selector: 'app-company-setting-edit',
  templateUrl: './company-setting-edit.component.html',
  styleUrls: ['./company-setting-edit.component.css'],
  providers: [CompanyService]
})
export class CompanySettingEditComponent implements OnInit {

  companyId: Number;
  constructor(private companyService: CompanyService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.companyId = +params.id);
  }

  ngOnInit() {
  }

}
