import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ClientForgotPasswordService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CLIENT_FORGOT_PASSWORD, '');
  }


  requestResetPassword(request: any): Observable<any> {
    this.checkServerUrl(request)
    return this.http.post(`${this.baseUrlV2}`, request);
  }

  checkValidToken(token: string): Observable<any> {
    return this.http.get(`${this.baseUrlV2}check-token?token=${token}`);
  }
  resetPassword(token: string, req: any): Observable<any> {
    return this.http.post(`${this.baseUrlV2}${token}`, req);
  }
}