export class CrmClient {
    id: number;
    agencyId: number;
    companyId: number;
    name: string;
    baseNo: string;
    stage: string;
    probability: number;
    address: string;
    description: string;
    phone: string;
    phone2: string;
    email: string;
    contactEmail: string;
    type: string;
    totalCar: number;
    activeCar: number;
    totalDriver: number;
    activeDriver: number;
    numberOfAdmin: number;
    presentProduct: string;
    website: string;
    existingYears: number;
    assignedTo: string;
    provideService: string;
    note: string;
    status: number;
    createdBy: number;
    stageChanged: boolean;
    possibility: string;
    descriptionOther?: string;
    transferAccepted: boolean;
    transferRejected: boolean;
    transferring: boolean;
    transferTime: Date;
    showHideAssignedToDropDown?: boolean;
    showHidePhone?: boolean;
    showHideEmail?: boolean;
    showHideAddress?: boolean;
    showHideName?: boolean;
    showHideStageDropDown?: boolean;
    assignedToValue?: any[''];
    labelIds: any;
    labelNames: any;
    stageId: number;
    pipelineId: number;
    createdByUsr?:string;
    frontEndUrl?:string;
    fullNameCreatedBy?:string;
    key: any;
    supportId?:number;
    supportName?:string;
    roleId?:number;
    password?:string;
    createdAt?:any;
    username?:any;
    crmProductPriceDtos?:any;
    taxId?: string;
    providerId?: string;
    fileNameSource?: string;
    reminderDate?: any;
    reminderStr?: any;
    enableReminder?: boolean;
}
