import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../payroll-setting/model/agency.model';
import { RoleLevel } from '../../../layout/component/app-menu/role-level';
import { User } from '../../../shared/model/user/user.model';
import { AgencyService } from '../../../agency/service';
import { CrmCommService } from '../../../crm/service/crm-comm.service';
import { PaymentInvoiceService } from '../../service/payment-invoice.service';
import { PaymentInvoice } from '../../model/payment-invoice.model';
import { PhonePackageService } from '../../../phonesystem/service/phone-package.service';
import { PhonePackage } from '../../../phonesystem/model/phone-package.model';
import { Subject } from 'rxjs/Subject';
import { interval } from 'rxjs/observable/interval';
import { takeUntil } from 'rxjs/operators';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ClientService } from '../../../crm/service/client.service';
import { ProductService } from '../../../crm/service/product.service';
import { DatePipe } from '@angular/common';
import { FollowUpBillingInvoiceService } from 'app/payment/service/follow-up-billing.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { FollowUpBillingInvoice } from 'app/payment/model/follow-up-billing-invoice.model';
import * as moment from 'moment';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-payment-available-credit',
  templateUrl: './payment-available-credit.component.html',
  styleUrls: ['./payment-available-credit.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService, FollowUpBillingInvoiceService,
    OperatorService,
     DocumentsService, BrowserNotificationService, PhonePackageService]
})
export class PaymentAvailableCreditListComponent implements OnInit, OnDestroy {
  detailEnable = false;
  contentList: any = [];
  details = [];
  totalRecords: Number = 0;
  items: MenuItem[];
  msgs: Message[] = [];
  fileUrl: any;

  private destroyed$ = new Subject<void>();
  loading = true;
  tableRows = 10;
  sumFee = 0;
  balance = 0;
  balanceTxt = '';
  sumMinFee = 0;
  sumTax = 0;
  sumTotalAmount = 0;
  sumFeeTxt: string;
  sumMinFeeTxt: string;
  sumTaxTxt: string;
  sumTotalAmountTxt: string;
  sumOutStandingBalanceTxt: string;
  sumOutStandingBalance = 0;
  sumPaidFee = 0;
  sumPaidFeeTxt: string;
  @ViewChild('dt', { static: true }) table: Table;
  userPermission: Boolean;
  selectedFollowUpNote: any;
  attachment = 'pi pi-upload';
  attachment1 = 'pi pi-upload';
  showHistoryDialog = false;
  uploadOption = null;
  rowData: any;
  size: number = 9999;
  page: number;
  allCompanyList: any = [];
  allAgencyList: any = [];
  phonePackages: PhonePackage[];
  invoiceNumber: string;
  searchText: string;
  selectedCompany: Company;
  selectedAgency: Agency;
  selectedPhonePackage: PhonePackage;
  selectedStatus: string;
  user: User;
  loggedInRole: number;
  disableCompany = true;
  disableAgency = false;
  fromDate: Date;
  selectedMethodContact = '';
  toDate: Date;
  rangeDates: any;
  statusTypes: any[] = [];
  invoiceFilter: any;
  enabledGenerate = false;
  followUpDate: any;
  rowsPerPageOptions: any[];
  rowsPerPageItems: SelectItem[];
  loadingSpinner = false;
  sortF: any = '';
  isLoadingExport = false;
  showConfirmDialog = false;
  deleteFollowUpId: any;
  isOpenCollectionNote = false;
  message = '';
  followUpParamId: any;
  cols: any[] = [
    { field: 'index', label: '#', sortOptions: '', sort: 'text' },
    { field: 'name', label: 'Client/Base', sortOptions: '', sort: 'text' },
    { field: 'orginalAmount', label: 'Credit', sortOptions: '', sort: 'number' },
    { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text' },
    { field: 'updatedAt', label: 'Updated', sortOptions: '', sort: 'number' },
  ];
  sortField: string;
  sortOrder: number;

  textSortOptionsClient = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptionsClient = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedSortOption = '';
  selectedField = '';
  allClientList: any = [];
  clientList: any = [];
  selectedClient;
  productList = [];
  showViewFileDialog = false;
  isPlatformAdmin = false;
  disableFields = false;
  selectedCollectionPresentative = '';
  headerNote = 'Collection Note';
  followUpNote: any;
  selectedContact = '';
  selectedNoteUpdate: any = {};
  selectedFollowUpNoteId: any;
  paymentInvoiceData: any = {};
  assignedToEmployeeList: any = [];
  employeeList: any = [];
  @ViewChild('notesEditor', { static: true })
  concernEditor: any;
  charactersLength = 0;
  MAX_LENGTH = 500;
  firstRowOnPage = 0;  // The index of the first row on the current page
  rowsPerPage = 10;    // The number of rows per page

  constructor(
    private phonePackageService: PhonePackageService,
    private browserNotificationService: BrowserNotificationService,
    private contentService: PaymentInvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private operatorService: OperatorService,
    private documentsService: DocumentsService,
    private messageService: MessageService,
    private translatePipe: TranslatePipe,
    private datePipe: DatePipe,
    private productService: ProductService) {
  }

  ngOnInit() {
    this.loggedInRole = this.authService.getRoleLevel();
    if (this.loggedInRole === RoleLevel.ROLE_CLIENT) {
      this.disableFields = true;
    }
    this.rowsPerPageOptions = [5, 10, 20];
    this.rowsPerPageItems = [];
    this.rowsPerPageOptions.forEach(rowPerPage => {
      this.rowsPerPageItems.push({ label: String(rowPerPage), value: rowPerPage });
    });
    this.statusTypes = [{ label: 'All', value: '' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Paid', value: 'Paid' }
    ];
    this.contentList = [];
    this.userPermission = this.authService.adminRole();
    this.searchText = '';
    this.loadAllClient();
    this.enabledGenerate = false;
    this.disableAgency = true;
    this.disableCompany = true;

    if (this.loggedInRole === RoleLevel.ROLE_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_COMPANY_ADMIN) {
      this.disableCompany = true;
      this.disableAgency = false;
    }

    if (this.loggedInRole === RoleLevel.ROLE_AGENCY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_AGENCY_ADMIN) {
      this.disableAgency = true;
      this.disableCompany = true;
    }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.loggedInRole === RoleLevel.ROLE_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_ADMIN) {
      this.disableAgency = false;
      this.disableCompany = false;
    }
    this.invoiceFilter = {
      fromDate: null,
      toDate: null,
      page: 0,
      size: 10,
      search: '',
      agencyId: 0,
      companyId: 0,
      packageId: 0,
      status: '',
      invoiceNumber: '',
      sort: null
    };
    this.detailEnable = false;
    this.search();
  }

  loadAllClient() {
    const options: any = { size: 9999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data.map(c => ({ value: c.key, label: c.value }));
        this.clientList = resObj.data;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }




  resetFilters() {
    this.invoiceNumber = null;
    this.selectedStatus = null;
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.searchText = null;
    this.fromDate = null;
    this.toDate = null;
    this.sortField = null;
    this.sortOrder = null;
    this.page = 0;
    this.size = 10;
    this.selectedClient = null;
    this.search();
  }

  search(event?: LazyLoadEvent) {

    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
        this.page = 0;
    }

    const options: any = {
        size: this.size,
        page: this.page,
        companyId: this.selectedCompany ? this.selectedCompany.key : null,
    };

    // Removed the row index reset line
    // this.table.first = 0;

    options.size = this.size;
    options.page = this.page;
    this.invoiceFilter.page = 0;
    this.invoiceFilter.size = this.table.rows;

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient;
    }

    if (this.searchText) {
      options.searchText = this.searchText;
    }

    this.loadPage(options);
}


  searchBySelectedDate(date) {
    if (date.startDate) {
      this.fromDate = date.startDate;
    }
    if (date.endDate) {
      this.toDate = date.endDate;
    }
  }

  loadPage(options) {
    this.contentList = [];
    options.oldInvoiceOnly = false;
    options.companyId = this.authService.getCurrentCompanyId();
    // options.size = 9999;
    this.contentService.searchCreditAmount(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        this.contentList = resObj.data.data.content;
		const startIndex = options.page * options.size; // Calculate the starting index based on the current page and size
		this.contentList = this.contentList.map((content, index) => {
		  return {
        number: startIndex + index + 1,
        ...content,
        orginalAmount: content.creditAmount,
        creditAmount: content.creditAmount ? '$' + (content.creditAmount).toFixed(2) : '',
        updatedAt: this.datePipe.transform(content.updatedAt, 'MM/dd/yyyy h:mm a')
		  };
		});
		
        this.totalRecords = resObj.data.data.totalElements;
      }
    });
  }


  loadAllCompanyList() {
    const pafc = this;
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        // this.allCompanyList = resObj.data;
        resObj.data.forEach(rs => {
          this.allCompanyList.push({ value: rs.key, label: rs.value });
        });
        if (this.user.companyId) {
          const company = this.allCompanyList.find(c => c.value === this.user.companyId);
          this.selectedCompany = company.value;
        }
      }
    });
  }

  loadAgencyBasedOnCompanyId() {
    const pafc = this;
    this.dropDownsService.getAllAgencyByCompanyId(this.selectedCompany).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allAgencyList = resObj.data;
      }
    });
  }
  sortDataSelected(selectedSortOption, event: LazyLoadEvent) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.contentList
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }
  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  openConfirmDeleteDialog(rowData: any) {
    this.showConfirmDialog = true;
    this.deleteFollowUpId = rowData.id;
    this.message = this.translatePipe.transform('Are you sure you want to Delete this record');
  }

  closeConfirmDeleteDialog() {
    this.showConfirmDialog = false;
  }


    exportTablePdf() {
        this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Exporting'),
            detail: this.translatePipe.transform('Export has started, please wait. This may take up to a few minutes to complete.')
        });
        let options: any = {
            page: 0,
            size: 9999
        };
        if (this.selectedClient != null) {
            options.clientId = this.selectedClient;
        }
        if (this.searchText) {
            options.searchText = this.searchText;
        }
        options.oldInvoiceOnly = false;
        options.companyId = this.authService.getCurrentCompanyId();
        this.contentService.searchCreditAmount(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const clientInfoList = resObj.data.data.content;
                clientInfoList.forEach(ele => {
                    const client = this.clientList?.find((p) => p.value === ele.clientId);
                    ele.client = client?.label;
                    ele.taxId = ele.taxId ? ele.taxId : '';
                });
                const data: any = {
                    contentList: clientInfoList.map((value, index) => {
                        return {
                            id: index + 1,
                            client: value.name,
                            creditAmount: value.creditAmount? '$' + value.creditAmount : '',
                            updated: this.datePipe.transform(value.updatedAt, 'MM/dd/yyyy h:mm a'),
                            createdByUsr: value.createdByUsr,
                        };
                    })
                };
                data.companyId = this.authService.getCurrentCompanyId();
                data.username = this.authService.getCurrentUsername();
                data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy h:mm a');
                this.contentService.exportCreditPdf(data).subscribe(res => {
                    if (res.data) {
                        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank');
                    } else {
                        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'), detail: this.translatePipe.transform('Problem exporting client account pdf list')});
                    }
                });
            }
        });

    }
}
