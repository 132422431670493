<h1 class="m-0 ml-2 my-4">{{'Billing Statuses' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Billing Client Statuses' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Uploader' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="createdByList" placeholder="{{'Select One' | translate}}" [(ngModel)]="createdSelected" [filter]="true">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>

		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchList()"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-start my-4">
	<h1 class="m-0 ml-2">{{'Browse Statuses' | translate}}</h1>
</div>

<p-panel id="table-ppanel"[toggleable]="true">
	<p-header class="flex-grow-1">
		<p-header><i class="fa-solid fa-table text-primary mr-2"></i>{{'Billing Client Statuses' | translate}} ({{totalRecords}})</p-header>
	</p-header>
	<p-table #dt class="para-table-text" selectionMode="single" [columns]="cols" [value]="billingReports" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" dataKey="id" (onLazyLoad)="loadBillingReports($event)" [lazy]="false" [responsive]="true"
			 [globalFilterFields]="['client', 'totalJobUploadedBy','totalJobUpTime', 'clientUpdateTotalJobBy', 'billingAddedDate', 'billingReportUploadedBy', 'billingAddedBy', 'status']">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
				<div class="d-flex">
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input [(ngModel)]="searchText" (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" pInputText />
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of cols" [style.width]="col.width">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'billingAdded'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op4.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'totalJobUpTime'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op4.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td>
					<span class="one-liner">{{i+1}}</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.client" pTooltip="{{rowData.client}}">{{rowData.client}}</span>
						<span *ngIf="!rowData.client" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total Job Uploader' | translate}}: </span>
						<span *ngIf="rowData.totalJobUploadedBy" pTooltip="{{rowData.totalJobUploadedBy}}">{{rowData.totalJobUploadedBy}}</span>
						<span *ngIf="!rowData.totalJobUploadedBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total Job Up Time' | translate}}: </span>
						<span *ngIf="rowData.totalJobUpTime" pTooltip="{{rowData.totalJobUpTime | date: 'MM/dd/yyyy hh:mm:ss' }}">{{rowData.totalJobUpTime | date: 'MM/dd/yyyy hh:mm:ss' }}</span>
						<span *ngIf="!rowData.totalJobUpTime" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client Return/Update Total Job' | translate}}: </span>
						<span *ngIf="rowData.clientUpdateTotalJobBy" pTooltip="{{rowData.clientUpdateTotalJobBy}}">{{rowData.clientUpdateTotalJobBy}}</span>
						<span *ngIf="!rowData.clientUpdateTotalJobBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Report Uploader' | translate}}: </span>
						<span *ngIf="rowData.billingReportUploadedBy" pTooltip="{{rowData.billingReportUploadedBy}}">{{rowData.billingReportUploadedBy}}</span>
						<span *ngIf="!rowData.billingReportUploadedBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Report Up Time' | translate}}: </span>
						<span *ngIf="rowData.billingReportUpTime" pTooltip="{{rowData.billingReportUpTime | date: 'MM/dd/yyyy hh:mm:ss'}}">{{rowData.billingReportUpTime | date: 'MM/dd/yyyy hh:mm:ss'}}</span>
						<span *ngIf="!rowData.billingReportUpTime" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Added by' | translate}}: </span>
						<span *ngIf="rowData.billingAddedBy" pTooltip="{{rowData.billingAddedBy}}">{{rowData.billingAddedBy}}</span>
						<span *ngIf="!rowData.billingAddedBy" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Added' | translate}}: </span>
						<span *ngIf="rowData.billingAdded" pTooltip="{{rowData.billingAdded | date: 'MM/dd/yyyy hh:mm:ss' }}">{{rowData.billingAdded | date: 'MM/dd/yyyy hh:mm:ss' }}</span>
						<span *ngIf="!rowData.billingAdded" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Status' | translate}}: </span>
						<span *ngIf="rowData.status" pTooltip="{{rowData.status}}">{{rowData.status}}</span>
						<span *ngIf="!rowData.status" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>