import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProtestHourService } from 'app/employee/service/protest-hour.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-protest-hour-history',
  templateUrl: './protest-hour-history.component.html',
  styleUrls: ['./protest-hour-history.component.scss']
})
export class ProtestHourHistoryComponent implements OnInit {

  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false, width: '22%' },
    { field: 'userName', label: 'User', sortable: false, width: '20%' },
    { field: 'oldValue', label: 'Old Value', sortable: false, width: '20%' },
    { field: 'newValue', label: 'New Value', sortable: false, width: '20%' },
    { field: 'action', label: 'Action', sortable: false, width: '18%' }
  ];

  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  size: number;
  page: number;
  rangeDates: Date[] = []
  historyFor: any = {};

  selectedField: string = '';
  loading: Boolean = false;
  @Output() onHideHistory: EventEmitter<any> = new EventEmitter();

  @Input() protestHourId:number;
  @Input() employeeId:number;
  @Input() fieldsName:any;

  showViewFileDialog = false;
  fileUrl = '';
  
  constructor(
    private operatorService: OperatorService,
    private documentsService: DocumentsService,
  ) { }

  ngOnInit(): void {
    this.selectedField = 'documentId';
    this.loadDataSource();
  }

  selectField(event) {
    this.historyFor = this.fieldsName.find(field => event.value === field.value)
  }

  loadDataSource(event?: any) {
    this.loading = true; // Indicate that loading has started

    this.dataSourceSubject.next([]);
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    const options: any = {
        size: this.size,
        page: this.page,
        fieldName: this.selectedField,
        protestHourId: this.protestHourId,
        employeeId: this.employeeId
    };

    if (this.rangeDates[0] && this.rangeDates[1]) {
        options.fromDate = new Date(this.rangeDates[0]).getTime();
        options.toDate = new Date(this.rangeDates[1]).getTime();
    }

    if (!this.selectedField) {
        this.dataSourceSubject.next([]);
        this.loading = false; // No operation is to be performed, so stop loading
        return;
    }

    this.operatorService.getProtestHourHistory(options).subscribe(
        (data: any) => {
            this.dataSourceSubject.next(data.data);
            this.loading = false; // Indicate that loading has ended on successful data retrieval
        },
        (error: any) => {
            console.error('Error retrieving data:', error);
            this.loading = false; // Indicate that loading has ended even if there was an error
        }
    );
}

  public hideHistory() {
    this.selectedField = '';
    this.dataSourceSubject.next([]);
    this.onHideHistory.emit();
  }

  reset() {
    this.rangeDates = [];
    this.selectedField = null;
    this.historyFor = {};
    this.loadDataSource();
  }

  onViewDocument(data) {
    if (data) {
      this.documentsService.getUploadedFile(data).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          if (resObj.data.fileType.match(/(jpeg|jpg|gif|png)$/) != null) {
            this.fileUrl = resObj.data.fileUrl;
            this.showViewFileDialog = true;
          } else if (resObj.data.fileType.match(/(pdf|xlsx|txt|docx|doc|xls)$/) != null) {
              window.location.href = resObj.data.fileUrl;
          } else {
              window.open(resObj.data.fileUrl);
          }
        }
      }, error1 => {
        // error
      });
        
    }
  }

  close() {
    this.fileUrl = null;
    this.showViewFileDialog = true;
  }
}
