import { GoogleMapsAPIWrapper } from "@agm/core";
import { DatePipe } from "@angular/common";
import { AfterViewInit, Directive, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { VoterListService } from "app/electoral-campaign/voter-list/service/voter-list.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import Supercluster from "supercluster";

declare let google: any;

@Directive({
  selector: "voter-markercluster-map",
})
export class VoterMarkerClusterMapDirective implements OnInit, AfterViewInit, OnChanges {

  @Input() selectedVoterIds: any = [];
  @Input() selectedTurfVoterIds: any = [];

  @Output()
  selectedSendSmsChange = new EventEmitter();

  map;
  supercluster: Supercluster;
  isSuperclusterLoaded = false;
  iconMap = new Map();
  currentInfowindow;

  voterMarkers: any[] = [];
  loadedVoters = [];

  private votersUnsubscribe = new Subject();

  constructor(
    private voterService: VoterListService,
    private gmapsApi: GoogleMapsAPIWrapper,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.gmapsApi.getNativeMap().then((map) => {
      this.map = map;
      this.showVoters();
      google.maps.event.addListener(map, 'click', () => {
        if (this.currentInfowindow) {
          this.currentInfowindow.close();
        }
      });
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        this.ngZone.runOutsideAngular(() => {
          this.updateClusters();
        });
      });
      google.maps.event.addListener(this.map, 'dragend', () => {
        this.ngZone.runOutsideAngular(() => {
          this.updateClusters();
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map) {
      if (
        changes["selectedVoterIds"] &&
        changes["selectedVoterIds"].previousValue !==
        changes["selectedVoterIds"].currentValue
      ) {
        this.ngZone.runOutsideAngular(() => {
          this.showVoters();
        });

      }
      if (
        changes["selectedTurfVoterIds"] &&
        changes["selectedTurfVoterIds"].previousValue !==
        changes["selectedTurfVoterIds"].currentValue
      ) {
        this.ngZone.runOutsideAngular(() => {
          this.showVoters(this.selectedTurfVoterIds);
        });

      }
    }
  }

  showVoters(turfVoterIds?: Set<number>) {
    if (this.votersUnsubscribe) {
      this.votersUnsubscribe.next();
      this.votersUnsubscribe.complete();
    }
    this.votersUnsubscribe = new Subject();
    let ids = this.selectedVoterIds;
    if (turfVoterIds) {
      turfVoterIds.forEach(id => ids.add(id));
    }
    const loadedVoters = this.loadedVoters.filter(v => ids.has(v.id));
    const voterIds = new Set<number>();
    const addedIds = new Set<number>(loadedVoters.map(v => v.id));
    ids.forEach(vId => {
      if (!addedIds.has(vId)) {
        voterIds.add(vId);
      }
    });
    if (voterIds.size == 0) {
      this.showVoterMarkers(loadedVoters);
    } else {
      this.voterService.getVoterLatLngByIds([...voterIds]).pipe(takeUntil(this.votersUnsubscribe)).subscribe((res: any) => {
        res.data.forEach(voter => {
          if (!addedIds.has(voter.id)) {
            addedIds.add(voter.id);
            loadedVoters.push(voter);
            this.loadedVoters.push(voter);
          }
        });
        this.showVoterMarkers(loadedVoters);
      })
    }
  }

  initSupercluster() {
    return new Supercluster({
      radius: 40,
      maxZoom: 18,
      map: (props) => ({
        voterCount: props.voterCount,
        voterList: props.voterList
      }),
      reduce: (accumulated, props) => {
        accumulated.voterCount += props.voterCount;
        if (accumulated.voterList && props.voterList) {
          const combinedVoterIDs = new Set([...accumulated.voterList, ...props.voterList]);
          accumulated.voterList = Array.from(combinedVoterIDs);
        }
      }
    });
  }

  showVoterMarkers(voters) {
    this.isSuperclusterLoaded = false;
    this.supercluster = this.initSupercluster();
    let voterMarkerMap = voters.reduce((group, voter) => {
      const key = '' + voter.lat + '_' + voter.lng;
      if (!group.has(key)) {
        group.set(key, []);
      }
      group.get(key).push(voter);
      return group;
    }, new Map());
    const voterData: any[] = [];
    for (const [key, voterList] of voterMarkerMap) {
      const position = { lat: voterList[0].lat, lng: voterList[0].lng };
      voterData.push({
        type: 'Feature',
        properties: {
          cluster: false,
          voterCount: voterList.length,
          voterList: voterList
        },
        geometry: { type: 'Point', coordinates: [position.lng, position.lat] }
      })
    }
    if (voterData.length) {
      this.isSuperclusterLoaded = true;
      this.supercluster.load(voterData);
    }
    this.updateClusters();
  }

  updateClusters() {
    this.voterMarkers.forEach((e) => {
      e.setMap(null);
    });
    if (!this.isSuperclusterLoaded) {
      return;
    }
    const bounds = this.map.getBounds();
    const bbox: GeoJSON.BBox = [bounds.getSouthWest().lng(), bounds.getSouthWest().lat(), bounds.getNorthEast().lng(), bounds.getNorthEast().lat()];
    const zoom = this.map.getZoom();
    const clusters = this.supercluster.getClusters(bbox, Math.floor(zoom));
    this.voterMarkers = [];
    let zoomLevel = this.map.getZoom();
    let newScaledSize = this.calculateScaledSize(zoomLevel);
    let radius = this.getRadius(zoomLevel);
    let textSize = this.getTextSize(zoomLevel);
    const markers: any = [];
    clusters.forEach(cluster => {
      const [lng, lat] = cluster.geometry.coordinates;
      const position = { lat: lat, lng: lng };
      const props = cluster.properties;
      const isCluster = cluster.properties.cluster;
      const voterList = props.voterList;
      let markerColor;
      if (voterList.find(v => v.status === 'Completed')) {
        markerColor = '#008000';
      } else {
        markerColor = '#000000';
      }
      let icon;
      const keyIcon = props.voterCount + markerColor;
      if (this.iconMap.has(keyIcon)) {
        icon = this.iconMap.get(keyIcon);
      } else {
        icon = {
          url: this.createSvgIcon(markerColor, props.voterCount, radius, textSize),
          anchor: new google.maps.Point(20, 20),
          size: newScaledSize
        };
        this.iconMap.set(keyIcon, icon);
      }
      const marker = new google.maps.Marker({
        position: position,
        map: this.map,
        icon: icon,
      });
      marker.addListener('click', (event) => {
        if (this.currentInfowindow) {
          this.currentInfowindow.close();
        }
        if (isCluster) {
          const zoomLevel = this.map.getZoom();
          this.map.panTo(event.latLng);
          this.map.setZoom(zoomLevel + 2);
          return;
        }
        let contentString = `<div style="max-height: 400px;"><button class="btn btn-primary btn-block" id="sendSms"> Send SMS/MMS </button>`;
        let count = 1;
        for (let voter of voterList) {
          const gender = voter.gender ? voter.gender.charAt(0) : '';
          const scheduledDate = voter.scheduleFor ? this.datePipe.transform(voter.scheduleFor, 'MM/dd/yyyy') : 'N/A';
          let fullNameStyle = voter.status === 'Completed' ? `<b style="font-weight: 600; color: green">${voter.fullName} (${gender})</b><br>`
            : `<b style="font-weight: 600;">${count}. ${voter.fullName} (${gender})</b><br>`;
          contentString += `
            <div style="border: 1px solid rgba(0,0,0,.125); padding: 10px;">
              ${fullNameStyle}
              <b>Address: ${voter.fullAddress}</b><br>
              <b>DOB: ${voter.birthYear ? voter.birthYear : ''}</b><br>
              <b>Phone: ${voter.phone ? voter.phone : ''}</b><br>
              <b>Scheduled Date: ${scheduledDate}</b><br>
              <b>Status: ${voter.status ? voter.status : 'N/A'}</b><br>
              <b>Zone: ${voter.zoneTitle ? voter.zoneTitle : ''}</b><br>
            </div>`;
          count++;
        }
        contentString += '</div>';
        const infowindow: any = new google.maps.InfoWindow({
          content: contentString
        });
        infowindow.addListener('domready', () => {
          const button = document.getElementById('sendSms');
          if (button) {
            button.addEventListener('click', () => {
              this.sendSms(voterList, voterList.length);
            });
          }
        });
        infowindow.open({
          anchor: marker,
          map: this.map as any
        });
        this.currentInfowindow = infowindow;
      });
      markers.push(marker);
    });
    this.voterMarkers = markers;
  }

  sendSms(voterList, numVoter) {
    console.log("Send SMS")
    console.log(voterList)
    voterList.numVoter = numVoter;
    this.selectedSendSmsChange.emit(voterList);
  }

  calculateScaledSize(zoomLevel) {
    if (zoomLevel <= 16) {
      return new google.maps.Size(40, 40);
    }
    if (zoomLevel < 18) {
      return new google.maps.Size(60, 60);
    }
    if (zoomLevel < 20) {
      return new google.maps.Size(80, 80);
    }
    return new google.maps.Size(120, 120);
  }

  getRadius(zoomLevel) {
    if (zoomLevel < 20) {
      return 60;
    }
    return 100;
  }

  getTextSize(zoomLevel) {
    if (zoomLevel < 20) {
      return 50;
    }
    return 80;
  }

  createSvgIcon(color, text, radius, textSize) {
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
      <circle cx="120" cy="120" opacity=".9" r="${radius}" />
      <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="${textSize}" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${text}</text>
    </svg>`;
    return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
  }
}