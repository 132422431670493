import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-one-time-payment',
    templateUrl: './one-time-payment.component.html',
    styleUrls: ['./one-time-payment.component.scss']
})
export class OneTimePaymentComponent implements OnInit {

    content = {};
    selectData: any;
    invoiceNumber;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.params.subscribe(params => this.invoiceNumber = params.id);
    }

    ngOnInit(): void {
        console.log('this.invoiceNumber', this.invoiceNumber);
    }

    back(event?) {
        this.router.navigate(['app/one-time-payment-thankyou']);
    }

}
