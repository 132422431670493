import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as moment from "moment-timezone";

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit, OnChanges {
    @Input()
    file: any;
    @Output()
    fileChange: EventEmitter<File> = new EventEmitter();

    @Input()
    imageSrc;
    @Output()
    imageSrcChange: EventEmitter<string> = new EventEmitter();

    @Input()
    fileName;

    @Input()
    fileUrl;

    @Input()
    readonly = false;

    @Input()
    createdAt;

    @Input() view;

    @Output()
    fileNameChange: EventEmitter<string> = new EventEmitter();
	showPreview = false;
    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.file) {
          this.readFileAsDataURL();
        }
    }

    onFileUploadChange(event) {
        if (event && event.target && event.target.files[0]) {
            this.file = event.target.files[0];
            console.log('this.file', this.file);
            this.readFileAsDataURL();
        }
    }

    readFileAsDataURL() {
        if (this.file) {
          if (this.file.lastModifiedDate) {
            const reader = new FileReader();
            reader.onload = function () {
              this.imageSrc = reader.result;
              this.fileName = this.file.name;
              this.fileChange.emit(this.file);
              this.imageSrcChange.emit(this.imageSrc);
              this.fileNameChange.emit(this.fileName);
            }.bind(this);
            reader.readAsDataURL(this.file);
          } else {
            //this.imageSrcChange.emit(this.imageSrc);
          }
        } else {
          this.imageSrc = '';
        }

    }

    removeImage() {
      this.imageSrc = "";
      this.file = null;
      this.fileName = "";
      this.imageSrc = "";
      this.fileName = "";
      this.fileChange.emit(null);
      this.imageSrcChange.emit(null);
      this.fileNameChange.emit(null);
    }

    isPdfFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('pdf' === key) {
          return true;
        }
      }

      return false;
    }

    isImageFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('png' === key || 'jpeg' === key || 'jpg' === key) {
          return true;
        }
      }

      return false;
    }

    isTxtFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('txt' === key || 'xml' === key) {
          return true;
        }
      }

      return false;

    }

    isDocFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('doc' === key || 'docx' === key || 'docm' === key) {
          return true;
        }
      }

      return false;

    }

    isExcelFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('xlsx' === key || 'xls' === key || 'xlsm' === key) {
          return true;
        }
      }

      return false;
    }

    isPowerpointFile() {
      if (this.fileName) {
        this.fileName = this.fileName.toLowerCase();
        const index = _.lastIndexOf(this.fileName, '.');
        const key = this.fileName.substr(index + 1);
        if ('ppt' === key || 'pptx' === key) {
          return true;
        }
      }
      return false;
    }

    openFile(event: Event, url: string) {
        event.preventDefault();
        if(url) window.open(url, '_blank');
    }

    disableEdit() {
        if (!this.createdAt) return false;
        let after24Hours = moment().subtract(24, 'hours').toDate();
        let createdTime = moment(this.createdAt).toDate();
        if (createdTime.getTime() < after24Hours.getTime()) {
            return true;
        }
        return false;
    }

    showViewImage = false;
    previewfileUrl;
    onViewImage(fileUrl) {
        this.previewfileUrl = fileUrl;
        this.showViewImage = true;
    }

    angle = 90;
    rotation = 0;
    rotateImage() {
        const rotated = document.getElementById('rotated');
        this.rotation = (this.rotation + this.angle) % 360;
        rotated.style.transform = `rotate(${this.rotation}deg)`;
    }

    zoomin() {
        var myImg = document.getElementById("rotated");
        var currWidth = myImg.clientWidth;
        if (currWidth >= 2500) return false;
        else {
            myImg.style.width = (currWidth + 100) + "px";
        }
    }

    zoomout() {
        var myImg = document.getElementById("rotated");
        var currWidth = myImg.clientWidth;
        if (currWidth <= 100) return false;
        else {
            myImg.style.width = (currWidth - 100) + "px";
        }
    }

    closeViewFileDialog() {
        this.showViewImage = false;
    }
}
