<p-table #table [value]="historiesData" [totalRecords]="totalRecords" [rows]="10"  [paginator]="true"  [lazy]="false" [responsive]="true">
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th *ngFor="let col of displayColumns" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''">
				<span class="d-flex align-items-center justify-content-between">
					<span class="one-liner" pTooltip="{{col?.label | translate}}">{{col?.label | translate}}</span>
					<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
				</span>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage" let-columns>
		<tr>
			<td [attr.colspan]="5" class="text-center py-5">
				<span class="text-muted my-5">{{'no data' | translate}}</span>
			</td>
		</tr>
	</ng-template>
	<ng-template  pTemplate="body" let-rowData let-rowIndex="rowIndex">
		<tr [pSelectableRow]="rowData">
			<td *ngFor="let col of displayColumns">
				<ng-container [ngSwitch]="col.field">
					<span *ngSwitchCase="'createdAt'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Uploaded' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</span>
					<span *ngSwitchCase="'createdByUsr'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Username' | translate}}: </span>
							<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
							<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</span>
					<span *ngSwitchCase="'fileName'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Filename' | translate}}: </span>
							<span *ngIf="rowData.fileName" class="text-primary" (click)="onViewDocument(rowData.fileUrl, rowData.fileId)" pTooltip="{{rowData.fileName}}" tooltipPosition="left" style="cursor: pointer !important">{{rowData.fileName}}</span>
							<span *ngIf="!rowData.fileName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</span>
					<span *ngSwitchCase="'action'">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Action' | translate}}: </span>
							<span *ngIf="rowData.action" pTooltip="{{rowData.action}}" tooltipPosition="left">{{rowData.action}}</span>
							<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</span>
				</ng-container>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage" let-columns>
		<tr *ngIf="!loading">
			<td [attr.colspan]="4" class="text-center py-5">
				<div class="text-muted my-5">{{'no data' | translate}}</div>
			</td>
		</tr>
		<tr *ngIf="loading">
			<td [attr.colspan]="4" class="text-center py-5">
				<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
			</td>
		</tr>
	</ng-template>
</p-table>
<p-dialog [(visible)]="showViewFileDialog" [style]="{'width': '90%', 'max-width':'800px', 'max-height':'85vh'}">
    <img class="w-100" [src]="fileUrl" alt="">
</p-dialog>
