<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'Invoice Summary' | translate}}</h1>
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Invoice Summaries' | translate}}</span>
        </p-header>
        <div class="ui-g">
            <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
                <label>{{'companyLabel' | translate}}</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-building"></i>
                        </button>
                    </div>
                    <p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Client/Base' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-briefcase"></i>
                        </button>
                    </div>
                    <p-dropdown [ngModelOptions]="{'standalone':true}" [options]="allClientList" [filter]="true" [(ngModel)]="selectedClient" [showClear]="true" [disabled]="isClientRole" placeholder="{{'pleaseSelect' | translate}}">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice Type' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-folder-tree"></i>
                        </button>
                    </div>
                    <p-dropdown [options]="invoiceTypes" [(ngModel)]="invoiceType" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Date Range' | translate}}</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white"  type="button" style="cursor:default !important">
                            <i class="fa-solid fa-calendar-week"></i>
                        </button>
                    </div>
                    <mat-form-field class="w-100">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
                            <input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
                        </mat-date-range-input>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Generated By' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-folder-tree"></i>
                        </button>
                    </div>
                    <p-dropdown [options]="employees" [(ngModel)]="employeeUsername" placeholder="{{'pleaseSelect' | translate}}" [autoDisplayFirst]="false" [filter]="true" [showClear]="true">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-12 text-center py-4">
                <button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
                <button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
            </div>
        </div>
    </p-panel>
    <div class="d-flex align-items-center justify-content-start py-4">
        <h1 class="m-0 ml-2">{{'Browse Summary' | translate}}</h1>
    </div>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Summary as of' | translate}} {{getCurrentDateString()}} <span *ngIf="totalRecords">({{totalRecords}})</span></span>
        </p-header>
        <p-table #dt [value]="invoiceSummaries" selectionMode="single" [rows]="10" [responsive]="true" [rowsPerPageOptions]="[10,25,50]"
                 [paginator]="true" [totalRecords]="totalRecords" [loading]="loading" [lazyLoadOnInit]="false"
                 [globalFilterFields]="['date', 'totalInvoices', 'invoiceType', 'totalClient', 'clientWithAutoPay', 'totalServiceFee', 'invoicePaid', 'balance']">

            <ng-template pTemplate="caption">
                <div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
                    <div class="d-flex"></div>
                    <div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
                        <div class="input-group-prepend p-0">
                            <button class="btn btn-white" type="button">
                                <i class="fa-solid fa-filter"></i>
                            </button>
                        </div>
                        <input type="text" [(ngModel)]="filterInvoice" pInputText placeholder="{{'Filter' | translate}}" (input)="filterInvoiceTable($event?.target?.value)">
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of cols">
                        <ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
                        </ng-container>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr *ngIf="!loading">
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.date" pTooltip="{{ rowData.date }}">{{ rowData.date }}</span>
						<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total Invoices' | translate}}: </span>
						<span *ngIf="rowData.totalInvoices" pTooltip="{{rowData.totalInvoices}}" (click)="openDetails(rowData)" class="text-primary">{{rowData.totalInvoices}}</span>
						<span *ngIf="!rowData.totalInvoices" class="text-muted">0</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice Type' | translate}}: </span>
						<span *ngIf="rowData.invoiceType" pTooltip="{{rowData.invoiceType}}" class="text-primary">{{rowData.invoiceType}}</span>
						<span *ngIf="!rowData.invoiceType" class="text-muted">0</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total Client' | translate}}: </span>
						<span *ngIf="rowData.totalClient" pTooltip="{{rowData.totalClient}}" (click)="openDetails(rowData)" class="text-primary">{{rowData.totalClient}}</span>
						<span *ngIf="!rowData.totalClient" class="text-muted">0</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client with Auto Pay' | translate}}: </span>
						<span *ngIf="rowData.clientWithAutoPay" pTooltip="{{rowData.clientWithAutoPay}}" class="text-primary">{{rowData.clientWithAutoPay}}</span>
						<span *ngIf="!rowData.clientWithAutoPay" class="text-muted">0</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Total Service Fee' | translate}}: </span>
						<span *ngIf="rowData.totalServiceFee" (click)="openDetails(rowData)" pTooltip="{{rowData.totalServiceFee | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.totalServiceFee | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.totalServiceFee" class="text-muted">$0.00</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice Paid' | translate}}: </span>
						<span *ngIf="rowData.invoicePaid" (click)="openDetails(rowData)" pTooltip="{{rowData.invoicePaid | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.invoicePaid | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.invoicePaid" class="text-muted">$0.00</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Balance' | translate}}: </span>
						<span *ngIf="rowData.balance" (click)="openDetails(rowData)" pTooltip="{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.balance" class="text-muted">$0.00</span>
					</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr *ngIf="!loading">
                    <td [attr.colspan]="5">
						<span class="one-liner">
							<span class="font-weight-bold">{{'Total' | translate}}: </span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="font-weight-bold">{{totalServiceFees | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="font-weight-bold">{{totalInvoicePaid | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="font-weight-bold">{{totalBalance | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="loading">
                    <td [attr.colspan]="cols.length" class="text-center py-5">
                        <div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
                    </td>
                </tr>
                <tr *ngIf="!loading">
                    <td [attr.colspan]="cols.length" class="text-center py-5">
                        <div class="text-muted my-5">{{'no data' | translate}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
    <p-overlayPanel #op1 appendTo="body">
        <p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
            <ng-template let-col pTemplate="item">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>
    <p-overlayPanel #op2 appendTo="body">
        <p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
            <ng-template let-col pTemplate="item">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>
    <p-overlayPanel #op3 appendTo="body">
        <p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
            <ng-template let-col pTemplate="item">
                <div>
                    <span>{{col.name | translate}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-overlayPanel>
</div>
<p-dialog [(visible)]="showDetails" [responsive]="true" [modal]="true" [dismissableMask]="true" [closeOnEscape]="false" [style]="{'width':'100%', 'max-width':'900px'}">
    <p-header>
        <span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Summary Details'}}</span>
    </p-header>
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Details as of Invoice Summary' | translate}}&nbsp;<span *ngIf="invoiceSummaryDetails">({{invoiceSummaryDetails.length}})</span></span>
        </p-header>
        <div class="row col-sm-12">
            <div class="ui-g-12 col-sm-12">
                <div class="ui-g-4 col-sm-4">
                    <span class="text-primary" style="font-weight: 500;">{{'Total Service Fee' | translate}}:&nbsp;<span>{{summaryDetails.totalServiceFee | currency : 'USD':'symbol':'1.2-2'}}</span></span>
                </div>
                <div class="ui-g-4 col-sm-4" style="text-align: center;">
                    <span class="text-success" style="font-weight: 500;">{{'Invoice Paid' | translate}}:&nbsp;<span>{{summaryDetails.invoicePaid | currency : 'USD':'symbol':'1.2-2'}}</span></span>
                </div>
                <div class="ui-g-4 col-sm-4">
                    <span class="text-danger" style="font-weight: 500; text-align: right; position: absolute; right: -25px;">
                        {{'Balance' | translate}}:&nbsp;<span>{{summaryDetails.balance | currency : 'USD':'symbol':'1.2-2'}}</span></span>
                </div>
            </div>
        </div>
        <p-table #dt1 [value]="invoiceSummaryDetails" selectionMode="single" [rows]="10" [responsive]="true" [rowsPerPageOptions]="[10,25,50]"
                 [paginator]="true" [totalRecords]="totalRecords" [loading]="loading" [globalFilterFields]="['invoiceNumber', 'clientName', 'serviceFee', 'balance']">
            <ng-template pTemplate="caption">
                <div class="d-flex align-items-center justify-content-between">
				<span class="d-flex">
					<button type="button" class="btn btn-primary mr-2" (click)="exportSummaryDetail()">
						<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
					</button>
				</span>
                    <div class="input-group flex-grow-1 mr-2" style="max-width: 450px">
                        <div class="input-group-prepend p-0">
                            <button class="btn btn-white" type="button" style="cursor:default !important">
                                <i class="fa-solid fa-filter"></i>
                            </button>
                        </div>
                        <input pInputText size="30" [(ngModel)]="filterInvoiceDetails" (input)="filterDetailsTable($event?.target?.value)" placeholder="{{'Filter' | translate}}">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of detailCols">
                        <ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
                                <span *ngIf="col.sort">
										<span (click)="col.sort == 'number' ? op6.toggle($event) : op5.toggle($event); selectedField = col.field; setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
							</div>
						</span>
                        </ng-container>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr *ngIf="invoiceSummaryDetails && invoiceSummaryDetails.length > 0">
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'#' | translate}}: </span>
						<span *ngIf="rowData.stt" pTooltip="{{ rowData.stt }}">{{ rowData.stt }}</span>
						<span *ngIf="!rowData.stt" class="text-muted">{{'no data' | translate}}</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice Number' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" (click)="clickInvoiceRef($event, rowData)" pTooltip="{{rowData.invoiceNumber}}" class="text-primary">{{rowData.invoiceNumber}}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">0</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}" class="text-primary">{{rowData.clientName}}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Service Fee' | translate}}: </span>
						<span *ngIf="rowData.serviceFee" pTooltip="{{rowData.serviceFee | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.serviceFee | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.serviceFee" class="text-muted">$0.00</span>
					</span>
                    </td>
                    <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Balance' | translate}}: </span>
						<span *ngIf="rowData.balance" pTooltip="{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}" class="text-primary">{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.balance" class="text-muted">$0.00</span>
					</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!invoiceSummaryDetails || invoiceSummaryDetails.length <= 0">
                    <td [attr.colspan]="cols.length" class="text-center py-5">
                        <div class="text-muted my-5">{{'no data' | translate}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
    <p-footer>
        <button class="btn btn-danger" (click)="showDetails = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
    </p-footer>
</p-dialog>
<p-overlayPanel #op5 appendTo="body">
    <p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
                <span> {{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>
<p-overlayPanel #op6 appendTo="body">
    <p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
                <span> {{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>
