import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {LangChangeEvent, TranslatePipe, TranslateService} from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslateCustomerService {

    constructor(private translatePipe: TranslatePipe, protected _http: HttpClient) {
    }


    getValue(key: any, args: any[]) {
        const options = [];
        if (args && args.length > 0) {
            args.forEach(arg => {
                options.push(arg.key, this.translatePipe.transform(arg.value));
            });
        }
        return this.translatePipe.transform(key, options);
    }

    get(key: any) {
        return this.translatePipe.transform(key);
    }
}
