<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="spinner-overlay" *ngIf="loading">
  <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<div *ngIf="estTaskNotExisted" class="text-center text-red">The estimation not available yet</div>
<p-panel>
  <p-header>
    <div class="d-flex align-items-center">
      <span style="margin-right: 10px">Assign Tickets</span>
    </div>
  </p-header>
  <br/>
  <br/>
  <p-panel>
    <p-header>
      <span>{{panelTaskTitle}}</span>
      <span class="pull-right ci-history" *ngIf="modeType !== 'create' && freelancerTicketId" (click)="getHistory()">History</span>
    </p-header>
    <div>
      <form [formGroup]="freelancerForm" #freelancerDataForm [name]="freelancerDataForm">
        <div class="ui-g">

          <div class="ui-g-2">
            <label class="pull-right">Ticket Link: <span class="required-indicator">*</span></label>
          </div>
          <div class="ui-g-10">
            <input type="text" formControlName="ticketLink" [disabled]="true" readonly
                   [(ngModel)]="unSavedTicket.ticketLink" pInputText
                   class="w-100">
            <div class="ui-g-12 custom-error-message pb-0">
                <span class="ui-message ui-messages-error"
                      *ngIf="!freelancerForm.controls['ticketLink'].valid && freelancerForm.controls['ticketLink'].dirty">
                    {{'Ticket Link is required' | translate}}</span>
            </div>
          </div>

          <div class="ui-g-2">
            <label class="pull-right">Estimated Hours: <span class="required-indicator">*</span></label>
          </div>
          <div class="ui-g-10">
            <p-inputMask formControlName="estimationHrs" mask="99:99"
                         placeholder="HH:MM"
                         [disabled]="editButton"
                         [(ngModel)]="unSavedTicket.estimationHrs"></p-inputMask>
            <div class="ui-g-12 custom-error-message pb-0">
                <span class="ui-message ui-messages-error"
                      *ngIf="!freelancerForm.controls['estimationHrs'].valid && freelancerForm.controls['estimationHrs'].dirty">
                    {{'Estimated Hours is required' | translate}}</span>
            </div>
          </div>

          <div class="ui-g-2">
            <label class="pull-right">Details :</label>
          </div>
          <div class="ui-g-10">
              <textarea pInputTextarea formControlName="details" [disabled]="editButton" [readonly]="editButton"
                        [(ngModel)]="unSavedTicket.details" [rows]="2"
                        class="acceptance-inputs" class="w-100" autoResize="true">
            </textarea>
          </div>

          <div class="ui-g-2">
            <label class="pull-right">Start Date: <span class="required-indicator">*</span></label>
          </div>
          <div class="ui-g-10">
            <mat-form-field class="w-100" style="padding-bottom: 0">
              <input matInput (focus)="picker.open()" [matDatepicker]="picker" [disabled]="editButton" [readonly]="editButton"
                     formControlName="startDate"
                     utc="true"
                     [(ngModel)]="unSavedTicket.startDate">
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div class="ui-g-12 custom-error-message pb-0">
                <span class="ui-message ui-messages-error"
                      *ngIf="!freelancerForm.controls['startDate'].valid && freelancerForm.controls['startDate'].dirty">
                    {{'Start Date is required' | translate}}</span>
            </div>
          </div>

          <div class="ui-g-2">
            <label class="pull-right">Deadline: <span class="required-indicator">*</span></label>
          </div>
          <div class="ui-g-10">
            <mat-form-field class="w-100 date-input ">
              <input matInput (focus)="picker1.open()" [matDatepicker]="picker1" [disabled]="editButton" [readonly]="editButton"
                     [min]="unSavedTicket.startDate"
                     formControlName="deadline"
                     class="date-input1"
                     utc="true"
                     [(ngModel)]="unSavedTicket.deadline">
              <mat-datepicker-toggle matSuffix [for]="picker1">
              </mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <div class="ui-g-12 custom-error-message pb-0">
                <span class="ui-message ui-messages-error"
                      *ngIf="!freelancerForm.controls['deadline'].valid && freelancerForm.controls['deadline'].dirty">
                    {{'Deadline is required' | translate}}</span>
            </div>
          </div>

          <div class="ui-g-2">
            <label class="pull-right">Status: <span class="required-indicator">*</span></label>
          </div>
          <div class="ui-g-10">
            <p-dropdown [options]="status" [disabled]="true" placeholder="Please Select"
                        formControlName="status"
                        [style]="{'width':'100%'}" [(ngModel)]="unSavedTicket.status" id="id_status"></p-dropdown>
            <div class="ui-g-12 custom-error-message pb-0">
                <span class="ui-message ui-messages-error"
                      *ngIf="!freelancerForm.controls['status'].valid && freelancerForm.controls['status'].dirty">
                    {{'status is required' | translate}}</span>
            </div>
          </div>
          <div class="ui-g-12 pb-0" style="min-height: 150px;">
            <div class="ui-g-2 text-right">
              <label>{{'Attachment' | translate}}:</label>
            </div>
            <div class="ui-g-1 text-right" [ngClass]="[editButton ? 'disabledDiv': '']">
              <a *ngIf="!editButton" (click)=" uploadFilePanel.toggle($event)">
                <span dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
                      [class.hovering]="isHovering">
                    <i class="material-icons" style="cursor: pointer;"
                       [ngClass]="{'text-green': unSavedTicket?.documentId && unSavedTicket?.documentId.length}">
                        publish</i></span>
              </a>
            </div>
            <div class="ui-g-9 pb-0" >
              <div [ngClass]="[editButton ? 'disabledDiv': '']" class="drag-drop-file-here" appDragToUploadFile (fileDropped)="onDrop($event)">
                <p class="title-click-here">{{'Drag & Drop' | translate}}</p>
                <p class="or">{{'Or' | translate}}</p>
                <div class="upload-btn-wrapper">
                  <div class="m-generalBtn m-generalBtn--sub m-generalBtn--interaction" (click)="upload.click()">
                    <a href="javascript:" class="m-generalBtn__box m-generalBtn__box--sub">
                      {{'Click to upload' | translate}}
                    </a>
                    <input hidden type="file" accept="/*" (change)="uploadFile($event)" #upload
                           multiple>
                  </div>
                </div>
                <p-overlayPanel #uploadFilePanel appendTo="body">
                  <ng-template pTemplate>
                    <ul class="m-0 px-1" style="list-style: none; min-width: 70px;">
                      <li style="cursor: pointer;">
                        <a class="p-1" (click)="onUploadDocument(uploadFilePanel)">{{'Upload' | translate}} </a>
                      </li>
                    </ul>
                  </ng-template>
                </p-overlayPanel>
              </div>
              <div class="upload-process" *ngIf="processing">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
                  <style>
                    .line {
                      fill: none;
                      stroke: #aaa;
                      stroke-width: 8;
                      stroke-dasharray: 40
                    }

                    .animated {
                      animation: draw .8s ease-in-out;
                      animation-iteration-count: infinite;
                      stroke-dashoffset: 0
                    }

                    .line2 {
                      animation-delay: .08s
                    }

                    .line3 {
                      animation-delay: .16s
                    }

                    @keyframes draw {
                      0% {
                        stroke-dashoffset: 0
                      }

                      50% {
                        stroke-dashoffset: 30
                      }

                      100% {
                        stroke-dashoffset: 0
                      }
                    }
                  </style>
                  <path class="line animated" d="M14 10V50z" />
                  <path class="line animated line2" d="M30 10V50z" />
                  <path class="line animated line3" d="M46 10V50z" />
                </svg>
              </div>
              <div class="files-list" *ngIf="showFileUpload == true">
                <div class="wrapper" *ngFor="let file of fileUploaded;let i=index">
                  <div class="item item-file-name d-flex align-self-center" style="cursor: pointer" (click)="showDocument(file)">{{file?.fileName}}</div>
                  <div class="item item-btn" (click)="removeFile(file.id)"
                  [ngClass]="[editButton ? 'disabledDiv': '']"><i class="fa-solid fa-xmark text-danger" ></i></div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-12">
            <p-accordion>
              <p-accordionTab header="Comment">
                <div class="p-2">
                  <label>Comment:</label>
                  <textarea pInputTextarea style="width:100%" [rows]="2"
                  class="w-100" autoResize="true"[ngModelOptions]="{'standalone':true}" 
                  [(ngModel)]="ticketComment.content"  [disabled]="!freelancerTicketId">
                  </textarea>
                  <div class="text-right">
                    <button pButton label="Save" (click)="addComment()" [disabled]="!freelancerTicketId"></button>
                  </div>
                  <div *ngIf="comments.length > 0">
                    <div class="row" *ngFor="let comment of comments">
                      <div class="col-md-1">
                        <span class="employee-name">{{displayName(comment?.createdByUsr)}}</span>
                      </div>
                      <div class="col-md-11">
                      <p><b>{{comment?.createdByUsr}}</b> &nbsp; {{comment?.createdAt | date : 'short'}} </p>
                      <p> {{comment?.content}} </p>
                      <p class="pl-4" *ngIf="comment.userId === loggedInId && comment.createdByUsr === loggedInUser"> 
                        <u (click)="editComment(comment)">Edit</u> 
                        <u style="margin-left:20px" (click)="deleteComment(comment)">Delete</u>
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </p-accordionTab>
          </p-accordion>
          </div>

        </div>

        <div class="ui-g" style="margin-top: 10px;">
          <div class="ui-g-12 text-center" style="margin: auto;">
            <button *ngIf="modeType == 'view'"
                    [disabled]="isCheckFreelancer"
                    (click)="changeStatus()" pButton type="button" label="Edit"
                    style="margin-right:10px">
            </button>
            <button *ngIf="modeType == 'create'" type="button" class="btn btn-success"
                    style="margin-right:10px;" [disabled]="isDisabled" (click)="saveFreelancerTicket(freelancerDataForm)">Save & Submit
            </button>
            <button *ngIf="modeType == 'edit'" type="button" class="btn btn-success" label="Submit"
                    style="margin-right:10px" [disabled]="isDisabled" (click)="updateFreelancerTicket(freelancerDataForm)">Save & Submit
            </button>
            <button *ngIf="modeType == 'create'" pButton type="button" label="Save"
                    style="margin-right:10px;margin-bottom:10px"
                    (click)="saveFreelancerTicket(freelancerDataForm, 'Save')" [disabled]="isDisabled" ></button>
            <button pButton type="button" label="Cancel" style="margin-bottom:10px" (click)="goBack()" [disabled]="isDisabled" ></button>
          </div>
        </div>
      </form>
    </div>

  </p-panel>
</p-panel>
<p-dialog *ngIf="historyShow" [showHeader]="true" [modal]="true" [(visible)]="historyShow" [dismissableMask]="true"
          showEffect="fade" width="1000" header="Estimation Task History">
  <app-freelancer-ticket-history-task [freelancerTicketId]="freelancerTicketId"></app-freelancer-ticket-history-task>
</p-dialog>
