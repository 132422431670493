import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { from, Observable } from 'rxjs';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-job-follow-up-add',
  templateUrl: './job-follow-up-add.component.html',
  styleUrls: ['./job-follow-up-add.component.scss']
})
export class JobFollowUpAddComponent implements OnInit {
  mode:string;

  constructor( public confirmationService: ConfirmationService,
               private translatePipe: TranslatePipe) { }

  ngOnInit(): void {
  }

  confirmNavigate(): boolean | Observable<any> {
    const isChangeForm = JSON.parse(localStorage.getItem('_jobFollowUp'));
    if (isChangeForm) {
      return from(new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          message: this.translatePipe.transform('Are you sure you want to leave without save'),
          header: this.translatePipe.transform('Leave Confirmation'),
          icon: 'pi pi-info-circle',
          accept: () => {
              resolve(true);
            },
            reject: () => {
              resolve(false);
            }
        });
      }));
    } else {
      return true;
    }
  }

}
