import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';
import { Contact } from '../model/contact';
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { Response } from '../../shared/model/response';
import { map } from 'rxjs/operators';
import { GroupSearch } from '../../shared/model/search/group.search';
import { ListResponse } from '../../shared/model/list.response';
import { Page } from '../../shared/model/page.model';
import { ContactHistory } from '../model/contact-history';

@Injectable({ providedIn: 'root' })
export class ContactService extends AbstractService<Contact> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CRM.CONTACT, '');
    }
    saveHistoryData(data) {
        return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
    }

    searchHistory(options?: any): Observable<Page<ContactHistory>> {
        const params = this.createParams(options);
        return this._http.post<Page<ContactHistory>>(`${this.baseUrlV2}history/search`, options).pipe(map(res => res));
    }

    saveMultipleContacts(data: Contact[]): Observable<Response<Contact>> {
        return this._http.post<Response<Contact>>(`${this.baseUrlV2}saveMultipleRows`, data).pipe(map(resp => resp));
    }

    getDropdownCreatedBy(searchParams?: any): Observable<ListResponse<any>> {
        const params = this.createParams(searchParams);
        return this._http.get<ListResponse<any>>(this.baseUrlV2 + 'dropdownCreatedBy', { params: params }).pipe(map(resp => resp));
    }

    exportPdf(options?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'pdf', options).pipe(map(res => res));
    }
}