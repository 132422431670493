import { Injectable, OnDestroy } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable, Subject } from 'rxjs';
import { ProtestHour } from '../model/protest-hour.model';
import { Response, PageRequest, PageResponse } from '../../shared/model/response';
import { Message } from 'primeng/api';
import * as _ from 'lodash';
import { Page } from '../../shared/model/page.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProtestHourService extends AbstractService<ProtestHour> implements OnDestroy {
  refreshListSubject = new Subject<ProtestHour>();
  editProtestHourSubject = new Subject<ProtestHour>();
  messageSubject = new Subject<Message>();
  // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PROTEST_HOUR, '');
  }

  getByEmployeeId(employeeId: number, pageRequest?: PageRequest): Observable<PageResponse<ProtestHour>> {
    const params = {};
    if (pageRequest) {
      if (pageRequest.size !== undefined) {
        params['size'] = pageRequest.size;
      }
      if (pageRequest.page !== undefined) {
        params['page'] = pageRequest.page;
      }
      if (pageRequest.sort !== undefined && pageRequest.sort.orders) {
        const sortRequest = pageRequest.sort.orders
          .map(o => `${o.property},${o.direction}`);
        params['sort'] = sortRequest;
      }
      console.log('params', params);
    }
    return this._http.get<PageResponse<ProtestHour>>(`${this.baseUrl}employee/${employeeId}`, { params: params });
  }

  filterByCompanyAndAgency(params: any): Observable<Page<ProtestHour>> {
    return this._http.get<Page<ProtestHour>>(this.baseUrl, { params: params }).pipe(map(resp => resp));
  }

  ngOnDestroy() {
    this.refreshListSubject.next();
    this.refreshListSubject.complete();

    this.editProtestHourSubject.next();
    this.editProtestHourSubject.complete();

    this.messageSubject.next();
    this.messageSubject.complete();
  }

  setStatus(id, status): Observable<any> {
    const entity = { id: id, status: status };
    return this._http.put<any>(`${this.baseUrlV2}set-status`, entity).pipe(map(resp => resp));
  }

}
