import { AbstractService } from '../../shared/service/abstract.service';
import { InvoiceTemplate } from '../model/invoicetemplate.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoiceTemplateService extends AbstractService<InvoiceTemplate>{

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.INVOICE_TEMPLATE, '');
    }

    getTemplateList(options?:any){
        return this.http.post(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }
    
    getUpdatedByList():Observable<any> {
        return this.http.get<any>(`${this.baseUrlV2}last-updated-by-username`).pipe(map(res=> res));
    }

}