import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'app/crm/service/client.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import moment from 'moment';
import { LazyLoadEvent, MessageService, Table } from 'primeng';
import { BillingUploadHistoryService } from '../../service/billing-upload-history.service'
import * as XLSX from 'xlsx';
import { FileUtility } from 'app/shared/utility/file.utility';
import { DatePipe, formatNumber } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-billing-upload-history',
  templateUrl: './billing-upload-history.component.html',
  styleUrls: ['./billing-upload-history.component.scss'],
  providers: [BillingUploadHistoryService]
})
export class BillingUploadHistoryComponent implements OnInit {
  loading: boolean = true;
  isPlatformAdmin: any;
  billingReports: any[];
  fromDate: Date;
  companySelected: any;
  toDate: Date;
  createdByList: any[];
  createdSelected: any;
  clients: any[];
  clientList: any[];
  reportTypeSelected: any;
  companies: any[];
  isClientAccount: boolean = false;
  cols = [
    { field: '#', label: '#' },
    { field: 'createdAt', label: 'Uploaded', sortOptions: '', sort: 'text' },
    { field: 'createdByUsr', label: 'By', sortOptions: '', sort: 'text' },
    { field: 'clientBase', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'startDate', label: 'Start', sortOptions: '', sort: 'text' },
    { field: 'endDate', label: 'End', sortOptions: '', sort: 'text' },
    { field: 'firstUpdateOn', label: 'Created', sortOptions: '', sort: 'text' },
    { field: 'totalRecords', label: 'Updated', sortOptions: '', sort: 'text' },
    { field: 'newRecords', label: 'New', sortOptions: '', sort: 'number' },
    { field: 'oldRecords', label: 'Old', sortOptions: '', sort: 'number' },
	{ field: 'status', label: 'Status', sortOptions: '', sort: 'text' },
  ];

  textSortOptionsStock = [
    { name: 'Sort A to Z', value: 'ASC', img: 'a-z' },
    { name: 'Sort Z to A', value: 'DESC', img: 'z-a' }
  ];
  numberSortOptionsStock = [
    { name: 'Sort 0 to 9', value: 'ASC', img: 'a-z' },
    { name: 'Sort 9 to 0', value: 'DESC', img: 'z-a' }
  ];

  reportTypes = [
    { label: 'Billing Report', value: 'BILLING_REPORT' },
    { label: 'Total Job', value: 'TOTAL_JOB_REPORT' },
    { label: 'Details Report', value: 'DETAIL_REPORT' },
    { label: 'All', value: 'ALL' }
  ];

  @ViewChild('dt', { static: true }) table: Table;
  selectedSortOption = 'ASC';
  selectedField = 'updateAt';
  clientSelected: any;
  sortField: string;
  size: any;
  page: any;
  searchText;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();

  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private datePipe: DatePipe,
    private messageService: MessageService, private clientService: ClientService,
    private billingUploadHistoryService: BillingUploadHistoryService, private router: Router,
    private monitoringDetailsService: MonitoringDetailsService) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-upload-history') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Upload History`,
            this.timeSpent,
            {
              navigated_to_upload_history_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Upload History`,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.loading = true;
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.getClientList();
    if (this.authService.isClientRole()) {
      this.clientSelected = this.authService.getUserInfo().adminId;
      this.isClientAccount = true;
    } else {
      this.isClientAccount = false;
    }
    // this.search();
    this.getCreatedByList();
    this.loadBillingReports();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
  }

  loadBillingReports(event?: LazyLoadEvent) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 9999);
    this.page = event && typeof event.first !== "undefined" && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options: any = {};
    options = {
      size: this.size,
      page: this.page,
      sortOrder: 'DESC'
    }

    if (this.reportTypeSelected) {
      options.reportType = this.reportTypeSelected;
    }
    if (this.clientSelected) {
      options.clientId = this.clientSelected;
    }

    if (this.createdSelected) {
      options.createdBy = this.createdSelected;
    }

    this.loadPage(options);
  }

  search() {
    this.loadBillingReports();
  }

  loadPage(options: any) {
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    if (this.createdSelected) options.createdBy = this.createdSelected;
    if (this.clientSelected) options.clientId = this.clientSelected;
    this.billingUploadHistoryService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.billingReports.forEach(item => {
          item.totalRecords = item.newRecords + item.oldRecords;
          item.clientBase = this.getClientName(item);
        });
        this.sortingClick(this.selectedSortOption);
      }
      this.loading = false;
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.billingReports = [];
    this.clientSelected = null;
    this.createdSelected = null;
    this.searchText = null;
    this.table.reset();
    this.search();
  }

  onChangeClient(event) {
    this.getCreatedByList();
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    if (this.companySelected) { options.companyId = this.companySelected; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }

  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null
    };
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    options.companyId = options.companyId || 0;
    this.billingUploadHistoryService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    })
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.search();
  }

  sortingClick(selectedSortOption) {
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.billingReports
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      "complete",
      action,
      0
    );
  }

  getClientName(data){
    if(data.clientId){
      const client = this.clientList.find(el => el.value === data.clientId);
      if(client) return client.label; 
    }
    return data.clientName;
}
}
