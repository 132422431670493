export enum ValidatorError {
  USERNAME = 'username',
  EXISTED_USERNAME = 'existedUsername',
  EXISTED_EMAIL = 'existedEmail',
  EXISTED_PHONE = 'existedPhone',
  EXISTED_FLEET_NUMBER = 'existedFleetNumber',
  USERNAME_CHAR = 'usernameChar',
  EXISTED_EXTENSION = 'existedPhoneExtension',
  EXISTED_NAME = 'existedName'
}

export const VALIDATOR_ERROR_MESSAGE: { [key: string]: string } = {
  [ValidatorError.USERNAME]:
    'Minimum length 6 - Maximum length 30',
  [ValidatorError.USERNAME_CHAR]:  'Lowercase letters, numbers and underscore only',
  [ValidatorError.EXISTED_USERNAME]: 'Username already exists',
  [ValidatorError.EXISTED_EMAIL]: 'Email already exists',
  [ValidatorError.EXISTED_PHONE]: 'Phone already exists',
  [ValidatorError.EXISTED_FLEET_NUMBER]: 'Fleet number already exists',
  [ValidatorError.EXISTED_EXTENSION]: 'Extension already exists',
  [ValidatorError.EXISTED_NAME]: 'Name already exists',
};

export const FILE_MAX_SIZE = 1; // MB
