import * as _ from 'lodash';

export class TodoFormValuesConstant {
    public static concert = 'Concern';
    public static support = 'Support';
    public static toDo = 'ToDo';
    public static statusWaiting = 'Waiting';
    public static statusCompleted = 'Completed';

    public static controlScheduleTo = 'scheduleTo';
    public static controlStatus = 'status';
    public static dailyTasksStatus = 'Daily Tasks';

    public static todoRecipientScheduleToLabel = 'Schedule To: ';
    public static todoRecipientFromLabel = 'From: ';

    public static getTodos() {
        return _.sortBy([
            { label: 'Call', value: 'Call' },
            { label: 'Meeting', value: 'Meeting' },
            { label: 'Send email', value: 'Send email' },
            { label: 'Other', value: 'Other' },
            { label: this.support, value: this.support },
            { label: this.concert, value: this.concert },
            { label : this.dailyTasksStatus, value : this.dailyTasksStatus},
            { label : 'Training', value : 'Training'}
        ], 'label');
    }

    public static getRegardings() {
        // return _.sortBy([
        //     { label: 'Enter custom regarding', value: 'Enter custom regarding' },
        //     { label: 'Cold Call', value: 'Cold Call' },
        //     { label: 'Follow Up Call', value: 'Follow Up Call' },
        //     { label: 'Left Message', value: 'Left Message' },
        //     { label: 'No Longer At Company', value: 'No Longer at Company' },
        //     { label: 'Not Appropriate', value: 'Not Appropriate' },
        //     { label: 'Not Interested', value: 'Not Interested' },
        //     { label: 'Present Proposal', value: 'Present Proposal' },
        //     { label: 'Review Proposal', value: 'Review Proposal' },
        //     { label: 'Wrong Number', value: 'Wrong Number' },
        //     { label: 'Edit List', value: 'Edit List' },
        //     { label : 'Training', value : 'Training'}
        // ], 'label');
        return _.sortBy([
            { label: 'Maintenance', value: 'Maintenance' },
            { label: 'Repairs', value: 'Repairs' },
            { label: 'Installation', value: 'Installation' },
            { label: 'Inspection', value: 'Inspection' },
            { label: 'Consultation', value: 'Consultation' },
            { label: 'Training', value: 'Training' },
            { label: 'Technical Support', value: 'Technical Support' },
            { label: 'Cleaning', value: 'Cleaning' },
            { label: 'Upgrades', value: 'Upgrades' },
            { label: 'Testing', value: 'Testing' },
            { label: 'Calibration', value: 'Calibration' },
            { label: 'Renovation', value: 'Renovation' },
            { label: 'Customization', value: 'Customization' },
            { label: 'Design', value: 'Design' },
            { label: 'Survey', value: 'Survey' },
            { label: 'Permitting', value: 'Permitting' },
            { label: 'Compliance', value: 'Compliance' },
            { label: 'Other', value: 'Other' },
            { label: 'Fault Diagnosis', value: 'Fault Diagnosis' },
            { label : 'Emergency Response', value : 'Emergency Response'}
        ], 'label');
    }

    public static getConcernRegardings() {
        return [
            { label: 'Job', value: 'Job' },
            { label: 'Tasks', value: 'Employee' },
            { label: 'Employee', value: 'Employee' },
            { label: 'Other', value: 'Other' },
        ];
    }

    public static getPriorities() {
        return [
            { label: 'Low', value: 'Low' },
            { label: 'Medium', value: 'Medium' },
            { label: 'High', value: 'High' }
        ];
    }

    public static getIntervals() {
        return [
            { label: 'On time', value: 'On time' },
            { label: '10 min before', value: '10 min before' },
            { label: '30 min before', value: '30 min before' },
            { label: '1 hour before', value: 'Hig1 hour beforeh' }
        ];
    }

    public static getSupportRegardings() {
        return [
            { label: 'Cannot Save', value: 'Cannot Save' },
            { label: 'Upload issue', value: 'Upload issue' },
            { label: 'Email not Working', value: 'Email not Working' },
            { label: 'Calendar not Update', value: 'Calendar not Update' },
            { label: 'Hardware', value: 'Hardware' },
            { label: 'Internet', value: 'Internet' },
            { label: 'Phone', value: 'Phone' },
            { label: 'Printer Software', value: 'Printer Software' }
        ];
    }

    public static getCompanyRegardings() {
        return [
            { label: 'Enter Custom Subject', value: 'Enter custom regarding' },
            { label: 'Cold Call', value: 'Cold Call' },
            { label: 'Follow Up Call', value: 'Follow Up Call' },
            { label: 'Left Message', value: 'Left Message' },
            { label: 'No Longer at Company', value: 'No Longer at Company' },
            { label: 'Not Appropriate', value: 'Not Appropriate' },
            { label: 'Not Interested', value: 'Not Interested' },
            { label: 'Present Proposal', value: 'Present Proposal' },
            { label: 'Review Proposal', value: 'Review Proposal' },
            { label: 'Wrong Number', value: 'Wrong Number' },
            { label: 'Edit List', value: 'Edit List' }
        ];
    }

    public static getTodoSupportStatuses() {
        return [
            { label: 'New Assignment', value: this.toDo },
            { label: 'InProgress', value: 'In Progress' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Voided', value: 'Voided' }
        ];
    }

    public static getTodoStatuses() {
        return [
            { label: 'Open', value: this.toDo , color:'#803cff'},
            { label: 'In Progress', value: 'In Progress', color:'#0081ff'}, 
            { label: 'On Hold', value: 'Temp Stop', color:'#e42e1f'}, 
            { label: this.statusCompleted, value: this.statusCompleted, color:'#70b60e'}, 
            { label: 'Pending Others', value: 'Waiting for Others', color:'#ef8f00'}
        ];
    }

    public static getSupportsRegarding() {
        return [
            { label: 'Hardware', value: 'Hardware' },
            { label: 'Software', value: 'Software' },
            { label: 'Printer', value: 'Printer' },
            { label: 'Others', value: 'Others' }
        ];
    }

    public static getDropdownData(label: string, value: string) {
        return { label, value};
    }
}
