export class MenuList {
    public menus = [
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [82, 83, 84, 94, 95, 96, 115, 100, 101, 102, 103, 104, 26, 1151, 2003, 2004, 2005, 1154, 1155, 1159, 1160, 1161, 1167, 1179, 1672, 1673, 1674, 1675, 1676, 1186, 4422, 2006],
            'name': 'Accounting',
            'label': 'Accounting',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 82,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transaction Type',
            'label': 'Transaction Type',
            'routerLink': '/app/transaction-type/add',
            'order': 82,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 83,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transaction List',
            'label': 'Transaction List',
            'routerLink': '/app/transaction/list',
            'order': 83,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 84,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transaction Add',
            'label': '',
            'routerLink': '/app/transaction/add',
            'order': 84,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 94,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Accounting',
            'label': 'Accounting',
            'routerLink': '',
            'order': 94,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 95,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transactions',
            'label': 'Transactions',
            'routerLink': '/app/transaction/list',
            'order': 95,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 96,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Received Payments',
            'label': 'Received Payments',
            'routerLink': '/app/payment-info/list',
            'order': 96,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 115,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tax Details',
            'label': 'Tax Details',
            'routerLink': '/app/tax-detail/list',
            'order': 115,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 100,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Projects',
            'label': 'Projects',
            'routerLink': '/app/project/add',
            'order': 100,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        }, {
            'id': 101,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Credit & Debit Accounts',
            'label': 'Credit & Debit Accounts',
            'routerLink': '/app/account/list',
            'order': 101,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 102,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contracts & Licenses',
            'label': 'Contracts & Licenses',
            'routerLink': '/app/generic-compliance/list',
            'order': 102,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 103,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Tracking',
            'label': 'Payment Tracking',
            'routerLink': '/app/payment-tracking/list',
            'order': 102,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 104,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Loans',
            'label': 'Loans',
            'routerLink': '/app/loan/list',
            'order': 104,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 26,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Follow-up Bills',
            'label': 'Follow-up Bills',
            'routerLink': '/app/employee-bill/list',
            'order': 26,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1151,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Insurance',
            'label': 'Insurance',
            'routerLink': '/app/insurance/list',
            'order': 1151,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2003,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'File Examination',
            'label': 'File Examination',
            'routerLink': '/app/file-examination/list',
            'order': 2003,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2004,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'General Invoices',
            'label': 'General Invoices',
            'routerLink': '/app/general-invoice/list',
            'order': 2004,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2005,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Tracking',
            'label': 'Invoice Tracking',
            'routerLink': '/app/invoice-tracking/list',
            'order': 2005,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1154,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'New Payroll',
            'label': 'New Payroll',
            'routerLink': '/app/contractors/new-payroll',
            'order': 1154,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1155,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll List',
            'label': 'Payroll List',
            'routerLink': '/app/contractors/payroll-list',
            'order': 1155,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1159,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SERVICE REVENUE',
            'label': 'SERVICE REVENUE',
            'routerLink': '/app/service-revenue/list',
            'order': 1159,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1160,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'AR Report',
            'label': 'AR Report',
            'routerLink': '/app/revenue-payment/list',
            'order': 1160,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1161,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Gross Income',
            'label': 'Gross Income',
            'routerLink': '/app/gross-income/list',
            'order': 1161,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1167,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Aging Details',
            'label': 'Aging Details',
            'routerLink': '/app/receivable-aging-details/list',
            'order': 1167,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1179,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Aging Summary',
            'label': 'Aging Summary',
            'routerLink': '/app/aging-summary/list',
            'order': 1179,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1672,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Statement & Balance',
            'label': 'Statement & Balance',
            'routerLink': '/app/statement-balance/list',
            'order': 1672,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1673,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Journal Report',
            'label': '',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1673,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 1674,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Journal Password',
            'label': 'label.admin_setting',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1674,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1675,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'PayStub PIN Not Required',
            'label': 'label.admin_setting',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1675,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1676,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Discount',
            'label': '',
            'routerLink': '',
            'order': 1676,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1186,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing Location',
            'label': 'Billing Location',
            'routerLink': '/billing-location/list',
            'order': 1186,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 4422,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS/MMS Billing',
            'label': 'SMS/MMS Billing',
            'routerLink': '/sms-mms-billing/list',
            'order': 4422,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 2006,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch Billing',
            'label': 'Punch Billing',
            'routerLink': '/app/billings/punch-billing/list',
            'order': 2006,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [2, 4, 98, 41, 49, 50, 1032, 1033, 1039, 108, 43, 1040, 2999, 44, 1189, 1190, 1191],
            'name': 'Admin',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 2,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Platform Admin',
            'label': 'label.platform_admin',
            'routerLink': '/app/admin/platform-admin',
            'order': 2,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 4,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Admin',
            'label': 'label.senior_center_admin',
            'routerLink': '/app/admin/agency-admin',
            'order': 4,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 98,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Admin Setting',
            'label': 'label.admin_setting',
            'routerLink': '/app/admin/agency/setting',
            'order': 98,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 41,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Groups',
            'label': 'label.groups',
            'routerLink': '/app/admin/groups',
            'order': 41,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 49,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Process Monitor',
            'label': 'label.process_monitor',
            'routerLink': '/app/admin/process-monitor',
            'order': 49,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 50,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Av Board',
            'label': 'label.employee_av_board',
            'routerLink': '/app/admin/employee-av-board',
            'order': 50,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1032,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monitoring',
            'label': 'Monitoring',
            'routerLink': '/app/admin/monitoring',
            'order': 1032,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1033,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Suspicious activities',
            'label': 'Suspicious activities',
            'routerLink': '/app/admin/monitoring/suspicious-activities',
            'order': 1033,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1039,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Subscription',
            'label': 'Email Subscription',
            'routerLink': '/app/admin/email-subscription/list',
            'order': 1039,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 108,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Menu & Inactivity',
            'label': '',
            'routerLink': '',
            'order': 108,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 43,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Activity',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/email-activity',
            'order': 43,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 43,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Export Monitor',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/export-monitor',
            'order': 1040,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 44,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS Validation',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/export-monitor',
            'order': 1040,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1189,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Plan Preview',
            'label': '',
            'routerLink': '/app/plan/preview',
            'order': 1189,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1190,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Plans',
            'label': '',
            'routerLink': '/app/plan/list',
            'order': 1190,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1191,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Maintenance',
            'label': '',
            'routerLink': '/app/maintenance/list',
            'order': 1191,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [21, 99, 1030, 1031, 22, 1690, 23, 25, 105, 106, 44, 107, 45, 47, 500, 72, 81, 73, 74, 55, 705, 707, 1100, 1158, 1171, 1028, 1035, 1180, 71, 1192,1138],
            'name': 'Employee',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false
        },
        {
            'id': 21,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Profile',
            'label': 'label.employee_profile',
            'routerLink': '/app/employee',
            'order': 21,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 99,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Employee',
            'label': 'label.employee_add',
            'routerLink': '/app/employee/add',
            'order': 99,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1192,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Document History',
            'label': 'Employee Document History',
            'routerLink': '',
            'order': 1192,
            'checked': false,
            'setting': false,
            'isActionsAllowed': false,
            'showMenu': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1030,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Intern',
            'label': 'Intern',
            'routerLink': '/app/intern/list',
            'order': 1030,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1031,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Volunteer',
            'label': 'Volunteer',
            'routerLink': '/app/volunteer/list',
            'order': 1031,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': 1111,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'My Contact List',
        //   'label': 'label.employee_add',
        //   'routerLink': '/app/employee/my-contact/list',
        //   'order': 51,
        //   'checked': false,
        //   'setting': true,
        //   'employeeMenu': false,
        //   'isActionsAllowed': true,
        //   'showMenu': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },
        {
            'id': 22,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch In/Out',
            'label': 'label.punch in/out',
            'routerLink': '/app/employee-clocking/punch',
            'order': 22,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1690,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punctuality',
            'label': 'Punctuality',
            'routerLink': '',
            'order': 1690,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 23,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch History',
            'label': 'label.punch_history',
            'routerLink': '/app/employee-clocking/punch-history',
            'order': 23,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 25,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Off Review',
            'label': 'label.time_off_review',
            'routerLink': '/app/absence/list',
            'order': 25,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 105,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Off Add',
            'label': 'label.time_off_add',
            'routerLink': '/app/absence/add',
            'order': 105,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 106,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Calendar',
            'label': 'label.employee_calendar',
            'routerLink': '/app/absence/calendar',
            'order': 106,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 44,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Performance',
            'label': 'label.performance',
            'routerLink': '/app/performance/list',
            'order': 44,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1081,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Suspension Records',
            'label': 'Suspension Records',
            'routerLink': '/app/performance/suspension-record/list',
            'order': 44,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 107,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Performance',
            'label': 'label.employee_performance',
            'routerLink': '/app/performance/employee-performance',
            'order': 107,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 45,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Correction',
            'label': 'label.protest_hour',
            'routerLink': '/app/employee/protest-hour',
            'order': 45,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 47,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Sheet',
            'label': 'label.time_sheet',
            'routerLink': '/app/employee/timesheet',
            'order': 47,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 500,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Paystub',
            'label': 'Paystub',
            'routerLink': '/app/employee/paystub',
            'order': 500,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 72,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Details | Add Employee',
            'label': '',
            'routerLink': '',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true
        },
        {
            'id': 81,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Hide Pay Rate | OT Rate',
            'label': '',
            'routerLink': '',
            'order': 81,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 73,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Summary View',
            'label': '',
            'routerLink': '',
            'order': 73,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true
        },
        {
            'id': 74,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee OT',
            'label': '',
            'routerLink': '',
            'order': 74,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true
        },

        {
            'id': 55,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OT Requested',
            'label': '',
            'routerLink': '',
            'order': 55,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': 705,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SSN View',
            'label': '',
            'routerLink': '',
            'order': 705,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        // {
        //   'id': 707,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Add Absence',
        //   'label': '',
        //   'routerLink': '',
        //   'order': 707,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },

        {
            'id': 707,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Absence',
            'label': 'label.add_absence',
            'routerLink': '/app/employee-clocking/punch-history',
            'order': 707,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1100,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'View Details Report',
            'label': '',
            'routerLink': '/app/chat-box',
            'order': 1100,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1158,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Directory',
            'label': '',
            'routerLink': '/app/employee/phone-directory-list',
            'order': 1158,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1171,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch Tracking',
            'label': '',
            'routerLink': '/app/employee-clocking/punch-tracking',
            'order': 1171,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1028,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Job Report',
            'label': '',
            'routerLink': '/app/employee/employee-job-report/list',
            'order': 1028,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1035,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Time history',
            'label': '',
            'routerLink': '/app/employee/employee-time-history-list',
            'order': 1035,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1180,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Scheduler',
            'label': '',
            'routerLink': '/app/employee/scheduler',
            'order': 1180,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 71,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Expense',
            'label': '',
            'routerLink': 'app/expense/list',
            'order': 71,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1138,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee On Duty',
            'label': '',
            'routerLink': '/app/crm/evv/onduty',
            'order': 1138,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [75, 97, 69, 76, 85],
            'name': 'Report',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 75,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Report',
            'label': 'label.crm',
            'routerLink': null,
            'order': 75,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 97,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Report Templates',
            'label': '',
            'routerLink': '/app/reports/report-template/list',
            'order': 97,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 69,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Performance report',
            'label': 'Performance report',
            'routerLink': '/app/reports/job-report',
            'order': 69,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 76,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Report',
            'label': 'label.crm',
            'routerLink': '/app/reports/compliance-report',
            'order': 76,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 85,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'API Usage Report',
            'label': '',
            'routerLink': '/app/reports/api-usage-report',
            'order': 85,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [30, 1000, 80, 51, 52, 1003, 1004, 1005, 1006, 1008, 1009, 66, 1101, 1132, 1133, 1134, 1135, 1136, 1137, 71, 1012, 109, 1139, 1153, 1157, 24],
            'name': 'CRM',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 30,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'CRM',
            'label': 'label.crm',
            'routerLink': '/app/crm',
            'order': 30,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1000,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Crm Dashboard',
            'label': '',
            'routerLink': '',
            'order': 1000,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 80,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transfer & Reshuffle',
            'label': '',
            'routerLink': '',
            'order': 80,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 51,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Transferred',
            'label': 'label.crm',
            'routerLink': '/app/crm/client/transfer-acceptance/',
            'order': 51,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 52,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'EVV Registered',
            'label': 'label.crm',
            'routerLink': '/app/crm/evv/search',
            'order': 52,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1003,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Events',
            'label': '',
            'routerLink': '/app/crm/event/list',
            'order': 1003,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1004,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Events Type',
            'label': '',
            'routerLink': '/app/crm/event-type/list',
            'order': 1004,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1005,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Calendar',
            'label': '',
            'routerLink': '/app/crm/event/calendar',
            'order': 1005,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1006,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Manage Pipeline',
            'label': '',
            'routerLink': '/app/crm/addPipeline',
            'order': 1006,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1008,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Pipeline',
            'label': '',
            'routerLink': '/app/crm/pipeline',
            'order': 1008,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1009,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flags',
            'label': '',
            'routerLink': '/app/crm/flags',
            'order': 1009,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 66,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Product',
            'label': 'label.crm',
            'routerLink': '/app/crm/products/list',
            'order': 66,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1101,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Campaign',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/list',
            'order': 1101,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1132,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Lead List',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/potential/list',
            'order': 1132,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1133,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Lead',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/potential/add',
            'order': 1133,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1134,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Marketing',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/marketing',
            'order': 1134,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1135,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Opportunity',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/opportunity/list',
            'order': 1135,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1136,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Activity Log',
            'label': 'label.crm',
            'routerLink': '/app/crm/activity-log/list',
            'order': 1136,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1137,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client User Account',
            'label': '',
            'routerLink': '/app/client-account/list',
            'order': 1137,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1012,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing Client Log',
            'label': '',
            'routerLink': '/app/job-follow-up/list',
            'order': 1012,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 109,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Import Clients',
            'label': '',
            'routerLink': '',
            'order': 109,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1139,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Template',
            'label': '',
            'routerLink': '/app/crm/email-template/list',
            'order': 1139,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1153,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Info Account',
            'label': '',
            'routerLink': '/app/client-info-account/list',
            'order': 1153,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1157,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Contract',
            'label': '',
            'routerLink': '/app/crm/client/contract/list',
            'order': 1157,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 24,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Payment',
            'label': 'label.payment',
            'routerLink': '/app/employee/payment',
            'order': 24,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1169, 1163, 1164, 1165, 1166, 1168, 1170, 2341, 3158, 1172, 1173, 1692],
            'name': 'VRM',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1169,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Electoral Campaign',
            'label': '',
            'routerLink': '/app/crm/campaign/electoral/list',
            'order': 1169,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1163,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voter List',
            'label': '',
            'routerLink': '/app/ele-campaign/voter/list',
            'order': 1163,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1164,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Walker\'s List',
            'label': '',
            'routerLink': '/app/ele-campaign/walker/list',
            'order': 1164,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1165,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Zone Management',
            'label': '',
            'routerLink': '/app/ele-campaign/zone-management/list',
            'order': 1165,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1166,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Candidate List',
            'label': '',
            'routerLink': '/app/ele-campaign/candidate/list',
            'order': 1166,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1168,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Schedule Batch',
            'label': '',
            'routerLink': '/app/ele-campaign/schedule-batch',
            'order': 1168,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1170,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flyer List',
            'label': '',
            'routerLink': '/app/ele-campaign/flyer/list',
            'order': 1170,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2341,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Visit History',
            'label': '',
            'routerLink': '/app/ele-campaign/visit-history/list',
            'order': 2341,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 3158,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voter Upload History',
            'label': '',
            'routerLink': '/app/ele-campaign/voter-upload-history/list',
            'order': 3158,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1172,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Customize Questions',
            'label': '',
            'routerLink': '/app/vrm/customize-questions/list',
            'order': 1172,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1173,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Fundraisers List',
            'label': '',
            'routerLink': '/app/vrm/fundraisers/list',
            'order': 1173,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1692,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Zone Preferences',
            'label': '',
            'routerLink': '/app/vrm/fundraisers/list',
            'order': 1692,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [114, 59, 65, 67, 112, 110, 113, 1013, 1016, 1014, 1120, 1666, 1667, 1668, 1678, 1679],
            'name': 'Payment',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 114,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Fees',
            'label': 'fees',
            'routerLink': '/app/monthly-fee/list',
            'order': 114,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 59,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Process Payment',
            'label': 'label.crm',
            'routerLink': '/app/payments',
            'order': 59,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 65,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice',
            'label': 'label.crm',
            'routerLink': '/app/payments/invoice',
            'order': 65,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 67,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing',
            'label': 'label.crm',
            'routerLink': '/app/billings/list',
            'order': 67,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 112,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Client Billing Details',
            'label': 'label.crm',
            'routerLink': '/app/billings/add',
            'order': 112,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 110,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Template',
            'label': 'label.crm',
            'routerLink': '/app/billings/invoice-template/list',
            'order': 110,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 113,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Dispatch Billing',
            'label': 'label.crm',
            'routerLink': '/app/billings/dispatch/list',
            'order': 113,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1013,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Old Invoice',
            'label': 'label.crm',
            'routerLink': '/app/payments/old-invoice',
            'order': 1013,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1016,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Credits',
            'label': 'label.crm',
            'routerLink': '/app/payments/available-credit',
            'order': 1016,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1014,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Collection Note',
            'label': 'label.crm',
            'routerLink': '/app/payments/follow-up-note',
            'order': 1014,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1120,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'View Payment Method',
            'label': 'label.crm',
            'routerLink': '/app/payments/view-payment-method/list',
            'order': 1120,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1666,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wallet',
            'label': 'wallet',
            'routerLink': '/app/payments/wallet',
            'order': 1666,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1667,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wallet Transaction',
            'label': 'wallet Transaction',
            'routerLink': '/app/payments/wallet-transaction',
            'order': 1667,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1668,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Self Payment',
            'label': 'Client Self Payment',
            'routerLink': '/app/payments/client-self-payment',
            'order': 1668,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1678,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Verification',
            'label': 'Invoice Verification',
            'routerLink': '/app/payments/invoice-verification',
            'order': 1678,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1679,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Summary',
            'label': 'Invoice Summary',
            'routerLink': '/app/payments/invoice-summary',
            'order': 1679,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1670, 1671, 1669, 1677],
            'name': 'Survey Land',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1670,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Quote',
            'label': 'Quote',
            'routerLink': '/app/quote/list',
            'order': 1670,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1671,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Land Project',
            'label': 'Land Project',
            'routerLink': '/app/lander/list',
            'order': 1671,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1669,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Rate',
            'label': 'Service Rate',
            'routerLink': '/app/service-rate/list',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1677,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Provider',
            'label': 'Service Provider',
            'routerLink': '/app/service-provider/list',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [57, 58, 68, 63, 61, 1027, 1182, 1183, 1036, 1184, 1037, 1185, 2342, 2343, 2344, 1111],
            'name': 'OperrTel',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': true,
            'deleteChecked': true,
            'disableParent': false,
        },
        {
            'id': 57,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel',
            'label': 'label.crm',
            'routerLink': null,
            'order': 57,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 58,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Package',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/list',
            'order': 58,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 68,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contract',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/contract',
            'order': 68,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 63,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Invoice',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/invoice',
            'order': 63,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 61,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Payment',
            'label': 'label.crm',
            'routerLink': '/app/payment',
            'order': 61,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1027,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Send SMS',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/send-sms',
            'order': 1027,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1182,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Bulk SMS',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/bulk-sms',
            'order': 1182,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1183,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Schedule List',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/schedule-list',
            'order': 1183,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1036,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS Usage',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/sms-monitoring',
            'order': 1036,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1184,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Auto Dial',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/auto-dial',
            'order': 1184,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1037,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Top up',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/top-up',
            'order': 1037,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1185,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'My Message',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/my-message',
            'order': 1185,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2342,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel Group',
            'label': '',
            'routerLink': '/app/ele-campaign/vrm-group/list',
            'order': 2342,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2343,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'DRIP Campaign',
            'label': '',
            'routerLink': '/app/phonesystem/drip-campaign/list',
            'order': 2343,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 2344,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contacts',
            'label': '',
            'routerLink': '/app/employee/my-contact/list',
            'order': 2344,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1111,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'My Contact List',
            'label': 'label.employee_add',
            'routerLink': '/app/employee/my-contact/list',
            'order': 51,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1121],
            'name': 'Billing',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1121,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Map Client/Base',
            'label': 'label.company',
            'routerLink': '/app/billing/map-client-base',
            'order': 1121,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1056, 5, 7, 8, 9, 10, 1011, 1055, 1059, 1176, 86, 1178, 1181, 1177, 1187, 1687, 1688, 1689],
            'name': 'Operation',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1056,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Notification',
            'label': 'label.crm',
            'routerLink': '/app/notification',
            'order': 1056,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 5,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Company Profile',
            'label': 'label.company',
            'routerLink': '/app/company',
            'order': 5,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': 78,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Company Settings',
        //   'label': '',
        //   'routerLink': '/app/company/setting',
        //   'order': 91,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': false,
        //   'editChecked': false,
        //   'deleteChecked': false,
        //   'disableDeleteChecked': false,
        // },
        {
            'id': 7,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Department',
            'label': 'label.department',
            'routerLink': '/app/department',
            'order': 7,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 8,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Position',
            'label': 'label.position',
            'routerLink': '/app/position',
            'order': 8,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 9,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Content',
            'label': 'label.content',
            'routerLink': '/app/content',
            'order': 9,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 10,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Note',
            'label': 'label.note',
            'routerLink': '/app/note',
            'order': 10,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': 42,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Payroll Setting',
        //   'label': 'label.payroll_setting',
        //   'routerLink': '/app/agency/payroll',
        //   'order': 1,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': true,
        //   'showMenu': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },
        {
            'id': 1011,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Conference Room',
            'label': '',
            'routerLink': '/app/conference-room',
            'order': 1011,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1055,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Share Holder',
            'label': '',
            'routerLink': '/app/share-holder/list',
            'order': 1011,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1059,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'PWD management',
            'label': '',
            'routerLink': '/app/pwdmng/list',
            'order': 1059,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1176,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Document Settings',
            'label': '',
            'routerLink': '/app/company/document-settings/list',
            'order': 1176,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 86,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'API Setting',
            'label': '',
            'routerLink': '/app/google-api-setting',
            'order': 86,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1178,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel Pool',
            'label': '',
            'routerLink': '/app/operrtel-pool/list',
            'order': 1178,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1181,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Package Service/Plan',
            'label': '',
            'routerLink': '/app/package-service-plan/list',
            'order': 1181,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1177,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrSign',
            'label': '',
            'routerLink': '/app/doc-sign',
            'order': 1177,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1187,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Location Management',
            'label': '',
            'routerLink': '/app/location-management/list',
            'order': 1187,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1687,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Company Settings',
            'label': '',
            'routerLink': '',
            'order': 1687,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1688,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Settings',
            'label': '',
            'routerLink': '',
            'order': 1688,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1689,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Other Settings',
            'label': '',
            'routerLink': '',
            'order': 1689,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1, 62, 77, 1156, 48, 36, 70, 701, 702, 46, 64, 78, 125, 1162],
            'name': 'Other',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Dashboard',
            'label': 'label.dashboard',
            'routerLink': '/app/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 62,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Overtime',
            'label': 'label.overtime',
            'routerLink': '/app/overtime/list',
            'order': 62,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 77,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OT Prevention',
            'label': '',
            'routerLink': '',
            'order': 77,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true
        },
        {
            'id': 48,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Work Order',
            'label': 'label.position',
            'routerLink': '/app/workorder',
            'order': 48,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 70,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Colors',
            'label': '',
            'routerLink': '',
            'order': 70,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 701,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Layout Palette',
            'label': '',
            'routerLink': '',
            'order': 701,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 702,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Themes',
            'label': '',
            'routerLink': '',
            'order': 702,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': 3,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Company Admin',
        //   'label': 'label.company_admin',
        //   'routerLink': '/app/admin/company-admin',
        //   'order': 22,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': true,
        //   'showMenu': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },
        {
            'id': 46,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Event Schedule',
            'label': 'label.event_schedule',
            'routerLink': '/app/employee/event-schedule',
            'order': 46,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 64,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Chat Box',
            'label': 'label.crm',
            'routerLink': '/app/chat-box',
            'order': 64,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 78,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Task Management',
            'label': 'Task Management',
            'routerLink': '/app/tm',
            'order': 37,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 126,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Video Conferences',
            'label': '/app/conference/schedule',
            'routerLink': '',
            'order': 126,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 127,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Join Conference',
            'label': '/app/conference/join',
            'routerLink': '',
            'order': 127,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1038,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Notebook',
            'label': 'Notebook',
            'routerLink': '/app/note',
            'order': 1038,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1779, 1156, 36, 125, 1162, 4461, 1777, 1778],
            'name': 'Tools',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1156,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Softphone',
            'label': '',
            'routerLink': '',
            'order': 1156,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1777,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Documents',
            'label': 'Documents',
            'routerLink': '/app/document',
            'order': 1777,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1778,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Working Time Calculator',
            'label': 'Working Time Calculator',
            'routerLink': '/app/working-time-calculator',
            'order': 1778,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1779,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Paystub PDF Processer',
            'label': 'Paystub PDF Processer',
            'routerLink': '/app/paystub-pdf-processer',
            'order': 1779,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 36,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Multimedia',
            'label': '',
            'routerLink': '/app/conference',
            'order': 36,
            'checked': false,
            'setting': false,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 125,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Screen Recorder',
            'label': '/app/screen-recorder/list',
            'routerLink': '',
            'order': 125,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 4461,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Law firm',
            'label': '/app/law-firm/list',
            'routerLink': '',
            'order': 4461,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 1162,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Spread Sheet',
            'label': '/app/spread-sheet/list',
            'routerLink': '',
            'order': 1162,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 4461,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Recollection Details',
            'label': '/app/recollection-details/list',
            'routerLink': '',
            'order': 4461,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [56, 79, 703, 704, 710, 711, 1029],
            'name': 'Contractor',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 56,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Ind Contractors',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/list',
            'order': 56,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 79,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contract',
            'label': '',
            'routerLink': '',
            'order': 79,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': 703,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contractor Invoice',
            'label': 'label.crm',
            'routerLink': '/app/contractors/invoice',
            'order': 703,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 704,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Method',
            'label': 'label.crm',
            'routerLink': '/app/payment-method/list',
            'order': 704,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 710,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Assigned Ticket',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/assignedtickets',
            'order': 710,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 711,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Completed Tickets',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/completedtickets',
            'order': 711,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1029,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Ticket Summary',
            'label': 'label.crm',
            'routerLink': '/app/contractors/ticket-summary',
            'order': 1029,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [60, 37, 38, 39],
            'name': 'Membership',
            'label': 'label.membership',
            'routerLink': '',
            'order': 42,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 60,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Excursion',
            'label': 'label.excursion',
            'routerLink': '/app/excursion',
            'order': 60,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 37,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Guest',
            'label': 'label.guest',
            'routerLink': '/app/guest',
            'order': 37,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 38,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Membership',
            'label': 'label.membership',
            'routerLink': '/app/membership',
            'order': 38,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 39,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contribution',
            'label': 'label.contribution',
            'routerLink': '/app/contribution',
            'order': 39,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [999, 998, 997],
            'name': 'Mail',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 999,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Mail',
            'label': 'Mail',
            'routerLink': '/app/lettermail',
            'order': 999,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 998,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Deadline Reminder',
            'label': 'Deadline Reminder',
            'routerLink': '/app/lettermail/reminder/list',
            'order': 998,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 997,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Document',
            'label': 'Document',
            'routerLink': '/app/lettermail/document/list',
            'order': 997,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1001, 1002],
            'name': 'Training/Schedule',
            'label': 'label.membership',
            'routerLink': '',
            'order': 42,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1001,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Training/Schedule',
            'label': 'Training/Schedule',
            'routerLink': '/app/stock',
            'order': 1001,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1002,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Training Topic',
            'label': 'Training/Schedule',
            'routerLink': '/app/training-topic/list',
            'order': 1002,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1015, 1017, 1018, 1080],
            'name': 'Admin Board Access',
            'label': 'Admin Board Access',
            'routerLink': '',
            'order': 45,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1015,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Admin Board Access',
            'label': 'Admin Board Access',
            'routerLink': '',
            'order': 1015,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1017,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Members',
            'label': 'Members',
            'routerLink': '',
            'order': 1017,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1018,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Tracking',
            'label': 'Tracking',
            'routerLink': '',
            'order': 1018,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1080,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Cards',
            'label': 'Cards ',
            'routerLink': '',
            'order': 1080,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1019, 1020, 1021, 1022, 1023, 1024, 1034, 1122],
            'name': 'Billing Report ',
            'label': 'Billing Report ',
            'routerLink': '',
            'order': 46,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1019,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Total Job',
            'label': 'Total Job',
            'routerLink': '/app/job-follow-up/billing-total-job',
            'order': 1019,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1122,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Dispatch Total Job',
            'label': 'Dispatch Total Job',
            'routerLink': '/app/job-follow-up/dispatch-total-job',
            'order': 1122,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1020,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'General Report',
            'label': 'General Report',
            'routerLink': '/app/job-follow-up/billing-report',
            'order': 1020,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1021,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Detail Report',
            'label': 'Detail Report',
            'routerLink': '/app/job-follow-up/billing-details-report',
            'order': 1021,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1022,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Correction',
            'label': 'Correction',
            'routerLink': '/app/job-follow-up/billing-correction-report',
            'order': 1022,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1023,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Fidelis Report',
            'label': 'Fidelis Report',
            'routerLink': '/app/fidelis-billing-report/list',
            'order': 1023,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1024,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Upload History',
            'label': 'Upload History',
            'routerLink': '/app/job-follow-up/billing-upload-history',
            'order': 1024,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1034,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Billing Client Status',
            'label': 'Billing Client Status',
            'routerLink': '/app/job-follow-up/billing-client-status',
            'order': 1034,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1025, 1026],
            'name': 'LOG',
            'label': 'LOG',
            'routerLink': '',
            'order': 47,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1025,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'LOG Total Job',
            'label': 'LOG Total Job',
            'routerLink': '/app/job-follow-up/billing-log-total-job',
            'order': 1025,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1026,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Detail Report',
            'label': 'Detail Report',
            'routerLink': '/app/job-follow-up/billing-log-details-report',
            'order': 1026,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        // {
        //     'id': 37,
        //     'ctrl': 0,
        //     'type': 'child',
        //     'child': '',
        //     'name': 'Plan',
        //     'label': 'Plan',
        //     'routerLink': '/app/plan/list',
        //     'order': 1026,
        //     'checked': false,
        //     'setting': true,
        //     'isActionsAllowed': true,
        //     'editChecked': false,
        //     'deleteChecked': false,
        //     'showMenu': true,
        //     'disableParent': false,
        // },
        {
            'id': 37,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Plan',
            'label': 'Plan',
            'routerLink': '/app/plan/preview',
            'order': 1026,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1272, 1273, 1274, 1275, 1276, 1277, 1278, 1279, 1280, 1206, 1207, 1208, 1209],
            'name': 'Resident',
            'label': 'Resident',
            'routerLink': '',
            'order': 1271,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1272,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Residents',
            'label': 'Residents',
            'routerLink': '/app/resident/list',
            'order': 1272,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1273,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Sign-Ins & Outs List',
            'label': 'Sign-Ins & Outs List',
            'routerLink': '/app/resident/sign-in-out-list',
            'order': 1273,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1274,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flag',
            'label': 'Flag',
            'routerLink': '/app/resident/flag',
            'order': 1274,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1275,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Alert History',
            'label': 'Alert History',
            'routerLink': '/app/resident/alert-history',
            'order': 1275,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1276,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Sign-Ins & Outs',
            'label': 'Sign-Ins & Outs',
            'routerLink': '/app/resident/sign-in-out',
            'order': 1276,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1277,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Global Dashboard',
            'label': 'Global Dashboard',
            'routerLink': '/app/resident/dashboard',
            'order': 1277,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1278,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Waived single Missing Sin-In/Out',
            'label': 'Waived single Missing Sin-In/Out',
            'routerLink': null,
            'order': 1278,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1279,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Waived In Bulk Missing Sin-In/Out',
            'label': 'Waived In Bulk Missing Sin-In/Out',
            'routerLink': null,
            'order': 1279,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': 1209,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Reminder Alert',
            'label': 'Reminder Alert',
            'routerLink': '/app/reminder/list',
            'order': 1209,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1280,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': '48 hours & Over Authorization',
            'label': '48 hours & Over Authorization',
            'routerLink': null,
            'order': 1280,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': 1281,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Negligence',
            'label': 'Negligence',
            'routerLink': null,
            'order': 1281,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': 1206,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Incident Report',
            'label': 'Incident Report',
            'routerLink': null,
            'order': 1206,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': 1207,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Curfew Schedule',
            'label': 'Curfew Schedule',
            'routerLink': null,
            'order': 1207,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': 1208,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Management',
            'label': 'Service Management',
            'routerLink': null,
            'order': 1208,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1681, 1682, 1683, 1680, 1685, 1684, 1686, 1691, 1694, 4871],
            'name': 'Compliance',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1681,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Document',
            'label': 'Compliance Document',
            'routerLink': '/app/compliance-setting/list',
            'order': 1681,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1682,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Stuff Compliance List',
            'label': 'Stuff Compliance List',
            'routerLink': '/app/employee/compliance/list',
            'order': 1682,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1683,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Document',
            'label': 'Employee Document',
            'routerLink': '',
            'order': 1683,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1680,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Dashboard',
            'label': 'Compliance Dashboard',
            'routerLink': '',
            'order': 1680,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1685,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Recollection',
            'label': 'Time Recollection',
            'routerLink': '',
            'order': 1685,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1693,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Recollection Upload History',
            'label': 'Time Recollection Upload History',
            'routerLink': '',
            'order': 1685,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1684,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voucher',
            'label': 'Voucher',
            'routerLink': '',
            'order': 1684,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1686,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wage Schedule',
            'label': 'Wage Schedule',
            'routerLink': '',
            'order': 1686,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1691,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Voucher',
            'label': 'Employee Voucher',
            'routerLink': '/app/compliance-setting/employee-voucher',
            'order': 1691,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 1694,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tip Credit',
            'label': 'Tip Credit',
            'routerLink': '/app/compliance-setting/tip-credit',
            'order': 1691,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': 4871,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monitoring Company Documents',
            'label': 'Monitoring Company Documents',
            'routerLink': '/app/compliance-setting/company-document',
            'order': 4871,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [1202, 1200, 1201, 1152, 1203, 1204, 1205],
            'name': 'Inventory',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': 1202,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Inventory List',
            'label': '',
            'routerLink': '/app/stock/list',
            'order': 1202,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1200,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Items',
            'label': '',
            'routerLink': '/app/product/add',
            'order': 1200,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1201,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Equipment Check-Out',
            'label': '',
            'routerLink': '/app/equipment',
            'order': 1201,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1152,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Vehicles',
            'label': '',
            'routerLink': '/app/vehicle/list',
            'order': 1152,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        }, {
            'id': 1203,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Purchase Requests',
            'label': '',
            'routerLink': '/app/purchase-requests',
            'order': 1203,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1204,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Inventory Report',
            'label': '',
            'routerLink': '/app/inventory-report',
            'order': 1204,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': 1205,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Manage Attributes',
            'label': '',
            'routerLink': '/app/manage-attributes',
            'order': 1205,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        }
    ];

    public static checkItem(showArray: any, baseArray: any, listArray: any): boolean {
        if (showArray && showArray.length === 0) {
            return false;
        } else {
            let flag = false;
            showArray.forEach(element => {
                if (baseArray.includes(+element)) {
                    // find in current ary
                    if (listArray.find(item => item.id === +element)) {
                        flag = true;
                    }
                }
            });
            return flag;
        }
    }

    public static checkChildItem(menu: any[], listArray): boolean {
        let flag = false;
        menu.forEach(element => {
            if (listArray.find(item => item.id === element)) {
                flag = true;
            }
        });
        return flag;
    }

    public static cleacObject(menus) {
        let order = 0;
        menus.forEach(element => {
            if (element.type === 'parent') {
                order = order + 1;
            } else {
                element.ctrl = order;
            }
        });
    }

    public static menuToDisplay(menuList) {
        const Ids = {
            employeeIds: [7, 8, 42, 62, 77],
            crmIds: [],
            operrTelIds: []
        };
        if (menuList.length > 0) {
            for (let i = 0; i <= menuList.length; i++) {
                if (menuList[i]?.name === 'CRM') {
                    Ids.crmIds.push(...menuList[i].child);
                }
                if (menuList[i]?.name === 'Employee') {
                    Ids.employeeIds.push(...menuList[i].child);
                }
                if (menuList[i]?.name === 'OperrTel') {
                    Ids.operrTelIds.push(...menuList[i].child);
                }
            }
            return Ids;
        }

    }
}
