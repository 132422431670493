import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { PaymentProfileService } from '../../../../../payment/service/payment-profile.service';
import { PaymentMethod } from '../../../../../payment/model/payment-method.model';
import { PaymentMethodService } from '../../../../../payment/service/payment-method.service';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../../shared/service/auth/auth.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AesUtilService } from 'app/shared/service/aes-util.service';
import moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng';
import { PaymentNotificationService } from 'app/payment/service/payment-notification.service';
import { AccountService } from 'app/account/service/account.service';
import { FormValidatorService } from 'app/shared/service/form-validator/form-validator.service';
import {TranslatePipe} from '@ngx-translate/core';
import { VisaCardComponent } from '../visa-card/visa-card.component';
import { AmexCardComponent } from '../amex-card/amex-card.component';


declare var google: any;
@Component({
  selector: 'app-fundraisers-payment-method',
  templateUrl: './fundraisers-payment-method.component.html',
  styleUrls: ['./fundraisers-payment-method.component.scss'],
  providers: [FormValidatorService]
})
export class FundraisersPaymentMethodComponent implements OnInit {
  @ViewChild(VisaCardComponent, { static: false }) visaCardComponent: VisaCardComponent;
  @ViewChild(AmexCardComponent, { static: false }) amexCardComponent: AmexCardComponent;
  @Input()
  paymentMethod = null;

  @Input()
  paymentCard = null;

  @Input()
  isEmexCard = false;

  @Input()
  isVisaCard = false;

  @Input()
  isTransfer = false;

  @Input()
  isEcheck = false;

  @Input()
  formatCard;

  @Input()
  selectedInvoices = [];

  @Input()
  backKeyFlag;

  @Input()
  noEcheck = false;

  @Input()
  isShowPaymentMethod = false;

  showRegisterAccountDialog = false;

  account: PaymentMethod = new PaymentMethod();
  msgs = [];
  routingNumber: string;
  routingNumberValid = '';
  private _jsonCountryCodeURL = 'assets/demo/data/countries.json';
  accountTypes = [
    { label: 'Checking', value: 'checking' },
    { label: 'Savings', value: 'savings' },
    { label: 'Business Checking', value: 'businessChecking' },
  ];
  generatedKey: string;
  listOfPhoneCode: any = [];
  timeSpent = new Date();
  private _routerSub = Subscription.EMPTY;

  registerAccountForm: UntypedFormGroup;
  transferDte: Date = new Date();
  isClicked = false;
  isDisabled = true;
  showSuccess = false;
  showError = false;
  pending: boolean;

  constructor(
    private paymentProfileService: PaymentProfileService,
    private http: HttpClient,
    private authService: AuthService,
    private paymentMethodService: PaymentMethodService,
    private monitoringDetailsService: MonitoringDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private aesUtilService: AesUtilService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private paymentNotificationService: PaymentNotificationService,
    private accountService: AccountService,
    private translatePipe: TranslatePipe,
    private formValidator: FormValidatorService
  ) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/payments/method') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Payment method`,
            this.timeSpent,
            {
              navigated_to_payment_method_page_by:
                this.authService.isClientRole()
                  ? this.authService.getUserInfo()?.name
                  : this.authService.getCurrentLoggedInName(),
            },
            'complete',
            `Navigated to Payment method`,
            0
          );
        }
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('paymentMethod') && localStorage.getItem('paymentMethod') !== undefined) {
      this.paymentMethod = localStorage.getItem('paymentMethod');
    }
    if (!this.paymentMethod && this.authService.isContractorRole()) {
      this.paymentMethod = 'TRANSFER';
    }
    if (!this.paymentMethod && this.authService.isEmployeeRole()) {
      this.paymentMethod = 'CREDIT';
    }
    if (!this.paymentMethod || this.paymentMethod === undefined || this.paymentMethod === 'undefined') {
      this.paymentMethod = 'CREDIT';
    }
    this.registerAccountForm = this.fb.group({
        countryCode: ['US', [Validators.required]],
        bankCurrency: ['USD'],
        accountName: null,
        accountType: ['checking', [Validators.required]],
        beneficiaryName: [null, [Validators.required]],
        bankAccount: [null, [Validators.required]],
        swiftCode: [null, [Validators.required, Validators.minLength(8)]],
        ssn: [null, [Validators.required]],
        dob: [null, [Validators.required]],
        address: [null, [Validators.required]],
        city: [null, [Validators.required]],
        state: [null, [Validators.required]],
        zipcode: [null, [Validators.required]],
        bankName: null,
      });
    this.account.countryCode = 'US';
    this.account.bankCurrency = 'USD';
    this.getCountry().subscribe((response) => {
      this.listOfPhoneCode = [];
      const phoneCodes = response.data;
      phoneCodes.forEach((item) => {
        const data = { label: item.name, value: item.code };
        this.listOfPhoneCode.push(data);
      });
      console.log(this.listOfPhoneCode);
      this.account.countryCode = 'US';
    });
    localStorage.removeItem('paymentMethod');
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000);
  }

  async showCardInfo() {
    const keyData: any = await this.paymentProfileService
      .getKeyStore()
      .toPromise();
    if (keyData.status === 200 && keyData.data) {
      this.generatedKey = keyData.data.id;
      this.isShowPaymentMethod = !this.isShowPaymentMethod;
      if (
        'CREDIT' === this.paymentMethod &&
        'AMERICAN_EXPRESS' === this.paymentCard
      ) {
        this.isEmexCard = true;
        this.formatCard = '3999 9999 9999 999';
      } else if ('TRANSFER' === this.paymentMethod) {
        this.isTransfer = true;
      } else if ('E_CHECK' === this.paymentMethod) {
        this.isEcheck = true;
      } else if (
        'CREDIT' === this.paymentMethod &&
        ('MASTER_CARD' === this.paymentCard ||
          'VISA' === this.paymentCard ||
          'DISCOVER' === this.paymentCard)
      ) {
        this.isVisaCard = true;
        if ('VISA' === this.paymentCard) {
          this.formatCard = '9999 9999 9999 9999';
        } else if ('MASTER_CARD' === this.paymentCard) {
          this.formatCard = '9999 9999 9999 9999';
        } else if ('DISCOVER' === this.paymentCard) {
          this.formatCard = '9999 9999 9999 9999';
        }
      }
    }
  }

  reset(event) {
    if (event) {
      this.isShowPaymentMethod = false;
      this.isEmexCard = false;
      this.isVisaCard = false;
      this.isTransfer = false;
      this.isEcheck = false;
    }
  }

  public getCountry(): Observable<any> {
    return this.http.get(this._jsonCountryCodeURL);
  }

  disableNextButton() {
    let res = true;
    if (
      this.paymentMethod &&
      (this.paymentMethod === 'TRANSFER' || this.paymentMethod === 'E_CHECK')
    ) {
      res = false;
    }
    if (
      this.paymentMethod &&
      this.paymentMethod === 'CREDIT' &&
      this.paymentCard
    ) {
      res = false;
    }
    return res;
  }

  verify() {
    if (this.routingNumber) {
      this.getRountingNumber(this.routingNumber);
    }
  }

  getRountingNumber(rountingNumber) {
    this.paymentProfileService
      .getBankName(rountingNumber)
      .subscribe((rs: any) => {
        if (rs && rs.code && rs.code === 200) {
          this.routingNumberValid = 'valid';
          this.msgs.push({
            severity: 'success',
            summary: this.translatePipe.transform('SUCCESS'),
            detail: this.translatePipe.transform('You have correct Round Number'),
          });
        } else {
          this.routingNumberValid = 'invalid';
          this.msgs.push({
            severity: 'error',
            summary: this.translatePipe.transform('ERROR'),
            detail: this.translatePipe.transform('You have incorrect Round Number'),
          });
          this.routingNumber = '';
        }
      });
  }

  registerAccount(dataForm, popup?) {
    this.account.isRegister = true;
    this.isClicked = true;
    this.account.companyId = this.authService.getCurrentCompanyId();
    this.account.createdByUsr = this.authService.getCurrentUsername();

    if (this.formValidator.validateForm(this.registerAccountForm, dataForm)) {
    const bankDataStr = `${this.account.beneficiaryName};${this.account.bankAccount};${this.account.swiftCode};${this.account.bankCurrency};${this.account.countryCode}`;
    this.paymentProfileService.getBankKeyStore().subscribe(
      (rp: any) => {
        const encryptData = this.aesUtilService.encryptDataWithKey(
          bankDataStr.trim(),
          rp.data.keyStore
        );
        const payload = {
          appUserId: this.authService.getUserInfo().adminId,
          userType: this.authService.getPaymentUserType(),
          encryptDataBank: encryptData,
          firstName: this.authService.getUserInfo().firstName || this.account.beneficiaryName,
          lastName: this.authService.getUserInfo().lastName || this.account.beneficiaryName,
          email: this.authService.getUserInfo().email,
          address: this.account.branchAddress,
          city: this.account.city,
          postalCode: this.account.zipcode,
          state: this.account.state,
          phoneNumber: this.authService.getUserInfo().phone ? this.authService.getUserInfo().phone : '0123456789',
          ssn: this.account.ssn,
          dateOfBirth: moment(this.account.dob).format('YYYY-MM-DD')
        };
        this.paymentProfileService
          .addBankAccount(payload, rp.data.id)
          .subscribe(
            (rp: any) => {
              if (rp != null && rp.status === 200) {
                this.account.bankAccountId = rp.data.bankAccount.id;
                this.paymentMethodService
                  .savePaymentMethod(this.account)
                  .subscribe((res) => {
                    this.showRegisterAccountDialog = false;
                    this.messageService.clear();
                    this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Bank account is successfully added')});
                    if (!popup) {
                      this.router.navigate(['app/payments/detail']);
                    }
                    this.monitorDetails('success');
                  });
              } else {
                this.isClicked = false;
                this.messageService.clear();
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: rp.message});
                this.msgs.push({
                  severity: 'error',
                  summary: this.translatePipe.transform('ERROR'),
                  detail: rp.message,
                });
                this.monitorDetails(null, rp.message);
              }
            },
            (err) => {
              this.messageService.clear();
              if (err && err.error) {
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.error.message});
              } else {
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Bad request')});
              }
              this.isClicked = false;
              this.monitorDetails(null, err.error.message);
            }
          );
      },
      (err) => {
        this.isClicked = false;
        this.messageService.clear();
        this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Get key store fail')});
        this.monitorDetails(null, 'Get key store fail.');
      }
    );
    } else { this.isClicked = false;  }
  }

  openRegisterDialog() {
    this.account = new PaymentMethod();
    this.account.countryCode = 'US';
    this.account.bankCurrency = 'USD';
    this.showRegisterAccountDialog = true;
  }

  transferClicked() {
    this.transferDte = new Date();
    this.monitoringDetailsService.monitorAction(
      `Navigated to Transfer/Register Account`,
      this.transferDte,
      {
        navigated_to_transfer_account_page_by:
          this.authService.isClientRole()
            ? this.authService.getUserInfo()?.name
            : this.authService.getCurrentLoggedInName(),
      },
      'complete',
      `Navigated to Transfer/Register Account`,
      0
    );
  }

  monitorDetails(type?, errorDescription?) {
    let args: any = {};
    if (type) {
      args = {
        new_account_added_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
      };
      this.monitoringDetailsService.monitorAction(
        'New Account Bank Added',
        this.transferDte,
        args,
        'complete',
        'New Account Bank Added',
        0,
      );
    } else {
      args = {
        failed_to_add_new_account_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
      };
      this.monitoringDetailsService.monitorAction(
        'Failed to Add New Bank Account',
        this.transferDte,
        args,
        'complete',
        'Failed to Add New Bank Account',
        0,
        (r) => {
          this.paymentNotificationService.sendPaymentFailedEmail(r, errorDescription);
        }
      );
    }
  }

  verifyBank() {
    this.isDisabled = true;
    if (this.account.swiftCode) { this.getBankList(this.account.swiftCode, 'valid'); }
  }

  getBankList(val?: any, type?: any) {
    if (val && val.length >= 8) {
      const options = { rountingNumber: val };
      this.accountService.getBankList(options).subscribe(res => {
        const resObj: any = res;
        if (resObj.data.code === 200) {
          this.isDisabled = true;
          this.pending = false;
          this.account.bankName = resObj.data.customer_name;
          this.isTrue(true, false);
        } else {
          this.pending = false;
          this.account.bankName = '';
          this.isTrue(false, true);
        }
      }, err => {
        this.pending = false;
        this.isTrue(false, true); });
    } else if (type) {
      this.pending = false;
      this.isTrue(false, true);
      this.messageService.clear();
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('Please enter the valid routing number')});
    } else { this.account.bankName = ''; }
  }

  isNumber(evt, typ?) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode && typ) {
      this.isDisabled = false;
      this.isTrue(false, false);
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  enable(evt) {
    if (evt) {
      if (this.account?.swiftCode?.length >= 8) {
        this.pending = true;
        this.verifyBank();
      }
      this.isDisabled = false;
      this.isTrue(false, false);
    }
  }

  isTrue(a, b) {
    this.showSuccess = a;
    this.showError = b;
  }

  resetRouting(field) {
    this.account.swiftCode = null;
    this.isTrue(false, false);
    document.getElementById(field).focus();
  }

    handlePlaceDetail(placeDetail) {
        const address_components = placeDetail.address_components;
        const cityObj = address_components.find(x => x.types.includes('sublocality_level_1'));
        const zipCode = address_components.find(x => x.types.includes('postal_code'));
        this.registerAccountForm.patchValue({
            address: placeDetail.name,
            city: placeDetail.city ? placeDetail.city : '',
            state: placeDetail.state ? placeDetail.state : '',
            zipcode: zipCode ? zipCode.long_name : '',
        });
    }
    ngAfterViewInit() {
        const addressOneBox = new google.maps.places.SearchBox(document.getElementById('address'));
        addressOneBox.addListener('places_changed', () => {
            const places = addressOneBox.getPlaces();
            if (places.length === 0) {
                return;
            }
            this.handlePlaceDetail(places[0]);
        });

        // const addressSecondBox = new google.maps.places.SearchBox(document.getElementById("branchAddress"));
        // addressSecondBox.addListener("places_changed", () => {
        //     const places = addressOneBox.getPlaces();
        //     if (places.length == 0) {
        //         return;
        //     }
        //     this.handlePlaceDetail(places[0])
        // });
    }

    changePaymentMethod() {
    const _this = this;
      if (this.paymentMethod === 'TRANSFER') {
        setTimeout(() => {
            _this.ngAfterViewInit();
        }, 2000);
      }
    }

    async initCard() {
      let data = {};
      if (['VISA', 'MASTER_CARD', 'DISCOVER'].includes(this.paymentCard)) {
        data = await this.visaCardComponent.addPaymentProfile();
      } else {
        data = await this.amexCardComponent.addPaymentProfile();
      }
      return {key: this.generatedKey, ...data};
    }

    disableAction() {
      if (!this.authService.getUserInfo()) {
        if (!this.paymentCard || !this.isShowPaymentMethod) {
          return true;
        }
        if (['VISA', 'MASTER_CARD', 'DISCOVER'].includes(this.paymentCard)) {
          return this.visaCardComponent && (this.visaCardComponent.isDisabled || !this.visaCardComponent.visaCardForm.valid);
        } else {
          return this.amexCardComponent && !this.amexCardComponent.amexCardForm.valid;
        }
      } else {
        return false;
      }
    }

}
