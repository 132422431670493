import { Injectable } from '@angular/core';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { DepartmentSearch } from "../../../shared/model/search/department.search";
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResponse } from '../../../shared/model/list.response';
import { Department } from 'app/department/model/department.model';
import {PaginatedResponse} from '../../../shared/model/PageResponse/paginated.response';
import {DepartmentHistory} from '../../model/department-history.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentServicev2 extends AbstractServiceV2<Department, DepartmentSearch>{ 

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/department', '');
  }

  getDropdown(searchParams:DepartmentSearch):Observable<ListResponse<Department>>
  {
      let params = this.createParams(searchParams);
      return this._http.get<ListResponse<Department>>(this.baseUrlV2 + '/' + "dropdown", { params: params }).pipe(map(resp => resp));
  }

    search(options: any ): Observable<PaginatedResponse<Department>> {
        let params = this.createParams(options);
        return this._http.get<PaginatedResponse<Department>>(this.baseUrlV2 + "/search", {params: params}).pipe(map(res => res));
    }

    getDropdownCreatedBy(searchParams:DepartmentSearch):Observable<ListResponse<Department>>
    {
        let params = this.createParams(searchParams);
        return this._http.get<ListResponse<Department>>(this.baseUrlV2 + "/dropdownCreatedBy", { params: params }).pipe(map(resp => resp));
    }

    getHistory(options: any ): Observable<PaginatedResponse<DepartmentHistory>> {
        return this._http.post<PaginatedResponse<DepartmentHistory>>(this.baseUrlV2 + "/history/search", options).pipe(map(res => res));
    }
}
