import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../abstract.service';
import { Constants } from '../../model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from 'app/shared/model/page.model';

@Injectable({ providedIn: 'root' })
export class ComplianceDocumentService extends AbstractService<any> {

  constructor(protected _http: HttpClient) {
    super(_http, Constants.ROUTES.COMPLIANCE_DOCUMENT, '');
  }

  save(document: any): Observable<any> {
    //return this._http.post<any>(`http://localhost:8009/api/v2/notification/compliance-document`, document).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}`, document).pipe(map(res => res));
  }

  delete(id: number): Observable<any> {
    return this._http.delete<any>(`${this.baseUrlV2}${id}`).pipe(map(res => res));
  }

  getDocumentList(adminId: number, adminType: string): Observable<any> {
    const params: any = { adminId: adminId, adminType: adminType };
    return this._http.get<any>(`${this.baseUrlV2}list`, { params: params }).pipe(map(res => res));
  }

  getHistory(params: any): Observable<Page<any>> {
    //return this._http.post<Page<any>>(`http://localhost:8009/api/v2/notification/compliance-document/history`, params).pipe(map(resp => resp));
    return this._http.post<Page<any>>(`${this.baseUrlV2}history`, params).pipe(map(resp => resp));
  }

  getComplianceList(params: any): Observable<Page<any>> {
      //return this._http.post<Page<any>>(`http://localhost:8009/api/v2/notification/compliance-document/history`, params).pipe(map(resp => resp));
      return this._http.post<Page<any>>(`${this.baseUrlV2}search`, params).pipe(map(resp => resp));
  }

  getEmployeeCompliance(params: any): Observable<Page<any>> {
      //return this._http.post<Page<any>>(`http://localhost:8009/api/v2/notification/compliance-document/history`, params).pipe(map(resp => resp));
      return this._http.post<Page<any>>(`${this.baseUrlV2}search/employee`, params).pipe(map(resp => resp));
  }

    exportPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportEmployeePdf`, data).pipe(map(resp => resp));
    }

  statistic(params: any): Observable<Page<any>> {
    return this._http.post<Page<any>>(`${this.baseUrlV2}statistic`, params).pipe(map(resp => resp));
  }

    saveConfirm(params: any): Observable<Page<any>> {
        return this._http.post<Page<any>>(`${this.baseUrlV2}/confirm`, params).pipe(map(resp => resp));
    }
}