import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { Observable } from 'rxjs';
import { AbstractService } from '../../shared/service/abstract.service';
import { WalletTransaction } from '../model/wallet-transaction.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WalletTransactionService extends AbstractService<WalletTransaction> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/wallet-transaction', '');
  }

  getAllUser(): Observable<any> {
    return this._http
      .get<any>(this.baseUrlV2 + 'all-users')
      .pipe(map((res) => res));
  }

  search(options?: any) {
    return this._http
      .post(`${this.baseUrlV2}search`, options)
      .pipe(map((res) => res));
  }

  sync(transactionId: any) {
    return this._http
      .get(`${this.baseUrlV2}sync/` + transactionId)
      .pipe(map((res) => res));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }
}
