import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiUsageService {

  public static readonly DISPATCH_CORE_URL = `${environment.dispatch.url}/core/api/v3/geo`;

  public static readonly DISPATCH_BASE_URL = `${environment.dispatch.url}/base/api/v3/operrwork`;

  public static readonly DISPATCH_BASE_URL_2 = `${environment.dispatch.url}/base/api/v3/base`;


  constructor(private http: HttpClient) { }

  public getListGeoServiceUrl(): Observable<any> {
    return this.http.get(`${ApiUsageService.DISPATCH_CORE_URL}/report/urls`);
  }

  public getHistoryTracking(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${ApiUsageService.DISPATCH_CORE_URL}/report/table-data`, {
      params: httpParams,
    });
  }

  public exportData(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${ApiUsageService.DISPATCH_CORE_URL}/report/export-data`, {
      params: httpParams,
      responseType: "blob",
    });
  }

  public sendEmail(payload): Observable<any> {
    return this.http.post(
      `${ApiUsageService.DISPATCH_CORE_URL}/report/send-email`,
      payload
    );
  }

  public getCompanyList(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${ApiUsageService.DISPATCH_BASE_URL}/company`, {
      params: httpParams,
    });
  }
  public getBaseList(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${ApiUsageService.DISPATCH_BASE_URL}/base`, {
      params: httpParams,
    });
  }

  getBaseInfo(baseId: number): Observable<any> {
    return this.http.get(`${ApiUsageService.DISPATCH_BASE_URL}/base/${baseId}`);
  }

  public getHistoryTrackingStatistic(options): Observable<any> {
    const params = options
      ? Object.keys(options)
          .filter((e) => options[e] != null)
          .map((e) => `${e}=${options[e]}`)
          .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${ApiUsageService.DISPATCH_CORE_URL}/report/table-statistic`, {
      params: httpParams,
    });
  }

  public getBaseByDispatchingLicenseNums(dispatchingLicenseNums: string[]): Observable<any> {
    // return this.http.post(`http://localhost:9070/api/v3/operrwork/dispatching-license-num`, dispatchingLicenseNums);
    return this.http.post(`${ApiUsageService.DISPATCH_BASE_URL}/dispatching-license-num`, dispatchingLicenseNums);
  }

  getAllBases(): Observable<any> {
    return this.http.post(`${ApiUsageService.DISPATCH_BASE_URL_2}/operrwork-simple`, {});
  }
}
