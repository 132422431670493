
export class EmployeeTimeLeftToClockOut {
    public isTimeInRange: boolean;
    public timeLeftToClockOut: number;
    public desiredTimeToAlert: number;
    public period: string;
    public message: string;
    public isSixHours: boolean;

    constructor(isTimeInRange: boolean, timeLeft: number, desiredTime: number, period: string, isSixHours: boolean) {
        this.isTimeInRange = isTimeInRange;
        this.timeLeftToClockOut = timeLeft;
        this.desiredTimeToAlert = desiredTime;
        this.period = period;
        this.isSixHours = isSixHours;
    }
}
