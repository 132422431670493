import {
  Directive,
  EventEmitter,
  Input, NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";
import { PolygonUtils } from "../utils/polygon.utils";
// import {} from '@types/googlemaps';
declare let google: any;

@Directive({
  selector: "turf-polygon-drawer-map",
})
export class TurfPolygonDrawerMapDirective implements OnInit, OnChanges {
  @Output() polygonChanged: EventEmitter<any> = new EventEmitter();
  @Output() pointClicked: EventEmitter<any> = new EventEmitter();
  private polygons: any = [];
  private map;
  customPolygons: any[] = [];
  overlays: any = [];
  polygonList = [];
  markers = [];
  @Input() zonePolygonGeoJson: any[] = [];

  @Input("clearPolygons")
  set clearPolygons(_clearPolygons: any) {
    if (_clearPolygons) {
      if (this.overlays && this.overlays.length) {
        for (let i = 0; i < this.overlays.length; i++) {
          this.overlays[i].setMap(null);
        }
        this.overlays = [];
      }
      this.polygons = [];
      if (this.turfData?.polygon) {
        this.turfData.polygon.setMap(null);
        this.turfData.polygon = null;
      }
      this.initDrawingManager();
      this.polygonChanged.emit(this.polygons);
    }
  }

  @Input("goBack")
  set goBack(index: any) {
    const overlay = this.overlays.filter((o, i) => i == index)[0];
    this.polygons = this.polygons.filter((p, i) => i != index);
    this.overlays = this.overlays.filter((o, i) => i != index);
    this.polygonChanged.emit(this.polygons);
    if (overlay) {
      overlay.setMap(null);
    }
  }

  @Input("removedPoint")
  set removedPoint(_removedPoint: any) {
    if (_removedPoint) {
      if (this.overlays && this.overlays.length) {
        for (let i = 0; i < this.overlays.length; i++) {
          const length = this.overlays[i].getPath().getLength();
          console.log(length);
          if (length <= 3) {
            break;
          }
          for (let j = 0; j < length; j++) {
            const vertex = this.overlays[i].getPath().getAt(j);
            if (vertex && _removedPoint.lat === vertex.lat() && _removedPoint.lng === vertex.lng()) {
              this.overlays[i].getPath().removeAt(j);
            }
          }
          for (let j = 0; j < length; j++) {
            const vertex = this.overlays[i].getPath().getAt(j);
            if (vertex && _removedPoint.lat === vertex.lat() && _removedPoint.lng === vertex.lng()) {
              this.overlays[i].getPath().removeAt(j);
            }
          }
        }
      }
      if (this.highlightedPoint) {
        this.highlightedPoint.setMap(null);
      }
      this.pointClicked.emit(false);
    }
  }

  highlightedPoint;

  @Input() selectedVoterIds = new Set<number>();

  voterMarkers: any[] = [];
  currentInfowindow = null;
  @Input("zones") zones: any[] = [];
  zonePolygons: any[] = [];
  zoneMarkers = [];

  @Input("turfs") turfs: any[] = [];
  turfPolygons: any[] = [];
  turfMarkers: any[] = [];

  @Input("turfData") turfData: any;
  turfInfowindow;
  drawingManager;

  zoneFeatures;

  constructor(private gmapsApi: GoogleMapsAPIWrapper, private datePipe: DatePipe, private ngZone: NgZone) { }
  ngOnInit() {
    this.gmapsApi.getNativeMap().then((map) => {
      this.map = map;
      this.map.setZoom(13);
      if (!this.turfData) {
        this.initDrawingManager();
      }
      google.maps.event.addListener(map, 'click', (event) => {
        if (this.highlightedPoint) {
          this.highlightedPoint.setMap(null);
        }
        if (this.currentInfowindow) {
          this.currentInfowindow.close();
        }
        if (this.turfInfowindow) {
          this.turfInfowindow.close();
        }
        this.pointClicked.emit(false);
      });

      this.showZone();
      this.zoomTurfs();
    });
  }

  initDrawingManager() {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
    });
    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //drawingManager.setDrawingMode(null);
          this.overlays.push(event.overlay);
          const newPolygon = event.overlay.getPath().getArray();
          google.maps.event.addListener(event.overlay, 'drag', (event) => {
            if (this.highlightedPoint) {
              this.highlightedPoint.setMap(null);
            }
          });
          google.maps.event.addListener(event.overlay, 'mouseover', (event) => {
            this.drawingManager.setDrawingMode(null);
          });
          google.maps.event.addListener(event.overlay, 'mouseout', (event) => {
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
          });
          google.maps.event.addListener(event.overlay, 'click', (event) => {
            if (event.vertex >= 0) {
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              this.pointClicked.emit({
                lat: lat,
                lng: lng
              });
              console.log('Clicked at: Lat ' + lat + ', Lng ' + lng);
              this.overlays.forEach(overlay => {
                overlay.getPath().forEach((point, index) => {
                  const pointLat = point.lat();
                  const pointLng = point.lng();
                  if (pointLat === lat && pointLng === lng) {
                    if (this.highlightedPoint) {
                      this.highlightedPoint.setMap(null);
                    }
                    this.highlightedPoint = new google.maps.Marker({
                      position: point,
                      map: this.map,
                      icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#00448D',
                        scale: 8,
                      }
                    });
                  }
                });
              });
            } else {
              if (this.highlightedPoint) {
                this.highlightedPoint.setMap(null);
              }
              this.pointClicked.emit(false);
            }
          });
          newPolygon.push(newPolygon[0]);
          this.polygons.push(newPolygon);
          this.polygonChanged.emit(this.polygons);
          if (this.drawingManager) {
            this.drawingManager.setMap(null);
          }
        }
      }
    );
  }

  clearPolygonList() {
    this.polygonList.forEach((p) => {
      p.setMap(null);
    });
    this.markers = [];
    this.markers.forEach((p) => {
      p.setMap(null);
    });
    this.markers = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["turfs"] && changes["turfs"].previousValue !== changes["turfs"].currentValue) {
      this.ngZone.runOutsideAngular(() => {
        this.zoomTurfs();
      });
    }
  }

  showZone() {
    if (this.zoneFeatures) {
      this.zoneFeatures.forEach(features => {
        features.forEach(feature => {
          this.map.data.remove(feature);
        })
      });
    }
    this.zoneFeatures = PolygonUtils.loadGeoJson(this.map, PolygonUtils.convertToGeoJsons(this.zones));
  }

  zoomTurfs() {
    this.turfPolygons.forEach((e) => {
      e.setMap(null);
    });
    this.turfMarkers.forEach((e) => {
      e.setMap(null);
    });
    this.turfPolygons = [];
    if (this.map && this.turfs && this.turfs.length) {
      let boundCoordinates = [];
      let mapCenter;

      _.forEach(this.turfs, (turf) => {
        const color = turf.color || '#28a745';
        const title = turf.title;
        if (turf.multiPolygons && turf.multiPolygons.length > 0) {
          turf.multiPolygons.forEach(polygon => {
            if (polygon.polygon && polygon.polygon.length > 0) {
              const arr = [];
              polygon.polygon.forEach(coordinate => {
                arr.push([coordinate.x, coordinate.y, color, title, turf])
              });
              boundCoordinates.push(arr);
            }
          });
        }
      });

      if (!_.isArray(boundCoordinates[0][0])) {
        const newArr = [];
        newArr.push(boundCoordinates);
        boundCoordinates = newArr;
      }
      const allBounds: any = new google.maps.LatLngBounds();
      _.forEach(boundCoordinates, (coordinates) => {
        const bounds: any = new google.maps.LatLngBounds();
        let color;
        let title;
        let turf;
        const bCoordinates = [];
        _.forEach(coordinates, (coordinate) => {
          bCoordinates.push(
            new google.maps.LatLng(coordinate[1], coordinate[0])
          );
          bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
          allBounds.extend(
            new google.maps.LatLng(coordinate[1], coordinate[0])
          );

          if (!color) {
            color = coordinate[2];
          }
          if (!title) {
            title = coordinate[3];
          }
          if (!turf) {
            turf = coordinate[4];
          }
        });
        const editable = this.turfData && this.turfData.id === turf.id;
        let zIndex = 99999;
        if (editable) {
          zIndex = 999999;
        }
        const polygon = new google.maps.Polygon({
          paths: bCoordinates,
          strokeColor: color || "#28a745",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: color || "#28a745",
          fillOpacity: 0.35,
          editable: editable,
          zIndex: zIndex
        });
        if (editable) {
          this.turfData.polygon = polygon;
        }
        let walker = '';
        if (turf.walker) {
          const turfWalker = JSON.parse(turf.walker);
          walker = turfWalker.map(t => t.value).join(', ');
        }
        let zoneName = '';
        if (turf.zones) {
          zoneName = turf.zones.map(t => t.title).join(', ');
        }
        let contentString = `
        <div id="content" style="opacity: 0.9; filter: alpha(opacity=90);">
          <b style="font-weight: 600;">${turf.title}</b><br>
          <b>Total Voter: ${turf.voterIds ? turf.voterIds.length : 0}</b><br>
          <b>Zone: ${zoneName}</b><br>
          <b>Visited voter: ${turf.voters ? turf.voters.filter(v => v.status === 'Completed').length : 0}</b><br>
          <b>Walker(s): ${walker}</b><br>
        </div>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: "Uluru",
        });
        google.maps.event.addListener(polygon, 'click', () => {
          if (this.turfInfowindow) {
            this.turfInfowindow.close();
          }
          infowindow.open({
            anchor: marker,
            map: this.map as any
          });
          this.turfInfowindow = infowindow;
        });
        // google.maps.event.addListener(polygon, 'mouseout', () => {
        //   if (this.turfInfowindow) {
        //     this.turfInfowindow.close();
        //   }
        // });
        this.turfPolygons.push(polygon);
        this.turfPolygons[this.turfPolygons.length - 1].setMap(this.map);
        const center = bounds.getCenter();
        if (editable) {
          mapCenter = center;
        }
        const marker = new google.maps.Marker({
          position: { lat: center.lat(), lng: center.lng() },
          map: this.map as any,
          label: {
            text: title || " ",
            color: "#000000",
            fontSize: "16px",
            fontWeight: "bold",
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0,
          },
        });
        this.turfMarkers.push(marker);
      });
      if (mapCenter && this.turfData) {
        this.map.fitBounds(allBounds);
        const targetLocation = new google.maps.LatLng(mapCenter.lat(), mapCenter.lng());
        this.map.setZoom(19);
        this.map.panTo(targetLocation);
      }
    }
  }
}
