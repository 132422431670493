import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../payroll-setting/model/agency.model';
import { RoleLevel } from '../../../layout/component/app-menu/role-level';
import { User } from '../../../shared/model/user/user.model';
import { AgencyService } from '../../../agency/service';
import { CrmCommService } from '../../../crm/service/crm-comm.service';
import { PaymentInvoiceService } from '../../service/payment-invoice.service';
import { PaymentInvoice } from '../../model/payment-invoice.model';
import { PhonePackageService } from '../../../phonesystem/service/phone-package.service';
import { PhonePackage } from '../../../phonesystem/model/phone-package.model';
import { Subject } from 'rxjs/Subject';
import { interval } from 'rxjs/observable/interval';
import { takeUntil } from 'rxjs/operators';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { ClientService } from '../../../crm/service/client.service';
import { ProductService } from '../../../crm/service/product.service';
import { DatePipe } from '@angular/common';
import { TransactionDetailsService } from 'app/transaction-type/service/transaction-details.service';
import { TransactionDetail } from 'app/transaction-type/model/transaction-detail.model';
import {TranslatePipe} from '@ngx-translate/core';
import * as moment from 'moment';
import { FollowUpBillingInvoiceService } from 'app/payment/service/follow-up-billing.service';
import { FollowUpBillingInvoice } from 'app/payment/model/follow-up-billing-invoice.model';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';

@Component({
  selector: 'app-payment-old-invoice-list',
  templateUrl: './payment-old-invoice-list.component.html',
  styleUrls: ['./payment-old-invoice-list.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService,
    PhonePackageService, TransactionDetailsService,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class PaymentOldInvoiceListComponent implements OnInit, OnDestroy {
  detailEnable = false;
  contentList: PaymentInvoice[] = [];
  contentTransactionList: TransactionDetail[] = [];
  selectedContent: PaymentInvoice = new PaymentInvoice();
  invoiceDetail: PaymentInvoice = new PaymentInvoice();
  details = [];
  totalRecords: Number = 0;
  items: MenuItem[];
  msgs: Message[] = [];

  private destroyed$ = new Subject<void>();
  loading = false;
  tableRows = 10;
  sumFee = 0;
  balance = 0;
  balanceTxt = '';
  sumMinFee = 0;
  sumTax = 0;
  sumTotalAmount = 0;
  sumFeeTxt: string;
  sumMinFeeTxt: string;
  sumTaxTxt: string;
  sumTotalAmountTxt: string;
  sumOutStandingBalanceTxt: string;
  sumOutStandingBalance = 0;
  sumPaidFee = 0;
  sumPaidFeeTxt: string;
  @ViewChild('dt', { static: true }) table: Table;
  userPermission: Boolean;
  rowData: any;
  allCompanyList: any = [];
  allAgencyList: any = [];
  phonePackages: PhonePackage[];
  invoiceNumber: string;
  searchText: string;
  selectedCompany: Company;
  selectedAgency: Agency;
  selectedPhonePackage: PhonePackage;
  selectedStatus: string;
  user: User;
  loggedInRole: number;
  disableCompany = true;
  disableAgency = false;
  fromDate: Date;
  toDate: Date;
  rangeDates: any;
  statusTypes: any[] = [];
  invoiceFilter: any;
  enabledGenerate = false;
  rowsPerPageOptions: any[];
  rowsPerPageItems: SelectItem[];
  loadingSpinner = false;
  page = 0;
  rows = 10;
  size: number;
  sortF: any = '';

  cols: any[] = [
    { field: 'invoiceNumber', label: 'Invoice #', sortOptions: '', sort: 'text' },
    { field: 'invoiceDate', label: 'Invoice Date', sortOptions: '', sort: 'number' },
    { field: 'clientName', label: 'Client/Base', sortOptions: '', sort: 'text' },
    { field: 'totalAmountTxt', label: 'Paid Amount', sortOptions: '', sort: 'number' },
    { field: 'feeTxt', label: 'Service Fee', sortOptions: '', sort: 'number' },
    { field: 'paidFeeTxt', label: 'Service Fee paid', sortOptions: '', sort: 'number' },
    { field: 'balanceTxt', label: 'Outstanding balance', sortOptions: '', sort: 'number' },
    { field: 'inactivedBy', label: 'By', sortOptions: '', sort: 'text' },
    { field: 'inactivedDate', label: 'On', sortOptions: '', sort: 'number' },
    { field: 'inactiveReason', label: 'Inactive Reason', sortOptions: '', sort: 'text' },
    { field: 'prevStage', label: 'Prev Stage', sortOptions: '', sort: 'text' },
    { field: 'followUps', label: 'Follow ups', sortOptions: '', sort: 'text' }
  ];
  sortField: string;
  sortOrder: number;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedSortOption = '';
  selectedField = '';
  allClientList: any = [];
  clientList: any = [];
  selectedClient;
  productList = [];
  isPlatformAdmin = false;
  disableFields = false;

  collectionTypeOptions = [
    { label: 'Follow-up', value: 'Follow-up' },
    { label: 'Payment Refusal', value: 'Payment Refusal' },
    { label: 'Delay Payment Requested', value: 'Delay Payment Requested' }
  ];
  
  contacts = [
    { label: 'Phone', value: 'Phone Call' },
    { label: 'SMS', value: 'Text Message' },
    { label: 'Email', value: 'Email' },
    { label: 'In-Person', value: 'In Person Contact' }
  ];
  
  isOverTime: boolean;
  isOwnerNote: boolean;
  now = new Date();
  paymentInvoiceData: any = {};
  isNew = false;
  followUpDate: any;
  headerNote = 'Collection Note';
  selectedCollectionPresentative: any;
  selectedContact = '';
  followUpNote: any;
  collectionTypeSelected;
  followUpCount;
  followUpInvoiceDueDate;
  disabledExtendDueDate;
  charactersLength = 0;
  isOpenCollectionNote = false;
  timespent = new Date();

  assignedToEmployeeList: any = [];
  assignedToEmployeeMentionList: any = [];
  employeeList: any = [];

  @ViewChild('notesEditor', { static: true })
  concernEditor: any;
  
  MAX_LENGTH = 500;
  followUpNoteEditor: any;
  isSaveCollectionNote: boolean = false;
  
  filteredOldInvoices: any[];

  constructor(
    private phonePackageService: PhonePackageService,
    private crmCommService: CrmCommService,
    private contentService: PaymentInvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translatePipe: TranslatePipe,
    private agencyService: AgencyService,
    private dropDownsService: DropDownsService,
    private transactionDetailsService: TransactionDetailsService,
    private messageService: MessageService,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private productService: ProductService,
    private followUpBillingInvoiceService: FollowUpBillingInvoiceService,
    private browserNotificationService: BrowserNotificationService,
    private operatorService: OperatorService,
  ) {
    this.user = this.authService.getUserInfo();
  }

  ngOnInit() {
    this.loggedInRole = this.authService.getRoleLevel();
    if (this.loggedInRole === RoleLevel.ROLE_CLIENT) {
      this.disableFields = true;
    }
    this.rowsPerPageOptions = [5, 10, 20];
    this.rowsPerPageItems = [];
    this.rowsPerPageOptions.forEach(rowPerPage => {
      this.rowsPerPageItems.push({ label: String(rowPerPage), value: rowPerPage });
    });
    this.statusTypes = [{ label: 'All', value: '' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Paid', value: 'Paid' }
    ];
    this.items = [
      { label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewContent(this.selectedContent) },
      { label: 'View', icon: 'fa-solid fa-xmark text-danger', command: (event) => this.deleteContent(this.selectedContent) }
    ];
    this.contentList = [];
    this.userPermission = this.authService.adminRole();
    this.searchText = '';
    this.loadAllCompanyList();
    this.loadAllClient();
    this.loadEmployeeDropDown();
    this.enabledGenerate = false;
    this.disableAgency = true;
    this.disableCompany = true;

    if (this.loggedInRole === RoleLevel.ROLE_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_COMPANY_ADMIN) {
      this.disableCompany = true;
      this.disableAgency = false;
    }

    if (this.loggedInRole === RoleLevel.ROLE_AGENCY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_AGENCY_ADMIN) {
      this.disableAgency = true;
      this.disableCompany = true;
    }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin) {
      this.cols = [
        { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
        { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number' },
        { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
        { field: 'companyName', label: 'companyLabel', sortOptions: '', sort: 'text' },
        { field: 'totalAmount', label: 'Amount', sortOptions: '', sort: 'number' },
        { field: 'fee', label: 'Fee', sortOptions: '', sort: 'number' },
        { field: 'serviceFeePaid', label: 'Paid', sortOptions: '', sort: 'number' },
        { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
        { field: 'inactiveBy', label: 'Archived', sortOptions: '', sort: 'text' },
        { field: 'inactiveDate', label: 'Timestamp', sortOptions: '', sort: 'number' },
        { field: 'inactiveReason', label: 'Inactive Reason', sortOptions: '', sort: 'text' },
        { field: 'followUps', label: 'Follow ups', sortOptions: '', sort: 'text' }
      ];
    }
    if (this.loggedInRole === RoleLevel.ROLE_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_ADMIN) {
      this.disableAgency = false;
      this.disableCompany = false;
    }
    this.invoiceFilter = {
      fromDate: null,
      toDate: null,
      page: 0,
      size: 10,
      search: '',
      agencyId: 0,
      companyId: 0,
      packageId: 0,
      status: '',
      invoiceNumber: '',
      sort: null
    };
    this.detailEnable = false;
    // this.subscribeMessage();
    this.search();
  }
  getAllAddedProductsByClientId(clientId: any) {
    const options: any = {
      page: 0, size: 99999, sort: 'id,desc',
    };
    options.clientId = this.selectedClient;
    options.crmClientIds = [this.selectedClient];
    this.productService.getAllProductsByClient(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.productList = resObj.data.content;
      }
    });
  }
  loadAllClient() {
    const options: any = { size: 99999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data.map(c => ({ value: c.key, label: c.value }));
        this.clientList = resObj.data;
      }
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  loadPackageBasedOnCompanyId() {
    this.phonePackageService.getAllList({ companyId: this.selectedCompany }).subscribe((res) => {
      const resObj: any = res;
      console.log(resObj);
      if (resObj.status === 'SUCCESS') {
        this.phonePackages = resObj.data;
      }
    });
    this.detailEnable = false;
  }
  changeStatus(event) {
    this.selectedStatus = event.status;
  }
  viewContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'view', c.id]);
  }

  deleteContent(c: PaymentInvoice) {
    this.contentService.delete(c.id).subscribe((_response) => {
      this.search();
    }, (error) => {
      console.log(error);
    });
  }

  getDetail(detail: PaymentInvoice) {
    this.invoiceDetail = new PaymentInvoice();
    this.invoiceDetail = detail;
    this.details = [];
    this.details = [{ name: 'Package Fee', value: detail.fee },
    { name: 'Minutes Fee', value: detail.minuteFee },
    { name: 'Taxes', value: detail.taxes }];
    this.detailEnable = true;
  }

  editContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'edit', c.id]);
  }

  paginate(event) {
    const first = event.first;
    const rows = event.rows;
    this.sumFee = 0;
    this.sumMinFee = 0;
    this.sumTax = 0;
    this.sumTotalAmount = 0;
    this.sumOutStandingBalance = 0;
    this.sumPaidFee = 0;
    const newList = this.contentList.slice(first, first + rows);
    newList.forEach(invoice => {
      const paidFee = invoice.paidFee ? invoice.paidFee : 0;
      invoice.paidFeeTxt = paidFee.toFixed(2);
      invoice.feeTxt = invoice.fee.toFixed(2);
      invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
      this.sumFee = this.sumFee + invoice.fee;
      this.sumTotalAmount = this.sumTotalAmount + invoice.totalAmount;
      this.balance = invoice.fee - paidFee;
      invoice.balanceTxt = this.balance.toFixed(2);
      this.sumOutStandingBalance += this.balance;
      this.sumPaidFee += paidFee;
    });
    this.sumFeeTxt = this.sumFee.toFixed(2);
    this.sumPaidFeeTxt = this.sumPaidFee.toFixed(2);
    this.sumOutStandingBalanceTxt = this.sumOutStandingBalance.toFixed(2);
    this.sumTotalAmountTxt = this.sumTotalAmount.toFixed(2);
    this.loading = false;
  }

  public loadContentsLazy(event?: any) {
    this.loading = true;
    this.detailEnable = false;
    if (event.originalEvent !== 'MouseEvent') {
      this.page = (event.first) / event.rows;

      this.sortField = this.selectedField;
      this.rows = event.rows;
    }
    // this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
    const order = this.selectedSortOption;
    // this.sortOrder === 1 ? 'ASC' : 'DESC';
    this.invoiceFilter.page = this.page;
    this.invoiceFilter.size = this.rows;
    if (this.sortField && order) {
      this.invoiceFilter.sort = this.sortField + ',' + order;
    }

    this.loadPage(this.invoiceFilter);
  }
  cancelDialog() {
    this.detailEnable = false;
  }
  resetFilters() {
    this.invoiceNumber = null;
    this.selectedClient = null;
    this.selectedStatus = null;
    this.searchText = null;
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.fromDate = null;
    this.toDate = null;
    this.search();
  }
  search(event?: any) {
    this.table.first = 0;
    const options = <any>{
      page: 0,
      size: 9999
    };
    // this.size = event ? event.rows : this.size ? this.size : 10;
    // this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    // const options = <any>{
    //   page: this.page,
    //   size: this.size
    // };
    this.invoiceFilter.page = 0;
    this.invoiceFilter.size = this.table.rows;
    // console.log(event)
    if (event === this.searchText) {
      options.search = this.searchText;
    }

    if (this.invoiceNumber) {
      options.invoiceNumber = this.invoiceNumber;
    }
    if (this.selectedStatus) {
      options.status = this.selectedStatus;
    }

    if (this.user.companyId) { options.companyId = this.user.companyId; }
    if (this.selectedCompany != null) {
      options.companyId = this.selectedCompany;
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient;
    }

    if (this.fromDate) { options.fromDate = this.fromDate; }
    if (this.toDate) { options.toDate = this.toDate; }
    this.detailEnable = false;

    this.loadPage(options);

  }

  generateInvoice() {
    this.loadingSpinner = true;

    const startDate = new Date(this.fromDate);
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date(this.toDate);
    endDate.setDate(endDate.getDate() - 30);
    this.contentService.generateGenerate({ fromDate: startDate, toDate: endDate }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        setTimeout(() => {
          this.loadingSpinner = false;
        }, 1000);
        this.contentService.notifyMessage(
          { severity: 'info', summary: this.translatePipe.transform('Generate'), detail: this.translatePipe.transform('Generate Invoice successfully')});
        this.loadPage(this.invoiceFilter);
      }
    });
  }

  searchBySelectedDate(date) {
    if (date.startDate) {
      this.fromDate = date.startDate;
    }
    if (date.endDate) {
      this.toDate = date.endDate;
    }
  }

  loadPage(options) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    this.loading = true;
    this.contentList = [];
    options.oldInvoiceOnly = true;
    const invoiceTopBarInfoString =  localStorage.getItem('top-bar-invoice-number');
    if (invoiceTopBarInfoString) {
      const invoiceTopBarInfo = JSON.parse(invoiceTopBarInfoString);
      options.clientId = invoiceTopBarInfo.clientId;
      this.searchText = invoiceTopBarInfo.invoiceNumber;
      this.selectedClient = invoiceTopBarInfo.clientId;
      localStorage.removeItem('top-bar-invoice-number');
    }
    this.contentService.filter(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.filteredOldInvoices = resObj.data.content;
        
        this.sumFee = 0;
        this.sumMinFee = 0;
        this.sumTax = 0;
        this.sumTotalAmount = 0;
        this.sumOutStandingBalance = 0;
        this.sumPaidFee = 0;
        for (const invoice of this.filteredOldInvoices) {
          const company = this.allCompanyList.find(com => com.value === invoice.companyId);
          const client = this.allClientList.find(com => com.value === invoice.clientId);
          if (company) {
            invoice.companyName = company.label;
          }
          if (client) {
            invoice.clientName = client.label;
          }

          if (invoice.invoiceDate) {
            invoice.invoiceDate = this.datePipe.transform(invoice.invoiceDate, 'MM/dd/yyyy')
          }

          if (invoice.inactivedDate) {
            invoice.inactivedDate = this.datePipe.transform(invoice.inactivedDate, 'MM/dd/yyyy HH:mm:ss')
          }

          let isMatch = true;
          if (this.searchText) {
            isMatch = (invoice.clientName != null && invoice.clientName.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.invoiceNumber != null && invoice.invoiceNumber.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.paymentMethod != null && invoice.paymentMethod.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.totalAmountTxt != null && invoice.totalAmountTxt.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.paidFeeTxt != null && invoice.paidFeeTxt.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.feeTxt != null && invoice.feeTxt.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0)
            || (invoice.balanceTxt != null && invoice.balanceTxt.toString().toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0);
          }

          if (!isMatch) {
            continue;
          }

          const paidFee = invoice.paidFee ? invoice.paidFee : 0;
          invoice.paidFeeTxt = paidFee.toFixed(2);
          invoice.feeTxt = invoice.fee.toFixed(2);
          invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);

          if (invoice.totalAmount) {
            invoice.totalAmountTxt1 = formatter.format(invoice.totalAmount);
          } else {
            invoice.totalAmountTxt1 = '$0.00';
          }
          if (invoice.fee) {
            invoice.feeTxt1 = formatter.format(invoice.fee);
          }

          if (paidFee) {
            invoice.paidFeeTxt1 = formatter.format(paidFee);
          }

          this.sumFee = this.sumFee + invoice.fee;
          this.sumTotalAmount = this.sumTotalAmount + invoice.totalAmount;
          this.balance = invoice.fee - paidFee;
          invoice.balanceTxt = this.balance.toFixed(2);

          if (this.balance) {
            invoice.balanceTxt1 = formatter.format(this.balance);
          }

          this.sumOutStandingBalance += this.balance;
          this.sumPaidFee += paidFee;

          const length = invoice.followUpBillingInvoices ? invoice.followUpBillingInvoices.length : 0;
          if (invoice.followUpBillingInvoices && length > 0) {
            invoice.followUpNoteDate = invoice.followUpBillingInvoices[length - 1].createdAt;
            invoice.numOfFollowUpNote = length;
          }
          
          if (invoice.followUpBillingInvoices && invoice.followUpBillingInvoices.length > 0) {
            for (const followUpItem of invoice.followUpBillingInvoices) {
              if (followUpItem.collectionType === 'Payment Refusal') {
                invoice.inactiveReason = followUpItem.collectionType;
                break;
              }
            }
          }
        }

        this.contentList = this.filteredOldInvoices;
        const option = {
          first: this.invoiceFilter.page,
          rows: this.invoiceFilter.size
        };
        this.paginate(option);
        // this.sumFeeTxt = this.sumFee.toFixed(2);
        // this.sumPaidFeeTxt = this.sumPaidFee.toFixed(2);
        // this.sumOutStandingBalanceTxt =  this.sumOutStandingBalance.toFixed(2);
        // this.sumTotalAmountTxt = this.sumTotalAmount.toFixed(2);

        this.totalRecords = resObj.data.totalElements;
      }
    });
  }

  exportReportPdf() {
      const data: any = {
        productPrices: this.productList,
        clientId: this.selectedClient
      };
      if (this.selectedCompany != null) {
        data.companyId = this.selectedCompany;
      }
      if (!(this.authService.isSuper() || this.authService.isSubSuper())) {
        data.companyId = this.authService.getCurrentCompanyId();
      }
      this.contentService.exportReportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
  }

  exportPdf(invoice) {
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.contentService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
    }
  }

  loadAllCompanyList() {
    const pafc = this;
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        // this.allCompanyList = resObj.data;
        resObj.data.forEach(rs => {
          this.allCompanyList.push({ value: rs.key, label: rs.value });
        });
        if (this.user.companyId) {
          const company = this.allCompanyList.find(c => c.value === this.user.companyId);
          this.selectedCompany = company.value;
        }
      }
    });
  }

  loadAgencyBasedOnCompanyId() {
    const pafc = this;
    this.dropDownsService.getAllAgencyByCompanyId(this.selectedCompany).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allAgencyList = resObj.data;
      }
    });
  }
  sortDataSelected(selectedSortOption, event: LazyLoadEvent) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.contentList
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1;
          let value2;

          if (event.field === 'totalAmountTxt' || event.field === 'feeTxt' || event.field === 'paidFeeTxt' || event.field === 'balanceTxt') {
            value1 = +(data1[event.field] || '0');
            value2 = +(data2[event.field] || '0');
          } else if (event.field === 'invoiceDate') {
            value1 = data1[event.field] ? moment(data1[event.field], 'MM/DD/YYYY').toDate() : null;
            value2 = data2[event.field] ? moment(data2[event.field], 'MM/DD/YYYY').toDate() : null;
          } else if (event.field === 'inactivedDate') {
            value1 = data1[event.field] ? moment(data1[event.field], 'MM/DD/YYYY HH:mm:ss').toDate() : null;
            value2 = data2[event.field] ? moment(data2[event.field], 'MM/DD/YYYY HH:mm:ss').toDate() : null;
          } else {
            value1 = data1[event.field];
            value2 = data2[event.field];
          }
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }
  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  exportTablePdf() {
    if (this.contentList && this.contentList.length) {
      const data: any = {
        contentList: this.contentList.map(value => {
          return {
            invoiceNumber: value.invoiceNumber,
            invoiceDate: this.datePipe.transform(value.invoiceDate, 'MM/dd/yyyy'),
            clientName: value.clientName,
            companyName: value.companyName,
            totalAmount: value.totalAmount,
            feeTxt: value.feeTxt,
            paidFeeTxt: value.paidFeeTxt,
            balance: value.feeTxt,
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompanyId();
        data.includeCompany = false;
      } else {
        data.companyId = this.selectedCompany;
        data.includeCompany = true;
      }
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      data.username = this.authService.getCurrentUsername();
      this.contentService.exportTablePdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'), detail: this.translatePipe.transform('Problem exporting client pdf list')});
        }
      });
    }
  }

  async loadEmployeeDropDown() {
    const options: any = {};
    options.companyId = this.authService.getCurrentCompanyId();
    options.status = 1;
    this.employeeList = [];
    const employee = await this.dropDownsService.getAllEmployeeList(options).toPromise();
    this.assignedToEmployeeList = [];
    this.assignedToEmployeeMentionList = [];
    if (employee.data.length > 0) {
      this.employeeList = employee.data;
      employee.data.forEach((item) => {
        const nm = item.value;
        this.assignedToEmployeeList.push({
          label: nm,
          value: nm
        });
      });

      this.assignedToEmployeeMentionList = Object.assign({}, this.assignedToEmployeeList);
    }
  }

  isEnableFollowUps() {
    if (this.authService.isEmployeeRole()) {
      return this.authService.getLoggedInUserEditListRoleEmployee().includes('1014');
    } else {
      return this.authService.getLoggedInUserEditList().includes('1014');
    }
  }

  isOver24hrs(createdDate) {
    const currentTime = moment().tz('America/New_York');
    return currentTime.diff(createdDate, 'days') > 0;
  }
  
  textChanged1(event) {
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.charactersLength = this.concernEditor.getLength() - 1;
      return;
    } else {
      this.charactersLength = this.concernEditor.getLength() - 1;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.followUpNote.deleteText(this.MAX_LENGTH - 1, this.concernEditor.getLength());
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit')});
    }
    this.charactersLength = this.concernEditor.getLength() - 1;
  }

  setEditor(event: any) {
    event.editor.root.focus();
    this.concernEditor = event.editor;
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.followUpNote = this.followUpNote.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.followUpNote = this.followUpNote.replace(regexTag2, '<strong>$1$2</strong>$3');
      setTimeout(() => {
        this.followUpNoteEditor.getQuill().setSelection(this.followUpNoteEditor.value.length);
      }, 100);

    }, 500);
  }

  customerPopup(event: any) {
  }

  
  openDialog(data?: any, event?: any, isNew?: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }
    this.isOverTime = false;
    this.isOwnerNote = true;
    this.now = new Date();
    this.paymentInvoiceData = data;
    this.isNew = isNew;
    this.followUpDate = this.now;
    this.headerNote = 'Collection Note';
    if (!isNew) {
      this.headerNote = 'Update Notes';
    }
    if (data.followUpBillingInvoices && data.followUpBillingInvoices.length > 0 && !isNew) {
      const length = data.followUpBillingInvoices.length;
      this.selectedCollectionPresentative = data.followUpBillingInvoices[length - 1].collectionRepresentative;
      this.selectedContact = data.followUpBillingInvoices[length - 1].methodContact;
      this.followUpNote = data.followUpBillingInvoices[length - 1].note;
      this.followUpDate = data.followUpBillingInvoices[length - 1].createdAt;
      this.collectionTypeSelected = data.followUpBillingInvoices[length - 1].collectionType;

      this.isOverTime = this.isOver24hrs(data.followUpBillingInvoices[length - 1].createdAt);
      this.isOwnerNote = this.authService.getCurrentLoggedInName() === data.followUpBillingInvoices[length - 1].createdByUsr;
    } else {
      if (this.authService.isEmployeeRole()) {
        this.selectedCollectionPresentative = this.authService.getCurrentUsername();
      } else {
        this.selectedCollectionPresentative = null;
      }
      this.selectedContact = '';
      this.followUpNote = '';
    }
    this.followUpCount = data.extendDueDateCount ? data.extendDueDateCount : 0;
    this.followUpInvoiceDueDate = new Date(data.dueDate);
    this.disabledExtendDueDate = data.disabledExtendDueDate;

    this.charactersLength = this.followUpNote ? this.followUpNote.replace(/<\/?[^>]+(>|$)/g, '').length : 0;
    this.isOpenCollectionNote = true;
    this.timespent = new Date();

  }

  closeDialog() {
    this.isOpenCollectionNote = false;
    this.paymentInvoiceData = {};
  }

  saveCollectionNote(data?: any, event?: any) {
    this.isSaveCollectionNote = true;
    const followUpBilling = new FollowUpBillingInvoice();
    followUpBilling.collectionRepresentative = this.selectedCollectionPresentative;
    followUpBilling.methodContact = this.selectedContact;
    followUpBilling.note = this.followUpNote;
    followUpBilling.invoiceId = this.paymentInvoiceData.id;
    followUpBilling.invoiceNumber = this.paymentInvoiceData.invoiceNumber;
    followUpBilling.clientName = this.paymentInvoiceData.clientName;
    followUpBilling.companyId = this.paymentInvoiceData.companyId;
    followUpBilling.collectionType = this.collectionTypeSelected;
    followUpBilling.invoiceDueDate = this.followUpInvoiceDueDate;
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Please add at least 25 characters')});
      this.isSaveCollectionNote = false;
      return;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit')});
      return;
    }
    if (this.paymentInvoiceData.followUpBillingInvoices && this.paymentInvoiceData.followUpBillingInvoices.length > 0 && !this.isNew) {
      const length = this.paymentInvoiceData.followUpBillingInvoices.length;
      followUpBilling.id = this.paymentInvoiceData.followUpBillingInvoices[length - 1].id;
      followUpBilling.createdAt = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdAt;
      followUpBilling.createdByUsr = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdByUsr;
      followUpBilling.lastModifiedBy = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.update(followUpBilling, this.paymentInvoiceData.followUpBillingInvoices[length - 1].id).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        this.isSaveCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Update Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been updated successfully!')});

          this.contentList.forEach(element => {
            if (element.id === this.paymentInvoiceData.id) {
              element.followUpBillingInvoices[length - 1] = resObj.data;
              if (this.collectionTypeSelected === 'Delay Payment Requested' && moment(this.followUpInvoiceDueDate).diff(moment(element.dueDate), 'days') !== 0) {
                element.extendDueDateCount = this.followUpCount + 1;
                element.dueDate = moment(this.followUpInvoiceDueDate).toDate();
                if (element.extendDueDateCount > 2) {
                  element.disabledExtendDueDate = true;
                }
              }
            }
          });
          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
      }, (error) => {
        this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message});
        this.isSaveCollectionNote = false;
      });
    } else {
      followUpBilling.createdByUsr = this.authService.getCurrentLoggedInName();
      this.followUpBillingInvoiceService.create(followUpBilling).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        this.isSaveCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.contentList.forEach(element => {
            if (element.id === this.paymentInvoiceData.id) {
              element.followUpBillingInvoices.push(resObj.data);
              const length = element.followUpBillingInvoices ? element.followUpBillingInvoices.length : 0;
              element.followUpNoteDate = element.followUpBillingInvoices[length - 1].createdAt,
                // element.numOfFollowUpNote = '(' + length + ')';
              element.numOfFollowUpNote = length;
              if (this.collectionTypeSelected === 'Delay Payment Requested' && moment(this.followUpInvoiceDueDate).diff(moment(element.dueDate), 'days') !== 0) {
                element.extendDueDateCount = this.followUpCount + 1;
                element.dueDate = moment(this.followUpInvoiceDueDate).toDate();
                if (element.extendDueDateCount > 2) {
                  element.disabledExtendDueDate = true;
                }
              }


            }
          });

          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Add Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been created successfully!')});
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
        // this.search();
      }, (error) => {
        this.messageService.add({severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message});
        this.isSaveCollectionNote = false;
      });
    }
    // this.search();
  }

  async notifyMentionEmployee(details: string, followNoteId: number, followUpBilling: any) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    if (!employeeList || employeeList.length < 1) return
    for (const employee of employeeList) {
      const key = this.employeeList.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#/app/payments/follow-up-note/' + followNoteId;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
        // add the bell notification
        const bellNotification: any = {};
        bellNotification.sourceId = followNoteId;
        bellNotification.targetTableName = 'employee';
        bellNotification.targetRoleId = 7;
        bellNotification.targetId = key;
        bellNotification.message = details;
        bellNotification.type = 'follow_up_note';
        bellNotification.tableName = 'follow_up_billing';
        bellNotification.projectId = this.authService.getCurrentCompanyId();
        bellNotification.createdByUsr = this.authService.getCurrentLoggedInName();
        bellNotification.urlLink = '/app/payments/follow-up-note/' + followNoteId;
        await this.operatorService.addBellNotification(bellNotification).toPromise();
        // send email
        const emailData: any = {};
        emailData.employeeId = key;
        emailData.methodContact = followUpBilling.methodContact;
        emailData.collectionRepresentation = followUpBilling.collectionRepresentative;
        emailData.clientName = followUpBilling.clientName;
        emailData.invoiceNumber = followUpBilling.invoiceNumber;
        emailData.note = details.replace(/<[^>]*>/g, '');
        await this.followUpBillingInvoiceService.sendFollowUpTagEmail(emailData).toPromise();
      }
    }
  }

  redirectToFollowUptUrl(rowData: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }
    localStorage.removeItem('follow-up-note-number');
    localStorage.setItem('follow-up-note-number', rowData.invoiceNumber);
    this.router.navigate(['/app/payments/follow-up-note']);
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  searchInput() {
    if (this.searchText) {
        this.filterOldInvoice();
    } else {
        this.contentList = this.filteredOldInvoices;
        this.totalRecords = this.contentList ? this.contentList.length : 0;
    }
  }

  filterOldInvoice() {
    this.contentList = this.filteredOldInvoices.filter((event: any) => {
      return (this.filterInvoiceNumber(event) || this.filterInvoiceDate(event) || this.filterClientName(event) || this.filterCompanyName(event)
              || this.filterTotalAmount(event) || this.filterServiceFee(event) || this.filterPaidFee(event) || this.filterBalance(event)
              || this.filterInactivedBy(event) || this.filterInactivedDate(event) || this.filterInactiveReason(event));
    });
    this.totalRecords = this.contentList ? this.contentList.length : 0;
  }

  filterInvoiceNumber(event) {
    return event.invoiceNumber != null && !(event.invoiceNumber.search(RegExp(this.searchText, 'i')) === -1);
  }
  
  filterInvoiceDate(event) {
    return event.invoiceDate != null && !(event.invoiceDate.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterClientName(event) {
    return event.clientName != null && !(event.clientName.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterCompanyName(event) {
    return event.companyName != null && !(event.companyName.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterTotalAmount(event) {
    return event.totalAmountTxt1 != null && !(event.totalAmountTxt1.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterServiceFee(event) {
    return event.feeTxt1 != null && !(event.feeTxt1.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterPaidFee(event) {
    return event.paidFeeTxt1 != null && !(event.paidFeeTxt1.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterBalance(event) {
    return event.balanceTxt1 != null && !(event.balanceTxt1.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterInactivedBy(event) {
    return event.inactivedBy != null && !(event.inactivedBy.search(RegExp(this.searchText, 'i')) === -1);
  }
  filterInactivedDate(event) {
    return event.inactivedDate != null && !(event.inactivedDate.search(RegExp(this.searchText, 'i')) === -1);
  }

  filterInactiveReason(event) {
    return event.inactiveReason != null && !(event.inactiveReason.search(RegExp(this.searchText, 'i')) === -1);
  }

}
