import {Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {PaymentProfileService} from '../../../payment/service/payment-profile.service';
import {MessageService} from 'primeng';
import {PaymentInvoiceService} from '../../../payment/service/payment-invoice.service';
import {PaymentTransactionService} from '../../../payment/service/payment-transaction.service';
import {TransactionService} from 'app/transaction-type/service/transaction.service';
import {ProjectService} from 'app/project/service/project.service';
import {AccountService} from 'app/account/service/account.service';
import {TransactionTypeService} from 'app/transaction-type/service/transaction-type.service';
import {PaymentCardDefaultService} from '../../../payment/service/payment-card-default.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Subscription } from 'rxjs';
import { NgxCaptureService } from 'ngx-capture';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { PaymentNotificationService } from 'app/payment/service/payment-notification.service';
import { FirebaseNotificationService } from 'app/shared/service/firebase-notification.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { debounceTime } from 'rxjs/operators';
import { PayrollSettingService } from 'app/payroll-setting/service/payroll-setting.service';
import { QuoteService } from 'app/quote/service/quote.service';
import { buildSurveyLandPdfFile, Quote } from 'app/quote/model/quote.model';
import { CompanyService } from 'app/company/service/company.service';
import { LanderService } from 'app/lander/service/lander.service';
import moment, * as MOMENT from "moment";
import { TransactionDetailsService } from 'app/ar-report/services/transaction-details.service';
import {TranslatePipe} from '@ngx-translate/core';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';

@Component({
    selector: 'app-company-payment-details',
    templateUrl: './company-payment-details.component.html',
    styleUrls: ['./company-payment-details.component.scss'],
    providers: [TransactionService, ProjectService, 
        AccountService, TransactionTypeService, PayrollSettingService, 
        QuoteService, CompanyService, DocumentsService, LanderService, TransactionDetailsService]
})
export class CompanyPaymentDetailsComponent implements OnInit {

    @Input()
    cardType;
    @Input()
    invoices;

    @Input()
    cardNumber;
    @Input()
    cardTokenCode;
    @Input()
    registerCustomerPaymentId;
    @Input()
    cardToken;

    @Input()
    isStored;

    @Output()
    displayChange = new EventEmitter();

    @Input()
    selectedInvoices;

    @Input()
    functionImport;

    @Input()
    dataImport;

    @Input()
    planTypeImport;
    planTypeName: any;
    smsAddCount = 0;
    paymentForm: UntypedFormGroup;
    isMatched = false
    totalInvoices = 0;
    isCharged = false;
    amountValue;
    balanceValue;
    invoiceNumbers;
    confirmedData: any = {};
    invoicesList = [];
    msgs = [];
    selectedInvoicesForCal = [];
    selectedInvoicesTotalAmount: any = 0;
    valueForCalBalanceTotalAmount: any = 0;
    selectedInvoicesTotalAmountTemp: any = 0;
    setFlagForPaymentMenu = false;
    userCardList: any[];
    selectedCard: any = {id: null};
    selectedAccount: any = {id: null, bankId: null};
    accountNumber: any;
    payEnable = false;
    cardButton = true;
    isPlatformAdmin = false;
    companyId: number;
    selectedProject: number;
    accounts: any[];
    txnType: any;
    autoPay: any;
    selectedPaymentTransaction;
    requestFilter: any;
    contentList = [];
    showConfirmCardDialog = false;
    showConfirmDialog = false;
    showConditionDialog = false;
    isSelectedCard = false;
    lastFourNumbers;
    @ViewChild('submitButton') submitButton: ElementRef;
    submited = false;
    timeSpent = new Date();
    private _routerSub = Subscription.EMPTY;
    invoiceNumber = null;
    showRegisterAccountDialog = false;
    bankVerifyAmount1;
    bankVerifyAmount2;
    bankVerifyAccountSelected;
    paymentCardDefault;
    balanceWaringDialog = false;
    requestCharging = false;
    @ViewChild('paymentConfirmationDialog', { static: true }) paymentConfirmationDialog: any;
    previewImage = null;
    visibleImage: boolean = false;
    totalOutstandingBalance;
    loadedCards = false;
    loadedBanks = false;
    loadedPaymentDefault = false;
    interval;
    deletedCard: any;
    payUnPaidInvoiceDialog;
    unpaidInvoices = [];
    autoPayPending;
    subscriptions: Subscription = new Subscription();
    transferFeeCashOut = 0;
    transferFeeFixedAmount = 0;
    lateFee = 0;
    totalTransferFee = 0;
    totalTransferFeeTxt = '0.00';
    totalChargeAmount = 0;
    totalChargeAmountTxt = '0.00';
    totalLateFee = 0;
    totalLateFeeTxt = '0.00';
    companyInfo;
    titleReceive: any;
    constructor(private authService: AuthService,
                private paymentInvoiceService: PaymentInvoiceService,
                private paymentProfileService: PaymentProfileService,
                private paymentTransactionService: PaymentTransactionService,
                private paymentCardDefaultService: PaymentCardDefaultService,
                private fb: UntypedFormBuilder,
                private router: Router,
                private messageService: MessageService,
                private transactionService: TransactionService,
                private projectService: ProjectService,
                private accountService: AccountService,
                private route: ActivatedRoute,
                private transactionTypeService: TransactionTypeService,
                private monitoringDetailsService: MonitoringDetailsService,
                private captureService: NgxCaptureService,
                private documentService: DocumentsService,
                private paymentNotificationService: PaymentNotificationService,
                private firebaseNotificationService: FirebaseNotificationService,
                private db: AngularFireDatabase,
                private payrollSettingService: PayrollSettingService,
                private quoteService: QuoteService,
                private companyService: CompanyService,
                private documentsService: DocumentsService,
                private translatePipe: TranslatePipe,
                private landerService: LanderService,
                private companySettingServiceV2: CompanySettingServiceV2,
                private transactionDetailsService: TransactionDetailsService) {
                this.route.queryParams.subscribe(params => {
                    if (params['invoiceNumber']) {
                        this.invoiceNumber = params['invoiceNumber'];
                    }
                });
                    this._routerSub = router.events.subscribe((event) => {
                        if (event instanceof NavigationEnd) {
                            if (event.url !== '/app/payments/detail') {
                                this.monitoringDetailsService.monitorAction(
                                    `Navigated to Payment Process`,
                                    this.timeSpent,
                                    {
                                        navigated_to_payment_process_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                                    },
                                    'complete',
                                    `Navigated to Payment Process`,
                                    0
                                );
                            }
                        }
                    })
    }

    ngOnInit() {
        this.interval = setInterval(() => {
            if (this.loadedCards && this.loadedBanks && this.loadedPaymentDefault) {
                if (this.autoPay) {
                    if (((!this.contentList || this.contentList.length === 0) && (!this.userCardList || this.userCardList.length === 0)) || !((this.selectedCard && this.selectedCard.id) || this.selectedAccount.id)) {
                        this.autoPay = false;
                        this.acceptCard();
                    }
                }
                clearInterval(this.interval);
            }
        }, 500)
        this.payEnable = false;
        this.isSelectedCard = false;
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        if (this.isPlatformAdmin) {
            this.companyId = null;
        }
        if (!this.isPlatformAdmin) {
            this.companyId = this.authService.getCurrentCompanyId();
        }
        this.requestFilter = {
            status: null,
            fromDate: null,
            toDate: null,
            bankName: null,
            companyId: this.authService.getCurrentCompanyId(),
            isRegister: true
        };
        this.paymentForm = this.fb.group({
            cvvNumber: [null, [Validators.maxLength(4)]],
            transferAmount: [null],
            amount: [''],
            balance: [0],
            totalOutstandingBalance: [0],
            autoPay: null
        });

        this.paymentForm.valueChanges.subscribe(e => {
            if (e) {
                // console.log('e form', e)
            }
        });
        const paymentTransaction = <any>{
            roleId: this.authService.getRoleLevel(),
            userId: this.authService.getCurrentLoggedInId(),
            companyId: this.authService.getCurrentCompanyId()
        };
        this.paymentCardDefaultService.getDefaultCard(paymentTransaction).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.paymentCardDefault = resObj.data;
                if (resObj.data && (resObj.data.cardId || resObj.data.bankId)) {
                    this.selectedPaymentTransaction = resObj.data;
                    this.isSelectedCard = true;
                    this.autoPay = resObj.data.autoPay;
                    this.payEnable = true;
                    this.loadedPaymentDefault = true;
                    if (this.userCardList && this.userCardList.find(c => c.id === resObj.data.cardId)) {
                        this.selectedCard.id = resObj.data.cardId;
                    } else if (this.contentList.find(c => c.id === resObj.data.bankId)) {
                        this.selectedAccount.id = resObj.data.bankId;
                    } else {
                        this.isSelectedCard = false;
                        this.payEnable = false;
                    }
                } else {
                    this.loadedPaymentDefault = true;
                }

            } else {
                this.loadedPaymentDefault = true;
            }
        }, () => this.loadedPaymentDefault = true);
        // this.invoices = [
        //     { label: '030204', value: 30.56 },
        //     { label: '030205', value: 44 },
        //     { label: '030206', value: 58.50 },
        //     { label: '030207', value: 69.6 },
        //     { label: '030208', value: 70.06 },
        //     { label: '030209', value: 8 },
        //     { label: '030210', value: 9 },
        // ];
        // this.searchInvoices();
        // this.generateTransferInvoiceData();
        // await this.loadInvoiceAmount();
        // this.showSelectedInvoiceInDropDown();
        if (window.location.toString().includes('app/payments') || window.location.toString().includes('app/payment')) {
            this.setFlagForPaymentMenu = true;
        }
        console.log('selectedInvoices', this.selectedInvoices);
        this.getCardDetails();
        this.loadProjectList();
        this.accountList();
        this.transactionType();
        this.loadTableData();
        this.previewImage = this.paymentProfileService.openBankAccountImage();

        this.subscriptions.add(this.db.object(`/auto-pay/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
            if (event) {
                const currentTimestamp = new Date().getTime();
                if (event.updateTime > currentTimestamp - 15000) {
                    this.autoPay = event.enable;
                }

            }
        }));

        const companyId: number = this.authService.getCurrentCompanyId();
        this.payrollSettingService.getPayrollSettings(companyId, { companyId: companyId }).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
                if (res.data.transferFeeCashOut) {
                    this.transferFeeCashOut = Number(res.data.transferFeeCashOut);
                }
                if (res.data.transferFeeFixedAmount) {
                    this.transferFeeFixedAmount = Number(res.data.transferFeeFixedAmount);
                }
                if (res.data.lateFee) {
                    this.lateFee = Number(res.data.lateFee);
                }

            }
            this.searchInvoices();
        }, () => this.searchInvoices());

        if (this.authService.isClientRole()) {
            this.db.object(`/generate-new-invoice/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
                if (event) {
                    const currentTimestamp = new Date().getTime();
                    if (event.updateTime > currentTimestamp - 15000) {
                        this.searchInvoices();
                    }
                }
            });
        } else if (this.authService.isCompanyAdminRole()) {
            this.db.object(`${this.authService.getCurrentCompanyId()}/generate-new-invoice`).valueChanges().pipe(debounceTime(1000)).subscribe((event: any) => {
                if (event) {
                    const currentTimestamp = new Date().getTime();
                    if (event.updateTime > currentTimestamp - 15000) {
                        this.searchInvoices();
                    }
                }
            });
        }

        this.companyService.getCompanyByCompanyId(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
            this.companyInfo = res.data;
        });

        
        console.log('this.functionImport: ', this.functionImport)
        console.log('this.planTypeImport: ', this.planTypeImport)
        if (this.functionImport === 'TOP_UP') {

            if (this.planTypeImport === '188_month_up_to_5000') {
                this.selectedInvoicesTotalAmount = 188;
                this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
                this.titleReceive = 'up to 5,000 times send';
                this.planTypeName = '$188/month - up to 5,000 times send';
                this.smsAddCount = 5000;
            }
            else if (this.planTypeImport === '348_month_up_to_10000') {
                this.selectedInvoicesTotalAmount = 348;
                this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
                this.titleReceive = 'up to 10,000 times send';
                this.planTypeName = '$348/month - up to 10,000 times send';
                this.smsAddCount = 10000;
            }
            else if (this.planTypeImport === '748_month_up_to_25000') {
                this.selectedInvoicesTotalAmount = 748;
                this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
                this.titleReceive = 'up to 25,000 times send';
                this.planTypeName = '$748/month - up to 25,000 times send';
                this.smsAddCount = 25000;
            }
            else if (this.planTypeImport === '1248_month_up_to_50000') {
                this.selectedInvoicesTotalAmount = 1248;
                this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
                this.titleReceive = 'up to 50,000 times send';
                this.planTypeName = '$1248/month - up to 50,000 times send';
                this.smsAddCount = 50000;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        setTimeout(() => {
          this._routerSub.unsubscribe();
        },1000)
      }

    loadTableData() {
        this.contentList = [];
        this.paymentProfileService.listBankAccount(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()).subscribe((rp: any) => {
            this.loadedBanks = true;
            if (rp.status === 200) {
                rp.data.forEach(data => {
                    let paymentMethod = data;
                    if (paymentMethod) {
                        paymentMethod = {
                            ...paymentMethod,
                            status: data.status,
                            bankId: data.id,
                        }
                        if (this.paymentCardDefault && this.paymentCardDefault.bankId === paymentMethod.id) {
                            this.selectedAccount.id = paymentMethod.id;
                            this.isSelectedCard = true;
                            this.payEnable = true;
                        }
                        this.contentList.push(paymentMethod);
                    }
                });
            } else {
                this.loadedBanks = true
            }
        }, () => this.loadedBanks = true);
    }

    openDialogCondition() {
        this.showConditionDialog = true;
    }

    closeDialogCondition() {
        this.showConditionDialog = false;
    }

    async loadInvoiceAmount() {
        this.invoices = [];
        /*await this.invoiceService.getInvoiceNumbers(this.authService.getMedicaidProviderNumber()).subscribe((res: any) => {
            res.forEach((item)=>{
                this.invoices.push({label:item, value:item});
            });
        });*/
        this.showSelectedInvoiceInDropDown();
        // let selectedInvoicesNumber = [];
        // this.selectedInvoices.forEach((invoice)=>{
        //   selectedInvoicesNumber.push({label: invoice.invoiceNumber, value: invoice.invoiceNumber});
        // });
        // const finalTempArry = selectedInvoicesNumber.filter(el => this.invoices.includes(el));
    }

    searchInvoices() {
        if (this.authService.isContractorRole()) {
            return;
        }
        const options = <any>{
            page: 0,
            size: 9999
        };

        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }

        if (this.authService.isClientRole()) {
            options.clientId = this.authService.getCurrentLoggedInId();
        }
        options.oldInvoiceOnly = false;
        options.sortField = "id";
        options.sortOrder = "DESC";
        options.ignoreInvoiceDetails = true;
        this.totalOutstandingBalance = null;
        this.paymentInvoiceService.filter(options).subscribe((res: any) => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const list = resObj.data.content;
                this.invoices = [];
                this.unpaidInvoices = [];
                let totalBalance = 0;
                const now = moment().startOf('d');
                for (const invoice of list) {
                    if (invoice.status === 'Pending') {
                        continue;
                    }
                    const dueDate = moment(invoice.dueDate).startOf('d');
                    const diff = dueDate.diff(now, 'd', true);
                    let lateFee = 0;
                    if (invoice.totalLateFee && !invoice.waiveLateFee) {
                        // lateFee = invoice.totalLateFee - (invoice.lateFeePaid ? invoice.lateFeePaid : 0);
                        lateFee = invoice.totalLateFee;
                    }
                    const balance = invoice.balance - lateFee;
                    if (balance > 0 && balance.toFixed(2) !== '0.00') {
                        totalBalance += balance;
                        this.invoices.push({label: invoice.invoiceNumber, value: balance, id: invoice.id, clientId: invoice.clientId, invoiceType: invoice.invoiceType, 
                            status: invoice.status, lateInvoice: diff < 0, waiveLateFee: invoice.waiveLateFee, apiUsageCost: invoice.apiUsageCost, lateFee: lateFee});
                        this.unpaidInvoices.push(invoice);
                    }
                }
                console.log('searchInvoices invoices: ', this.invoices);
                this.totalOutstandingBalance = totalBalance.toFixed(2);
                if (this.invoiceNumber) {
                    const selectedInvoicesToPay = this.invoices.find(iv => iv.label === this.invoiceNumber);
                    this.selectedInvoicesForCal = [];
                    if (selectedInvoicesToPay) {
                        this.selectedInvoicesForCal.push(selectedInvoicesToPay);
                        this.selectedInvoicesTotalAmount = selectedInvoicesToPay.value;
                        this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
                        this.valueForCalBalanceTotalAmount = this.selectedInvoicesTotalAmount;
                        this.calculateBalance();
                        this.selectInvoice();
                    }

                }
            }
        }, err => {
            console.log(err);
        });
    }

    showSelectedInvoiceInDropDown() {
        const tempObj = <any>{transferAmount: []};
        let aa = 0;
        this.totalInvoices = 0;
        if (this.selectedInvoices && this.selectedInvoices.length > 0) {
            this.totalInvoices = this.selectedInvoices.length;
            this.selectedInvoices.forEach((invoice) => {
                tempObj.transferAmount.push(invoice.invoiceNumber);
                if (invoice.totalBillServiceFee && invoice.totalBillServiceFee > 0) {
                    aa += invoice.totalBillServiceFee;
                } else if (invoice.serviceFee && invoice.serviceFee > 0) {
                    aa += invoice.serviceFee;
                }
            });
            tempObj.amount = aa;
            this.paymentForm.patchValue(tempObj);
            console.log('payment', this.paymentForm);
        }
    }

    validateCVV(event) {
        this.isMatched = false;
        console.log(event);
        if (event && event.target && this.cardTokenCode === event.target.value) {
            this.isMatched = true;
        }
    }


    deleteExistedCard() {
        this.paymentProfileService.deleteCard(this.registerCustomerPaymentId, this.cardToken, this.cardType).subscribe(res => {
            this.msgs.push({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('The card is deleted')});
            this.displayChange.emit(true);
        }, (err) => {
            this.msgs.push({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.error.message});
        });
    }

    acceptCard() {
        // this.selectedAccount.id = null;
        // this.selectCard()
        this.paymentProfileService.deletePaymentCard(this.selectedCard.id, this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()).subscribe(() => {
            this.isSelectedCard = false;
            this.showConfirmCardDialog = false;
            this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Card has been deleted')});
            const cardId = (this.selectedCard.id === this.paymentCardDefault.cardId || this.userCardList.length - 1 <= 0) ? null : this.selectedCard.id;
            const paymentTransaction = <any>{
                id: null,
                cardId: cardId,
                paymentCardType: this.selectedCard.paymentCardType,
                bankId: this.selectedAccount ?  this.selectedAccount.id : null,
                roleId: this.authService.getRoleLevel(),
                userId: this.authService.getCurrentLoggedInId(),
                autoPay: cardId && this.autoPay,
                clientName: this.authService.getCurrentUsername()
            };
            if (this.selectedPaymentTransaction) {
                paymentTransaction.id = this.selectedPaymentTransaction.id;
            }
            this.paymentCardDefaultService.setDefaultCard(paymentTransaction).subscribe(() => this.ngOnInit(), () => this.ngOnInit());
            const args = {
                account_has_been_deleted_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            const action = 'Account has been Deleted'
            this.monitorDetails(args, action)
        }, err => {
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.responeObj.errors.error[0].errorText});
        });
    }
    rejectCard() {
        this.showConfirmCardDialog = false;
    }
    confirmRemoveCard(card: any) {
        this.selectedCard = card;
        // this.paymentProfileService.deletePaymentCard(this.selectedCard.id, this.authService.getUserInfo().adminId).subscribe(() => {
        //     this.isSelectedCard = false;
        //     this.messageService.add({severity: 'success', summary: 'SUCCESS', detail: 'Card has been deleted.'});
        //     this.ngOnInit();
        // }, err => {
        //     this.messageService.add({severity: 'error', summary: 'ERROR', detail: err.responeObj.errors.error[0].errorText});
        // });
        this.lastFourNumbers = this.selectedCard.lastFourNumbers;
        this.showConfirmCardDialog = true;
    }

    deletedAccountDefault(rowData) {
        this.accountNumber = rowData.accountNumber;
        this.showConfirmDialog = true;
        this.deletedCard = rowData;
    }

    accept() {
        this.paymentProfileService.deleteBankAccount(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType(), this.deletedCard.id).subscribe((rp: any) => {
            if (rp.status === 200) {
                this.showConfirmDialog = false;
                this.loadTableData();
                this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Bank Account has been deleted')});
            } else {
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Failed to delete Bank Account')});
            }
        }, (err) => {
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.error.message});
            this.hideRegisterAccountDialog();
        });
        this.selectedAccount = {id: null, bankId: null};
    }

    reject() {
        this.showConfirmDialog = false;
    }

    cancel(): void {
        this.displayChange.emit(true);
    }

    totalInvoice(values, event) {
        this.totalInvoices = 0;
        this.amountValue = 0;
        if (values && values.length > 0) {
            this.totalInvoices = values.map(v => v).reduce((sum, current) => sum + current);
            this.amountValue = values.join(',');
            // this.paymentForm.get('amount').setValue(this.amountValue)
        }

        this.selectedInvoicesTotalAmount = 0;
        let totalInvoiceAmount = 0;
        let totalApiUsageCost = 0;
        let numberChargingInvoice = 0;
        this.totalLateFee = 0;
        if (this.selectedInvoicesForCal && this.selectedInvoicesForCal.length > 0) {
            console.log('totalInvoice selectedInvoicesForCal:', this.selectedInvoicesForCal);
            this.selectedInvoicesForCal.forEach((invoice) => {
                if (invoice.value > 0) {
                    this.selectedInvoicesTotalAmount += invoice.value;
                    totalApiUsageCost += invoice.apiUsageCost ? invoice.apiUsageCost : 0;
                    this.totalLateFee += invoice.lateFee;
                    numberChargingInvoice++;
                }
            });
            totalInvoiceAmount = this.selectedInvoicesTotalAmount;
            this.selectedInvoicesTotalAmount = this.selectedInvoicesTotalAmount.toFixed(2);
            this.valueForCalBalanceTotalAmount = this.selectedInvoicesTotalAmount;
        }

        if (event.value.length === this.invoices.length) {
            const x = document.getElementById('id_invoices_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            const x = document.getElementById('id_invoices_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }

        this.totalTransferFee = ((totalInvoiceAmount + this.totalLateFee) * this.transferFeeCashOut / 100) + (this.transferFeeFixedAmount * numberChargingInvoice);
        this.totalTransferFeeTxt = this.totalTransferFee.toFixed(2);
        this.totalLateFeeTxt = this.totalLateFee.toFixed(2);
        this.totalChargeAmount = this.totalTransferFee + totalInvoiceAmount + this.totalLateFee;
        this.totalChargeAmountTxt = this.totalChargeAmount.toFixed(2);
    }

    generateTransferInvoiceData() {
        /*this.invoiceService.generateTransferInvoiceData(this.authService.getMedicaidProviderNumber()).subscribe(res => {
            if (res) {
                this.invoices = (res as any).filter(v => v.total).map(v => ({label: v.invoiceNumber, value: v}))
            }
        })*/
    }

    closeConfirmDialog(event) {
        this.isCharged = false;
        this.displayChange.emit(true);
        this.router.navigate(['app/payments/detail/list']);
    }

    calBalance() {
        if (Number(this.selectedInvoicesTotalAmount).valueOf() >= this.valueForCalBalanceTotalAmount.valueOf()) {
            this.selectedInvoicesTotalAmount = this.valueForCalBalanceTotalAmount;
            this.balanceValue = 0;
            return;
        } else {
            this.balanceValue = this.valueForCalBalanceTotalAmount - Number(this.selectedInvoicesTotalAmount);
            this.balanceValue = this.balanceValue.toFixed(2);
        }
    }

    getCardDetails() {
        const userId = this.authService.getUserInfo().adminId;
        const userType = this.authService.getPaymentUserType();
        this.paymentProfileService.getUserCardList(userId, userType).subscribe((response: any) => {
            this.loadedCards = true;
            if (response.status === 200) {
                this.userCardList = response.data;
                if (this.paymentCardDefault && this.userCardList.find(c => c.id === this.paymentCardDefault.cardId)) {
                    this.selectedCard.id = this.paymentCardDefault.cardId;
                    this.isSelectedCard = true;
                    this.payEnable = true;
                }
            }
        }, err => {
            this.loadedCards = true;
            console.log(err);
        });
    }

    enablePayAndCheckOut() {
        console.log(this.isSelectedCard);
        if (Number(this.selectedInvoicesTotalAmount) > 0 && this.isSelectedCard) {
            // this.submitButton.nativeElement.disabled = false;
            // this.submited = false;
        } else {
            this.submitButton.nativeElement.disabled = true;
            this.submited = false;
        }
    }

    selectCard(event, data?) {
        let unpaidChecking = true;
        if (event === 'bank') {
            this.selectedCard = {id: null}; 
            this.selectedAccount = data;
            unpaidChecking = false;
        }
        if (event === 'card'){
            this.selectedCard = data;
            this.selectedAccount = {id: null, bankId: null};
            unpaidChecking = false;
        }
        this.isSelectedCard = true;
        const paymentTransaction = <any>{
            id: null,
            cardId: this.selectedCard?.id,
            paymentCardType: this.selectedCard?.paymentCardType,
            bankId: this.selectedAccount ? this.selectedAccount.id : null,
            roleId: this.authService.getRoleLevel(),
            userId: this.authService.getCurrentLoggedInId(),
            autoPay: this.autoPay,
            clientName: this.authService.getCurrentUsername()
        };
        if (this.selectedPaymentTransaction) {
            paymentTransaction.id = this.selectedPaymentTransaction.id;
        }
        this.paymentCardDefaultService.setDefaultCard(paymentTransaction).subscribe(res => {
            this.showConfirmDialog = false;
            const resObj: any = res;
            if (unpaidChecking && resObj.status === 'SUCCESS' && resObj.data && resObj.data.autoPay && (resObj.data.cardId || resObj.data.bankId)) {
                if (this.authService.isClientRole() && this.totalOutstandingBalance > 0 && this.unpaidInvoices && this.unpaidInvoices.length > 0) {
                    // this.payUnPaidInvoiceDialog = true;
                }
            }

        });
        this.cardButton = false;
        this.payEnable = true;
        if (event === 'bank' || event === 'card') {
            const msg = event === 'bank' ? `Bank number ${this.selectedAccount?.accountNumber}` : `${this.selectedCard?.paymentCardType} card`;
            this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: `${msg} is selected!`});
        } else {
            let args = {};
            let actions;
            if (event.checked) {
                args = {
                    auto_pay_has_been_activated_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                actions = 'Auto Pay Has been activated';
                this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Auto Pay was activated')});
            } else {
                args = {
                    auto_pay_was_inactivated_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                actions = 'Auto Pay was inactivated';
                this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Auto Pay was inactivated')});
            }
            if (!data) {
                this.monitorDetails(args, actions, (r) => {
                    this.paymentNotificationService.sendAutoPayChangeEmail(r);
                });
            }
        }
    }

    async chargeAmount() {
        if (this.selectedInvoicesForCal && this.selectedInvoicesForCal.length > 0) {
            this.requestCharging = true;
            this.submited = false;
            this.isCharged = false;
            const bankTransfer = this.selectedAccount && this.selectedAccount.id;

            let paidInvoices = [];
            this.selectedInvoicesTotalAmountTemp = Number(this.selectedInvoicesTotalAmount);
            for (let invoice of this.selectedInvoicesForCal) {
                const invoiceRes = await this.processChargeAmountPerInvoice(invoice, bankTransfer);
                if (invoiceRes) {
                    paidInvoices.push(invoiceRes);
                }

            }
            if (paidInvoices.length > 0) {
                let totalInvoiceAmount = 0;
                paidInvoices.forEach(p => {
                    if (p.value > 0) {
                        totalInvoiceAmount += p.value;
                    }
                })
                this.confirmedData.invoice = paidInvoices.map(i => i.label).join(', ');
                this.confirmedData.amount = Number(this.selectedInvoicesTotalAmount) < totalInvoiceAmount ? Number(this.selectedInvoicesTotalAmount) : totalInvoiceAmount;
                this.confirmedData.invoiceNumbers = paidInvoices.length;
                this.confirmedData.paymentCardType = bankTransfer ? 'BANK_TRANSFER' : paidInvoices[0].cardType;
                this.confirmedData.transactionPaymentId = paidInvoices.map(i => i.transactionPaymentId).join(', ');
                this.confirmedData.fee = this.totalTransferFee + this.totalLateFee;

                this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Payment has been Processed')});

                this.requestCharging = false;
                this.submited = true;
                this.isCharged = true;
                this.sendNewPaymentTransactionNotification(paidInvoices);
                this.totalTransferFee = 0;
                this.totalTransferFeeTxt = '0.00';
                this.totalChargeAmount = 0;
                this.totalChargeAmountTxt = '0.00';
                this.totalLateFee = 0;
                this.totalLateFeeTxt = '0.00';
                this.reset();

                setTimeout(() => {
                    let paymentImg: HTMLElement = document.getElementById("paymentConfirmationDialog");
                    if (paymentImg && paymentImg.firstChild) {
                        paymentImg = (paymentImg.firstChild as HTMLElement).firstChild as HTMLElement;
                        this.captureService.getImage(paymentImg, true).subscribe(img => {
                            this.documentService.uploadFileEntity(this.dataURIToBlob(img, paidInvoices[0].transactionPaymentId + '.jpeg'), 'PaymentDetails', 0, 'Payment').subscribe(res => {
                                this.paymentNotificationService.sendEmail({
                                    to: this.authService.getUserInfo().email,
                                    emailCompanyId: this.authService.getUserInfo().companyId,
                                    emailFullName: this.authService.getUserInfo().name,
                                    paymentDetailsImg: res.data.fileUrl,
                                    invoicePdfLink: paidInvoices.map(i => i.invoicePdfLink).join(', '),
                                }).subscribe();
                            });
                        });
                    }
                }, 500);
            }

        }
    }

    async processChargeAmountPerInvoice(invoice, bankTransfer): Promise<any> {        
        if (this.selectedInvoicesTotalAmountTemp <= 0 || (!this.selectedAccount && !this.selectedCard)) {
            return Promise.resolve(null);
        }
        const time = new Date();
        const amount = invoice.value > this.selectedInvoicesTotalAmountTemp ? this.selectedInvoicesTotalAmountTemp : invoice.value;
        const transferFee = ((amount + invoice.lateFee) * this.transferFeeCashOut / 100) + this.transferFeeFixedAmount;
        let chargeAmount = amount + transferFee + invoice.lateFee;
        const key = 'PaymentInvoice_' + invoice.id + '_' + chargeAmount;
        const acquireLockRes: any = await this.paymentTransactionService.acquireLock(key, 300000).toPromise();
        if (!acquireLockRes.data) {
            console.error("Duplicate transaction for invoice: " + invoice.label);
            return Promise.resolve(null);
        }
        const transactionDetailsRes: any = await this.transactionDetailsService.loadLists({
            invoiceId: invoice.id, 
            size: 5,
            page: 0,
            sortField: "createdAt",
            sortOrder: "DESC",
        }).toPromise();
        if (transactionDetailsRes.status === 'SUCCESS') {
            const transactionList = transactionDetailsRes.data.content;
            transactionList.find(t => {
                let diff = Math.abs(moment(t.createdAt).diff(moment(time), 'seconds'));
                if (t.netCashInOut === chargeAmount && diff <= 60) {
                    this.selectedInvoicesTotalAmountTemp = this.selectedInvoicesTotalAmountTemp - amount;
                    console.error("Duplicate transaction for invoice: " + invoice.label);
                    return Promise.resolve(null);
                }
            });

        }
        try {
            let paymentResponse;
            if (this.selectedAccount && this.selectedAccount.id) {
                const options = {
                    app_user_id: this.authService.getUserInfo().adminId,
                    amount: chargeAmount,
                    bank_id: this.selectedAccount.id,
                    user_type: this.authService.getPaymentUserType(),
                    created_by_user: this.authService.getUserInfo().username,
                    memo: 'Client ' + this.authService.getUserInfo().username + ' paid for Invoice = ' + invoice.label
                };
                bankTransfer = true;
                paymentResponse = await this.paymentProfileService.chargeBankAccountAmount(options).toPromise();
            } else {
                const options = {
                    card_id:  this.selectedCard.id,
                    app_user_id: this.authService.getUserInfo().adminId,
                    amount: chargeAmount,
                    user_type: this.authService.getPaymentUserType(),
                    created_by_user: this.authService.getUserInfo().username,
                    memo: 'Client ' + this.authService.getUserInfo().username + ' paid for Invoice = ' + invoice.label
                };
                paymentResponse = await this.paymentProfileService.chargeAmount(options).toPromise();
            }

            let invoiceNumber = [];
            invoiceNumber.push(invoice.label);
            if (paymentResponse.status === 200 && paymentResponse.data) {
                const paymentTransaction = <any>{
                    date: new Date(),
                    invoiceNumber: invoiceNumber,
                    transactionId: paymentResponse.data.transactionPaymentId,
                    paymentMethod: bankTransfer ? 'BANK_TRANSFER' : paymentResponse.data.paymentCardType,
                    amount: chargeAmount,
                    companyId: this.companyId,
                    createdByUsr: this.authService.getCurrentUsername(),
                    autoPay: false,
                    transferFee: transferFee,
                    lateFee: invoice.lateFee
                };
                let paymentStatus;
                if (paymentResponse.data.status === 1 || paymentResponse.data.status === 7) {
                    paymentStatus = 1;
                } else if (paymentResponse.data.status === 3) {
                    paymentStatus = 0;
                } else {
                    paymentStatus = 2;
                }
                this.paymentTransactionService.create(paymentTransaction).subscribe(() => {
                    this.saveTransaction(invoice, paymentTransaction, time, paymentStatus, bankTransfer);
                }, err => {
                    const args = {
                        payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                    }
                    this.saveMonitorDetails(args, `Paid & Checkout Failed`, time, (r) => {
                        this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
                    });
                });
                if (invoice.invoiceType === 'Survey Land') {
                    try {
                        const quoteResponse: any = await this.quoteService.findByInvoiceNumber(invoice.label).toPromise();
                        if (invoice.status === 'Unpaid') {
                            this.generateProjectLand(quoteResponse.data, invoice.label);
                        }
                        const doc = buildSurveyLandPdfFile(quoteResponse.data, this.companyInfo.name, this.authService.getUserInfo().email, this.authService.getUserInfo().phone, true);
                        const blob = doc.output("blob");
                        const fileUpdateResponse: any = await this.documentsService.uploadFile(new File([blob], 'QuoteData.pdf'), 'ServiceRate', this.authService.getUserInfo().adminId, 'ServiceRate').toPromise();
                        invoice.invoicePdfLink = fileUpdateResponse.data.fileUrl;
                    } catch (err) {
                    }
                } else {
                    const invoicePdfRes = await this.paymentInvoiceService.generatePdfLink(invoice.id).toPromise();
                    invoice.invoicePdfLink = invoicePdfRes.data;
                }
                invoice.cardType = paymentResponse.data.paymentCardType;
                invoice.transactionPaymentId = paymentResponse.data.transactionPaymentId;
                this.selectedInvoicesTotalAmountTemp = this.selectedInvoicesTotalAmountTemp - amount;
                return Promise.resolve(invoice);
            } else {
                this.paymentTransactionService.releaseLock(key).subscribe();
                const paymentTransaction = <any>{
                    date: new Date(),
                    invoiceNumber: invoiceNumber,
                    amount: chargeAmount,
                    companyId: this.companyId,
                    createdByUsr: this.authService.getCurrentUsername(),
                    autoPay: false,
                    description: paymentResponse.data
                };
                this.paymentTransactionService.create(paymentTransaction).subscribe();
                this.reset();
                this.messageService.add({ severity: 'error', summary: 'ERROR', detail: paymentResponse.data });
                const args = {
                    payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                this.saveMonitorDetails(args, `Paid & Checkout Failed`, time, (r) => {
                    this.paymentNotificationService.sendPaymentFailedEmail(r, paymentResponse.data);
                });
            } 
        } catch (err) {
            this.paymentTransactionService.releaseLock(key).subscribe();
            let invoiceNumber = [];
            invoiceNumber.push(invoice.label);
            const paymentTransaction = <any>{
                date: new Date(),
                invoiceNumber: invoiceNumber,
                amount: chargeAmount,
                companyId: this.companyId,
                createdByUsr: this.authService.getCurrentUsername(),
                autoPay: false,
                description: err.error.message
            };
            this.paymentTransactionService.create(paymentTransaction).subscribe();
            this.messageService.add({severity: 'error', summary: 'ERROR', detail: err.error.message});
            const args = {
                payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            this.saveMonitorDetails(args, `Paid & Checkout Failed`, time, (r) => {
                this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
            });
        }
        return Promise.resolve(null);
    }

    dataURIToBlob(dataURI: string, fileName: string) : File {
        const splitDataURI = dataURI.split(',');
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ia], fileName, { type: 'image/jpeg' });
    }

    loadProjectList() {
        const options: any = {
            status: 1
        };
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        this.projectService.getProjectDropdown(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(ele => {
                    if (ele.projectName === 'Operr Service Bureau' || ele.projectName === 'Operr Services Bureau' || ele.projectName === 'Income') {
                        this.selectedProject = ele.id;
                    }
                });
            }
        });
    }


    accountList() {
        const options: any = {};
        this.accounts = [];
        this.accountService.getAccoundDropdown(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(ele => {
                    this.accounts.push({label: ele.account, value: ele.id});
                });
            }

        });
    }

    transactionType() {
        const options: any = {
            companyId: this.authService.getCurrentCompanyId(),
            status: 'Active'
        };
        this.transactionTypeService.findAllTransactionType(options).subscribe(res => {
            const resObj: any = res;
            this.txnType = resObj.data.content.filter(item => item.transactionType.toLowerCase() === 'payment');
        });
    }

    saveTransaction(invoice, data, time, paymentStatus, bankTransfer?) {
        const details: any = [];
        const payload: any = {
            companyId: this.authService.getCurrentCompanyId(),
            createdByUsr: this.authService.getCurrentUsername(),
            crmClientId: invoice.clientId,
            projectId: this.selectedProject,
            expenseCurrency: 'USD',
            invoiceId: invoice.id,
            status: paymentStatus,
            appliedTransaction: true,
            paymentTransactionId: data.transactionId
        };

        const transactionDetail: any = {};
        transactionDetail.invoiceId = invoice.id,
        transactionDetail.status = paymentStatus;
        transactionDetail.date = new Date();
        transactionDetail.cashIn = data.amount;
        transactionDetail.cashKept = 0;
        transactionDetail.cashOut = 0;
        transactionDetail.paymentMethod = bankTransfer ? 'BANK_TRANSFER' : 'credit/Debit';
        transactionDetail.transactionTypeId = this.txnType && this.txnType.length > 0 ? this.txnType[0].id : null;
        transactionDetail.billingAccountId = this.accounts[0].value;
        transactionDetail.createdByUsr = this.authService.getCurrentUsername(),
            // tslint:disable-next-line:max-line-length
            transactionDetail.netCashInOut = (parseFloat(transactionDetail.cashIn) + parseFloat(transactionDetail.cashKept)) - parseFloat(transactionDetail.cashOut);
        transactionDetail.netCashInOut = transactionDetail.netCashInOut.toFixed(2);
        transactionDetail.paymentTransactionId = data.transactionId;
        transactionDetail.clientInvoiceFee = Number(data.transferFee);
        transactionDetail.lateFee = Number(data.lateFee);
        details.push(transactionDetail);

        payload.transactionDetails = details;
        this.transactionService.createTransaction(payload).subscribe((res: any) => {
            if (invoice.id) {
                this.paymentInvoiceService.updateApplyTransaction(invoice.id).toPromise();
                const args = {
                    paid_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                this.saveMonitorDetails(args, `Paid & Checkout with Success`, time)
            }
        });
    }

    loadInvoiceBalance() {
        this.balanceValue = 0;
        const options = <any>{
            oldInvoiceOnly: false,
            sortField: "id",
            sortOrder : "DESC"
        };
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.authService.isClientRole()) {
            options.clientId = this.authService.getCurrentLoggedInId();
        }
        if(this.invoiceNumber) {
            options.clientId =  this.invoiceNumber.clientId;
        }

        if (this.invoiceNumber) {
            options.invoiceNumber = this.invoiceNumber;
        }
        this.paymentInvoiceService.filter(options).subscribe((res: any) => {
            const resObj: any = res;
            const invoice = resObj.data.content.filter(i => this.selectedInvoicesForCal.find(s => s.label === i.invoiceNumber));
            if (invoice && invoice.length > 0) {
                let paidFee = 0;
                let balance = 0;
                invoice.forEach(i => {
                    if (i.paidFee > 0) {
                        paidFee += i.paidFee;
                    }
                    balance += (i.totalFee - i.paidFee);
                });
                this.selectedInvoicesTotalAmount = balance.toFixed(2);
                this.valueForCalBalanceTotalAmount = Number(this.selectedInvoicesTotalAmount);
            }
        });
    }

    selectInvoice() {
        this.enablePayAndCheckOut();
        this.loadInvoiceBalance();
        const args = {
            invoice_selected_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
            invoice_number: this.invoiceNumber
        }
        this.saveMonitorDetails(args, `Invoice ${this.invoiceNumber} selected for payment `, new Date())

    }

    calculateBalance() {
        this.balanceValue = this.valueForCalBalanceTotalAmount - this.selectedInvoicesTotalAmount;
        if (this.balanceValue < 0) {
            this.balanceValue = 0;
            this.selectedInvoicesTotalAmount = this.valueForCalBalanceTotalAmount;
            this.balanceWaringDialog = true;
        } else {
            this.balanceWaringDialog = false;
            this.balanceValue = this.balanceValue.toFixed(2);
        }

        let numberChargingInvoice = 0;
        this.totalLateFee = 0;
        let totalApiUsageCost = 0;
        this.selectedInvoicesForCal.forEach((invoice) => {
            if (invoice.value > 0) {
                totalApiUsageCost += invoice.apiUsageCost ? invoice.apiUsageCost : 0;
                if (invoice.lateInvoice && !invoice.waiveLateFee) {
                    this.totalLateFee += invoice.lateFee;
                }
                numberChargingInvoice++;
            }
        });
        this.selectedInvoicesTotalAmount = Number(this.selectedInvoicesTotalAmount);
        this.totalTransferFee = ((this.selectedInvoicesTotalAmount + this.totalLateFee) * this.transferFeeCashOut / 100) + (this.transferFeeFixedAmount * numberChargingInvoice);
        this.totalTransferFeeTxt = this.totalTransferFee.toFixed(2);
        this.totalLateFeeTxt = this.totalLateFee.toFixed(2);
        this.totalChargeAmount = this.totalTransferFee + this.selectedInvoicesTotalAmount + this.totalLateFee;
        this.totalChargeAmountTxt = this.totalChargeAmount.toFixed(2);
        console.log('calculateBalance totalChargeAmountTxt: ', this.totalChargeAmountTxt);
        
    }

    isDisabled() {
        if (Number(this.selectedInvoicesTotalAmount) <= 0 || (!this.selectedAccount && !this.selectedCard)) {
            return true;
        }
        if (this.payEnable && this.selectedInvoicesForCal && this.selectedInvoicesForCal.length > 0) {
            return false;
        }
        return true;
    }

    isDisabledForTopup() {
        if (Number(this.selectedInvoicesTotalAmount) <= 0 || (!this.selectedAccount && !this.selectedCard)) {
            return true;
        }
        if (this.payEnable) {
            return false;
        }
        return true;
    }

    saveMonitorDetails(args, type, time, callback?: Function){
        this.monitoringDetailsService.monitorAction(
            `${type}`,
            time,
            args,
            'complete',
            `${type}`,
            0,
            callback
        );
    }

    hideRegisterAccountDialog() {
        this.bankVerifyAccountSelected = null;
        this.bankVerifyAmount1 = null;
        this.bankVerifyAmount2 = null;
        this.showRegisterAccountDialog = false;
    }

    getBankAccountStatus(status: number) {
        if (status === 1) {
            return 'ACTIVE';
        } else if (status === 3) {
            return 'PENDING'
        } else {
            return 'INACTIVE'
        }
    }

    verifyBankAccount(bankAccount) {
        this.bankVerifyAccountSelected = bankAccount;
        this.showRegisterAccountDialog = true;
        const args = {
            clicked_to_verify_bank_account_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        }
        const action = 'Clicked to Verify bank account'
        this.monitorDetails(args, action)
    }

    confirmVerifyBankAccount() {
        const args = {
            clicked_to_verified_amounts_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        }
        const action = 'Click to Verified Amounts'
        this.monitorDetails(args, action)
        const payload = {
            appUserId: this.authService.getUserInfo().adminId,
            bankId: this.bankVerifyAccountSelected.id,
            firstValue: this.bankVerifyAmount1,
            secondValue: this.bankVerifyAmount2,
            userType: this.authService.getPaymentUserType()
        }
        this.paymentProfileService.verifyBankAccount(payload).subscribe((rp: any) => {
            if (rp.status === 200) {
                this.loadTableData();
                this.messageService.add({severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('The bank account verified successfully')});
                const args = {
                    account_successfully_verified_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                const action = 'Account Successfully verified'
                this.monitorDetails(args, action)
            } else {
                this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Failed to verify bank account')});
                const args = {
                    bank_account_verification_failed_by:this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                const action = 'Bank Account Verification Failed'
                this.monitorDetails(args, action)
            }
            this.hideRegisterAccountDialog();
        }, (err) => {
            this.messageService.add({severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: err.error.message});
            this.hideRegisterAccountDialog();
            const args = {
                bank_account_verification_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            const action = 'Bank Account Verification Failed'
            this.monitorDetails(args, action)
        });
    }

    monitorDetails(args, action, callback?: Function) {
        this.monitoringDetailsService.monitorAction(
            action,
            new Date(),
            args,
            "complete",
            action,
            0,
            callback
        );
    }

    previewVerifyImage(){
       this.visibleImage = true;
    }

    reset() {
        this.selectedInvoicesForCal = [];
        this.selectedInvoicesTotalAmount = null;
        this.totalOutstandingBalance = null;
        this.balanceValue = null;
        this.requestCharging = false;
        const x = document.getElementById('id_invoices_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
        x.textContent = 'Choose';
        this.searchInvoices();
    }

    routePage(type?){
        localStorage.setItem('paymentMethod', type)
        this.router.navigate(['app/company/company-payment-method'])
    }

    isNumber(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        if(evt.target.value.indexOf(".")>-1 && (evt.target.value.split('.')[1].length > 1))	return false;
        if (evt.target.value.indexOf('.') >= 0 && charCode == 46) return false;
        return true;
    }
    
    getUnpaidInvoices(invoices: any[]) {
        if (!invoices || invoices.length <= 0) {
          return null;
        }
        invoices = invoices.sort((a, b) => a.id - b.id);
        return invoices.filter(i => (i.totalFee - (i.paidFee ? i.paidFee : 0) > 0) && ((i.totalFee - (i.paidFee ? i.paidFee : 0)).toFixed(2) !== '0.00'));
    }

    acceptPayUnPaidInvoice() {
        this.autoPayPending = true;
        const payload = {
            clientId: this.authService.getUserInfo().adminId,
            companyId: this.authService.getUserInfo().companyId,
            invoices: this.unpaidInvoices
        }
        this.paymentInvoiceService.autoPayInvoices(payload).subscribe((rp: any) => {
            this.payUnPaidInvoiceDialog = false;
            this.autoPayPending = false;
            if (rp.status === 'SUCCESS') {
                this.sendNewPaymentTransactionNotification(payload);
                this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: this.translatePipe.transform('Successfully')});
            } else {
                this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: rp.message });
            }
          }, () => this.autoPayPending = false);
    }

    sendNewPaymentTransactionNotification(transaction) {
        this.firebaseNotificationService.saveFirebaseNotification(`/latest-transaction/${this.authService.getCurrentCompanyId()}`, {
          ...transaction,
          updateTime: new Date().getTime()
        }).subscribe();
    }

    generateProjectLand(quoteInfo: any, invoiceNumber: string) {
        const payload: any = {
            address: quoteInfo.address,
            borough: quoteInfo.borough,
            city: quoteInfo.city,
            clientId: quoteInfo.clientId,
            companyId: quoteInfo.companyId,
            county: quoteInfo.county,
            createdByUsr: this.authService.getCurrentUsername(),
            district: quoteInfo.district,
            email: this.authService.getUserInfo().email,
            invoiceId: invoiceNumber,
            town: quoteInfo.town,
            zipcode: quoteInfo.zipcode,
            quote: quoteInfo.quoteNumber,
            state: quoteInfo.state,
            jobNumber: 'Job_' + Math.floor(100000 + Math.random() * 900000),
            autoGenerate: true
        }
        this.landerService.createServiceRate(payload).subscribe();
    }

    showDialogInfoFee = false;
    openDialogInfoFee() {
        this.showDialogInfoFee = true;
    }

    closeDialogInfoFee() {
        this.showDialogInfoFee = false;
    }

    async chargeAmountForTopup() {

        if (this.functionImport !== 'TOP_UP') {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'Amount must be greater than 0' });
            return;
        }

        this.requestCharging = true;
        this.submited = false;
        this.isCharged = false;
        const bankTransfer = this.selectedAccount && this.selectedAccount.id; 

        this.selectedInvoicesTotalAmountTemp = Number(this.selectedInvoicesTotalAmount);

        const processRes = await this.processChargeAmountForTopup(bankTransfer);
        if (processRes) {

            this.confirmedData.amount = Number(this.selectedInvoicesTotalAmount);
            this.confirmedData.paymentCardType = bankTransfer ? 'BANK_TRANSFER' : processRes.cardType;
            this.confirmedData.transactionPaymentId = processRes.transactionPaymentId;
            this.confirmedData.fee = this.totalTransferFee + this.totalLateFee;
            this.confirmedData.planTypeName = this.planTypeName;
            this.confirmedData.description = 'Top Up: ' + this.planTypeName;
            this.confirmedData.totalSmsAvailable = processRes.totalSmsAvailable ? processRes.totalSmsAvailable : this.smsAddCount;

            this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Payment has been Processed')});
            this.requestCharging = false;
            this.submited = true;
            this.isCharged = true;
            this.totalTransferFee = 0;
            this.totalTransferFeeTxt = '0.00';
            this.totalChargeAmount = 0;
            this.totalChargeAmountTxt = '0.00';
            this.totalLateFee = 0;
            this.totalLateFeeTxt = '0.00';
            this.reset();

            setTimeout(() => {
                const sendEmailOptions: any = {
                    action: 'TOP_UP',
                    planTypeName: this.planTypeName,
                    totalSmsAvailable: this.confirmedData.totalSmsAvailable,
                    description: this.confirmedData.description,
                    transactionId: this.confirmedData.transactionPaymentId,
                    emailCompanyId: this.authService.getUserInfo().companyId,
                    emailFullName: this.authService.getUserInfo().name,
                    invoiceAmount: this.confirmedData.amount,
                    transferFee: this.confirmedData.fee,
                    paymentMethod: this.confirmedData.paymentCardType
                };
                this.paymentNotificationService.sendEmail(sendEmailOptions).subscribe();
            }, 500);
        }
    }

    async processChargeAmountForTopup(bankTransfer) : Promise<any> {
        if (this.selectedInvoicesTotalAmountTemp <= 0) {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: 'Amount must be greater than 0' });
            return Promise.resolve(null);
        }

        if (!this.selectedAccount && !this.selectedCard) {
            return Promise.resolve(null);
        }

        const time = new Date();
        const amount = this.selectedInvoicesTotalAmountTemp;
        const transferFee = ((amount) * this.transferFeeCashOut / 100) + this.transferFeeFixedAmount;
        let chargeAmount = amount + transferFee;

        try {
            let paymentResponse;

            if (this.selectedAccount && this.selectedAccount.id) {
                const options = {
                    app_user_id: this.authService.getUserInfo().adminId,
                    amount: chargeAmount,
                    bank_id: this.selectedAccount.id,
                    user_type: this.authService.getPaymentUserType(),
                    created_by_user: this.authService.getUserInfo().username,
                    memo: 'Client ' + this.authService.getUserInfo().username + ' topup for plan = ' + this.planTypeName
                };
                bankTransfer = true;
                paymentResponse = await this.paymentProfileService.chargeBankAccountAmount(options).toPromise();
            } else {
                const options = {
                    card_id:  this.selectedCard.id,
                    app_user_id: this.authService.getUserInfo().adminId,
                    amount: chargeAmount,
                    user_type: this.authService.getPaymentUserType(),
                    created_by_user: this.authService.getUserInfo().username,
                    memo: 'Client ' + this.authService.getUserInfo().username + ' topup for plan = ' + this.planTypeName
                };
                paymentResponse = await this.paymentProfileService.chargeAmount(options).toPromise();
            }

            if (paymentResponse.status === 200 && paymentResponse.data) {

                const processRes = <any>{}

                const paymentTransaction = <any>{
                    date: new Date(),
                    transactionId: paymentResponse.data.transactionPaymentId,
                    paymentMethod: bankTransfer ? 'BANK_TRANSFER' : paymentResponse.data.paymentCardType,
                    amount: chargeAmount,
                    companyId: this.companyId,
                    createdByUsr: this.authService.getCurrentUsername(),
                    autoPay: false,
                    transferFee: transferFee,
                    lateFee: 0
                };

                let paymentStatus;
                if (paymentResponse.data.status === 1 || paymentResponse.data.status === 7) {
                    paymentStatus = 1;
                } else if (paymentResponse.data.status === 3) {
                    paymentStatus = 0;
                } else {
                    paymentStatus = 2;
                }
                this.paymentTransactionService.create(paymentTransaction).subscribe(() => {
                    this.saveTransactionForTopUp(paymentTransaction, time, paymentStatus, bankTransfer);
                }, err => {
                    const args = {
                        payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                    }
                    this.saveMonitorDetails(args, `Pay & Checkout Failed`, time, (r) => {
                        this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
                    });
                });

                const updateSmsAvailableReq = {
                    addSmsAvailable: this.smsAddCount,
                    companyId:  this.authService.getCurrentCompanyId(),
                    lastModifiedBy: this.authService.getUserInfo().username
                };
                this.companySettingServiceV2.updateSmsAvailable(updateSmsAvailableReq).subscribe((updateSmsAvailableRsp: any) => {
                    console.log('updateSmsAvailable updateSmsAvailableRsp: ', updateSmsAvailableRsp);
                    if (updateSmsAvailableRsp.status === 200 && updateSmsAvailableRsp.data) {
                        processRes.totalSmsAvailable = updateSmsAvailableRsp.data.smsAvailableAfterQuota;
                    }
                }, err => {
                    console.log('updateSmsAvailable err: ', err);
                });

                processRes.cardType = paymentResponse.data.paymentCardType;
                processRes.transactionPaymentId = paymentResponse.data.transactionPaymentId;
                this.selectedInvoicesTotalAmountTemp = this.selectedInvoicesTotalAmountTemp - amount;
                return Promise.resolve(processRes);
            } else {
                const paymentTransaction = <any>{
                    date: new Date(),
                    amount: chargeAmount,
                    companyId: this.companyId,
                    createdByUsr: this.authService.getCurrentUsername(),
                    autoPay: false,
                    description: paymentResponse.data
                };
                this.paymentTransactionService.create(paymentTransaction).subscribe();
                this.messageService.add({ severity: 'error', summary: 'ERROR', detail: paymentResponse.data });
				console.log(paymentResponse.data, 'RESPONSE = ')
                const args = {
                    payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                this.saveMonitorDetails(args, `Pay & Checkout Failed`, time, (r) => {
                    this.paymentNotificationService.sendPaymentFailedEmail(r, paymentResponse.data);
                });
            }
        } catch (err) {
            const paymentTransaction = <any>{
                date: new Date(),
                amount: chargeAmount,
                companyId: this.companyId,
                createdByUsr: this.authService.getCurrentUsername(),
                autoPay: false,
                description: err.error.message
            };
            this.paymentTransactionService.create(paymentTransaction).subscribe();
            this.messageService.add({severity: 'error', summary: 'ERROR', detail: err.error.message});
            const args = {
                payment_failed_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            this.saveMonitorDetails(args, `Pay & Checkout Failed`, time, (r) => {
                this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
            });
        } 
    }

    saveTransactionForTopUp(data, time, paymentStatus, bankTransfer?) {
        const details: any = [];
        const payload: any = {
            companyId: this.authService.getCurrentCompanyId(),
            createdByUsr: this.authService.getCurrentUsername(),
            projectId: this.selectedProject,
            expenseCurrency: 'USD',
            status: paymentStatus,
            appliedTransaction: true,
            paymentTransactionId: data.transactionId
        };

        const transactionDetail: any = {};
        transactionDetail.status = paymentStatus;
        transactionDetail.date = new Date();
        transactionDetail.cashIn = data.amount;
        transactionDetail.cashKept = 0;
        transactionDetail.cashOut = 0;
        transactionDetail.paymentMethod = bankTransfer ? 'BANK_TRANSFER' : 'credit/Debit';
        transactionDetail.transactionTypeId = this.txnType && this.txnType.length > 0 ? this.txnType[0].id : null;
        transactionDetail.billingAccountId = this.accounts[0].value;
        transactionDetail.createdByUsr = this.authService.getCurrentUsername(),
        transactionDetail.netCashInOut = (parseFloat(transactionDetail.cashIn) + parseFloat(transactionDetail.cashKept)) - parseFloat(transactionDetail.cashOut);
        transactionDetail.netCashInOut = transactionDetail.netCashInOut.toFixed(2);
        transactionDetail.paymentTransactionId = data.transactionId;
        transactionDetail.clientInvoiceFee = Number(data.transferFee);
        transactionDetail.lateFee = Number(data.lateFee);
        details.push(transactionDetail);

        payload.transactionDetails = details;
        this.transactionService.createTransaction(payload).subscribe((res: any) => {
            const args = {
                paid_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            }
            this.saveMonitorDetails(args, `Paid & Checkout with Success`, time)
        });
    }
}
