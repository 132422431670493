import { Injectable } from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionFileHistoryService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.TRANSACTION_FILE_HISTORY, '');
  }

  getFileHistories(transactionDetailId, options: any): Observable<any> {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/transaction-file-history/` + transactionDetailId, options).pipe(map(res => res));
    return this.http.post<any>(`${this.baseUrlV2}` + transactionDetailId, options).pipe(map(res => res));
  }
}
