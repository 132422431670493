import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {JobFollowUpModel} from '../model/job-follow-up.model';

@Injectable({
  providedIn: 'root'
})
export class JobFollowUpService extends AbstractService<JobFollowUpModel>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.JOB_FOLLOW_UP, '');
  }

  search(options: any): Observable<any> {
    //return this._http.post<any>(`http://localhost:8010/api/v2/crm/total-job/search`, options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

getCustomColumn(options:any):Observable<any> {
  return this._http.get<any>(`${this.baseUrlV2}find-custom-column`, {params:options}).pipe(map(res => res));
}

checkDuplicateJob(options:any):Observable<any> {
  return this._http.post<any>(`${this.baseUrlV2}is-data-exist`, options).pipe(map(res => res));
}

  exportPdf(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}pdf`, options).pipe(map(res => res));
  }

  saveSupportIds(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}save-selected-support`, options).pipe(map(resp => resp));
  }

  getSupportIds(params: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}selected-support`, {params:params}).pipe(map(resp => resp));
  }

  exportListPdf(options: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8010/api/v2/crm/total-job/exportListPdf`, options).pipe(map(res => res));
    return this._http.post<any>(`${this.baseUrlV2}exportListPdf`, options).pipe(map(res => res));
  }
}
