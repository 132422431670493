import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { EmployeEvent } from '../model/employe-event';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Response } from '../../shared/model/response';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeeEventService extends AbstractService<EmployeEvent> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CRM.EMPLOYEE_EVENT , '');
    }

    getByEvent(id: number): Observable<Response<EmployeEvent[]>> {
        return this.http.get<Response<EmployeEvent[]>>(`${this.baseUrlV2}byvent/${id}`).pipe(map(resp => resp));
    }

    saveAll(entitys: EmployeEvent[]): Observable<EmployeEvent> {
        return this._http.post<EmployeEvent>(this.baseUrlV2, entitys).pipe(map(res => res));
    }

    updateAll(entitys: EmployeEvent[], id: Number): Observable<EmployeEvent[]> {
        return this._http.put<EmployeEvent[]>(`${this.baseUrlV2}${id}`, entitys).pipe(map(res => res));
    }


    getByEmployeeEvent(id: number): Observable<Response<EmployeEvent[]>> {
        return this.http.get<Response<EmployeEvent[]>>(`${this.baseUrlV2}byEmployee/${id}`).pipe(map(resp => resp));
    }
    
}
