import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import './rxjs.operators';
import { AuthService } from './shared/service/auth/auth.service';
import * as _ from 'lodash';
import { environment, environmentLocal } from '../environments/environment';
import { of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InterceptHeaderHttps implements HttpInterceptor {

    constructor(private injector: Injector,
                private cookieService: CookieService,
                private authService: AuthService,
                private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url;

        if (_.includes(req.url, 'api/forgot-password/') || _.includes(req.url, 'api/reset-password/')
        // || _.includes(req.url, environment.v2_server_ip)
        ) {
            return next.handle(req.clone());
        }
        // Get the token header
        let authHeader = '';
        // const tmpCookie = JSON.parse(this.cookieService.get('_globals'));
        const tmpCookie = JSON.parse(localStorage.getItem('_globals'));

        if (tmpCookie && tmpCookie.token) {
            authHeader = tmpCookie.token;
        }

       if(req.url.includes('api.operrwork.com') || req.url.includes('localhost')){
           req = req.clone({headers: req.headers.set('auth_token', `${authHeader}`)});
        } else {
        //    const token = localStorage.getItem('dev3Auth') ? localStorage.getItem('dev3Auth') : authHeader
            req = req.clone({headers: req.headers.set('app_key', 'f9811572162e4d0a99cb3595e7906baa')});
       }
        return next.handle(req)
            .catch(err => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // Avoid redirecting to login if the current route is /forgot-password
                    if (this.router.url.includes('/forgot-password')) {
                        // Maybe handle differently, like refresh the page or show a message
                        return throwError(err);
                    }
                    this.cookieService.delete('_globals');
                    localStorage.clear();
                    if (!this.router.url.includes('app/vrm/contribute') && !this.router.url.includes('app/one-time-payment')) {
                      this.router.navigate(['/login']);
                      return;
                    }
                }
                // this.cookieService.delete('_globals');
                // localStorage.clear();
                // this.authService.authenticateUser();
                // this.authService.setAuthObservable();
                // this.router.navigate(['/login']);
            }
            return throwError(err);
        });
    }
}
