<div>
    <section class="section-content padding-y bg mt-3">

        <div class="row">
            <main class="col-md-8">
                <article class="card mb-4">
                    <div *ngIf="warningMessage" class="alert alert-danger alert-dismissible" role="alert">
                        <p>{{warningMessage}}</p>
                    </div>
                    <h3 class="font-weight-bold">Invoice</h3>
                    <div class="card-body">
                        <ul style="text-align: left;">
                            <li style="font-size: 16px">Invoice type: {{selectedInvoice.invoiceType}}</li>
                            <li style="font-size: 16px">Invoice number: {{selectedInvoice.invoiceNumber}}</li>
                            <li style="font-size: 16px">Invoice status: {{selectedInvoice.status}}</li>
                        </ul>
                    </div>
                </article>

                <article class="card mb-4" id="accordion_pay">
                    <h3 class="font-weight-bold">Payment method</h3>
                    <div class="card-body" *ngIf="authService.getUserInfo()">

                        <div class="d-flex flex-wrap align-items-center justify-content-between py-4">
                            <h4 class="card-title mb-4">{{'Debit & Credit Cards' | translate}}</h4>
                            <button class="btn btn-primary mx-1" (click)="routePage('CREDIT')"
                                    pTooltip="{{'Add' | translate}}"
                                    tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
                        </div>
                        <p-panel [toggleable]="true" id="table-ppanel">
                            <p-header class="flex-grow-1">
				<span><i class="fa-solid fa-credit-card text-primary mr-2"></i>{{'Saved Cards' | translate}}
                    ({{userCardList?.length || 0}})</span>
                            </p-header>
                            <div *ngFor="let card of userCardList" class="card add-shadow mb-0 p-2">
                                <div class="d-flex align-items-center" *ngIf="card">
                                    <img src="/assets/payment/visa-straight-128px.png"
                                         *ngIf="card.paymentCardType === 'VISA'"
                                         class="card-image"/>
                                    <img src="/assets/payment/mastercard-straight-128px.png"
                                         *ngIf="card.paymentCardType === 'MASTER_CARD'"
                                         class="card-image"/>
                                    <img src="/assets/payment/discovery-straight-128px.png"
                                         *ngIf="card.paymentCardType === 'DISCOVER'"
                                         class="card-image"/>
                                    <img src="/assets/payment/american-express-straight-128px.png"
                                         *ngIf="card.paymentCardType === 'AMERICAN_EXPRESS'" class="card-image"/>
                                    <img src="/assets/payment/debit-card-straight-128px.png"
                                         *ngIf="card.paymentCardType === 'DEBIT'"
                                         class="card-image"/>
                                    <div class="d-flex align-items-center justify-content-between flex-grow-1 pl-3">
						<span class=""><span style="font-weight: 500">{{'Ends With' | translate}}</span>:
                            {{card.lastFourNumbers}}</span>
                                        <span><span
                                                style="font-weight: 500">{{'Exp' | translate}}</span> {{card.expire}}</span>
                                        <div class="d-flex">
                                            <button class="btn btn-white mx-1"
                                                    [disabled]="selectedCard?.id === card.id"
                                                    (click)="selectCard('card', card)" id="button-addon2"
                                                    type="button" pTooltip="{{'Select'  | translate}}"
                                                    style="cursor:default !important">
                                                <i class="fa-solid fa-check text-primary"></i>
                                            </button>
                                            <button class="btn btn-white mx-1" (click)="confirmRemoveCard(card)"
                                                    id="button-addon2" type="button"
                                                    pTooltip="{{'Delete'  | translate}}"
                                                    style="cursor:default !important">
                                                <i class="fa-solid fa-xmark text-danger"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-g" *ngIf="!userCardList || userCardList?.length <= 0">
                                <div class="ui-g-12 text-center py-4">
                                    <span class="text-muted">{{'No cards have been added' | translate}}</span><br>
                                    <button class="btn btn-light mt-4" (click)="routePage('CREDIT')">
                                            <span class="text-nowrap"><i
                                                    class="fa-solid fa-plus mr-2"></i> {{'Add Now' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                            <p-footer *ngIf="!userCardList?.includes('card')" class="d-flex justify-content-center">
                                <button class="btn btn-white" (click)="routePage('CREDIT')">
					<span class="text-nowrap"><i class="fa-solid fa-plus text-primary mr-2"></i> {{'Add Another' |
                        translate}}</span>
                                </button>
                            </p-footer>
                        </p-panel>
                        <div class="d-flex flex-wrap align-items-center justify-content-between py-4">
                            <h4 class="card-title mb-4">{{'Bank Accounts' | translate}}</h4>
                            <button class="btn btn-primary mx-1" (click)="routePage('TRANSFER')"
                                    pTooltip="{{'Add' | translate}}"
                                    tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
                        </div>
                        <p-panel [toggleable]="true" id="table-ppanel">
                            <p-header class="flex-grow-1">
				<span><i class="fa-solid fa-piggy-bank text-primary mr-2"></i> {{'Bank Accounts' | translate}}
                    ({{contentList?.length || 0}})</span>
                            </p-header>
                            <p-table *ngIf="contentList" [value]="contentList" dataKey="id" [lazy]="false"
                                     [rows]="10" [paginator]="true"
                                     [rowsPerPageOptions]="[10,25,50]" [responsive]="true"
                                     [resizableColumns]="true">

                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <span class="one-liner">{{'Account Name' | translate}}</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <span class="one-liner">{{'Account' | translate}} #</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <span class="one-liner">{{'Routing' | translate}} #</span>
                                            </div>
                                        </th>
                                        <th style="width: 45px !important;">
                                            <div class="d-flex align-items-center justify-content-center w-100">
                                                <i class="fa-solid fa-info text-muted m-auto"
                                                   pTooltip="{{'Status' | translate}}"></i>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <i class="fa-solid fa-bars text-muted m-auto"
                                                   pTooltip="{{'Actions' | translate}}"></i>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
                                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Account Name' | translate}}
                                    : </span> {{rowData.accountHolderName}}
							</span>
                                        </td>
                                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Account' | translate}}
                                    #: </span> {{rowData.accountNumber}}
							</span>
                                        </td>
                                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Routing' | translate}}
                                    #: </span> {{rowData.routingNumber}}
							</span>
                                        </td>
                                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Status' | translate}}:
								</span><span>{{getBankAccountStatus(rowData.status)}}<i *ngIf="rowData.status === 3"
                                                                                        class="fa-solid fa-circle-info text-primary ml-2"
                                                                                        (click)="previewVerifyImage()"
                                                                                        style="cursor: pointer;"></i></span>
							</span>
                                        </td>
                                        <td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i *ngIf="rowData.status === 3" class="fa-solid fa-circle-info text-primary mx-1"
                                   (click)="previewVerifyImage()" style="cursor: pointer;" tooltipPosition="left"></i>
								<i *ngIf="rowData.status === 3" class="fa-solid fa-circle-check text-primary mx-1"
                                   pTooltip="{{'Select Bank Account'  | translate}}"
                                   (click)="verifyBankAccount(rowData)"
                                   tooltipPosition="left"></i>
								<i *ngIf="rowData.status !== 3" class="fa-solid fa-credit-card text-purple mx-1"
                                   pTooltip="{{'Select Bank Account'  | translate}}"
                                   [disabled]="selectedAccount?.id === rowData.id"
                                   (click)="selectCard('bank', rowData)" tooltipPosition="left"></i>
								<i class="fa-solid fa-xmark text-danger mx-1" pTooltip="{{'Delete'  | translate}}"
                                   (click)="deletedAccountDefault(rowData)" tooltipPosition="left"></i>
							</span>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr *ngIf="!loading" class="text-center">
                                        <td [attr.colspan]="5" class="py-4 bg-white">
                                            <div class="ui-g">
                                                <div class="ui-g-12 text-center">
                                                    <span class="text-muted">{{'No bank accounts have been added' | translate}}</span><br>
                                                    <button class="btn btn-light mt-4"
                                                            (click)="routePage('TRANSFER')">
                                                            <span class="text-nowrap"><i
                                                                    class="fa-solid fa-plus mr-2"></i> {{'Add Now' | translate}}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" let-columns>
                                    <tr *ngIf="loading" class="text-center">
                                        <td [attr.colspan]="5" class="py-5 text-muted">
                                            {{'Loading' | translate}} ...
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>

                    </div>
                    <div class="card-body" *ngIf="!authService.getUserInfo()">
                        <app-one-time-payment-method></app-one-time-payment-method>
                    </div>
                </article>
            </main>
            <aside class="col-md-4">
                <div class="card shadow">
                    <div class="card-body">
                        <h3 class="font-weight-bold">Payment summary</h3>
                        <!--<br/>
                        <div class="d-flex flex-wrap align-items-center justify-content-center">
                            <label class="switch btn-color-mode-switch">
                                <input value="1" id="color_mode" name="color_mode" type="checkbox"
                                       (change)="checkboxClick($event)">
                                <label class="btn-color-mode-switch-inner" data-off="Monthly" data-on="Yearly"
                                       for="color_mode"></label>
                            </label>
                        </div>-->
                        <br/>
                        <br/>

                        <dl class="dlist-align">
                            <span class="text-dark font-weight-bold">{{titleReceive}}</span>
                        </dl>
                        <br/>
                        <br/>
                        <hr>

                        <dl class="dlist-align">
                            <span class="text-dark font-weight-bold">Sub total</span>
                            <span class="float-right font-weight-bold">${{totalChargeAmount | number : '1.2-2'}}</span>
                        </dl>
                        <dl class="dlist-align">
                            <span class="text-dark">State tax</span>
                            <span class="float-right">$0.00</span>
                        </dl>
                        <dl class="dlist-align">
                            <span class="text-dark">City tax</span>
                            <span class="float-right">$0.00</span>
                        </dl>
                        <dl class="dlist-align">
                            <span class="text-dark">Special tax</span>
                            <span class="float-right">$0.00</span>
                        </dl>
                        <hr>
                        <dl class="dlist-align">
                            <span class="text-dark font-weight-bold">Total amount</span>
                            <span class="float-right font-weight-bold">${{totalChargeAmount | number : '1.2-2'}}</span>
                        </dl>
                        <hr>
                        <div class="d-flex flex-wrap align-items-center justify-content-center py-4">
                            <p-checkbox (onChange)="selectCard($event)" [(ngModel)]="autoPay"
                                        formControlName="autoPay"
                                        label="{{'I Authorize the processing of Auto-Pay payments' | translate}}"
                                        value="true" binary="true"></p-checkbox>
                            <a href="javascript:void(0)" (click)="openDialogCondition()"
                               class="text-primary">{{'Terms of Service' |
                                translate}}</a>
                        </div>
                        <div>
                            <button #submitButton appNoDblClick
                                    [disabled]="selectedInvoice.status === 'Paid' || oneTimePaymentMethodComponent?.disableAction()"
                                    (click)="chargeAmountForPlan()"
                                    class="btn btn-primary btn-block">
                                <i class="fa-solid fa-check mr-2"></i>{{'Pay now' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </aside>
        </div>


    </section>

    <app-payment-confirmation-dialog *ngIf="isCharged" [display]="isCharged" [data]="confirmedData"
                                     [functionImport]="'PLAN'" (displayChange)="closeConfirmDialog($event)">
    </app-payment-confirmation-dialog>
</div>

<p-dialog [style]="{'width':'70vw'}" [(visible)]="showConditionDialog" modal='modal' [modal]='true'>
    <p-header>
        <span><i class="fa-solid fa-shield mr-2"></i>{{'Payment Authorization' | translate}}</span>
    </p-header>
    <div>
        <h3 class="m-0 my-3">{{'Card Payment & Refund Authorization' | translate}}</h3>
        <p>
            {{'this_checkbox' | translate}}
        </p>
        <p>
            {{'by_selecting_this_option' | translate}}
        </p>
        <p>
            {{'i_authorize_operr' | translate}}
        </p>
    </div>
    <p-footer>
        <div class="ui-g-12" align="right">
            <button type="button" class="btn btn-primary" (click)="closeDialogCondition()"><i
                    class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
        </div>
    </p-footer>
</p-dialog>
<p-dialog [style]="{'width':'400px'}" [(visible)]="showConfirmDialog" modal='modal' [modal]='true'>
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Bank Account' | translate}}</span>
    </p-header>
    <div class="ui confirm p-3">
        {{'Are you sure you want to delete account ending in' | translate}} {{accountNumber}}?
    </div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="reject()" class="btn btn-danger"><i
                class="fa-solid fa-xmark mr-2"></i>{{'Cancel' |
            translate}}</button>
        <button type="button" (click)="accept()" class="btn btn-primary"><i
                class="fa-solid fa-check mr-2"></i>{{'Confirm' |
            translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [style]="{'width':'400px'}" [(visible)]="showConfirmCardDialog" modal='modal' [modal]='true'>
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Card' | translate}}</span>
    </p-header>
    <div class="ui confirm p-3">
        {{'Are you sure you want to delete card ending in' | translate}} {{lastFourNumbers}}?
    </div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="rejectCard()" class="btn btn-danger"><i
                class="fa-solid fa-xmark mr-2"></i>{{'Cancel'
            | translate}}</button>
        <button type="button" (click)="acceptCard()" class="btn btn-primary"><i
                class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>


<p-dialog header="{{'Verify Bank Account' | translate}}" [modal]="true" [(visible)]="showRegisterAccountDialog"
          (onHide)="hideRegisterAccountDialog()" styleClass="w-20">
    <p-panel>
        <p-header style="font-size: 15.5px;">
            {{'Verify Bank Account' | translate}}
        </p-header>
        <div class="ui-g pad-12">
            <div class="ui-g">
                <div class="ui-g-6">
                    <label>{{'Amount 1' | translate}}<span class="required-indicator">*</span>: </label>
                    <div class="d-flex">
                        <input pInputText type="text" [(ngModel)]="bankVerifyAmount1" class="w-90"/>
                    </div>
                </div>

                <div class="ui-g-6">
                    <label>{{'Amount 2' | translate}} <span class="required-indicator">*</span>:</label>
                    <input pInputText type="text" [(ngModel)]="bankVerifyAmount2" class="w-90"/>
                </div>
            </div>

        </div>
    </p-panel>

    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" (click)="confirmVerifyBankAccount()"
                [disabled]="!bankVerifyAmount1 || !bankVerifyAmount2" type="button" label="Verify"></button>
    </div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [style]="{'width':'30%'}" [(visible)]="balanceWaringDialog"
          modal='modal' [modal]='true'>
    <div class="ui confirm">
        {{'You cannot add Pay more than the Invoice amount' | translate}}
    </div>
    <p-footer>
        <div class="ui-g-12">
            <button type="button" pButton label="{{'ok' | translate}}" (click)="balanceWaringDialog = false"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog class="show-image" [showHeader]="false" [(visible)]="visibleImage" [modal]="true" [dismissableMask]="true">
    <div><img [src]="previewImage"/></div>
    <div class="close" (click)="visibleImage=false">X</div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="payUnPaidInvoiceDialog" class="modal_in" [modal]='true'
          (onHide)="payUnPaidInvoiceDialog = false">
    <div class="ui-g">
        {{'Would you like to pay your current balance of' | translate}} {{totalOutstandingBalance | currency}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptPayUnPaidInvoice()"
                        [disabled]="autoPayPending"></button>
                <button type="button" pButton label="{{'no' | translate}}"
                        (click)="payUnPaidInvoiceDialog = false"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="" [style]="{'width':'50vw'}" [(visible)]="showDialogInfoFee" modal='modal' [modal]='true'>
    <div>
        <p>
            {{'the_additional' | translate}}
        </p>
    </div>
    <p-footer>
        <div class="ui-g-12" align="right">
            <button type="button" pButton label="{{'Ok' | translate}}" (click)="closeDialogInfoFee()"></button>
        </div>
    </p-footer>
</p-dialog>
