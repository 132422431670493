import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { TransactionType } from '../model/transaction-type.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class TransactionTypeService extends AbstractService<TransactionType> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.OPERATOR, '');
    }

    getTransactionTypeListByPage(params: any): Observable<Page<TransactionType>> {
        return this._http.get<Page<TransactionType>>(this.baseUrl + Constants.URI.TRANSACTIONTYPE.LISTALLBYPAGE, { params: params }).pipe(map(resp => resp));
    }

    findAllTransactionType(params?: any): Observable<TransactionType[]> {
        return this._http.get<any>(`${this.baseUrlV2}transaction-type/search`, { params }).pipe(map(resp => resp));
    }

    getAll(params?: any): Observable<TransactionType[]> {
        return this._http.get<any>(`${environment.v2_server_ip}/operation-service/transactionDetailses/${params.companyId}/transactionType`).pipe(map(resp => resp));
    }

    createTransactionType (data:any) : Observable<TransactionType> {
        return this._http.post<TransactionType>(`${this.baseUrlV2}transaction-type`, data).pipe(map(res => res));
    }

    getTransactionType (id:number): Observable<TransactionType> {
        return this._http.get<TransactionType>(`${this.baseUrlV2}transaction-type/${id}`).pipe(map(resp => resp));  
    }

    updateTransactionType (data:any): Observable<TransactionType> {
        return this._http.put<TransactionType>(`${this.baseUrlV2}transaction-type`, data).pipe(map(res => res)); 
    }

    deleteTransactionType (id:number){
        return this._http.delete(`${this.baseUrlV2}transaction-type/${id}`).pipe(map(res => res)); 
    }

    getDropdown(options?:any) {
        let params = this.createParams(options);
        return this._http.get(this.baseUrlV2 + 'transaction-type/dropdown', {params :params}).pipe(map(res => res));
    }
}
