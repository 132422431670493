import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { Note } from 'app/note/model/note.model';

@Component({
  selector: 'app-note-content',
  templateUrl: './note-content.component.html',
  styleUrls: ['./note-content.component.scss']
})
export class NoteContentComponent implements OnInit {
  @Input() data: Note;
  @Output() onTextChange = new EventEmitter<string>();
  @Output() onSendUpdateRequest = new EventEmitter<string>();
  typingTimer: any;
  currentVal: any;
  @ViewChild('noteEditor', { static: true }) noteEditor: any;
  constructor() { }

  ngOnInit() {
  }

  textChanged(event) {
    this.onTextChange.emit(event)
    clearTimeout(this.typingTimer);
    // this.noteEditor.setSelection(this.currentVal);
    this.typingTimer = setTimeout(() => this.onSendUpdateRequest.emit(), 500);
  }

  setEditor($event: any) {
    this.noteEditor = $event.editor;
  }

  boldMention(event) {
    if (event.range) {
      this.currentVal = event.range.index;
    }
  }
}
