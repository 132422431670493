import {Injectable} from '@angular/core';
import {ExportMonitor} from '../model/export-monitor.model';
import {AbstractService} from '../../../shared/service/abstract.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../shared/model/constants';
import {Page} from '../../../shared/model/page.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExportMonitorService extends AbstractService<ExportMonitor> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.EXPORT_MONITOR, '');
    }

    getProcessListByPage(params: any): Observable<Page<ExportMonitor>> {
        return this.http.post<Page<ExportMonitor>>(this.baseUrlV2 + 'search', params).pipe(map(resp => resp));
    }

    exportPdf(params: any): Observable<Page<ExportMonitor>> {
        return this.http.post<Page<ExportMonitor>>(this.baseUrlV2 + 'pdf', params).pipe(map(resp => resp));
    }

}
