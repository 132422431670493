<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Payment Methods' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Payment Methods' | translate}}</span>
		</p-header>
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-6 ui-sm-12">
                    <label>{{'Auto Pay' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-triangle-exclamation"></i>
							</button>
						</div>
						<p-dropdown [options]="autoPays" [filter]="false" [(ngModel)]="autoPaySelected" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}" >
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
                <div class="ui-g-6 ui-sm-12">
                    <label>{{'Payment Method' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-wallet"></i>
							</button>
						</div>
						<p-dropdown [options]="cards" [filter]="true" [(ngModel)]="cardSelected" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-12 py-4 text-center">
					<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
					<button type="button" class="btn btn-primary mx-1" (click)="loadTable()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
				</div>
            </div>
        </div>
    </p-panel> 
	<h1 class="m-0 ml-2 my-4">{{'Browse Payment Methods' | translate}}</h1>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Payment Methods' | translate}} ({{totalRecords || 0 | number}})</span>
        </p-header>
        <p-table #dt [value]="transactionList" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10 ,25, 50]" [responsive]="true" [customSort]="true" >

			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap">
					<button type="button" (click)="exportTablePdf()" class="btn btn-primary mx-1">
						<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
					</button>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input [(ngModel)]="searchText" #searchKeyInput placeholder="{{'Filter' | translate}}" (keyup)="loadTable()" pInputText>
					</div>
				</div>
			</ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>#</th>
                    <th class="adjust-padding" pResizableColumn *ngFor="let col of cols" [ngStyle]="{'display': col.display, 'width': col.width}">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'expirationDate'">
								<div class="d-flex justify-content-between align-items-center w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</div>
							</span>
						</ng-container>  
					</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td>
						 {{i+1}}
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'name' | translate}}: </span>
							<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}">{{rowData.clientName}}</span>
							<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Card Type' | translate}}: </span>
							<span *ngIf="rowData.paymentCardType" pTooltip="{{rowData.paymentCardType}}">{{rowData.paymentCardType}}</span>
							<span *ngIf="!rowData.paymentCardType" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Expires' | translate}}: </span>
							<span *ngIf="rowData.expirationDate" pTooltip="{{rowData.expirationDate}}">{{rowData.expirationDate}}</span>
							<span *ngIf="!rowData.expirationDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Autopay' | translate}}: </span>
							<span *ngIf="rowData.autoPay === 'No' || !rowData.balance">{{rowData.autoPay}}</span>
							<a *ngIf="rowData.autoPay === 'Yes' && rowData.balance" class="cursor-pointer" (click)="autoPay(rowData)">{{rowData.autoPay}}</a>
							<span *ngIf="!rowData.autoPay" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                </tr>
            </ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="5" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
        </p-table>
    </p-panel>
</div>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showAutoPayConfirmDialog" class="modal_in"
    [modal]='true' (onHide)="closeAutoPay()">
    <div class="ui-g">
        {{clientAutoPaySelected?.clientName}} have an outstanding balance of ${{clientAutoPaySelected?.balance | number : '1.2-2'}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'Pay Now' | translate}}" (click)="acceptAutoPay()" [disabled]="loading"></button>
                <button type="button" pButton label="{{'Cancel' | translate}}" (click)="closeAutoPay()" [disabled]="loading"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

