import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {SignaturePad} from "angular2-signaturepad/signature-pad";
import {PaymentProfileService} from "../../service/payment-profile.service";

@Component({
  selector: 'app-esignature-dialog',
  templateUrl: './esignature-dialog.component.html',
  styleUrls: ['./esignature-dialog.component.scss']
})
export class EsignatureDialogComponent implements OnInit {
    
    @Input()
    display: boolean;
    @Input()
    transferData;
    
    @Input()
    isOnlyCharged: boolean;
    @Output()
    displayChange = new EventEmitter();
    
    @ViewChild('signaturePad') signaturePad: SignaturePad;
    
    signatureBy;
    signatureName;
    
    showSignature = false;
    signatureUrl;
    
    signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 400,
        canvasHeight: 200,
    };
    
    constructor(
        private paymentProfileService: PaymentProfileService,
        //private contractService: ContractServ,
    ) {}
    
    ngOnInit() {}
    
    onClickClose(): void {
        //this.displayChange.emit(false);
    }
    
    onSubmit() {
        this.transferData.signatureBy = this.signatureBy;
        this.transferData.signatureName = this.signatureName;
        
        const file: any = this.signaturePad.toDataURL();
        
        if (file) {
            const blob: any = this.dataURItoBlob(file);
            /*this.contractService.upload(blob).subscribe((res: any) => {
                this.transferData.signatureUrl = res.uploadedFileUrl;
                if (this.isOnlyCharged) {
                    this.paymentProfileService.applyChargeAmount(this.transferData).subscribe((res: any) => {
                        if (res && res.transactionId > 0) {
                            this.notificationService.open({
                                type: NotificationType.SUCCESS,
                                body: 'Payment has been Processed',
                            });
                            this.displayChange.emit(res);
                        } else {
                            if (res && res.responeObj && res.responeObj.errors) {
                                this.notificationService.open({
                                    type: NotificationType.ERROR,
                                    body: res.responeObj.errors.error[0].errorText,
                                });
                            }
                        }
                    })
                } else {
                    this.paymentProfileService.applyTransferAmount(this.transferData).subscribe((res: any) => {
                        if (res && res.transactionId > 0) {
                            this.notificationService.open({
                                type: NotificationType.SUCCESS,
                                body: 'Payment has been Processed',
                            });
                            this.displayChange.emit(res);
                        } else {
                            if (res && res.responeObj && res.responeObj.errors) {
                                this.notificationService.open({
                                    type: NotificationType.ERROR,
                                    body: res.responeObj.errors.error[0].errorText,
                                });
                            }
                        }
                        
                    })
                }
                
            });*/
        } else {
            if (this.isOnlyCharged) {
                this.paymentProfileService.applyChargeAmount(this.transferData).subscribe((res: any) => {
                    /*if (res && res.transactionId > 0) {
                        this.notificationService.open({
                            type: NotificationType.SUCCESS,
                            body: 'Payment has been Processed',
                        });
                        this.displayChange.emit(res);
                    } else {
                        if (res && res.responeObj && res.responeObj.errors) {
                            this.notificationService.open({
                                type: NotificationType.ERROR,
                                body: res.responeObj.errors.error[0].errorText,
                            });
                        }
                    }*/
                })
            } else {
                this.paymentProfileService.applyTransferAmount(this.transferData).subscribe((res: any) => {
                    /*if (res && res.transactionId > 0) {
                        this.notificationService.open({
                            type: NotificationType.SUCCESS,
                            body: 'Payment has been Processed',
                        });
                        this.displayChange.emit(res);
                    } else {
                        if (res && res.responeObj && res.responeObj.errors) {
                            this.notificationService.open({
                                type: NotificationType.ERROR,
                                body: res.responeObj.errors.error[0].errorText,
                            });
                        }
                    }*/
                    
                })
            }
        }
    }
    
    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = this.unescape(dataURI.split(',')[1]);
        
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        
        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ia], {type: mimeString});
    }


    unescape(str: string): string {
        return str.replace(/%(?![\da-f]{2})/gi, function () {
          // If the match is not a valid percent-encoded string, return the original match
          return '%25';
        }).replace(/%([\da-f]{2})/gi, function (match, code) {
          // Convert the percent-encoded string to its corresponding character
          return String.fromCharCode(parseInt(code, 16));
        });
    }
}
