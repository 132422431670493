import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appThousandSeparator2Decima]'
})
export class ThousandSeparator2DecimaDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/);  
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value?.replace(/,/g, '') || '';
    const next: string = current.concat(event.key).replace(/,/g, '');
    
    if (next.includes('.') && next.split('.')[1].length > 2) {
      event.preventDefault();
      return;
    }
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/,/g, '');
    if (value && !isNaN(+value)) {
      this.control.control?.setValue(this.formatNumber(value));
    }
  }

  private formatNumber(value: string): string {
    if (!value) {
      return '';
    }
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}