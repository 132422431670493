<p-toast position="bottom-right">{{msgs}}</p-toast>
<p-panel header="{{'summary_view' | translate}}">
    <div class="ui-g">
        <div class="ui-g-12">
            <div class="ui-g-4 pb-0" *ngIf="isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">{{'company.name' | translate}}:</div>
                    <p-dropdown [options]="companies" [(ngModel)]="selectedCompany" (ngModelChange)="loadAgencies($event)" [showClear]="true"
                        class="flex-auto" [style]="{'width': '100%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}" [disabled]="disableCompany()">
                    </p-dropdown>
                </div>
            </div>
            <!-- <div class="ui-g-4 pb-0" *ngIf="isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">Agency:</div>
                    <p-dropdown [options]="agencies" [(ngModel)]="selectedAgency" [style]="{'width': '100%'}"
                        class="flex-auto" placeholder="Please select" [disabled]="disableAgency()" (onChange)="changeAgency($event)"></p-dropdown>
                </div>
            </div> -->
            <div class="ui-g-4 pb-0" *ngIf="!isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">{{'ViewBy' | translate}}:</div>
                    <p-dropdown [options]="viewBy" [(ngModel)]="selectedViewBy" [style]="{'width': '100%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="viewByChange($event)"
                        class="flex-auto" [disabled]="disableAll">
                        <ng-template pTemplate="item" let-item>
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            {{item.label | translate}}
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-4 pb-0" *ngIf="!isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3"></div>
                </div>
            </div>
            <div class="ui-g-4 pb-0">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">{{'employeeName' | translate}}:</div>
                    <p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" [options]="listGroups" class="flex-auto" [(ngModel)]="historyFilter.employees" [style]="{'width': '100%'}"
                        (onChange)="searchBySelectedEmployees($event)" [disabled]="disableEmployee() || disableAll" id="id_filteredEmployeeList_to_selected_label">
                    </p-multiSelect>
                </div>
            </div>
            <div class="ui-g-4">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex" id="week">
                    <div class="flex-none mr-3">{{'weekDate' | translate}}:</div>
                    <p-calendar id="end-date" [showIcon]="true" class="flex-auto" [(ngModel)]="payrollDate" selectionMode="range" [style]="{'width': '100%'}"
                        [disabled]="disableCalField || disableAll" [readonlyInput]="true" [disabledDays]="daysDisable" [maxDate]="maxDateForHistory"
                        (onSelect)="selectWeek($event)"></p-calendar>
                </div>
            </div>
            <div class="ui-g-4" *ngIf="isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">{{'ViewBy' | translate}}:</div>
                    <p-dropdown [options]="viewBy" [(ngModel)]="selectedViewBy" [style]="{'width': '100%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="viewByChange($event)"
                        class="flex-auto" [disabled]="disableAll">
                        <ng-template pTemplate="item" let-item>
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            {{item.label | translate}}
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g-4" *ngIf="!isPlatformAdmin">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3"></div>
                </div>
            </div>
            <div class="ui-g-4">
                <div class="ui-g-12 p-0 ui-lg-12 d-flex">
                    <div class="flex-none mr-3">{{'department.name' | translate}}:</div>
                    <p-dropdown [options]="departments" [style]="{'width': '100%'}" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="searchByDepartment($event)"
                        class="flex-auto" [disabled]="disableAll"></p-dropdown>
                </div>
            </div>
            <div class="ui-g-12 text-center">
                <button pButton label="{{'button.search' | translate}}" (click)="onSearch()"></button>
                <button pButton label="{{'button.reset' | translate}}" (click)="onReset()"></button>
            </div>
        </div>
    </div>
    <p-toast position="bottom-right">{{msgs}}</p-toast>
    <p-contextMenu #cm [model]="items"></p-contextMenu>

    <p-table #dt header="Index" [value]="summaryViewList" dataKey="id" [customSort]="true" [lazy]="true" (onLazyLoad)="loadSummaryViewyLazy($event)"
        [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,20]" [totalRecords]="totalRecords" [style]="{'margin-bottom':'20px'}"
        class="pioh-data" [(contextMenuSelection)]="selectedSummaryView" [contextMenu]="cm" [responsive]="true">
        <ng-template pTemplate="caption">
            <div style="text-align: right">
                <i class="fa-solid fa-magnifying-glass" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="{{'search' | translate}}" style="width:auto" (keyup)="search($event)">
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>{{'SNo' | translate}}
                </th>
                <th>{{'department.name' | translate}}
                    <span (click)="op1.toggle($event); selectedField = 'emp.employee.department';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th>{{'firstName' | translate}}
                    <span (click)="op1.toggle($event); selectedField = 'emp.employee.firstName';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th>{{'lastName' | translate}}
                    <span (click)="op1.toggle($event); selectedField = 'emp.employee.lastName';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th>{{'weekDate' | translate}}
                </th>
                <th>{{'ScheduleHours' | translate}}
                    <span (click)="op2.toggle($event); selectedField = 'emp.employee.weeklyHours';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th>{{'TotalHoursWorked' | translate}}
                    <span (click)="op2.toggle($event); selectedField = 'emp.employee.completeHoursInWeak';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th *ngIf="!showShort" class="text-nowrap">{{'ShortTimeHistory' | translate}}
                    <span (click)="op2.toggle($event); selectedField = 'emp.employee.completeHours';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
                <th *ngIf="showShort" class="text-nowrap">{{'ShortTimePrediction' | translate}}
                    <span (click)="op2.toggle($event); selectedField = 'emp.employee.completeHours';">
                        <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                    </span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
                <td>{{i+1}}</td>
                <td>{{rowData.emp.employee.department}}</td>
                <td (click)="openSummaryView(rowData)" title="click to view details" style="cursor: pointer;"> {{rowData.emp.employee.firstName}}</td>
                <td (click)="openSummaryView(rowData)" title="click to view details" style="cursor: pointer;">{{rowData.emp.employee.lastName}}</td>
                <td>{{payrollDate[0] | date: 'MM/dd/yyyy'}} - {{payrollDate[1] | date: 'MM/dd/yyyy'}}</td>
                <td>{{rowData.weeklyhoursInMinuteStr}}</td>
                <td>{{rowData.completeHoursInWeakStr}}</td>
                <td>{{rowData.shortHoursStr}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
            <tr>
                <td align="right" colspan="4">
                    <b>{{'total' | translate}}:</b>
                </td>
                <td>{{getTotalDay(summaryViewList)}}</td>
                <td class="bold-text">{{totalWeeklyhoursInMinute}}</td>
                <td class="bold-text">{{totalWeeklyhoursWorked}}</td>
                <td>{{totalCompleteHours}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="text-center">
              <td [attr.colspan]="8">
                  {{'noData' | translate}}
              </td>
            </tr>
            <tr *ngIf="loading" class="text-center">
              <td [attr.colspan]="8">
                  {{'noData' | translate}}
              </td>
            </tr>
          </ng-template>
    </p-table>
<p-overlayPanel #op1 appendTo="body">
    <p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
    <p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
</p-panel>

<!-- new added -->
<p-dialog [showHeader]="false" [(visible)]="showSummaryViewDetails" modal="modal" [dismissableMask]="true"
    showEffect="fade" width="1000">
    <p-panel>
        <p-header>
            <span>{{'SummaryViewDetails' | translate}}</span>
        </p-header>
        <div class="ui-g">
            <h6>{{'ID' | translate}}:{{selectedSummaryData?.employeeOT.employeeId}}</h6>
        </div>
        <br>
        <div class="ui-g">
            <div class="ui-g-6">
                <div class="ui-g">
                    <div class="ui-g-7" style="text-align: right;">
                        <h6>{{'employee.eName' | translate}}:</h6>
                    </div>
                    <div class="ui-g-5">
                        <h6> {{selectedSummaryData?.employeeOT.employeeName}}</h6>
                    </div>
                </div>
            </div>
            <div class="ui-g-6">
                <div class="ui-g">
                    <div class="ui-g-5" style="text-align: right;">
                        <h6>{{'weekDate' | translate}} :</h6>
                    </div>
                    <div class="ui-g-7">
                        <p-calendar id="end-date" [showIcon]="true" [(ngModel)]="payrollDate" selectionMode="range" [style]="{'width': '100%'}"
                        [disabled]="disableCalField || disableAll" [readonlyInput]="true" [disabledDays]="daysDisable" [maxDate]="maxDateForHistory"
                        (onSelect)="selectWeek($event)"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="ui-g">
                <!-- for employee summary view starts-->
                <p-table [value]="employeeSummaryDetails">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>{{'SNo' | translate}}</th>
                            <th>{{'weekDate' | translate}} </th>
                            <th>{{'ScheduleHours' | translate}}
                                <span (click)="op3.toggle($event); selectedSummaryField = 'scheduledTime';">
                                    <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th>{{'TotalHoursWorked' | translate}}
                                <span (click)="op3.toggle($event); selectedSummaryField = 'workedTime';">
                                    <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th *ngIf="!showShort" class="text-nowrap">{{'ShortTimeHistory' | translate}}
                                <span (click)="op3.toggle($event); selectedSummaryField = 'shortHours';">
                                    <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th *ngIf="showShort" class="text-nowrap">{{'ShortTimePrediction' | translate}}
                                <span
                                    (click)="op3.toggle($event); selectedSummaryField = 'shortHours';">
                                    <i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
                                </span>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <td>{{i+1}}</td>
                            <td>{{rowData.weekDate | date: 'MM/dd/yyyy EEE'}}</td>
                            <td>{{rowData.scheduledTimeStr}}</td>
                            <td>{{rowData.workedTimeStr}}</td>
                            <td>{{rowData.shortHoursStr}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" let-columns>
                        <tr>
                            <td align="right"><b>{{'total' | translat:</b> </td>
                            <td>7 {{'Record' | translate}}</td>
                            <td class="bold-text">{{selectedSummaryData?.weeklyhoursInMinuteStr}}</td>
                            <td class="bold-text">{{selectedSummaryData?.completeHoursInWeakStr}}</td>
                            <td>{{selectedSummaryData?.shortHoursStr}}</td>
                        </tr>
                    </ng-template>
                </p-table>

                <p-overlayPanel #op3 appendTo="body">
                    <p-listbox [options]="textSortOptionsClient" [(ngModel)]="summarySelectedSortOption"
                        (onChange)="summarySortingClick(summarySelectedSortOption)">
                        <ng-template let-col pTemplate="item">
                            <div>
                                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                                <span>{{col.name | translate}}</span>
                            </div>
                        </ng-template>

                    </p-listbox>
                </p-overlayPanel>

                <!-- for employee summary view ends-->
            </div>

            <div class="ui-g-12 text-center">
                <button pButton label="{{'button.close' | translate}}" (click)="closeModal()"></button>
            </div>
        </div>


    </p-panel>
</p-dialog>