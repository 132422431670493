import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component_v1.html'
})
export class UserAgreementComponent implements OnInit {

  constructor(public app: AppComponent
  ) {
  }

  ngOnInit() {
  }
}
