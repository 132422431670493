import { Company } from "../../company/model/company.model";

export class TransactionType {
    id: number;
    transactionType: string;
    companyId: number;
    createdBy: number;
    status: string;
    creatorName: string;
}
