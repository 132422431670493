import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'app/crm/service/client.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { FileUpload, LazyLoadEvent, MessageService, Table } from 'primeng';
import { ClientBillingReportService } from '../../service/client-billing-report.service';
import { BillingUploadHistoryService } from '../../service/billing-upload-history.service';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { FileUtility } from 'app/shared/utility/file.utility';
import { DatePipe, formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { BillingClientStatusService } from '../../service/billing-client-status.service';
import { TranslatePipe } from "@ngx-translate/core";
import { UploadEvent } from '../../model/upload-event.model';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-client-billing-report',
  templateUrl: './client-billing-report.component.html',
  styleUrls: ['./client-billing-report.component.scss']
})
export class ClientBillingReportComponent implements OnInit, OnDestroy {
  showProgressBar = false;
  showProgressReasonFail = false;
  showProgressBarPage = false;
  showProgressBarForNote = false;
  showProgressBarOnInitialLoad = false;
  @ViewChild('fileUpload') fileUpload: FileUpload;
  showProcessComplete = false;
  progressBarValue = 0;
  excel: {
    csv2json: any;
    excel2json: any;
    json2excel: any;
  };
  notSavedCount: number;
  loading: boolean = true;
  companySelected: any;
  downloadSelected: any;
  isPlatformAdmin: any;
  companies: any[];
  totalRecords: number = 0;
  billingReports: any[];
  showConfirmDeleteDialog = false;
  selectedVideo: any;
  fromDate: Date;
  toDate: Date;
  dataUpdateList: any;
  createdByList: any[];
  createdSelected: any;
  uploadDateSelected: any;
  uploadDateList: any;
  isClientAccount: boolean = false;
  isChooseClient: boolean = true;
  isChooseClientUpload: boolean = true;
  downloadEnable: boolean = true;
  clientSelectedUpload: any;
  totalPaidAmount: number = 0;
  showProcessUpdateComplete = false;
  previousUpdatedDate: any;
  totalUpdatedRecords: number = 0;
  totalNewRecords: number = 0;
  isPreviousUpdatedDate = false;
  searchText: any;
  cols = [
    { field: '#', label: '#', width: '5%' },
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'serviceDate', label: 'Service Date', sortOptions: '', sort: 'number' },
    { field: 'invoiceNumber', label: 'Invoice Number', sortOptions: '', sort: 'number' },
    { field: 'cin', label: 'CIN', sortOptions: '', sort: 'text' },
    { field: 'paNumber', label: 'PA Number', sortOptions: '', sort: 'number' },
    { field: 'patientName', label: 'Patient Name', sortOptions: '', sort: 'text' },
    { field: 'paidAmount', label: 'Paid Amount', sortOptions: '', sort: 'number' },
    { field: 'createdByUsr', label: 'Uploader', sortOptions: '', sort: 'text' },
    { field: 'createdAt', label: 'Uploaded', sortOptions: '', sort: 'number' },
    { field: 'actions', label: 'Actions', width: '8%' }
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  fileTypes = [
    { name: 'Excel', value: 'excel' },
    { name: 'PDF', value: 'pdf' }
  ];

  @ViewChild('dt', { static: true }) table: Table;
  selectedSortOption = 'ASC';
  selectedField = 'serviceDate';
  clients: any[];
  clientList: any[];
  clientSelected: any;
  showUploaded: boolean = false;
  chooseClient: any;
  showConfirmDialog: boolean;
  showConfirmUpdateDialog: boolean;
  isPermitted: boolean = false;
  billingRecordId: any;
  sortField: string;
  size: any;
  page: any;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();
  totalFileUpload: any = 0;
  uploadedFiles: any = [];

  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private datePipe: DatePipe,
    private exportMonitorService: ExportMonitorService,
    private messageService: MessageService, private clientService: ClientService,
    private billingReportService: ClientBillingReportService,
    private billingUploadHistoryService: BillingUploadHistoryService,
    private billingClientStatusService: BillingClientStatusService,
    private router: Router,
    private translatePipe: TranslatePipe,
    private decimalPipe: DecimalPipe,
    private monitoringDetailsService: MonitoringDetailsService) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-report') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Billing Report`,
            this.timeSpent,
            {
              navigated_to_billing_report_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Billing Report`,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.dataUpdateList = [];
    this.isPermitted = this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole() || this.authService.isCompanyAd();
    this.excel = require('assets/libs/js2excel.min.js');
    this.loading = true;
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.loadAllCompanyList();
    this.getClientList();
    if (this.authService.isClientRole()) {
      this.clientSelected = this.authService.getUserInfo().adminId;
      this.clientSelectedUpload = this.authService.getUserInfo().adminId;
      this.isChooseClient = false;
      this.isChooseClientUpload = false;
      // this.downloadEnable = true;
      this.isClientAccount = true;
      this.cols = [
        { field: '#', label: '#', width: '5%' },
        { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
        { field: 'serviceDate', label: 'Service Date', sortOptions: '', sort: 'number' },
        { field: 'invoiceNumber', label: 'Invoice Number', sortOptions: '', sort: 'number' },
        { field: 'cin', label: 'CIN', sortOptions: '', sort: 'text' },
        { field: 'paNumber', label: 'PA Number', sortOptions: '', sort: 'number' },
        { field: 'patientName', label: 'Patient Name', sortOptions: '', sort: 'text' },
        { field: 'paidAmount', label: 'Paid Amount', sortOptions: '', sort: 'text' },
        { field: 'createdAt', label: 'Uploaded', sortOptions: '', sort: 'number' },
        { field: 'createdByUsr', label: 'Uploader', sortOptions: '', sort: 'text' }
      ];
    } else {
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }
    // this.search();
    this.getCreatedByList();
    this.loadUploadedDateList('init');
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000);
  }

  loadBillingReports(event?: LazyLoadEvent) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : 0;
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options: any = {};
    options = {
      size: this.size,
      page: this.page,
      sortOrder: 'DESC',
      sortField: 'serviceDate'
    };
    this.loadPage(options);
  }

  search() {
    this.loadBillingReports();
  }

  loadPage(options: any) {
    this.downloadSelected = null;
    this.totalPaidAmount = 0;
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    if (this.uploadDateSelected) {
      options.uploadStartDate = this.uploadDateSelected;
      options.uploadEndDate = this.uploadDateSelected;
    }
    if (this.createdSelected) options.createdBy = this.createdSelected;
    if (this.clientSelected) options.clientId = this.clientSelected;
    if (this.searchText) options.searchText = this.searchText;
    this.billingReportService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.billingReports.forEach(ele => {
          ele.showHideActions = (moment(moment().toDate()).diff(ele.createdAt, 'minutes') >= 1440);
          if (!isNaN(Number(ele.paidAmount))) {
            this.totalPaidAmount += Number(ele.paidAmount);
          } else {
            console.log("Not a Number" + ele.paidAmount);
          }
        });
        // this.sortingClick(this.selectedSortOption);
        this.totalRecords = resObj.data.totalElements;
        if (this.totalRecords === 0) {
          this.totalPaidAmount = 0;
        }
        this.loading = false;
      }
    });
  }

  reset() {
    this.fromDate = null;
    this.dataUpdateList = [];
    this.toDate = null;
    this.createdSelected = null;
    this.uploadDateSelected = this.uploadDateList ? this.uploadDateList[0].value : null;
    this.downloadSelected = null;
    this.searchText = null;
    this.billingReports = [];
    if (!this.authService.isClientRole()) {
      // this.downloadEnable = false;
      this.companySelected = null;
      this.clientSelected = null;
      this.clientSelectedUpload = null;
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }
    if (this.clientSelected) {
      this.clientSelected = null;
      this.loadUploadedDateList('reset');
      this.getCreatedByList();
    } else {
      this.search();
    }
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  onChangeClient(event) {
    this.loadUploadedDateList('');
    this.getCreatedByList();
    if (!this.clientSelectedUpload) {
      this.clientSelectedUpload = this.clientSelected;
    }
  }

  loadUploadedDateList(type) {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    options.companyId = options.companyId || 0;
    this.billingReportService.getUploadedDateList(options).subscribe((res) => {
      const resObj: any = res;
      const tempUploadDateList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        resObj.data.forEach((date) => {
          tempUploadDateList.push({ label: moment(date).format('MM/DD/YYYY'), value: moment(date, 'YYYY-MM-DD').toDate().getTime() });
        });
        this.uploadDateList = tempUploadDateList;
        this.uploadDateList.sort((a, b) => b.value - a.value);
        this.uploadDateSelected = this.uploadDateList[0].value;
      } else {
        this.uploadDateList = [];
      }
      if (type === 'reset' || type === 'init') {
        this.search();
      }
    }, () => {
      if (type === 'reset' || type === 'init') {
        this.search();
      }
    }
    );
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    if (this.companySelected) { options.companyId = this.companySelected; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }

  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.clientSelected) options.clientId = this.clientSelected;
    options.companyId = options.companyId || 0;
    this.billingReportService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    })
  }

  chooseCompany(event) {
    this.getClientList();
  }

  exportClientList(type) {
    if (type === 'excel') {
      this.downloadExcel();
    }
    if (type === 'pdf') {
      this.exportPdf();
    }
  }


  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.search();
    // this.downloadEnable = true;
  }

  async importReports(event) {
    this.totalFileUpload = event.files.length;
    const date = new Date();
    this.dataUpdateList = [];
    const loggedInUser = JSON.parse(localStorage.getItem('_globals'));
    const dataToBeSaved = [];
    const allData = [];

    /* wire up file reader */
    const filePromises = event.files.map((file) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true, dateNF: 'mm/dd/yyyy;@' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          /* save data */
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          let output = {};
          (data).forEach((item: any) => {
            const isEmpty = Object.values(item).every(x => (x === null || x === ''));
            if (!isEmpty) {
              let output = {};
              for (let key in item) {
                output[_.camelCase(key)] = item[key];
              }
              allData.push(output);
            }
          });
          // Resolve the promise with the response value
          resolve(reader.result)
        }
      });
    });

    // Wait for all promises to be resolved
    await Promise.all(filePromises);
    const uniqueArray = allData.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === allData.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });
    let uploadHistory: any = {
      createdByUsr: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      startDate: new Date().getTime(),
      reportType: 'BILLING_REPORT'
    }

    let billingClientStatus: any = {
      billingReportUploadedBy: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      billingReportUpTime: new Date().getTime(),
      startDate: moment(new Date().getTime()).utc(true).startOf('week').toDate(),
      endDate: moment(new Date().getTime()).utc(true).endOf('week').toDate(),
      type: 2
    };

    uniqueArray.forEach((item: any) => {
      if (!item.invoiceNumber && !item.paNumber && !item.cin && !item.patientName && !item.serviceDate) {
        console.log('empty', item)
      } else {
        item.clientId = this.clientSelectedUpload
        item.companyId = this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId();
        item.paNumber = item.paNumber
        item.invoiceNumber = item.invoiceNumber
        item.cin = item.cin
        item.patientName = item.patientName;
        item.paidAmount = item.paidAmount;
        item.uploadedBy = loggedInUser.adminId;
        item.createdByUsr = loggedInUser.username;
        item.serviceDate = moment(item.serviceDate).toDate();
        dataToBeSaved.push(item);
      }
    });

    const invoiceNumbers = dataToBeSaved.map(c => c.invoiceNumber);
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
    if (findDuplicates(invoiceNumbers).length > 0) {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Uploading failed, duplicate Invoice Number:') + ' ' + findDuplicates(invoiceNumbers)
      });
      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });

      return;
    }

    if (dataToBeSaved.length > 0) {
      // validate invoice number
      this.billingReportService.validateDuplidateInvoiceNumber(this.clientSelectedUpload,
        dataToBeSaved[0].invoiceNumber).subscribe(async res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            let validateResult = resObj.data;
            if (validateResult.duplicated) {
              if (validateResult.clientId !== this.clientSelectedUpload) {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translatePipe.transform('Failed'),
                  detail: this.translatePipe.transform('File was already uploaded for ' + this.getClient(validateResult.clientId))
                });
                return;
              } else {
                this.dataUpdateList = dataToBeSaved;
                this.showConfirmUpdateDialog = true;
              }
            } else {
              await this.uploadAndMail(dataToBeSaved);
            }
          }
        });
    } else {
      uploadHistory.status = 'FAILED';
      uploadHistory.endDate = new Date().getTime();
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Invalid data please check file again!')
      });
      this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
      }, (error) => {
      });

      this.billingClientStatusService.save(billingClientStatus).subscribe((_response) => {
      }, (error) => {
      });
    }

    this.fileUpload.clear();
    const interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 300);

  }

  uploadAndMail(data: any) {
    this.showProgressBar = true;
    this.showProcessComplete = false;
    this.showConfirmUpdateDialog = false;
    const date = new Date();
    const loggedInUser = JSON.parse(localStorage.getItem('_globals'));
    let uploadHistory: any = {
      createdByUsr: loggedInUser.username,
      clientId: this.clientSelectedUpload,
      companyId: this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId(),
      startDate: new Date().getTime(),
      reportType: 'BILLING_REPORT'
    }
    let userType = this.authService.getUserType();
    this.billingReportService.uploadAndMail(data, userType, loggedInUser.username,
      moment(date).format('MM/DD/YYYY')).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          uploadHistory.status = 'SUCCESS';
          uploadHistory.endDate = new Date().getTime();
          this.showUploaded = false;
          if (res.data.previousUpdatedDate) {
            this.previousUpdatedDate = moment(res.data.previousUpdatedDate).format('MM/DD/YYYY');
            this.isPreviousUpdatedDate = true;
            uploadHistory.firstUpdateOn = res.data.previousUpdatedDate;
          }
          this.totalNewRecords = res.data.totalNewRecords;
          this.totalUpdatedRecords = res.data.totalUpdatedRecords;
          this.showProgressBar = false;
          this.showProcessUpdateComplete = true;

          uploadHistory.newRecords = res.data.totalNewRecords;
          uploadHistory.oldRecords = res.data.totalUpdatedRecords;
          const args = {
            billing_report_file_uploaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
          }
          const action = 'Billing Report File Uploaded'
          this.monitorDetails(args, action, date);
          this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
          }, (error) => {
          });

        } else {
          uploadHistory.status = 'FAILED';
          uploadHistory.endDate = new Date().getTime();
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: this.translatePipe.transform('Uploading Failed!') });
          this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
          }, (error) => {
          });

        }
        this.search();
      }, () => {
        this.showProgressBar = false;
        this.showProcessComplete = true;
        uploadHistory.status = 'FAILED';
        uploadHistory.endDate = new Date().getTime();
        this.billingUploadHistoryService.save(uploadHistory).subscribe((_response) => {
        }, (error) => {
        });

        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Failed'),
          detail: this.translatePipe.transform('Uploading Failed!')
        });
      });
  }

  sortingClick(selectedSortOption) {
    this.sortField = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      data: this.billingReports
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
          else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          return (event.order * result);
        });
      }
      this.sortField = ""
    }
  }

  setSortOption(field, selectedSortOption) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  accept() {
    this.billingReportService.delete(this.billingRecordId).subscribe((_response) => {
      this.search();
      this.messageService.add({
        severity: 'success',
        summary: this.translatePipe.transform('Success'),
        detail: this.translatePipe.transform('Deleted Successfully')
      });
      this.showConfirmDialog = false;
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('Delete Failed')
      });
    });
  }

  reject() {
    this.showConfirmDialog = false;
  }

  deleteRecord(data) {
    if (!this.isPermitted) {
      const showHideActions = (moment(moment().toDate()).diff(data.createdAt, 'minutes') >= 1440);
      if (showHideActions) {
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Information'),
          detail: this.translatePipe.transform(`Sorry you cannot delete the selected record after 24hrs`)
        });
        return;
      }
    }
    this.billingRecordId = data.id;
    this.showConfirmDialog = true;
  }

  exportPdf() {
    if (this.billingReports && this.billingReports.length) {
      const data: any = {
        contentList: this.billingReports.map(value => {
          return {
            serviceDate: this.datePipe.transform(value.serviceDate, 'MM/dd/yyyy'),
            invoiceNumber: value.invoiceNumber,
            cin: value.cin,
            paNumber: value.paNumber,
            patientName: value.patientName,
            paidAmount: formatNumber(value.paidAmount, 'en-US', '1.2-2')
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompany();
      } else {
        if (this.companySelected) data.companyId = this.companySelected.key;
      }
      if (this.authService.isClientRole()) {
        data.clientName = this.authService.getUserInfo()?.name;
      }
      data.username = this.authService.getCurrentUsername();
      data.totalPaid = this.decimalPipe.transform(this.totalPaidAmount, '1.2-2');
      this.billingReportService.exportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          let data = <any>{
            featureName: 'Client Billing Report',
            fileName: blobUrl,
            fileSize: blob.size,
            action: 'Export',
            createdByUsr: this.authService.getCurrentUsername(),
            companyId: this.authService.getCurrentCompanyId()
          };
          this.exportMonitorService.create(data).subscribe(() => {
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting client pdf list')
          });
        }
      });
    }
  }

  downloadExcel() {
    if (!this.clientSelected) {
      this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Please choose client'),
        detail: this.translatePipe.transform('Please choose client')
      });
      return;
    }

    let options = {
      size: 99999,
      page: 0,
      companyId: null,
      startDate: null,
      endDate: null,
      uploadStartDate: null,
      uploadEndDate: null,
      createdBy: this.createdSelected,
      clientId: this.clientSelected
    };
    if (this.fromDate) options.startDate = this.fromDate;
    if (this.toDate) options.endDate = this.toDate;
    if (this.uploadDateSelected) {
      options.uploadStartDate = this.uploadDateSelected;
      options.uploadEndDate = this.uploadDateSelected;
    }

    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    this.billingReportService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billingReports = data[i];
          const excelTrans: any = {};
          excelTrans['Service Date'] = this.datePipe.transform(billingReports.serviceDate, 'MM/dd/yyyy');
          excelTrans['Invoice Number'] = billingReports.invoiceNumber;
          excelTrans['CIN'] = billingReports.cin;
          excelTrans['PA Number'] = billingReports.paNumber;
          excelTrans['Patient Name'] = billingReports.patientName;
          excelTrans['Paid Amount'] = formatNumber(billingReports.paidAmount, 'en-US', '1.2-2');
          excelData.push(excelTrans);
        }
        this.billingReportService.exportAsExcelFile(excelData, 'BillingReport');
      }
    });
  }

  closeProcessCompleteDialog() {
    this.showProcessUpdateComplete = false;
  }

  uploadBtn() {
    this.showUploaded = true;
    const args = {
      billing_report_upload_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    }
    const action = 'Billing Report Upload Button Clicked'
    this.monitorDetails(args, action, new Date())
  }


  onSelectChange(event) {
    if (event.value.length === this.uploadDateList.length) {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = 'All';
    } else {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = `${event.value.length} items selected`;
    }
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      "complete",
      action,
      0
    );
  }

  getClient(id) {
    if (this.clientList) {
      const client = this.clientList.find(el => el.value === id);
      if (client) return client.label;
    }
  }

  acceptUpdate() {
    this.uploadAndMail(this.dataUpdateList);
  }

  rejectUpdate() {
    this.showConfirmUpdateDialog = false;
  }
}
