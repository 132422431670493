import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ServiceTypes} from '../../../shared/data/service-types'
import {CompanyService} from '../../service/company.service';
import * as _ from 'lodash';
import { ServiceType } from 'app/company/model/service-type.model';

@Component({
    selector: 'app-company-service-type',
    templateUrl: './company-service-type.component.html',
    styleUrls: ['./company-service-type.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CompanyServiceTypeComponent,
        multi: true,
    }],
})
export class CompanyServiceTypeComponent implements OnInit, ControlValueAccessor {

    @Input()
    dataType = 'number';

    @Input()
    placeholder = 'Login Type';

    @Input()
    required = false;

    onChange;

    inputControl = new UntypedFormControl(null);

    services:ServiceTypes = new ServiceTypes()
    options:any[];


    constructor(private companyService: CompanyService) {

    }

    ngOnInit() {
        this.placeholder = this.required ? `${this.placeholder} *` : this.placeholder;
        this.options = _.map(this.services.serviceType, s => {
            const option = {value: s.id, label: s.name};
                    return option;})
        ;
        }

    writeValue(value) {
        console.log(value);
        this.inputControl.setValue(value);
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputControl.disable();
        } else {
            this.inputControl.enable();
        }
    }

    registerOnTouched(fn: any): void {
    }

    onSelectionChange(event) {
        if (this.onChange) {
            this.onChange(event.value);
        }
    }

    showPanel() {
        const elements = document.getElementsByClassName("selectAll");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
        let nodes = document.getElementsByClassName('ui-multiselect-header');
        for (let i = 0; i < nodes.length; i ++) {
            const childs = nodes[i].childNodes;
            for (let j = 0; j < childs.length; j ++) {
                if (childs[j]["className"] && childs[j]["className"].indexOf("ui-chkbox") > -1) {
                    const span = document.createElement("SPAN");
                    span.style.cssText = "color: #fff;";
                    span.className = "selectAll";
                    const textnode = document.createTextNode("All");
                    span.appendChild(textnode);
                    childs[j].parentNode.appendChild(span);
                    break;
                }
            }
        }
    }
}
