export class JobFollowUpModel {
    id:number;
    companyId:number;
    cycle:String;
    fromDate:Date;
    toDate:Date;
    totalJobDetails:any;
    createdByUsr:String;
    lastModifiedBy:string;
    customColumnName?:any;
    note:string;
    customColumn?:any;
}