import {HttpClient, HttpEvent, HttpEventType, HttpHeaderResponse, HttpParams, HttpProgressEvent, HttpResponse, HttpUserEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map, scan} from 'rxjs/operators';
import { AbstractService } from '../../shared/service/abstract.service';
import { Constants } from '../../shared/model/constants';
import { environment } from 'environments/environment';

export interface UploadStatus<T> {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE' | 'SENT';
  body: T | null;
}

@Injectable({ providedIn: 'root' })
export class PayrollJournalReportService extends AbstractService<any> {
  notificationUrl: string;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PAYROLL_EMPLPOYEE_REPORT, '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }
  importData(payload: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}`, payload).pipe(map(resp => resp));
  }

  getList(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  searchHistory(options: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/search-history`, options).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}search-history`, options).pipe(map(resp => resp));
  }

  getId(id: Number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}${id}`)
      .pipe(map(res => res));
  }

  getHistoryId(id: Number): Observable<any> {
    // return this._http.get<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/history/${id}`).pipe(map(resp => resp));
    return this._http.get<any>(`${this.baseUrlV2}history/${id}`).pipe(map(res => res));
  }

  getCreatedByList(options: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('companyId', options.companyId);
    return this._http.get<any>(`${this.baseUrlV2}find-created-by-user`, { params }).pipe(map(resp => resp));
  }

  update(payload) {
    return this.http.post(`${this.baseUrlV2}update`, payload).pipe(map(resp => resp));
  }

  validateDuplicatePayrollPeriod(payload) {
    return this.http.post(`${this.baseUrlV2}validate-duplicate-period`, payload).pipe(map(resp => resp));
  }

  getAllPayrollPeriod(companyId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}payroll-period/${companyId}`).pipe(map(resp => resp));
  }

  hasPassword(companyId: number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}password/${companyId}`).pipe(map(resp => resp));
  }

  validatePassword(payload) {
    return this._http.post<any>(`${this.baseUrlV2}password/validate`, payload).pipe(map(resp => resp));
  }

  updatePassword(payload) {
    return this._http.post<any>(`${this.baseUrlV2}password`, payload).pipe(map(resp => resp));
  }

  resetPassword(companyId) {
    return this._http.post<any>(`${this.baseUrlV2}reset-password/${companyId}`, {}).pipe(map(resp => resp));
  }

  updateEmpId(payload) {
    return this._http.post<any>(`${this.baseUrlV2}update-emp-id`, payload).pipe(map(resp => resp));
  }

  sendEmail(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }

  searchDetailsByEmpId(empId: number, options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}employee/${empId}/search`, options).pipe(map(resp => resp));
  }

  getYtd(empId: number, payrollEmployeeScheduleId): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}ytd/${empId}?payrollEmployeeScheduleId=${payrollEmployeeScheduleId}`)
    .pipe(map(res => res));
  }

  getYtds(payload: {empId: number, payrollEmployeeScheduleId: number}[]): Observable<any> {
    // return this.http.post<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/ytd`, payload).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}ytd`, payload).pipe(map(res => res));
  }

  downloadAll(data:any){
    return this._http.post<any>(this.baseUrlV2 + 'download-all', data).pipe(map(res => res));
  }

  importDataNew(payload: any, isReplace?): Observable<UploadStatus<any>> {
    let url = this.baseUrlV2;
    if (isReplace) {
      url = url + 're-upload'
    }
    return this._http.post<any>(`${url}`, payload, {
      reportProgress: true,
      observe: 'events',
    }).pipe(scan((uploadStatus: any, httpEvent: HttpEvent<unknown>, index: number): any => {
      if (PayrollJournalReportService.isHttpResponse(httpEvent)) {
        return {
          progress: 100,
          state: 'DONE',
          body: httpEvent.body
        };
      }
      if (PayrollJournalReportService.isHttpSent(httpEvent)) {
        return {
          progress: 0,
          state: 'PENDING',
          body: null,
          httpEvent

        };
      }
      if (PayrollJournalReportService.isHttpUserEvent(httpEvent)) {
        return {
          progress: 0,
          state: 'PENDING',
          body: null
        };
      }
      if (PayrollJournalReportService.isHttpHeaderResponse(httpEvent)) {
        return {
          progress: 0,
          state: 'PENDING',
          body: null
        };
      }
      if (PayrollJournalReportService.isHttpProgressEvent(httpEvent)) {
        return {
          progress: Math.round((100 * index) / (payload?.payrollEmployeeReports?.size || 1)),
          state: 'IN_PROGRESS',
          body: null
        };
      }
      throw new Error('unknown HttpEvent');
    }, {state: 'PENDING', progress: 0, body: null} as UploadStatus<any>));
  }

  private static isHttpSent<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Sent;
  }

  private static isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }


  private static isHttpHeaderResponse<T>(event: HttpEvent<T>): event is HttpHeaderResponse {
    return event.type === HttpEventType.ResponseHeader;
  }


  private static isHttpUserEvent<T>(event: HttpEvent<T>): event is HttpUserEvent<T> {
    return event.type === HttpEventType.User;
  }

  private static isHttpProgressEvent(event: HttpEvent<Blob>): event is HttpProgressEvent {
    return (
        event.type === HttpEventType.DownloadProgress ||
        event.type === HttpEventType.UploadProgress
    );
  }

  getEmployeePaystubs(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}get-emp-paystubs`, options).pipe(map(resp => resp));
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/get-emp-paystubs`, options).pipe(map(resp => resp));
  }

  getEmployeePaystubsDropdown(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}get-emp-paystubs-dropdown`, options).pipe(map(resp => resp));
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/get-emp-paystubs-dropdown`, options).pipe(map(resp => resp));
  }

  saveBulkConfirmation(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}save-bulk-confirm`, options).pipe(map(resp => resp));
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/payroll-employee-report/save-bulk-confirm`, options).pipe(map(resp => resp));
  }
}
