import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {ProjectModel} from '../model/project.model';
import {Page} from '../../shared/model/page.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends AbstractService<ProjectModel> {

  constructor(private http: HttpClient) {
      super(http, Constants.ROUTES.OPERATOR + '/project', '');
  }

  getAllProjectList(options:any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`,options).pipe(map(resp => resp));
  }
  getClientHistoryPage(options?: any): Observable<Page<any>> {
    const params = this.createParams(options);
    return this._http.post<Page<any>>(`${this.baseUrlV2}history/search`, options).pipe(map(resp => resp));
  }
  getProjectDropdown(options:any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}dropdown`, options).pipe(map(resp => resp));
  }
  exportPdf(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }
}
