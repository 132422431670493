<form [formGroup]="polygonForm">
    <div class="ui-g p-3 pb-4">
		<div class="ui-g-3 ui-sm-6">
			<label>{{'Zone' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-location-dot"></i>
					</button>
				</div>
				<p-multiSelect [options]="zoneList" optionLabel="title" defaultLabel="{{'Select Items' | translate}}" maxSelectedLabels="1" emptyFilterMessage="{{'No results found' | translate}}" [(ngModel)]="selectedZones" (onChange)="onSelectedZoneChange()" formControlName="selectedZones">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
      	<div class="ui-g-3 ui-sm-6">
			<label>{{'Polygon' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-draw-polygon"></i>
					</button>
				</div>
				<p-multiSelect #dropdown maxSelectedLabels="1" class="w-100" [options]="polygonList" formControlName="selectedPolygonModel" [filter]="true"  emptyFilterMessage="{{'No results found' | translate}}" defaultLabel="{{'Find Polygon' | translate}}" (onChange)="onPolygonSelectionChange($event)">
					<ng-template pTemplate="item" let-item>
						<span class="one-liner" style="width: 93% !important">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
        	</div>
      	</div>

		<div class="ui-g-3 ui-sm-6">
			<label>{{'Name/Title' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div> 
				<input pInputText type="text" maxlength="100" formControlName="polygonName" placeholder="{{'Give it a name' | translate}}">
			</div>
		</div>
		<div class="ui-g-3 ui-sm-6">
			<label>{{'Color' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
						<i class="fa-solid fa-palette"></i>
					</button>
				</div>
				<p-colorPicker [style]="{width: '100%'}" formControlName="color"></p-colorPicker>
			</div>
		</div>
	</div>
	<div class="ui-g">
		<div class="ui-g-12 p-0">
			<agm-map class="map-container" [latitude]="40.7830603" [longitude]="-73.9712488">
				<custom-polygon-drawer-map  [editMode]="editMode" [zoneData]="zoneData" [lockedZonesPolygon]="lockedZonesPolygon" [zonePolygonGeoJson]="selectedZonePolygon" [statePolygonGeoJson]="selectedStatePolygon" [clearPolygons]="clearPolygons" [goBack]="polygonLength" (polygonChanged)="polygonChanged($event)" (pointClicked)="pointClicked($event)" [removedPoint]="selectedMovePoint"></custom-polygon-drawer-map>
			</agm-map>
		</div>
	</div>
	<div class="d-flex align-items-center justify-content-between w-100 p-3">
		<span>
			<button class="btn btn-danger mx-1" (click)="cancel()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button *ngIf="isCreateNew" type="submit" class="btn btn-orange mx-1" (click)="removeAll()" pTooltip="{{'Delete Polygon' | translate}}"><i class="fa-solid fa-trash mr-2"></i>{{'Polygon' | translate}}</button>
			<button *ngIf="selectedPoint" class="btn btn-danger mx-1" (click)="removePoint()"><i class="fa-solid fa-xmark mr-2"></i>{{'Point' | translate}}</button>
		</span>
		<!--<span class="checkwrap m-0">-->
			<!--<input type="checkbox" (change)="changeDisplayZones()" [(ngModel)]="showAllZone" binary="true" name="showAllZone" [ngModelOptions]="{standalone: true}">-->
			<!--<span class="checkmark"></span>-->
			<!--{{'Display Current Zones' | translate}}-->
		<!--</span>-->
        <!--<p-multiSelect #dropdown maxSelectedLabels="1" class="w-50" [options]="zoneTypeOption" formControlName="selectedPolygonModel" [filter]="true"  emptyFilterMessage="{{'No results found' | translate}}" defaultLabel="{{'Select Zone Type' | translate}}" (onChange)="onDisplayAllZoneToggle($event)">-->
            <!--<ng-template pTemplate="item" let-item>-->
                <!--<span class="one-liner" style="width: 93% !important">{{item.label | translate}}</span>-->
            <!--</ng-template>-->
        <!--</p-multiSelect>-->
		<div class="w-25">
			<div class="text-nowrap w-100">{{'Select Zone Types' | translate}}<i class="fa-solid fa-angle-right ml-5 cursor-pointer" (click)="displayZoneType.toggle($event)"></i></div>
		</div>
		<span>
			<button *ngIf="!editMode && !isCreateNew" class="btn btn-info mx-1" (click)="editZone()"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit' | translate}}</button>
			<button *ngIf="isCreateNew || editMode" type="submit" class="btn btn-primary" (click)="createOrUpdateZone()" [disabled]="!isSaveButtonEnabled || processing" id="btnSave"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</span>
	</div>
</form>

<p-overlayPanel #displayZoneType appendTo="body">
	<div class="ui-g p-2 turfToggle" style="width: 230px;">
		<div class="ui-g-12">
			<p-checkbox class="turfToggle" binary="true" (onChange)="onUnSelectAllZoneToggle($event)" label="{{'Unselect All' | translate}}" [(ngModel)]="unSelectChecked"></p-checkbox>
		</div>
		<div class="ui-g-12" *ngFor="let zType of zoneTypeOption; let i = index">
			<div *ngIf="!zoneTypeLoaded[zType.value]"><i class="fa-solid fa-spinner loadingHourglass mr-2"></i> <span class="">{{zType.label | translate}}</span></div>
			<p-checkbox *ngIf="zoneTypeLoaded[zType.value]" #checkboxRef class="turfToggle" binary="true" (onChange)="onDisplayAllZoneToggle(zType.value, $event, checkboxRef)" label="{{zType.label | translate}}"></p-checkbox>
		</div>
	</div>
</p-overlayPanel>
