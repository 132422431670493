export class Absence {
    employeeId: number;
    type: string;
    timeStart: Date;
    timeEnd: Date;
    noEventOnThisPeriod: boolean;
    applyToPayroll: boolean;
    note: string;
    ptoHoursApproved = 0;
    ptoValidate = 0;
    ptoAmount: number;
    timeZoneOffset: number;
    callOut: string;
    companyId: number;
    agencyId: number;
    approvalId: number;
    approvalTime: Date;
    documentId: any;
    dayOffType: string;
    lastModifiedBy: string;
    createdByUsr: string;
    employeeSignature?: string;
    adminSignature?: string;
}

export class AbsenseAccured {
    absenceType: string;
    ptoAccured: number;
    employeeId: number;
    toPeriod: Date;
    usedHours: number;
    availablePtoHours: number;
    pendingHours: number;
}
