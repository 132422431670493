import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login.component';

export const LoginRoutes: Routes = [
  {
    path: 'login', component: LoginComponent,
    children: [
      // {path: '', component: LoginComponent},
      // { path: '**', redirectTo: '/login', pathMatch: 'full' }
    ]
  }
];

export const LoginRoutingModule = RouterModule.forChild(LoginRoutes);
