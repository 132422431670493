import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';
import { DepartmentService } from '../department/service/department.service';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../shared/shared.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { OntimeLateComponent } from './component/ontime-late/ontime-late.component';
import { EmployeeClockingRoutingModule } from './employee-clocking-routing.module';
import { PunchInOutComponent } from './component/punch-in-out/punch-in-out.component';
import { PunchInOutHistoryComponent } from './component/punch-in-out-history/punch-in-out-history.component';
import { SummaryViewComponent } from './component/summary-view/summary-view.component';
import { EmployeeOTComponent } from './component/employee-ot/employee-ot.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EmployeeClockingLayoutComponent } from './component/employee-clocking-layout/employee-clocking-layout.component';
import { EmployeeServiceV2 } from 'app/employee/service/v2/employee.v2.service';
import { FaceRecognitionModule } from 'app/face-recognition/face-recognition.module';
import { EmployeeClockingTrackingServiceV2 } from './service/v2/employee.clocking.tracking.v2.service';
import { PunchInOutTrackingComponent } from './component/punch-in-out-tracking/punch-in-out-tracking.component';
import { AgmCoreModule } from "@agm/core";
import { AgmDrawingModule } from "@agm/drawing";
import { MapModule } from 'app/map/map.module';
import { ClockingAutorizationCodeService } from './service/clocking-authorization-code.service';
import { ProgressSpinnerModule } from 'primeng';
import {EmployeePunchConfirmationComponent} from './component/employee-punch-confirmation/employee-punch-confirmation.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNgModule,
    WebcamModule,
    SharedModule,
    SignaturePadModule,
    EmployeeClockingRoutingModule,
    NgxDaterangepickerMd.forRoot(),
    FaceRecognitionModule,
    AgmCoreModule,
    AgmDrawingModule,
    MapModule,
    ProgressSpinnerModule
  ],
  declarations: [PunchInOutComponent, PunchInOutHistoryComponent,
    OntimeLateComponent, SummaryViewComponent,
    EmployeeClockingLayoutComponent, EmployeeOTComponent, EmployeePunchConfirmationComponent,
    PunchInOutTrackingComponent],
  exports: [PunchInOutComponent,
    PunchInOutHistoryComponent,
    SummaryViewComponent, EmployeeOTComponent, PunchInOutTrackingComponent],
  providers: [DepartmentService, EmployeeServiceV2, EmployeeClockingTrackingServiceV2, ClockingAutorizationCodeService]
})
export class EmployeeClockingModule { }
