import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../shared/model/constants';
import { PaginatedResponse } from '../../shared/model/PageResponse/paginated.response';
import { AbstractService } from '../../shared/service/abstract.service';
import { DispatchBilling } from '../model/dispatch-billing.model';
import { MonthlyFeeHistory } from '../../monthly-fee/model/monthly-fee-history.model';

@Injectable({
  providedIn: 'root'
})
export class DispatchBillingService extends AbstractService<DispatchBilling> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/dispatch-billing', '');
  }

  search(options: any): Observable<PaginatedResponse<DispatchBilling>> {
    return this._http.post<PaginatedResponse<DispatchBilling>>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }

  getHistory(options: any): Observable<PaginatedResponse<MonthlyFeeHistory>> {
    return this._http.post<PaginatedResponse<MonthlyFeeHistory>>(this.baseUrlV2 + 'history/search', options).pipe(map(res => res));
  }

  getDispatch(prm) {
    let url;
    if (this.sendUrl === 'https://dev2-api.operrwork.com') url = 'https://dev3-api.operr.com';
    if (this.sendUrl === 'https://qa2-api.operrwork.com') url = 'https://qa3-api.operr.com';
    if (this.sendUrl === 'https://prod2-api.operrwork.com') url = 'https://prodbackup-api.operr.com';
    let finalUrl;
    if (prm.dispatchingLicenseNum) {
      finalUrl = `${url}/billing/api/v3/operrwork-receipt/billingSummary?startDate=${prm.startDate}&endDate=${prm.endDate}&pageNumber=${prm.pageNumber}&pageSize=${prm.pageSize}&dispatchingLicenseNum=${prm.dispatchingLicenseNum}`
    } else {
      finalUrl = `${url}/billing/api/v3/operrwork-receipt/billingSummary?startDate=${prm.startDate}&endDate=${prm.endDate}&pageNumber=${prm.pageNumber}&pageSize=${prm.pageSize}`
    }

    if (prm.isMonthly) {
      finalUrl += '&isMonthly=' + prm.isMonthly;
    }

    return this._http.get(finalUrl)
  }


}
