import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from '../../../app/shared/model/constants';

@Injectable({ providedIn: 'root' })
export class TurfService {
  private static URL = `${environment.v2_server_ip}/${Constants.ROUTES.TURF}`;
  constructor(private http: HttpClient) {}

  deleteTurf(turfId: number): Observable<any> {
    return this.http.delete<any>(TurfService.URL + `/${turfId}`);
  }

  createTurf(options: any) {
    return this.http.post(`${TurfService.URL}`, options);
  }

  listAllTurf(companyId) {
    return this.http.get(`${TurfService.URL}/list?companyId=${companyId}`);
  }

  listAllTurfSimple(companyId) {
    return this.http.get(`${TurfService.URL}/turf-simple-list?companyId=${companyId}`);
  }

  assignVoters(turfId: number, voterIds: number[]) {
    return this.http.post(`${TurfService.URL}/assign-voters/${turfId}`, voterIds);
  }

  reAssignVoters(turfId: number, voterIds: number[]) {
    return this.http.post(`${TurfService.URL}/re-assign-voters/${turfId}`, voterIds);
  }

  removeVoters(turfId: number, voterIds: number[]) {
    return this.http.post(`${TurfService.URL}/remove-voters/${turfId}`, voterIds);
  }
}
