export class CompanyV2
{

    id:number;
    licenseNumber:string;
    federalTax:string;
    federalTaxStart:Date;
    federalTaxExpire:Date;
    federalTaxStatus:number;
    stateTax:string;
    stateTaxStart:Date;
    stateTaxExpire:Date;
    stateTaxStatus:number;
    name:string;
    phone:string;
    email:string;
    fax:string;
    addressOne:string;
    addressTwo:string;
    worktimeStart:Date;
    worktimeEnd:Date;
    city:String;
    state:string;
    zipcode:string;
    //Todo: To be converted to Enum 
    status:number;
    type:string;
    daysWork:any;
    timezone:string;
    serviceTypeIds:number[];
    branchAgency:number;
    serviceTypes:string[];
    createdByUsr?:string;
    lastModifiedBy?:string;
    planId?: number;
    documentIds?: any;
    operrTel?: string;
    operrTelApiKey?: string;
    operrTelApiSecret?: string;
    extraOperrTelKeys?: any[];
    operrTelProcessMethod?: string = 'One default number';
    autoPay: number;
    operrTelId?: any;
    operrtelStart:Date;
    operrtelEnd:Date;
    operationalPolicy?: any;
    specialty?: any;
    website?: any;
    description?: any;
    totalEmployee?: any;
}

export enum ServiceTypes {

    CRM = "Content Relationship Management",
    EM = "Employee Management",
    OPTel = "OperrTel"
}
