import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from "../../shared/model/page.model";
import { HolidayHistory } from "../model/holiday-history.model";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class HolidayHistoryService extends AbstractService<HolidayHistory> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.HOLIDAY_HISTORY_INFO, '');
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrlV2}`, data).pipe(map(resp => resp));
  }
  getClientHistoryPage(options?: any): Observable<Page<HolidayHistory>> {
    const params = this.createParams(options);
    return this._http.post<Page<HolidayHistory>>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }


}
