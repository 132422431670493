import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';

import { ContactModel } from '../../model/call-log/contact';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ContactResponseDTO } from '../../model/call-log/contactResponseDTO';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactService {
    private static URL = environment.server_ip + '/contact';
    private contact: ContactModel;

    constructor(private http: HttpClient) {
    }

    public create(contact: ContactModel): Observable<ContactModel> {
        const createdContact = Object.assign(new ContactModel(), contact);
        return this.http.post<ContactModel>(ContactService.URL, createdContact);
    }

    public delete(contactId: number): Observable<ContactModel> {
        return this.http.delete<ContactModel>(`${ContactService.URL}/${contactId}`);
    }

    public getAll(): Observable<ContactResponseDTO> {
        return this.http.get<ContactResponseDTO>(`${ContactService.URL}`).pipe(catchError(err => Observable.throw(err)));
    }
}
