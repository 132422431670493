import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AbstractServiceV2 } from '../../shared/service/abstract.v2.service';
import { AgencyAdminSearch } from '../../shared/model/agency.admin.search';

@Injectable({ providedIn: 'root' })
export class EmployeeForgotPasswordService extends AbstractServiceV2<any, AgencyAdminSearch> {

    constructor(private http: HttpClient) {
        super(http, 'employee/api/v2/forgot-password', '');
    }

    requestResetPassword(request: any): Observable<any> {
        return this.http.post(`${this.baseUrlV2}`, request);
    }

    checkValidToken(token: string): Observable<any> {
        return this.http.get(`${this.baseUrlV2}/check-token?token=${token}`);
    }

    resetPassword(token: string, req: any): Observable<any> {
        return this.http.post(`${this.baseUrlV2}/${token}`, req);
    }
}
