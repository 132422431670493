<h1 class="m-0 ml-2 my-4">{{'Punctuality' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Punch Data' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="!disableEmployee()">
			<label>{{'company.name' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="companies" [(ngModel)]="selectedCompany" (ngModelChange)="loadAgencies($event)" [showClear]="true" placeholder="{{'Select One' | translate}}" [disabled]="disableCompany()">
					<ng-template pTemplate="item" let-item>
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'employee.name' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-multiSelect defaultLabel="{{'Select Items' | translate}}"  emptyFilterMessage="{{'No results found' | translate}}" [options]="filteredEmployeeList" [(ngModel)]="historyFilter.employees" optionLabel="firstName" (onChange)="searchBySelectedEmployees($event)" id="id_filteredEmployeeList_to_selected_label" [disabled]="disableEmployee()">
					<ng-template let-option pTemplate="item">
						<span class="one-liner" style="font-size: 14px;max-width: 95%;">{{option.label | translate}}</span>
					</ng-template>
					<ng-template let-option pTemplate="selectedItem">
						<span class="one-liner" style="font-size: 14px;max-width: 95%;">{{option.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'form.dateRange' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="historyFilter.fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="historyFilter.toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
				<!--<input id="calendar" pInputText type="text" ngxDaterangepickerMd [(ngModel)]="historyFilter.date" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" style="width:100%;" (ngModelChange)="searchBySelectedDate($event)" />-->
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'timeClock' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-triangle-exclamation"></i>
					</button>
				</div>
				<p-dropdown [options]="timeClock" [(ngModel)]="timeclockVal" (onChange)="timeClockChange($event)" [showClear]="true" placeholder="{{'Select One' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button class="btn btn-danger mx-1" type="button" (click)="onResetClick($event)"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
			<button class="btn btn-primary mx-1" type="button" (click)="onSubmitClick($event)"><i class="fa-solid fa-check mr-2"></i>{{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-start my-4">   
	<h1 class="m-0 ml-2">{{'Punctuality Plot' | translate}}</h1>
</div>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-chart-bar text-primary mr-2"></i> {{'ScheduleDeviationPlot' | translate}}</span>
	</p-header>
	<p-toast position="bottom-right">{{msgs}}</p-toast>
	<p-contextMenu #cm [model]="items"></p-contextMenu>
	<canvas id="onlateCanvas"></canvas>
</p-panel>
<p-dialog [hidden]="!(showNoteDetailsDialog && missingOrInvalidDate)" styleClass="w-50" id="id_note_detail_dialog">
	<div class="card card-w-title p-0 border-0 m-0">
		<p class="tabCardHead mb-0">
			<span>{{'Incomplete' | translate}}</span>
		</p>
		<div *ngIf="missingCheckInCount>0" class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
			<div class="ui-g-12 ui-md-12">
				<div>({{missingCheckInCount}}) {{'CheckOutWithNoCheckIn' | translate}}</div>
			</div>
		</div>
		<div *ngIf="missingCheckOutCount>0" class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
			<div class="ui-g-12 ui-md-12">
				<div>({{missingCheckOutCount}}) {{'CheckOutWithNoCheckIn' | translate}}</div>
			</div>
		</div>
	</div>
</p-dialog>