import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { UntypedFormGroup, Form } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormValidatorService {

  constructor(private windowObj: WindowRefService) { }

  validateForm(formGroupObj: UntypedFormGroup, form: any) {
    let control: any;
    if (formGroupObj.valid) {
      return true;
    } else {
      const formArr = Object.keys(formGroupObj.controls);
      for (const field of formArr) {
        if (!!formGroupObj.controls[field].invalid) {
          control = formGroupObj.controls[field];
          control.markAsDirty();
        }
      }
      if (control && form && form.elements) {
        for (const el of form.elements) {
          let invalidEle: any;
          if (el.className.includes('ng-invalid')) {
            invalidEle = el;
            const boundingRect = invalidEle.getBoundingClientRect();
            const scrollTop = this.windowObj.nativeWindow.document.documentElement.scrollTop || this.windowObj.nativeWindow.document.body.scrollTop || 0;
            const top = boundingRect.top + scrollTop - 150;
            this.windowObj.nativeWindow.scrollTo({ left: 0, top: top, behavior: 'smooth' });
            break;
          }
        }
      }
      return false;
    }
  }

  checkValidEmails(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidPhone(phone: string): boolean {
    let phonePattern = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
    return phonePattern.test(String(phone));
  }

  public onlyDecimal(evt): boolean {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 &&
          (charCode != 46 || evt.target.value.indexOf('.') != -1) &&
          (charCode < 48 || charCode > 57))
          return false;
      //get the carat position
      if ((evt.target.value.indexOf('.') != -1) && (evt.target.value.substring(evt.target.value.indexOf('.'), evt.target.value.indexOf('.').length).length > 2)) {
          if (evt.keyCode !== 8 && evt.keyCode !== 46) { //exception
              return false;
          }
      } else {
          if (!evt.target.value && evt.key == '.') {
              return false;
          }
      }
      if (!this.decimalRegex(evt)) {
          return false;
      }
      return true;
  }

    public onlyDecimalNoLimit(evt): boolean {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 &&
            (charCode != 46 || evt.target.value.indexOf('.') != -1) &&
            (charCode < 48 || charCode > 57))
            return false;
        //get the carat position

        if (!evt.target.value && evt.key == '.') {
            return false;
        }
        if (!this.decimalRegex(evt)) {
            return false;
        }
        return true;
    }

  decimalRegex(evt: any) {
      let regex = new RegExp(/^\d*\.?\d{0,2}$/g);
      let current: string = evt.target.value;
      const position = evt.target.selectionStart;
      const next: string = [current.slice(0, position), evt.key == 'Decimal' ? '.' : evt.key, current.slice(position)].join(
          ''
      );

      if (next && !String(next).match(regex)) {
          evt.preventDefault();
          return false;
      }
      return true
  }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}
