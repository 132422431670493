import { Agency } from "./agency.model";


export class AgencyHoliday {
    id?: number;
    holidayName?: string;
    date: Date;
    agency: Agency;
    status: number;
}
