<h1 class="m-0 ml-2 my-4">{{'Cards' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Cards' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Board' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-chalkboard-user"></i>
					</button>
				</div>
				<p-dropdown [options]="boardList" filter="true" placeholder="{{'Select One' | translate}}" [showClear]="true" [(ngModel)]="boardSelected">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Assigned' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<p-dropdown [options]="freelancers" filter="true" placeholder="{{'Select One' | translate}}" [showClear]="true" [(ngModel)]="freelancerSelected">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Status' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown [options]="statusList" filter="true" placeholder="{{'Select One' | translate}}" [(ngModel)]="statusSelected" [showClear]="true">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchClick()"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<h1 class="m-0 ml-2 my-4">{{'Browse Cards' | translate}}</h1>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Task Cards' | translate}} ({{totalRecords || 0 | number}})</span>
	</p-header>
	<p-table #dt [value]="tasks" selectionMode="single" dataKey="id" [lazy]="false" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="totalRecords" [responsive]="false" [columns]="cols">
		<ng-template pTemplate="caption">
			<div class="d-flex flex-wrap align-items-center justify-content-end" style="row-gap: 10px">
				<div class="input-group w-100 mr-2" style="max-width: 400px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input [(ngModel)]="textSearchStr" (keyup)="filterTextSearch()" placeholder="{{'Filter' | translate}}" pInputText [disabled]="loading">
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of cols" [style.width]="col.width">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;loadSortOption(col.sortOptions);">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'startDate'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;loadSortOption(col.sortOptions);">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'deadline'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;loadSortOption(col.sortOptions);">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'createdAt'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;loadSortOption(col.sortOptions);">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'status'">
							<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="!loading">
				<td>{{i+1}}</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Board' | translate}}: </span>
						<span *ngIf="getBoardName(rowData.boardId)" pTooltip="{{getBoardName(rowData.boardId)}}">{{getBoardName(rowData.boardId)}}</span>
						<span *ngIf="!getBoardName(rowData.boardId)" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Assigned To' | translate}}: </span>
						<span *ngIf="getName(rowData.freelancer)" pTooltip="{{getName(rowData.freelancer)}}">{{getName(rowData.freelancer)}}</span>
						<span *ngIf="!getName(rowData.freelancer)" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>

				<td>
					<span *ngIf="rowData.ticketLink" class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Ticket URL' | translate}}: </span>
							<span [innerHTML]="linkify(rowData.ticketLink)" class="c-pointer ql-editor p-0" dir="rtl" id="textToCopy" pTooltip="{{'Ticket Link' | translate}}"></span>
						</span>
						<i (click)="copyToClipboard()" class="fa-solid fa-copy text-muted c-pointer ml-1" pTooltip="{{'Copy URL' | translate}}"></i>
					</span>
					<span *ngIf="!rowData.ticketLink" class="one-liner">
						<span class="mobile-table-label">{{'Ticket URL' | translate}}: </span>
						<span class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Start Date' | translate}}: </span>
						<span *ngIf="rowData.startDate" pTooltip="{{rowData.startDate | date:'MM/dd/yyyy'}}">{{rowData.startDate | date:'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.startDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Deadline'  | translate}}: </span>
						<span *ngIf="rowData.deadline" pTooltip="{{rowData.deadline | date:'MM/dd/yyyy'}}">{{rowData.deadline | date:'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.deadline" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Creator'  | translate}}: </span>
						<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
						<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>
						<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date:'MM/dd/yyyy'}}">{{rowData.createdAt | date:'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Status' | translate}}: </span>
						<span *ngIf="rowData.status == 'Approved'"  class="badge badge-success"><i class="fa-solid fa-check mr-1" pTooltip="{{'Status' | translate}}"></i>{{rowData.status}}</span>
						<span *ngIf="rowData.status == 'Confirmed' || rowData.status == 'Confirm'"  class="badge badge-success"><i class="fa-solid fa-check-double mr-1" pTooltip="{{'Confirmed' | translate}}"></i>{{'Confirmed' | translate}}</span>
						<span *ngIf="rowData.status == 'Processing'"  class="badge badge-info"><i class="fa-solid fa-gear mr-1" pTooltip="{{rowData.status}}"></i>{{rowData.status}}</span>
						<span *ngIf="rowData.status == 'Pending'"  class="badge badge-primary"><i class="fa-solid fa-hourglass-half mr-1" pTooltip="{{rowData.status}}"></i>{{rowData.status}}</span>
						<span *ngIf="rowData.status == 'Close'"  class="badge badge-muted"><i class="fa-solid fa-xmark mr-1" pTooltip="{{'Closed' | translate}}"></i>{{'Closed' | translate}}</span>
						<span *ngIf="rowData.status == 'Rejected'" class="badge badge-danger"><i class="fa-solid fa-ban mr-1 " pTooltip="{{rowData.status}}"></i>{{rowData.status}}</span>
						<span *ngIf="rowData.status == 'Reviewing'" class="badge badge-purple"><i class="fa-solid fa-glasses mr-1" pTooltip="{{rowData.status}}"></i>{{rowData.status}}</span>
						<span *ngIf="rowData.status == 'Save'"  class="badge badge-bright"><i class="fa-solid fa-floppy-disk mr-1" pTooltip="{{rowData.status}}"></i>{{rowData.status}}</span>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="cols?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="cols?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body" style="padding: 0px !important">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body" style="padding: 0px !important">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
