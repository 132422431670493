import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../shared/model/constants';
import { AbstractService } from '../../../shared/service/abstract.service';
import { Absence, AbsenseAccured } from '../model/absence';
import { Observable, Subject } from 'rxjs';
import { Page } from '../../../shared/model/page.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AbsenceService extends AbstractService<Absence> {

    private modeView = new Subject<string>();

    modeHandler = this.modeView.asObservable();
    constructor(private http: HttpClient) {
        super(http, 'employee/api/v2', '');
    }
    setMode(bl: string) {
        this.modeView.next(bl);
    }
    getAccruedByEmployeeId(id: Number, params?: any): Observable<Page<AbsenseAccured>> {
        return this._http.get<any>(`${this.baseUrlV2}employee/${id}/absence/accured`, {params: params})
          .pipe(map(resp => resp));
      }

    getReportByAgency(options): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.ABSENCE.REPORT}`;
        return this._http.post<any>(`${this.baseUrlV2}employee/absence/report-absence`, options).pipe(map(resp => resp));
    }

    exportPdf(options): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}employee/absence/exportPdf`, options).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<Absence>> {
        return this._http.post<Page<Absence>>(`${this.baseUrl}filter`, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(resp => resp));
    }

    getAllAbsenties(): Observable<any> {
        return this._http.get<any>(this.baseUrl + 'all').pipe(map(resp => resp));
    }
    
    getEmployeeDayOff() {
        return this._http.get<any>(`${this.baseUrl}${Constants.URI.ABSENCE.APPROVEDLEAVE}`).pipe(map(resp => resp));
    }
    
    getAbsenceNotification(params) {
        return this._http.get<any>(`${this.baseUrlV2}${Constants.URI.ABSENCE.NOTIFICATION}`, {params: params}).pipe(map(resp => resp));
    }
    
    deleteAbsenceNotification(options) {
        return this._http.post<any>(`${this.baseUrlV2}${Constants.URI.ABSENCE.DELETE_NOTIFICATION}`,  options).pipe(map(resp => resp));
    }

    absenceSearch (options: any): Observable<Page<Absence>> {
        return this._http.post<Page<Absence>>(`${this.baseUrlV2}filter`, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(resp => resp));
    }

    createAbsence(entity: any): Observable<any> {
        return this._http.post(`${this.baseUrlV2}employee/${entity.employeeId}/absence`, entity).pipe(map(res => res));
    }

    updateAbsence(entity: any): Observable<any> {
        return this._http.put(`${this.baseUrlV2}employee/${entity.employeeId}/absence/`, entity).pipe(map(res => res));
    }
}
