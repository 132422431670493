import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionDetailsHistoryService extends AbstractService<any> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.TRANSACTION_DETAILS_HISTORY, '');
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrlV2}`, data).pipe(map(resp => resp));
  }

  getTransactionDetailsHistory(options:any):Observable<any>{
    return this.http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(res => res));
  }


}
