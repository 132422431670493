<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="spinner-overlay" *ngIf="generateEmployeeAuthorizationCodeLoading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<div class="d-flex flex-wrap align-items-center justify-content-between py-3 px-1" style="row-gap: 20px">
	<h1 class="m-0 ml-4 text-truncate" style="font-size: 30px">{{'Hello' | translate}}, {{employee ? employee.firstName : ''}}</h1>
	<div class="card add-shadow d-flex align-items-center flex-row p-2 m-0 mx-2">
		<i *ngIf="hhmm(totalHoursToday) !== '0h 00m'" class="fa-solid fa-hourglass-half fa-2x text-muted mx-2"></i>
		<div *ngIf="hhmm(totalHoursToday) !== '0h 00m'" class=" d-flex flex-column justify-content-between px-2" style="border-right: 1px solid #e4e4e4;padding-right: 20px !important;">
			<span>{{'Hours Today' | translate}}</span>
			<h5><b>{{hhmm(totalHoursToday)}}</b></h5>
		</div>
		<i class="fa-solid fa-clock fa-2x text-muted ml-3"></i>
		<div class=" d-flex flex-column justify-content-between px-2" style="padding-left: 17px !important;">
			<span>{{'Total Hours' | translate}}</span>
			<h5><b>{{hhmm(totalHoursWeek)}}</b></h5>
		</div>
	</div>
</div>

<div *ngIf="!isPunchInOut" class="ui-g d-flex">
	<div class="ui-g-4 ui-sm-12" style="padding: 15px">
		<div class="card add-shadow p-0 m-0">
			<div class="card-header d-flex">
				<h5 *ngIf="!faceRecognitionMode" class="m-0"><i class="fa-solid fa-hand-pointer text-primary mr-2"></i>{{'PIN Pad' | translate}}</h5>
				<h5 *ngIf="faceRecognitionMode" class="m-0"><i class="fa-solid fa-users-viewfinder text-primary mr-2"></i>{{'Facial Recognition' | translate}}</h5>
			</div>
			<div class="ui-g pb-4" style="background: #f0f0f0;height: 533px">
				<div *ngIf="faceModel && !this.photoUserURL">
					<div class="ui-g-12 ui-sm-12 text-center d-flex align-items-center justify-content-center">
						<div style="max-width: 380px;" [hidden]="!faceRecognitionMode">
							<img src="assets/images/no_image_uploaded.png" class="w-100">
						</div>
					</div>
					<div class="ui-g-12 ui-sm-12 text-center d-flex align-items-center justify-content-center">
						<h2 style="color: red">{{'No Employee Image Uploaded' | translate}}</h2>
					</div>
				</div>
				
				<div *ngIf="faceModel && this.photoUserURL" class="ui-g-12 ui-sm-12 text-center d-flex align-items-center justify-content-center">
					<div style="max-width: 380px;" [hidden]="!faceRecognitionMode">
						<app-face-recognition #faceRecognition [model]="faceModel" [mode]="faceMode" [personName]="personName" [takePhoto]="true" [disabledTakePhoto]="disabledTakePhoto" [pauseDetectFace]="pauseDetectFace" (detectionEvent)="handleDetectionEvent($event)" (takePhotoEvent)="onTakePhoto($event)" (resumeVideoEvent)="clearPinNumber(0)"></app-face-recognition>
					</div>
				</div>
				<div class="text-center m-auto pt-4" id="pinwrapper" *ngIf="!faceRecognitionMode">
					<div class="ui-g-12 text-center">
						<h2 *ngIf="!employeePunch.pin && !errorMessage" class="m-0 text-muted" style="height: 51px">{{'Enter Your PIN' | translate}}</h2>
						<input *ngIf="employeePunch.pin" type="{{pinInputType}}" class="text-secondary"
						style="width: 100%;height: 50px !important;text-align: center;background: transparent !important;border: 0px;letter-spacing: 19px;font-size: 55px !important;"
						pInputText [pKeyFilter]="pinRegExp" [(ngModel)]="employeePunch.pin" (change)="handleValidatePin()" [disabled]="readOnly" (keyup)="checkForButtonDisable()" autocomplete="new-password">
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(1)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-1 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(2)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-2 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(3)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-3 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(4)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-4 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(5)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-5 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(6)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-6 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(7)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-7 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(8)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-8 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(9)" type="button" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-9 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="clearPinNumber(0)" type="button" class="card add-shadow p-4"><i class="fa-solid fa-xmark text-danger fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="updatePinNumber(0)" type="button" label="0" class="card add-shadow p-4" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? '#e1e1e1 !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-0 fa-2x"></i></button>
					</div>
					<div class="ui-g-4 d-flex justify-content-center">
						<button  (click)="reDoLastNumber()" type="button" label="<" class="card add-shadow p-4"><i class="fa-solid fa-chevron-left text-danger fa-2x"></i></button>
					</div>
				</div>
			</div>
			<div class="card-footer text-center">
				<button *ngIf="!faceRecognitionMode" class="btn btn-white btn-sm" (click)="faceRecognitionModeChange()"><i class="fa-solid fa-users-viewfinder text-primary mr-2"></i>{{'Facial Recognition' | translate}}<i class="fa-solid fa-chevron-right text-muted ml-2"></i></button>
				<button *ngIf="faceRecognitionMode" class="btn btn-white btn-sm" (click)="faceRecognitionModeChange()"><i class="fa-solid fa-hand-point-up text-primary mr-2"></i>{{'Manual PIN' | translate}}<i class="fa-solid fa-chevron-right text-muted ml-2"></i></button>
			</div>
		</div>
	</div>
	<div class="ui-g-4 ui-sm-12 p-0">
		<div class="ui-g-12" style="padding: 15px">
			<div class="card add-shadow p-0 m-0">
				<div class="card-header">
					<h5 class="m-0"><i class="fa-solid fa-signature text-primary mr-2"></i>{{'Sign Inside' | translate}}</h5>
				</div>
				<div class="w-100" #signaturePadSection>
					<signature-pad #signaturePad style="min-height: 300px !important;" [options]="signaturePadConfig" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()" [height]="400"></signature-pad>
				</div>
				<div class="card-footer text-center">
					<button type="button" class="btn btn-white btn-sm" (click)="clearSignature()"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'ClearSignature' | translate}}</button>
				</div>
			</div>
		</div>
		<div class="ui-g-12" style="padding: 15px">
			<div class="card add-shadow p-0 m-0">
				<div class="card-header">
					<h5 class="m-0"><i class="fa-solid fa-mouse-pointer text-primary mr-2"></i>{{'Punch Clock Controls' | translate}}</h5>
				</div>
				<div *ngIf="disabledStarkWork || disabledLunchOutTime || disabledLunchInTime || disabledEndWork" class="d-flex flex-column align-items-center justify-content-start p-3" style="height: 254px;background: #f0f0f0;overflow:auto;gap:15px">
					<button *ngIf="disabledStarkWork" type="button" class="btn btn-white btn-lg w-100" (click)="checkStartWork()" [disabled]="!disabledStarkWork"><i class="fa-solid fa-door-open text-primary mr-2"></i>{{'Start Work' | translate}}</button>
					<button *ngIf="disabledLunchOutTime" type="button" class="btn btn-white btn-lg w-100" (click)="outToLunch()" [disabled]="!disabledLunchOutTime"><i class="fa-solid fa-utensils text-orange mr-2"></i>{{'Start Break' | translate}}</button>
					<button *ngIf="disabledLunchInTime" type="button" class="btn btn-white btn-lg w-100" (click)="backFromLunch()" [disabled]="!disabledLunchInTime"><i class="fa-solid fa-reply text-purple mr-2"></i>{{'End Break' | translate}}</button>
					<button *ngIf="disabledEndWork" type="button" class="btn btn-white btn-lg w-100" (click)="checkEndWork()" [disabled]="!disabledEndWork"><i class="fa-solid fa-check text-success mr-2"></i>{{'End Work' | translate}}</button>
				</div>
				<div *ngIf="!disabledStarkWork && !disabledLunchOutTime && !disabledLunchInTime && !disabledEndWork" class="d-flex align-items-center justify-content-center w-100" style="height: 254px;background: #f0f0f0;overflow:auto">
					<div class="text-center">
						<i class="fa-solid fa-circle-exclamation text-muted fa-2x"></i>
						<div class="text-muted">{{'Enter PIN or get recognized to continue' | translate}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g-4 ui-sm-12" style="padding: 15px">
		<div class="card add-shadow p-0 m-0">
			<div class="card-header">
				<h5 class="m-0"><span class="badge mr-2" [ngClass]="punchHistoryListUpdated?.length > 0 ? 'badge-primary' : 'badge-muted'">{{punchHistoryListUpdated?.length || 0}}</span>{{'Recent Activity' | translate}}</h5>
			</div>
			<div class="d-flex flex-column" style="height: 531px;background: #f0f0f0;overflow:auto">
				<ng-container *ngIf="punchHistoryListUpdated.length > 0; else emptyMessage">
					<ng-container *ngFor="let rowData of punchHistoryListUpdated.slice().reverse(); let i = index;">
						<div class="d-flex border-bottom align-items-center justify-content-between bg-white p-3">
							<span class="d-flex align-items-center">
								<i *ngIf="rowData.type === 'In'" class="fa-solid fa-door-open text-primary mr-2" style="font-size: 20px"></i>
								<i *ngIf="rowData.type === 'Break Out'" class="fa-solid fa-utensils text-orange mr-2" style="font-size: 20px"></i>
								<i *ngIf="rowData.type === 'Break Back'" class="fa-solid fa-reply text-purple mr-2" style="font-size: 20px"></i>
								<i *ngIf="rowData.type === 'Out'" class="fa-solid fa-check text-success mr-2" style="font-size: 20px"></i>
						
								<div *ngIf="rowData.type === 'In'" style="font-size: 16px"><b>{{'Started Work' | translate}}</b></div>
								<div *ngIf="rowData.type === 'Break Out'" style="font-size: 16px"><b>{{'Started Break' | translate}}</b></div>
								<div *ngIf="rowData.type === 'Break Back'" style="font-size: 16px"><b>{{'Ended Break' | translate}}</b></div>
								<div *ngIf="rowData.type === 'Out'" style="font-size: 16px"><b>{{'Finished Work' | translate}}</b></div>

								<i *ngIf="rowData.type === 'OFF'" class="fa-solid fa-circle-xmark text-muted mr-2" style="font-size: 20px"></i>
								<div *ngIf="rowData.type === 'OFF'" style="font-size: 16px"><b>{{'OFF' | translate}}</b></div>

								<i *ngIf="!rowData.type" class="fa-solid fa-triangle-exclamation text-muted mr-2" style="font-size: 20px"></i>
								<div *ngIf="!rowData.type" class="text-muted" style="font-size: 16px">{{'no data' | translate}}</div>
							</span>
							<span class="text-muted text-truncate" style="font-size: 12px">
								<span pTooltip="{{rowData.fullDate}}" tooltipPosition="left"> {{rowData.day | slice : 0 : 3}} {{rowData.fullDate | date : 'hh:mm a'}}</span>
								<i *ngIf="rowData.labelType" class="fa-solid fa-circle-info ml-1" [ngClass]="rowData.labelType ? 'text-primary' : 'text-muted'" pTooltip="{{'Duration' | translate}}: {{rowData.labelType}}" tooltipPosition="left"></i>
								<i *ngIf="!rowData.labelType" class="fa-solid fa-circle-info ml-1" [ngClass]="rowData.labelType ? 'text-primary' : 'text-muted'"></i>
							</span>
						</div>
					</ng-container>
				</ng-container>
				<ng-template #emptyMessage>
					<div *ngIf="!loading" class="d-flex h-100 justify-content-center align-items-center">
						<div class="text-muted my-5">{{'You have no punch activity' | translate}}</div>
					</div>
					<div *ngIf="loading" class="d-flex h-100 justify-content-center align-items-center">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</div>
				</ng-template>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<button (click)="punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" class="btn btn-white btn-sm" pTooltip="{{'Refresh' | translate}}"><i class="fa-solid fa-rotate text-primary"></i></button>
				<a class="btn btn-white btn-sm" routerLink="/app/employee-clocking/protest-hour/"><i class="fa-solid fa-triangle-exclamation text-primary mr-2"></i>{{'Make Correction' | translate}}<i class="fa-solid fa-chevron-right text-muted ml-2"></i></a>
			</div>
		</div>
	</div>
</div>

<!-- DONT TOUCH THIS, ILL CLEAN IT UP LATER .. I Promise -->
<div style="display:none;">
	<p-table #dt [value]="punchHistoryListUpdated" dataKey="id" [lazy]="true" [columns]="punchHistoryListTable" (onLazyLoad)="loadPunchHistoryLazy($event)" [responsive]="true"></p-table>
</div>
<!-- END OF DONT TOUCH ZONE -->

<div *ngIf="isPunchInOut" class="punch-in-out pt-0 pb-2 d-flex justify-content-center align-items-center" [ngClass]="{
	'btn-danger': selectedColor === 'red',
	'btn-orange': selectedColor === 'orange',
	'btn-warning': selectedColor === 'yellow',
	'btn-success': selectedColor === 'green',
	'btn-info': selectedColor === 'teal',
	'btn-primary': selectedColor === 'blue',
	'btn-purple': selectedColor === 'purple',
	'btn-dark': selectedColor === 'dark'
 }">
    <div class="logout-btn" style="position: absolute; top: 20px; right: 120px;">
        <a title="Logout" style="cursor: pointer;">
            <i class="fa-solid fa-power-off text-light" (click)="this.translateService.use('en');triggerLogOut()" style="font-size: 30px;margin-top: 2px;"></i>
        </a>
    </div>
	<div class="dropdown" style="position: absolute; top: 20px; right: 70px;">
		<a title="Color Options" style="cursor: pointer;" role="button" data-toggle="dropdown" aria-expanded="false">
			<i class="fa-solid fa-palette text-light" style="font-size: 30px;margin-top: 2px;"></i>
		</a>
		<div class="dropdown-menu" style="left: -70px !important; width: 230px !important; right: 70px !important;">
			<h6 class="dropdown-header">Change Default Color</h6>
			<div class="dropdown-divider"></div>
			
			<div class="p-2">
				<div *ngFor="let color of colors" class="form-check d-flex align-items-center py-1" (click)="onColorChange(color.value)" style="cursor: pointer">

					<button class="btn round" [ngClass]="{
						'btn-danger': color.label === 'Red',
						'btn-orange': color.label === 'Orange',
						'btn-warning': color.label === 'Yellow',
						'btn-success': color.label === 'Green',
						'btn-info': color.label === 'Teal',
						'btn-primary': color.label === 'Blue',
						'btn-purple': color.label === 'Purple',
						'btn-dark': color.label === 'Dark'
					}" 
						style="width: 30px;height:30px"></button>
					<div class="form-check-label pl-3" style="font-size: 22px">{{ color.label }}</div>
				</div>
			</div>
			<div class="dropdown-divider"></div>
			<div class="px-3 py-1">
				<button class="btn btn-danger btn-lg m-auto" (click)="resetBG()">
					<i class="fa-solid fa-refresh"></i> Reset
				</button>
			</div>
		</div>
	</div>
	
	<div class="dropdown" style="position: absolute; top: 20px; right: 20px;">
		<a title="Options" style="cursor: pointer;" role="button" data-toggle="dropdown" aria-expanded="false">
			<i class="fa-solid fa-earth-europe text-light" style="font-size: 30px;margin-top: 2px;"></i>
		</a>
		<div class="dropdown-menu" style="left: -70px !important;width:230px !important;right:70px !important">
			<h6 class="dropdown-header">Additional Labeling</h6>
			<div class="dropdown-divider"></div>
	
			<div class="p-2">
				<div *ngFor="let language of languages" class="form-check d-flex align-items-center ml-3 py-1">
				  <input class="form-check-input" 
						 type="checkbox" 
						 [value]="language.value" 
						 (change)="onLanguageChange(language.value, $event)" 
						 [checked]="selectedLanguages.includes(language.value)" 
						 [disabled]="!selectedLanguages.includes(language.value) && selectedLanguages.length >= 2" style="width: 30px;height:30px !important">
				  <div class="form-check-label pl-3" style="font-size: 22px">{{ language.label }}</div>
				</div>
			</div>
			<div class="dropdown-divider"></div>
			<div class="px-3 py-1">
				<button class="btn btn-danger btn-lg m-auto" (click)="resetSelection()"><i class="fa-solid fa-refresh mr-2"></i>Reset</button>
			</div>
		</div>
	</div>


    <div style="height: 80vh;">
        <div style="height: 70px !important;">
			<div *ngIf="successMessage"  class="d-flex align-items-center justify-content-center m-auto" style="line-height:.7">
				<i class="fa-solid fa-circle-check fa-3x mr-3"></i>
				<div>
					<h3 class="text-light font-weight-normal text-center" style="font-size: 27px;">{{successMessage}}</h3>
					<div class="d-flex" style="column-gap: 10px">
						<div *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-light" style="font-size: 16px;opacity:0.6;font-weight:500">
							{{ getTranslation(successMessage, lang) }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="errorMessage" class="d-flex align-items-center alert alert-danger justify-content-center w-100 mb-4" style="font-size: 27px;">
				<img src="assets/images/exclamation_1.gif" width="50" class="text-orange fa-2x mr-3"> 
				<div class="d-flex flex-column" style="line-height: 1;">
					<span>{{errorMessage}}</span>
					<span *ngIf="errorMessage && faceRecognitionMode" style="font-size: 14px;opacity:.7">{{'Please try another way' | translate}}</span>
				</div>
		   </div>
			<div *ngIf="!successMessage && !errorMessage" class="d-flex flex-column align-items-center justify-content-center m-auto" style="line-height:1.2">
				<h3 class="text-light font-weight-normal m-auto text-center" style="font-size: 27px;">{{welcomeText}}</h3>
				<div class="d-flex" style="column-gap: 10px">
					<div *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-light" style="font-size: 18px;opacity:0.6;font-weight:500">
						{{ getTranslation(welcomeText, lang) }}
					</div>
				</div>
			</div>
        </div>
        <div class="wrapper d-flex justify-content-center align-items-center" style="background: #00000050;border-radius: 40px;box-shadow: 1px 12px 24px -12px #000;">
            <div class="left text-center py-5 pl-5 d-flex">
                <div class="left text-center p-4">
                    <div class="mb-5 px-0 mx-0 text-center" style="color: #fefefb;line-height: .9;min-width: 250px">
						<style>
							@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@700&family=Zen+Dots&display=swap');
						  </style>
                        <p style="font-size: 68px;font-family: 'Gemunu Libre';" class="text-uppercase m-0">{{currentHour}}</p>
                        <p class="m-0" style="font-size: 20px;">{{currentDate}}</p>
					
                    </div>
					<div  *ngIf="!faceRecognitionMode" class="logo mb-4">
                        <img *ngIf="!loadingButtons" alt="Logo Image" class="round" [src]="companyLogo" width="150" height="150" style="border: 6px solid #ffffff;"/>
						<i *ngIf="loadingButtons" class="fa-solid fa-spinner text-warning loadingHourglass" style="font-size: 150px"></i>
                    </div>
					<div  *ngIf="!faceRecognitionMode" class="welcome mb-5">
                        <h4 class="font-weight-normal text-light" style="font-size: 34px;max-width: 420px;">{{companyName}}</h4>
                    </div>
					<div id="pinwrapper" *ngIf="faceRecognitionMode" style="position: relative; display: flex; flex-direction: column">
						<app-face-recognition #faceRecognition [model]="faceModel" [selectedLanguages]="selectedLanguages" [selectedColor]="selectedColor" [selectedColors]="selectedColors" [mode]="faceMode" [personName]="personName" [isPunchInOut]="isPunchInOut" [takePhoto]="true" [disabledTakePhoto]="disabledTakePhoto" [pauseDetectFace]="pauseDetectFace" (detectionEvent)="handleMutilDetectionEvent($event)" (takePhotoEvent)="onTakePhoto($event)" (resumeVideoEvent)="clearPinNumber(0)"></app-face-recognition>
					</div>
                </div>        
            </div>
            <div class="right p-5">
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<div style="width: 335px; margin-bottom: 30px;">
							<div *ngIf="!loadingButtons && !employeePunch?.pin" id="pininput" class="d-flex flex-column align-items-center justify-content-center" style="font-size: 26px;line-height:1.2">
								<div class="text-muted">{{'Enter PIN'}}</div>
								<div class="d-flex align-items-center">
									<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-light" style="font-size: 14px;opacity: 0.6"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Enter Your PIN', lang) }}</span>
								</div>
							</div>
							<input *ngIf="!loadingButtons && employeePunch?.pin" class="text-center p-1 w-100" type="{{pinInputType}}" id="pininput" pInputText [pKeyFilter]="pinRegExp" [(ngModel)]="employeePunch.pin" [disabled]="readOnly" (keyup)="checkForButtonDisable()" maxlength="4" autocomplete="new-password" style="font-size: 39px !important">
							<div *ngIf="loadingButtons" id="pininput" class="d-flex align-items-center justify-content-center" style="font-size: 18px">
								<i class="fa-solid fa-spinner mr-2 loadingHourglass" style="font-size: 28px"></i>
								<div style="line-height:1.2">
									<div class="text-light" style="font-size: 24px">{{'Loading...'}}</div>
									<div class="d-flex" style="column-gap: 6px">
										<div *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap text-light" style="font-size: 14px;opacity:0.6">{{ getTranslation('Loading...', lang) }}</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="ui-g-6 ui-sm-12 d-flex justify-content-center align-items-center">
						<div class="my-2">
							<span style="font-size: 18px" class="d-flex align-items-center" (click)="showEnterPinDialog = true"><u>{{'View My time'}}</u></span>
						</div>
                	</div>
                </div>

                <div class="d-flex">
                    <div class="pin-button">
                        <div>
                            <button (click)="updatePinNumber(1)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-1"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(2)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-2"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(3)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-3"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(4)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-4"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(5)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-5"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(6)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-6"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(7)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-7"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(8)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-8"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(9)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-9"></i></button>
                        </div>
                        <div>
                            <button (click)="clearPinNumber(0)" type="button" class="punch-in-pin-button"><i class="fa-solid fa-xmark text-danger"></i></button>
                        </div>
                        <div>
                            <button (click)="updatePinNumber(0)" type="button" class="punch-in-pin-button" [style.background-color]="!(pinMethod || photoBase64String || personName == 'Unrecognized') ? 'gray !important' : ''" [disabled]="disabledPinButton || !(pinMethod || photoBase64String || personName == 'Unrecognized')"><i class="fa-solid fa-0"></i></button>
                        </div>
                        <div>
                            <button (click)="reDoLastNumber()" type="button" class="punch-in-pin-button"><i class="fa-solid fa-chevron-left text-orange"></i></button>
                        </div>
                    </div>
                    <div class="ml-4 d-flex flex-column justify-content-between" style="width: 320px">
						<div class="my-2">
							<button *ngIf="!disabledStarkWork" type="button" class="btn btn-secondary btn-lg w-100" (click)="startWork()" [disabled]="!disabledStarkWork" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-door-open mr-3" style="font-size: 36px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'Start Work' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Start Work', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
							<button *ngIf="disabledStarkWork" type="button" class="btn btn-white btn-lg w-100" (click)="startWork()" [disabled]="!disabledStarkWork" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-door-open text-primary mr-3" style="font-size: 36px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'Start Work' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Start Work', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
						</div>
						<div class="my-2">
							<button *ngIf="!disabledLunchOutTime" type="button" class="btn btn-secondary btn-lg w-100" (click)="outToLunch()" [disabled]="!disabledLunchOutTime" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-utensils mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'Start Break' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Start Break', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
							<button *ngIf="disabledLunchOutTime" type="button" class="btn btn-white btn-lg w-100" (click)="outToLunch()" [disabled]="!disabledLunchOutTime" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-utensils text-orange mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'Start Break' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('Start Break', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
						</div>
						<div class="my-2">
							<button *ngIf="!disabledLunchInTime" type="button" class="btn btn-secondary btn-lg w-100" (click)="backFromLunch()" [disabled]="!disabledLunchInTime" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-reply mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'End Break' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('End Break', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
							<button *ngIf="disabledLunchInTime" type="button" class="btn btn-white btn-lg w-100" (click)="backFromLunch()" [disabled]="!disabledLunchInTime" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-reply text-primary mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'End Break' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('End Break', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
						</div>
						<div class="my-2">
							<button *ngIf="!disabledEndWork" type="button" class="btn btn-secondary btn-lg w-100" (click)="endWork()" [disabled]="!disabledEndWork" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-check mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'End Work' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('End Work', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
							<button *ngIf="disabledEndWork" type="button" class="btn btn-white btn-lg w-100" (click)="endWork()" [disabled]="!disabledEndWork" style="border-radius: 20px !important;height:80px !important">
								<span class="d-flex align-items-center">
									<i class="fa-solid fa-check text-danger mr-3" style="font-size: 36px;width:30px"></i>
									<div class="text-left text-start" style="line-height: 1.2">
										<span style="font-size: 1.2em"><b>{{ 'End Work' }}</b></span>
										<div class="d-flex justify-content-start" style="opacity: 0.7">
											<span *ngFor="let lang of selectedLanguages;let i = index" class="text-nowrap" style="font-size: 13px"><span *ngIf="i+1==2" class="mx-1">-</span>{{ getTranslation('End Work', lang) }}</span>
										</div>
									</div>
								</span>
							</button>
						</div>
                    </div>
                </div>
            </div>
        </div>
		<div class="d-flex align-items-center justify-content-center pt-4" style="border-color: #00000020 !important;">
			<div class="d-flex flex-column align-items-center justify-content-center">
				<img src="https://login.operrwork.com/assets/layout/images/logo-slim-new.png" class="w-100" style="max-height: 49px">
				Powered by OperrWork™ 
			</div>
		</div>
    </div>
</div>

<p-dialog [(visible)]="isInvalidSign" [modal]="true" [style]="{'width':'90%', 'max-width':'450px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{punchHeader | translate}}</span>
 	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'isInvalidSign' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="isInvalidSign = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="errorMessage" [modal]="true" [style]="{'width':'90%', 'max-width':'450px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Error' | translate}}</span>
 	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{errorMessage | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="errorMessage = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="showPIOD" (onHide)="isHided()" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
    <p-header>
       	<span><i class="fa-solid fa-clock mr-2"></i>{{punchHeader | translate}}</span>
    </p-header>
	<div class="ui-g" style="font-size: 16px;">
		<div class="ui-g-12" *ngIf="punchInOutData">
			<h4 class="text-red">
				<span *ngIf="punchInOutData.isMissedPunchIn === 1">
					<i class="fa-solid fa-triangle-exclamation text-danger mr-2"></i>{{'YouMissedAPunchIn' | translate}}
				</span>
				<span *ngIf="punchInOutData.isMissedPunchIn === 0">
					<i class="fa-solid fa-triangle-exclamation text-danger mr-2"></i>{{'YouMissedAPunchOut' | translate}}
				</span>
			</h4>
			<div class="ui-g-12">
				<span class="d-flex align-items-center"><i class="fa-solid fa-circle-check text-success mr-3 fa-2x"></i><b>{{'ClockWasSuccessful' | translate}}</b></span>
			</div>
			<div class="ui-g-12">
				<span>{{punchInOutData.lastPunchDate? (('Previous Punch' | translate) + ':  ' + (punchInOutData.lastPunchDate | date: 'MM/dd/yyyy hh:mm')): 'Summary' | translate}}</span><br>
				<span>{{'TotalWorkingHours' | translate}}: </span><span style="font-weight: bold">{{punchInOutData.totalHours}}</span><br>
				<span style="font-size: 16px;">{{'WorkHoursLeft' | translate}}: </span><span style="font-weight: bold">{{punchInOutData.leftWorkingHours}}</span>
			</div>
			<div class="ui-g-12" *ngIf="punchInOutData.punchInLateCount > 0 && punchInOutData.clockType === 'CLOCK_IN'">
				<h4 class="text-danger">
					{{'You are late for the' | translate}}  <span style="font-weight: bold">{{punchInOutData.punchInLateCount}} {{getSuffixTimes(punchInOutData.punchInLateCount)}}</span> {{ 'time this month' | translate}}.
				</h4>
			</div>
		</div>
	</div>
	<div class="ui-g-12" *ngIf="punchInOutData && punchInOutData.punchInLate && punchInOutData.clockType === 'CLOCK_IN'">
		<p class="px-4">
			{{'arriving_on_time_allows_us' | translate}}
		</p>
	</div>
    <p-footer class="d-flex justify-content-end">
        <button type="button" (click)="closedDialog();punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
</p-dialog>



 
<p-dialog [(visible)]="showLunchDurationDialog" (onHide)="isHided()" [modal]="true" [style]="{'width':'90%', 'max-width':'450px'}">
    <p-header>
        <span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Confirm End Break' | translate}}</span>
    </p-header>
	<div class="ui-g">
		<div class="ui-g-12" style="font-size: 16px">
			<span>{{'YouDidnReach' | translate}} </span><br>
			<span [innerHTML]="'timeMessage' | translate:{lunchTimeLeft: lunchTimeLeft}"> </span><br>
			<span> {{'WouldYouLike' | translate}}</span>
		</div>
	</div> 
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="closedLunchTimeDialog()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="openLunchDurationConfirmDialog();punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>


<!--Add reason popup-->
<p-dialog [modal]="true" [closable]="false" [(visible)]="showAddNoteDialog" (onHide)="isHided()" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}">
	<p-header class="d-flex flex-grow-1 justify-content-between">
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'punchInOutReason' | translate}}</span>
		<a (click)="hiddenPopupReason()" tabindex="0" role="button" class="ng-tns-c103-38 ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all ng-star-inserted" ng-reflect-ng-class="[object Object]"><span class="ng-tns-c103-38 pi pi-times"></span></a>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<label>{{'Reason' | translate}} <span class="required-indicator">*</span></label>
			<div class="pt-4" style="font-size: 16px !important">
				<p-radioButton name="awayOption" value="1" [(ngModel)]="punchInOutOption" label="{{'UserForgotClockOut' | translate}}"></p-radioButton>
				<p-radioButton name="awayOption" value="2" [(ngModel)]="punchInOutOption" label="{{'UserUnableClockOut' | translate}}"></p-radioButton>
				<p-radioButton name="awayOption" value="3" [(ngModel)]="punchInOutOption" label="{{'DoNotAccessLocation' | translate}}"></p-radioButton>
				<p-radioButton name="awayOption" value="4" [(ngModel)]="punchInOutOption" label="{{'other' | translate}}"></p-radioButton>
			</div>
		</div>
		<div class="ui-g-12">
			<label>{{'Description' | translate}} <span class="required-indicator">*</span></label> 
			<textarea id="id_note_text" pInputTextarea [rows]="4" minlength="25" maxlength="600" class="w-100" [(ngModel)]="punchInOutNote" placeholder="{{'PleaseEnterMinimum25Characters' | translate}}" style="font-size: 16px"></textarea>
			<span class="pull-right" [ngClass]="punchInOutNote?.length < 600 ? 'text-muted' : 'text-danger'">{{punchInOutNote?.length || 0}}/600</span>
		</div>
	</div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="hiddenPopupReason()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="handleSubmitEmployeePunchForAccount('CLOCK_IN', 0);punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" *ngIf="punchType == 1" class="btn btn-primary" [disabled]="punchInOutOption =='' || punchInOutNote=='' || punchInOutNote?.length < 25 "><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
        <button type="button" (click)="handleSubmitEmployeePunchForAccount('LUNCH_OUT', 1);punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" *ngIf="punchType == 2" class="btn btn-primary" [disabled]="punchInOutOption =='' || punchInOutNote=='' || punchInOutNote?.length < 25 "><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
        <button type="button" (click)="handleSubmitEmployeePunchForAccount('LUNCH_IN', 0);punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" *ngIf="punchType == 3" class="btn btn-primary" [disabled]="punchInOutOption =='' || punchInOutNote=='' || punchInOutNote?.length < 25 "><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
        <button type="button" (click)="handleSubmitEmployeePunchForAccount('CLOCK_OUT', 1);punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" *ngIf="punchType == 4" class="btn btn-primary" [disabled]="punchInOutOption =='' || punchInOutNote=='' || punchInOutNote?.length < 25 "><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
    </p-footer>
</p-dialog>

<!--Plan assignment dialog-->

<p-dialog [(visible)]="showPlanAssignmentDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'450px'}">
    <p-header>
       <span><i class="fa-solid fa-triangle-exclamation mr-2"></i> {{'Confirmation' | translate}}</span>
    </p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure want to end work? You have not added any work updates or job reports.' | translate}}</span>
		</div>
	</div>
    <p-footer class="d-flex justify-content-between">
		<button type="button" (click)="closePlanAssignmentDialog()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="clockOutAnyWay();punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="retryFaceRecognitionPopup" (onHide)="retryFaceRecognitionClose()"  [modal]="true" [style]="{'width': '90%', 'max-width':'450px'}">
    <p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Nothing Detected' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Face not detected, would you like to retry?' | translate}}</span>
		</div>
	</div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="disabledRetryFaceRecognition = false; retryFaceRecognitionClose()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="disabledRetryFaceRecognition = true; retryFaceRecognitionClose()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Continue' | translate}}</button>
    </p-footer>
</p-dialog>


<p-dialog width="500" [(visible)]="errorPopup">
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{errorTitle}}</span>
    </p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{errorMessage}}</span>
		</div>
	</div>
    <p-footer class="d-flex justify-content-end">
        <button type="button" (click)="errorPopup = false" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="authorizationCodePopup" (onHide)="closeAuthorizationCodePopup()" [modal]="true" [style]="{'width': '60%', 'max-width':'450px'}">
    <p-header>
        <span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Authorization Request' | translate}}</span>
    </p-header>
    <div class="ui-g pb-3">
        <div class="ui-g-12">
            <label>{{'Authorization Code' | translate}}</label>
            <div class="input-group w-100" style="flex-wrap: nowrap;">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-hashtag"></i>
                    </button>
                </div>
                <input type="text" pInputText class="w-100" placeholder="{{'Enter Code' | translate}}" [(ngModel)]="authorizationCode"/>
				<div class="input-group-append">
					<button *ngIf="!requestCodeCountdown || !requestCodeCountdownStr" (click)="requestAuthorizationCode()" class="btn btn-white text-nowrap" [disabled]="requestCodeCountdown > 0 && requestCodeCountdownStr"><i class="fa-solid fa-mobile text-primary mr-2"></i>{{'Get Code' | translate}}</button>
					<button *ngIf="requestCodeCountdown > 0 && requestCodeCountdownStr" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-stopwatch mr-1"></i>{{requestCodeCountdown > 0 ? requestCodeCountdownStr + 's' : ''}}
                    </button>
				</div>
            </div>
        </div>
    </div>
    <p-footer class="d-flex justify-content-between">
		<button class="btn btn-danger" (click)="authorizationCodePopup = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button (click)="submitAuthorizationCode()" class="btn btn-primary mx-1" [disabled]="!authorizationCode"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showPopupSelect" [modal]="true" (onHide)="closePopupSelect()" [style]="{'width': '90%', 'max-width':'500px'}" >
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Schedule Discrepancy' | translate}}</span>
    </p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'This punch is outside your typical schedule or is out of order. This may have been caused by a missing punch. How would you like to proceed?' | translate}}</span>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<button class="btn btn-white w-100" (click)="gotoTimeCorrect()"><div class="d-flex flex-column p-4"><i class="fa-solid fa-business-time text-primary fa-3x m-2"></i>{{'Time Correction' | translate}}</div></button>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<button class="btn btn-white w-100"  (click)="showPopupReason()"><div class="d-flex flex-column p-4"><i class="fa-solid fa-clock text-primary fa-3x m-2"></i>{{'Continue Punch' | translate}}</div></button>
		</div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="showPopupSelect = false;" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showLunchDurationConfirmDialog" [modal]="true" (onHide)="closeLunchDurationConfirmDialog()" [style]="{'width': '90%', 'max-width':'500px'}" class="lunchDurationConfirmDialog">
    <p-header>
        <span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Please Explain' | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12">
            <label>{{'Date & Time' | translate}}</label>
            <div class="input-group w-100" style="flex-wrap: nowrap;">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-calendar"></i>
                    </button>
                </div>
                <p-calendar [inputStyle]="{'width':'100%'}" [(ngModel)]="lunchDurationDate" [showTime]="true" disabled class="time-input"></p-calendar>
            </div>
        </div>
        <div class="ui-g-12">
            <label>{{'Description' | translate}}</label>
			<textarea class="w-100" pInputTextarea [rows]="4" maxlength="500" minlength="25" placeholder="{{'Reason why you\'re ending break early' | translate}}" [(ngModel)]="lunchDurationNote" style="font-size: 16px"></textarea>
			<span class="pull-right" [ngClass]="lunchDurationNote?.length < 500 ? 'text-muted' : 'text-danger'" style="font-size: 12px">{{lunchDurationNote?.length || 0}}/500</span>
        </div>
    </div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="closeLunchDurationConfirmDialog()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="submitLunchDuration();punchHistoryListUpdated = [];loadPunchHistoryLazy($event)" class="btn btn-primary" [disabled]="!lunchDurationNote || lunchDurationNote.length < 25"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showPunchDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'80vh'}" [closable]="true" id="punchConfirmationModal" appendTo="body" (onHide)="showPunchDialog = false">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Confirm Weekly Hours' | translate}}</span>
	</p-header>
	<div class="ui-g my-3" id="area-two">
		<div class="ui-g-12 d-flex justify-content-between align-items-center flex-wrap">
			<h4 class="m-0 ml-2">{{'Punch history record from'| translate}} <i class="fa-solid fa-calendar-week text-secondary mr-2"></i>{{punchFromDateDialog}}<i class="fa-solid fa-right-long text-muted mx-1"></i>{{punchToDateDialog}}</h4>
<!--			<div *ngIf="punchFromDateDialog || punchToDateDialog" class="text-secondary ml-2" style="font-size: 16px"></div>-->
		</div>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Punch Records' | translate}}&nbsp;({{totalPunchRecords || 0}})</span>
		</p-header>
		<p-table #dtPunch [value]="punchHistoryList" [responsive]="true" dataKey="id" [customSort]="true" [lazy]="true" styleClass="punch-table" [rows]="10" [paginator]="false" [totalRecords]="totalPunchRecords" class="pioh-data">
			<ng-template pTemplate="header" let-columns let-i="index">
				<tr id="header-row">
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="text-truncate" pTooltip="{{'Date' | translate}}">{{'form.date' | translate}}</span>
						</div>
					</th>
					<th style="width: 55px !important">
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="text-truncate" pTooltip="{{'Day' | translate}}">{{'Day' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="text-truncate" pTooltip="{{'Start Work' | translate}}">{{'Start Work' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="text-truncate" pTooltip="{{'Break' | translate}}">{{'Break' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'End Break' | translate}}">{{'End Break' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'Break Duration' | translate}}">{{'Break Duration' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'End Work' | translate}}">{{'End Work' | translate}}</span>
						</div>
					</th>
					<th style="width: 70px !important">
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'total'  | translate}}
							</span>
						</div>
					</th>
					<th style="width: 70px !important">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'O.T.' | translate}}">{{'O.T.' | translate}}</span>
						</div>
					</th>
					<th style="width: 45px !important">
						<div  class="d-flex align-items-center justify-content-center w-100">
							<i class="fa-solid fa-location-dot text-muted m-auto" pTooltip="{{'Track' | translate}}" tooltipPosition="left"></i>
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'form.date' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'MM/dd/yyyy'}}">{{rowData.punchDate | date: 'MM/dd/yyyy'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Day' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'EEE'}}">{{rowData.punchDate | date: 'EEE'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td class="p-0">
						<div class="d-flex align-items-center">
							<div *ngIf="!rowData.editAllowedCheckIn && !isExceptionPunchInOut(rowData.locationName)" class=" text-left pl-lg-2 pl-sm-0">
								<span class="one-liner d-flex">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.clockInTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedStartWork && rowData.clockInTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockInTimeOriginal" pTooltip="{{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockInTimeOriginal" pTooltip="{{ rowData.missedStartWork? ('Missing' | translate) : ((rowData.clockInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedCheckIn && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy) : ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									{{ rowData.locationName | translate}}
								</span>
							</div>
						</div>
					</td>
					<td class="p-0">
						<div class="d-flex align-items-center justify-content-between">
							<div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedOutForLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null && rowData.employeeClockingLunchHourHistory.length > 1))"  class="one-liner d-flex">
									<span class="mobile-table-label">{{'Break' | translate}}:</span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedLunchOutTime && rowData.lunchOutTime == null ? '--:--' : ''}}</span>
									<span class="d-flex justify-content-between align-items-center" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'" >
										<span *ngIf="rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : ((rowData.lunchOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedOutForLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger text-center' : 'text-center'" >
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
									{{ rowData.locationName | translate}}
								</span>
							</div>
						</div>
					</td>
					<td class="p-0">
						<div class="d-flex align-items-center justify-content-between">
							<div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2" >
								<span class="one-liner">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedBackFromLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1))" class="one-liner d-flex">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger' : ''">{{! rowData.missedLunchInTime && rowData.lunchInTime == null ? '--:--' : ''}}</span>
									<span  [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : ((rowData.lunchInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedBackFromLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									{{ rowData.locationName | translate}}
								</span>
							</div>
						</div>
					</td>
					<td class="p-0">
						<div *ngIf="isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center pl-lg-2">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Break Total' | translate}}: </span>{{rowData.locationName | translate}}
							</span>
						</div>
						<div *ngIf="!isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center justify-content-between pl-lg-2 w-100">
							<span class="one-liner">
								<span class="mobile-table-label">{{'LunchTot' | translate}}: </span>
								{{calCulateTotalLunchTothhmm(rowData)}}
							</span>
						</div>
					</td>
					<td class="p-0">
						<div class="d-flex flex-wrap align-items-center justify-content-start">
							<span class="mobile-table-label mobile-left text-nowrap">{{'WorkEnd' | translate}}: </span>
							<span *ngIf="(!rowData.clockOutTime || !rowData.editAllowedCheckOut) && isExceptionPunchInOut(rowData.locationName)" class="text-nowrap text-truncate text-muted pl-3">{{'no data' | translate}}</span>
							<div *ngIf="!rowData.editAllowedCheckOut && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-3 pl-sm-0 text-left">
								<span class="one-liner d-flex">
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left"  [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedEndWork && rowData.clockOutTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'hh:mm a'}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="bottom" >{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : ((rowData.clockOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
									</span>
								</span>
							</div>
						</div>
					</td>
					<td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span>
                            <span *ngIf="rowData.workHours" class="mobile-big">{{rowData.workHours}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
                            <span *ngIf="!rowData.workHours" class="text-muted">--:--</span>
						</span>
					</td>
					<td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span>
                            <span *ngIf="rowData.workHours" class="mobile-big">{{rowData.workHours}}</span>
                            <span *ngIf="!rowData.workHours" class="text-muted">--:--</span>

						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
							<span *ngIf="rowData.overTimeStr" pTooltip="{{rowData.overTimeStr}}">{{rowData.overTimeStr}}</span>
							<span *ngIf="!rowData.overTimeStr" class="text-muted">--:--</span>
						</span>
					</td>
					<td>
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Location' | translate}}: </span>
							<i *ngIf="rowData.lat && rowData.lng && (!rowData.missedStartWork || !rowData.missedEndWork)" class="fa-solid fa-location-dot text-primary c-pointer"  (click)="viewMap(rowData)" pTooltip="{{'View Map' | translate}}" tooltipPosition="left"></i>
                            <i *ngIf="!rowData.lat || !rowData.lng || rowData.missedStartWork || rowData.missedEndWork" class="fa-solid fa-location-dot text-muted c-pointer"></i>
                        </span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer" let-columns>
				<tr id="footer-row">
					<td colspan="6"></td>
					<td><b class="desktop-table-label pull-right">{{'total' | translate}}:</b></td>
					<td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Hours' | translate}}: </span>
							<span pTooltip="{{getTotalHour(punchHistoryList)}}" tooltipPosition="left">{{getTotalHour(punchHistoryList)}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total Overtime' | translate}}: </span>
							{{getTotalOverrtime(punchHistoryList)}}
						</span>
					</td>
					<td></td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!punchHistoryList">
					<td [attr.colspan]="13" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
	<div class="ui-g-12 ui-sm-12">
		<div class="input-group-append align-items-center justify-content-center">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Signature' | translate}}<span class="text-danger">*</span>:</label>
				<div id="signLicensor" class="align-items-center justify-content-center" [ngStyle]="{'width.px': divWidth}">
					<img *ngIf="!showSignaturePadConfirm" src="{{content.fileUrl}}" height="225"/>
					<signature-pad *ngIf="showSignaturePadConfirm" #signaturePadConfirm [options]="signaturePadConfirmConfig" (onBeginEvent)="drawStartSignaturePad()" (onEndEvent)="drawCompleteSignaturePad()"></signature-pad>
				</div>
				<div class="input-group-append align-items-center justify-content-center" style="padding-top: 10px">
					<button class="btn btn-white" (click)="clearLicensorSign()">
						<i class="fa-solid fa-xmark text-danger px-2"  style="cursor:pointer" title="clear signature"></i>
						<span>{{'Clear signature' | translate}}</span>
					</button>
				</div>
			</div>
		</div>
	</div>

<!--	<div class="ui-g my-3">-->
<!--		<div class="ui-g-12 text-center">-->
<!--			<p-checkbox binary="true" [(ngModel)]="confirmedPunch" [ngModelOptions]="{standalone: true}"></p-checkbox>-->
<!--			<span style="font-size: 16px">{{'I confirm, punch times displayed for this week are accurate and reflect my actual work hours.' | translate}}</span>-->
<!--		</div>-->
<!--	</div>-->
	<p-footer class="d-flex justify-content-between">
<!--		<button class="btn btn-info mx-1" (click)="redirectToPunchHistory()">-->
<!--			<span class="text-nowrap"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Make Correction' | translate}}</span>-->
<!--		</button>&nbsp;-->
		<button class="btn btn-primary mx-1" (click)="submitPunchConfirmation()">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showEnterPinDialog" [modal]="true" (onHide)="showEnterPinDialog = false" [style]="{'width': '90%', 'max-width':'500px'}" class="lunchDurationConfirmDialog">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Employee Time Lookup' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<label>{{'Employee Pin' | translate}}</label>
			<input type="text" size="4" [(ngModel)]="pinConfirm" placeholder="{{'Enter pin' | translate}}" pInputText>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="showEnterPinDialog = false" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="confirmPin();" class="btn btn-primary" [disabled]="!pinConfirm && pinConfirm?.length >= 4"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>
