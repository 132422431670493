<div class=" ui-g card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'My Messages' | translate}}</h1>
    <div class="ui-g crm-dashboad">
		<div class="ui-g-2 ui-sm-12 overview-box">
            <p-panel id="table-ppanel" [toggleable]="true">
                <p-header class="flex-grow-1">
                    <span><i class="fa-solid fa-bars text-primary mr-2"></i>{{'Menu' | translate}}</span>
                </p-header>
                <p-table #dt [value]="dataMenu" dataKey="id">
                    <ng-template pTemplate="body" let-rowData>
                        <tr class="c-pointer" style="cursor: pointer !important">
                            <td (click)="selectMenu(rowData?.name)">
								<span class="d-flex align-items-center justify-content-between">
									<span class="one-liner" pTooltip="{{rowData?.name}}" tooltipPosition="left">{{ rowData?.name}}</span>
									<span *ngIf="rowData?.value === 0" class="badge badge-muted">{{ rowData?.value }}</span>
									<span *ngIf="rowData?.value > 0" class="badge badge-primary">{{ rowData?.value }}</span>
								</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-panel>
        </div>
        <div class="ui-g-4 ui-sm-12 overview-box">
            <p-panel id="table-ppanel" [toggleable]="true">
                <p-header class="flex-grow-1">
                    <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Messages' | translate}} ({{totalElement}})</span>
                </p-header>
                <p-table [columns]="cols" #table [value]="mailList" selectionMode="single" dataKey="id" [lazy]="false" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [responsive]="true" [globalFilterFields]="['recievers']">
                    <ng-template pTemplate="caption">
                        <div class="d-flex flex-wrap align-items-center justify-content-end" style="row-gap: 10px">
                            <div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
                                <div class="input-group-prepend p-0">
                                    <button class="btn btn-white" id="button-addon2" type="button">
                                        <i class="fa-solid fa-filter"></i>
                                    </button>
                                </div>
                                <input type="text" [(ngModel)]="textSearch" pInputText placeholder="{{'Filter' | translate}}" (input)="table.filterGlobal($event?.target?.value, 'contains')">
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr [pSelectableRow]="rowData">
                            <td (click)="selectMail(rowData)">
								<p>{{rowData?.recievers}} - {{rowData?.createdAt | date: 'MM/dd/yyyy HH:mm'}}</p>
							</td>
                        </tr>
                    </ng-template>
					<ng-template pTemplate="emptymessage" let-columns>
						<tr *ngIf="!loading">
							<td [attr.colspan]="7" class="text-center py-5">
								<div class="text-muted my-5">{{'no data' | translate}}</div>
							</td>
						</tr>
						<tr *ngIf="loading">
							<td [attr.colspan]="7" class="text-center py-5">
								<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
							</td>
						</tr>
					</ng-template>
                </p-table>
            </p-panel>
        </div>
		<div class="ui-g-6 ui-sm-12 overview-box">
			<p-panel id="table-ppanel" [toggleable]="true">
				<p-header class="flex-grow-1">
                    <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Email' | translate}}</span>
                </p-header>

				<div class="d-flex align-items-center justify-content-between px-2" style="height: 63px" *ngIf="!selectedMail">
					<span class="text-muted">{{'Select an item to view' | translate}}</span>
				</div>
				
				<div *ngIf="selectedMail" class="row col-md-12 mt-3">
					<p> Recievers: {{selectedMailData?.recievers}}</p>
					<p> Subject: {{selectedMailData?.subject}}</p>
					<p> Create At: {{selectedMailData?.createdAt | date: 'MM/dd/yyyy HH:mm'}}</p>
                    <p> Attach Files: {{selectedMailData?.documentIds?.length}} {{'File Uploaded' | translate}}</p>
                    <div class="col-md-12" *ngIf="selectedMailData?.documentIds?.length > 0">
                        <ul *ngIf="selectedMailData?.lstFileName?.length > 0">
                            <li *ngFor="let attach of selectedMailData.lstFileName">
                                <a target="_blank" href="{{attach?.fileUrl}}">{{attach?.fileName}}</a>
                            </li>
                        </ul>
                    </div>
					<p> Body: </p>
					<div [innerHTML]="selectedMailData?.body | safeHtml" class="ql-editor p-4">
					</div>
				</div>
			</p-panel>
		</div>
    </div>
</div>

<p-toast position="bottom-right" key="br"></p-toast>
