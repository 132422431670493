<div class="ui-g pb-4">
    <div class="ui-g-6 ui-sm-12">
        <label>{{'Field' | translate}}</label>
        <div class="input-group w-100">
            <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                    <i class="fa-solid fa-mouse-pointer"></i>
                </button>
            </div>
            <p-dropdown [options]="fieldsName" style="flex-grow: 1 !important" placeholder="Select A Field" [(ngModel)]="selectedField" (onChange)="selectField($event)">
                <ng-template pTemplate="item" let-item>
                    {{item.label | translate}}
                </ng-template>
                <ng-template let-item pTemplate="selectedItem">
                    {{item.label | translate}}
                </ng-template>
            </p-dropdown>
        </div>
    </div>
    <div class="ui-g-6 ui-sm-12">
        <label>{{'Date Range' | translate}}</label>
        <div class="input-group w-100">
            <div class="input-group-prepend p-0">
                <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                    <i class="fa-solid fa-calendar-week"></i>
                </button>
            </div>
			<mat-form-field class="w-100" style="width: 50% !important; flex-grow:1">
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate matInput style="padding-left: 10px;" [(ngModel)]="rangeDates[0]" name="Startdate" (focus)="picker.open()">
					<input matEndDate matInput [(ngModel)]="rangeDates[1]" name="Enddate" (focus)="picker.open()">
				</mat-date-range-input>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
            <!-- <p-calendar [(ngModel)]="rangeDates" selectionMode="range" readonlyInput="true" placeholder="Date" style="flex-grow:1"></p-calendar> -->
            <div class="input-group-append pl-3">
                <button class="btn btn-white" (click)="reset()" pTooltip="{{'button.reset' | translate}}" tooltipPosition="left"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
                <button class="btn btn-white" (click)="loadDataSource()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
            </div>
        </div>
    </div>
</div>
<p-panel id="table-ppanel" [toggleable]="true" class="mb-4">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History:' | translate}} {{historyFor?.label}} <span *ngIf="(dataSource$|async)?.content">({{(dataSource$|async)?.content.length}})</span></span>
	</p-header>
    <p-table #table [value]="(dataSource$|async)?.content" [rows]="(dataSource$|async)?.size" [paginator]="false" (onLazyLoad)="loadDataSource($event)" [lazy]="true" [responsive]="true" [loading]="loading">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumns" [pSortableColumn]="col?.field" [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
					</span>
				</th>
			</tr>
		</ng-template> 
		<ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of displayColumns">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'updatedDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedDate" pTooltip="{{rowData.updatedDate | date: 'MM/dd/yyyy h:mm a'}}">{{rowData.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
								<span *ngIf="!rowData.updatedDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'userName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Username' | translate}}: </span>
								<span *ngIf="rowData.userName" pTooltip="{{rowData.userName}}">{{rowData.userName}}</span>
								<span *ngIf="!rowData.userName" class="text-muted">{{'no data' | translate}}</span>		
							</span>
						</span>
						<span *ngSwitchCase="'oldValue'">
                            <span class="one-liner" *ngIf="selectedField !== 'documentId'">
                                <span class="mobile-table-label">{{'Old Value' | translate}}: </span>
                                <span *ngIf="rowData.oldValue" pTooltip="{{rowData.oldValue}}">{{rowData.oldValue}}</span>
                                <span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
                            <span class="one-liner" *ngIf="selectedField === 'documentId'">
                                <span class="mobile-table-label">{{'Old Value' | translate}}: </span>
                                <i *ngIf="rowData.oldValue" (click)="onViewDocument(rowData.oldValue); viewDocument.moveOnTop()" 
                                class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Attachments'}}"></i>
                                <span *ngIf="!rowData.oldValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
						</span>
						<span *ngSwitchCase="'newValue'">
                            <span class="one-liner" *ngIf="selectedField !== 'documentId'">
                                <span class="mobile-table-label">{{'New Value' | translate}}: </span>
                                <span *ngIf="rowData.newValue" pTooltip="{{rowData.newValue}}">{{rowData.newValue}}</span>
                                <span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
                            <span class="one-liner" *ngIf="selectedField === 'documentId'">
                                <span class="mobile-table-label">{{'New Value' | translate}}: </span>
                                <i *ngIf="rowData.newValue" (click)="onViewDocument(rowData.newValue); viewDocument.moveOnTop()" 
                                class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Attachments'}}"></i>
                                <span *ngIf="!rowData.newValue" class="text-muted">{{'no data' | translate}}</span>
                            </span>
						</span>
						<span *ngSwitchCase="'action'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Action' | translate}}: </span>
								<span *ngIf="rowData.action" pTooltip="{{rowData.action}}">{{rowData.action}}</span>
								<span *ngIf="!rowData.action" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading && (!totalRecords || !totalRecords.length)">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted">{{'no data' | translate}}</span>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted">{{'Loading...' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<br>
<p-dialog #viewDocument header="View Document" *ngIf="showViewFileDialog" [(visible)]="showViewFileDialog" [modal]="true"
    styleClass="client-dialog" appendTo="body" id="view-document" [modal]="true" [closable] = "true"[responsive]="true">
    <img class="w-100" [src]="fileUrl" id="fullImage">
    <p-footer>
        <div class="text-center">
            <button type="button" pButton (click)="close()" label="Close"></button>
        </div>
    </p-footer>
</p-dialog>