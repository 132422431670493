export class Sip {
    api: string;
    dndApi: string;
    callForwardApi: string;
    phoneSettingsApi: string;
    callLog: string;
    listAvailableDids: string;
    server: string;
    websocketProxyUrl: string;
    iceServer: string;
}
