export class ServiceTypes {

    public serviceType = [{
        "id": 1,
        "name": "Customer Relation Management"
    },
    {
        "id": 2,
        "name": "Employee Management"
    },
    {
        "id": 3,
        "name": "OperrTel"
    }];
}
