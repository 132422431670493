import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
@Directive({
    selector: 'input[numbersOnly]'
})
export class NumbersOnlyDirective {
    @Input() minNumber: string;
    @Input() maxNumber: string;
    @Input() timeFormat: boolean;
    constructor(private _el: ElementRef){
        console.log('anc');
    }
    @HostListener('input', ['$event']) onInputChange(event) {
        console.log('onInputChange');
        const initalValue = this._el.nativeElement.value;
        const newValue = initalValue.replace(/[^0-9]*/g, '');
        let changeableValue = newValue;
        if(!!this.minNumber && !! this.maxNumber){
            if(+changeableValue < +this.minNumber || +changeableValue > +this.maxNumber){
                changeableValue = '';
            }
        } else if(!!this.minNumber){
            if(+changeableValue < +this.minNumber){
                changeableValue = '';
            }
        } else if(!!this.maxNumber){
            if(+changeableValue > +this.maxNumber){
                changeableValue = '';
            }
        }
        this._el.nativeElement.value = changeableValue;

        if ( initalValue !== this._el.nativeElement.value) {
          event.stopPropagation();
        }
    
    }

    @HostListener('blur', ['$event']) onBlurChange(event){
        console.log('abc')
        let value = this._el.nativeElement.value;
        if(!!this.timeFormat && !!value){
            while(value.length < 2){
                value = '0' + value;
            }
        }
        this._el.nativeElement.value = value;
    }
}