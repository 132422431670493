<div class="spinner-overlay" *ngIf="loading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<app-task-management-top-bar [boards]="allProject" (onAdd)="showDialogAddProject=true"></app-task-management-top-bar>
<ul class="nav nav-tabs mt-4">
	<li class="nav-item" (click)="showBoards(); setBoardsBreadcrumb()" style="cursor: pointer">
		<span class="nav-link" [ngClass]="!displayMembers && !displaySettings && !displayAllCards? 'active' : null"  aria-current="page" href="#"><i class="fa-solid fa-chelkboard-user mr-2"></i>{{'Boards' | translate}}</span>
	</li>
	<li class="nav-item" *ngIf="isDisplay()" (click)="memberWorkspace();setMembersBreadcrumb()" style="cursor: pointer">
		<span class="nav-link" [ngClass]="displayMembers? 'active' : null"  href="#" ><i class="fa-solid fa-users mr-2"></i>{{'Members' | translate}}</span>
	</li>
	<li class="nav-item" *ngIf="isDisplayCars()" (click)="showAllCard(); setCardsBreadcrumb()" style="cursor: pointer">
		<span class="nav-link" [ngClass]="displayAllCards? 'active' : null" href="#"><i class="fa-solid fa-sticky-note mr-2"></i>{{'Cards' | translate}}</span>
	</li>
	<li class="nav-item" *ngIf="isDisplay()"  (click)="displaySettings = true;displayMembers=false;displayAllCards=false;setTrackingBreadcrumb()" style="cursor: pointer">
		<span class="nav-link" [ngClass]="displaySettings? 'active' : null" href="#"><i class="fa-solid fa-magnifying-glass mr-2"></i>{{'Activity' | translate}}</span>
	</li>
</ul>
<div class="card add-shadow p-4">
	<h1 *ngIf="!displaySettings && !displayMembers && !displayAllCards"  class="m-0 ml-2 my-4">{{'Task Boards' | translate}}</h1>
	<p-panel *ngIf="!displaySettings && !displayMembers && !displayAllCards" [toggleable]="true">
		<p-header>
			<span><i class="fa-solid fa-sticky-note text-primary mr-2"></i> {{'My Boards' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-2 ui-sm-6">
				<div class="card-project-management justify-center align-center" (click)="showAddProjectModal()">
					<div class="add-new-card-icon">{{'Add New Board' | translate}}</div>
				</div>
			</div>
			<div class="ui-g-2 ui-sm-6"  *ngFor="let p of projectsAll">
				<div class="card-project-management" [ngStyle]="{
						'background-image': 'url(' + p.background + ')',
						'color': p.background?'#fff':'#fff' }" (click)="gotoProjectDetail(p)">
					<div class="project-name-content"><span class="tooltiptext">{{p.name}}</span>
						<pre class="title-show">{{p.name}}</pre></div>
					<div class="project-action-icon" [class.project-name-bg]="p.background">
						<i [ngClass]="{'disabledIcon': isSuperAdmin}" class="material-icons note-item-icon" (click)="openBackgroundPicker($event,p);
							op1.toggle($event)" title="{{'Choose Background Image' | translate}}">image_search</i>
						<i [ngClass]="{'disabledIcon': isSuperAdmin}" class="material-icons note-item-icon" (click)="edit($event,p)" title="{{'Edit this board' | translate}}">edit</i>
						<i [ngClass]="{'disabledIcon': isSuperAdmin}" class="material-icons note-item-icon" (click)="delete($event,p)" title="{{'Delete this board' | translate}}">delete</i>
					</div>
				</div>
			</div>
		</div>
	</p-panel>
	<br *ngIf="!displaySettings && !displayMembers && !displayAllCards">
	<p-panel *ngIf="!displaySettings && !displayMembers && !displayAllCards" [toggleable]="true">
		<p-header>
			<span><i class="fa-solid fa-sticky-note text-primary mr-2"></i> {{'Guest Boards' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-2 ui-sm-6">
				<div class="card-project-management justify-center align-center" (click)="showAddProjectModal()">
					<div class="add-new-card-icon">{{'Add New Board' | translate}}</div>
				</div>
			</div>
			<div class="ui-g-2 ui-sm-6"  *ngFor="let p of guestProjectsList">
				<div class="card-project-management" [ngStyle]="{
						'background-image': 'url(' + p.background + ')',
						'color': p.background?'#fff':'#fff' }" (click)="gotoProjectDetail(p)">
					<div class="project-name-content"><span class="tooltiptext">{{p.name}}</span>
						<pre style="background-color: #ffffff;opacity: 0.65;font-family: IBM Plex Sans;font-size: 16px;font-weight: 500;width: 100%;color: #000;" class="title-show">{{p.name}}</pre></div>
				</div>
			</div>
		</div>
	</p-panel>
	<div class="ui-g-12" *ngIf="displaySettings && !displayMembers">
		<app-tracking-card></app-tracking-card>
	</div>
	<div class="ui-g-12 p-0" *ngIf="!displaySettings && displayMembers">
		<div class="ui-g">
			<div class="ui-g-12">
				<h1 class="m-0 ml-2 my-4">{{'Members' | translate}}</h1>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<p-panel [toggleable]="true" id="table-ppanel">
					<p-header>
						<span><i class="fa-solid fa-bars text-primary mr-2"></i> {{'Board Users' | translate}}</span>
					</p-header>
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center" [ngClass]="!displayGuestList? 'active1' : null" (click)="displayGuest('member')">
							{{'Workspace members' | translate}}
							<span class="badge bg-primary rounded-pill">{{workspace?.length}}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-center" [ngClass]="displayGuestList? 'active1' : null" (click)="displayGuest()">
							{{'Guests' | translate}}
							<span class="badge bg-primary rounded-pill">{{guestWorkspace?.length}}</span>
						</li>
					</ul>
				</p-panel>
			</div>
			<div class="ui-g-9 ui-sm-12">
				<p-panel [toggleable]="true" id="table-ppanel">
					<p-header>
						<span [ngClass]="!displayGuestList? null : 'd-none'"><i class="fa-solid fa-users text-primary mr-2"></i> {{'Members' | translate}}</span>
						<span [ngClass]="displayGuestList? null : 'd-none'"><i class="fa-solid fa-users text-primary mr-2"></i> {{'Guests' | translate}}</span>
					</p-header>
					<div class="d-flex justify-content-between m-0 p-3">
						<span>
							<button *ngIf="!displayGuestList" class="btn btn-primary" (click)="inviteMember()">
								<span class="text-nowrap"><i class="fa-solid fa-user-plus"></i> {{'Invite' | translate}}</span>
							</button>
						</span>
						<div class="input-group w-100" style="max-width: 350px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-filter"></i>
								</button>
							</div>
							<input type="text" pInputText placeholder="{{'Filter by name' | translate}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchText" (keyup)="search()" >
							<span *ngIf="searchText" class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="removeSearchText()">
									<i class="fa-solid fa-xmark text-danger mx-1"></i>
								</button>
							</span>
						</div>
					</div>
					<div *ngFor="let member of memberList; let i = index" class="card add-shadow m-0 p-3">
						<div class="d-flex align-items-center justify-content-between w-100" style="column-gap: 30px">
							<span class="name" [ngStyle]="{background: member.userDetail.color}">{{member.userDetail.displayName}}</span>
							<div class="flex-grow-1">
								<h5 class="m-0 one-liner">{{member.userDetail.name}} <span class="badge bg-success">{{member.boardDetails.length}}</span></h5>
								<span class="text-secondary one-liner"><i class="fa-solid fa-envelope mr-2"></i>{{member.userDetail.userName}}</span>
							</div>
							<span>
								<button class="btn btn-white mx-1" (click)="op4.toggle($event); selectMember(member)" pTooltip="{{'Remove from' | translate}}" tooltipPosition="right"><i class="fa-solid fa-user-xmark text-danger"></i></button>
								<button class="btn btn-white mx-1" (click)="op3.toggle($event);" pTooltip="{{'Edit Role' | translate}}" tooltipPosition="right"><i class="fa-solid fa-gear text-primary"></i></button>
							</span>
							
						</div>
					</div>
				</p-panel>
			</div>
		</div>
	</div>
	<div class="ui-g-12" *ngIf="displayAllCards">
		<app-all-card-list-task></app-all-card-list-task>
	</div>
</div>


<p-dialog header="{{'Add New Board' | translate}}" [dismissableMask]="true" showEffect="true" height="500px" width="550px"
    [modal]="true" [(visible)]="showDialogAddProject" (keydown.enter)="addProject()">
    <div class="ui-g-12 note-popup-content text-center" style="border: 1px solid #d8d8d8;" >
        <div class="error-wrapper">
            <span class="note-error-message">{{errorMessage}}</span>
        </div>
        <div class="ui-g-12">
            <input type="text" maxlength="250" [(ngModel)]="newProjectName" pInputText placeholder="{{'Board Name' | translate}}" autofocus>
        </div>
        <div class="ui-g-12 notes-action">
            <p-button label="{{'Save' | translate}}" (onClick)="addProject()"
                [disabled]="!newProjectName || newProjectName.trim() === ''"></p-button>
            <p-button label="{{'Cancel' | translate}}" (onClick)="showDialogAddProject=false"></p-button>
        </div>
    </div>
</p-dialog>

<p-dialog header="{{'Update Board' | translate}}" [dismissableMask]="true" showEffect="true" height="500px" width="550px" [modal]="true"
    [(visible)]="showDialogUpdateProject" (keydown.enter)="confirmUpdate()">
    <div class="note-popup-content text-center">
        <div class="error-wrapper">
            <span class="note-error-message">{{errorMessage}}</span>
        </div>
        <div class="ui-g-12">
            <input type="text" maxlength="250" [(ngModel)]="selectedProject.name" pInputText placeholder="{{'Board Name' | translate}}" autofocus>
        </div>
        <div class="ui-g-12 notes-action">
            <p-button label="{{'Save' | translate}}" (onClick)="confirmUpdate()"
                [disabled]="!selectedProject.name || selectedProject.name.trim() === ''"></p-button>
            <p-button label="{{'Cancel' | translate}}" (onClick)="showDialogUpdateProject=false"></p-button>
        </div>
    </div>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmDeleteDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
		{{'Are you want to delete board' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12" align="right">
                <button type="button" pButton label="{{'Yes' | translate}}" (click)="confirmDelete()"></button>
                <button type="button" pButton label="{{'No' | translate}}" (click)="showConfirmDeleteDialog=false"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-overlayPanel #op1>
    <app-background-panel [background]="selectedProject.background" (onPick)="onPickBackgroundImage($event)"></app-background-panel>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
    <div *ngFor="let board of selectedMember" class="boardSelected">
        <span (click)="op5.toggle($event);selectedBoard=board; ">{{board.boardName}}</span>
    </div>
 </p-overlayPanel>
<p-overlayPanel #op5 appendTo="body">
    <div class="boardSelected" [ngClass]="{'change-style': selectedBoard?.role === 'ADMIN'}" (click)="changeMemberRole(1)"> {{'Admin' | translate}} </div>
    <div class="boardSelected" [ngClass]="{'change-style': selectedBoard?.role === 'SUB_ADMIN'}" (click)="changeMemberRole(2)"> {{'Sub Admin' | translate}} </div>
    <div class="boardSelected" [ngClass]="{'change-style': selectedBoard?.role === 'MEMBER'}" (click)="changeMemberRole(0)"> {{'Guest' | translate}} </div>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body" >
    <div class="boardSelected"> {{'Admin' | translate}} </div>
    <div class="boardSelected"> {{'Sub Admin' | translate}} </div>
    <div class="boardSelected"> {{'Guest' | translate}} </div>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
    <div class="boardSelected" (click)="remove()">
		{{'All' | translate}}
    </div>
    <div *ngFor="let board of selectedMember" class="boardSelected" (click)="remove(board)">
        {{board.boardName}}
    </div>
</p-overlayPanel>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmDelete" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
		{{'Are you want to delete user from board' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12" align="right">
                <button type="button" pButton label="{{'Yes' | translate}}" (click)="deletedFromBoard()"></button>
                <button type="button" pButton label="{{'No' | translate}}" (click)="showConfirmDelete=false"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>
