import { Injectable } from '@angular/core';
import { Subject, Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IdleTimerService {
  private timer$: Observable<number>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor() {}

  startTimer(inactivityPeriod: number) {
    this.timer$ = timer(inactivityPeriod * 1000, inactivityPeriod * 1000);
    this.timer$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.userInactive.next();
    });
  }

  stopTimer() {
    this.destroy$.next();
  }

  private userInactive: Subject<void> = new Subject<void>();

  onUserInactive(): Observable<void> {
    return this.userInactive.asObservable();
  }
}