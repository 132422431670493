import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable, Subject } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import * as moment from 'moment';
import { Package } from '../../phonesystem/model/package.model';
import { Message } from 'primeng/api';
import { ContractorInvoice } from '../model/contractor-invoice.model';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ContractorInvoiceService extends AbstractService<ContractorInvoice> {
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CONTRACTOR_INVOICE, '');

    }
    generatePdfFile(data?: any) {
        // const params = this.createParams(options);
        return this._http.post<any>(this.baseUrlV2 + 'pdf', data).pipe(map(res => res));
    }
    notifyMessage(message: Message) {
        this.messageSubject.next(message);
    }
    getAllList(options?: any): Observable<ContractorInvoice[]> {
        // return this._http.post<ContractorInvoice[]>(`http://localhost:8013/api/v2/contractor/invoice/search`, options).pipe(map(resp => resp));
        return this._http.post<ContractorInvoice[]>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }
    getListByPage(params: any): Observable<Page<ContractorInvoice>> {
        return this._http.get<Page<ContractorInvoice>>(this.baseUrl, { params: params }).pipe(map(resp => resp));
    }

    checkGenerate(options: any): Observable<Page<ContractorInvoice>> {
        if (options.toDate) {
            options.toDate = moment(options.toDate).toISOString(true);
        }

        if (options.fromDate) {
            options.fromDate = moment(options.fromDate).toISOString(true);
        }

        const reqUrl = `${this.baseUrlV2}${Constants.URI.CONTRACTOR_INVOICE.CHECKGENERATE}`;
        return this._http.post<Page<ContractorInvoice>>(reqUrl, options).pipe(map(resp => resp));
    }
    generateGenerate(options: any): Observable<Page<ContractorInvoice>> {
        options.toDate = moment(options.toDate).toISOString(true);
        options.fromDate = moment(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrlV2}${Constants.URI.CONTRACTOR_INVOICE.GENERATE}`;
        return this._http.post<Page<ContractorInvoice>>(reqUrl, options).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<ContractorInvoice>> {
        if (options.toDate) {
            options.toDate = moment(options.toDate).toISOString(true);
        }
        if (options.fromDate) {
            options.fromDate = moment(options.fromDate).toISOString(true);
        }
        const fields: any = { page: options.page, size: options.size };
        if (options.sort) {
            fields.sort = options.sort;
        }
        const reqUrl = `${this.baseUrlV2}search`;
        return this._http.post<Page<ContractorInvoice>>(reqUrl, options).pipe(map(resp => resp));
    }
    exportPdf(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf/${id}`)
            .pipe(map(res => res));
    }

    exportPdfWithName(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}pdf-name/${id}`)
            .pipe(map(res => res));
    }

    generateContractorInvoicePdfWithFileName(data?: any) {
        return this._http.post<any>(this.baseUrlV2 + 'pdf-name', data).pipe(map(res => res));
    }

    updateInactiveStatus(id: number): Observable<any> {
        return this._http.get(`${this.baseUrlV2}${id}/in-active`).pipe(map(resp => resp));
    }

    updateInvoiceStatus(option: any): Observable<any> {
        return this._http.post(`${this.baseUrlV2}update-status`, option).pipe(map(resp => resp));
    }

    findAllCreatedByContractorInvoiceByCompanyId(companyId: number): Observable<any> {
        return this._http.get(`${this.baseUrlV2}all-created-by/${companyId}`).pipe(map(resp => resp));
    }

    sendingContractorPaymentConfirmation(option: any): Observable<any> {
        return this._http.post(`${this.baseUrlV2}payment-confirmation-email`, option).pipe(map(resp => resp));
    }

    reCalculatePaidStatus(id: Number): Observable<any> {
        return this._http.get(`${this.baseUrlV2}re-calculate-paid-status${id}`).pipe(map(resp => resp));
    }
}
