import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from 'app/company/service/company.service';
import { CrmClient } from 'app/crm/model/base';
import { ClientService } from 'app/crm/service/client.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { JobFollowUpDetails } from '../../model/job-follow-up-details.model';
import { JobFollowUpModel } from '../../model/job-follow-up.model';
import { JobFollowUpService } from '../../service/job-follow-up.service';
import * as _ from 'lodash';
import { EmployeeService } from 'app/employee/service/employee.service';
import {TotalJobService} from '../../service/total-job.service';
import {FollowUpLabelService} from '../../service/follow-up-label.service';
import { MessageService } from 'primeng';
import { Router } from '@angular/router';
import { FollowUplabel } from '../../model/follow-up-label';
import { ContactService } from 'app/crm/service/contact.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import {FileUtility} from '../../../../shared/utility/file.utility';
import * as moment from 'moment';
import {TranslatePipe} from '@ngx-translate/core';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';



@Component({
  selector: 'app-job-follow-up-form',
  templateUrl: './job-follow-up-form.component.html',
  styleUrls: ['./job-follow-up-form.component.scss']
})
export class JobFollowUpFormComponent implements OnInit {
  @Input() mode;
  @Input() jobDetailsId;
  fromDate: Date;
  toDate: Date;
  jobFollowUp = new JobFollowUpModel();
  totalJobDetails: JobFollowUpDetails[] = [];
  cycle = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Bi-Weekly', value: 'Bi-Weekly' },
    { label: 'Monthly', value: 'Monthly' }
  ];

  isPlatformAdmin = false;
  isCompanyAdmin = false;
  clientList = [];
  today = new Date();
  allCompanyList: any;
  selectedCompany: any;
  billerEmployees: any;
  supportEmployees: any;
  billerArray: any;
  supportArray: any;
  clients: any;
  disableInput = false;
  createdOn = new Date();
  allLabel: any[];
  listLabelColor = ['#61bd4f', '#f2d600', '#ff9f1a', '#eb5a46', '#c377e0', '#0079bf', '#00c2e0', '#51e898', '#ff78cb', '#344563'];
  selectedLabel: any = {};
  showLabelForm = false;
  loadingLabel = false;
  followUpLabel = new FollowUplabel();
  dropdownLabel: any;
  showErrorMsg: boolean;
  customizeColumns =[
    { label: 'Created On', value: 'createdOn' },
    { label: 'Cycle', value: 'cycle' },
    { label: 'Contact Number', value: 'contactNumber' },
    { label: 'Email ID 1', value: 'emailId1' },
    { label: 'Email ID 2', value: 'emailId2' },
    { label: 'Note', value: 'note' }
  ];
  customCol: any = {};
  customColumnName:any = [];
  supportSearch: any = [];
  timeSpent =  new Date();
  disableButton: boolean = false;
  count: number = 0;
  colHeading: any[];
  historyShow: boolean = false;
  selectedJobId: number;
  selectedSortOption: any;
  selectedField: string;
  searchText: string;
  widthBase: number;
  displayConfirmDialog = 0;
  displaySaveUp = 0;
  totalJobDetailId: number = 0;

  numberSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'a-z' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'z-a' }
  ];
  textSortOptions = [
    { name: 'SortAToZ', value: 'ASC', img: 'a-z' },
    { name: 'SortZToA', value: 'DESC', img: 'z-a' },
  ];
  isCheckSave = false;
  selectedSupportList: any;
  jobs: JobFollowUpDetails[];
  showHighlighted: boolean;
  duplicates: any[];
  showErr: boolean;
  highlightRow = [];
  maxDate: Date = new Date();
  constructor(private clientService: ClientService, private authService: AuthService,
    private jobFollowUpService: JobFollowUpService, private companyService: CompanyService,
    private employeeService: EmployeeService, private messageService: MessageService,
    private translatePipe: TranslatePipe,
    private router: Router, private totalJobService: TotalJobService,
    private followUpLabelService: FollowUpLabelService,
    private contactService: ContactService,
    private exportMonitorService: ExportMonitorService,
    private monitoringDetailsService: MonitoringDetailsService,) { }

  ngOnInit() {
    let show:boolean = this.mode !== 'create' ?  true : false;
    this.colHeading =[
      { label: 'Created On', field: 'createdOn',width:102,show:false, sort: 'number'},
      { label: 'Bases', field: 'bases',  show:true,width:this.widthBase, required:true, sort: 'text'},
      { label: 'Biller', field: 'biller',  show:true,width:this.widthBase, required:true, sort: 'text'},
      { label: 'Support', field: 'support',  show:true,width:this.widthBase, required:true, sort: 'text'},
      { label: 'Cycle', field: 'cycle',width:150, show:false, sort: 'text'},
      { label: 'Contact Name', field: 'contactName', show:true,width:150, required:true, sort: 'text'},
      { label: 'Contact Number', field: 'contactNumber',width:150, show:false, sort: 'text'},
      { label: 'Email ID 1', field: 'emailId1', show:false,width:150, sort: 'text'},
      { label: 'Email ID 2', field: 'emailId2', show:false,width:150, sort: 'text'},
      { label: 'Status', field: 'followUp', show:true, required:true,width:150, sort: 'text'},
      { label: 'Status Date', field: 'statusDate', show:true, width:108, sort: 'number'},
      { label: 'Note', field: 'note', show:false,width:500, sort: 'text'},
      { label: 'History', field: 'history', show:show, width:70},
      { label: '', field: '',width:26, show:true}
    ];
    localStorage.removeItem('_jobFollowUp');
    if (this.mode === 'view') {
        localStorage.setItem('_jobFollowUp', JSON.stringify(false));
    } else {
        localStorage.setItem('_jobFollowUp', JSON.stringify(true));
    }
    if(this.mode === 'view') { this.disableInput = true; }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.isCompanyAdmin = this.authService.isSubCompanyAd() || this.authService.isCompanyAd();
    this.selectedCompany = {
      key: this.authService.getCurrentCompanyId()
    }
    this.getClientList();
    this.getBillerEmployees();
    this.getSupportEmployees();
    this.followUpLabels();
    // this.getUserCustomizeCols();
    if (this.jobDetailsId) {
      this.jobFollowUpService.search({ id: this.jobDetailsId }).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.getUserCustomizeCols();
          this.jobFollowUp = resObj.data.content[0];
          this.totalJobDetails = resObj.data.content[0].totalJobDetails;
          this.jobFollowUp.fromDate = new Date(resObj.data.content[0].fromDate);
          this.jobFollowUp.toDate = new Date(resObj.data.content[0].toDate);
          this.totalJobDetails.forEach(job => {
            if(!job.createdAt) { job.createdAt = new Date(resObj.data.content[0].createdAt); }
            if(job.statusDate) job.statusDate = new Date(job.statusDate);
          });
          this.totalJobDetails.forEach(val => {
            const name = this.clientList.find(ele => ele.value === val.clientId)
            val.clientName = name?.label;
          })
          this.totalJobDetails.sort((a, b) => (a.clientName.toLowerCase() > b.clientName.toLowerCase()) ? 1 : ((b.clientName.toLowerCase() > a.clientName.toLowerCase()) ? -1 : 0));

        }
        this.loadAllCompanies();
      });

      this.jobFollowUpService.getSupportIds({ userName: this.authService.getCurrentUsername() }).subscribe((res: any) => {
        if (res.status === 'SUCCESS' && res.data.length) {
          this.selectedSupportList = res.data[0];
          this.supportSearch = res.data[0].supportIds.map(i => Number(i));
          if (this.supportSearch[0] === 0) {
            this.supportSearch.splice(0, 1);
          }
          this.onSelectEmployee();
        }
      });

    } else {
      this.loadAllCompanies();
    }

    if (this.totalJobDetails.length >= 15) {
      this.displaySaveUp = 1;
    } else {
      this.displaySaveUp = 0;
    }
    this.addJobDetails();
    this.loadAllCompanies();
  }

  followUpLabels() {
    const options: any = {
      status: 1
    };
    if (this.selectedCompany) { options.companyId = this.selectedCompany.key; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.followUpLabelService.getLabelDropdown(options).subscribe((res: any) => {
      this.dropdownLabel = [];
      if (res.status === 'SUCCESS') {
        this.allLabel = res.data;
        res.data.forEach(label => {
          this.dropdownLabel.push({
            label: label.labelName,
            value: label.id.toString(),
            color: label.color
          });
        });
        if ( this.dropdownLabel.length === 0) {
          this.dropdownLabel.push({
            label: 'No Data',
            value: null,
            color: '#c8c8c8'
          });
        }
      }
    });
  }

  addJobDetails() {
    this.showHighlighted = false;
    this.showErrorMsg = false;
    const totalJobDetail = new JobFollowUpDetails();
    totalJobDetail.cycle = this.jobFollowUp.cycle;
    if(this.supportSearch){
      totalJobDetail.supportId = this.supportSearch[0]
    }
    this.totalJobDetails.push(totalJobDetail);
    this.jobs = this.totalJobDetails;
    this.onSelectColumn();
    if (this.totalJobDetails.length >= 15) {
      this.displaySaveUp = 1;
    } else {
      this.displaySaveUp = 0;
    }
  }

  loadAllCompanies() {
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
        if (this.jobFollowUp.companyId) {
          this.selectedCompany = this.allCompanyList.find(company => company.key === this.jobFollowUp.companyId);
        } else {
          this.selectedCompany = this.allCompanyList.find(company => company.key === this.authService.getCurrentCompanyId());
        }
        this.followUpLabels();
      }
    });
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    if (this.selectedCompany) { options.companyId = this.selectedCompany.key; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }

  getBillerEmployees() {
    let employees = [];
    const options: any = {
      status:1
    };
    if (this.selectedCompany) { options.companyId = this.selectedCompany.key; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    options.positionSearch = ['Biller'];
    this.employeeService.getAllsDropdown(options).subscribe((res: any) => {
      this.billerEmployees = [];
      if (res.status === 'SUCCESS') {
        employees = res.data;
        employees.forEach(emp => {
          this.billerEmployees.push({ label: emp.fullName, value: emp.key });
        });
      }
    });
  }

  getSupportEmployees() {
    let employees = [];
    const options: any = {
      status:1
    };
    if (this.selectedCompany) { options.companyId = this.selectedCompany.key; }
    if (!this.isPlatformAdmin) { options.companyId = this.authService.getCurrentCompanyId(); }
    options.positionSearch = ['Support'];
    this.employeeService.getAllsDropdown(options).subscribe((res: any) => {
      this.supportEmployees = [];
      if (res.status === 'SUCCESS') {
        employees = res.data;
        employees.forEach(emp => {
          this.supportEmployees.push({ label: emp.fullName, value: emp.key });
        });
      }
    });
  }


  changeCycle() {
    if (this.jobFollowUp.cycle) {
      this.totalJobDetails.forEach(job => job.cycle = this.jobFollowUp.cycle);
    }
  }

  getTotalJobDetailsView() {
    this.showDuplicateValues();
    if (this.supportSearch.length > 0) {
      return this.totalJobDetails.filter(value => this.supportSearch.indexOf(value.supportId) > -1);
    } else {
      return this.totalJobDetails;
    }

  }

  async setOtherfields(i: number) {
    let contactExist;
    let newContact;
    const clientId = this.totalJobDetails[i].clientId;
    if (this.duplicates.length === 0) this.showHighlighted = false;
    try {
      const resp1 = await this.contactService.getPage({ clientId: clientId }).toPromise();
      const resp2 = await this.totalJobService.jobDetailDropdown({ clientId: clientId }).toPromise();
      if (resp1.data.content.length) { contactExist = resp1.data.content[resp1.data.content.length - 1]; }
      if (resp2.data.length) { newContact = resp2.data[resp2.data.length - 1]; }
      this.totalJobDetails[i].clientId = clientId;
      this.totalJobDetails[i].contactName = (newContact && newContact.contactName) ? newContact.contactName : (contactExist && contactExist.firstName) ? (contactExist.firstName + ' ' + contactExist.lastName) : '';
      this.totalJobDetails[i].contactNumber = (contactExist && contactExist.phone) ? contactExist.phone : '';
      this.totalJobDetails[i].billerId = (newContact && newContact.billerId) ? newContact.billerId : null;
      this.totalJobDetails[i].supportId = (newContact && newContact.supportId && this.supportSearch.includes(newContact.supportId)) ? newContact.supportId : this.supportSearch[0];
      this.totalJobDetails[i].emailId1 = (contactExist && contactExist.email) ? contactExist.email : '';
      this.totalJobDetails[i].emailId2 = (contactExist && contactExist.email2) ? contactExist.email2 : '';
      this.isCheckSave = true;
    } catch (error) {
      // this.messageService.add({ severity: 'error', summary: 'Failed', detail: error });
    }

  }

  onChange() {
    this.isCheckSave = true;
  }

  removeJobDetail(i: number) {
    if (this.mode === 'view') { return; }
    if(!this.totalJobDetailId){
      this.totalJobDetails.splice(i, 1);
      this.highlightRow = []
      return;
    }
    this.totalJobService.delete(this.totalJobDetailId).subscribe((_response) => {
      this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Deleted'), detail: this.translatePipe.transform('Record deleted Successfully')});
      this.totalJobDetails.splice(i, 1);
      if (this.totalJobDetails.length >= 15) {
        this.displaySaveUp = 1;
      } else {
        this.displaySaveUp = 0;
      }
      this.displayConfirmDialog = 0;
      this.totalJobDetailId = 0;
      this.highlightRow = []
    }, (error) => {
      console.log(error);
    });
  }

  isValid() {
    if (!this.selectedCompany || !this.jobFollowUp.fromDate || !this.jobFollowUp.toDate || !this.jobFollowUp.cycle) {
      return true;
    }
  }

  checkIfDuplicateExists(totalJobDetails) {
    const valueArr = totalJobDetails.map(function(item){ return item.clientId });
    const isDuplicate = valueArr.some(function(item, idx){ 
        return valueArr.indexOf(item) != idx 
    });
    return isDuplicate; 
  }

  async saveJobDetails(isCheckClose: boolean) {
    this.showErr = true;
    if(!this.jobFollowUp.fromDate || !this.jobFollowUp.toDate || !this.jobFollowUp.cycle) {
      return   this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Invalid Data'),
          detail: this.translatePipe.transform('Please enter required fields') });
    };
    if(this.checkIfDuplicateExists(this.totalJobDetails)){
      this.showHighlighted = true;
      this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('Information'), detail: this.translatePipe.transform('Please select unique client for each row')});
       return;
    }
    if(isCheckClose) this.disableButton = true;
    if(!isCheckClose) this.count++;
    this.jobFollowUp.totalJobDetails = this.totalJobDetails;
    this.jobFollowUp.companyId = this.selectedCompany.key;

    for (let i = 0; i < this.totalJobDetails.length; i++) {
      if (!this.totalJobDetails[i].billerId || !this.totalJobDetails[i].supportId ||
        !this.totalJobDetails[i].followUp ||
        !this.totalJobDetails[i].clientId || !this.totalJobDetails[i].contactName) {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('InvalidData'), detail: this.translatePipe.transform('PleaseEnterRequiredFields')});
        this.showErrorMsg = true;
        this.disableButton = false;
        return;
      }
      this.showErrorMsg = false;
      this.totalJobDetails[i].lastModifiedBy = this.mode !== 'create' ? this.authService.getCurrentUsername() : null;
    }
    this.isCheckSave = false;
    this.customColumnName.forEach(col => {
      if (col === 'createdOn') { this.customCol.createdOn = true; }
      if (col === 'cycle') { this.customCol.cycle = true; }
      if (col === 'contactNumber') { this.customCol.contactNumber = true; }
      if (col === 'emailId1') { this.customCol.emailId1 = true; }
      if (col === 'emailId2') { this.customCol.emailId2 = true; }
      if (col === 'note') { this.customCol.note = true; }
    });
    this.customCol.userName = this.authService.getCurrentUsername();
    this.jobFollowUp.customColumn = this.customCol;
    this.jobFollowUp.lastModifiedBy = this.authService.getCurrentUsername();
    const options:any = {
      userName:this.authService.getCurrentUsername(),
      supportIds:this.supportSearch,
      userType:this.authService.getUserType()
    }
    if(this.selectedSupportList) options.id = this.selectedSupportList.id;
    await this.jobFollowUpService.saveSupportIds(options).toPromise();
    if (this.jobFollowUp.id) {
      this.jobFollowUpService.updateEntity(this.jobFollowUp).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          // if(isCheckClose || this.count === 1)
            this.messageService.add({ severity: 'info', summary: this.translatePipe.transform('Updated'), detail: this.translatePipe.transform('Client Log Updated successfully')});
          if (isCheckClose) {
            setTimeout(() => {
              this.router.navigate(['/app/job-follow-up/list']);
            }, 1000);
          } else {
            this.setFieldValues(res.data)
          }

          this.monitoringDetailsService.monitorAction(
            'Updated Billing Client Log',
            this.timeSpent,
            {
              updated_billing_client_log_by: this.authService.getCurrentLoggedInName(),
              billing_client_id: res.data.id
            },
            'complete',
            'Billing Client Log',
            0
          );
        }
      }, err => {
        this.disableButton = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: err.message });
      });
    } else {
      this.jobFollowUp.createdByUsr = this.authService.getCurrentUsername();
      this.jobFollowUpService.create(this.jobFollowUp).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          // if(isCheckClose || this.count === 1)
            this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('Client Log Created successfully')});
          if (isCheckClose) {
            setTimeout(() => {
              this.router.navigate(['/app/job-follow-up/list']);
            }, 1000);
          } else {
           this.setFieldValues(res.data)
          }
          this.monitoringDetailsService.monitorAction(
            'Added Billing Client Log',
            this.timeSpent,
            {
              added_billing_client_log_by: this.authService.getCurrentLoggedInName(),
              billing_client_id: res.data.id
            },
            'complete',
            'Billing Client Log',
            0
          );
        }
      }, err => {
        this.disableButton = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: err.message });
      });
    }
    localStorage.removeItem('_jobFollowUp');
    localStorage.setItem('_jobFollowUp', JSON.stringify(false));
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.getClientList();
      this.getBillerEmployees();
      this.getSupportEmployees();
      this.followUpLabels();
      if (this.totalJobDetails.length >= 15) {
        this.displaySaveUp = 1;
      } else {
        this.displaySaveUp = 0;
      }
    }
  }

  enableForm() {
    this.mode = 'edit';
    this.disableInput = false;
    localStorage.removeItem('_jobFollowUp');
    localStorage.setItem('_jobFollowUp', JSON.stringify(true));
    this.monitoringDetailsService.monitorAction(
      'View Billing Client Log',
      this.timeSpent,
      {
        viewed_billing_client_log_by: this.authService.getCurrentLoggedInName(),
        billing_client_id: this.jobDetailsId
      },
      'complete',
      'Billing Client Log',
      0
    );
    this.router.navigate([`/app/job-follow-up/edit/${this.jobDetailsId}`])
  }

  createLabel(type?: string) {
    this.loadingLabel = true;
    this.followUpLabel.labelName = this.selectedLabel.labelName;
    this.followUpLabel.color = this.selectedLabel.color;
    this.followUpLabel.status = 1;
    if (this.selectedCompany) { this.followUpLabel.companyId = this.selectedCompany.key; }
    if (!this.isPlatformAdmin) { this.followUpLabel.companyId = this.authService.getCurrentCompanyId(); }
    if (type) { this.followUpLabel.id = this.selectedLabel.id; }
    this.followUpLabelService.create(this.followUpLabel).subscribe((res: any) => {
      if (res.message === 'FAIL') {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: res.data });
        this.loadingLabel = false;
        this.showLabelForm = false;
        return;
      }
      this.loadingLabel = false;
      this.showLabelForm = false;
      this.followUpLabels();
    }, err => {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: err.message });
      this.loadingLabel = false;
    });
  }

  deleteLabel() {
    const idx = this.allLabel.findIndex(x => x.id === this.selectedLabel.id);
    this.loadingLabel = true;
    this.followUpLabelService.delete(this.selectedLabel.id).subscribe(res => {
      this.loadingLabel = false;
      this.showLabelForm = false;
      this.followUpLabels();
    }, err => {
      this.loadingLabel = false;
    });
  }

  openAddNewLabel() {
    this.selectedLabel = { color: this.listLabelColor[1] };
    this.showLabelForm = true;
  }
  openEditLabel(label) {
    this.selectedLabel = { ...label };
    this.showLabelForm = true;
  }

  onSelectColumn(event?: any) {
    if (event) {
      this.checkColumns(event.value);
    } else {
      const column = this.customColumnName;
      if (column && column.length) { this.checkColumns(column); }
    }
    let totalWidth = 0;
    this.colHeading.filter(c => c.show === true).forEach(c => totalWidth += (c.width ? c.width : 150));
    console.log(totalWidth);
    if (totalWidth >= 1600) {
      this.widthBase = 150;
    } else {
      this.widthBase = null;
    }
    this.colHeading[1].width = this.widthBase;
    this.colHeading[2].width = this.widthBase;
    this.colHeading[3].width = this.widthBase;
    console.log(this.colHeading[1]);
  }

  checkColumns(column) {
    column.forEach(ele => {
      this.colHeading.forEach(col => {
        if (ele === col.field) { col.show = true; }
      });
    });
    this.columnsToShow(column);
    this.totalJobDetails.forEach(job => {
      job.showCreatedOn = column.includes('createdOn') ? true : false;
      job.showCycle = column.includes('cycle') ? true : false;
      job.showNumber = column.includes('contactNumber') ? true : false;
      job.showEmail1 = column.includes('emailId1') ? true : false;
      job.showEmail2 = column.includes('emailId2') ? true : false;
      job.showNote = column.includes('note') ? true : false;
    });
  }

  columnsToShow(column) {
    this.colHeading.forEach(col => {
    if (!column.includes('createdOn') && col.field === 'createdOn') { col.show = false; }
    if (!column.includes('cycle') && col.field === 'cycle') { col.show = false; }
    if (!column.includes('contactNumber') && col.field === 'contactNumber') { col.show = false; }
    if (!column.includes('emailId1') && col.field === 'emailId1') { col.show = false; }
    if (!column.includes('emailId2') && col.field === 'emailId2') { col.show = false; }
    if (!column.includes('note') && col.field === 'note') { col.show = false; }
    });
  }

  getUserCustomizeCols() {
    const customColumnName = [];
    this.jobFollowUpService.getCustomColumn({userName: this.authService.getCurrentUsername()}).subscribe((res: any) => {
      this.customColumnName = [];
      if (res.status === 'SUCCESS' && res.data) {
        if (res.data.createdOn === true) { customColumnName.push('createdOn'); }
        if (res.data.cycle === true) { customColumnName.push('cycle'); }
        if (res.data.contactNumber === true) { customColumnName.push('contactNumber'); }
        if (res.data.emailId1 === true) { customColumnName.push('emailId1'); }
        if (res.data.emailId2 === true) { customColumnName.push('emailId2'); }
        if (res.data.note === true) { customColumnName.push('note'); }
      }
      this.customColumnName = customColumnName;
      this.onSelectColumn();
    });
  }

  escapeHtml(unsafe)
  {
      return unsafe
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");
  }
  exportPdf() {
    const totalJobDetails: any[] = [];
    this.totalJobDetails.forEach(job => {
      const tmp = {
        createdOn : job.createdAt ? moment(job.createdAt).format('MM/DD/yyyy') : '',
        bases: this.escapeHtml(this.clientList.find(c => c.value === job.clientId) ? this.clientList.find(c => c.value === job.clientId).label : ''),
        biller: this.billerEmployees.find(b => b.value === job.billerId) ? this.billerEmployees.find(b => b.value === job.billerId).label : '',
        support: this.supportEmployees.find(b => b.value === job.supportId) ? this.supportEmployees.find(b => b.value === job.supportId).label : '',
        cycle: job.cycle,
        contactNumber: job.contactNumber,
        emailId1: job.emailId1,
        emailId2: job.emailId2,
        status: this.dropdownLabel.find(b => b.value === job.followUp) ? this.dropdownLabel.find(b => b.value === job.followUp).label : '',
        note: job.note,
        contactName: job.contactName,
        statusDate: job.statusDate ? moment(job.statusDate).format('MM/DD/yyyy') : ''
      };
      totalJobDetails.push(tmp);
    });

    let customColumnName = '';
    for (let i = 0; i < this.customColumnName.length; i++) {
      if (i === this.customColumnName.length - 1) {
        customColumnName += this.customizeColumns.find(c => c.value === this.customColumnName[i]).label;
      } else {
        customColumnName += this.customizeColumns.find(c => c.value === this.customColumnName[i]).label + ',';
      }
    }
    const options: any = {
      id: this.jobDetailsId,
      period: moment(this.jobFollowUp.fromDate).format('MM/DD/yyyy')  + ' - ' + moment(this.jobFollowUp.toDate).format('MM/DD/yyyy'),
      cycle: this.jobFollowUp.cycle,
      customColumnName: customColumnName,
      totalJobDetails: totalJobDetails
    };

    if (this.selectedCompany && this.selectedCompany.key) {
      options.companyId = this.selectedCompany.key;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    options.companyName = ((this.allCompanyList||[]).find(company => company.key === options.companyId)||{}).value;

    options.printedBy = this.authService.getCurrentUsername();
    this.jobFollowUpService.exportPdf(options).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
          let data = <any>{
              featureName: 'Job Follow Up',
              fileName: blobUrl,
              fileSize: blob.size,
              action: 'Export',
              createdByUsr: this.authService.getCurrentUsername(),
              companyId: this.authService.getCurrentCompanyId()
          };
          this.exportMonitorService.create(data).subscribe(()=>{
          });
      } else {
        this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting list pdf') });
      }
    }, () => {
      this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Problem exporting'),
          detail: this.translatePipe.transform('Problem exporting list pdf') });
    });
  }

  openHistory(id){
    this.selectedJobId = id;
    this.historyShow = true;
  }

  sortingClicked() {
    if (this.selectedField === 'createdOn') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareDate(a.createdAt, b.createdAt) : -this.compareDate(a.createdAt, b.createdAt)
      );
    }

    if (this.selectedField === 'bases') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareBase(a.clientId, b.clientId) : -this.compareBase(a.clientId, b.clientId)
      );
    }

    if (this.selectedField === 'biller') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareBiller(a.billerId, b.billerId) : -this.compareBiller(a.billerId, b.billerId)
      );
    }

    if (this.selectedField === 'support') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareSupport(a.supportId, b.supportId) : -this.compareSupport(a.supportId, b.supportId)
      );
    }

    if (this.selectedField === 'cycle') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.cycle, b.cycle) : -this.compareText(a.cycle, b.cycle)
      );
    }

    if (this.selectedField === 'contactName') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.contactName, b.contactName) : -this.compareText(a.contactName, b.contactName)
      );
    }

    if (this.selectedField === 'contactNumber') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.contactNumber, b.contactNumber) : -this.compareText(a.contactNumber, b.contactNumber)
      );
    }

    if (this.selectedField === 'emailId1') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.emailId1, b.emailId1) : -this.compareText(a.emailId1, b.emailId1)
      );
    }

    if (this.selectedField === 'emailId2') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.emailId2, b.emailId2) : -this.compareText(a.emailId2, b.emailId2)
      );
    }

    if (this.selectedField === 'followUp') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareFollowUp(a.followUp, b.followUp) : -this.compareFollowUp(a.followUp, b.followUp)
      );
    }

    if (this.selectedField === 'note') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareText(a.note, b.note) : -this.compareText(a.note, b.note)
      );
    }

    if (this.selectedField === 'statusDate') {
      this.totalJobDetails.sort((a, b) => this.selectedSortOption === 'ASC' ?
          this.compareDate(a.statusDate, b.statusDate) : -this.compareDate(a.statusDate, b.statusDate)
      );
    }
  }

  compareText(a, b) {
    if (a == null) { a = ''; }
    if (b == null) { b = ''; }
    return a.localeCompare(b);
  }

  compareDate(a, b) {
    if (a == null) { a = new Date(); }
    if (b == null) { b = new Date(); }
    return a - b;
  }

  compareFollowUp(a, b) {
    const a1 = this.dropdownLabel.find(x => x.value === a) ? this.dropdownLabel.find(x => x.value === a).label : '';
    const b1 = this.dropdownLabel.find(x => x.value === b) ? this.dropdownLabel.find(x => x.value === b).label : '';
    return a1.localeCompare(b1);
  }

  compareSupport(a, b) {
    const a1 = this.supportEmployees.find(x => x.value === a) ? this.supportEmployees.find(x => x.value === a).label : '';
    const b1 = this.supportEmployees.find(x => x.value === b) ? this.supportEmployees.find(x => x.value === b).label : '';
    return a1.localeCompare(b1);
  }

  compareBiller(a, b) {
    const a1 = this.billerEmployees.find(x => x.value === a) ? this.billerEmployees.find(x => x.value === a).label : '';
    const b1 = this.billerEmployees.find(x => x.value === b) ? this.billerEmployees.find(x => x.value === b).label : '';
    return a1.localeCompare(b1);
  }

  compareBase(a, b) {
    const a1 = this.clientList.find(x => x.value === a) ? this.clientList.find(x => x.value === a).label : '';
    const b1 = this.clientList.find(x => x.value === b) ? this.clientList.find(x => x.value === b).label : '';
    return a1.localeCompare(b1);
  }

  search() {
    if (this.searchText == null || this.searchText === '') {
      this.totalJobDetails.forEach(c => {
          c.hide = false;
      });
    } else {
      this.totalJobDetails.forEach(c => {
        c.createdAtStr = moment(c.createdAt).format('MM/DD/YYYY');
        c.statusDateStr = moment(c.statusDate).format('MM/DD/YYYY');
        c.follow = this.dropdownLabel.find(x => x.value === c.followUp) ? this.dropdownLabel.find(x => x.value === c.followUp).label : '';
        c.support = this.supportEmployees.find(x => x.value === c.supportId) ? this.supportEmployees.find(x => x.value === c.supportId).label : '';
        c.biller = this.billerEmployees.find(x => x.value === c.billerId) ? this.billerEmployees.find(x => x.value === c.billerId).label : '';
        c.base = this.clientList.find(x => x.value === c.clientId) ? this.clientList.find(x => x.value === c.clientId).label : '';
      });
      console.log(this.totalJobDetails);
      this.totalJobDetails.forEach(c => {
        if (!(c.follow.includes(this.searchText)
            || c.createdAtStr.includes(this.searchText)
            || c.statusDateStr.includes(this.searchText)
            || c.support.includes(this.searchText)
            || c.biller.includes(this.searchText)
            || c.base.includes(this.searchText)
            || c.contactName?.includes(this.searchText)
            || c.contactNumber?.includes(this.searchText)
            || c.emailId1?.includes(this.searchText)
            || c.emailId2?.includes(this.searchText)
            || c.note?.includes(this.searchText))) {
          c.hide = true;
        }
      });
    }
  }

  showConfirmDialog(number, totalJobDetailId) {
    this.highlightRow[number] = true;
    this.displayConfirmDialog = number;
    this.totalJobDetailId = totalJobDetailId;
  }

  resetHighlight(i:number){
    this.highlightRow = []
  }

  onSelectEmployee(event?){
    this.totalJobDetails.forEach(job => {
      if(!job.supportId){
        job.supportId = this.supportSearch ? this.supportSearch[0] : null
      }
    })
    
  }

  setFieldValues(data){
    this.jobFollowUp = data;
    this.jobFollowUp.fromDate = new Date(data.fromDate);
    this.jobFollowUp.toDate = new Date(data.toDate);
    this.totalJobDetails = data.totalJobDetails;
    this.totalJobDetails.forEach(job => {
      if(!job.createdAt) { job.createdAt = new Date(data.createdAt); }
      if(job.statusDate) job.statusDate = new Date(job.statusDate);
    });
    this.onSelectColumn()
  }

  showDuplicateValues(){
    this.duplicates = [];
    const valueArr = this.totalJobDetails.map(function(item){ if(item.clientId) return Number(item.clientId) });
    this.duplicates = valueArr.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
    }, []);
    if(this.duplicates.length > 0) {
      this.totalJobDetails.forEach(val => {
        val.highLight = this.duplicates.includes(val.clientId) ? true :false
      })
    }
  }

}
