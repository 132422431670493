import { AgmDrawingManager } from '@agm/drawing';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/shared/service/auth/auth.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng';
import { Subject } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { EmployeeService } from 'app/employee/service/employee.service';
import { TurfService } from 'app/map/service/turf.service';
import { Router } from '@angular/router';
import { TransferDataService } from 'app/shared/service/transfer-data.service';

@Component({
  selector: 'app-turf-polygon-drawer-map',
  templateUrl: './turf-polygon-drawer-map.component.html',
  styleUrls: ['./turf-polygon-drawer-map.component.scss'],
  providers: [AgmDrawingManager],
})
export class TurfPolygonDrawerMapComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  @Input()
  turfData: any = {};

  @Input()
  zones: any[] = [];

  @Input() selectedVoterIds = new Set<number>();
  @Input() selectedTurfVoterIds = new Set<number>();

  @Input()
  turfs: any[] = [];

  polygonForm: UntypedFormGroup;

  processing = false;
  clearPolygons = false;
  congestion = false;
  polygonLength = 0;

  polygons: any = [];

  boundCoordinates = [];
  boundCoordinateMap = {};

  multiPolygons = [];

  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  selectedPoint: any;
  selectedMovePoint: any;

  walkerList = [];
  selectedWalkers = [];

  outOfZoneDialog = false;

  isMapView = true;
  turfVoters = [];
  voterColumns = [
    { field: 'id', header: 'ID' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'fullAddress', header: 'Address' },
    { field: 'turfName', header: 'Turf' },
    { field: 'questionair', header: 'Questionnaire' },
    { field: 'status', header: 'Status' },
    { field: 'action', header: 'Action' },
  ];
  ignoreVoterIds = [];

  deleteTurfDialog = false;

  constructor(
    private router: Router,
    private transferDataService: TransferDataService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private employeeService: EmployeeService,
    private messageService: MessageService,
    private turfService: TurfService,
    private translatePipe: TranslatePipe,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.buildForm();
    this.employeeService.getAllsDropdown({
      companyId: this.authService.getCurrentCompanyId(),
      status: 1
    }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.walkerList = resObj.data.map(v => ({
          walkerId: v.key,
          walkerType: v.type,
          value: v.value,
          fullName: v.fullName
        }));
      }
    });
    this.multiPolygons = [];
    if (this.turfData && this.turfData.id) {
      console.log(this.turfData);
      this.multiPolygons = this.turfData.multiPolygons;
      this.turfVoters = this.turfData.voters;
      const title = this.turfData.title;
      if (this.turfData.zones) {
        this.turfData.zones.forEach(zone => {
          if (!this.zones.find(z => z.id === zone.id)) {
            this.zones.push(zone);
          }
        });
      }
      if (this.turfData.voterIds) {
        this.turfData.voterIds.forEach(vId => {
          if (!this.selectedVoterIds.has(vId)) {
            this.selectedVoterIds.add(vId);
          }
        });
      }
      if (this.turfData.walker) {
        this.selectedWalkers = JSON.parse(this.turfData.walker);
      }
      this.polygonForm.patchValue({ polygonName: title, color: this.turfData.color });
    }
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private buildForm() {
    this.polygonForm = this.fb.group({
      polygonName: [this.turfData ? this.turfData.title : '', [Validators.required]],
      limitation: false,
      selectedWalker: null,
      color: this.turfData ? this.turfData.color : '#28a745'
    });
  }

  createTurf() {
    this.processing = true;
    this.updateTurf();
  }

  editTurf() {
    this.multiPolygons = [];
  }

  updateTurf() {
    this.processing = true;
    const multiPolygon = _.map(this.polygons, (e) => {
      return { polygon: _.map(e, (coordinate) => ({ x: coordinate.lng(), y: coordinate.lat() })) };
    });
    multiPolygon.forEach((polygon) => {
      console.log(polygon);

      this.multiPolygons.push(polygon);
    });
    console.log(this.multiPolygons);
    this.createMultiPolygonTurf(this.multiPolygons);
  }

  createMultiPolygonTurf(multiPolygon) {
    const companyId = this.authService.isSuper() || this.authService.isSubSuper() ? null : this.authService.getCurrentCompanyId();
    const payload = {
      companyId: companyId,
      multiPolygon,
      title: this.polygonForm.value.polygonName,
      color: this.polygonForm.value.color,
      createdByUsr: this.authService.getUserInfo().username,
      zones: this.zones ? this.zones.map(zone => {
        if (zone.subZone) {
          return {
            zoneType: zone.type,
            zoneKey: zone.zoneId,
            subZoneKey: zone.id
          }
        }
        return {
          zoneType: zone.type,
          zoneKey: zone.id
        }
      }) : null,
      walker: this.selectedWalkers && this.selectedWalkers.length > 0 ? JSON.stringify(this.selectedWalkers) : null,
      ignoreVoterIds: this.ignoreVoterIds
    };

    if (this.turfData) {
      payload['id'] = this.turfData.id;
      if (this.turfData.polygon) {
        const multiPolygonUpdate = [];
        this.turfData.polygon.getPaths().forEach(mulPolygons => {
          const polygons = [];
          mulPolygons.forEach(p => {
            polygons.push({
              x: p.lng(),
              y: p.lat()
            });
          });
          multiPolygonUpdate.push({
            polygon: polygons
          });
        });
        if (multiPolygonUpdate.length) {
          payload.multiPolygon = multiPolygonUpdate;
        }
      }
    }
    if ((!payload.multiPolygon || payload.multiPolygon.length === 0) && this.turfData) {
      this.deleteTurfDialog = true;
      return;
    }
    this.turfService.createTurf(payload).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Turf is created successfully') });
        this.onSubmit.next(res.data);
      } else {
        this.processing = false;
        if (res.message === 'Out Of Zone') {
          this.outOfZoneDialog = true;
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.message });
        }
      }

    }, (error) => {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: error.error.message });
      this.processing = false;
    });
  }

  polygonChanged(polygons: any) {
    if (!polygons || polygons.length === 0) {
      this.clearPolygons = false;
    }
    this.polygons = polygons;
    this.polygonLength = polygons.length;
  }

  goBack() {
    this.onClose.next('BACK');
  }

  removeAll() {
    this.clearPolygons = true;
    this.multiPolygons = [];
  }

  pointClicked(event) {
    this.selectedPoint = event;
  }

  removePoint() {
    this.selectedMovePoint = this.selectedPoint;
  }

  cancel() {
    this.onClose.next('CANCEL');
  }

  get isSaveButtonEnabled() {
    return this.polygonForm.valid && (this.turfData || (this.polygons && this.polygons.length > 0));
  }

  showQuestionOnListWalk(rowData) {
    if (rowData) {
      return rowData.answerReason === 'Moved'
        || rowData.answerReason === 'Died'
        || rowData.answerReason === 'Non available';
    }
    return false;
  }

  removeTurfVoter(voter) {
    this.ignoreVoterIds.push(voter.id);
    this.turfVoters = this.turfVoters.filter(t => t.id !== voter.id);
  }

  deleteTurf() {
    this.turfService.deleteTurf(this.turfData.id).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('SUCCESS'), detail: this.translatePipe.transform('Turf is deleted successfully') });
        const data = Object.assign({}, this.turfData);
        data.deleted = true;
        //this.turfData.deleted = true;
        this.onSubmit.next(data);
      } else {
        this.processing = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.message });
      }

    }, (error) => {
      this.processing = false;
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: error.message });
    });
  }

  sendSms(turfVoters) {

    console.log("Send SMS");
    console.log(turfVoters);
    
    const turfSend: any = [];
    turfSend.push(turfVoters.title);
    console.log(turfSend);
    this.transferDataService.sendDataTurf(turfSend);
    // window.open('/#/app/phonesystem/send-sms', '_blank');
    this.router.navigate(['app/phonesystem/send-sms']);
  }
}
