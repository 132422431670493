import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { FileUtility } from 'app/shared/utility/file.utility';
import { MessageService } from 'primeng';
import { forkJoin, Subscription } from 'rxjs';
import { PotentialClientService } from '../../service/potential-client.service';

@Component({
  selector: 'app-dispatch-potential-client',
  templateUrl: './dispatch-potential-client.component.html',
  styleUrls: ['./dispatch-potential-client.component.scss']
})
export class DispatchPotentialClientComponent implements OnInit {
  signaturePadConfig: Object = {
    'minWidth': 1,
    'canvasWidth': 300,
    'canvasHeight': 300,
    'canvasBorder': 'none',
    'penColor': '#4a87f3'
  };

  @Input() id;
  @Input() disableEdit;
  private _disableEditingContract = null;
  @Input('disableEditingContract')
  set disableEditingContract(disableEditingContract: Boolean) {
    if (this._disableEditingContract != null) {
      if (disableEditingContract) {
        this.lockEditing();
      } else {
        this.enableEditing();
      }
    }
    this._disableEditingContract = disableEditingContract;
  }
  get disableEditingContract(): Boolean { return this._disableEditingContract; }
  showEditButton = false;
  shareContractDialog = false;
  contractForm: UntypedFormGroup;
  @ViewChild('companySignpad') companySignpad: SignaturePad;
  @ViewChild('clientSignpad') clientSignpad: SignaturePad;
  companyId;
  recipent;
  row;
  emailClientSelect;
  clientName;
  bodyEmail;
  @Output() onHide = new EventEmitter<boolean>();

  constructor(private documentServiceV2: DocumentsServicev2,
    private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private contentService: PotentialClientService) {
    this.route.params.subscribe(params => this.id = +params.id);
  }

  ngOnInit(): void {
    this.contractForm = new UntypedFormGroup({
      baseNumber: new UntypedFormControl(null, [Validators.required]),
      startFee: new UntypedFormControl(null, [Validators.required]),
      vehicleFee: new UntypedFormControl(null, [Validators.required]),

      printNameCompany: new UntypedFormControl(null, [Validators.required]),
      signatureCompany: new UntypedFormControl(null, [Validators.required]),
      emailCompany: new UntypedFormControl(null, [Validators.required, Validators.email]),
      directPhoneCompany: new UntypedFormControl(null, [Validators.required]),
      dateCompany: new UntypedFormControl(null, [Validators.required]),
      clientName: new UntypedFormControl(null, [Validators.required]),
      addressClient: new UntypedFormControl(null, [Validators.required]),
      phoneClient: new UntypedFormControl(null, [Validators.required]),
      printNameClient: new UntypedFormControl(null, [Validators.required]),
      signatureClient: new UntypedFormControl(null, [Validators.required]),
      emailClient: new UntypedFormControl(null, [Validators.required, Validators.email]),
      directPhoneClient: new UntypedFormControl(null, [Validators.required]),
      dateClient: new UntypedFormControl(null, [Validators.required]),
    });
    this.companyId = this.authService.getCurrentCompanyId();
    this.recipent = this.authService.getCurrentUsername();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.disableEditingContract) {
        this.lockEditing();
      } else {
        this.enableEditing();
      }

      if (!this.disableEdit) {
        this.contractForm.controls['baseNumber'].disable();
        this.contractForm.controls['startFee'].disable();
        this.contractForm.controls['vehicleFee'].disable();
        this.contractForm.controls['printNameCompany'].disable();
        this.contractForm.controls['emailCompany'].disable();
        this.contractForm.controls['directPhoneCompany'].disable();
        this.contractForm.controls['dateCompany'].disable();
        this.companySignpad.off();
      }
      this.checkAndSetContractData();
    });
  }

  updateProduct() {
    this.contractForm.value.templateType = 1;
    this.row.contractDocumentInfo = JSON.stringify(this.contractForm.value);
    this.contentService.updateContract(this.row).subscribe(res => {
      this.messageService.add({ severity: 'info', summary: 'Updated', detail: 'eContract updated successfully!' });
      this.onHide.emit(true);
    },
      () => this.messageService.add({ severity: 'error', summary: 'Error', detail: 'eContract updated error!' }));
  }

  generateContract() {
    if (this.companySignpad.isEmpty() || this.clientSignpad.isEmpty()) {
      this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'Please sign the contract !' });
      return;
    }
    this.contractForm.get('signatureCompany').setValue(this.companySignpad.toDataURL());
    this.contractForm.get('signatureClient').setValue(this.clientSignpad.toDataURL());
    if (this.contractForm.valid) {
      this.contentService.generateContractPdf(1, this.contractForm.value).subscribe((res: any) => {
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const contractFile = new File([blob], 'eContract.pdf');
        this.documentServiceV2.uploadFile(contractFile, 'econtract_file', this.companyId, 'eContract').subscribe(res => {
          this.row.contractDocumentId = res.data.id;
          this.updateProduct();
        });
      })
    } else {
      this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'Please enter all fields !' });
    }
  }

  isRequired(field) {
    return this.contractForm.get(field).hasError('required') && this.contractForm.get(field).touched;
  }
  isEmail(field) {
    return this.contractForm.get(field).invalid && this.contractForm.get(field).touched;
  }

  lockEditing() {
    this.contractForm.disable();
    this.companySignpad.off();
    this.clientSignpad.off();
  }

  cancelEditing() {
    this.onHide.emit(true);
  }

  enableEditing() {
    this.contractForm.enable();
    this.companySignpad.on();
    this.clientSignpad.on();
  }

  edit() {
    this.disableEditingContract = false;
  }

  checkAndSetContractData() {
    if (!this.id) {
      return;
    }
    this.contentService.get(this.id).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.row = resObj.data;
        this.clientName = this.row.ptClientName;
        this.emailClientSelect = this.row.email;
        if (this.row.contractDocumentInfo != null) {
          if (this.row.contractSignAt == null || ((new Date().getTime() - this.row.contractSignAt) < 1000 * 3600 * 24)) {
            this.showEditButton = true;
          }

          if (this.contractForm != null) {
            this.contractForm.patchValue(JSON.parse(this.row.contractDocumentInfo));
            this.companySignpad.fromDataURL(JSON.parse(this.row.contractDocumentInfo).signatureCompany);
            this.clientSignpad.fromDataURL(JSON.parse(this.row.contractDocumentInfo).signatureClient);
          }
        }
      } else {
        this.contractForm.reset();
        this.companySignpad.clear();
        this.clientSignpad.clear();
      }
    });
  }

  clearSign(type?) {
    if (!type) {
      this.contractForm.get('signatureCompany').setValue(null);
      if (this.companySignpad) {
        this.companySignpad.clear();
        this.contractForm.get('signatureCompany').setValue(null);
      }
    } else {
      this.contractForm.get('signatureClient').setValue(null);
      if (this.clientSignpad) {
        this.clientSignpad.clear();
        this.contractForm.get('signatureClient').setValue(null);
      }
    }
  }

  sendEmail() {
    const emails = [];
    const url = location.origin + '/#/dispatch-potential-client/' + this.id;
    this.bodyEmail = 'Hi ' + this.clientName + ',<br>Click on view <a href="' + url + '">eContract</a> hyberlink  have access to the contract, please fill out the required information and sign then click <strong>Submit</strong> when done. </br></br> Best Regards!<br> For any concern or support email us.</br>Support@operrwork.com';
    emails.push(this.emailClientSelect);
    const payload = {
      toEmails: emails,
      subject: 'eContract',
      body: this.bodyEmail,
      emailCompanyId: this.authService.getUserInfo().companyId,
    }
    this.contentService.sendEmailQuote(payload).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Sent email quote successfully' });
      this.shareContractDialog = false;
    }, () => {
      this.shareContractDialog = false;
    });
  }

  rejectEmail() {
    this.shareContractDialog = false;
  }

  showSendEmail() {
    if (!this.clientName || !this.emailClientSelect) {
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Please update client name or email for client' });
      return;
    }
    this.shareContractDialog = true;
    const url = location.origin + '/#/dispatch-potential-client/' + this.id;
    this.bodyEmail = 'Hi ' + this.clientName + ',<br>Click on view <a href="' + url + '">eContract</a> hyberlink  have access o the contract, please fill out the required information and sign then click <strong>Submit</strong> when done. </br></br> Best Regards!<br> For any concern or support email us.</br>Support@operrwork.com';
  }

  copyLink() {
    return location.origin + '/#/dispatch-potential-client/' + this.id;
  }

  onClipboardCopy(successful: boolean): void {
    if (successful) {
      this.messageService.add({ severity: 'success', summary: 'Copied', detail: 'Copied successfully' });
    }
  }
}
