<p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem">
    <ng-template pTemplate="item" let-item let-i="index">
        {{item.label | translate}}
    </ng-template>
</p-tabMenu>
<div class="spinner-overlay" *ngIf="fullLoading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Notebook' | translate}}</h1>
	<div class="ui-g h-100">
		<div class="ui-g-3 ui-sm-12 pl-lg-0 pr-lg-2">
			<p-panel [toggleable]="true" id="table-ppanel" class="notes-area mb-2">
				<p-header>
					<span><i class="fa-solid fa-sticky-note text-primary mr-2"></i>{{'Notes' | translate}}</span>
				</p-header>
				<div class="p-3 bottom-border bg-white" id="findNotes">
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-magnifying-glass"></i>
							</button>
						</div>
						<input type="text" id="search_box" pInputText [(ngModel)]="searchTerm" (ngModelChange)="onSearch($event)" placeholder="{{'Find notes' | translate}}">
					</div>
				</div>
				<div *ngFor="let note of notes" (click)="changeNote(note)">
                    <app-note-item [data]="note" (onEditName)="openFormEditName($event)" (onDelete)="openDeletionConfirmationDialog($event, 'note')" (onShare)="openShareDialog($event,'note')" [isActive]="note.id === activeNote.id"></app-note-item>
                </div>
				<p-footer class="d-flex justify-content-center">
					<button *ngIf="!activeFolder.showAll && activeFolder.owner" class="btn btn-white" (click)="openFormAddNote()" >
						<span class="text-nowrap"><i class="fa-solid fa-plus text-primary mr-2"></i> {{'Create Note' | translate}}</span>
					</button>
					<button *ngIf="activeFolder.showAll && activeFolder.owner" class="btn btn-white" pTooltip="{{'No folder selected' | translate}}">
						<span class="text-nowrap"><i class="fa-solid fa-triangle-exclamation text-danger mr-2"></i> {{'Create Note' | translate}}</span>
					</button>
				</p-footer>
			</p-panel>
			<br *ngIf="lineBreak">
			<div class="alert alert-primary alert-dismissible d-flex align-items-center mt-3" role="alert">
				<i class="fa-solid fa-circle-info text-primary mr-2"></i>
				<span class="one-liner">{{'Select folder to create note.' | translate}}</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="lineBreak = true"></button>
			</div>
			<p-panel [toggleable]="true" id="table-ppanel" class="folders-area mt-2">
				<p-header id="folders-area">
					<span><i class="fa-solid fa-folder text-primary mr-2"></i>{{'Folders' | translate}}</span>
				</p-header>
				<div *ngFor="let folder of folders; let i = rowIndex" (click)="changeFolder(folder)">
					<app-folder-item [data]="folder" (onEditName)="openFormEditName($event)" (onDelete)="openDeletionConfirmationDialog($event, 'folder')" (onShare)="openShareDialog($event,'folder')" [isActive]="folder.id===activeFolder.id"></app-folder-item>
				</div>
				<p-footer class="d-flex justify-content-center">
					<button class="btn btn-white"  (click)="openFormAddFolder()">
						<span class="text-nowrap"><i class="fa-solid fa-plus text-primary mr-2"></i> {{'Add Another' | translate}}</span>
					</button>
				</p-footer>
			</p-panel>
		</div>
		<div class="ui-g-9 ui-sm-12 p-sm-0 p-lg-2" id="note-container-wrapper">
			<p-panel [toggleable]="true" id="table-ppanel" [style]="{'height':'100%'}" class="h-100">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Note Content' | translate}}</span>
				</p-header>
				<app-note-content [ngClass]="showDialogAddFolder || showDialogAddNote || showFormEditName || showDeletionConfirmation ? 'note-editor-disabled h-100' : 'h-100'" [data]="activeNote" (onTextChange)="onChangeNoteContent($event)" (onSendUpdateRequest)="submitEdit(true)" style="height: 100% !important"></app-note-content>
			</p-panel>
		</div>
	</div>
</div>
<p-dialog [dismissableMask]="true" [modal]="true" [(visible)]="showDialogAddFolder" (keydown.enter)="addFolder()"  [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Add New Folder' | translate}}</span>
	</p-header>
    <div class="ui-g pb-3">
		<div class="ui-g-12">
			<label>{{'Folder Name' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="newFolderName" placeholder="Note Name" autofocus maxlength="255" [disabled]="isCalling" placeholder="{{'Give it a name' | translate}}">
			</div>
        </div>
		<div *ngIf="errorMessage" class="ui-g-12">
			<div class="alert alert-danger alert-dismissible fade show" role="alert">
				<i class="fa-solid fa-triangle-exclamation mr-2"></i>{{errorMessage}}
				<button type="button" class="close" data-dismiss="alert" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="showDialogAddFolder=false; errorMessage=''" [disabled]="isCalling" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button (click)="addFolder()" [disabled]="!newFolderName || newFolderName.trim() === '' || isCalling" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>
<p-dialog [dismissableMask]="true" [modal]="true" [(visible)]="showDialogAddNote" (keydown.enter)="addNote()" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Add New Note' | translate}}</span>
	</p-header>
    <div class="ui-g pb-3">
		<div *ngIf="errorMessage" class="ui-g-12">
			<div class="alert alert-danger alert-dismissible fade show" role="alert">
				<i class="fa-solid fa-triangle-exclamation mr-2"></i>{{errorMessage}}
				<button type="button" class="close" data-dismiss="alert" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
        <div class="ui-g-12">
			<label>{{'Title' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div>
           		 <input type="text" pInputText [(ngModel)]="newNoteName" placeholder="Note Name" autofocus maxlength="255" [disabled]="isCalling" placeholder="{{'Give it a name' | translate}}">
			</div>
        </div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="showDialogAddNote=false" [disabled]="isCalling" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button (click)="addNote()" [disabled]="!newNoteName || newNoteName.trim() === '' || isCalling" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>
<p-dialog [dismissableMask]="false" [modal]="true" [(visible)]="showFormEditName" (keydown.enter)="submitEdit()" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span *ngIf="editObj.hasOwnProperty('content')"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit Note Title' | translate}}</span>
		<span *ngIf="!editObj.hasOwnProperty('content')"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit Folder Title' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="editObj.name" placeholder="{{'Give it a name' | translate}}" [disabled]="isCalling" maxlength="255">
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="showFormEditName=false" [disabled]="isCalling" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button (click)="submitEdit()" class="btn btn-primary" [disabled]="!editObj.name || editObj.name.trim() === '' || isCalling"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>
<p-dialog header="{{'Share Note' | translate}}" [dismissableMask]="true" showEffect="true" height="800px" width="90px" [modal]="true"
    [(visible)]="showDeletionConfirmation" (keydown.enter)="onDelete()">
    <div class="note-popup-content text-center">
        <div class="ui-g-12">
            <span>{{ (editObj.hasOwnProperty("content") ? 'Do you really want to delete this note' : 'Do you really want to delete this folder') | translate }}</span>
        </div>
        <div class="ui-g-12 notes-action">
            <p-button label="{{'Yes' | translate}}" (onClick)="onDelete()" [disabled]="isCalling"></p-button>
            <p-button label="{{'Cancel' | translate}}" (onClick)="showDeletionConfirmation=false" [disabled]="isCalling"></p-button>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showSharePopup" (onHide)="resetForm()" class="modal_in" id="sendNotebookItemModal" [modal]="true" [style]="{'width':'90%', 'max-width':'600px'}">
	<p-header>
		<span><i class="fa-solid fa-share-nodes mr-2"></i>{{'Send Notebook Item' | translate}}</span>
	</p-header>
	<form [formGroup]="emailForm" (ngSubmit)="submitShare()">
		<div class="ui-g">
			<div class="ui-g-12">
				<label>{{'E-Mail Addresses' | translate}}:</label>
				<p-autoComplete #item formControlName="email" (ngModelChange)="changeEmail()" [(ngModel)]="toEmail" [suggestions]="suggestionsForBaseEmail" (completeMethod)="filterEmailProviderForTo($event)" class="w-100" [minLength]="1" placeholder="{{'Email Addresses' | translate}}" field="key" [multiple]="true"></p-autoComplete>
				<div class="text-danger" style="font-size: 12px">
					<!--<span *ngIf="requireEmail">{{'This field is required.' | translate}} </span>-->
					<span *ngIf="requireEmail || invalidEmail">{{'Enter a valid email' | translate}} </span>
				</div>
			</div>
			<div class="ui-g-12 pb-4">
				<label>{{'Editing' | translate}}</label>
				<p-checkbox [(ngModel)]="allowWrite" binary="true" class="py-2" label="{{'Allowed' | translate}}"></p-checkbox>
			</div>
		</div>
		<hr>
		<p-footer class="d-flex justify-content-between">
			<button (click)="closeDialog()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button (click)="submitShare()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</p-footer>
	</form>
  </p-dialog>