import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ProtestHour, IssueDate } from '../../../model/protest-hour.model';
import { ProtestHourService } from '../../../service/protest-hour.service';
import { PageRequest, Sort } from '../../../../shared/model/response';
import { DatePipe } from '@angular/common';
import { Time } from '../../../model/time.model';
import { EmployeeClocking } from '../../../../employee-clocking/model/employee-clocking.model';
import * as MOMENT from 'moment';
import { Router } from '@angular/router';
import { DocumentData } from '../../../../shared/model/document-data.model';
import { RoleLevel } from '../../../../layout/component/app-menu/role-level';
import { RESPONSE_STATUS } from '../../../../shared/model/constants';
import { DropDownsService } from '../../../../shared/service/drop-downs/drop-downs.service';
import { AuthService } from '../../../../shared/service/auth/auth.service';
import { EmployeeService } from '../../../service/employee.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { Table } from 'primeng/table';
import { SelectOption } from '../../../../shared/model/custom-ui.model';
import { EmployeeClockingServiceV2 } from 'app/employee-clocking/service/v2/employee.clocking.v2.service';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { LazyLoadEvent, ConfirmationService, Message, MessageService } from 'primeng/api';
import { DocumentsService } from '../../../../shared/service/documents-upload/documents.service';
import { FileUtility } from "../../../../shared/utility/file.utility";
import { CompanySettingServiceV2 } from '../../../../company/service/company-setting.v2.service';
import { TranslatePipe } from '@ngx-translate/core';
import {OverlayPanel} from "primeng";
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-protest-hour-list-all',
  templateUrl: './protest-hour-list-all.component.html',
  styleUrls: ['./protest-hour-list-all.component.scss'],
  providers: [
    OperatorService, EmployeeClockingServiceV2
  ]
})
export class ProtestHourListAllComponent implements OnInit {
  private pageRequest = new PageRequest(0, 5);

  loading = true;
  dataSourceProtestedHour: any = [];
  dataSourceProtestedHourPdf: any = [];
  dataSourcePunchHistory: any = [];
  totalRecords = 0;
  sortField: string;
  sortOrder: number;
  size: number;
  page: number;
  first = 0;
  totalRecordProtestdHour = 0;
  protestHourDetail: any;
  displayColumns: any = [];
  workIn: any = [];
  pageSizeProtestedHour = 10;
  totalWorkingTime = '00:00';
  totalOvertime = '00:00';
  showHistoryInfoDialog = false;
  showDialogLunch: Boolean = false;
  punchDate;
  displayColumnsPunchHistory: any[] = [
    { field: 'id', label: 'ID', sortable: false },
    { field: 'firstName', label: 'firstName', sortable: false },
    { field: 'lastName', label: 'lastName', sortable: false },
    { field: 'type', label: 'cols.type', sortable: false },
    { field: 'date', label: 'date', sortable: false },
    { field: 'punchIn', label: 'In', sortable: false },
    { field: 'punchOut', label: 'Out', sortable: false },
    { field: 'total', label: 'total', sortable: false },
    { field: 'overtime', label: 'Overtime', sortable: false }
  ];

  displayColumnsProtestedHour: any[] = [];
  allCompanyList: any = [];
  allAgencyList: any = [];
  allCreatedByList: any = [];
  statusList = [
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' },
    { label: 'All' }
  ];
  isCompanyDisabled: boolean;
  isAgencyDisabled: boolean;
  selectedCompany: any;
  selectedAgency: any;
  selectedStatus: number;
  selectedReason: string;
  rangeDates: any;
  startDate: any;
  endDate: any;
  isPlatformAdmin: Boolean = false;
  isEmployee: Boolean = false;
  selectedProtestHour: any;
  fileUrl: any;
  showViewFileDialog: boolean;
  @ViewChild('dt', { static: true }) table: Table;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  statusSortOptions = [
    { name: 'Approved First', value: 'ASC', img: 'circle-check text-success' },
    { name: 'Rejected First', value: 'DESC', img: 'circle-xmark text-danger' }
  ];
  reasonOptions: SelectOption[] = ['Daily Hours wrong', 'Sick Hours missing', 'OT missing', 'Overpay',
    'Holiday hours Missing', 'Bonus missing', 'Other']
    .map((item: string) => <SelectOption>{ label: item, value: item });
  selectedSortOption = '';
  selectedField = '';
  employeeId: number;
  attachment = 'fa-solid fa-paperclip text-muted';
  attachment1 = 'fa-solid fa-paperclip text-success';
  hasFileOptions = [
    { label: 'View', value: 'View' },
    { label: 'Change', value: 'Change' },
    { label: 'Remove', value: 'Remove' },
    { label: 'History', value: 'History' }
  ];
  historyShow;
  protestHourId;
  noFileOptions = [
    { label: 'Upload', value: 'Upload' }
    //   { label: 'History', value: 'History' }
  ];
  msgs: Message[] = [];
  statusType: any;
  selectedRowData: any;
  showConfirmDialog: boolean;
  isShowButton = true;
  companySetting: any;
  textSearch;
  exportingPDF = false;

  selectedMultiLunchIssueDate = [];
  showDialogMultiLunch = false;
  punchDateMultiLunch;
  combinedTotalStr;
  displayLunchColumns: any[] = [
    { field: 'id', label: '#', sortable: false, width: '10%' },
    { field: 'lunchStart', label: 'Lunch Start', sortable: false, width: '35%' },
    { field: 'lunchEnd', label: 'Lunch End', sortable: false, width: '35%' },
    { field: 'lunchTot', label: 'Lunch Tot', sortable: false, width: '20%' }
  ];

  constructor(
    private protestHourService: ProtestHourService,
    private operatorService: OperatorService,
    private datePipe: DatePipe,
    private router: Router,
    private dropDownsService: DropDownsService,
    private employeeClockingServiceV2: EmployeeClockingServiceV2,
    private browserNotificationService: BrowserNotificationService,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private messageService: MessageService,
    private documentsService: DocumentsService,
    private confirmationService: ConfirmationService,
	  private translatePipe: TranslatePipe,
    private companySettingService: CompanySettingServiceV2,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.protestHourDetail = {};
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.isEmployee = this.authService.isEmployeeRole();
    this.displayColumnsProtestedHour = [
        { field: 'name', label: 'Employee', sort: 'text' },
        { field: 'issueDatesText', label: 'Problem Days' },
        { field: 'reason', label: 'reason', sort: 'text' },
        { field: 'note', label: 'note', id: 'note', sort: 'text'},
        { field: 'date', label: 'date', sort: 'number' },
        { field: 'totalTimeCorrections', label: 'Used', id: 'totalTimeCorrections', sort: 'number'},
        { field: 'document', label: 'Upload' },
        { field: 'status', label: 'cols.status', sort: 'text' },
      ];
    if (!this.isEmployee) {
      this.displayColumnsProtestedHour.push({ field: 'actions', label: '' });
    } else {
      this.displayColumnsProtestedHour.splice(5, 1);
      this.employeeId = this.authService.getCurrentEmployee();
      this.displayColumnsProtestedHour.push({ field: 'actions', label: '' });
    }
    this.companySettingService.getCompanySettingByCompanyId(this.authService.getUserInfo().companyId).subscribe(com => {
      if (com.status === 'SUCCESS') {
        this.companySetting = com.data;
      }
    });

    if (!this.isPlatformAdmin) {
      this.selectedCompany = this.authService.getCurrentCompanyId();
    }
    this.loading = true;
    this.loadAllCompanyList();
    this.employeeService.getCreatedByList({}).subscribe((e: any) => {
      if (e.status === RESPONSE_STATUS.SUCCESS) {
        e.data.forEach((item) => {
          if (item.toString().length > 0) {
            this.allCreatedByList.push({ label: item, value: item });
          }
        });
      }
    });
    this.loadProtestHourList();
  }

  addTimeCorrection() {
    localStorage.removeItem('_protestHourInfo');
    this.router.navigate(['app/employee', 'time-correction', this.employeeId]);
  }

  loadProtestHourList(event?: any) {
    this.dataSourceProtestedHourPdf = [];
    if (this.table)
      this.table.first = 0;
    const options = <any>{
      sort: 'id',
      order: 'DESC'
    };

    options.size = 9999;
    options.page = 0;
    if (event && event.sortField) {
      const sortOrder = <number>event['sortOrder'];
      options.sort = new Sort();
      options.sort.orders = [{ property: event.sortField, direction: sortOrder ? 'ASC' : 'DESC' }];
    }
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (this.employeeId) {
      options.employeeId = this.employeeId;
    }

    if (this.startDate && this.endDate) {
      options.fromDate = moment(this.startDate).startOf('day').toDate().getTime();
      options.toDate = moment(this.endDate).endOf('day').toDate().getTime();
    }
    if (this.selectedStatus) {
      options.status = this.selectedStatus;
    }
    if (this.authService.isEmployeeRole()) {
      options.username = this.authService.getCurrentUsername();
    }

    options.reason = this.selectedReason ? this.selectedReason : '';
    this.dataSourceProtestedHour = [];
    this.loading = true;


    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    this.operatorService.searchProtectHour(options).subscribe(res => {
      let date;
      let firstDay;
      let lastDay;
      let total;
      this.loading = false;
      const resObject: any = res;
      if (resObject.data == null || resObject.data.content == null) {
        return;
      }
      const listData: ProtestHour[] = resObject.data.content;
      this.totalRecordProtestdHour = resObject.data.totalElements;
      this.dataSourceProtestedHour = [];
      for (const data of listData) {
        const protestHour: any = data;
        protestHour['name'] = data.name;
        const issueDates = data.issueDates.filter(item => {
          let hasIssue = (item.date != null) && (item.checkIn || item.checkOut || item.lunchIn || item.lunchOut);
          if (!hasIssue && item.multiLunchIssueDate && item.multiLunchIssueDate.length > 0) {
            return item.multiLunchIssueDate.find(l => l.lunchIn || l.lunchOut)
          }
          return hasIssue;
        });
        const issueDateString = issueDates.map(issueDate => {
          return this.datePipe.transform(issueDate.date, 'M/d/yyyy EEE');
        });
        protestHour['issueDatesText'] = issueDateString;
        // Calcualte protested Hours
        let totalTime: Time = new Time(null, 0, 0);
        let startTimeClock: Time = null;
        let endTimeClock: Time = null;
        for (const issueDate of issueDates) {
          const listClocking = issueDate.listClocking;
          if (listClocking != null && listClocking.length > 0) {
            const startTime = this.datePipe.transform(listClocking[0].checkInTime, 'HH:mm');
            const endTime = this.datePipe.transform(listClocking[0].checkOutTime, 'HH:mm');
            startTimeClock = new Time(startTime);
            endTimeClock = new Time(endTime);
          }
          if (startTimeClock == null && endTimeClock == null && issueDate.checkIn && issueDate.checkOut) {
            const checkInTimeProtest = new Time(issueDate.checkInTime);
            const checkOutTimeProtest = new Time(issueDate.checkOutTime);
            totalTime = this.addTime(totalTime, this.diffTime(checkOutTimeProtest, checkInTimeProtest));
          }
          if (issueDate.checkIn) {
            const checkInTimeProtest = new Time(issueDate.checkInTime);
            if (startTimeClock != null) {
              totalTime = this.addTime(totalTime, this.diffTime(checkInTimeProtest, startTimeClock));
            }
          }

          if (issueDate.checkOut) {
            const checkOutTimeProtest = new Time(issueDate.checkOutTime);
            if (endTimeClock != null) {
              totalTime = this.addTime(totalTime, this.diffTime(checkOutTimeProtest, endTimeClock));
            }
          }
        }
        protestHour['protestHours'] = totalTime.toString();
        if (!this.isEmployee) {
          if (this.companySetting.timeCorrectionPer === 'Weekly') {
            const dt = new Date(protestHour['date']); // current date of week
            const currentWeekDay = dt.getDay();
            const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
            firstDay = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
            lastDay = new Date(new Date(firstDay).setDate(firstDay.getDate() + 6));
            total = listData.filter(item => item.employeeId === protestHour.employeeId && this.compareDate(new Date(item.date), firstDay) && this.compareDate(lastDay, new Date(item.date))).length;
          } else if (this.companySetting.timeCorrectionPer === 'Monthly') {
            date = new Date(protestHour['date']);
            firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            console.log('firstDay', firstDay, lastDay);
            total = listData.filter(item => item.employeeId === protestHour.employeeId && this.compareDate(new Date(item.date), firstDay) && this.compareDate(lastDay, new Date(item.date))).length;
          } else {
            date = new Date();
            firstDay = new Date(date.getFullYear(), 1, 1);
            lastDay = new Date(date.getFullYear(), 12, 31);
            total = listData.filter(item => item.employeeId === protestHour.employeeId && this.compareDate(new Date(item.date), firstDay) && this.compareDate(lastDay, new Date(item.date))).length;
          }
          protestHour['totalTimeCorrections'] = total;
        }
        this.dataSourceProtestedHour.push(protestHour);
      }
      this.dataSourceProtestedHourPdf.push(...this.dataSourceProtestedHour);
    });
  }

  addTime(time1: Time, time2: Time) {
    if (!time1.isValid) {
      return new Time(null);
    }
    if (!time2.isValid) {
      return new Time(null);
    }
    let minutes = time1.minutes + time2.minutes;
    let hours = time1.hour + time2.hour;
    if (minutes >= 60) {
      minutes = minutes - 60;
      hours = hours + 1;
    }
    return new Time(null, hours, minutes);
  }

  compareTime(time1: Time, time2: Time) {
    if (time1.hour > time2.hour) {
      return true;
    }
    if ((time1.hour === time2.hour) && (time1.minutes > time2.minutes)) {
      return true;
    }
    return false;
  }

  compareDate(date1: Date, date2: Date) {
    if (date1.getTime() >= date2.getTime()) {
      return true;
    }
    return false;
  }

  diffTime(time1: Time, time2: Time) {
    if (time1 == null && time2 == null) {
      return new Time(null);
    }
    if (time1 == null) {
      return time2;
    }
    if (time2 == null) {
      return time1;
    }
    let hours = time2.hour - time1.hour;
    let minutes = time2.minutes - time1.minutes;
    if (this.compareTime(time1, time2)) {
      hours = time1.hour - time2.hour;
      minutes = time1.minutes - time2.minutes;

    }
    if (minutes < 0) {
      minutes = minutes + 60;
      hours = hours - 1;
    }
    return new Time(null, hours, minutes);
  }

  onLazyLoadList(event: any) {
    this.pageRequest.size = event && event.rows ? event.rows : 5;
    this.pageRequest.page = event && event.first && event.rows ? (event.first / event.rows) : 0;
    if (event.sortField) {
      const sortOrder = <number>event['sortOrder'];
      this.pageRequest.sort = new Sort();
      this.pageRequest.sort.orders = [{ property: event.sortField, direction: sortOrder ? 'ASC' : 'DESC' }];
    }
    this.protestHourService.refreshListSubject.next();
  }

  formatIssueDates(issueDates: IssueDate[]): String {
    let result = '';
    if (issueDates) {
      result = issueDates
        .filter(item => {
          const hasIssue = (item.date != null) && (item.checkIn || item.checkOut);
          return hasIssue;
        })
        .map(item => {
          return this.datePipe.transform(item.date, 'M/d/y');
        })
        .join(', ');
    }
    return result;
  }

  openConfirmModal(rowData, status) {
    this.statusType = status
    this.selectedRowData = rowData;
    this.showConfirmDialog = true;
  }

  reject() {
    this.showConfirmDialog = false;
  }

  rejectReason;

  setStatus() {
    if (this.statusType !== 'Approved' && !this.rejectReason) {
        this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'Please fill the reject reason.' });
        return;
    }
    this.loading = true;
    this.selectedRowData.status = this.statusType;
    if (this.rejectReason) {
        this.selectedRowData.rejectReason = this.rejectReason;
    }
    this.selectedRowData.lastModifiedBy = this.authService.getUserInfo().username;
    // update edit value to employee clocking
    this.operatorService.setStatusProtestHour(this.selectedRowData).subscribe(async res => {
      this.selectedRowData.status = this.statusType;
      if (this.currentData) this.currentData = this.statusType;
      if (this.statusType === 'Approved') {
        const options = {
          'protestHourId': this.selectedRowData.id,
          'employeId': this.selectedRowData.employeeId,
          'approvedBy': this.authService.getUserInfo().username
        };
        await this.employeeClockingServiceV2.updateTimeCorrection(options).toPromise();

      }
      this.isShowButton = false;
      this.rejectReason = null;
      this.messageService.add({ severity: 'info', summary: this.selectedRowData.status, detail: 'Your Time Correction has been ' + this.selectedRowData.status });
      this.showConfirmDialog = false;
      await this.createNotification(this.selectedRowData);

      this.employeeService.searchEmployee({
        companyId: this.authService.getUserInfo().companyId,
        departmentNameSearch: ['Accountant', 'HR'],
        status: 1
      }).subscribe((r: any) => {
        this.loading = false;
        if (r.status === 'SUCCESS') {
          const emails = [];
          emails.push(this.authService.getUserInfo().email);
          r.data.content.forEach(e => {
            if (!emails.includes(e.email)) {
              emails.push(e.email);
            }
          });
          if (emails.length > 0) {
            const payload = {
              toEmails: emails,
              emailCompanyId: this.authService.getUserInfo().companyId,
              employeeName: this.selectedRowData.employee.fullName,
              employeeId: this.selectedRowData.employee.employeeId,
              date: this.datePipe.transform(this.selectedRowData.date, 'MM/dd/yyyy'),
              status: this.selectedRowData.status,
              subject: "Time Update Requested"
            }
            this.operatorService.sendEmailNotification(payload).subscribe();
          }
        }
      }, () => {
        this.loading = false;
      });
    }, error => {
      this.loading = false;
    });
  }

  async createNotification(element: any) {
    const notification: BrowserNotification = new BrowserNotification();
    notification.entity = 'employee';
    notification.entityId = element.employeeId;
    notification.content = 'Your Time Correction has been ' + element.status;
    notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
    notification.url = document.location.origin + `/#/app/employee/protest-hour`;
    notification.status = 'New';
    await this.browserNotificationService.save(notification).toPromise();
  }

  async loadPunchHistoryLazy(event?: LazyLoadEvent) {
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'createdAt';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;
    const order = this.sortOrder === 1 ? 'ASC' : this.sortOrder;
    const options: any = {
      page: this.page,
      size: this.size,
      sortField: this.sortField,
      sortOrder: order
    };
    options.companyId = this.authService.getUserInfo().companyId;
    if (this.isEmployee) {
      options.employeeIds = [];
      options.employeeIds.push(this.authService.getCurrentEmployee());
      options.companyId = this.authService.getUserInfo().companyId;
    }
    if (this.startDate) {
      options.fromDate = moment(this.startDate);
    } else {
      options.fromDate = moment().subtract(1, 'month');
    }

    if (this.endDate) {
      options.toDate = moment(this.endDate);
    } else {
      options.toDate = moment();
    }
    const resObj: any = await this.employeeClockingServiceV2.search2(options).toPromise();
    if (resObj.status === 'SUCCESS') {
      resObj.data.content = _.compact(resObj.data.content);
      resObj.data.content.forEach(obj => {
        if (obj && obj.clockInTime !== null && obj.clockOutTime !== null) {
          if (obj.modifiedByUserName === 'Holiday') {
            obj.disableEditAllowed = true;
            obj.location = 'Holiday';
          }

          if (obj.modifiedByUserName === 'OFF') {
            obj.location = 'OFF';
          }
        }
      });
      let totalWorkingTime = new Time(null, 0, 0);
      let totalOvertime = new Time(null, 0, 0);
      const rows = [];
      for (const clocking of resObj.data.content) {
        const punchHistory = {};
        punchHistory['clockingId'] = clocking.id;
        punchHistory['id'] = clocking.employee.employeeId;
        const fullName: string = clocking.employee.fullName;
        const splitName = fullName.split(/\s+/);
        punchHistory['firstName'] = splitName[0];
        if (splitName.length > 1) {
          punchHistory['lastName'] = splitName[1];
        }
        punchHistory['type'] = clocking.locationName;
        const clockInTime = moment(clocking.clockInTime);
        punchHistory['date'] = clockInTime.toDate();
        punchHistory['punchIn'] = clockInTime.format('hh:mm A');
        punchHistory['punchOut'] = clocking.clockOutTime ? moment(clocking.clockOutTime).format('hh:mm A') : null;
        punchHistory['total'] = clocking.clockOutTime ? this.diffTime(new Time(moment(clocking.clockOutTime).format('HH:mm')), new Time(clockInTime.format('HH:mm'))).toString() : '00:00';
        punchHistory['overtime'] = this.getOverrtime(clocking);
        rows.push(punchHistory);
        totalWorkingTime =  this.addTime(totalWorkingTime, new Time(punchHistory['total']));
        totalOvertime = this.addTime(totalOvertime, new Time(punchHistory['overtime']));
      }
      this.dataSourcePunchHistory = rows;
      this.totalOvertime = totalOvertime.toString();
      this.totalWorkingTime = totalWorkingTime.toString();
      this.totalRecords = resObj.data.totalElements;
    }
  }

  getOverrtime(rowData: EmployeeClocking) {
    if (rowData.checkInTime === null || rowData.checkOutTime) {
      return '00:00';
    }
    let total = 0;
    if (rowData.list != null && rowData.list.length > 0) {
      for (let i = 0; i < rowData.list.length; i++) {
        const row = rowData.list[i];

        if (row.id === rowData.id) {
          break;
        } else {
          const duration = row.checkOutTime - row.checkInTime;
          const second1 = duration / 1000;
          total = total + second1;
        }
      }

      const duration1 = rowData.checkOutTime - rowData.checkInTime;
      const second = total + duration1 / 1000;
      let overrtimeAfter = 0;
      if (rowData.payrollSetting) {
        overrtimeAfter = moment.duration(rowData.payrollSetting.overtimeAfter).asHours();
      }
      const overtime = overrtimeAfter * 60 * 60;
      if (second > overtime) {
        const time = second - overtime;
        console.log(time < duration1 / 1000);
        if (time < duration1 / 1000) {
          rowData.overTime = time;
          return MOMENT.utc(time * 1000).format('HH:mm');

        } else {
          rowData.overTime = duration1 / 1000;
          return MOMENT.utc(duration1).format('HH:mm');
        }

      } else {
        rowData.overTime = 0;
      }
    }

    return '00:00';
  }

  editPunchHistory(data: any) {
    this.router.navigate(['app/employee/clocking/edit/' + data.clockingId]);
  }

  openDocumentURL(document: DocumentData) {
    window.open(document.fileUrl, '_blank');
  }

  loadAllCompanyList() {
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = resObj.data;
        this.loadCompanyBasedOnAdminRole();
      }
    });
  }

  loadCompanyBasedOnAdminRole() {
    const loggedIn = this.authService.getUserInfo();
    if (loggedIn.role.level === RoleLevel.ROLE_COMPANY_ADMIN || loggedIn.role.level === RoleLevel.ROLE_AGENCY_ADMIN) {
      this.isCompanyDisabled = true;
      this.allCompanyList.map(company => {
        if (company.id === loggedIn.company.id) {
          this.selectedCompany = company;
          this.loadBasedOnCompanyId();
        }
      });
    }
  }

  loadBasedOnCompanyId() {
    this.selectedAgency = null;
    const pafc = this;

    this.dropDownsService.getAllAgencyByCompanyId(this.selectedCompany.key).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data.length > 0) {
          this.selectedAgency = resObj.data[0];
        }
        // this.allAgencyList = resObj.data;
        // this.loadAgencyBasedOnAdminRole();
      }
    });
  }

  loadAgencyBasedOnAdminRole() {
    const loggedIn = this.authService.getUserInfo();
    if (loggedIn.role.level === RoleLevel.ROLE_AGENCY_ADMIN) {
      this.isAgencyDisabled = true;
      this.allAgencyList.map(agency => {
        if (agency.id === loggedIn.agency.id) {
          this.selectedAgency = agency;
        }
      });
    }
  }

  resetSearchForm() {
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
      this.selectedAgency = null;
    }
    this.selectedReason = null;
    this.selectedStatus = null;
    this.rangeDates = null;
    this.startDate = null;
    this.endDate = null;
    this.textSearch = null;
    this.table.reset();
    this.loadProtestHourList();
  }

  sortDataSelected(selectedSortOption) {
    if (this.selectedField === 'name') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour,
        (x) => x[this.selectedField] ? x[this.selectedField].toLowerCase() : x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'date') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour, (x) => x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'reason') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour,
        (x) => x[this.selectedField] ? x[this.selectedField].toLowerCase() : x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'issueDatesText') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour, (x) => x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'note') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour,
        (x) => x[this.selectedField] ? x[this.selectedField].toLowerCase() : x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'protestHours') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour, (x) => x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'status') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour, (x) => x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    if (this.selectedField === 'totalTimeCorrections') {
      this.dataSourceProtestedHour = _.sortBy(this.dataSourceProtestedHour, (x) => x[this.selectedField]);
      if (this.selectedSortOption === 'DESC') {
        this.dataSourceProtestedHour = this.dataSourceProtestedHour.reverse();
      }
    }
    this.setSortOption(this.selectedField, this.selectedSortOption);
  }

  setSortOption(field, selectedSortOption?) {
    console.log(this.selectedField);
    console.log(this.selectedSortOption);
    this.displayColumnsProtestedHour.forEach(item => {
      if (this.selectedField === item.field) {
        item.sortOptions = selectedSortOption;
      }
      else {
        item.sortOptions = null;
      }
    });
  }

  currentData;
  openDetailDialog(data: any) {
    this.currentData = data;
    this.showHistoryInfoDialog = true;
    this.isShowButton = true;
    this.builProtestHourDetail(data);
  }

  builProtestHourDetail(data: any) {
    this.protestHourDetail = data;
    console.log("Data")
    console.log(data)
    this.displayColumns = [];
    this.workIn = [];
    this.displayColumns.push({ dateStr: 'Punch', weekDay: '' });
    this.workIn.push({ checkInTime: 'Start Work', checkIn: false });
    const lunchOut = [];
    lunchOut.push({ lunchOutTime: 'Start Break', lunchOut: false });
    const lunchIn = [];
    lunchIn.push({ lunchInTime: 'End Break', lunchIn: false });
    const workEnd = [];
    workEnd.push({ checkOutTime: 'End Work', checkOut: false });
    this.protestHourDetail.issueDates.forEach(e => {
      let hasIssue = (e.date != null) && (e.checkIn || e.checkOut || e.lunchIn || e.lunchOut);
      if (!hasIssue && e.multiLunchIssueDate && e.multiLunchIssueDate.length > 0) {
        hasIssue = e.multiLunchIssueDate.find(l => l.lunchIn || l.lunchOut)
      }
      if (!hasIssue) {
        return;
      }
      const weekDay = moment(e.date).format('ddd');
      const dateStr = moment(e.date).format('MM/DD');
      const object: any = {};
      object.dateStr = dateStr;
      object.weekDay = weekDay.toUpperCase();
      const checkIn: any = {};
      checkIn.checkInTime = e.checkInTime ? e.checkInTime : 'Missing';
      checkIn.checkInTime = this.convertTo12Format(checkIn.checkInTime);
      checkIn.checkIn = e.checkIn;
      checkIn.prevCheckInTime = e.prevCheckInTime;
      checkIn.prevCheckInTime = this.convertTo12Format(checkIn.prevCheckInTime);

      const lunchOutObj: any = {};
      lunchOutObj.lunchOutTime = e.lunchOutTime ? e.lunchOutTime : 'Missing';
      lunchOutObj.lunchOutTime = this.convertTo12Format(lunchOutObj.lunchOutTime);
      lunchOutObj.lunchOut = e.lunchOut;
      lunchOutObj.prevLunchOutTime = e.prevLunchOutTime;
      lunchOutObj.prevLunchOutTime = this.convertTo12Format(lunchOutObj.prevLunchOutTime);
      lunchOutObj.isMultiLunch = e.multiLunchIssueDate && e.multiLunchIssueDate.length > 1;
      lunchOutObj.multiLunchIssueDate = e.multiLunchIssueDate;

      const lunchInObj: any = {};
      lunchInObj.lunchInTime = e.lunchInTime ? e.lunchInTime : 'Missing';
      lunchInObj.lunchInTime = this.convertTo12Format(lunchInObj.lunchInTime);
      lunchInObj.lunchIn = e.lunchIn;
      lunchInObj.prevLunchInTime = e.prevLunchInTime;
      lunchInObj.prevLunchInTime = this.convertTo12Format(lunchInObj.prevLunchInTime);
      lunchInObj.isMultiLunch = e.multiLunchIssueDate && e.multiLunchIssueDate.length > 1;
      lunchInObj.multiLunchIssueDate = e.multiLunchIssueDate;

      const checkOut: any = {};
      checkOut.checkOutTime = e.checkOutTime ? e.checkOutTime : 'Missing';
      checkOut.checkOutTime = this.convertTo12Format(checkOut.checkOutTime);
      checkOut.checkOut = e.checkOut;
      checkOut.prevCheckOutTime = e.prevCheckOutTime;
      checkOut.prevCheckOutTime = this.convertTo12Format(checkOut.prevCheckOutTime);

      this.displayColumns.push(object);
      this.workIn.push(checkIn);
      lunchOut.push(lunchOutObj);
      lunchIn.push(lunchInObj);
      workEnd.push(checkOut);
    });
    this.protestHourDetail.lunchOut = lunchOut;
    this.protestHourDetail.lunchIn = lunchIn;
    this.protestHourDetail.workEnd = workEnd;
  }

  convertTo12Format(hourStr: string) {
    const hour = moment(hourStr, 'HH:mm');
    if (hour.isValid()) {
      return hour.format('hh:mm A');
    }
    return hourStr;
  }

  closeDetailDialog() {
    this.showHistoryInfoDialog = false;
  }



  onChangeUploadOption(panel: OverlayPanel, type) {
    this.selectedProtestHour = this.selectedRecord;
    if (type === 'View') {
      this.onViewDocument();
    }
    if (type === 'Upload' || type === 'Change') {
      this.selectFile();
    }
    if (type === 'Remove') {
      this.onRemoveDocument();
    }
    this.selectedProtestHour.uploadOption = {};
    panel.hide();
  }
  fieldsName;
  viewHistoryUpload(panel: OverlayPanel, data) {
      panel.hide();
      this.fieldsName = [
          { value: 'documentId', label: 'Document' }
      ];
      this.protestHourId = data.id;
      this.historyShow = true;
  }
  hideHistory() {
      this.historyShow = false;
  }

  selectFile() {
    document.getElementById('documentId').click();
  }

  onRemoveDocument() {
    if (this.selectedProtestHour.documentId) {
      this.selectedProtestHour.documentId = null;
      this.operatorService.updateProtectHour(this.selectedProtestHour, this.selectedProtestHour.id).subscribe((res: any) => {
        const resObj: any = res;
        if (res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Document Uploaded Successfully!') });
        }
      });
    }
  }

  onViewDocument() {
    this.documentsService.getUploadedFile(this.selectedProtestHour.documentId).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data.fileType === 'pdf') {
          window.open(resObj.data.fileUrl);
        } else {
          this.fileUrl = resObj.data.fileUrl;
          this.showViewFileDialog = true;
        }
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Can\'t open attached file, try again later. If this error persists, contact support.') });
    });
  }

  onSelectDocument(event) {
    if (event.target.files.length === 0) {
      return;
    }
    if (event.target.files[0].size > 1048576) {
      this.messageService.add({
        severity: 'error', summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('File size is too big. Note: File must be 1000 KB in size or smaller.')
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.documentsService.uploadFile(event.target.files[0], 'protest_file', this.authService.getCurrentLoggedInId(), 'Correction')
      .subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.selectedProtestHour.documentId = resObj.data.id;
          this.operatorService.updateProtectHour(this.selectedProtestHour, this.selectedProtestHour.id).subscribe((resChild: any) => {
            const resObjChild: any = resChild;
            if (resObjChild.status === 'SUCCESS') {
              this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Document Uploaded Successfully!') });
            }
          });
        }
      });
  }
  closeViewFileDialog() {
    this.showViewFileDialog = false;
  }

  reportPrint(rowData) {
    this.builProtestHourDetail(rowData);
    this.cdr.detectChanges(); 
    setTimeout(() => {
      const element = document.getElementById('issueDaysContent');
      htmlToImage.toPng(element!, {skipFonts: true})
      .then((dataUrl) => {
        const base64String = dataUrl.replace(/^data:image\/png;base64,/, '');
        console.log(base64String);
        const reportDate = moment(new Date()).format( 'MM/DD/YYYY EEE hh:mm A');
        this.operatorService.generateReport(rowData.id, reportDate, base64String).subscribe(res => {
          if (res.data) {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Export Error'), detail: this.translatePipe.transform('Error encountered while exporting PDF file, try again later. If this error persists, contact support.') });
          }
        });
      })
      .catch((error) => {
        console.error('Oops, something went wrong!', error);
        const reportDate = moment(new Date()).format( 'MM/DD/YYYY EEE hh:mm A');
        this.operatorService.generateReport(rowData.id, reportDate).subscribe(res => {
          if (res.data) {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else {
            this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Export Error'), detail: this.translatePipe.transform('Error encountered while exporting PDF file, try again later. If this error persists, contact support.') });
          }
    });
      });
    }, 500);

  }

  isDisabledActions(rowData) {
    return rowData.status.toLocaleString() === 'Approved' || rowData.status.toLocaleString() === 'Rejected';
  }

  isApproved(rowData) {
    return rowData.status.toLocaleString() === 'Approved';
  }

  onEdit(rowData) {
    //this.router.navigate(['app/employee/time-correction/' + this.employeeId, {correctionId: rowData.id}]);
    this.router.navigate(['app/employee/time-correction/', this.employeeId], { queryParams: { correctionId: rowData.id } });
  }

  onDelete(rowData) {
    this.selectedRowData = rowData;
    this.showDeleteConfirmDialog = true;
  }
  showDeleteConfirmDialog = false;
  deleteCorrectionRecord() {
    this.showDeleteConfirmDialog = false;
    this.operatorService.deleteProtestHour(this.selectedRowData.id).subscribe(res => {
      this.loadProtestHourList()
    });
  }

  closeConfirmDeleteDialog() {
    this.showDeleteConfirmDialog = false;
  }

  calCulateTotalhhmm() {
    if (!(this.selectedRowData && this.selectedRowData.value)) {
      return '';
    }
    let totalMinutes = 0;
    const clockings = this.selectedRowData.value;
    for (const clocking of clockings) {
      if (clocking.lunchTime) { totalMinutes += clocking.lunchTime; }
    }
    const hours = Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;
    return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
  }


  escapeHtml(unsafe) {
    unsafe = this.removeTags(unsafe);
    return unsafe
      //.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    //.replace(/"/g, "&quot;")
    //.replace(/'/g, "&#039;");

  }
  removeTags(str) {
    if ((str === null) || (str === ''))
      return '';
    else
      str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }
  exportPdf() {
    this.exportingPDF = true;
    if (this.dataSourceProtestedHourPdf && this.dataSourceProtestedHourPdf.length) {
      const data: any = {
        contentList: this.dataSourceProtestedHourPdf.map(value => {
          return {
            employee: value.name,
            date: this.datePipe.transform(value.date, 'M/d/yyyy'),
            reason: this.escapeHtml(value.reason),
            problemDays: value.issueDatesText ? value.issueDatesText.toString() : '',
            notes: this.escapeHtml(value.note),
            usage: value.totalTimeCorrections,
            status: value.status
          };
        })
      };
      data.companyId = this.authService.getCurrentCompany();
      data.username = this.authService.getCurrentUsername();
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      this.operatorService.exportProtestPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          this.exportingPDF = false;
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Export Error'), detail: this.translatePipe.transform('Error encountered while exporting PDF file, try again later. If this error persists, contact support.') });
          this.exportingPDF = false;
        }
      });
    }
  }

  expandMultiLunch(multiLunchIssueDate, date) {
    this.punchDateMultiLunch = date[0].split(' ')[0];
    this.selectedMultiLunchIssueDate = multiLunchIssueDate;
    let combinedTotal = 0;
    this.selectedMultiLunchIssueDate.forEach(s => {
      let totalMinutes;
      if (!s.lunchOutTimeDate || !s.lunchInTimeDate) {
        totalMinutes = 0;
      } else {
        totalMinutes = moment(s.lunchInTimeDate).diff(moment(s.lunchOutTimeDate), 'minutes');
      }
      combinedTotal += totalMinutes;
      s.lunchTimeStr = this.calculateLunchTotal(totalMinutes);
    });
    this.combinedTotalStr = this.calculateLunchTotal(combinedTotal);
    this.showDialogMultiLunch = true;
  }

  calculateLunchTotal(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;
    return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
  }
  selectedRecord;
  onFilter(event, dt) {
    this.dataSourceProtestedHourPdf = event.filteredValue;
    this.totalRecordProtestdHour = this.dataSourceProtestedHourPdf.length;
  }
}
