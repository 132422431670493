export class PayrollModelUtility {
    public static getConditionalData(): NGDropdownModel[] {
        return [{
            value: 'yes',
            label: 'yes'
        }, {
            value: 'no',
            label: 'no'
        }];
    }
}


export interface NGDropdownModel {
    value: any;
    label: string;
}
