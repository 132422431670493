import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { AgencyHoliday } from '../model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from "../../shared/model/page.model";
import { Stock } from "../../stock/model/stock.model";
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AgencyHolidayService extends AbstractService<AgencyHoliday> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.AGENCY_HOLIDAY, '');
    }
    readAllForDropDownList(params: any): Observable<AgencyHoliday[]> {
        return this._http.get<any>(`${this.baseUrlV2}drop_down`, { params: params }).pipe(map(resp => resp));
    }

    filter(options: any):  Observable<any> {
        const companyId = options.companyId;
        const params: any = { page: options.page, size: options.size };
        options.status = 'ACTIVE';
        if (options.sort) {
            params.sort = options.sort;
        }
        return this._http.post<any>(`${this.baseUrlV2}`+  `/holiday/search`, options).pipe(map(resp => resp));
    }

    getHoliday(options: any):  Observable<any> {
        const companyId = options.companyId;
        const params = this.createParams(options);
        return this._http.get<any>(`${this.baseUrlV2}`+  `${companyId}/holiday`, { params: params }).pipe(map(resp => resp));
    }


}
