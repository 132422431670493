<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 *ngIf="mode === 'create'" class="m-0 ml-2 my-4">{{'Add Type' | translate}}</h1>
<h1 *ngIf="mode === 'edit'" class="m-0 ml-2 my-4">{{'Edit Type' | translate}}</h1>
<form #form="ngForm">
	<p-panel id="transactionTypePanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span *ngIf="mode === 'edit'"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{'EditTransactionType' | translate}}</span>
			<span *ngIf="mode === 'create'"><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'AddTransactionType' | translate}}</span>
		</p-header>
		<div class="ui-g" style="margin: auto;">
			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{'companyLabel' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" [options]="allCompanyList" optionLabel="value" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="search()" [ngModelOptions]="{standalone: true}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'TransactionType' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-quote-left"></i>
						</button>
					</div>
					<input pInputText [(ngModel)]="transactionType.transactionType" type="text" [ngModelOptions]="{standalone: true}" 
						placeholder="{{'Give it a name' | translate}}" maxlength="255">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'status' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown [options]="statusTypes" placeholder="{{'placeholder.selectaStatus' | translate}}" [(ngModel)]="transactionType.status" [ngModelOptions]="{standalone: true}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-12 text-center py-4">
				<button type="button" *ngIf="mode === 'create'" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
				<button type="button" *ngIf="mode === 'edit'" class="btn btn-danger mx-1" (click)="goBack()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" [disabled]="isSubmitDisabled() || submitClicked" *ngIf="mode == 'create'" (click)="createTransactionType()"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" [disabled]="isSubmitDisabled() || submitClicked" *ngIf="mode == 'edit'" (click)="updateTransactionType()"><i class="fa-solid fa-check mr-2"></i>{{'Save' | translate}}</button>
			</div>
		</div>
	</p-panel>
</form>
<app-transaction-type-list *ngIf="mode === 'create' || mode !== 'edit'" #ttList></app-transaction-type-list>