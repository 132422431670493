<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Time Confirmations' | translate}}</h1>
    <p-panel [toggleable]="true">
        <p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
            <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Time Confirmations' | translate}}</span>
        </p-header>
        <div class="ui-g">
            <div *ngIf="isPlatformAdmin" class="ui-g-6 ui-sm-12">
                <label>{{'company.name' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-building"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="companies" [(ngModel)]="selectedCompany" (ngModelChange)="getAllEmployeesByCompany()" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12" *ngIf="!isEmployee">
                <label>{{'employee.name' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-user"></i>
                        </button>
                    </div>
<!--                    <p-multiSelect defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" [(ngModel)]="employeeIds" >-->
<!--                        <ng-template pTemplate="item" let-item>-->
<!--                            <span class="one-liner" style="width: 90% !important">{{item.label | translate}}</span>-->
<!--                        </ng-template>-->
<!--                    </p-multiSelect>-->
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" [(ngModel)]="selectEmployee" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'form.dateRange' | translate}}</label>
                <div class="input-group">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-calendar-week"></i>
                        </button>
                    </div>
                    <mat-form-field >
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate matInput  [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
                            <input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" >
                        </mat-date-range-input>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'Application' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-desktop"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="optionsApplication" [(ngModel)]="selectApplication" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-6 ui-sm-12">
                <label>{{'Week/Period' | translate}}:</label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-calendar-week"></i>
                        </button>
                    </div>
                    <p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="periodList" [(ngModel)]="selectPeriod" [showClear]="true" optionLabel="label" placeholder="{{'Select One' | translate}}" >
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-12 pb-4 pt-3 text-center">
                <button type="button" class="btn btn-danger mx-1" (click)="onReset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
                <button type="button" class="btn btn-primary mx-1" (click)="onSearch()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
            </div>
        </div>
    </p-panel>
	<h1 class="m-0 ml-2 my-4">{{'Browse Confirmations' | translate}}</h1>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Time Confirmations' | translate}} ({{dt?._totalRecords || 0 | number}})</span>
        </p-header>
        <p-table #dt [value]="employeeConfirmation" id="main-table" dataKey="id" [customSort]="true" [lazy]="false" (onFilter)="onFilter($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [responsive]="true" [globalFilterFields]="['fullName', 'statusStr', 'period', 'createdAt', 'application', 'price', 'totalTicket', 'totalAmount', 'createdByUsr']">
            <ng-template pTemplate="caption">
                <div class="d-flex align-items-center justify-content-between">
                <span class="d-flex">
                        <button type="button" class="btn btn-primary mr-2" (click)="exportPdf()">
                            <span class="text-nowrap">
                                <i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i>
                                <i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>
                                {{'Export PDF' | translate}}
                            </span>
                        </button>
                    </span>
                    <div class="input-group" style="max-width: 450px">
                        <div class="input-group-prepend p-0">
                            <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="search()">
                                <i class="fa-solid fa-filter"></i>
                            </button>
                        </div>
                        <input (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" pInputText type="text">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns let-i="index">
                <tr id="header-row">
                    <th>{{'#' | translate}}</th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Employee' | translate}}">{{'Employee' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'fullName';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Period' | translate}}">{{'Period' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'period';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Views' | translate}}">{{'Views' | translate}}</span>
                            <span appNoDblClick (click)="op2.toggle($event); selectedField = 'viewConfirm';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Confirmed' | translate}}">{{'Confirmed' | translate}}</span>
                            <span (click)="op4.toggle($event); selectedField = 'createdAt';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-center w-100">
							<i class="fa-solid fa-signature text-muted m-auto" pTooltip="{{'Signature' | translate}}" tooltipPosition="left"></i>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'Application' | translate}}">{{'Application' | translate}}</span>
                            <span appNoDblClick (click)="op1.toggle($event); selectedField = 'application';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
					<th>
                        <div class="d-flex align-items-center justify-content-between w-100">
							<i class="fa-solid fa-info text-muted" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
                            <span appNoDblClick (click)="op3.toggle($event); selectedField = 'statusStr';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-between w-100">
                           <i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
                    <td>{{i+1}}</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'FullName' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.fullName" pTooltip="{{rowData.fullName}}">{{rowData.fullName}}</span>
							<span *ngIf="!rowData.fullName" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Period' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.period" pTooltip="{{rowData.period}}">{{rowData.period}}</span>
							<span *ngIf="!rowData.period" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Views' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.viewConfirm" pTooltip="{{rowData.viewConfirm}}">{{rowData.viewConfirm}}</span>
							<span *ngIf="!rowData.period" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Confirmed' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td class="p-0 px-2">
						<span class="text-truncate">
							<span class="mobile-table-label">{{'Signature' | translate}}: </span>
							<img [src]="rowData?.signatureUrl" *ngIf="rowData.signatureUrl" class="ml-2 align-self-center profile-picture m-auto cursor-pointer" (click)="viewSignatureDialog(rowData)" style="max-height: 63px;max-width: 114px;margin-left: -11px !important;overflow: hidden;margin-bottom: -5px !important;filter: brightness(0);margin-top: -4px !important;"/>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Application' | translate}}: </span>
							<span style="cursor: pointer;" *ngIf="rowData.application" pTooltip="{{rowData.application}}">{{rowData.application}}</span>
							<span *ngIf="!rowData.application" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
					<td>
						<span class="one-liner">
                            <span class="mobile-table-label">{{'Status' | translate}}:</span>
                            <i class="fa-solid fa-circle-check text-success" *ngIf="rowData.status==='1'" pTooltip="{{'Confirmed' | translate}}" tooltipPosition="left"></i>
                            <i class="fa-solid fa-circle-info text-primary" *ngIf="rowData.status==='0'" pTooltip="{{'Pending' | translate}}" tooltipPosition="left"></i>
                        </span>
                    </td>
                    <td>
						<span class="d-flex align-items-center">
                            <span class="mobile-table-label">{{'Actions' | translate}}:</span>
							<i class="fa-solid fa-pen-to-square text-primary mx-1" (click)="showPopupPunch(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left" style="cursor: pointer;"></i>
                            <i *ngIf="!rowData.isExporting" (click)="exportPdfDetail(rowData)" class="fa-solid fa-print text-purple mx-1" pTooltip="{{'Export PDF' | translate}}"  tooltipPosition="left" style="cursor: pointer;"></i>
							<i *ngIf="rowData.isExporting" class="fa-solid fa-spinner fa-spin text-primary mx-1"></i>
						</span>
                    </td>
                </tr>
            </ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="9" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="9" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
        </p-table>
    </p-panel>

	<p-overlayPanel #op1 [appendTo]="'body'">
		<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op2 appendTo="body">
		<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op3 appendTo="body">
		<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op4 appendTo="body">
		<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span> {{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	
	

<p-dialog #viewDocument *ngIf="showViewFileDialog" [(visible)]="showViewFileDialog" [modal]="true" id="view-document" [style]="{'width':'90%','max-width':'600px','max-hegiht':'80vh'}">
    <p-header>
        <span><i class="fa-solid fa-image mr-2"></i>{{'Signature' | translate}}</span>
    </p-header>
    <img class="w-100" [src]="fileUrl" id="fullImage">
</p-dialog>



<p-dialog [(visible)]="showPunchDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'80vh'}" [closable]="true" id="punchConfirmationModal" appendTo="body" (onHide)="closePunchDialog()">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Confirm Weekly Hours' | translate}}</span>
    </p-header>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Week' | translate}}: <span *ngIf="punchFromDateDialog || punchToDateDialog" class="">{{punchFromDateDialog.slice(0, 5)}}<i class="fa-solid fa-right-long text-muted mx-1"></i>{{punchToDateDialog.slice(0, 5)}}</span></span>
        </p-header>
        <p-table #dtPunch [value]="punchHistoryList" [responsive]="true" dataKey="id" [customSort]="true" [lazy]="true" styleClass="punch-table" [rows]="10" [paginator]="false" [totalRecords]="totalPunchRecords" class="pioh-data">
            <ng-template pTemplate="header" let-columns let-i="index">
                <tr id="header-row">
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Date' | translate}}">{{'form.date' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 55px !important">
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Day' | translate}}">{{'Day' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Start Work' | translate}}">{{'Start Work' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="text-truncate" pTooltip="{{'Break' | translate}}">{{'Break' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'End Break' | translate}}">{{'End Break' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'Break Duration' | translate}}">{{'Break Duration' | translate}}</span>
                        </div>
                    </th>
                    <th>
                        <div class="d-flex align-items-center justify-content-start w-100">
                            <span class="one-liner" pTooltip="{{'End Work' | translate}}">{{'End Work' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 70px !important">
                        <div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'total'  | translate}}
							</span>
                        </div>
                    </th>
                    <th style="width: 70px !important">
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <span class="one-liner" pTooltip="{{'O.T.' | translate}}">{{'O.T.' | translate}}</span>
                        </div>
                    </th>
                    <th style="width: 45px !important">
                        <div  class="d-flex align-items-center justify-content-center w-100">
                            <i class="fa-solid fa-location-dot text-muted m-auto" pTooltip="{{'Track' | translate}}" tooltipPosition="left"></i>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'form.date' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'MM/dd'}}"><b>{{rowData.punchDate | date: 'MM/dd'}}</b></span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Day' | translate}}: </span>
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'EEE'}}">{{rowData.punchDate | date: 'EEE'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center">
                            <div *ngIf="!rowData.editAllowedCheckIn && !isExceptionPunchInOut(rowData.locationName)" class=" text-left pl-lg-2 pl-sm-0">
								<span class="one-liner d-flex">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.clockInTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedStartWork && rowData.clockInTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockInTimeOriginal" pTooltip="{{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockInTimeOriginal" pTooltip="{{ rowData.missedStartWork? ('Missing' | translate) : ((rowData.clockInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedCheckIn && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy) : ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2">
                                <span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedOutForLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null && rowData.employeeClockingLunchHourHistory.length > 1))"  class="one-liner d-flex">
									<span class="mobile-table-label">{{'Break' | translate}}:</span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedLunchOutTime && rowData.lunchOutTime == null ? '--:--' : ''}}</span>
									<span class="d-flex justify-content-between align-items-center" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'" >
										<span *ngIf="rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : ((rowData.lunchOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedOutForLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger text-center' : 'text-center'" >
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2" >
                                <span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedBackFromLunch && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-2 pl-sm-0 text-left">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1))" class="one-liner d-flex">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger' : ''">{{! rowData.missedLunchInTime && rowData.lunchInTime == null ? '--:--' : ''}}</span>
									<span  [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : ((rowData.lunchInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
									</span>
								</span>
                            </div>
                            <div *ngIf="!rowData.editAllowedBackFromLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
                                    {{ rowData.locationName | translate}}
								</span>
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                        <div *ngIf="isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center pl-lg-2">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Break Total' | translate}}: </span>{{rowData.locationName | translate}}
							</span>
                        </div>
                        <div *ngIf="!isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center justify-content-between pl-lg-2 w-100">
							<span class="one-liner">
								<span class="mobile-table-label">{{'LunchTot' | translate}}: </span>
                                {{calCulateTotalLunchTothhmm(rowData)}}
							</span>
                        </div>
                    </td>
                    <td class="p-0">
                        <div class="d-flex flex-wrap align-items-center justify-content-start">
                            <span class="mobile-table-label mobile-left text-nowrap">{{'WorkEnd' | translate}}: </span>
                            <span *ngIf="(!rowData.clockOutTime || !rowData.editAllowedCheckOut) && isExceptionPunchInOut(rowData.locationName)" class="text-nowrap text-truncate text-muted pl-3">{{'no data' | translate}}</span>
                            <div *ngIf="!rowData.editAllowedCheckOut && !isExceptionPunchInOut(rowData.locationName)" class="pl-lg-3 pl-sm-0 text-left">
								<span class="one-liner d-flex">
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left"  [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedEndWork && rowData.clockOutTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'hh:mm a'}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="bottom" >{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : ((rowData.clockOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
									</span>
								</span>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHours" class="mobile-big">{{rowData.workHours}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
                            <span *ngIf="!rowData.workHours" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> 
                            <span *ngIf="rowData.workHours" class="mobile-big">{{rowData.workHours}}</span>
                            <span *ngIf="!rowData.workHours" class="text-muted">--:--</span>

						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
							<span *ngIf="rowData.overTimeStr" pTooltip="{{rowData.overTimeStr}}">{{rowData.overTimeStr}}</span>
							<span *ngIf="!rowData.overTimeStr" class="text-muted">--:--</span>
						</span>
                    </td>
                    <td>
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Location' | translate}}: </span>
							<i *ngIf="rowData.lat && rowData.lng && (!rowData.missedStartWork || !rowData.missedEndWork)" class="fa-solid fa-location-dot text-primary c-pointer"  (click)="viewMap(rowData)" pTooltip="{{'View Map' | translate}}" tooltipPosition="left"></i>
                            <i *ngIf="!rowData.lat || !rowData.lng || rowData.missedStartWork || rowData.missedEndWork" class="fa-solid fa-location-dot text-muted c-pointer"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
                <tr id="footer-row">
                    <td colspan="6">
						<div>
							<button class="btn btn-white btn-sm mx-1" (click)="redirectToPunchHistory();showPunchDialog = false;">
								<span class="text-nowrap"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{'Make Corrections' | translate}}<i class="fa-solid fa-chevron-right text-muted ml-2"></i></span>
							</button>
						</div>
					</td>
                    <td><b class="desktop-table-label pull-right">{{'total' | translate}}:</b></td>
                    <td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Hours' | translate}}: </span>
							<span pTooltip="{{getTotalHour(punchHistoryList)}}" tooltipPosition="left">{{getTotalHour(punchHistoryList)}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total Overtime' | translate}}: </span>
                            {{getTotalOverrtime(punchHistoryList)}}
						</span>
                    </td>
                    <td></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!punchHistoryList">
                    <td [attr.colspan]="13" class="text-center py-5">
                        <div class="text-muted my-5">{{'no data' | translate}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-panel>
	<br>
	<div class="d-flex w-100 py-2 text-center px-5">
		<span style="font-size: 16px"><i class="fa-solid fa-hand-point-right text-orange mr-2" style="font-size: 18px"></i> <p-checkbox [binary]="true" [(ngModel)]="confirmWeeklyHoursCorrect" [disabled]="!showSignaturePadConfirm"></p-checkbox>{{'I confirm (to the best of my knowledge) that the above time record for the week is accurate and reflects the hours I worked. The weekly hours are true and do not need any time corrections.' | translate}}</span>
	</div>


		<div class="d-flex justify-content-center py-4">
			<div class="card add-shadow p-0" id="signature-pad">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-signature text-primary mr-2"></i>{{'Signature Pad' | translate}} </h5>
					</div>
				</div>

				<div id="signLicensor" class="bg-light" style="width: 100%; height: 100%;">
					<!-- Image when signature pad is not shown -->
					<img *ngIf="!showSignaturePadConfirm" src="{{signaturePadUrl}}" style="height: 200px; width: 100%;" />
				
					<!-- Signature pad when signature pad is shown -->
					<signature-pad 
						*ngIf="showSignaturePadConfirm" 
						#signaturePadConfirm 
						[options]="signaturePadConfirmConfig" 
						(onBeginEvent)="drawStartSignaturePad()" 
						(onEndEvent)="drawCompleteSignaturePad()" 
						class="signature-pad">
					</signature-pad>
				</div>
				<div class="card-footer text-center">
					<button class="btn btn-white" (click)="clearLicensorSign()" [disabled]="!showSignaturePadConfirm">
						<i class="fa-solid fa-xmark text-danger mr-2" aria-title="clear Signature"></i>
						<span>{{'Clear Pad' | translate}}</span>
					</button>
				</div>		
			</div>
				

		</div>

     
    <p-footer class="d-flex justify-content-between">
		<span>
			<button *ngIf="showSignaturePadConfirm" class="btn btn-danger mr-1" (click)="showPunchDialog = false">
				<span><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</span>
			</button>
		   <button *ngIf="showSignaturePadConfirm" class="btn btn-primary" (click)="submitPunchConfirmation()" [disabled]="!signaturePadConfirm || signaturePadConfirm.isEmpty() || !confirmWeeklyHoursCorrect">
				<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span>
			</button>
		</span>
        <button *ngIf="!showSignaturePadConfirm" class="btn btn-primary mx-1" (click)="showPunchDialog = false">
            <span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
        </button>
    </p-footer>
</p-dialog>

