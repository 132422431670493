import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { TotalJobHistoryService } from '../../service/total-job-history.service';

@Component({
  selector: 'app-job-follo-up-history',
  templateUrl: './job-follo-up-history.component.html',
  styleUrls: ['./job-follo-up-history.component.scss']
})
export class JobFolloUpHistoryComponent implements OnInit {

  fieldsName = [
    { value: 'clientId', label: 'Base' },
    { value: 'billerId', label: 'Biller' },
    { value: 'supportId', label: 'Support' },
    { value: 'cycle', label: 'Cycle' },
    { value: 'contactName', label: 'Contact' },
    { value: 'contactNumber', label: 'Contact Number' },
    { value: 'emailId1', label: 'Email ID 1' },
    { value: 'emailId2', label: 'Email ID 2' },
    { value: 'followUp', label: 'Status' },
    { value: 'note', label: 'Note' },
    { value: 'statusDateStr', label: 'Status Date' }
  ];

  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false, width: '22%' },
    { field: 'userName', label: 'User', sortable: false, width: '20%' },
    { field: 'oldValue', label: 'Old Value', sortable: false, width: '20%' },
    { field: 'newValue', label: 'New Value', sortable: false, width: '20%' },
    { field: 'action', label: 'Action', sortable: false, width: '18%' }
  ];
  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  size: number;
  page: number;
  historyFor: any = {};
  selectedField: string;

  @Input() jobId: number;
  @Output() onHideHistory: EventEmitter<any> = new EventEmitter();
  @Input() clientList: any;
  @Input() billerEmployees: any;
  @Input() supportEmployees: any;
  loading: Boolean = false;
  toDate: any;
  fromDate: any;

  constructor(private jobHistory: TotalJobHistoryService) { }

  ngOnInit() {
    console.log(this.clientList, this.supportEmployees, this.billerEmployees);
    this.loadDataSource();
  }

  selectField(event) {
    this.historyFor = this.fieldsName.find(field => event.value === field.value);
  }

  loadDataSource(event?: any) {
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    const options: any = {
      size: this.size,
      page: this.page,
      fieldName: this.selectedField
    };
      if (this.fromDate != null && this.toDate != null) {
        options.fromDate = new Date(this.fromDate);
        options.toDate = new Date(this.toDate);
      }
    if (this.jobId) { options.totalJobDetailId = this.jobId; }
    if (!options.totalJobDetailId || !this.selectedField) {
      this.dataSourceSubject.next([]);
      return;
    }
    this.loading = true;
    this.jobHistory.jobDetailHistory(options).subscribe(
      (data: any) => {
        this.dataSourceSubject.next(data.data);
        this.loading = false;
      }
    );
  }

  public hideHistory() {
    this.onHideHistory.emit();
  }


  checkField(value, field) {
    if (field === 'clientId') {
      const client = this.clientList.find(e => e.value === value);
      if (client) { return client.label; }
    }
    if (field === 'billerId') {
      const biller = this.billerEmployees.find(e => e.value === value);
      if (biller) { return biller.label; }
    }
    if (field === 'supportId') {
      const support = this.supportEmployees.find(e => e.value === value);
      if (support) { return support.label; }
    }
    if (field === 'statusDateStr') {
    return moment(parseInt(value)).format('MM/DD/YYYY');
    } else { return value; }
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.selectedField = null;
    this.historyFor = {};
    this.loadDataSource();

  }

}
