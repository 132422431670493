import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-confirmation-dialog',
  templateUrl: './payment-confirmation-dialog.component.html',
  styleUrls: ['./payment-confirmation-dialog.component.scss']
})
export class PaymentConfirmationDialogComponent implements OnInit {
    
    @Input()
    display: boolean;
    
    @Input()
    data;
    
    @Input()
    functionImport;
    
    @Output()
    displayChange = new EventEmitter();

    @Output()
    sendEmailChange = new EventEmitter();
    
    constructor(private fb: UntypedFormBuilder,) {
    }
    
    ngOnInit() {}
    
    onSubmit() {
        this.displayChange.emit(false);
    }
    
    onClickClose(): void {
        this.displayChange.emit(false);
    }

    sendEmail(e) {
        this.sendEmailChange.emit(e)
    }
}
