export class Note {
    id: string;
    noteFolderId: number;
    noteFolderName: string;
    name: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    permission?: string;
    owner?: boolean
}

export class NoteFolder {
    id: number;
    name: string;
    showAll?: Boolean;
    noteFiles?: Note[];
    createdAt: string;
    updatedAt: string;
    owner?: boolean;
}