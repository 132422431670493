import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Lander } from '../model/lander.model';
import {Page} from '../../shared/model/page.model';


@Injectable({
    providedIn: 'root'
})
export class LanderService extends AbstractService<Lander> {
    notificationUrl: string;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.LANDER, '');
    }

    getList(options: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    getById(id: number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    createServiceRate(entity: Lander): Observable<Lander> {
        return this._http.post<Lander>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
    }

    updateServiceRate(entity: Lander): Observable<Lander> {
        return this._http.put<Lander>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
    }


    deleteServiceRate(id: Number) {
        return this._http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    getClientHistoryPage(options?: any): Observable<Page<any>> {
        const params = this.createParams(options);
        return this._http.post<Page<any>>(`${this.baseUrlV2}history/search`, options).pipe(map(resp => resp));
    }

    getCreatedByList(params): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}findAllCreatedBy`, { params: params }).pipe(map(resp => resp));
    }

    exportPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }
}
