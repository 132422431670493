export class DocumentData {
    id:number;
    entity?:string;
    entityId?:number;
    fileClass?:string;
    fileName?:string;
    fileType?:string;
    fileSize?:number;
    filePath?:string;
    fileUrl?:string;
}
