import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-transaction-type-update',
    templateUrl: './transaction-type-update.component.html',
    styleUrls: ['./transaction-type-update.component.css']
})
export class TransactionTypeUpdateComponent implements OnInit {
    transactionTypeId: number;

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe(params => this.transactionTypeId = +params.id);
    }

    ngOnInit() {
    }

}
