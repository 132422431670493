import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../demo/service/carservice';
import {Car} from '../../../demo/domain/car';
import { HighlightModel } from '../../model/highlight.model';
import { SuspendUser } from '../../model/popup';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { DashboardService } from '../../service/dashboard/dashboard.service';
import {ClientService} from '../../../crm/service/client.service';
import {EmployeeClockingService} from '../../../employee-clocking/service/employee-clocking.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {GuestService} from '../../../guest/service/guest.service';
import { Chart } from 'chart.js';
import {EventService} from '../../../crm/service/event.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {UtilsService} from '../../../shared/service/utils.service';
import {EmployeeService} from '../../../employee/service/employee.service';
import { Employee } from '../../../employee/model/employee.model';
import {AgencyHolidayService} from '../../../agency/service';
import {ToDoService} from '../../../todo/service/todo.service';
import {SelectItem, MenuItem, Message, MessageService} from 'primeng/api';
import {LangChangeEvent, TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Observable, Subject, forkJoin} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {interval} from 'rxjs/observable/interval';
import {DocumentsService} from '../../../shared/service/documents-upload/documents.service';
import { CookieService } from 'ngx-cookie-service';
import * as MOMENT from 'moment';
import { PaymentInvoiceService } from 'app/payment/service/payment-invoice.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { PrcoessMonitorLoginActivityService } from 'app/admin/process-monitor/service/process-monitor-login-activity.service';
import { CompanyService } from 'app/company/service/company.service';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';

import {OperatorService} from '../../../employee/service/v2/operator.v2.service';
import { ClockingComplianceRequest } from 'app/reports/model/clock.compliance.model';
import { PayrollSettingService } from 'app/payroll-setting/service/payroll-setting.service';
import {EmployeeJobReportNoteService} from '../../../employee/service/employee-job-report-note.service';
import * as colors from './color.json';
import {DocumentSettingsService} from '../../../company/service/document-settings.service';
import * as _ from 'lodash';
import {ComplianceDocumentService} from '../../../shared/service/compliance-document/compliance-document.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DashboardService, ClientService, TaskManagementService, OperatorService,
      EmployeeClockingService, GuestService, AuthService]
})
export class DashboardComponent implements OnInit {
    cities: SelectItem[];
    userName: any;
    cars: Car[];
    cols: any[];
    chartData: any;
    chartOptions: any;
    lineTooltips: any[] = [];
    events: any[] = [];
    selectedCity: any;
    selectedCar: Car;
    items: MenuItem[];
    connectivityChart: any;
    productivityChart: any;
    allUsersCount = 0;
    roleLevel: any;
    header: any;
    productivityData: any;
    connectivityData: any;
    employeeOverTimeList: Array<any>;
    clientsCount = 0;
    absencesCount = 0;
    clockedIn = 0;
    showAgreementDialog = true;
    checkAgreement = false;
    showOkBtn = false;
    expectedClockedIn = 0;
    highlight: HighlightModel = {} as HighlightModel;
    highlightRoute: String;
    highlightQueryParams: any;
    userActivities: Array<any> = [];
    content: any[] = [];
    counter = 0;
    totalUserActivity = 0;
    page = 0;
    size = 10;
    visitsCompleted: Array<any>;
    visitsScheduled: Array<any>;
    visitsIncomplete: Array<any>;
    logins: any[] = [];
    guests: any[] =  [];
    overtimeChart = [];
    isEmployee: any;
    birthdays: any[] = [];
    upcomings: any[] = [];
    holidays: any[] = [];
    todos: any[] = [];
    anniversaries: any[] = [];
    companyId: any;
    disableViewMore = false;
    startDate = MOMENT().startOf('week').toDate();
    endDate = MOMENT().endOf('week').toDate();
    totalPayment = 0;
    payment: any;
    balances = [];
    chartData2: any;
    clientList: any = [];
    invoices = [];
    date = [];
    types = [];
    selectedType = 'weekly';
    selectedType2 = 'weekly';
    dataPie: any;
    tabShowChart = 1;
    taskManagementNumber = 0;
    myTaskNumber = 0;
    guestTaskNumber = 0;
    allProjectList = [];
    projectsAll = [];
    dataPlanAssigment: any;
    totalOutForLunch = 0;
    totalOutForLunchCount = 0;
    totalAbsent = 0;
    totalClockedIn = 0;
    totalNotLunch = 0;
    numOfCurrentLoginUser = 0;
    isPopupOutForLunch = false;
    isPopupClockIn = false;
    isPopupOutAbsent = false;
    isPopupOutNotLunch = false;
    employeeDisplayData: any = [];
    employeeAbsentDisplayData: any = [];
    employeeDisplayClockingOutForLunch: any = [];
    employeeDisplayClockIn: any = [];
    employeeDisplayClockingAbsent: any = [];
    employeeNotLunchList: any = [];
    employeeDisplayAbsenseList: any = [];
    popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
    popupOutForLunchCols = [
        { field: 'order', header: '#' },
        { field: 'fullName', header: 'FullName' },
        { field: 'timeIn', header: 'In Time' },
    ];
    popupAbsentCols = [];
    dataPlanAssignment: any;
    horizontalOptions: any;
    isDisplay: boolean = false;
    absencesAprroved: any[] = [];
    employeeId: number;
    id: number;
    absenceList:  any[] = [];
    requestsLoading: boolean;
    datePipe: any;
    status: any;
    loadingTable = false;
    popupIndex = 0;
    total = 0;
    totalAssignments = 0;
    listEmployeeOnline = [];
    popupUserOnlineHeader;
    isShowUserOnline = false;
    employeeOnlineData = [];
    employeeOnlineCols = [];
    selectedReport;
    fullSelectedReport;
    newReply;
    showConcern = false;
    reportSize = 10;
    reportPage = 0;
    loadingJobReports = false;
    jobReports = [];
    jobReportsConcerns = [];
    popupJobReportSummaryData: any = [];
    employeeDisplayMissedJobReport: any = [];
    employeeDisplayMissedJobReportMoreThanLimit: any = [];
    popupJobReportSummaryCols = [];
    totalMissJobReport = 0;
    totalMissThanLimit = 0;
    isPopupJobReportSummary = false;
    popupIndexSummary = 1;
    showSumary = false;
    isPopupLateness = false;
    popupLatenessData: any = [];
    popupLatenessCols = [];
    totalLateness = 0;
    complianceFilter: ClockingComplianceRequest = new ClockingComplianceRequest();
    unitList = [];
    isShowPopupSuspend = false;
    isShowPopupUnSuspend = false;
    messageConfirmUnsuspendUser: any;
    dataSelectedSuspend = {};
    maxDate: Date;
    suspendUser: SuspendUser = new SuspendUser();
    modalHeaderTitle: string;
    jobReportMissingLimit = 0;
    beyondLimitationDecision = 0;
    employeeLatenessDecision = 0;

    loadingLateness = false;
    loadingPunchStatisticNew = false;
    isComplianceTab = false;
    complianceOptions = {
        title: {
            display: true,
            text: '0',
            fontSize: 30,
            position: 'bottom'
        },
        legend: {
            position: 'top'
        },
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI
    };
    complianceData = {
        labels: ['1 to 57','58 to 69','70 to 100'],
        datasets: [
            {
                data: [57, 12, 31],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                circumference: 180,
                rotation: -90,
            }]
    }
    mandatoryOptions = {
        title: {
            display: false,
            text: 'Mandatory Compliance Open Issues',
            fontSize: 16,
            position: 'bottom'
        },
        legend: {
            position: 'right',
            display: false,
        }
    };
    mandatoryData = {
        labels: ['A \n dsdsd','B','C'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                circumference: 180,
                rotation: -90,
            }]
    };
    compliances = [];

    currentPage: number = 0;
    pageSize: number = 4;
    paginatedConcerns: any[] = [];
    complianceScores = true;

    textSearch: string = '';
    filteredEmployeeDisplayData: any[] = [];
    filteredEmployeeDisplayData2: any[] = [];
    absentFilter = false;
    userTable = true;

    constructor(private carService: CarService,
                private clientService: ClientService,
                private companyService: CompanyService,
                private employeeClockingService: EmployeeClockingService,
                private guestService: GuestService,
                private eventService: EventService,
                private authService: AuthService,
                private utilsService: UtilsService,
                private toDoService: ToDoService,
                private agencyHolidayService: AgencyHolidayService,
                private router: Router,
                private cookieService: CookieService,
                private employeeService: EmployeeService,
                private breadcrumbService: BreadcrumbService,
                private dashboardService: DashboardService,
                private translate: TranslateService,
                private translatePipe: TranslatePipe,
                private taskManagementService: TaskManagementService,
                private employeeJobReportService: EmployeeJobReportService,
                private employeeJobReportNoteService: EmployeeJobReportNoteService,
                private messageService: MessageService,
                private documentService: DocumentsService,
                private paymentInvoiceService: PaymentInvoiceService,
                private operatorService: OperatorService,
                private emmployeeService: EmployeeService,
                private dropDownsService: DropDownsService,
                private documentSettingsService: DocumentSettingsService,
                private cdr: ChangeDetectorRef,
                private complianceDocumentService: ComplianceDocumentService,
                private payrollSettingService: PayrollSettingService) {
                    const userInfo = this.authService.getUserInfo();
                    this.roleLevel = userInfo.role ? userInfo.role.level : null;
                  this.isEmployee = this.authService.isEmployeeRole();
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.setDefaultLang(event.lang);
            this.translate.use(event.lang);
        });
    }
    ngOnInit() {
        this.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompanyId() : 0;
        this.getAllDocument();
        this.types = [
            {value: 'weekly', label: 'This Week'},
            {value: 'month', label: 'This Month'},
            {value: 'year', label: 'This Year'}
        ];
        this.breadcrumbService.setItems([
            {label: 'Dashboard'}
        ]);
        this.unitList = [
            {value: 1, label: 'Hours'},
            {value: 2, label: 'Days'}
        ]
        this.loadPayrollSetting();
        this.loadStat();
        this.loadAllTask();
        this.planAssignmentData();
        this.getMissedJobReport();
        this.loadDataLateness();
        this.getEmployeeOnline();
        Chart.pluginService.register({
            beforeDraw: function (chart) {
                if (chart.config.options.elements.center) {
                    const ctx = chart.chart.ctx;
                    const centerConfig = chart.config.options.elements.center;
                    const fontStyle = centerConfig.fontStyle || 'IBM Plex Sans';
                    const txt = centerConfig.text;
                    const color = centerConfig.color || '#000';
                    const sidePadding = centerConfig.sidePadding || 20;
                    const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                    ctx.font = '30px ' + fontStyle;
                    const stringWidth = ctx.measureText(txt).width;
                    const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
                    const widthRatio = elementWidth / stringWidth;
                    const newFontSize = Math.floor(30 * widthRatio);
                    const elementHeight = (chart.innerRadius * 2);
                    const fontSizeToUse = Math.min(newFontSize, elementHeight);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontSizeToUse + 'px ' + fontStyle;
                    ctx.fillStyle = color;
                    ctx.fillText(txt, centerX, centerY);
                }
            }
        });
        const clientOptions = {
            username: this.authService.getUserInfo().username
        };
        this.carService.getCarsSmall().then(cars => this.cars = cars);
        this.loadAnniversary();
        this.loadAbsenceList();
        this.loadHolidays();
        this.getActivity();
        this.getMyAssignments();
        const d = new Date();
        const request = {
            page: 0,
            size: 1000,
            isFromMobile: true,
            fromDate : moment().subtract(4, 'weeks').startOf('week').toDate(),
            toDate:  moment().endOf('week').toDate(),
            timeZoneOffset: (d.getTimezoneOffset()).toString(),
            adminId: parseInt(localStorage.getItem('_userId'))
        };
        this.dashboardService.getDashboardDataNew(request).subscribe((res) => {
           if (res.status === 'SUCCESS') {
                this.clientsCount = res.data.clientsCount;
                this.absencesCount = res.data.absencesCount;
                this.expectedClockedIn = res.data.expectedClockedIn;
                this.overtimeChart = res.data.overtimeChart.overtimeChartBean;
                this.chartData = this.getOvertimeData();
                this.changeOvertimeData();
                this.highlight = res.data.dashboardHighlight;
                const value: any = this.highlight.value;
                this.highlight.value = value ? value : 0;
            if (this.highlight.title === 'Performance') {
                this.highlightRoute = '/app/performance/employees-list';
                this.highlightQueryParams = {};
            } else {
                this.highlightRoute = '/app/todo/list';
                this.highlightQueryParams = {status : 'Todo'};
            }
            this.logins.length = res.data.dailyConnection.loginTodayLength;
            this.allUsersCount = res.data.dailyConnection.userCounts;
            this.changeConnectivityData();
           }
        });

        this.chartOptions = {
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        return (data.tooltips[tooltipItem.index]).split('#');
                    }
                }

            }
        };
        this.visitsCompleted = [];
        this.visitsScheduled = [];
        this.visitsIncomplete = [];
        this.cols = [
            { field: 'vin', header: 'Vin' },
            { field: 'year', header: 'Year' },
            { field: 'brand', header: 'Brand' },
            { field: 'color', header: 'Color' }
        ];
        this.cities = [];
        this.cities.push({label: 'Select City', value: null});
        this.cities.push({label: 'New York', value: {id: 1, name: 'New York', code: 'NY'}});
        this.cities.push({label: 'Rome', value: {id: 2, name: 'Rome', code: 'RM'}});
        this.cities.push({label: 'London', value: {id: 3, name: 'London', code: 'LDN'}});
        this.cities.push({label: 'Istanbul', value: {id: 4, name: 'Istanbul', code: 'IST'}});
        this.cities.push({label: 'Paris', value: {id: 5, name: 'Paris', code: 'PRS'}});
        this.items = [
            {label: 'Save', icon: 'fa-solid fa-check'},
            {label: 'Update', icon: 'fa-solid fa-rotate'},
            {label: 'Delete', icon: 'fa-solid fa-xmark'}
        ];

        this.header = {
            left: 'prev, next today',
            center: 'title',
            right: 'month, agendaWeek, agendaDay'
        };


        this.employeeOverTimeList = [];
        // this.getEmployeeOvertime();

        if (!this.isCEOAdmin()) {
            this.changeConnectivityData();
            this.changeProductivityData();
            this.productivityData = this.getProductivityData();
            this.connectivityData = this.getConnectivityData();
            this.connectivityChart = new Chart('connectivityCanvas', this.connectivityData);
            this.productivityChart = new Chart('productivityCanvas', this.productivityData);
        }

        if (this.isCEOAdmin()) {
            this.startDate = MOMENT().startOf('week').toDate();
            this.endDate = MOMENT().endOf('week').toDate();
            this.dataPie = {
                labels: [this.translatePipe.transform('Service Fees Paid'), this.translatePipe.transform('Outstanding Balances'), this.translatePipe.transform('Total Amount')],
                datasets: [
                    {
                        data: [0, 0, 0],
                        backgroundColor: [
                            '#36A2EB',
                            '#FFCE56',
                            '#e9e8e6'
                        ],
                        hoverBackgroundColor: [
                            '#36A2EB',
                            '#FFCE56',
                            '#e9e8e6'
                        ]
                    }]
            };
            this.loadAllClient();
            this.getTotalPayment();
            this.getTotalPaymentData();
            this.getOutStandingBalance();
            this.getInvoice();
        }
        this.isDisplay =  this.authService.getLoggedInUserMenuList().includes('49');
        this.loadDataSourceJobReport();

    }

    statisticEmployee: any = {};
    statisticEmployeeCompliance: any = {};
    totalDocument = 0;
    showChart;
    getAllDocument() {
        const fromDate = MOMENT().startOf('day').toDate().getTime();
        const toDate = MOMENT().endOf('day').toDate().getTime();
        /*this.complianceDocumentService.statistic({companyId: this.companyId, fromDate, toDate}).subscribe();
        this.employeeService.statistic({companyId: this.companyId, fromDate, toDate}).subscribe();
        this.employeeService.statisticCompliance({companyId: this.companyId, fromDate, toDate}).subscribe();*/

        /*this.statisticEmployee = {
            count: 1000,
            increase: 5,
            decrease: 6
        };
        this.statisticEmployeeCompliance = {
            count: 30,
            increase: 1,
            decrease: 3
        };
        const value1 = this.statisticEmployeeCompliance.count/this.statisticEmployee.count;
        this.complianceOptions.title.text = (_.ceil(value1, 2)*100).toString();*/
        this.showChart = false;
        forkJoin(this.employeeService.statistic({companyId: this.companyId, fromDate, toDate}),
            this.employeeService.statisticCompliance({companyId: this.companyId, fromDate, toDate})).subscribe((response: any) => {
                if (response.length > 1) {
                    const statisticEmployee = response[0].data || {};
                    this.statisticEmployee = {
                        count: statisticEmployee.count || 0,
                        increase: statisticEmployee.increase || 0,
                        decrease: statisticEmployee.decrease || 0
                    };
                    const statisticEmployeeCompliance = response[1].data || {};
                    this.statisticEmployeeCompliance = {
                        count: statisticEmployeeCompliance.count || 0,
                        increase: statisticEmployeeCompliance.increase || 0,
                        decrease: statisticEmployeeCompliance.decrease || 0
                    };
                    const value = this.statisticEmployeeCompliance.count/this.statisticEmployee.count;
                    this.complianceOptions.title.text = this.statisticEmployee.count ? (_.ceil(value, 2)*100).toString() : '0';
                    this.showChart = true;
                    this.cdr.detectChanges();
                }
            }
        );
        forkJoin(this.documentSettingsService.search({type: 'visible', companyId: this.companyId, forPolicy: true, required: true}),
            this.complianceDocumentService.statistic({companyId: this.companyId, fromDate, toDate})).subscribe((response: any) => {
            if (response.length > 1) {
                const selectedColors = _.shuffle(colors).slice(0, (response[0].data || []).length);
                const colorMap = selectedColors.reduce(function(map, obj, index) {
                    map[index] = obj.code && obj.code.hex;
                    return map;
                }, {});

                const countMap = (response[1].data.count || []).reduce(function(map, obj, index) {
                    map[obj[0]] = obj[1];
                    return map;
                }, {});
                const inCreaseMap = (response[1].data.increase || []).reduce(function(map, obj, index) {
                    map[obj[0]] = obj[1];
                    return map;
                }, {});
                const deCreaseMap = (response[1].data.decrease || []).reduce(function(map, obj, index) {
                    map[obj[0]] = obj[1];
                    return map;
                }, {});
                this.compliances = (response[0].data || []).map((item, index) => ({
                    name: item.documentName,
                    color: colorMap[index],
                    count: countMap[item.documentName] || 0,
                    increase: inCreaseMap[item.documentName] || 0,
                    decrease: deCreaseMap[item.documentName] || 0
                }));
                this.totalDocument = this.compliances.reduce((n, {count}) => n + count, 0);
                this.compliances = _.orderBy(this.compliances, 'count', 'desc');
                this.mandatoryData.datasets[0].data = this.compliances.map(item => item.count);
                this.mandatoryData.datasets[0].backgroundColor = this.compliances.map(item => item.color);
                this.mandatoryData.datasets[0].hoverBackgroundColor = this.compliances.map(item => item.color);
                this.mandatoryData.labels = this.compliances.map(item => item.name);
                this.cdr.detectChanges();
            }
        });
    }

    employeePercent(increase?) {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        if (increase) {
            const value = this.statisticEmployee.increase/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        } else {
            const value = this.statisticEmployee.decrease/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        }
    }

    employeeCompliancePercent(increase?) {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        if (increase) {
            const value = this.statisticEmployeeCompliance.increase/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        } else {
            const value = this.statisticEmployeeCompliance.decrease/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        }
    }

    employeeNonCompliancePercent(increase?) {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        if (increase) {
            const value = this.statisticEmployeeCompliance.decrease/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        } else {
            const value = this.statisticEmployeeCompliance.increase/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        }
    }

    compianceScore() {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        const value = this.statisticEmployeeCompliance.count/this.statisticEmployee.count;
        return _.ceil(value, 2)*100;
    }

    increaseCompianceScore(increase?) {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        if (increase) {
            const value = this.statisticEmployeeCompliance.increase/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        } else {
            const value = this.statisticEmployeeCompliance.decrease/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        }
    }

    increaseCompianceDocument(data, increase?) {
        if (!this.statisticEmployee.count) {
            return 0;
        }
        if (increase) {
            const value = data.increase/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        } else {
            const value = data.decrease/this.statisticEmployee.count;
            return _.ceil(value, 2)*100;
        }
    }

    loadAnniversary() {
        this.employeeService.findAllAnniversaryByAdmin(this.companyId).subscribe(res => {
            this.anniversaries = res.data.anniversary;
            this.birthdays = res.data.birthday;
            const today = new Date();
            this.anniversaries.forEach(an => {
                an.title = an.gender === 'FEMALE' ? 'her' : 'his';
                an.year = today.getFullYear() - new Date(an.employeeStartDate).getFullYear();
            });
        });
        const employeeId = this.authService.getCurrentEmployee();
        this.employeeId = employeeId;
        this.employeeService.dashboard(employeeId).subscribe(res => {
            const data = res.data;
            console.log(data);
            this.birthdays = data.birthday;
            this.anniversaries = data.anniversary ?? [];
            const today = new Date();
            if (this.loadAnniversary) {
                this.anniversaries.forEach(an => {
                    an.title = an.gender === 'FEMALE' ? 'her' : 'his';
                    an.year = today.getFullYear() - new Date(an.employeeStartDate).getFullYear();
                });
            }
            this.requestsLoading = false;
            if (this.absenceList && this.absenceList.length > 0) {
                this.absenceList.forEach(absence => {
                    absence['status'] = this.status(absence);
                    absence['from'] = this.datePipe.transform(absence.timeStart, 'M/d/y');
                    absence['to'] = this.datePipe.transform(absence.timeEnd, 'M/d/y');
                    if (absence.status === 'Approved' && absence.employee) {
                        const fullName = absence.employee.firstName + ' ' + absence.employee.lastName;
                        const timeStart = new Date(absence.timeStart);
                        const timeEnd = new Date(absence.timeEnd);
                        let message;
                        if (moment(timeStart).diff(moment(timeEnd), 'd') === 0) {
                            message = fullName + ' scheduled for time off on ' + moment(timeStart).format('MM/DD/YYYY');
                        } else {
                            message = fullName + ' scheduled for time off from ' + moment(timeStart).format('MM/DD/YYYY') + ' to ' + moment(timeEnd).format('MM/DD/YYYY');
                        }
                        this.absencesAprroved.push(message);
                    }
                });
            }
        });
    }


    loadAbsenceList() {
        const currTime = new Date().getTime();
        const payload = {
            status: 2,
            companyId: this.authService.getCurrentCompanyId(),
            fromDate: currTime,
            toDate: currTime
        }
        this.employeeService.getAllAbsence(payload).subscribe((res: any) => {
            res.data.content.forEach(absence => {
                const fullName = absence.employee.fullName;
                const timeStart = new Date(absence.timeStart);
                const timeEnd = new Date(absence.timeEnd);
                let message;
                if (moment(timeStart).diff(moment(timeEnd), 'd') === 0) {
                    message = fullName + ' scheduled for time off on ' + moment(timeStart).format('MM/DD/YYYY');
                } else {
message = fullName + ' scheduled for time off from ' + moment(timeStart).format('MM/DD/YYYY') + ' to ' + moment(timeEnd).format('MM/DD/YYYY');
                }
                this.absencesAprroved.push(message);
            });
        });
    }

    loadUpcoming() {
        this.eventService.getUpComing({companyId: this.companyId}).subscribe((res: any) => {
            this.upcomings = res.data;
        });
    }

    getTodoList() {
        const options = {
            fromDate: new Date(),
            toDate: new Date(),
            status: '',
            ids: null,
            flag: '',
            username: '',
            agencyId: null,
            companyId: this.companyId
        };
        this.toDoService.getAllByDueDateAndStatus(options).subscribe((res: any) => {
            this.todos = res.data;
        });
    }

    checkNoData() {
        let countNews = 0;
        if (this.todos) {
            countNews = countNews + this.todos.length;
        }
        if (this.anniversaries) {
            countNews = countNews + this.anniversaries.length;
        }
        if (this.birthdays) {
            countNews = countNews + this.birthdays.length;
        }

        if (this.upcomings) {
            countNews = countNews + this.upcomings.length;
        }

        if (this.holidays) {
            countNews = countNews + this.holidays.length;
        }
        if (this.absencesAprroved) {
            countNews = countNews + this.absencesAprroved.length;
        }

        return countNews > 0 ? true : false;
    }

    loadHolidays() {
        const options = {
            fromDate: moment().startOf('week').toDate(),
            toDate: moment().endOf('week').toDate(),
            companyId: this.companyId
        };
        this.agencyHolidayService.filter(options).subscribe((res: any) => {
           res.data.content.forEach(i => {
                const date = new Date(i.holidayDate);
                 date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                 i.holidayDate = date;
                 this.holidays.push(i);
            });

        });
    }

    isCEOAdmin() {
        return this.roleLevel === 3 && this.authService.getCurrentUsername().includes('ceoadmin_');
    }

    convertDate(row: any) {
        if (row.date) {
            return moment(row.date).format('mmmm DD');
        }
        return '';
    }
    private getConnectivityData(langulage?: string) {
        const lang = langulage ? langulage : this.translate.currentLang;
        const labels = this.translate.translations[lang]['connectivityDataLabel'];
        return {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                {
                    data: [this.logins.length, this.guests.length],
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                    ]
                }]
            },
            options: {
                elements: {
                    center: {
                        text: this.getConnectivityText(),
                        color: '#FF6384', // Default is #000000
                        fontStyle: 'IBM Plex Sens', // Default is Arial
                        sidePadding: 20 // Defualt is 20 (as a percentage)
                    }
                }
            }
        };
    }

    private getProductivityData(language?: string) {
        const lang = language ? language : this.translate.currentLang;
        const labels = this.translate.translations[lang]['ProductivityDataLabel'];
        return {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: [this.getCompleted(), this.getScheduled(), this.getIncomplete()],
                        backgroundColor: [
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56'
                        ],
                        hoverBackgroundColor: [
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56'
                        ]
                    }]
            },
            options: {
                elements: {
                    center: {
                        text: this.getProductivityText(),
                        color: '#FF6384', // Default is #000000
                        fontStyle: 'IBM Plex Sans', // Default is Arial
                        sidePadding: 20 // Defualt is 20 (as a percentage)
                    }
                }
            }
        };
    }

    getProductivityText() {
        if (this.getCompleted() !== 0) {
            return (this.getScheduled() / this.getCompleted()).toFixed(2);
           // return this.getScheduled() === 0 ? '0.00' : this.getScheduled() / this.getCompleted();
        }
        return 'NA';
    }

    getConnectivityText() {
        if (this.guests.length > 0) {
            const allUsers = this.logins.length + this.guests.length
            return ((this.logins.length * 100) / allUsers).toFixed(2) + '%'
        } else {
            return '100%'
        }
        // if (this.allUsersCount !== 0) {
        //     this.allUsersCount += this.guests.length;
        //     if ((this.logins.length * 100) / this.allUsersCount > 100) return 100;
        //     if ((this.logins.length * 100) % this.allUsersCount === 0) {
        //         return (this.logins.length * 100) / this.allUsersCount;
        //     } else {
        //         const connectTivity = (this.logins.length * 100) / this.allUsersCount;
        //         return connectTivity.toFixed(2) + '%';
        //     }
        //
        // }
        // return '0%';
    }

    getScheduled() {
        return this.visitsScheduled.length;
    }

    getCompleted() {
        return this.visitsCompleted.length;
    }

    getIncomplete() {
        return this.visitsIncomplete.length;
    }

    changeProductivityData() {
        const changedData = this.getProductivityData();
        this.productivityData = Object.assign({}, changedData);
        this.productivityChart = new Chart('productivityCanvas', this.productivityData);
    }

    changeConnectivityData() {
        const changedData = this.getConnectivityData();
        this.connectivityData = Object.assign({}, changedData);
        this.connectivityChart = new Chart('connectivityCanvas', this.connectivityData);
    }

    getEmployeeOvertime() {
        this.dashboardService.getEmployeeOvertimeList().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeeOverTimeList = resObj.data;
            }
        });
    }

    getTimeDifference(dt1) {
        const newDate = new Date(dt1);
        const miliseconds = ((new Date()).getTime() - newDate.getTime());
        const totalSeconds = parseInt(Math.floor(miliseconds / 1000).toString(), 10);
        const totalMinutes = parseInt(Math.floor(totalSeconds / 60).toString(), 10);
        const totalHours = parseInt(Math.floor(totalMinutes / 60).toString(), 10);
        const days = parseInt(Math.floor(totalHours / 24).toString(), 10);
        if (totalSeconds < 60) {
            return totalSeconds + ' ' + this.translatePipe.transform('secs ago');
        } else if (totalMinutes > 0 && totalMinutes < 60) {
            return totalMinutes + ' ' + this.translatePipe.transform('mins ago');
        } else if (totalHours > 0 && totalHours < 24) {
            return totalHours + ' ' + this.translatePipe.transform('hours ago');
        } else {
            return days > 1 ? days + ' ' + this.translatePipe.transform('days ago') : '1 ' + this.translatePipe.transform('days ago');
        }
    }

    generateOvertimeMessage(employee) {
        if (!employee.weeklyWorkedHours) {
            return '';
        }
        const workedWeekly = employee.weeklyWorkedHours.replace('m', '').split('h');
        const workedHours = parseInt(workedWeekly[0], 10);
        const workedMinutes = parseInt(workedWeekly[1], 10);
        const weeklyWorkingTimeLimit = employee.weeklyWorkingTimeLimitation;

        // tslint:disable-next-line:max-line-length
        return this.translatePipe.transform(' has worked ') + workedHours + this.translatePipe.transform(' hours ') + (workedMinutes > 0 ? workedMinutes + this.translatePipe.transform(' minutes ') : '') + this.translatePipe.transform('(limitation: ') + weeklyWorkingTimeLimit + 'h).';
    }

    getData(type) {
        if (type === 'less') {
            this.counter = 0;
            this.page = 0;
            this.size = 10;
            this.disableViewMore = false;
        }
        if (type === 'more') {
            this.size = this.size + 10;
        }
        this.getActivity();
    }

    changeOvertimeData() {
        const changedData = this.getOvertimeData();
        this.chartData = Object.assign({}, changedData);
    }

    getActivity() {
        const options = {
            companyId: this.authService.getCurrentCompanyId(),
            page: 0,
            size: this.size,
            sortField: 'createdAt',
            sortOrder: 'DESC'
        };
        this.authService.getLatestUserActivities(options.companyId, options.page, options.size).subscribe(res => {
            if (res.data && res.data.content.length > 0) {
                this.counter = res.data.totalElements;
                this.totalUserActivity = res.data.totalElements;
                if (this.totalUserActivity < options.size) {
                    this.disableViewMore = true;
                }
                this.userActivities = [];
                res.data.content.forEach(e => {
                    if ((e.profilePhoto === null)) {
                        e.avatar = 'assets/images/logo.png';
                    } else {
                        this.getImageFile(e).subscribe(profile => {
                            e.avatar = profile.avatar;
                        });
                    }

                    e.green = false;
                    if (e.deskStatus != null) {
                        if (e.deskStatus.toString().toLowerCase().includes('start') || e.deskStatus.includes('back')) {
                            e.green = true;
                        }
                    }
                    this.userActivities.push(e);
                });
            }
        }, (_error) => {
        });
    }

    private getOvertimeData(langulage?: string) {
        const lang = langulage ? langulage : this.translate.currentLang;
        const labels = this.translate.translations[lang]['TotalOvertimeHours'];
        return {
            labels: this.getLineLabels(),
            tooltips: this.getLineTooltips(),
            datasets: [
                {
                    label: labels,
                    data: this.getLineValues(),
                    fill: false,
                    borderColor: '#a053ff'
                }
            ]
        };
    }

    private getLineLabels() {
        const labels = [];
        this.overtimeChart.forEach(item => {
            labels.push(new Date(item.weekEndDate).toLocaleDateString('en-US'));
        });
        return labels;
    }

    selectData(event) {
        const data = this.chartData.datasets[0].data[event.element._index];
        this.router.navigate(['/app/overtime/list', {weekEndDate: this.chartData.labels[event.element._index], includeNoOvertime: data === 0}]);
    }

    private getLineValues() {
        const values = [];
        this.overtimeChart.forEach(item => {
            values.push(item.totalHours);
        });
        return values;
    }

    private getLineTooltips() {
        const tooltips = [];
        this.overtimeChart.forEach(item => {
            tooltips.push(item.tooltip);
        });
        return tooltips;
    }

    onClickLogout() {
        this.employeeService.invoiceWarningDialog = false;
        this.employeeService.balanceWaringDialog = false;
        this.employeeService.showConfirmAutoPayReminderDialog = false;
        this.employeeService.payUnPaidInvoiceDialog = false;
        this.employeeService.showRegisterChildAccountDialog = false;
        this.employeeService.showOutstandingBalancePayDialog = false;
        document.getElementById('logoutHiddenLabel').click();
        this.authService.logout().subscribe((res: any) => {
            if (res.status === 200) {
                this.authService.logUserActivity();
                this.cookieService.deleteAll();
                localStorage.clear();
                this.authService.authenticateUser();
                this.authService.setAuthObservable();
                this.router.navigate(['/', 'login']);
            }
        }, error => {
            this.cookieService.deleteAll();
            localStorage.clear();
            this.authService.authenticateUser();
            this.authService.setAuthObservable();
            this.router.navigate(['/login']);
        });
        this.utilsService.isLogoutTriggered.next(false);
        this.utilsService.isLogoutTriggerCompleted = false;
    }

    showOnlyOkBtn() {
        if (this.checkAgreement) {
            this.showOkBtn = true;
        } else {
            this.showOkBtn = false;
        }
    }

    getImageFile(userInfo) {
        return this.documentService.getAvatarUrl(userInfo);
    }

    /* Chart Bar Invoice */

    switchTab(tab: number) {
        this.tabShowChart = tab;
    }

    loadAllClient() {
        const options: any = { size: 99999, page: 0, moduleName: 'billing' };
        this.dropDownsService.getAllClientList(options).subscribe((res) => {
            const resObj: any = res;
            this.clientList = [];
            if (resObj.status === 'SUCCESS') {
                this.clientList = resObj.data;
            }
        });
    }
    getOutStandingBalance() {
        this.startDate = MOMENT().startOf('week').toDate();
        this.endDate = MOMENT().endOf('week').toDate();
        const options = <any>{
            fromDate: null,
            toDate: null,
            companyId: 0
        };
        options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
        options.fromDate = this.startDate;
        options.toDate = this.endDate;
        this.paymentInvoiceService.invoiceOutStandingBalance(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.balances = resObj.data;
                const labels = [];
                const data = [];

                setTimeout(() => {this.balances.forEach(dt => {
                        const client = this.clientList.find(com => com.key === dt.clientId);
                        if (client) {
                            labels.push(client.value);
                        }
                        data.push(dt.total);
                    });
                    this.chartData2 = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.translatePipe.transform('Outstanding Balances'),
                                backgroundColor: '#4842f5',
                                borderColor: '#4842f5',
                                data: data
                            }
                        ]
                    };
                }, 1000);
            }
        });
    }

    getPlanAssigment() {
        let byType: any = 'week';
        if (this.selectedType === 'weekly') {
            byType = 'week';
        }
        if (this.selectedType === 'month') {
            byType = 'month';
        }
        if (this.selectedType === 'year') {
            byType = 'year';
        }


    }

    getInvoice() {
        const options = <any>{
            companyId: 0,
            fromDate: null,
            toDate: null,
            page: 0,
            size: 9999
        };
        options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
        options.fromDate = this.startDate.toDateString();
        options.toDate = this.endDate.toDateString();
        this.paymentInvoiceService.filter(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.invoices = resObj.data.content;
                this.invoices.forEach(invoice => {
                    const client = this.clientList.find(com => com.key === invoice.clientId);
                    if (client) {
                        invoice.clientName = client.value;
                    }
                    const paidFee = invoice.paidFee ? invoice.paidFee : 0;
                    invoice.paidFeeTxt = paidFee.toFixed(2);
                    invoice.feeTxt = invoice.fee.toFixed(2);
                    invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
                    const balance = invoice.totalFee - paidFee;
                    invoice.balanceTxt = balance.toFixed(2);
                    invoice.color = '';
                    if (!invoice.dueDate) {
                        invoice.color = 'green';
                    } else {
                        this.date.push(new Date(invoice.dueDate));

                        invoice.color = MOMENT(invoice.dueDate).isAfter(MOMENT(new Date())) ? 'orange' : 'red';
                    }
                    console.log(this.date);
                });
            }
        });
    }

    getAllData() {
        let byType: any = 'week';
        if (this.selectedType === 'weekly') {
            byType = 'week';
        }
        if (this.selectedType === 'month') {
            byType = 'month';
        }
        if (this.selectedType === 'year') {
            byType = 'year';
        }

        this.startDate = MOMENT().startOf(byType).toDate();
        this.endDate = MOMENT().endOf(byType).toDate();
        this.getTotalPaymentData();
        this.getOutStandingBalance();
        this.getInvoice();
    }

    getTotalPayment() {
        const options = <any>{
            fromDate: null,
            toDate: null,
            companyId: 0
        };
        options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
        options.fromDate = MOMENT().startOf('month').toDate();
        options.toDate = MOMENT().startOf('month').toDate();
        this.paymentInvoiceService.getTotalPayment(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.totalPayment = resObj.data.totalAmount.toFixed(2);
            }
        });
    }


    getTotalPaymentData() {
        const options = <any>{
            fromDate: null,
            toDate: null,
            companyId: 0
        };
        options.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
        options.fromDate = this.startDate;
        options.toDate = this.endDate;
        this.paymentInvoiceService.getTotalPayment(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.payment = resObj.data;
                this.totalPayment =  this.payment.totalAmount.toFixed(2);
                this.dataPie = {
                    labels: [this.translatePipe.transform('Service Fees Paid'), this.translatePipe.transform('Outstanding Balances'), this.translatePipe.transform('Total Amount')],
                    datasets: [
                        {
                            data: [this.payment.serviceFee.toFixed(2),
                                this.payment.outStandingBalance.toFixed(2),
                                this.payment.totalAmount.toFixed(2)],
                            backgroundColor: [
                                '#36A2EB',
                                '#FFCE56',
                                '#e9e8e6'
                            ],
                            hoverBackgroundColor: [
                                '#36A2EB',
                                '#FFCE56',
                                '#e9e8e6'
                            ]
                        }]
                };
            }
        });
    }

    loadStat(option?: any) {
        option = {};
        option.companyId = this.authService.getCurrentCompanyId();
        option.fromDate = moment(new Date()).startOf('day').toDate();
        option.toDate = moment(new Date()).endOf('day').toDate();
        option.isForOT = false;
        option.isMissing = false;
        option.type = 1;
        option.employeeIds = [];
        option.employees = [];
        option.ids = [];
        option.page = 0;
        option.size = 9999;
        if (this.isEmployee) {
            option.employeeIds.push(this.authService.getCurrentEmployee());
            option.companyId = this.authService.getUserInfo().companyId;
        }
        this.loadingPunchStatisticNew = true;
        this.employeeClockingService.punchStatisticNew(option).subscribe(res => {
            this.loadingPunchStatisticNew = false;
            if (res.data) {
                const statisticData: any = res.data;
                this.totalOutForLunch = statisticData.totalOutForLunch;
                this.totalAbsent = statisticData.totalAbsent > 0 ? statisticData.totalAbsent : 0;
                this.totalClockedIn = statisticData.totalClockedIn;
                this.totalNotLunch = statisticData.totalNotLunch;
                this.employeeDisplayClockIn =  statisticData.employeeClockIn;
                this.employeeDisplayClockingOutForLunch = statisticData.totalOutForLunchEmployeeList;
                this.employeeDisplayClockingAbsent = statisticData.employeeAbsent;
                this.employeeDisplayAbsenseList = statisticData.employeeAbsentAskForLeave;
                this.employeeNotLunchList = statisticData.employeeNotLunch;

                const employeeIdOutForLunchTmp = (this.employeeDisplayClockingOutForLunch || []).map(i => Object.assign({}, i.employee?.employeeId));
                this.employeeNotLunchList = this.employeeNotLunchList.filter(i => !(employeeIdOutForLunchTmp || []).includes(i.employeeId)).map(i => Object.assign({}, i));
                this.totalNotLunch = this.employeeNotLunchList ? this.employeeNotLunchList.length : this.totalNotLunch;
            }
        }, (_error) => {
            this.loadingPunchStatisticNew = false;
            console.log(_error);
        });
    }

    closeDialog() {
        this.isPopupOutForLunch = false;
        this.isPopupClockIn = false;
        this.isPopupOutAbsent = false;
        this.isPopupOutNotLunch = false;
    }

    openOutForLunchDialog() {
        console.log("this.employeeDisplayClockingOutForLunch");
        console.log(this.employeeDisplayClockingOutForLunch);
        this.popupIndex = 2;
        this.popupOutForLunchCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' },
            { field: 'timeIn', header: 'Time' }
        ];
        this.modalHeaderTitle = 'Break';
        this.popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
        this.totalOutForLunchCount = this.totalOutForLunch;
        this.employeeDisplayData = [];
        this.employeeDisplayClockingOutForLunch.forEach((item: any, index: number) => {
            this.employeeDisplayData.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.employee.fullName} `,
                    timeIn: moment(item.clockOut).format('hh:mm:ss A'),
                    employeeId: `${item.employee.employeeId}`,
                    suspendStatus: `${item.suspendStatus}`
                }
            );
        });
        this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        this.isPopupOutForLunch = true;
        this.total = this.employeeDisplayData.length;
    }

    openOutForClockInDialog() {
        console.log("openOutForClockInDialog");
        console.log(this.employeeDisplayClockIn);
        this.popupIndex = 1;
        this.popupOutForLunchCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' },
            { field: 'timeIn', header: 'In Time' },
            { field: 'timeOut', header: 'Out Time' }
        ];
        this.modalHeaderTitle = 'Working';
        this.popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
        this.totalOutForLunchCount = this.totalClockedIn;
        this.employeeDisplayData = [];
        this.employeeDisplayClockIn.forEach((item: any, index: number) => {
            this.employeeDisplayData.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    timeIn: moment(item.clockIn).format('hh:mm:ss A'),
                    timeOut: item.clockOut != null ? moment(item.clockOut).format('hh:mm:ss A') : "",
                    employeeId: `${item.employeeId}`,
                    suspendStatus: `${item.suspendStatus}`
                }
            );
        });
        this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        this.loadingTable = false;
        this.isPopupOutForLunch = true;
        this.total = this.employeeDisplayData.length;
    }

    openOutForAbsentDialog() {
        this.userTable = true;
        this.popupIndex = 3;
        this.popupOutForLunchCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' }
        ];
        this.popupAbsentCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' }
        ];
        this.loadingTable = true;
        this.modalHeaderTitle = 'Absent';
        this.popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
        this.totalOutForLunchCount = this.totalAbsent;
        this.employeeDisplayData = [];
        this.employeeAbsentDisplayData = [];
        this.employeeDisplayClockingAbsent.forEach((item: any, index: number) => {
            this.employeeDisplayData.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    employeeId: `${item.employeeId}`,
                    suspendStatus: `${item.suspendStatus}`
                }
            );
        });
        this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        this.filteredEmployeeDisplayData2 = [...this.employeeAbsentDisplayData];
        this.loadingTable = false;
        this.isPopupOutForLunch = true;
        this.total = this.employeeDisplayData.length;

    }

    openNotLunchDialog() {
        console.log("this.employeeNotLunchList");
        console.log(this.employeeNotLunchList);
        this.popupIndex = 4;
        this.modalHeaderTitle = 'Missed Break';
        this.popupOutForLunchCols = [
            { field: 'order', header: '#' },
            { field: 'fullName', header: 'FullName' }
        ];
        this.loadingTable = true;
        this.popupOutHeader = `(${moment().format('MM/DD/YYYY')}) `;
        this.totalOutForLunchCount = this.totalNotLunch;
        this.employeeDisplayData = [];
        this.employeeAbsentDisplayData = [];
        this.employeeNotLunchList.forEach((item: any, index: number) => {
            this.employeeDisplayData.push(
                {
                    order: `${index + 1}.`,
                    fullName: `${item.fullName} `,
                    employeeId: `${item.employeeId}`,
                    suspendStatus: `${item.suspendStatus}`
                }
            );
        });
        this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        this.loadingTable = false;
        this.isPopupOutForLunch = true;
        this.total = this.employeeDisplayData.length;
    }

    loadAllTask(option?: any) {
        option = {};
        option.page = 0;
        option.size = 999;
        option.fromDate = new Date();
        option.toDate = new Date;
        this.allProjectList = [];
        this.projectsAll = [];
        this.taskManagementService.getNewProjectList().subscribe((res: any) => {
            this.allProjectList = res.data;
            this.projectsAll = this.allProjectList.filter(p => p.userId === this.authService.getCurrentLoggedInId() && p.userType && p.userType.toLowerCase() === this.taskManagementService.getUserType().toLowerCase());
            this.myTaskNumber = this.projectsAll.length;
            this.guestTaskNumber = this.allProjectList.filter(p => p.userId !== this.authService.getCurrentLoggedInId()).length;
        }, (_error) => {
            console.log(_error);
        });
    }

    getNumberOfCurrentUserOnline() {
        this.employeeService.getNumberOfCurrentUserOnline(this.authService.getCurrentCompanyId()).subscribe(res => {
          if (res.status === 'SUCCESS') {
            this.numOfCurrentLoginUser = res.data.numOfEmployee;
            this.listEmployeeOnline = res.data.listEmployeeOnline;
          }
        }, (_error) => {
          console.log(_error);
        });
      }

    getEmployeeOnline() {
        this.companyService.getOnlineUsers(this.authService.getCurrentCompanyId()).subscribe((res:any) => {
            if (res.status === 'SUCCESS') {
                this.numOfCurrentLoginUser = res.data.length;
                this.listEmployeeOnline = res.data;
            }
        }, (_error) => {
            console.log(_error);
        });
    }

    openOutForEmployeeOnlineDialog() {
        console.log("this.openOutForEmployeeOnlineDialog");
        console.log(this.listEmployeeOnline);
        this.employeeOnlineCols = [
            { field: 'fullName', header: 'Full Name' },
            { field: 'userName', header: 'User Name' },
            { field: 'type', header: 'Type' }
        ];
        this.popupUserOnlineHeader = `Online`;
        this.employeeOnlineData = [];
        if (this.listEmployeeOnline && this.listEmployeeOnline.length > 0) {
            this.listEmployeeOnline.forEach((item: any, index: number) => {
                this.employeeOnlineData.push(
                    {
                        order: `${index + 1}.`,
                        fullName: item.firstLastName ? item.firstLastName : '',
                        userName: `${item.userName} `,
                        type: `${item.userType}`,
                        employeeId: `${item.employeeId}`,
                        userId: `${item.userId}`,
                        id: `${item.id}`
                    }
                );
            });
            this.isShowUserOnline = true;
        }

    }


    async planAssignmentData() {
        const options = {
            fromDate: new Date(),
            toDate: new Date(),
            page: 0,
            size: 9999,
            companyId: this.companyId
        };

        this.horizontalOptions = {
            tooltips: {
                enabled: true
              },
            indexAxis: 'y',
            plugins: {
                legend: {
                    labels: {
                        color: '#4caf50'
                    }
                }
            },
            scales: {
             xAxes: [{
                stacked: true,
                ticks: {
                    min: 0,
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    min: 0,
                }
            }],
                x: {
                    ticks: {
                        color: '#4caf50',
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#4caf50',
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        const planData: any = await this.toDoService.dashboardSearch(options).toPromise();
        if (planData.status === 'SUCCESS') {
            this.dataPlanAssignment = {
                labels: ['Waiting For Others', 'Processing', 'Completed', 'Todo', 'Temp Stop', 'Start'],
                datasets: [
                    {
                        label: 'Waiting',
                        backgroundColor: '#6f42c1',
                        data: [planData.data.numberOfWaiting, 0, 0, 0, 0, 0]
                    },
                    {
                        label: 'Processing',
                        backgroundColor: '#007bff',
                        data: [0, planData.data.numberOfProcessing, 0, 0, 0, 0]
                    },
                    {
                        label: 'Completed',
                        backgroundColor: '#28a745',
                        data: [0, 0, planData.data.numberOfCompleted, 0, 0, 0]
                    },
                    {
                        label: 'Todo',
                        backgroundColor: '#e4e4e4',
                        data: [0, 0, 0,  planData.data.numberOfTodo, 0, 0]
                    },
                    {
                        label: 'Temp Stop',
                        backgroundColor: '#dc3545',
                        data: [0, 0, 0, 0, planData.data.numberOfTempStop, 0]
                    },
                    {
                        label: 'Start',
                        backgroundColor: '#fd7e14',
                        data: [0, 0, 0, 0, 0, planData.data.numberOfStart]
                    },
                ]
            };
         }
    }

    gotoPage(type) {
        if (type == 'task_management') {
            this.router.navigate(['/app/tm']);
        } else if (type == 'task_assignment') {
            this.router.navigate(['/app/workorder/list']);
        }
    }

    onTabChange(event: any) {
        if (event.index == 0) {
            console.log("goToAbsentUser");
            this.total = this.employeeDisplayData.length;
        }

        if (event.index == 1) {
            console.log("goToAskForLeave");
            this.total = this.employeeAbsentDisplayData.length;
        }
    }
    gotoEmployeePage(employeeId: number) {
        this.router.navigate([`/app/employee/edit/${employeeId}`]);
    }
    gotoUserPage(userId: number) {
        this.router.navigate([`/app/admin/agency-admin/edit/${userId}`]);
    }

    gotoUserPageOrEmployeePage(rowData: any) {
        if (rowData.type === 'ADMIN') {
            this.router.navigate([`/app/admin/agency-admin/edit/${rowData.userId}`]);
        } else if (rowData.employeeId) {
            this.router.navigate([`/app/employee/edit/${rowData.employeeId}`]);
        }
    }

    getMyAssignments() {
        if (!this.isCEOAdmin()) {
            const options = {
                companyId: this.authService.getCurrentCompanyId(),
                size: 10,
                page: 0
            };
            this.operatorService.searchTodo(options, true).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.totalAssignments = resObj.data.totalElements;
                }
            }, (_error) => {
                console.log(_error);
            });
        }
    }

    refreshJobReportConcerns() {
        this.reportSize = 10;
        this.reportPage = 0;
        const options: any = {};
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        this.loadJobReport(options, 'concerns');
    }

    refreshJobReport() {
        this.reportSize = 10;
        this.reportPage = 0;
        const options: any = {};
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        this.loadJobReport(options, 'JobReport');
    }

      loadDataSourceJobReport(event?: any): void {
    this.reportSize = event && event.rows ? event.rows : this.reportSize;
    this.reportPage = event && event.first && event.rows ? (event.first / event.rows) : this.reportPage;
    if (event && (event.first / event.rows) === 0) {
      this.reportPage = 0;
    }
    const options: any = {
      sortField: 'createdAt',
      sortOrder: 'DESC'
    };
    this.loadJobReport(options, 'all');
  }

  loadJobReport(optionSort: any, type: string): void {
    this.loadingJobReports = true;
    const options: any = {
      size: this.reportSize,
      page: this.reportPage,
      sortOrder: optionSort.sortOrder,
      sortField: optionSort.sortField,
      companyId: this.authService.getCurrentCompanyId()
    };
    this.employeeJobReportService.search(options).subscribe((data: any) => {
      if (type === 'all') {
        this.jobReports = data.data.content;
        this.jobReportsConcerns = data.data.content.filter((item) => item.concern !== null);
        this.jobReports.forEach(item => {
          item.latestNote = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].note : '';
          item.time = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].notesTime : '';
        });
      } else if (type === 'concerns') {
        this.jobReportsConcerns = data.data.content.filter((item) => item.concern !== null);
      } else {
        this.jobReports = data.data.content;
        this.jobReportsConcerns = data.data.content.filter((item) => item.concern !== null);
        this.jobReports.forEach(item => {
          item.latestNote = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].note : '';
          item.time = item.jobReportNotes && item.jobReportNotes.length > 0 ? item.jobReportNotes[item.jobReportNotes.length - 1].notesTime : '';
        });
      }
      this.loadingJobReports = false;
      this.updatePaginatedConcerns();
    }, () => {
      this.loadingJobReports = false;
    });
  }

    getAllEmployeeLatestNote() {
        this.employeeJobReportNoteService.getAllEmployeeLatestNote(this.authService.getCurrentCompanyId()).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.jobReports = resObj.data;
            } else {
                this.jobReports = [];
            }
        });
    }

    loadConcern(data) {
        this.selectedReport = data;
        this.employeeJobReportService.getEmployeeJobReportById(data.id).subscribe((resObj) => {
            this.fullSelectedReport = resObj.data;
            this.selectedReport = resObj.data;
        });
        this.showConcern = true;
    }

    isReplying = false;
    addReply() {
        if (!this.newReply) { return; }
        if (this.isReplying) { return; }
        this.isReplying = true;
        const data = {
            userName:this.authService.getCurrentUsername(),
            reply: this.newReply,
            reportJobId: this.selectedReport.id
        };
        this.employeeJobReportService.adminAddReply(data).subscribe((resObj) => {
            this.isReplying = false;
            this.fullSelectedReport = resObj.data;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Reply successfully' });
            this.showConcern = false;
        }, error => {
            this.isReplying = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Reply failed.' });
        });
    }
    gotoJobReport() {
        this.router.navigate(['/app/employee/employee-job-report/list']);
    }

    openJobReportSummaryDialog(type: any) {
        this.isPopupJobReportSummary = true;
        this.changeTypeJobReport(type);
    }

    openLateness() {
        this.isPopupLateness = true;
        this.popupLatenessCols = [
            { field: 'id', header: 'ID' },
            { field: 'fullName', header: 'FullName' }
        ];
    }
    changeTypeJobReport(type: any) {
        this.showSumary = false;
        this.popupJobReportSummaryCols = [
            { field: 'order', header: '#' },
            { field: 'name', header: 'Full Name' },
            { field: 'missedReport', header: 'Count' }
        ];
        this.popupOutHeader = `Job Report Summary: (${moment().format('MM/DD/YYYY')}) `;
        this.popupJobReportSummaryData = [];
        const currentTime = moment(new Date()).format('YYYY-MM-DD');

        if (type == 'miss') {
            this.popupIndexSummary = 1;
            this.employeeDisplayMissedJobReport.forEach((item: any, index: number) => {
                let listMiss = "";
                let totalMiss = 0;
                item.listDataMiss.forEach((product: any) => {
                    const hhmm = product.timeNote.split(' - ');
                    const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
                    const endTime =  moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
                    const currTime = moment().unix() * 1000;

                    if ((currTime - endTime > 0 && product.status != "Done") || product?.noteReason != null) {
                        product.status = "Missed";
                        listMiss += `${product.timeNote}: ${product.status} \n`;
                        totalMiss += 1;
                    }
                });
                this.popupJobReportSummaryData.push(
                {
                    order: `${index + 1}.`,
                    name: `${item.name} `,
                    missedReport: totalMiss,
                    listMissed: listMiss,
                    employeeId: item?.employeeId,
                    suspendStatus: item?.suspendStatus
                }
                );
            });

        } else {
            this.popupIndexSummary = 2;
            this.showSumary = true;
            this.employeeDisplayMissedJobReportMoreThanLimit.forEach((item: any, index: number) => {
                let listMiss = "";
                let totalMiss = 0;

                if (item.listDataMiss.length >= this.jobReportMissingLimit) {
                    item.listDataMiss.forEach((product: any) => {
                        const hhmm = product.timeNote.split(' - ');
                        const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
                        const endTime =  moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
                        const currTime = moment().unix() * 1000;
    
                        if ((currTime - endTime > 0 && product.status != "Done") || product?.noteReason != null) {
                            product.status = "Missed";
                            listMiss += `${product.timeNote}: ${product.status} \n`;
                            totalMiss += 1;
                        }
                    });
    
                    this.popupJobReportSummaryData.push({
                        order: `${index + 1}.`,
                        name: `${item.name} `,
                        missedReport: totalMiss,
                        listMissed: listMiss,
                        employeeId: item?.employeeId,
                    });
                }
            });
        }
    }
    getMissedJobReport() {
        const options = {
          companyId: this.authService.getCurrentCompanyId(),
        };
        this.employeeJobReportService.getMissedJobReport(options).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            this.employeeDisplayMissedJobReport = resObj.data.lstMissJobReport;
            this.employeeDisplayMissedJobReportMoreThanLimit = resObj.data.lstMissJobReportThanLimit;
            this.totalMissJobReport = this.employeeDisplayMissedJobReport.length;
            this.totalMissThanLimit = this.employeeDisplayMissedJobReportMoreThanLimit.length;
          }
        });
    }

    loadDataLateness() {
        let start = new Date();
        let end = new Date();
        this.complianceFilter.fromDate = start.getTime();
        this.complianceFilter.toDate = end.getTime();
        this.complianceFilter.companyId = this.authService.getCurrentCompanyId();
        this.complianceFilter.size = 1;
        this.complianceFilter.page = 0;
        this.complianceFilter.timeZone = moment.tz.guess();
        let dataRsp:any = {};
        this.loadingLateness = true;
        this.employeeClockingService.getComplianceReportNew(this.complianceFilter).subscribe(res => {
            if (res.status === 'SUCCESS') {
                dataRsp = res.data;
                if (dataRsp?.daywiseCompliance?.length != 0 && dataRsp.daywiseCompliance[0].lateIds?.length > 0) {
                    let option = {
                        ids: dataRsp.daywiseCompliance[0]?.lateIds,
                        page: 0,
                        size: dataRsp.daywiseCompliance[0]?.lateIds.length,
                        status: 1,
                    }

                    this.emmployeeService.filterByCompanyNew(option).subscribe(res1 => {
                        this.loadingLateness = false;
                        this.totalLateness = res1?.data?.content?.length;
                        this.popupLatenessData = [];
                        res1?.data?.content.forEach((item: any, index: number) => {
                            const comeIn = ((dataRsp.daywiseCompliance || []).length && dataRsp.daywiseCompliance[0].comeInMap) ?
                                dataRsp.daywiseCompliance[0].comeInMap[item.id] : new Date();
                            this.popupLatenessData.push(
                                {
                                    order: `${index + 1}.`,
                                    fullName: `${item.fullName} `,
                                    id: `${item.id} `,
                                    employeeId: `${item.id} `,
                                    suspendStatus: `${item.suspendStatus}`,
                                    comeIn: moment(comeIn).format('HH:mm a')
                                }
                            );
                        });
                    }, error => {
                        console.error('Error filter Employee By CompanyNew');
                        this.loadingLateness = false;
                    });
                } else {
                    this.loadingLateness = false;
                }
            } else {
                this.loadingLateness = false;
            }
        }, error => {
            console.error('Error Fetching Compliance');
            this.loadingLateness = false;
        });
    }

    showModalSuspend(fullName, employeeId) {
        console.log("Show Modal Suspend: ", this.popupLatenessData)
        console.log(employeeId);
        this.suspendUser = new SuspendUser();
        this.suspendUser.unit = 1;
        this.suspendUser.suspendAt = new Date();
        this.suspendUser.employeeId = employeeId;
        this.suspendUser.userName = fullName;
        this.isShowPopupSuspend  = true;
        this.dataSelectedSuspend = fullName;
    }

    showModalUnSuspend(fullName, employeeId) {
        this.suspendUser.suspendAt = new Date();
        this.suspendUser.employeeId = employeeId;
        this.isShowPopupUnSuspend  = true;
        this.messageConfirmUnsuspendUser = fullName + 'has been suspended. Would you like to unsuspended this employee?';
    }

    submitSuspendUser(type: number) {
        const requestSuspend: any = {};
        requestSuspend.employeeId = this.suspendUser.employeeId;
        requestSuspend.unit = this.suspendUser?.unit;
        requestSuspend.unit = requestSuspend?.unit?.value;
        requestSuspend.time = this.suspendUser.time;
        requestSuspend.startDateSuspend = new Date(this.suspendUser?.suspendAt);
        requestSuspend.status = type;
        requestSuspend.userName = this.suspendUser.userName;
        requestSuspend.reason = this.suspendUser.reason;
        requestSuspend.companyId = this.authService.getCurrentCompanyId();

        if (requestSuspend.time == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'For how many is required' });    
            return;        
        }
        if (requestSuspend.unit == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unit is required' });            
            return;
        }
        if (requestSuspend.startDateSuspend == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Date suspend is required' });            
            return;
        }
        
        this.emmployeeService.suspendUser(requestSuspend).subscribe(res => {
            if (res.status == "SUCCESS") {
                // this.isPopupOutForLunch = false;
                this.employeeDisplayData.forEach((item: any, index: number) => {
                    if (item.employeeId === this.suspendUser.employeeId) {
                        item.suspendStatus = "SUSPEND";
                    }
                });
                this.loadStat();
                this.loadDataLateness();
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Suspend User Success' });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Suspend User failed.' });            
            }
        });
        this.isShowPopupSuspend = false;
    }

    submitUnsuspendUser() {
        const requestSuspend: any = {};
        requestSuspend.employeeId = this.suspendUser.employeeId;

        this.emmployeeService.unsuspendUser(requestSuspend).subscribe(res => {
            if (res.status == "SUCCESS") {
                // this.isPopupOutForLunch = false;
                this.employeeDisplayData.forEach((item: any, index: number) => {
                    if (item.employeeId === this.suspendUser.employeeId) {
                        item.suspendStatus = null;
                    }
                });
                this.loadStat();
                this.loadDataLateness();
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Unsuspend User Success' });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unsuspend User failed.' });            
            }
        }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unsuspend User failed.' });
        });
        this.isShowPopupUnSuspend = false;
    }

    loadPayrollSetting() {
        this.payrollSettingService.getbyId(this.companyId).subscribe((res: any) => {
            if (res.status == "SUCCESS") {
                this.jobReportMissingLimit = res.data?.jobReportMissingLimit;
                this.beyondLimitationDecision = res.data?.beyondLimitationDecision;
                this.employeeLatenessDecision = res.data?.employeeLatenessDecision;
            }
        });
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
            return false;
        }
        return true;

    }


    updatePaginatedConcerns() {
        const start = this.currentPage * this.pageSize;
        const end = start + this.pageSize;
        this.paginatedConcerns = this.jobReportsConcerns.slice(start, end);
      }


    nextPage() {
        if ((this.currentPage + 1) * this.pageSize < this.jobReportsConcerns.length) {
          this.currentPage++;
          this.updatePaginatedConcerns();
        }
      }

      prevPage() {
        if (this.currentPage > 0) {
          this.currentPage--;
          this.updatePaginatedConcerns();
        }
      }

      toggleComplianceGraph() {
        this.complianceScores = !this.complianceScores;
      }

      onSearchChange(searchValue: string) {
        this.textSearch = searchValue.toLowerCase();
        if (this.textSearch) {
          this.filteredEmployeeDisplayData = this.employeeDisplayData.filter(employee =>
            employee.fullName.toLowerCase().includes(this.textSearch)
          );
        } else {
          this.filteredEmployeeDisplayData = [...this.employeeDisplayData];
        }
      }

      onSearchChangeTimeOff(searchValue: string) {
        this.textSearch = searchValue.toLowerCase();
        if (this.textSearch) {
          this.filteredEmployeeDisplayData2 = this.employeeAbsentDisplayData.filter(employee =>
            employee.fullName.toLowerCase().includes(this.textSearch)
          );
        } else {
          this.filteredEmployeeDisplayData2 = [...this.employeeAbsentDisplayData];
        }
      }

    onTopbarMenuProfileClick(user) {
        // this.router.navigate(['app/admin/platform-admin/view/1']);
        console.log(user);
        const userId = user.userId;
        const roleLevel = user.type;
        if (roleLevel === 'Admin') {
            this.router.navigate(['app/admin/platform-admin/view/' + userId]);
        }
        if (roleLevel === 'Employee') {
            this.router.navigate(['app/employee/view/' + userId]);
        }
        if (roleLevel === 'Freelancer') {
            this.router.navigate(['app/crm/freelancer/edit/' + userId]);
        }
        if (roleLevel === 'Client') {
            this.router.navigate(['app/crm/client/edit/' + userId]);
        }
    }
}
