import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../../shared/service/auth/auth.service";
import {AesUtilService} from "../../../shared/service/aes-util.service";
import {PaymentProfileService} from "../../service/payment-profile.service";

@Component({
  selector: 'app-transfer-card',
  templateUrl: './transfer-card.component.html',
  styleUrls: ['./transfer-card.component.scss']
})
export class TransferCardComponent implements OnInit {
    
    @Output()
    displayChange = new EventEmitter();
    
    transferForm: UntypedFormGroup;
    accountTypes = [
        {label: 'Checking', value: 'checking'},
        {label: 'Savings', value: 'savings'},
        {label: 'Business Checking', value: 'businessChecking'}
    ]
    
    @Input()
    paymentCardType;
    
    registerCustomerPaymentId = null;
    cardToken = null;
    
    messageError: string = '';
    requiredValidate = false;
    constructor(private fb: UntypedFormBuilder,
                private authService: AuthService,
                private aesUtilService: AesUtilService,
                private paymentProfileService: PaymentProfileService,) { }
    
    ngOnInit() {
        this.transferForm = this.fb.group({
            accountName: [null, [Validators.required]],
            accountType: [null, [Validators.required]],
            roundNumber: [null, [Validators.required]],
            bankName: [null, [Validators.required]],
            bankAccountNumber: [null, [Validators.required]],
            startDate: [null, [Validators.required]],
            endDate: [],
            payAmount: [null, [Validators.required]],
            payPercentage: [null, [Validators.required]]
        });
        this.getPaymentProfile(this.authService.getUserInfo().userId);
    }
    
    verify() {
        if (this.transferForm.get('roundNumber').value) {
            this.getRountingNumber(this.transferForm.get('roundNumber').value)
        }
    }
    
    save() {
        const formValues: any = this.transferForm.getRawValue();
        
        if (this.transferForm.invalid) {
            this.requiredValidate = true;
        }
        if (!formValues.startDate) {
            this.transferForm.get('startDate').setValue(null);
            return;
        }
        
        if (!formValues.payAmount) {
            return;
        }
        
        if (!formValues.payPercentage) {
            return;
        }
        const payAmount = formValues.payAmount ? formValues.payAmount : '0';
        const payPercent = formValues.payPercentage ? formValues.payPercentage : '0';
        const cardDataStr = `accountName=${formValues.accountName};accountType=${formValues.accountType};roundNumber=${formValues.roundNumber};
    bankName=${formValues.bankName};bankAccountNumber=${formValues.bankAccountNumber};startDate=${formValues.startDate};
    endDate=${formValues.endDate};payAmount=${payAmount};payPercentage=${payPercent};`;
        const encryptData = this.aesUtilService.encrypt(cardDataStr);
        
        // 1. Account Name
        // 2. Account Type
        // 3. Routing number
        // 4. Bank Name
        // 5. Bank Account
        // 6. Start Date
        // 7. End Date
        // 8. Pay amount
        // 9. Pay percent
        
        const customer = this.authService.getUserInfo();
        
        const payload = {
            customerRegisterPaymentId: this.registerCustomerPaymentId,
            paymentCardType: this.paymentCardType,
            ownerId: customer.userId,
            gatewayType: 'AUTHORIZE_NET',
            encryptData: encryptData,
            userName: this.authService.getUserInfo().userName,
            securelyStoreCard: formValues.securelyStoreCard,
            transferAccount: true,
        };
        
        this.paymentProfileService.createPaymentProfile(payload).subscribe(response => {
            if (response) {
                this.cardToken = (response as any).cardToken;
            }
        })
    }
    
    getPaymentProfile(userId) {
        this.paymentProfileService.getPaymentProfile(this.paymentCardType, userId, 'AUTHORIZE_NET').subscribe(response => {
            if (response) {
                this.cardToken = (response as any).cardToken;
                //this.cardNumber = 'XXXX-XXXX-XXX-' + (response as any).lastFourNumbers;
                //this.cardTokenCode = (response as any).cardTokenCode;
            }
        })
    }
    
    cancel(): void {
        this.displayChange.emit(true);
    }
    
    onBack(){
        this.displayChange.emit(true);
    }
    
    getRountingNumber(rountingNumber) {
        this.paymentProfileService.getBankName(rountingNumber).subscribe((rs: any) => {
            if (rs && rs.code && rs.code === 200) {
                this.transferForm.get('bankName').setValue(rs.customer_name);
            } else {
                this.messageError = 'You have incorrect Round Number';
            }
        })
    }

}
