declare let google: any;

export class PolygonUtils {

  public static colors = ['red', 'black', 'brown', 'green', 'blue', 'yellow', 'purple '];

  public static defaultColorMapping = {
    manual_created_zones: '#4CAF50',
    block_level_zones: '#000000',
    state_assembly_districts: '#F44336',
    us_congressional_districts: '#00008B',
    state_senate_districts: '#006400',
    municipal_court_districts: '#1E90FF',
    city_council_districts: '#8B4513',
    election_districts: '#FFA500',
    zipcode: '#FF8F00',
  }

  public static zoneTypeOption = [
    { label: 'Manually Created Zones', value: 'manual_created_zones' },
    { label: 'Block Level Zones', value: 'block_level_zones'},
    { label: 'State Assembly Districts', value: 'state_assembly_districts' },
    { label: 'US Congressional Districts', value: 'us_congressional_districts' },
    { label: 'State Senate Districts', value: 'state_senate_districts' },
    { label: 'Municipal Court Districts', value: 'municipal_court_districts' },
    { label: 'City Council Districts', value: 'city_council_districts' },
    { label: 'Election Districts', value: 'election_districts' },
    { label: 'Zip Code', value: 'zipcode' }
  ];

  public static convertToFeatureData(manualZone) {
    const color = manualZone.color || '#28a745';
    let title = manualZone.label;
    const coordinates: any[] = [];
    if (manualZone.multiPolygons && manualZone.multiPolygons.length > 0) {
      manualZone.multiPolygons.forEach(polygon => {
        const polygonCoordinates: any[] = [];
        if (polygon.polygon && polygon.polygon.length > 0) {
          const arr: any[] = [];
          polygon.polygon.forEach((coordinate: any) => {
            arr.push([coordinate.x, coordinate.y])
          });
          if (arr.length > 0) {
            arr.push(arr[0]);
            polygonCoordinates.push(arr);
          }
        }
        if (polygonCoordinates.length > 0) {
          coordinates.push(polygonCoordinates);
        }

      });
    }
    if (coordinates.length > 0) {
      return {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: coordinates
        },
        properties: {
          zoneId: manualZone.id,
          title: title,
          color: color,
          label: title,
          labelName: title,
        }
      };
    }
    return null;
  }

  public static convertToGeoJsons(zoneList, isConvertMissingFeature?) {
    if (!zoneList || !zoneList.length) {
      return null;
    }
    const zonePolygons = new Map<string, any>();
    const zoneFeatures: any[] = [];
    zoneList.forEach((zone: any) => {
      if (!zone.color) {
        zone.color = PolygonUtils.defaultColorMapping[zone.type];
      }
      if (!zone.feature && isConvertMissingFeature) {
        const feature = PolygonUtils.convertToFeatureData(zone);
        zone.feature = feature;
        if (zone.feature && zone.feature.properties) {
            zone.feature.properties['color'] = zone.color;
        }
        zoneFeatures.push(zone);
      } else {
        if (zone.subZones && zone.subZones.length > 0) {
          zoneFeatures.push(zone);
        } else if (zone.feature) {
          zone.feature.properties['color'] = zone.color;
          if (!zone.feature.properties['type']) {
            zone.feature.properties['type'] = 'manual_created_zones';
          }
          if (!zone.type) {
            zone.type = 'manual_created_zones';
          }
          if (zone.subZone) {
            zone.feature.properties['zoneId'] = zone.zoneId;
            zone.feature.properties['subZoneId'] = zone.id;
            zone.feature.properties['labelName'] = zone.title;
          }
          zoneFeatures.push(zone);
        } else {
          const feature = PolygonUtils.convertToFeatureData(zone);
          zone.feature = feature;
          if (zone.feature && zone.feature.properties) {
              zone.feature.properties['color'] = zone.color;
          }
          zoneFeatures.push(zone);
        }
      }
    });
    if (zoneFeatures.length > 0) {
      zoneFeatures.forEach(zoneFeature => {
        let zonePolygon = zonePolygons.get(zoneFeature.type);
        if (!zonePolygon) {
          zonePolygon = {
            type: zoneFeature.type,
            geoJson: {
              type: 'FeatureCollection',
              features: []
            }
          }
          zonePolygons.set(zoneFeature.type, zonePolygon);
        }
        if (zoneFeature.subZones && zoneFeature.subZones.length > 0) {
          zoneFeature.subZones.forEach(subZone => {
            if (typeof subZone.feature === 'string') {
              subZone.feature = JSON.parse(subZone.feature);
            }
            const f = subZone.feature;
            f.properties['zoneId'] = subZone.zoneId;
            f.properties['subZoneId'] = subZone.id;
            f.properties['type'] = 'manual_created_zones';
            f.properties['title'] = zoneFeature.title + ' - ' + subZone.title;
            f.properties['labelName'] = subZone.title;
            zonePolygon.geoJson.features.push(f);
          });
        } else {
          zonePolygon.geoJson.features.push(zoneFeature.feature ?? zoneFeature);
        }
      });
    }
    const result = Array.from(zonePolygons.values());
    result.forEach(zone => {
      if (zone.geoJson && zone.geoJson.features) {
          zone.geoJson.features = zone.geoJson.features.filter(f => f != null);
        zone.geoJson.features.forEach(f => {
          f.id = null;
          if (f.geometry && f.geometry.coordinates) {
            const arr1 = f.geometry.coordinates[0];
            if (typeof arr1[0][0] === 'number') {
              if (arr1[0][0] !== arr1[arr1.length - 1][0]) {
                f.geometry.coordinates[0][0].push(arr1[0]);
              }
            } else {
              if (arr1[0][0][0] !== arr1[0][arr1[0].length - 1][0]) {
                f.geometry.coordinates[0][0].push(arr1[0][0]);
              }
            }
          }
        });
      }
    });
    return result;
  }

  public static loadGeoJson(map, zonePolygonGeoJson, editable?, featureDbClicked?: Set<string>) {
    if (!map || !zonePolygonGeoJson || zonePolygonGeoJson.length === 0) {
      return null;
    }
    console.log('Start loadGeoJson: ', new Date());
    
    const allBounds: any = new google.maps.LatLngBounds();
    const zoneFeatures: any[] = [];
    zonePolygonGeoJson.forEach(data => {
      const features = map.data.addGeoJson(data.geoJson);
      if (features) {
        features.forEach(feature => {
          feature.setProperty('type', data.type);
          let dbClicked = false;
          if (featureDbClicked) {
            const key = PolygonUtils.buildFeatureKey(feature);
            if (key && featureDbClicked.has(key)) {
              dbClicked = true;
            }
          }

          if (data.type === 'manual_created_zones') {
            map.data.overrideStyle(feature, {
              strokeColor: feature.getProperty('color'),
              strokeWeight: dbClicked ? 3 : 2,
              strokeOpacity: 0.8,
              fillColor: feature.getProperty('color'),
              fillOpacity: dbClicked ? 0.8 : 0.35,
              editable: editable,
              draggable: editable
            });
          } else {
            map.data.overrideStyle(feature, {
              strokeColor: feature.getProperty('color'),
              strokeWeight: dbClicked ? 3 : 2,
              strokeOpacity: 0.8,
              fillColor: feature.getProperty('color'),
              fillOpacity: dbClicked ? 0.8 : 0.35,
              editable: editable
            });
          }
          feature.getGeometry().forEachLatLng(coordinate => {
            allBounds.extend(new google.maps.LatLng(coordinate.lat(), coordinate.lng()));
          });
        });
        zoneFeatures.push(features);
      }
    });
    map.fitBounds(allBounds);
    console.log('End loadGeoJson: ', new Date());
    return zoneFeatures;
  }

  public static openFeatureInfoWindow(map, feature, polygonInfoWindow) {
    if (polygonInfoWindow) {
      polygonInfoWindow.close();
    }
    const geometry = feature.getGeometry();
    let center;
    if (geometry.getType() === 'MultiPolygon') {
      const bounds = new google.maps.LatLngBounds();
      geometry.getArray().forEach((polygon) => {
        polygon.getArray().forEach((path) => {
          path.getArray().forEach((latLng) => {
            bounds.extend(latLng);
          });
        });
      });
      center = bounds.getCenter();
    } else if (geometry.getType() === 'Polygon') {
      const bounds = new google.maps.LatLngBounds();
      geometry.getArray().forEach((path) => {
        path.getArray().forEach((latLng) => {
          bounds.extend(latLng);
        });
      });
      center = bounds.getCenter();
    } else if (geometry.getType() === 'Point') {
      center = geometry.get();
    }

    const type = feature.getProperty('type');
    if (type === 'manual_created_zones') {
      const title = feature.getProperty('title');
      let contentString = `
      <div id="content" style="opacity: 0.9; filter: alpha(opacity=90);">
        <b style="font-weight: 600;">${type} : ${title}</b><br>
      </div>`;
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Uluru",
        position: center
      });

      infowindow.open({
        map: map as any
      });
      polygonInfoWindow = infowindow;
    } else {
      const labelName = feature.getProperty('labelName');
      let contentString = `
      <div id="content" style="opacity: 0.9; filter: alpha(opacity=90);">
        <b style="font-weight: 600;">${type} : ${labelName}</b><br>
      </div>`;
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Uluru",
        position: center
      });

      infowindow.open({
        map: map as any
      });
      polygonInfoWindow = infowindow;
    }
    return polygonInfoWindow;
  }

  public static convertCoordinatesToMultiPolygons(coordinates) {
    const multiPolygons = [];
    coordinates.forEach(coordinate => {
      const polygons = [];
      coordinate.forEach(location => {
        if (location.length > 0) {
          if (typeof location[0] === 'number') {
            polygons.push({
              x: location[0],
              y: location[1]
            });
          } else {
            location.forEach(l => {
              if (typeof l[0] === 'number') {
                polygons.push({
                  x: l[0],
                  y: l[1]
                });
              } else {
                l.forEach(l2 => {
                  polygons.push({
                    x: l2[0],
                    y: l2[1]
                  });
                })
              }

            });
          }
        }


      });
      multiPolygons.push({
        polygon: polygons
      });
    });
    return multiPolygons;
  }

  public static convertToMultiPolygons(geoJson) {
    const coordinates = geoJson.geometry.coordinates;
    const multiPolygons = [];
    coordinates.forEach(coordinate => {
      const polygons = [];
      coordinate.forEach(location => {
        if (location.length > 0) {
          if (typeof location[0] === 'number') {
            polygons.push({
              x: location[0],
              y: location[1]
            });
          } else {
            location.forEach(l => {
              polygons.push({
                x: l[0],
                y: l[1]
              });
            });
          }
        }


      });
      multiPolygons.push({
        polygon: polygons
      });
    });
    return multiPolygons;
  }

  public static geometryToGeoJSON(geometry) {
    let type = geometry.getType();
    let coordinates = [];

    switch (type) {
      case "Point":
        coordinates = [geometry.get().lng(), geometry.get().lat()];
        break;
      case "LineString":
      case "MultiPoint":
        coordinates = geometry.getArray().map((coord) => {
          return [coord.lng(), coord.lat()];
        });
        break;
      case "Polygon":
      case "MultiLineString":
        coordinates = [geometry.getArray().map((lineString) => {
          return lineString.getArray().map((coord) => {
            return [coord.lng(), coord.lat()];
          });
        })];
        const arr1 = coordinates[0];
        if (typeof arr1[0][0] === 'number') {
          if (arr1[0][0] !== arr1[arr1.length - 1][0]) {
            coordinates[0][0].push(arr1[0])
          }
        } else {
          if (arr1[0][0][0] !== arr1[0][arr1[0].length - 1][0]) {
            coordinates[0][0].push(arr1[0][0]);
          }
        }

        break;
      case "MultiPolygon":
        coordinates = geometry.getArray().map((polygon) => {
          return polygon.getArray().map((lineString) => {
            return lineString.getArray().map((coord) => {
              return [coord.lng(), coord.lat()];
            });
          });
        });
        const arr2 = coordinates[0][0];
        if (typeof arr2[0][0] === 'number') {
          if (arr2[0][0] !== arr2[arr2.length - 1][0]) {
            coordinates[0][0].push(arr2[0])
          }
        } else {
          if (arr2[0][0][0] !== arr2[0][arr2[0].length - 1][0]) {
            coordinates[0][0].push(arr2[0][0]);
          }
        }

        break;
    }
    return {
      "type": type,
      "coordinates": coordinates
    };
  }



  public static isCreateNew(zoneData) {
    return !zoneData || !(zoneData.zoneKey || zoneData.subZone)
  }

  public static buildFeatureKey(feature) {
    const type = feature.getProperty('type');
    if (!type) {
      return null;
    }
    let zoneKey;
    let subZoneKey;
    if (type === 'manual_created_zones') {
      zoneKey = feature.getProperty('zoneId');
      subZoneKey = feature.getProperty('subZoneId');
    } else {
      zoneKey = feature.getProperty('OBJECTID');
    }
    let key;
    if (zoneKey) {
      key = type + '@' + zoneKey;
    }
    if (key && subZoneKey) {
      key += '@' + subZoneKey;
    }
    return key;
  }

  public static filterNoise(locations: any[], distanceThreshold: number = 50): any[] {
    if (!locations || locations.length === 0) {
      return [];
    }
    const filteredLocations = [locations[0]];
    for (let i = 1; i < locations.length; i++) {
      const previousLocation = filteredLocations[filteredLocations.length - 1];
      const currentLocation = locations[i];
      if (currentLocation.evvType === 'Walker/questionnaire') {
        continue
      }
      if (currentLocation.evvType !== 'LIVE UPDATE' && currentLocation.evvType !== 'LIVE_UPDATE') {
        filteredLocations.push(currentLocation);
        continue;
      }
      const distance = this.calculateDistance(previousLocation, currentLocation);
      if (distance >= distanceThreshold) {
        filteredLocations.push(currentLocation);
      }
    }
  
    return filteredLocations;
  }

  public static calculateDistance(pointA: any, pointB: any): number {
    const lat1 = pointA.lat;
    const lon1 = pointA.lng;
    const lat2 = pointB.lat;
    const lon2 = pointB.lng;
  
    const R = 6371e3;
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
  
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }
}