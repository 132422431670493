import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { EmploymentAuthorization } from '../model/EmploymentAuthorization.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment, environmentLocal } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EmploymentAuthorizationService extends AbstractService<EmploymentAuthorization>{
  url: string;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.EMPLOYEE, '');
  }

  public getEmploymentAuthorizationByEmployeeId(id: number): Observable<EmploymentAuthorization> {
    return this._http.get<EmploymentAuthorization>(`${this.baseUrlV2}${id}/authorization`).pipe(map(res => res));
  }


  public getI9Documents1(): Observable<any[]> {
    return this._http.get<any[]>(`${this.baseUrlV2}i9-documents1`).pipe(map(res => res));
  }

  public getI9Documents2(): Observable<any[]> {
    return this._http.get<any[]>(`${this.baseUrlV2}i9-documents2`).pipe(map(res => res));
  }

  public getCriminalResults(): Observable<any[]> {
    return this._http.get<any[]>(`${this.baseUrlV2}criminal-results`).pipe(map(res => res));
  }

  public createAuthorization(id: any, entity: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}${id}/authorization`, entity).pipe(map(res => res));
  }

}
