import { Injectable } from '@angular/core';
import { Page } from '../../shared/model/response';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { ContactInfoMapping } from '../model/contact-info-mapping';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AbstractServiceV2} from '../../shared/service/abstract.v2.service';
import {AgencyAdminSearch} from '../../shared/model/agency.admin.search';
import {ContactInfo} from '../model/contact-info';


@Injectable({ providedIn: 'root' })
export class ContactInfoMappingService extends AbstractServiceV2<ContactInfoMapping, AgencyAdminSearch> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CONTACT_INFO_MAPPING, '');
  }

  getAllByEntityIds(entityIds: number[], entity: string): Observable<ContactInfoMapping[]> {
    // return this.http.post<any>(`http://localhost:8003/api/v2/contact-mapping/byEntity?entity=${entity}`, entityIds).pipe(map(resp => resp));
    return this._http.post<any>(`${this.baseUrlV2}/byEntity?entity=${entity}`, entityIds).pipe(map(resp => resp));
  }

  getAllByEntityId(entityId: number, params: any): Observable<ContactInfoMapping[]> {
    return this._http.get<any>(`${this.baseUrlV2}/byEntity/${entityId}`, { params: params }).pipe(map(resp => resp));
  }

  searchChanges(params: any): Observable<Page<any>> {
    return this._http.post<Page<any>>(`${this.baseUrlV2}/history/search`, params).pipe(map(resp => resp));
  }

  saveHistoryContactInfo(data) {
    return this._http.post(`${this.baseUrlV2}/history`, data).pipe(map(resp => resp));
  }

  updateContact(entity: ContactInfoMapping): Observable<ContactInfoMapping> {
    return this._http.put<ContactInfoMapping>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }
}
