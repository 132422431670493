import { ProjectDetailComponent } from './component/project-detail/project-detail.component';
import { BoardProjectComponent } from './component/board-project/board-project.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { TaskLayoutComponent } from './component/task-layout/task-layout.component';
import { NgModule } from '@angular/core';

export const TaskManagementRoutes: Routes = [
    {
        path: 'app/task-management',
        component: TaskLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: BoardProjectComponent },
            { path: ':id', component: ProjectDetailComponent }
        ]
    },
    {
        path: 'app/tm',
        component: TaskLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: BoardProjectComponent },
            { path: ':id', component: ProjectDetailComponent }
        ]
    }
];

export const TaskManagementRoutingModule = RouterModule.forChild(TaskManagementRoutes);
