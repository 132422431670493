import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { MonitoringDetailsService } from '../../admin/process-monitor/service/monitoring-details.service';
import { Constants } from '../model/constants';
import { MonitoringActionModel } from '../model/monitoring-action.model';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class FirebaseNotificationService {

    private readonly actions: MonitoringActionModel[] = [];
    notificationUrl;

    constructor(private httpClient: HttpClient) {
        this.notificationUrl = `${environment.v2_server_backend}/${Constants.ROUTES.NOTIFICATION}`;
    }

    saveFirebaseNotification(rootPath: string, data: any) {
        const payload = {
          rootPath: rootPath,
          data: data
        };
        return this.httpClient.post(`${this.notificationUrl}/firebase/save`, payload);
        // return this.httpClient.post(`http://localhost:8009/api/v2/notification/firebase/save`, payload);
    }

    pushFirebaseNotification(rootPath: string, data: any) {
        const payload = {
          rootPath: rootPath,
          data: data
        }
        return this.httpClient.post(`${this.notificationUrl}/firebase/push`, payload);
    }

    getFirebase(rootPath: string) {
        const payload = {
            rootPath: rootPath
        };
        return this.httpClient.post(`${this.notificationUrl}/firebase/getNotification`, payload);
        // return this.httpClient.post(`http://localhost:8009/api/v2/notification/firebase/save`, payload);
    }
}
