import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PayrollSettingService } from 'app/payroll-setting/service/payroll-setting.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-payroll-setting-other-history',
  templateUrl: './payroll-setting-other-history.component.html',
  styleUrls: ['./payroll-setting-other-history.component.scss']
})
export class PayrollSettingOtherHistoryComponent implements OnInit {

  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false },
    { field: 'userName', label: 'User', sortable: false },
    { field: 'oldValue', label: 'Old Value', sortable: false },
    { field: 'newValue', label: 'New Value', sortable: false },
    { field: 'action', label: 'Action', sortable: false }
  ];

  fieldsOther: any[] = [
    { value: 'employeeLateAfterHours', label: 'Lateness Grace Period' },
    { value: 'employeeLateLimit', label: 'Lateness limitation #' },
    { value: 'employeeLatenessDecision', label: 'Lateness Decision' },
    { value: 'alertEmployeeEndTime', label: 'Alert Employee End Time' },
    { value: 'frequency', label: 'Frequency' },
    { value: 'sendAlertTo', label: 'Alert Recipient' },
    { value: 'allowEmployeePTO', label: 'Allow Employee to see PTO' },
    { value: 'redeemHours', label: 'Redeem Hours' },
    { value: 'onTimeRate', label: 'On Time Rate' },
    { value: 'jobReportVsEndWork', label: 'End of Day Job Report Options' },
    { value: 'planAssignmentOption', label: 'Plan Assignment vs End Work' },
    { value: 'transferFeeCashOut', label: 'Transfer Fee (Cash Out)%' },
    { value: 'transferFeeFixedAmount', label: 'Transfer Fee Fixed Amount' },
    { value: 'lateFee', label: 'Late Fee %' },
    { value: 'delayOver', label: 'Delay Over' },
  ];
  dataOtherSourceSubject = new BehaviorSubject(null);
  dataOtherSource$ = this.dataOtherSourceSubject.asObservable();
  size: number;
  page: number;
  rangeOtherDates: any = [];
  historyOtherFor: any = {};
  selectedOtherField: String = '';
  loading = false;

  @Output() onHideHistory: EventEmitter<any> = new EventEmitter();
  @Input() payrollSettingId: number;
  @Input() historyType: any;

  constructor(private payrollSettingService: PayrollSettingService) {
  }

  ngOnInit(): void {
  }

  selectField(event) {
    this.historyOtherFor = this.fieldsOther.find(field => event.value === field.value);
  }

  loadDataOtherource(event?: any) {
    this.dataOtherSourceSubject.next([]);
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    const options: any = {
      size: this.size,
      page: this.page,
      fieldName: this.selectedOtherField,
      payrollSettingId: this.payrollSettingId
    };

    if (this.rangeOtherDates[0] && this.rangeOtherDates[1]) {
      options.fromDate = moment(this.rangeOtherDates[0]).toDate().getTime();
      options.toDate = moment(this.rangeOtherDates[1]).toDate().getTime();
    }

    if (!this.selectedOtherField && (!this.rangeOtherDates[0] && !this.rangeOtherDates[1])) {
      this.dataOtherSourceSubject.next([]);
      return;
    }
    console.log(options);
    this.loading = true;
    this.payrollSettingService.getPayrollSettingListHistory(options).subscribe(
      (data: any) => {
        this.dataOtherSourceSubject.next(data.data);
        this.loading = false;
      }
    );
  }

  checkField(value, field) {
    return value;
  }

  public hideHistory() {
    this.selectedOtherField = '';
    this.dataOtherSourceSubject.next([]);
    this.onHideHistory.emit();
  }

  reset() {
    this.rangeOtherDates = [];
    this.selectedOtherField = null;
    this.historyOtherFor = {};
    this.loadDataOtherource();

  }
}
