import { AgmDrawingManager } from '@agm/drawing';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  OnInit,
  ViewChild, ViewChildren, QueryList
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PolygonService } from 'app/map/service/polygon.service';
import { ZoneService } from 'app/map/service/zone.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { forkJoin, Subject } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { PolygonUtils } from 'app/map/utils/polygon.utils';
import { Checkbox } from "primeng/checkbox";

@Component({
  selector: 'app-polygon-drawer-map',
  templateUrl: './polygon-drawer-map.component.html',
  styleUrls: ['./polygon-drawer-map.component.scss'],
  providers: [AgmDrawingManager],
})
export class PolygonDrawerMapComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  @Input()
  zoneData: any = {};

  @Input()
  lockedZones: any = {};
  @Input()
  allZonePolygon: any = [];

  @ViewChild('dropdown', { read: ElementRef })
  dropdownElement: ElementRef;
  polygonForm: UntypedFormGroup;
  editMode = false;

  processing = false;
  clearPolygons = false;
  congestion = false;
  polygonLength = 0;

  polygonTypes = ['STATE'];
  polygons: any = [];
  polygonList: any[] = [];

  selectedZonePolygon = [];
  selectedStatePolygon = []

  zoneList: any;
  allZoneLis: any = [];
  @ViewChildren('checkboxRef', { read: Checkbox }) checkboxRefs: QueryList<Checkbox>;
  zoneTypeLoaded: { [key: string]: boolean } = {};

  @Input('zoneTypeLoaded')
  set setZoneTypeLoaded(zoneTypeLoaded: any) {
    this.zoneTypeLoaded = zoneTypeLoaded;
  }
  selectedZones: any[] = [];
  multiPolygons = [];

  selectedPolygons: any = new UntypedFormControl();
  polygonSelection = '1';

  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  selectedPoint: any;
  selectedMovePoint: any;
  timeSpent = new Date();
  showAllZone = false;

  lockedZonesPolygon;
  zoneTypeOption = PolygonUtils.zoneTypeOption;

  zoneTypeChecked = new Set<string>();
  selectedZoneChecked = [];
  unSelectChecked;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private polygonService: PolygonService,
    private messageService: MessageService,
    private zoneService: ZoneService,
    private translatePipe: TranslatePipe,
    private cdr: ChangeDetectorRef,
    private monitoringDetailsService: MonitoringDetailsService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.multiPolygons = [];
    if (!PolygonUtils.isCreateNew(this.zoneData)) {
      this.polygonSelection = '0';
      const title = this.zoneData.title;
      this.polygonForm.patchValue({ polygonName: title });
    }
    this.loadZones();
    this.polygonTypes.forEach(type => {
      this.zoneService.getPolygonByType(type).subscribe((res) => {
        this.polygonList = res.data;
        this.polygonList = _.orderBy(this.polygonList, ['label'], ['asc']);
      });
    });
    if (this.lockedZones && this.lockedZones.length > 0) {
      this.lockedZonesPolygon = PolygonUtils.convertToGeoJsons(this.lockedZones);
    }
  }

  ngAfterViewInit() {
    fromEvent(this.dropdownElement.nativeElement, 'input')
      .pipe(takeUntil(this.ngUnsubscribe)
        , map((e: any) => (e).target.value)
        , filter((e: any) => e.length)
        , debounceTime(1000)
        , distinctUntilChanged()
        , switchMap((e: any) =>
          this.zoneService.getPolygons(e)))
      .subscribe((e) => {
        const list = e.rows.map((polygon) => ({
          value: polygon,
          label: polygon.name || polygon.zipcode,
        })).filter(e => {
          const labels = this.polygonList.map(p => p.label);
          return !labels.includes(e.label);
        })
        this.polygonList = [...this.polygonList, ...list];
        this.cdr.detectChanges();
      });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private buildForm() {
    this.polygonForm = this.fb.group({
      polygonName: [this.zoneData ? this.zoneData.title : '', [Validators.required]
      ],
      limitation: false,
      selectedPolygonModel: [''],
      color: ['#28a745'],
      selectedZones: [''],
      polygonSelection: ['']
    });
  }

  loadZones() {
    this.zoneService.getZoneList({ companyId: this.authService.getCurrentCompanyId() })
      .subscribe((response) => {
        if (response && response.data && response.data.length) {
          const loadedZoneList = _.map(response.data, (zone) => ({
            ...zone,
            type: zone.type ? zone.type : 'manual_created_zones',
            polygonType: zone.polygonType,
            polygonId: zone.polygonId,
            polygonIds: zone.polygonIds,
            color: zone.color,
            multiPolygons: zone.multiPolygons,
            zoneKey: 'manual_created_zones_' + zone.id
          }));
          this.zoneList = loadedZoneList;
        }
      });
  }

  onPolygonSelectionChange(event) {
    if (event.value) {
      let request;
      if (!Array.isArray(event.value)) {
        request = this.polygonService.getPolygonByIds([event.value]);
      } else {
        request = this.polygonService.getPolygonByIds(event.value);
      }
      request.subscribe((result) => {
        if (result && result.data.length) {
          const polygonData = result.data;
          polygonData.forEach(data => {
            data.type = 'state_polygon';
          });
          this.selectedStatePolygon = PolygonUtils.convertToGeoJsons(polygonData, true);
        } else {
          this.selectedStatePolygon = [];
        }
      });
    }
  }

  onSelectedZoneChange() {
    this.selectedZonePolygon = PolygonUtils.convertToGeoJsons(this.selectedZones)
  }

  editZone() {
    this.multiPolygons = [];
    this.editMode = true;
  }

  createOrUpdateZone() {
    this.processing = true;
    const multiPolygon = _.map(this.polygons, (e) => {
      return { polygon: _.map(e, (coordinate) => ({ x: coordinate.lng(), y: coordinate.lat() })) };
    });
    multiPolygon.forEach((polygon) => {
      this.multiPolygons.push(polygon);
    });

    this.createMultiPolygonZone(this.multiPolygons);
  }

  createMultiPolygonZone(multiPolygon) {
    this.timeSpent = new Date();
    const companyId = this.authService.isSuper() || this.authService.isSubSuper() ? null : this.authService.getCurrentCompanyId();
    const payload: any = {
      companyId: companyId,
      multiPolygon: multiPolygon,
      polygonType: 'CUSTOMIZED',
      title: this.polygonForm.value.polygonName,
      color: this.polygonForm.value.color
    };
    if (this.lockedZonesPolygon && this.lockedZonesPolygon.length > 0) {
      const features = [];
      this.lockedZonesPolygon.forEach(polygon => {
        if (polygon.geoJson.features) {
          polygon.geoJson.features.forEach(feature => {
            features.push({
              type: 'Feature',
              geometry: feature.geometry,
              properties: {
                color: feature.properties['color'],
                type: feature.properties['type'],
                title: feature.properties['labelName']
              }
            })
          })
        }
      });
      payload.features = JSON.stringify(features);
      payload.multiPolygon = null;
      this.createZone(payload, false);
      return;
    }
    if (!PolygonUtils.isCreateNew(this.zoneData)) {
      const coordinates = [];
      const updateSubZoneRequests = [];
      this.zoneData.zoneDataFeatures.forEach(feature => {
        const geoJson = PolygonUtils.geometryToGeoJSON(feature.getGeometry());
        const subZoneId = feature.getProperty("subZoneId");
        if (subZoneId) {
          let subZone;
          if (this.zoneData.subZone) {
            subZone = this.zoneData;
          } else {
            subZone = this.zoneData.subZones.find(sub => sub.id === subZoneId);
          }
          if (subZone && subZone.feature && subZone.feature.geometry 
            && ((this.zoneData.subZone && (subZone.title != payload.title || subZone.color != payload.color)) || this.isCoordinatesChange(subZone.feature.geometry.coordinates, geoJson.coordinates))) {
            const feature = subZone.feature;
            feature.geometry.coordinates = geoJson.coordinates;
            updateSubZoneRequests.push(this.zoneService.updateSubZone({
              id: subZoneId,
              color: this.polygonForm.value.color,
              title: this.zoneData.subZone ? payload.title : null,
              feature: JSON.stringify(feature)
            }));
          }
        } else {
          coordinates.push(geoJson.coordinates);
        }
      });
      if (coordinates.length > 0) {
        payload['id'] = this.zoneData.id;
        payload.multiPolygon = PolygonUtils.convertCoordinatesToMultiPolygons(coordinates);
        this.createZone(payload, true);
      } else if (updateSubZoneRequests.length > 0 || (!this.zoneData.subZone && (this.zoneData.title != payload.title || this.zoneData.color != payload.color))) {
        if ((!this.zoneData.subZone && (this.zoneData.title != payload.title || this.zoneData.color != payload.color))) {
          payload.id = this.zoneData.id;
          updateSubZoneRequests.push(this.zoneService.createZone(payload));
        }
        forkJoin(updateSubZoneRequests).subscribe(() => {
          const detailMessage = this.translatePipe.transform('Zone was updated successfully');
          this.messageService.add({
            severity: 'success',
            summary: this.translatePipe.transform('SUCCESS'),
            detail: detailMessage
          });
          this.monitoringDetailsService.monitorAction(
            `Zone/Turf has been Updated`,
            this.timeSpent,
            {
              zone_has_updated_by: this.authService.getCurrentLoggedInName()
            },
            'complete',
            `zone/Turf has been Updated`,
            0
          );
          this.onClose.next('SAVED');
        });
      } else {
        this.onClose.next('CLOSED');
      }
    } else {
      this.createZone(payload, false);
    }
  }

  createZone(request, isUpdate) {
    this.zoneService.createZone(request).subscribe((res: any) => {
      const detailMessage = isUpdate ? this.translatePipe.transform('Zone was updated successfully') : this.translatePipe.transform('Zone is created successfully');
      this.messageService.add({
        severity: 'success',
        summary: this.translatePipe.transform('SUCCESS'),
        detail: detailMessage
      });
      this.onClose.next('SAVED');
      if (this.isCreateNew) {
        this.monitoringDetailsService.monitorAction(
          `New Zone Created`,
          this.timeSpent,
          {
            new_zone_created_by: this.authService.getCurrentLoggedInName()
          },
          'complete',
          `New Zone Created`,
          0
        );
      } else {
        this.monitoringDetailsService.monitorAction(
          `Zone/Turf has been Updated`,
          this.timeSpent,
          {
            zone_has_updated_by: this.authService.getCurrentLoggedInName()
          },
          'complete',
          `zone/Turf has been Updated`,
          0
        );
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: error.error.message });
      this.processing = false;
    });
  }

  polygonChanged(polygons: any) {
    if (!polygons || polygons.length === 0) {
      this.clearPolygons = false;
    }
    this.polygons = polygons;
    this.polygonLength = polygons.length;
  }

  goBack() {
    this.onClose.next('BACK');
  }

  removeAll() {
    this.selectedZones = [];
    this.clearPolygons = true;
  }

  pointClicked(event) {
    this.selectedPoint = event;
  }

  removePoint() {
    this.selectedMovePoint = this.selectedPoint;
  }

  cancel() {
    this.onClose.next('CANCEL');
  }

  get isSaveButtonEnabled() {
    if (this.lockedZonesPolygon && this.lockedZonesPolygon.length > 0) {
      return this.polygonForm.valid;
    }
    if (this.isCreateNew) {
      return this.polygonForm.valid && this.polygons && this.polygons.length > 0;
    } else {
      return this.polygonForm.valid;
    }
  }

  get isCreateNew() {
    return PolygonUtils.isCreateNew(this.zoneData);
  }

  changeDisplayZones() {
    if (this.showAllZone) {
      this.selectedZones = this.zoneList.map(z => z.value);
    } else {
      this.selectedZones = [];
    }
    this.selectedZonePolygon = PolygonUtils.convertToGeoJsons(this.selectedZones);
  }

  isCoordinatesChange(oldCoordinates, newCoordinates) {
    if (!oldCoordinates || !newCoordinates || oldCoordinates.length !== newCoordinates.length) {
      return true;
    }
    for (let i = 0; i < oldCoordinates.length; i++) {
      const oldCoordinate = oldCoordinates[i];
      const newCoordinate = newCoordinates[i];
      if (!oldCoordinate || !newCoordinate || !oldCoordinate.length || !newCoordinate.length) {
        return true;
      }
      let oldArr;
      if (typeof oldCoordinate[0] === 'number') {
        oldArr = oldCoordinate;
      } else {
        oldArr = oldCoordinate[0];
      }

      let newArr;
      if (typeof newCoordinate[0] === 'number') {
        newArr = newCoordinate;
      } else {
        newArr = newCoordinate[0];
      }
      if (!oldCoordinate || !newArr || !oldArr.length || !newArr.length || oldArr.length !== newArr.length) {
        return true;
      }
      for (let j = 0; j < oldArr.length; j++) {
        if (oldArr[j].length != 2 || newArr[j].length != 2) {
          return true;
        }
        if (oldArr[j][0] !== newArr[j][0] || oldArr[j][1] !== newArr[j][1]) {
          return true;
        }
      }
    }
    return false;
  }

  zoneSelectedChange() {
    this.selectedZonePolygon = PolygonUtils.convertToGeoJsons(this.selectedZones);
  }

  onDisplayAllZoneToggle(type, event, checkboxRef, isTypeClicked?) {
    if (this.zoneTypeChecked.size === 2 && event.checked && !isTypeClicked) {
      if (checkboxRef) {
        checkboxRef.checked = false;
      }
      this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Notice'),
        detail: this.translatePipe.transform('Only able to select maximum 2 zone types.')
      });
      return;
    }

    if (event.checked) {
      this.unSelectChecked = false;
      if (!this.zoneTypeChecked.has(type)) {
        const zType = PolygonUtils.zoneTypeOption.find(z => z.value === type);
        if (zType && !isTypeClicked) {
          this.zoneTypeChecked.add(zType.value);
        }
      }
      const allZones = [...this.zoneList];
      if (type !== 'manual_created_zones' && !isTypeClicked) {
        this.allZonePolygon.forEach(p => {
          if (p.type === type) {
            allZones.push(p);
          }
        });
      }
      const zones = (allZones || []).filter(z => z.type === type);
      const addKeys = new Set<string>(this.selectedZones.map(s => s.zoneKey));
      zones.forEach(z => {
        if (!addKeys.has(z.zoneKey)) {
          this.selectedZones.push(z);
        }
      });
      this.selectedZones = [...this.selectedZones];
    } else {
      if (!isTypeClicked) {
         this.zoneTypeChecked.delete(type);
      }

      if (type !== 'manual_created_zones' && !isTypeClicked) {
        this.zoneList = this.zoneList.filter(z => {
          if (this.lockedZones.find(l => l.zoneKey === z.zoneKey)) {
            return true;
          }
          return z.type !== type;
        });
        this.zoneList = [...this.zoneList];
      }
      this.selectedZones = this.selectedZones.filter(z => {
        if (this.lockedZones.find(l => l.zoneKey === z.zoneKey)) {
          return true;
        }
        return z.type !== type;
      });
    }
    this.zoneSelectedChange();
  }

  onUnSelectAllZoneToggle(event) {
    if (event.checked) {
      this.zoneTypeChecked = new Set<string>();
      this.zoneList = this.zoneList.filter(z => {
        if (this.lockedZones.find(l => l.zoneKey === z.zoneKey)) {
          return true;
        }
        return z.type === 'manual_created_zones';
      });
      this.zoneList = [...this.zoneList];
      this.selectedZones = this.selectedZones.filter(z => {
        if (this.lockedZones.find(l => l.zoneKey === z.zoneKey)) {
          return true;
        }
        return false;
      });
      if (this.checkboxRefs) {
        console.log(this.checkboxRefs);
        this.checkboxRefs.forEach(checkbox => {
          checkbox.checked = false;
        });
      }
      this.zoneSelectedChange();
    }
  }

}
