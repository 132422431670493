import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'custom-time-picker',
  templateUrl: './custom-time-picker.component.html',
  styleUrls: ['./custom-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTimePickerComponent),
      multi: true,
    },
  ],
})
export class CustomTimePickerComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor
{
  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  htmlId = '';

  @Input()
  htmlClass = '';

  @Input()
  fontSize: 'normal' | 'large' = 'normal';

  @Output()
  valueChange = new EventEmitter();

  hours = [];
  minutes = [];
  show = false;
  inputControl = new UntypedFormControl();

  onChange;

  focused = false;
  constructor() {
  }

  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {
  }

  @Input()
  set value(value: string) {
    if (!value) {
      this.show = false;
      this.inputControl.setValue(null);
    }
    const momentTime = moment(value);
    if (momentTime.isValid()) {
      this.inputControl.setValue(momentTime.format('HH:mm'));
    }
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {}
  writeValue(value) {
    this.inputControl.setValue(value);
  }

  reset() {
    this.inputControl.reset();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  focus() {
    this.focused = true;
    if (!this.inputControl.value) {
      this.inputControl.setValue(moment().format('HH:mm'));
    }
  }

  clear() {
    this.inputControl.setValue(null);
    this.valueChange.emit(null);
  }

  updateMeridiem(event) {
    console.log(this.inputControl.value);
    if (this.inputControl.value) {
      const currentValue = moment(this.inputControl.value, 'HH:mm');
      const hour = currentValue.get('hour');
      if (event.key === 'a' || event.key === 'A') {
        if (hour >= 12) {
          this.inputControl.setValue(currentValue.add(-12, 'hour').format('HH:mm'));
        }
      }
      if (event.key === 'p' || event.key === 'P') {
        if (hour < 12) {
          this.inputControl.setValue(currentValue.add(12, 'hour').format('HH:mm'));
        }
      }
    }
    console.log(event);
    
  }

  onEndEvent() {
    const momentTime = moment(this.inputControl.value, 'HH:mm');
    if (momentTime.isValid()) {
      this.valueChange.emit(momentTime.toDate());
    } else {
      this.valueChange.emit(null);
    }
  }
}
