import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';

import { CallLogModel } from '../../model/call-log/call-log';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment, environmentLocal } from '../../../../environments/environment';
import { CallLogResponseDTO } from '../../model/call-log/callLogResponseDTO';

@Injectable({ providedIn: 'root' })
export class CallLogService {
    private static URL = environment.server_ip + '/call-log';
    private callLog: CallLogModel;

    constructor(private http: HttpClient) {
    }

    public create(callLog: CallLogModel): Observable<CallLogModel> {
        const createdCallLog = Object.assign(new CallLogModel(), callLog);
        return this.http.post<CallLogModel>(CallLogService.URL, createdCallLog);
    }

    public getAll(did: string): Observable<any> {
        return this.http.post(environmentLocal.callLog, {extension: did})
    }
}
