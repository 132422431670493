import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Constants } from '../../../app/shared/model/constants';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class GeoService {
  
  private static URL = `${environment.v2_server_ip}/${Constants.ROUTES.GEO}`;
  // private static URL = `http://localhost:8080/${Constants.ROUTES.GEO}`;

  constructor(private http: HttpClient) {}

  getGeoCodingAddress(address: string): Observable<any> {
    return this.http.post(`${GeoService.URL}/geo-coding`, {
      address: address
    });
  }

  getGeoCodingLocation(lat, lng): Observable<any> {
    return this.http.post(`${GeoService.URL}/geo-coding`, {
      lat: lat,
      lng: lng
    });
  }

  getDirections(payload): Observable<any> {
    return this.http.post(`${GeoService.URL}/get-directions`, payload);
  }

  public getListGeoServiceUrl(): Observable<any> {
    return this.http.get(`${GeoService.URL}/report/urls`);
  }

  public getHistoryTracking(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${GeoService.URL}/report/table-data`, {
      params: httpParams,
    });
  }

  public exportData(options): Observable<any> {
    const params = options
      ? Object.keys(options)
        .filter((e) => options[e] != null)
        .map((e) => `${e}=${options[e]}`)
        .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${GeoService.URL}/report/export-data`, {
      params: httpParams,
      responseType: "blob",
    });
  }

  public sendEmail(payload): Observable<any> {
    return this.http.post(
      `${GeoService.URL}/report/send-email`,
      payload
    );
    }

  public getHistoryTrackingStatistic(options): Observable<any> {
    const params = options
      ? Object.keys(options)
          .filter((e) => options[e] != null)
          .map((e) => `${e}=${options[e]}`)
          .join("&")
      : "";
    const httpParams = new HttpParams({ fromString: params });
    return this.http.get(`${GeoService.URL}/report/table-statistic`, {
      params: httpParams,
    });
  }

  public getApiCostSettingList(): Observable<any> {
    return this.http.get(`${GeoService.URL}/google-api-setting`);
  }

  public updateApiCostSettingList(payload): Observable<any> {
    return this.http.post(`${GeoService.URL}/google-api-setting`, payload);
  }

  public deleteApiCostSetting(id): Observable<any> {
    return this.http.delete(`${GeoService.URL}/google-api-setting/${id}`);
  }

  exportPdf(data?: any): Observable<any> {
    return this.http.post<any>(`${GeoService.URL}/pdf`, data).pipe(map(resp => resp));
    // return this.http.post<any>(`http://localhost:8007/api/v2/geo/pdf`, data).pipe(map(resp => resp));
  }

  exportCostPdf(data?: any): Observable<any> {
      return this.http.post<any>(`${GeoService.URL}/pdf-cost`, data).pipe(map(resp => resp));
      // return this.http.post<any>(`http://localhost:8007/api/v2/geo/pdf`, data).pipe(map(resp => resp));
  }
}
