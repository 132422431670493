import { Agency } from '../../agency/model';
import { Company } from '../../company/model/company.model';


export class ToDo {
    id: number;
    ticketNumber: string;
    agency: Agency;
    // company: Company;
    companyId: number;
    todo: string;
    scheduleTo: string;
    regarding: string;
    details: any;
    alarm: boolean;
    todoInterval: string;
    reminderDate: Date;
    deadline: Date;
    priority: string;
    startsDate: any;
    endDate: any;
    status: string;
    invitationStatus: string;
    client: string;
    createdAt: any;
    updatedAt: any;
    timezone: string;
    charactersNumber: number;
    timeReopened: Date;
    timePassed: number; // Number of miliseconds since first start
    executed: boolean;
    fullUserName?: string;
    createdByUsr?: string;
    ticketReference?: any;
    completedDate: any;
    inProgressDate: any;
    duration?:any
    supportEmails?:string;
    todoCheckList?: any[] = [];
    selectedHours?: any[] = [];
    documentId: number;
    recurring: boolean;
    recurringType: string;
    recurringEndDate: any;
    locationId: any;
    locationName: string;
    room: string;
    locationRoom: string;
    productId?: any;
}
