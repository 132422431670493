import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class TransferDataService {
    constructor() {}

    private dataSubjectVoter = new BehaviorSubject<any>(null);
    private dataSubjectTurf = new BehaviorSubject<any>(null);

    dataVoter$ = this.dataSubjectVoter.asObservable();
    dataTurf$ = this.dataSubjectTurf.asObservable();

    sendDataVoter(data: any) {
        this.dataSubjectVoter.next(data);
    }

    sendDataTurf(data: any) {
        this.dataSubjectTurf.next(data);
    }     
}