<p-toast position="bottom-right" class="concern"></p-toast>
<div class="card add-shadow p-4">
    <h1 class="m-0 ml-2 my-4">{{'OperrSign' | translate}}</h1>
    <div class="ui-g">
        <div class="ui-g-3 ui-sm-12">
            <div class="w-100 d-flex justify-content-end" *ngIf="loadDocSign">
                <button *ngIf="!recipient?.signed" class="btn btn-primary mx-1 px-4" (click)="finish()" [disabled]="summiting">
                    <span class="text-nowrap">{{'Finish' | translate}}</span>
                </button>
                <button *ngIf="recipient?.signed" class="btn btn-primary mx-1 px-4" (click)="download()" [disabled]="summiting">
                    <span class="text-nowrap">{{'Download' | translate}}</span>
                </button>
            </div>
            <div class="w-100 d-flex justify-content-end" *ngIf="recipient && recipient.allSigned && recipient.allSignedRecipients">
                <div>
                    <div *ngFor="let data of recipient.allSignedRecipients; index as i" class="p-3" style="font-size: large;">
                        <div>Recipient {{i + 1}}</div>
                        <div>Full Name: {{data.name}}</div>
                        <div>Title: {{data.title}}</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="ui-g-9 ui-sm-12">
            <p-panel [toggleable]="true" id="table-ppanel">
                <p-header class="flex-grow-1">
                    <span><i class="fas fa-gear text-primary mr-2"></i>{{'Contract' | translate}}</span>
                </p-header>
                <div *ngIf="!loadDocSign && viewInit" class="d-flex justify-content-center">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                </div>
                <div class="card add-shadow p-3 mb-0" *ngIf="loadDocSign && viewInit">
                    <ngx-extended-pdf-viewer *ngIf="docSignFileBlobUrl" #pdfViewer [src]="docSignFileBlobUrl"
                        [enableDragAndDrop]="false" [textLayer]="false" [minifiedJSLibraries]="false"
                        [showHandToolButton]="false" [ignoreKeys]="['ctrl']" [showToolbar]="false"
                        (pdfLoaded)="pdfLoaded()" (pageChange)="pageChange($event)"
                        (annotationEditorLayerRendered)="annotationEditorLayerRendered($event)">
                    </ngx-extended-pdf-viewer>
                </div>
            </p-panel>
        </div>
    </div>
</div>

<p-dialog *ngIf="recipient" [(visible)]="addSignatureDialog && recipient" [style]="{'width': '60%', 'max-width':'600px'}" [modal]='true'>
    <p-header>
        {{'Missing fields for recipients' | translate}}
    </p-header>
    <div class="ui-g">
        <div class="ui-g-12 p-0 pt-3">
            <div class="ui-g-12">
                <label>{{'Full Name' | translate}} <span class="required-indicator">*</span></label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button class="btn btn-white" id="button-addon2" type="button"
                            style="cursor:default !important">
                            <i class="fa-solid fa-user"></i>
                        </button>
                    </div>
                    <input placeholder="{{'Full Name' | translate}}" type="text" pInputText
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="recipient.name" readonly />
                </div>
            </div>
            <div class="ui-g-12" style="height: 320px;">
                <p-tabView [activeIndex]="drawType" (onChange)="changeDrawType($event)">
                    <p-tabPanel header="Text">
                        <ng-template pTemplate="content">
                            <h4>Preview</h4>
                            <div id="signatureTextPreview" style="width: 525px; height: 210px; display: flex; justify-content: center; align-items: center;">
                                <img [src]="signatureTextTemplateUrl" width="200" height="100"
                                    class="align-self-center profile-picture">
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel header="Draw">
                        <ng-template pTemplate="content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <h4>Draw your signature</h4>
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle ml-2" type="button" data-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-bars"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <li *ngFor="let width of penWidths" (click)="setPenWidth(signaturePad, width)" class="px-3 dropdown-item d-flex align-items-center" [class.active]="width === selectedPenWidth" style="height: 30px;">
                                                <div class="pen-width" [style.border]="'1px solid #000'" [style.border-width.px]="width"></div>
                                            </li>
                                        </div>
                                    </div> 
                                </div>
                                <i class="fa fa-history cursor-pointer" style="font-size: 21px" aria-hidden="true" (click)="signaturePad.clear(); signatureDrawTemplate = null;"></i>
                            </div>
                            <div class="mt-4">
                                <signature-pad #signaturePad [options]="signaturePadConfig"
                                    (onBeginEvent)="drawStartSignature(signaturePad)"
                                    (onEndEvent)="drawCompleteSignature(signaturePad)">
                                </signature-pad>
                            </div>
                        </ng-template>

                    </p-tabPanel>
                    <!-- <p-tabPanel header="Upload">
                        <ng-template pTemplate="content">
                            <h4>Draw your signature</h4>
                        </ng-template>
                    </p-tabPanel> -->
                </p-tabView>
            </div>
        </div>
    </div>

    <br>
    <p-footer class="flex-center">
        <button type="button" (click)="adoptAndSign(selectedEditorId)" class="btn btn-primary mx-1">{{'Adopt and Sign' |
            translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="replaceSignatureDialog" [style]="{'width': '60%', 'max-width':'480px'}">
    <p-header>
        {{'replaceSignatureDialog' | translate}}
    </p-header>
    <div class="ui-g">
        {{'Are you sure you want to replace the signature associated with this name?' | translate}}
    </div>

    <br>
    <p-footer class="flex-center">
        <button type="button" (click)="adoptAndAllCurrentSign()" class="btn btn-primary mx-1">{{'Replace' |
            translate}}</button>
        <button type="button" (click)="replaceSignatureDialog = false" class="btn btn-danger mx-1">{{'cancel' |
            translate}}</button>
    </p-footer>
</p-dialog>

<p-overlayPanel #op>
    <ng-template pTemplate>
        <div>
            <div>
                <button type="button" class="item button-link px-4 py-2 close" (click)="changeSignature()">Change</button>
            </div>
            <div>
                <button type="button" class="item button-link px-4 py-2 close" style="color: red; float: left" (click)="clearSignature()">Clear</button>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>