import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, OverlayPanel, Table } from 'primeng';
import { AuthService } from '../../../../shared/service/auth/auth.service';
import { DispatchTotalJobService } from '../../service/dispatch-total-job.service';
import { BillingUploadHistoryService } from '../../service/billing-upload-history.service';
import * as _moment from 'moment';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { DropDownsService } from '../../../../shared/service/drop-downs/drop-downs.service';
import { ClientService } from '../../../service/client.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { Subscription } from 'rxjs';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { FileUtility } from '../../../../shared/utility/file.utility';
import { UploadEvent } from 'app/crm/job-follow-up/model/upload-event.model';
import { BillingClientStatusService } from '../../service/billing-client-status.service';
import { FileUpload } from 'primeng/fileupload';
import { TranslatePipe } from "@ngx-translate/core";
import { debounceTime } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import {DocumentsServicev2} from '../../../../shared/service/documents-upload/document.v2.service';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-dispatch-total-job-list',
  templateUrl: './dispatch-total-job-list.component.html',
  styleUrls: ['./dispatch-total-job-list.component.scss'],
  providers: [DispatchTotalJobService]
})
export class DispatchTotalJobListComponent implements OnInit {
  showProgressBar = false;
  showProgressReasonFail = false;
  showProgressBarPage = false;
  showProgressBarForNote = false;
  showProgressBarOnInitialLoad = false;
  showProcessComplete = false;
  progressBarValue = 0;
  uploadedFiles: any[] = [];
  excel: {
    csv2json: any;
    excel2json: any;
    json2excel: any;
  };
  page: number;
  size: number = 9999;
  notSavedCount: number;
  loading: boolean = true;
  companySelected: any;
  uploadDateSelected: any;
  isClientAccount = false;
  uploadDateList: any;
  isPlatformAdmin: any;
  companies: any[];
  totalRecords: number = 0;
  billingReports: any[];
  showConfirmDeleteDialog = false;
  selectedVideo: any;
  fromDate: Date;
  toDate: Date;
  createdByList: any[];
  createdSelected: any;
  showProcessUpdateComplete = false;
  previousUpdatedDate: any;
  totalUpdatedRecords: number = 0;
  totalNewRecords: number = 0;
  isPreviousUpdatedDate = false;
  reminderData: any[];
  searchText: any;
  customColumnName: any = [];
  customizeColumns = [];
  downloadOptions = [
    { name: 'Download Old Format', value: 'oldformat' },
    { name: 'Download New Format', value: 'newformat' },
  ];
  selectedDownload = '';
  listSelected = [];
  cols = [
      //{ label: '#', field: 'id', sortOptions: '', customizeColumn: true, show: true, sort: 'number', width: '4%' },
      { label: 'Pickup Date', field: 'pickupDate', sortOptions: '', customizeColumn: true, show: true, sort: 'number' },
      { label: 'Pickup Time', field: 'pickupTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '5%' },
      { label: 'POB Time', field: 'podTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '5%' },
      { label: 'Drop off Time', field: 'dropOffTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '7%' },
      { label: 'Trip Type', field: 'tripType', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '10%' },
      { label: 'Agency', field: 'agency', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '3%' },
      { label: 'Insurance ID', field: 'insuranceId', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Fleet', field: 'fleetNumber', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Driver', field: 'driverName', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Name', field: 'customerFullName', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Phone', field: 'customerPhone', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Pickup Address', field: 'pickupAddress', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Drop off Address', field: 'dropOffAddress', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Status', field: 'status', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
      { label: 'Toll Fee', field: 'tollFee', sortOptions: '', customizeColumn: true, show: true, sort: 'number' },
      { label: 'Trip Fare', field: 'tripFare', sortOptions: '', customizeColumn: true, show: true, sort: 'number', width: '4%' }
  ];

  colsReminder = [
    { label: '#', field: 'id', sortOptions: '', sort: 'number' },
    { label: 'Uploaded', field: 'updatedDate', sortOptions: '', sort: 'text' },
    { label: 'Client', field: 'clientName', sort: 'number' },
    { label: 'Due In', field: 'dueIn', sort: 'text' }
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  @ViewChild('dt', { static: true }) table: Table;
  selectedSortOption: string = 'ASC';
  selectedField: string = 'pickupDate';
  clients: any[];
  clientList: any[];
  bulkDeleteRecords: any[] = [];
  clientSelected: any;
  showUploaded: boolean = false;
  clientSelectedUpload: any;
  totalJobId: number;
  showConfirmDialog: boolean;
  showSignOffReminder: boolean = false;
  showUpdateBillingConfirmDialog: boolean;
  isPermitted: boolean = false;
  isChooseClient: boolean = true;
  isChooseClientUpload: boolean = true;
  downloadEnable: boolean = false;
  sortField: string;
  sortOrder: string;
  private _routerSub = Subscription.EMPTY;
  timeSpent = new Date();
  displayMessage: boolean = false;
  uploadDates = [];

  toggleMultiDeleteOptions = {
    label: 'Bulk Select',
    allowSelection: false,
    checkAll: false,
    show: true
  };
  @ViewChild('profilePhoto') profilePhoto: any;
  displayDialog = false;
  selectedGroup: any[] = [];
  isDeleteAble = false;
  totalFileUpload = 0;
  ableCheckAll = true;

  showProgressBarImport;
  progressBarImportValue = 0;
  estimateTime;
  totalRecordAdded = 0;
  duplicateRecord = 0;
  durationImport;
  tripLastDate;
  showProcessImportComplete;
  fireBaseSubscriptions: Subscription;
  showSendEmail = false;
  emailClient: any;
  clientName: any;
  @ViewChild('fileUpload', { static: false }) fileUpload!: FileUpload;
  constructor(private authService: AuthService,
    private dropdownsService: DropDownsService,
    private messageService: MessageService, private clientService: ClientService,
    private dispatchTotalJobService: DispatchTotalJobService,
    private billingUploadHistoryService: BillingUploadHistoryService,
    private billingClientStatusService: BillingClientStatusService,
    private employeeService: EmployeeService,
    private router: Router,
    private translatePipe: TranslatePipe,
    private monitoringDetailsService: MonitoringDetailsService,
    private documentServicev2: DocumentsServicev2,
    private exportMonitorService: ExportMonitorService,
    private db: AngularFireDatabase) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/job-follow-up/billing-total-job') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Dispatch Total Job`,
            this.timeSpent,
            {
              navigated_to_dispatch_total_job_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Dispatch Total Job`,
            0
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.excel = require('assets/libs/js2excel.min.js');
    this.loading = true;
    this.isPermitted = this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole() || this.authService.isCompanyAd();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.billingReports = [];
    this.loadAllCompanyList();
    if (this.authService.isClientRole() || this.authService.isChildClientRole()) {
      this.isClientAccount = true;
      console.log('160');
      if (this.authService.isClientRole()) {
        this.clientSelected = this.authService.getUserInfo().adminId;
        this.clientSelectedUpload = this.authService.getUserInfo().adminId;
      } else {
        this.clientSelected = this.authService.getUserInfo().crmClientId;
        this.clientSelectedUpload = this.authService.getUserInfo().crmClientId;
      }

      this.isChooseClient = false;
      this.isChooseClientUpload = false;
      this.downloadEnable = true;
      this.cols = [
        //{ label: '#', field: 'id', sortOptions: '', customizeColumn: true, show: true, sort: 'number', width: '4%' },
        { label: 'Pickup Date', field: 'pickupDate', sortOptions: '', customizeColumn: true, show: true, sort: 'number' },
        { label: 'Pickup Time', field: 'pickupTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '5%' },
        { label: 'POB Time', field: 'podTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '5%' },
        { label: 'Drop off Time', field: 'dropOffTime', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '7%' },
        { label: 'Trip Type', field: 'tripType', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '10%' },
        { label: 'Agency', field: 'agency', sortOptions: '', customizeColumn: true, show: true, sort: 'text', width: '3%' },
        { label: 'Insurance ID', field: 'insuranceId', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Fleet', field: 'fleetNumber', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Driver', field: 'driverName', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Name', field: 'customerFullName', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Phone', field: 'customerPhone', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Pickup Address', field: 'pickupAddress', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Drop off Address', field: 'dropOffAddress', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Status', field: 'status', sortOptions: '', customizeColumn: true, show: true, sort: 'text' },
        { label: 'Toll Fee', field: 'tollFee', sortOptions: '', customizeColumn: true, show: true, sort: 'number' },
        { label: 'Trip Fare', field: 'tripFare', sortOptions: '', customizeColumn: true, show: true, sort: 'number', width: '4%' }
      ];
    } else {
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    }

    this.customizeColumns = this.cols.filter(c => c.customizeColumn).map(c => {
      return {
        ...c,
        value: c.field
      }
    });

    this.customColumnName = this.cols.filter(item => item.show).map(c => c.field);
    //this.search();
    //this.getCreatedByList();
    //this.loadUploadedDateList();
  }

  ngOnDestroy(): void {
    if (this.fireBaseSubscriptions) {
      this.fireBaseSubscriptions.unsubscribe();
    }
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000);
  }

  loadBillingReports(event?: LazyLoadEvent) {
    this.billingReports = [];
    if (!event) {
      this.bulkDeleteRecords = [];
    }
    
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : 0;
    const option = {
      page: this.page,
      size: this.size,
      sortField: 'pickupDate',
      sortOrder: 'DESC',
    };
    this.downloadEnable = true;
    this.loadPage(option);
  }

  search(event?: any) {
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options = {
      size: this.size,
      page: this.page,
      sortField: 'createdAt',
      sortOrder: 'DESC',
      companyId: null
    };
    this.cancelDelete();
    this.loadPage(options);
  }

  accept() {
    if (this.toggleMultiDeleteOptions.allowSelection) {
      const toDeleteIds = [];
      this.bulkDeleteRecords.filter(billing => {
        if (billing.isSelected) {
          toDeleteIds.push(billing.id);
        }
      });
      if (toDeleteIds.length <= 0) {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('Please select Jobs to delete')
        });
      } else {
        this.dispatchTotalJobService.deleteByIds(toDeleteIds).subscribe((_response) => {
          this.loadBillingReports();
          this.messageService.add({
            severity: 'success',
            summary: this.translatePipe.transform('Success'),
            detail: this.translatePipe.transform('Deleted Successfully')
          });
          this.showConfirmDialog = false;
        }, (error) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: this.translatePipe.transform('Delete Failed')
          });
        });
      }
    } else {
      this.dispatchTotalJobService.delete(this.totalJobId).subscribe((_response) => {
        this.loadBillingReports();
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Success'),
          detail: this.translatePipe.transform('Deleted Successfully')
        });
        this.showConfirmDialog = false;
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('Delete Failed')
        });
      });
    }
  }

  reject() {
    this.showConfirmDialog = false;
  }

  deleteRecord(data: any, type: number) {
    if (type === 1) {
      if (this.toggleMultiDeleteOptions.allowSelection) {
        this.bulkDeleteRecords.filter(billing => {
          if (billing.isSelected) {
            if (!this.isPermitted) {
              const showHideActions = (moment(moment().toDate()).diff(billing.createdAt, 'minutes') >= 1440);
              if (showHideActions) {
                this.messageService.add({
                  severity: 'info',
                  summary: this.translatePipe.transform('Information'),
                  detail: this.translatePipe.transform(`Sorry you cannot delete the selected record after 24hrs`)
                });
                return;
              }
            }
          }
        });
      }
    } else {
      if (!this.isPermitted) {
        const showHideActions = (moment(moment().toDate()).diff(data.createdAt, 'minutes') >= 1440);
        if (showHideActions) {
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Information'),
            detail: this.translatePipe.transform(`Sorry you cannot delete the selected record after 24hrs`)
          });
          return;
        }
      }
      this.totalJobId = data.id;
    }
    this.showConfirmDialog = true;
  }

  loadPage(options: any) {
    this.loading = true;
    if (this.companySelected) options.companyId = this.companySelected;
    if (!this.isPlatformAdmin) options.companyId = this.authService.getCurrentCompanyId();
    if (this.fromDate) options.fromDate = this.fromDate? moment(this.fromDate).format('MM/DD/YYYY') : null;
    if (this.toDate) options.toDate = this.toDate? moment(this.toDate).format('MM/DD/YYYY') : null;
    // if (this.uploadDateSelected) {
    //   options.startDate = this.uploadDateSelected;
    //   options.endDate = this.uploadDateSelected;
    // }
    //if (this.createdSelected) options.createdBy = this.createdSelected;
    //if (this.clientSelected) options.clientId = this.clientSelected;
    if (this.searchText) {
      options.searchText = this.searchText;
    }
    this.dispatchTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.billingReports = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        this.billingReports.forEach(item => {

          if (item.createdAt !== item.updatedAt) {
            item.lastUpdated = item.updatedAt;
          } else {
            item.lastUpdated = '';
          }

          if (isNaN(item.tollFee)) {
            item.tollFee = Number(item.tollFee.replace(/\D/, ''));
          }
          item.tollFeeTmp = item.tollFee;
          item.fleetNumberTmp = item.fleetNumber;

          if (item.createdAt && this.toggleMultiDeleteOptions.allowSelection) {
            let after24h = new Date(item.createdAt);
            after24h.setDate(after24h.getDate() + 1);
            if (new Date().getTime() <= after24h.getTime()) {
              item.isDisable = false;
              this.ableCheckAll = false;
            } else {
              item.isDisable = true;
            }
          }
          // this.billingReports.filter(billing => {
          //   for (let i = 0; i < this.bulkDeleteRecords.length; i++) {
          //     if (billing.id === this.bulkDeleteRecords[i].id) {
          //       billing.isSelected = true;
          //     }
          //   }
          // });
        });
        // this.sortingClick(this.selectedSortOption);
        this.loading = false;
      }
    });
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    //this.createdSelected = null;
    // this.clientSelected = null;
    //this.uploadDateSelected = null;
    //this.billingReports = [];
    //this.uploadDates = [];
    // const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
    // x.textContent = `Choose`;
    this.searchText = null;
    this.table.reset()
    //this.loadBillingReports();
    if (!this.authService.isClientRole() && !this.authService.isChildClientRole()) {
      this.downloadEnable = false;
      this.companySelected = null;
      this.clientSelected = null;
      this.clientSelectedUpload = null;
      this.isChooseClient = true;
      this.isChooseClientUpload = true;
    };
    this.cancelDelete();
  }

  loadAllCompanyList() {
    this.dropdownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      const tempCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach((company) => {
          tempCompanyList.push({ label: company.value, value: company.key });
        });
        this.companies = tempCompanyList;
      }
    });
  }

  loadUploadedDateList(type?) {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.clientSelected) {
      options.clientId = this.clientSelected;
    }
    this.uploadDates = [];
    this.dispatchTotalJobService.getUploadedDateList(options).subscribe((res) => {
      const resObj: any = res;
      const tempUploadDateList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        resObj.data.forEach((date) => {
          tempUploadDateList.push({ label: _moment(date).format('MM/DD/YYYY'), value: _moment(date).format('YYYY-MM-DD') });
        });
        this.uploadDateList = tempUploadDateList;
        this.uploadDateList.sort((a, b) => b.value - a.value);
        this.uploadDateSelected = this.uploadDateList[0].value;
        if (this.uploadDateList && this.uploadDateList.length > 0) {
          this.uploadDates.push(this.uploadDateList[0].value);
          this.loadBillingReports();
        } else {
          this.uploadDates = [];
        }

      } else {
        this.uploadDateList = [];
        this.uploadDates = [];
      }
      // if (!type) {
      //   this.search();
      // }
    }, () => {
      // if (!type) {
      //   this.search();
      // }
    });
  }

  getClientList() {
    this.clients = [];
    const options: any = {
      status: 1
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.clientService.getClientDropdown(options).subscribe(res => {
      const resObj: any = res;
      this.clientList = [];
      if (resObj.status === 'SUCCESS' && resObj.data.length > 0) {
        this.clients = resObj.data;
        this.clients.forEach(client => {
          this.clientList.push({ label: client.value, value: client.key, taxId: client.taxId, providerId: client.providerId, email: client.email });
        });
        this.clientList.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      }
    });
  }


  loadBillingSignOff() {
    let options = {
      companyId: null
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.dispatchTotalJobService.getBillingSignOff(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.reminderData = res.data;
      }
    });
  }

  getCreatedByList() {
    let options = {
      companyId: null,
      clientId: null
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.clientSelected) {
      options.clientId = this.clientSelected;
    }
    this.dispatchTotalJobService.getCreatedByList(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.createdByList = res.data.map((user: any) => this.mapToDropdown(user, user));
      }
    });
  }

  chooseCompany(event) {
    this.getClientList();
  }


  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  searchList() {
    this.billingReports = [];
    this.loadBillingReports();
    this.downloadEnable = true;
  }

  async importReports(event) {
    this.totalFileUpload = event.files.length;
    const date = new Date();
    this.showProgressBar = true;
    let userType = this.authService.getUserType();
    const loggedInUser = JSON.parse(localStorage.getItem('_globals'));
    const dataToBeSaved = [];
    const allData = [];

    /* wire up file reader */
    const filePromises = event.files.map((file) => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary", cellDates: true, dateNF: 'mm/dd/yyyy;@' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          /* save data */
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          let output = {};
          (data).forEach((item: any) => {
            const isEmpty = Object.values(item).every(x => (x === null || x === ''));
            if (!isEmpty) {
              let output = {};
              for (let key in item) {
                output[_.camelCase(key)] = item[key];
              }
              allData.push(output);
            }
          });
          // Resolve the promise with the response value
          resolve(reader.result)
        }
      });
    });

    // Wait for all promises to be resolved
    await Promise.all(filePromises);
    const uniqueArray = allData.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === allData.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });

    console.log(uniqueArray);
    uniqueArray.forEach((item) => {
      item.companyId = this.isPlatformAdmin ? this.companySelected : this.authService.getCurrentCompanyId();
      item.fleetNumber = item.fleet;
      item.podTime = item.pobTime;
      item.customerPhone = item.customerPhoneNumber;
      item.driverName = item.driver;
      if (item.dropoffAddress) {
        item.dropOffAddress = item.dropoffAddress;
      } else if (item.dropOffAddress) {
        item.dropOffAddress = item.dropOffAddress;
      }
      item.tollFee = item.tollFee;
      if (item.tollFee !== undefined && isNaN(item.tollFee)) {
        item.tollFee = Number(item.tollFee.replace(/\D/, ''));
      }
      item.createdByUsr = loggedInUser.username;
      dataToBeSaved.push(item);
    });

    // const invoiceNumbers = dataToBeSaved.map(c => c.invoiceNumber);
    // let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
    // if (findDuplicates(invoiceNumbers).length > 0) {
    //   this.showProgressBar = false;
    //   this.showProcessComplete = true;
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Failed',
    //     detail: 'Uploading failed, duplicate Invoice Number: ' + findDuplicates(invoiceNumbers)
    //   });
    //
    //   return;
    // }

    if (dataToBeSaved.length > 0) {
      this.dispatchTotalJobService.upload(dataToBeSaved, userType, loggedInUser.username,
        _moment(date).format('MM/DD/YYYY')).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            this.showUploaded = false;
            if (res.data.previousUpdatedDate) {
              this.previousUpdatedDate = moment(res.data.previousUpdatedDate).format('MM/DD/YYYY');
              this.isPreviousUpdatedDate = true;
            }
            this.totalNewRecords = res.data.totalNewRecords;
            this.totalUpdatedRecords = res.data.totalUpdatedRecords;
            this.showProgressBar = false;
            this.showProcessUpdateComplete = true;
            this.loadUploadedDateList('ref');
            const args = {
              total_job_file_uploaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            };
            const action = 'Total Job File Uploaded';
            this.monitorDetails(args, action, date);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translatePipe.transform('Failed'),
              detail: this.translatePipe.transform('Uploading Failed!')
            });
          }
          this.loadBillingReports();
        }, () => {
          this.showProgressBar = false;
          this.showProcessComplete = true;
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Failed'),
            detail: this.translatePipe.transform('Uploading Failed!')
          });
        });
    } else {
      this.showProgressBar = false;
      this.showProcessComplete = true;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('Invalid data please check file again!')
      });
    }


    const interval = setInterval(() => {
      this.progressBarValue = this.progressBarValue + Math.floor(Math.random() * 10) + 1;
      if (this.progressBarValue >= 100) {
        this.progressBarValue = 100;
        clearInterval(interval);
      }
    }, 300);

  }

  // sortingClick(selectedSortOption) {
  //   this.sortField = this.selectedField;
  //   const options = {
  //     field: this.selectedField,
  //     order: selectedSortOption === 'ASC' ? 1 : -1,
  //     data: this.billingReports
  //   };
  //   this.customSort(options);
  // }

  customSort(event) {
    if (this.sortField.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
        });
      }
      this.sortField = '';
    }
  }

  // setSortOption(field, selectedSortOption) {
  //   this.cols.forEach((item) => {
  //     if (field === item.field) {
  //       item.sortOptions = selectedSortOption;
  //       this.selectedSortOption = selectedSortOption;
  //     }
  //   });
  // }

  loadSortOption(selectedSortOption?) {
    this.selectedSortOption = selectedSortOption;
  }

  sortingClick(selectedSortOption) {
    let options: any = {};
    this.setSortOption(this.selectedField, selectedSortOption);
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption;
    options = {
      sortOrder: this.sortOrder,
      sortField: this.selectedField
    };
    options.page = this.page;
    options.size = this.size;
    this.loadPage(options);
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
      } else {
        item.sortOptions = '';
      }
    });
  }

  downloadExcel() {
    let options: any = {
      size: 99999,
      page: 0,
      companyId: null,
      startDate: this.fromDate,
      endDate: this.toDate,
      createdBy: this.createdSelected,
      clientId: this.clientSelected
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    // if (this.uploadDateSelected) {
    //   options.startDate = this.uploadDateSelected;
    //   options.endDate = this.uploadDateSelected;
    // }
    if (this.searchText) {
      options.key = this.searchText;
    }
    if (this.uploadDates.length > 0) {
      options.listSelectedUpload = this.uploadDates;
    }
    this.dispatchTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billing = data[i];
          const excelTrans: any = {};
          excelTrans['Fleet Number'] = billing.fleetNumber;
          excelTrans['Invoice Number'] = billing.invoiceNumber;
          excelTrans['Name'] = billing.name;
          excelTrans['Gender'] = billing.gender;
          excelTrans['Telephone'] = billing.telephone;
          excelTrans['Pick-up Date'] = moment(billing.pickUpDate).tz('UTC').format('MM/DD/YYYY');
          excelTrans['Pick-up Time'] = billing.pickUpTime;
          excelTrans['Pick-up Address'] = billing.pickUpAddress;
          excelTrans['Pick-up City'] = billing.pickUpCity;
          excelTrans['Pick-up Zip'] = billing.pickUpZip;
          excelTrans['Drop-off Time'] = billing.dropOffTime;
          excelTrans['Drop-off Address'] = billing.dropOffAddress;
          excelTrans['Drop-off City'] = billing.dropOffCity;
          excelTrans['Drop-off Zip'] = billing.dropOffZip;
          excelTrans['Leg Mileage'] = billing.legMileage;
          excelTrans['Leg ID'] = billing.legId;
          excelTrans['Toll Fee'] = billing.tollFee;
          excelData.push(excelTrans);
        }
        this.dispatchTotalJobService.exportAsExcelFile(excelData, 'BillingTotalJob');
      }
    });
  }


  downloadExcelNewFormat() {
    let options: any = {
      size: 9999,
      page: 0,
      companyId: null,
      startDate: this.fromDate,
      endDate: this.toDate,
      createdBy: this.createdSelected,
      clientId: this.clientSelected,
      sortField: 'pickupDate',
      sortOrder: 'DESC',
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    // if (this.uploadDateSelected) {
    //   options.startDate = this.uploadDateSelected;
    //   options.endDate = this.uploadDateSelected;
    // }
    if (this.searchText) {
      options.key = this.searchText;
    }
    if (this.uploadDates.length > 0) {
      options.listSelectedUpload = this.uploadDates;
    }
    this.dispatchTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billing = data[i];
          const excelTrans: any = {};
          excelTrans['Pick-up Date'] = moment(billing.pickUpDate).tz('UTC').format('MM/DD/YYYY');
          excelTrans['Name'] = billing.name;
          excelTrans['CIN'] = billing.cin;
          excelTrans['Invoice Number'] = billing.invoiceNumber;
          excelTrans['Mileage'] = billing.legMileage;
          excelTrans['Leg Number'] = billing.legId;
          excelTrans['Pickup Address'] = billing.pickUpAddress;
          excelTrans['Pickup City'] = billing.pickUpCity;
          excelTrans['Dropoff Address'] = billing.dropOffAddress;
          excelTrans['Dropoff City'] = billing.dropOffCity;
          excelTrans['Trip Price'] = billing.tripPrice;
          excelTrans['Fleet Number'] = billing.fleetNumber;
          excelTrans['Toll Fee'] = billing.tollFee;
          excelData.push(excelTrans);
        }
        this.dispatchTotalJobService.exportAsExcelFile(excelData, 'BillingTotalJob');
      }
    });
  }

  updateData(rowData: any, type?: any) {
    if (type === 'Fleet') {
      if (rowData.fleetNumber === rowData.fleetNumberTmp) {
        return;
      }
      rowData.fleetNumberTmp = rowData.fleetNumber;
    } else if (type === 'Tolls') {
      if (rowData.tollFee === rowData.tollFeeTmp) {
        return;
      }
      rowData.tollFeeTmp = rowData.tollFee;
    }

    console.log('rowData', rowData);
    this.dispatchTotalJobService.update(rowData).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log('update success');
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Success'),
          detail: this.translatePipe.transform('value updated', { value: type })
        });
        if (type === 'Fleet') {
          this.monitoringDetailsService.monitorAction(
            'Fleet # value updated',
            this.timeSpent,
            {
              fleet_updated_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            'Fleet # value updated',
            0
          );
          this.dispatchTotalJobService.updateFleetTollFee([resObj.data]).subscribe();
        } else if (type === 'Tolls') {
          this.monitoringDetailsService.monitorAction(
            'Tolls # value updated',
            this.timeSpent,
            {
              tolls_updated_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            'Tolls # value updated',
            0
          );
          this.dispatchTotalJobService.updateFleetTollFee([resObj.data]).subscribe();
        }


        this.billingReports.forEach(item => {
          if (item.id === rowData.id) {
            if (item.fleetNumber !== rowData.fleetNumber) {
              item.fleetNumber = rowData.fleetNumber;
            }
            if (item.tollFee !== rowData.tollFee) {
              item.tollFee = rowData.tollFee;
            }
            item.updatedAt = resObj.data.updatedAt;
            return;
          }
        });
        // this.search();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Failed'),
          detail: this.translatePipe.transform('Cannot updated', { value: type })
        });
      }
    });
  }

  onChangeClient(event) {
    const  data = this.clientList.filter(item => item.value === event.value);
    this.emailClient = data && data.length > 0 ? data[0].email : null;
    this.clientName = data && data.length > 0 ? data[0].label : null;
    console.log('event', this.emailClient);
    this.loadUploadedDateList('onChangeClient');
    this.getCreatedByList();

  }

  closeProcessCompleteDialog() {
    this.clientSelectedUpload = null;
    this.uploadedFiles = [];
    this.showProcessUpdateComplete = false;
  }

  closeShowSignOffReminder() {
    this.showSignOffReminder = false;
  }

  updateBillingAccept() {
    this.employeeService.searchEmployee({
      companyId: this.authService.getUserInfo().companyId,
      departmentNameSearch: ['Billing', 'Support'],
      status: 1
    }).subscribe((res: any) => {

      if (res.status === 'SUCCESS') {
        const emails = [];
        res.data.content.forEach(e => {
          if (!emails.includes(e.email)) {
            emails.push(e.email);
          }
        });

        if (emails.length > 0) {
          const payload = {
            toEmails: emails,
            subject: 'New data update by ' + this.authService.getUserInfo().username,
            body: 'Hi Guys, you have new data update by ' + this.authService.getUserInfo().username,
            emailCompanyId: this.authService.getUserInfo().companyId,
          };
          this.dispatchTotalJobService.sendBillingTeam(payload).toPromise();
        }
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Sent Successfully'
      });
      this.showUpdateBillingConfirmDialog = false;
      this.displayMessage = false;
    }, () => {
      this.showUpdateBillingConfirmDialog = false;
      this.messageService.add({
        severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Send Failed')
      });
    });
  }

  updateBillingReject() {
    this.showUpdateBillingConfirmDialog = false;
  }

  showSignOffReminderDialog() {
    this.showSignOffReminder = true;
  }

  uploadBtn() {
    this.showUploaded = true;
    this.uploadedFiles = [];
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
    if (this.clientSelected && !this.clientSelectedUpload) {
      this.clientSelectedUpload = this.clientSelected;
    }
    const args = {
      total_job_upload_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
    };
    const action = 'Total Job Upload Button Clicked';
    this.monitorDetails(args, action, new Date());
  }

  importBtn() {
    this.showProgressBarImport = true;
    const options = {
      companyId: this.authService.getCurrentCompanyId(),
      createdByUser: this.authService.getUserInfo().username,
      clientId: this.clientSelected,
      startDate: this.fromDate,
      endDate: this.toDate
    }
    this.dispatchTotalJobService.importBillingJobs(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        if (this.fireBaseSubscriptions) {
          this.fireBaseSubscriptions.unsubscribe();
        }
        this.fireBaseSubscriptions = this.db.object(res.data.firebaseAddress).valueChanges().pipe(debounceTime(50)).subscribe((event: any) => {
          if (event) {
            this.progressBarImportValue = Number(event.progress.toFixed(2));
            const date = new Date(event.estimateTime);
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const seconds = date.getUTCSeconds().toString().padStart(2, '0');
            this.estimateTime = `${hours}:${minutes}:${seconds}`;
            if (this.progressBarImportValue >= 100) {
              this.showUploaded = false;
              this.totalRecordAdded = event.addedRecords;
              this.duplicateRecord = event.duplicateRecords;
              this.tripLastDate = event.tripLastDate;
              this.calulateCountdown(event.duration);
              this.showProgressBarImport = false;
              this.showProcessImportComplete = true;
              this.progressBarImportValue = 0;
              this.search();
            }
          }
        });
      } else {
        this.showProgressBarImport = false;
        this.progressBarImportValue = 0;
        this.durationImport = null;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: this.translatePipe.transform("Failed to import") });
      }
    }, () => {
      this.showProgressBarImport = false;
      this.progressBarImportValue = 0;
      this.durationImport = null;
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: this.translatePipe.transform("Failed to import") });
    });
  }

  calulateCountdown(estimateTime) {
    if (estimateTime <= 0) {
      this.durationImport = '00:00:00';
    }
    const date = new Date(estimateTime);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    this.durationImport = `${hours}:${minutes}:${seconds}`;
  }

  monitorDetails(args, action, time) {
    this.monitoringDetailsService.monitorAction(
      action,
      time,
      args,
      'complete',
      action,
      0
    );
  }

  searchBilling(event) {
    this.table.first = 0;
    this.cancelDelete();
    this.loadPage({ page: 0, size: this.table.rows, searchText: this.searchText });
  }

  openModal() {
    this.displayMessage = true;
  }

  exportPdf() {
    /**
     * colsReminder = [
     { label: '#', field: 'id', sortOptions: '', sort: 'number', width: "10px" },
     { label: 'Uploaded Date', field: 'updatedDate', sortOptions: '', sort: 'text', width: "110px" },
     { label: 'Client Name', field: 'clientName', sort: 'number' },
     { label: 'Due In', field: 'dueIn', sort: 'text' }
     ];
     */
    if (this.reminderData && this.reminderData.length) {
      const data: any = {
        jobList: this.reminderData.map((value, index) => {
          return {
            id: index + 1,
            updatedDate: value.updatedDate,
            clientName: value.clientName,
            dueIn: value.dueIn
          };
        })
      };
      data.companyId = this.authService.getCurrentCompanyId();
      data.username = this.authService.getCurrentUsername();
      this.dispatchTotalJobService.exportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Billing Job List',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('Problem exporting'),
            detail: this.translatePipe.transform('Problem exporting MAS Billing Sign-Off Reminder')
          });
        }
      });
    }
  }

  getClient(id) {
    if (this.clientList) {
      const client = this.clientList.find(el => el.value === id);
      if (client) return client.label;
    }
  }

  onSelectColumn(event) {
    console.log(this.customColumnName);

    console.log(event);

    if (event) {
      this.checkColumns(event.value);
    } else {
      const column = this.customColumnName;
      if (column && column.length) { this.checkColumns(column); }
    }
  }

  checkColumns(column) {
    this.cols.filter(c => c.customizeColumn).forEach(col => {
      if (column.includes(col.field)) {
        col.show = true;
      } else {
        col.show = false;
      }
    });
  }

  getShowHeader() {
    return this.cols.filter(item => !item.customizeColumn || item.show);
  }

  async clickDownladFile() {
    if (this.selectedDownload === 'oldformat') {
      await this.downloadExcel();
    } else if (this.selectedDownload === 'newformat') {
      await this.downloadExcelNewFormat();
    }

  }

  onSelectChange(event) {
    if (event.value.length === this.uploadDateList.length) {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = 'All';
    } else {
      const x = document.getElementById('id_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = `${event.value.length} items selected`;
    }
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  closePopupUploaded() {
    this.clientSelectedUpload = null;
    this.uploadedFiles = [];
    this.showUploaded = false;
  }

  onGroupCheckAllToggle(event: any) {
    console.log(event)
    this.billingReports.forEach((listItem, index) => {
      if (event.checked) {
        if (index < this.table.rows) {
          listItem.isSelected = true;
        } else {
          listItem.isSelected = false;
        }
      } else {
        listItem.isSelected = false;
      }
    });
  }

  toggleDelete() {
    if (this.toggleMultiDeleteOptions.label === 'Delete') {
      this.deleteRecord(null, 1);
      return;
    }
    this.toggleMultiDeleteOptions.allowSelection = !this.toggleMultiDeleteOptions.allowSelection;
    if (this.toggleMultiDeleteOptions.allowSelection) {
      this.toggleMultiDeleteOptions.label = 'Delete Selected';
      this.toggleMultiDeleteOptions.show = false;
      this.toggleMultiDeleteOptions.checkAll = false;
      this.ableCheckAll = true;

      this.billingReports.forEach((listItem, index) => {
        if (listItem.createdAt) {
          let after24h = new Date(listItem.createdAt);
          after24h.setDate(after24h.getDate() + 1);
          if (new Date().getTime() <= after24h.getTime()) {
            listItem.isDisable = false;
            this.ableCheckAll = false;
          } else {
            listItem.isDisable = true;
          }
        } else {
          listItem.isDisable = true;
        }
      });
      this.onGroupCheckAllToggle({ event: { checked: false } });
    } else {
      this.toggleMultiDeleteOptions.label = 'Bulk Select';
    }
  }

  hasSelectedRecord(): boolean {
    const result = this.billingReports.some(item => item.isSelected === true);
    return result;
  }

  onGroupSelectedChanged(rowData: any) {
    if (rowData.isSelected) {
      this.bulkDeleteRecords.push(rowData);
    } else {
      this.bulkDeleteRecords = this.bulkDeleteRecords.filter(item => item.id !== rowData.id);
    }

    this.toggleMultiDeleteOptions.show = true;
    if (this.bulkDeleteRecords.length > 0) {
      this.toggleMultiDeleteOptions.label = 'Delete';
    } else {
      this.toggleMultiDeleteOptions.label = 'Select Jobs to Remove';
    }
    if (this.bulkDeleteRecords.length === this.totalRecords) {
      this.toggleMultiDeleteOptions.checkAll = true;
    } else {
      this.toggleMultiDeleteOptions.checkAll = false;
    }
  }

  cancelDelete() {
    this.toggleMultiDeleteOptions.allowSelection = false;
    this.toggleMultiDeleteOptions.label = 'Bulk Select';
    this.toggleMultiDeleteOptions.checkAll = false;
    this.toggleMultiDeleteOptions.show = true;
    this.onGroupCheckAllToggle(false);
    this.bulkDeleteRecords = [];
    this.displayDialog = false;
  }

  async sendEmail() {
    if (!this.emailClient) {
      this.showSendEmail = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('The client email does not exist')
      });
      return;
    }
    let options: any = {
      size: 9999,
      page: 0,
      companyId: null,
      startDate: this.fromDate,
      endDate: this.toDate,
      createdBy: this.createdSelected,
      clientId: this.clientSelected,
      sortField: 'pickupDate',
      sortOrder: 'DESC',
    };
    if (this.companySelected) {
      options.companyId = this.companySelected;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    // if (this.uploadDateSelected) {
    //   options.startDate = this.uploadDateSelected;
    //   options.endDate = this.uploadDateSelected;
    // }
    if (this.searchText) {
      options.key = this.searchText;
    }
    if (this.uploadDates.length > 0) {
      options.listSelectedUpload = this.uploadDates;
    }
    this.dispatchTotalJobService.getList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.content;
        const excelData = [];
        for (let i = 0; i < data.length; i++) {
          const billing = data[i];
          const excelTrans: any = {};
          excelTrans['Pickup Date'] = moment(billing.pickUpDate).tz('UTC').format('MM/DD/YYYY');
          excelTrans['Pickup Time'] = billing.name;
          excelTrans['CIN'] = billing.cin;
          excelTrans['Invoice Number'] = billing.invoiceNumber;
          excelTrans['Mileage'] = billing.legMileage;
          excelTrans['Leg Number'] = billing.legId;
          excelTrans['Pickup Address'] = billing.pickUpAddress;
          excelTrans['Pickup City'] = billing.pickUpCity;
          excelTrans['Dropoff Address'] = billing.dropOffAddress;
          excelTrans['Dropoff City'] = billing.dropOffCity;
          excelTrans['Trip Price'] = billing.tripPrice;
          excelTrans['Fleet Number'] = billing.fleetNumber;
          excelTrans['Toll Fee'] = billing.tollFee;
          excelData.push(excelTrans);
        }

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
        const wscols = [{ wch: 12 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 15 }];

        worksheet['!cols'] = wscols;
        const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };

        // @ts-ignore
        const excelBuffer: any = XLSX.write(workbook, { bookType: '', type: 'array' });

        const blob: Blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        });

        const file = new File([blob], this.clientName + '.xlsx');
        this.documentServicev2.uploadFile(file, 'totalJobList', this.authService.getCurrentCompanyId(), 'totalJobList').subscribe(resData => {
          const dataEmail = {
            emailClient: this.emailClient,
            companyId: this.authService.getCurrentCompanyId(),
            clientName: this.clientName,
            fileUrl: resData.data.fileUrl
          };
          const emailBody = `Hi ${this.clientName}<br><br>     This email contain total job. For more details please click <a href="${dataEmail.fileUrl}" target="_blank">here</a>.`;
          const emails = [];
          emails.push(this.emailClient);
          const emailPayload = {
            toEmails: emails,
            subject: 'Total Job Email',
            body: emailBody,
            emailCompanyId: dataEmail.companyId,
          };
          this.dispatchTotalJobService.sendBillingTeam(emailPayload).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Send email successfully' });
            this.showSendEmail = false;
          }, () => {
            this.messageService.add({ severity: 'error', summary: 'Created', detail: 'Send email error' });
            this.showSendEmail = false;
          });
        });
      }
    });
  }

  inputSearchTimeout;
  searchByKeyword() {
      clearTimeout(this.inputSearchTimeout);
      this.inputSearchTimeout = setTimeout(() =>{
          this.page = 0;
          this.loadBillingReports();
      }, 1500);

  }
}
