import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../../../shared/model/page.model';
import { ProcessMonitorLoginActivity } from '../model/process-monitor-login-activity.model';
import { AbstractService } from '../../../shared/service/abstract.service';
import { Constants } from '../../../shared/model/constants';
import { map } from 'rxjs/operators';
import { ProcessMonitorEmailActivity } from '../model/process-monitor-email-activity.model';

@Injectable({ providedIn: 'root' })
export class ProcessMonitorEmailActivityService extends AbstractService<ProcessMonitorEmailActivity> {

    constructor(
        private http: HttpClient) {
        super(http, Constants.ROUTES.NOTIFICATION, '');
    }

    searchFilter(options?: any): Observable<Page<ProcessMonitorEmailActivity>> {
        //return this._http.post<Page<ProcessMonitorEmailActivity>>('http://localhost:8009/api/v2/notification/email-logs/search-filter', options).pipe(map(resp => resp));
        return this._http.post<Page<ProcessMonitorEmailActivity>>(this.baseUrlV2 + 'email-logs/search-filter', options).pipe(map(resp => resp));
    }

    searchDetailFilter(options?: any): Observable<Page<ProcessMonitorEmailActivity>> {
        return this._http.post<Page<ProcessMonitorEmailActivity>>(this.baseUrlV2 + 'email-logs/detail/search-filter', options).pipe(map(resp => resp));
    }

    updateActionStatus(id: number, type: number): Observable<Page<any>> {
        return this._http.get<Page<any>>(this.baseUrlV2 + 'email-settings/' + id + '/status/' + type).pipe(map(resp => resp));
    }

    getListAllSubjectEmail(): Observable<Page<any>> {
        return this._http.get<Page<any>>(this.baseUrlV2 + 'email-logs/email-subjects').pipe(map(resp => resp));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'email-logs/exportPdf', data).pipe(map(resp => resp));
    }

    exportPdfDetail(data?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'email-logs/exportPdfDetail', data).pipe(map(resp => resp));
    }

    getListAllLocationEmail(): Observable<Page<any>> {
        return this._http.get<Page<any>>(this.baseUrlV2 + 'email-logs/email-locations').pipe(map(resp => resp));
    }

    search(options?: any): Observable<Page<any>> {
        return this._http.post<Page<ProcessMonitorEmailActivity>>(this.baseUrlV2 + 'email-logs/search', options).pipe(map(resp => resp));
    }
}
