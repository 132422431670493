import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CountryService {

    constructor(private http: HttpClient) { }

    getCountries() {
        return this.http.get('assets/demo/data/countries.json')
            .toPromise()
            .then((res: any) => <any[]>res.data)
            .then(data => data);
    }
}
