import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {PrimeNgModule} from '../prime-ng/prime-ng.module';
import {WebcamModule} from 'ngx-webcam';
import {SharedModule} from '../shared/shared.module';
import {TransactionTypeRoutingModule} from './transaction-type-routing.module';
import {TransactionTypeLayoutComponent} from './component/transaction-type-layout/transaction-type-layout.component';
import {TransactionTypeListComponent} from './component/transaction-type-list/transaction-type-list.component';
import {TransactionTypeAddComponent} from './component/transaction-type-add/transaction-type-add.component';
import {TransactionTypeFormComponent} from './component/transaction-type-form/transaction-type-form.component';
import {TransactionTypeUpdateComponent} from './component/transaction-type-update/transaction-type-update.component';
import {TransactionListComponent} from './component/transaction-list/transaction-list.component';
import {TransactionAddComponent} from './component/transaction-add/transaction-add.component';
import {TransactionUpdateComponent} from './component/transaction-update/transaction-update.component';
import {TransactionFormComponent} from './component/transaction-form/transaction-form.component';
import {NoWhitespaceValidatorDirective} from '../shared/directive/no-whitespace.directive';
import {DecimalPipe} from '@angular/common';
import { StatementBalanceComponent } from './component/statement-balance/statement-balance.component';
import { MentionModule } from 'angular-mentions';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { TransactionDiscountListComponent } from './component/transaction-discount-list/transaction-discount-list.component';
import { TransactionFileHistoryComponent } from './component/transaction-file-history/transaction-file-history.component';
import { TransactionGroupAddComponent } from './component/transaction-group-add/transaction-group-add.component';
import { TransactionGroupUpdateComponent } from './component/transaction-group-update/transaction-group-update.component';
import { TransactionGroupFormComponent } from './component/transaction-group-form/transaction-group-form.component';
import {TransactionDetailsService} from './service/transaction-details.service';
import {TransactionService} from './service/transaction.service';
import {TransactionTypeService} from './service/transaction-type.service';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        PrimeNgModule,
        WebcamModule,
        SharedModule,
        TransactionTypeRoutingModule,
        MentionModule,
        ProgressSpinnerModule,
    ],
    declarations: [TransactionTypeLayoutComponent, TransactionTypeListComponent, TransactionTypeAddComponent,
        TransactionTypeFormComponent,
        TransactionTypeUpdateComponent,
        TransactionListComponent,
        TransactionAddComponent,
        TransactionUpdateComponent,
        TransactionFormComponent,
        NoWhitespaceValidatorDirective,
        StatementBalanceComponent,
        TransactionDiscountListComponent,
        TransactionFileHistoryComponent,
        TransactionGroupAddComponent,
        TransactionGroupUpdateComponent,
        TransactionGroupFormComponent
    ],
    exports: [
        TransactionFileHistoryComponent
    ],
    providers: [DecimalPipe, TransactionDetailsService, TransactionService, TransactionTypeService]
})
export class TransactionTypeModule {
}
