import {Component, OnInit, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Company } from 'app/company/model/company.model';
import { CompanyService } from 'app/company/service/company.service';
import { WalletTransactionService } from 'app/payment/service/wallet-transaction.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng';
import { Subscription } from 'rxjs';
import {FileUtility} from "../../../shared/utility/file.utility";
import {MessageService} from "primeng/api";
import {CurrencyPipe, DatePipe, formatNumber} from "@angular/common";
import {TranslatePipe} from '@ngx-translate/core';
import {Table} from "primeng/table";
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-wallet-transaction',
  templateUrl: './wallet-transaction.component.html',
  styleUrls: ['./wallet-transaction.component.scss'],
})
export class WalletTransactionComponent implements OnInit {
  isPlatformAdmin: Boolean = false;
  isCompanyAdmin: Boolean = false;

  allCompanyList: Company[] = [];
  selectedCompany;

  allTransactionTypes: any[] = [
    { label: 'WITHDRAWAL', value: 'WITHDRAWAL' },
    { label: 'TRANSFER', value: 'TRANSFER' },
    { label: 'CREDIT', value: 'CREDIT' },
    { label: 'DEBIT', value: 'DEBIT' },
  ];
  @ViewChild('dt', { static: true }) table: Table;
  selectedTransactionType;

  fromDate: Date;
  toDate: Date;

  allUsers;
  allUsersList;
  selectedUser;

  transactionList = [];
  loading = false;
  size: number = 9999;
  page: number = 0;
  sortField: string = 'createdAt';
  sortOrder: number;
  selectedSortOption = 'DESC';
  selectedField = '';
  totalRecords;
  textSearch;

  cols = [
    { field: '#', label: '#', width: '5%' },
    { field: 'createdAt', label: 'Date', sortOptions: '', sort: 'number' },
    {
      field: 'sourceUsername',
      label: 'User',
      sortOptions: '',
      sort: 'text',
    },
    {
      field: 'sourceFullName',
      label: 'Name',
      sortOptions: '',
      sort: 'text',
    },
    {
      field: 'companyName',
      label: 'From',
      sortOptions: '',
      sort: 'text',
    },
    {
      field: 'to',
      label: 'To',
      sortOptions: '',
      sort: 'text',
    },
    {
      field: 'transactionType',
      label: 'Type',
      sortOptions: '',
      sort: 'text',
    },
    {
      field: 'amount',
      label: 'Amount',
      sortOptions: '',
      sort: 'number',
    },
    {
      field: 'fee',
      label: 'Fee',
      sortOptions: '',
      sort: 'number',
    },
    {
      field: 'status',
      label: 'Status',
      sortOptions: '',
      sort: 'text',
    },
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
];
numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
];
  timeSpent = new Date();
  private _routerSub = Subscription.EMPTY;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private walletTransactionService: WalletTransactionService,
    private monitoringDetailsService: MonitoringDetailsService,
    private router: Router,
    private translatePipe: TranslatePipe,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private exportMonitorService: ExportMonitorService,
    private currencyPipe: CurrencyPipe
  ) {
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
          if (event.url !== '/app/payments/wallet') {
              this.monitoringDetailsService.monitorAction(
                  `Navigated to Wallet Transaction `,
                  this.timeSpent,
                  {
                      navigated_to_wallet_transaction_by:  this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                  },
                  'complete',
                  `Navigated to Wallet Transaction`,
                  0
              );
          }
      }
  })
  }

  ngOnInit(): void {
    if(!this.isDisplay()){
      this.cols = this.cols.filter(e => e.label !== 'Username' && e.label !== 'Name')
    }
    this.isPlatformAdmin =
      this.authService.isSuper() || this.authService.isSubSuper();
    this.isCompanyAdmin = this.authService.isCompanyAd();
    if (!this.isPlatformAdmin) {
      this.selectedCompany = this.authService.getCurrentCompanyId();
    }
    this.loadAllCompanies();
    if (this.isPlatformAdmin) {
      this.loadAllUsers();
    } else if (this.isCompanyAdmin) {
      this.loadAllUsers();
    }
    this.loadContentsLazy();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    },1000)
  }

  loadAllCompanies() {
    this.companyService.getCompaniesDropdown({}).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = resObj.data;
        this.allCompanyList = _.sortBy(resObj.data, 'value');
        if (this.transactionList) {
            for (let trans of this.transactionList) {
                trans.companyName = this.getCompanyName(trans);
            }
        }
      }
    });
  }

  loadAllUsers() {
    this.walletTransactionService.getAllUser().subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allUsersList = resObj.data;
        this.allUsers = [...this.allUsersList];
      }
    })
  }

  changeCompany() {
    if (this.allUsersList) {
      this.allUsers = this.allUsersList.filter(u => u.companyId === this.selectedCompany.key);
    } else {
      this.allUsers = null;
    }

  }
  filteredValues = [];
  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    this.totalRecords = this.filteredValues.length;
  }
  exportPdf() {
    if (this.filteredValues && this.filteredValues.length) {
      const data: any = {
        contentList: this.filteredValues.map((value, idx) => {
          return {
            index: idx +1,
            createdAt: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy HH:mm:ss'),
            sourceUsername: value.sourceUsername,
            sourceFullName: value.sourceFullName,
            companyName: this.getCompanyName(value),
            to: value.to,
            transactionType: value.transactionType,
            amount: value.amount? '$' + value.amount: null,
            fee: value.fee?'$' + value.fee: null,
            status: value.status,
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompany();
      }
      data.username = this.authService.getCurrentUsername();
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      this.walletTransactionService.exportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Wallet Transaction',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Problem exporting'), detail: this.translatePipe.transform('Problem exporting wallet transaction pdf list') });
        }
      });
    }
  }

  loadContentsLazy(event?: LazyLoadEvent) {
    this.loading = true;
    this.sortField = event && event.sortField ? event.sortField : '';
    this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
    let sortOrder = this.selectedSortOption === 'DESC' ? 'desc' : 'asc';
    const fromDate = this.fromDate
      ? new Date(this.fromDate).getTime() +
        new Date(this.fromDate).getTimezoneOffset() * 60000
      : null;
    const toDate = this.toDate
      ? new Date(this.toDate).getTime() +
        new Date(this.toDate).getTimezoneOffset() * 60000
      : null;
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event ? event.first / event.rows : this.page ? this.page : 0;
    const options: any = {
      size: this.size,
      page: this.page,
      fromDate: fromDate,
      toDate: toDate,
    };
    if (this.authService.isCompanyAdminRole() || this.authService.isContractorRole()) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.isPlatformAdmin) {
      options.companyId = this.selectedCompany ? this.selectedCompany.key : null;
    }

    if (this.authService.isContractorRole()) {
      options.username = this.authService.getUserInfo().username;
    } else {
      if (this.selectedUser) {
        options.username = this.selectedUser.username;
      } else {
        options.username = null;
      }

    }

    if (this.selectedField != '') {
      options.sort = this.selectedField + ',' + sortOrder;
      options.sortField = this.selectedField;
      options.sortOrder = this.selectedSortOption;
    } else {
      options.sortField = 'createdAt';
      options.sortOrder = 'DESC';
    }
    options.transactionType = this.selectedTransactionType;
    this.loadPage(options);
  }

  loadPage(options: any) {
    this.walletTransactionService.search(options).subscribe(
      (res) => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.transactionList = resObj.data.content;
          this.filteredValues = this.transactionList;
          this.totalRecords = resObj.data.totalElements;
          for (let trans of this.transactionList) {
            trans.companyName = this.getCompanyName(trans);
            trans.amount = formatNumber(trans.amount, 'en-US', '1.2-2');
            trans.fee = formatNumber(trans.fee, 'en-US', '1.2-2');
          }
        }
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    if (!this.isPlatformAdmin) {
      this.selectedCompany = this.authService.getCurrentCompanyId();
    } else {
        this.selectedCompany = null;
    }
    this.selectedUser = null;
    this.selectedField = 'createdAt';
    this.selectedSortOption = 'DESC';
    this.totalRecords = null;
    this.selectedTransactionType = null;
    if (this.allUsersList) {
      this.allUsers = [...this.allUsersList];
    } else {
      this.allUsers = null;
    }
    this.textSearch = null;
    this.table.reset();
    this.filteredValues = this.transactionList;
    this.loadContentsLazy();
  }

    sortF;
    sortingClick(selectedSortOption) {
        this.sortF = this.selectedField;
        const options = {
            field: this.selectedField,
            order: selectedSortOption === 'ASC' ? 1 : -1,
            mode: 'single',
            data: this.filteredValues
        };

        // this.setSortOption(this.selectedField, selectedSortOption);
        this.customSort(options);
    }

    customSort(event) {
        if (this.sortF.length > 0) {
            if (event.data) {
                if (event.field === 'amount' || event.field === 'fee' ) {
                    event.data.sort((row1, row2) => {
                        let val1 = row1[event.field];
                        let val2 = row2[event.field];
                        if (val1?.toLowerCase) {
                            val1 = val1.toLowerCase();
                        }
                        if (val2?.toLowerCase) {
                            val2 = val2.toLowerCase();
                        }
                        if (val1 === val2) {
                            return 0;
                        }
                        let result = -1;

                        if (Number(val1) > Number(val2)) {
                            result = 1;
                        }
                        if (event.order < 0) {
                            result = -result;
                        }
                        return result;
                    });
                } else {
                    event.data.sort((data1, data2) => {
                        const value1 = data1[event.field];
                        const value2 = data2[event.field];
                        let result = null;

                        if (value1 == null && value2 != null) {
                            result = -1;
                        } else if (value1 != null && value2 == null) {
                            result = 1;
                        } else if (value1 == null && value2 == null) {
                            result = 0;
                        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                            result = value1.localeCompare(value2);
                        } else {
                            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                        }
                        return (event.order * result);
                    });
                }
            }
            this.sortF = '';
        }
    }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  getCompanyName(data){
    const company:any = this.allCompanyList.find(c => c.key === data.companyId)
    if(company) return company.value;
  }

  isDisplay() {
    if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) return true;
    return false;
  }

  search() {
    this.loadContentsLazy()
    this.monitoringDetailsService.monitorAction(
      'Wallet Transaction searched',
      new Date(),
      { fund_transferred_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName() },
      "complete",
      'Wallet Transaction searched',
      0
    );
  }
}
