<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'My Wallet' | translate}}</h1>
	<div class="ui-g">
		<div class="ui-g-8 ui-sm-12 p-sm-0 p-lg-2">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-wallet text-primary mr-2"></i>{{'Wallet Contents' | translate}}</span>
				</p-header>
				<div class="ui-g p-4">
					<div class="d-flex align-items-center w-100">
						<div class="w-30">
							<label>{{'Account Balance' | translate}}</label>
							<span style="font-size: 40px; font-weight: 400">{{(accountBalance?.availableBalance) || 0 | currency}}</span>
							<span *ngIf="accountBalance?.pendingBalance" class="px-2 ml-2 badge bg-danger" style="font-size: 13px; position: relative; top: -20px;">Pending: {{(accountBalance?.pendingBalance) || 0 | currency}}</span>
							<br>
							<strong *ngIf="defaultBankAccount" class="pr-5">{{defaultBankAccount.accountNumber}}</strong> 
						</div>
						<div class="w-70">
							<div class="w-100 d-flex align-items-center justify-content-center ml-5">
								<p-radioButton [style]="{'width': '175px'}" *ngIf="customerCard" [(ngModel)]="changeMethodSelected" name="paymentMethod" [value]="customerCard" label="{{selectedMethod?.paymentCardType | titlecase}} Ending {{selectedMethod?.lastFourNumbers}}" [inputId]="customerCard?.id"></p-radioButton>
								<p-radioButton [style]="{'width': '175px'}" *ngIf="customerCard" [(ngModel)]="changeMethodSelected" name="paymentMethod" [value]="'paypal'" label="Paypal"></p-radioButton>
								<p-radioButton [style]="{'width': '175px'}" class="px-2" *ngIf="customerBank" [(ngModel)]="changeMethodSelected" name="paymentMethod" [value]="customerBank" label="Bank account Ending {{selectedMethod?.lastFourNumbers}}" [inputId]="customerBank?.id"></p-radioButton>
							</div>

						</div>
						
					</div>
				</div>
				<p-footer class="d-flex justify-content-left" style="column-gap: 10px;">
					<button class="btn btn-white" [disabled]="loading" (click)="addPaymentMethod()">
						<span class="text-nowrap"><i class="fa-solid fa-glasses text-primary mr-2"></i>{{'Add Payment Method' | translate}}</span>
					</button>
					<button *ngIf="defaultBankAccount" class="btn btn-white" [disabled]="loading">
						<span class="text-nowrap"><i class="fa-solid fa-glasses text-primary mr-2"></i>{{'Account Details' | translate}}</span>
					</button>
					<button *ngIf="!defaultBankAccount || !defaultBankAccount.linkedAccount" class="btn btn-white" (click)="linkAccount()" [disabled]="loading">
						<span class="text-nowrap"><i class="fa-solid fa-link text-primary mr-2"></i>{{'Link Account' | translate}}</span><span *ngIf="defaultBankAccount" class="px-2 ml-2 badge bg-warning text-dark" style="top: -6px;">Unverified</span>
					</button>
					<button *ngIf="defaultBankAccount && defaultBankAccount.linkedAccount" class="btn btn-white"  (click)="unlinkAccount()" [disabled]="loading">
						<span class="text-nowrap"><i class="fa-solid fa-link-slash text-danger mr-2"></i>{{'UnLink Account' | translate}}</span>
					</button>
				</p-footer>
			</p-panel>
			<h1 class="m-0 ml-2 my-4">{{'Recent History' | translate}}</h1>
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'Wallet History' | translate}}</span>
				</p-header>
				<div class="ui-g align-items-center" style="min-height: 228px">
					<div *ngIf="!transactionHistories" class="ui-g-12 text-center py-5">
						<span class="text-muted">{{'There is no history yet' | translate}}</span>
					</div>
					<div *ngIf="transactionHistories" class="ui-g-12 p-0">
						<div class="card add-shadow p-3 m-0" *ngFor="let acc of transactionHistories">
							<div class="d-flex align-items-center w-100">
								<i *ngIf="acc.type === 'debit'" class="fa-solid fa-angles-up text-danger fa-3x mx-3"></i>
								<i *ngIf="acc.type === 'credit'" class="fa-solid fa-angles-down text-success fa-3x mx-3"></i>
								<div class="flex-grow-1 d-flex flex-column align-items-start px-4">
									<h5 class="one-liner m-0">{{acc.title}}</h5> 
									<p class="text-muted m-0">{{acc.date | date :'longDate'}}</p> 
								</div>
								<div class="d-flex align-items-center">
									<span *ngIf="acc.type === 'debit'" class="d-flex text-danger"> -{{acc.amount | currency}} &nbsp;&nbsp;
										<span class="badge bg-{{acc.statusTxt === 'SUCCEEDED' ? 'info' : (acc.statusTxt === 'FAILED' ? 'danger' : 'secondary')}} text-dark" [pTooltip]="acc.failureMessage">{{acc.statusTxt}}</span>
									</span>
									<span *ngIf="acc.type === 'credit'" class="d-flex text-success"> +{{acc.amount | currency}} &nbsp;&nbsp;
										<span class="badge bg-{{acc.statusTxt === 'SUCCEEDED' ? 'info' : (acc.statusTxt === 'FAILED' ? 'danger' : 'secondary')}} text-dark" [pTooltip]="acc.failureMessage">{{acc.statusTxt}}</span>
									</span>
									<span *ngIf="acc.type === 'debit'" class="text-danger">
										-{{(acc.fee ? acc.fee : 0) | currency}} <i class="fa-solid fa-circle-info text-primary ml-2" style="font-size:15px" pTooltip="{{'Processing Fee' | translate}}: {{(acc.fee ? acc.fee : 0) | currency}}"></i>
									</span>
									<span *ngIf="acc.type === 'debit'" class="text-danger">
										-{{(acc.fee ? (acc.amount - acc.fee) : acc.amount) | currency}}
									</span>

									<span *ngIf="acc.type === 'credit'" class="text-danger">
										-{{(acc.fee ? acc.fee : 0) | currency}} <i class="fa-solid fa-circle-info text-primary ml-2" style="font-size:15px" pTooltip="{{'Processing Fee' | translate}}: {{(acc.fee ? acc.fee : 0) | currency}}"></i>
									</span>
									<span *ngIf="acc.type === 'credit'" class="text-success">
										+{{(acc.fee ? (acc.amount - acc.fee) : acc.amount) | currency}}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-panel>
		</div>
		<div class="ui-g-4 ui-sm-12 p-sm-0 p-lg-2">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-money-bill-transfer text-primary mr-2"></i>{{'Transfers' | translate}}</span>
				</p-header>
				<div class="ui-g">

					<div *ngIf="isAdmin()" class="ui-g-12">
						<label>{{'Contractor' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-briefcase"></i>
								</button>
							</div>
							<p-dropdown [options]="contractorAccounts" optionLabel="name" (onChange)="seletectedContractorChange()" placeholder="{{'Select Contractor' | translate}}" [(ngModel)]="seletectedContractor" showClear="true">
							</p-dropdown>
						</div>
					</div>

					<div *ngIf="isAdmin()" class="ui-g-12">
						<label>{{'Payment Type' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="paymentTypeList" (onChange)="paymentTypeChange()" placeholder="{{'select' | translate}}" [(ngModel)]="paymentTypeSelected" [disabled]="!seletectedContractor"></p-dropdown>
						</div>
					</div>

					<div *ngIf="isAdmin()" class="ui-g-12">
						<label>{{'Payment For' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-receipt"></i>
								</button>
							</div>
							<p-dropdown [options]="payForList"  optionLabel="name" (onChange)="payForSelectedChange()" placeholder="Select" [(ngModel)]="payForSelected" [disabled]="!(paymentTypeSelected && (paymentTypeSelected === 'invoice' || paymentTypeSelected === 'contract'))"></p-dropdown>
						</div>
					</div>
	

					<div class="ui-g-12">
						<label>{{'Amount' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-calculator"></i>
								</button>
							</div>
							<input type="text" pInputText placeholder="0.00" [(ngModel)]="transfer.amount" maxlength="10" (keypress)="decimalOnly($event)">
						</div>
					</div>
					
					<div class="ui-g-12">
						<label>{{'Source' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="accountDropdownList"  placeholder="Choose Account" [(ngModel)]="transfer.fromAccount" [disabled]="seletectedContractor"></p-dropdown>
						</div>
					</div>

					<div class="ui-g-12">
						<label>{{'Target' | translate}}</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-folder-tree"></i>
								</button>
							</div>
							<p-dropdown [options]="accountDropdownList"  placeholder="{{'Choose Account' | translate}}" [(ngModel)]="transfer.toAccount" [disabled]="seletectedContractor"></p-dropdown>
						</div>
					</div>
					<div class="ui-g-12 text-center py-4">
						<button (click)="openModal()" [disabled]="transfer.amount <= 0 || (!transfer.fromAccount && !transfer.toAccount) || (transfer.fromAccount === transfer.toAccount)" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
					</div>
				</div>
			</p-panel>
		</div>
	</div>
</div>

<p-dialog header="Confirmation" [style]="{'width':'30%'}" [(visible)]="showConfirmDialog" modal='modal' [modal]='true'>
    <div class="ui-g p-2 confirm">
        <div>
			{{'Are you sure you want to send' | translate}}  {{transfer.amount | currency}} {{'to' | translate}} {{getToAccount()}} ?
        </div>
        <div class="w-100 p-2" *ngIf="transferFeeCashOut && transfer.amount && !seletectedContractor && transfer.toAccount !== ''">
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Amount' | translate}}</div>
                <div class="ui-g-10 p-0">{{transfer.amount | currency}}</div>
            </div>
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Fee' | translate}}</div>
                <div class="ui-g-10 p-0">{{transfer.fee | currency}}</div>
            </div>
            <div class="ui-g">
                <div class="ui-g-2 p-0">{{'Net' | translate}}</div>
                <div class="ui-g-10 p-0">{{transfer.net | currency}}</div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-g-12 p-0" align="right">
            <button type="button" pButton label="Yes" [disabled] = "isDisabled" (click)="accept()"></button>
            <button type="button" pButton label="No" (click)="reject()"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="unlinkAccountDialog" class="modal_in"
    [modal]='true' (onHide)="unlinkAccountDialog = false; this.loading = false">
    <div class="ui-g">
		{{'Are you sure you want to unlink this account' | translate}}
    </div>
    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12">
                <button type="button" pButton label="{{'yes' | translate}}" (click)="acceptUnlinkAccount()"></button>
                <button type="button" pButton label="{{'no' | translate}}" (click)="unlinkAccountDialog = false; this.loading = false"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>