import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeToString'
})
export class TimeToStringPipe implements PipeTransform {

    transform(value: number, args?: any): string {
        value = Number(value) / 1000;

        const h = Math.floor(value / 3600);
        const m = Math.floor(value % 3600 / 60);
        const s = Math.floor(value % 3600 % 60);

        return (h !== 0 ? h + ' hours ' : '') + (m !== 0 ? m + ' minutes ' : '') + (s !== 0 ? s + ' sec' : '');
    }

}
