<form [formGroup]="contractForm" class="p-4">
	<div class="ui-g">
		<div class="ui-g-12 text-center">
			<img class="mt-4" src="https://www.operrgroup.com/images/operr-title.png" style="width: 220px; height:auto">
			<h1 class="m-0">FID Billing Service Contract</h1>
			<span style="font-size: 16px;line-height: 1.4;">30-50 Whitestone Expressway<br>Suite 402, Flushing N.Y. 11354<br>(800) 668-6906</span>
		</div>
	</div>
	<div class="ui-g mt-4" style="row-gap: 50px">
		<div class="ui-g-12 ui-sm-12">
			<div class="ui-g-12">	
				<h1>Sales Agent</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-briefcase text-primary mr-2"></i> Merchant Details</span>
					</p-header>
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Authorized Agent</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="printNameCompany" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameCompany')" style="font-size: 12px !important;">
								{{'validate.thisField' | translate}}
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="emailCompany" pInputText>
							</div>
							<span  class="ui-message ui-messages-error" *ngIf="isRequired('emailCompany') || isEmail('emailCompany')" style="font-size: 12px !important;">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>	
								<p-inputMask class="w-100" formControlName="directPhoneCompany" mask="(999) 999-9999" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneCompany')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>	
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker1" formControlName="dateCompany"
										(focus)="picker1.open()">
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker1 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateCompany')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
			<div class="ui-g-12 pt-5">
				<h1>Customer</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-handshake-alt text-primary mr-2"></i> Client Details</span>
					</p-header>
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Client Name:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signature"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="clientName" pInputText>
							</div>	
							<span class="ui-message ui-messages-error" *ngIf="isRequired('clientName')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Address:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="addressClient" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('addressClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>              
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Phone:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-phone"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="phoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('phoneClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Authorized Agent:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="printNameClient" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="emailClient" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('emailClient') || isEmail('emailClient')" style="font-size: 12px !important;">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone #:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="directPhoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneClient')"  style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker2" formControlName="dateClient" (focus)="picker2.open()">
									<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker2 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateClient')"  style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
		</div>

		<div class="ui-g-12 ui-sm-12" id="contract-area2">
			<div class="ui-g-12">
				<h1 class="m-0 mb-2">Service Agreement</h1>
				<p class="m-0 mb-4">Operr Technologies, Inc., (hereafter “Operr” or "Merchant") is the service provider for the services herein, located at 30-50 Whitestone Expressway, Suite 402, Flushing NY 11354, is licensed by the New York State Department of Health to provide billing services to third parties.</p>
				
				<div class="" style="font-size: 16px;line-height: 1.4;">Client is a For-Hire Vehicle (FHV) base with a base name and number of...</div>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>Base Name (Client)</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-taxi"></i>
								</button>
							</div>
							<input type="text" formControlName="clientNameContract" pInputText placeholder="Client Base Name">
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('clientNameContract')" style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>Base Number (T&LC)</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-taxi"></i>
								</button>
							</div>
							<input type="text" formControlName="baseNumber" pInputText placeholder="T&LC Base Number">
						</div>
					</div>
				</div>
				
				
				<div class="mt-5" style="font-size: 16px;line-height: 1.4"><b>Both parties, Operr and Client enter into the following service agreement:</b></div>

				<h4 class="mb-2 mt-5">Services</h4>
				<p class="m-0 mb-4">Client Shall Pay the Following Fees for Operr’s Billing Services:</p>
				<ul>
					<li class="mb-4">
						<p class="m-0" style="font-size: 16px">Percentage of total funds received from Fidelis (FID) preliminary billing in the amount of...</p>
						<p class="m-0 mb-2 asterisk-text">This includes sign-off, submission, eligibility check. The Client has the option to do the sign off on their own, or Operr can do the sign off on their behalf, and the price will remain unchanged. Client is required to provide the toll fee information and any group trips while they are providing the sign off information to Operr. Client needs to confirm that all completed information is accurate and true to the best of their knowledge.</p>
						<div class="input-group" style="max-width: 100px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-percent"></i>
								</button>
							</div>
							<input type="text" formControlName="totalReceivedMoney" pInputText>
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('totalReceivedMoney')" style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
	
					</li>
					<li class="mb-4">
						<p class="m-0" style="font-size: 16px">Percentage of money claimed from Fidelis (FID) in the amount of...</p>
						<p class="m-0 mb-2 asterisk-text">This includes but is not limited to look back service, appeals, pre-corrections, corrections, re-attestations, reclaims, etc.) which are billed by Operr on behalf of the Client.</p>
						<div class="input-group" style="max-width: 100px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-percent"></i>
								</button>
							</div>
							<input type="text" formControlName="operrClaimed" pInputText>
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('operrClaimed')" style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
					</li>
					<li class="mb-2">
						<p class="m-0" style="font-size: 16px">Billing service rates under 1.1 have to be re-negotiated if client does not comply with 1.3 clause.</p>
					</li>
					<li>
						<p class="m-0" style="font-size: 16px">Within two weeks of effectiveness of the contract, the above rate may be subject to renegotiation.</p>
					</li>
				</ul>

				<h4 class="mb-4 mt-5">Payment Terms</h4>
				<p class="m-0 mb-2"><b>1.</b> Invoices will be issued according to the terms set forth in this Service Agreement. All invoices are payable by check or bank transfer, plus sales/service tax. Pricing is set forth in Section 1, above. Tax is applied if applicable. Fees are calculated prior to deduction of any necessary third-party service fee, meaning if there's any applicable fees for bank transfer or any other fees associated with payment then the base will be responsible for paying related fees.</p>
				<p class="m-0 mb-2"><b>2.</b> Every two weeks bundled recurring service charges will become due on the 3rd day after the invoice date.</p>
				<p class="m-0 mb-2"><b>3.</b> The client has been advised that Operr may need multiple attempts at its billing service in order to recover all payments.</p>
				<p class="m-0 mb-2"><b>4.</b> Any discrepancy between the amount identified and the amount actually received will serve as a credit towards additional billing services and will be adjusted in subsequent rounds of services.</p>
				<p class="m-0 mb-2"><b>5.</b> While Operr will exercise its best efforts to locate all of the entitled money through this billing service, it does not in any way guarantee that all of client’s identified monies owed will be recovered in full by Operr. However, whether Operr may continuously provide the billing service and/or elect to stop providing billing service will remain within the full discretion of Operr.</p>
				<p class="m-0 mb-2"><b>6.</b> The client will take responsibility to provide true and accurate data for billing services. Operr will not take responsibility for verifying whether billing data is true or not, which is the sole responsibility of the Client. The Client acknowledges that it constitutes fraud to provide false billing data and assumes legal responsibility for any false data provided to Operr.</p>

				
				<h4 class="mb-2 mt-4">Disputes</h4>
				<p class="m-0">Any controversy or claim including, but not limited to, loss or damage arising out of or relating to this Service Agreement, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association within the City of New York. The parties may agree in writing to waive this provision in favor of litigation in New York State courts, County of Queens. Such waiver must be mutually approved in writing.</p>
				<h4 class="mb-2 mt-4">Termination</h4>
				<p class="m-0">Written notice by certified mail must be provided by Client to Operr thirty (30) days before the termination of the services.</p>
				<h4 class="mb-2 mt-4">Agreement</h4>
				<p class="m-0">The terms set forth in this Service Agreement constitute the entire Agreement.</p>
				<h4 class="mb-2 mt-4">Effectiveness</h4>
				<p class="m-0 mb-2">Upon both parties' signatures, the above Service Agreement is agreed upon and is effective from...</p>
				<div class="input-group mt-2" style="max-width: 260px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<mat-form-field  placeholder="Select Effective Date">
						<input matInput [ngxMatDatetimePicker]="picker3" formControlName="effectiveDate" (focus)="picker3.open()">
						<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
						<ngx-mat-datetime-picker #picker3 [enableMeridian]="true"></ngx-mat-datetime-picker>
					</mat-form-field>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="isRequired('effectiveDate')" style="font-size: 12px !important;">
					{{'validate.thisField' | translate}}
				</span>
			
				<h1 class="mt-5">Signatures</h1>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>Merchant's Signature:</label>   
						<div class="input-group d-flex">
							<div id="companySignpad">
								<signature-pad #companySignpad [options]="signaturePadConfig" height="70" width="263"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign()" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser px-2"  style="cursor:pointer"></i>    
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>Client's Signature:</label>  
						<div class="input-group d-flex">
							<div id="clientSignpad">
								<signature-pad #clientSignpad [options]="signaturePadConfig" height="70" width="263"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign('client')" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
<div class="ui-g pb-5">
	<div class="ui-g-12 text-center">
		<button (click)="cancelEditing()" *ngIf="disableEditingContract" class="btn btn-danger btn-lg mx-1"><i class="fa-solid fa-xmark mr-2"></i> {{'Cancel' | translate}}</button>	
		<button (click)="createPdf()" *ngIf="!disableEditingContract" class="btn btn-purple btn-lg mx-1"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</button>
		<button (click)="edit()"*ngIf="disableEditingContract && showEditButton" class="btn btn-info btn-lg mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i> {{'Edit' | translate}}</button>	
		<button (click)="generateContract()" *ngIf="!disableEditingContract" class="btn btn-primary btn-lg mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Complete' | translate}}</button>
	</div>
</div>