<div class="d-flex align-items-center folder-item bottom-border p-3" [class.active]="isActive">
	<div class="flex-grow-1 d-flex align-items-center">
		<i *ngIf="data.name === 'All Notes'" class="fa-solid fa-box-archive text-secondary mr-2"></i>
		<i *ngIf="data.name !== 'All Notes'" class="fa-solid fa-folder text-secondary mr-2"></i>
		<span class="one-liner" style="margin-right: 40px">{{data.name}}</span>
	</div>
    <div class="folder-action-icon align-items-center pr-2" *ngIf="!data.showAll && data.owner">
		<i class="fa-solid fa-share-alt note-item-icon text-primary mx-1" (click)="share($event)" pTooltip="{{'Share' | translate}}" tooltipPosition="left"></i>
		<i class="fa-solid fa-pen-to-square note-item-icon text-primary mx-1" (click)="editName($event)" pTooltip="{{'Edit' | translate}}" tooltipPosition="left"></i>
		<i class="fa-solid fa-xmark text-danger note-item-icon mx-1" (click)="delete($event)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
    </div>
</div>