import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentProfileService} from '../../../../service/payment-profile.service';
import {AuthService} from '../../../../../shared/service/auth/auth.service';
import {AesUtilService} from '../../../../../shared/service/aes-util.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng';
import {MonitoringDetailsService} from '../../../../../admin/process-monitor/service/monitoring-details.service';
import {PaymentCardDefaultService} from '../../../../service/payment-card-default.service';
import {PaymentNotificationService} from '../../../../service/payment-notification.service';
import * as _moment from 'moment';

declare var google: any;
@Component({
  selector: 'app-one-time-amex-card',
  templateUrl: './one-time-amex-card.component.html',
  styleUrls: ['./one-time-amex-card.component.scss']
})
export class OneTimeAmexCardComponent implements OnInit {

  @Output()
  displayChange = new EventEmitter();
  amexCardForm: UntypedFormGroup;
  months = [
    {label: '01', value: '01'},
    {label: '02', value: '02'},
    {label: '03', value: '03'},
    {label: '04', value: '04'},
    {label: '05', value: '05'},
    {label: '06', value: '06'},
    {label: '07', value: '07'},
    {label: '08', value: '08'},
    {label: '09', value: '09'},
    {label: '10', value: '10'},
    {label: '11', value: '11'},
    {label: '12', value: '12'},
  ];
  registerCustomerPaymentId = null;
  @Input()
  paymentCardType;
  @Input() key;
  cardNumber;
  cardTokenCode;
  cardToken = null;
  isStored: boolean = false;
  messageMonthError: string = '';
  messageYearError: string = '';
  msgs = [];
  timeSpent = new Date();

  defaultCard;
  showConfirmAutoPayReminderDialog;
  showCard = true;
  showCVV = false;

  @Input()
  functionImport;

  @Output()
  getDataSave = new EventEmitter();

  constructor(private fb: UntypedFormBuilder,
              private paymentProfileService: PaymentProfileService,
              private authService: AuthService,
              private aesUtilService: AesUtilService,
              private router: Router,
              private messageService: MessageService,
              private monitoringDetailsService: MonitoringDetailsService,
              private paymentCardDefaultService: PaymentCardDefaultService,
              private paymentNotificationService: PaymentNotificationService) { }

  ngOnInit() {
    // this.getPaymentProfile(this.authService.getUserInfo().adminId);
    this.amexCardForm = this.fb.group({
      cardNumber: [null, [Validators.required]],
      cardName: [null, [Validators.required]],
      cardMonth: [null, [Validators.required]],
      // cardYear: [null, [Validators.required, Validators.maxLength(4)]],
      cardYear: [],
      cvcNumber: [null, [Validators.required,Validators.maxLength(4)]],
      address1: [],
      address2: [],
      city: [],
      state: [],
      zipcode: [],
      country: [],
      saveCardDetails: [],
    });

    this.amexCardForm.valueChanges.subscribe(e => {
      if (e) {
      }
    });

  }

  getPaymentProfile(userId) {
    this.paymentProfileService.getPaymentProfile(this.paymentCardType, userId, 'AUTHORIZE_NET').subscribe((response: any) => {
      if (response) {
        if (!response.securelyStoreCard) {
          this.paymentProfileService.deleteCard(response.registerPaymentId, response.cardToken, this.paymentCardType).subscribe(res => {
          }, (err) => {
            this.msgs.push({ severity: 'error', summary: 'ERROR', detail: err.error.message });
          })
        } else {
          this.registerCustomerPaymentId = response.registerPaymentId;
          this.cardNumber = 'XXXX-XXXX-XXX-' + response.lastFourNumbers;
          this.cardTokenCode = response.cardTokenCode;
          this.cardToken = response.cardToken;
        }

      }
    }, (err) => {
      this.msgs.push({ severity: 'error', summary: 'ERROR', detail: err.error.message });
    })
  }

  addPaymentMethod() {
    const formValues: any = this.amexCardForm.getRawValue();
    if (!formValues.cardNumber || !formValues.cvcNumber || !formValues.cardYear || !formValues.cardMonth) {
      this.msgs.push({ severity: 'warning', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
      return;
    }
    const cardDataStr = `cardNumber=${formValues.cardNumber ? formValues.cardNumber.replace(/\D+/g, '') : ''};cvcNumber=${formValues.cvcNumber};
    year=${formValues.cardYear};month=${formValues.cardMonth};zipCode=${formValues.zipcode};cardName=${formValues.cardName};address=${formValues.address1};city=${formValues.city};country=${formValues.country};email=${formValues.email};phone=${formValues.phone}`;
    const encryptData = this.aesUtilService.encrypt(cardDataStr);
    const customer = this.authService.getUserInfo();

    const payload = {
      customerRegisterPaymentId: this.registerCustomerPaymentId,
      paymentCardType: this.paymentCardType,
      ownerId: customer.adminId,
      gatewayType: 'AUTHORIZE_NET',
      encryptData: encryptData,
      userName: this.authService.getUserInfo().userName,
      securelyStoreCard: formValues.saveCardDetails,
    };

    this.paymentProfileService.createPaymentProfile(payload).subscribe((response: any) => {
      if (response && response.cardToken) {
        if (formValues.securelyStoreCard) {
          this.msgs.push({ severity: 'success', summary: 'SUCCESS', detail: 'Information Saved Successfully' });
        } else {
          this.msgs.push({ severity: 'success', summary: 'SUCCESS', detail: 'Information Verified Successfully' });
        }
        this.isStored = formValues.securelyStoreCard;
        this.registerCustomerPaymentId = (response as any).registerPaymentId;
        this.cardNumber = 'XXXX-XXXX-XXX-' + (response as any).lastFourNumbers;
        this.cardTokenCode = response.cardTokenCode;
        this.cardToken = response.cardToken;
      } else {
        this.msgs.push({ severity: 'error', summary: 'ERROR', detail: response.message });
      }
    }, (err) => {
      this.msgs.push({ severity: 'error', summary: 'ERROR', detail: err.error.message });
    })
  }

  onBack(){
    this.displayChange.emit(true);
  }

  cancelToHomepage(event) {
    if (event) {
      this.cardToken = null;
      this.displayChange.emit(true);
    }
  }

  validateYear(event) {
    const momentYear = _moment();
    const valueyear = this.amexCardForm.get('cardYear').value;
    this.messageYearError = '';
    if (valueyear < momentYear.year()) {
      this.messageYearError = 'The year should be greater than current year'
    }
  }

  validateMonth(event) {
    this.messageMonthError = '';
    const valueyear = this.amexCardForm.get('cardYear').value;
    if (!valueyear) {
      this.messageMonthError = 'You should choose the year first';
      this.amexCardForm.get('cardMonth').setValue(null);
    } else {
      const momentYear = _moment();
      if (valueyear < momentYear.year()) {
        this.messageMonthError = 'The year should be greater than current year'
      }

      const valueMonth = this.amexCardForm.get('cardMonth').value;
      if (valueMonth*1 <= momentYear.month() + 1 && valueyear <= momentYear.year()) {
        this.messageMonthError = 'The month should be greater than current month'
        this.amexCardForm.get('cardMonth').setValue(null);
      }
    }
  }

  async addPaymentProfile() {

    if (this.functionImport === 'SIGN_IN') {
      console.log('addPaymentProfile functionImport: ', this.functionImport);
      const formValues: any = this.amexCardForm.getRawValue();
      const cardExpiryTime = _moment(formValues.cardMonth);
      const month = cardExpiryTime.month() + 1;
      const realmonth = month >= 10 ? month : '0' + month;
      if (!formValues.cardNumber || !formValues.cvcNumber || !formValues.cardMonth) {
        this.messageService.clear();
        this.messageService.add({ severity: 'warning', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
        return;
      }
      const cardDataStr = `${formValues.cardName};${formValues.cardNumber ? formValues.cardNumber.replace(/\s/g, '') : ''};${formValues.cvcNumber};${cardExpiryTime.year()};${realmonth};${formValues.zipcode};${formValues.address1};${formValues.city};${formValues.country};`;
      const payload = {
        formValues: formValues,
        paymentCardType: this.paymentCardType,
        cardDataStr: cardDataStr,
      };
      this.getDataSave.emit(payload);
      return;
    }

    const formValues: any = this.amexCardForm.getRawValue();
    const cardExpiryTime = _moment(formValues.cardMonth);
    const month = cardExpiryTime.month() + 1;
    const realmonth = month >= 10 ? month : '0' + month;
    if (!formValues.cardNumber || !formValues.cvcNumber || !formValues.cardMonth) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warning', summary: 'WARNING', detail: 'Having incorrect data, please help check' });
      return;
    }
    const cardDataStr = `${formValues.cardName};${formValues.cardNumber ? formValues.cardNumber.replace(/\s/g, '') : ''};${formValues.cvcNumber};${cardExpiryTime.year()};${realmonth};${formValues.zipcode};${formValues.address1};${formValues.city};${formValues.country};`;
    const customer = this.authService.getUserInfo();
    const encrtyptedData: any = await this.paymentProfileService.encryptCardDetails(cardDataStr, this.key).toPromise()

    const payload = {
      paymentCardType: this.paymentCardType,
      encryptDataCard: encrtyptedData.data,
      /*userId: this.authService.getUserInfo().adminId,
      firstName:this.authService.isClientRole() ?  customer.name : customer.firstName ,
      lastName: this.authService.isClientRole() ?  customer.name : customer.lastName,
      email: customer.email,*/
      userType: this.authService.getPaymentUserType(),
      phoneNumber:"",
    };
    return payload;
    /*await this.paymentProfileService.createNewPaymentProfile(payload, this.key).subscribe((response: any) => {
        if (response && response.data.cardInfo && response.data.user) {
            this.messageService.clear();
            this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Information Saved Successfully' });
            this.defaultCard = null;
            if (this.authService.isClientRole()) {
                this.paymentCardDefaultService.getDefaultCard({
                    roleId: this.authService.getRoleLevel(),
                    userId: this.authService.getCurrentLoggedInId(),
                    companyId: this.authService.getCurrentCompanyId()
                  }).subscribe(r => {
                    const resObj: any = r;
                    if (resObj.status === 'SUCCESS' && resObj.data && (!resObj.data.autoPay || (!resObj.data.bankId && !resObj.data.cardId))) {
                        this.defaultCard = resObj.data;
                        if (!this.defaultCard.cardId && !resObj.data.bankId) {
                            this.defaultCard.cardId = response.data.cardInfo.id;
                        }
                        this.defaultCard.paymentCardType = payload.paymentCardType;
                        this.showConfirmAutoPayReminderDialog = true;
                    } else {
                        this.onNewCardAdded();
                    }
                }, () => this.onNewCardAdded());
            } else {
                this.onNewCardAdded();
            }
        } else {
            const args = {
                failed_to_add_new_card_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
                card_type: this.paymentCardType
            }
            this.saveMonitorDetails(args, 'Failed to Add New Card', (r) => {
                this.paymentNotificationService.sendPaymentFailedEmail(r, response.data);
            });
            this.messageService.clear();
            this.messageService.add({ severity: 'error', summary: 'Invalid card info', detail: 'Invalid card information. Please check!' });
        }
    }, (err) => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: err.error.message });
        const args = {
            failed_to_add_new_card_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
            card_type: this.paymentCardType
        }
        this.saveMonitorDetails(args, 'Failed to Add New Card', (r) => {
            this.paymentNotificationService.sendPaymentFailedEmail(r, err.error.message);
        });
    })*/
  }
  ngAfterViewInit() {
    const address1Box = new google.maps.places.SearchBox(document.getElementById("address1"));
    address1Box.addListener('places_changed', () => {
      const places = address1Box.getPlaces();
      if (places.length == 0) {
        return;
      }
      this.handlePlaceDetail(places[0])
    });
    const address2Box = new google.maps.places.SearchBox(document.getElementById("address2"));
    address2Box.addListener('places_changed', () => {
      const places = address2Box.getPlaces();
      if (places.length == 0) {
        return;
      }
      this.amexCardForm.patchValue({
        address2: places[0].formatted_address
      })
    });
  }
  handlePlaceDetail(placeDetail){
    const address_components = placeDetail.address_components
    const countryObj = address_components.find(x => x.types.includes('country'));
    const cityObj = address_components.find(x => x.types.includes("sublocality_level_1"));
    const stateObj = address_components.find(x => x.types.includes("administrative_area_level_1"));
    const zipCode = address_components.find(x => x.types.includes("postal_code"));
    this.amexCardForm.patchValue({
      address1: placeDetail.name,
      city: cityObj ? cityObj.long_name : '',
      country: countryObj ? countryObj.long_name : '',
      zipcode: zipCode ? zipCode.long_name : '',
      state:stateObj?stateObj.long_name:'',
    })
  }

  saveMonitorDetails(args, type, callback?: Function){
    this.monitoringDetailsService.monitorAction(
        `${type}`,
        this.timeSpent,
        args,
        'complete',
        `${type}`,
        0,
        callback
    );
  }


  onNewCardAdded() {
    setTimeout(() => this.router.navigate(['app/payments/detail']), 1000);
    const args = {
      new_card_added_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName(),
      card_type: this.paymentCardType
    }
    this.saveMonitorDetails(args, 'New Card Added')
  }

  acceptAutoPay() {
    if (this.defaultCard) {
      this.defaultCard.autoPay = true;
      this.defaultCard.roleId = this.authService.getRoleLevel(),
          this.defaultCard.userId = this.authService.getCurrentLoggedInId(),
          this.defaultCard.clientName = this.authService.getCurrentUsername()
      this.paymentCardDefaultService.setDefaultCard(this.defaultCard).subscribe(() => {
        this.showConfirmAutoPayReminderDialog = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auto pay active successfully' });
        this.onNewCardAdded();
      }, () => this.onNewCardAdded());
    } else {
      this.onNewCardAdded();
    }
  }

  rejectAutoPay() {
    this.defaultCard = null;
    this.showConfirmAutoPayReminderDialog = false;
    this.onNewCardAdded();
  }

  closeModal() {
    this.defaultCard = null;
    this.showConfirmAutoPayReminderDialog = false;
    this.router.navigate(['app/payments/detail']);
  }

  validateCardNumber(event) {
    this.messageMonthError = '';
    const cardExpiryTime = _moment(this.amexCardForm.get('cardMonth').value);
    const momentYear = _moment();
    const valueMonth = cardExpiryTime.month() + 1;
    const valueYear = cardExpiryTime.year();
    console.log(cardExpiryTime);
    if (valueMonth*1 <= momentYear.month() + 1 && valueYear <= momentYear.year()) {
      this.messageMonthError = 'The month should be greater than current month';
    }
    if (this.functionImport === 'SIGN_IN') {
      const formValues: any = this.amexCardForm.getRawValue();
      this.getDataSave.emit(formValues);
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toggleCard() {
    this.showCard = !this.showCard;
  }
  toggleCVV() {
    this.showCVV = !this.showCVV;
  }

  changeValueForm() {
    if (this.functionImport === 'SIGN_IN') {
      const formValues: any = this.amexCardForm.getRawValue();
      this.getDataSave.emit(formValues);
    }
  }

}
