export const RoleLevel = {
    ROLE_ADMIN: 1,
    ROLE_SUB_ADMIN: 2,
    ROLE_COMPANY_ADMIN: 3,
    ROLE_SUB_COMPANY_ADMIN: 4,
    ROLE_AGENCY_ADMIN: 5,
    ROLE_SUB_AGENCY_ADMIN: 6,
    ROLE_EMPLOYEE: 7,
    ROLE_FRONT_DESK: 8,
    ROLE_CLIENT: 9,
    GUESS: 10,
    ROLE_CONTRACTOR: 13,
    ROLE_CHILD_CLIENT: 14
};
