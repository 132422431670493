import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { VisaCardComponent } from './component/visa-card/visa-card.component';
import { TransferCardComponent } from './component/transfer-card/transfer-card.component';
import { EcheckCardComponent } from './component/echeck-card/echeck-card.component';
import { PaymentMethodComponent } from './component/payment-method/payment-method.component';
import { PaymentDetailsComponent } from './component/payment-details/payment-details.component';
import { EsignatureDialogComponent } from './component/esignature-dialog/esignature-dialog.component';
import { PaymentConfirmationDialogComponent } from './component/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { EcheckFundsComponent } from './component/echeck-funds/echeck-funds.component';
import { TransferFundsComponent } from './component/transfer-funds/transfer-funds.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AmexCardComponent } from './component/amex-card/amex-card.component';
import { PaymentProfileService } from './service/payment-profile.service';
import { AesUtilService } from '../shared/service/aes-util.service';
import { PaymentLayoutComponent } from './component/payment-layout/payment-layout.component';
import { PaymentInvoiceListComponent } from './component/payment-invoice-list/payment-invoice-list.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaymentMethodService } from './service/payment-method.service';
import { PaymentInvoiceService } from './service/payment-invoice.service';
import { PaymentOldInvoiceListComponent } from './component/payment-old-invoice-list/payment-old-invoice-list.component';
import { ParkingInvoiceListComponent } from './component/parking-invoice-list/parking-invoice-list.component';
import { PaymentFollowUpNoteListComponent } from './component/payment-follow-up-note-list/payment-follow-up-note.component';
import { MentionModule } from 'angular-mentions';
import { PaymentAvailableCreditListComponent } from './component/payment-available-credit-list/payment-available-credit.component';
import { PaymentDetailsListComponent } from "./component/payment-details-list/payment-details-list.component";
import { PaymentTransactionService } from "./service/payment-transaction.service";
import { PaymentCardDefaultService } from "./service/payment-card-default.service";
import { ViewPaymentMethodListComponent } from './component/view-payment-method-list/view-payment-method-list.component';
import { NgxCaptureModule } from 'ngx-capture';
import { PaymentNotificationService } from './service/payment-notification.service';
import { WalletComponent } from './component/wallet/wallet.component';
import { WalletTransactionComponent } from './component/wallet-transaction/wallet-transaction.component';
import { WalletTransactionService } from "./service/wallet-transaction.service";
import { PaymentAvailabilityComponent } from './component/payment-availability/payment-availability.component';
import { ClientSelfPaymentComponent } from './component/client-self-payment/client-self-payment.component';
import { InvoiceVerificationComponent } from './component/invoice-verification/invoice-verification.component';
import { PaymentFollowUpHistoryComponent } from './component/payment-follow-up-history/payment-follow-up-history.component';
import { FollowUpBillingInvoiceService } from './service/follow-up-billing.service';
import { InvoiceSummaryComponent } from './component/invoice-summary/invoice-summary.component';
import { ExternalPaymentService } from './service/external-payment.service';
import { OneTimePaymentMethodComponent } from './component/one-time-payment/one-time-payment-method/one-time-payment-method.component';
import { OneTimeAmexCardComponent } from './component/one-time-payment/one-time-payment-method/one-time-amex-card/one-time-amex-card.component';
import { OneTimeVisaCardComponent } from './component/one-time-payment/one-time-payment-method/one-time-visa-card/one-time-visa-card.component';
import { OneTimeTransferCardComponent } from './component/one-time-payment/one-time-payment-method/one-time-transfer-card/one-time-transfer-card.component';
import { OneTimeEcheckCardComponent } from './component/one-time-payment/one-time-payment-method/one-time-echeck-card/one-time-echeck-card.component';
import { PaymentDetailsNewComponent } from './component/payment-details-new/payment-details-new.component';
import { EmployeeService } from 'app/employee/service/employee.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { ClientService } from 'app/crm/service/client.service';
import { MessageService } from 'primeng';
import {NgxPrintModule} from 'ngx-print';

@NgModule({
    imports: [
        CommonModule,
        PaymentRoutingModule,
        CommonModule,
        SharedModule,
        PrimeNgModule,
        ProgressSpinnerModule,
        SignaturePadModule,
        MentionModule,
        NgxCaptureModule,
        NgxPrintModule
    ],
    declarations: [
        VisaCardComponent,
        TransferCardComponent,
        EcheckCardComponent,
        PaymentMethodComponent,
        PaymentDetailsComponent,
        EsignatureDialogComponent,
        PaymentConfirmationDialogComponent,
        EcheckFundsComponent,
        TransferFundsComponent,
        AmexCardComponent,
        PaymentLayoutComponent,
        PaymentInvoiceListComponent,
        PaymentOldInvoiceListComponent,
        ParkingInvoiceListComponent,
        PaymentFollowUpNoteListComponent,
        PaymentAvailableCreditListComponent,
        PaymentDetailsListComponent,
        ViewPaymentMethodListComponent,
        WalletComponent,
        WalletTransactionComponent,
        PaymentAvailabilityComponent,
        ClientSelfPaymentComponent,
        InvoiceVerificationComponent,
        PaymentFollowUpHistoryComponent,
        InvoiceSummaryComponent,
        OneTimePaymentMethodComponent,
        OneTimeAmexCardComponent,
        OneTimeVisaCardComponent,
        OneTimeTransferCardComponent,
        OneTimeEcheckCardComponent,
        PaymentDetailsNewComponent
    ],
    providers: [
        PaymentProfileService,
        AesUtilService,
        PaymentMethodService,
        PaymentInvoiceService,
        PaymentTransactionService,
        PaymentCardDefaultService,
        PaymentNotificationService,
        WalletTransactionService,
        FollowUpBillingInvoiceService,
        ExternalPaymentService,
        EmployeeService,
        DropDownsService,
        ClientService,
        CurrencyPipe,
        MessageService
    ],
    exports: [
        VisaCardComponent,
        PaymentMethodComponent,
        AmexCardComponent,
        TransferCardComponent,
        EcheckCardComponent,
        PaymentDetailsComponent,
        PaymentConfirmationDialogComponent,
        PaymentDetailsNewComponent
    ],
})
export class PaymentModule { }
