import { AbstractService } from "../../shared/service/abstract.service";
import { BillingNote } from "../model/billingnote.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../../shared/model/constants";
import { Observable } from "rxjs";
import { BillingPayment } from "../model/billingpayment.model";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentDetailsService extends AbstractService<BillingPayment>
{
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.BILLINGPAYMENT, '');
    }

    getPaymentDetails(id: number): Observable<any[]> {

        return this._http.get<any[]>(`${this.baseUrlV2 + Constants.URI.BILLING.GETPAYMENTSLIST}/${id}`).pipe(map(resp => resp));
    }
}