<div class="input-group w-100">
	<div class="input-group-prepend p-0">
		<button [disabled]="inputControl.disabled" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
			<i class="fa-solid fa-folder-tree"></i>
		</button>
	</div>
	<p-multiSelect [options]="options" [formControl]="inputControl"
				[filter]="false"
				(onChange)="onSelectionChange($event)"
				[disabled]="inputControl.disabled"
				(onPanelShow)="showPanel()"
				class="w-100">
				<ng-template pTemplate="item" let-item>
					<span class="text-truncate">{{item.label | translate}}</span>
				</ng-template>
				<ng-template let-item pTemplate="selectedItem">
					<span class="one-liner">{{item.label | translate}}</span>
				</ng-template>
	</p-multiSelect>
</div>