import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { VALIDATOR_ERROR_MESSAGE, ValidatorError } from '../validator/constant';

export function inputValidationMessage(control: AbstractControl): string {
  let validationMessage = '';

  if (control) {
    if (control.hasError('duplicatePassword')) {
      validationMessage = 'Password don\'t match';
    }

    if (control.hasError('medicaidProviderNumberTaken')) {
      validationMessage = 'Medic Aid Provider Number is already Exists';
    }

    if (control.hasError('dispatchingLicenseNumTaken')) {
      validationMessage = 'Dispatching License Number is already Exists';
    }

    if (control.hasError('irsRegisteredBusinessNameTaken')) {
      validationMessage = 'IRS Registered Business Name is already Exists';
    }

    if (control.hasError('etinTaken')) {
      validationMessage = 'ETIN is already Exists';
    }

    if (control.hasError('npiTaken')) {
      validationMessage = 'NPI already Exists';
    }

    if (control.hasError('taxIdTaken')) {
      validationMessage = 'Tax Id is already Exists';
    }

    if (control.hasError('fidEmailTaken')) {
      validationMessage = 'FID email is already Exists';
    }

    if (control.hasError('required')) {
      validationMessage = 'Please provide a value';
    }

    if (control.hasError('minlength')) {
      validationMessage = `Minimum length ${control.errors?.minlength.requiredLength}`;
    }

    if (control.hasError('maxlength')) {
      validationMessage = `Maximum length ${control.errors?.maxlength.requiredLength}`;
    }

    if (control.hasError('min')) {
      validationMessage = `Minimum value ${control.errors?.min.min}`;
    }

    if (control.hasError('max')) {
      validationMessage = `Maximum value ${control.errors?.max.max}`;
    }

    if (control.hasError('email') || control.hasError('validateEmail')) {
      validationMessage = 'Please enter a valid email address';
    }

    if (control.hasError('alphabet')) {
      validationMessage = 'Please enter alphabet letters';
    }

    if (control.hasError('alphanumeric')) {
      validationMessage = 'Please enter number or alphabet letters';
    }

    if (control.hasError('numeric')) {
      validationMessage = 'Please enter number';
    }

    if (control.hasError('whitespace')) {
      validationMessage = 'Not allow space in the beginning or the end';
    }

    if (control.hasError('pattern')) {
      validationMessage = 'No Negative Numbers';
    }

    if (control.hasError('stockperday')) {
      validationMessage = "'Stock per day' should be greater than 'Limit per order'";
    }

    if (control.hasError('positiveNumeric')) {
      validationMessage = 'Please enter positive number';
    }

    if (control.hasError('existingName')) {
      validationMessage = 'Agency name is existed';
    }


    validationMessage =
      control.hasError(ValidatorError.USERNAME) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.USERNAME] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_USERNAME) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_USERNAME] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_EMAIL) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_EMAIL] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_EXTENSION) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_EXTENSION] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_PHONE) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_PHONE] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_FLEET_NUMBER) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_FLEET_NUMBER] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.USERNAME_CHAR) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.USERNAME_CHAR] : validationMessage;
    validationMessage =
      control.hasError(ValidatorError.EXISTED_NAME) ? VALIDATOR_ERROR_MESSAGE[ValidatorError.EXISTED_NAME] : validationMessage;
  }

  return validationMessage;
}

export function dummyValidator(control: AbstractControl) {
  return (c: UntypedFormControl) => {
    return control.valid ? null : {invalid: true};
  };
}

export function setDummyError(control: AbstractControl) {
  if (control) {
    control.setErrors({invalid: true});
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched)); // || isSubmitted));
  }
}
