export class CallLogModel {
	id: number;
	did: string;
	dateTime: number | Date = new Date();
	duration: string;
	otherEndName: string;
	otherEndDid: string;
	callType: string;
	createdAt: number | Date = new Date();
	updatedAt: number | Date = new Date();
}
