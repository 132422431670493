import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter
} from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
  file: File,
  url: SafeUrl
}
@Directive({
  selector: '[appFileDrag]'
})
export class FileDragDirective {

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  timeout: any;
  constructor(private sanitizer: DomSanitizer) { }

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById('upload-drop-content').style.display = 'flex'
    this.timeout = setTimeout(() => {
      document.getElementById('upload-drop-content').style.display = 'none'
    }, 1000);

  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    // document.getElementById('upload-drop-content').style.display='none'
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById('upload-drop-content').style.display = 'none'
    clearTimeout(this.timeout);
    let files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }

}
