import { HttpClient , HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../app/shared/model/constants';
import { AbstractService } from '../../../../app/shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PotentialClient } from '../model/potential-client.model';
import { Contact } from "../../model/contact";
import { environment } from 'environments/environment';
import { Response } from "../../../shared/model/response";

@Injectable({
  providedIn: 'root'
})
export class PotentialClientService extends AbstractService<PotentialClient> {
  notificationUrl: string;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.POTENTIAL_CLIENT, '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  search(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  updateClient(entity: any): Observable<any> {
    const url = this.baseUrlV2 + entity.id;
    return this._http.put<any>(url, entity).pipe(map(res => res));
  }

  exportPdf(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}pdf`, options).pipe(map(res => res));
  }

  saveMultipleRows(data: Contact[]): Observable<Response<Contact>> {
    return this._http.post<Response<Contact>>(`${this.baseUrlV2}saveMultipleRows`, data).pipe(map(resp => resp));
  }

  clientByCampaignId(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}find-according-to-marketing`, options).pipe(map(res => res));
  }

  countDashBoard(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}count`, options).pipe(map(resp => resp));
  }

  updateContract(updateProductContract) {
    return this._http.put(`${this.baseUrlV2}/contract`, updateProductContract).pipe(map(resp => resp));
  }

  generateContractPdf(contractType, data) {
    return this._http.post(`${this.baseUrlV2}/contract-pdf?templateType=${contractType}`, data).pipe(map(resp => resp));
  }

  sendEmailQuote(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }

  getCreatedByList(options: any): Observable<any> {
      let params = new HttpParams();
      if (options.companyId) {
          params = params.set('companyId', options.companyId);
      }
      return this._http.get<any>(`${this.baseUrlV2}getCreatedBy`, { params }).pipe(map(resp => resp));
  }
}
