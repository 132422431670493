import { Injectable } from '@angular/core';
import { Car } from '../domain/car';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CarService {

    constructor(private http: HttpClient) { }

    getCarsSmall() {
        return this.http.get('assets/demo/data/cars-small.json')
            .toPromise()
            .then((res: any) => <Car[]>res.data)
            .then(data => data);
    }

    getCarsMedium() {
        return this.http.get('assets/demo/data/cars-medium.json')
            .toPromise()
            .then((res: any) => <Car[]>res.data)
            .then(data => data);
    }

    getCarsLarge() {
        return this.http.get('assets/demo/data/cars-large.json')
            .toPromise()
            .then((res: any) => <Car[]>res.data)
            .then(data => data);
    }
}
