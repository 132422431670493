import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../../shared/model/constants";
import { CompanySettingServiceV2 } from "app/company/service/company-setting.v2.service";
import { environment } from "environments/environment";
import moment from "moment";
import { EmployeeService } from "app/employee/service/employee.service";
import { forkJoin } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PaymentNotificationService {
  baseUrlV2;
  constructor(private http: HttpClient, 
    private companySettingService: CompanySettingServiceV2,
    private employeeService: EmployeeService) {
    this.baseUrlV2 = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  sendEmail(payload) {
    // return this.http.post(`http://localhost:8009/api/v2/notification/email/payment-email`, payload);
    return this.http.post(`${this.baseUrlV2}/payment-email`, payload);
  }

  sendAutoPayChangeEmail(monitorDetails: any) {
    this.sendMonitorDetailsEmail(monitorDetails, 'monitor-details-email');
  }

  sendPaymentFailedEmail(monitorDetails: any, description?): void {
    this.sendMonitorDetailsEmail(monitorDetails, 'payment-failed-email', description);
  }

  sendMonitorDetailsEmail(monitorDetails: any, url, description?) {
    if (monitorDetails && monitorDetails.status === 'SUCCESS' && monitorDetails.data && monitorDetails.data.companyId) {
      const data = monitorDetails.data;
      const companyId = data.companyId;
      
      forkJoin([
        this.companySettingService.getCompanySettingByCompanyId(companyId),
        this.employeeService.searchEmployee({
          companyId: companyId,
          departmentNameSearch: ['Support'],
          status: 1
        })
      ]).subscribe((details: any[]) => {
        const emails = [];
        if (details[0] && details[0].status === 'SUCCESS' && details[0].data) {
          if (details[0].data.employeeMissingPunchNotificationEmail) {
            emails.push(details[0].data.employeeMissingPunchNotificationEmail);
          }
        }
        if (details[1] && details[1].status === 'SUCCESS' && details[1].data && details[1].data.content && details[1].data.content.length > 0) {
          details[1].data.content.forEach(e => {
            if (!emails.includes(e.email)) {
              emails.push(e.email);
            }
          });
        }
        if (emails.length > 0) {
          const payload: any = {
            toEmails: emails,
            startedBy: data.startedByName,
            details: data.argumentsForReport,
            openAt: moment(data.requestStartTime).format('MMM D, y, h:mm:ss A'),
            exitAt: moment(data.endTime).format('MMM D, y, h:mm:ss A'),
            timeSpend: data.durationForReport,
            action: data.action,
            emailFullName: data.startedByName,
            emailCompanyId: data.companyId
          }
          if (description) {
            payload.description = description;
          }
          this.http.post(`${this.baseUrlV2}/${url}`, payload).subscribe();
        }
      });
    }
  }

  sendPendingTransactionEmail(pendingTransaction: any, clientEmail): void {
    forkJoin([
      this.companySettingService.getCompanySettingByCompanyId(pendingTransaction.companyId),
      this.employeeService.searchEmployee({
        companyId: pendingTransaction.companyId,
        departmentNameSearch: ['Support', 'Billing'],
        status: 1
      })
    ]).subscribe((details: any[]) => {
      const emails = [];
      if (clientEmail) {
        emails.push(clientEmail);
      }
      if (details[0] && details[0].status === 'SUCCESS' && details[0].data) {
        if (details[0].data.employeeMissingPunchNotificationEmail) {
          emails.push(details[0].data.employeeMissingPunchNotificationEmail);
        }
      }
      if (details[1] && details[1].status === 'SUCCESS' && details[1].data && details[1].data.content && details[1].data.content.length > 0) {
        details[1].data.content.forEach(e => {
          if (!emails.includes(e.email)) {
            emails.push(e.email);
          }
        });
      }

      if (emails.length > 0) {
        const payload = Object.assign({}, pendingTransaction);
        const title = pendingTransaction.status;
        payload.title = title;
        payload.subject = title;
        payload.toEmails = emails;
        payload.emailCompanyId = pendingTransaction.companyId;

        this.http.post(`${this.baseUrlV2}/pending-transaction-email`, payload).subscribe();
      }
    });
  }

  sendShareHolderTransactionEmail(shareHolderTransaction: any, employeeEmail): void {
    forkJoin([
      this.companySettingService.getCompanySettingByCompanyId(shareHolderTransaction.companyId),
      this.employeeService.searchEmployee({
        companyId: shareHolderTransaction.companyId,
        departmentNameSearch: ['Support', 'Billing'],
        status: 1
      })
    ]).subscribe((details: any[]) => {
      const emails = [];
      if (employeeEmail) {
        emails.push(employeeEmail);
      }
      if (details[0] && details[0].status === 'SUCCESS' && details[0].data) {
        if (details[0].data.employeeMissingPunchNotificationEmail) {
          emails.push(details[0].data.employeeMissingPunchNotificationEmail);
        }
      }
      if (details[1] && details[1].status === 'SUCCESS' && details[1].data && details[1].data.content && details[1].data.content.length > 0) {
        details[1].data.content.forEach(e => {
          if (!emails.includes(e.email)) {
            emails.push(e.email);
          }
        });
      }

      if (emails.length > 0) {
        const payload = Object.assign({}, shareHolderTransaction);
        payload.toEmails = emails;
        payload.emailCompanyId = shareHolderTransaction.companyId;
        this.http.post(`${this.baseUrlV2}/share-holder-purchased`, payload).subscribe();
      }
    });
  }
}
