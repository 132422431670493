import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-time-payment-thankyou',
  templateUrl: './one-time-payment-thankyou.component.html',
  styleUrls: ['./one-time-payment-thankyou.component.scss']
})
export class OneTimePaymentThankyouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
