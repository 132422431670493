import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-transaction-group-update',
    templateUrl: './transaction-group-update.component.html',
    styleUrls: ['./transaction-group-update.component.scss']
})
export class TransactionGroupUpdateComponent implements OnInit {
    todoId: number;

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe(params => this.todoId = +params.id);
    }

    ngOnInit() {
    }

}
