<p-dialog [(visible)]="leaveConfirmDialogVisible" modal="modal" width="300">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Leave Page' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to leave without saving?' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="onReject()"  class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button (click)="onAccept()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>
<app-job-follow-up-form mode="edit" [jobDetailsId]="jobDetailsId"></app-job-follow-up-form>