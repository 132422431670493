<h1 class="m-0 ml-2 my-4">{{'Correction Report' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Corrections' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'Company' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [options]="companies" [filter]="true" placeholder="{{'Select One' | translate}}" [(ngModel)]="companySelected" (onChange)="chooseCompany($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Client' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="clientList" [filter]="true" [disabled]="!isChooseClient" placeholder="{{'Select One' | translate}}" [(ngModel)]="clientSelected" (onChange)="onChangeClient($event)" appendTo="body" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Uploader' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="createdByList" placeholder="{{'Select One' | translate}}" [(ngModel)]="createdSelected" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Uploaded' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<p-dropdown [options]="uploadDateList" filter="true" placeholder="{{'Select One' | translate}}" [(ngModel)]="uploadDateSelected" appendTo="body" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
    
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchList()"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-between my-4">   
	<h1 class="m-0 ml-2">{{'Browse Corrections' | translate}}</h1>
	<h4 class="ml-2">{{'Total Paid' | translate}}: <span class="text-success">{{totalPaidAmount | currency : 'USD':'symbol':'1.2-2'}}</span></h4>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{ 'Corrections' | translate }} ({{ totalRecords || 0 | number}})</span>
	</p-header>
    <p-table #dt selectionMode="single" [columns]="cols" [value]="billingReports" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" dataKey="id" (onLazyLoad)="loadBillingReports($event)" [totalRecords]="totalRecords" [lazy]="false" [responsive]="true">

		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
				<div class="d-flex">
					<button type="button" [disabled]="!downloadEnable" (click)="op3.toggle($event);" class="btn btn-primary mx-1">
						<span class="text-nowrap"><i class="fa-solid fa-file-export mr-2"></i> {{'Export File' | translate}}</span>
					</button>
					<button type="button" [disabled]="!downloadEnable || isClientAccount" (click)="uploadBtn()" class="btn btn-info mx-1">
						<i class="fa-solid fa-upload mr-2"></i> {{'Upload' | translate}}
					</button>
				</div>
				<div class="input-group w-100" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input [(ngModel)]="textSearch" (keyup)="search()" placeholder="{{'Filter' | translate}}" pInputText type="text">
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display, 'width': col.width}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'serviceDate'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op4.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'actions'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>

        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td>
					<span class="one-liner">{{i+1}}</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.serviceDate" pTooltip="{{ rowData.serviceDate  | date:'MM/dd/yyyy'}}">{{ rowData.serviceDate  | date:'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.serviceDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" pTooltip="{{ rowData.invoiceNumber }}">{{ rowData.invoiceNumber }}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
                	</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'CIN' | translate}}: </span>
						<span *ngIf="rowData.cin" pTooltip="{{ rowData.cin }}">{{ rowData.cin }}</span>
						<span *ngIf="!rowData.cin" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'PA' | translate}}#: </span>
						<span *ngIf="rowData.paNumber" pTooltip="{{ rowData.paNumber }}">{{ rowData.paNumber }}</span>
						<span *ngIf="!rowData.paNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Patient' | translate}}: </span>
						<span *ngIf="rowData.patientName" pTooltip="{{ rowData.patientName }}">{{ rowData.patientName }}</span>
						<span *ngIf="!rowData.patientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Paid' | translate}}: </span>
						<span *ngIf="rowData.paidAmount" pTooltip="{{ rowData.paidAmount | currency : 'USD':'symbol':'1.2-2' }}">{{ rowData.paidAmount | currency : 'USD':'symbol':'1.2-2' }}</span>
						<span *ngIf="!rowData.paidAmount" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="d-flex align-items-center">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
	                    <i *ngIf="isPermitted" class="fa-solid fa-xmark text-danger" (click)="deleteRecord(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
						<i *ngIf="!isPermitted" class="fa-solid fa-xmark text-muted" pTooltip="{{'Not allowed' | translate}}" tooltipPosition="left"></i>
					</span>
                </td>
            </tr>
        </ng-template>

		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="cols.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>

    </p-table>
</p-panel>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-dialog header="Upload" [(visible)]="showUploaded" class="modal_in" [modal]='true' [style]="{'width':'30vw'}">
    <div class="ui-g">
        <div class="ui-g-12">
            <label>{{'Client/Base' | translate}} :</label>
            <p-dropdown [options]="clientList" filter="true" [style]="{'width': '100%'}"
                [disabled]="!isChooseClientUpload" class="employee-list-dropdowns" placeholder="Please Select"
                [(ngModel)]="clientSelectedUpload" appendTo="body">
            </p-dropdown>
        </div>
		<div class="ui-g-12 m-2 text-center">
			<p-fileUpload #fileUpload accept=".csv,.xlsx,.xls" customUpload="true" (onUpload)="onUpload($event)" 
				maxFileSize="100000000" (uploadHandler)="importReports($event)" multiple="true" [disabled]='!clientSelectedUpload'>
			</p-fileUpload>
			<ng-template pTemplate="content" [style]="{'min-height': '85px'}">
				<ul *ngIf="uploadedFiles.length">
					<li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
				</ul>
			</ng-template>
		</div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [(visible)]="showProgressBar" styleClass="w-50" id="id_progress_bar_dialog">
    <div class="card card-w-title p-0 border-0 m-0">
        <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
            <div class="ui-g-12 ui-md-12">
                <p style="margin: 0 0 0 50%;">{{progressBarValue}}%</p>
                <p-progressBar [value]="progressBarValue"></p-progressBar>
            </div>
            <div class="ui-g-12 ui-md-12 text-center pb-3">
                <button pButton class="w-auto" label="Close" (click)="showProgressBar=false"></button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="showConfirmDialog" class="modal_in" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{ 'Delete Item' | translate }}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{ 'Are you sure you want to delete this item?' | translate }}</span>
		</div>
    </div>
    <p-footer class="d-flex justify-content-between">
		<button type="button" class="btn btn-danger" (click)="reject()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="accept()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>

<p-overlayPanel #op3 [appendTo]="'body'">
    <p-listbox [options]="fileTypes">
        <ng-template let-col pTemplate="item">
            <div (click)="exportClientList(col.value)">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="showProcessUpdateComplete" styleClass="w-50" id="id_process_complete_dialog">
    <div class="card card-w-title p-0 border-0 m-0">
        <p class="tabCardHead">
            <span>{{'Process Completed' | translate}}</span>
        </p>
        <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
            <div class="ui-g-12 ui-md-12">
                <p-card>
                    <p-header class="text-center font-weight-bold">
                        Result
                    </p-header>
                    <div *ngIf="isPreviousUpdatedDate">
                        <span class="font-weight-bold">{{'This File was previously upload on' | translate}}: </span>
                        <span>{{previousUpdatedDate}}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{'Total Record updated' | translate}}: </span>
                        <span>{{totalUpdatedRecords}}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{'New record' | translate}}: </span>
                        <span>{{totalNewRecords}}</span>
                    </div>
					<div>
						<span class="font-weight-bold">{{'File Uploaded' | translate}}: </span>
						<span>{{totalFileUpload}}</span>
					</div>
                </p-card>
            </div>
            <div class="ui-g-12 ui-md-12 text-center pb-3">
                <button pButton class="w-auto" label="{{'Ok' | translate}}" (click)="closeProcessCompleteDialog()"></button>
            </div>
        </div>
    </div>