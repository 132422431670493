<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="d-flex flex-wrap align-items-center justify-content-between py-4">
	<h1 class="m-0 ml-2" *ngIf="mode !== 'edit' && mode !== 'view'">{{'AddTransaction' | translate}}</h1>
	<h1 class="m-0 ml-2" *ngIf="mode === 'view'">{{'Transaction Details' | translate}}</h1>
	<h1 class="m-0 ml-2" *ngIf="mode === 'edit'">{{'EditTransaction' | translate}}</h1>
	<div class="d-flex align-items-center">
		<h3 class="text-secondary">{{'Credit' | translate}}: ${{availableCreditStr}}</h3>&nbsp;
		<a *ngIf="mode === 'view' || mode === 'edit'" type="button" class="btn btn-danger round mx-1" pTooltip="{{'button.cancel' | translate}}" tooltipPosition="left" (click)="onCancel()"><i class="fa-solid fa-xmark"></i></a>
	</div>
</div>
<form [formGroup]="transactionForm" #transactionDataForm [name]="transactionDataForm">
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i> {{'Basic Information' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{'companyLabel' | translate}}<span class="required-indicator">*</span>: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" [options]="allCompanyList" optionLabel="value" dataKey="key" [ngModelOptions]="{'standalone':true}" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany('load')" [disabled]="readOnly">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
				<div class="ui-g-6 ui-sm-12" >
					<label>{{'Project' | translate}}<span class="required-indicator">*</span>: </label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-project-diagram"></i>
							</button>
						</div>
						<p-dropdown id="projectSelected" #projectsDropdown [options]="allProjectsList" placeholder="{{'pleaseSelect' | translate}}" optionLabel="label" dataKey="value" [ngModelOptions]="{'standalone':true}" [(ngModel)]="projectSelected" [filter]="true" [disabled]="disableAll || readOnly" (onChange)="selectProject()"
							filterBy="label" (onShow)="projectsDropdownShow()">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12" >
					<label>{{'ExpenseCurrency' | translate}}<span class="required-indicator">*</span>: </label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-dollar"></i>
							</button>
						</div>
						<p-dropdown id="expenseCurrencySelected" [options]="expenseCurrency" [ngModelOptions]="{'standalone':true}" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="transaction.expenseCurrency" [disabled]="disableAll || readOnly">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12" *ngIf="!isPlatformAdmin && !showContractorInvoice">
					<label>{{'Client/Base' | translate}}: </label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-briefcase"></i>
							</button>
						</div>
						<p-dropdown id="clientSelected" [options]="allClientList" optionLabel="value"  dataKey="key" [filter]="true" [ngModelOptions]="{'standalone':'true'}" [(ngModel)]="selectedClient" (onChange)="changeClientBase()" placeholder="{{'Select One' | translate}}" [disabled]="showClient || readOnly" >
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12" *ngIf="!isPlatformAdmin && !showContractorInvoice">
					<label>{{'Billing invoice' | translate}}<span class="required-indicator">*</span>:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-file-invoice-dollar"></i>
							</button>
						</div>
						<p-dropdown id="billingInvoiceSelected" [options]="allBillingInvoice"  optionLabel="value" dataKey="key" filter="true" [ngModelOptions]="{'standalone':'true'}" [(ngModel)]="selectedBillingInvoice" (ngModelChange)="changeBillingInvoice()" placeholder="{{'Select One' | translate}}" [disabled]="showClient || readOnly" >
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				
				<div class="ui-g-6 ui-sm-12" *ngIf="showExpenses">
					<label>{{'Expenses (only the unpaid expenses will be show here)' | translate}}: </label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<p-multiSelect [options]="optAllExpenses" [filter]="true"
                               (onChange)="allExpensesDropDownChange($event)"
							   [ngModelOptions]="{'standalone': 'standalone'}" 
							   [(ngModel)]="selectedExpenses"
                               id="id_expenseOptions_to_selected_label"
                               [style]="{'width':'100%'}">
							   <ng-template let-item pTemplate="item">
									<span class="one-liner">{{item?.label}}</span>
								</ng-template>
							   <ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item?.label}}</span>
								</ng-template>
                		</p-multiSelect>
						<span *ngIf="!loadingExpense"><i class="fas fa-spinner fa-spin"></i> Loading..</span>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12" *ngIf="!isPlatformAdmin && showContractorInvoice && !showExpenses">
					<label>{{'Contractor/Invoice' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-file-invoice-dollar"></i>
							</button>
						</div>
						<p-dropdown id="contractorInvoiceSelected" [options]="allContractorInvoiceList"  optionLabel="value"  dataKey="key" filter="true" [ngModelOptions]="{'standalone':'true'}" [(ngModel)]="selectedContractorInvoice" (onChange)="changeContractorInvoice()"  placeholder="{{'Select One' | translate}}" [disabled]="readOnly" >
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Discount Type' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-folder-tree"></i>
							</button>
						</div>
						<p-dropdown [options]="allDiscountType" [ngModelOptions]="{'standalone':'true'}" [(ngModel)]="discountType" (ngModelChange)="changeDiscountType()" placeholder="{{'pleaseSelect' | translate}}" [disabled]="!isEnableDiscount() || readOnly || !isServiceBureau() || !selectedBillingInvoice || selectedBillingInvoice.discountType">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Discount' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-scissors"></i>
							</button>
						</div>
						<input type="text" class="w-100" [disabled]="!isEnableDiscount() || readOnly || !discountType || discountType === 'NA' || !isServiceBureau() || !selectedBillingInvoice || selectedBillingInvoice.discountValue" pInputText  [ngModelOptions]="{'standalone':'true'}" twoDigitDecimaNumber [(ngModel)]="discountValue" placeholder="0.00" (keyup)="calculateBalanceWithDisCount()">
						<div class="input-group-append">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="openConfirmationAppyDiscount()" [disabled]="!isEnableDiscount() || isDisabledAppyDiscount()">
								<span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span>
							</button>
						</div>
					</div>
				</div>

				<div class="ui-g-6 ui-sm-12">
					<label>{{'Date' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-calendar-day"></i>
							</button>
						</div>
						<p-calendar [ngModelOptions]="{standalone: true}" class="w-100" [(ngModel)]="transactionDate" placeholder="{{'pleaseSelect' | translate}}" [showIcon]="false" [disabled]="!isOtherProject" ></p-calendar>
					</div>
				</div>
				
				<div class="ui-g-2 ui-sm-12">
					<label>{{'Late Fee' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" >
								<p-checkbox name="checkLateFee" id="checkLateFeeId" 
							
									formControlName="checkLateFee" (onChange)="checkLateFeeChange($event)" 
									[(ngModel)]="checkLateFee" binary="true" pTooltip="{{'Toggle' | translate}}">
								</p-checkbox>
							</button>
						</div>
						<input type="text" pInputText disabled [value]="totalLateFeeTxt  | currency : 'USD':'symbol':'1.2-2'">
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>{{'API Usage fee' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" >
								<p-checkbox name="checkApiUsageFee" id="checkApiUsageFeeId" 
							
									formControlName="checkApiUsageFee" (onChange)="checkApiUsageFeeChange($event)" 
									[(ngModel)]="checkApiUsageFee" binary="true">
								</p-checkbox>
							</button>
						</div>
						<input type="text" pInputText disabled [value]="totalApiUsageFeeTxt  | currency : 'USD':'symbol':'1.2-2'">
						<div class="input-group-append pl-4">
							<button class="btn btn-white" id="button-addon2" [disabled]="readOnly" type="button" (click)="waiveFee()" style="cursor:pointer !important">
								<span class="text-nowrap"><i class="fa-solid fa-square-check text-primary mr-2"></i>{{'Waive Fees' | translate}}</span>
							</button>
						</div>
					</div>
				</div>
				
				<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
					<label>{{'Client/Base' | translate}}: </label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-briefcase"></i>
							</button>
						</div>
						<p-dropdown  [options]="allClientList"  optionLabel="value"  dataKey="key" [filter]="true" [ngModelOptions]="{'standalone':'true'}" [(ngModel)]="selectedClient" (onChange)="changeClientBase()"  placeholder="{{'pleaseSelect' | translate}}" [disabled]="showClient || readOnly" >
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Balance' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-pie-chart"></i>
							</button>
						</div>
						<input type="text" class="w-100" [disabled]="true" pInputText  [ngModelOptions]="{'standalone':'true'}" (keypress)="numberOnly($event)"  [(ngModel)]="outStandingBalanceWithDiscount" placeholder="0.00">
					</div>
				</div>
			</div>
		</p-panel>
		<div class="d-flex flex-wrap align-items-center justify-content-between py-4">
			<h1 class="m-0 ml-2">{{'Browse Details' | translate}}</h1>
			<button class="btn btn-primary" (click)="addDetails()" [disabled]="readOnly" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
		</div>
		<p-panel id="table-area" [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-calculator text-primary mr-2"></i> {{'Transaction Details' | translate}} ({{transactionDetails?.length || 0 | number}})</span>
			</p-header>
			<div class="add-shadow d-flex flex-wrap align-items-center justify-content-between p-2" style="row-gap: 10px">
				<button class="btn btn-success text-nowrap mx-1" (click)="openConfirmationApplyCredit()" [disabled]="applyCreditDisable"><i class="fa-solid fa-check mr-2"></i>{{'Apply Credit' | translate}} (${{availableCreditStr}})</button>
				<span class="one-liner mx-2"><span style="font-weight: 600;">{{'Account' | translate}}: </span>{{amountAvailableSelectedAccount | currency : 'USD':'symbol':'1.2-2'}}</span>
			</div>
			<div>
			<table class="table table-bordered" style="margin-bottom: 0px !important">
				<thead>
					<tr>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Type' | translate}}<span class="text-danger">*</span></span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Cash In' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Kept' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Out' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Net' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Method' | translate}}<span class="text-danger">*</span></span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center">
								<span class="one-liner">{{'Memo' | translate}}</span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-between align-items-center">
								<span class="">{{'Acct' | translate}}#<span class="text-danger">*</span></span>
							</span>
						</th>
						<th>
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let transactionDetail of transactionDetails; index as i" (click)="clickTransactionRow(i)">
						<td>
							
								<span class="mobile-table-label">{{'Type' | translate}}: </span>	
								<p-dropdown id="transactionTypeSelected{{i}}" #transactionTypeDropdown [ngModelOptions]="{standalone: true}" [options]="allTransactionTypes"
									[style]="{'width':'100%'}" placeholder="{{'pleaseSelect' | translate}}"
									[(ngModel)]="transactionDetail.transactionTypeId" [disabled]="disableAll || readOnly" (onChange)="removeHighlight('transactionTypeSelected' + i, true)"
									[filter]="true" filterBy="label" (onShow)="transactionTypeDropdownShow(transactionTypeDropdown)"></p-dropdown>
							
						</td>
						<td> 
				
								<span class="mobile-table-label">{{'Cash-In' | translate}}: </span>	
								<input type="text" pInputText [(ngModel)]="transactionDetail.cashIn"
									[ngModelOptions]="{standalone: true}"
									(blur)="formatData(transactionDetail.cashIn, i, 'cashIn');"
									(keypress)="isNumber($event)" [disabled]="disableField('cashIn')"
									placeholder="0.00"  step="0.01" appBlockPasteCopy (mousedown)="transactionDetail.cashIn = null">
						
						</td>
						<td>
							
								<span class="mobile-table-label">{{'Kept' | translate}}: </span>	
								<input type="text" pInputText [(ngModel)]="transactionDetail.cashKept" maxlength="19"
									[ngModelOptions]="{standalone: true}"  step="0.01"
									(blur)="formatData(transactionDetail.cashKept, i, 'cashKept')"
									(keypress)="isNumber($event)" [disabled]="disableField()" appBlockPasteCopy>
						
						</td>
						<td>
				
								<span class="mobile-table-label">{{'Out' | translate}}: </span>	
								<input type="text" pInputText [(ngModel)]="transactionDetail.cashOut" maxlength="19"
									[ngModelOptions]="{standalone: true}" step="0.01" (mousedown)="transactionDetail.cashOut = null"
									(blur)="formatData(transactionDetail.cashOut, i, 'cashOut')"
									(keypress)="isNumber($event)" [disabled]="disableAll || readOnly || isDisable" appBlockPasteCopy>
					
						</td>
						<td>
						
								<span class="mobile-table-label">{{'Net' | translate}}: </span>	
								<input type="text" pInputText [(ngModel)]="transactionDetail.netCashInOut"
									[ngModelOptions]="{standalone: true}" [disabled]="true">
					
						</td>
						<td>
								<span class="mobile-table-label">{{'Method' | translate}}: </span>	
								<p-dropdown id="methodSelected{{i}}" [ngModelOptions]="{standalone: true}" [options]="paymentMethod"
								[style]="{'width':'100%'}" placeholder="{{'pleaseSelect' | translate}}" (onChange)="removeHighlight('methodSelected' + i, true)"
								[(ngModel)]="transactionDetail.paymentMethod" [disabled]="disableAll || readOnly"></p-dropdown>
						
						</td>
						<td>
								<span class="mobile-table-label">{{'Memo' | translate}}: </span>	
								<input [style]="{'width':'100%'}" type="text" pInputText [(ngModel)]="transactionDetail.memo"
									[ngModelOptions]="{standalone: true}" (keypress)="showLength($event.target)" maxlength="250"
									[disabled]="disableAll || readOnly">
						</td>
						<td>
								<span class="mobile-table-label">{{'Acct' | translate}}#: </span>
								<p-dropdown id="acctSelected{{i}}" [ngModelOptions]="{standalone: true}" [options]="accounts" [ngClass]="{'selected-billing-account': i === selectedTransactionDetailsIndex}"
									[style]="{'width':'100%'}" placeholder="{{'pleaseSelect' | translate}}" 
									[(ngModel)]="transactionDetail.billingAccountId"  [disabled]="disableAll || readOnly" (onChange)="billingAccountChange(); removeHighlight('acctSelected' + i, true)">
								</p-dropdown>
						
						</td>
						<td>
							<span class="mobile-table-label">{{'Actions' | translate}}: </span>	
							<i [disabled]="mode === 'view'" class="fa-solid fa-plus text-primary mx-1" (click)="addDetails()" pTooltip="{{'Add Another' | translate}}" tooltipPosition="left"></i>
							<i class="fa-solid fa-paperclip text-primary mx-1" style="cursor: pointer;" (click)="onSeletedTransaction(transactionDetail, i); uploadFilePanel.toggle($event)" pTooltip="{{'Attachments' | translate}}" tooltipPosition="left" [ngClass]="{'text-green': transactionDetail?.fileUrl}"></i>
							<input type="file" id="{{i}}" hidden (change)="uploadFile($event)" accept="image/*" #upload>
							<i [disabled]="mode === 'view'" class="fa-solid fa-xmark text-danger mx-1" (click)="removeDetail(transactionDetail.id, i)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>

							<p-overlayPanel #uploadFilePanel appendTo="body">
								<ul class="m-0 p-0" style="list-style: none;">
									<li style="cursor: pointer;" class="d-flex align-items-center c-pointer p-2" *ngIf="!transactionDetail?.fileUrl">
										<span (click)="onUploadDocument(uploadFilePanel)"><i class="fa-solid fa-upload text-primary mr-2"></i> {{'upload' | translate}}</span>
									</li>
									<li style="cursor: pointer;" class="d-flex align-items-center p-2" *ngIf=" transactionDetail?.fileUrl">
										<span (click)="onViewDocument(uploadFilePanel, transactionDetail)"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i> {{'View' | translate}}</span>
									</li>
									<li style="cursor: pointer;" class="d-flex align-items-center p-2" *ngIf=" transactionDetail?.fileUrl">
										<span (click)="onChangeDocument(uploadFilePanel)"><i class="fa-solid fa-rotate text-primary mr-2"></i> {{'change' | translate}}</span>
									</li>
									<li style="cursor: pointer;" class="d-flex align-items-center p-2" *ngIf=" transactionDetail?.fileUrl">
										<span (click)="onRemoveDocument(uploadFilePanel)"><i class="fa-solid fa-xmark text-danger mr-2"></i> {{'Delete' | translate}}</span>
									</li>
									<li style="cursor: pointer;" class="d-flex align-items-center p-2">
										<span (click)="showFileHistoryPopup(transactionDetail.id, transactionDetail.transactionFileHistories); uploadFilePanel.hide();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i> {{'history.title' | translate}}</span>
									</li>
								</ul>
							</p-overlayPanel>

						
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p-footer class="d-flex justify-content-center py-2">
			<button class="btn btn-white" (click)="addDetails()">
				<span class="text-nowrap"><i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}</span>
			</button>
		</p-footer>
	</p-panel>
	<br>
	<p-panel id="table-area" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-sticky-note text-primary mr-2"></i> {{'Transaction Notes' | translate}}<span class="text-danger">*</span></span>
		</p-header>
		<textarea id="transactionNotesSelected" pInputTextarea [rows]="4" [cols]="45" [ngModelOptions]="{'standalone':true}" pInputTextarea autoResize="autoResize" [(ngModel)]="transaction.note" maxlength="1500" [disabled]="readOnly" style="margin-bottom: -7px !important;font-size:16px;" (ngModelChange)="removeHighlight('transactionNotesSelected')"></textarea>
		<p-footer class="d-flex justify-content-end">
			<span class="text-muted" [class.text-danger]="transaction.note?.length > 1500" style="font-size: 12px">{{transaction.note?.length || 0}}/1500</span>
		</p-footer>
	</p-panel>
	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button *ngIf="mode === 'create'" type="button" class="btn btn-danger mx-1" (click)="onCancel()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="mode === 'edit'" type="button" class="btn btn-danger mx-1" (click)="cancelEnableTransaction()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="mode === 'view'"  type="button" class="btn btn-orange mx-1" (click)="onCancel()"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
			<button *ngIf="mode === 'view'" type="button" class="btn btn-info" (click)="enbaleTransaction()"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'button.edit' | translate}}</button>
			<button class="btn btn-success mx-1" (click)="openConfirmationApplyCredit()" [disabled]="applyCreditDisable"><i class="fa-solid fa-hand-holding-dollar mr-2"></i>{{'Apply Credit' | translate}} (${{availableCreditStr}})</button>
			<button class="btn btn-primary mx-1"  [disabled]="disableAll || disableSave || transaction.showHideActions" type="button" *ngIf="mode !== 'view'" (click)="openConfirmation()"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>
	</div>
</form>

<p-dialog header="" [(visible)]="showViewFileDialog" [responsive]="true" showEffect="fade" [style]="{width: '65vw'}" [closable]="false">
    <img [src]="fileUrl" *ngIf="fileUrl" class="imageShown"/>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton (click)="closeViewFileDialog()" label="{{'close' | translate}}"></button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
		{{'Are you sure you want to save?' | translate}}
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" align="right">
          <button type="button" pButton label="{{'yes' | translate}}" (click)="saveTransaction(transactionDataForm)"></button>
          <button type="button" pButton label="{{'no' | translate}}" (click)="reject()"></button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog  header="{{'Confirmation' | translate}}" [(visible)]="showConfirmApplyCreditDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
		{{'Are you sure you want to save?' | translate}}: #{{selectedBillingInvoice?.value}}?
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" align="right">
          <button type="button" pButton label="{{'yes' | translate}}" (click)="applyCredit()"></button>
          <button type="button" pButton label="{{'no' | translate}}" (click)="reject()"></button>
        </div>
      </div>
    </p-footer>
  </p-dialog>


	<p-dialog  header="{{'Confirmation' | translate}}" [(visible)]="showConfirmApplyDiscountDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px;padding:8px;">
		{{'Are you sure you want to apply the discount to the invoice' | translate}}: #{{selectedBillingInvoice?.value}}?
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" align="right">
          <button type="button" pButton label="{{'yes' | translate}}" (click)="applyDiscountV1()"></button>
          <button type="button" pButton label="{{'no' | translate}}" (click)="rejectDiscount()"></button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

	<p-dialog  header="{{'Confirmation' | translate}}" [(visible)]="showConfirmWaiveFeeDialog" class="modal_in" [modal]='true'>
    <div class="ui-g" style="width:380px; padding:8px;">
		{{'Are you sure you want to waive these charges?' | translate}}
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" align="right">
          <button type="button" pButton label="{{'yes' | translate}}" (click)="confirmedWaiveFee()"></button>
          <button type="button" pButton label="{{'no' | translate}}" (click)="showConfirmWaiveFeeDialog = false"></button>
        </div>
      </div>
    </p-footer>
  </p-dialog>
<p-dialog *ngIf="showFileHistoryDialog" [(visible)]="showFileHistoryDialog" [modal]="true" id="historyModal" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Attachment History' | translate}}</span>
	</p-header>
	<app-transaction-file-history [transactionDetailId]="transactionDetailId" [data]="transactionFileHistories"></app-transaction-file-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="hideFileHistoryDialog()"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog  header="{{'Confirmation' | translate}}" [(visible)]="deleteTransactionDetailsPopup" class="modal_in" [modal]='true'>
	<div class="ui-g" style="width:380px;padding:8px;">
	{{'Are you sure you want to delete?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12" align="right">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="deleteTransactionDetails()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="deleteTransactionDetailsPopup = false"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>
