<h1 class="m-0 my-4">{{'Upload History' | translate}}</h1>
<p-panel>
	<p-header>
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Search Upload History' | translate}}</span>
	</p-header>
    <div class="ui-g">

		<div *ngIf="!isClientAccount" class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Client/Base' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="clientList" filter="true" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="clientSelected" (onChange)="onChangeClient($event)" appendTo="body" [style]="{'flex-grow':1}"></p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Uploader' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown [options]="createdByList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="createdSelected" [style]="{'flex-grow':1}"></p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-md-6 ui-sm-12">
			<label>{{'Report Type' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [options]="reportTypes" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="reportTypeSelected" [style]="{'flex-grow':1}"></p-dropdown>
			</div>
		</div>
    
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchList()"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-start my-4">   
	<h1 class="m-0 ml-2">{{'Browse Upload History' | translate}}</h1>
</div>
<p-panel id="table-ppanel">
	<p-header>
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{ 'Upload History Data' | translate }}</span>
	</p-header>
	<p-table #dt class="para-table-text" selectionMode="single" [columns]="cols" [value]="billingReports" [rows]="10"
			[paginator]="true" [rowsPerPageOptions]="[5, 10, 25, 100]" [resizableColumns]="true" dataKey="id"
			(onLazyLoad)="loadBillingReports($event)" [lazy]="false" [responsive]="true">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<div class="d-flex">
					</div>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input [(ngModel)]="searchText" (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" pInputText />
					</div>
				</div>
			</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th class="adjust-padding" pResizableColumn *ngFor="let col of columns"
					[ngStyle]="{'display': col.display, 'width': col.width}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'status'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-info text-muted"></i>
							</span>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>

        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
            <tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td dir="rtl">
					<span class="one-liner">
						{{i+1}}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>{{rowData.createdAt | date:'MM/dd/yyyy hh:mm'}}
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'By' | translate}}: </span>{{rowData.createdByUsr}}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>{{rowData.clientBase}}
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Start' | translate}}: </span>{{rowData.startDate | date: 'MM/dd/yyyy hh:mm:ss' }}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'End' | translate}}: </span>{{rowData.endDate | date: 'MM/dd/yyyy hh:mm:ss' }}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Created' | translate}}: </span>{{rowData.firstUpdateOn | date:'MM/dd/yyyy hh:mm'}}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Records' | translate}}: </span>{{rowData.totalRecords }}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'New Records' | translate}}: </span>{{rowData.newRecords}}
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Old Records' | translate}}: </span>{{rowData.oldRecords}}
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Status' | translate}}: </span>
						<i *ngIf="rowData.status === 'SUCCESS'" class="fa-solid fa-circle-check text-success" pTooltip="{{rowData.status | translate}}"></i>
						<i *ngIf="rowData.status === 'FAILED'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{rowData.status | translate}}"></i>
					</span>
				</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="text-center">
                <td [attr.colspan]="cols.length">
					{{'no data' | translate}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-columns>
            <tr *ngIf="loading" class="text-center">
                <td [attr.colspan]="cols.length">
					{{'Loading...' | translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>


<p-overlayPanel #op1 appendTo="body">
    <p-listbox [options]="textSortOptionsStock" [(ngModel)]="selectedSortOption"
        (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<p-overlayPanel #op2 appendTo="body">
    <p-listbox [options]="numberSortOptionsStock" [(ngModel)]="selectedSortOption"
        (onChange)="sortingClick(selectedSortOption)">
        <ng-template let-col pTemplate="item">
            <div>
                <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>

    </p-listbox>
</p-overlayPanel>
