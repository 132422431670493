import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Constants} from '../../model/constants';
import {AbstractService} from '../abstract.service';
import {BrowserNotification} from '../../model/browser-notification.model';
import { Observable } from 'rxjs';
import { Page } from '../../model/page.model';
import { Response } from '../../model/response';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BrowserNotificationService extends AbstractService<BrowserNotification> {

    constructor(protected _http: HttpClient) {
        super(_http, Constants.ROUTES.BROWSER_NOTIFICATION, '');
    }

    save(notification: BrowserNotification): Observable<BrowserNotification> {
        return this._http.post<BrowserNotification>(`${this.baseUrlV2}`, notification).pipe(map(res => res));
    }

    createAll(entities: BrowserNotification[]): Observable<Response<BrowserNotification>> {
        return this._http.post<Response<BrowserNotification>>(`${this.baseUrlV2}saveMultipleRows`, entities).pipe(map(res => res));
    }

    update(notification: BrowserNotification): Observable<BrowserNotification> {
        return this._http.put<BrowserNotification>(`${this.baseUrlV2}${notification.id}`, notification).pipe(map(res => res));
    }

    filter(options: any): Observable<Page<BrowserNotification>> {
        const params: any = { page: options.page, size: options.size };
        if (options.sort) {
            params.sort = options.sort;
        }
        return this._http.post<Page<BrowserNotification>>(`${this.baseUrlV2}search`, options, { params: params}).pipe(map(res => res));
    }
}
