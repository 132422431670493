import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AesUtilService {
  readonly password = 'OPERR-V3';
  readonly keySize = 128;
  readonly ivSize = 128;
  readonly iterations = 65536;
  readonly operrWorkSecretKey = 'operrwork-s3cr3t';

  private secretKey: CryptoJS.lib.WordArray;
  constructor() {
  }

  encrypt(text: string): string | undefined {
    if (!_.isString(text)) {
      return;
    }
    const salt = Array(40).fill(0).map(() => _.random(15).toString(16)).join('');
    const iv = Array(32).fill(0).map(() => _.random(15).toString(16)).join('');

    const key = CryptoJS.PBKDF2(this.password, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize / 32,
      iterations: this.iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return CryptoJS.enc.Hex.parse(salt + iv + encrypted.ciphertext.toString()).toString(CryptoJS.enc.Base64);
  }

  decrypt(encrypted: string): string | undefined {
    if (!_.isString(encrypted)) {
      return;
    }

    const packet = CryptoJS.enc.Base64.parse(encrypted);
    const salt = packet.toString().slice(0, 40);
    const iv = packet.toString().slice(40, 72);
    const cipherText = packet.toString().slice(72);

    const key = CryptoJS.PBKDF2(this.password, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize / 32,
      iterations: this.iterations,
    });

    const decrypted = CryptoJS.AES.decrypt({
      ciphertext: CryptoJS.enc.Hex.parse(cipherText),
      salt: '',
    } as any, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptDataWithKey(text: string, key: string): string | undefined {
    if (!_.isString(text)) {
      return;
    }
    const keyMd5 = CryptoJS.MD5(key).toString().substring(0,16);
    const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(keyMd5), {
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.ECB
    });

    return CryptoJS.enc.Hex.parse(encrypted.ciphertext.toString()).toString(CryptoJS.enc.Base64);
  }

  setKey(): void {
    try {
      const key = CryptoJS.enc.Utf8.parse(this.operrWorkSecretKey);
      
      const sha1Key = CryptoJS.SHA1(key);
      
      const truncatedKey = CryptoJS.lib.WordArray.create(sha1Key.words.slice(0, 4));
      
      this.secretKey = truncatedKey;
    } catch (error) {
      console.error('Error setting key:', error);
    }
  }

  encryptV2(strToEncrypt: string): string {
    this.setKey();
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(strToEncrypt), this.secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    const encryptedBase64 = encrypted.toString();
    return encryptedBase64;
  }
}
