import { EmployeeClockingService } from "../../service/employee-clocking.service";
import { PayrollSettingService } from "../../../payroll-setting/service/payroll-setting.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DropDownsService } from "../../../shared/service/drop-downs/drop-downs.service";
import { EmployeeService } from "../../../employee/service/employee.service";
import { Table } from "primeng/table";
import { Employee } from "../../../employee/model/employee.model";
import { SelectItem, MenuItem, MessageService } from "primeng/api";
import { PayrollSetting } from "../../../payroll-setting/model/payroll-setting.model";
import { Department } from "../../../department/model/department.model";
import { DepartmentService } from "../../../department/service/department.service";
import { EmployeeOT } from "../../model/employee-ot.model";
import { AuthService } from "../../../shared/service/auth/auth.service";
import { start } from "repl";
import * as moment from 'moment';
import {UtilsService} from '../../../shared/service/utils.service';
import {AgencyService} from '../../../agency/service';
@Component({
    selector: 'app-employee-ot',
    templateUrl: './employee-ot.component.html',
    styleUrls: ['./employee-ot.component.scss'],
    providers: [EmployeeClockingService, PayrollSettingService, MessageService]
})
export class EmployeeOTComponent implements OnInit {

    @ViewChild('dt', {static: true}) table: Table;
    items: MenuItem[];
    msgs: any[] = [];

    size: number;
    page: number;
    totalRecords: number;

    disableCaledar:Boolean = true;
    placeholderCalendar:string;
    showPrevent:Boolean = false;

    loading: Boolean = false;
    companies: any[];
    selectedCompanyId: any;
    agencies: any[];
    selectedAgencyId: any;
    futureDatesDisableOTHistory: any;

    departments: any[] = [];
    types = [
        { label: 'Part Time', value: 'part_time' },
        { label: 'Full Time', value: 'full_time' },
        { label: 'Consultant', value: 'consultant' },
        { label: 'Volunteer', value: 'volunteer' }];
    historyFilter: any;
    payrollDate: Date[] = [];
    daysDisable: any[] = [];

    employeeList: SelectItem[];
    payrollSettings: PayrollSetting;

    OtviewList: EmployeeOT[] = [];
    selectedOTview: EmployeeOT;

    viewBy: any[] = [{ label: 'OTHistory', value: 'employeeOT' }, { label: 'PreventOT', value: 'preventOT' }];
    selectedViewBy: string;

    textSortOptionsOT = [
        { name: 'SortAToZ', value: 'ASC', img: 'a-z' },
        { name: 'SortZToA', value: 'DESC', img: 'z-a' }
    ];
    numberSortOptionsOT = [
        { name: 'Sort0To9', value: 'ASC', img: 'a-z' },
        { name: 'Sort9To0', value: 'DESC', img: 'z-a' }
    ];

    selectedField: string;
    selectedSortOption: any;
    initLoad:Boolean = false;
    sortField: string;
    sortOrder: number;
    companyDisabled = false;
    agencyDisabled = false;
    cols: any[];
    isPlatformAdmin: boolean = false;
    selectedDepartment: any;
    constructor(
        private dropdownService: DropDownsService,
        private payrollSettingService: PayrollSettingService,
        private employeeService: EmployeeService,
        private departmentService: DepartmentService,
        private employeeClockingService: EmployeeClockingService,
        private utilsService: UtilsService,
        private agencyService: AgencyService,
        private authService: AuthService
    ) {

    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.selectedViewBy = "employeeOT";

        // this.loadAllCompanies();
        this.loadCompanyAndAgencyDropdown();
        // this.loadDepartments();
        this.selectedCompanyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : null;
        this.selectedAgencyId = this.authService.getCurrentAgency() ? this.authService.getCurrentAgency() : null;

        const employeeIdsStr = localStorage.getItem('overtime_alert');
        if (employeeIdsStr && employeeIdsStr.length > 0) {
            this.historyFilter.employeeIds.push(employeeIdsStr.split(','));
            localStorage.removeItem('overtime_alert');
        }

        this.cols =
            [{ field: 'id', label: 'ID', sortOptions: '', sort: 'number',OTdisplay : 'table-cell',preventDisplay : 'table-cell'},
            { field: 'department', label: 'department.name', sortOptions: '', sort: 'text',OTdisplay : 'table-cell', preventDisplay : 'table-cell' },
            { field: 'firstName', label: 'firstName', sortOptions: '', sort: 'text',OTdisplay : 'table-cell',preventDisplay : 'table-cell' },
            { field: 'lastName', label: 'lastName', sortOptions: '', sort: 'text',OTdisplay : 'table-cell',preventDisplay : 'table-cell' },
            { field: 'payrollDate', label: 'weekDate', sortOptions: '', sort: 'number',OTdisplay : 'table-cell',preventDisplay : 'table-cell' },
            { field: 'totalTimeinHHMM', label: 'hours_Stipulated', sortOptions: '', sort: 'text',OTdisplay : 'table-cell',preventDisplay : 'table-cell' },
            { field: 'overtimeHoursInHHMM', label: 'OverTimePrediction', sortOptions: '', sort: 'text', OTdisplay : 'none',preventDisplay : 'table-cell'},
            { field: 'balanceHoursinHHMM', label: 'OverTimeHours', sortOptions: '', sort: 'text', OTdisplay: 'table-cell',preventDisplay : 'none'},
            { field: 'potentialOTInHHMM', label: 'BalanceHours', sortOptions: '', sort: 'text', OTdisplay : 'table-cell',preventDisplay : 'none'}];

        this.historyFilter = {
            employees: [],
            employeeIds: [],
            fromDate: null,
            toDate: null,
            page: 0,
            size: 100,
            searchKey: '',
            date: [new Date(), new Date()],
            agencyId: null,
            lateEarlier: null,
            isMissing: false
        };
        this.loadSummaryViewyLazy();
    }


    loadCompanyAndAgencyDropdown() {
        this.utilsService.loadCompanyAndAgencyDropdown().then((res)=>{
            let companyAgencyDropDown: any = res;
            this.companyDisabled = companyAgencyDropDown.companyDisabled;
            this.agencyDisabled = companyAgencyDropDown.agencyDisabled;
            if (companyAgencyDropDown.companyResponse) {
                if (companyAgencyDropDown.companyResponse.length == 1) {
                    this.companies = companyAgencyDropDown.companyResponse;
                    this.selectedCompanyId = companyAgencyDropDown.companyResponse[0];
                } else {
                    this.companies = companyAgencyDropDown.companyResponse;
                }
            }
            if (companyAgencyDropDown.agencyResponse) {
                if (companyAgencyDropDown.agencyResponse.length == 1) {
                    this.agencies = companyAgencyDropDown.agencyResponse;
                    this.selectedAgencyId = companyAgencyDropDown.agencyResponse[0];
                    this.onAgencyChange(this.selectedAgencyId.id);
                }
                else {
                    this.agencies = companyAgencyDropDown.agencyResponse;
                }
            }
            let userInfo = this.authService.getUserInfo();
            let roleId = userInfo.role? userInfo.role.id : null;
            if (roleId == 4) {
                this.selectedAgencyId.id = this.authService.getCurrentAgency()? this.authService.getCurrentAgency().id: null;
                if (this.selectedAgencyId.id != null) {
                    this.loadDepartments();
                    this.loadEmployees();
                } 
            }
        });
    }
    disableCompany() {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency() {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee() {
        return this.authService.isEmployeeRole();
    }

    searchByDepartment(event: any) {
        if (event.value) {
            this.historyFilter.department = event.value;
        }
    }

    loadAllCompanies() {
        this.dropdownService.getAllCompanyList().subscribe(
            res => {
                const responseObject = res;
                const tempCompanyList = [];
                if (responseObject.status === 'SUCCESS') {
                    responseObject.data.forEach(element => {
                        tempCompanyList.push({ label: element.name, value: element.id });
                    });
                    this.companies = tempCompanyList;
                }
            });
    }
    loadAgencies(event) {
        this.employeeList = [];
        this.historyFilter.employeeIds = null;
        this.departments = [];
        this.historyFilter.department = null;
        this.selectedAgencyId = null;
        console.log(event);
        this.agencyService.readAllForDropDownList({companyId: event.id}).subscribe((res) => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.agencies = resObj.data;
            }
            if (this.agencies && this.agencies.length > 0 && this.isPlatformAdmin) {
                this.selectedAgencyId = this.agencies[0];
                this.onAgencyChange();
            }
        });
        
        // this.dropdownService.getAllAgencyByCompanyId(this.selectedCompanyId).subscribe(
        //     res => {
        //         const responseObject = res;
        //         const tempAgencyList = [];
        //         if (responseObject.status === 'SUCCESS') {
        //             responseObject.data.forEach(element => {
        //                 tempAgencyList.push({ label: element.name, value: element.id });
        //             });
        //             this.agencies = tempAgencyList;
        //         }
        //     });
    }

    loadEmployees() {
        this.historyFilter.agencyId = this.selectedAgencyId.id;
        this.historyFilter.employees = [];
        this.historyFilter.employeeIds = [];
        this.employeeList = [];
    }

    loadDepartments() {
        this.departmentService.getDepartmentsDropdown({ companyId: (this.selectedCompanyId) ? this.selectedCompanyId.id : 0, agencyId: (this.selectedAgencyId) ? this.selectedAgencyId.id : 0 }).subscribe(res => {
            const resObj: any = res;
            const tempDepList = [];
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(function (dVal) {
                    tempDepList.push({ label: dVal.name, value: dVal.id });
                });
                this.departments = tempDepList;
            }
        });
    }
    selectWeek(evt) {
        let endDate = new Date(evt);
        let startDate: any = endDate.setDate(endDate.getDate() - 6);
        startDate = new Date(startDate).setHours(0,0,0);
        this.payrollDate[0] = new Date(startDate);
        let updatedEndDate = new Date(evt).setHours(23,59,59);
        this.payrollDate[1] = new Date(updatedEndDate);
        this.historyFilter.fromDate = this.payrollDate[0];
        this.historyFilter.toDate = this.payrollDate[1];
        this.historyFilter.isMissing = false;
    }

    disableDayArr(day: number) {
        const days = [];
        this.daysDisable = [];
        for (let i = 0; i < 7; i++) {
            if (i !== day) {
                this.daysDisable.push(i);
            }
        }
    }

    getByDayOfWeek(dayOfWeek: number) {
        this.payrollDate = [];
        this.disableDayArr(dayOfWeek);

        const curr = new Date();
        const first = new Date();
        const last = new Date();
        first.setDate(first.getDate() - curr.getDay() );
        last.setDate(last.getDate() - curr.getDay());

        if (this.selectedViewBy === "preventOT") {
            if(dayOfWeek >= curr.getDay())
            {
                last.setDate(last.getDate() + dayOfWeek);
                first.setDate(last.getDate() - 6);
            } else
            {
                first.setDate(first.getDate() + dayOfWeek + 1 );
                last.setDate(first.getDate() + 6);
            }
        } else {
            last.setDate(first.getDate() - dayOfWeek);
            first.setDate(first.getDate() - 6);
            this.futureDatesDisableOTHistory = new Date();
        }
        first.setHours(0,0,0);
        last.setHours(0,0,0);
        this.payrollDate.push(first);
        this.payrollDate.push(last);
        this.historyFilter.fromDate = first;
        this.historyFilter.toDate = last;
        
    }

    viewByChange(event) {
        this.OtviewList = [];
        if (event.value === "preventOT") {
            this.disableCaledar = true;
            this.showPrevent = true;
            this.getByDayOfWeek(this.payrollSettings?this.payrollSettings.payrollWeekEnding:1);
            this.placeholderCalendar = "Select a date Range";
        } else {
            this.getByDayOfWeek(this.payrollSettings?this.payrollSettings.payrollWeekEnding:1);
            this.disableCaledar = false;
            this.showPrevent = false;
        }
    }
    selectCurrentWeek() {
        let curr = new Date;
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;
        let firstday = new Date(curr.setDate(first));
        let lastday = new Date(curr.setDate(last));
        this.payrollDate[0] = firstday;
        this.payrollDate[1] = lastday;
        this.historyFilter.fromDate = this.payrollDate[0];
        this.historyFilter.toDate = this.payrollDate[1];
    }

    onSearch() {
        this.initLoad = true;
        this.loadPage();
    }


    onAgencyChange(agencyId?)
    {
        let agenId = agencyId? agencyId : this.selectedAgencyId.id; 
        this.payrollSettings = new PayrollSetting();
        this.payrollSettingService.getPayrollSetting(agenId).subscribe(res => {

            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.payrollSettings = resObj.data;
            }
            this.getByDayOfWeek(this.payrollSettings.payrollWeekEnding);

        });
        this.loadEmployees();
        this.loadDepartments(); 
        this.disableCaledar = false;
    }
    onReset() {
        this.initLoad = false;
        this.selectedCompanyId = null;
        this.selectedAgencyId = null;
        this.historyFilter.employees = null;
        this.historyFilter.employeeIds = [];
        this.payrollDate = null;
        this.daysDisable = [];
        this.disableCaledar = false;
        this.selectedDepartment = 0;
        this.historyFilter.department = null;
    }

    loadSummaryViewyLazy(event?) {

        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

        if ((event && event.first / event.rows) === 0) {
            this.page = 0;
        }
        this.historyFilter.page = this.page;
        this.historyFilter.size = this.size;
        if(this.initLoad)
            this.loadPage(this.historyFilter);
    }

    async loadPage(options?: any) {
        if (!options) {
            options = this.historyFilter;
        }
        this.loading = true;
        this.OtviewList = [];
        const resObj: any = await this.employeeClockingService.getOverTimeWithFilter(options).toPromise();
        this.loading = false;
        if (resObj.status === 'SUCCESS') {
            this.OtviewList = resObj.data.content;
            this.totalRecords = resObj.data.totalElements;
            this.updateOverTimeSheet();
            this.getDapartmentName();
        }
    }

    getDapartmentName() {
        this.OtviewList.forEach(element => {
            element["firstName"] = element.empClocking.employee.firstName;
            element["lastName"] = element.empClocking.employee.lastName;
            element["department"] = element.empClocking.employee.department;
        });
    }

    updateOverTimeSheet() {
        const overtimeAfterInMinutes = moment.duration(this.payrollSettings.overtimeAfter).asMinutes();
        console.log("overtime After" + overtimeAfterInMinutes);
        this.OtviewList.forEach(
            record => {
                const rate = record.empClocking.employee.rate?record.empClocking.employee.rate:0;
                if (record.totalTime > overtimeAfterInMinutes) {
                    record.overtimeHours = record.totalTime - overtimeAfterInMinutes;
                    record.totalTime = overtimeAfterInMinutes;
                    if(record.overtimeHours > 0)
                    {
                    const amount = rate * (record.overtimeHours/60) * this.payrollSettings.overtimeMultipler 
                    const extraTime = (amount / rate) * 60;
                    record.balanceHours = record.weeklyhoursInMinute - extraTime;
                    }else
                    record.balanceHours = 0;
                }
                record.potentialOT = record.weeklyhoursInMinute + record.potentialOT;
                if(record.potentialOT < overtimeAfterInMinutes)
                        record.potentialOT = 0;
                //record.regularHours = <any>this.convertMinutesToHours(record.regularHours ? record.regularHours : 0);
                record.totalTimeinHHMM = this.convertMinutesToHours(record.totalTime ? record.totalTime : 0);
                record.overtimeHoursInHHMM = this.convertMinutesToHours(record.overtimeHours ? record.overtimeHours : 0);
                record.balanceHoursinHHMM = this.convertMinutesToHours(record.balanceHours?record.balanceHours:0);
                record.potentialOTInHHMM = this.convertMinutesToHours(record.potentialOT > 0?record.potentialOT:0);
            }
        )
    }

    convertMinutesToHours(minutes: number) {
        
        let isNegative = false;
        if(minutes < 0 )
            isNegative = true;
        
        minutes = Math.abs(minutes);

        if (minutes == 0 || minutes == null)
            return "00:00";
        else {
            if (minutes % 60 == 0)
            {
                let m = (minutes / 60);
                return m < 10? '0'+m + ':00' : m.toString().concat(":00");
            }
                
            else {
                let m:any = Math.floor(minutes % 60);
                let h:any = Math.floor(((minutes - m) / 60));

                if(h < 10) h='0'+h;
                if(m < 10) m = '0'+m;

                return   (isNegative?'-':'') + h + ":" + m;
            }
        }

    }

    sortingClicked(selectedSortOptions){
  
        selectedSortOptions = selectedSortOptions === 'ASC' ? 1 : -1;
          this.sort(this.selectedField, selectedSortOptions);
      }

      sort(sortField:string, selectedSortOption:number) {

        this.OtviewList.sort((row1,row2) => {
        
        let val1;
        let val2;
        if(sortField == 'firstName')
        {
            val1 = row1['empClocking']['employee'].firstName.toLowerCase();
            val2 = row2['empClocking']['employee'].firstName.toLowerCase();
        }else if(sortField == "lastName")
        {
            val1 = row1['empClocking']['employee'].lastName.toLowerCase();
            val2 = row2['empClocking']['employee'].lastName.toLowerCase();
        }else if(sortField == "department")
        {
            val1 = row1['empClocking']['employee'].department;
            val2 = row2['empClocking']['employee'].department;
        }else
        {
            val1 = row1[sortField];
            val2 = row2[sortField]
        }
        
          
          if(val1 == val2)
                return 0;
              
          let result=-1;
          if(val1 > val2) result= 1;
          
          if(selectedSortOption < 0 ){
                result=-result;
              }
          
          return result;
        })
        
      }

}
