import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Guest } from '../model/guest.model';
import { CompanyAdmin } from '../../admin/company-admin/model/company-admin.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class GuestService extends AbstractService<Guest> {

    // private docApiUrl = `${environment.server_ip}/${Constants.ROUTES.FILEUPLOAD}`;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.GUEST, '');
    }

    getGuestsListByPage(params: any): Observable<Page<Guest>> {
        return this._http.get<Page<Guest>>(this.baseUrl + Constants.URI.GUEST.LISTALLBYPAGE, { params: params }).pipe(map(resp => resp));
    }

    getGuestsByAgencyId(params: any): Observable<Page<Guest>> {
        return this._http.get<Page<Guest>>(this.baseUrl + Constants.URI.GUEST.LISTALLBYAGENCY, { params: params }).pipe(map(resp => resp));
    }

    getGuestsToday(params?: any): Observable<Guest[]> {
        return this._http.get<Guest[]>(this.baseUrl + Constants.URI.GUEST.ALLTODAY, { params: params }).pipe(map(resp => resp));
    }

    getAllUserCreatedGuest(agencyId: number): Observable<any[]> {
        return this._http.get<any[]>(this.baseUrl + 'findAllUserCreatedGuest?agencyId=' + agencyId).pipe(map(resp => resp));
    }

    loadGuests(options: any) {
        return this._http.get<Page<Guest>>(this.baseUrl + 'loadGuests', { params: options }).pipe(map(resp => resp));
    }

    unblockUser(data) {
        return this._http.get(`${this.baseUrl}${data.id}/status/${data.status}`);
    }

    createGuests(entity: any): Observable<any> {
        // return this._http.post<any>('http://localhost:8011/api/v2/guest', entity).pipe(map(res => res));
        return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
    }
}
