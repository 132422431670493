<h5 class="column-title d-flex align-items-center group-handle" *ngIf="editingTitle ? false : true"
    (dblclick)="enableEditTitle()">
    <span class="group-handle">&#x2630;</span>
    <span style="width: 70%;"><span class="tooltiptext">{{column.name}}</span>
        <pre style="margin-bottom: 0;font-size: 1.03rem;" class="group-handle">{{column.name}}</pre>
    </span>
</h5>
<!-- Form to show when clicked on text -->
<div class="form-group mb-0 p-3" *ngIf="editingTitle ? true : false">
    <input #editTitleField type="text" class="form-control input-sm" placeholder="Enter column title..."
        (keyup)="editTitleOnEnter($event)" (blur)="editTitleOnBlur()" [(ngModel)]="editTitleText">
</div>