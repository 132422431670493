import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[opr-only-numbs-special-chars]'
})
export class OnlyNumbersSpecialCharacterDirective {

  @Input('allow-special-character')
  allowSpecialCharacters: string[];

  inputElement: HTMLElement;

  private readonly SPECIAL_CHARACTERS = '~!@#$%^&*()_+{}|:"<>?`=\\-[\\]\\\\;\',./';

  private navigationKeys = [
    'Delete',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
    'Backspace',
  ];

  constructor(private el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) ||
      (e.key === 'c' && e.ctrlKey === true) ||
      (e.key === 'v' && e.ctrlKey === true) ||
      (e.key === 'x' && e.ctrlKey === true) ||
      (e.key === 'a' && e.metaKey === true) ||
      (e.key === 'c' && e.metaKey === true) ||
      (e.key === 'v' && e.metaKey === true) ||
      (e.key === 'x' && e.metaKey === true)
    ) {
      return;
    }

    if (this.allowSpecialCharacters && this.allowSpecialCharacters.findIndex(s => s === e.key || (s === e.key && e.shiftKey === true)) > -1) {
      return;
    }

    if ((e.shiftKey || (e.which < 48 || e.which > 57)) &&
      (e.which < 96 || e.which > 105)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let pastedInput: string;
    if (this.allowSpecialCharacters) {
      let allSpecialCharacters = this.SPECIAL_CHARACTERS;
      this.allowSpecialCharacters.forEach(s => {
        allSpecialCharacters = allSpecialCharacters.replace(s, '');
      });
      const regExp = new RegExp('[a-zA-Z' + allSpecialCharacters + ']', "g");
      pastedInput = event.clipboardData.getData('text/plain').replace(regExp, '');
    } else {
      pastedInput = event.clipboardData.getData('text/plain').replace(/\D/g, '');
    }
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    let textData;
    if (this.allowSpecialCharacters) {
      let allSpecialCharacters = this.SPECIAL_CHARACTERS;
      this.allowSpecialCharacters.forEach(s => {
        allSpecialCharacters = allSpecialCharacters.replace(s, '');
      });
      const regExp = new RegExp('[a-zA-Z' + allSpecialCharacters + ']', "g");
      textData = event.dataTransfer.getData('text').replace(regExp, '');
    } else {
      textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    }
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}
