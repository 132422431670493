import { Injectable, NgZone } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { LazyMapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class GMapsService extends GoogleMapsAPIWrapper {
  private static FUSION_TABLE_URL = environment.fustiontable.apiUrl;
  private static FUSTION_TABLE_API_KEY = environment.fustiontable.apiKey;
  private static FUSTION_TABLE_ID = environment.fustiontable.tableId;

  constructor(
    private __loader: LazyMapsAPILoader,
    private __zone: NgZone,
    private http: HttpClient
  ) {
    super(__loader, __zone);
  }

  getCoordinate(polygonIds: any) {
    const whereQuery = ' where polygon_id IN (' + polygonIds.join(',') + ')';
    const query =
      'SELECT geometry FROM ' + GMapsService.FUSTION_TABLE_ID + whereQuery;
    return this.http.get(
      GMapsService.FUSION_TABLE_URL +
        '?key=' +
        GMapsService.FUSTION_TABLE_API_KEY +
        '&sql=' +
        query
    );
  }

  getAllCoordinate() {
    
  }
}
