
import {DocumentData} from "../../shared/model/document-data.model";

export class CompanySetting {
    id: number;
    companyId: number;
    mainWhiteLabel: string;
    upperLeftLabel: string;
    logoId: number;
    document: DocumentData;
    customizedHour: number;
    customizedMinute: number;
    sendTime: number;
    intervalTime: number;
    selectedAllShortcuts?:boolean;
    shortcutsCheckBoxes?:any;
    clientDashboardF1:any;
    clientDashboardF2:any;
    clientDashboardF3:any;
    marquee:any;
    driver:any;
    host:any;
    fromName: any;
    port: any;
    fromAddress: any;
    encryption: any;
    userName: any;
    password: any;
    emailSignature: any;
    createdByUsr:any;
    lastModifiedBy: any;
    documentId: any;
}
