import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Agency, CommandRequest } from '../model';
import { Message } from 'primeng/api';
import { AgencySetting } from '../model/agency-setting.model';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AgencySettingService extends AbstractService<AgencySetting> {

    private requestSubject = new Subject<CommandRequest>();
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    request$ = this.requestSubject.asObservable();

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.AGENCY_SETTING, '');
    }

    sendCommand(cmd: CommandRequest) {
        this.requestSubject.next(cmd);
    }

    loadSendToUsers(params): Observable<any> {
        return this._http.get(`${this.baseUrl}employees`, { params: params });
    }

    notifyMessage(message: Message) {
        this.messageSubject.next(message);
    }

    getAgencyPage(params: any): Observable<Page<Agency>> {
        return this._http.get<Page<Agency>>(this.baseUrl, { params: params }).pipe(map(resp => resp));
    }

    saveAgency(model: Agency): Observable<any> {
        return this._http.post<any>(`${this.baseUrl}`, model).pipe(map(resp => resp));
    }

    saveHistoryData(data) {
        return this._http.post(`${this.baseUrl}history`, data).pipe(map(resp => resp));
    }
}
