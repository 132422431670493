<h1 class="m-0 ml-2 my-4">{{'Communication' | translate}}</h1>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'SMS Monitoring' | translate}} ({{ totalSmsRecords }})</span>
	</p-header>
	

	<p-table #dt [value]="smsTableList" selectionMode="single" dataKey="id" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalSmsRecords" (onLazyLoad)="loadSmsListLazy($event)" [lazy]="true" [contextMenu]="smsCM" [responsive]="true" [columns]="smsColumns">
		<p-contextMenu #smsCM [model]="smsItems"></p-contextMenu>


		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center flex-wrap justify-content-end" style="gap: 10px">
				<div class="input-group w-100" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="smsMonitoringFromDate" name="Startdate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
							<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="smsMonitoringToDate" name="Enddate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
					<div class="input-group-append">
						<button class="btn btn-white" (click)="resetSmsMonitoring()" pTooltip="{{'button.reset' | translate}}" tooltipPosition="left"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
						<button class="btn btn-white" (click)="loadSmsListLazy()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
        		<th class="adjust-padding" pResizableColumn *ngFor="let col of smsColumns" [ngStyle]="{'display': col.display}" [ngStyle]="{'width': col.width}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedSmsField = col.field;">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'createdAt'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedEmailField = col.field;">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'documentId'">
							<span class="d-flex justify-content-between w-100">
								<i class="fa-solid fa-paperclip text-muted m-auto" pTooltip="{{'Attachments' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</th>
				<th style="width: 45px;">
					<span class="d-flex justify-content-between align-items-center w-100">
						<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr *ngIf="!loadingSms" [pSelectableRow]="rowData">
				<td *ngFor="let col of smsColumns" class="adjust-padding-td ui-resizable-column" [ngStyle]="{'display': col.display,'cursor':col.click || col.dblClick? 'pointer': ''}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'createdAt'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'sentBy'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Sender' | translate}}: </span>
								<span *ngIf="rowData.sentBy" pTooltip="{{rowData.sentBy}}">{{rowData.sentBy}}</span>
								<span *ngIf="!rowData.sentBy" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'content'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Content' | translate}}: </span>
								<span *ngIf="rowData.content" pTooltip="{{rowData.content}}">{{rowData.content}}</span>
								<span *ngIf="!rowData.content" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'documentId'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
								<i *ngIf="rowData.documentId" (click)="onViewDocument(rowData.documentId)" class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Open' | translate}}"></i>
								<i *ngIf="!rowData.documentId" class="fa-solid fa-paperclip text-muted" pTooltip="{{'no data' | translate}}" style="cursor: default"></i>
							</span>
						</span>
					</ng-container>
				</td>
				<td class="actions mobile-wide">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i class="fa-solid fa-up-right-from-square text-primary c-pointer mx-1" pTooltip="{{'Open' | translate}}" tooltipPosition="left" (click)="showModalViewSmsMonitoring(rowData, 'SMS Monitoring')"></i>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loadingSms">
				<td [attr.colspan]="smsColumns?.length + 1" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loadingSms">
				<td [attr.colspan]="smsColumns?.length + 1" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
  	</p-table>
</p-panel>
<br>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Email' | translate}}  ({{ totalEmailRecords || 0 }})</span>
	</p-header>
	<p-table #dt2 [value]="emailTableList" selectionMode="single" dataKey="id" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalEmailRecords" (onLazyLoad)="loadEmailListLazy($event)" [lazy]="true" [contextMenu]="emailCM" [responsive]="true" [columns]="emailColumns">
		<p-contextMenu #emailCM [model]="emailItems"></p-contextMenu>
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center flex-wrap justify-content-end" style="gap: 10px">
				<div class="input-group w-100" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field>
						<mat-date-range-input [rangePicker]="picker1">
							<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="emailFromDate" name="Startdate" (focus)="picker1.open()" [ngModelOptions]="{standalone: true}">
							<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="emailToDate" name="Enddate" (focus)="picker1.open()" [ngModelOptions]="{standalone: true}">
						</mat-date-range-input>
						<mat-date-range-picker #picker1></mat-date-range-picker>
					</mat-form-field>
					<div class="input-group-append">
						<button class="btn btn-white" (click)="resetEmail()" pTooltip="{{'button.reset' | translate}}" tooltipPosition="left"><span class="text-nowrap"><i class="fa-solid fa-xmark text-danger"></i></span></button>
						<button class="btn btn-white" (click)="loadEmailListLazy()"><span class="text-nowrap"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</span></button>
					</div>
				</div>
				</div>
			</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th class="adjust-padding" pResizableColumn *ngFor="let col of emailColumns" [ngStyle]="{'display': col.display}" [ngStyle]="{'width': col.width}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedEmailField = col.field;">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'createdAt'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedEmailField = col.field;">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'documentIds'">
							<span class="d-flex justify-content-between w-100">
								<i class="fa-solid fa-paperclip text-muted m-auto" pTooltip="{{'Attachments' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</th>
				<th style="width: 45px;">
					<span class="d-flex justify-content-between align-items-center w-100">
						<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
					</span>
				</th>
			</tr>
		</ng-template>
    	<ng-template pTemplate="body" let-rowData>
			<tr *ngIf="!loadingSms" [pSelectableRow]="rowData">
				<td *ngFor="let col of emailColumns" class="adjust-padding-td ui-resizable-column" [ngStyle]="{'display': col.display,'cursor':col.click || col.dblClick? 'pointer': ''}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'createdAt'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy h:mm:ss a'}}</span>
								<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'createdByUsr'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Sender' | translate}}: </span>
								<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
								<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'subject'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'subject' | translate}}: </span>
								<span *ngIf="rowData.subject" pTooltip="{{rowData.subject}}">{{rowData.subject}}</span>
								<span *ngIf="!rowData.subject" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'content'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Content' | translate}}: </span>
								<span *ngIf="rowData.content" pTooltip="{{rowData.content}}">{{rowData.content}}</span>
								<span *ngIf="!rowData.content" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'documentIds'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
								<i *ngIf="rowData.documentIds && rowData.documentIds?.length > 0" (click)="onViewDocument(rowData.documentIds, true)" class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Open' | translate}}"></i>
								<i *ngIf="!rowData.documentIds || rowData.documentIds?.length <= 0" class="fa-solid fa-paperclip text-muted" pTooltip="{{'no data' | translate}}" style="cursor: default"></i>
							</span>
						</span>
					</ng-container>
				</td>
				<td class="actions mobile-wide">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i class="fa-solid fa-up-right-from-square text-primary c-pointer mx-1" pTooltip="{{'Open' | translate}}" tooltipPosition="left" (click)="showModalViewSmsMonitoring(rowData, 'Email')"></i>
					</span>
				</td>
			</tr>
    	</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loadingEmail">
				<td [attr.colspan]="emailColumns?.length + 1" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loadingEmail">
				<td [attr.colspan]="emailColumns?.length + 1" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSmsSortOption" (onChange)="smsSortingClick(selectedSmsSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSmsSortOption" (onChange)="smsSortingClick(selectedSmsSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSmsSortOption" (onChange)="smsSortingClick(selectedSmsSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSmsSortOption" (onChange)="smsSortingClick(selectedSmsSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-overlayPanel #op5 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedEmailSortOption" (onChange)="emailSortingClick(selectedEmailSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op6 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedEmailSortOption" (onChange)="emailSortingClick(selectedEmailSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op7 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedEmailSortOption" (onChange)="emailSortingClick(selectedEmailSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op8 appendTo="body">
	<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedEmailSortOption" (onChange)="emailSortingClick(selectedEmailSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="showViewFileDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'600px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'View Attached' | translate}}</span>
	</p-header>
	<img *ngFor="let fileUrl of fileUrls" class="w-100" [src]="fileUrl" alt="">
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showViewFileDialog = false">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showViewSmsMonitoring" [modal]="true" [style]="{'width': '90%', 'max-width':'600px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-message mr-2"></i>{{'Message Details' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span *ngIf="selectedSmsMonitoring" style="font-size: 16px">{{selectedSmsMonitoring?.content}}</span>
			<span *ngIf="!selectedSmsMonitoring" style="font-size: 16px">{{'no data' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<span>
			<button *ngIf="selectedSmsMonitoring?.documentId"  class="btn btn-primary" (click)="onViewDocument(selectedSmsMonitoring?.documentId)">
				<span><i class="fa-solid fa-paperclip mr-2"></i>{{'View Attached' | translate}}<i class="fa-solid fa-up-right-from-square ml-2"></i></span>
			</button>
		</span>
		<button class="btn btn-primary" (click)="showViewSmsMonitoring = false">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>