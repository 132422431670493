<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Aging Details' | translate}}</h1>
<p-panel [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Aging Details' | translate}}</span>
    </p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
            <label>{{'companyLabel' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
                        <i class="fa-solid fa-building"></i>
                    </button>
                </div>
                <p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Client' | translate}}: </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
                        <i class="fa-solid fa-briefcase"></i>
                    </button>
                </div>
                <p-dropdown [options]="clientList" filter="true" optionLabel="value"  placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedClient" [showClear]="true" (onChange)="changeClient()">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Date Range' | translate}}:</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
                        <i class="fa-solid fa-calendar-week"></i>
                    </button>
                </div>
                <mat-form-field class="w-100">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
                        <input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Show' | translate}}</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-filter"></i>
                    </button>
                </div>
                <p-dropdown [options]="showSelectedList" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedToShow">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
                </p-dropdown>
            </div>
        </div>
		<div class="ui-g-6 ui-sm-12">
            <label>{{'Status' | translate}}:</label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" type="button" id="button-addon2" style="cursor:default !important">
                        <i class="fa-solid fa-info"></i>
                    </button>
                </div>
                <p-multiSelect defaultLabel="{{'pleaseSelect' | translate}}" name="statusSearch" [options]="statusTypes" [(ngModel)]="selectedStatuses" id="id_invoice_status_search_label">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner" style="font-size: 14px !important">{{item.label | translate}}</span>
					</ng-template>
                </p-multiSelect>
            </div>
        </div>
        <div class="ui-g-12 text-center py-4">
            <button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
            <button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
        </div>
    </div>
</p-panel>
<div class="d-flex align-items-center justify-content-start py-4">
    <h1 class="m-0 ml-2">{{'Browse Details' | translate}}</h1>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
    <p-header class="flex-grow-1">
        <span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Aging Details' | translate}} ({{totalRecords}})</span>
    </p-header>
    <p-table #dt [value]="statementBalanceList" dataKey="section" selectionMode="single" [rows]="10" [totalRecords]="totalRecords" [responsive]="true">
		<ng-template pTemplate="caption">
            <div class="d-flex align-items-center justify-content-start">
                <span class="d-flex">
					<button type="button" class="btn btn-primary mr-2" (click)="exportTable()">
						<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
					</button>
				</span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of cols">
                    <ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
                        <span *ngSwitchCase="'invoiceDate'">
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op4.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
                    </ng-container>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded">
            <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData.section].index === rowIndex && !rowData.amount && rowData.amount !== 0">
                <td colspan="7">
                    <a href="#" [pRowToggler]="rowData" (click)="expandedList(expanded, rowData.section)">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>&nbsp;
                        <span *ngIf="rowData.section === '1'">{{'1 - 30'}}&nbsp;{{'days past due' | translate}}</span>
                        <span *ngIf="rowData.section === '3'">{{'30 - 60'}}&nbsp;{{'days past due' | translate}}</span>
                        <span *ngIf="rowData.section === '5'">{{'61 - 90'}}&nbsp;{{'days past due' | translate}}</span>
                        <span *ngIf="rowData.section === '7'">{{'91 - 120'}}&nbsp;{{'days past due' | translate}}</span>
                        <span *ngIf="rowData.section === '9'">{{'120+'}}&nbsp;{{'days past due' | translate}}</span>
                    </a>
                </td>
            </tr>
            <tr *ngIf="rowData.amount >= 0">
                <td [attr.colspan]="5">
					<span class="one-liner">
						<span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '2'">{{'Total' | translate}}&nbsp;{{'1 - 30'}}&nbsp;{{'days past due' | translate}}</span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '4'">{{'Total' | translate}}&nbsp;{{'31 - 60'}}&nbsp;{{'days past due' | translate}}</span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '6'">{{'Total' | translate}}&nbsp;{{'61 - 90'}}&nbsp;{{'days past due' | translate}}</span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '8'">{{'Total' | translate}}&nbsp;{{'91 - 120'}}&nbsp;{{'days past due' | translate}}</span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '10'">{{'Total' | translate}}&nbsp;{{'120+'}}&nbsp;{{'days past due' | translate}}</span>
                            <span class="font-weight-bold" *ngIf="rowData.section === '11'">{{'Total' | translate}}</span>
                        </span>
					</span>
                </td>
                <td>
                    <span class="one-liner">
						<span class="font-weight-bold">{{(rowData.balance ? rowData.balance : 0) | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
                <td>
                    <span class="one-liner">
						<span class="font-weight-bold">{{(rowData.amount ? rowData.amount : 0) | currency : 'USD':'symbol':'1.2-2'}}</span>
					</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-i="rowIndex">
            <tr *ngIf="!loading && !rowData.amount">
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.invoiceDate" pTooltip="{{formatDate(rowData.invoiceDate)}}">{{formatDate(rowData.invoiceDate)}}</span>
						<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'TransactionType' | translate}}: </span>
						<span *ngIf="rowData.paymentMethod" pTooltip="{{ rowData.paymentMethod }}">{{ rowData.paymentMethod }}</span>
						<span *ngIf="!rowData.paymentMethod" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" (click)="clickInvoiceRef($event,rowData);" class="text-primary c-pointer" pTooltip="{{ rowData.invoiceNumber }}">{{ rowData.invoiceNumber }}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Customer' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{ rowData.clientName }}">{{ rowData.clientName }}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Due Date' | translate}}: </span>
						<span *ngIf="rowData.dueDate" pTooltip="{{rowData.dueDate | date: 'M/d/yyyy'}}">{{rowData.dueDate | date: 'M/d/yyyy'}}</span>
						<span *ngIf="!rowData.dueDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Amount' | translate}}:</span>
						<span *ngIf="rowData.balance" pTooltip="{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.balance | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.balance" class="text-muted">$0</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Open Balance' | translate}}: </span>
						<span *ngIf="rowData.totalFee" class="text-danger" pTooltip="{{rowData.totalFee | currency : 'USD':'symbol':'1.2-2'}}">{{rowData.totalFee | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.totalFee" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
            </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="loading">
				<td [attr.colspan]="7" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="!loading">
				<td [attr.colspan]="7" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
		</ng-template>
    </p-table>
</p-panel>

<p-overlayPanel #op3 appendTo="body">
    <p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
        <ng-template let-col pTemplate="item">
            <div>
                <span>{{col.name | translate}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-overlayPanel>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>