export class CheckListModel {
    id: number;
    name: string;
    projectId: number;
    selectedHide: boolean;
    checked: boolean;
    taskCheckListItems: any;
    taskId: number;
    createdByUsr: string;
    lastModifiedBy: string;
    assignedUser: any;
    assignedUserId: number;
}