import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from '../shared/shared.module';
import { ArReportRoutingModule } from './ar-report-routing.module';
import { RevenuePaymentComponent } from './component/revenue-payment/revenue-payment.component';
import { ArReportLayoutComponent } from './component/ar-report-layout/ar-report-layout.component';
import { ServiceRevenueComponent } from './component/service-revenue/service-revenue.component';
import { GrossIncomeComponent } from './component/gross-income/gross-income.component';
import { DecimalPipe } from '@angular/common';
import { ReceivableAgingDetailsComponent } from './component/receivable-aging-details/receivable-aging-details.component';
import { AgingSummaryComponent } from './component/aging-summary/aging-summary.component';
import { MentionModule } from 'angular-mentions';
import { TransactionDetailsService } from './services/transaction-details.service';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimeNgModule,
    WebcamModule,
    SharedModule,
    ArReportRoutingModule,
    MentionModule
  ],
  declarations: [ArReportLayoutComponent,
    RevenuePaymentComponent,
    ServiceRevenueComponent,
    GrossIncomeComponent,
    ReceivableAgingDetailsComponent,
    AgingSummaryComponent
  ],
  providers: [DecimalPipe, TransactionDetailsService]
})
export class ArReportModule { }
