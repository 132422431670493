export class UserActivity {
    id: number;
    userId: number;
    username: string;
    companyId: number;
    name: string;
    type: string;
    loggedIn: number;
    loginStatus: string;
    profilePhoto: string;
    applicationType: string;
}
