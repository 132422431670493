import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShareDataService {
    constructor() {}

    private dataSubject = new BehaviorSubject<any>(null);

    shareData$ = this.dataSubject.asObservable();

    share(data: any) {
        this.dataSubject.next(data);
    }

    clear() {
        this.dataSubject.next(null);
    }
}
