export class TicketCommentModel {
    id:number
    content: string
    freelancerTicketId: number
    taskId: number
    userId: number
    userType: string
    commentUserType: string
    createdByUsr: string
    ticketLink:string
    boardName:string
}