import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-member',
  templateUrl: './card-member.component.html',
  styleUrls: ['./card-member.component.scss']
})
export class CardMemberComponent implements OnInit {
  @Input() member: any = {};
  @Input() allMember: any = [];
  @Input() boardAdmin: any;
  @Input() fromZoneList: boolean = false;

  colors = ['#88d300',
    '#FFC300',
    '#ff7c00',
    '#70c600',
    '#0e7fe1',
    '#e42525']
  constructor() { }

  ngOnInit(): void {
    try {
      const memberInfo = this.allMember.find(x => x.id == this.member.id && x.userType==this.member.userType);
      if(memberInfo){
        this.member.avatar = memberInfo.avatar;
      }
      this.member.displayName = this.member.name.match(/\b(\w)/g).join('').slice(0, 2);
    } catch (error) {
      this.member.displayName = this.member?.name?.split(0, 1)
    }
    this.member.color = this.colors[this.member.id % this.colors.length]
  }

}
