import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { switchMap, take, tap } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';
import { Constants } from '../model/constants';
import { TimeUtility } from '../utility/time.utility';

@Directive({
  selector: '[appCounterIncrease]'
})
export class CounterIncreaseDirective implements OnInit, OnChanges, OnDestroy {
  private audioFile: HTMLAudioElement;
  private _counterSource$ = new Subject<any>();
  private _subscription = Subscription.EMPTY;
  private static SECONDS_IN_MINUTE = 60;

  @Input() counter: number; // Value in minutes
  @Input() showVolume: boolean; // Value in minutes
  @Input() interval: number; // Value in miliseconds
  @Output() value = new EventEmitter<string>();
  // @Output() lastMinute = new EventEmitter<void>();
  @Output() updateMinute = new EventEmitter<string>();
  constructor() {
    this._subscription = this._counterSource$.pipe(
      switchMap(({ interval, countIncrease }) =>
        timer(0, interval).pipe(
          tap(() => {
            const countEmit = ++countIncrease;
            this.value.emit(this.getTimeLeftAsString(countEmit));
            this.updateMinute.emit(countEmit.toString());
          })
        )
      )
    ).subscribe();
  }

  ngOnInit(): void { 
    this.playAudio();
  }

  ngOnChanges() {
    if (!this.showVolume) {
      this.muteAudio();
    }else {
      this.unMuteAudio();
    }
    this._counterSource$.next({ countIncrease: this.counter, interval: this.interval });
  }

  ngOnDestroy() {
    this.stopCounter();
  }

  stopCounter() {
    this._subscription.unsubscribe();
    this.stopAudio();
  }

  getTimeLeftAsString(count) {
    let hours = Math.floor(count / 3600);
    let minutes = Math.floor(count % 3600 / 60);
    let seconds = Math.floor(count % 3600 % 60);
    hours = TimeUtility.checkTime(hours);
    minutes = TimeUtility.checkTime(minutes);
    seconds = TimeUtility.checkTime(seconds);

    return `${hours}:${minutes}:${seconds}`;
  }

  playAudio() {
    this.audioFile = new Audio();
    this.audioFile.src = 'assets/audio/clock_ticking.mp3';
    this.audioFile.onloadedmetadata = () => {
      // Loop audio
      this.audioFile.addEventListener('ended', () => {
        this.audioFile.currentTime = 0;
        this.audioFile.play();
      }, false);
      const playPromise = this.audioFile.play();
      playPromise.catch((e)  => {
        // do something
        console.error(e);
        if (this.audioFile) {
          this.audioFile.play();
        }
      });
    };

  }

  stopAudio() {
    if (!this.audioFile) {
      return;
    }

    this.audioFile.currentTime = 0;
    this.audioFile.pause();

  }

  muteAudio() {
    if (!this.audioFile) {
      return;
    }
    this.audioFile.pause();

  }

  unMuteAudio() {
    if (!this.audioFile) {
      return;
    }
    this.audioFile.play();

  }
}
