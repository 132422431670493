export enum AppHotkey {
    A_KEY = 65,
    C_KEY = 67,
    D_KEY = 68,
    E_KEY = 69,
    H_KEY = 72,
    M_KEY = 77,
    N_KEY = 78,
    P_KEY = 80,
    R_KEY = 82,
    S_KEY = 83,
    T_KEY = 84,
    V_KEY = 86,
}
