import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaceRecognitionComponent } from './face-recognition/face-recognition.component';
import { FaceRecognitionService } from 'app/shared/service/face-recognition/face-recognition.service';
import { PrimeNgModule } from 'app/prime-ng/prime-ng.module';

@NgModule({
  declarations: [FaceRecognitionComponent],
  imports: [CommonModule, PrimeNgModule],
  exports: [FaceRecognitionComponent],
  providers: [FaceRecognitionService]
})
export class FaceRecognitionModule {}
