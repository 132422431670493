import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-transaction-group-add',
    templateUrl: './transaction-group-add.component.html',
    styleUrls: ['./transaction-group-add.component.scss']
})
export class TransactionGroupAddComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}
