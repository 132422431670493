import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { OntimeLateComponent } from './component/ontime-late/ontime-late.component';
import { PunchInOutHistoryComponent } from './component/punch-in-out-history/punch-in-out-history.component';
import { PunchInOutComponent } from './component/punch-in-out/punch-in-out.component';
import { EmployeeClockingLayoutComponent } from './component/employee-clocking-layout/employee-clocking-layout.component';
import { SummaryViewComponent } from './component/summary-view/summary-view.component';
import { EmployeeOTComponent } from "./component/employee-ot/employee-ot.component";
import { PunchInOutTrackingComponent } from './component/punch-in-out-tracking/punch-in-out-tracking.component';
import { EmployeeClockingListComponent } from '../employee/component/employee-clocking-list/employee-clocking-list.component';
import { NgModule } from '@angular/core';
import {ProtestHourListAllComponent} from "../employee/component/protest-hour/protest-hour-list-all/protest-hour-list-all.component";
import {EmployeePunchConfirmationComponent} from './component/employee-punch-confirmation/employee-punch-confirmation.component';


export const routes: Routes = [
  {
    path: '',
    component: EmployeeClockingLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'punch', component: PunchInOutComponent },
      { path: 'punch-history', component: PunchInOutHistoryComponent },
      { path: 'ontime-late', component: OntimeLateComponent },
      { path: 'summary-view', component: SummaryViewComponent },
      { path: 'employee-ot', component: EmployeeOTComponent },
      { path: 'punch-tracking', component: PunchInOutTrackingComponent },
      { path: 'list', component: EmployeeClockingListComponent },
      { path: 'protest-hour', component: ProtestHourListAllComponent },
      { path: 'work-confirmation', component: EmployeePunchConfirmationComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeClockingRoutingModule { }

