import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FaceRecognitionService {
  
  protected faceRecognitionApiUrl:string = `${environment.server_ip}/${Constants.ROUTES.FACERECOGNITION}`;
  protected faceDetectionApiUrl:string = `${environment.server_ip}/${Constants.ROUTES.FACEDETECTION}`;

  constructor(protected _http:HttpClient) { }

  uploadFace(file:File,entityName:string,entityId:any,fileclass:string,userType:string): Observable<any>{
    let reqParams = {entity:entityName,entityId:entityId,fileClass:fileclass};
    if(!reqParams.entityId){
      reqParams.entityId = 0;
    }
    let payload = new FormData();
    payload.append("file",file);
    return this._http.post(`${this.faceRecognitionApiUrl}/${Constants.URI.FACERECOGNITION.FACE}/${userType}/${entityId}`,payload,{params:reqParams}).pipe(map(res => res));
  }

  getFaceIds(userId:any,userType:string):Observable<any>{
    return this._http.get(`${this.faceRecognitionApiUrl}/${Constants.URI.FACERECOGNITION.GETFACES}/${userType}/${userId}`).pipe(map(res => res));
  }

  deleteFace(faceId:any,userId:any,userType:string):Observable<any>{
    if(!userId){
      userId = 0;
    }
    return this._http.delete<any>(`${this.faceRecognitionApiUrl}/${Constants.URI.FACERECOGNITION.FACE}/${faceId}/${userType}/${userId}`).pipe(map(res => res));
  }

  faceDetection(image:any,userType:string):Observable<any>{
    let reqData = {
      fileType:'png',
      imageBase64String:image
    };
    return this._http.post(`${this.faceDetectionApiUrl}/${userType}`,reqData).pipe(map(res => res));
  }

}
