import { EditColumnTitleComponent } from './component/edit-column-title/edit-column-title.component';
import { AddCardComponent } from './component/add-card/add-card.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TaskLayoutComponent } from './component/task-layout/task-layout.component';
import { BoardProjectComponent } from './component/board-project/board-project.component';
import { ProjectDetailComponent } from './component/project-detail/project-detail.component';
import { CardComponent } from './component/card/card.component';
import { ProjectItemComponent } from './component/project-item/project-item.component';
import { TaskManagementRoutingModule } from './task-management-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from 'app/prime-ng/prime-ng.module';
import { ContextMenuModule, DialogModule } from 'primeng';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DragulaModule } from 'ng2-dragula';
import { EditorModule } from 'primeng/editor';
import { ListboxModule } from 'primeng/listbox';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from 'app/shared/material-module/material.module';
import { CommentItemComponent } from './component/comment-item/comment-item.component';
import { FileDragDirective } from './file-drag.directive';
import { BackgroundPanelComponent } from './component/background-panel/background-panel.component';
import { TaskManagementTopBarComponent } from './component/task-management-top-bar/task-management-top-bar.component';
import { AttachmentSectionComponent } from './component/attachment-section/attachment-section.component';
import { FreelancerAssignComponent } from './component/freelancer-assign/freelancer-assign.component';
import { FreelancerTicketHistoryComponent } from './component/freelancer-ticket-history/freelancer-ticket-history.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MentionModule } from 'angular-mentions';
import { FormValidatorService } from '../shared/service/form-validator/form-validator.service';
import { DragToUploadFileDirective } from './service/drag-to-upload-file.directive';
import { DragDropFileUploadDirective } from './service/drag-drop.directive';
import { TrustHtmlPipe } from './pipes/safeHtmlPipe.pipe';
import { TrackingCardComponent } from './component/tracking-card/tracking-card.component';
import { AllCardListTaskComponent } from './component/all-card/all-card-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from 'app/shared/shared.module';
@NgModule({
  declarations: [TaskLayoutComponent,
    BoardProjectComponent,
    ProjectDetailComponent,
    CardComponent,
    ProjectItemComponent,
    AddCardComponent,
    EditColumnTitleComponent,
    CommentItemComponent,
    FileDragDirective,
    BackgroundPanelComponent,
    TaskManagementTopBarComponent,
    FreelancerAssignComponent,
    FreelancerTicketHistoryComponent,
    DragToUploadFileDirective,
    DragDropFileUploadDirective,
    AttachmentSectionComponent,
    TrustHtmlPipe,
    TrackingCardComponent,
    AllCardListTaskComponent
  ],
  imports: [
    MentionModule,
    FormsModule,
    CommonModule,
    EditorModule,
    PrimeNgModule,
    ListboxModule,
    ProgressSpinnerModule,
    NgxDaterangepickerMd.forRoot(),
    ContextMenuModule,
    DialogModule,
    SharedModule,
    TaskManagementRoutingModule,
    DragulaModule.forRoot(),
    TranslateModule,
    ClickOutsideModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MaterialModule,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  providers: [
    FormValidatorService
  ],
  exports: [TrustHtmlPipe]
})
export class TaskManagementModule {
}
