import {Component, OnInit} from '@angular/core';
import {CarService} from '../../../demo/service/carservice';
import {EventService} from '../../../demo/service/eventservice';
import {Car} from '../../../demo/domain/car';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import {SelectItem} from 'primeng/api';
import {MenuItem} from 'primeng/api';
import { DashboardService } from '../../service/dashboard/dashboard.service';
import { UtilsService } from '../../../shared/service/utils.service';


@Component({
  selector: 'app-app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.css'],
  providers:[DashboardService]
})
export class AppDashboardComponent implements OnInit {

  cities: SelectItem[];

    cars: Car[];

    cols: any[];

    chartData: any;

    events: any[];

    selectedCity: any;

    selectedCar: Car;

    items: MenuItem[];

    header: any;

    employeeOverTimeList: Array<any>;

    options: any
    constructor(private carService: CarService, private eventService: EventService, private breadcrumbService: BreadcrumbService,private dashboardService:DashboardService, private utilsService:UtilsService) {
        this.breadcrumbService.setItems([
            {label: ''}
        ]);
        this.utilsService.visitedPageData.pageVisitStartTime = new Date();
        console.log(this.utilsService.visitedPageData.pageVisitStartTime);
    }

    ngOnInit() {
        this.options = {
            header: this.header,
        }
        this.carService.getCarsSmall().then(cars => this.cars = cars);

        this.cols = [
            { field: 'vin', header: 'Vin' },
            { field: 'year', header: 'Year' },
            { field: 'brand', header: 'Brand' },
            { field: 'color', header: 'Color' }
        ];

        this.eventService.getEvents().then(events => {this.events = events; });

        this.cities = [];
        this.cities.push({label: 'Select City', value: null});
        this.cities.push({label: 'New York', value: {id: 1, name: 'New York', code: 'NY'}});
        this.cities.push({label: 'Rome', value: {id: 2, name: 'Rome', code: 'RM'}});
        this.cities.push({label: 'London', value: {id: 3, name: 'London', code: 'LDN'}});
        this.cities.push({label: 'Istanbul', value: {id: 4, name: 'Istanbul', code: 'IST'}});
        this.cities.push({label: 'Paris', value: {id: 5, name: 'Paris', code: 'PRS'}});

        this.chartData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: '#FFC107'
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: '#03A9F4'
                }
            ]
        };

        this.items = [
            {label: 'Save', icon: 'fa-solid fa-check'},
            {label: 'Update', icon: 'fa-solid fa-rotate'},
            {label: 'Delete', icon: 'fa-solid fa-xmark'}
        ];

        this.header = {
            left: 'prev, next today',
            center: 'title',
            right: 'month, agendaWeek, agendaDay'
        };

        this.employeeOverTimeList = [];
        this.getEmployeeOvertime();
    }

    getEmployeeOvertime(){
        this.dashboardService.getEmployeeOvertimeList().subscribe(res => {
            let resObj:any = res;
            if(resObj.status === "SUCCESS"){
                this.employeeOverTimeList = resObj.data;
            }
        });
    }

    generateOvertimeMessage(employee){
        if (!employee.weeklyWorkedHours) {
            return '';
        }
        let workedWeekly = employee.weeklyWorkedHours.replace("m","").split("h");
        let workedHours = parseInt(workedWeekly[0]);
        let workedMinutes = parseInt(workedWeekly[1]);
        let weeklyWorkingTimeLimit = employee.weeklyWorkingTimeLimitation;

        return ' has worked ' + workedHours + ' hours ' + (workedMinutes > 0 ? workedMinutes + ' minutes ' : '') + '(limitation: ' + weeklyWorkingTimeLimit + 'h).';
    }

}
