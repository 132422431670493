<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="mt-4">{{'Payment Availability' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Payments' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Date Range' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown [options]="statuses" [(ngModel)]="selectedStatus" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex align-items-center flex-wrap justify-content-between py-4" syle="row-gap: 10px">   
		<h1 class="m-0 ml-2">{{'Browse Payments' | translate}}</h1>
		<h5 id="total-payments" class="m-0 text-secondary"><span class="text-dark">{{'Available' | translate}}</span>: {{availableBalance | currency}}</h5>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Payments' | translate}} ({{totalRecords || 0 | number}})</span>
		</p-header>
		<p-table #dt [value]="transactionList" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10 ,25, 50]" [responsive]="true" [customSort]="true" [lazy]="false" (onLazyLoad)="search($event)" [totalRecords]="totalRecords">

			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between w-100">
					<button type="button" (click)="exportTablePdf()" class="btn btn-primary text-nowrap mx-1">
						<i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i><i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i> {{'Export PDF' | translate}}
					</button>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input [(ngModel)]="searchText" #searchKeyInput placeholder="{{'Filter' | translate}}" pInputText type="text">
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th>#</th>
					<th class="adjust-padding" pResizableColumn *ngFor="let col of cols" [ngStyle]="{'display': col.display, 'width': col.width}">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span
											(click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr [pSelectableRow]="rowData">
					<td>
						{{i+1}}
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'login_type' | translate}}: </span>
							<span *ngIf="rowData.type" pTooltip="{{ rowData.type | titlecase }}" tooltipPosition="left">{{ rowData.type | titlecase }}</span>
							<span *ngIf="!rowData.type" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Net' | translate}}: </span>
							<span *ngIf="rowData.net"  pTooltip="{{rowData.net | currency : 'USD' : true : '1.2-2'}}" tooltipPosition="left">{{rowData.net | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.net" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Status' | translate}}Amount: </span>
							<span *ngIf="rowData.amount"  pTooltip="{{rowData.amount | currency : 'USD' : true : '1.2-2'}}" tooltipPosition="left">{{rowData.amount | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.amount" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Fee' | translate}}: </span>
							<span *ngIf="rowData.fee"  pTooltip="{{rowData.fee | currency : 'USD' : true : '1.2-2'}}" tooltipPosition="left">{{rowData.fee | currency : 'USD' : true : '1.2-2'}}</span>
							<span *ngIf="!rowData.fee" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Description' | translate}}: </span>
							<span *ngIf="rowData.description" pTooltip="{{ rowData.description }}" tooltipPosition="left">{{ rowData.description }}</span>
							<span *ngIf="!rowData.description" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Available On' | translate}}: </span> 
							<span *ngIf="rowData.availableOn" pTooltip="{{rowData.availableOn | date : 'MM/dd/yyyy'}}" tooltipPosition="left">{{rowData.availableOn | date : 'MM/dd/yyyy'}}</span>
							<span *ngIf="!rowData.availableOn" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="cols?.length + 1" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="cols?.length + 1" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
</div>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption"  (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
