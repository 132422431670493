<form [formGroup]="contractForm" class="p-4">
	<div class="ui-g">
		<div class="ui-g-12 text-center">
			<img class="mt-4" src="https://www.operrgroup.com/images/operr-title.png" style="width: 220px; height:auto">
			<h1 class="m-0">Operr Service Contract</h1>
			<span style="font-size: 16px;line-height: 1.4;">30-50 Whitestone Expressway<br>Suite 402, Flushing N.Y. 11354<br>(800) 668-6906</span>
		</div>
	</div>
	<div class="ui-g mt-5" style="row-gap: 50px">
		<div class="ui-g-12 ui-sm-12">
			<div class="ui-g-12">	
				<h1>Sales Agent</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-briefcase text-primary mr-2"></i> Merchant Details</span>
					</p-header>
			
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Authorized Agent</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="printNameCompany" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameCompany')">
								{{'validate.thisField' | translate}}
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="emailCompany" pInputText>
							</div>
							<span class="error">
								<span class="text-danger" *ngIf="isRequired('emailCompany')">{{'validate.thisField' | translate}}. </span>
								<span class="text-danger" *ngIf="isEmail('emailCompany')">{{'isValidClientEmail' | translate}}</span>
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>	
								<p-inputMask class="w-100" formControlName="directPhoneCompany" mask="(999) 999-9999" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneCompany')">
								{{'validate.thisField' | translate}}
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>	
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker1" formControlName="dateCompany"
										(focus)="picker1.open()">
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker1 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateCompany')">
								{{'validate.thisField' | translate}}
							</span>
						</div>
					</div>
				</p-panel>
			</div>
			<div class="ui-g-12 pt-5">
				<h1>Customer</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-handshake-alt text-primary mr-2"></i> Client Details</span>
					</p-header>
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Client Name:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signature"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="clientName" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('clientName')">
								{{'validate.thisField' | translate}}
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Address:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="addressClient" pInputText>
							</div>
							<span class="error">
								<span class="ui-message ui-messages-error" *ngIf="isRequired('addressClient')">
									{{'validate.thisField' | translate}}
								</span>
							</span>               
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Phone:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-phone"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="phoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
							</div>
							<span class="error">
								<span class="ui-message ui-messages-error" *ngIf="isRequired('phoneClient')">
									{{'validate.thisField' | translate}}
								</span>
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Authorized Agent:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="printNameClient" pInputText>
							</div>
							<span class="error">
								<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameClient')">
									{{'validate.thisField' | translate}}
								</span>
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="emailClient" pInputText>
							</div>
							<span class="error">
								<span class="ui-message ui-messages-error" *ngIf="isRequired('emailClient')">
									{{'validate.thisField' | translate}}
								</span>
								<span class="ui-message ui-messages-error" *ngIf="isEmail('emailClient')">
									{{'isValidClientEmail' | translate}}
								</span>
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone #:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="directPhoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
								<span class="error">
									<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneClient')">
										{{'validate.thisField' | translate}}
									</span>
								</span>
							</div>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker2" formControlName="dateClient" (focus)="picker2.open()">
									<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker2 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
								<span class="error">
									<span class="ui-message ui-messages-error" *ngIf="isRequired('dateClient')">
										{{'validate.thisField' | translate}}
									</span>
								</span>
							</div>
						</div>
					</div>
				</p-panel>
			</div>
		</div>

		<div class="ui-g-12 ui-sm-12" id="contract-area2">
			<div class="ui-g-12">
				<h1 class="m-0 mb-2">Service Agreement</h1>
				<p class="m-0 mb-4">Operr Technologies, Inc., (hereafter “Operr” or "Merchant") is the service provider for the services herein, located at 30-50 Whitestone Expressway, Suite 402, Flushing NY 11354 (licensor). Both parties, Operr and Client (“licensee”) enter into the following service agreement:</p>
				
				<span class="" style="font-size: 16px">Client is a For-Hire Vehicle (FHV) base with a base number of...</span>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-taxi"></i>
						</button>
						<input type="text" formControlName="baseNumber" pInputText  style="width:260px">
					</div>
				</div>
		
				<div class="mt-5" style="font-size: 16px">Client Shall Pay the Following Fees for Operr Dispatch Software Services:</div>

				<ul>
					<li>Two week FREE trial period</li>
					<li>1,000 FREE commercial 3rd party API calls (such as Google API) and standard rates thereafter, set by the API host.</li>
					<li>
						<span style="font-size: 16px">A one-time start-up fee of...</span>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-dollar-sign"></i>
								</button>
								<input type="number" formControlName="startFee" pInputText>
							</div>
						</div>
					</li>
				
					<li>
						<span style="font-size: 16px">A weekly, per vehicle charge of...</span>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-dollar-sign"></i>
								</button>
								<input type="number" formControlName="vehicleFee" pInputText> 
							</div>
						</div> 
						<span class="ui-message ui-messages-error" *ngIf="isRequired('vehicleFee')">
							{{'validate.thisField' | translate}}
						</span>
					</li>

				</ul>
				<h4 class="mb-2 mt-5">Payment Terms</h4>
				<p class="m-0">Invoices will be issued according to the terms set forth in this Service Agreement. All invoices are payable by check or bank transfer, plus sales/service tax. Pricing is set forth in Section 3, above. Tax is applied if applicable. Fees are calculated prior to deduction of any necessary third-party service fee, meaning if there's any applicable fees for bank transfer or any other fees associated with payment then the base will be responsible for paying related fees.</p>
				<p class="m-0">Every two weeks bundled recurring service charges will become due on the 3rd day after the invoice date.</p>
				<h4 class="mb-2 mt-4">Disputes</h4>
				<p class="m-0">Any controversy or claim, including, but not limited to, loss or damage arising out of or relating to this Service Agreement, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association.</p>
				<h4 class="mb-2 mt-4">Termination</h4>
				<p class="m-0">Written notice by email, fax, or certified mail must be provided by Client to Operr thirty (30) days before the termination of the services.</p>
				<h4 class="mb-2 mt-4">Disclaimer</h4>
				<p class="m-0">For any reason the dispatch system breaks down, Operr IT engineers will try their best to solve the problem and licensee will not be charged for the time of licensor fixing the dispatch system, and licensor will not take any responsibility for any other loss due to the system breaking down.</p>
				<h4 class="mb-2 mt-4">Effectiveness</h4>
				<p class="m-0">Upon both parties' signatures, the above Service Agreement is effective, immediately.</p>


				<h1 class="mt-5">Signatures</h1>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>Merchant's Signature:</label>   
						<div class="input-group d-flex">
							<div id="companySignpad">
								<signature-pad #companySignpad [options]="signaturePadConfig" height="70" width="340"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign()" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser text-danger px-2"  style="cursor:pointer"></i>    
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>Client's Signature:</label>  
						<div class="input-group d-flex">
							<div id="clientSignpad">
								<signature-pad #clientSignpad [options]="signaturePadConfig" height="70" width="340"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign('client')" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser text-danger px-2"  style="cursor:pointer"></i>    
								</button>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
<div class="ui-g pb-5">
	<div class="ui-g-12 text-center">
		<button (click)="cancelEditing()" *ngIf="disableEditingContract" class="btn btn-danger btn-lg mx-1"><i class="fa-solid fa-xmark mr-2"></i> {{'Cancel' | translate}}</button>	
		<button (click)="createPdf()" *ngIf="!disableEditingContract" class="btn btn-purple btn-lg mx-1"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</button>
		<button (click)="edit()"*ngIf="disableEditingContract && showEditButton" class="btn btn-info btn-lg mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i> {{'Edit' | translate}}</button>	
		<button (click)="generateContract()" *ngIf="!disableEditingContract" class="btn btn-primary btn-lg mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Complete' | translate}}</button>
	</div>
</div>

<p-dialog header="Share eContract " [showHeader]="true" showEffect="fade" [closable]="true"
    [style]="{ 'z-index': 999999999, 'min-height': '30vh', 'padding': 0, 'width':'450px'}" styleClass="w-50"
    *ngIf="shareContractDialog" [(visible)]="shareContractDialog" modal="modal" [dismissableMask]="true">
    <div class="row mx-0 mb-2 align-items-center">
        <div class="ui-g-12">
            <label>{{'Recipients' | translate}}<span class="required-indicator"></span>: {{clientName}}</label>
            <div style="margin-top: 15px;">
                <span style="padding:5px;">Email: {{emailClientSelect}}</span>
            </div>
            <div style="margin-top: 15px;">
                <span style="padding:5px;">Title: <strong>eContract</strong></span>
            </div>
        </div>
    </div>
    <div class="row mx-0 mb-2 align-items-center">
        <div class="ui-g-12" style="min-height: 600px;">
            <label>{{'Preview' | translate}}<span class="required-indicator"></span>:</label>
            <div [innerHTML]="bodyEmail | safeHtml" class="ql-editor" id="note_details"></div>
        </div>
    </div>

    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 p-1" align="center">
                <button type="button" pButton label="Send Email" (click)="sendEmail()"></button>
                <button type="button" pButton label="Close" (click)="rejectEmail()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>