import {
  OnChanges,
  Directive,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  AfterViewInit,
  NgZone,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core";
import * as _ from "lodash";
import { GMapsService } from "../service/gmaps.service";
import { PolygonService } from "../service/polygon.service";
import { PolygonUtils } from '../utils/polygon.utils';

declare let google: any;

@Directive({
  selector: "custom-polygon-map",
})
export class PolygonMapDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() polygonIds: any = [];
  @Input() zonePolygonGeoJson;
  @Input() zoneType: any = {};
  @Input() displayAllZone: boolean = false;

  @Output() onPolygonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCountySelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onZoneDoubleClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedPolygonIds: number[] = [];

  @Input() turfs: any = [];

  @Input() zoneLayerSelected;

  @Output()
  selectedPolygonIdsChange = new EventEmitter();

  @Output()
  selectedTurfChange = new EventEmitter();

  private fusionTableLayer: any;
  private map;
  customPolygon: any;
  customPolygons: any[] = [];
  markers: any[] = [];

  voterMarkers: any[] = [];

  turfPolygons: any[] = [];
  turfMarkers: any[] = [];
  turfInfowindow;

  zonePolygonInfoWindow;
  selectedFeature;
  zoneFeatures;

  featureDbClicked = new Set<string>();

  constructor(
    private gmapsApi: GoogleMapsAPIWrapper,
    private gmapService: GMapsService,
    private polygonService: PolygonService,
    private ngZone: NgZone
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.gmapsApi.getNativeMap().then((map) => {
      this.map = map;
      this.initMap();
      this.zoomTurfs();
      if (this.zoneFeatures) {
        this.zoneFeatures.forEach(features => {
          features.forEach(feature => {
            this.map.data.remove(feature);
          })
        });
      }
      this.zoneFeatures = PolygonUtils.loadGeoJson(this.map, this.zonePolygonGeoJson);
    });
  }

  addCountryPolygon(lng, lat) {
    this.polygonService
      .getZipcodeCounty(lng, lat)
      .subscribe((zipcodeCounty: any) => {
        this.polygonIds = zipcodeCounty.zipcodePolygonIds;
        this.initMap();
        this.onCountySelected.emit(this.polygonIds);
      });
  }

  initMap() {
    this.map.setOptions({ disableDoubleClickZoom: true });
    if (this.fusionTableLayer != null) {
      this.fusionTableLayer.setMap(null);
    }
    this.map.data.addListener('click', (event) => {
      this.ngZone.runOutsideAngular(() => {
        this.zonePolygonInfoWindow = PolygonUtils.openFeatureInfoWindow(this.map, event.feature, this.zonePolygonInfoWindow);
      });
    });

    this.map.data.addListener('dblclick', (event) => {
      this.ngZone.runOutsideAngular(() => {
        const feature = event.feature;
        const type = feature.getProperty('type');
        if (!type) {
          return null;
        }
        let key = PolygonUtils.buildFeatureKey(feature);
        if (!key) {
          return;
        }
        if (this.featureDbClicked.has(key)) {
          this.featureDbClicked.delete(key);
          this.map.data.overrideStyle(feature, {
            strokeWeight: type === 'manual_created_zones' ? 3 : 2,
            fillOpacity: 0.35
          });
        } else {
          this.featureDbClicked.add(key);
          this.map.data.overrideStyle(feature, {
            strokeWeight: 3,
            fillOpacity: 0.8
          });
        }
        this.onZoneDoubleClick.next(this.featureDbClicked);

      });
      return false;
    });
    google.maps.event.addListener(this.map, 'click', () => {
      this.ngZone.runOutsideAngular(() => {
        if (this.zonePolygonInfoWindow) {
          this.zonePolygonInfoWindow.close();
        }
        if (this.turfInfowindow) {
          this.turfInfowindow.close();
        }
      });
    });
    if (this.polygonIds && this.polygonIds.length) {
      google.maps.event.addListener(this.fusionTableLayer, "click", (event) => {
        if (
          event &&
          event.row &&
          event.row.polygon_id &&
          event.row.polygon_id.value
        ) {
          const polygonId = Number(event.row.polygon_id.value);
          if (!this.selectedPolygonIds) {
            this.selectedPolygonIds = [];
          }
          if (this.selectedPolygonIds.indexOf(polygonId) > -1) {
            _.pull(this.selectedPolygonIds, polygonId);
          } else {
            this.selectedPolygonIds.push(polygonId);
          }

          this.selectedPolygonIdsChange.emit(this.selectedPolygonIds);

          this.gmapService.getCoordinate([polygonId]).subscribe((result) => {
            const geometries = result["rows"][0][0].geometries;
            const data = { polygonId, geometries };
            this.onPolygonClick.emit(data);
          });
        }
      });
    } else {
      if (this.fusionTableLayer) {
        this.fusionTableLayer.setMap(null);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map) {
      if (
        changes["polygonIds"] &&
        changes["polygonIds"].previousValue !==
        changes["polygonIds"].currentValue
      ) {
        this.initMap();
      }

      if (
        changes["turfs"] &&
        changes["turfs"].previousValue !==
        changes["turfs"].currentValue
      ) {
        this.ngZone.runOutsideAngular(() => {
          this.zoomTurfs();
        });

      }

      if (
        changes["zonePolygonGeoJson"] &&
        changes["zonePolygonGeoJson"].previousValue !==
        changes["zonePolygonGeoJson"].currentValue
      ) {
        this.ngZone.runOutsideAngular(() => {
          if (this.zoneFeatures) {
            this.zoneFeatures.forEach(features => {
              features.forEach(feature => {
                this.map.data.remove(feature);
              })
            });
          }
          this.zoneFeatures = PolygonUtils.loadGeoJson(this.map, this.zonePolygonGeoJson, false, this.featureDbClicked);
          if (this.zoneFeatures) {
            const currentFutureKeys = new Set<string>();
            this.zoneFeatures.forEach(features => {
              features.forEach(feature => {
                let key = PolygonUtils.buildFeatureKey(feature);
                if (key) {
                  currentFutureKeys.add(key);
                }
              });
            });
            this.featureDbClicked = new Set([...this.featureDbClicked].filter(x => currentFutureKeys.has(x)));
            this.onZoneDoubleClick.next(this.featureDbClicked);
          }
        });
      }

      if (
        changes["zoneLayerSelected"] &&
        changes["zoneLayerSelected"].previousValue !==
        changes["zoneLayerSelected"].currentValue
      ) {
        this.ngZone.runOutsideAngular(() => {
          this.zoneFeatures.forEach(features => {
            features.forEach(feature => {
              const type = feature.getProperty('type');
              if (type === this.zoneLayerSelected) {
                this.map.data.overrideStyle(feature, {
                  zIndex: 999999
                });
              } else {
                this.map.data.overrideStyle(feature, {
                  zIndex: 1
                });
              }
            });
          });
        });

      }
    }
  }

  zoomTurfs() {
    this.turfPolygons.forEach((e) => {
      e.setMap(null);
    });
    this.turfMarkers.forEach((e) => {
      e.setMap(null);
    });
    this.turfPolygons = [];
    if (this.map && this.turfs && this.turfs.length) {
      let boundCoordinates = [];
      _.forEach(this.turfs, (turf) => {
        const color = turf.color || '#28a745';
        const title = turf.title;
        if (turf.multiPolygons && turf.multiPolygons.length > 0) {
          turf.multiPolygons.forEach(polygon => {
            if (polygon.polygon && polygon.polygon.length > 0) {
              const arr = [];
              polygon.polygon.forEach(coordinate => {
                arr.push([coordinate.x, coordinate.y, color, title, turf])
              });
              boundCoordinates.push(arr);
            }
          });
        }
      });
      if (!_.isArray(boundCoordinates[0][0])) {
        const newArr = [];
        newArr.push(boundCoordinates);
        boundCoordinates = newArr;
      }
      const allBounds: any = new google.maps.LatLngBounds();
      _.forEach(boundCoordinates, (coordinates) => {
        const bounds: any = new google.maps.LatLngBounds();
        let color;
        let title;
        let turf;
        const bCoordinates = [];
        _.forEach(coordinates, (coordinate) => {
          bCoordinates.push(
            new google.maps.LatLng(coordinate[1], coordinate[0])
          );
          bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
          allBounds.extend(
            new google.maps.LatLng(coordinate[1], coordinate[0])
          );

          if (!color) {
            color = coordinate[2];
          }
          if (!title) {
            title = coordinate[3];
          }
          if (!turf) {
            turf = coordinate[4];
          }
        });
        const polygon = new google.maps.Polygon({
          paths: bCoordinates,
          strokeColor: color || "#28a745",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color || "#28a745",
          fillOpacity: 0.35,
          zIndex: 99999
        });
        let walker = '';
        if (turf.walker) {
          const turfWalker = JSON.parse(turf.walker);
          walker = turfWalker.map(t => t.value).join(', ');
        }
        let zoneName = '';
        if (turf.zones) {
          zoneName = turf.zones.map(t => t.title).join(', ');
        }
        let contentString = `
        <div id="content" style="opacity: 0.9; filter: alpha(opacity=90);">
          <b style="font-weight: 600;">${turf.title}</b><br>
          <b>Total Voter: ${turf.voterIds ? turf.voterIds.length : 0}</b><br>
          <b>Zone: ${zoneName}</b><br>
          <b>Visited voter: ${turf.voters ? turf.voters.filter(v => v.status === 'Completed').length : 0}</b><br>
          <b>Walker(s): ${walker}</b><br>
        </div>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: "Uluru",
        });
        this.turfPolygons.push(polygon);
        google.maps.event.addListener(polygon, 'mouseover', () => {
          if (this.turfInfowindow) {
            this.turfInfowindow.close();
          }
          infowindow.open({
            anchor: marker,
            map: this.map as any
          });
          this.turfInfowindow = infowindow;
        });
        google.maps.event.addListener(polygon, 'mouseout', () => {
          if (this.turfInfowindow) {
            this.turfInfowindow.close();
          }
        });
        polygon.addListener("click", () => {
          this.selectedTurfChange.emit(turf);
        });
        this.turfPolygons[this.turfPolygons.length - 1].setMap(this.map);
        const center = bounds.getCenter();
        const marker = new google.maps.Marker({
          position: { lat: center.lat(), lng: center.lng() },
          map: this.map as any,
          label: {
            text: title || " ",
            color: "#000000",
            fontSize: "16px",
            fontWeight: "bold",
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0,
          },
        });
        this.turfMarkers.push(marker);
      });
      this.map.fitBounds(allBounds);
    }
  }
}
