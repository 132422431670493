<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 my-4 ml-2">{{'Statements & Balance' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Statements & Balances' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [options]="allCompanyList" [filter]="true" optionLabel="value" dataKey="key" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="clientList" [filter]="true" optionLabel="value" placeholder="{{'pleaseSelect' | translate}}"  [(ngModel)]="selectedClient" [showClear]="true" (onChange)="changeClient($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Bill Type' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" [options]="typeList" [showClear]="true"  [(ngModel)]="selectedType" placeholder="{{'pleaseSelect' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Status' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-multiSelect  defaultLabel="{{'pleaseSelect' | translate}}" name="statusSearch" [options]="statusTypes" [(ngModel)]="selectedStatuses" id="id_invoice_status_search_label" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button type="button" class="btn btn-danger mx-1" (click)="reset();"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchData();"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex flex-wrap align-items-center justify-content-start py-4">
	<h1 class="m-0 ml-2 mr-4">{{'Balance Breakdown' | translate}}</h1>
</div>
<p-panel id="balance-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-calculator text-primary mr-2"></i> {{'Balance Overview' | translate}}</span>
	</p-header>
	<p-table #dt [value]="statementBalanceStatistics" dataKey="id" selectionMode="single" [paginator]="false" [totalRecords]="totalRecords" [responsive]="true" [lazy]="false" id="table-sumary" [loading]="loading">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of statisticsCols">
					<span class="d-flex justify-content-center">
						<span class="one-liner">{{col.label | translate}}</span>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">0-30: </span><a href="javascript:void(0);" (click)="initStatementBalanceDays('1')">{{rowData.first30 | currency : 'USD':'symbol':'1.2-2'}}</a>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">31-60: </span><a href="javascript:void(0);" (click)="initStatementBalanceDays('2')">{{rowData.first60 | currency : 'USD':'symbol':'1.2-2'}}</a>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">61-90: </span><a href="javascript:void(0);" (click)="initStatementBalanceDays('3')">{{rowData.first90 | currency : 'USD':'symbol':'1.2-2'}}</a>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">91-120: </span><a href="javascript:void(0);" (click)="initStatementBalanceDays('4')">{{rowData.first120 | currency : 'USD':'symbol':'1.2-2'}}</a>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">121+: </span><a href="javascript:void(0);" (click)="initStatementBalanceDays('5')">{{rowData.last120 | currency : 'USD':'symbol':'1.2-2'}}</a>
					</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<p-footer>
		<div class="d-flex justify-content-start align-items-center w-100">
			<i class="fa-solid fa-circle-info text-orange mr-2"></i><b class="mr-1">{{'Balance Due' | translate}}: </b> {{totalBalanceStatistics | currency : 'USD':'symbol':'1.2-2'}}
		</div>
	</p-footer>
</p-panel>

<div class="d-flex flex-wrap align-items-center justify-content-between py-4" style="row-gap: 10px">
	<h1 class="m-0 ml-2 mr-4">{{'Browse Statements' | translate}}</h1>
	<!--  NO FUNCTION WRITTEN IN TYPESCRIPT FILE for ADD function !!!! 
		<button class="btn btn-primary ml-2" (click)="addStatementBalance()"><i class="fa-solid fa-plus"></i></button>
	-->
</div>

<p-panel id="balance-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Statements & Balances' | translate}} ({{totalRecords || 0}})</span>
	</p-header>

	<p-table #dt2 [value]="statementBalanceList" id="statementsTable" dataKey="id" selectionMode="single" [rows]="10" [paginator]="true"
			 [rowsPerPageOptions]="[10, 25, 50]" (onLazyLoad)="search($event)" [totalRecords]="totalRecords" [responsive]="true" [lazy]="true" [loading]="loading">

		 <ng-template pTemplate="caption">
			 <div class="d-flex align-items-center justify-content-between">
				 <div class="d-flex align-items-center">
					 <button type="button" class="btn btn-primary mr-2" (click)="downloadPdf()" [disabled]="allRecords.length === 0">
						 <span class="d-flex align-items-center" style="white-space: nowrap"><i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i><i *ngIf="exportingPDF"  class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Export PDF' | translate}}</span>
					 </button>
					 <button type="button" class="btn btn-purple mr-2" (click)="openPopupEmailStatement()" [disabled]="!selectedClient">
					<span class="d-flex align-items-center" style="white-space: nowrap">
						<i class="fa-brands fa-at mr-2"></i>{{'Email Statement' | translate}}</span>
					 </button>
				 </div>
				 <div class="input-group w-100 mr-2" style="max-width: 450px">
					 <div class="input-group-prepend p-0">
						 <button class="btn btn-white" type="button" style="cursor:default !important">
							 <i class="fa-solid fa-filter"></i>
						 </button>
					 </div>
					 <input pInputText type="text" size="35" [(ngModel)]="filterText" (input)="customFilter()" (keyup)="filterChange()" placeholder="{{'Filter' | translate}}">
				 </div>
			 </div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 80px !important">#</th>
				<th *ngFor="let col of cols">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'invoiceDate'">
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'dueDate'">
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'updatedAt'">
							<div class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField !== col.field ? selectedSortOption = '' : selectedSortOption = selectedSortOption; selectedField = col.field">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'numOfFollowUpNote'">
							<span class="d-flex justify-content-center w-100">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>


		<ng-template  pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td>
					{{i+1}}
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" class="text-primary" (click)="clickInvoiceRef($event,rowData);" pTooltip="{{ rowData.invoiceNumber }}">{{ rowData.invoiceNumber}}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Dated' | translate}}: </span>
						<span *ngIf="rowData.invoiceDate" pTooltip="{{formatDate(rowData.invoiceDate)}}">{{formatDate(rowData.invoiceDate)}}</span>
						<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{ rowData.clientName }}">{{ rowData.clientName}}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Ref' | translate}}: </span>
						<span *ngIf="rowData.referenceNo" pTooltip="{{ rowData.referenceNo }}">{{ rowData.referenceNo}}</span>
						<span *ngIf="!rowData.referenceNo" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Due' | translate}}: </span>
						<span *ngIf="rowData.dueDate" pTooltip="{{rowData.dueDate | date: 'MM/dd/yyyy'}}">{{rowData.dueDate | date: 'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.dueDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Paid' | translate}}: </span>
						<span *ngIf="rowData.paidFee" pTooltip="{{ (rowData.paidFee ? rowData.paidFee : 0 ) | currency : 'USD':'symbol':'1.2-2'}}">{{ (rowData.paidFee ? rowData.paidFee : 0 ) | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.paidFee" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Balance' | translate}}: </span>
						<span *ngIf="rowData.balance" class="text-danger" pTooltip="{{ rowData.balance | currency : 'USD':'symbol':'1.2-2'}}">{{ rowData.balance | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.balance" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Updated' | translate}}: </span>
						<span *ngIf="rowData.updatedAt" pTooltip="{{formatDate(rowData.updatedAt)}}">{{formatDate(rowData.updatedAt)}}</span>
						<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="d-flex align-items-center">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span> 	
						<i (click)="openDialog(rowData, $event, true)" class="fa-solid fa-plus text-primary mx-1" pTooltip="{{'Add Note' | translate}}" tooltipPosition="left"></i>       
						<span *ngIf="rowData.followUpNoteDate" class="mx-1">
							<i class="fa-solid fa-note-sticky text-warning c-pointer" (click)="openDialog(rowData, $event, false)" pTooltip="{{'Updated' | translate}} {{rowData.followUpNoteDate | date: 'MM/dd/yyyy'}}"  tooltipPosition="left">		
								<span *ngIf="rowData.numOfFollowUpNote != 0 || null" class="translate-middle badge rounded-pill bg-danger" style="font-size: 8px">
									{{rowData.numOfFollowUpNote}}
								</span>
							</i>
							<i class="fa-solid fa-angles-right text-purple c-pointer mx-1" (click)="redirectToFollowUptUrl(rowData)" pTooltip="{{'Follow-Ups' | translate}}"  tooltipPosition="left"></i>
						</span>							
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>

<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
			<ng-template let-col pTemplate="item">
					<div>
							<span>{{col.name | translate}}</span>
					</div>
			</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption"  (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-dialog [(visible)]="isOpenCollectionNote" [responsive]="true" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'85vh'}"  [modal]="true">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{headerNote}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client Name' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{paymentInvoiceData.clientName}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice Date' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{paymentInvoiceData.invoiceDate | date: 'MM/dd/yyyy HH:MM'}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Status' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{paymentInvoiceData.status}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Balance' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar-sign"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{paymentInvoiceData.balanceTxt}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice' | translate}} #:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{paymentInvoiceData.invoiceNumber}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Amount Due' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar-sign"></i>
					</button>
				</div>
				<div class="form-control text-muted">{{'No Data Available' | translate}}</div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Contact Phone' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-mobile-phone"></i>
					</button>
				</div>
				<div class="form-control text-muted">{{'No Data Available' | translate}}</div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Support' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-circle-question"></i>
					</button>
				</div>
				<div class="form-control text-muted">{{'No Data Available' | translate}}</div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Assignee' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<div class="form-control text-muted">{{'No Data Available' | translate}}</div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Assignee' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<div class="form-control"><span class="one-liner">{{followUpDate | date: 'MM/dd/yyyy hh:mm'}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Method of Contact' | translate}}:<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-tty"></i>
					</button>
				</div>
				<p-dropdown [options]="contacts" [(ngModel)]="selectedContact"  placeholder="{{'Choose method' | translate}}"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Collection Agent' | translate}}:<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user-tie"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="assignedToEmployeeList" [filter]="true" [(ngModel)]="selectedCollectionPresentative" [showClear]="true"  placeholder="{{'pleaseSelect' | translate}}"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Note' | translate}}:<span class="text-danger">*</span></label>
			<p-editor #notesEditor (onTextChange)="textChanged1()" (onInit)="setEditor($event)" (closed)="boldMention()" [mentionConfig]="{triggerChar:'@', maxItems:10, labelKey:'value'}" [mention]="assignedToEmployeeList" [(ngModel)]="followUpNote" [style]="{'height':'220px'}" ></p-editor>
			<span [ngClass]="{'text-danger': exceededMaxLength}" class="text-muted pull-right" style="font-size: 12px">{{MAX_LENGTH - charactersLength}} {{'chars' | translate}}. {{'remain' | translate}}</span>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button [disabled]="followUpNote === '' || selectedCollectionPresentative === '' || selectedContact === ''" (click)="saveCollectionNote()"  class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
			<button (click)="closeDialog()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		</div>
	</div>
</p-dialog>
<p-dialog header="{{'Email Statement' | translate}}" [(visible)]="showPopupEmailStatement" [modal]="true" [style]="{width: '25vw'}">
	<div class="ui-g-12">
		<div class="ui-g-4 text-right">
			<label>Client's Email:</label>
		</div>
		<div class="ui-g-8">
			<div class="form-control"><span class="one-liner">{{selectedClientEmail}}</span></div>
		</div>
    </div>
    <div class="ui-g-12 text-center" style="margin-top:-15px">
        <hr>
		<button type="button" class="btn btn-primary mx-1" (click)="actionEmailStatement()"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		<button (click)="closePopupEmailStatement()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
    </div>
</p-dialog>
<p-dialog [(visible)]="showStatementBalanceDays" [responsive]="true" appendTo="body" modal="true" dismissableMask="true" [style]="{'width': '90%', 'max-width':'750px'}"
		  (onHide)="showStatementBalanceDays = false; statementBalanceDays = []; totalBalanceDays = 0; dtdays.first = 0; dtdays.rows = 10; textSearch = ''; dtdays.reset();">
	<p-header>
		<span><i class="fa-solid fa-calculator text-primary mr-2"></i> {{'Balance Days Overview' | translate}}</span>
	</p-header>
	<p-panel>
		<p-table #dtdays [value]="statementBalanceDays" selectionMode="single" [paginator]="true" [totalRecords]="totalBalanceDays"
				 [responsive]="true" [lazy]="false" [rowsPerPageOptions]="[10,15,20]" [rows]="10" [globalFilterFields]="['clientName', 'invoiceNumber', 'balance']">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<div class="align-items-center justify-content-start flex-wrap"></div>
					<div class="input-group w-100 mr-2" style="max-width: 350px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="textSearch" size="35" (input)="dtdays.filterGlobal($event?.target?.value, 'contains')"
							   placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th>#</th>
					<th *ngFor="let col of statementBalanceDaysCols">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex justify-content-between align-items-center w-100">
									<span class="one-liner">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event);">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>
			<ng-template  pTemplate="body" let-rowData let-i="rowIndex">
				<tr *ngIf="!loading" [pSelectableRow]="rowData">
					<td>{{i+1}}</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}: </span>{{rowData.clientName}}
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
							<span class="text-primary c-pointer" (click)="clickInvoiceRef($event,rowData);">{{rowData.invoiceNumber}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Balance' | translate}}: </span> <span class="text-danger">${{rowData.balance | number : '2.2-2'}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="(statementBalanceDays || []).length === 0" class="text-center">
					<td [attr.colspan]="4">
						{{'NoRecordsFound' | translate}}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
	<div class="ui-g">
		<div class="ui-g-12 text-center py-4">
			<button (click)="showStatementBalanceDays = false; statementBalanceDays = []; totalBalanceDays = 0;"
					class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
			<button type="button" class="btn btn-orange mr-2" (click)="exportBalanceDays()">
				<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-print mr-2"></i>{{'Export PDF' | translate}}</span>
			</button>
		</div>
	</div>
</p-dialog>
