import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Table } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { FreelancerTicketService } from '../../../crm/service/freelancerticket.service';
import { FreelancerService } from '../../../crm/service/freelancer.service';
import { Freelancer } from '../../../crm/model/freelancer.model';
import { FreelancerTicket } from '../../../crm/model/freelancer-ticket.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { OverlayPanel } from 'primeng';
import { DocumentsServicev2 } from '../../../shared/service/documents-upload/document.v2.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import { TicketCommentModel } from "../../model/ticket-comment.model";

@Component({
  selector: 'app-freelancer-assign-task',
  templateUrl: './freelancer-assign.component.html',
  styleUrls: ['./freelancer-assign.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class FreelancerAssignComponent implements OnInit, OnChanges {
  @Input() freelancerId: number;
  @Input() freelancerTicketId: number;
  @Input() taskId: number;
  @Input() modeType: string;
  @Input() changeStatusAssignTicket: string;
  @Output() onConfirm = new EventEmitter();
  @Output() onApproved = new EventEmitter();
  @Output() onCloseDialog = new EventEmitter();
  @Output() onExisted = new EventEmitter();
  @Output() currentStatus = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @ViewChild('dt', { static: true }) table: Table;
  @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;
  freelancerForm: UntypedFormGroup;
  panelTaskTitle = '';
  unSavedTicket: FreelancerTicket = new FreelancerTicket();
  isSubmit = false;
  details: string;
  mode: Boolean = false;
  rowData: any;
  msgs = [];
  loading = false;
  freelancer: Freelancer = new Freelancer();
  selectedStatus: String = '';
  rangeDates: undefined;
  subscription: Subscription;
  editButton: Boolean = true;
  historyShow = false;
  userInfo: any;
  estTaskNotExisted: Boolean = true;
  isCheckFreelancer: boolean = false;
  fileUrl: SafeResourceUrl;
  isHovering: boolean;
  files: File[] = [];
  showFileUpload = false;
  fileUploaded: any = [];
  processing: Boolean = false;
  documents: any;
  fieldsName = [];
  showViewFileDialog = false;
  @Input() boardAdminId: number;
  @Input() boardCreatedBy: string;
  @Input() boardName: string;

  status = [
    { label: 'Confirm', value: 'Confirm' },
    { label: 'Processing', value: 'Processing' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Pending', value: 'Reviewing' },
    { label: 'Close', value: 'Close' },
  ];
  @Input() userDetails: any;
  ticketComment = new TicketCommentModel();
  boardAdmin: boolean = false;
  comments: any = [];
  loggedInUser: string;
  loggedInId: number;
  @Input() internalId: number;
  isDisabled: boolean = false;

  constructor(
    private ticketService: FreelancerTicketService,
    private freelancerService: FreelancerService,
    private browserNotificationService: BrowserNotificationService,
    private authService: AuthService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private formValidator: FormValidatorService,
    private documentServiceV2: DocumentsServicev2,
    private taskManagementService: TaskManagementService,
  ) {

  }

  ngOnInit() {
    this.loggedInUser = this.authService.getCurrentUsername();
    this.loggedInId = this.authService.getCurrentLoggedInId();
    this.loading = true;
    this.freelancerForm = this.fb.group({
      ticketLink: ['', Validators.required],
      estimationHrs: ['', Validators.required],
      details: ['', {}],
      startDate: ['', Validators.required],
      deadline: ['', Validators.required],
      status: ['', Validators.required],
    });
    if (this.boardAdminId === this.loggedInId && this.boardCreatedBy === this.loggedInUser) {
      this.boardAdmin = true;
    }
  }
  ngOnChanges() {
    this.userInfo = this.authService.getUserInfo();
    if (this.taskId) {
      const options: any = {};
      options.page = 0;
      options.size = 9999;
      options.taskId = this.taskId;
      this.fileUploaded = [];
      this.ticketService.searchFreelancerTicket(options).subscribe(response => {
        this.loading = false;
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          if (resObj.data.content && resObj.data.content.length > 0) {
            this.unSavedTicket = resObj.data.content[0];
            this.unSavedTicket.status = this.unSavedTicket.status === 'Save' ? 'Reviewing' : this.unSavedTicket.status;
            this.freelancer = this.unSavedTicket.freelancer;
            const panelTitle = 'Freelance: ' + this.freelancer.email
              + ' ( ' + this.freelancer.firstName + ' ' + this.freelancer.lastName + ' )';
            this.panelTaskTitle = panelTitle;
            if (this.unSavedTicket.documentId === null || !this.unSavedTicket.documentId) {
              this.unSavedTicket.documentId = [];
            } else {
              let options: any = {
                ids: this.unSavedTicket.documentId.map(Number)
              };
              this.getAllFiles(options);
              this.freelancer.documentId = [];
            }
            this.modeType = 'view';
            if (localStorage.getItem('_user') === 'freelancer') {
              if (this.unSavedTicket.status !== 'Confirmed' && this.unSavedTicket.status !== 'Confirm' &&
                this.unSavedTicket.status !== 'Approved') {
                this.isCheckFreelancer = false;
              } else {
                this.isCheckFreelancer = true;
              }
            } else {
              this.isCheckFreelancer = true;
            }
            this.freelancerTicketId = this.unSavedTicket.id;
            this.loading = false;
            this.estTaskNotExisted = false;
            this.onExisted.emit(true);
            this.currentStatus.emit(this.unSavedTicket.status);
            this.getTicketComments();
            if (this.unSavedTicket.status === 'Confirm' || this.unSavedTicket.status === 'Approved' || this.unSavedTicket.status === 'Close') {
              this.isDisabled = true;
              this.freelancerForm.disable();
            }
          } else {
            this.onExisted.emit(false);
            this.currentStatus.emit('');
            if (localStorage.getItem('_user') === 'freelancer') {
              this.freelancerService.filter({ id: this.userInfo.adminId }).subscribe(resFreelancer => {
                const resFreelancerData: any = resFreelancer;
                this.freelancer = resFreelancerData.data.content[0];
                this.freelancerTicketId = null;
                const panelTitle = 'Freelance: ' + this.freelancer.email
                  + ' ( ' + this.freelancer.firstName + ' ' + this.freelancer.lastName + ' )';
                this.panelTaskTitle = panelTitle;
                this.unSavedTicket = new FreelancerTicket();
                this.unSavedTicket.ticketLink = window.location.href;
                this.unSavedTicket.taskId = this.taskId;
                this.unSavedTicket.startDate = new Date();
                this.unSavedTicket.freelancer = this.freelancer;
                this.unSavedTicket.companyId = this.freelancer.companyId;
                this.unSavedTicket.status = 'Reviewing';
                this.loading = false;
                this.estTaskNotExisted = false;
                this.modeType = 'create';
                this.editButton = false;
              });
            } else {
              this.panelTaskTitle = '';
              this.unSavedTicket = new FreelancerTicket();
              this.loading = false;
              this.estTaskNotExisted = true;
              this.modeType = 'view';
              this.isCheckFreelancer = true;
            }
          }
        }
      });
    }
    if (this.changeStatusAssignTicket === 'Reviewing') {
      this.updateStatusAssignTicket(this.changeStatusAssignTicket);
    } else if (this.changeStatusAssignTicket === 'Confirm') {
      this.updateStatusAssignTicket(this.changeStatusAssignTicket);
    } else if (this.changeStatusAssignTicket === 'Approved') {
      this.updateStatusAssignTicket(this.changeStatusAssignTicket);
    } else if (this.changeStatusAssignTicket === 'Close') {
      this.updateStatusAssignTicket(this.changeStatusAssignTicket);
    } else if (this.changeStatusAssignTicket === 'delete') {
      this.deleteAssignTicket();
    }
  }

  getHistory() {

    this.historyShow = true;
  }

  goBack() {
    this.freelancerForm.reset();
    this.onCloseDialog.emit(null);
  }

  clearFormData() {
    this.unSavedTicket = new FreelancerTicket();
  }

  changeStatus() {
    this.editButton = false;
    this.modeType = 'edit';
  }

  changeHistoryHandler(freelancerTicketId, mergedObject) {
    const admin = this.authService.getUserInfo();
    const historyData = [];
    const fieldsName = ['ticketLink', 'estimationHrs', 'details', 'startDate', 'deadline', 'status'];
    fieldsName.forEach(element => {
      if (mergedObject[element] !== null && mergedObject[element] !== '') {
        const dataObj: any = {};
        dataObj.fieldName = element;
        dataObj.oldValue = '';
        dataObj.newValue = mergedObject[element];
        dataObj.action = 'Created';
        dataObj.userId = freelancerTicketId;
        dataObj.freelancerTicketId = freelancerTicketId;
        dataObj.userName = admin.username;
        historyData.push(dataObj);
      }
    });
    this.ticketService.saveHistoryData(historyData).subscribe((item: any) => {
      console.log(item);
    });
  }

  async changeHistoryHandlerEdit(newClientData) {
    const admin = this.authService.getUserInfo();
    await this.ticketService.get(newClientData.id).subscribe((item: any) => {
      const oldData = item.data;
      oldData.startDate = oldData.startDate ? new Date(oldData.startDate) : null;
      oldData.deadline = oldData.deadline ? new Date(oldData.deadline) : null;
      const historyData = [];
      const fieldsName = ['ticketLink', 'estimationHrs', 'details', 'startDate', 'deadline', 'status'];
      fieldsName.forEach(element => {
        let oldValue = oldData[element];
        let newValue = newClientData[element];
        if (element === 'startDate' || element === 'deadline') {
          oldValue = oldData[element] != null ? new Date(oldData[element]).getTime() : oldData[element];
          newValue = newClientData[element] != null ? new Date(newClientData[element]).getTime() : newClientData[element];
        }
        if (newValue !== oldValue) {
          const dataObj: any = {};
          dataObj.fieldName = element;
          if (element === 'startDate' || element === 'deadline') {
            dataObj.oldValue = oldData[element];
            dataObj.newValue = new Date(newClientData[element]).getTime();
          } else {
            dataObj.oldValue = oldData[element];
            dataObj.newValue = newClientData[element];
          }

          if (dataObj.newValue.length === 0) {
            dataObj.action = 'Deleted';
          } else {
            dataObj.action = 'Updated';
          }

          dataObj.freelancerTicketId = newClientData.id;
          dataObj.userName = admin.username;
          historyData.push(dataObj);
        }
      });
      this.ticketService.saveHistoryData(historyData).subscribe();
    });
  }

  saveFreelancerTicket(dataForm, type?: string) {
    this.unSavedTicket.status = type ? type : this.unSavedTicket.status;
    if (this.formValidator.validateForm(this.freelancerForm, dataForm)) {
      this.isSubmit = true;
      this.unSavedTicket.createdByUsr = this.authService.getCurrentUsername();
      this.unSavedTicket.loggedInUserEmail = this.userInfo.email;
      this.unSavedTicket.loggedInUserFullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      this.unSavedTicket.userDetails = this.userDetails;
      this.unSavedTicket.documentId = this.unSavedTicket.documentId ? this.unSavedTicket.documentId : [];
      if (this.unSavedTicket.ticketLink && this.unSavedTicket.estimationHrs && this.unSavedTicket.startDate
        && this.unSavedTicket.deadline && this.unSavedTicket.status) {
        this.fileUploaded.forEach(file => {
          if (!this.unSavedTicket.documentId ||
            (this.unSavedTicket.documentId && !this.unSavedTicket.documentId.includes(file.id))) {
            this.unSavedTicket.documentId.push(file.id);
          }
        });
        if (this.unSavedTicket.id) {
          this.updateFreelancerTicket(dataForm);
        } else {
          this.ticketService.create(this.unSavedTicket).subscribe(async res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
              this.onExisted.emit(true);
              this.changeHistoryHandler(resObj.data.id, this.unSavedTicket);
              await this.createNotification(resObj.data);
              this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'Ticket Added successfully!' });
              this.onCloseDialog.emit(this.unSavedTicket.deadline);
            }
          });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields' });
      }
      this.unSavedTicket.estimationHrs = this.unSavedTicket.estimationHrs.replace(':', '.');
    } else {
      const invalid = [];
      for (const name in this.freelancerForm.controls) {
        if (this.freelancerForm.controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields' });
    }
  }

  async createNotification(element: FreelancerTicket) {
    const notification: BrowserNotification = new BrowserNotification();
    notification.entity = 'freelancer';
    notification.entityId = element.freelancer.id;
    notification.content = `Ticket #${element.id} assigned!`;
    notification.logo =
      'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
    notification.url = document.location.origin + `/#/app/crm/freelancer/assign/${element.id}/edit`;
    notification.status = 'New';
    this.browserNotificationService.save(notification).subscribe(response => {
      console.log('Created notification sucessfully');
    });
  }


  async updateFreelancerTicket(dataForm, type?: string) {
    if (this.formValidator.validateForm(this.freelancerForm, dataForm)) {
      this.unSavedTicket.createdByUsr = this.authService.getCurrentUsername();
      this.unSavedTicket.loggedInUserEmail = this.userInfo.email;
      this.unSavedTicket.loggedInUserFullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      this.unSavedTicket.userDetails = this.userDetails;
      this.unSavedTicket.documentId = this.unSavedTicket.documentId ? this.unSavedTicket.documentId : [];
      if (this.unSavedTicket.ticketLink && this.unSavedTicket.estimationHrs && this.unSavedTicket.startDate
        && this.unSavedTicket.deadline && this.unSavedTicket.status) {
        this.fileUploaded.forEach(file => {
          if (!this.unSavedTicket.documentId ||
            (this.unSavedTicket.documentId && !this.unSavedTicket.documentId.includes(file.id))) {
            this.unSavedTicket.documentId.push(file.id);
          }
        });
        await this.changeHistoryHandlerEdit(this.unSavedTicket);
        await this.ticketService.update(this.unSavedTicket, this.freelancerTicketId).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            this.messageService.add({ severity: 'info', summary: 'updated', detail: 'Ticket updated successfully!' });
            this.onCloseDialog.emit(null);
          }
        });
      } else {
        console.log('Nothing to save');
      }
    } else {
      const invalid = [];
      for (const name in this.freelancerForm.controls) {
        if (this.freelancerForm.controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields' });
    }
  }


  deleteAssignTicket() {
    if (this.unSavedTicket.id) {
      // this.ticketService.delete(this.unSavedTicket.id).subscribe(response => {
      //   this.onDelete.emit(this.unSavedTicket.freelancer);
      // });
      this.loading = true;
      const payLoad = {
        id: this.unSavedTicket.id,
        takenBackBy: this.authService.getCurrentLoggedInName(),
        ticketNumber: this.internalId
      }
      this.ticketService.takeTicketBack(payLoad).subscribe(response => {
        this.onDelete.emit(this.unSavedTicket.freelancer);
        this.loading = false;
      }, () => this.loading = false);
    }
  }

  updateStatusAssignTicket(status) {
    if (this.unSavedTicket.id) {
      this.loading = true;
      this.unSavedTicket.status = status;
      this.unSavedTicket.loggedInUserEmail = this.userInfo.email;
      this.unSavedTicket.loggedInUserFullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      this.unSavedTicket.userDetails = this.userDetails;
      this.fileUploaded.forEach(file => {
        if (!this.unSavedTicket.documentId ||
          (this.unSavedTicket.documentId && !this.unSavedTicket.documentId.includes(file.id))) {
          this.unSavedTicket.documentId.push(file.id);
        }
      });
      this.ticketService.updateFreelancerTicket(this.unSavedTicket).subscribe(response => {
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          this.createNotification(this.unSavedTicket);
          this.onConfirm.emit(resObj.data.status);
        }
        this.loading = false;
      }, () => this.loading = false);
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.uploadMultipleFiles(files[i]);
    }
    this.showFileUpload = true;
  }

  uploadMultipleFiles(file) {
    this.processing = true;
    this.documentServiceV2.uploadFile(file, 'freelancer_ticket_file',
      1, 'Company')
      .subscribe(res => {
        const resObj: any = res;
        this.processing = false;
        if (resObj.status === 'SUCCESS') {
          const options = {
            ids: [resObj.data.id]
          };
          this.getAllFiles(options);
        }
      });
  }

  getAllFiles(options: any) {
    this.documentServiceV2.searchFile(options)
      .subscribe(res => {
        const resObj: any = res;
        this.documents = resObj.data.content;
        this.showFileUpload = this.documents.length > 0;
        for (let i = 0; i < this.documents.length; i++) {
          this.fileUploaded.push(this.documents[i]);
        }
      });
  }

  uploadFile(event) {
    let filesToUpload = [];
    if (event.target.files.length === 0) {
      return;
    }

    filesToUpload = event.target.files;
    for (let i = 0; i < filesToUpload.length; i++) {
      this.files.push(filesToUpload[i]);
      const reader = new FileReader();
      reader.readAsDataURL(filesToUpload[i]);
      this.uploadMultipleFiles(filesToUpload[i]);
    }
    this.showFileUpload = true;
  }

  onUploadDocument(uploadFilePanel: OverlayPanel) {
    this.uploadFileRef.toArray()[0].nativeElement.click();
    uploadFilePanel.hide();
  }


  removeFile(id) {
    this.fileUploaded = this.fileUploaded.filter(file => file.id !== id);
    this.showFileUpload = this.fileUploaded.length > 0;
  }

  closeViewFileDialog() {
    this.fileUrl = null;
    this.showViewFileDialog = false;
  }

  showDocument(file) {
    window.open(file.fileUrl);
  }

  addComment() {
    let msg = {}
    this.ticketComment.createdByUsr = this.authService.getCurrentUsername(),
      this.ticketComment.freelancerTicketId = this.freelancerTicketId,
      this.ticketComment.taskId = this.taskId,
      this.ticketComment.userId = this.loggedInId,
      this.ticketComment.userType = this.taskManagementService.getUserType(),
      this.ticketComment.commentUserType = this.boardAdmin ? 'ADMIN' : 'FREELANCER',
      this.ticketComment.ticketLink = window.location.toString(),
      this.ticketComment.boardName = this.boardName

    if (this.ticketComment.id) {
      msg = { severity: 'info', summary: 'Updated', detail: 'Comment updated successfully!' }
    } else {
      msg = { severity: 'success', summary: 'Addedd', detail: 'Comment added successfully!' }
    }
    this.ticketService.addCommentToTicket(this.ticketComment).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.ticketComment = new TicketCommentModel();
        this.getTicketComments();
        this.messageService.add(msg);
      }
    })
  }

  getTicketComments() {
    const options = {
      taskId: this.taskId,
      freelancerTicketId: this.freelancerTicketId
    }
    this.ticketService.getTicketComment(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.comments = resObj.data;
      }
    })
  }

  editComment(comment) {
    this.ticketComment = comment;
  }

  deleteComment(comment) {
    this.ticketService.deleteComment(comment.id).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.getTicketComments();
        this.messageService.add({ severity: 'success', summary: 'Addedd', detail: 'Comment deleted successfully!' });
      }
    })
  }

  displayName(value) {
    if (value) return value.match(/\b(\w)/g).join('').slice(0, 2).toUpperCase();
  }

}
