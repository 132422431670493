import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Quote } from '../model/quote.model';
import { QuoteCustomize } from '../model/quote-customize.model';


@Injectable({
    providedIn: 'root'
})
export class QuoteService extends AbstractService<Quote> {
    notificationUrl: string;

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.QUOTE, '');
        this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
    }

    getList(options: any): Observable<any> {
        // return this._http.post<any>(`http://localhost:8007/api/v2/operation/quote/search`, options).pipe(map(resp => resp));
        return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    getById(id: number): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}${id}`).pipe(map(resp => resp));
    }

    createServiceRate(entity: Quote): Observable<Quote> {
        return this._http.post<Quote>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
    }

    updateServiceRate(entity: Quote): Observable<Quote> {
        return this._http.put<Quote>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
    }


    deleteServiceRate(id: Number) {
        return this._http.delete(`${this.baseUrlV2}${id}`).pipe(map(data => data));
    }

    getCreatedByList(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('companyId', options.companyId);
        return this._http.get<any>(`${this.baseUrlV2}getCreatedByList`, { params }).pipe(map(resp => resp));
    }

    getCustomizeSearch(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('userId', options.userId);
        return this._http.get<any>(`${this.baseUrlV2}customize/findByUserId`, { params }).pipe(map(resp => resp));
    }

    saveCustomizeSearch(entity: QuoteCustomize): Observable<QuoteCustomize> {
        return this._http.post<QuoteCustomize>(`${this.baseUrlV2}customize`, entity).pipe(map(res => res));
    }


    getState(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('companyId', options.companyId);
        return this._http.get<any>(`${this.baseUrlV2}getState`, { params }).pipe(map(resp => resp));
    }

    getCity(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('companyId', options.companyId);
        return this._http.get<any>(`${this.baseUrlV2}getCity`, { params }).pipe(map(resp => resp));
    }

    getCounty(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('companyId', options.companyId);
        return this._http.get<any>(`${this.baseUrlV2}getState`, { params }).pipe(map(resp => resp));
    }

    sendEmailQuote(payload) {
        return this.http.post(`${this.notificationUrl}/text-email`, payload);
    }

    findByInvoiceNumber(invoiceNumber: string): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}find-by-invoice/${invoiceNumber}`).pipe(map(resp => resp));
    }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }
}
