import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import * as _ from 'lodash';
import { CompanyService } from '../../service/company.service';
import { DatePipe } from '@angular/common';
import {MenuList} from '../../../shared/data/menu-list';
import {MessageService} from 'primeng/api';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-company-plan',
    templateUrl: './company-plan.component.html',
    styleUrls: ['./company-plan.component.scss']
})
export class CompanyPlanComponent implements OnInit {
    @Input() planId: any;
    @Output() newItemEvent = new EventEmitter<any>();
    @Input() isDisable: any;
    loading: Boolean = false;
    availableAdminMenuList = new MenuList();
    displayedColumns: string[] = ['name'];
    displayedHead: string[] = ['Your Plan'];
    displayedSubHead: string[] = [''];
    displayedSubHeadColor: string[] = ['', '#ffa500', '#d9d9d9', '#f1c751', '#ffa500', '#d9d9d9', '#f1c751'];
    displayedFields: string[] = ['name'];
    displayedPlanIds: string[] = [''];
    displayedRadio: string[] = [];
    groups: any[];
    @Output() groupsList = new EventEmitter<any>();

    constructor(private companyService: CompanyService,
                private datePipe: DatePipe,
                private messageService: MessageService,
                private translatePipe: TranslatePipe) { }

    ngOnInit() {

        const adminType = ['PLAN'];
        this.availableAdminMenuList.menus = this.availableAdminMenuList.menus.filter(menu => menu.showMenu);
        this.companyService.getAdminType(adminType).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.groups = resObj.data;
                if (this.groups.length > 0 ) {
                    this.groups.forEach(group => {
                        const groupName = group.name.split(/\s+/).map(e => _.capitalize(e)).join('');
                        this.displayedColumns.push(groupName);
                        console.log(this.displayedColumns);
                        this.displayedFields.push(groupName);
                        this.displayedHead.push(group.name);
                        this.displayedPlanIds.push(group.id);
                        this.displayedSubHead.push(group.pricePlan);
                        if (group.menuList) {
                            this.availableAdminMenuList.menus.forEach((listItem) => {
                                group.menuList.forEach((menuId) => {
                                    if (parseInt(menuId, 10) === listItem.id ) {
                                        listItem[groupName] = true;
                                    }
                                });
                            });
                        }
                    });
                }
                this.groupsList.emit(this.groups);
            }
        }, err => {
                this.onError(this.translatePipe.transform(this.translatePipe.transform('Group updated error')));
            });
    }

    onError(data?: any) {
        this.messageService.add({ key: 'br', severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform(data) });
    }

    addPlanId(value: any) {
        this.newItemEvent.emit(value);
    }

    getPlanId(planIdCheck) {
        return this.planId === planIdCheck ? planIdCheck : 0;
    }
}
