import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Terminal, loadStripeTerminal } from '@stripe/terminal-js';
import { map } from 'rxjs/operators';
import { Constants } from '../../shared/model/constants';
import { MessageService } from 'primeng';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ExternalPaymentService {
  constructor(private http: HttpClient, private messageService: MessageService) { }

  private resourceUrl = `${environment.v2_server_ip}/${Constants.ROUTES.OPERATOR}/external-payment`;

  private terminals: { [key: string]: Terminal } = {};

  retrieveReader(readerId: string, testMode: boolean) {
    return this.http.get(`${this.resourceUrl}/terminal/retrieve-reader/${readerId}?testMode=${testMode}`);
  }

  cancelReaderAction(readerId: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/cancel-reader-action/${readerId}?testMode=${testMode}`, {});
  }

  getConnectionToken(locationId: string, testMode: boolean) {
    return this.http.get(`${this.resourceUrl}/terminal/connection-token/${locationId}?testMode=${testMode}`);
  }

  createPaymentIntent(amount: number, testMode: boolean, description: string) {
    return this.http.post(`${this.resourceUrl}/terminal/create-payment-intent?amount=${amount}&testMode=${testMode}&description=${description}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  retrievePaymentIntent(paymentIntentId: string, testMode: boolean) {
    return this.http.get(`${this.resourceUrl}/terminal/retrieve-payment-intent?paymentIntentId=${paymentIntentId}&testMode=${testMode}`).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data);
      }
      return res;
    }));
  }

  processPaymentIntent(readerId: string, paymentIntentId: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/process-payment-intent?readerId=${readerId}&paymentIntentId=${paymentIntentId}&testMode=${testMode}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  capturePaymentIntent(paymentIntentId: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/capture-payment-intent?paymentIntentId=${paymentIntentId}&testMode=${testMode}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  cancelPaymentIntent(paymentIntentId: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/cancel-payment-intent?paymentIntentId=${paymentIntentId}&testMode=${testMode}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  refundPaymentIntent(paymentIntentId: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/refund-payment-intent?paymentIntentId=${paymentIntentId}&testMode=${testMode}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  testHelpersPaymentIntent(readerId: string, cardNumber: string, testMode: boolean) {
    return this.http.post(`${this.resourceUrl}/terminal/test_helpers?readerId=${readerId}&cardNumber=${cardNumber}&testMode=${testMode}`, {}).pipe(map((res: any) => {
      if (res.status === 200) {
        res.data = JSON.parse(res.data)
      }
      return res;
    }));
  }

  async initStripeTerminal(locationId: string, testMode: boolean,
    onPaymentStatusChangeEvent: (event: any) => void,
    onConnectionStatusChangeEvent: (event: any) => void,
    showMessage?): Promise<Terminal> {
    let terminal = this.terminals[locationId];
    if (!terminal) {
      const stripeTerminal = await loadStripeTerminal();
      terminal = stripeTerminal.create({
        onFetchConnectionToken: async () => {
          const connectionTokenRes: any = await this.getConnectionToken(locationId, testMode).toPromise();
          return connectionTokenRes.data;
        },
        onPaymentStatusChange: onPaymentStatusChangeEvent,
        onConnectionStatusChange: onConnectionStatusChangeEvent,
        onUnexpectedReaderDisconnect: (event) => {
          console.log('onUnexpectedReaderDisconnect: ', event);
        }
      });
      this.terminals[locationId] = terminal;
    }
    if (terminal.getConnectionStatus().toString().toUpperCase() === 'CONNECTED') {
      return Promise.resolve(terminal);
    }
    const config = { simulated: testMode, location: locationId };
    const discoverResult: any = await terminal.discoverReaders(config);
    if (discoverResult.error) {
      if (showMessage) {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Failed to discover:' + discoverResult.error });
      }
      return Promise.resolve(null);
    }
    if (discoverResult.discoveredReaders.length === 0) {
      if (showMessage) {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'No available readers' });
      }
      return Promise.resolve(null);
    }
    console.log('Available reader: ', discoverResult.discoveredReaders);
    const selectedReader = discoverResult.discoveredReaders[0];
    const connectResult: any = await terminal.connectReader(selectedReader);
    if (connectResult.error) {
      if (showMessage) {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Failed to connect:' });
      }
      return Promise.resolve(null);
    }
    if (showMessage) {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Connected to reader: ' + connectResult.reader.label });
    }
    return Promise.resolve(terminal);
  }
}
