import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable, Subject } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import * as moment from 'moment';
import { PaymentInvoice } from '../model/payment-invoice.model';
import { Message } from 'primeng/api';
import { map } from 'rxjs/operators';
import { FollowUpBillingInvoice } from '../model/follow-up-billing-invoice.model';
@Injectable({ providedIn: 'root' })
export class FollowUpBillingInvoiceService extends AbstractService<FollowUpBillingInvoice> {
  private messageSubject = new Subject<Message>();
  message$ = this.messageSubject.asObservable();
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/follow-up-billing-invoice', '');
    // this.baseUrlV2 = 'http://localhost:8007/api/v2/operation/follow-up-billing-invoice/'
  }

  getFollowUpBillingInvoiceListByPage(params: any): Observable<Page<FollowUpBillingInvoice>> {
    return this._http.post<Page<FollowUpBillingInvoice>>(this.baseUrlV2 + 'search', params).pipe(map(resp => resp));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + 'exportPdf', data).pipe(map(resp => resp));
  }

  sendFollowUpTagEmail(option: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}follow-up-reminder`, option).pipe(map(res => res));
  }

    getHistory(options: any) {
        return this._http.post<any>(this.baseUrlV2 +  "history", options).pipe(map(rsp => rsp));
    }
}
