import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { TranslatePipe } from "@ngx-translate/core";
import { MessageService } from 'primeng/api';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { VrmGroupService } from 'app/electoral-campaign/vrm-group/service/vrm-group.service';

@Component({
  selector: 'app-email-list',
  templateUrl: './app-email-list.component.html',
  styleUrls: ['./app-email-list.component.scss'],
  providers: [VrmGroupService]
})
export class EmailListComponent implements OnInit {

  dataMenu = [
    { name: 'Sent', value: 0 },
    { name: 'Failure', value: 0 }
  ];


  cols: any[] = [
    { label: 'Priority', field: 'priority', sortOptions: '', sort: 'text', width: '9%' },
    { label: 'Recipient', field: 'destinationUserName', sortOptions: '', sort: 'number', width: '25%' },
    { label: 'Message', field: 'message', sortOptions: '', sort: 'text' },
    { label: 'Creator', field: 'createdByUsr', sortOptions: '', sort: 'text', width: '11%' },
    { label: 'Created', field: 'date', sortOptions: '', sort: 'number', width: '9%'},
    { label: 'Action', field: 'Action', width: '8%' },
  ];
  mailList: any;
  allMail = {
    sent: [],
    fail: [],
  };
  selectedMail = false;
  selectedMailData: any = {};
  loading = true;
  totalElement = 0;
  totalRecords = 0;
  constructor(
    private authService: AuthService,
    private documentServicev2: DocumentsServicev2,
    private documentsService: DocumentsService,
    private messageService: MessageService,
    private notificationService: DocumentsServicev2,
    private translatePipe: TranslatePipe,
  ) {}

  ngOnInit() {
    this.getEmailList();

  }

  getEmailList() {
    const options = <any>{};
    options.page = 0;
    options.size = 99999;
    options.receiver = this.authService.getUserInfo().username;
    this.selectedMail = false;
    this.notificationService.searchEmail(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const rspData = resObj.data.content;
        for (let i = 0; i < rspData.length; i++) {
          if (rspData[i]?.status == 'FAILURE') {
            this.allMail.fail.push(rspData[i]);
          }
          if (rspData[i]?.status == 'SENT') {
            this.allMail.sent.push(rspData[i]);
          }
        }
        this.dataMenu = [
          { name: 'Sent', value: this.allMail.sent.length },
          { name: 'Failure', value: this.allMail.fail.length }
        ];
        this.mailList = this.allMail.sent;
        this.totalElement = this.mailList.length;
      } else {
        this.messageService.add({ key: 'br', severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: this.translatePipe.transform('Load List Mail Fail'), sticky: true });
      }
      this.loading = false;
    });
  }

  selectMenu(name: any) {
    if (name == 'Sent') {
      this.mailList = this.allMail.sent;
    } 

    if (name == 'Failure') {
      this.mailList = this.allMail.fail;
    }
    this.totalElement = this.mailList.length;
    this.loading = false;
  }

  selectMail(data: any) {
    this.selectedMail = true;
    this.selectedMailData = data;
    this.selectedMailData.lstFileName = [];
    if (this.selectedMailData?.documentIds?.length > 0) {
      this.checkDocument(this.selectedMailData?.documentIds)
    }
  }

  checkDocument(documentIds) {
    if (documentIds && documentIds.length > 0) {
        const options = { ids: documentIds.map(Number) };
        this.documentServicev2.searchFile(options).subscribe(res => {
            const resObj: any = res;
            this.selectedMailData.lstFileName = resObj.data.content;
            console.log("selectedMailData");
            console.log(this.selectedMailData.lstFileName);
        });
    }
  }

  onViewDocument(documentId) {
    this.documentsService.getUploadedFile(documentId).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
            window.open(resObj.data.fileUrl, '_blank');
        }
    }, error => {
        // error
    });
}
}
