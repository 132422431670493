import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _moment from 'moment';
import { AuthService } from "../../../shared/service/auth/auth.service";
import { PaymentProfileService } from "../../service/payment-profile.service";

@Component({
    selector: 'app-transfer-funds',
    templateUrl: './transfer-funds.component.html',
    styleUrls: ['./transfer-funds.component.scss']
})
export class TransferFundsComponent implements OnInit {

    @Output()
    displayChange = new EventEmitter();

    transferFunds: UntypedFormGroup;

    transferFroms;
    transferTos;

    displayEsignatureDialog = false;

    submittedData = null;

    amounts = [];

    totalInvoices = 0;
    amountValue = '';
    invoiceNumbers = '';

    isCompleted: boolean = false;

    confirmedData: any = {};

    constructor(private fb: UntypedFormBuilder,
        private authService: AuthService,
        private paymentProfileService: PaymentProfileService) { }

    ngOnInit() {
        this.transferFunds = this.fb.group({
            transferDate: [_moment().local().format('MM/DD/YYYY')],
            transferFundFrom: [],
            transferFundTo: [],
            transferAmount: [],
            transferMemo: [],
        });

        this.transferFunds.valueChanges.subscribe(e => {
            if (e) {
                //console.log('e form', e)
            }
        });
        this.generateTransferInvoiceData();
        this.generateTransferInfo();
    }

    showEsignature() {
        const transferObjects = this.transferFunds.getRawValue();
        if (transferObjects) {
            this.submittedData = {
                ownerId: this.authService.getUserInfo().userId,
                gatewayType: 'AUTHORIZE_NET',
                fromCustomerId: transferObjects.transferFundFrom.registerPaymentId,
                fromCustomerProfileId: transferObjects.transferFundFrom.cardToken,
                toCustomerId: transferObjects.transferFundTo.registerPaymentId,
                toCustomerProfileId: transferObjects.transferFundTo.cardToken,
                amount: this.totalInvoices,
                transferDate: transferObjects.transferDate ? _moment(transferObjects.transferDate) : _moment(),
                memo: transferObjects.transferMemo,
                //medicaidProviderNum: this.authService.getMedicaidProviderNumber(),
                invoiceNumber: this.invoiceNumbers
            }
            this.displayEsignatureDialog = true;
        }
    }

    closeCancelDialog(event) {
        this.confirmedData.response = event;
        this.confirmedData.invoice = this.transferFunds.get('transferAmount').value;
        this.confirmedData.amount = this.totalInvoices;
        this.confirmedData.invoiceNumbers = this.invoiceNumbers;
        this.displayEsignatureDialog = false;
        // need to show confirmation
        this.isCompleted = true;
    }

    loadInvoiceAmount() {
        /*let optionsSearch: any = {
            medicaidProviderNum: this.authService.getMedicaidProviderNumber(),
            invoiceDate: _moment().format('MM/DD/YYYY'),
        }
        this.invoiceService.getInvoiceSummary(optionsSearch).subscribe((e: any) => {
            if (e) {
                this.amounts = [{label: e.totalBillServiceFee + '', value: e.totalBillServiceFee}]
            }
        });*/
    }

    cancel(): void {
        this.displayChange.emit(true);
    }

    onBack() {
        this.displayChange.emit(true);
    }

    generateTransferInvoiceData() {
        /*this.invoiceService.generateTransferInvoiceData(this.authService.getMedicaidProviderNumber()).subscribe(res => {
            if (res) {
                this.amounts = (res as any).filter(v => v.total).map(v => ({label: v.invoiceNumber, value: v}))
            }
        })*/
    }

    totalInvoice(values, event) {
        this.totalInvoices = 0;
        if (values && values.length > 0) {
            this.totalInvoices = values.map(v => v.total).reduce((sum, current) => sum + current);
            this.amountValue = values.map(v => v.total).join(',');
            this.invoiceNumbers = values.map(v => v.invoiceNumber).join(',');
        }
        if (event.value.length === this.amounts.length) {
            let x = document.getElementById('id_amount_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            let x = document.getElementById('id_amount_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }

    generateTransferInfo() {
        this.paymentProfileService.generateTransferInfo().subscribe(res => {
            if (res) {
                this.transferFroms = (res as any).filter(v => v.ownerId * 1 === this.authService.getUserInfo().userId).map(v =>
                    ({ label: v.cardToken, value: v }))
                this.transferTos = (res as any).filter(v => v.ownerId * 1 !== this.authService.getUserInfo().userId).map(v =>
                    ({ label: v.cardToken, value: v }))
            }
        })
    }
}
