<p-dialog header="Esignature"
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [style]="{
            width:'500px',
            minWidth:'400px'
          }"
          (onHide)="onClickClose()"
          [minY]="70"
          [dismissableMask]="true">
    
    <div class="ui-g">
        <div class="ui-g-12">
            Agreed to And Accepted
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-12">
            <signature-pad #signaturePad [options]="signaturePadOptions" *ngIf="!showSignature"></signature-pad>
            <img [src]="signatureUrl" *ngIf="showSignature" width="400px" height="200px">
            <input pInputText type="text" label="_______________________________" style="width: 400px">
        </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-3">
            By
        </div>
        <div class="ui-g-9">
            <input pInputText [(ngModel)]="signatureBy"/>
        </div>
    </div>
    <!--<div class="ui-g">
      <div class="ui-g-3">
        Enter your Name:
      </div>
      <div class="ui-g-9">
        <input pInputText [(ngModel)]="signatureName"/>
      </div>
    </div>-->
    
    <p-footer>
        <button pButton label="Apply" (click)="onSubmit()"></button>
    </p-footer>
</p-dialog>
