<form [formGroup]="contractForm" class="p-2">
	<div class="ui-g">
		<div class="ui-g-12 text-center">
			<img class="mt-4" src="https://www.operrgroup.com/images/operr-title.png" style="width: 220px; height:auto">
			<h1 class="m-0">{{'Service Contract' | translate}}</h1>
			<span style="font-size: 16px;line-height: 1.4;">30-50 Whitestone Expressway<br>Suite 402, Flushing N.Y. 11354<br>(929) 201-1899</span>
		</div>
	</div>
	<div class="ui-g mt-5" style="row-gap: 50px">
		<div class="ui-g-12 ui-sm-12">
			<div class="ui-g-12 p-0">	
				<h1 class="m-0 ml-2 my-4">{{'Sales Agent' | translate}}</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-briefcase text-primary mr-2"></i> {{'Merchant Details' | translate}}</span>
					</p-header>
					<div class="ui-g pb-3">
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Authorized Agent' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="printNameCompany" pInputText placeholder="{{'Enter name' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameCompany')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Email' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="emailCompany" pInputText placeholder="example@operr.com">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="(isEmail('emailCompany') || isRequired('emailCompany'))">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>	
								<p-inputMask class="w-100" formControlName="directPhoneCompany" mask="(999) 999-9999" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneCompany')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Date' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-day"></i>
									</button>
								</div>
								<mat-form-field>
									<input matInput placeholder="{{'Select One' | translate}}" [ngxMatDatetimePicker]="picker1" formControlName="dateCompany" (focus)="picker1.open()">
									<ngx-mat-datetime-picker #picker1 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateCompany')">{{'This field is required' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
			<div class="ui-g-12 p-0">
				<h1 class="m-0 ml-2 my-4">{{'Customer' | translate}}</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-handshake-alt text-primary mr-2"></i> {{'Client Details' | translate}}</span>
					</p-header>
					<div class="ui-g pb-3">
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Name' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="clientName" pInputText placeholder="{{'Enter name' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('clientName')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Address' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="addressClient" pInputText placeholder="{{'Full address' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('addressClient')">{{'This field is required' | translate}}</span>           
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-phone"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="phoneClient" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('phoneClient')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Authorized Agent' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="printNameClient" pInputText placeholder="{{'Enter name' | translate}}">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameClient')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Email' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="emailClient" pInputText placeholder="example@operr.com">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('emailClient')">{{'This field is required' | translate}}</span>
							<span class="ui-message ui-messages-error" *ngIf="isEmail('emailClient')">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Phone' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="directPhoneClient" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneClient')">{{'This field is required' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>{{'Date' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker2" formControlName="dateClient" (focus)="picker2.open()">
									<ngx-mat-datetime-picker #picker2 [enableMeridian]="true"></ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateClient')">{{'This field is required' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12" id="contract-area2">
			<div class="ui-g-12 p-0">	
				<h1 class="m-0 mb-2">{{'Service Agreement' | translate}}</h1>
				<p class="m-0 mb-4">{{'dispatchServiceAgreementIntro' | translate}}</p>
				
				<span class="" style="font-size: 16px">{{'Client is a For-Hire Vehicle (FHV) base with a base number of...' | translate}}</span>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-taxi"></i>
						</button>
						<input type="text" formControlName="baseNumber" pInputText  style="width:260px">
					</div>
				</div>
				<h4 class="mb-2 mt-5">{{'Client Shall Pay the Following Fees for Operr Dispatch Software Services:' | translate}}</h4>
				<ul>
					<li>{{'Two week FREE trial period' | translate}}</li>
					<li>{{'1,000 FREE commercial 3rd party API calls (such as Google API) and standard rates thereafter, set by the API host.' | translate}}</li>
					<li>
						<span style="font-size: 16px">{{'A one-time start-up fee of...' | translate}}</span>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-dollar-sign"></i>
								</button>
								<input type="number" formControlName="startFee" pInputText>
							</div>
						</div>
					</li>
					<li>
						<span style="font-size: 16px">{{'A weekly, per vehicle charge of...' | translate}}</span>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-dollar-sign"></i>
								</button>
								<input type="number" formControlName="vehicleFee" pInputText> 
							</div>
						</div> 
						<span class="ui-message ui-messages-error" *ngIf="isRequired('vehicleFee')">{{'This field is required' | translate}}</span>
					</li>
				</ul>
				<h4 class="mb-2 mt-5">{{'Payment Terms' | translate}}</h4>
				<p class="m-0">{{'paymentTermsText' | translate}}</p>
				<p class="m-0">{{'paymentTermsText2' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Disputes' | translate}}</h4>
				<p class="m-0">{{'disputesText' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Termination' | translate}}</h4>
				<p class="m-0">{{'terminationText' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Disclaimer' | translate}}</h4>
				<p class="m-0">{{'disclaimerText' | translate}}</p>
				<h4 class="mb-2 mt-4">{{'Effectiveness' | translate}}</h4>
				<p class="m-0">{{'effectivenessText' | translate}}</p>
				<h1 class="mt-5">{{'Signatures' | translate}}</h1>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Merchant' | translate}}</label>

						<div class="input-group d-flex">
							<div id="companySignpad">
								<signature-pad #companySignpad [options]="signaturePadConfig" height="300" width="300"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign()">
									<i class="fa-solid fa-eraser text-danger px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>{{'Client' | translate}}</label>   
						<div class="input-group d-flex">
							<div id="clientSignpad">
								<signature-pad #clientSignpad [options]="signaturePadConfig" height="300" width="300"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign('client')">
									<i class="fa-solid fa-eraser text-danger px-2" style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g">
		<div class="ui-g-12 text-center py-4">
			<button (click)="cancelEditing()" *ngIf="disableEditingContract" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>	
			<button (click)="edit()" *ngIf="disableEditingContract && showEditButton" class="btn btn-info mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'Edit' | translate}}</button>	
			<button class="btn btn-secondary btn-lg mx-1" [cdkCopyToClipboard]="copyLink()" (cdkCopyToClipboardCopied)="onClipboardCopy($event)" pTooltip="{{'Copy URL' | translate}}"><i class="fa-solid fa-link"></i></button>
			<button class="btn btn-purple btn-lg mx-1" (click)="showSendEmail()"><i class="fa-solid fa-paper-plane mr-2"></i>{{'Email Contract' | translate}}</button>
			<button (click)="generateContract()" *ngIf="!disableEditingContract" class="btn btn-primary btn-lg mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Complete' | translate}}</button>
		</div>
	</div>
</form>
<p-dialog *ngIf="shareContractDialog" [(visible)]="shareContractDialog" [showHeader]="true" showEffect="fade" [closable]="true" [style]="{'width':'90%', 'max-width':'600px'}" [modal]="true" [dismissableMask]="true">
	<p-header>
		<span><i class="fa-solid fa-paper-plane mr-2"></i>{{'Send Contract' | translate}}</span>
	</p-header>
    <div class="ui-g">
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Recipent' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-users"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="clientName" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
            <label>{{'E-Mail' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-at"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="emailClientSelect" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12">
            <label>{{'Subject' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-inbox"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="modalSubject" placeholder="{{'no data' | translate}}" readonly>
			</div>
		</div>
		<div class="ui-g-12 pt-4">
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Message' | translate}}</span>
				</p-header>
				<div style="pointer-events: none;">
					<p-editor [(ngModel)]="bodyEmail" [style]="{'height':'220px', 'pointer-events':'none'}" ></p-editor>
				</div>
			</p-panel>
		</div>
    </div>
    <p-footer class="d-flex justify-content-between align-items-center">
		<button type="button" (click)="rejectEmail()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="sendEmail()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
    </p-footer>
</p-dialog>

