
import {PayrollSetting} from "./payroll-setting.model";
import {Department} from "../../department/model/department.model";
import {Employee} from "../../employee/model/employee.model";
import {Holiday} from "./holiday.model";
import {AgencyHoliday} from "../../agency/model/agency-holiday.model";
import {Position} from '../../position/model/position.model';
export class PayrollHolidayV2 {
    id: number;
    payrollSettingsId: number;
    payrollSetting: PayrollSetting;
    positions: any[];
    positionNames: any[];
    holidayId: number;
    departmentId: number;
    holidayMultipler: number;
    departments: any[];
    departmentNames: any[];
    employee: Employee;
    holiday: any;

}
