import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Page } from '../../shared/model/page.model';
import { Agency, CommandRequest } from '../model';
import { Message } from 'primeng/api';
import { Response } from '../../shared/model/response';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';


@Injectable({ providedIn: 'root' })
export class AgencyService extends AbstractService<Agency> {

    private requestSubject = new Subject<CommandRequest>();
    private agencyData = new Subject<Agency>();
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    request$ = this.requestSubject.asObservable();
    agency$ = this.agencyData.asObservable();

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.AGENCY, '');
    }

    setAgency(agency: Agency) {
        this.agencyData.next(agency);
    }

    sendCommand(cmd: CommandRequest) {
        this.requestSubject.next(cmd);
    }

    notifyMessage(message: Message) {
        this.messageSubject.next(message);
    }

    getAgencyPage(params: any): Observable<Page<Agency>> {
        return this._http.get<Page<Agency>>(this.baseUrl, { params: params }).pipe(map(resp => resp));
    }

    readAllForDropDownList(params: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}drop_down`, { params: params }).pipe(map(resp => resp));
    }

    readAllForDropDownListWIthCompanyId(): Observable<any> {
        return this._http.get<any>(`${this.baseUrl}withCompId`).pipe(map(resp => resp));
    }

    saveAgency(model: Agency): Observable<any> {
        return this._http.post<any>(`${this.baseUrl}`, model).pipe(map(resp => resp));
    }

    getAgencySetting(agencyId: number): Observable<any> {
        return this._http.get<any>(`${this.baseUrl}${agencyId}/setting`).pipe(map(resp => resp));
    }

    getAgencyByCompany(companyId: number): Observable<Agency[]> {
        const options: any = { 'companyId': companyId };
        return this._http.get<any>(`${this.baseUrl}byCompId`, { params: options }).pipe(map(resp => resp));
    }

    getAgencyByCompanyId(companyId: number): Observable<Response<Agency>> {
        const options: any = { 'companyId': companyId };
        return this._http.get<Response<Agency>>(`${this.baseUrl}byCompId`, { params: options }).pipe(map(resp => resp));
    }

    getPageWithFilter(options?: any): Observable<Page<Agency>> {
        const params = this.createParams(options);
        return this._http.get<Page<Agency>>(this.baseUrl + 'filter', { params: params }).pipe(map(resp => resp));
    }

    saveHistoryData(data) {
        return this._http.post(`${this.baseUrl}history`, data).pipe(map(resp => resp));
    }

    getHistories(params: any): Observable<Page<any>> {
        return this._http.get<Page<any>>(`${this.baseUrl}history`, { params: params }).pipe(map(resp => resp));
    }

    getPayrollSetting(companyId: number) {
        //return this._http.get<any>(`http://localhost:8003/api/v2/company/${companyId}/payrollSettings`)
        //  .pipe(map(res => res));
        return this._http.get<any>(`${environment.v2_server_ip}/${Constants.ROUTES.COMPANY}/${companyId}/payrollSettings`)
            .pipe(map(res => res));
    }
}
