import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberTowords'
})
export class NumberTowordsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      if (value === '0.00') return 'zero .zero zero';
      if (value === '0') return 'zero';
      value = parseFloat(value).toFixed(2);
      let amounth = value.toString().split('.');
      let price: any = amounth[0];
      let pointer: any = amounth.length > 0 ? amounth[1] : null;
      var singleDigit = [
          'Zero',
          'One',
          'Two',
          'Three',
          'Four',
          'Five',
          'Six',
          'Seven',
          'Eight',
          'Nine',
        ],
        doubleDigit = [
          'Ten',
          'Eleven',
          'Twelve',
          'Thirteen',
          'Fourteen',
          'Fifteen',
          'Sixteen',
          'Seventeen',
          'Eighteen',
          'Nineteen',
        ],
        tensPlace = [
          '',
          'Ten',
          'Twenty',
          'Thirty',
          'Forty',
          'Fifty',
          'Sixty',
          'Seventy',
          'Eighty',
          'Ninety',
        ],
        handle_tens = function (digit: any, prevdigit: any) {
          return 0 == digit
            ? ''
            : ' ' + (1 == digit ? doubleDigit[prevdigit] : tensPlace[digit]);
        },
        handle_utlc = function (digit: any, nextdigit: any, denom: any) {
          return (
            (0 != digit && 1 != nextdigit ? ' ' + singleDigit[digit] : '') +
            (0 != nextdigit || digit > 0 ? ' ' + denom : '')
          );
        };
      var dollar = '',
        digitIndex = 0,
        digit = 0,
        nextDigit = 0,
        words = [],
        cent = '';
      if (((price += ''), isNaN(parseFloat(price)))) dollar = '';
      else if (parseFloat(price) > 0 && price.length <= 10) {
        for (digitIndex = price.length - 1; digitIndex >= 0; digitIndex--)
          switch (
            ((digit = price[digitIndex] - 0),
            (nextDigit = digitIndex > 0 ? price[digitIndex - 1] - 0 : 0),
            price.length - digitIndex - 1)
          ) {
            case 0:
              words.push(handle_utlc(digit, nextDigit, ''));
              break;
            case 1:
              words.push(handle_tens(digit, price[digitIndex + 1]));
              break;
            case 2:
              words.push(
                0 != digit
                  ? ' ' +
                      singleDigit[digit] +
                      ' Hundred' +
                      (0 != price[digitIndex + 1] && 0 != price[digitIndex + 2]
                        ? ''
                        : '')
                  : ''
              );
              break;
            case 3:
              words.push(handle_utlc(digit, nextDigit, 'Thousand'));
              break;
            case 4:
              words.push(handle_tens(digit, price[digitIndex + 1]));
              break;
            case 5:
              words.push(handle_utlc(digit, nextDigit, 'Lakh'));
              break;
            case 6:
              words.push(handle_tens(digit, price[digitIndex + 1]));
              break;
            case 7:
              words.push(handle_utlc(digit, nextDigit, 'Crore'));
              break;
            case 8:
              words.push(handle_tens(digit, price[digitIndex + 1]));
              break;
            case 9:
              words.push(
                0 != digit
                  ? ' ' +
                      singleDigit[digit] +
                      ' Hundred' +
                      (0 != price[digitIndex + 1] || 0 != price[digitIndex + 2]
                        ? ' and'
                        : ' Crore')
                  : ''
              );
          }
        dollar = words.reverse().join('');
      } else dollar = '';
      if (dollar) dollar = `${dollar}`;
      if (pointer != '00') {
        cent = amounth[1] + ' / 100';
        if (dollar) dollar = `${dollar} and ${cent}`;
        else dollar = `${cent}`;
      }
      return dollar;
    }
  }

}
