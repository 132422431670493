import {Transaction} from './transaction.model';
import {TransactionFileHistoryModel} from './transaction-file-history.model';

export class TransactionDetail {
    id: number;
    transactionTypeId: number;
    date: Date;
    cashIn: number;
    cashOut: number;
    cashKept: number;
    netCashInOut: any;
    creatorName: string;
    status: number;
    transaction: Transaction;
    transactionType: any;
    fileUrl: any;
    createdByUsr: string;
    lastModifiedBy: string;
    createdAt: string;
    updatedAt: string;
    billingAccountId?: number;
    memo?: string;
    paymentMethod?: string;
    contractorInvoiceId: number;
    invoiceId: number;
    verification1st: string;
    verification2st: string;
    transactionFileHistories?: TransactionFileHistoryModel[];
    lateFee: number;
    invoiceLst: any;
    type: any;
}
