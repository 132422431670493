import { Injectable } from '@angular/core';
import {AbstractService} from '../../shared/service/abstract.service';
import {AutoGenerateInvoiceSettings} from '../model/auto-generate-invoice-settings.model';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutoGenerateInvoiceSettingsService extends AbstractService<AutoGenerateInvoiceSettings> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.AUTO_GENERATE_INVOICE_SETTINGS, '');
    // this.baseUrlV2 = 'http://localhost:8003/api/v2/company/generate-invoice-settings';
  }

  findById(companyId: Number, id: Number): Observable<AutoGenerateInvoiceSettings> {
    return this._http.get<any>(`${this.baseUrlV2}/${companyId}/${id}`).pipe(map(res => res));
  }

  saveAll(companyId: Number, params: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/${companyId}/saveAll`, params).pipe(map(res => res));
  }

  findByCompanyIdAndPayrollSettingId(companyId: Number, payrollSettingId: Number): Observable<AutoGenerateInvoiceSettings> {
    return this._http.get<any>(`${this.baseUrlV2}/findBy/${companyId}/${payrollSettingId}`).pipe(map(res => res));
  }

  deleteByIdAndCompanyId(companyId: Number, id: Number): Observable<any> {
    return this._http.delete<any>(`${this.baseUrlV2}/${companyId}/${id}`).pipe(map(res => res));
  }
}
