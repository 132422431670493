import { GetNotifyForModel } from '../model/get-notify-for.model';

export class ModelUtility {
    public static getNotifyfor(): Array<GetNotifyForModel> {
        return [{
            id: 'missPunch',
            name: 'MissPunch',
            selected: false
        }, {
            id: 'clientNote',
            name: 'ClientNote',
            selected: false
        }, {
            id: 'overTimer',
            name: 'OverTime',
            selected: false
        }, {
            id: 'employeeEndTime',
            name: 'EmployeeEndTime',
            selected: false
        }, {
            id: 'clientTransfer',
            name: 'ClientTransfer',
            selected: false
        }, {
            id: 'dayOffRequested',
            name: 'DayOffRequested',
            selected: false
        }, {
            id: 'clientAcceptance',
            name: 'ClientAcceptance',
            selected: false
        }, {
            id: 'afterHours',
            name: 'AfterHours',
            selected: false
        }, {
            id: 'employeeLate',
            name: 'EmployeeLate',
            selected: false
        }, {
            id: 'absenceReport',
            name: 'AbsenceReport',
            selected: false
        }, {
            id: 'logInOutVsClockInOut',
            name: 'logInOutVsClockInOut',
            selected: false
        }, {
            id: 'didNotTakeLunchYet',
            name: 'didNotTakeLunchYet',
            selected: false
        }, {
            id: 'editPunchInOut',
            name: 'editPunchInOut',
            selected: false
        },
        {
            id: 'protestHour',
            name: 'protestHour',
            selected: false
        },
        {
            id: 'billingDispatchInvoice',
            name: 'billingDispatchInvoice',
            selected: false
        },
        {
            id: 'Plan/Assignment',
            name: 'Plan/Assignment',
            selected: false
        }
    ];
    }

}
