import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AbstractService} from "../../shared/service/abstract.service";
import {AuthService} from '../../shared/service/auth/auth.service';
import {Constants} from '../../shared/model/constants';
import {environment} from '../../../environments/environment';
import {map} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class PaymentCardDefaultService extends AbstractService<any>{
    reqHeaders:HttpHeaders;
    
    constructor(private http: HttpClient, private authService: AuthService) {
        super(http, Constants.ROUTES.PAYMENT_CARD_DEFAULT, '');
    }


    setDefaultCard(payload) {
        return this.http.post(`${this.baseUrlV2}`, payload, {headers: this.reqHeaders}).map(res => res);
    }

    getDefaultCard(params) {
        return this.http.get(`${this.baseUrlV2}`, { params: params, headers: this.reqHeaders}).map(res => res);
    }

    search(options?: any) {
        return this._http.post(`${this.baseUrlV2}search`, options).pipe(map(res => res));
    }
}
