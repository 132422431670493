import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { Product } from '../model/product.model';
import { Observable } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import { Constants } from '../../shared/model/constants';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AddProduct } from '../model/add-product.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductService extends AbstractService<Product> {
  notificationUrl: string;
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.PRODUCT, '');
    this.notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
  }

  getProductHistoryListByPage(params: any): Observable<Page<Product>> {
    const history_url = `${this.baseUrlV2}history/search`;
    return this._http.post<Page<Product>>(`${history_url}`, params).pipe(map(resp => resp));
  }

  addproduct(addProduct: AddProduct): Observable<any> {
    return this._http.post(`${this.baseUrlV2}price`, addProduct).pipe(map(resp => resp));
  }

  getAllProducts(): Observable<any> {
    return this._http.post(`${this.baseUrlV2}price/search`, {}).pipe(map(resp => resp));
  }

  getAllProductsByClient(params: any): Observable<any> {
    return this._http.post(`${this.baseUrlV2}price/search`, params).pipe(map(resp => resp));
  }
  updateProduct(updateProduct: AddProduct) {
    return this._http.put(`${this.baseUrlV2}price`, updateProduct).pipe(map(resp => resp));
  }

  getProductPrice(id: Number): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}price/${id}`)
        .pipe(map(res => res));
  }
  generateContractPdf(contractType, data) {
    return this._http.post(`${this.baseUrlV2}price/contract-pdf?templateType=${contractType}`, data).pipe(map(resp => resp));
  }

  exportClientContractPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}price/exportClientContractPdf`, data).pipe(map(resp => resp));
  }

  searchProduct(data: any, options?: any): Observable<Page<Product>> {
    const params = this.createParams(options);
    return this._http.post<Page<Product>>(this.baseUrlV2 + 'search', data, { params: params }).pipe(map(res => res));
  }

  searchContract(options: any): Observable<Page<any>> {
    return this._http.post<Page<any>>(this.baseUrlV2 + 'price/contract/search', options).pipe(map(res => res));
  }

  searchContractRelated(options: any): Observable<Page<any>> {
    return this._http.post<Page<any>>(this.baseUrlV2 + 'price/contract/related/search', options).pipe(map(res => res));
  }

  updateProductContract(updateProductContract) {
    return this._http.put(`${this.baseUrlV2}price/contract`, updateProductContract).pipe(map(resp => resp));
  }

  countDashboardSearch(options: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + 'price/dashboard-count', options).pipe(map(res => res));
  }

  sendEmailQuote(payload) {
    return this.http.post(`${this.notificationUrl}/text-email`, payload);
  }
  deleteProduct(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrlV2}price/${id}`).pipe(map(data => data));
    // return this._http.delete(`http://localhost:8010/api/v2/crm-product/price/${id}`).pipe(map(res => res));
  }
  exportPdf(data: any) {
      return this._http.post<any>(this.baseUrlV2 + 'export-pdf', data).pipe(map(res => res));
  }

  updateDocumentContract(options: any): Observable<Page<any>> {
      return this._http.post<Page<any>>(this.baseUrlV2 + 'price/contract/document/update', options).pipe(map(res => res));
  }
}
