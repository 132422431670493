import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Page } from '../../shared/model/page.model';
import { EmployeePaymentHistory } from '../model/paymenthistory.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployeePaymentHistoryService extends AbstractService<EmployeePaymentHistory> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.PAYMENT_HISTORY, '');
    }

    getAllBillingInvoice(): Observable<EmployeePaymentHistory[]> {
        return this._http.get<EmployeePaymentHistory[]>(this.baseUrl + Constants.URI.BILLING.LISTALLBYPAGE).pipe(map(resp => resp));
    }

    getByPage(params: any): Observable<Page<EmployeePaymentHistory>> {
        return this._http.post<Page<EmployeePaymentHistory>>(this.baseUrlV2 + Constants.URI.BILLING.LISTALLBYPAGE,
            params).pipe(map(resp => resp));
    }
    saveHistoryInfo(data) {
        return this._http.post(`${this.baseUrlV2}`, data).pipe(map(resp => resp));
    }

    getFieldNames(): Observable<Array<string>> {
        return this._http.get<Array<string>>(this.baseUrl + Constants.URI.BILLING.GETFIELDNAMES).pipe(map(resp => resp));
    }
    generatePaymentHistoryReport(params: any): Observable<Array<string>> {
        return this._http.post<any>(this.baseUrlV2 + Constants.URI.BILLING.EXPORT_PAYMENT_HISTORY,
            params).pipe(map(resp => resp));
    }
}
