<form [formGroup]="contractForm" class="p-4">
	<div class="ui-g">
		<div class="ui-g-12 text-center">
			<img class="mt-4" src="https://www.operrgroup.com/images/operr-title.png" style="width: 220px; height:auto">
			<h1 class="m-0">(FID) Look-Back Service Contract</h1>
			<span style="font-size: 16px;line-height: 1.4;">30-50 Whitestone Expressway<br>Suite 402, Flushing N.Y. 11354<br>(800) 668-6906</span>
		</div>
	</div>
	<div class="ui-g mt-4" style="row-gap: 50px">
		<div class="ui-g-12 ui-sm-12">
			<div class="ui-g-12">	
				<h1>Sales Agent</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-briefcase text-primary mr-2"></i> Merchant Details</span>
					</p-header>
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Authorized Agent<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="printNameCompany" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameCompany')" style="font-size: 12px !important;">
								{{'validate.thisField' | translate}}
							</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>	
								<input type="text" class="w-100" formControlName="emailCompany" pInputText>
							</div>
							<span  class="ui-message ui-messages-error" *ngIf="isRequired('emailCompany') || isEmail('emailCompany')" style="font-size: 12px !important;">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>	
								<p-inputMask class="w-100" formControlName="directPhoneCompany" mask="(999) 999-9999" placeholder="(###) ###-####"></p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneCompany')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date<span class="required-indicator">*</span></label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>	
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker1" formControlName="dateCompany" (focus)="picker1.open()">
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker1 [enableMeridian]="true"></ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateCompany')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
			<div class="ui-g-12 pt-5">
				<h1>Customer</h1>
				<p-panel [toggleable]="true">
					<p-header class="flex-grow-1">
						<span><i class="fa-solid fa-handshake-alt text-primary mr-2"></i> Client Details</span>
					</p-header>
					<div class="ui-g p-3">
						<div class="ui-g-6 ui-sm-12">
							<label>Client Base (Name):</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-building-flag"></i>
									</button>
								</div>
								<input type="text" formControlName="clientName" pInputText>
							</div>	
							<span class="ui-message ui-messages-error" *ngIf="isRequired('clientName')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Address:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-signs-post"></i>
									</button>
								</div>
								<input type="text" class="w-100" pInputText formControlName="addressClient">
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('addressClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>        
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Phone:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-phone"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="phoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('phoneClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Company Rep/Agent:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-tie"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="printNameClient" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('printNameClient')" style="font-size: 12px !important;">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Email:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-at"></i>
									</button>
								</div>
								<input type="text" class="w-100" formControlName="emailClient" pInputText>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('emailClient') || isEmail('emailClient')" style="font-size: 12px !important;">{{'isValidClientEmail' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Direct Phone #:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-mobile-screen-button"></i>
									</button>
								</div>
								<p-inputMask class="w-100" mask="(999) 999-9999" formControlName="directPhoneClient"
									placeholder="(###) ###-####">
								</p-inputMask>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('directPhoneClient')"  style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
						</div>
						<div class="ui-g-6 ui-sm-12">
							<label>Date:</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-calendar-days"></i>
									</button>
								</div>
								<mat-form-field>
									<input matInput [ngxMatDatetimePicker]="picker2" formControlName="dateClient" (focus)="picker2.open()">
									<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
									<ngx-mat-datetime-picker #picker2 [enableMeridian]="true">
									</ngx-mat-datetime-picker>
								</mat-form-field>
							</div>
							<span class="ui-message ui-messages-error" *ngIf="isRequired('dateClient')"  style="font-size: 12px !important">{{'validate.thisField' | translate}}</span>
						</div>
					</div>
				</p-panel>
			</div>
		</div>
		<div class="ui-g-12 ui-sm-12" id="contract-area2">
			<div class="ui-g-12">
				<h1 class="m-0 mb-2">Service Agreement</h1>
				<p class="m-0 mb-4">Operr Technologies, Inc. and/or Operr Service Bureau, Inc. are hereinafter referred to as “Operr” or Operr Technologies, Inc. and its affiliates”, located at 30-50 Whitestone Expressway, Suite 402, Flushing NY 11354, is licensed by the New York State Department of Health to provide billing services to third parties.</p>
				
				<div class="" style="font-size: 16px;line-height: 1.4;">Client is a transportation company with a T&LC license number of...<span class="required-indicator">*</span></div>
				<div class="input-group" style="max-width: 200px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-taxi"></i>
						</button>
					</div>
					<input type="text" formControlName="licenseNumber" pInputText placeholder="T&LC License Number">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="isRequired('licenseNumber')">{{'validate.thisField' | translate}}</span>
	
	
				<div class="mt-5" style="font-size: 16px;line-height: 1.4"><b>Both parties, Operr and Client enter into the following service agreement:</b></div>
				<h4 class="mb-2 mt-5">Services</h4>
				<p class="m-0 mb-4">Client Shall Pay the Following Fees for Operr’s Look-Back Services for Fidelis (FID):</p>
				<ul>
					<li class="mb-4">
						<p class="m-0" style="font-size: 16px">Percentage of the recovery amount, for each attempt to recover funds...<span class="required-indicator">*</span></p>
						<p class="m-0 mb-2 asterisk-text">(Operr's Look-Back service is the process of auditing previous billing data for billing errors or underpayments that have cost you money. OPERR Service Bureau will help identify and recover at least a portion of the overlooked or omitted billing funds or any other loss being incurred by any other types of billing errors.)</p>
						<div class="input-group" style="max-width: 100px">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-percent"></i>
								</button>
							</div>
							<input type="text" formControlName="recoveredAmount" pInputText>
						</div>
						<span class="ui-message ui-messages-error" *ngIf="isRequired('recoveredAmount')">{{'validate.thisField' | translate}}</span>
					</li>
					<li class="mb-4">
						<p class="m-0">In the event that Operr is unable to identify and recover any “lookback” monies within a reasonable period of time - not less than six (6) months - this Agreement shall be declared null and void, Client shall not be responsible for any payments to Operr, and Operr shall remit to Client the amount of $500 for their time.</p>
					</li>
					<li class="mb-4">
						<p class="m-0 mt-4">Client shall provide the date range for which Operr will conduct its “look back” service. Operr shall strictly limit its service to the date range set forth by client in order to prevent unnecessary interference with current billing activities.</p>
					</li>
					<li class="mb-4">
						<p class="m-0 mt-4">Client requests Operr's Look-Back Service for Fidelis (FID) for the following date range...</p>
						<div class="d-flex" style="column-gap: 60px">
							<div>
								<label>Start Date<span class="required-indicator">*</span></label>
								<div class="input-group" style="max-width: 200px">
									<div class="input-group-prepend p-0">
										<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
											<i class="fa-solid fa-calendar-day"></i>
										</button>
									</div>
									<mat-form-field>
										<input matInput [ngxMatDatetimePicker]="picker3" formControlName="fromDate" (focus)="picker3.open()">
										<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
										<ngx-mat-datetime-picker #picker3 [enableMeridian]="true">
										</ngx-mat-datetime-picker>
									</mat-form-field>
								</div>
								<span class="ui-message ui-messages-error" *ngIf="isRequired('fromDate')">{{'validate.thisField' | translate}}</span>
							</div>
							<div>
								<label>End Date<span class="required-indicator">*</span></label>
								<div class="input-group" style="max-width: 200px">
									<div class="input-group-prepend p-0">
										<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
											<i class="fa-solid fa-calendar-day"></i>
										</button>
									</div>
									<mat-form-field>
										<input matInput [ngxMatDatetimePicker]="picker4" formControlName="toDate" (focus)="picker4.open()">
										<mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
										<ngx-mat-datetime-picker #picker4 [enableMeridian]="true">
										</ngx-mat-datetime-picker>
									</mat-form-field>
								</div>
								<span class="ui-message ui-messages-error" *ngIf="isRequired('toDate')">{{'validate.thisField' | translate}}</span>
							</div>
						</div>
					</li>
				</ul>

				<h4 class="mb-4 mt-5">Payment Terms</h4>
				<p class="m-0 mb-2"><b>1.</b> Invoices will be issued according to the terms set forth in this Service Agreement. All invoices are payable by check or bank transfer, plus sales/service tax. Pricing is set forth in the 'Services' section of this Agreement.</p>
				<p class="m-0 mb-2"><b>2.</b> Monthly bundled recurring service charges will become due on the 1st day of each month where applicable.</p>
				<p class="m-0 mb-2"><b>3.</b> The full invoice amount must be settled within five (5) days of issuance of the invoice (to be issued at 11:00am EST on the first day of each month).</p>
				<ol style="font-size: 16px;line-height:1.4;list-style: lower-alpha;">
					<li class="mb-2">If payment is not received by Operr accordingly within the 5-day period set forth above, Client will be assessed a $50 late fee (the “Late Payment Penalty”).</li>
					<li class="mb-2">If no payment is received by Operr within five (5) days after the Late Payment Penalty is assessed, Operr reserves the right to cancel this Service Agreement immediately and take necessary legal action depending on the situation and according to any applicable law. Client takes full responsibility for failure to pay for Operr’s services.</li>
				</ol>
				<p class="m-0 mb-2"><b>4.</b> The client has been advised that Operr may need multiple attempts at its “look back” service in order to recover all payments.</p>
				<p class="m-0 mb-2"><b>5.</b> All applicable fees for the first time the lookback service is performed and results in affirmative recovery of funds will be paid at the time client receives that recovered payment. Upon the clearance of the payment of the service fee, Operr will then work for a second time to identify further “look back” funds and to recover additional payments. That subsequent payment is due to Operr at the time such subsequent funds are identified by Operr and shall not be pursued until clearance of the payment of the service fee. Any performance of look back services subsequent to the aforementioned second time shall follow this payment requirement.</p>
				<p class="m-0 mb-2"><b>6.</b> Any discrepancy between the amount identified and the amount actually recovered will serve as a credit towards additional lookback services and will be adjusted in subsequent rounds of services.</p>
				<p class="m-0 mb-2"><b>7.</b> While Operr will exercise its best efforts to locate as much as it can through this lookback service, it does not in any way guarantee that all of client’s identified monies owed will be recovered in full by Operr. However, whether Operr may continuously provide the “lookback” service and/or elect to stop providing “lookback” service will remain within the full discretion of Operr.</p>
				<p class="m-0 mb-2"><b>8.</b> The client will take responsibility to provide true and accurate data for billing services. Operr will not take responsibility for verifying whether billing data is true or not, which is the sole responsibility of the Client. The Client acknowledges that it constitutes fraud to provide false billing data and assumes legal responsibility for any false data provided to Operr.</p>
				<p class="m-0 mb-2"><b>9.</b> Operr’s “look back” service is based solely upon information and data provided client’s Fidelis account. As such, no new sign-off data is created during this process from service provider by Operr.</p>
				<p class="m-0 mb-2"><b>10.</b> This Agreement may only be terminated as set forth in Section 6, below, or In the event that Operr has substantially performed its “look back” service pursuant to this Agreement and client seeks to unilaterally void specific claims, client will be responsible for the value of the services provided by Operr, as set forth at Section 3 above, with regards to those claims.</p>
				
				<h4 class="mb-2 mt-4">Disputes</h4>
				<p class="m-0">Any controversy or claim including, but not limited to, loss or damage arising out of or relating to this Service Agreement, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association within the City of New York. The parties may agree in writing to waive this provision in favor of litigation in New York State courts, County of Queens. Such waiver must be mutually approved in writing.</p>
				<h4 class="mb-2 mt-4">Termination</h4>
				<p class="m-0">Written notice by certified mail must be provided by Client to Operr thirty (30) days before the termination of the services.</p>
				<h4 class="mb-2 mt-4">Agreement</h4>
				<p class="m-0">The terms set forth in this Service Agreement constitute the entire Agreement.</p>
				<h4 class="mb-2 mt-4">Effectiveness</h4>
				<p class="m-0 mb-2">Upon both parties' signatures, the above Service Agreement is agreed upon and is effective immediately.</p>

				<h1 class="mt-5">Signatures</h1>
				<div class="ui-g">
					<div class="ui-g-6 ui-sm-12">
						<label>Merchant's Signature<span class="required-indicator">*</span>:</label>   
						<div class="input-group d-flex">
							<div id="companySignpad">
								<signature-pad #companySignpad [options]="signaturePadConfig" height="70" width="263"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign()" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label>Client's Signature:</label>  
						<div class="input-group d-flex">
							<div id="clientSignpad">
								<signature-pad #clientSignpad [options]="signaturePadConfig" height="70" width="263"></signature-pad>
							</div>
							<div class="input-group-append">
								<button class="btn btn-white" (click)="clearSign('client')" pTooltip="{{'Erase' | translate}}">
									<i class="fa-solid fa-eraser px-2"  style="cursor:pointer" title="clear signature"></i>    
								</button>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g pb-5">
		<div class="ui-g-12 text-center">
			<button (click)="cancelEditing()" *ngIf="disableEditingContract" class="btn btn-danger btn-lg mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button type="button" class="btn btn-purple btn-lg mx-1" (click)="showSendEmail()" ><i class="fa-solid fa-share-from-square"></i></button>
			<button type="button" class="btn btn-orange btn-lg mx-1" [cdkCopyToClipboard]="copyLink()" (cdkCopyToClipboardCopied)="onClipboardCopy($event)"><i class="fa-solid fa-link"></i></button>
			<button (click)="createPdf()" *ngIf="!disableEditingContract" class="btn btn-purple btn-lg mx-1"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</button>
			<button (click)="edit()"*ngIf="disableEditingContract && showEditButton" class="btn btn-info btn-lg mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i> {{'Edit' | translate}}</button>	
			<button (click)="generateContract()" *ngIf="!disableEditingContract" class="btn btn-primary btn-lg mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Complete' | translate}}</button>

		</div>
	</div>
</form>

<p-dialog header="Share eContract " [showHeader]="true" showEffect="fade" [closable]="true"
    [style]="{ 'z-index': 999999999, 'min-height': '30vh', 'padding': 0, 'width':'450px'}" styleClass="w-50"
    *ngIf="shareContractDialog" [(visible)]="shareContractDialog" modal="modal" [dismissableMask]="true">
    <div class="row mx-0 mb-2 align-items-center">
        <div class="ui-g-12">
            <label>{{'Recipients' | translate}}<span class="required-indicator"></span>: {{clientName}}</label>
            <div style="margin-top: 15px;">
                <span style="padding:5px;">Email: {{emailClientSelect}}</span>
            </div>
            <div style="margin-top: 15px;">
                <span style="padding:5px;">Title: <strong>eContract</strong></span>
            </div>
        </div>
    </div>
    <div class="row mx-0 mb-2 align-items-center">
        <div class="ui-g-12" style="min-height: 600px;">
            <label>{{'Preview' | translate}}<span class="required-indicator"></span>:</label>
            <div [innerHTML]="bodyEmail | safeHtml" class="ql-editor" id="note_details"></div>
        </div>
    </div>

    <p-footer>
        <div class="ui-g">
            <div class="ui-g-12 p-1" align="center">
                <button type="button" pButton label="Send Email" (click)="sendEmail()"></button>
                <button type="button" pButton label="Close" (click)="rejectEmail()"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>