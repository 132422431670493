import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MailManagerment} from '../model/mail-managerment.model';
import {LettermailSearch} from '../../shared/model/search/lettermail.search';
import {AbstractServiceV2} from '../../shared/service/abstract.v2.service';
import {Constants} from '../../shared/model/constants';
import {ListResponse} from '../../shared/model/list.response';
import {PaginatedResponse} from '../../shared/model/PageResponse/paginated.response';
import {Page} from '../../shared/model/page.model';
import {ToDo} from '../../todo/model/todo.model';
import {DeadlineReminder} from '../model/deadline-reminder.model';
import {DeadlineReminderSearch} from '../../shared/model/search/deadline-reminder.search';

@Injectable({
  providedIn: 'root'
})
export class DeadlineReminderService extends AbstractServiceV2<DeadlineReminder, DeadlineReminderSearch>{

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.DEADLINE_REMINDER, '');
  }

  getDropdown(searchParams:DeadlineReminderSearch):Observable<ListResponse<DeadlineReminder>>
  {
      let params = this.createParams(searchParams);
      return this._http.get<ListResponse<DeadlineReminder>>(this.baseUrlV2 + '/' + "dropdown", { params: params }).pipe(map(resp => resp));
  }

  search(options: any ): Observable<PaginatedResponse<DeadlineReminder>> {
      let params = this.createParams(options);
      return this._http.get<PaginatedResponse<DeadlineReminder>>(this.baseUrlV2 + "/search", {params: params}).pipe(map(res => res));
  }

  exportPdf(data): Observable<any> {
    return this._http.post(`${this.baseUrlV2}/exportPdf`, data).pipe(map(resp => resp));
  }    

  getDropdownCreatedBy(searchParams:DeadlineReminder):Observable<ListResponse<DeadlineReminder>>
  {
      let params = this.createParams(searchParams);
      return this._http.get<ListResponse<DeadlineReminder>>(this.baseUrlV2 + "/dropdownCreatedBy", { params: params }).pipe(map(resp => resp));
  }

  updateReminder(options: any): Observable<Page<DeadlineReminder>> {
      return this._http.put<Page<DeadlineReminder>>(`${this.baseUrlV2}`, options).pipe(map(res => res));
  }

  getDeadlinePopup(options: any) {
    return this._http.post<DeadlineReminder>(`${this.baseUrlV2}/popup`, options).pipe(map(resp => resp)); 
  }

  getDeadlineReminderHistory(entity) {
    return this._http.post(`${this.baseUrlV2}/search-history`, entity).pipe(map(res => res));
  }
}
