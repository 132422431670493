<p-multiSelect class="listOption" defaultLabel="{{'Select Items' | translate}}" [options]="options"
  [filter]="true" [(ngModel)]="selectedItems" [disabled]="disabled" (onChange)="onSelectItem($event)" (onPanelHide)="onPanelHide()">
  <ng-template pTemplate="item" let-item>
    <ng-container *ngIf="item.subItems && item.subItems.length">
      <div (click)="$event.stopPropagation();">
        <p-accordion>
          <p-accordionTab header="{{item.label | translate}}" [(selected)]="item.hasSubItemSelected">
            <ng-container *ngIf="item.hasSubItemSelected;">
              <span *ngFor="let subItem of item.subItems" class="d-flex text-truncate p-2"
                style="padding-top: 0 !important;">
                <p-checkbox name="{{item.value}}" [value]="subItem" [(ngModel)]="selectedSubItems"
                  (onChange)="onSelectSubItem($event, subItem)"></p-checkbox>
                <ng-container *ngIf="subItem.subItems && subItem.subItems.length">
                  <div (click)="$event.stopPropagation();">
                    <p-accordion>
                      <p-accordionTab header="{{subItem.label | translate}}" [(selected)]="item.hasSubItemSelected">
                        <ng-container *ngIf="item.hasSubItemSelected;">
                          <span *ngFor="let subItem2 of subItem.subItems" class="d-flex text-truncate p-2"
                          style="padding-top: 0 !important;">
                          <p-checkbox name="{{subItem2.value}}" [value]="subItem2" [(ngModel)]="selectedSubItems2"
                            (onChange)="onSelectSubItem2($event, subItem2)"></p-checkbox>
                          <span class="text-truncate pt-2">{{subItem2.label}}</span>
                        </span>
                        </ng-container>
                      </p-accordionTab>
                    </p-accordion>
                  </div>
                </ng-container>
                <ng-container *ngIf="!subItem.subItems || !item.subItems.length">
                  <span class="text-truncate pt-2">{{subItem.label}}</span>
                </ng-container>
              </span>
            </ng-container>

          </p-accordionTab>
        </p-accordion>
      </div>
    </ng-container>
    <ng-container *ngIf="!(item.subItems && item.subItems.length)">
      <span class="text-truncate">{{item.label | translate}}</span>
    </ng-container>
  </ng-template>
  <ng-template let-items pTemplate="selectedItems">
    <ng-contailer *ngIf="allSelectedLabels.length > 3">
      <span class="text-truncate">Selected {{allSelectedLabels.length}} items</span>
    </ng-contailer>
    <ng-contailer *ngIf="allSelectedLabels.length <= 3">
      <span class="text-truncate pl-2" *ngFor="let item of allSelectedLabels">{{item}}</span>
    </ng-contailer>
  </ng-template>
</p-multiSelect>