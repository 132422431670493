import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/service/auth/auth.service';
import { AbstractService } from '../../shared/service/abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RevenueService extends AbstractService<any> {

  constructor(private http: HttpClient, private authService: AuthService) {
    super(http, Constants.ROUTES.OPERATOR + '/service-revenue', '');
  }

  getRevenueList(paylaod) {
    return this.http.post(`${this.baseUrlV2}search`, paylaod);
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  update(payload) {
    return this.http.post(`${this.baseUrlV2}update`, payload)
  }

}